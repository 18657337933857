import React, { Component } from 'react';
import { func, object, shape, string } from 'prop-types';
import { getSegments, newSegment } from 'shared/common.api';
import { UserStateContext } from 'context/user-state-context';
import { Button } from 'components/button/button.component';
import { DynamicTypeAhead } from '../../../../components/dynamic-type-ahead/dynamic-type-ahead.component';
import { Loader } from 'components/loader/loader.component';
import { Stack, Typography, Autocomplete, Box, FormControl, InputAdornment, Paper, TextField, Tooltip } from '@mui/material';
import { CustomerIcon, TransactionIcon, VehicleIcon, ShoppingCartIcon, Calendar30DaysIcon } from 'components/mui';
const Styles = {
  textHelper: {
    textTransform: 'none',
    padding: '8px',
    fontWeight: '400'
  },
  createButton: {
    display: 'block',
    float: 'right',
    paddingTop: '4px'
  }
};
export class ActionAddToSegment extends Component {
  static contextType = UserStateContext;
  static propTypes = {
    update: func,
    node: object,
    data: shape({
      segment_id: string
    })
  };
  state = {
    segmentList: [],
    isCreateNewSegment: false,
    loadingCreateSegment: false,
    newSegment: {
      name: '',
      description: ''
    },
    segmentNamePlaceholder: 'Choose Static Segment'
  };
  componentDidMount() {
    getSegments({
      company: this.context.asCompany.id,
      time_dynamic_segment: false,
      limit: 600
    }).then(({
      data
    }) => {
      this.setState({
        segmentList: data.results
      });
    });
  }
  toggleSegment = id => {
    const {
      segment_id
    } = this.props.data;
    this.props.update({
      segment_id: !segment_id ? id : segment_id.id !== id ? id : null
    });
  };
  onChangeNewSegment = e => {
    this.setState({
      newSegment: {
        ...this.state.newSegment,
        [e.target.name]: e.target.value
      }
    });
  };
  createNewSegment = () => {
    this.setState({
      loadingCreateSegment: true
    });
    newSegment({
      ...this.state.newSegment,
      company: this.context.asCompany.id
    }).then(({
      data
    }) => {
      this.toggleSegment(data.id);
      getSegments({
        company: this.context.asCompany.id,
        time_dynamic_segment: false,
        limit: 600
      }).then(({
        data
      }) => {
        this.setState({
          segmentList: data.results,
          isCreateNewSegment: false,
          loadingCreateSegment: false,
          newSegment: {
            name: '',
            description: ''
          }
        });
      });
    }).catch(error => {
      this.setState({
        loadingCreateSegment: false
      });
    });
  };
  isNewSegmentValid = () => {
    const {
      newSegment
    } = this.state;
    if (newSegment.name.length === 0) {
      return true;
    }
    //if(newSegment.description.length === 0){
    //  return true
    // }
    return false;
  };
  makeOverflowTooltip = (content, length, bold) => {
    const contentLength = content?.length || content?.props?.children?.length;
    if (content) {
      return <Tooltip title={contentLength > length ? <> {content} </> : ''}>
          <Typography variant={bold ? 'tableHeader' : ''}>
            {contentLength > length ? <>{('' + (content.props?.children || content)).slice(0, length) + '...'}</> : <>{content}</>}
          </Typography>
        </Tooltip>;
    }
    return <Typography variant="nullData" color={'#BEBEBE'}>
        <span>--</span>{' '}
      </Typography>;
  };
  getIcon = included => {
    if (included === 'possession') {
      return <VehicleIcon />;
    } else if (included === 'transaction') {
      return <TransactionIcon />;
    } else if (included === 'schedule') {
      return <Calendar30DaysIcon />;
    } else if (included === 'cart') {
      return <ShoppingCartIcon />;
    } else {
      return <CustomerIcon />;
    }
  };
  render() {
    const {
      segmentList,
      isCreateNewSegment,
      newSegment,
      loadingCreateSegment
    } = this.state;
    const selectedSegment = segmentList.find(segment => segment.id === this.props.data.segment_id);
    const segmentInclusion = selectedSegment ? selectedSegment.included : null;
    const segmentNamePlaceholder = selectedSegment ? `${selectedSegment.name}` : 'Choose Static Segment';
    const {
      getIcon
    } = this;
    return <div>
        <div className="form-group" style={{
        marginBottom: isCreateNewSegment ? '48px' : '-9px'
      }}>
          <Paper sx={{
          width: '103px',
          height: '19px',
          pl: '10px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          position: 'absolute',
          marginTop: '-16px',
          zIndex: 1,
          boxShadow: 'none'
        }}>
            <Typography sx={{
            position: 'absolute',
            marginTop: '10px',
            opacity: 0.5,
            '&.Mui-focused': {
              opacity: 1
            },
            fontSize: '12px'
          }} id="static-segment-select-label">
              Static Segment
            </Typography>
          </Paper>
          <FormControl variant="outlined" sx={{
          width: '315px'
        }}>
            <Autocomplete id="choose-static-segment-select" options={segmentList} getOptionLabel={option => option.name} value={selectedSegment || null} onChange={(event, newValue) => {
            if (!newValue) {
              this.toggleSegment(null);
              return;
            }
            const segment = segmentList.find(segment => segment.name === newValue.name);
            this.toggleSegment(segment ? segment.id : null);
          }} filterOptions={(options, {
            inputValue
          }) => {
            return options.filter(option => option.name.toLowerCase().includes(inputValue.toLowerCase()));
          }} renderInput={params => <TextField {...params} label="" placeholder={selectedSegment ? `${selectedSegment.name}` : 'Choose Static Segment'} InputProps={{
            ...params.InputProps,
            startAdornment: <InputAdornment position="start">{getIcon(selectedSegment?.included)}</InputAdornment>
          }} value={selectedSegment ? selectedSegment.name : ''} />} renderOption={(props, option, {
            index
          }) => <Box component="li" {...props} key={option.name + index} sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px'
          }}>
                  {getIcon(option.included)}
                  {this.makeOverflowTooltip(option.name, 30)}
                </Box>} />
          </FormControl>

          <Stack direction={'column'} spacing={2} sx={{
          pb: '25px',
          pt: '10px',
          opacity: 0.7
        }}>
            <Typography>{'Segment Type: Static'}</Typography>

            <Typography>
              {'Segment Inclusion: '}

              {segmentInclusion === 'possession' ? 'Vehicle' : segmentInclusion ? segmentInclusion.charAt(0).toUpperCase() + segmentInclusion.slice(1) : segmentNamePlaceholder === 'Choose Static Segment' ? 'None' : 'Customer Only'}
            </Typography>
          </Stack>
        </div>
        <div>
          {/* create new segment stuff */}
          {isCreateNewSegment ? <div className="form-group mt-4 mb-2">
              <label>New Static Segment</label>
              <input type="text" className="form-control mb-2" name="name" value={newSegment.name || ''} placeholder="Segment Name" onChange={e => this.onChangeNewSegment(e)} />
              <div className="form-control" style={{
            height: 'auto',
            padding: '0px',
            border: 'none'
          }}>
                <textarea cols="3" name="description" className="form-control " placeholder="Input Segment Description" onChange={e => this.onChangeNewSegment(e)} value={newSegment.description || ''} />
                <div style={Styles.createButton}>
                  <Button actionType="primary" className="mt-2" onClick={this.createNewSegment} disabled={loadingCreateSegment || this.isNewSegmentValid()}>
                    {loadingCreateSegment ? <Loader style={{
                  float: 'right'
                }} size="sm" /> : 'Create New Segment'}
                  </Button>
                </div>
              </div>
            </div> : <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          paddingTop: '20px'
        }}>
              <Typography component="label" style={{
            textTransform: 'none',
            cursor: 'pointer',
            color: '#53A6D6',
            fontSize: '14px'
          }} onClick={() => this.setState({
            isCreateNewSegment: true
          })}>
                Create New Static Segment
              </Typography>
            </div>}
        </div>
      </div>;
  }
}