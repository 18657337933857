import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Icon } from '../../icon/icon.component';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { FormLabel, IconButton, Button } from '@mui/material';
import { AddCircleFilledIcon } from 'components/mui';
import { Toggle } from 'components/toggle/toggle.component';
const OutlinedBox = ({
  width,
  height,
  onClick,
  label,
  children
}) => {
  return <Box sx={{
    width: width,
    height: height,
    display: 'flex'
  }} onClick={onClick}>
      <FormLabel sx={{
      marginLeft: '0.71em',
      marginTop: '-0.71em',
      paddingLeft: '0.44em',
      paddingRight: '0.44em',
      zIndex: 2,
      backgroundColor: theme => theme.palette.background.default,
      position: 'absolute',
      fontSize: '0.75em',
      width: 'auto',
      color: '#BEBEBE'
    }}>
        {label}
      </FormLabel>
      <Box sx={{
      position: 'relative',
      borderRadius: '14px',
      backgroundColor: '#FFFFFF',
      fontSize: '0.875rem',
      width: '100%'
    }}>
        <Box sx={{
        padding: theme => theme.spacing(1),
        display: 'flex',
        gap: theme => theme.spacing(1),
        flexWrap: 'wrap',
        overflow: 'auto'
      }}>
          {children}
        </Box>
        <fieldset aria-hidden={'true'} style={{
        textAlign: 'left',
        position: 'absolute',
        bottom: 0,
        right: 0,
        top: '-5px',
        left: 0,
        margin: 0,
        padding: '0 8px',
        pointerEvents: 'none',
        //borderRadius: 'inherit',
        borderStyle: 'solid',
        borderWidth: '1px',
        overflow: 'hidden',
        minWidth: '0%',
        borderColor: '#BEBEBE',
        //height: '56px',
        // minWidth: '216px',
        borderRadius: '14px'
      }}>
          <legend style={{
          float: 'unset',
          overflow: 'hidden',
          display: 'block',
          width: 'auto',
          padding: 0,
          height: '11px',
          fontSize: '0.75em',
          visibility: 'hidden',
          maxWidth: '100%',
          //'-webkit-transition': 'max-width 100ms cubic-bezier(0.0, 0, 0.2, 1) 50ms',
          transition: 'max-width 100ms cubic-bezier(0.0, 0, 0.2, 1) 50ms',
          whiteSpace: 'nowrap'
        }}>
            <span>{label}</span>
          </legend>
        </fieldset>
      </Box>
    </Box>;
};
export const MultiSelect = ({
  value,
  options,
  width = '150px',
  height = '28px',
  label,
  top = 0,
  placeholder = 'Select options',
  onChange,
  zIndex = 200
}) => {
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
  const open = Boolean(menuAnchorEl);
  const [showMore, setShowMore] = React.useState(null);
  const [search, setSearch] = React.useState('');
  const [selectedLabel, setSelectedLabel] = React.useState(null);
  const [selectedOptions, setSelectedOptions] = React.useState([]);
  const [customOptions, setCustomOptions] = React.useState([]);
  const [customValue, setCustomValue] = React.useState('');
  const setOptions = () => {
    setSelectedOptions(options.filter(o => value.includes(o)));
    setCustomOptions((value || []).filter(o => !options.includes(o)));
  };
  React.useEffect(() => {
    if (options.length > 10) {
      setShowMore(showMore => showMore === null ? false : !showMore);
    }
    setOptions();
  }, [options]);
  React.useEffect(() => {
    const allOptions = [...selectedOptions, ...customOptions];
    if (allOptions.length === 0) {
      setSelectedLabel(null);
    } else if (allOptions.length === 1) {
      setSelectedLabel(allOptions[0]);
    } else {
      setSelectedLabel(`${allOptions[0]}, +${allOptions.length - 1} more`);
    }
  }, [customOptions, selectedOptions]);
  const handleClick = event => {
    setMenuAnchorEl(open ? null : event.currentTarget);
  };
  const handleClose = () => {
    setMenuAnchorEl(null);
    setSearch('');
    setCustomValue('');
    setOptions();
  };

  // Only lower casing this once since it's used in a filter
  const lowerSearch = search.toLowerCase();
  // Generate a list of options to see.  This could be a state variable but I think this might be more efficient.
  const filteredOptions = options.filter((opt, index) => lowerSearch === '' && (index < 10 || showMore) || lowerSearch !== '' && opt.toLowerCase().startsWith(lowerSearch));
  // Calculate whether or not all options are selected
  const allSelected = search === '' && selectedOptions.length === options.length || search !== '' && selectedOptions.length === filteredOptions.length;
  const handleSelect = option => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter(o => o !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };
  const handleSelectAll = () => {
    if (allSelected) {
      setSelectedOptions([]);
    } else {
      setSelectedOptions(search === '' ? [...options] : [...filteredOptions]);
    }
  };
  const addCustom = () => {
    setCustomOptions([...customOptions, customValue]);
    setCustomValue('');
  };
  const removeCustom = option => {
    setCustomOptions(customOptions.filter(o => o !== option));
  };
  const handleApply = () => {
    onChange([...selectedOptions, ...customOptions]);
    setMenuAnchorEl(null);
  };
  return <>
      <OutlinedBox onClick={handleClick} width={width} height={height}>
        <Box display="flex" flexDirection="row" justifyContent="space-around" sx={{
        width: '100%',
        top,
        position: 'absolute',
        cursor: 'pointer',
        height
      }}>
          <Box sx={{
          flexGrow: 2,
          width: '100%',
          alignContent: 'center',
          margin: 'auto',
          alignItems: 'center',
          pl: '8px'
        }}>
            <Box sx={{
            display: 'flex',
            alignContent: 'center',
            alignItems: 'center'
          }}>
              <Typography sx={{
              color: selectedLabel ? '#1D252D' : '#1D252D80',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis'
            }}>
                {selectedLabel || placeholder}
              </Typography>
            </Box>
          </Box>
          {open ? <Box sx={{
          pl: '16px',
          transform: 'rotate(180deg)',
          alignContent: 'center',
          display: 'flex',
          alignItems: 'center'
        }}>
              <Icon fill="#1C1B1F" name="custom-keyboard-arrow-down" size={20} />
            </Box> : <Box sx={{
          alignContent: 'center',
          display: 'flex',
          alignItems: 'center',
          pr: '16px'
        }}>
              <Icon fill="#1C1B1F" name="custom-keyboard-arrow-down" size={20} />
            </Box>}
        </Box>
      </OutlinedBox>
      <Popper anchorEl={menuAnchorEl} open={open} onClose={handleClose} placement="bottom-start" sx={{
      zIndex
    }} modifiers={[{
      name: 'preventOverflow',
      enabled: true,
      options: {
        altAxis: true,
        altBoundary: true,
        tether: true,
        rootBoundary: 'viewport',
        padding: 8
      }
    }]}>
        <ClickAwayListener onClickAway={handleClose}>
          <Paper elevation={0} sx={{
          borderRadius: '16px',
          overflow: 'auto',
          width: '216px',
          filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))',
          maxHeight: '50vh'
        }}>
            <Box sx={{
            p: 1
          }} display="flex" flexDirection="column" gap="8px">
              <Box sx={{
              py: 1
            }} display="flex" flexDirection="row" justifyContent="space-between">
                Select {search === '' ? 'all' : 'matching'}
                <Toggle checked={allSelected} onToggle={handleSelectAll} />
              </Box>

              <input style={{
              marginBottom: '8px'
            }} value={search} onChange={e => setSearch(e.target.value)} placeholder="Search" className="form-control" />

              {filteredOptions.map((opt, i) => <Box display="flex" flexDirection="row" alignItems="center" key={opt}>
                  <input type="checkbox" id={`opt-${i}`} checked={selectedOptions.includes(opt)} onChange={() => handleSelect(opt)} />
                  <label htmlFor={`opt-${i}`} style={{
                marginBottom: 0
              }}>
                    {opt}
                  </label>
                </Box>)}

              {filteredOptions.length === 0 && <Box display="flex" flexDirection="row">
                  <em>No options</em>
                </Box>}

              {search === '' && <Button onClick={() => setShowMore(!showMore)}>Show {showMore ? 'less' : 'more'}</Button>}

              <hr style={{
              margin: 0,
              height: '1px',
              backgroundColor: '#ccc',
              border: 'none'
            }} />

              <Box display="flex" flexDirection="row" alignItems="center">
                <input placeholder="Add other value" className="form-control" value={customValue} onChange={e => setCustomValue(e.target.value)} />
                <IconButton sx={{
                height: '32px',
                borderRadius: '23px',
                color: '#3898D9',
                '&:hover': {
                  backgroundColor: '#C2E7FF'
                }
              }} onClick={addCustom}>
                  <AddCircleFilledIcon />
                </IconButton>
              </Box>

              {customOptions.map((opt, i) => <Box display="flex" flexDirection="row" alignItems="center" key={opt}>
                  <input type="checkbox" id={`custom-opt-${i}`} checked={true} onChange={() => removeCustom(opt)} />
                  <label htmlFor={`custom-opt-${i}`} style={{
                marginBottom: 0
              }}>
                    {opt}
                  </label>
                </Box>)}

              <hr style={{
              margin: 0,
              height: '1px',
              backgroundColor: '#ccc',
              border: 'none'
            }} />

              <Box display="flex" flexDirection="row" justifyContent="flex-end" gap="8px">
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleApply}>Apply</Button>
              </Box>
            </Box>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>;
};