import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

/**
StaticTypeAheadMui is a component that provides a type-ahead input field with a dropdown list of options.
@Type {React.Component}
*/

/**
   StaticTypeAheadMui is a Custom component that provides a type-ahead input field with a dropdown list of options.
   * @type {React.Component}
   * @param {Object} displayProperty - The property of the object to display in the dropdown list. Generally the ID or key of the object.
   * @param {Object} itemProperty - The property of the object to use as the value of the input field. Generally the name of the object.
   * @param {Object} items - The list of objects to display in the dropdown list.
   * @param {Object} value - The selected object from the dropdown list.
   * @param {Object} onChange - The function to call when the selected object changes. Generally text input
   * @param {Object} disableFreeSolo - Whether to allow free text input. Default is false.
   * @param {Object} autoFocus - Whether to focus on the input field when the component is rendered. Default is false.
   * @param {Object} size - The size of the input field. Default is 'medium'.
   * @param {Object} placeholder - The placeholder text for the input field. Default is an empty string.
   * @param {Object} label - The label for the input field. Default is an empty string.
   *
   *
   *
   */

export const StaticTypeAheadMui = props => {
  const [search, setSearch] = useState('');
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  useEffect(() => {
    const results = props.items || [];
    setOptions(results);
    for (const result of results) {
      if (result.id === props.value) {
        setValue(result);
        if (result?.name) {
          setInputValue(result.name);
        }
      }
    }
  }, [search]);
  return <>
      <div>
        <Autocomplete disabled={props?.disabled} freeSolo={!props?.disableFreeSolo} value={value} inputValue={inputValue} onChange={(event, newValue) => {
        setValue(newValue);
        props.onChange(newValue);
      }} onInputChange={(event, newInputValue) => {
        setSearch(newInputValue);
        setInputValue(newInputValue);
      }} options={options} getOptionLabel={option => {
        return option[props.displayProperty];
      }} sx={{
        width: 300,
        ...props.sx
      }} renderInput={params => {
        return <TextField {...params} autoFocus={props?.autoFocus} size={props.size || 'medium'} placeholder={props?.placeholder || ''} label={props?.label || ''} />;
      }} renderOption={(propsOpt, option) => {
        return <li {...propsOpt} key={option.id}>
                {option[props.displayProperty]}
              </li>;
      }} />
      </div>
    </>;
};