import React from 'react';
import 'react-toastify/dist/ReactToastify.min.css';
import { Icon } from '../components/icon/icon.component';
const Page403 = () => {
  return <div className="wrapper">
      <div style={{
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignContent: 'center'
    }}>
        <div style={{
        textAlign: 'center',
        marginBottom: '25px'
      }}>
        <Icon name="fa-solid-exclamation-triangle" size={24} />
        </div>
        <div style={{
        textAlign: 'center',
        fontSize: '20px'
      }}>
          You don't have permission to view this page.
        </div>
      </div>
    </div>;
};
export default Page403;