import React from 'react';
function Icon() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none" viewBox="0 0 24 25">
      <mask id="mask0_7_3397" style={{
      maskType: 'alpha'
    }} width="24" height="25" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0 0.5H24V24.5H0z"></path>
      </mask>
      <g fill="#1D252D" mask="url(#mask0_7_3397)">
        <path d="M12.528 17.057a.726.726 0 01-.534-.215.726.726 0 01-.216-.535c0-.213.072-.39.216-.534a.726.726 0 01.534-.216h7c.213 0 .39.072.535.216a.726.726 0 01.215.535c0 .212-.072.39-.215.534a.726.726 0 01-.535.215h-7zm0-7.615a.726.726 0 01-.534-.216.726.726 0 01-.216-.534c0-.213.072-.391.216-.535a.726.726 0 01.534-.215h7c.213 0 .39.072.535.216a.726.726 0 01.215.534c0 .213-.072.39-.215.534a.726.726 0 01-.535.216h-7z"></path>
        <path fillRule="evenodd" d="M3.722 7.596c0-.966.783-1.75 1.75-1.75h2.192c.967 0 1.75.784 1.75 1.75V9.79a1.75 1.75 0 01-1.75 1.75H5.472a1.75 1.75 0 01-1.75-1.75V7.597zm1.75-.25a.25.25 0 00-.25.25V9.79c0 .138.112.25.25.25h2.192a.25.25 0 00.25-.25V7.597a.25.25 0 00-.25-.25H5.472zM3.722 15.21c0-.966.783-1.75 1.75-1.75h2.192c.967 0 1.75.784 1.75 1.75v2.194a1.75 1.75 0 01-1.75 1.75H5.472a1.75 1.75 0 01-1.75-1.75V15.21zm1.75-.25a.25.25 0 00-.25.25v2.194c0 .137.112.25.25.25h2.192a.25.25 0 00.25-.25V15.21a.25.25 0 00-.25-.25H5.472z" clipRule="evenodd"></path>
      </g>
    </svg>;
}
export default Icon;