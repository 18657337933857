import React from 'react';
function Icon(props) {
  if (props.size == 'sm') {
    return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill={props.fill || '#1D252D'} viewBox="0 -960 960 960">
        <path d="M252.31-400v-160h-80q-4.62 0-8.46 3.85-3.85 3.84-3.85 8.46v375.38q0 4.62 3.85 8.46 3.84 3.85 8.46 3.85h615.38q4.62 0 8.46-3.85 3.85-3.84 3.85-8.46v-375.38q0-4.62-3.85-8.46-3.84-3.85-8.46-3.85H400v-60h387.69Q818-620 839-599q21 21 21 51.31v375.38Q860-142 839-121q-21 21-51.31 21H172.31Q142-100 121-121q-21-21-21-51.31v-375.38Q100-578 121-599q21-21 51.31-21h80v-240h290v152.31h-230V-400h-60zM160-560v160-160 400-400z"></path>
      </svg>;
  }
  return <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill={props.fill || '#1D252D'} viewBox="0 -960 960 960">
      <path d="M252.31-408.26v-154.3h-89.74q-4.62 0-8.47 3.84-3.84 3.85-3.84 8.46v387.69q0 4.62 3.84 8.47 3.85 3.84 8.47 3.84h634.86q4.62 0 8.47-3.84 3.84-3.85 3.84-8.47v-387.69q0-4.61-3.84-8.46-3.85-3.84-8.47-3.84H380.51v-50.26h416.92q25.79 0 44.18 18.49Q860-575.85 860-550.26v387.69q0 25.79-18.39 44.18T797.43-100H162.57q-25.79 0-44.18-18.39T100-162.57v-387.69q0-25.59 18.39-44.07 18.39-18.49 44.18-18.49h89.74V-860h280.25v152.31h-230v299.43h-50.25zm-102.05-154.3v154.3-154.3 412.3-412.3z"></path>
    </svg>;
}
export default Icon;