import React, { useContext, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import { Rules } from 'components/rules/rules.component';
import { UserStateContext } from 'context/user-state-context';
export const EditSegmentBuilder = ({
  segment,
  setSegment,
  setHasUnsavedChanges,
  showErrors,
  setIsValid
}) => {
  const {
    companyFields
  } = useContext(UserStateContext);
  const [onHoverOverride, setOnHoverOverride] = React.useState(false);
  const updateQuery = segment_builder => {
    setHasUnsavedChanges(true);
    setSegment(prevState => ({
      ...prevState,
      segment_builder
    }));
  };
  return <Stack spacing="30px">
      <Box onClick={e => setOnHoverOverride(!onHoverOverride)} display="flex" flexDirection="row"
    //justifyContent="space-between"
    sx={{
      width: '100%'
    }}>
        <Typography sx={{
        fontFamily: 'Nexa',
        fontSize: 20,
        color: '#1D252D'
      }}>Segment Builder </Typography>
      </Box>

      <Rules fieldModels={companyFields.filter(field => field.id !== 'location')} query={segment.segment_builder || {
      and: [{}]
    }} onChange={updateQuery} hoverOverride={onHoverOverride} showRuleErrors={showErrors} isQueryValid={e => {
      if (segment?.segment_builder?.and?.length == 0 || segment?.segment_builder?.or?.length == 0) {
        setIsValid(true);
      } else {
        setIsValid(e);
      }
    }} />
    </Stack>;
};