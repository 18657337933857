import React, { Component } from 'react';
import { func, object } from 'prop-types';
import { RRule } from 'rrule';
import { toInteger } from 'lodash';
import { DateTime } from 'luxon';
import { annuallyOptions, dayNumToStr, numToMonth } from './trigger-recurring.utils';
import { RecurringOption } from './recurring-option.component';
import { FancyInput } from './fancy-input.component';
export class Annually extends Component {
  static propTypes = {
    update: func,
    rrule: object,
    data: object
  };
  setYearly = patternOption => {
    this.props.update({
      patternOption,
      rrule: {
        ...this.props.rrule.options,
        ...annuallyOptions[patternOption]
      }
    });
  };
  update = options => {
    this.props.update({
      rrule: {
        ...this.props.rrule.options,
        ...options
      }
    });
  };
  onYearDayChange = e => {
    const num = toInteger(e.target.value) || 1;
    this.update({
      bymonthday: num > 31 ? 31 : num
    });
  };
  onDayChange = e => {
    const nth = this.props.rrule.options.bynweekday[0][1];
    this.update({
      byweekday: RRule[e.target.value].nth(nth)
    });
  };
  onFirstLastChange = e => {
    const day = this.props.rrule.options.bynweekday[0][0];
    this.update({
      byweekday: RRule[dayNumToStr(day)].nth(parseInt(e.target.value))
    });
  };
  activeByWeekday = () => {
    const {
      bynweekday
    } = this.props.rrule.options;
    if (bynweekday) {
      const [day, nth] = bynweekday[0];
      return {
        day: dayNumToStr(day),
        nth
      };
    }
    return {
      day: null,
      nth: null
    };
  };
  updateMonth = month => {
    this.update({
      bymonth: month.target.value
    });
  };
  render() {
    const {
      rrule,
      data
    } = this.props;
    const month = numToMonth(rrule.options.bymonth[0]);
    return <div>
        <RecurringOption active={data.patternOption === 'everyYear'} onChange={() => this.setYearly('everyYear')} inactiveMessage={`Every year on the 1st in January`}>
          <div style={{
          display: 'inline-flex',
          alignItems: 'center'
        }}>
            Every year on{' '}
            <select className="form-control recurring-inline-with-selection" onChange={this.updateMonth} value={rrule.options.bymonth[0]} style={{
            marginBottom: 0,
            marginLeft: '.8rem'
          }}>
              <option value="1">January</option>
              <option value="2">February</option>
              <option value="3">March</option>
              <option value="4">April</option>
              <option value="5">May</option>
              <option value="6">June</option>
              <option value="7">July</option>
              <option value="8">August</option>
              <option value="9">September</option>
              <option value="10">October</option>
              <option value="11">November</option>
              <option value="12">December</option>
            </select>
            <FancyInput onChange={this.onYearDayChange} value={Array.isArray(rrule.options.bymonthday) ? rrule.options.bymonthday[0] : rrule.options.bymonthday} ordinal />
          </div>
        </RecurringOption>
        <RecurringOption active={data.patternOption === 'onEveryYear'} onChange={() => this.setYearly('onEveryYear')} inactiveMessage={`First Monday in January`}>
          <select className="form-control recurring-inline-with-selection" onChange={this.onFirstLastChange} value={this.activeByWeekday().nth}>
            <option value="1">First</option>
            <option value="2">Second</option>
            <option value="3">Third</option>
            <option value="4">Fourth</option>
            <option value="5">Fifth</option>
            <option value="-1">Last</option>
          </select>
          <select className="form-control recurring-inline-with-selection" onChange={this.onDayChange} value={this.activeByWeekday().day} style={{
          marginBottom: 0
        }}>
            <option value="MO">Monday</option>
            <option value="TU">Tuesday</option>
            <option value="WE">Wednesday</option>
            <option value="TH">Thursday</option>
            <option value="FR">Friday</option>
            <option value="SA">Saturday</option>
            <option value="SU">Sunday</option>
          </select>{' '}
          <br />
          <span style={{
          marginLeft: '2.2rem'
        }}>In</span>
          <select className="form-control recurring-inline-with-selection" onChange={this.updateMonth} value={rrule.options.bymonth[0]} style={{
          marginBottom: 0,
          marginLeft: '.8rem'
        }}>
            <option value="1">January</option>
            <option value="2">February</option>
            <option value="3">March</option>
            <option value="4">April</option>
            <option value="5">May</option>
            <option value="6">June</option>
            <option value="7">July</option>
            <option value="8">August</option>
            <option value="9">September</option>
            <option value="10">October</option>
            <option value="11">November</option>
            <option value="12">December</option>
          </select>
        </RecurringOption>
      </div>;
  }
}