import React from 'react';
function Icon(props) {
  if (props.size == 'lg') {
    return <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 -960 960 960">
        <path fill={props.fill || '#1D252D'} d="M100.001-337.18v-56.794h57.564v56.794h-57.564zm0-150.641v-56.793h57.564v56.793h-57.564zm0-149.999v-56.409h57.564v56.409h-57.564zm152.692 297.178v-50.255h139.255v50.255H252.693zm0-150.256v-50.255h501.306v50.255H252.693zm0-149.999v-50.255h501.306v50.255H252.693zm381.025 460.896L483.334-331.616l35.999-35.999 114.795 115.616 223.153-222.897 35.794 35.537-259.357 259.358z"></path>
      </svg>;
  }
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 -960 960 960">
      <path fill={props.fill || '#1D252D'} d="M100.001-332.309v-66.537h67.307v66.537h-67.307zm0-148.076v-66.537h67.307v66.537h-67.307zm0-147.691v-66.153h67.307v66.153h-67.307zm155 292.306v-59.999h138.845v59.999H255.001zm0-147.692v-59.998h491.152v59.998H255.001zm0-147.691v-59.999h491.152v59.999H255.001zM632.23-180.001L479.539-332.693l42.768-42.768L632.23-265.538 850.922-484.23l42.153 43.384L632.23-180.001z"></path>
    </svg>;
}
export default Icon;