import React, { Component } from 'react';
import { bool, func, oneOf, string, node, oneOfType } from 'prop-types';
import { Modal } from './modal.component';
import { Button } from '../button/button.component';
import { UserStateContext } from 'context/user-state-context';
export class ModalCompanySelect extends Component {
  static contextType = UserStateContext;
  state = {
    selectedCompany: this.context.asCompany.id
  };
  static propTypes = {
    open: bool,
    onClose: func,
    onCancel: func,
    onSubmit: func,
    allowBackdropClick: bool,
    title: string,
    size: oneOf(['sm', 'md', 'lg', 'xl']),
    submitText: oneOfType([string, node]),
    cancelText: oneOfType([string, node]),
    children: node
  };
  render() {
    const {
      selectedCompany
    } = this.state;
    const {
      companies
    } = this.context.user;
    const {
      open,
      onClose,
      onCancel,
      allowBackdropClick,
      title,
      size,
      type,
      onSubmit,
      submitText,
      cancelText,
      children
    } = this.props;
    return <div>
        <Modal open={open} onClose={() => {
        onCancel();
        onClose();
      }} allowBackdropClick={allowBackdropClick} title={title || "Select company to use"} size={size || "sm"} type={type}>
          <div className="modal__body">
            {children}

            <select className="form-control" value={selectedCompany} onChange={event => this.setState({
            selectedCompany: event.target.value
          })}>
              {companies.map(company => <option value={company.id} key={company.id}>{company.name}</option>)}
            </select>
          </div>
          <div className="modal__actions">
            <Button onClick={() => {
            onCancel();
            onClose();
          }}>
              {cancelText || 'Cancel'}
            </Button>
            <Button actionType="primary" onClick={() => {
            onSubmit(selectedCompany);
            onClose();
          }}>
              {submitText || 'Select'}
            </Button>
          </div>
        </Modal>
      </div>;
  }
}