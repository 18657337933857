import React from 'react';
function Icon(props) {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none" viewBox="0 0 24 25">
      <mask id="mask0_49_1256" style={{
      maskType: 'alpha'
    }} width="25" height="25" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M24 24.257H48V48.257000000000005H24z" transform="rotate(-180 24 24.257)"></path>
      </mask>
      <g mask="url(#mask0_49_1256)">
        <path fill={props.fill || '#1D252D'} d="M12 9.25c.117 0 .23.017.337.05.109.034.205.1.288.2L17.15 14c.133.15.2.325.2.525a.72.72 0 01-.225.525.765.765 0 01-.525.2.765.765 0 01-.525-.2L12 10.976 7.9 15.05a.606.606 0 01-.5.213.821.821 0 01-.525-.213.733.733 0 01-.225-.537c0-.208.075-.38.225-.512l4.5-4.5a.605.605 0 01.287-.2c.109-.034.221-.05.338-.05z"></path>
      </g>
    </svg>;
}
export default Icon;