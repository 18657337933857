import React from 'react';
function Icon() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" fill="none" viewBox="0 0 25 24">
      <mask id="mask0_1036_6155" style={{
      maskType: 'alpha'
    }} width="25" height="24" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#24335D" d="M0.517 0H24.517V24H0.517z"></path>
      </mask>
      <g mask="url(#mask0_1036_6155)">
        <path fill="#fff" d="M5.817 20.5c-.5 0-.925-.175-1.275-.525a1.736 1.736 0 01-.525-1.275V5.3c0-.5.175-.925.525-1.275.35-.35.775-.525 1.275-.525h13.4c.5 0 .925.175 1.275.525.35.35.525.775.525 1.275v13.4c0 .5-.175.925-.525 1.275-.35.35-.775.525-1.275.525h-13.4zm6.7-4.2a2.91 2.91 0 001.488-.4c.458-.267.82-.633 1.087-1.1a.942.942 0 01.363-.362 1 1 0 01.512-.138h3.55v-9a.289.289 0 00-.087-.212.29.29 0 00-.213-.088h-13.4a.287.287 0 00-.212.088.287.287 0 00-.088.212v9h3.55a1 1 0 01.512.138c.159.091.28.212.363.362.267.467.63.833 1.088 1.1.458.267.954.4 1.487.4z"></path>
      </g>
    </svg>;
}
export default Icon;