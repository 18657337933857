import React, { useContext } from 'react';
import { getCompany, getRTCRule, getRTCRules } from '../../shared/common.api';
import { Box, Button, CircularProgress, ClickAwayListener, Divider, IconButton, Paper, Popper, Typography, Radio, RadioGroup, FormControlLabel, Skeleton, TextField, InputAdornment } from '@mui/material';
import Stack from '@mui/material/Stack';
import TableRow, { tableRowClasses } from '@mui/material/TableRow';
import { UserStateContext } from 'context/user-state-context';
import { Calendar24HoursIcon, Calendar365DaysIcon, DirectionsCarCustomerIcon, HelpIcon, RocketLaunchIcon, TargetIcon, TransactionIcon, UnfoldLessIcon } from 'components/mui';
import { AntSwitch } from 'components/mui/switch/ant-switch.component';
export const RTCStepThree = ({
  RTC,
  updateRTC
}) => {
  const {
    asCompany
  } = useContext(UserStateContext);
  const [isLoading, setIsLoading] = React.useState(true);
  const [helpAnchorEl, setHelpAnchorEl] = React.useState(null);
  const [helpData, setHelpData] = React.useState(null);
  const [acceleratorUnitType, setAcceleratorUnitType] = React.useState(RTC.accelerator > 0 && RTC.accelerator < 1 ? 'percent' : 'units');
  const [automotiveOptions, setAutomotiveOptions] = React.useState(false);
  const AUTOMOTIVEINDUSTRIES = ['Quick Lube', 'Automotive Repair'];
  React.useEffect(() => {
    setIsLoading(true);
    getCompany(asCompany.id).then(data => {
      if (AUTOMOTIVEINDUSTRIES.find(i => i === data.data.naics_title)) {
        setAutomotiveOptions(true);
      } else {
        updateRTC({
          ...RTC,
          type: 'days',
          includes_possession: false
        });
      }
      setIsLoading(false);
    });
  }, [asCompany.id]);
  const handleHelpPopupClick = (e, index) => {
    if (index === 1) {
      setHelpData({
        title: <Typography variant="tableHeader"> {automotiveOptions ? 'Customer or Vehicle' : 'Customer'}</Typography>,
        content: <Typography>
            You can make return recommendations based on the customer overall, or on the individual vehicle with which they are associated.
          </Typography>
      });
    } else if (index == 2) {
      setHelpData({
        title: <Typography variant="tableHeader"> Target Return</Typography>,
        content: <>
            <Typography>
              Use a mileage recommendation if you want to make an "oil change sticker" recommendation for this product.
            </Typography>
            <br />
            <Typography sx={{
            fontStyle: 'italic'
          }}> e.g. 90 days or 30,000 miles </Typography>
          </>
      });
    } else if (index == 3) {
      setHelpData({
        title: <Typography variant="tableHeader"> Min & Max Days</Typography>,
        content: <>
            <Typography>What is the soonest you would recommend a customer make this purchase again? </Typography>
            <br />
            <Typography sx={{
            fontStyle: 'italic'
          }}>
              What is the longest you recommend a customer go before making this purchase again?
            </Typography>
          </>
      });
    } else if (index == 4) {
      setHelpData({
        title: <Typography variant="tableHeader"> Accelerator</Typography>,
        content: <>
            <Typography>
              Adding an accelerator will decrease the recommended return date for customers in the segment by whatever you set. This will
              then trigger sending communcation to that customer sooner in an attempt to get them to purchase sooner.
            </Typography>
            <br />
            <Typography sx={{
            fontStyle: 'italic'
          }}>
              e.g. Sally has an average time between purchases of 100 days. By adding a 2% accelerator, her new recommended time between
              purchases is 98 days.
            </Typography>
          </>
      });
    }
    setHelpAnchorEl(e.currentTarget);
  };
  const handleHelpClose = () => {
    setHelpAnchorEl(null);
    setHelpData(null);
  };
  const handleCustomerVehicleChange = e => {
    if (e.target.value == 'true') {
      updateRTC({
        ...RTC,
        includes_possession: true,
        type: 'miles'
      });
    } else {
      if (RTC.type == 'miles') {
        updateRTC({
          ...RTC,
          includes_possession: false,
          type: 'days'
        });
      } else {
        updateRTC({
          ...RTC,
          includes_possession: false
        });
      }
    }
  };
  const handleTargetReturnChange = e => {
    updateRTC({
      ...RTC,
      type: e.target.value
    });
  };
  const updateOptimalValue = e => {
    if (e.target.value >= 0) {
      updateRTC({
        ...RTC,
        optimal_value: e.target.value
      });
    }
  };
  const handleMinChange = e => {
    if (Number(e.target.value) >= 0) {
      updateRTC({
        ...RTC,
        min: e.target.value
      });
    }
  };
  const handleMaxChange = e => {
    if (e.target.value >= 0) {
      updateRTC({
        ...RTC,
        max: e.target.value
      });
    }
  };
  const handleAcceleratorSwitchChange = e => {
    updateRTC({
      ...RTC,
      accelerator: 0,
      acceleratorActive: e.target.checked
    });
    setAcceleratorUnitType('units');
  };
  const handleAcceleratorValueChange = e => {
    if (acceleratorUnitType == 'percent') {
      updateRTC({
        ...RTC,
        accelerator: e.target.value == '' ? '' : e.target.value / 100,
        acceleratorUnitType: 'percent'
      });
    } else {
      updateRTC({
        ...RTC,
        accelerator: e.target.value,
        acceleratorUnitType: 'units'
      });
    }
  };
  const handleAcceleratorUnitTypeChange = e => {
    updateRTC({
      ...RTC,
      accelerator: 0
    });
    setAcceleratorUnitType(e.target.value);
  };
  return <>
      <Box spacing={'20px'} sx={{
      height: '66vh',
      padding: '20px',
      background: '#E4E9F1'
    }}>
        {isLoading ? <Stack spacing={'100px'}>
            <Stack direction="row" spacing="20px" sx={{
          overflowY: 'auto'
        }}>
              {Array(4).fill(null).map((row, index) => <Paper key={index} sx={{
            borderRadius: '14px',
            height: 'fit-content'
          }}>
                    <Stack sx={{
              padding: '20px'
            }} spacing={2}>
                      <Skeleton variant="rounded" width={210} height={30} />
                      <Box sx={{
                py: '8px'
              }}>
                        <Divider />
                      </Box>
                      <Skeleton variant="rectangular" width={210} height={30} />
                      <Skeleton variant="rectangular" width={210} height={30} />
                    </Stack>
                  </Paper>)}
            </Stack>
            <Box sx={{
          display: 'flex',
          justifyContent: 'center'
        }}>
              <CircularProgress />
            </Box>
          </Stack> : <Stack direction="row" spacing="20px" sx={{
        overflowY: 'auto'
      }}>
            <Paper sx={{
          borderRadius: '14px',
          height: 'fit-content',
          width: '25%'
        }}>
              <Stack sx={{
            padding: '20px'
          }}>
                <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
                  <Stack spacing="8px" direction={'row'} sx={{
                display: 'flex',
                alignItems: 'center',
                pr: '16px'
              }}>
                    <DirectionsCarCustomerIcon />
                    <Typography fontSize={20}> {automotiveOptions ? 'Customer/Vehicle' : 'Customer'}</Typography>
                  </Stack>
                  <IconButton onClick={e => handleHelpPopupClick(e, 1)}>
                    <HelpIcon fill="#3898D9" size="sm" />
                  </IconButton>
                </Box>

                <Box sx={{
              py: '8px'
            }}>
                  <Divider />
                </Box>

                <RadioGroup defaultValue={automotiveOptions ? 'true' : 'false'} value={RTC?.includes_possession ? 'true' : 'false'} onChange={e => {
              handleCustomerVehicleChange(e);
            }}>
                  <FormControlLabel value="false" control={<Radio />} label="Customer" />
                  {automotiveOptions && <FormControlLabel value="true" control={<Radio />} label="Vehicle" />}
                </RadioGroup>
              </Stack>
            </Paper>

            <Paper sx={{
          borderRadius: '14px',
          height: 'fit-content',
          width: '25%'
        }}>
              <Stack sx={{
            padding: '20px'
          }}>
                <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
                  <Stack spacing="8px" direction={'row'} sx={{
                display: 'flex',
                alignItems: 'center',
                pr: '16px'
              }}>
                    <TargetIcon />
                    <Typography fontSize={20}> Target Return</Typography>
                  </Stack>
                  <IconButton onClick={e => handleHelpPopupClick(e, 2)}>
                    <HelpIcon fill="#3898D9" size="sm" />
                  </IconButton>
                </Box>

                <Box sx={{
              py: '8px'
            }}>
                  <Divider />
                </Box>

                <RadioGroup defaultValue="days" value={RTC.type} onChange={handleTargetReturnChange}>
                  <FormControlLabel value="days" control={<Radio />} label="Days" />
                  {automotiveOptions && <FormControlLabel disabled={!RTC.includes_possession} value="miles" control={<Radio />} label="Milage" />}
                </RadioGroup>
                <Box sx={{
              py: '20px'
            }}>
                  <TextField variant="outlined" label={RTC.type == 'miles' ? 'Target return miles *' : 'Target return days *'} inputProps={{
                type: 'number'
              }} fullWidth value={RTC?.optimal_value || ''} onChange={updateOptimalValue} error={RTC.type == 'days' && (Number(RTC.min) >= Number(RTC.optimal_value) || Number(RTC.max) <= Number(RTC.optimal_value))} helperText={RTC.type == 'days' && (Number(RTC.min) >= Number(RTC.optimal_value) || Number(RTC.max) <= Number(RTC.optimal_value)) ? 'Target return must be between min and max days' : ''} />
                </Box>
              </Stack>
            </Paper>

            <Paper sx={{
          borderRadius: '14px',
          height: 'fit-content',
          width: '25%'
        }}>
              <Stack sx={{
            padding: '20px'
          }}>
                <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
                  <Stack spacing="8px" direction={'row'} sx={{
                display: 'flex',
                alignItems: 'center',
                pr: '16px'
              }}>
                    <Calendar365DaysIcon />
                    <Typography fontSize={20}> Min & Max Days</Typography>
                  </Stack>
                  <IconButton onClick={e => handleHelpPopupClick(e, 3)}>
                    <HelpIcon fill="#3898D9" size="sm" />
                  </IconButton>
                </Box>
                <Box sx={{
              py: '8px'
            }}>
                  <Divider />
                </Box>
                <Stack sx={{
              pt: '12px'
            }} direction={'row'} spacing={2}>
                  <TextField sx={{
                width: '121px'
              }} error={!RTC.min || RTC.min < 1 || Number(RTC.min) >= Number(RTC.max)} label="Min Days *" helperText={!RTC.min ? 'Min days cannot be empty' : RTC.min < 1 ? 'Min days must be greater than 0' : Number(RTC.min) >= Number(RTC.max) ? 'Min Days must be less than Max Days' : ''} inputProps={{
                type: 'number'
              }} value={RTC.min} onChange={e => {
                handleMinChange(e);
              }} />

                  <TextField sx={{
                width: '121px'
              }} error={Number(RTC.max) <= Number(RTC.min)} helperText={!RTC.max ? 'Max Days cannot be empty' : Number(RTC.max) <= Number(RTC.min) ? 'Max Days must be greater than Min Days' : ''} inputProps={{
                type: 'number'
              }} label="Max Days *" value={RTC.max} onChange={e => {
                handleMaxChange(e);
              }} />
                </Stack>
              </Stack>
            </Paper>

            <Paper sx={{
          borderRadius: '14px',
          height: 'fit-content',
          width: '25%'
        }}>
              <Stack sx={{
            padding: '20px'
          }}>
                <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
                  <Stack spacing="8px" direction={'row'} sx={{
                display: 'flex',
                alignItems: 'center',
                pr: '16px'
              }}>
                    <RocketLaunchIcon />
                    <Typography fontSize={20}> Accelerator</Typography>
                  </Stack>
                  <IconButton onClick={e => handleHelpPopupClick(e, 4)}>
                    <HelpIcon fill="#3898D9" size="sm" />
                  </IconButton>
                </Box>
                <Box sx={{
              py: '8px'
            }}>
                  <Divider />
                </Box>
                <Stack sx={{
              pt: '12px'
            }} spacing={2}>
                  <Stack spacing={2} sx={{
                display: 'flex',
                alignItems: 'center'
              }} direction={'row'}>
                    <AntSwitch checked={RTC.acceleratorActive} onChange={e => {
                  handleAcceleratorSwitchChange(e);
                }} />
                    <Typography>Add Accelerator</Typography>
                  </Stack>
                  <RadioGroup defaultValue="true" value={acceleratorUnitType} onChange={e => {
                handleAcceleratorUnitTypeChange(e);
              }}>
                    <FormControlLabel disabled={!RTC.acceleratorActive} value="units" control={<Radio />} label="#" />
                    <FormControlLabel disabled={!RTC.acceleratorActive} value="percent" control={<Radio />} label="%" />
                  </RadioGroup>
                  <TextField disabled={!RTC.acceleratorActive} error={RTC.accelerator < 0 || RTC.acceleratorActive && RTC.accelerator == 0 || acceleratorUnitType == 'percent' && RTC.accelerator > 0.999 || acceleratorUnitType == 'units' && RTC.type == 'days' && RTC.accelerator > Number(RTC.max)} helperText={RTC.accelerator < 0 ? 'Accelerator cannot be less than 0' : RTC.acceleratorActive && RTC.accelerator == 0 ? 'If active, accelerator cannot be 0 or blank' : acceleratorUnitType == 'percent' && RTC.accelerator > 0.999 ? 'Accelerator cannot be greater than 99%' : acceleratorUnitType == 'units' && RTC.type == 'days' && RTC.accelerator > Number(RTC.max) ? 'Accelerator cannot be greater than max days' : ''} InputProps={{
                type: 'number',
                endAdornment: <InputAdornment position="start">
                          {acceleratorUnitType == 'percent' ? '%' : RTC.type == 'miles' ? 'miles' : 'days'}
                        </InputAdornment>
              }} label={RTC.type == 'miles' ? 'Accelerator - miles' : 'Accelerator - days'} value={acceleratorUnitType == 'percent' ? Number(RTC.accelerator) == '' ? '' : Number(RTC.accelerator) == 0 ? 0 : Math.round(Number(RTC.accelerator) * 100) : RTC.accelerator} onChange={e => {
                handleAcceleratorValueChange(e);
              }} />
                </Stack>
              </Stack>
            </Paper>
          </Stack>}
      </Box>

      <Popper anchorEl={helpAnchorEl} open={!!helpData} onClose={handleHelpClose} placement="bottom-start" sx={{
      zIndex: 100
    }}>
        <ClickAwayListener onClickAway={handleHelpClose}>
          <Paper elevation={0} sx={{
          borderRadius: '16px',
          overflow: 'visible',
          filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))',
          width: '312px'
        }}>
            <Stack>
              <Box sx={{
              padding: '16px'
            }}>{helpData?.title} </Box>
              <Box sx={{
              px: '16px'
            }}> {helpData?.content}</Box>
              <Box sx={{
              py: '16px'
            }}>
                <Button sx={{
                display: 'flex',
                justifyItems: 'left',
                textTransform: 'none'
              }} onClick={handleHelpClose}>
                  {helpData?.buttonText || ' Got it'}
                </Button>
              </Box>
            </Stack>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>;
};