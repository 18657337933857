import React from 'react';
function Icon(props) {
  return <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="none" viewBox="0 0 25 25">
      <mask id="mask0_1689_1487" style={{
      maskType: 'alpha'
    }} width="25" height="25" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill={props.fill || '#1D252D'} d="M0.815 0.225H24.815V24.225H0.815z"></path>
      </mask>
      <g fill={props.fill || '#1D252D'} mask="url(#mask0_1689_1487)">
        <path fillRule="evenodd" d="M8.197 11.56a.852.852 0 01-.626.258.852.852 0 01-.626-.258.853.853 0 01-.258-.626c0-.245.086-.454.258-.626a.853.853 0 01.626-.259c.245 0 .454.086.626.259a.853.853 0 01.259.626.853.853 0 01-.259.626zm4 0a.852.852 0 01-.626.258.852.852 0 01-.626-.258.853.853 0 01-.258-.626c0-.245.086-.454.258-.626a.853.853 0 01.626-.259c.245 0 .454.086.626.259a.853.853 0 01.259.626.853.853 0 01-.259.626zm4 0a.852.852 0 01-.626.258.852.852 0 01-.626-.258.853.853 0 01-.258-.626c0-.245.086-.454.258-.626a.853.853 0 01.626-.259c.245 0 .454.086.626.259a.853.853 0 01.259.626.853.853 0 01-.259.626zm-12.59 8.875l2.003-2.002h7.649a5.4 5.4 0 017.812-5.978V5.241c0-.505-.175-.932-.525-1.282a1.745 1.745 0 00-1.282-.525H3.879c-.505 0-.933.175-1.283.525-.35.35-.525.777-.525 1.282v14.546c0 .405.185.686.555.842.37.155.697.091.982-.194z" clipRule="evenodd"></path>
        <path d="M22.81 16.928h-5.37l1.298-1.332a.702.702 0 00.207-.518.702.702 0 00-.207-.517.713.713 0 00-.53-.22.709.709 0 00-.514.21l-2.495 2.495a.867.867 0 00-.27.632c0 .241.09.452.27.633l2.485 2.485a.71.71 0 00.527.22.759.759 0 00.527-.21.717.717 0 00.235-.528.71.71 0 00-.22-.527l-1.323-1.323h5.38a.726.726 0 00.534-.215.726.726 0 00.215-.535.726.726 0 00-.215-.534.726.726 0 00-.535-.216z"></path>
      </g>
    </svg>;
}
export default Icon;