import React, { Component } from 'react';
import { func, object } from 'prop-types';
import { RRule } from 'rrule';
import { toInteger } from 'lodash';
import { monthlyOptions, dayNumToStr } from './trigger-recurring.utils';
import { RecurringOption } from './recurring-option.component';
import { FancyInput } from './fancy-input.component';
export class Monthly extends Component {
  static propTypes = {
    update: func,
    rrule: object,
    data: object
  };
  setMonthly = patternOption => {
    this.props.update({
      patternOption,
      rrule: {
        ...this.props.rrule.options,
        ...monthlyOptions[patternOption]
      }
    });
  };
  update = options => {
    this.props.update({
      rrule: {
        ...this.props.rrule.options,
        ...options
      }
    });
  };
  onMonthDayChange = e => {
    const num = toInteger(e.target.value) || 1;
    this.update({
      bymonthday: num > 31 ? 31 : num
    });
  };
  onDayChange = e => {
    const nth = this.props.rrule.options.bynweekday[0][1];
    this.update({
      byweekday: RRule[e.target.value].nth(nth)
    });
  };
  onFirstLastChange = e => {
    const day = this.props.rrule.options.bynweekday[0][0];
    this.update({
      byweekday: RRule[dayNumToStr(day)].nth(parseInt(e.target.value))
    });
  };
  sanitizeIntervalValue = interval => {
    const int = toInteger(interval);
    return (int > 12 ? 12 : int) || 1;
  };
  activeByWeekday = () => {
    const {
      bynweekday
    } = this.props.rrule.options;
    if (bynweekday) {
      const [day, nth] = bynweekday[0];
      return {
        day: dayNumToStr(day),
        nth
      };
    }
    return {
      day: null,
      nth: null
    };
  };
  render() {
    const {
      rrule,
      data
    } = this.props;
    return <div>
        <RecurringOption active={data.patternOption === 'everyMonth'} onChange={() => this.setMonthly('everyMonth')} inactiveMessage="1st Day Of Every 1 Month">
          <FancyInput onChange={this.onMonthDayChange} value={Array.isArray(rrule.options.bymonthday) ? rrule.options.bymonthday[0] : rrule.options.bymonthday} ordinal />
          Day Of Every
          <FancyInput onChange={e => this.update({
          interval: this.sanitizeIntervalValue(e.target.value)
        })} value={rrule.options.interval} leftMargin />
          month{rrule.options.interval > 1 ? 's' : ''}
        </RecurringOption>
        <RecurringOption active={data.patternOption === 'onEveryMonth'} onChange={() => this.setMonthly('onEveryMonth')} inactiveMessage="First Monday of every 1 month">
          <select className="form-control recurring-inline-with-selection" onChange={this.onFirstLastChange} value={this.activeByWeekday().nth}>
            <option value="1">First</option>
            <option value="2">Second</option>
            <option value="3">Third</option>
            <option value="4">Fourth</option>
            <option value="5">Fifth</option>
            <option value="-1">Last</option>
          </select>
          <select className="form-control recurring-inline-with-selection" onChange={this.onDayChange} value={this.activeByWeekday().day}>
            <option value="MO">Monday</option>
            <option value="TU">Tuesday</option>
            <option value="WE">Wednesday</option>
            <option value="TH">Thursday</option>
            <option value="FR">Friday</option>
            <option value="SA">Saturday</option>
            <option value="SU">Sunday</option>
          </select>
          <br />
          <span style={{
          marginLeft: '2.2rem'
        }}>of every</span>
          <FancyInput onChange={e => this.update({
          interval: this.sanitizeIntervalValue(e.target.value)
        })} value={rrule.options.interval} leftMargin />{' '}
          Month
          {rrule.options.interval > 1 ? 's' : ''}
        </RecurringOption>
      </div>;
  }
}