import React, { useState, useEffect } from 'react';
import { ModalDialog, Button } from 'components';
import { getCouponBatches, downloadCouponBatch } from 'shared/common.api';
import moment from 'moment-timezone';
export const CouponDownloadBatchModal = props => {
  const [batches, setBatches] = useState();
  useEffect(() => {
    if (props.coupon) {
      getCouponBatches(props.coupon.id).then(({
        data
      }) => {
        setBatches(data);
      });
    }
  }, [props.coupon]);
  const download = batchNumber => {
    downloadCouponBatch(props.coupon.id, batchNumber).then(({
      data
    }) => {
      const csvContent = 'data:text/csv;charset=utf-8,' + data.join('\n');
      const encodedUri = encodeURI(csvContent);
      var link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', 'batch.csv');
      document.body.appendChild(link); // Required for FF
      link.click();
    });
  };
  return <ModalDialog title="Download Batch" onClose={props.onClose} open={props.open} cancelText="Done" allowBackdropClick>
      <table className="table-list no-hover">
        <thead>
          <tr>
            <th>Batch</th>
            <th>Date</th>
            <th>Count</th>
            <td>&nbsp;</td>
          </tr>
        </thead>
        <tbody>
          {batches !== undefined && batches.length === 0 && <tr>
              <td colSpan={4}>
                <i>No batches created for this coupon</i>
              </td>
            </tr>}
          {batches !== undefined && batches.length > 0 && batches.map(batch => <tr key={batch.batch_number}>
                <td>{batch.batch_number}</td>
                <td>
                  {moment(batch.created_when).local().format('MMM D, YYYY h:mm A')}
                </td>
                <td>{batch.count}</td>
                <td>
                  <Button icon="fa-regular-download" actionType="flat" onClick={() => download(batch.batch_number)} />
                </td>
              </tr>)}
        </tbody>
      </table>
    </ModalDialog>;
};