import React from 'react';
function Icon() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <mask id="mask0_40_525" style={{
      maskType: 'alpha'
    }} width="24" height="24" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0 0H24V24H0z"></path>
      </mask>
      <g mask="url(#mask0_40_525)">
        <path fill="#1D252D" d="M12.452 9.498v5.004c0 .208.092.35.276.427.184.077.35.041.497-.106l2.19-2.19a.867.867 0 00.272-.633.867.867 0 00-.272-.633l-2.19-2.19c-.147-.147-.313-.183-.497-.106s-.276.22-.276.427zM5.308 20.5c-.499 0-.925-.177-1.278-.53a1.742 1.742 0 01-.53-1.278V5.308c0-.499.177-.925.53-1.278.353-.353.779-.53 1.278-.53h13.384c.499 0 .925.177 1.278.53.353.353.53.779.53 1.278v13.384c0 .499-.177.925-.53 1.278-.353.353-.779.53-1.278.53H5.308zM8 19V5H5.308a.294.294 0 00-.212.096.294.294 0 00-.096.212v13.384c0 .077.032.148.096.212a.294.294 0 00.212.096H8zm1.5 0h9.192a.294.294 0 00.212-.096.294.294 0 00.096-.212V5.308a.294.294 0 00-.096-.212.294.294 0 00-.212-.096H9.5v14z"></path>
      </g>
    </svg>;
}
export default Icon;