import React from 'react';
import { fileSize } from 'components/file-viewer/utils';
import classnames from 'classnames';
export const Media = props => {
  const {
    data
  } = props;
  const {
    media
  } = data;
  return <>
    <div className="form-group">
      {media.map(file => <div className="d-flex" key={file.Key}>
          <div className="flex-grow truncate">{file.name}</div>
          <div className="ml-2">{fileSize(file.Size)}</div>
        </div>)}
      <div className="d-flex justify-content-between mt-2">
        <p className={classnames('text-sm', {
          'text-danger': media.length > 10
        })}>
          {media.length} of 10 files
        </p>
        <p className={classnames('text-sm', {
          'text-danger': media.reduce((x, y) => x + y.Size, 0) > 5200880
        })}>
          {fileSize(media.reduce((x, y) => x + y.Size, 0))} of 5 MB
        </p>
      </div>
    </div>
  </>;
};