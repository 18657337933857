import React, { useState, useEffect, useContext } from 'react';
import { PageHeader, Loader, Pagination, Status, SortHeader, Dropdown, Modal, PageHeaderMui, Icon, StatusMui, TagModal } from 'components';
import { getSurveys, duplicateSurvey, deleteSurvey, updateFolder, updateSurvey, createNewFolder, getCompanyFolders, getSurveysTags, getJourneys, deleteFolder } from 'shared/common.api';
import { UserStateContext } from 'context/user-state-context';
import { snackbarService } from 'components/mui/snackbar/snackbar-service';
import { history } from '../../root.component';
import CssBaseline from '@mui/material/CssBaseline';
import moment from 'moment-timezone';
import { IconButton, Tooltip, Box, Stack, Popper, ClickAwayListener, Grid, Button, Paper, Typography, Dialog, DialogContent, TextField, DialogActions, DialogTitle } from '@mui/material';
import EnhancedTable from 'components/mui/enhanced-table/enhanced-table.component';
import { ModalContext } from 'context/modal-context';
import { AddFolderIcon, AddTagsIcon, ArchiveIcon, ArchivedIcon, CloseIcon, DeleteIcon, DraftIcon, DuplicateIcon, HandymanIcon, ListAltIcon, MoveToFolderIcon, MultiCompanyPrompt, PlaylistAddCheckIcon, PublishedIcon, RestoreIcon } from 'components/mui';
import { NewButton } from 'components/mui/button/new.component';
import { MultiSelectFilter } from 'components/mui/filter/multi-select.component';
import { getFiltersFromURL } from 'components/mui/enhanced-table/utils';
import { DateRangeFilter } from 'components/mui/filter/date-range.component';
import { useLocation } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import FolderSelectDialog from 'components/mui/folder/folder-select-dialog';
import TagsMui from 'components/tags/tagsMui.component';
import { TagFilter } from 'components/mui/filter/tags.component';
import { Modal as NewModal } from '../../components/modal/modal.component';
import { DateTime } from 'luxon';
const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius: `14px`,
      '& fieldset': {}
    }
  }
})(TextField);
export const SurveysList = () => {
  const {
    hasPermission,
    hasMultiPermission,
    asCompany,
    user,
    flags
  } = useContext(UserStateContext);
  const {
    show
  } = useContext(ModalContext);
  const [renderIteration, setRenderIteration] = useState(0);
  const loc = useLocation();
  const searchParams = new URLSearchParams(loc.search);
  const folderParam = searchParams.get('folder');
  const [folderSelectDialog, setFolderSelectDialog] = React.useState([]);
  const [createNewFolderDialog, setCreateNewFolderDialog] = React.useState(false);
  const [newFolderName, setNewFolderName] = React.useState('');
  const [surveyCreateAnchorEl, setSurveyCreateAnchorEl] = React.useState(null);
  const surveyCreateOpen = Boolean(surveyCreateAnchorEl);
  const [isLoading, setIsLoading] = React.useState(false);
  const [tagModal, setTagModal] = React.useState(null);
  const [archiveModal, setArchiveModal] = React.useState([]);
  const [tabButtonHover, setTabButtonHover] = React.useState(null);
  const [deleteModal, setDeleteModal] = React.useState([]);
  const [surveyCreateMenu, setSurveyCreateMenu] = React.useState([]);
  const filterOptions = [{
    component: TagFilter,
    api: getSurveysTags,
    refreshApi: renderIteration,
    key: 'tags'
  }, {
    key: 'archived',
    hidden: {
      hideInFilterList: true,
      hideChip: true
    }
  }, {
    component: MultiSelectFilter,
    label: 'Status',
    pluralLabel: 'Statuses',
    key: 'status_in',
    options: [{
      label: 'Draft',
      value: 'draft',
      icon: <DraftIcon />,
      color: '#107EBE'
    }, {
      label: 'Published',
      value: 'published',
      icon: <PublishedIcon />,
      color: '#3EB87B'
    }]
  }, {
    component: DateRangeFilter,
    label: 'Created Date',
    key: 'created'
  }, {
    component: DateRangeFilter,
    label: 'Updated Date',
    key: 'updated'
  }];
  const [filters, setFilters] = React.useState(() => {
    const fils = getFiltersFromURL(filterOptions);
    return fils;
  });
  const [currentTab, setCurrentTab] = React.useState(() => {
    if (filters['archived']) {
      return 1;
    } else {
      return 0;
    }
  });
  React.useEffect(() => {
    const menu = [];
    if (user.is_superuser || flags.cinch_testers) {
      menu.push({
        key: 'prebuilt',
        name: ' Prebuilt Surveys',
        icon: <HandymanIcon />
      });
    }
    menu.push({
      key: 'survey',
      name: user.is_superuser || flags.cinch_testers ? 'New Custom Survey' : 'New Survey',
      icon: <ListAltIcon />
    });
    menu.push({
      key: 'folder',
      name: 'New folder',
      icon: <AddFolderIcon />
    });
    setSurveyCreateMenu(menu);
  }, []);
  const handleSurveyCreateMenuClick = item => {
    handleSurveyCreateClose();
    if (item.key == 'survey') {
      history.push('/surveys/edit/create');
    }
    if (item.key == 'folder') {
      setCreateNewFolderDialog(true);
    }
    if (item.key == 'prebuilt') {
      history.push('/prebuilt/install/survey');
    }
  };
  const handleSurveyCreateClose = () => {
    setSurveyCreateAnchorEl(null);
  };
  const handleSurveyCreateClick = event => {
    setSurveyCreateAnchorEl(surveyCreateOpen ? null : event.currentTarget);
  };
  function onClose() {
    setFolderSelectDialog([]);
  }
  function onSubmitData(data) {
    const promises = [];
    folderSelectDialog.filter(f => f._type === 'folder').map(s => {
      promises.push(updateFolder(s.id, {
        parent: data === 'home' ? null : data
      }));
    });
    folderSelectDialog.filter(f => f._type === 'rowItem').map(s => {
      promises.push(updateSurvey(s.id, {
        folder: data === 'home' ? null : data
      }));
    });
    Promise.all(promises).then(() => {
      setRenderIteration(i => i + 1);
      setFolderSelectDialog([]);
    });
  }
  const duplicateSurveys = surveys => {
    let promise;
    if (hasMultiPermission('surveys.change_survey')) {
      promise = show(MultiCompanyPrompt, {
        title: 'Duplicate Survey',
        permission: 'surveys.change_survey'
      });
    } else {
      promise = Promise.resolve();
    }
    promise.then(companyIds => {
      const promises = [];
      surveys.map(survey => {
        promises.push(duplicateSurvey(survey.id, companyIds));
      });
      Promise.all(promises).then(data => {
        if (surveys.length > 1 || !!companyIds) {
          setRenderIteration(i => i + 1);
        } else {
          history.push(`/surveys/edit/${data[0]?.data?.id}`);
        }
        snackbarService.popup({
          type: 'success',
          message: 'Survey successfully duplicated.'
        });
      }).catch(() => {
        snackbarService.popup({
          type: 'error',
          message: 'There was an error trying to duplicate the survey. Please try again.'
        });
      });
    }).catch(e => {
      console.error(e);
    });
  };
  const deleteSurveys = () => {
    const promises = [];
    deleteModal.filter(f => f._type === 'folder').map(s => {
      promises.push(deleteFolder(s.id));
    });
    deleteModal.filter(f => f._type === 'rowItem').map(s => {
      promises.push(deleteSurvey(s.id));
    });
    Promise.all(promises).then(() => {
      setRenderIteration(i => i + 1);
      closeDeleteModal();
    });
  };
  const updateFolderName = name => {
    updateFolder(name.id, {
      name: name.name
    }).then(() => {
      setRenderIteration(i => i + 1);
    });
  };
  const addTagFromSurveyRow = (e, tag) => {
    e.stopPropagation();
    //check if already selected
    if (!filters['tags']?.includes(tag)) {
      setFilters({
        ...(filters || {}),
        ['tags']: [...(filters['tags'] || []), tag]
      });
    }
  };
  const headCells = [{
    key: 'title',
    label: 'Name',
    width: '25%',
    displayComponent: ({
      data,
      isNested
    }) => <Stack direction="row" spacing={1}>
          {isNested ? <>
              <Box sx={{
          display: 'flex',
          alignItems: 'center'
        }}>
                <Icon name="custom-folder_open" />
              </Box>
              <Typography sx={{
          display: 'flex',
          alignItems: 'center'
        }}> {data.path + (data.title || '')}</Typography>
            </> : <Typography sx={{
        display: 'flex',
        alignItems: 'center'
      }}> {data.title || ''}</Typography>}
          <TagsMui tags={data.tags} onClick={(e, tag) => {
        addTagFromSurveyRow(e, tag);
      }} />
        </Stack>
  }, {
    key: 'status',
    label: 'Status',
    noSort: true,
    width: '20%',
    displayComponent: ({
      data: survey
    }) => <StatusMui hideBackground disablePadding justifyContent="left" status={survey.status} />
  }, {
    key: 'updated_when',
    label: 'Updated',
    width: '20%',
    displayComponent: ({
      data: survey
    }) => <Tooltip title={survey.most_recent_revision_created_by ? 'Created by: ' + survey.most_recent_revision_created_by : ''}>
          {moment(survey.created_when).local().format('MMM D, YYYY h:mm A')}
        </Tooltip>
  }, {
    key: 'created_when',
    label: 'Created',
    width: '20%',
    displayComponent: ({
      data: survey
    }) => moment(survey.updated_when).local().format('MMM D, YYYY h:mm A')
  }];
  useEffect(() => {
    if (history?.location?.state?.published) {
      snackbarService.popup({
        type: 'success',
        message: `Survey published successfully`,
        delay: 5000,
        action: <React.Fragment>
            <IconButton size="small" color="white" onClick={() => snackbarService.close()}>
              <CloseIcon size="sm" fontSize="small" />
            </IconButton>
          </React.Fragment>
      });
    }
  }, []);
  const handleRowClick = survey => {
    if (survey?.revisions?.some(r => !!r.published_when)) {
      history.push(`/surveys/summary/${survey.id}`);
    } else {
      history.push(`/surveys/edit/${survey.id}`);
    }
  };
  const makeNewFolder = newFolderName => {
    setIsLoading(true);
    createNewFolder({
      name: newFolderName,
      model: 'survey',
      parent: folderParam,
      company: asCompany.id
    }).then(() => {
      getCompanyFolders({
        model: 'survey',
        company: asCompany.id,
        parent: folderParam ? folderParam : 'home'
      }).then(results => {
        setRenderIteration(i => i + 1);
        setIsLoading(false);
        setCreateNewFolderDialog(false);
        setNewFolderName('');
      });
    });
  };
  const hasMixedItemTypes = selected => {
    const uniqueValues = new Set(selected.map(v => v?._type));
    if (uniqueValues.size > 1) return true;
    return false;
  };
  const closeArchiveModal = () => {
    setArchiveModal([]);
  };
  const openArchiveModal = surveys => {
    const promises = [];
    surveys.map(survey => {
      promises.push(getJourneys({
        company: asCompany.id,
        node_survey: survey.id,
        archived: false
      }));
    });
    Promise.all(promises).then(data => {
      let index = 0;
      const newSurveys = surveys.map(survey => {
        const seg = {
          ...survey,
          journeys: data[index].data.results
        };
        index = index + 1;
        return seg;
      });
      setArchiveModal(newSurveys);
    });
  };
  const archiveSurvey = () => {
    const promises = [];
    archiveModal.map(survey => {
      promises.push(updateSurvey(survey.id, {
        archived_when: DateTime.local()
      }));
    });
    Promise.all(promises).then(data => {
      setRenderIteration(i => i + 1);
      closeArchiveModal();
    });
  };
  const restoreSurvey = surveys => {
    const promises = [];
    surveys.map(survey => {
      promises.push(updateSurvey(survey.id, {
        archived_when: null
      }));
    });
    Promise.all(promises).then(() => {
      setRenderIteration(i => i + 1);
    });
  };
  const canArchive = () => {
    return !archiveModal.some(survey => survey.journeys.length > 0);
  };
  const handleTabsChange = (event, newValue) => {
    setCurrentTab(newValue);
    if (newValue == 1) {
      setFilters({
        ...(filters || {}),
        ['archived']: true
      });
    } else {
      const {
        archived: _,
        ...newFilters
      } = filters;
      setFilters(newFilters);
    }
  };
  const closeDeleteModal = () => {
    setDeleteModal([]);
  };
  const openDeleteModal = surveys => {
    const promises = [];
    surveys.filter(f => f._type === 'rowItem').map(survey => {
      promises.push(getJourneys({
        company: asCompany.id,
        node_survey: survey.id,
        archived: false
      }));
    });
    Promise.all(promises).then(data => {
      let index = 0;
      const newSurveys = surveys.filter(f => f._type === 'rowItem').map(survey => {
        const seg = {
          ...survey,
          journeys: data[index].data.results
        };
        index = index + 1;
        return seg;
      });
      const surveyFolders = surveys.filter(f => f._type === 'folder').map(s => {
        return s;
      });
      setDeleteModal([...newSurveys, ...surveyFolders]);
    });
  };
  const canDelete = () => {
    return !deleteModal.filter(f => f._type === 'rowItem').some(survey => survey.journeys.length > 0);
  };
  const actionButtons = {
    displayComponent: ({
      data
    }) => <Stack direction="row" spacing={2}>
        {hasPermission('surveys.change_survey') && data.selected?.length > 0 && data.selected.filter(s => !s.archived_when).length > 0 && <Tooltip title="Move to folder">
              <IconButton sx={{
          ':hover': {
            backgroundColor: '#DAEFFF'
          }
        }} onClick={e => {
          setFolderSelectDialog(data.selected);
        }}>
                <MoveToFolderIcon />
              </IconButton>
            </Tooltip>}
        {hasPermission('surveys.add_survey') && data.selected?.length > 0 && data.selected.filter(s => s._type === 'folder').length === 0 && <Tooltip title="Duplicate">
              <IconButton sx={{
          ':hover': {
            backgroundColor: '#DAEFFF'
          }
        }} onClick={e => {
          e.stopPropagation();
          duplicateSurveys(data.selected);
        }}>
                <DuplicateIcon />
              </IconButton>
            </Tooltip>}
        {hasPermission('surveys.change_survey') && data.selected?.length > 0 && !hasMixedItemTypes(data.selected) && data.selected[0]._type != 'folder' && data.selected.filter(s => !s.archived_when).length > 0 && <Tooltip title="Archive">
              <IconButton sx={{
          ':hover': {
            backgroundColor: '#DAEFFF'
          }
        }} onClick={e => {
          openArchiveModal(data.selected);
        }}>
                <ArchiveIcon />
              </IconButton>
            </Tooltip>}

        {hasPermission('surveys.change_survey') && data.selected?.length > 0 && !hasMixedItemTypes(data.selected) && data.selected[0]._type != 'folder' && data.selected.filter(s => !!s.archived_when).length > 0 && <Tooltip title="Restore">
              <IconButton sx={{
          ':hover': {
            backgroundColor: '#DAEFFF'
          }
        }} onClick={e => {
          e.stopPropagation();
          restoreSegment(data.selected);
        }}>
                <RestoreIcon />
              </IconButton>
            </Tooltip>}

        {hasPermission('surveys.delete_survey') && data.selected?.length > 0 && <Tooltip title="Delete">
            <IconButton sx={{
          ':hover': {
            backgroundColor: '#DAEFFF'
          }
        }} onClick={e => {
          e.stopPropagation();
          openDeleteModal(data.selected);
        }}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>}
      </Stack>
  };
  return <>
      <CssBaseline />
      {isLoading && <Loader overlay />}
      <div className="wrapper">
        <PageHeaderMui type={currentTab == 1 ? 'Surveys - Archived' : 'Surveys'} icon={<ListAltIcon size="lg" />} folderBreadcrumbs updateName={updateFolderName} updateNamePermission={hasPermission('surveys.change_survey') && folderParam} />

        <Stack sx={{
        px: '20px',
        pt: '20px'
      }} spacing="15px" direction={'row'}>
          <Box sx={{
          backgroundColor: currentTab == 0 ? '#3898D9' : tabButtonHover == 0 ? '#C2E7FF' : '#F3F6FC',
          cursor: 'pointer',
          textTransform: 'none',
          height: '32px',
          borderRadius: '16px',
          paddingLeft: '20px',
          paddingRight: '20px',
          display: 'flex',
          alignItems: 'center',
          alignContent: 'center',
          justifyContent: 'center',
          justifyItems: 'center'
        }} onMouseEnter={() => setTabButtonHover(0)} onMouseLeave={() => setTabButtonHover(null)} onClick={e => {
          handleTabsChange(e, 0);
        }}>
            <PlaylistAddCheckIcon fill={currentTab == 0 ? '#ffffff' : '#1D252D'} />

            <Typography sx={{
            pl: '10px',
            color: currentTab == 0 ? '#ffffff' : '#1D252D'
          }}>
              Active
            </Typography>
          </Box>
          <Box sx={{
          backgroundColor: currentTab == 1 ? '#3898D9' : tabButtonHover == 1 ? '#C2E7FF' : '#F3F6FC',
          cursor: 'pointer',
          textTransform: 'none',
          height: '32px',
          borderRadius: '16px',
          paddingLeft: '20px',
          paddingRight: '20px',
          display: 'flex',
          alignItems: 'center',
          alignContent: 'center',
          justifyContent: 'center',
          justifyItems: 'center'
        }} onMouseEnter={() => setTabButtonHover(1)} onMouseLeave={() => setTabButtonHover(null)} onClick={e => {
          handleTabsChange(e, 1);
        }}>
            <ArchivedIcon fill={currentTab == 1 ? '#ffffff' : '#1D252D'} />

            <Typography sx={{
            pl: '10px',
            color: currentTab == 1 ? '#ffffff' : '#1D252D'
          }}>
              Archived
            </Typography>
          </Box>
        </Stack>

        <div className="mui-wrapper">
          <EnhancedTable type="Surveys" headCells={headCells} api={getSurveys} filterOptions={filterOptions} filtersState={[filters, setFilters]} enableCheckboxes folderModel="survey" folderApi={updateSurvey} defaultSort={'-updated_when'} handleRowClick={survey => {
          handleRowClick(survey);
        }} renderIteration={renderIteration} actions={hasPermission('surveys.add_survey') && <NewButton onClick={e => handleSurveyCreateClick(e)} />} actionButtons={actionButtons} hoverActions={({
          data
        }) => <Stack direction="row" gap="10px">
                {hasPermission('surveys.change_survey') && <Tooltip title="Move to folder">
                    <IconButton sx={{
              ':hover': {
                backgroundColor: '#DAEFFF'
              }
            }} onClick={e => {
              e.stopPropagation();
              setFolderSelectDialog([data]);
            }}>
                      <MoveToFolderIcon />
                    </IconButton>
                  </Tooltip>}
                {hasPermission('surveys.change_survey') && <Tooltip title="Add tags">
                    <IconButton sx={{
              ':hover': {
                backgroundColor: '#DAEFFF'
              }
            }} onClick={e => {
              e.stopPropagation();
              setTagModal(data);
            }}>
                      <AddTagsIcon />
                    </IconButton>
                  </Tooltip>}
                {!!data?.archived_when && hasPermission('surveys.change_survey') && <Tooltip title="Restore">
                    <IconButton sx={{
              ':hover': {
                backgroundColor: '#DAEFFF'
              }
            }} onClick={e => {
              e.stopPropagation();
              restoreSurvey([data]);
            }}>
                      <RestoreIcon />
                    </IconButton>
                  </Tooltip>}

                {!data?.archived_when && hasPermission('surveys.change_survey') && <Tooltip title="Archive">
                    <IconButton sx={{
              ':hover': {
                backgroundColor: '#DAEFFF'
              }
            }} onClick={e => {
              e.stopPropagation();
              openArchiveModal([data]);
            }}>
                      <ArchiveIcon />
                    </IconButton>
                  </Tooltip>}
                {hasPermission('surveys.add_survey') && <Tooltip title="Duplicate Survey">
                    <IconButton sx={{
              ':hover': {
                backgroundColor: '#DAEFFF'
              }
            }} onClick={e => {
              e.stopPropagation();
              duplicateSurveys([data]);
            }}>
                      <DuplicateIcon />
                    </IconButton>
                  </Tooltip>}

                {hasPermission('surveys.delete_survey') && <Tooltip title="Delete">
                    <IconButton sx={{
              ':hover': {
                backgroundColor: '#DAEFFF'
              }
            }} onClick={e => {
              e.stopPropagation();
              openDeleteModal([data]);
            }}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>}
              </Stack>} />
        </div>
      </div>

      <Popper anchorEl={surveyCreateAnchorEl} sx={{
      zIndex: 200
    }} open={surveyCreateOpen} onClose={handleSurveyCreateClose} placement="bottom-start">
        <ClickAwayListener onClickAway={handleSurveyCreateClose}>
          <Paper elevation={0} sx={{
          borderRadius: '16px',
          overflow: 'visible',
          filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))'
        }}>
            <Box sx={{
            py: 1
          }}>
              <Grid container justifyContent="center" sx={{
              px: 2
            }}></Grid>
              <Box display="flex" flexDirection="column">
                {surveyCreateMenu.map(opt => <Button variant="text" fullWidth key={opt.key} sx={{
                cursor: 'pointer',
                height: '48px',
                color: '#1D252D',
                textTransform: 'none',
                justifyContent: 'flex-start',
                px: 2
              }} onClick={e => {
                handleSurveyCreateMenuClick(opt);
              }}>
                    {opt.icon}
                    <Typography sx={{
                  pl: 1
                }}> {opt.name}</Typography>
                  </Button>)}
              </Box>
            </Box>
          </Paper>
        </ClickAwayListener>
      </Popper>

      <Dialog PaperProps={{
      style: {
        borderRadius: '28px',
        minWidth: '350px'
      }
    }} open={createNewFolderDialog} onClose={() => {
      setCreateNewFolderDialog(false);
    }}>
        <DialogTitle sx={{
        m: 0,
        p: 2,
        fontSize: 'medium'
      }}>
          <IconButton aria-label="close" onClick={e => {
          setCreateNewFolderDialog(false);
        }} sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500]
        }}>
            <CloseIcon />
          </IconButton>
          <Stack alignItems="center" component="span">
            <Typography variant="h4" component="span">
              Create New Folder
            </Typography>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Box component="span">
            <CustomTextField placeholder="Folder Name" onChange={e => setNewFolderName(e.target.value)} value={newFolderName} focused autoFocus fullWidth onFocus={event => {
            event.target.select();
          }} onKeyPress={e => {
            if (e.key === 'Enter' && e.target.value != '') {
              makeNewFolder(e.target.value);
            }
          }} />
          </Box>
        </DialogContent>
        <DialogActions sx={{
        py: 1
      }}>
          <Button onClick={() => {
          setCreateNewFolderDialog(false);
          setNewFolderName('');
        }} variant="text" sx={{
          borderRadius: '20px',
          color: 'rgba(29, 37, 45, 0.7)',
          width: '75px',
          textTransform: 'none'
        }}>
            Cancel
          </Button>
          <Button disabled={!newFolderName} onClick={() => {
          makeNewFolder(newFolderName);
        }} variant="text" sx={{
          mr: 1,
          borderRadius: '20px',
          width: '75px',
          color: '#53A6D6',
          textTransform: 'none'
        }}>
            Create
          </Button>
        </DialogActions>
      </Dialog>

      {folderSelectDialog.length > 0 && <FolderSelectDialog data={folderSelectDialog} onClose={onClose} onSubmit={onSubmitData} folderModel="survey" />}

      <TagModal onClose={() => setTagModal(null)} onSuccess={() => {
      setRenderIteration(i => i + 1);
    }} objectTarget={tagModal} getViewTags={getSurveysTags} patchView={updateSurvey} viewName={'Surveys'}></TagModal>

      <NewModal title={canArchive() ? 'Archive Survey(s)' : 'Cannot Archive Survey(s)'} onClose={closeArchiveModal} open={archiveModal.length > 0} allowBackdropClick>
        <div className="modal__body">
          {canArchive() ? <>
              <p>
                Are you sure you would like to archive the following surveys? Any invite already sent will no longer work, but existing
                responses will be unaffected.
              </p>
              {archiveModal.map(survey => <div key={survey.id}>{survey.title}</div>)}
            </> : archiveModal.filter(s => s.journeys.length > 0).map(survey => <>
                  <p>
                    The survey <strong>{survey.title} </strong> cannot be archived because it is used in the following places.
                  </p>
                  {!!survey.journeys.length && <>
                      <h4>Journeys</h4>
                      <Box sx={{
              px: 2
            }}>
                        {survey.journeys.map((j, index) => {
                return <Button key={index} onClick={e => {
                  closeArchiveModal();
                  history.push(`/customer-journeys/${j.id}`);
                }}>
                              {j.name}
                            </Button>;
              })}
                      </Box>
                    </>}
                </>)}
        </div>
        <div className="modal__actions">
          {canArchive() ? <>
              <Button actionType="flat" onClick={closeArchiveModal}>
                Cancel
              </Button>
              <Button actionType="primary" onClick={() => archiveSurvey()}>
                Confirm
              </Button>
            </> : <Button actionType="primary" onClick={closeArchiveModal}>
              Okay
            </Button>}
        </div>
      </NewModal>

      <NewModal title={canDelete() ? 'Delete item(s)' : 'Cannot Delete Item(s)'} onClose={closeDeleteModal} open={deleteModal.length > 0} allowBackdropClick>
        <div className="modal__body">
          {canDelete() ? <>
              <p>Are you sure you would like to delete the following items? </p>
              {deleteModal.some(i => i._type == 'folder') && <Stack sx={{
            p: 1
          }}>
                  <Typography component="span">Folders:</Typography>
                  <em component="span">
                    If these folders have any surveys in it, they will not be deleted, but will be moved back to the home folder.
                  </em>
                  {deleteModal.filter(s => s._type == 'folder').map(folder => <Box component="span" key={folder.id} sx={{
              py: 1,
              pl: 2
            }}>
                        {folder.name}
                      </Box>)}
                </Stack>}

              {deleteModal.some(i => i._type == 'rowItem') && <Stack sx={{
            p: 1
          }}>
                  <Typography component="span">Surveys:</Typography>
                  <em component="span">Any invite already sent will no longer work, but existing responses will be unaffected.</em>
                  {deleteModal.filter(s => s._type == 'rowItem').map(survey => <Box sx={{
              py: 1,
              pl: 2
            }} component="span" key={survey.id}>
                        {survey.title}
                      </Box>)}
                </Stack>}
            </> : deleteModal.filter(s => s._type == 'rowItem').filter(s => s.journeys.length > 0).map(survey => <>
                  <p>
                    The survey <strong>{survey.title} </strong> cannot be deleted because it is used in the following places.
                  </p>
                  {!!survey.journeys.length && <>
                      <h4>Journeys</h4>
                      <Box sx={{
              px: 2
            }}>
                        {survey.journeys.map((j, index) => {
                return <Button key={index} onClick={e => {
                  closeArchiveModal();
                  history.push(`/customer-journeys/${j.id}`);
                }}>
                              {j.name}
                            </Button>;
              })}
                      </Box>
                    </>}
                </>)}
        </div>
        <div className="modal__actions">
          {canDelete() ? <>
              <Button actionType="flat" onClick={closeDeleteModal}>
                Cancel
              </Button>
              <Button actionType="primary" onClick={() => deleteSurveys()}>
                Confirm
              </Button>
            </> : <Button actionType="primary" onClick={closeDeleteModal}>
              Okay
            </Button>}
        </div>
      </NewModal>
    </>;
};