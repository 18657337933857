import React from 'react';
function Icon(props) {
  return <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" fill="none" viewBox="0 0 25 24">
      <mask id="mask0_1002_1173" style={{
      maskType: 'alpha'
    }} width="25" height="24" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0.518 0H24.518V24H0.518z"></path>
      </mask>
      <g mask="url(#mask0_1002_1173)">
        <path fill={props.fill || '#1D252D'} d="M11.768 19.57v-7.14l-6.25-3.618v6.965c0 .051.013.1.039.144a.33.33 0 00.115.116l6.096 3.532zm1.5 0l6.096-3.534a.33.33 0 00.116-.115.286.286 0 00.038-.144V8.812l-6.25 3.619v7.138zm-.75-8.431l6.175-3.57-6.021-3.482a.288.288 0 00-.154-.049.288.288 0 00-.154.049L6.343 7.569l6.175 3.57zm-7.596 6.215a1.78 1.78 0 01-.904-1.562V8.208a1.77 1.77 0 01.904-1.562l6.692-3.852a1.782 1.782 0 011.808 0l6.692 3.852a1.78 1.78 0 01.904 1.562v7.584a1.77 1.77 0 01-.904 1.562l-6.692 3.852a1.782 1.782 0 01-1.808 0l-6.692-3.852z"></path>
      </g>
    </svg>;
}
export default Icon;