import React from 'react';
function Icon() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <mask id="mask0_22_5173" style={{
      maskType: 'alpha'
    }} width="24" height="24" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0 0H24V24H0z"></path>
      </mask>
      <g mask="url(#mask0_22_5173)">
        <path fill="#1D252D" d="M12 14.135a.853.853 0 01-.626-.259.853.853 0 01-.259-.626c0-.245.087-.454.259-.626a.853.853 0 01.626-.258c.245 0 .454.086.626.258a.852.852 0 01.259.626.852.852 0 01-.26.626.853.853 0 01-.625.259zm-4 0a.853.853 0 01-.626-.259.853.853 0 01-.259-.626c0-.245.087-.454.259-.626A.853.853 0 018 12.366c.245 0 .453.086.626.258a.853.853 0 01.259.626.853.853 0 01-.26.626.853.853 0 01-.625.259zm8 0a.853.853 0 01-.626-.259.853.853 0 01-.259-.626c0-.245.087-.454.259-.626a.853.853 0 01.626-.258c.245 0 .453.086.626.258a.852.852 0 01.259.626.852.852 0 01-.26.626.853.853 0 01-.625.259zm-4 3.923a.853.853 0 01-.626-.259.853.853 0 01-.259-.626c0-.245.087-.453.259-.626a.853.853 0 01.626-.259c.245 0 .454.087.626.26a.852.852 0 01.259.625.853.853 0 01-.26.626.853.853 0 01-.625.259zm-4 0a.853.853 0 01-.626-.259.853.853 0 01-.259-.626c0-.245.087-.453.259-.626A.852.852 0 018 16.288c.245 0 .453.087.626.26a.853.853 0 01.259.625.853.853 0 01-.26.626.853.853 0 01-.625.259zm8 0a.853.853 0 01-.626-.259.853.853 0 01-.259-.626c0-.245.087-.453.259-.626a.853.853 0 01.626-.259c.245 0 .453.087.626.26a.852.852 0 01.259.625.853.853 0 01-.26.626.853.853 0 01-.625.259zm-10.692 3.5c-.505 0-.933-.175-1.283-.525A1.745 1.745 0 013.5 19.75V6.365c0-.505.175-.932.525-1.282.35-.35.778-.525 1.283-.525h1.384V3.212c0-.22.074-.403.22-.55a.745.745 0 01.55-.22c.219 0 .402.074.549.22.146.147.22.33.22.55v1.346h7.577V3.192c0-.212.071-.39.215-.534a.726.726 0 01.535-.216c.212 0 .39.072.534.216a.726.726 0 01.216.534v1.366h1.384c.505 0 .933.175 1.283.525.35.35.525.777.525 1.282V19.75c0 .505-.175.933-.525 1.283-.35.35-.778.525-1.283.525H5.308zm0-1.5h13.384a.3.3 0 00.221-.087.3.3 0 00.087-.221v-9.384H5v9.384a.3.3 0 00.087.221.3.3 0 00.22.087zM5 8.865h14v-2.5a.3.3 0 00-.087-.22.3.3 0 00-.22-.087H5.307a.3.3 0 00-.221.086.3.3 0 00-.087.221v2.5z"></path>
      </g>
    </svg>;
}
export default Icon;