import React from 'react';
function CheckIcon(props) {
  return <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="none" viewBox="0 0 25 25">
      <g>
        <mask id="mask0_34_10183" style={{
        maskType: 'alpha'
      }} width="25" height="25" x="0" y="0" maskUnits="userSpaceOnUse">
          <path fill="#D9D9D9" d="M0.5 0.512H24.5V24.512H0.5z"></path>
        </mask>
        <g mask="url(#mask0_34_10183)">
          <path fill={props.fill || '#1D252D'} fillOpacity="0.8" d="M10.05 16.027L18.69 7.39a.742.742 0 011.069 0 .735.735 0 01.233.535c0 .2-.078.38-.233.534l-9.075 9.075a.867.867 0 01-.633.271.867.867 0 01-.632-.27l-4.175-4.176a.714.714 0 01-.22-.53.75.75 0 01.235-.539.735.735 0 01.535-.233c.201 0 .38.078.534.233l3.723 3.739z"></path>
        </g>
      </g>
    </svg>;
}
export default CheckIcon;