import React, { Component } from 'react';
import { Box, LinearProgress, Stack, Typography } from '@mui/material';
export const Score = props => {
  return <Stack direction="row">
      <Box sx={{
      width: '50px'
    }}>
        <Typography>{props.rawValue ? '' + Math.round(props.rawValue * 100) + '%' : '0%'} </Typography>
      </Box>
      <Box sx={{
      right: '10px',
      width: '100px'
    }}>
        <LinearProgress variant="determinate" sx={{
        height: '10px',
        borderRadius: '3px',
        top: '3px'
      }} value={props.rawValue * 100 || 0} />
      </Box>
    </Stack>;
};