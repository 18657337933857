import React from 'react';
function Icon() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none" viewBox="0 0 24 25">
      <mask id="mask0_136_283" style={{
      maskType: 'alpha'
    }} width="25" height="25" x="-1" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M-0.001 0.5H23.999V24.5H-0.001z"></path>
      </mask>
      <g mask="url(#mask0_136_283)">
        <path fill="#1D252D" d="M8.35 15.365c.258.743.63 1.332 1.116 1.77.485.437 1.094.74 1.826.911v.579c0 .182.068.341.204.478a.655.655 0 00.478.205.655.655 0 00.478-.205.655.655 0 00.205-.478v-.529a4.407 4.407 0 002.107-.874c.629-.477.943-1.202.943-2.172 0-.7-.207-1.32-.62-1.858-.412-.538-1.219-1.024-2.418-1.457-1.052-.36-1.75-.67-2.095-.933-.345-.263-.517-.63-.517-1.102 0-.472.176-.855.53-1.15.353-.295.84-.442 1.462-.442.52 0 .94.122 1.26.368.32.245.565.553.736.924l1.235-.496a3.262 3.262 0 00-1.022-1.366 2.783 2.783 0 00-1.551-.634v-.529a.655.655 0 00-.205-.478.655.655 0 00-.478-.205.655.655 0 00-.478.205.655.655 0 00-.204.478v.529c-.853.164-1.508.51-1.965 1.037-.457.528-.685 1.114-.685 1.759 0 .751.227 1.356.682 1.814.455.457 1.197.87 2.225 1.24 1.063.39 1.789.74 2.178 1.049.39.31.584.725.584 1.247 0 .627-.227 1.082-.68 1.366a2.815 2.815 0 01-1.527.426c-.566 0-1.071-.169-1.515-.507-.445-.339-.786-.834-1.024-1.485l-1.266.515zM12 22a9.255 9.255 0 01-3.704-.748 9.596 9.596 0 01-3.017-2.03 9.591 9.591 0 01-2.031-3.016 9.245 9.245 0 01-.749-3.704c0-1.314.25-2.55.748-3.705a9.596 9.596 0 012.03-3.017 9.592 9.592 0 013.016-2.032A9.245 9.245 0 0111.998 3c1.314 0 2.549.25 3.705.748a9.597 9.597 0 013.017 2.03 9.59 9.59 0 012.03 3.016 9.245 9.245 0 01.75 3.704c0 1.314-.25 2.55-.749 3.705a9.595 9.595 0 01-2.03 3.018 9.593 9.593 0 01-3.016 2.03 9.246 9.246 0 01-3.704.749zm0-1.5c2.233 0 4.124-.775 5.674-2.325C19.224 16.625 20 14.733 20 12.5c0-2.233-.775-4.125-2.325-5.675C16.124 5.275 14.233 4.5 12 4.5c-2.233 0-4.125.775-5.675 2.325C4.774 8.375 4 10.267 4 12.5c0 2.233.775 4.125 2.325 5.675C7.874 19.725 9.766 20.5 12 20.5z"></path>
      </g>
    </svg>;
}
export default Icon;