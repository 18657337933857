import { Formik, Form } from 'formik';
import React, { useState, useEffect, useCallback, useContext } from 'react';
import { CinchModal } from '../../../../components/mui/modal/form.component';
import { BroadcastEmailModalFooter } from './manage-broadcast/broadcast-email-modal-footer';
import { BroadcastEmailModalHeader } from './manage-broadcast/broadcast-email-modal-header';
import { BroadcastEmailModalContent } from './manage-broadcast/broadcast-email-modal-content';
import { getCompany, createManagedBroadcast, deleteManagedBroadcast, updateManagedBroadcast } from 'shared/common.api';
import * as Yup from 'yup';
import { ManagedBroadcastRequestBuilder } from './utility';
import { UserStateContext } from 'context/user-state-context';
import { ManagedBroadcastConfirmationContent } from './manage-broadcast/confirmation/confirmation-modal-content';
import { ManagedBroadcastConfirmationFooter } from './manage-broadcast/confirmation/confirmation-modal-footer';
import { ManagedBroadcastConfirmationHeader } from './manage-broadcast/confirmation/confirmation-modal-header';

/**
 * This is an example to help others learn how to use formik, also
 * a feature.
 */
// TODO name show / setShow to be a better convention
export const ManageBroadcastEmailModal = ({
  show,
  setShow,
  initialFormValues,
  reloadData
}) => {
  const {
    asCompany
  } = useContext(UserStateContext);
  const [companiesChildren, setCompaniesChildren] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showSaveConfirmation, setShowSaveConfirmation] = useState(false);
  const loadPreliminaryData = useCallback(async () => {
    try {
      setCompaniesChildren(asCompany.children.map(data => {
        return {
          value: data.id,
          name: data.name,
          default_email: data.default_email,
          default_from_name: data.default_from_name
        };
      }));
    } catch (error) {
      console.error(error, 'exception');
    } finally {
      setLoading(false);
    }
  }, [asCompany]);
  useEffect(() => {
    loadPreliminaryData();
  }, []);

  /**
   * This is one of the easiest ways to write validation, but if you need
   * more complicated stuff, see custom validation.
   */
  const validationSchema = data => {
    return Yup.object().shape({
      children_companies: Yup.array().min(1, 'You must select at least one child company.'),
      segment: Yup.string().required('Required'),
      email_template: Yup.string().required('Required')
    });
  };
  const customValidation = values => {
    /**
     * You CAN do nested validation in Yup,
     * but for more complicated validation this is easier.
     */
    const errors = {};
    if (values.drip_toggled === true) {
      if (!values.drip_end) {
        errors.drip_end = 'Required';
      }
    }
    if (values.window_toggle === true) {
      if (!values.window_start) {
        errors.window_start = 'Required';
      }
      if (!values.window_end) {
        errors.window_end = 'Required';
      }
      if (Object.keys(values.window_days).length === 0) {
        errors.window_days = 'Required';
      }
    }
    for (const child of companiesChildren) {
      if (values.children_companies.includes(child.value)) {
        if (!child.default_email) {
          errors.children_companies = `${child.name} requires the setting "DEFAULT EMAIL ADDRESS" to be configured on the company.`;
        }
        if (!child.default_from_name) {
          errors.children_companies = `${child.name} requires the setting "DEFAULT FROM NAME" to be configured on the company.`;
        }
      }
    }
    return errors;
  };
  const openConfirmationModal = () => {
    setShowSaveConfirmation(true);
  };

  // TODO wrap in try / catch
  const saveManagedBroadcast = async values => {
    const requestBuilder = new ManagedBroadcastRequestBuilder().setCompanyId(asCompany.id).setChildrenCompanies(values.children_companies).setSegment(values.segment).setEmailTemplate(values.email_template).setDripEnd(values.drip_end).setScheduleWhen(values.schedule_when).setScheduleTimezone(values.schedule_timezone).setDistinctEmails(values.distinct_emails).setGoogleCampaign(values.google_campaign).setWindowStart(values.window_start).setWindowEnd(values.window_end).setWindowDays(values.window_days);
    if (values.id) {
      const identifier = values.id;
      const payload = requestBuilder.build();
      try {
        await updateManagedBroadcast(identifier, payload);
      } catch (error) {
        console.error(error, 'there was an issue saving (updating) the form');
      }
    } else {
      const payload = requestBuilder.build();
      try {
        await createManagedBroadcast(payload);
        setShow(false);
      } catch (error) {
        console.error(error, 'there was an issue saving (creating) the form');
      }
    }
    reloadData();
    setShowSaveConfirmation(false);
    closeModal(); // TODO name things better
  };
  const closeModal = () => {
    setShow(false);
  };
  const markManagedBroadcastForDeletion = async id => {
    if (id) {
      await deleteManagedBroadcast(id);
    }
    reloadData();
  };
  return <>
      {!loading && <Formik initialValues={initialFormValues} onSubmit={openConfirmationModal} validationSchema={validationSchema} validate={customValidation} validateOnBlur={true} validateOnChange={true}>
          {({
        submitForm,
        values
      }) => <Form>
              <CinchModal show={show} setShow={setShow} headerComponent={<BroadcastEmailModalHeader />} contentComponent={<BroadcastEmailModalContent companiesChildren={companiesChildren} />} footerComponent={<BroadcastEmailModalFooter openConfirmation={submitForm} closeModal={closeModal} deleteManagedBroadcast={async () => {
          await markManagedBroadcastForDeletion(values.id);
          closeModal();
        }} values={values} />} />
              <CinchModal show={showSaveConfirmation} headerComponent={<ManagedBroadcastConfirmationHeader />} contentComponent={<ManagedBroadcastConfirmationContent values={values} companiesChildren={companiesChildren} />} footerComponent={<ManagedBroadcastConfirmationFooter saveManagedBroadcast={saveManagedBroadcast} values={values} setShowSaveConfirmation={setShowSaveConfirmation} />} />
              ;
            </Form>}
        </Formik>}
    </>;
};