import React, { Component } from 'react';
import { UserStateContext } from 'context/user-state-context';
import { Button } from '../../../components/button/button.component';
import { addPestRoutesCredentials, updatePestRoutesCredentials, getPestRoutesCredentials, deletePestRoutesCredentials } from '../integrations.resource';
import { toasterService } from '../../../components/toaster/toaster-service';
import { ModalDialog } from '../../../components/modal/modal-dialog.component';
import moment from 'moment-timezone';
import { StaticTypeAhead } from '../../../components/static-type-ahead/static-type-ahead.component';
import { Loader } from 'components';
import { Stack } from '@mui/material';
export class IntegrationPestRoutesUpdate extends Component {
  static contextType = UserStateContext;
  static propTypes = {};
  state = {
    credentials: [],
    deleteCredentials: {},
    isAddModalOpen: false,
    credential: {},
    loading: true
  };
  componentDidMount() {
    //const onLoad = this.props.initLoad('pestroutes');
    this.getPestRoutesData();
    //.finally(() => onLoad());
  }
  getPestRoutesData = () => {
    this.setState({
      loading: true
    });
    return getPestRoutesCredentials({
      company: this.context.asCompany.id
    }).then(({
      results
    }) => {
      this.setState({
        credentials: results
      });
      this.setState({
        loading: false
      });
    });
  };
  submitCredentials = credential => {
    if (credential.id) {
      this.updateCredentials(credential.id, credential.domain, credential.key, credential.token, credential.timezone);
    } else {
      this.addCredentials(credential.domain, credential.key, credential.token, credential.timezone);
    }
  };
  addCredentials = (domain, key, token, timezone) => {
    return addPestRoutesCredentials(domain, key, token, this.context.asCompany.id, timezone).then(() => toasterService.success('Successfully added credentials')).then(() => this.getPestRoutesData()).catch(e => {
      if (e.response && e.response.data) {
        toasterService.error(e.response.data[0]);
      } else {
        toasterService.error('Unknown error trying add credentials. Please try again.');
      }
    });
  };
  updateCredentials = (id, domain, key, token, timezone) => {
    return updatePestRoutesCredentials(id, domain, key, token, timezone).then(() => toasterService.success('Successfully updated credentials')).then(() => this.getPestRoutesData()).catch(e => {
      if (e.response && e.response.data) {
        toasterService.error(e.response.data[0]);
      } else {
        toasterService.error('Unknown error trying add credentials. Please try again.');
      }
    });
  };
  removeCredentials = credentialsId => {
    return deletePestRoutesCredentials(credentialsId).then(() => toasterService.success('Successfully deleted credentials')).then(() => this.getPestRoutesData()).catch(() => toasterService.error('Unknown error trying delete credentials. Please try again.'));
  };
  render() {
    const {
      credentials,
      deleteCredentials,
      isAddModalOpen,
      credential,
      loading
    } = this.state;
    const timezone = moment.tz.names().map(t => {
      return {
        id: t,
        value: t
      };
    });
    return <>
        {loading ? <Loader /> : <div className="integration mb-5">
            <div className="integration__header">
              {this.context.hasPermission('pestroutes.add_credential') && <Button className="btn" actionType="primary" tag="a" onClick={() => this.setState({
            isAddModalOpen: true,
            credential: {}
          })}>
                  Add Credentials
                </Button>}
            </div>
            <div className="integration__body">
              {credentials.map(credential => <div className="integration-item" key={credential.id}>
                  <div className="integration-item__inner">
                    <Stack direction="row" spacing={2}>
                      <div className="integration-title">
                        <strong>{credential.domain}</strong>
                      </div>
                      <div className="integration-actions">
                        {this.context.hasPermission('pestroutes.change_credential') && <Button actionType="flat" icon="fa-regular-edit" onClick={e => {
                    e.stopPropagation();
                    this.setState({
                      isAddModalOpen: true,
                      credential
                    });
                  }} />}
                        {this.context.hasPermission('pestroutes.delete_credential') && <Button actionType="flat" icon="fa-regular-trash" onClick={e => {
                    e.stopPropagation();
                    this.setState({
                      deleteCredentials: credential
                    });
                  }} />}
                      </div>
                    </Stack>
                  </div>
                </div>)}
            </div>

            <ModalDialog open={isAddModalOpen} title={`${credential.id ? 'Update' : 'Add'} API Credentials`} onSubmit={() => this.submitCredentials(credential)} onClose={() => this.setState({
          isAddModalOpen: false
        })} allowBackdropClick>
              <p>Please enter the API authentication key and token for your PestRoutes account.</p>
              <p>
                <label>Domain</label>
                <input required className="form-control" value={credential.domain || ''} onChange={e => this.setState({
              credential: {
                ...credential,
                domain: e.target.value
              }
            })} />
                <small>
                  Only include the first part of the domain. For example, if your company is https://acme.pestroutes.com only enter "acme"
                  (without quotation marks)
                </small>
              </p>
              <p>
                <label>Frequency</label>
                <select required className="form-control" value={credential.frequency || 'daily'} onChange={e => this.setState({
              credential: {
                ...credential,
                frequency: e.target.value
              }
            })}>
                  <option value="daily">Daily</option>
                  <option value="hourly">Hourly</option>
                </select>
                <small>Use daily if you're on the PestRoutes free API</small>
              </p>
              <p>
                <label>Authentication Key</label>
                <input required className="form-control" value={credential.key || ''} onChange={e => this.setState({
              credential: {
                ...credential,
                key: e.target.value
              }
            })} />
              </p>
              <p>
                <label>Authentication Token</label>
                <input required className="form-control" value={credential.token || ''} onChange={e => this.setState({
              credential: {
                ...credential,
                token: e.target.value
              }
            })} />
              </p>
              <div className="form-group">
                <label>Timezone</label>
                <StaticTypeAhead items={timezone} placeholder={credential ? credential.timezone : 'Choose Timezone'} displayProperty="value" keyProperty="id" value={credential.timezone || ''} onChange={tz => tz && this.setState({
              credential: {
                ...credential,
                timezone: tz.value
              }
            })} />
              </div>
            </ModalDialog>

            <ModalDialog open={!!deleteCredentials.id} title="Remove Credentials" submitText="Remove" onSubmit={() => this.removeCredentials(deleteCredentials.id)} onClose={() => this.setState({
          deleteCredentials: {}
        })} allowBackdropClick>
              <p>
                <strong>{deleteCredentials.domain}</strong>
              </p>
              <p>Are you sure you want to delete these credentials?</p>
            </ModalDialog>
          </div>}
      </>;
  }
}