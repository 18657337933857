import React from "react";
function Icon() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" fill="none" viewBox="0 0 21 20">
      <mask id="mask0_38_2652" style={{
      maskType: "alpha"
    }} width="21" height="20" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0.73 0H20.73V20H0.73z"></path>
      </mask>
      <g mask="url(#mask0_38_2652)">
        <path fill="#53A6D6" d="M5.486 16.583a1.29 1.29 0 01-.946-.393 1.29 1.29 0 01-.393-.946V4.756c0-.368.13-.683.393-.946a1.29 1.29 0 01.946-.393h10.488c.368 0 .683.13.946.393.262.263.393.578.393.946v10.488c0 .368-.13.683-.393.946a1.29 1.29 0 01-.946.393H5.486zm0-1.083h10.488a.25.25 0 00.184-.072.25.25 0 00.072-.184V6h-11v9.244a.25.25 0 00.072.184.25.25 0 00.184.072zm5.242-2.167c-.909 0-1.723-.242-2.443-.727a4.445 4.445 0 01-1.598-1.856 4.44 4.44 0 011.6-1.856c.722-.485 1.537-.727 2.445-.727.91 0 1.724.242 2.444.727a4.445 4.445 0 011.597 1.856 4.44 4.44 0 01-1.6 1.856c-.722.485-1.537.727-2.445.727zm.002-.743c.677 0 1.296-.162 1.857-.486a3.837 3.837 0 001.37-1.354 3.838 3.838 0 00-1.37-1.354 3.647 3.647 0 00-1.857-.486c-.677 0-1.296.162-1.857.486a3.838 3.838 0 00-1.37 1.354c.352.579.809 1.03 1.37 1.354.56.324 1.18.486 1.857.486zm.002-.75c.303 0 .56-.106.771-.319.211-.212.317-.47.317-.773 0-.303-.106-.56-.319-.771a1.056 1.056 0 00-.773-.317c-.303 0-.56.106-.771.319-.211.212-.317.47-.317.773 0 .303.106.56.319.771.212.211.47.317.773.317z"></path>
      </g>
    </svg>;
}
export default Icon;