import React from 'react';
function Icon() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="none" viewBox="0 0 25 25">
      <mask id="mask0_24_6554" style={{
      maskType: 'alpha'
    }} width="25" height="25" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0.908 0.095H24.908V24.095H0.908z"></path>
      </mask>
      <g mask="url(#mask0_24_6554)">
        <path fill="#1D252D" d="M13.713 12.845l-1.332 1.332a.686.686 0 00-.203.518.719.719 0 00.736.725.7.7 0 00.52-.208l2.485-2.485a.867.867 0 00.271-.632.867.867 0 00-.27-.633l-2.495-2.494a.702.702 0 00-.517-.208.71.71 0 00-.725.731.7.7 0 00.207.521l1.323 1.333H9.909a.725.725 0 00-.535.215.726.726 0 00-.215.535c0 .213.072.39.215.534a.726.726 0 00.535.216h3.806zm-.804 8.75a9.255 9.255 0 01-3.705-.748 9.596 9.596 0 01-3.017-2.03A9.591 9.591 0 014.156 15.8a9.245 9.245 0 01-.748-3.705c0-1.314.249-2.549.748-3.705a9.596 9.596 0 012.03-3.017 9.592 9.592 0 013.016-2.031 9.245 9.245 0 013.704-.748c1.314 0 2.549.25 3.705.748a9.597 9.597 0 013.017 2.03 9.59 9.59 0 012.031 3.016 9.245 9.245 0 01.749 3.704c0 1.314-.25 2.549-.748 3.705a9.596 9.596 0 01-2.03 3.017 9.592 9.592 0 01-3.016 2.031 9.247 9.247 0 01-3.705.749zm-.001-1.5c2.233 0 4.125-.775 5.675-2.325 1.55-1.55 2.325-3.442 2.325-5.675 0-2.234-.775-4.125-2.325-5.675-1.55-1.55-3.442-2.325-5.675-2.325-2.234 0-4.125.775-5.675 2.325-1.55 1.55-2.325 3.441-2.325 5.675 0 2.233.775 4.125 2.325 5.675 1.55 1.55 3.441 2.325 5.675 2.325z"></path>
      </g>
    </svg>;
}
export default Icon;