import React, { useState } from 'react';
import { ModalDialog, Dropdown, Button, Icon } from 'components';
const STATUS_MAP = {
  opt_in: 'Opt In',
  pending: 'Pending',
  suppressed: 'Suppressed',
  unknown: 'Unknown'
};
export const SMSOptStatuses = props => {
  const {
    param
  } = props;
  const optStatuses = props.data.optStatuses || [];
  const statuses = optStatuses.map(s => STATUS_MAP[s]);
  const [optAgreeOpen, setOptAgreeOpen] = useState(false);
  const [understood, setUnderstood] = useState(false);
  const toggleStatus = status => {
    const optStatuses = props.data.optStatuses ? [...props.data.optStatuses] : [];
    const index = optStatuses.indexOf(status);
    if (index === -1) {
      optStatuses.push(status);
    } else {
      optStatuses.splice(index, 1);
    }

    // If any status other than opt_in is there we need to get approval.
    if (!understood && (optStatuses.indexOf('not_opt_in') > -1 || optStatuses.indexOf('pending') > -1 || optStatuses.indexOf('unknown') > -1 || optStatuses.indexOf('suppressed') > -1)) {
      setOptAgreeOpen(true);
    }
    props.update({
      optStatuses
    });
  };
  return <div className="form-group">
      <label>{param.label}</label>
      <Dropdown size="block" contentHeight={250} trigger={() => <Button dropdown block>
            {statuses.length ? statuses.join(', ') : 'Choose Statuses'}
          </Button>} content={() => <ul className="select-list">
            <li>
              <a onClick={() => toggleStatus('opt_in')}>
                <div className="select-list__content">Opt In</div>
                {optStatuses && optStatuses.indexOf('opt_in') > -1 && <Icon className="select-list__selected" size={14} name="fa-regular-check" />}
              </a>
            </li>
            <li>
              <a onClick={() => toggleStatus('pending')}>
                <div className="select-list__content">Pending</div>
                {optStatuses && optStatuses.indexOf('pending') > -1 && <Icon className="select-list__selected" size={14} name="fa-regular-check" />}
              </a>
            </li>
            <li>
              <a onClick={() => toggleStatus('suppressed')}>
                <div className="select-list__content">Suppressed</div>
                {optStatuses && optStatuses.indexOf('suppressed') > -1 && <Icon className="select-list__selected" size={14} name="fa-regular-check" />}
              </a>
            </li>
            <li>
              <a onClick={() => toggleStatus('unknown')}>
                <div className="select-list__content">Unknown</div>
                {optStatuses && optStatuses.indexOf('unknown') > -1 && <Icon className="select-list__selected" size={14} name="fa-regular-check" />}
              </a>
            </li>
          </ul>} />
      <ModalDialog open={optAgreeOpen} title="SMS Liability Warning" cancelText="Nevermind" submitText="I Agree" onSubmit={() => setUnderstood(true)} onClose={() => setOptAgreeOpen(false)} onCancel={() => props.update({
      optStatuses: ['opt_in']
    })}>
        <p>
          Sending SMS messages to numbers that have not expressly opted to
          receive such messages may expose you to civil liability under
          applicable law.
        </p>
        <p>
          By using this system to send SMS messages, you affirm that you have
          received express permission from the recipient to send the messages or
          that such messages are otherwise permitted under applicable law.
          Further, you agree to assume all such liability and to defend,
          indemnify, and hold harmless Cinch against all claims and damages
          whatsoever, including cost of defense, caused by your use of this
          system.
        </p>
      </ModalDialog>
    </div>;
};