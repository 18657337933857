import React, { Component } from 'react';
import { zones, zoneMap } from './fast-sensor-helper';
export class ConditionFastSensorCheck extends Component {
  render() {
    return <div>
        <div className="form-group">
          <label>Zone</label>
          <select className="form-control" onChange={this.onZoneChange}>
            {zones.map(zone => <option key={zone} value={zone}>
                {zoneMap[zone]}
              </option>)}
          </select>
        </div>
      </div>;
  }
}