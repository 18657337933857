import React, { useState, useContext } from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, InputLabel } from '@mui/material';
import { FormikTextField } from 'components/mui/formik/formiktextfield.component';
import { FormikSelect } from 'components/mui/formik/formikselect.component';
import { FormikMultipleSelectCheckmarks } from 'components/mui/formik/formikmultipleselect.component';
import { FormikDateTimePicker } from 'components/mui/formik/formikdatetimepicker.component';
import { FormikTimePicker } from 'components/mui/formik/formiktimepicker.component';
import { FormikTimeZonePicker } from 'components/mui/formik/formiktimezonepicker.component';
import { FormikDayPicker } from 'components/mui/formik/formikdaypicker.component';
import { FormikSwitchComponent } from 'components/mui/formik/formikswitch.component';
import { useFormikContext } from 'formik';
import { ManageBroadcastInitialValueBuilder } from '../../utility';
import { FormikAutoComplete } from 'components/mui/formik/formikautocomplete.component';
import { getEmailTemplates, getSegments } from 'shared/common.api';
import { UserStateContext } from 'context/user-state-context';
const useStyles = makeStyles(() => ({
  itemContainer: {
    padding: '1rem'
  },
  lowercaseToggle: {
    textTransform: 'none'
  }
}));
export const BroadcastEmailModalContent = ({
  companiesChildren
}) => {
  const [showDrip, setShowDrip] = useState(false);
  const [showSendWindow, setShowSendWindow] = useState(false);
  const [showCompanyTimezonePicker, setShowCompanyTimezonePicker] = useState(true);
  const classes = useStyles();
  const {
    setFieldValue,
    setFieldTouched,
    values
  } = useFormikContext();
  const {
    asCompany
  } = useContext(UserStateContext);
  const toggleDrip = newValue => {
    setShowDrip(newValue);
    if (newValue === false) {
      const defaults = new ManageBroadcastInitialValueBuilder();
      setFieldValue('drip_end', defaults.drip_end);
      setFieldTouched('drip_end', false, false);
    }
  };
  const toggleSendWindow = newValue => {
    setShowSendWindow(newValue);
    if (newValue === false) {
      const defaults = new ManageBroadcastInitialValueBuilder();
      setFieldValue('window_start', defaults.window_start);
      setFieldTouched('window_start', false, false);
      setFieldValue('window_end', defaults.window_end);
      setFieldTouched('window_end', false, false);
      setFieldValue('window_days', defaults.window_days);
      setFieldTouched('window_days', false, false);
    }
  };
  const toggleScheduleTimezone = newValue => {
    setShowCompanyTimezonePicker(newValue);
    if (newValue === true) {
      const defaults = new ManageBroadcastInitialValueBuilder();
      setFieldValue('schedule_timezone', defaults.schedule_timezone);
      setFieldTouched('schedule_timezone', false, false);
    }
  };
  return <Grid container>
      <Grid item xs={12} className={classes.itemContainer}>
        <FormikMultipleSelectCheckmarks name="children_companies" options={companiesChildren} label="Companies To Broadcast To" />
      </Grid>
      <Grid item xs={12} className={classes.itemContainer}>
        <FormikAutoComplete name="segment" label="Segments To Broadcast From" listResourceRequest={getSegments} resourceRequestParams={{
        company: asCompany.id
      }} />
      </Grid>
      <Grid item xs={12} className={classes.itemContainer}>
        <FormikAutoComplete name="email_template" label="Email Template For The Broadcast" listResourceRequest={getEmailTemplates} resourceRequestParams={{
        company: asCompany.id,
        archived: false,
        published: true
      }} />
      </Grid>
      <Grid item xs={12} className={classes.itemContainer}></Grid>
      <Grid item xs={12} className={classes.itemContainer}>
        <InputLabel style={{
        marginBottom: '1rem'
      }}>When Would You Like To Send Your Broadcast?</InputLabel>
        <FormikSwitchComponent name="schedule_timezone_toggle" label="Send at company's local timezone" onChange={newValue => {
        toggleScheduleTimezone(newValue);
      }} />
        <Grid container>
          <Grid item xs={6}>
            <FormikDateTimePicker name="schedule_when" label="Broadcast Time" disablePast={true} />
          </Grid>
          {!showCompanyTimezonePicker && <Grid item xs={6}>
              <FormikTimeZonePicker name="schedule_timezone" label="Company's Timezone" />
            </Grid>}
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.itemContainer}>
        <FormikSwitchComponent name="drip_toggled" label="Drip Emails Over time" onChange={newValue => {
        toggleDrip(newValue);
      }} />
        {showDrip && <Grid item xs={12}>
            <Grid container>
              <Grid item xs={6}>
                <FormikDateTimePicker name="drip_end" label="Drip End Time" disablePast={true} />
              </Grid>
            </Grid>
          </Grid>}
      </Grid>
      <Grid item xs={12} className={classes.itemContainer}>
        <FormikSwitchComponent name="window_toggle" label="Use Send Window" onChange={newValue => {
        toggleSendWindow(newValue);
      }} />
        {showSendWindow && <Grid>
            <Grid container style={{
          paddingTop: '1rem',
          paddingBottom: '1rem'
        }}>
              <Grid item xs={6}>
                <FormikTimePicker name="window_start" label="Window Start Time" />
              </Grid>
              <Grid item xs={6}>
                <FormikTimePicker name="window_end" label="Window End Time" />
              </Grid>
            </Grid>
            <Grid container style={{
          paddingTop: '1rem',
          paddingBottom: '1rem'
        }}>
              <FormikDayPicker name="window_days" />
            </Grid>
          </Grid>}
      </Grid>
      <Grid item xs={12} className={classes.itemContainer}>
        <FormikSwitchComponent name="distinct_emails" label="Send Only One Email Per Email Address" />
      </Grid>
      <Grid item xs={12} className={classes.itemContainer}>
        <FormikTextField name="google_campaign" label="Google Analytics Campaign Title (Optional)" />
      </Grid>
    </Grid>;
};