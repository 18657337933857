import React, { useEffect, useState, createContext, useContext } from 'react';
import { DrawerCustomer } from '../components/drawer/customer';
import { getCustomers, getCustomer } from '../shared/common.api';
import { UserStateContext } from './user-state-context';
import { toasterService } from 'components/toaster/toaster-service';
export const CustomerDrawerContext = createContext();
export const CustomerDrawerContextProvider = ({
  children
}) => {
  const {
    asCompany,
    user,
    redirect,
    checkCompany,
    hasIntegration,
    hasPermission,
    logOut,
    setAsCompany,
    setToken,
    setTokenExpiry,
    hasMultiAccess,
    setUser,
    setRedirect,
    isValidToken
  } = useContext(UserStateContext);
  const [customerID, setCustomerID] = useState();
  const [customerTransaction, setCustomerTransaction] = useState();
  const [startTab, setStartTab] = useState('summary');
  const [scrollToTransactionDetails, setScrollToTransactionDetails] = useState(false);
  useEffect(() => {
    if (!asCompany?.is_active) {
      // We may need to figure out a better way to load everything like maybe a global loader page.
      // but checking for is_active is enough to prevent trying to load data before our token is ready.
      return;
    }
    const params = new URLSearchParams(window.location.search);
    if (params.has('customer_drawer_tab')) {
      setStartTab(params.get('customer_drawer_tab'));
    }
    if (params.has('transaction_id')) {
      setCustomerTransaction(params.get('transaction_id'));
    }
    if (params.has('customer_drawer_id')) {
      getCustomer(params.get('customer_drawer_id')).then(customer => {
        setCustomerID(customer.data.id);
      }).catch(e => {
        toasterService.error('No customer was found with that id');
      });
    } else if (params.has('customer_drawer_ref') && !params.has('customer_drawer_entity')) {
      toasterService.error('Please also include a customer_drawer_entity URL param for the lookup');
    } else if (!params.has('customer_drawer_ref') && params.has('customer_drawer_entity')) {
      toasterService.error('Please also include a customer_drawer_ref URL param for the lookup');
    } else if (params.has('customer_drawer_ref') && params.has('customer_drawer_entity')) {
      getCustomers({
        company: asCompany.id,
        customer_refs__entity_ref: params.get('customer_drawer_ref'),
        customer_refs__entity_source: params.get('customer_drawer_entity'),
        limit: 1
      }).then(({
        data
      }) => {
        if (data.results.length > 0) {
          setCustomerID(data.results[0].id);
        } else {
          toasterService.error('No customer was found with those ids');
        }
      });
    }
  }, [asCompany?.is_active]);
  const addCustomerParam = (id, tab) => {
    let queryParams = new URLSearchParams(window.location.search);
    queryParams.set('customer_drawer_id', id);
    queryParams.set('customer_drawer_tab', tab ? tab : startTab);
    let newurl = window.location.protocol + '//' + window.location.host + window.location.pathname + '?' + queryParams.toString();
    window.history.pushState({
      path: newurl
    }, '', newurl);
  };
  const removeCustomerParam = () => {
    let queryParams = new URLSearchParams(window.location.search);
    queryParams.delete('customer_drawer_id');
    queryParams.delete('customer_drawer_ref');
    queryParams.delete('customer_drawer_entity');
    queryParams.delete('customer_drawer_tab');
    if (Array.from(queryParams).length) {
      let newurl = window.location.protocol + '//' + window.location.host + window.location.pathname + '?' + queryParams.toString();
      window.history.pushState({
        path: newurl
      }, '', newurl);
    } else {
      let newurl = window.location.protocol + '//' + window.location.host + window.location.pathname;
      window.history.pushState({
        path: newurl
      }, '', newurl);
    }
  };
  const setTabParam = tab => {
    let queryParams = new URLSearchParams(window.location.search);
    queryParams.set('customer_drawer_tab', tab);
    let newurl = window.location.protocol + '//' + window.location.host + window.location.pathname + '?' + queryParams.toString();
    window.history.pushState({
      path: newurl
    }, '', newurl);
  };
  const openCustomerDrawer = (customerID, tab, transaction, transactionScrollDown) => {
    if (tab) {
      setStartTab(tab);
    }
    setCustomerTransaction(transaction);
    setScrollToTransactionDetails(transactionScrollDown);
    setCustomerID(customerID);
    addCustomerParam(customerID, tab);
  };
  const closeCustomerDrawer = () => {
    removeCustomerParam();
    setCustomerID(null);
  };
  return <CustomerDrawerContext.Provider value={{
    openCustomerDrawer,
    closeCustomerDrawer,
    asCompany,
    user,
    redirect,
    checkCompany,
    hasIntegration,
    hasPermission,
    logOut,
    setAsCompany,
    setToken,
    setTokenExpiry,
    hasMultiAccess,
    setUser,
    setRedirect,
    isValidToken
  }}>
      {children}
      <DrawerCustomer isOpen={!!customerID} customerID={customerID} onTabChange={tab => {
      setTabParam(tab);
      setStartTab(tab);
    }} onClose={() => {
      removeCustomerParam();
      setCustomerID(null);
      setStartTab('summary');
    }} startTab={startTab} startTransaction={customerTransaction} scrollToTransactionDetails={scrollToTransactionDetails} />
    </CustomerDrawerContext.Provider>;
};