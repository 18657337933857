export const getFilterKeys = filterOptions => {
  // TODO maybe we should make the filter options tell you all of the possible options?
  // This takes common date keys if they exist and turn them into _before/_after keys

  return (filterOptions || []).reduce((result, opt) => {
    if (opt.key === 'date' || opt.key === 'created' || opt.key === 'updated' || opt.key === 'overall_score') {
      result.push(`${opt.key}_before`);
      result.push(`${opt.key}_after`);
    } else {
      result.push(opt.key);
    }
    return result;
  }, ['search']);
};
export const getFiltersFromURL = filterOptions => {
  //this adds url params to active filters
  const params = {};
  const pageFilters = getFilterKeys(filterOptions);
  const queryParams = new URLSearchParams(window.location.search);
  for (let key of queryParams.keys()) {
    const inFilters = pageFilters.indexOf(key) > -1;
    if (inFilters) {
      params[key] = queryParams.get(key);
    } else {
      //check if key is a multi-select filter
      const subKey = key.substring(0, key.length - 3);
      const elementFilter = pageFilters.indexOf(subKey) > -1;
      if (elementFilter) {
        if (!params[subKey]) {
          params[subKey] = [];
        }
        params[subKey].push(queryParams.get(key));
      }
    }
  }
  return params;
};