import React, { useEffect, useState, useContext } from 'react';
import validator from 'validator';
import { Button, ModalDialog, DynamicTypeAhead } from 'components';
import { snackbarService } from 'components/mui/snackbar/snackbar-service';
import { getPhoneNumbers } from 'shared/common.api';
import { UserStateContext } from 'context/user-state-context';
export const ModalToolRedirectBuilder = ({
  open,
  onClose
}) => {
  const {
    asCompany
  } = useContext(UserStateContext);
  const [url, setUrl] = useState('');
  const [message, setMessage] = useState('');
  const [encodedURL, setEncodedUrl] = useState('');
  const [from, setFrom] = useState('');
  const [validURL, setValidURL] = useState('');
  useEffect(() => {
    if (!url || url === '') {
      setValidURL('');
    } else if (validator.isURL(url)) {
      setValidURL('This URL is valid.');
    } else {
      setValidURL('This URL is NOT valid.');
    }
    if (message && url && from?.phone && validator.isURL(url)) {
      const ios = `sms:${from.phone}%26body=${encodeURIComponent(message)}`;
      const android = `sms:${from.phone}?body=${encodeURIComponent(message)}`;
      setEncodedUrl(`https://engine.cinch.io/redirect?ios=${ios}&android=${android}&web=${url}`);
    }
  }, [url, message, from]);
  const handleEncodeClick = () => {
    navigator.clipboard.writeText(encodedURL);
    snackbarService.popup({
      message: 'Encoded Url copied to clipboard',
      horizontal: 'center'
    });
  };
  return <ModalDialog open={open} title="Redirect Builder" cancelText="Close" onClose={onClose} allowBackdropClick type="scroll">
    <div className="w-100">
      <p> This tool provides an easy way to build a redirect URL,
        which will redirect a customer based
        on the device (ios, android, web) they are using
      </p>
      <div className="modal__body">
        <div className="form-group">
          <label>To Phone Number</label>
          <DynamicTypeAhead getItems={getPhoneNumbers} getItemsFilters={{
            company: asCompany.id
          }} placeholder="Select a Phone Number" displayProperty="name" keyProperty="id" value={from?.id || null} onChange={from => setFrom(from)} />
        </div>
        <div className="form-group">
          <label>Text message body</label>
          <textarea style={{
            height: '6em'
          }} className="form-control" placeholder="Enter Message" onChange={e => setMessage(e.target.value)} value={message} />
        </div>
        <div className="form-group">
          <label>Webpage URL (ex: https://www.example.com)</label>
          <textarea className="form-control" style={{
            height: '2.5em'
          }} placeholder="Enter URL" onChange={e => setUrl(e.target.value)} value={url} />
          <label>{validURL}</label>
        </div>
        <div>
          <label>Your URL: (click to copy to clipboard)</label>

          <Button style={{
            width: '75%'
          }} onClick={handleEncodeClick}>
            <div style={{
              whiteSpace: 'wrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              width: '225px'
            }}>
              {encodedURL}
            </div>
          </Button>
        </div>
      </div>
    </div>
  </ModalDialog>;
};