import React, { Component } from 'react';
import { func, object, shape, string } from 'prop-types';
import { Button } from 'components/button/button.component';
import { Loader } from 'components/loader/loader.component';
import { toasterService } from 'components/toaster/toaster-service';
import { testSQL } from '../../../../shared/common.api';
export class ConditionAdvancedSQL extends Component {
  static propTypes = {
    update: func,
    node: object,
    data: shape({
      query: string
    })
  };
  state = {
    loading: false
  };
  static defaultProps = {
    query: ''
  };
  verifySql() {
    this.setState({
      loading: true
    });
    testSQL(this.props.data.query).then(() => {
      toasterService.success("Query validated successfully");
    }).catch(e => {
      toasterService.error(e.response.data.sql);
    }).then(() => {
      this.setState({
        loading: false
      });
    });
  }
  render() {
    if (!this.props || !this.props.data) return null;
    const {
      query
    } = this.props.data;
    const {
      loading
    } = this.state;
    return <div>
        <div className="form-group">
          <label>Query</label>
          <textarea style={{
          height: "100px"
        }} className="form-control" onChange={e => {
          this.props.update({
            query: e.target.value
          });
        }} value={query || ''} />
        </div>

        <div className="d-flex">
          <select required className="form-control mr-5" onChange={e => {
          this.props.update({
            query: `${query || ''}%(${e.target.value})s`
          });
        }} value="">
            <option value="" disabled={true}>Insert Variable Data</option>
            <option value="customer_id">Customer ID</option>
            <option value="transaction_id">Transaction ID</option>
            <option value="possession_id">Possession ID</option>
            <option value="schedule_id">Schedule ID</option>
            <option value="cart_id">Cart ID</option>
            <option value="session_id">Session ID</option>
          </select>
          <Button actionType="primary" onClick={() => this.verifySql()} disabled={loading}>
            Validate Query
            {loading && <Loader className="ml-3" size="sm" />}
          </Button>
        </div>
      </div>;
  }
}