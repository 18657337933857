import React, { useContext } from 'react';
import { PageHeaderMui } from 'components';
import { RunRTCCalculator, getRTCRule, updateRTCRule } from '../../shared/common.api';
import { Box, Button, CircularProgress, ClickAwayListener, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton, Paper, Popper, Table, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import CssBaseline from '@mui/material/CssBaseline';
import { UserStateContext } from 'context/user-state-context';
import { history } from '../../root.component';
import { CancelIcon, CheckCircleFilledIcon, FiberManualRecordIcon, HelpIcon, RepeatTransactionCalculatorIcon } from 'components/mui';
import { RTCStepOne } from './repeat-transaction-calculator-builder-step-one.component';
import { RTCStepTwo } from './repeat-transaction-calculator-builder-step-two.component';
import { RTCStepThree } from './repeat-transaction-calculator-builder-step-three.component';
export const RTCBuilder = props => {
  const {
    hasPermission
  } = useContext(UserStateContext);
  const [isLoading, setIsLoading] = React.useState(true);
  const [RTC, setRTC] = React.useState({});
  const [currentStep, setCurrentStep] = React.useState(0);
  const [helpAnchorEl, setHelpAnchorEl] = React.useState(null);
  const [helpData, setHelpData] = React.useState(null);
  const [stepOneValid, setStepOneValid] = React.useState(false);
  const [stepTwoValid, setStepTwoValid] = React.useState(false);
  const [stepThreeValid, setStepThreeValid] = React.useState(false);
  const [rulesQueryValid, setRulesQueryValid] = React.useState(false);
  const [showRuleErrors, setShowRuleErrors] = React.useState(false);
  const [redoCalculationModal, setRedoCalculationModal] = React.useState(null);
  React.useEffect(() => {
    setIsLoading(true);
    getRTCRule(props.match.params.id).then(data => {
      data.acceleratorActive = data.accelerator != 0 ? true : false;
      setRTC(data.data);
      if (history.location.state?.duplicate) {
        setCurrentStep(1);
      } else if (data.data.status === 'draft_1') {
        setCurrentStep(0);
      } else if (data.data.status === 'draft_2') {
        setCurrentStep(2);
      } else if (data.data.status === 'draft_3') {
        setCurrentStep(3);
      }
      setIsLoading(false);
    });
  }, []);
  const updateName = name => {
    updateRTCRule(props.match.params.id, {
      name: name.name
    }).then(data => {
      setRTC(data.data);
    });
  };
  const handleHelpPopupClick = e => {
    if (currentStep === 1) {
      setHelpData({
        title: <Typography variant="tableHeader"> Define your rule</Typography>,
        content: <>
            <Typography>
              Use your POS transaction data in the table below to group transactions together in the Calculations Rules to define the
              products or services you offer.
            </Typography>
            <br />
            <Typography sx={{
            fontStyle: 'italic'
          }}> i.e. Item Type = "XYZ" and Item Description constains "%123%" </Typography>
          </>
      });
    } else if (currentStep == 2) {
      setHelpData({
        title: <Typography variant="tableHeader"> Verify Customers</Typography>,
        content: <Typography>
            Review this sample of the 100 most recent transactions assoicated with the data you used to define your rules. Does this reflect
            your selected data?
          </Typography>
      });
    } else if (currentStep == 3) {
      setHelpData({
        title: <Typography variant="tableHeader"> Return Window</Typography>,
        content: <Typography>
            This step defines the variables and the window of time you are recommending your customers return for a second purchase.
          </Typography>
      });
    }
    setHelpAnchorEl(e.currentTarget);
  };
  const handleHelpClose = () => {
    setHelpAnchorEl(null);
    setHelpData(null);
  };
  const handleSubmitRule = () => {
    updateRTCRule(RTC.id, {
      ...RTC
    }).then(d => {
      if (d.data.status === 'completed') {
        setRedoCalculationModal(d);
      } else {
        runCalculation(d);
      }
    });
  };
  const runCalculation = d => {
    RunRTCCalculator(d.data.id).then(() => {
      history.push({
        pathname: '/repeat-transaction-calculator-list',
        state: {
          id: d.data.id,
          name: d.data.name
        }
      });
    });
  };
  const updateRTC = newRTC => {
    setRTC(newRTC);
  };
  const handleStepOneSubmit = () => {
    if (stepOneValid) {
      updateRTCRule(RTC.id, {
        ...RTC,
        status: RTC.status != 'completed' ? 'draft_2' : 'completed'
      }).then(d => {
        setRTC({
          ...RTC,
          ...d.data
        });
        setCurrentStep(2);
      });
    }
  };
  const handleStepTwoSubmit = () => {
    if (stepTwoValid) {
      updateRTCRule(RTC.id, {
        ...RTC,
        status: RTC.status != 'completed' ? 'draft_3' : 'completed'
      }).then(d => {
        setRTC({
          ...RTC,
          ...d.data
        });
        setCurrentStep(3);
      });
    }
  };
  React.useEffect(() => {
    if (!!RTC.builder && (currentStep != 1 || rulesQueryValid) || !!RTC.query) {
      setStepOneValid(true);
    } else {
      setStepOneValid(false);
    }
    if (RTC.sampleData?.length > 0) {
      setStepTwoValid(true);
    } else {
      setStepTwoValid(false);
    }
    if (!!RTC.optimal_value && !!RTC.min && !!RTC.max) {
      if (RTC?.acceleratorActive && (RTC.accelerator == 0 || RTC.acceleratorUnitType == 'percent' && RTC.accelerator > 0.999 || RTC.acceleratorUnitType == 'units' && RTC.type == 'days' && Number(RTC.accelerator) > Number(RTC.max) || RTC.type == 'days' && (Number(RTC.min) >= Number(RTC.optimal_value) || Number(RTC.max) <= Number(RTC.optimal_value)))) {
        setStepThreeValid(false);
      } else {
        setStepThreeValid(true);
      }
    } else {
      setStepThreeValid(false);
    }
  }, [RTC, rulesQueryValid]);
  const handleShowRuleErrors = () => {
    const timer = setTimeout(() => {
      setShowRuleErrors(false);
    }, 4000);
    return () => clearTimeout(timer);
  };
  return <>
      <CssBaseline />
      <div className="wrapper">
        <PageHeaderMui type="Repeat Transaction Calculator" name={RTC.name} updateName={updateName} updateNamePermission={hasPermission('repeat_transaction_calculator.change_transactioncalculatorrule')} icon={<Box sx={{
        opacity: '0.6'
      }}>
              <RepeatTransactionCalculatorIcon size="lg" />
            </Box>} />

        <div className="mui-wrapper" style={{
        height: '100%'
        //overflow: 'hidden',
      }}>
          {!isLoading ? <Box sx={{
          mt: '5px',
          height: '100%'
        }}>
              <Paper sx={{
            width: '100%',
            height: currentStep != 0 ? '100%' : 'auto'
          }}>
                {currentStep === 0 ? <Stack>
                    <Box sx={{
                border: '1px solid #E3E5E7',
                cursor: 'pointer'
              }} onClick={() => {
                setCurrentStep(1);
              }}>
                      <Box sx={{
                  py: '20px',
                  display: 'flex',
                  justifyContent: 'space-between'
                }}>
                        <Stack direction={'row'} spacing={'10px'} sx={{
                    display: 'flex',
                    alignItems: 'center',
                    pl: '54px'
                  }}>
                          <Typography sx={{
                      fontSize: '24px'
                    }}>1. Define Your Rule</Typography>
                        </Stack>
                        <Stack direction={'row'} spacing={'10px'} sx={{
                    pr: '20px'
                  }}>
                          <Button sx={{
                      width: '115px'
                    }} variant="outlined" onClick={() => history.push('/repeat-transaction-calculator-list')}>
                            Cancel
                          </Button>

                          <Button sx={{
                      width: '115px'
                    }} variant="contained" onClick={() => {
                      setCurrentStep(1);
                    }}>
                            <Typography sx={{
                        color: '#fff'
                      }}> Get Started</Typography>
                          </Button>
                        </Stack>
                      </Box>
                    </Box>

                    <Box sx={{
                border: '1px solid #E3E5E7'
              }}>
                      <Box sx={{
                  py: '30px',
                  display: 'flex',
                  justifyContent: 'space-between'
                }}>
                        <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    pl: '54px'
                  }}>
                          <Typography sx={{
                      fontSize: '24px',
                      opacity: 0.3
                    }}>2. Verify Customers</Typography>
                        </Box>
                      </Box>
                    </Box>

                    <Box sx={{
                border: '1px solid #E3E5E7'
              }}>
                      <Box sx={{
                  py: '30px',
                  display: 'flex',
                  justifyContent: 'space-between'
                }}>
                        <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    pl: '54px'
                  }}>
                          <Typography sx={{
                      fontSize: '24px',
                      opacity: 0.3
                    }}>3. Return Window</Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Stack> : <Stack sx={{
              height: '100%'
            }}>
                    <Box sx={{
                border: '1px solid #E3E5E7'
              }}>
                      <Box sx={{
                  py: currentStep === 1 ? '20px' : '26px',
                  display: 'flex',
                  justifyContent: 'space-between'
                }} onClick={() => setCurrentStep(1)}>
                        <Stack direction={'row'} spacing={'10px'} sx={{
                    display: 'flex',
                    alignItems: 'center',
                    pl: '20px'
                  }}>
                          {currentStep === 1 ? <FiberManualRecordIcon fill="#3898D9" /> : <CheckCircleFilledIcon fill="#3898D9" />}
                          <Typography sx={{
                      fontSize: '24px',
                      fontWeight: '300'
                    }}>1. Define Your Rule </Typography>
                          {currentStep === 1 && <Divider orientation="vertical" flexItem sx={{
                      borderColor: '#1D252D',
                      borderRightWidth: 2
                    }} />}
                          {currentStep === 1 && <Typography sx={{
                      fontSize: '20px'
                    }}>Tell us what POS data you want to use. </Typography>}

                          {currentStep === 1 && <IconButton onClick={e => handleHelpPopupClick(e)}>
                              <HelpIcon fill="#3898D9" size="sm" />
                            </IconButton>}
                        </Stack>

                        {currentStep === 1 && <Stack direction={'row'} spacing={'10px'} sx={{
                    pr: '20px'
                  }}>
                            <Button sx={{
                      width: '115px'
                    }} variant="outlined" onClick={() => history.push('/repeat-transaction-calculator-list')}>
                              Cancel
                            </Button>
                            <Box onClick={e => {
                      if (stepOneValid) {
                        e.stopPropagation();
                        handleStepOneSubmit();
                      } else {
                        setShowRuleErrors(true);
                        handleShowRuleErrors();
                      }
                    }}>
                              <Button disabled={!stepOneValid} sx={{
                        width: '115px'
                      }} variant="contained" onClick={e => {
                        if (stepOneValid) {
                          e.stopPropagation();
                          handleStepOneSubmit();
                        } else {
                          e.stopPropagation();
                          setShowRuleErrors(true);
                          handleShowRuleErrors();
                        }
                      }}>
                                Next
                              </Button>
                            </Box>
                          </Stack>}
                      </Box>
                    </Box>

                    {currentStep === 1 && !!RTC?.id && <RTCStepOne RTC={RTC} updateRTC={updateRTC} updateRulesQueryValid={e => {
                setRulesQueryValid(e);
              }} showRuleErrors={showRuleErrors} />}

                    <Box sx={{
                border: '1px solid #E3E5E7'
              }}>
                      <Box sx={{
                  py: currentStep === 2 ? '20px' : '26px',
                  display: 'flex',
                  justifyContent: 'space-between'
                }} onClick={() => {
                  if (stepOneValid) {
                    // setCurrentStep(2);
                    handleStepOneSubmit();
                  }
                }}>
                        <Stack direction={'row'} spacing={'10px'} sx={{
                    display: 'flex',
                    alignItems: 'center',
                    pl: '20px'
                  }}>
                          {currentStep === 2 ? <FiberManualRecordIcon fill="#3898D9" /> : currentStep == 1 ? <></> : <CheckCircleFilledIcon fill="#3898D9" />}
                          <Typography sx={{
                      fontSize: '24px',
                      fontWeight: '300',
                      opacity: stepOneValid ? 1 : 0.3
                    }}>
                            2. Verify Customers
                          </Typography>
                          {currentStep === 2 && <Divider orientation="vertical" flexItem sx={{
                      borderColor: '#1D252D',
                      borderRightWidth: 2
                    }} />}
                          {currentStep === 2 && <Typography sx={{
                      fontSize: '20px'
                    }}>Do these customers match what you expected? </Typography>}

                          {currentStep === 2 && <IconButton onClick={e => handleHelpPopupClick(e)}>
                              <HelpIcon fill="#3898D9" size="sm" />
                            </IconButton>}
                        </Stack>

                        {currentStep === 2 && <Stack direction={'row'} spacing={'10px'} sx={{
                    pr: '20px'
                  }}>
                            <Button sx={{
                      width: '115px'
                    }} variant="outlined" onClick={() => history.push('/repeat-transaction-calculator-list')}>
                              Cancel
                            </Button>
                            <Button sx={{
                      width: '115px'
                    }} variant="outlined" onClick={e => {
                      e.stopPropagation();
                      setCurrentStep(1);
                    }}>
                              Previous
                            </Button>
                            <Button disabled={!stepTwoValid} sx={{
                      width: '115px'
                    }} variant="contained" onClick={e => {
                      e.stopPropagation();
                      handleStepTwoSubmit();
                    }}>
                              Next
                            </Button>
                          </Stack>}
                      </Box>
                    </Box>

                    {currentStep === 2 && !!RTC?.id && <RTCStepTwo RTC={RTC} updateRTC={updateRTC} />}

                    {(currentStep === 2 || currentStep === 3 || currentStep === 4) && <Box sx={{
                border: '1px solid #E3E5E7'
              }}>
                        <Box sx={{
                  py: currentStep === 3 ? '20px' : '26px',
                  display: 'flex',
                  justifyContent: 'space-between'
                }} onClick={() => {
                  if (stepTwoValid) {
                    // setCurrentStep(3);
                    handleStepTwoSubmit();
                  }
                }}>
                          <Stack direction={'row'} spacing={'10px'} sx={{
                    display: 'flex',
                    alignItems: 'center',
                    pl: '20px'
                  }}>
                            {currentStep === 3 ? <FiberManualRecordIcon fill="#3898D9" /> : currentStep == 2 ? <></> : <CheckCircleFilledIcon fill="#3898D9" />}
                            <Typography sx={{
                      fontSize: '24px',
                      fontWeight: '300',
                      opacity: stepTwoValid ? 1 : 0.3
                    }}>
                              3. Return Window
                            </Typography>
                            {currentStep === 3 && <Divider orientation="vertical" flexItem sx={{
                      borderColor: '#1D252D',
                      borderRightWidth: 2
                    }} />}
                            {currentStep === 3 && <Typography sx={{
                      fontSize: '20px'
                    }}>When would you expect these customers to return? </Typography>}

                            {currentStep === 3 && <IconButton onClick={e => handleHelpPopupClick(e)}>
                                <HelpIcon fill="#3898D9" size="sm" />
                              </IconButton>}
                          </Stack>

                          {currentStep === 3 && <Stack direction={'row'} spacing={'10px'} sx={{
                    pr: '20px'
                  }}>
                              <Button sx={{
                      width: '115px'
                    }} variant="outlined" onClick={() => history.push('/repeat-transaction-calculator-list')}>
                                Cancel
                              </Button>
                              <Button sx={{
                      width: '115px'
                    }} variant="outlined" onClick={e => {
                      e.stopPropagation();
                      setCurrentStep(2);
                    }}>
                                Previous
                              </Button>
                              <Button disabled={!stepThreeValid} sx={{
                      width: '115px'
                    }} variant="contained" onClick={e => {
                      e.stopPropagation();
                      handleSubmitRule();
                    }}>
                                Calculate
                              </Button>
                            </Stack>}
                        </Box>
                      </Box>}

                    {currentStep === 3 && !!RTC?.id && <RTCStepThree RTC={RTC} updateRTC={updateRTC} />}
                  </Stack>}
              </Paper>
            </Box> : <Paper sx={{
          height: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
              <Box>
                <CircularProgress />
              </Box>
            </Paper>}
        </div>
      </div>

      <Popper anchorEl={helpAnchorEl} open={!!helpData} onClose={handleHelpClose} placement="bottom-start">
        <ClickAwayListener onClickAway={handleHelpClose}>
          <Paper elevation={0} sx={{
          borderRadius: '16px',
          overflow: 'visible',
          filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))',
          width: '312px'
        }}>
            <Stack>
              <Box sx={{
              padding: '16px'
            }}>{helpData?.title} </Box>
              <Box sx={{
              px: '16px'
            }}> {helpData?.content}</Box>
              <Box sx={{
              py: '16px'
            }}>
                <Button sx={{
                display: 'flex',
                justifyItems: 'left',
                textTransform: 'none'
              }} onClick={handleHelpClose}>
                  {helpData?.buttonText || ' Got it'}
                </Button>
              </Box>
            </Stack>
          </Paper>
        </ClickAwayListener>
      </Popper>

      <Dialog sx={{
      fontFamily: 'Nexa',
      color: '#1D252D'
    }} PaperProps={{
      style: {
        borderRadius: '28px',
        width: '360px '
      }
    }} open={!!redoCalculationModal} onClose={() => {
      setRedoCalculationModal(null);
    }}>
        <DialogTitle className="d-flex w-100 justify-content-between" sx={{
        m: 0,
        px: 0,
        py: 1
      }}>
          <Stack spacing={'20px'} sx={{
          pt: '10px',
          width: '100%',
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center'
        }}>
            <CancelIcon fill="#F30F0F" />
            <Typography sx={{
            fontSize: 24
          }}>Redo Calculation?</Typography>
          </Stack>
        </DialogTitle>

        <DialogContent dividers>
          <DialogContentText>
            <Stack component="span"></Stack>
          </DialogContentText>
          <Stack spacing="10px">
            <Typography>
              Making any changes to the existing parameters will delete all data associated with this record and you will need to begin a
              new calculation.
            </Typography>
          </Stack>
        </DialogContent>

        <DialogActions sx={{
        py: 3
      }}>
          <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
            <Box />
            <Stack direction="row" spacing="10px">
              <Button variant="text" onClick={e => {
              setRedoCalculationModal(null);
            }} sx={{
              px: 2,
              py: 1
            }}>
                <Typography sx={{
                textTransform: 'none'
              }}> Cancel</Typography>
              </Button>
              <Button onClick={e => {
              runCalculation(redoCalculationModal);
              setRedoCalculationModal(null);
            }} variant="text" sx={{
              px: 2,
              py: 1
            }}>
                <Typography sx={{
                textTransform: 'none',
                color: '#3898D9'
              }}> Accept</Typography>
              </Button>
            </Stack>
          </Box>
        </DialogActions>
      </Dialog>
    </>;
};