import React, { useState, useEffect, useContext } from 'react';
import { PageHeaderMui } from 'components';
import { duplicateRTCRule, getRTCResults, getRTCRule, updateRTCRule } from 'shared/common.api';
import { UserStateContext } from 'context/user-state-context';
import CssBaseline from '@mui/material/CssBaseline';
import { MoreVertIcon, RepeatTransactionCalculatorIcon, SearchIcon, CloseIcon, CustomerIcon, CustomersIcon, EventNoteIcon, SpeedIcon, EditIcon, DeleteIcon, CancelIcon } from 'components/mui';
import { NewButton } from 'components/mui/button/new.component';
import { Box, CircularProgress, IconButton, Paper, Stack, Tooltip, Typography, Divider, TextField, InputAdornment, Table, TableHead, TableCell, TableBody, TableRow, Skeleton, Popper, Button, ClickAwayListener, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TablePagination } from '@mui/material';
import { withStyles } from '@mui/styles';
import utils from 'shared/utils';
import moment from 'moment-timezone';
import { LocationTooltip } from 'components/mui/tooltip/location-tooltip.component';
import { history } from '../../root.component';
import { CustomerDrawerContext } from 'context/customer-drawer-context';
const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      padding: 0,
      backgroundColor: 'white',
      borderRadius: `14px`,
      '& fieldset': {
        border: 'none',
        padding: 0
      }
    },
    '& .MuiOutlinedInput-input': {
      padding: '10px'
    }
  }
})(TextField);
export const RepeatTransactionCalculatorResults = props => {
  const {
    hasPermission,
    asCompany
  } = useContext(UserStateContext);
  const {
    openCustomerDrawer
  } = useContext(CustomerDrawerContext);
  const [isLoading, setIsLoading] = useState(false);
  const [resultsData, setResultsData] = useState([]);
  const [searchOpen, setSearchOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [ruleData, setRuleData] = useState({});
  const [optionsMenuOpen, setOptionsMenuOpen] = useState(false);
  const [optionsAnchorEl, setOptionsAnchorEl] = useState(null);
  const [redoCalculationModal, setRedoCalculationModal] = useState(false);
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);
  const [onHover, setOnHover] = useState(null);
  const optionsMenu = [{
    label: 'Edit Calculation',
    onClick: () => {
      setRedoCalculationModal(true);
    },
    icon: <EditIcon />,
    key: 'edit'
  }, {
    label: 'Delete Calculation',
    onClick: () => {
      setDeleteConfirmModal(true);
    },
    icon: <DeleteIcon />,
    key: 'delete'
  }];
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  useEffect(() => {
    const resultsId = props.match.params.id;
    setIsLoading(true);
    const promises = [];
    promises.push(getRTCResults({
      ordering: '-last_transaction__transaction_when',
      limit: rowsPerPage,
      offset: page * rowsPerPage,
      company: asCompany.id,
      rule: resultsId,
      search: search || ''
    }));
    promises.push(getRTCRule(resultsId));
    Promise.all(promises).then(([resultsData, ruleData]) => {
      setResultsData(resultsData?.data);
      setRuleData(ruleData?.data);
      setIsLoading(false);
    });
  }, [search, page]);
  const updateRTCName = name => {
    updateRTCRule(props.match.params.id, {
      name: name.name
    }).then(data => {
      setRuleData(data.data);
    });
  };
  const handleSearchOpenClick = () => {
    setSearchOpen(!searchOpen);
  };
  const handleNewButtonClick = event => {
    history.push({
      pathname: '/create-segment',
      state: {
        rtcRule: {
          ...ruleData
        }
      }
    });
  };
  const handleRowItemClick = row => {
    openCustomerDrawer(row.customer, 'transactions');
  };
  const makeReturnDateTooltip = (date, last_transaction_date) => {
    if (!date) {
      return 'N/A';
    }
    const days = moment(date).diff(moment(last_transaction_date), 'days');
    return <Tooltip title={moment(date).local().format('MMM D, YYYY h:mm A')}>
        <Typography>{days} Days</Typography>
      </Tooltip>;
  };
  const handleOptionsClose = () => {
    setOptionsMenuOpen(false);
    setOptionsAnchorEl(null);
  };
  const handleOptionsClick = e => {
    setOptionsAnchorEl(e.currentTarget);
    setOptionsMenuOpen(true);
  };
  const handleRedoCalculation = () => {
    updateRTCRule(props.match.params.id, {
      status: 'draft_1'
    }).then(data => {
      history.push(`/repeat-transaction-calculator-builder/${data.data.id}`, {
        duplicate: true
      });
    });
  };
  const handleDeleteRTC = () => {
    updateRTCRule(props.match.params.id, {
      deleted_when: moment()
    }).then(() => {
      history.push(`/repeat-transaction-calculator-list`);
    });
  };
  return <>
      <CssBaseline />
      <div className="wrapper">
        <PageHeaderMui type="Repeat Transaction Calculator Results" icon={<Box sx={{
        opacity: '0.6'
      }}>
              <RepeatTransactionCalculatorIcon size="lg" />
            </Box>} name={ruleData.name} updateName={updateRTCName} updateNamePermission={hasPermission('repeat_transaction_calculator.change_transactioncalculatorrule')} />

        <div className="mui-wrapper">
          <Box sx={{
          p: '20px'
        }}>
            <Stack spacing={'20px'}>
              <Paper sx={{
              height: '72px',
              borderRadius: '14px'
            }}>
                <Stack spacing={'20px'} direction={'row'} sx={{
                height: '100%',
                p: '16px',
                display: 'flex',
                alignItems: 'center'
              }}>
                  <Typography fontSize={'20px'}>Calculation Data:</Typography>
                  {isLoading ? <Stack spacing={'10px'} direction={'row'} sx={{
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center'
                }}>
                      <Skeleton width={'130px'} />
                    </Stack> : <Stack spacing={'10px'} direction={'row'} sx={{
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center'
                }}>
                      <CustomersIcon />
                      <Typography variant="tableHeader"> Customers:</Typography>
                      <Typography> {resultsData?.count || 0}</Typography>
                    </Stack>}
                  <Divider orientation="vertical" sx={{
                  height: '24px'
                }} />
                  {isLoading ? <Stack spacing={'10px'} direction={'row'} sx={{
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center'
                }}>
                      <Skeleton width={'130px'} />
                    </Stack> : <Stack spacing={'10px'} direction={'row'} sx={{
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center'
                }}>
                      <EventNoteIcon fill="#3898D9" />
                      <Typography variant="tableHeader"> Min Days:</Typography>
                      <Typography> {ruleData?.min || 0}</Typography>
                    </Stack>}
                  <Divider orientation="vertical" sx={{
                  height: '24px'
                }} />
                  {isLoading ? <Stack spacing={'10px'} direction={'row'} sx={{
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center'
                }}>
                      <Skeleton width={'130px'} />
                    </Stack> : <Stack spacing={'10px'} direction={'row'} sx={{
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center'
                }}>
                      <EventNoteIcon fill="#FAB021" />
                      <Typography variant="tableHeader"> Max Days:</Typography>
                      <Typography> {ruleData?.max || 0}</Typography>
                    </Stack>}

                  <Divider orientation="vertical" sx={{
                  height: '24px'
                }} />
                  {isLoading ? <Stack spacing={'10px'} direction={'row'} sx={{
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center'
                }}>
                      <Skeleton width={'130px'} />
                    </Stack> : <Stack spacing={'10px'} direction={'row'} sx={{
                  height: '100%',
                  p: '16px',
                  display: 'flex',
                  alignItems: 'center'
                }}>
                      <SpeedIcon fill="#6C9" />
                      <Typography variant="tableHeader"> Target Return:</Typography>
                      <Typography>
                        {ruleData?.optimal_value || 0} {ruleData?.type || ''}
                      </Typography>
                    </Stack>}
                </Stack>
              </Paper>
              <Paper sx={{
              borderRadius: '14px'
            }}>
                <Stack>
                  <Box sx={{
                  paddingY: '8px',
                  paddingX: '24px',
                  borderBottom: '1px solid #E3E5E7',
                  borderTop: '1px solid #E3E5E7',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}>
                    <Box />
                    {isLoading ? <Box>
                        <CircularProgress sx={{
                      color: '#FFB82B'
                    }} size={'20px'} />
                      </Box> : <Box />}
                    <Stack spacing={'20px'} direction={'row'}>
                      <Divider orientation="vertical" sx={{
                      height: '32px'
                    }} />
                      {searchOpen ? <Box sx={{
                      height: '42px'
                    }}>
                          <CustomTextField id="outlined-basic" autoFocus={true} placeholder={'Search'} sx={{
                        width: '290px',
                        height: '56px',
                        pr: '20px',
                        py: 0
                      }} onChange={e => setSearch(e.target.value || '')} value={search || ''} InputProps={{
                        startAdornment: <InputAdornment position="start">
                                  <Tooltip title="Collapse Search">
                                    <IconButton sx={{
                              width: '40px',
                              height: '40px',
                              textAlign: 'center',
                              padding: 0,
                              backgroundColor: '#fff',
                              color: '#1D252D',
                              ':hover': {
                                backgroundColor: '#DAEFFF',
                                color: '#53A6D6'
                              }
                            }} onClick={handleSearchOpenClick}>
                                      <SearchIcon />
                                    </IconButton>
                                  </Tooltip>
                                </InputAdornment>,
                        endAdornment: !!search && <InputAdornment onClick={e => {
                          setSearch('');
                        }} position="start" sx={{
                          cursor: 'pointer'
                        }}>
                                  <CloseIcon />
                                </InputAdornment>
                      }} />
                        </Box> : <Tooltip title={'Search'}>
                          <IconButton sx={{
                        textAlign: 'center',
                        padding: 0,
                        backgroundColor: '#fff',
                        color: '#1D252D',
                        ':hover': {
                          backgroundColor: '#DAEFFF',
                          color: '#53A6D6'
                        }
                      }} onClick={handleSearchOpenClick}>
                            <SearchIcon />
                          </IconButton>
                        </Tooltip>}
                      <Divider orientation="vertical" sx={{
                      height: '32px'
                    }} />

                      <IconButton onClick={e => {
                      handleOptionsClick(e);
                    }}>
                        <MoreVertIcon />
                      </IconButton>
                      <Divider orientation="vertical" sx={{
                      height: '32px'
                    }} />

                      {isLoading ? <Skeleton width={'130px'} /> : <TablePagination sx={{
                      '& .MuiTablePagination-displayedRows': {
                        margin: '0px'
                      },
                      '& .MuiTablePagination-toolbar': {
                        height: '40px',
                        minHeight: '40px',
                        maxHeight: '40px',
                        padding: '0px'
                      },
                      overflow: 'hidden',
                      border: '0px solid #fff',
                      p: 0
                    }} count={resultsData?.count} rowsPerPage={rowsPerPage} page={page} onPageChange={handleChangePage} rowsPerPageOptions={[5]} nextIconButtonProps={{
                      style: {
                        display: resultsData?.count > rowsPerPage ? 'inline' : 'none'
                      }
                    }} backIconButtonProps={{
                      style: {
                        display: resultsData?.count > rowsPerPage ? 'inline' : 'none'
                      }
                    }} />}

                      <Divider orientation="vertical" sx={{
                      height: '32px'
                    }} />

                      {Object.keys(ruleData).length > 0 && <Box sx={{
                      py: '4px'
                    }}>
                          <NewButton label="Add to Segment" onClick={e => {
                        handleNewButtonClick(e);
                      }} />
                        </Box>}
                    </Stack>
                  </Box>
                  <Box sx={{
                  height: '100%'
                }}>
                    <Table>
                      <colgroup>
                        <col style={{
                        width: '16%'
                      }} />
                        <col style={{
                        width: '16%'
                      }} />
                        <col style={{
                        width: '16%'
                      }} />
                        <col style={{
                        width: '16%'
                      }} />
                        <col style={{
                        width: '16%'
                      }} />
                        <col style={{
                        width: '16%'
                      }} />
                      </colgroup>
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{
                          py: '12px'
                        }}>
                            <Typography variant="tableHeader">Name</Typography>
                          </TableCell>
                          <TableCell sx={{
                          py: '12px'
                        }}>
                            <Typography variant="tableHeader">Email </Typography>
                          </TableCell>
                          <TableCell sx={{
                          py: '12px'
                        }}>
                            <Typography variant="tableHeader">Phone </Typography>
                          </TableCell>
                          <TableCell sx={{
                          py: '12px'
                        }}>
                            <Typography variant="tableHeader">Last Transaction Date </Typography>
                          </TableCell>
                          <TableCell sx={{
                          py: '12px'
                        }}>
                            <Typography variant="tableHeader">Location </Typography>
                          </TableCell>
                          <TableCell sx={{
                          py: '12px'
                        }}>
                            <Typography variant="tableHeader">Target Return </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      {isLoading ? <TableBody>
                          {Array(3).fill(null).map((row, index) => <TableRow key={index}>
                                {Array(6).fill(null).map((r, i) => <TableCell key={index + i}>
                                      <Box>
                                        <Skeleton />
                                      </Box>
                                    </TableCell>)}
                              </TableRow>)}
                        </TableBody> : resultsData?.results?.length > 0 ? <TableBody>
                          {resultsData?.results?.map((row, index) => <TableRow hover onMouseEnter={() => setOnHover(row.id)} onMouseLeave={() => setOnHover(null)} sx={{
                        cursor: 'pointer'
                      }} key={row.id} onClick={e => {
                        handleRowItemClick(row);
                      }}>
                              <TableCell>{row.customer_full_name || 'N/A'}</TableCell>
                              <TableCell>{row.email || 'N/A'}</TableCell>
                              <TableCell>{row.phone ? utils.formatPhoneNumber(row.phone) : 'N/A'}</TableCell>
                              <TableCell>
                                {row.last_transaction_date ? moment(row.last_transaction_date).local().format('MMM D, YYYY h:mm A') : 'N/A'}
                              </TableCell>
                              <TableCell>
                                <LocationTooltip location={row.full_location} />
                              </TableCell>
                              <TableCell sx={{
                          py: 0
                        }}>
                                <Stack direction={'row'} spacing={'20px '} sx={{
                            display: 'flex',
                            alignContent: 'center',
                            alignItems: 'center'
                          }}>
                                  {makeReturnDateTooltip(row.estimated_return_date, row.last_transaction_date)}
                                  {onHover === row.id ? <Tooltip title="View Customer">
                                      <IconButton onClick={e => {
                                e.stopPropagation();
                                openCustomerDrawer(row.customer, 'transactions');
                              }}>
                                        <CustomerIcon />
                                      </IconButton>
                                    </Tooltip> : <Box sx={{
                              width: '40px'
                            }} />}
                                </Stack>
                              </TableCell>
                            </TableRow>)}
                        </TableBody> : <TableBody>
                          <TableRow>
                            <TableCell />
                            <TableCell>
                              <Typography>No customer data was found</Typography>
                            </TableCell>
                            <TableCell />
                            <TableCell />
                          </TableRow>
                        </TableBody>}
                    </Table>
                  </Box>
                </Stack>
              </Paper>
            </Stack>
          </Box>
        </div>
      </div>
      <Popper anchorEl={optionsAnchorEl} open={optionsMenuOpen} onClose={handleOptionsClose}>
        <ClickAwayListener onClickAway={handleOptionsClose}>
          <Paper elevation={0} sx={{
          borderRadius: '16px',
          filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))'
        }}>
            <Stack>
              {optionsMenu.map(item => <Button key={item.key} sx={{
              width: '100%',
              height: '48px',
              borderRadius: '16px',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              padding: '0 16px',
              ':hover': {
                backgroundColor: '#DAEFFF',
                color: '#53A6D6'
              }
            }} onClick={() => {
              item.onClick();
              handleOptionsClose();
            }}>
                  {item.icon}
                  <Typography sx={{
                textTransform: 'none',
                ml: '16px'
              }}>{item.label}</Typography>
                </Button>)}
            </Stack>
          </Paper>
        </ClickAwayListener>
      </Popper>

      <Dialog sx={{
      fontFamily: 'Nexa',
      color: '#1D252D'
    }} PaperProps={{
      style: {
        borderRadius: '28px',
        width: '360px '
      }
    }} open={!!redoCalculationModal} onClose={(event, reason) => {
      setRedoCalculationModal(false);
    }}>
        <DialogTitle className="d-flex w-100 justify-content-between" sx={{
        m: 0,
        px: 0,
        py: 1
      }}>
          <Stack spacing={'20px'} sx={{
          pt: '10px',
          width: '100%',
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center'
        }}>
            <CancelIcon fill="#F30F0F" />
            <Typography sx={{
            fontSize: 24
          }}>Redo Calculation?</Typography>
          </Stack>
        </DialogTitle>

        <DialogContent dividers>
          <DialogContentText>
            <Stack component="span"></Stack>
          </DialogContentText>
          <Stack spacing="10px">
            <Typography>
              Making any changes to the existing parameters will delete all data associated with this record and you will need to begin a
              new calculation.
            </Typography>
          </Stack>
        </DialogContent>

        <DialogActions sx={{
        py: 3
      }}>
          <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
            <Box />
            <Stack direction="row" spacing="10px">
              <Button variant="text" onClick={e => {
              setRedoCalculationModal(false);
            }} sx={{
              px: 2,
              py: 1
            }}>
                <Typography sx={{
                textTransform: 'none'
              }}> Cancel</Typography>
              </Button>
              <Button onClick={e => {
              handleRedoCalculation();
              setRedoCalculationModal(false);
            }} variant="text" sx={{
              px: 2,
              py: 1
            }}>
                <Typography sx={{
                textTransform: 'none',
                color: '#3898D9'
              }}> Accept</Typography>
              </Button>
            </Stack>
          </Box>
        </DialogActions>
      </Dialog>

      <Dialog sx={{
      fontFamily: 'Nexa',
      color: '#1D252D'
    }} PaperProps={{
      style: {
        borderRadius: '28px',
        width: '312px '
      }
    }} open={!!deleteConfirmModal} onClose={(event, reason) => {
      setDeleteConfirmModal(false);
    }}>
        <DialogTitle className="d-flex w-100 justify-content-between" sx={{
        m: 0,
        px: 0,
        py: 1
      }}>
          <Stack spacing={'20px'} sx={{
          width: '100%',
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center'
        }}>
            <CancelIcon fill="#F30F0F" />
            <Typography sx={{
            fontSize: 24
          }}>Delete Calculation?</Typography>
          </Stack>
        </DialogTitle>

        <DialogContent dividers>
          <DialogContentText>
            <Stack component="span"></Stack>
          </DialogContentText>
          <Stack spacing="10px">
            <Typography>Are you sure you want to delete these results? This action cannot be undone.</Typography>
          </Stack>
        </DialogContent>

        <DialogActions sx={{
        py: 3
      }}>
          <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
            <Box />
            <Stack direction="row" spacing="10px">
              <Button variant="text" onClick={e => {
              setDeleteConfirmModal(false);
            }} sx={{
              px: 2,
              py: 1
            }}>
                <Typography sx={{
                textTransform: 'none'
              }}> Cancel</Typography>
              </Button>
              <Button onClick={e => {
              handleDeleteRTC();
            }} variant="text" sx={{
              px: 2,
              py: 1
            }}>
                <Typography sx={{
                textTransform: 'none',
                color: '#3898D9'
              }}> Delete</Typography>
              </Button>
            </Stack>
          </Box>
        </DialogActions>
      </Dialog>
    </>;
};