import React, { useEffect, useState, useContext } from 'react';
import moment from 'moment-timezone';
import copy from 'copy-to-clipboard';
import { snackbarService } from 'components/mui/snackbar/snackbar-service';
import { Table, TableHead, TableRow, TableCell, TableBody, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper, Stack, TextField, Tooltip, Typography, Divider, FormControl, Select, MenuItem, InputLabel, Skeleton, Popper, ClickAwayListener, Switch, InputAdornment, Chip } from '@mui/material';
import { DeleteIcon, HelpIcon } from 'components/mui';
import { getApiKeys, deleteApiKey } from 'shared/common.api';
import { ModalContext } from 'context/modal-context';
import { ApiKeyModal } from './api-key-modal.component';
import { UserStateContext } from 'context/user-state-context';
export const ApiKeysMui = ({
  companyId
}) => {
  const [keys, setKeys] = useState();
  const {
    show,
    confirm,
    alert
  } = useContext(ModalContext);
  const {
    hasPermission
  } = useContext(UserStateContext);
  const [helpAnchorEl, setHelpAnchorEl] = React.useState(null);
  const [helpData, setHelpData] = React.useState(null);
  useEffect(() => {
    if (!companyId) {
      return;
    }

    // Load API tokens
    getApiKeys(companyId).then(({
      data
    }) => {
      setKeys(data);
    });
  }, [companyId]);
  const generateKey = () => {
    show(ApiKeyModal, {
      companyId
    }).then(data => {
      // TODO Show modal with token
      alert({
        message: <>
              <p>The following API key has been generated. This is the only time it will be shown so keep it in a safe place:</p>
              <code style={{
            display: 'block',
            width: '100%',
            padding: '10px 5px'
          }} onClick={() => {
            if (copy(data.token)) {
              snackbarService.popup({
                type: 'success',
                message: `API key copied to clipboard`
              });
            }
          }}>
                {data.token}
              </code>
            </>,
        title: 'API Key Generated'
      }).catch(() => {});
      // Load API tokens
      getApiKeys(companyId).then(({
        data
      }) => {
        setKeys(data);
      });
    }).catch(() => {});
  };
  const confirmDeleteApiKey = key => {
    confirm({
      title: 'Delete API Key',
      message: `Are you sure you want to delete this API key?  This action cannot be undone and the key will immediately stop working.`,
      submitText: 'Delete'
    }).then(() => {
      deleteApiKey(companyId, key.id).then(() => {
        getApiKeys(companyId).then(({
          data
        }) => {
          setKeys(data);
        });
      });
    }).catch(e => {
      console.error(e);
    });
  };
  const handleHelpPopupClick = (e, index) => {
    if (index == 'company_api_keys') {
      setHelpData({
        title: <Typography variant="tableHeader"> API Keys</Typography>,
        content: <Stack spacing={'20px'}>
            <Typography component="div">
              <Box fontWeight="700" display="inline" sx={{
              pr: '3px'
            }}>
                API Keys:
              </Box>
              {/*               TODO: api helper text */}
              Some api help text
            </Typography>
          </Stack>
      });
    }
    setHelpAnchorEl(e.currentTarget);
  };
  const handleHelpClose = () => {
    setHelpAnchorEl(null);
    setHelpData(null);
  };
  return <>
      <Paper sx={{
      width: '65vw',
      maxWidth: '1200px',
      borderRadius: '14px'
    }}>
        <Stack direction="row" spacing={'10px'}>
          <Box sx={{
          py: '20px',
          pl: '20px',
          display: 'flex',
          alignItems: 'center'
        }}>
            <Typography sx={{
            fontSize: '20px',
            fontWeight: '400',
            lineHeight: '16.8px'
          }}>API Keys</Typography>
          </Box>
          <Box sx={{
          display: 'flex',
          alignItems: 'center'
        }}>
            {/* <IconButton onClick={(e) => handleHelpPopupClick(e, 'company_api_keys')}>
              <HelpIcon fill="#3898D9" size="sm" />
             </IconButton> */}
          </Box>
        </Stack>
        <Stack spacing="20px" sx={{
        pb: '20px'
      }}>
          <div className="row">
            <div className="col-12">
              <Table sx={{
              backgroundColor: '#fff'
            }}>
                <TableHead>
                  <TableRow>
                    <TableCell width="25%">Name</TableCell>
                    <TableCell width="25%">Role</TableCell>
                    <TableCell width="25%">Last Used</TableCell>
                    <TableCell width="25%">Expiration</TableCell>
                    <TableCell>&nbsp;</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {keys === undefined && <TableRow>
                      <TableCell colSpan={5}>
                        <em>Loading...</em>
                      </TableCell>
                    </TableRow>}

                  {keys?.length === 0 && <TableRow>
                      <TableCell colSpan={5}>
                        <em>No API Keys</em>
                      </TableCell>
                    </TableRow>}

                  {keys?.map(key => <TableRow key={key.id}>
                      <TableCell>{key.name}</TableCell>
                      <TableCell>{key.role}</TableCell>
                      <TableCell>
                        {key.last_used_when ? moment(key.last_used_when).local().format('MMM D, YYYY h:mm A') : <em>Never</em>}
                      </TableCell>
                      <TableCell>
                        {key.expires_when ? moment(key.expires_when).local().format('MMM D, YYYY h:mm A') : <em>Never</em>}
                      </TableCell>
                      <TableCell>
                        {hasPermission('organization.change_company') && <IconButton onClick={() => confirmDeleteApiKey(key)}>
                            <DeleteIcon />
                          </IconButton>}
                      </TableCell>
                    </TableRow>)}
                </TableBody>
              </Table>
            </div>
          </div>
          {hasPermission('organization.change_company') && <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
              <Button sx={{
            width: '200px'
          }} onClick={generateKey}>
                Generate API Key
              </Button>
            </Box>}
        </Stack>
      </Paper>

      <Popper anchorEl={helpAnchorEl} open={!!helpData} onClose={handleHelpClose} placement="right-start" sx={{
      zIndex: 2100
    }}>
        <ClickAwayListener onClickAway={handleHelpClose}>
          <Paper elevation={0} sx={{
          borderRadius: '16px',
          overflow: 'visible',
          filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))',
          width: '312px'
        }}>
            <Stack>
              <Box sx={{
              padding: '16px'
            }}>{helpData?.title} </Box>
              <Box sx={{
              px: '16px'
            }}> {helpData?.content}</Box>
              <Box sx={{
              py: '16px'
            }}>
                <Button sx={{
                display: 'flex',
                justifyItems: 'left',
                textTransform: 'none'
              }} onClick={handleHelpClose}>
                  {helpData?.buttonText || ' Got it'}
                </Button>
              </Box>
            </Stack>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>;
};