import React from 'react';
function Icon() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <mask id="mask0_8_150" style={{
      maskType: 'alpha'
    }} width="24" height="24" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0 0H24V24H0z"></path>
      </mask>
      <g mask="url(#mask0_8_150)">
        <path fill="#1D252D" d="M9.577 17.75c-.505 0-.936-.175-1.292-.525a1.73 1.73 0 01-.535-1.283V4.558c0-.505.178-.933.535-1.283.356-.35.787-.525 1.292-.525h8.365c.499 0 .925.177 1.278.53.353.353.53.779.53 1.278v11.384c0 .499-.177.925-.53 1.278-.353.353-.779.53-1.278.53H9.577zm0-1.5h8.365a.296.296 0 00.226-.087.31.31 0 00.082-.22V4.557a.306.306 0 00-.082-.226.306.306 0 00-.226-.082H9.577a.345.345 0 00-.23.082.28.28 0 00-.097.226v11.384c0 .09.032.164.096.221a.333.333 0 00.23.087zm-3.52 5c-.498 0-.924-.177-1.277-.53a1.741 1.741 0 01-.53-1.278V7.5c0-.213.072-.391.215-.535A.726.726 0 015 6.75c.213 0 .391.072.535.215a.726.726 0 01.215.535v11.942c0 .077.032.148.096.212a.294.294 0 00.212.096H15c.213 0 .391.072.535.215a.726.726 0 01.215.535.725.725 0 01-.215.535.726.726 0 01-.535.215H6.058z"></path>
      </g>
    </svg>;
}
export default Icon;