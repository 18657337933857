import React from "react";
function Icon() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none" viewBox="0 0 24 25">
      <mask id="mask0_143_1512" style={{
      maskType: "alpha"
    }} width="24" height="25" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0 0.031H24V24.031H0z"></path>
      </mask>
      <g mask="url(#mask0_143_1512)">
        <path fill="#1D252D" d="M14.125 2.756c2.15.5 3.917 1.604 5.3 3.312 1.383 1.709 2.075 3.696 2.075 5.963 0 1.483-.308 2.854-.925 4.113-.617 1.258-1.5 2.354-2.65 3.287h1.95a.73.73 0 01.538.212.731.731 0 01.212.538c0 .217-.07.396-.212.538a.731.731 0 01-.538.212H16.15a.869.869 0 01-.638-.263.867.867 0 01-.262-.637v-3.725a.726.726 0 01.75-.75.728.728 0 01.75.75v2.15c1.033-.8 1.833-1.75 2.4-2.85s.85-2.292.85-3.575c0-1.883-.575-3.538-1.725-4.963-1.15-1.425-2.625-2.362-4.425-2.812a.682.682 0 01-.437-.263.742.742 0 01-.163-.462c0-.267.087-.475.262-.625.175-.15.38-.2.613-.15zm-4.25 18.55c-2.15-.5-3.917-1.6-5.3-3.3-1.383-1.7-2.075-3.692-2.075-5.975 0-1.483.308-2.854.925-4.113.617-1.258 1.5-2.345 2.65-3.262h-1.95a.706.706 0 01-.537-.225.74.74 0 01-.213-.525.728.728 0 01.75-.75H7.85c.25 0 .463.083.638.25a.858.858 0 01.262.65v3.725c0 .2-.07.375-.212.525A.71.71 0 018 8.531a.706.706 0 01-.537-.225.74.74 0 01-.213-.525v-2.15c-1.05.767-1.854 1.704-2.412 2.812A7.861 7.861 0 004 12.031c0 1.883.571 3.537 1.713 4.962 1.141 1.425 2.62 2.363 4.437 2.813.183.033.33.121.438.263a.745.745 0 01.162.462c0 .267-.087.475-.262.625-.175.15-.38.2-.613.15zm.7-5.4a.922.922 0 01-.337-.063.812.812 0 01-.288-.187l-2.725-2.75a.641.641 0 01-.225-.5c0-.2.075-.383.225-.55.15-.133.325-.2.525-.2s.375.067.525.2l2.3 2.3 5.15-5.15a.678.678 0 01.525-.213.77.77 0 01.525.238.72.72 0 01.225.525.72.72 0 01-.225.525L11.2 15.656a.814.814 0 01-.287.187.93.93 0 01-.338.063z"></path>
      </g>
    </svg>;
}
export default Icon;