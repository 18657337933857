import * as React from 'react';
import { Scoped } from 'kremling';
import styles from './tags.styles.scss';
import { Icon } from '../icon/icon.component';
import classnames from 'classnames';
import { Box, Chip, ClickAwayListener, Grid, Paper, Popper, Stack } from '@mui/material';
export default function TagsMui(props) {
  const [tagsAnchorEl, setTagsAnchorEl] = React.useState(null);
  const tagsOpen = Boolean(tagsAnchorEl);
  const handleTagsPopperClose = () => {
    setTagsAnchorEl(null);
  };
  const handleTagsPopperClick = event => {
    event.stopPropagation();
    setTagsAnchorEl(tagsOpen ? null : event.currentTarget);
  };
  return <>
      {props.tags !== null && props.tags !== undefined && props.tags.slice(0, 3).map((t, index) => <Chip key={t} onClick={e => props.onClick(e, t)} label={t} sx={{
      '& .MuiChip-label': {
        textTransform: 'none'
      }
    }} />)}
      {props.tags !== null && props.tags !== undefined && props.tags.length > 3 && <Chip onClick={e => handleTagsPopperClick(e)} label={props.tags.length - 3 + ' +'} sx={{
      '& .MuiChip-label': {
        color: '#53A6D6',
        textTransform: 'none'
      }
    }} />}

      <Popper anchorEl={tagsAnchorEl} sx={{
      zIndex: 200
    }} open={tagsOpen} onClose={handleTagsPopperClose} placement="right">
        <ClickAwayListener onClickAway={handleTagsPopperClose}>
          <Paper elevation={0} sx={{
          borderRadius: '16px',
          overflow: 'visible',
          filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))'
        }}>
            <Box sx={{
            p: 1
          }}>
              <Stack spacing={1}>
                {props.tags !== null && props.tags !== undefined && props.tags.slice(3).map((t, index) => <Chip key={t} onClick={e => props.onClick(e, t)} label={t} sx={{
                '& .MuiChip-label': {
                  textTransform: 'none'
                }
              }} />)}
              </Stack>
            </Box>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>;
}