import React, { createRef, useEffect, useState, useCallback } from 'react';
import { Scoped, a, m } from 'kremling';
import { isEqual, isNumber, set } from 'lodash';
import styles from './static-type-ahead-tags.styles.scss';
import { Icon } from '../icon/icon.component';
import { Tags as TagComponent } from '../tags/tags.component';
export const StaticTypeAheadTags = props => {
  const contentEl = createRef();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenAbove, setIsOpenAbove] = useState(false);
  const [search, setSearch] = useState('');
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [tags, setTags] = useState([]);
  const [tagsSelected, setTagsSelected] = useState([]);
  const handleDocumentClick = useCallback(e => {
    // This isn't working because contentEl is null... :(
    if (contentEl.current && !contentEl.current.contains(e.target)) {
      close();
    }
  }, []);
  useEffect(() => {
    if (props.tagsSelected) {
      setTagsSelected(props.tagsSelected);
    }
    return () => {
      // On unmount
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);
  useEffect(() => {
    if (props.tagsSelected && props.tagsSelected.length !== 0) {
      let newTags = tags.filter(i => !props.tagsSelected.includes(i.tag));
      setTags(newTags);
      setTagsSelected(props.tagsSelected);
    }
  }, [props]);
  useEffect(() => {
    let tags = [...props.tags];
    if (search !== '') {
      setTags([]);
      tags = tags.filter(i => i[`${props.displayProperty}`].toLowerCase().includes(search.toLowerCase()) && !tagsSelected.includes(i.tag));
      setTags(tags);
    } else if (search === '') {
      tags = tags.filter(i => !tagsSelected.includes(i.tag));
      setTags(tags);
    }
  }, [props.tags, search]);

  // TODO Check if these functions should useRef or anything like that.
  const open = () => {
    setIsOpen(true);
    document.addEventListener('click', handleDocumentClick);
  };

  // TODO Check if these functions should useRef or anything like that.
  const close = () => {
    setIsOpen(false);
    setIsOpenAbove(false);
    document.removeEventListener('click', handleDocumentClick);
  };
  const onSearch = e => {
    if (!isOpen) open();
    setSearch(e.target.value);
  };
  const onFocus = e => {
    e.target.setSelectionRange(0, e.target.value.length);
    let newTags = tags.filter(i => !tagsSelected.includes(i.tag));
    setTags(newTags);
    if (!isOpen) {
      open();
    }
  };
  const onSelect = item => {
    let newTags = tagsSelected;
    newTags.push(item.tag);
    setTagsSelected(newTags);
    props.onChange(newTags);
  };
  const deleteTag = i => {
    let deleteTags = tagsSelected;
    deleteTags.splice(i, 1);
    let newTags = props.tags.filter(t => !t.tag.includes(tagsSelected[i]));
    setTags(newTags);
    setTagsSelected(prevTags => {
      return [...deleteTags];
    });
    props.onChange(tagsSelected);
  };
  const onBlur = () => {
    if (isOpen) {
      close();
    }
  };
  return <Scoped css={styles}>
      <div className="type-ahead" ref={contentEl}>
        {!!props.disabled ? <div className="type-ahead__container">
            <input type="text" className="form-control" value={search} disabled={true} />
          </div> : <>
            <div className="type-ahead__container">
              <input type="text" className={props.placeholderStyles ? 'form-control placeholderStyle' : 'form-control'} onChange={onSearch} value={search} onFocus={onFocus} placeholder={props.placeholder} autoComplete="none" onBlur={onBlur} />
            </div>
            <div className={a('type-ahead__content').m('type-ahead__content--open', isOpen).m('type-ahead__content--above', isOpenAbove)}>
              <ul className="select-list select-list__controlled">
                {tags.map((item, i) => {
              return <li key={props.keyProperty ? item[props.keyProperty] : item} role="option">
                      <a onMouseDown={() => onSelect(item)} className={m('active', selectedIndex === i)} onMouseOver={() => setSelectedIndex(i)}>
                        <div className="select-list__content">
                          {props.displayProperty ? item[props.displayProperty] : item}
                        </div>
                        {isEqual(props.value, props.keyProperty ? item[props.keyProperty] : item) && <Icon className="select-list__selected" size={14} name="fa-regular-check" />}
                      </a>
                    </li>;
            })}
              </ul>
            </div>
          </>}
      </div>
      <div style={{
      maxWidth: '175px',
      marginTop: '8px'
    }}>
        <TagComponent tags={tagsSelected} styleType="custom" onClick={null} onDelete={i => deleteTag(i)}></TagComponent>
      </div>
    </Scoped>;
};