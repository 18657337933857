import React from 'react';
import { Toggle } from 'components';
export const Checkbox = props => {
  const {
    param,
    update,
    data
  } = props;
  return <div className="form-group">
      <label>{param.label}</label>
      <Toggle checked={!!data[param.key]} onToggle={() => update({
      [param.key]: !data[param.key]
    })} />
    </div>;
};