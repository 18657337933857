import React, { useState, useContext, useEffect } from 'react';
import { UserStateContext } from 'context/user-state-context';
import { addCallToolsToken, deleteCallToolsToken, addCallToolsDisposition, editCallToolsDisposition, deleteCallToolsDisposition } from '../integrations.resource';
import { history } from '../../../root.component';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, InputAdornment, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { AddIcon, CancelIcon, CloseIcon, DeleteIcon, EditIcon, HelpIcon, VisibilityIcon, VisibilityOffIcon } from 'components/mui';
const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius: `14px`,
      '& fieldset': {}
    }
  }
})(TextField);
export const IntegrationCallToolsMui = props => {
  const {
    user,
    asCompany,
    hasPermission
  } = useContext(UserStateContext);
  const [token, setToken] = useState(props?.credential?.token || '');
  const [changesMade, setChangesMade] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [dispositions, setDispositions] = useState(props.credential?.dispositions || []);
  const [saving, setSaving] = useState(false);
  const [showHiddenValues, setShowHiddenValues] = useState([]);
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(null);
  const [unsavedChangesModalOpen, setUnsavedChangesModalOpen] = useState(null);
  const handleDeleteCredential = () => {
    deleteCallToolsToken(props?.credential?.id).then(() => {
      history.push(`/integrations/manage/${props?.install.id}`, {
        sucessfulCredential: 'Credential sucessfully deleted'
      });
    });
  };
  const handleSubmit = () => {
    if (!token || token == '' || dispositions.some(disposition => !disposition.name)) {
      setShowErrors(true);
      return;
    }
    setSaving(true);
    if (props?.credential) {
      //existing, and can only update dispositions
      //check if any were delted
      const dispositionsToDelete = props?.credential?.dispositions.filter(disposition => !dispositions.some(d => d.id === disposition.id));
      const dispositionDeletePromises = dispositionsToDelete.map(disposition => {
        return deleteCallToolsDisposition(disposition.id);
      });
      const dispositionPromises = dispositions.map(disposition => {
        if (disposition.id) {
          return editCallToolsDisposition(disposition.id, disposition.name);
        } else {
          return addCallToolsDisposition(disposition.name, props?.credential.id);
        }
      });
      Promise.all([...dispositionPromises, ...dispositionDeletePromises]).then(() => {
        history.push(`/integrations/manage/${props?.install.id}`, {
          sucessfulCredential: 'Credential sucessfully edited'
        });
      });
    } else {
      //make token and then add dispositions
      addCallToolsToken(token, asCompany.id).then(res => {
        if (res) {
          if (dispositions.length > 0) {
            const dispositionPromises = dispositions.map(disposition => {
              return addCallToolsDisposition(disposition.name, res.id);
            });
            Promise.all(dispositionPromises).then(() => {
              if (!props?.credential) {
                history.push(`/integrations/manage/${props?.install.id}`, {
                  sucessfulCredential: 'New Credential sucessfully added'
                });
              } else {
                history.push(`/integrations/manage/${props?.install.id}`, {
                  sucessfulCredential: 'Credential sucessfully edited'
                });
              }
            });
          } else {
            if (!props?.credential) {
              history.push(`/integrations/manage/${props?.install.id}`, {
                sucessfulCredential: 'New Credential sucessfully added'
              });
            } else {
              history.push(`/integrations/manage/${props?.install.id}`, {
                sucessfulCredential: 'Credential sucessfully edited'
              });
            }
          }
        }
      });
    }
  };
  return <>
      <Stack>
        <Box sx={{
        pb: '16px'
      }}>
          <Typography sx={{
          fontSize: '18px',
          fontWeight: '300',
          lineHeight: '26px'
        }}>Configuration</Typography>
        </Box>
        <Box sx={{
        pb: '16px'
      }}>
          <CustomTextField sx={{
          width: '50%'
        }} helperText={<>
                {showErrors && (!token || token == '') && 'Api Token is required.'}
                Please enter the API Token for your CallTools account. It can be found on this page:
                <a target="_blank" href="https://app.calltools.com/api/documentation/">
                  https://app.calltools.com/api/documentation/
                </a>
              </>} error={showErrors && (!token || token == '')} value={token} required type={showHiddenValues.includes('token') && !props?.credential ? 'text' : 'password'} label={'API Token'} disabled={!!props?.credential || !hasPermission('calltools.change_disposition')} InputProps={{
          readOnly: !!props?.credential,
          endAdornment: !props?.credential ? <InputAdornment position="end">
                  <Stack direction="row" spacing="5px">
                    <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                      <Tooltip title="Toggle Visibility">
                        <IconButton onClick={e => {
                    if (showHiddenValues.includes('token')) {
                      setShowHiddenValues(showHiddenValues.filter(value => value !== 'token'));
                    } else {
                      setShowHiddenValues([...showHiddenValues, 'token']);
                    }
                  }}>
                          {showHiddenValues.includes('token') ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Stack>
                </InputAdornment> : <InputAdornment position="end">
                  <Stack direction="row" spacing="5px">
                    <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                      <Tooltip title="This field cannot be edited on an existing credential. If you need to change it, remove this credential and create a new one">
                        <IconButton>
                          <HelpIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Stack>
                </InputAdornment>
        }} variant={!!props?.credential ? 'filled' : 'outlined'} onChange={event => {
          setChangesMade(true);
          setToken(event.target.value);
        }} />
        </Box>
        <Box sx={{
        pb: '16px'
      }}>
          <Typography sx={{
          fontSize: '18px',
          fontWeight: '300',
          lineHeight: '26px'
        }}>Dispositions</Typography>
        </Box>
        <Box>
          {hasPermission('calltools.change_disposition') && <Button variant="contained" startIcon={<AddIcon fill="#3898D9" />} sx={{
          width: '220px',
          backgroundColor: '#F3F3F4',
          '&:hover': {
            backgroundColor: '#F3F3F4'
          }
        }} onClick={() => {
          setDispositions([...dispositions, {
            name: ''
          }]);
          setChangesMade(true);
        }}>
              <Box sx={{
            py: '5px',
            pr: '12px'
          }}>
                <Typography sx={{
              color: '#3898D9'
            }}>Add New Desposition </Typography>
              </Box>
            </Button>}
        </Box>
        {dispositions.length > 0 && <Stack sx={{
        pt: '24px'
      }} spacing="16px">
            {dispositions.map((disposition, index) => <Stack key={index} direction="row" spacing="16px">
                <CustomTextField sx={{
            width: '50%'
          }} value={disposition.name} helperText={showErrors && !disposition.name && 'Disposition Name is required.'} error={showErrors && !disposition.name} required label={'Disposition ' + (index + 1) + ' Name'} onChange={event => {
            disposition.name = event.target.value;
            setDispositions([...dispositions]);
            setChangesMade(true);
          }} />
                <Box sx={{
            position: 'relative'
          }}>
                  <Box sx={{
              height: '40px',
              width: '40px',
              display: 'flex',
              alignItems: 'center'
            }}>
                    <Tooltip title="Delete">
                      <IconButton onClick={() => {
                  setDispositions(dispositions.filter((_, i) => i !== index));
                  setChangesMade(true);
                }}>
                        <CancelIcon fill="#1D252D" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
              </Stack>)}
          </Stack>}

        <Stack sx={{
        pt: '16px'
      }}>
          <Divider />
          <Box sx={{
          pr: '20px',
          pt: '20px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
            <Stack direction="row" spacing="16px">
              {hasPermission('integration.delete_credential') && props?.credential ? <Button variant="contained" sx={{
              backgroundColor: '#F3F3F4',
              '&:hover': {
                backgroundColor: '#F3F3F4'
              }
            }} startIcon={<CancelIcon fill="#3898D9" />} onClick={e => {
              setOpenConfirmDeleteModal(true);
            }}>
                  <Box sx={{
                py: '5px',
                pr: '12px'
              }}>
                    <Typography sx={{
                  color: '#3898D9'
                }}>Remove </Typography>
                  </Box>
                </Button> : <Box></Box>}
            </Stack>
            <Stack direction="row" spacing="16px">
              <Button variant="contained" sx={{
              backgroundColor: '#F3F3F4',
              '&:hover': {
                backgroundColor: '#F3F3F4'
              }
            }} onClick={e => {
              if (changesMade) {
                setUnsavedChangesModalOpen(true);
              } else {
                history.push(`/integrations/manage/${props?.install?.id}`);
              }
            }}>
                <Box sx={{
                py: '5px',
                px: '12px'
              }}>
                  <Typography sx={{
                  color: '#3898D9'
                }}>Cancel </Typography>
                </Box>
              </Button>
              <Button sx={{
              backgroundColor: '#3898D9',
              '&:hover': {
                backgroundColor: '#3898D9'
              }
            }} variant="contained" endIcon={saving && <CircularProgress sx={{
              color: '#FFF'
            }} size={'20px'} />} disabled={!changesMade && hasPermission('calltools.change_disposition')} onClick={e => {
              if (hasPermission('calltools.change_disposition')) {
                handleSubmit();
              } else {
                history.push(`/integrations/manage/${props?.install.id}`);
              }
            }}>
                <Box sx={{
                py: '6px',
                px: '12px'
              }}>
                  <Typography sx={{
                  color: '#fff'
                }}>
                    {saving ? 'Working...' : hasPermission('calltools.change_disposition') ? 'Submit' : 'Done'}
                  </Typography>
                </Box>
              </Button>
            </Stack>
          </Box>
        </Stack>
      </Stack>

      <Dialog scroll="paper" PaperProps={{
      style: {
        borderRadius: '28px'
      }
    }} open={!!unsavedChangesModalOpen} onClose={(e, r) => {
      setUnsavedChangesModalOpen(null);
    }}>
        <DialogTitle sx={{
        m: 0,
        p: 2,
        fontSize: 'medium'
      }}>
          <Stack spacing={'20px'}>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Stack direction="row" spacing={'10px'}>
                <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                  <Typography sx={{
                  fontWeight: '300',
                  lineHeight: '30px',
                  fontSize: '24px',
                  display: 'flex',
                  alignItems: 'center'
                }} variant="h4" component="span">
                    Unsaved Changes
                  </Typography>
                </Box>
              </Stack>

              <IconButton onClick={() => {
              setUnsavedChangesModalOpen(null);
            }}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Divider />
          </Stack>
        </DialogTitle>

        <DialogContent sx={{
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
          width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
          marginTop: '20px',
          marginBottom: '20px'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#888'
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555'
        }
      }}>
          <Stack sx={{
          pt: 1
        }} spacing={'24px'}>
            <Box>You have unsaved changes. Do you want to discard them? </Box>
          </Stack>
        </DialogContent>

        <DialogActions sx={{
        pt: 1,
        pb: 2
      }}>
          <Stack sx={{
          pr: '24px'
        }} direction={'row'} spacing={'20px'}>
            <Button onClick={() => {
            setUnsavedChangesModalOpen(false);
          }} variant="contained" sx={{
            backgroundColor: '#F3F3F4',
            '&:hover': {
              backgroundColor: '#F3F3F4'
            }
          }}>
              <Box sx={{
              py: '5px',
              px: '12px'
            }}>
                <Typography sx={{
                color: '#3898D9'
              }}>Cancel </Typography>
              </Box>
            </Button>
            <Button onClick={() => {
            history.push(`/integrations/manage/${props?.install.id}`);
          }} variant="contained" sx={{
            backgroundColor: '#3898D9',
            '&:hover': {
              backgroundColor: '#3898D9'
            }
          }}>
              <Box sx={{
              py: '5px',
              px: '12px'
            }}>
                <Typography sx={{
                color: '#fff'
              }}> Discard</Typography>
              </Box>
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>

      <Dialog scroll="paper" PaperProps={{
      style: {
        borderRadius: '28px'
      }
    }} open={!!openConfirmDeleteModal} onClose={(e, r) => {
      setOpenConfirmDeleteModal(null);
    }}>
        <DialogTitle sx={{
        m: 0,
        p: 2,
        fontSize: 'medium'
      }}>
          <Stack spacing={'20px'}>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Stack direction="row" spacing={'10px'}>
                <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                  <Typography sx={{
                  fontWeight: '300',
                  lineHeight: '30px',
                  fontSize: '24px',
                  display: 'flex',
                  alignItems: 'center'
                }} variant="h4" component="span">
                    Confirm Remove Credential
                  </Typography>
                </Box>
              </Stack>

              <IconButton onClick={() => {
              setOpenConfirmDeleteModal(null);
            }}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Divider />
          </Stack>
        </DialogTitle>

        <DialogContent sx={{
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
          width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
          marginTop: '20px',
          marginBottom: '20px'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#888'
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555'
        }
      }}>
          <Stack sx={{
          pt: 1
        }} spacing={'24px'}>
            <Box>Are you sure you want to remove this credential? </Box>
          </Stack>
        </DialogContent>

        <DialogActions sx={{
        pt: 1,
        pb: 2
      }}>
          <Stack sx={{
          pr: '24px'
        }} direction={'row'} spacing={'20px'}>
            <Button onClick={() => {
            setOpenConfirmDeleteModal(false);
          }} variant="contained" sx={{
            backgroundColor: '#F3F3F4',
            '&:hover': {
              backgroundColor: '#F3F3F4'
            }
          }}>
              <Box sx={{
              py: '5px',
              px: '12px'
            }}>
                <Typography sx={{
                color: '#3898D9'
              }}>Cancel </Typography>
              </Box>
            </Button>
            <Button onClick={() => {
            handleDeleteCredential();
            setOpenConfirmDeleteModal(null);
          }} variant="contained" sx={{
            backgroundColor: '#3898D9',
            '&:hover': {
              backgroundColor: '#3898D9'
            }
          }}>
              <Box sx={{
              py: '5px',
              px: '12px'
            }}>
                <Typography sx={{
                color: '#fff'
              }}> Remove</Typography>
              </Box>
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </>;
};