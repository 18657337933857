import React, { useState, useEffect, useCallback } from 'react';
import { MenuItem, TextField } from '@mui/material';
import { Field } from 'formik';
import { useFormikContext } from 'formik';
import moment from 'moment-timezone';

// TODO change this to a pure select component
/**
 *
 * This component works a bit different and doesn't have a "blank" default value, but rather
 * provides a default if not provided.  We must update formik in this scenario
 */
export const FormikTimeZonePicker = ({
  name,
  validate,
  label
}) => {
  const [selectedTimezone, setSelectedTimezone] = useState();
  const [availableTimezones, setAvailableTimezones] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const {
    initialValues,
    setFieldValue
  } = useFormikContext();
  useEffect(() => {
    const keyExists = initialValues.hasOwnProperty(name);
    const startingValue = initialValues[name];
    let defaultValue;
    if (!keyExists) {
      console.warn('FormikTimeZonePicker is misconfigured, you must provide the correct name prop.');
      defaultValue = moment.tz.guess();
    } else if (!startingValue) {
      defaultValue = moment.tz.guess();
    } else {
      defaultValue = startingValue;
    }
    setSelectedTimezone(defaultValue);

    // because this component autofills suggestions, we need to make
    // sure the form has the up to date value if we override selected timezone
    setFieldValue(name, defaultValue, true);
    const timezones = [];
    moment.tz.names().map(timezone => {
      timezones.push(timezone);
    });
    setAvailableTimezones(timezones);
    setIsLoading(false);
  }, []);
  const changeSelection = async (event, form) => {
    const newValue = event.target.value;
    await form.setFieldValue(name, newValue, true);
    setSelectedTimezone(newValue);
  };
  return <>
      {!isLoading && <Field name={name} validate={validate}>
          {({
        field,
        // { name, value, onChange, onBlur }
        form,
        // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        meta
      }) => <TextField {...field} value={selectedTimezone} label={label} onChange={async event => {
        await changeSelection(event, form);
      }} error={meta.touched && meta.error ? true : false} helperText={meta.touched && meta.error ? meta.error : null} select // tells textfield to render as select
      // InputProps={{transfor}}
      // SelectProps={{ native: true }}
      >
              {/** TODO limit the size of this window! */}
              {availableTimezones.map(function (timezone) {
          return <MenuItem key={timezone} value={timezone}>
                    {timezone}
                  </MenuItem>;
        })}
            </TextField>}
        </Field>}
    </>;
};