import React, { useEffect, useState, useContext } from 'react';
import { createNewCustomerRef, getCustomers } from '../../shared/common.api';
import { UserStateContext } from 'context/user-state-context';
import { CustomerDrawerContext } from '../../context/customer-drawer-context';
import CloseIcon from '@mui/icons-material/Close';
import { history } from '../../root.component';
import { WarningRedFilledIcon, WarningRedIcon } from 'components/mui';
import utils from 'shared/utils';
import { validator } from 'validator';
import { Avatar, Box, Typography, Stack, Button, Dialog, DialogActions, DialogContent, DialogContentText, TextField, InputAdornment, Tooltip } from '@mui/material';
import { isValidPhoneNumber } from 'libphonenumber-js';
import isEmail from 'validator/lib/isEmail';

/*******************************************************************************************
 * This dialog will allow the user to create a new customer to add into the database
 * the customer created will POST to the customer_ref table
 *
 * @param saveCustomer                checks if the customer already exists in the database then begins the save process
 * @param makeCustomerRef             creates a new customer_ref to add to the database
 * @param toggleWarnings              toggles the input warnings off (red exclamation points)
 * @param validInputCheck             checks if the user input is valid
 * @param warningInputMessages        displays the warning text to the user
 * @param duplicateCustomers          list of duplicate customers (if any)
 * @param showDuplicateCustomerList   dialog that shows the list of duplicate customers
 * @param showDiscardWarning          shows the discard changes warning when a user attempts to close the dialog
 * @param makeOverflowTooltip         truncates the text and adds a tooltip if the text is too long
 * @param getInitials                 gets the initials of the customer
 *
 *
 *******************************************************************************************/

export const NewCustomerDialog = props => {
  const [customerFName, setCustomerFName] = useState('');
  const [customerLName, setCustomerLName] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');
  const [customerPhone, setCustomerPhone] = useState(props.phone || '');
  const [open, setOpen] = useState(true);
  const {
    asCompany
  } = useContext(UserStateContext);
  const [invalidInputWarning, setInvalidInputWarning] = useState(false);
  const [duplicateCustomers, setDuplicateCustomers] = useState([]);
  const [showDuplicateCustomerList, setShowDuplicateCustomerList] = useState(false);
  const [showDiscardWarning, setShowDiscardWarning] = useState(false);
  const [avatarHovered, setAvatarHovered] = React.useState(null);
  const [invalidEmailWarning, setInvalidEmailWarning] = useState(false);
  const [invalidPhoneWarning, setInvalidPhoneWarning] = useState(false);
  const [invalidFNameWarning, setInvalidFNameWarning] = useState(false);
  const [invalidLNameWarning, setInvalidLNameWarning] = useState(false);
  const {
    openCustomerDrawer
  } = useContext(CustomerDrawerContext);
  const makeOverflowTooltip = (content, length, bold) => {
    if (content) {
      return <Tooltip title={content.length > length ? <> {content} </> : ''}>
          <Typography variant={bold ? 'tableHeader' : ''}>
            {content.length > length ? <>{('' + content).slice(0, length) + '...'}</> : <>{content}</>}
          </Typography>
        </Tooltip>;
    }
  };
  const getInitals = name => {
    if (name) return name.split(' ').filter((segment, i) => i < 2).map(segment => segment[0]).join('').toUpperCase();
  };
  const handleRowClick = customer => {
    handleCloseDuplicateCustomerList();
    handleClose();
    openCustomerDrawer(customer.id, 'messaging');
  };

  // verify the phonenumber entered is valid, backup incase we stop using libphonenumber
  const phoneNumberCheck = phoneNumber => {
    const regex = /^\s*(?:\+?(\d{1,3}))?[^()-]*(\d{3})[^()-]*(\d{3})[^()-]*(\d{4})(?: *x(\d+))?\s*$/;
    return regex.test(phoneNumber);
  };
  const makeCustomerRef = () => {
    createNewCustomerRef({
      company: asCompany.id,
      first_name: customerFName,
      last_name: customerLName,
      email: customerEmail,
      phone: customerPhone,
      entity_source: 'manual_entry'
    }).then(data => {
      handleClose();
      if (data?.data?.customer) {
        openCustomerDrawer(data.data.customer, 'messaging');
      }
    });
  };
  const saveCustomer = () => {
    if (customerFName == '' || customerLName == '' || customerPhone == '') {} else {
      setInvalidInputWarning(false);
    }
    if (customerFName == '') {
      setInvalidFNameWarning(true);
    } else {
      setInvalidFNameWarning(false);
    }
    if (customerLName == '') {
      setInvalidLNameWarning(true);
    } else {
      setInvalidLNameWarning(false);
    }
    if (!isEmail(customerEmail) && customerEmail !== '') {
      setInvalidEmailWarning(true);
    } else {
      setInvalidEmailWarning(false);
    }
    if (!isValidPhoneNumber(customerPhone, 'US') || customerPhone == '' || !phoneNumberCheck(customerPhone)) {
      setInvalidPhoneWarning(true);
    } else {
      setInvalidPhoneWarning(false);
    }
    if (validInputCheck()) {
      toggleWarnings();
    } else {
      setInvalidInputWarning(true);
      return;
    }

    // check if has a duplicate
    getCustomers({
      company: asCompany.id,
      limit: 1000,
      email: customerEmail,
      phone: customerPhone,
      ordering: 'first_name'
    }).then(data => {
      if (data?.data?.results.length > 0) {
        setDuplicateCustomers(data.data.results);
        setShowDuplicateCustomerList(true);
      } else {
        makeCustomerRef();
      }
    });
  };
  const validInputCheck = () => {
    if (customerFName == '' || customerLName == '' || customerPhone == '') {
      return false;
    }
    if (customerFName == '') {
      setInvalidFNameWarning(true);
      return false;
    }
    if (customerLName == '') {
      setInvalidLNameWarning(true);
      return false;
    }
    if (!isEmail(customerEmail) && customerEmail !== '') {
      setInvalidEmailWarning(true);
      return false;
    }

    // allow only valid information into the phone number field
    if (!isValidPhoneNumber(customerPhone, 'US') || customerPhone == '' || !phoneNumberCheck(customerPhone) || customerPhone.includes('-') || customerPhone.includes('(') || customerPhone.includes(')')) {
      setInvalidPhoneWarning(true);
      return false;
    } else {
      return true;
    }
  };
  const warningInputMessages = () => {
    if (invalidFNameWarning && !invalidLNameWarning && !invalidEmailWarning && !invalidPhoneWarning) {
      return 'Please enter a First name.';
    }
    if (invalidLNameWarning && !invalidFNameWarning && !invalidEmailWarning && !invalidPhoneWarning) {
      return 'Please enter a Last name.';
    }
    if (invalidEmailWarning && !invalidPhoneWarning && !invalidLNameWarning && !invalidFNameWarning) {
      return 'Please enter a valid email, e.g. customer@example.com';
    }
    if (invalidPhoneWarning && !invalidEmailWarning && !invalidLNameWarning && !invalidFNameWarning) {
      return 'Please enter a valid phone number, e.g. +11234567890';
    }
    if (invalidInputWarning) {
      return 'Please fill out the required fields.';
    } else {
      return '';
    }
  };
  const toggleWarnings = () => {
    setInvalidInputWarning(false);
    setInvalidFNameWarning(false);
    setInvalidLNameWarning(false);
    setInvalidEmailWarning(false);
    setInvalidPhoneWarning(false);
  };
  const handleClose = () => {
    setCustomerFName('');
    setCustomerLName('');
    setCustomerEmail('');
    setCustomerPhone('');
    setOpen(false);
    setShowDuplicateCustomerList(false);
    props.onClose();
  };
  const handleCloseDuplicateCustomerList = () => {
    setDuplicateCustomers([]);
    setShowDuplicateCustomerList(false);
  };
  const handleOpenDiscardWarning = () => {
    setShowDiscardWarning(true);
  };
  const handleCancelDiscardWarning = () => {
    setShowDiscardWarning(false);
  };
  const handleCloseDiscardWarning = () => {
    handleCloseDuplicateCustomerList();
    setShowDiscardWarning(false);
    handleClose();
  };
  return <>
      {open && <Dialog open={open} onClose={handleClose} PaperProps={{
      style: {
        borderRadius: '28px',
        background: '#fff',
        maxWidth: '560px',
        minHeight: '244px',
        maxHeight: '600px',
        width: '560px'
      }
    }}>
          <Stack component="span">
            <Typography variant="h4" component="span" fontWeight={'bold'} sx={{
          pl: 3,
          pt: 2,
          color: '#1D252D'
        }}>
              New Customer
            </Typography>
          </Stack>
          <Box>
            <DialogContent sx={{
          width: '100%'
        }}>
              <Stack display={'grid'}>
                <Box sx={{
              pr: 1,
              pb: 2,
              width: '100%'
            }}>
                  <TextField sx={{
                width: '245px'
              }} id="outlined-basic" label="First Name" variant="outlined" autoComplete="off" required fullWidth error={invalidFNameWarning} defaultValue={customerFName} onChange={e => {
                setCustomerFName(e.target.value);
              }} InputProps={{
                endAdornment: <InputAdornment position="end">
                          {invalidFNameWarning && <WarningRedFilledIcon width="16.9px" height="16.9px" />}
                        </InputAdornment>
              }} />
                </Box>
                <Box alignItems={'right'} sx={{
              pl: 1,
              pb: 2,
              width: '100%'
            }}>
                  <TextField sx={{
                width: '245px'
              }} id="outlined-basic" label="Last Name" autoComplete="off" required variant="outlined" fullWidth error={invalidLNameWarning} defaultValue={customerLName} onChange={e => setCustomerLName(e.target.value)} InputProps={{
                endAdornment: <InputAdornment position="end">
                          {invalidLNameWarning && <WarningRedFilledIcon width="16.9px" height="16.9px" />}
                        </InputAdornment>
              }} />
                </Box>
                <TextField sx={{
              gridColumnStart: 1,
              gridColumnEnd: 3,
              pb: 2,
              width: '100%'
            }} id="outlined-basic" label="Phone Number" required variant="outlined" type="tel" fullWidth error={invalidPhoneWarning} defaultValue={customerPhone} onChange={e => {
              setCustomerPhone(e.target.value);
            }} InputProps={{
              endAdornment: <InputAdornment position="end">
                        {invalidPhoneWarning && <WarningRedFilledIcon width="16.9px" height="16.9px" />}
                      </InputAdornment>
            }} />
                <TextField sx={{
              gridColumnStart: 1,
              gridColumnEnd: 3,
              width: '100%'
            }} id="outlined-basic" label="Email" variant="outlined" type="email" fullWidth error={invalidEmailWarning} defaultValue={customerEmail} onChange={e => setCustomerEmail(e.target.value)} InputProps={{
              endAdornment: <InputAdornment position="end">
                        {invalidEmailWarning && <WarningRedFilledIcon width="16.9px" height="16.9px" />}
                      </InputAdornment>
            }} />
              </Stack>
            </DialogContent>
          </Box>
          <Box sx={{
        pb: 2,
        minHeight: '20px',
        maxHeight: '20px'
      }}>
            {invalidInputWarning && <Box>
                <DialogContentText id="alert-dialog-description" sx={{
            textAlign: 'left',
            color: invalidInputWarning ? '#EF3C34' : '#1D252D',
            fontFamily: 'Arial',
            fontSize: '14px',
            fontStyle: 'italic',
            fontWeight: '400',
            lineHeight: '16.8px',
            pl: 3
          }}>
                  {warningInputMessages()}
                </DialogContentText>
              </Box>}
          </Box>

          <DialogActions sx={{
        pb: '16px'
      }}>
            <Button onClick={handleOpenDiscardWarning} sx={{
          width: '75px',
          borderRadius: '28px',
          mr: 1,
          textTransform: 'none'
        }}>
              Cancel
            </Button>
            <Button
        //disabled={!canSave}
        onClick={e => {
          saveCustomer();
        }} sx={{
          width: '75px',
          borderRadius: '28px',
          mr: 1,
          textTransform: 'none'
        }}>
              Save
            </Button>
          </DialogActions>
        </Dialog>}
      {showDiscardWarning && <Dialog open={showDiscardWarning} onClose={handleCloseDiscardWarning} autoFocus={false} disableAutoFocus hideBackdrop PaperProps={{
      style: {
        borderRadius: '28px',
        background: '#fff',
        height: '240px',
        width: '312px',
        overflow: 'hidden',
        pb: 0
      }
    }}>
          <Box>
            <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          p: 3
        }}>
              <WarningRedIcon sx={{
            alignSelf: 'center',
            p: 3
          }} />
            </Box>
            <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
              <Typography variant="h4" component="span" sx={{
            alignSelf: 'center',
            p: 3,
            pb: 0,
            pt: 0,
            color: '#1D252D',
            fontSize: '24px'
          }}>
                Discard Changes?
              </Typography>
            </Box>

            <Typography sx={{
          color: '#1D252D',
          fontSize: '14px',
          p: 3,
          textAlign: 'left'
        }}>
              You have unsaved changes that will be lost if you close this window.
            </Typography>
          </Box>
          <DialogActions sx={{
        pb: '8px',
        alignItems: 'end'
      }}>
            <Button onClick={handleCancelDiscardWarning} variant="text" sx={{
          width: '75px',
          borderRadius: '28px',
          mr: 1,
          textTransform: 'none'
        }}>
              Cancel
            </Button>
            <Button onClick={handleCloseDiscardWarning} variant="text" sx={{
          width: '75px',
          borderRadius: '28px',
          mr: 1,
          textTransform: 'none'
        }}>
              Discard
            </Button>
          </DialogActions>
        </Dialog>}
      {showDuplicateCustomerList && <Dialog open={showDuplicateCustomerList} onClose={handleCloseDuplicateCustomerList} autoFocus={false} disableAutoFocus hideBackdrop PaperProps={{
      style: {
        borderRadius: '28px',
        background: '#fff',
        maxWidth: '560px',
        minHeight: '330px',
        maxHeight: '600px',
        width: '444px',
        overflow: 'hidden'
      }
    }}>
          <Box>
            <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          p: 3
        }}>
              <WarningRedIcon sx={{
            alignSelf: 'center',
            p: 3
          }} />
            </Box>
            <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
              <Typography variant="h4" component="span" sx={{
            alignSelf: 'center',
            p: 3,
            pb: 0,
            pt: 0,
            color: '#1D252D',
            fontSize: '24px'
          }}>
                Customer Already Exists
              </Typography>
            </Box>

            <Typography sx={{
          color: '#1D252D',
          fontSize: '14px',
          p: 3,
          textAlign: 'left'
        }}>
              We found a customer with the same information. Please update the existing customer. If this is intended, then proceed.
            </Typography>
          </Box>
          <Stack sx={{
        overflowY: 'auto',
        overflowX: 'hidden',
        maxHeight: '42vh',
        minHeight: '6vh',
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
          width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
          marginTop: '20px',
          marginBottom: '20px'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#888'
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555'
        }
      }}>
            {duplicateCustomers.map((customer, index) => <Box key={index} sx={{
          pb: 1
        }}>
                <Button onMouseEnter={() => {
            setAvatarHovered(customer.id);
          }} onMouseLeave={() => {
            setAvatarHovered(null);
          }} onClick={() => handleRowClick(customer)} sx={{
            textAlign: 'left',
            justifyContent: 'left',
            width: '100%',
            pl: 3,
            borderBottom: '1px solid #E5E5E5',
            '&:hover': {
              backgroundColor: '#fff'
            }
          }}>
                  <Box sx={{
              px: '12px',
              pl: '0px'
            }}>
                    <Avatar sx={{
                width: 30,
                height: 30,
                backgroundColor: avatarHovered === customer.id ? '#53A6D6' : '#BEBEBE'
              }} children={getInitals(customer.first_name + ' ' + customer.last_name)} />
                  </Box>
                  <Stack>
                    <Typography sx={{
                //color: '#1D252D',
                fontSize: '14px',
                fontWeight: 'bold',
                pl: 2,
                textAlign: 'left',
                textTransform: 'capitalize',
                color: avatarHovered === customer.id ? '#53A6D6' : '#1D252D'
              }}>
                      {makeOverflowTooltip(customer.first_name, 10, true)} {makeOverflowTooltip(customer.last_name, 10, true)}
                    </Typography>
                    <Typography sx={{
                color: '#1D252D',
                fontSize: '14px',
                pl: 2,
                textAlign: 'left'
              }}>
                      {utils.formatIntlPhone(customer.phone, 'US')}
                    </Typography>
                    <Typography sx={{
                color: '#1D252D',
                fontSize: '12px',
                pl: 2,
                textAlign: 'left',
                fontWeight: 'bold',
                textTransform: 'none'
              }}>
                      {customer.email}
                    </Typography>
                  </Stack>
                </Button>
              </Box>)}
          </Stack>
          <DialogActions sx={{
        pb: '16px'
      }}>
            <Button onClick={() => {
          handleCloseDuplicateCustomerList();
        }} variant="text" sx={{
          width: '75px',
          borderRadius: '28px',
          mr: 1,
          textTransform: 'none'
        }}>
              Cancel
            </Button>
            <Button onClick={() => {
          makeCustomerRef();
        }} variant="text" sx={{
          width: '85px',
          borderRadius: '28px',
          mr: 1,
          textTransform: 'none'
        }}>
              Add New
            </Button>
          </DialogActions>
        </Dialog>}
    </>;
};