import React from 'react';
function Icon(props) {
  if (props.size == 'xlg') {
    return <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="#e8eaed" viewBox="0 -960 960 960">
        <path fill={props.fill || '#1D252D'} d="M620.71-537.23q19.71 0 33.73-14.11 14.02-14.11 14.02-33.83 0-19.71-14.11-33.73-14.11-14.02-33.83-14.02-19.71 0-33.73 14.11-14.02 14.11-14.02 33.82 0 19.72 14.11 33.74 14.11 14.02 33.83 14.02zm-281.23 0q19.71 0 33.73-14.11 14.02-14.11 14.02-33.83 0-19.71-14.11-33.73-14.11-14.02-33.83-14.02-19.71 0-33.73 14.11-14.02 14.11-14.02 33.82 0 19.72 14.11 33.74 14.11 14.02 33.83 14.02zM480-272.92q61.38 0 113.04-33 51.65-33 76.27-89h-42.39q-23 39.23-62.11 60.73-39.12 21.5-84.58 21.5t-84.81-21.19q-39.34-21.2-61.34-61.04h-43.39q25.23 56 76.58 89 51.35 33 112.73 33zm.07 172.92q-78.84 0-148.21-29.92t-120.68-81.21q-51.31-51.29-81.25-120.63Q100-401.1 100-479.93q0-78.84 29.92-148.21t81.21-120.68q51.29-51.31 120.63-81.25Q401.1-860 479.93-860q78.84 0 148.21 29.92t120.68 81.21q51.31 51.29 81.25 120.63Q860-558.9 860-480.07q0 78.84-29.92 148.21t-81.21 120.68q-51.29 51.31-120.63 81.25Q558.9-100 480.07-100zM480-480zm-.04 334.61q140.11 0 237.38-97.23 97.27-97.24 97.27-237.34 0-140.11-97.23-237.38-97.24-97.27-237.34-97.27-140.11 0-237.38 97.23-97.27 97.24-97.27 237.34 0 140.11 97.23 237.38 97.24 97.27 237.34 97.27z"></path>
      </svg>;
  }
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 -960 960 960">
      <path fill={props.fill || '#1D252D'} d="M616.064-527.693q22.628 0 37.512-14.795 14.885-14.795 14.885-37.423 0-22.627-14.795-37.512-14.795-14.884-37.423-14.884t-37.512 14.795q-14.885 14.795-14.885 37.423 0 22.627 14.795 37.512 14.795 14.884 37.423 14.884zm-272.307 0q22.628 0 37.512-14.795 14.885-14.795 14.885-37.423 0-22.627-14.795-37.512-14.795-14.884-37.423-14.884t-37.512 14.795q-14.885 14.795-14.885 37.423 0 22.627 14.795 37.512 14.795 14.884 37.423 14.884zM480-272.309q51.615 0 96.884-23.576 45.269-23.577 72.423-64.885 9.308-13.769 2.539-26.499Q645.076-400 630.154-400q-5.205 0-10.41 3.038-5.205 3.039-8.436 7.5-22 31.539-55.577 50.5Q522.154-320 480-320q-42.154 0-75.731-18.962-33.577-18.961-55.577-50.5-3.231-4.461-8.615-7.5Q334.693-400 329.039-400q-13.731 0-20.692 12.231-6.962 12.23 1.731 25.768 27.769 42.539 73.038 66.116 45.269 23.576 96.884 23.576zm.067 172.308q-78.836 0-148.204-29.92-69.369-29.92-120.682-81.21-51.314-51.291-81.247-120.629-29.933-69.337-29.933-148.173t29.92-148.204q29.92-69.369 81.21-120.682 51.291-51.314 120.629-81.247 69.337-29.933 148.173-29.933t148.204 29.92q69.369 29.92 120.682 81.21 51.314 51.291 81.247 120.629 29.933 69.337 29.933 148.173t-29.92 148.204q-29.92 69.369-81.21 120.682-51.291 51.314-120.629 81.247-69.337 29.933-148.173 29.933zM480-480zm0 320q133 0 226.5-93.5T800-480q0-133-93.5-226.5T480-800q-133 0-226.5 93.5T160-480q0 133 93.5 226.5T480-160z"></path>
    </svg>;
}
export default Icon;