import React from 'react';
function Icon(props) {
  if (props.size == 'lg') {
    return <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 -960 960 960">
        <path fill={props.fill || '#1D252D'} d="M355.538-140.001q-90.307 0-152.922-62.615-62.615-62.615-62.615-152.922v-248.924q0-90.307 62.615-152.922 62.615-62.615 152.922-62.615h248.924q90.307 0 152.922 62.615 62.615 62.615 62.615 152.922v248.924q0 90.307-62.615 152.922-62.615 62.615-152.922 62.615H355.538zm84.052-196.41l226.153-226.153-35.794-35.794L439.59-407.999l-92.462-92.205-35.794 35.794L439.59-336.411zm-83.936 146.155h248.692q68.423 0 116.91-48.488 48.488-48.487 48.488-116.91v-248.692q0-68.423-48.488-116.91-48.487-48.488-116.91-48.488H355.654q-68.423 0-116.91 48.488-48.488 48.487-48.488 116.91v248.692q0 68.423 48.488 116.91 48.487 48.488 116.91 48.488zM480-480z"></path>
      </svg>;
  }
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 -960 960 960">
      <path fill={props.fill || '#1D252D'} d="M360-140.001q-91.666 0-155.832-64.167Q140.001-268.334 140.001-360v-240q0-91.666 64.167-155.832Q268.334-819.999 360-819.999h240q91.666 0 155.832 64.167Q819.999-691.666 819.999-600v240q0 91.666-64.167 155.832Q691.666-140.001 600-140.001H360zm80-193.846L666.153-560 624-602.153l-184 184-88-88L309.847-464 440-333.847zM360-200h240q66 0 113-47t47-113v-240q0-66-47-113t-113-47H360q-66 0-113 47t-47 113v240q0 66 47 113t113 47zm120-280z"></path>
    </svg>;
}
export default Icon;