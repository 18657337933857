import { Calendar30DaysIcon, CustomerIcon, ShoppingCartIcon, TransactionIcon, VehicleIcon } from 'components/mui';
export class EditMode {
  // Private Fields
  static #_EDIT = 0;
  static #_CREATE = 1;
  static #_COPY = 2;

  // Accessors for "get" functions only (no "set" functions)
  static get EDIT() {
    return this.#_EDIT;
  }
  static get CREATE() {
    return this.#_CREATE;
  }
  static get COPY() {
    return this.#_COPY;
  }
}
export class BuilderType {
  // Private Fields
  static #_BUILDER = 0;
  static #_ADVANCED = 1;
  static #_AI = 2;

  // Accessors for "get" functions only (no "set" functions)
  static get BUILDER() {
    return this.#_BUILDER;
  }
  static get ADVANCED() {
    return this.#_ADVANCED;
  }
  static get AI() {
    return this.#_AI;
  }
}
export const segmentTypes = [{
  key: 'static',
  name: 'Static',
  icon: 'custom-web_asset',
  value: false
}, {
  key: 'dynamic',
  name: 'Dynamic',
  icon: 'custom-dynamic_segment',
  value: true
}];
export const segmentIncludedTypes = [{
  key: null,
  name: 'Customer Only',
  icon: CustomerIcon
}, {
  key: 'possession',
  name: 'Possession',
  icon: VehicleIcon
}, {
  key: 'schedule',
  name: 'Schedule',
  icon: Calendar30DaysIcon
}, {
  key: 'transaction',
  name: 'Transaction',
  icon: TransactionIcon
}, {
  key: 'cart',
  name: 'Cart',
  icon: ShoppingCartIcon
}];
export const fieldFields = [{
  key: 'text',
  name: 'Text'
}, {
  key: 'numeric',
  name: 'Numeric'
}, {
  key: 'picklist',
  name: 'Picklist'
}, {
  key: 'picklist-custom',
  name: 'Picklist(sql)'
}];