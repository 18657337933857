import React, { Component } from 'react';
import { UserStateContext } from 'context/user-state-context';
import { Button } from '../../components/button/button.component';
import { getGoogleAccounts, addGoogleAccount, deleteGoogleAccount, syncGoogleForms } from './integrations.resource';
import { toasterService } from '../../components/toaster/toaster-service';
import { ModalDialog } from '../../components/modal/modal-dialog.component';
import { ModalCompanySelect } from '../../components/modal/modal-company-select.component';
export class IntegrationGoogle extends Component {
  static contextType = UserStateContext;
  static propTypes = {};
  state = {
    accounts: [],
    deleteAccount: {},
    installId: false
  };
  componentDidMount() {
    const onLoad = this.props.initLoad('google');
    this.googleRedirect().then(hasRedirect => hasRedirect && this.props.history.push('/integrations')).then(() => this.getGoogleData()).finally(() => onLoad());
  }
  googleRedirect = () => {
    return new Promise(resolve => {
      const {
        match,
        location
      } = this.props;
      if (match.params.redirect === 'google-authorize') {
        const installId = new URLSearchParams(location.search).get('install_id');
        this.addAccount(installId).then(() => resolve(false));
      } else {
        resolve(false);
      }
    });
  };
  getGoogleData = () => {
    return getGoogleAccounts({
      company: this.context.asCompany.id
    }).then(({
      results
    }) => this.setState({
      accounts: results
    }));
  };
  addAccount = installId => {
    if (this.context.hasMultiAccess()) {
      this.setState({
        installId
      });
      return Promise.resolve(false);
    } else {
      return this.finallyAddAccount(installId, this.context.asCompany.id);
    }
  };
  finallyAddAccount = (installId, companyId) => {
    return addGoogleAccount(installId, companyId).then(() => toasterService.success('Successfully added account. Forms will start syncing momentarily')).then(() => this.getGoogleData()).catch(err => {
      const key = Object.keys(err.response.data)[0];
      toasterService.error(err.response.data[key][0]);
    });
  };
  removeAccount = accountId => {
    return deleteGoogleAccount(accountId).then(() => toasterService.success('Successfully deleted account')).then(() => this.getGoogleData()).catch(() => toasterService.error('Unknown error trying delete account. Please try again.'));
  };
  syncForms = accountId => {
    return syncGoogleForms(accountId).then(() => toasterService.success('Queued forms for syncing.')).catch(() => toasterService.error('Unknown error trying sync forms. Please try again.'));
  };
  render() {
    const {
      accounts,
      deleteAccount,
      installId
    } = this.state;
    return <div className="integration mb-5">
        <div className="integration__header">
          <div>Google Forms</div>
          {this.context.hasPermission('google.add_account') && <Button className="btn" actionType="primary" tag="a" target="_blank" href={`${API_URL}/google/account/request`}>
              Add Account
            </Button>}
        </div>
        <div className="integration__body">
          {accounts.map(account => <div className="integration-item" key={account.id}>
              <div className="integration-item__inner">
                <div className="integration-title">
                  <strong>{account.email}</strong>
                </div>
                <div className="integration-actions">
                  {this.context.hasPermission('google.change_account') && <Button onClick={e => {
                e.stopPropagation();
                this.syncForms(account.id);
              }} actionType="flat" icon="fa-regular-sync-alt" />}

                  {this.context.hasPermission('google.delete_account') && <Button actionType="flat" icon="fa-regular-trash" onClick={e => {
                e.stopPropagation();
                this.setState({
                  deleteAccount: account
                });
              }} />}
                </div>
              </div>
            </div>)}
        </div>

        <ModalCompanySelect open={!!installId} onClose={() => this.setState({
        installId: false
      })} onSubmit={companyId => this.finallyAddAccount(installId, companyId)} onCancel={() => toasterService.error('You must select a company to link your account')}>
          <p>Select which company to link this Google account.</p>
        </ModalCompanySelect>

        <ModalDialog open={!!deleteAccount.id} title="Remove Account" submitText="Remove" onSubmit={() => this.removeAccount(deleteAccount.id)} onClose={() => this.setState({
        deleteAccount: {}
      })} allowBackdropClick>
          <p>
            <strong>{deleteAccount.email}</strong>
          </p>
          <p>Are you sure you want to remove this account?</p>
        </ModalDialog>
      </div>;
  }
}