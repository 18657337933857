import React, { useRef } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography, Stack } from '@mui/material';
import { Form, Formik } from 'formik';
import { FormikTextField, FormikSelect, FormikTextarea } from 'components/mui/formik';
import * as Yup from 'yup';
import CloseIcon from '@mui/icons-material/Close';
const validationSchema = Yup.object().shape({
  key: Yup.string().required('Key is required'),
  type: Yup.string().required('Type is required'),
  subtype: Yup.string().when('type', {
    is: val => val?.includes('uuid'),
    then: () => Yup.string().required(),
    otherwise: () => Yup.string()
  })
});
export const EditParamModal = ({
  onClose,
  open,
  param,
  onSubmit,
  existing
}) => {
  const formRef = useRef();
  return <Formik initialValues={param || {
    key: '',
    type: '',
    subtype: ''
  }} validationSchema={validationSchema} validateOnBlur={true} validateOnChange={true} innerRef={formRef} onSubmit={onSubmit}>
      {({
      submitForm,
      isSubmitting,
      values,
      setFieldValue
    }) => <Dialog PaperProps={{
      style: {
        borderRadius: '28px',
        minWidth: '550px'
      }
    }} open={open} onClose={onClose}>
          <DialogTitle sx={{
        m: 0,
        p: 2,
        fontSize: 'medium'
      }}>
            <IconButton aria-label="close" onClick={onClose} sx={{
          position: 'absolute',
          right: 8,
          top: 15,
          color: theme => theme.palette.grey[500]
        }}>
              <CloseIcon />
            </IconButton>
            <Stack alignItems="center" component="span">
              <Typography variant="h4" component="span">
                Edit Journey Parameter
              </Typography>
            </Stack>
          </DialogTitle>
          <DialogContent>
            <Stack gap="24px" sx={{
          marginTop: '1rem'
        }}>
              <FormikTextField name="key" label="Key" value={values.key} error={values.key && !param && existing.some(p => p.key === values.key)} helperText={values.key && !param && existing.some(p => p.key === values.key) ? 'Key already exists' : ''} onChange={e => {
            const value = e.target.value || '';
            setFieldValue('key', value.toUpperCase().replace(/[\W_]+/g, '-').replace(/(^\W*)|(\W*$)/g, ''));
          }} />
              <FormikSelect name="type" label="Type" value={values.type} options={[{
            value: 'datetime',
            name: 'Date/Time'
          }, {
            value: 'cron',
            name: 'Cron Schedule'
          }, {
            value: 'int',
            name: 'Integer'
          }, {
            value: 'numeric',
            name: 'Numeric'
          }, {
            value: 'bool',
            name: 'Boolean'
          }, {
            value: 'text',
            name: 'Text'
          },
          //{ value: 'email', name: 'Email template' },
          {
            value: 'uuid',
            name: 'UUID'
          }]} />

              {values.type == 'uuid' && <FormikSelect name="subtype" label="SubType" value={values.subtype} options={[{
            value: 'segment',
            name: 'Segment ID'
          }, {
            value: 'email',
            name: 'Email ID'
          }, {
            value: 'mailer',
            name: 'Mailer ID'
          }, {
            value: 'sms',
            name: 'Text ID'
          }, {
            value: 'custom',
            name: 'Manually Entered UUID'
          }]} />}
            </Stack>
          </DialogContent>
          <DialogActions sx={{
        py: 1
      }}>
            <Button onClick={onClose} variant="text" sx={{
          borderRadius: '20px',
          color: 'rgba(29, 37, 45, 0.7)',
          width: '75px',
          textTransform: 'none'
        }}>
              Cancel
            </Button>
            <Button disabled={isSubmitting} onClick={submitForm} variant="text" sx={{
          mr: 1,
          borderRadius: '20px',
          width: '75px',
          color: '#53A6D6',
          textTransform: 'none'
        }}>
              Save
            </Button>
          </DialogActions>
        </Dialog>}
    </Formik>;
};