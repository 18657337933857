import React, { useContext, useState, useEffect } from 'react';
import { UserStateContext } from 'context/user-state-context';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper, Stack, TextField, Tooltip, Typography, Divider, FormControl, Select, MenuItem, InputLabel, Skeleton, Popper, ClickAwayListener, Switch, InputAdornment, Chip } from '@mui/material';
import { AddCircleIcon, AddIcon, CloseIcon, EditIcon, HelpIcon } from 'components/mui';
import { withStyles } from '@mui/styles';
import utils from '../../../shared/utils';
import { isValidPhoneNumber } from 'libphonenumber-js';
import isEmail from 'validator/lib/isEmail';
import { ModalDialog } from '../../../components/modal/modal-dialog.component';
const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius: `14px`,
      '& fieldset': {}
    }
  }
})(TextField);
export const CompanySmsSettingsInfoCard = props => {
  const {
    hasPermission
  } = useContext(UserStateContext);
  const [companySmsModal, setCompanySmsModal] = useState(null);
  const [companySmsModalFocus, setCompanySmsModalFocus] = useState(null);
  const [helpAnchorEl, setHelpAnchorEl] = React.useState(null);
  const [helpData, setHelpData] = React.useState(null);
  const [failureEmailInput, setFailureEmailInput] = useState('');
  const [failureSMSInput, setFailureSMSInput] = useState('');
  const [aFieldHasChanged, setAFieldHasChanged] = useState(false);
  const [openModalDisableSMS, setOpenModalDisableSMS] = useState(false);
  const handleHelpPopupClick = (e, index) => {
    if (index == 'company_sms') {
      setHelpData({
        title: <Typography variant="tableHeader"> SMS Settings</Typography>,
        content: <Stack spacing={'20px'}>
            <Typography component="div">
              <Box fontWeight="700" display="inline" sx={{
              pr: '3px'
            }}>
                Toggle SMS:
              </Box>
              Enable or disable all SMS and MMS messaging. Warning: be absolutely sure you want to do this.
            </Typography>
            <Typography component="div">
              <Box fontWeight="700" display="inline" sx={{
              pr: '3px'
            }}>
                SMS maximum failure rate:
              </Box>
              On a scale of 1 - 100, what percentage of messages fail before triggering an SMS failure alert?
            </Typography>
          </Stack>
      });
    }
    setHelpAnchorEl(e.currentTarget);
  };
  const handleHelpClose = () => {
    setHelpAnchorEl(null);
    setHelpData(null);
  };
  const handleSMSModalClose = () => {
    setCompanySmsModalFocus(null);
    setCompanySmsModal(null);
    setFailureEmailInput('');
    setFailureSMSInput('');
    setAFieldHasChanged(false);
  };
  const hasInputErrors = () => {
    if (companySmsModal?.sms_max_failure_rate == '' || isNaN(companySmsModal?.sms_max_failure_rate) || Number(companySmsModal?.sms_max_failure_rate) < 0 || Number(companySmsModal?.sms_max_failure_rate) > 100) {
      return true;
    }
    return false;
  };
  const hasBackendRejectionErrors = () => {
    if (props?.errors?.sms_max_failure_rate || props?.errors?.sms_failure_notify_emails || props?.errors?.sms_failure_notify_sms || props?.errors?.sms_enabled || props?.errors?.sms_failure_action) {
      return true;
    }
    return false;
  };
  const canEdit = () => {
    return props?.company?.id && hasPermission('organization.change_company');
  };
  return <>
      <Paper sx={{
      width: '65vw',
      maxWidth: '1200px',
      borderRadius: '14px',
      border: hasBackendRejectionErrors() ? '2px solid #EF3C34' : 'none'
    }}>
        <Stack>
          <Stack direction="row" spacing={'10px'}>
            <Box sx={{
            py: '20px',
            pl: '20px',
            display: 'flex',
            alignItems: 'center'
          }}>
              <Typography sx={{
              fontSize: '20px',
              fontWeight: '400',
              lineHeight: '16.8px'
            }}>SMS Settings</Typography>
            </Box>
            <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
              <IconButton onClick={e => handleHelpPopupClick(e, 'company_sms')}>
                <HelpIcon fill="#3898D9" size="sm" />
              </IconButton>
            </Box>
          </Stack>

          {/* enable SMS  */}

          <Box className="cardrow" sx={{
          py: '10px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          '&:hover': {
            backgroundColor: '#F3F3F4',
            cursor: 'pointer'
          }
        }} onClick={e => {
          if (canEdit()) {
            if (props?.company?.sms_enabled) {
              setOpenModalDisableSMS(true);
            } else {
              props.updateCompany({
                ...props.company,
                sms_enabled: true
              });
            }
          }
        }}>
            <Stack direction="row" spacing="10px" sx={{
            px: '20px'
          }}>
              <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                <Typography>Enable SMS</Typography>
              </Box>
            </Stack>
            {props?.errors?.sms_enabled && <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
                <Typography sx={{
              color: '#EF3C34'
            }}>{props?.errors?.sms_enabled}</Typography>
              </Box>}

            {props.loading ? <Box sx={{
            px: '20px',
            py: '8px',
            width: '30%'
          }}>
                <Skeleton variant="rectangular" width="100%" height={20} />
              </Box> : <Box sx={{
            pr: '35px'
          }}>
                <Switch disabled={!canEdit()} checked={props?.company?.sms_enabled} onChange={e => {
              if (props?.company?.sms_enabled) {
                setOpenModalDisableSMS(true);
              } else {
                props.updateCompany({
                  ...props.company,
                  sms_enabled: true
                });
              }
            }} />
              </Box>}
          </Box>
          <Box sx={{
          px: '20px'
        }}>
            <Divider />
          </Box>

          {/* sms max failure rate */}

          <Box className="cardrow" sx={{
          py: '10px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          '&:hover': {
            backgroundColor: '#F3F3F4',
            cursor: 'pointer'
          }
        }} onClick={e => {
          setCompanySmsModalFocus('sms_max_failure_rate');
          setCompanySmsModal(props.company);
        }}>
            <Stack direction="row" spacing="10px" sx={{
            px: '20px'
          }}>
              <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                <Typography>SMS maximum failure rate</Typography>
              </Box>
            </Stack>
            {props?.errors?.sms_max_failure_rate && <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
                <Typography sx={{
              color: '#EF3C34'
            }}>{props?.errors?.sms_max_failure_rate}</Typography>
              </Box>}
            {props.loading ? <Box sx={{
            px: '20px',
            py: '8px',
            width: '30%'
          }}>
                <Skeleton variant="rectangular" width="100%" height={20} />
              </Box> : <Stack sx={{
            pr: '6px'
          }} direction={'row'} spacing="4px">
                <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                  {props.company.sms_max_failure_rate ? <Typography>{props.company.sms_max_failure_rate} %</Typography> : <Typography sx={{
                opacity: 0.5
              }}>None</Typography>}
                </Box>
                <Box className="icons__remove">
                  {props.company.sms_max_failure_rate ? <Tooltip title={'Edit SMS maximum failure rate'}>
                      <IconButton>
                        <EditIcon />
                      </IconButton>
                    </Tooltip> : <Tooltip title={'Add SMS maximum failure rate'}>
                      <IconButton>
                        <AddIcon />
                      </IconButton>
                    </Tooltip>}
                </Box>
              </Stack>}
          </Box>
          <Box sx={{
          px: '20px'
        }}>
            <Divider />
          </Box>

          {/* sms failure action */}

          <Box className="cardrow" sx={{
          py: '10px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          '&:hover': {
            backgroundColor: '#F3F3F4',
            cursor: 'pointer'
          }
        }} onClick={e => {
          setCompanySmsModalFocus('sms_failure_action');
          setCompanySmsModal(props.company);
        }}>
            <Stack direction="row" spacing="10px" sx={{
            px: '20px'
          }}>
              <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                <Typography>SMS failure action</Typography>
              </Box>
            </Stack>
            {props?.errors?.sms_failure_action && <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
                <Typography sx={{
              color: '#EF3C34'
            }}>{props?.errors?.sms_failure_action}</Typography>
              </Box>}

            {props.loading ? <Box sx={{
            px: '20px',
            py: '8px',
            width: '30%'
          }}>
                <Skeleton variant="rectangular" width="100%" height={20} />
              </Box> : <Stack sx={{
            pr: '6px'
          }} direction={'row'} spacing="4px">
                <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                  <Typography>
                    {props.company.sms_failure_action == 'notify' ? 'Notify only' : props.company.sms_failure_action == 'notify_disable' ? 'Notify and disable' : 'No notification'}
                  </Typography>
                </Box>
                <Box className="icons__remove">
                  <Tooltip title={'Edit SMS failure action'}>
                    <IconButton>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Stack>}
          </Box>
          <Box sx={{
          px: '20px'
        }}>
            <Divider />
          </Box>

          {/* sms failure email notifications */}

          <Box className="cardrow" sx={{
          py: '10px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          '&:hover': {
            backgroundColor: '#F3F3F4',
            cursor: 'pointer'
          }
        }} onClick={e => {
          setCompanySmsModalFocus('sms_failure_notify_emails');
          setCompanySmsModal(props.company);
        }}>
            <Stack direction="row" spacing="10px" sx={{
            px: '20px'
          }}>
              <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                <Typography>SMS failure email notifications</Typography>
              </Box>
            </Stack>

            {!!Object.values(props?.errors?.sms_failure_notify_emails || {}).length && <Stack>
                {Object.values(props?.errors?.sms_failure_notify_emails)?.map((error, i) => <Typography key={i} sx={{
              color: '#EF3C34'
            }}>
                    Email #{i + 1} : {error}
                  </Typography>)}
              </Stack>}

            {props.loading ? <Box sx={{
            px: '20px',
            py: '8px',
            width: '30%'
          }}>
                <Skeleton variant="rectangular" width="100%" height={20} />
              </Box> : <Stack sx={{
            pr: '6px'
          }} direction={'row'} spacing="4px">
                <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                  {props.company.sms_failure_notify_emails?.length > 0 ? <>
                      {props.company.sms_failure_notify_emails?.length == 1 ? <Typography>{props.company.sms_failure_notify_emails[0].val} </Typography> : <Typography>{props.company.sms_failure_notify_emails?.length} recipients</Typography>}
                    </> : <Typography sx={{
                opacity: 0.5
              }}>None</Typography>}
                </Box>
                <Box className="icons__remove">
                  {props.company.sms_failure_notify_emails?.length > 0 ? <Tooltip title={'Edit SMS failure email notifications'}>
                      <IconButton>
                        <EditIcon />
                      </IconButton>
                    </Tooltip> : <Tooltip title={'Add SMS failure email notifications'}>
                      <IconButton>
                        <AddIcon />
                      </IconButton>
                    </Tooltip>}
                </Box>
              </Stack>}
          </Box>
          <Box sx={{
          px: '20px'
        }}>
            <Divider />
          </Box>

          {/* sms failure sms notifications */}

          <Box className="cardrow" sx={{
          py: '10px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          '&:hover': {
            backgroundColor: '#F3F3F4',
            cursor: 'pointer'
          }
        }} onClick={e => {
          setCompanySmsModalFocus('sms_failure_notify_sms');
          setCompanySmsModal(props.company);
        }}>
            <Stack direction="row" spacing="10px" sx={{
            px: '20px'
          }}>
              <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                <Typography>SMS failure SMS notifications</Typography>
              </Box>
            </Stack>

            {!!Object.values(props?.errors?.sms_failure_notify_sms || {}).length && <Stack>
                {Object.values(props?.errors?.sms_failure_notify_sms)?.map((error, i) => <Typography key={i} sx={{
              color: '#EF3C34'
            }}>
                    Phone #{i + 1} : {error}
                  </Typography>)}
              </Stack>}

            {props.loading ? <Box sx={{
            px: '20px',
            py: '8px',
            width: '30%'
          }}>
                <Skeleton variant="rectangular" width="100%" height={20} />
              </Box> : <Stack sx={{
            pr: '6px'
          }} direction={'row'} spacing="4px">
                <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                  {props.company.sms_failure_notify_sms?.length > 0 ? <>
                      {props.company.sms_failure_notify_sms?.length == 1 ? <Typography>
                          {utils.formatPhoneNumber(props?.company?.sms_failure_notify_sms[0]?.val) || props?.company?.sms_failure_notify_sms[0]?.val}
                        </Typography> : <Typography>{props.company.sms_failure_notify_sms?.length} recipients</Typography>}
                    </> : <Typography sx={{
                opacity: 0.5
              }}>None</Typography>}
                </Box>
                <Box className="icons__remove">
                  {props.company.sms_failure_notify_sms?.length > 0 ? <Tooltip title={'Edit SMS failure SMS notifications'}>
                      <IconButton>
                        <EditIcon />
                      </IconButton>
                    </Tooltip> : <Tooltip title={'Add SMS failure SMS notifications'}>
                      <IconButton>
                        <AddIcon />
                      </IconButton>
                    </Tooltip>}
                </Box>
              </Stack>}
          </Box>
          <Box sx={{
          px: '20px'
        }}>
            <Divider />
          </Box>
        </Stack>
      </Paper>

      <Dialog scroll="paper" PaperProps={{
      style: {
        borderRadius: '28px',
        maxWidth: '1080px'
      }
    }} open={!!companySmsModal} onClose={(e, r) => {
      if (r != 'backdropClick') {
        handleSMSModalClose();
      }
    }}>
        <DialogTitle sx={{
        m: 0,
        p: 2,
        fontSize: 'medium'
      }}>
          <Stack spacing={'20px'}>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Stack direction="row" spacing={'10px'}>
                <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                  <Typography sx={{
                  fontWeight: '300',
                  lineHeight: '30px',
                  fontSize: '24px',
                  display: 'flex',
                  alignItems: 'center'
                }} variant="h4" component="span">
                    SMS Settings
                  </Typography>
                </Box>
                <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                  <IconButton onClick={e => handleHelpPopupClick(e, 'company_sms')}>
                    <HelpIcon fill="#3898D9" size="sm" />
                  </IconButton>
                </Box>
              </Stack>
              <IconButton onClick={() => {
              handleSMSModalClose();
            }}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Divider />
          </Stack>
        </DialogTitle>

        <DialogContent sx={{
        //overflowY: 'scroll',
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
          width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
          marginTop: '20px',
          marginBottom: '20px'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#888'
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555'
        }
      }}>
          <Stack sx={{
          pt: 1
        }} spacing={'24px'}>
            <Stack spacing={'24px'} direction={'row'}>
              <CustomTextField disabled={!canEdit()} variant="outlined" fullWidth sx={{
              minWidth: '300px'
            }} autoFocus={companySmsModalFocus === 'sms_max_failure_rate'} error={companySmsModal?.sms_max_failure_rate == '' || isNaN(companySmsModal?.sms_max_failure_rate) || Number(companySmsModal?.sms_max_failure_rate) < 0 || Number(companySmsModal?.sms_max_failure_rate) > 100} helperText={companySmsModal?.sms_max_failure_rate == '' || isNaN(companySmsModal?.sms_max_failure_rate) || Number(companySmsModal?.sms_max_failure_rate) < 0 || Number(companySmsModal?.sms_max_failure_rate) > 100 ? 'Please enter a value between 0 and 100' : ''} label="SMS maximum failure rate" autoComplete="new-password" InputProps={{
              endAdornment: <InputAdornment position="end">
                      <Stack direction="row" spacing="5px">
                        <Box sx={{
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                          <Typography>%</Typography>
                        </Box>
                      </Stack>
                    </InputAdornment>
            }} value={companySmsModal?.sms_max_failure_rate || ''} onChange={e => {
              setAFieldHasChanged(true);
              setCompanySmsModal({
                ...companySmsModal,
                sms_max_failure_rate: e.target.value
              });
            }} />

              <FormControl sx={{
              width: '48%',
              minWidth: '300px'
            }}>
                <InputLabel id="sms_failure_action-label">Sms failure action</InputLabel>
                <Select disabled={!canEdit()} autoFocus={companySmsModalFocus === 'sms_failure_action'} labelId="sms_failure_action-label" id="sms_failure_action" value={companySmsModal?.sms_failure_action || 'none'} label="sms failure action____" onChange={e => {
                setAFieldHasChanged(true);
                setCompanySmsModal({
                  ...companySmsModal,
                  sms_failure_action: e.target.value == 'none' ? null : e.target.value
                });
              }}>
                  <MenuItem value="none">No notification</MenuItem>
                  <MenuItem value="notify">Notify only</MenuItem>
                  <MenuItem value="notify_disable">Notify and disable</MenuItem>
                </Select>
              </FormControl>
            </Stack>
            <Divider />
            <Stack spacing={'24px'} direction={'row'}>
              <CustomTextField disabled={!canEdit()} variant="outlined" sx={{
              minWidth: '300px'
            }} autoFocus={companySmsModalFocus === 'sms_failure_notify_emails'} helperText={failureEmailInput && failureEmailInput != '' && !isEmail(failureEmailInput) ? 'Please enter a valid email' : ''} error={failureEmailInput && failureEmailInput != '' && !isEmail(failureEmailInput)} label="SMS failure email notifications" placeholder="Add email address" autoComplete="new-password" value={failureEmailInput || ''} onChange={e => {
              setFailureEmailInput(e.target.value);
            }} />
              <Box sx={{
              pt: '7px'
            }}>
                <Tooltip title="Add email address to list">
                  <IconButton disabled={failureEmailInput?.length == 0 || !isEmail(failureEmailInput) || !canEdit()} onClick={e => {
                  setCompanySmsModal({
                    ...companySmsModal,
                    sms_failure_notify_emails: [...companySmsModal?.sms_failure_notify_emails, {
                      val: failureEmailInput
                    }]
                  });
                  setFailureEmailInput('');
                  setAFieldHasChanged(true);
                }}>
                    <AddCircleIcon fill={canEdit() && failureEmailInput?.length > 0 && isEmail(failureEmailInput) ? '#1D252D' : '#1D252D80'} />
                  </IconButton>
                </Tooltip>
              </Box>
              <Stack spacing={'10px'}>
                {companySmsModal?.sms_failure_notify_emails?.map((email, index) => <Stack key={index} direction={'row'} spacing={'10px'}>
                    <Chip sx={{
                  backgroundColor: '#C2E7FF'
                }} label={email.val} disabled={!canEdit()} onDelete={e => {
                  if (canEdit()) {
                    setCompanySmsModal({
                      ...companySmsModal,
                      sms_failure_notify_emails: companySmsModal?.sms_failure_notify_emails.filter((_, i) => i !== index)
                    });
                  }
                }} />
                  </Stack>)}
              </Stack>
            </Stack>
            <Divider />

            <Stack spacing={'24px'} direction={'row'}>
              <CustomTextField disabled={!canEdit()} variant="outlined" sx={{
              minWidth: '300px'
            }} autoFocus={companySmsModalFocus === 'sms_failure_notify_sms'} label="SMS failure SMS notifications" placeholder="Add phone number" autoComplete="new-password" helperText={failureSMSInput && failureSMSInput != '' && !isValidPhoneNumber(failureSMSInput, 'US') ? 'Please enter a valid number' : ''} error={failureSMSInput && failureSMSInput != '' && !isValidPhoneNumber(failureSMSInput, 'US')} value={failureSMSInput || ''} onChange={e => setFailureSMSInput(e.target.value)} />
              <Box sx={{
              pt: '7px'
            }}>
                <Tooltip title="Add phone number to the list">
                  <IconButton disabled={failureSMSInput?.length == 0 || !isValidPhoneNumber(failureSMSInput, 'US') || !canEdit()} onClick={e => {
                  setCompanySmsModal({
                    ...companySmsModal,
                    sms_failure_notify_sms: [...companySmsModal?.sms_failure_notify_sms, {
                      val: failureSMSInput
                    }]
                  });
                  setFailureSMSInput('');
                  setAFieldHasChanged(true);
                }}>
                    <AddCircleIcon fill={canEdit() && failureSMSInput?.length > 0 && isValidPhoneNumber(failureSMSInput, 'US') ? '#1D252D' : '#1D252D80'} />
                  </IconButton>
                </Tooltip>
              </Box>
              <Stack spacing={'10px'}>
                {companySmsModal?.sms_failure_notify_sms?.map((sms, index) => <Stack key={index} direction={'row'} spacing={'10px'}>
                    <Chip sx={{
                  backgroundColor: '#C2E7FF'
                }} label={utils.formatPhoneNumber(sms.val) || sms.val} disabled={!canEdit()} onDelete={e => {
                  if (canEdit()) {
                    setCompanySmsModal({
                      ...companySmsModal,
                      sms_failure_notify_sms: companySmsModal?.sms_failure_notify_sms.filter((_, i) => i !== index)
                    });
                  }
                }} />
                  </Stack>)}
              </Stack>
            </Stack>
            <Divider />
          </Stack>
        </DialogContent>

        <DialogActions sx={{
        pt: 1,
        pb: 2
      }}>
          <Stack sx={{
          pr: '24px'
        }} direction={'row'} spacing={'20px'}>
            <Button onClick={() => {
            handleSMSModalClose();
          }} variant="contained" sx={{
            borderRadius: '20px',
            textTransform: 'none'
          }}>
              Cancel
            </Button>
            <Button disabled={hasInputErrors() || !aFieldHasChanged || !canEdit()} onClick={() => {
            props.updateCompany(companySmsModal);
            handleSMSModalClose();
          }} variant="contained" sx={{
            mr: 1,
            borderRadius: '20px',
            textTransform: 'none',
            px: '20px'
          }}>
              Save
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>

      <Popper anchorEl={helpAnchorEl} open={!!helpData} onClose={handleHelpClose} placement="right-start" sx={{
      zIndex: 2100
    }}>
        <ClickAwayListener onClickAway={handleHelpClose}>
          <Paper elevation={0} sx={{
          borderRadius: '16px',
          overflow: 'visible',
          filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))',
          width: '312px'
        }}>
            <Stack>
              <Box sx={{
              padding: '16px'
            }}>{helpData?.title} </Box>
              <Box sx={{
              px: '16px'
            }}> {helpData?.content}</Box>
              <Box sx={{
              py: '16px'
            }}>
                <Button sx={{
                display: 'flex',
                justifyItems: 'left',
                textTransform: 'none'
              }} onClick={handleHelpClose}>
                  {helpData?.buttonText || ' Got it'}
                </Button>
              </Box>
            </Stack>
          </Paper>
        </ClickAwayListener>
      </Popper>

      <ModalDialog open={openModalDisableSMS} onClose={() => {
      setOpenModalDisableSMS(false);
    }} allowBackdropClick title="Disable All SMS/MMS Messages" onSubmit={() => {
      props.updateCompany({
        ...props.company,
        sms_enabled: false
      });
    }} submitText="Confirm">
        Are you sure you want to disable all SMS/MMS messages? This will immediately clear out all SMS and MMS messages from the queue and
        they are NOT recoverable. No automated SMS messages will be sent.
      </ModalDialog>
    </>;
};