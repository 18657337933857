import React from "react";
function Icon(props) {
  if (props.size == 'lg') {
    return <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 -960 960 960">
            <path fill={props.fill || '#1D252D'} d="M314.615-619.744q-10.679 0-17.903-7.263-7.224-7.263-7.224-18 0-10.736 7.224-17.864t17.903-7.128h490.257q10.679 0 17.903 7.263 7.224 7.263 7.224 17.999 0 10.737-7.224 17.865-7.224 7.128-17.903 7.128H314.615zm0 164.871q-10.679 0-17.903-7.262-7.224-7.263-7.224-18 0-10.736 7.224-17.864t17.903-7.128h490.257q10.679 0 17.903 7.262 7.224 7.263 7.224 18 0 10.736-7.224 17.864t-17.903 7.128H314.615zm0 164.872q-10.679 0-17.903-7.263-7.224-7.263-7.224-17.999 0-10.737 7.224-17.865 7.224-7.128 17.903-7.128h490.257q10.679 0 17.903 7.263 7.224 7.263 7.224 18 0 10.736-7.224 17.864t-17.903 7.128H314.615zM158.178-616.667q-11.998 0-20.088-8.117-8.089-8.116-8.089-20.115 0-11.998 8.116-20.088 8.117-8.089 20.115-8.089 11.999 0 20.088 8.116 8.09 8.117 8.09 20.115 0 11.999-8.117 20.088-8.116 8.09-20.115 8.09zm0 164.872q-11.998 0-20.088-8.117-8.089-8.117-8.089-20.115 0-11.998 8.116-20.088 8.117-8.09 20.115-8.09 11.999 0 20.088 8.117 8.09 8.117 8.09 20.115 0 11.998-8.117 20.088-8.116 8.09-20.115 8.09zm0 164.871q-11.998 0-20.088-8.116-8.089-8.117-8.089-20.115 0-11.999 8.116-20.088 8.117-8.09 20.115-8.09 11.999 0 20.088 8.117 8.09 8.116 8.09 20.115 0 11.998-8.117 20.088-8.116 8.089-20.115 8.089z"></path>
        </svg>;
  }
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 -960 960 960">
      <path fill={props.fill || '#1D252D'} d="M314.615-610.001q-12.75 0-21.374-8.628-8.625-8.629-8.625-21.384 0-12.756 8.625-21.371 8.624-8.615 21.374-8.615H800q12.75 0 21.375 8.628 8.624 8.629 8.624 21.384 0 12.756-8.624 21.371-8.625 8.615-21.375 8.615H314.615zm0 160q-12.75 0-21.374-8.628-8.625-8.629-8.625-21.384 0-12.756 8.625-21.371 8.624-8.615 21.374-8.615H800q12.75 0 21.375 8.628 8.624 8.629 8.624 21.384 0 12.756-8.624 21.371-8.625 8.615-21.375 8.615H314.615zm0 160q-12.75 0-21.374-8.628-8.625-8.629-8.625-21.384 0-12.756 8.625-21.371 8.624-8.615 21.374-8.615H800q12.75 0 21.375 8.628 8.624 8.629 8.624 21.384 0 12.756-8.624 21.371-8.625 8.615-21.375 8.615H314.615zM163.172-606.924q-14.018 0-23.594-9.482-9.577-9.482-9.577-23.5 0-14.017 9.482-23.594 9.482-9.576 23.5-9.576 14.017 0 23.594 9.482 9.577 9.482 9.577 23.5 0 14.017-9.483 23.594-9.482 9.576-23.499 9.576zm0 160q-14.018 0-23.594-9.482-9.577-9.482-9.577-23.5 0-14.017 9.482-23.594 9.482-9.576 23.5-9.576 14.017 0 23.594 9.482 9.577 9.482 9.577 23.5 0 14.017-9.483 23.594-9.482 9.576-23.499 9.576zm0 160q-14.018 0-23.594-9.482-9.577-9.482-9.577-23.5 0-14.017 9.482-23.594 9.482-9.576 23.5-9.576 14.017 0 23.594 9.482 9.577 9.482 9.577 23.5 0 14.017-9.483 23.594-9.482 9.576-23.499 9.576z"></path>
    </svg>;
}
export default Icon;