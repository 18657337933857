import React, { useEffect, useState, useContext } from 'react';
import { Scoped, k } from 'kremling';
import { NodeHelpResource } from './node-help-resource.component';
import { NodeLibrary } from './node-library.component';
import { ParameterManager } from './parameter-manager.component';
import { Box, Tab, Tabs, Stack } from '@mui/material';
import { UserStateContext } from 'context/user-state-context';
export const Toolbar = ({
  nodes,
  mode,
  setMode,
  appRef,
  revision,
  trigger,
  paramNodes,
  extraNodes = []
}) => {
  const {
    hasPermission,
    flags
  } = useContext(UserStateContext);
  const [search, setSearch] = useState('');
  const [tab, setTab] = useState('library');
  const [clickedNode, setClickedNode] = useState(null);
  const [changedSearchValue, setChangedSearchValue] = useState(false);
  const handleHelpClose = () => {
    setClickedNode(null);
  };
  useEffect(() => {
    setChangedSearchValue(true);
  }, [search]);
  return <Scoped css={css}>
      <Stack direction={'column'} spacing={0}>
        {hasPermission('journey.journey_architect') && flags?.journey_params && <Box sx={{
        borderBottom: 1,
        borderColor: 'divider'
      }}>
            <Tabs value={tab} onChange={(e, val) => setTab(val)}>
              <Tab sx={{
            textTransform: 'none'
          }} label="Library" value="library" />
              <Tab sx={{
            textTransform: 'none'
          }} label="Parameters" value="parameters" />
            </Tabs>
          </Box>}
        {tab === 'parameters' && <ParameterManager revision={revision} appRef={appRef} trigger={trigger} paramNodes={paramNodes} mode={mode} setMode={setMode} />}
        {tab === 'library' && (clickedNode && !changedSearchValue ? <NodeHelpResource node={clickedNode} onClose={handleHelpClose} onSearchChange={value => {
        setSearch(value);
      }} searchValue={search} /> : <NodeLibrary setSearch={setSearch} search={search} setClickedNode={setClickedNode} setChangedSearchValue={setChangedSearchValue} nodes={nodes} mode={mode} extraNodes={extraNodes} />)}
      </Stack>
    </Scoped>;
};
const css = {
  styles: `[kremling="i26"] body,body[kremling="i26"] {
  --color-primary: #487aae;
  --color-highlight: #e7efff;
  --color-accent: #354052;
  --color-success: #73b56e;
  --color-warning: #f4b707;
  --color-danger: #df5651;
  --color-grey-10: #f9f9f9;
  --color-grey-25: #f3f3f3;
  --color-grey-50: #e9e9e9;
  --color-grey-75: #e3e3e3;
  --color-grey-100: #d3d3d3;
  --color-grey-200: #c3c3c3;
  --color-grey-300: #b3b3b3;
  --color-grey-400: #a3a3a3;
  --color-grey-500: #808080;
  --color-grey-600: #707070;
  --color-grey-700: #606060;
  --color-grey-800: #505050;
  --color-grey-900: #404040;
  --base-font-family: Roboto, Helvetica, Arial, sans-serif;
  --base-font-size: 1.4rem;
  --base-font-color: #404040;
  --base-font-weight: 400;
  --base-line-height: 1.4;
  --box-shadow-1: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.12);
  --box-shadow-2: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-3: 0 10px 20px rgba(0, 0, 0, 0.17), 0 6px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-4: 0 14px 28px rgba(0, 0, 0, 0.13), 0 10px 10px rgba(0, 0, 0, 0.1);
  --box-shadow-5: 0 19px 38px rgba(0, 0, 0, 0.18), 0 15px 12px rgba(0, 0, 0, 0.1);
  --base-border-radius: 0.4rem;
}`,
  id: 'i26',
  namespace: 'kremling'
};