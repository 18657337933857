class Util {
  constructor() {
    this.shakingSprites = [];
  }
  update() {
    if (this.shakingSprites.length > 0) {
      for (let i = this.shakingSprites.length - 1; i >= 0; i--) {
        let shakingSprite = this.shakingSprites[i];
        if (shakingSprite.updateShake) shakingSprite.updateShake();
      }
    }
  }
  shake(sprite, magnitude = 16, angular = false) {
    let self = this;
    let counter = 1;
    let numberOfShakes = 30;
    let startX = sprite.x,
      startY = sprite.y,
      startAngle = sprite.rotation;
    let magnitudeUnit = magnitude / numberOfShakes;
    let randomInt = (min, max) => {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    };
    if (self.shakingSprites.indexOf(sprite) === -1) {
      self.shakingSprites.push(sprite);
      sprite.updateShake = () => {
        if (angular) {
          angularShake();
        } else {
          upAndDownShake();
        }
      };
    }
    function upAndDownShake() {
      if (counter < numberOfShakes) {
        sprite.x = startX;
        sprite.y = startY;
        magnitude -= magnitudeUnit;
        sprite.x += randomInt(-magnitude, magnitude);
        sprite.y += randomInt(-magnitude, magnitude);
        counter += 1;
      }
      if (counter >= numberOfShakes) {
        sprite.x = startX;
        sprite.y = startY;
        self.shakingSprites.splice(self.shakingSprites.indexOf(sprite), 1);
      }
    }
    let tiltAngle = 1;
    function angularShake() {
      if (counter < numberOfShakes) {
        sprite.rotation = startAngle;
        magnitude -= magnitudeUnit;
        sprite.rotation = magnitude * tiltAngle;
        counter += 1;
        tiltAngle *= -1;
      }
      if (counter >= numberOfShakes) {
        sprite.rotation = startAngle;
        self.shakingSprites.splice(self.shakingSprites.indexOf(sprite), 1);
      }
    }
  }
}
export const pixiUtils = new Util();