import React from 'react';
export const Number = props => {
  const {
    param,
    update,
    data
  } = props;
  return <div className="form-group">
      <label>{param.label}</label>
      <input className="form-control" type="number" placeholder={param.placeholder} value={data[param.key] || ''} onChange={e => update({
      [param.key]: parseInt(e.target.value)
    })} />
      {param.description && <small className="form-text text-muted">{param.description}</small>}
    </div>;
};