class SnackbarService {
  popup(arg) {
    this.toast({
      open: true,
      ...arg
    });
  }
  close() {
    this.toast({
      open: false
    });
  }
  toast() {
    console.warn(`<SnackbarToaster /> component must be mounted before using the snackbarService`);
  }
  passToastFunc(toast) {
    this.toast = toast;
  }
}
export const snackbarService = new SnackbarService();