import React from 'react';
function Icon() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="316" height="109" fill="none" viewBox="0 0 316 109">
      <g clipPath="url(#clip0_64_4964)">
        <path stroke="#E6E7E8" strokeMiterlimit="10" strokeWidth="0.5" d="M18.38 0v87.37M.903 43.68h314.194M.903 17.288h314.194M.903 70.081h314.194M53.327 0v87.37"></path>
        <path fill="#fff" d="M69.288 31.243H37.257v24.295h32.031V31.243z"></path>
        <path stroke="#E6E7E8" strokeMiterlimit="10" strokeWidth="0.5" d="M88.273 0v87.37M123.219 0v87.37M158.165 0v87.37M193.11 0v87.37M228.066 0v87.37M263.013 0v87.37M297.958 0v87.37"></path>
        <path fill="#E6E7E8" d="M53.327 46.175a3.773 3.773 0 100-7.546 3.773 3.773 0 000 7.546zM44.065 55.728s4.92-4.123 9.203-3.743c4.282.379 6.957 1.706 8.764 3.992l-17.967-.25z"></path>
        <path fill="#DADCE0" d="M38.924 61.377v-1.916H67.72v1.916H38.924zm0-34.107v-1.916H67.72v1.916H38.924zm14.403 19.933c1.328 0 2.466-.469 3.394-1.407.938-.938 1.408-2.066 1.408-3.394 0-1.327-.47-2.465-1.408-3.394-.938-.938-2.066-1.407-3.394-1.407-1.327 0-2.465.47-3.393 1.407-.939.939-1.408 2.067-1.408 3.394 0 1.328.47 2.466 1.408 3.394.938.938 2.066 1.407 3.393 1.407zm-14.184 9.593c-.888 0-1.617-.3-2.216-.889-.588-.588-.888-1.327-.888-2.215V33.019c0-.888.3-1.617.888-2.215.59-.59 1.328-.889 2.216-.889h28.358c.889 0 1.617.3 2.216.889.6.588.889 1.327.889 2.215v20.672c0 .889-.3 1.617-.889 2.216-.589.6-1.327.889-2.216.889H39.143zm4.692-1.917c1.437-1.297 2.954-2.265 4.531-2.894a13.29 13.29 0 014.961-.949c1.727 0 3.374.32 4.961.949 1.577.629 3.095 1.597 4.532 2.894h4.691c.3 0 .57-.12.809-.369.25-.25.37-.52.37-.808V33.029c0-.299-.12-.569-.37-.808-.25-.25-.52-.37-.809-.37H39.153c-.3 0-.569.12-.808.37-.25.25-.37.519-.37.808v20.672c0 .3.12.57.37.809.25.25.519.37.808.37h4.692-.01zm3.154 0h12.667a10.55 10.55 0 00-3.005-1.437 11.657 11.657 0 00-3.334-.48c-1.148 0-2.266.16-3.334.48a10.68 10.68 0 00-3.004 1.437h.01zm6.338-9.602c-.788 0-1.467-.28-2.036-.849s-.848-1.237-.848-2.036c0-.798.28-1.467.848-2.036.569-.569 1.238-.849 2.036-.849.799 0 1.468.28 2.037.849s.848 1.238.848 2.036c0 .799-.28 1.467-.848 2.036-.57.57-1.238.849-2.037.849z"></path>
        <path stroke="#E6E7E8" strokeMiterlimit="10" strokeWidth="1.5" d="M232.239 6.768v3.942"></path>
        <path fill="#F3F3F4" stroke="#DADCE0" strokeMiterlimit="10" strokeWidth="1.5" d="M223.479 6.768l61.238 61.237V6.768h-61.238zm50.707 34.626l-24.115-24.116h24.115v24.116z"></path>
        <path stroke="#DADCE0" strokeMiterlimit="10" strokeWidth="1.5" d="M236.61 6.768v3.942M240.982 6.768v3.942M245.354 6.768v3.942M249.727 6.768v3.942M254.099 6.768v3.942M258.471 6.768v3.942M262.843 6.768v3.942M267.215 6.768v3.942M271.587 6.768v3.942M275.959 6.768v3.942M280.331 6.768v3.942"></path>
        <path fill="#fff" stroke="#808184" strokeMiterlimit="10" d="M103.196 25.952h109.319c1.098 0 1.996.899 1.996 1.997v73.036H101.199V27.949c0-1.098.899-1.997 1.997-1.997z"></path>
        <path fill="#F3F3F4" stroke="#808184" strokeMiterlimit="10" d="M209.521 30.943H106.19v65.05h103.331v-65.05z"></path>
        <path fill="#FFB82B" d="M193.271 35.245l.479 4.432 3.553-2.695.479 4.432 3.544-2.695 1.677-11.828-2.286-.979-7.446 9.333z"></path>
        <path fill="#3898D9" d="M191.564 39.198l1.707-3.953-22.619 52.444v20.812h.579l30.095-69.782-1.707 3.953-8.055-3.474z"></path>
        <path fill="#3898D9" d="M197.303 36.982l-3.554 2.695-.479-4.432-1.707 3.953 8.056 3.474 1.707-3.953-3.544 2.695-.479-4.432z"></path>
        <path fill="#3B4043" d="M203.462 23.677l-.459 3.214.459-3.214zM200.717 25.913l2.026-2.546-2.026 2.546zM202.743 23.367l-2.026 2.535 2.286.989.459-3.214c.06-.41-.459-.63-.719-.31z"></path>
        <path fill="#BBBDBF" d="M139.928 100.825c.868 2.356 3.364 3.593 5.53 2.735 2.166-.858 3.134-3.474 2.136-5.79L122.47 39.299l-4.432 1.757 21.88 59.76.01.01z"></path>
        <path fill="#B5E1FF" d="M122.47 39.298h.01l-1.448-3.364h-.01l1.448 3.364zM119.056 37.172c-.749.3-1.517.439-2.276.459l1.258 3.444 4.432-1.757-1.448-3.364a6.283 6.283 0 01-1.966 1.228v-.01z"></path>
        <path fill="#C3996B" d="M120.613 34.986l.41.948c1.956-1.777 2.725-4.641 1.687-7.247a6.44 6.44 0 00-.999-1.707c-1.427-2.136-4.501-5.779-9.911-8.055-2.366 5.38-2.097 10.132-1.667 12.667.05.649.189 1.298.449 1.927a6.528 6.528 0 006.198 4.112l-.359-.978 4.192-1.667z"></path>
        <path fill="#C3996B" d="M121.023 35.934h.01l-.42-.948.41.948zM116.432 36.653l.349.968-.349-.968z"></path>
        <path fill="#C3996B" d="M121.022 35.934l-.409-.958-4.192 1.667.359.978a6.483 6.483 0 004.242-1.687z"></path>
        <path fill="#FFEABF" d="M159.822 85.234v.998h-3.913l.888 3.324h3.025v.998h-2.765l.888 3.314h6.249v.998h-5.979l.888 3.314h.719v.998h-.449l.09.33-.25.06-.629 2.934h1.238v.998h-1.457l-.709 3.314h4.751l8.245-19.125V20.612H155.37v4.213h4.452v.998h-4.452v3.314h8.824v.998h-8.824v3.324h4.452v.998h-4.452v3.314h4.452v.998h-4.452v3.314h8.824v.998h-8.824v3.324h4.452v.998h-4.452v3.314h4.452v.998h-4.452v3.314h8.824v.998h-8.824v3.324h4.452v.998h-4.452v3.314h4.452v.998h-4.452v3.314h8.824v.998h-8.824v3.324h4.452v.999h-4.452v3.313h4.452v.999h-4.452v3.313h8.824v.999h-8.824v2.305l.27 1.009h4.182zM157.436 107.812l-.15.689h4.392l.3-.689h-4.542zM156.298 107.812h-.928v.689h1.557l-.629-.689zM155.37 106.784v.03h.03l-.03-.03z"></path>
        <path fill="#FFEABF" d="M164.194 106.814v.998h-2.216l-.299.689h8.973V87.689l-8.245 19.125h1.787z"></path>
        <path fill="#FFB82B" d="M159.822 24.825h-4.452v.998h4.452v-.998zM164.194 29.137h-8.824v.998h8.824v-.998zM159.822 33.459h-4.452v.998h4.452v-.998zM159.822 37.77h-4.452v.999h4.452v-.998zM164.194 42.083h-8.824v.998h8.824v-.998zM159.822 46.405h-4.452v.998h4.452v-.998zM159.822 50.717h-4.452v.998h4.452v-.998zM164.194 55.029h-8.824v.998h8.824v-.998zM159.822 59.351h-4.452v.998h4.452v-.998zM159.822 63.663h-4.452v.998h4.452v-.998zM164.194 67.975h-8.824v.998h8.824v-.998zM159.822 72.297h-4.452v.999h4.452v-.999zM159.822 76.61h-4.452v.998h4.452v-.999zM164.194 80.921h-8.824v.999h8.824v-.999zM159.822 86.232v-.998h-4.182l.269.998h3.913zM159.821 90.554v-.998h-3.024l.259.998h2.765zM164.194 94.866v-.998h-6.249l.27.998h5.979zM159.333 99.018l.04.16h.449v-.998h-.718l.229.838zM159.822 102.502h-1.238l-.22.998h1.458v-.998zM161.978 107.812l.429-.998h-4.751l-.219.998h4.541zM155.4 106.814h-.03v.998h.928l-.898-.998z"></path>
        <path fill="#FFB82B" d="M164.193 107.812v-.998h-1.786l-.429.998h2.215z"></path>
        <path fill="#3EB87B" d="M155.37 99.178V84.226l-8.235-30.694-8.844 2.365-.01-.04 12.078 45.058 5.011-1.348v-.389zM143.552 40.156a4.574 4.574 0 00-5.61-3.234 4.573 4.573 0 00-3.234 5.61l.948 3.534 8.844-2.376-.948-3.534zM135.676 46.135l.11.4-.11-.4zM150.478 101.394l-.04-.17.04.17zM155.37 103.5v-2.895l-4.512 1.208 4.512 4.971V103.5zM155.909 86.232h-.539v3.324h1.427l-.888-3.324z"></path>
        <path fill="#3EB87B" d="M157.057 90.554h-1.687v3.314h2.575l-.888-3.314zM157.656 106.814l.709-3.314h-2.995v3.284l.03.03h2.256zM158.215 94.866h-2.845v3.314h3.733l-.888-3.314zM155.64 85.234l-.27-1.008v1.008h.27zM156.298 107.812l.629.689h.359l.15-.689h-1.138zM155.37 99.178v.39l1.427-.39h-1.427zM159.213 99.567l-3.843 1.038v1.897h3.214l.629-2.935zM155.64 85.234h-.27v.998h.539l-.269-.998zM156.797 89.556h-1.427v.998h1.687l-.26-.998zM157.945 93.868h-2.575v.998h2.845l-.27-.998z"></path>
        <path fill="#3EB87B" d="M159.203 98.54l.13.478-.23-.838h-3.733v.998h1.427l2.406-.639zM155.37 102.502v.998h2.995l.219-.998h-3.214zM157.655 106.814h-2.256l.899.998h1.138l.219-.998z"></path>
        <path fill="#5F6369" d="M150.609 101.874l.25-.06-.38-.42.13.48zM150.359 100.915l.08.31-.08-.31zM159.213 99.568l.25-.06-.09-.33h-.08l-.08.39zM159.293 99.178h.08l-.04-.16-.04.16z"></path>
        <path fill="#5F6369" d="M155.37 99.567l-5.011 1.348.08.309.04.17.379.419 4.512-1.208v-1.038zM159.293 99.178h-2.496l-1.427.39v1.037l3.843-1.038.08-.389z"></path>
        <path fill="#5F6369" d="M159.202 98.54l-2.405.638h2.495l.04-.16-.13-.479z"></path>
        <path fill="#C5EAD7" d="M146.876 52.563l.04-.01.13.49-2.256-8.405-.03.01 2.116 7.915z"></path>
        <path fill="#C5EAD7" d="M146.876 52.563l-2.116-7.915-8.844 2.376-.13-.49 2.246 8.405 8.844-2.376z"></path>
        <path fill="#3B4043" d="M145.428 44.468c.329-.09.658.1.748.42l7.616 26.321.959-.28-7.616-26.32a1.617 1.617 0 00-1.967-1.109l-.639.17.26.968.639-.17zM147.036 53.043l-.029.008.129.483.029-.008-.129-.483zM138.161 55.418l-.13-.479.25.919-.12-.44z"></path>
        <path fill="#3B4043" d="M147.135 53.532l-.13-.48-8.844 2.366.12.44.01.04 8.844-2.366zM146.906 52.564l-.029.008.129.483.029-.008-.129-.483z"></path>
        <path fill="#3B4043" d="M146.882 52.553l-8.851 2.373.13.482 8.85-2.373-.129-.482zM144.66 44.159l.129.48-.259-.96-.03.01.13.48.03-.01zM135.656 46.056l.02.08-.02-.08z"></path>
        <path fill="#3B4043" d="M144.63 44.169l-.13-.48-8.844 2.367.02.08.11.399 8.844-2.366zM144.65 44.18l-.029.009.13.483.028-.008-.129-.483z"></path>
        <path fill="#3B4043" d="M144.631 44.148l-8.849 2.381.13.482 8.848-2.38-.129-.483z"></path>
        <path fill="#fff" d="M209.521 95.984H106.19v4.991h103.331v-4.991z"></path>
        <path fill="#fff" stroke="#808184" strokeMiterlimit="10" d="M96.487 100.975h122.735v3.174a4.355 4.355 0 01-4.352 4.352H100.83a4.356 4.356 0 01-4.352-4.352v-3.174h.01z"></path>
        <path stroke="#808184" strokeMiterlimit="10" d="M106.19 95.984h103.331"></path>
        <path stroke="#E6E7E8" strokeMiterlimit="10" strokeWidth="1.5" d="M46.16 108.251h40.346M229.204 108.251h40.356"></path>
        <path fill="#fff" d="M263.122 33.678l-5.25 2.216c-.639-.709-1.468-1.377-2.476-1.996-1.018-.62-1.986-.988-2.925-1.108l-.658-5.55h-4.163l-.619 5.51c-1.078.21-2.056.559-2.934 1.068-.879.499-1.737 1.178-2.585 2.036l-5.171-2.176-1.996 3.474 4.541 3.324c-.159.489-.269.998-.339 1.537a13.04 13.04 0 00-.11 1.687c0 .539.04 1.068.11 1.577.07.509.17 1.018.309 1.537l-4.501 3.364 1.996 3.474 5.141-2.177a10.661 10.661 0 002.535 1.967 11.8 11.8 0 002.984 1.128l.659 5.51h4.123l.738-5.55a11.686 11.686 0 002.815-1.128c.859-.49 1.717-1.148 2.595-1.966l5.141 2.216 1.996-3.474-4.541-3.364a27.79 27.79 0 00.309-1.667c.07-.499.11-.988.11-1.477 0-.54-.03-1.048-.1-1.517-.07-.47-.17-.989-.309-1.557l4.611-3.444-1.996-3.474h-.04zm-10.131 13.386c-.919.918-2.047 1.377-3.384 1.377-1.338 0-2.436-.459-3.364-1.377-.928-.919-1.398-2.047-1.398-3.384 0-1.338.47-2.466 1.398-3.384.928-.918 2.056-1.377 3.364-1.377 1.307 0 2.465.459 3.384 1.377.918.918 1.377 2.046 1.377 3.384 0 1.337-.459 2.465-1.377 3.384z"></path>
        <path fill="#DADCE0" d="M263.821 31.772l-5.43 2.316a13.571 13.571 0 00-2.146-1.528 17.277 17.277 0 00-2.386-1.098l-.808-5.75h-6.648l-.848 5.76c-.759.23-1.558.58-2.376 1.048a16.74 16.74 0 00-2.266 1.557l-5.24-2.315-3.334 5.81 4.701 3.523c-.07.479-.12.938-.159 1.387-.04.45-.06.858-.06 1.238 0 .4.02.828.06 1.267.039.45.099.869.159 1.278l-4.701 3.484 3.334 5.919 5.27-2.386c.609.56 1.358 1.088 2.226 1.577.878.49 1.687.849 2.416 1.078l.808 5.71h6.648l.808-5.75a9.384 9.384 0 002.326-1.038 17.558 17.558 0 002.206-1.617l5.35 2.426 3.334-5.92-4.811-3.513c.09-.509.16-.978.2-1.397.04-.42.059-.809.059-1.168 0-.38-.009-.789-.039-1.208-.03-.42-.1-.858-.22-1.328l4.851-3.573-3.294-5.81.01.02zm-3.314 8.834c.14.569.24 1.087.31 1.557.069.469.099.978.099 1.517 0 .489-.039.978-.109 1.477-.07.5-.17 1.058-.31 1.667l4.542 3.364-1.996 3.473-5.141-2.215c-.878.828-1.737 1.477-2.595 1.966-.859.49-1.787.868-2.815 1.128l-.739 5.55h-4.122l-.659-5.51c-1.098-.27-2.096-.639-2.985-1.128-.888-.49-1.736-1.148-2.535-1.966l-5.14 2.175-1.997-3.473 4.502-3.364a14.18 14.18 0 01-.309-1.537c-.07-.51-.11-1.038-.11-1.577 0-.59.04-1.158.11-1.687a9.208 9.208 0 01.339-1.537l-4.542-3.324 1.997-3.474 5.17 2.176c.839-.858 1.707-1.537 2.585-2.036a9.623 9.623 0 012.935-1.068l.619-5.51h4.162l.659 5.55c.938.12 1.906.479 2.925 1.108 1.018.619 1.846 1.287 2.475 1.996l5.25-2.216 1.997 3.474-4.612 3.444h.04z"></path>
        <path fill="#DADCE0" d="M252.991 40.296c-.919-.918-2.046-1.377-3.384-1.377s-2.436.459-3.364 1.377c-.928.918-1.397 2.046-1.397 3.384 0 1.337.469 2.465 1.397 3.384.928.918 2.056 1.377 3.364 1.377 1.308 0 2.465-.46 3.384-1.377.918-.919 1.377-2.047 1.377-3.384 0-1.338-.459-2.466-1.377-3.384zm-1.078 5.68c-.629.629-1.388.938-2.296.938-.908 0-1.657-.31-2.286-.938a3.118 3.118 0 01-.948-2.296c0-.908.319-1.677.948-2.296.629-.629 1.398-.938 2.286-.938.888 0 1.677.31 2.296.938.619.629.938 1.388.938 2.296 0 .908-.309 1.677-.938 2.296z"></path>
      </g>
      <defs>
        <clipPath id="clip0_64_4964">
          <path fill="#fff" d="M0 0H314.193V109H0z" transform="translate(.903)"></path>
        </clipPath>
      </defs>
    </svg>;
}
export default Icon;