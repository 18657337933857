import React from 'react';
function Icon(props) {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <g>
        <mask id="mask0_240_17468" style={{
        maskType: 'alpha'
      }} width="24" height="24" x="0" y="0" maskUnits="userSpaceOnUse">
          <path fill="#D9D9D9" d="M0 0H24V24H0z"></path>
        </mask>
        <g mask="url(#mask0_240_17468)">
          <g>
            <path fill="#1C1B1F" d="M8.058 17.75c-.505 0-.933-.175-1.283-.525a1.745 1.745 0 01-.525-1.283V4.558c0-.505.175-.933.525-1.283.35-.35.778-.525 1.283-.525h11.384c.505 0 .933.175 1.283.525.35.35.525.778.525 1.283v11.384c0 .505-.175.933-.525 1.283-.35.35-.778.525-1.283.525H8.058zm0-1.5h11.384a.294.294 0 00.212-.096.294.294 0 00.096-.212V4.558a.294.294 0 00-.096-.212.294.294 0 00-.212-.096H8.058a.294.294 0 00-.212.096.294.294 0 00-.096.212v11.384c0 .077.032.148.096.212a.294.294 0 00.212.096zm-3.5 5c-.505 0-.933-.175-1.283-.525a1.745 1.745 0 01-.525-1.283V7.308c0-.213.072-.391.216-.535a.726.726 0 01.534-.215c.213 0 .391.072.535.215a.727.727 0 01.215.535v12.134c0 .077.032.148.096.212a.294.294 0 00.212.096h12.134c.213 0 .391.072.535.215a.726.726 0 01.215.535.726.726 0 01-.215.535.726.726 0 01-.535.215H4.558z"></path>
            <path fill={props.fill || '#1D252D'} fillRule="evenodd" d="M14.503 7.504a.75.75 0 00-1.5 0v2h-2a.75.75 0 000 1.5h2v2a.75.75 0 001.5 0v-2h2a.75.75 0 000-1.5h-2v-2z" clipRule="evenodd"></path>
          </g>
        </g>
      </g>
    </svg>;
}
export default Icon;