import React, { useState, useEffect, useContext } from 'react';
import { Scoped } from 'kremling';
import moment from 'moment';
import 'react-toastify/dist/ReactToastify.min.css';
import { PageHeader } from '../../components/page-header/page-header';
import styles from './users.styles.scss';
import { Button } from '../../components/button/button.component';
import { Dropdown } from '../../components/dropdown/dropdown.component';
import { UserStateContext } from 'context/user-state-context';
import { Loader } from '../../components/loader/loader.component';
import { Pagination } from '../../components/pagination/pagination.component';
import { getUsers, getRoles, getUserLocations, getMarkets, getLocations, replaceUserToLocation, replaceUserToMarket } from '../../shared/common.api';
import { deleteUserCompany, inviteUser as inviteUserAPI, updateUserCompany } from '../../shared/common.api';
import { SortHeader } from '../../components/table/sort-header.component';
import { ModalDialog } from '../../components/modal/modal-dialog.component';
import { Modal } from '../../components/modal/modal.component';
import { toasterService } from '../../components/toaster/toaster-service';
import { Icon, PageHeaderMui } from 'components';
import CssBaseline from '@mui/material/CssBaseline';
import { Autocomplete, Box, Button as MuiButton, Checkbox, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, Stack, TextField, Typography, Tooltip } from '@mui/material';
import { CloseIcon } from 'components/mui';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import EnhancedTable from 'components/mui/enhanced-table/enhanced-table.component';
import { DeleteIcon, EditIcon } from 'components/mui';
import { NewButton } from 'components/mui/button/new.component';
const UsersList = ({
  history
}) => {
  const {
    asCompany,
    hasPermission,
    user,
    flags
  } = useContext(UserStateContext);
  const [renderIteration, setRenderIteration] = useState(0);
  const [roles, setRoles] = useState([]);
  const [inviteUser, setInviteUser] = useState(false);
  const [inviteUserEmail, setInviteUserEmail] = useState('');
  const [inviteUserRole, setInviteUserRole] = useState('');
  const [inviteUserFirstName, setInviteUserFirstName] = useState('');
  const [inviteUserLastName, setInviteUserLastName] = useState('');
  const [removeUser, setRemoveUser] = useState();
  const [changeRole, setChangeRole] = useState();
  const [changeRoleRole, setChangeRoleRole] = useState('');
  const [userLocationsLoading, setUserLocationsLoading] = useState(true);
  const [locationsData, setLocationsData] = useState([]);
  const [selectedUserLocations, setSelectedUserLocations] = useState([]);
  const [selectedUserLocationsChanged, setSelectedUserLocationsChanged] = useState(false);
  const [marketsData, setMarketsData] = useState([]);
  const [selectedMarkets, setSelectedMarkets] = useState([]);
  const [selectedMarketsChanged, setSelectedMarketsChanged] = useState(false);
  const [saving, setSaving] = useState(false);
  useEffect(() => {
    if (hasPermission('authentication.view_role')) {
      getRoles({
        offset: 0,
        limit: 1000,
        company: asCompany.id,
        ordering: 'name'
      }).then(({
        data
      }) => {
        if (data) {
          if (!flags.catalyst_user) {
            setRoles(data.results.filter(role => role.display_name !== 'Catalyst'));
          } else {
            setRoles(data.results);
          }
        }
      });
    }
  }, []);
  const headCells = [{
    key: 'first_name',
    label: 'First Name',
    width: '16.66%',
    displayComponent: ({
      data: user
    }) => user?.first_name || ''
  }, {
    key: 'last_name',
    label: 'Last Name',
    width: '16.66%',
    displayComponent: ({
      data: user
    }) => user?.last_name || ''
  }, {
    key: 'email',
    label: 'Email',
    width: '16.66%',
    displayComponent: ({
      data: user
    }) => user?.email || ''
  }, {
    key: 'created_when',
    label: 'Created',
    width: '16.66%',
    displayComponent: ({
      data: user
    }) => moment(user?.created_when).format('MMM D, YYYY')
  }, {
    key: 'last_login',
    label: 'Last Login',
    width: '16.66%',
    displayComponent: ({
      data: user
    }) => user.last_login === null ? 'N/A' : moment(user?.last_login).format('MMM D, YYYY')
  }, {
    key: 'role',
    label: 'Role',
    noSort: true,
    width: '16.66%',
    displayComponent: ({
      data: user
    }) => user.is_superuser === true ? 'Superuser' : user.role
  }, {
    key: 'tools',
    noSort: true,
    displayComponent: ({
      data: row,
      onHover
    }) => {
      if (row.id === onHover.row) {
        return <Box sx={{
          position: 'absolute'
        }}>
              <Stack direction="row" spacing={1} sx={{
            position: 'absolute',
            right: 0,
            top: '-18px',
            justifyContent: 'flex-end'
          }}>
                {(!row.role_is_private || user.is_superuser) && hasPermission('authentication.change_usertocompany') && <Tooltip title="Change Role">
                    <IconButton onClick={e => {
                e.stopPropagation();
                setChangeRole(row);
                const r = roles.find(role => role.display_name == row.role);
                if (r) {
                  setChangeRoleRole(r.id);
                  if (r.display_name == 'Catalyst') {
                    handleEditLocations(row);
                  }
                }
              }}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>}
                {(!row.role_is_private || user.is_superuser) && hasPermission('authentication.delete_usertocompany') && <Tooltip title="Remove User">
                    <IconButton onClick={e => {
                e.stopPropagation();
                setRemoveUser(row);
              }}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>}
              </Stack>
            </Box>;
      }
      return <></>;
    }
  }];
  const actuallyRemoveUser = () => {
    if (user.id === removeUser.id) {
      toasterService.error('You cannot remove yourself');
    } else {
      deleteUserCompany(removeUser.user_to_company).then(() => {
        setRenderIteration(i => i + 1);
        toasterService.success('Successfully removed user');
      }).catch(() => {
        toasterService.error('An unknown error occured. Please try again.');
      });
    }
  };
  const actuallyInviteUser = () => {
    inviteUserAPI({
      first_name: inviteUserFirstName,
      last_name: inviteUserLastName,
      company: asCompany.id,
      email: inviteUserEmail,
      role: inviteUserRole
    }).then(() => {
      setInviteUser(false);
      setInviteUserEmail('');
      setInviteUserRole('');
      setInviteUserFirstName('');
      setInviteUserLastName('');
      setRenderIteration(i => i + 1);
      toasterService.success('User invitation sent.');
    }).catch(err => {
      if (err?.response?.data?.non_field_errors) {
        toasterService.error(err?.response?.data?.non_field_errors[0]);
      } else {
        toasterService.error('An unknown error occured. Please try again.');
      }
    });
  };
  const actuallyChangeRole = () => {
    setSaving(true);
    const promises = [updateUserCompany(changeRole.user_to_company, {
      role: changeRoleRole
    })];
    if (selectedUserLocationsChanged) {
      promises.push(replaceUserToLocation({
        user: changeRole.id,
        company: asCompany.id,
        locations: selectedUserLocations.map(e => e.id)
      }));
    }
    if (selectedMarketsChanged) {
      promises.push(replaceUserToMarket({
        user: changeRole.id,
        company: asCompany.id,
        markets: selectedMarkets
      }));
    }
    Promise.all(promises).then(() => {
      setChangeRole(false);
      setChangeRoleRole('');
      setRenderIteration(i => i + 1);
      toasterService.success('User role updated');
      setSaving(false);
    }).catch(() => {
      toasterService.error('An unknown error occured. Please try again.');
    });
  };
  const handleEditLocations = row => {
    setUserLocationsLoading(true);
    const promises = [getUserLocations({
      user: row.id,
      company: asCompany.id
    }), getMarkets({
      company: asCompany.id,
      limit: 1000
    }), getLocations({
      company: asCompany.id,
      limit: 1000
    })];
    Promise.all(promises).then(([userLocations, markets, locations]) => {
      const selectedLocations = [];
      const selectedMarks = [];
      userLocations.data.results.map(ul => {
        if (ul.source == 'location' && selectedLocations.findIndex(e => e.id == ul.location) == -1) {
          selectedLocations.push({
            friendly_name: ul.friendly_name,
            id: ul.location,
            name: ul.name
          });
        } else if (ul.source == 'markets' && selectedMarks.indexOf(ul.source_id) == -1) {
          selectedMarks.push(ul.source_id);
        }
      });
      setLocationsData(locations.data.results.map(e => ({
        friendly_name: e.friendly_name,
        id: e.id,
        name: e.name
      })));
      setSelectedUserLocations(selectedLocations);
      setMarketsData(markets.data.results);
      setSelectedMarkets(selectedMarks);
      setUserLocationsLoading(false);
    });
  };
  return <>
      <CssBaseline />
      <div className="wrapper">
        <PageHeaderMui type="Users" icon={<Icon name="custom-segment" size={34} />} />
        <div className="mui-wrapper">
          <EnhancedTable type="Users" headCells={headCells} api={getUsers} defaultSort={'-updated_when'} handleRowClick={user => history.push(`/users/${user.id}`)} renderIteration={renderIteration} actions={hasPermission('authentication.add_usertocompany') && <NewButton onClick={e => setInviteUser(true)} />} />
        </div>
      </div>
      <ModalDialog open={!!removeUser} onClose={() => setRemoveUser(undefined)} allowBackdropClick title="Remove User" onSubmit={actuallyRemoveUser} submitText="Remove User" actionType="danger">
        Are you sure you want to remove this user from this company?
      </ModalDialog>

      <Modal open={!!inviteUser} onClose={() => setInviteUser(false)} allowBackdropClick title="Invite User">
        <div className="modal__body">
          <p>Enter the information for the user you want to invite:</p>
          <div className="form-group">
            <label>First Name</label>
            <input className="form-control" name="inviteUserFirstName" onChange={e => setInviteUserFirstName(e.target.value)} type="text" value={inviteUserFirstName} />
          </div>
          <div className="form-group">
            <label>Last Name</label>
            <input className="form-control" name="inviteUserLastName" onChange={e => setInviteUserLastName(e.target.value)} type="text" value={inviteUserLastName} />
          </div>
          <div className="form-group">
            <label>Email Address</label>
            <input className="form-control" name="inviteUserEmail" onChange={e => setInviteUserEmail(e.target.value)} type="email" value={inviteUserEmail} />
          </div>
          <div className="form-group">
            <label>Role</label>
            <select className="form-control" name="inviteUserRole" onChange={e => setInviteUserRole(e.target.value)} value={inviteUserRole}>
              <option value=""></option>
              {roles.map(role => <option key={role.id} value={role.id}>
                  {role.display_name}
                </option>)}
            </select>
          </div>
        </div>
        <div className="modal__actions">
          <Button actionType="flat" onClick={() => setInviteUser(false)}>
            Cancel
          </Button>
          <Button actionType="primary" disabled={inviteUserRole === '' || inviteUserEmail === '' || inviteUserFirstName === '' || inviteUserLastName === ''} onClick={actuallyInviteUser}>
            Invite
          </Button>
        </div>
      </Modal>

      <Modal size="lg" open={!!changeRole} onClose={() => setChangeRole(false)} allowBackdropClick title="Change Role">
        <div className="modal__body">
          <p>To what role do you want to change this user:</p>
          <div className="form-group">
            <label>Role</label>
            <select className="form-control" name="changeRoleRole" onChange={e => {
            setChangeRoleRole(e.target.value);
            if (roles.find(role => role.id == e.target.value)?.display_name == 'Catalyst') {
              handleEditLocations(changeRole);
            }
          }} value={changeRoleRole}>
              <option value=""></option>
              {roles.map(role => <option key={role.id} value={role.id}>
                  {role.display_name}
                </option>)}
            </select>
          </div>
          {roles.find(role => role.id == changeRoleRole)?.display_name == 'Catalyst' && <Stack sx={{
          pt: 1
        }} spacing="16px">
              <Typography> Edit Catalyst Locations:</Typography>
              {userLocationsLoading ? <Box sx={{
            width: '100%',
            height: '100%'
          }}>
                  <Box sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              justifyItems: 'center',
              alignItems: 'center'
            }}>
                    <CircularProgress />
                  </Box>
                </Box> : <Stack sx={{
            py: 2
          }} spacing={2}>
                  <Box sx={{
              width: '100%'
            }}>
                    <Autocomplete disabled={saving} multiple loading={userLocationsLoading} id="location-select" options={locationsData} disableCloseOnSelect value={selectedUserLocations || []} getOptionLabel={option => option.friendly_name} onChange={(event, newValue) => {
                setSelectedUserLocations(newValue);
                setSelectedUserLocationsChanged(true);
              }} renderOption={(props, option, {
                selected
              }) => {
                const {
                  key,
                  ...optionProps
                } = props;
                return <li key={option.id} {...optionProps}>
                            <Checkbox icon={<CheckBoxOutlineBlankIcon />} checkedIcon={<CheckBoxIcon />} style={{
                    marginRight: 8
                  }} checked={!!selectedUserLocations?.find(e => e.id == option.id)} />
                            {option.friendly_name}
                          </li>;
              }} renderTags={(tagValue, getTagProps) => {
                if (tagValue.length == 0) {
                  return <Chip label="None" />;
                }
                if (tagValue.length == 1) {
                  return <Typography sx={{
                    width: '350px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden !important',
                    textOverflow: 'ellipsis'
                  }}>
                              {tagValue[0].friendly_name}
                            </Typography>;
                }
                return <Typography sx={{
                  width: '350px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden !important',
                  textOverflow: 'ellipsis'
                }}>
                            {tagValue[0].friendly_name + ' + ' + (tagValue.length - 1) + ' more'}
                          </Typography>;
              }} renderInput={params => <TextField {...params} label="Locations" />} />
                  </Box>

                  <Box sx={{
              width: '100%'
            }}>
                    <FormControl sx={{
                width: '100%'
              }}>
                      <InputLabel id="market-label">Markets</InputLabel>
                      <Select disabled={saving} sx={{
                  borderRadius: '14px'
                }} labelId="market-label" id="market" multiple value={selectedMarkets || []} onChange={event => {
                  const {
                    target: {
                      value
                    }
                  } = event;
                  setSelectedMarkets(typeof value === 'string' ? value.split(',') : value);
                  setSelectedMarketsChanged(true);
                }} input={<OutlinedInput label={'markets'} />} renderValue={selected => {
                  if (selected.length === 0) {
                    return 'None';
                  }
                  if (selected.length === 1) {
                    return marketsData.find(e => e.id == selected[0])?.name;
                  }
                  return marketsData.find(e => e.id == selected[0])?.name + ' + ' + (selected.length - 1) + ' more';
                }} MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 224,
                      width: 250
                    }
                  }
                }}>
                        {marketsData.map(market => <MenuItem key={market.id} value={market.id}>
                            <Checkbox checked={selectedMarkets?.indexOf(market.id) > -1} />
                            <ListItemText primary={market?.name} />
                          </MenuItem>)}
                      </Select>
                    </FormControl>
                  </Box>
                </Stack>}
            </Stack>}
        </div>
        <div className="modal__actions">
          <MuiButton disabled={saving} variant="text" sx={{
          borderRadius: '20px',
          color: 'rgba(29, 37, 45, 0.7)',
          width: '75px',
          textTransform: 'none'
        }} onClick={() => setChangeRole(false)}>
            Cancel
          </MuiButton>
          <MuiButton endIcon={saving && <CircularProgress size={'20px'} />} variant="text" disabled={changeRoleRole === '' || saving} onClick={actuallyChangeRole} sx={{
          mr: 1,
          borderRadius: '20px',
          width: '75px',
          color: '#53A6D6',
          textTransform: 'none'
        }}>
            Update
          </MuiButton>
        </div>
      </Modal>
    </>;
};
export default UsersList;