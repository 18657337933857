import React from 'react';
function Icon() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="none" viewBox="0 0 25 25">
      <mask id="mask0_21_3912" style={{
      maskType: 'alpha'
    }} width="25" height="25" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0.333 0.568H24.333V24.568H0.333z"></path>
      </mask>
      <g mask="url(#mask0_21_3912)">
        <path fill="#1C1B1F" d="M4.484 12.918a.819.819 0 01-.6-.25.818.818 0 01-.25-.6c0-.233.083-.433.25-.6a.818.818 0 01.6-.25h6c.233 0 .433.084.6.25.166.167.25.367.25.6a.818.818 0 01-.25.6.818.818 0 01-.6.25h-6zm9.674 0a.819.819 0 01-.6-.25.819.819 0 01-.25-.6c0-.233.084-.433.25-.6a.818.818 0 01.6-.25h6c.234 0 .434.084.6.25.167.167.25.367.25.6a.818.818 0 01-.25.6.818.818 0 01-.6.25h-6z"></path>
      </g>
    </svg>;
}
export default Icon;