import React, { useState, useContext, useEffect } from 'react';
import { object, func } from 'prop-types';
import { UserStateContext } from 'context/user-state-context';
function OnEvent({
  node,
  update
}) {
  const [events, setEvents] = useState([]);
  const {
    asCompany
  } = useContext(UserStateContext);
  const {
    name,
    parameters
  } = node;
  useEffect(() => {
    if (node.onEventList) {
      setEvents(node.onEventList);
    } else if (node.getEventList) {
      node.getEventList({
        node,
        asCompany
      }).then(events => setEvents(events));
    }
  }, []);
  return <div onClick={e => e.stopPropagation()}>
      <h3>{name || parameters.name}</h3>
      <div className="form-group">
        {events.map(event => <label key={event.id} className="block">
            <input type="radio" name="events" value={event.id} onChange={() => update(event)} />
            {event.name}
          </label>)}
      </div>
    </div>;
}
OnEvent.propTypes = {
  node: object,
  update: func
};
export default OnEvent;