import React from 'react'; // why is this needed?

/* 
    All of this logic, assumes we keep all scope of the same 
    type (market, location, company) underneath a contact.  
    If this ever changes this will need to be adjusted.
*/

// is there a more inbuilt way?
function capitalize(string) {
  return string && string[0].toUpperCase() + string.slice(1);
}

// TODO probably don't return components, make it highly reusable
/**
 * @param {Array} scopeArray
 * @returns {String}
 */
export const getAlertLevel = (scopeArray, defaultValue) => {
  if (scopeArray.length > 0) {
    return scopeArray[0].alert_level;
  }
  return defaultValue;
};

/**
 * @param {Array} scopeArray
 * @returns {String}
 */
export const getContactScope = (scopeArray, defaultValue) => {
  if (scopeArray.length > 0) {
    const scope = scopeArray[0];
    if (scope.location) {
      return 'location';
    } else if (scope.market) {
      return 'market';
    } else {
      return 'company';
    }
  }
  return defaultValue;
};

/**
 * @param {*} scopeArray
 * @returns {String}
 */
export const getUserFriendlyScope = scopeArray => {
  return capitalize(getContactScope(scopeArray));
};

/**
 * @param {*} scopeArray
 * @returns {String}
 */
export const getUserFriendlyAlertLevel = scopeArray => {
  return capitalize(getAlertLevel(scopeArray));
};