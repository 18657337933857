import React, { useState, useContext } from 'react';
import { AsyncModalDialog } from 'components';
import { createCoupon, editCoupon } from 'shared/common.api';
import { UserStateContext } from 'context/user-state-context';
import { ModalContext } from 'context/modal-context';
import { ModalFileViewer2 } from 'components/file-viewer/modal-file-viewer.component-mui';
export const CouponModal = props => {
  const [errors, setErrors] = useState({});
  const {
    asCompany
  } = useContext(UserStateContext);
  const {
    show
  } = useContext(ModalContext);
  const [coupon, setCoupon] = useState(props.coupon || {});
  const onSubmit = () => {
    let promise;
    const payload = {
      name: coupon.name,
      image: coupon.image,
      discount_type: coupon.discount_type,
      discount_amount: coupon.discount_amount,
      days_till_expires: coupon.days_till_expires,
      promo_code: coupon.promo_code,
      company: asCompany.id
    };
    if (coupon.id) {
      promise = editCoupon(coupon.id, payload);
    } else {
      promise = createCoupon(payload);
    }
    return promise.then(() => {
      if (props.onSubmit) {
        props.onSubmit();
      }
    }).catch(err => {
      setErrors(err?.response?.data || {});
      throw e;
    });
  };
  const imagePicker = $event => {
    $event.stopPropagation();
    $event.preventDefault();
    show(ModalFileViewer2).then(({
      url
    }) => {
      setCoupon({
        ...coupon,
        image: url
      });
    }).catch(() => {});
  };
  return <AsyncModalDialog title={(coupon.id ? 'Update' : 'Create') + " Coupon"} onClose={props.onClose} onSubmit={onSubmit} open={props.open} submitText={coupon.id ? 'Save' : 'Create'} allowBackdropClick submitDisabled={!coupon.name || !coupon.promo_code}>
      <div className="form-group">
        <label>
          Promo Code <span className="form-error">*</span>
        </label>
        <input id="coupon-name" className="form-control" name="promo_code" onChange={e => setCoupon({
        ...coupon,
        promo_code: e.target.value.toUpperCase()
      })} value={coupon.promo_code || ''} type="input" />
        {!!errors && !!errors.promo_code && <div className="form-error pt-3">{errors.promo_code[0]}</div>}
      </div>
      <div className="form-group">
        <label>
          Name <span className="form-error">*</span>
        </label>
        <input id="coupon-name" className="form-control" name="name" onChange={e => setCoupon({
        ...coupon,
        name: e.target.value
      })} value={coupon.name || ''} type="input" />
      </div>
      <div className="form-group">
        <label>Discount Type</label>
        <select id="coupon-discount-type" className="form-control" name="discount_type" onChange={e => setCoupon({
        ...coupon,
        discount_type: e.target.value
      })} value={coupon.discount_type || ''}>
          <option value="">Select Discount Type</option>
          <option value="percent_off">Percent Off</option>
          <option value="sale_price">Sale Price</option>
          <option value="amount_off">Amount Off</option>
        </select>
      </div>
      <div className="form-group">
        <label>Discount Amount</label>
        <input id="coupon-discount-amount" className="form-control" name="discount_amount" onChange={e => setCoupon({
        ...coupon,
        discount_amount: e.target.value
      })} value={coupon.discount_amount || ''} type="input" />
      </div>
      <div className="form-group">
        <label>Days till expires</label>
        <input id="coupon-days-till-expires" className="form-control" name="days_till_expires" onChange={e => setCoupon({
        ...coupon,
        days_till_expires: e.target.value
      })} value={coupon.days_till_expires || ''} type="number" min="1" />
      </div>


      <div className="form-group d-flex flex-column" style={{
      gap: '10px'
    }}>
        <label style={{
        margin: 0
      }}>Image</label>

        {!!coupon.image ? <img onClick={() => {
        setCompanySettingsModal(props.company);
      }} src={coupon.image} /> : <small>No image selected</small>}

        <a href="" onClick={imagePicker}>{!coupon.image ? 'Add' : 'Edit'} Image</a>
      </div>

      <div className="form-group">
        <label>Description</label>
        <textarea id="coupon-name" className="form-control" name="description" onChange={e => setCoupon({
        ...coupon,
        description: e.target.value
      })} value={coupon.description || ''} style={{
        height: '50px'
      }} />
      </div>
    </AsyncModalDialog>;
};