import React from 'react';
function Icon() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" fill="none" viewBox="0 0 20 21">
      <mask id="mask0_70_5573" style={{
      maskType: 'alpha'
    }} width="21" height="21" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0 0.257H20V20.257H0z"></path>
      </mask>
      <g mask="url(#mask0_70_5573)">
        <path fill="#1D252D80" fillOpacity="0.5" d="M5.688 11.34c-.306 0-.563-.107-.77-.323a1.054 1.054 0 01-.314-.76c0-.305.108-.562.323-.77a1.06 1.06 0 01.761-.313c.305 0 .562.108.77.323.209.215.313.468.313.76 0 .306-.104.563-.313.771a1.046 1.046 0 01-.77.312zm4.312 0c-.305 0-.562-.107-.77-.323a1.056 1.056 0 01-.313-.76c0-.305.108-.562.323-.77.215-.209.468-.313.76-.313.306 0 .563.108.771.323.208.215.312.468.312.76 0 .306-.107.563-.323.771a1.056 1.056 0 01-.76.312zm4.312 0c-.305 0-.562-.107-.77-.323a1.054 1.054 0 01-.313-.76c0-.305.105-.562.313-.77.208-.209.465-.313.77-.313.306 0 .563.108.771.323.209.215.313.468.313.76 0 .306-.107.563-.323.771a1.06 1.06 0 01-.76.312z"></path>
      </g>
    </svg>;
}
export default Icon;