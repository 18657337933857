import React, { useEffect, useState, useContext } from 'react';
import { Loader, PageHeaderMui, Icon } from 'components';
import { UserStateContext } from 'context/user-state-context';
import { Box } from '@mui/material';
import { authenticateProductBoard } from 'shared/common.api';
export const Community = () => {
  const [isLoading, setIsLoading] = useState(true);
  const {
    asCompany,
    isUserEnrolledInLaunchDarklyBeta
  } = useContext(UserStateContext);
  const [productBoardToken, setProductBoardToken] = useState('');
  useEffect(() => {
    if (!asCompany?.id) {
      return;
    }
    authenticateProductBoard({
      company_id: asCompany.id
    }).then(({
      data
    }) => {
      setProductBoardToken(data.token);
      setIsLoading(false);
    });
  }, [asCompany?.id]);
  if (isLoading) return <Loader overlay />;
  return <Box display="flex" flexDirection="column" sx={{
    height: '100vh',
    width: '100%'
  }}>
      <PageHeaderMui type={isUserEnrolledInLaunchDarklyBeta ? 'Community (Beta)' : 'Community'} icon={<Icon name="custom-marketing" size={34} />} />
      <Box sx={{
      // p: '20px',
      backgroundColor: 'var(--custom-background-color, #e4e9f1)',
      height: '100%'
    }}>
        <iframe src={'https://portal.productboard.com/2pun6glks4gtufw5vdjpodvb?token=' + productBoardToken + '&hide_header=1'} frameBorder="0" style={{
        width: '100%',
        border: 0,
        flexGrow: 1,
        height: '100% '
      }}></iframe>
      </Box>
    </Box>;
};