import * as React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { Icon } from 'components';
import { getSms, getSmsRevision, getSmsTemplates, postSms } from 'shared/common.api';
import { withStyles } from '@mui/styles';
import { TextField, Tooltip } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import { UserStateContext } from 'context/user-state-context';
import CssBaseline from '@mui/material/CssBaseline';
import { history } from '../../root.component';
import { CustomerDrawerContext } from '../../context/customer-drawer-context';
import { TemplatesIcon } from 'components/mui';
const RoundedTextField = withStyles({
  root: {
    backgroundColor: '#F1F3F4',
    height: '40px',
    borderRadius: `8px`,
    borderColor: '#F1F3F4',
    '& .MuiOutlinedInput-root': {
      height: '40px'
    }
  }
})(TextField);
export const SmsTemplatesMenu = props => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [templateSearch, setTemplateSearch] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [templates, setTemplates] = React.useState([]);
  const [recentlyUsedSmsTemplates, setRecentlyUsedSmsTemplates] = React.useState([]);
  const {
    closeCustomerDrawer
  } = React.useContext(CustomerDrawerContext);
  const {
    asCompany
  } = React.useContext(UserStateContext);
  const handleClick = event => {
    setAnchorEl(open ? null : event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const reset = () => {
    setTemplateSearch('');
  };
  React.useEffect(() => {
    //load in sms templates
    setIsLoading(true);
    getSmsTemplates({
      company: asCompany.id,
      published: true,
      archived: false,
      limit: 200
    }).then(({
      data
    }) => {
      setTemplates(data.results);
      setRecentlyUsedSmsTemplates(localStorage.getItem('recentlyUsedSmsTemplates') ? JSON.parse(localStorage.getItem('recentlyUsedSmsTemplates')) : []);
      setIsLoading(false);
    });
  }, []);
  const handleTemplateClick = template => {
    getSmsRevision(template.revisions[0].published_when ? template.revisions[0].id : template.revisions[1].id).then(({
      data
    }) => {
      let all = recentlyUsedSmsTemplates;
      if (!all.find(x => x.company === asCompany.id)) {
        all.push({
          company: asCompany.id,
          templates: []
        });
      }
      const index = all.findIndex(x => x.company === asCompany.id);
      if (all[index].templates.find(x => x.id === template.id)) {
        const toDeleteIndex = all[index].templates.findIndex(y => y.id === template.id);
        if (toDeleteIndex > -1) {
          all[index].templates.splice(toDeleteIndex, 1);
        }
      }
      all[index].templates.unshift(template);
      if (all[index].templates.length > 3) {
        all[index].templates = all[index].templates.slice(0, -1);
      }
      localStorage.setItem('recentlyUsedSmsTemplates', JSON.stringify(all));
      setRecentlyUsedSmsTemplates(all);
      props.handleTemplateChoice(template, data);
      handleClose();
    });
  };
  const handleCreateClick = () => {
    postSms({
      name: 'Untitled',
      company: asCompany.id
    }).then(({
      data
    }) => {
      if (window.location.pathname == '/messaging') {
        const params = window.location.search;
        closeCustomerDrawer();
        setAnchorEl(null);
        history.push({
          pathname: `/templates/edit/sms/${data.id}`,
          return: window.location.pathname,
          returnParams: params
        });
      } else {
        history.push({
          pathname: `/templates/edit/sms/${data.id}`,
          return: window.location.pathname
        });
      }
    });
  };
  return <React.Fragment>
      <CssBaseline />
      <Box sx={{
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      fontFamily: 'Nexa-Regular',
      fontWeight: 400,
      lineHeight: '16.8px',
      pl: 1
    }}>
        <Tooltip title="Add in a premade template">
          <IconButton onClick={handleClick}>
            <TemplatesIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Popper anchorEl={anchorEl} sx={{
      zIndex: 2000
    }} open={open} onClose={handleClose} placement={props.placement ? props.placement : 'right'} modifiers={[{
      name: 'offset',
      options: {
        offset: [props.offset?.y ? props.offset.y : 0, props.offset?.x ? props.offset.x : 0]
      }
    }]}>
        <ClickAwayListener onClickAway={handleClose}>
          <Paper elevation={0} sx={{
          p: 1,
          borderRadius: '16px',
          overflow: 'visible',
          width: '300px',
          overflowY: 'scroll',
          height: '420px',
          scrollbarWidth: 'thin',
          '&::-webkit-scrollbar': {
            width: '0.4em'
          },
          '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
            marginTop: '20px',
            marginBottom: '20px'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888'
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#555'
          },
          filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))',
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 200
          }
        }}>
            <Box sx={{
            p: 1
          }}>
              <Button variant="text" fullWidth sx={{
              cursor: 'pointer',
              height: '48px',
              textTransform: 'none',
              justifyContent: 'flex-start',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }} onClick={handleCreateClick}>
                Create new Sms Template
              </Button>
              <Grid container justifyContent="center">
                <RoundedTextField placeholder="Search templates" fullWidth sx={{
                height: '40px'
              }} value={templateSearch} onChange={e => {
                setTemplateSearch(e.target.value);
              }} InputProps={{
                startAdornment: <InputAdornment position="start">
                        <Icon name="custom-search" />
                      </InputAdornment>,
                endAdornment: !!templateSearch && <InputAdornment onClick={reset} position="start" sx={{
                  cursor: 'pointer'
                }}>
                        <CloseIcon />
                      </InputAdornment>
              }} />
              </Grid>
              {!templateSearch && recentlyUsedSmsTemplates.find(x => x.company === asCompany.id)?.templates.length > 0 && <Divider flexItem sx={{
              pt: 2
            }} />}
              {!templateSearch && recentlyUsedSmsTemplates.find(x => x.company === asCompany.id)?.templates.length > 0 && <Box display="flex" flexDirection="column">
                  {recentlyUsedSmsTemplates.find(x => x.company === asCompany.id)?.templates.map((recent, index) => <Button variant="text" fullWidth key={recent.id + index} sx={{
                cursor: 'pointer',
                height: '48px',
                color: '#1D252D',
                textTransform: 'none',
                justifyContent: 'flex-start',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }} onClick={() => {
                //refresh this sms before inserting
                getSms(recent.id).then(({
                  data
                }) => {
                  handleTemplateClick(data);
                });
              }}>
                        {recent.name}
                      </Button>)}
                </Box>}

              <Divider flexItem sx={{
              pt: 2
            }} />
              <Box display="flex" flexDirection="column">
                {!isLoading ? templates.filter(opt => !templateSearch || opt.name.toLowerCase().includes(templateSearch.toLowerCase())).map(template => <Button variant="text" fullWidth key={template.id} sx={{
                cursor: 'pointer',
                height: '48px',
                color: '#1D252D',
                textTransform: 'none',
                justifyContent: 'flex-start',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }} onClick={() => {
                handleTemplateClick(template);
              }}>
                        {template.name}
                      </Button>) : <Box sx={{
                display: 'flex',
                alignContent: 'center',
                margin: 'auto',
                alignItems: 'center',
                pt: '100px'
              }}>
                    <CircularProgress />
                  </Box>}
              </Box>
              <Grid container justifyContent="flex-end">
                <></>
              </Grid>
            </Box>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </React.Fragment>;
};