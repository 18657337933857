import React, { useState, useEffect, useContext } from 'react';
import { UserStateContext } from 'context/user-state-context';
import { TypeAhead } from 'components';
import { getJourneyReasons } from 'shared/common.api';
export const ActionEject = props => {
  const {
    asCompany
  } = useContext(UserStateContext);
  const {
    status,
    reason
  } = props.data;
  const [reasons, setReasons] = useState({
    Success: [],
    Failure: [],
    'Not Applicable': []
  });
  useEffect(() => {
    getJourneyReasons(asCompany.id).then(({
      data
    }) => {
      const allReasons = {
        Success: [],
        Failure: [],
        'Not Applicable': []
      };
      data.results.forEach(([status, reason]) => {
        if (status && reason) {
          allReasons[status].push(reason);
        }
      });
      setReasons(allReasons);
    });
  }, []);
  return <div>
      <div className="form-group">
        <label>Status</label>
        <select className="form-control" value={status || ''} onChange={e => props.update({
        status: e.target.value,
        reason: null
      })}>
          <option></option>
          <option value="Success">Success</option>
          <option value="Failure">Failure</option>
          <option value="Not Applicable">Not Applicable</option>
        </select>
      </div>
      <div className="form-group">
        <label>Reason</label>
        <TypeAhead items={status ? reasons[status] : []} onChange={reason => props.update({
        reason
      })} disabled={!status} value={reason || ''} placeholder="Select a reason" allowFreeText={true} />
      </div>
    </div>;
};