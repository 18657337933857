import React from 'react';
import { Field } from 'formik';
import { TextField } from '@mui/material';

/**
 * A formik driven component that allows us to unify
 * styling our input fields with Formik `form` Field
 * component API
 *
 * Another way to get the same result would be:
 * ```js
 * <TextField
 *   name="segment"
 *   type="search"
 *   size="small"
 *   fullWidth={true}
 *   value={values.segment}
 *   onChange={handleChange}
 *   error={errors.segment && touched.segment}
 *   helperText={<ErrorMessage name="segment" />}
 *   label="Enter Segment Here"
 * />
 * ```
 * Where TextField simply requires `name` in order to function with Formik, and the TextField API
 * must be wired up by the props from formik.  For example these props can be passed into Textfield:
 *
 * ```js
 * <Formik
 *     initialValues={initialValues}
 *     onSubmit={submitForm}
 *   >
 *     {({ resetForm, submitForm, errors, touched, handleChange, values }) => (
 *       <Form>
 * ```
 *
 * However, for now we decided to go with re-usable components
 * https://formik.org/docs/api/field#example
 *
 * Lastly, here are some other ways to combine MUI with formik
 * https://codesandbox.io/s/simple-mui-formik-yup-form-validation-jlr1o?file=/src/Components/BasicFormValidation.js
 */
export const FormikTextField = ({
  name,
  type,
  textFieldType = 'search',
  size = 'small',
  helperText,
  ...params
}) => {
  // TODO handle object deconstruction better
  // TODO how to handle overlaps in props (`type`, for example)
  return <Field name={name} type={type}>
      {({
      field,
      // { name, value, onChange, onBlur }
      form: {
        touched,
        errors,
        isSubmitting
      },
      // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
      meta
    }) => <TextField {...field} type={textFieldType} size={size} fullWidth={true} error={meta.touched && !!meta.error} helperText={meta.touched && meta.error || helperText} disabled={isSubmitting} {...params} />}
    </Field>;
};