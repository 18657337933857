import React from 'react';
import { PageHeaderMui } from 'components';
import { getNotificationContacts } from 'shared/common.api';
import EnhancedTable from 'components/mui/enhanced-table/enhanced-table.component';
import { NewButton } from 'components/mui/button/new.component';
import { history } from '../../root.component';
import { getUserFriendlyScope, getUserFriendlyAlertLevel } from './shared';
export const NotificationList = () => {
  const rows = [{
    key: 'firstName',
    label: 'First Name',
    width: '15%',
    displayComponent: ({
      data
    }) => {
      return <div>{data.first_name}</div>;
    }
  }, {
    key: 'lastName',
    label: 'last Name',
    width: '15%',
    displayComponent: ({
      data
    }) => {
      return <div>{data.last_name}</div>;
    }
  }, {
    key: 'email',
    label: 'Email',
    width: '30%',
    displayComponent: ({
      data
    }) => {
      return <div>{data.email}</div>;
    }
  }, {
    key: 'phone',
    label: 'Phone',
    width: '10%',
    displayComponent: ({
      data
    }) => {
      return <div>{data.phone}</div>;
    }
  }, {
    key: 'alertLevel',
    label: 'Alert Level',
    width: '10%',
    displayComponent: ({
      data
    }) => {
      if (data.scope.length > 0) {
        return getUserFriendlyAlertLevel(data.scope);
      }
      return null;
    }
  }, {
    key: 'scope',
    label: 'Scope',
    width: '20%',
    displayComponent: ({
      data
    }) => {
      if (data.scope.length > 0) {
        return getUserFriendlyScope(data.scope);
      }
      return null;
    }
  }];
  const newNotificationContact = () => {
    history.push('/notification-list/create');
  };
  const editNotificationContact = id => {
    history.push(`/notification-list/edit/${id}`);
  };
  return <div className="wrapper">
      <PageHeaderMui type="Notification List" icon={<svg preserveAspectRatio="xMinYMin meet" width="40px" height="40px" viewBox="0 0 1 1">
            <use href={'#custom-cell_tower'} xlinkHref={'#custom-cell_tower'} />
          </svg>} />

      <div className="mui-wrapper">
        <EnhancedTable type="Notification Lists" headCells={rows} handleRowClick={rowData => {
        return editNotificationContact(rowData.id);
      }} actions={<NewButton onClick={newNotificationContact} />} api={getNotificationContacts} hoverActions={({
        data
      }) => {
        return null;
      }} />
      </div>
    </div>;
};