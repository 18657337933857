import React, { useContext, useState, useEffect } from 'react';
import { Scoped } from 'kremling';
import styles from './company-mui.styles.scss';
import { UserStateContext } from 'context/user-state-context';
import { Dropdown, DynamicTypeAhead, Icon, PageHeaderMui } from 'components';
import { Box, Button, ClickAwayListener, Paper, Popper, Stack, Typography } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { PageHeader } from '../../components/page-header/page-header';
import { bulkUpdateEmailNameAddress, duplicateCompany, getCompany, getCompanyStatuses, getUsers, patchCompany } from 'shared/common.api';
import utils from '../../shared/utils';
import { v4 as uuid } from 'uuid';
import { Save } from '../../components/save/save.component';
import { Status } from '../../components/status/status.component';
import { AddIcon, CarrotDownIcon, CarrotUpIcon, StatusDotIcon, DoneIcon } from 'components/mui';
import { ModalDialog } from '../../components/modal/modal-dialog.component';
import { CompanyDetailsInfoCard } from './details_cards/company-info.component';
import { CompanySettingsInfoCard } from './details_cards/company-settings.component';
import { CompanySmsSettingsInfoCard } from './details_cards/company-sms.component';
import { CompanyEmailSettingsInfoCard } from './details_cards/company-email.component';
import { snackbarService } from 'components/mui/snackbar/snackbar-service';
import { ApiKeysMui } from './api-keys-mui.component';
import { CompanyAdminInfoCard } from './details_cards/company-admin.component';
import { Calendar } from 'components/calendar/calendar.component';
import moment from 'moment-timezone';
const companyActiveStatusMenu = [{
  key: true,
  name: 'Active',
  icon: <StatusDotIcon fill="#66CC99" />
}, {
  key: false,
  name: 'Inactive',
  icon: <StatusDotIcon fill="#F30F0F" />
}];
export const CompanyMui = props => {
  const {
    hasPermission,
    setAsCompany,
    user,
    asCompany,
    flags
  } = useContext(UserStateContext);
  const [loading, setLoading] = useState(false);
  const [duplicating, setDuplicating] = useState(false);
  const [company, setCompany] = useState({});
  const [errors, setErrors] = useState({});
  const [openModalDuplicate, setOpenModalDuplicate] = useState(false);
  const [companyActiveStatusAnchorEl, setCompanyActiveStatusAnchorEl] = React.useState(null);
  const companyActiveStatusOpen = Boolean(companyActiveStatusAnchorEl);
  const [activeModal, setActiveModal] = useState(false);
  const [newCompany, setNewCompany] = useState({
    newCompanyName: '',
    journeys: true,
    templates: true,
    segments: true,
    dataFields: true,
    savedBlocks: true,
    usersAndRoles: false,
    surveys: true,
    features: true
  });
  const [openApplyChangesToEmailTemplatesModal, setOpenApplyChangesToEmailTemplatesModal] = useState(false);
  useEffect(() => {
    if (!props.match.params.id) {
      return;
    }
    setLoading(true);
    getCompany(props.match.params.id).then(companyRes => {
      formatCompanyApiResponse(companyRes.data);
    });
  }, []);
  const formatCompanyApiResponse = data => {
    const company = data;
    company.phone = utils.formatPhoneNumber(company.phone) || company.phone;
    company.sms_failure_notify_sms = (company.sms_failure_notify_sms || []).map(phone => ({
      key: uuid(),
      val: phone
    }));
    company.sms_failure_notify_emails = (company.sms_failure_notify_emails || []).map(email => ({
      key: uuid(),
      val: email
    }));

    //console.log('company', company);
    setCompany(company);
    setLoading(false);
  };
  const save = c => {
    snackbarService.popup({
      message: 'Saving changes...'
    });
    setErrors({});
    setLoading(true);
    const comp = JSON.parse(JSON.stringify(c));
    const nullKeys = ['email_suppress_bounces', 'email_suppress_days', 'email_suppress_unopens', 'session_timeout', 'days_to_reset_password'];
    nullKeys.forEach(key => {
      if (comp[key] === '') comp[key] = null;
    });
    comp.sms_failure_notify_sms = (comp.sms_failure_notify_sms || []).map(phone => phone.val).filter(phone => !!phone && phone !== '');
    comp.sms_failure_notify_emails = (comp.sms_failure_notify_emails || []).map(email => email.val).filter(email => !!email && email !== '');
    Promise.all([patchCompany(comp.id, comp)]).then(([{
      data: c
    }, ...args]) => {
      if (asCompany.id === c.id) {
        setAsCompany(c);
      }
      formatCompanyApiResponse(c);
      snackbarService.popup({
        message: 'Changes saved successfully!'
      });
    }).catch(patchError => {
      setErrors(patchError?.response?.data);
      setLoading(false);
      snackbarService.popup({
        type: 'error',
        message: 'Failed to save changes. See errors highlighted below.'
      });
    });
  };
  const canEdit = () => {
    return company?.id && hasPermission('organization.change_company');
  };
  const handleCompanyActiveStatusClose = () => {
    setCompanyActiveStatusAnchorEl(null);
  };
  const handleCompanyActiveStatusClick = event => {
    setCompanyActiveStatusAnchorEl(companyActiveStatusOpen ? null : event.currentTarget);
  };
  const handleCompanyActiveStatusChoice = key => {
    if (key && !company?.is_active || !key && company?.is_active) {
      setActiveModal(true);
    }
  };
  const handleDuplicateCompany = () => {
    setDuplicating(true);
    return duplicateCompany({
      name: newCompany.newCompanyName,
      status: newCompany.newCompanyStatus,
      account_manager: newCompany.newCompanyAccountManager,
      contract_original_signed_date: newCompany.newCompanyContractSignedDate ? newCompany.newCompanyContractSignedDate.format('YYYY-MM-DD') : null,
      contract_signed_mrr: newCompany.newCompanyContractSignedMRR,
      journeys: newCompany.journeys,
      templates: newCompany.templates,
      segments: newCompany.segments,
      data_fields: newCompany.dataFields,
      saved_blocks: newCompany.savedBlocks,
      user_roles: newCompany.usersAndRoles,
      surveys: newCompany.surveys,
      features: newCompany.features
    }, company.id).then(() => {
      setDuplicating(false);
    });
  };
  const getTimezoneOffset = date => {
    if (!date) return null;
    const d = new Date(date);
    d.setMinutes(d.getMinutes() + d.getTimezoneOffset());
    return d;
  };
  const applyChangesToEmailTemplates = () => {
    bulkUpdateEmailNameAddress(company.default_email, company.default_from_name, company.id).then(() => {
      snackbarService.popup({
        type: 'success',
        message: 'Email templates updated.'
      });
    }).catch(() => {
      snackbarService.popup({
        type: 'error',
        message: 'Unable to update emails.  Check that the email address is valid and try again.'
      });
    });
  };
  return <>
      <CssBaseline />
      <Scoped css={styles}>
        <div className="wrapper">
          <PageHeaderMui type="Company" name={company.name || ''} icon={<Icon name="custom-dashboard" size={34} />} updateName={canEdit() ? name => {
          save({
            ...company,
            name: name.name
          });
        } : null} updateNamePermission={canEdit()} />
          <PageHeader actions={<>
                <Save saving={loading} />
                {user.is_superuser && <Button variant="outlined" onClick={e => {
            setOpenModalDuplicate(true);
          }}>
                    <Typography sx={{
              textTransform: 'none'
            }}>Duplicate </Typography>
                  </Button>}
                {asCompany.id === company.id ? <Status data-cy="btn-work-as" type="success">
                    Working As
                  </Status> : <Button data-cy="btn-work-as" onClick={() => setAsCompany(company)}>
                    Work as this company
                  </Button>}
                {user.is_superuser && <>
                    <Stack onClick={e => {
              handleCompanyActiveStatusClick(e);
            }} sx={{
              '&:hover': {
                backgroundColor: '#F3F3F4'
              },
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              borderRadius: '8px',
              px: '8px',
              py: '3px',
              border: '1px solid rgba(29, 37, 45, 0.50)'
            }} direction={'row'} spacing={'8px'}>
                      <StatusDotIcon fill={company.is_active ? '#66CC99' : '#F30F0F'} />
                      <Typography sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                        {companyActiveStatusMenu.find(item => item.key == company?.is_active)?.name}
                      </Typography>
                      {companyActiveStatusOpen ? <CarrotUpIcon /> : <CarrotDownIcon />}
                    </Stack>
                  </>}
              </>} />
          <Stack sx={{
          paddingTop: '20px',
          paddingBottom: '300px',
          display: 'flex',
          alignItems: 'center'
        }} spacing="20px">
            <CompanyDetailsInfoCard company={company} errors={errors} loading={loading} updateCompany={(e, defaultEmailOrNameHasChanged) => {
            save(e);
            setOpenApplyChangesToEmailTemplatesModal(defaultEmailOrNameHasChanged);
          }} />
            <CompanySettingsInfoCard company={company} errors={errors} loading={loading} updateCompany={e => {
            save(e);
          }} />

            {user.is_superuser && <CompanyAdminInfoCard company={company} errors={errors} loading={loading} updateCompany={e => {
            save(e);
          }} />}

            <CompanySmsSettingsInfoCard company={company} errors={errors} loading={loading} updateCompany={e => {
            save(e);
          }} />

            <CompanyEmailSettingsInfoCard company={company} loading={loading} errors={errors} updateCompany={e => {
            save(e);
          }} />

            {canEdit() && <ApiKeysMui companyId={company.id} />}
          </Stack>
        </div>

        <Popper anchorEl={companyActiveStatusAnchorEl} sx={{
        zIndex: 200
      }} open={companyActiveStatusOpen} onClose={handleCompanyActiveStatusClose} placement="bottom-start">
          <ClickAwayListener onClickAway={handleCompanyActiveStatusClose}>
            <Paper elevation={0} sx={{
            borderRadius: '16px',
            overflow: 'visible',
            filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))'
          }}>
              <Stack sx={{
              py: '10px'
            }}>
                {companyActiveStatusMenu.map((item, index) => <Stack onClick={() => {
                handleCompanyActiveStatusChoice(item.key);
                handleCompanyActiveStatusClose();
              }} key={index} direction={'row'} spacing={'12px'} sx={{
                '&:hover': {
                  backgroundColor: company.is_active == item.key ? '#3898D933' : '#F3F3F4'
                },
                cursor: 'pointer',
                paddingX: '15px',
                paddingY: '10px',
                backgroundColor: company.is_active == item.key ? '#3898D933' : 'none'
              }}>
                    <Box>{item.icon}</Box>
                    <Typography sx={{
                  display: 'flex',
                  alignItems: 'center'
                }}>{item.name}</Typography>
                    {company.is_active == item.key ? <Box>
                        <DoneIcon fill="#1D252D80" />
                      </Box> : <Box sx={{
                  px: '12px'
                }} />}
                  </Stack>)}
              </Stack>
            </Paper>
          </ClickAwayListener>
        </Popper>

        <ModalDialog open={activeModal} onClose={() => setActiveModal(false)} allowBackdropClick title={`${company.is_active ? 'Deactivate' : 'Activate'} Company`} onSubmit={() => save({
        ...company,
        is_active: !company.is_active
      })} submitText={company.is_active ? 'Deactivate' : 'Activate'}>
          Are you sure you want to {company.is_active ? 'deactivate' : 'activate'} this company?
        </ModalDialog>

        <ModalDialog open={openModalDuplicate} onClose={() => {
        setOpenModalDuplicate(false);
      }} allowBackdropClick title={`Duplicate Company`} onSubmit={handleDuplicateCompany} submitText="Create Company" submitDisabled={duplicating || !newCompany.newCompanyName || !newCompany.newCompanyStatus}>
          <div className="form-group mb-sm">
            <label>New Company Name</label>
            <input className="form-control" name="newCompanyName" defaultValue={''} onChange={e => {
            setNewCompany({
              ...newCompany,
              newCompanyName: e.target.value
            });
          }} />
          </div>

          <div className="form-group">
            <label>
              Status <span className="form-error">*</span>
            </label>
            <DynamicTypeAhead getItems={getCompanyStatuses} placeholder="Choose Company Status" displayProperty="name" keyProperty="id" value={newCompany.newCompanyStatus || null} onChange={manager => {
            setNewCompany({
              ...newCompany,
              newCompanyStatus: manager.id
            });
          }} />
          </div>
          <div className="form-group">
            <label>Account Manager</label>
            <DynamicTypeAhead getItems={getUsers} placeholder="Choose Account Manager" displayProperty="email" keyProperty="id" value={newCompany.newCompanyAccountManager || null} onChange={manager => {
            setNewCompany({
              ...newCompany,
              newCompanyAccountManager: manager ? manager.id : null
            });
          }} />
          </div>
          <div className="form-group">
            <label>Contract Signed Date</label>
            <Dropdown size="md" allowContentClicks trigger={() => <Button dropdown block>
                  {newCompany.newCompanyContractSignedDate ? newCompany.newCompanyContractSignedDate.format('LL') : 'Select Date'}
                </Button>} content={({
            close
          }) => <div className="p-sm">
                  <Calendar value={newCompany.newCompanyContractSignedDate ? getTimezoneOffset(newCompany.newCompanyContractSignedDate.toDate()) : null} minDate={null} onChange={date => {
              setNewCompany({
                ...newCompany,
                newCompanyContractSignedDate: moment(date)
              });
              close();
            }} />
                  <Button actionType="primary" onClick={() => {
              setNewCompany({
                ...newCompany,
                newCompanyContractSignedDate: null
              });
              close();
            }}>
                    Clear Date
                  </Button>
                </div>} />
          </div>
          <div className="form-group">
            <label>Contract Signed MRR</label>
            <input type="text" className="form-control" value={newCompany.newCompanyContractSignedMRR} onChange={e => {
            setNewCompany({
              ...newCompany,
              newCompanyContractSignedMRR: e.target.value
            });
          }} />
          </div>

          <div>
            <ul className="select-list mt-4 ml-2">
              {['journeys', 'templates', 'segments', 'dataFields', 'savedBlocks', 'usersAndRoles', 'surveys', 'features'].map((field, i) => {
              return <li key={i}>
                    <div className="checkbox-label">
                      <label className="form-check-label">
                        <input className="form-check-input" name={field} style={{
                      position: 'relative',
                      top: '1px',
                      transform: 'scale(1.25)'
                    }} type="checkbox" onChange={e => {
                      setNewCompany({
                        ...newCompany,
                        [e.target.name]: !newCompany[e.target.name]
                      });
                    }} checked={newCompany && newCompany[field]} />
                        {_.startCase(field)}
                      </label>
                    </div>
                  </li>;
            })}
            </ul>
          </div>
        </ModalDialog>

        <ModalDialog open={!loading && openApplyChangesToEmailTemplatesModal} onClose={() => {
        setOpenApplyChangesToEmailTemplatesModal(false);
      }} allowBackdropClick title="Update All Email Templates" onSubmit={() => {
        applyChangesToEmailTemplates();
      }} submitText="Yes" cancelText="No">
          You have updated the default email address or from name for this company. <br />
          Existing email templates do not automatically update when you change these values. <br />
          Do you want to update all of your existing email templates to use these new values?
          <br /> <br />
          From Address ={company.default_email} <br />
          From Name = {company.default_from_name}
        </ModalDialog>
      </Scoped>
    </>;
};