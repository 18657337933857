import React, { useState, useEffect, useRef, useContext } from 'react';
import styles from './roles-list.styles.scss';
import { debounce, template } from 'lodash';
import { Scoped } from 'kremling';
import { PageHeader } from '../../components/page-header/page-header';
import { Modal } from '../../components/modal/modal.component';
import { Button } from '../../components/button/button.component';
import { Loader } from '../../components/loader/loader.component';
import { toasterService } from '../../components/toaster/toaster-service';
import { getRole, updateRole, createRole, deleteRole } from '../../shared/common.api';
import { UserStateContext } from 'context/user-state-context';
import { Save } from '../../components/save/save.component';
import { getInstalls } from '../../pages/integrations/integrations.resource.js';
import { Icon, PageHeaderMui } from 'components';

/*******************************************************************************************
 * Role permission requirements
 * Please note, company, users, and permissions must always be set to view or manage minimum for minimum access
 *
 * @param isInvalidPermissionChange   checks if the permission change is valid before applying the change
 * @param checkAutomaticPermissions   automatically apply the required child permissions for the parent role
 *
 * The following is the list of roles and their required permissions, these are marked by an asterisk in the UI
 * Customer Journeys * requires: Users, Permissions, Segments, Templates, Locations, Forms, Phone Numbers, Domains, Reviews
 * Two-way Messaging * requires: Users, Permissions, Customer Journeys, Broadcasts, Segments, Templates, Locations, Phone Numbers, Domains, Customers, Reviews
 * Templates * requires: Domains
 * Analytics * requires: Users, Permissions, Customer Journeys, Broadcasts, Segments, Templates, Locations, Phone Numbers, Domains, Customers, Reviews
 * Customers / Transactions * requires: Users, Permissions, Customer Journeys, Broadcasts, Segments, Templates, Locations, Forms, Phone Numbers, Domains, Customers, Reviews
 *******************************************************************************************/

/**
 * The permissions are ranked by index, from lowest being the most restrive to the higher
 * index giving you the most abilities in the system.  If more permissions are added and the current
 * code is maintained, the new options must be in order.
 */
const VIEW_ONLY = {
  name: 'VIEW_ONLY',
  options: ['none', 'view', undefined]
};
const ALL = {
  name: 'ALL',
  options: ['none', 'view', 'manage']
};
const MANAGE_ONLY = {
  name: 'MANAGE_ONLY',
  options: ['none', undefined, 'manage']
};

/**
 * This function chooses the class that applies the type of dot per permission row
 * that ultimately gets applied.  If a permission exists as an optional permission, and
 * the permission has been surpassed, we make that an active dot.
 *
 * @param {*} allOptions all the permissions available to the specific role
 * @param {*} permission the current permission applied
 * @param {*} level the current evaluated permission
 * @returns
 */
const getClassNames = (allOptions, permission, level) => {
  /** Handles new permission creation, first none's will
   *  be selected as "active" if the permission is not defined,
   *  otherwise everything else is blank / non selected. */
  if (permission === undefined) {
    if (level === 'none') {
      return 'dot active';
    }
    return 'dot blank-dot';
  }

  /**
   * If the level (none, view, manage) is accessible as an option,
   * decide how to display the dots.  If a permission is undefined,
   * this implies it's unavailable for the role and must be hidden.
   */
  if (level !== undefined && allOptions.includes(level)) {
    const permissionIndex = allOptions.indexOf(permission);
    const levelIndex = allOptions.indexOf(level);
    if (permissionIndex >= levelIndex) {
      return 'dot active';
    } else {
      return 'dot blank-dot';
    }
  }
  return 'hidden-dot';
};
const getBackdropBarWidth = levels => {
  if (levels.name === 'ALL') {
    return 'calc(100% - 20px)';
  } else if (levels.name === 'VIEW_ONLY') {
    return 'calc(50% - 15px)';
  } else if (levels.name === 'MANAGE_ONLY') {
    return 'calc(100% - 20px)';
  }
};
const getActualPermissionWidth = permission => {
  if (permission === 'manage') {
    return 'calc(100% - 20px)';
  } else if (permission === 'view') {
    return 'calc(50% - 15px)';
  } else if (permission === 'none' || permission === undefined) {
    return '0%';
  }
};
const ProgressRow = ({
  label,
  ky,
  updatePermission,
  permission,
  levels
}) => {
  return <div className="row permission-row mb-3">
      <div className="col-3 progress-label">
        <div>{label}</div>
      </div>
      <div className="col-9 progress-bar">
        <div className="bar" style={{
        width: getBackdropBarWidth(levels),
        backgroundColor: '#dfdfdf',
        zIndex: 1
      }} />
        <div className="bar" style={{
        width: getActualPermissionWidth(permission)
      }} />
        {levels.options.map(level => {
        return <div key={`${ky}-${level}`} onClick={() => updatePermission(ky, level)} className={getClassNames(levels.options, permission, level)} />;
      })}
      </div>
    </div>;
};
const Role = props => {
  const {
    asCompany,
    hasPermission,
    user
  } = useContext(UserStateContext);
  const [role, setRole] = useState();
  const [saving, setSaving] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [confirmModal, setConfirmModal] = useState(false);
  const [showCoupons, setShowCoupons] = useState(false);
  const [showSurveys, setShowSurveys] = useState(false);
  const [showReviews, setShowReviews] = useState(false);
  const [showRepeatTransactionCalculator, setShowRepeatTransactionCalculator] = useState(false);
  const [showLandingPages, setShowLandingPages] = useState(false);
  const confirmDeleteRole = () => {
    if (`${role.id}` === 'undefined') {
      toasterService.success('Role successfully deleted.');
      props.history.push('/roles');
      return;
    }
    deleteRole(role.id).then(() => {
      toasterService.success('Role successfully deleted.');
      props.history.push('/roles');
    }).catch(e => {
      toasterService.error('Unable to delete role.  Make sure there are no users still using this role');
    });
    setConfirmModal(false);
  };
  const save = updatedRole => {
    const promise = updatedRole.id ? updateRole(updatedRole.id, updatedRole) : createRole(updatedRole).then(({
      data
    }) => {
      setRole(role => ({
        ...role,
        id: data.id
      }));
      props.history.replace(`/roles/${data.id}`);
    });
    promise.then(() => {
      setSaving(false);
    });
  };
  const isInvalidPermissionChange = (permission, level) => {
    // Company requires users and permissions
    // must be set to view or manage to login
    if ((permission === 'company' || permission === 'users' || permission === 'roles') && (['view', 'manage'].includes(role.permissions.company) || ['view', 'manage'].includes(role.permissions.users) || ['view', 'manage'].includes(role.permissions.roles)) && level === 'none') {
      toasterService.success('This is a required permission and cannot be None');
      return true;
    }

    // Templates requires domains
    if (permission === 'domains' && ['view', 'manage'].includes(role.permissions.templates) && level === 'none') {
      toasterService.success('The Domains role cannot be None while the Templates role is active');
      return true;
    }

    // Customers / Transactions
    if (['segments', 'locations', 'numbers', 'users', 'roles', 'journeys', 'broadcasts', 'reviews', 'templates'].includes(permission) && ['view', 'manage'].includes(role.permissions.customers) && level === 'none') {
      toasterService.success('The ' + permission + ' role cannot be None while the Customers / Transactions role is active');
      return true;
    }

    // customer journeys
    if (['segments', 'templates', 'locations', 'forms', 'numbers', 'domains', 'reviews'].includes(permission) && ['view', 'manage'].includes(role.permissions.journeys) && level === 'none') {
      toasterService.success('The ' + permission + ' role cannot be None while the Customer Journeys role is active');
      return true;
    }

    // two-way messaging
    if (['segments', 'numbers', 'customers', 'templates', 'broadcasts', 'reviews', 'journeys'].includes(permission) && ['view', 'manage'].includes(role.permissions.messaging) && level === 'none') {
      toasterService.success('The ' + permission + ' role cannot be None while the Two-way Messaging role is active');
      return true;
    }

    // Analytics
    if (['users', 'segments', 'customers', 'permission', 'roles', 'templates', 'domains', 'numbers', 'forms', 'locations', 'journeys', 'broadcasts', 'reviews'].includes(permission) && role.permissions.analytics === 'view' && level === 'none') {
      toasterService.success('The ' + permission + ' role cannot be None while the Analytics role is active');
      return true;
    }
    return false;
  };
  const checkAutomaticPermissions = (permission, level, permissions) => {
    //Users permission requires at least role permission level to be at least set to view
    // permission.roles is permission.permissions in the Object
    if (permission == 'users' && (level == 'view' || level == 'manage')) {
      if (permissions.roles == 'none' || permissions.roles == null) {
        permissions.roles = 'view';
        toasterService.success('The Users role requires the Permissions role to be set to View or Manage');
      }
    }

    // template permission
    if (permission == 'templates' && (level == 'view' || level == 'manage')) {
      if (permissions.domains == 'none' || permissions.domains == null) {
        permissions.domains = 'view';
        toasterService.success('The Templates role requires the Domains role to be set to View or Manage ');
      }
    }

    // customers / transactions permission
    if (permission == 'customers' && (level == 'view' || level == 'manage')) {
      let somethingChanged = false;
      if (permissions.segments == 'none' || permissions.segments == null) {
        permissions.segments = 'view';
        somethingChanged = true;
      }
      if (permissions.locations == 'none' || permissions.locations == null) {
        permissions.locations = 'view';
        somethingChanged = true;
      }
      if (permissions.numbers == 'none' || permissions.numbers == null) {
        permissions.numbers = 'view';
        somethingChanged = true;
      }
      if (permissions.roles == 'none' || permissions.roles == null) {
        permissions.roles = 'view';
        somethingChanged = true;
      }
      if (permissions.users == 'none' || permissions.users == null) {
        permissions.users = 'view';
        somethingChanged = true;
      }
      if (permission.journeys == 'none' || permissions.journeys == null) {
        permissions.journeys = 'view';
        somethingChanged = true;
      }
      if (permissions.broadcasts == 'none' || permissions.broadcasts == null) {
        permissions.broadcasts = 'view';
        somethingChanged = true;
      }
      if (permissions.templates == 'none' || permissions.templates == null) {
        permissions.templates = 'view';
        somethingChanged = true;
      }
      if (permissions.reviews == 'none' || permissions.reviews == null) {
        permissions.reviews = 'view';
        somethingChanged = true;
      }
      if (somethingChanged) {
        toasterService.success('The Customers / Transactions role requires requires multiple roles to be set to View or Manage ');
      }
    }

    //Customer journey permission:
    if (permission == 'journeys' && (level == 'view' || level == 'manage')) {
      let somethingChanged = false;
      if (permissions.segments == 'none' || permissions.segments == null) {
        permissions.segments = 'view';
        somethingChanged = true;
      }
      if (permissions.templates == 'none' || permissions.templates == null) {
        permissions.templates = 'view';
        somethingChanged = true;
      }
      if (permissions.locations == 'none' || permissions.locations == null) {
        permissions.locations = 'view';
        somethingChanged = true;
      }
      if (permissions.forms == 'none' || permissions.forms == null) {
        permissions.forms = 'view';
        somethingChanged = true;
      }
      if (permissions.numbers == 'none' || permissions.numbers == null) {
        permissions.numbers = 'view';
        somethingChanged = true;
      }
      if (permissions.domains == 'none' || permissions.domains == null) {
        permissions.domains = 'view';
        somethingChanged = true;
      }
      if (permission.reviews == 'none' || permissions.reviews == null) {
        permissions.reviews = 'view';
        somethingChanged = true;
      }
      if (somethingChanged) {
        toasterService.success('The Customer Journeys role requires multiple roles to be set to View or Manage');
      }
    }

    //Two-way messaging:
    if (permission == 'messaging' && (level == 'view' || level == 'manage')) {
      let somethingChanged = false;
      if (permissions.journeys == 'none' || permissions.journeys == null) {
        permissions.journeys = 'view';
        somethingChanged = true;
      }
      if (permissions.broadcasts == 'none' || permissions.broadcasts == null) {
        permissions.broadcasts = 'view';
        somethingChanged = true;
      }
      if (permissions.templates == 'none' || permissions.templates == null) {
        permissions.templates = 'view';
        somethingChanged = true;
      }
      if (permissions.reviews == 'none' || permissions.reviews == null) {
        permissions.reviews = 'view';
        somethingChanged = true;
      }
      if (permissions.numbers == 'none' || permissions.numbers == null) {
        permissions.numbers = 'view';
        somethingChanged = true;
      }
      if (permissions.segments == 'none' || permissions.segments == null) {
        permissions.segments = 'view';
        somethingChanged = true;
      }
      if (permissions.customers == 'none' || permissions.customers == null) {
        permissions.customers = 'view';
        somethingChanged = true;
      }
      if (somethingChanged) {
        toasterService.success('The Two-way Messaging role requires multiple roles to be set to View or Manage');
      }
    }

    //Analytics:
    if (permission == 'analytics' && (level == 'view' || level == 'manage')) {
      let somethingChanged = false;
      if (permissions.users == 'none' || permissions.users == null) {
        permissions.users = 'view';
        somethingChanged = true;
      }
      if (role.permissions.roles == 'none' || role.permissions.roles == null) {
        permissions.roles = 'view';
        somethingChanged = true;
      }
      if (permissions.journeys == 'none' || permissions.journeys == null) {
        permissions.journeys = 'view';
        somethingChanged = true;
      }
      if (permissions.broadcasts == 'none' || permissions.broadcasts == null) {
        permissions.broadcasts = 'view';
        somethingChanged = true;
      }
      if (permissions.reviews == 'none' || permissions.reviews == null) {
        permissions.reviews = 'view';
        somethingChanged = true;
      }
      if (permissions.segments == 'none' || permissions.segments == null) {
        permissions.segments = 'view';
        somethingChanged = true;
      }
      if (permissions.customers == 'none' || permissions.customers == null) {
        permissions.customers = 'view';
        somethingChanged = true;
      }
      if (permissions.templates == 'none' || permissions.templates == null) {
        permissions.templates = 'view';
        somethingChanged = true;
      }
      if (permissions.domains == 'none' || permissions.domains == null) {
        permissions.domains = 'view';
        somethingChanged = true;
      }
      if (permissions.numbers == 'none' || permissions.numbers == null) {
        permissions.numbers = 'view';
        somethingChanged = true;
      }
      if (permissions.forms == 'none' || permissions.forms == null) {
        permissions.forms = 'view';
        somethingChanged = true;
      }
      if (permissions.locations == 'none' || permissions.locations == null) {
        permissions.locations = 'view';
        somethingChanged = true;
      }
      if (somethingChanged) toasterService.success('The Analytics role requires multiple roles to be set to View or Manage');
    }

    // Company is required by all roles and must be set to view or manage to login!
    if (permissions.company == 'none' || permissions.company == null) {
      permissions.company = 'view';
      toasterService.success('All roles require the Company / Data Fields role to be set to View or Manage');
    }
  };
  const updatePermission = (permission, level) => {
    if (!canEdit || isInvalidPermissionChange(permission, level)) return;
    const permissions = {
      ...role.permissions,
      [permission]: level
    };
    checkAutomaticPermissions(permission, level, permissions);
    update('permissions', permissions);
  };
  const update = (key, val) => {
    setRole(role => {
      const updatedRole = {
        ...role,
        [key]: val
      };
      setSaving(true);
      debouncedSave(updatedRole);
      return updatedRole;
    });
  };
  const debouncedSave = useRef(debounce(save, 500)).current;
  useEffect(() => {
    if (asCompany.id) {
      getInstalls({
        company: asCompany.id,
        limit: 100
      }).then(({
        results: installs
      }) => {
        setShowCoupons(installs.some(i => i.integration === 'loyalty' || i.integration === 'isi'));
        setShowSurveys(installs.some(i => i.integration === 'surveys'));
        setShowReviews(installs.some(i => i.integration === 'reviews'));
        setShowRepeatTransactionCalculator(installs.some(i => i.integration === 'repeat_transaction_calculator'));
        setShowLandingPages(installs.some(i => i.integration === 'convrrt'));
        if (props.match.params.id === 'new') {
          setIsLoading(false);
          setRole({
            display_name: 'Untitled',
            company: asCompany.id,
            permissions: {
              company: 'view',
              users: 'view',
              roles: 'view'
            }
          });
          setCanEdit(hasPermission('authentication.add_role'));
        } else {
          setIsLoading(true);
          getRole(props.match.params.id).then(({
            data
          }) => {
            if (data) {
              setRole(data);
              setIsLoading(false);
              setCanEdit(data.company && hasPermission('authentication.change_role'));
            }
          });
        }
      });
    }
  }, []);
  if (!role) {
    return <Loader overlay background="#fff" />;
  }
  return <Scoped css={styles}>
      <div className="wrapper">
        <PageHeaderMui type="Roles" name={role.display_name || 'Role'} icon={<Icon name="custom-assignment_ind" size={34} />} updateName={name => {
        update('display_name', name.name);
      }} updateNamePermission={canEdit} />
        <PageHeader name={role.display_name || 'Role'} updateName={canEdit ? name => update('display_name', name) : null} actions={canEdit && <>
                <Save saving={saving} />
                <Button actionType="danger" onClick={() => setConfirmModal(true)}>
                  Delete
                </Button>
              </>} />
        <div className="wrapper-scroll roles-list" style={{
        background: '#DAEFFF'
      }}>
          {isLoading && <Loader overlay />}

          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label>Description</label>
                <input onChange={e => update('description', e.target.value)} className="form-control" type="text" disabled={!canEdit} value={role.description || ''} />
              </div>
            </div>
          </div>

          <div className="row permission-row mb-3">
            <div className="col-3">
              <strong>Permissions</strong>
            </div>
            <div className="col-9 progress-header">
              <div>None</div>
              <div>View</div>
              <div>Manage</div>
            </div>
          </div>
          <ProgressRow label="Company / Data Fields" ky="company" updatePermission={updatePermission} permission={role.permissions.company} levels={ALL} />
          <ProgressRow label="Users" ky="users" updatePermission={updatePermission} permission={role.permissions.users} levels={ALL} />
          <ProgressRow label="Permissions" ky="roles" updatePermission={updatePermission} permission={role.permissions.roles} levels={ALL} />
          <ProgressRow label="Customer Journeys *" ky="journeys" updatePermission={updatePermission} permission={role.permissions.journeys} levels={ALL} />
          <ProgressRow label="Broadcasts" ky="broadcasts" updatePermission={updatePermission} permission={role.permissions.broadcasts} levels={ALL} />
          <ProgressRow label="Two-way Messaging *" ky="messaging" updatePermission={updatePermission} permission={role.permissions.messaging} levels={ALL} />
          {showReviews && <ProgressRow label="Reviews" ky="reviews" updatePermission={updatePermission} permission={role.permissions.reviews} levels={ALL} />}
          {showCoupons && <ProgressRow label="Coupons" ky="coupons" updatePermission={updatePermission} permission={role.permissions.coupons} levels={ALL} />}
          <ProgressRow label="Segments" ky="segments" updatePermission={updatePermission} permission={role.permissions.segments} levels={ALL} />
          <ProgressRow label="Templates *" ky="templates" updatePermission={updatePermission} permission={role.permissions.templates} levels={ALL} />
          <ProgressRow label="Locations" ky="locations" updatePermission={updatePermission} permission={role.permissions.locations} levels={ALL} />
          <ProgressRow label="Web Forms" ky="forms" updatePermission={updatePermission} permission={role.permissions.forms} levels={ALL} />
          <ProgressRow label="Phone Numbers" ky="numbers" updatePermission={updatePermission} permission={role.permissions.numbers} levels={ALL} />
          <ProgressRow label="Files" ky="files" updatePermission={updatePermission} permission={role.permissions.files} levels={ALL} />
          {showSurveys && <ProgressRow label="Surveys" ky="surveys" updatePermission={updatePermission} permission={role.permissions.surveys} levels={ALL} />}
          <ProgressRow label="Analytics *" ky="analytics" updatePermission={updatePermission} permission={role.permissions.analytics} levels={VIEW_ONLY} />
          <ProgressRow label="Customers / Transactions *" ky="customers" updatePermission={updatePermission} permission={role.permissions.customers} levels={ALL} />
          <ProgressRow label="Integrations" ky="integrations" updatePermission={updatePermission} permission={role.permissions.integrations} levels={ALL} />
          <ProgressRow label="Domains" ky="domains" updatePermission={updatePermission} permission={role.permissions.domains} levels={ALL} />
          {showRepeatTransactionCalculator && <ProgressRow label="Repeat Transaction Calculator" ky="repeat_transaction_calculator" updatePermission={updatePermission} permission={role.permissions.repeat_transaction_calculator} levels={ALL} />}
          {showLandingPages && <ProgressRow label="Landing Pages" ky="landing_pages" updatePermission={updatePermission} permission={role.permissions.landing_pages} levels={MANAGE_ONLY} />}
          <p className="mt-5">* Setting this permission will automatically set other necessary permissions</p>
        </div>
      </div>

      <Modal open={!!confirmModal} onClose={() => setConfirmModal(false)} allowBackdropClick title="Delete Role">
        <div className="modal__body">
          <p>Are you sure you want to delete this role? This action cannot be undone.</p>
        </div>
        <div className="modal__actions">
          <Button actionType="flat" onClick={() => setConfirmModal(false)}>
            Cancel
          </Button>
          <Button actionType="danger" onClick={() => confirmDeleteRole()}>
            Confirm
          </Button>
        </div>
      </Modal>
    </Scoped>;
};
export default Role;