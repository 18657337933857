import React from 'react';
function Icon(props) {
  return <svg xmlns="http://www.w3.org/2000/svg" width="376" height="109" fill="none" viewBox="0 0 376 109">
      <g clipPath="url(#clip0_119_13032)">
        <path stroke="#E6E7E8" strokeMiterlimit="10" strokeWidth="0.5" d="M10.934 0v83.325M21.35 0v83.325M31.765 0v83.325M42.18 0v83.325M52.596 0v83.325M63.012 0v83.325M73.428 0v83.325M83.844 0v83.325M94.259 0v83.325M104.675 0v83.325M115.09 0v83.325M125.506 0v83.325M135.922 0v83.325M146.337 0v83.325M156.753 0v83.325M167.169 0v83.325M177.584 0v83.325M188 0v83.325M198.416 0v83.325M208.832 0v83.325M219.247 0v83.325M229.663 0v83.325M240.079 0v83.325M250.494 0v83.325M260.91 0v83.325M271.325 0v83.325M281.741 0v83.325M292.157 0v83.325M302.573 0v83.325M312.988 0v83.325M323.404 0v83.325M333.82 0v83.325M344.235 0v83.325M354.651 0v83.325M365.067 0v83.325M.518 10.416h374.964M.518 20.831h374.964M.518 10.416h374.964M.518 31.247h374.964M.518 41.663h374.964M.518 52.078h374.964M.518 62.494h374.964M.518 72.91h374.964"></path>
        <path stroke="#E6E7E8" strokeMiterlimit="10" strokeWidth="1.5" d="M77.969 108.219h42.1M268.982 108.219h42.1"></path>
        <path stroke="#3898D9" strokeDasharray="4 4" strokeLinecap="round" strokeLinejoin="round" d="M67.928 12.603c3.953-4.552 8.877-8.301 14.43-9.51 8.105-1.76 17.743 3.531 21.41 11.603 3.401 7.5 4.535 14.707 5.473 24.574.805 8.86 1.258 11.29 3.743 16.196 4.886 9.645 8.757 12.881 18.672 14.152 7.848 1.01 15.867-.166 23.525-2.104 0 0 19.287-3.458 27.135-19.53 4.067-8.332-3.001-17.393-11.058-18.33-7.486-.876-15.953 5.738-13.553 14.8 4.99 18.821 68.108 36.861 89.5 29.018 21.392-7.843 9.629-38.82 21.754-52.151 12.124-13.332 42.793-10.197 42.431 5.885"></path>
        <path fill="#fff" stroke="#DADCE0" strokeMiterlimit="10" strokeWidth="1.5" d="M95.04 13.02H20.401a6.947 6.947 0 00-6.947 6.947v43.391a6.947 6.947 0 006.947 6.948h74.64a6.947 6.947 0 006.946-6.948V19.967a6.947 6.947 0 00-6.947-6.947zM355.515 23.435h-74.638a6.948 6.948 0 00-6.948 6.947v43.392a6.948 6.948 0 006.948 6.947h74.638a6.948 6.948 0 006.948-6.947V30.382a6.948 6.948 0 00-6.948-6.947z"></path>
        <path fill="#fff" stroke="#DADCE0" strokeMiterlimit="10" strokeWidth="1.5" d="M91.572 13.02H23.87v57.286h67.702V13.02zM352.047 23.435h-67.702v57.286h67.702V23.435z"></path>
        <path fill="#3EB87B" d="M300.866 38.487l3.906-3.907a.454.454 0 01.666 0 .458.458 0 01.006.664l-4.238 4.248a.46.46 0 01-.667 0l-1.868-1.868a.456.456 0 01-.143-.333c0-.126.047-.237.143-.333a.461.461 0 01.667 0l1.528 1.529zM300.866 30.966l3.906-3.906a.454.454 0 01.666 0 .458.458 0 01.006.663l-4.238 4.249a.46.46 0 01-.667 0l-1.868-1.869a.456.456 0 01-.143-.333c0-.126.047-.237.143-.333a.461.461 0 01.667 0l1.528 1.529zM300.866 46.007l3.906-3.906a.454.454 0 01.666 0 .458.458 0 01.006.664l-4.238 4.248a.46.46 0 01-.667 0l-1.868-1.868a.456.456 0 01-.143-.333c0-.126.047-.237.143-.333a.461.461 0 01.667 0l1.528 1.528zM300.866 53.528l3.906-3.906a.454.454 0 01.666 0 .458.458 0 01.006.663l-4.238 4.25a.46.46 0 01-.667 0l-1.868-1.869a.456.456 0 01-.143-.333c0-.126.047-.237.143-.333a.461.461 0 01.667 0l1.528 1.528zM300.866 61.05l3.906-3.907a.454.454 0 01.666 0 .458.458 0 01.006.663l-4.238 4.25a.46.46 0 01-.667 0l-1.868-1.869a.456.456 0 010-.666.461.461 0 01.667 0l1.528 1.528zM300.866 68.57l3.906-3.906a.454.454 0 01.666 0 .458.458 0 01.006.663l-4.238 4.25a.46.46 0 01-.667 0l-1.868-1.87a.456.456 0 01-.143-.332c0-.126.047-.238.143-.333a.461.461 0 01.667 0l1.528 1.528zM300.866 76.091l3.906-3.906a.454.454 0 01.666 0 .458.458 0 01.006.663l-4.238 4.249a.46.46 0 01-.667 0l-1.868-1.868a.456.456 0 01-.143-.333c0-.127.047-.238.143-.334a.461.461 0 01.667 0l1.528 1.529z"></path>
        <path fill="#F3F3F4" stroke="#DADCE0" strokeMiterlimit="10" strokeWidth="1.5" d="M292.153 23.435h-7.808v57.286h7.808V23.435z"></path>
        <mask id="mask0_119_13032" style={{
        maskType: 'alpha'
      }} width="7" height="7" x="285" y="26" maskUnits="userSpaceOnUse">
          <path fill="#D9D9D9" d="M285.235 26.37H291.262V32.397H285.235z"></path>
        </mask>
        <g mask="url(#mask0_119_13032)">
          <path fill="#3898D9" d="M286.232 31.61a.215.215 0 01-.204-.12.216.216 0 01.013-.236l2.017-3.022a.22.22 0 01.191-.102.22.22 0 01.191.102l2.017 3.022a.216.216 0 01.013.236.215.215 0 01-.204.12h-4.034z"></path>
        </g>
        <mask id="mask1_119_13032" style={{
        maskType: 'alpha'
      }} width="7" height="7" x="285" y="45" maskUnits="userSpaceOnUse">
          <path fill="#1D252D" d="M285.106 45.096H291.133V51.123H285.106z"></path>
        </mask>
        <g mask="url(#mask1_119_13032)">
          <path fill="#1D252D" d="M286.571 50.238a.467.467 0 01-.344-.14.466.466 0 01-.142-.345v-3.356c0-.135.047-.25.142-.344a.467.467 0 01.344-.141h3.356c.135 0 .25.047.344.14a.469.469 0 01.141.345v3.356c0 .135-.047.25-.141.344a.468.468 0 01-.344.141h-3.356zm0-.407h3.356a.075.075 0 00.078-.078v-3.356a.075.075 0 00-.078-.078h-3.356a.075.075 0 00-.078.078v3.356a.075.075 0 00.078.078z"></path>
        </g>
        <mask id="mask2_119_13032" style={{
        maskType: 'alpha'
      }} width="7" height="7" x="285" y="54" maskUnits="userSpaceOnUse">
          <path fill="#1D252D" d="M285.235 54.371H291.262V60.398H285.235z"></path>
        </mask>
        <g mask="url(#mask2_119_13032)">
          <path fill="#1D252D" d="M287.297 59.361a.451.451 0 01-.403-.229l-.961-1.68a.443.443 0 010-.457l.961-1.68a.451.451 0 01.4-.23h1.907a.462.462 0 01.403.23l.961 1.68a.452.452 0 010 .458l-.961 1.68a.466.466 0 01-.168.168.461.461 0 01-.233.06h-1.906zm-.003-.458h1.909l.961-1.68-.961-1.68h-1.909l-.96 1.68.96 1.68z"></path>
        </g>
        <mask id="mask3_119_13032" style={{
        maskType: 'alpha'
      }} width="7" height="7" x="285" y="35" maskUnits="userSpaceOnUse">
          <path fill="#1D252D" d="M285.235 35.743H291.262V41.77H285.235z"></path>
        </mask>
        <g mask="url(#mask3_119_13032)">
          <path fill="#1D252D" d="M288.251 41.37c-.337 0-.653-.064-.95-.191a2.467 2.467 0 01-.78-.525 2.468 2.468 0 01-.524-.78 2.375 2.375 0 01-.191-.95c0-.338.063-.654.191-.949a2.468 2.468 0 011.304-1.301c.296-.127.613-.19.951-.19.337 0 .653.063.948.19a2.462 2.462 0 011.301 1.303c.128.296.191.612.191.948 0 .336-.063.653-.191.95a2.454 2.454 0 01-1.302 1.304c-.296.127-.612.19-.948.19zm-.002-.458c.551 0 1.02-.193 1.406-.58.386-.385.579-.854.579-1.405 0-.552-.193-1.02-.579-1.407a1.915 1.915 0 00-1.406-.579c-.551 0-1.02.193-1.406.58a1.915 1.915 0 00-.579 1.406c0 .551.193 1.02.579 1.406.386.386.855.579 1.406.579z"></path>
        </g>
        <path stroke="#AEAFB0" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1.5" d="M39.494 19.1h9.374M53.555 19.1H64.49M72.303 19.1h4.687M29.078 19.1h5.208M81.677 19.1h2.604"></path>
        <path stroke="#3898D9" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1.5" d="M311.953 29.516h11.629M331.888 29.516h4.984M341.855 29.516h2.769"></path>
        <path stroke="#DADCE0" strokeLinecap="round" strokeMiterlimit="10" d="M39.494 56.704h9.374M53.555 56.704H64.49M72.303 56.704h4.687M29.078 56.704h5.208M81.677 56.704h2.604M311.953 67.12h11.629M331.888 67.12h4.984M341.855 67.12h2.769M39.494 64.225h9.374M53.555 64.225H64.49M72.303 64.225h4.687M29.078 64.225h5.208M81.677 64.225h2.604M311.953 74.64h11.629M331.888 74.64h4.984M341.855 74.64h2.769M39.494 26.62h7.29M53.555 26.62h14.06M72.303 26.62h3.645M29.078 26.62h4.05M81.677 26.62h3.645M311.953 37.036h14.951M331.888 37.036h3.876M341.855 37.036h3.877M39.494 34.142h8.332M53.555 34.142h13.02M72.303 34.142h4.687M29.078 34.142h5.208M81.677 34.142h2.604M311.953 44.557h13.844M331.888 44.557h4.984M341.855 44.557h2.769M39.494 41.663h9.374M53.555 41.663h9.895M72.303 41.663h2.604M29.078 41.663h2.893M81.677 41.663h4.687M311.953 52.078h10.521M331.888 52.078h2.769M341.855 52.078h4.984M39.494 49.184h6.25M53.555 49.184h11.978M72.303 49.184h4.687M29.078 49.184h5.208M81.677 49.184h4.687M311.953 59.6h12.736M331.888 59.6h4.984M341.855 59.6h4.984"></path>
        <path fill="#fff" stroke="#808184" strokeMiterlimit="10" d="M137.432 22.03h114.072a2.09 2.09 0 012.084 2.082v76.212H135.349V24.112c0-1.145.937-2.083 2.083-2.083z"></path>
        <path fill="#F3F3F4" stroke="#808184" strokeMiterlimit="10" d="M248.38 27.237H140.557v67.879H248.38v-67.88z"></path>
        <path fill="#fff" stroke="#DADCE0" strokeMiterlimit="10" d="M244.373 31.247h-99.819v64.92h99.819v-64.92z"></path>
        <path stroke="#AEAFB0" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1.5" d="M160.163 37.8h7.138M199.419 37.8h7.956M148.638 37.8h7.957"></path>
        <path stroke="#6C9" strokeLinecap="round" strokeMiterlimit="10" d="M160.163 64.695h7.138"></path>
        <path stroke="#3898D9" strokeLinecap="round" strokeMiterlimit="10" d="M160.163 91.59h7.138"></path>
        <path stroke="#DADCE0" strokeLinecap="round" strokeMiterlimit="10" d="M199.419 64.695h7.956M199.419 91.59h7.956M148.638 64.695h7.957M148.638 91.59h7.957"></path>
        <path stroke="#AEAFB0" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1.5" d="M170.869 37.8h8.327M211.354 37.8h9.282"></path>
        <path stroke="#DADCE0" strokeLinecap="round" strokeMiterlimit="10" d="M170.869 64.695h8.327M170.869 91.59h8.327"></path>
        <path stroke="#E35989" strokeLinecap="round" strokeMiterlimit="10" d="M211.354 64.695h9.282"></path>
        <path stroke="#DADCE0" strokeLinecap="round" strokeMiterlimit="10" d="M211.354 91.59h9.282"></path>
        <path stroke="#AEAFB0" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1.5" d="M185.145 37.8h3.569M227.266 37.8h3.978"></path>
        <path stroke="#DADCE0" strokeLinecap="round" strokeMiterlimit="10" d="M185.145 64.695h3.569M185.145 91.59h3.569M227.266 64.695h3.978M227.266 91.59h3.978"></path>
        <path stroke="#AEAFB0" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1.5" d="M192.282 37.8h1.983M235.222 37.8h2.21"></path>
        <path stroke="#DADCE0" strokeLinecap="round" strokeMiterlimit="10" d="M192.282 64.695h1.983M192.282 91.59h1.983M235.222 64.695h2.21M235.222 91.59h2.21M160.163 43.178h5.551M199.419 43.178h6.188"></path>
        <path stroke="#E35989" strokeLinecap="round" strokeMiterlimit="10" d="M148.638 43.178h6.189"></path>
        <path stroke="#DADCE0" strokeLinecap="round" strokeMiterlimit="10" d="M160.163 70.073h5.551M199.419 70.073h6.188"></path>
        <path stroke="#3898D9" strokeLinecap="round" strokeMiterlimit="10" d="M148.638 70.073h6.189"></path>
        <path stroke="#DADCE0" strokeLinecap="round" strokeMiterlimit="10" d="M170.869 43.178h10.707"></path>
        <path stroke="#6C9" strokeLinecap="round" strokeMiterlimit="10" d="M211.354 43.178h11.934"></path>
        <path stroke="#DADCE0" strokeLinecap="round" strokeMiterlimit="10" d="M170.869 70.073h10.707M211.354 70.073h11.934M185.145 43.178h2.776M227.266 43.178h3.094M185.145 70.073h2.776M227.266 70.073h3.094M192.282 43.178h2.776M235.222 43.178h3.094M192.282 70.073h2.776M235.222 70.073h3.094M160.163 48.557h6.344M199.419 48.557h7.072M148.638 48.557h7.073M160.163 75.453h6.344"></path>
        <path stroke="#E35989" strokeLinecap="round" strokeMiterlimit="10" d="M199.419 75.453h7.072"></path>
        <path stroke="#DADCE0" strokeLinecap="round" strokeMiterlimit="10" d="M148.638 75.453h7.073"></path>
        <path stroke="#E35989" strokeLinecap="round" strokeMiterlimit="10" d="M170.869 48.557h9.914"></path>
        <path stroke="#DADCE0" strokeLinecap="round" strokeMiterlimit="10" d="M211.354 48.557h11.05M170.869 75.453h9.914M211.354 75.453h11.05M185.145 48.557h3.569"></path>
        <path stroke="#3898D9" strokeLinecap="round" strokeMiterlimit="10" d="M227.266 48.557h3.978"></path>
        <path stroke="#DADCE0" strokeLinecap="round" strokeMiterlimit="10" d="M185.145 75.453h3.569M227.266 75.453h3.978M192.282 48.557h1.983"></path>
        <path stroke="#6C9" strokeLinecap="round" strokeMiterlimit="10" d="M235.222 48.557h2.21"></path>
        <path stroke="#DADCE0" strokeLinecap="round" strokeMiterlimit="10" d="M192.282 75.453h1.983"></path>
        <path stroke="#6C9" strokeLinecap="round" strokeMiterlimit="10" d="M235.222 75.453h2.21"></path>
        <path stroke="#DADCE0" strokeLinecap="round" strokeMiterlimit="10" d="M160.163 53.937h7.138M199.419 53.937h7.956"></path>
        <path stroke="#6C9" strokeLinecap="round" strokeMiterlimit="10" d="M148.638 53.937h7.957"></path>
        <path stroke="#DADCE0" strokeLinecap="round" strokeMiterlimit="10" d="M160.163 80.832h7.138M199.419 80.832h7.956M148.638 80.832h7.957M170.869 53.937h7.534M211.354 53.937h8.398"></path>
        <path stroke="#E35989" strokeLinecap="round" strokeMiterlimit="10" d="M170.869 80.832h7.534"></path>
        <path stroke="#DADCE0" strokeLinecap="round" strokeMiterlimit="10" d="M211.354 80.832h8.398"></path>
        <path stroke="#3898D9" strokeLinecap="round" strokeMiterlimit="10" d="M185.145 53.937h1.982"></path>
        <path stroke="#DADCE0" strokeLinecap="round" strokeMiterlimit="10" d="M227.266 53.937h2.21M185.145 80.832h1.982M227.266 80.832h2.21M192.282 53.937h3.569M235.222 53.937h3.978"></path>
        <path stroke="#3898D9" strokeLinecap="round" strokeMiterlimit="10" d="M192.282 80.832h3.569"></path>
        <path stroke="#DADCE0" strokeLinecap="round" strokeMiterlimit="10" d="M235.222 80.832h3.978M160.163 59.316h4.758"></path>
        <path stroke="#E35989" strokeLinecap="round" strokeMiterlimit="10" d="M199.419 59.316h5.304"></path>
        <path stroke="#DADCE0" strokeLinecap="round" strokeMiterlimit="10" d="M148.638 59.316h5.305M160.163 86.21h4.758M199.419 86.21h5.304"></path>
        <path stroke="#E35989" strokeLinecap="round" strokeMiterlimit="10" d="M148.638 86.21h5.305"></path>
        <path stroke="#DADCE0" strokeLinecap="round" strokeMiterlimit="10" d="M170.869 59.316h9.121M211.354 59.316h10.166M170.869 86.21h9.121"></path>
        <path stroke="#3898D9" strokeLinecap="round" strokeMiterlimit="10" d="M211.354 86.21h10.166"></path>
        <path stroke="#DADCE0" strokeLinecap="round" strokeMiterlimit="10" d="M185.145 59.316h3.569"></path>
        <path stroke="#E35989" strokeLinecap="round" strokeMiterlimit="10" d="M227.266 59.316h3.978"></path>
        <path stroke="#DADCE0" strokeLinecap="round" strokeMiterlimit="10" d="M185.145 86.21h3.569"></path>
        <path stroke="#6C9" strokeLinecap="round" strokeMiterlimit="10" d="M227.266 86.21h3.978"></path>
        <path stroke="#DADCE0" strokeLinecap="round" strokeMiterlimit="10" d="M192.282 59.316h3.569M235.222 59.316h3.978M192.282 86.21h3.569M235.222 86.21h3.978"></path>
        <path fill="#fff" d="M248.38 95.105H140.557v5.208H248.38v-5.208z"></path>
        <path fill="#fff" stroke="#808184" strokeMiterlimit="10" d="M130.433 100.313h128.071v3.312c0 2.5-2.031 4.542-4.541 4.542H134.964a4.546 4.546 0 01-4.542-4.542v-3.312h.011z"></path>
        <path stroke="#808184" strokeMiterlimit="10" d="M140.557 95.105H248.38"></path>
      </g>
      <defs>
        <clipPath id="clip0_119_13032">
          <path fill="#fff" d="M0 0H374.964V109H0z" transform="translate(.518)"></path>
        </clipPath>
      </defs>
    </svg>;
}
export default Icon;