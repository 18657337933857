import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import classnames from 'classnames';
import { fileSize } from 'components/file-viewer/utils';
import CircularProgress from '@mui/material/CircularProgress';
import { getSmsTemplates, getSmsRevision } from 'shared/common.api';
import { ModalDialog, Icon } from 'components';
import { SegmentedMessage, GraphemeSplitter } from 'shared/segment-calculator';
const SPLITTER = new GraphemeSplitter();
export const TemplatePreview = props => {
  const {
    param,
    update,
    data
  } = props;
  const [previewLoading, setPreviewLoading] = useState(true);
  const [previewMessage, setPreviewMessage] = useState("");
  const [media, setMedia] = useState([]);
  const [showSegmentModal, setShowSegmentModal] = useState(false);
  useEffect(() => {
    if (!data.template_id) return;
    getSmsTemplates({
      id: data.template_id,
      limit: 1
    }).then(({
      data
    }) => {
      if (data.count) {
        const revision = data.results[0].revisions.length > 1 ? data.results[0].revisions[1] : data.results[0].revisions[0];
        getSmsRevision(revision.id).then(({
          data
        }) => {
          setPreviewMessage(data.message || '');
          setPreviewLoading(false);
          setMedia(data.media || []);
        });
      } else {
        setPreviewMessage("Template not found");
        setPreviewLoading(false);
        setMedia([]);
      }
    });
  }, [data.template_id]);
  const getMessageCount = message => {
    const fullMessage = message + '\nReply STOP to optout';
    const segmentedMessage = new SegmentedMessage(fullMessage, SPLITTER);
    const charactersValue = `${SPLITTER.countGraphemes(fullMessage)}* characters = `;
    let segmentsValues = `${segmentedMessage.segments.length}`;
    if (message.indexOf('{') > -1) {
      segmentsValues += '**';
    }
    segmentsValues += ' segment';
    if (segmentedMessage.segments.length != 1) {
      segmentsValues += 's';
    }
    return <>
        {charactersValue}
        <span style={segmentedMessage.segments.length > 1 ? {
        color: '#f28837'
      } : {
        color: '#404040'
      }}>{segmentsValues}</span>
      </>;
  };
  return <div className="form-group">
      <label>Template Preview</label>
      <Box sx={{
      display: 'flex',
      overflowY: 'auto',
      flexDirection: 'column-reverse',
      height: '90%',
      maxHeight: '90%'
    }}>
        <Box sx={{
        mb: '30px',
        width: '100%',
        background: '#53A6D6',
        color: '#fff',
        padding: '10px 20px',
        borderRadius: '8px',
        whiteSpace: 'pre-wrap',
        position: 'relative',
        '&::after': {
          backgroundColor: '#53A6D6',
          content: '""',
          display: 'block',
          position: 'absolute',
          width: 16,
          height: 16,
          bottom: -6,
          transform: 'rotate(45deg)',
          left: '7px'
        }
      }}>
          {previewLoading ? <Box sx={{
          display: 'flex',
          alignContent: 'center',
          margin: 'auto',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
              <CircularProgress color="white" size={20} />
            </Box> : <Box sx={{
          width: '100%',
          wordWrap: 'break-word',
          whiteSpace: 'pre-wrap'
        }}>
              {previewMessage + '\n\nReply STOP to optout'}
            </Box>}
        </Box>
      </Box>
      <span className="text-sm">
        {getMessageCount(previewMessage || '')}
        <span onClick={() => setShowSegmentModal(true)}>
          <Icon className="select-list__selected ml-2" size={14} name="fa-solid-question-circle" fill={'#f28837'} />
        </span>
      </span>

      {param.message_type === 'sms' && media.length > 0 && <div className="node-modal__error-message mt-3">This message template includes media attachments but this is an SMS node.  Attachments will not be included.</div>}
      {param.message_type === 'mms' && media.length === 0 && data.template_id && <div className="node-modal__error-message mt-3">This message template does NOT includes media attachments but this is an MMS node.  You need to use an SMS node instead.</div>}

      {param.message_type === 'mms' && <div className="form-group mt-3">
          <div className="d-flex justify-content-between">
            <label>Media</label>
          </div>
          {media.map(file => <div className="d-flex" key={file.Key}>
              <div className="flex-grow truncate">{file.name}</div>
              <div className="ml-2">{fileSize(file.Size)}</div>
            </div>)}
          <div className="d-flex justify-content-between mt-2">
            <p className={classnames('text-sm', {
          'text-danger': media.length > 10
        })}>
              {media.length} of 10 files
            </p>
            <p className={classnames('text-sm', {
          'text-danger': media.reduce((x, y) => x + y.Size, 0) > 5200880
        })}>
              {fileSize(media.reduce((x, y) => x + y.Size, 0))} of 5 MB
            </p>
          </div>
        </div>}

      <ModalDialog open={showSegmentModal} title="SMS Segments" submitText="Okay" allowBackdropClick={true} onClose={() => setShowSegmentModal(false)} cancelBtn={false} onSubmit={() => setShowSegmentModal(false)}>
        <p>
          SMS (aka text) messages are billed per segment. A single segment is typically 160 characters, but if you're using special
          characters, like emoji it may be less. You can send multiple segments (really as many as you like) but for billing purposes, each
          segment counts as a message sent.
        </p>
      </ModalDialog>
    </div>;
};