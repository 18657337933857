import { Scoped } from 'kremling';
import React, { useState, useEffect, useRef } from 'react';
import { ChromePicker } from 'react-color';
import styles from './color-picker.styles.scss';
export const ColorPicker = props => {
  const [showPicker, setShowPicker] = useState(false);
  const ref = useRef(null);
  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShowPicker(false);
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);
  return <Scoped css={styles}>
      <div ref={ref} className="color-wrapper">
        <div className="color-display" onClick={() => !props.disabled && setShowPicker(true)} style={{
        background: props.color
      }}></div>
        {showPicker && <div className="color-picker">
            <ChromePicker disableAlpha={true} color={{
          hex: props.color
        }} onChange={color => props.onChange(color.hex)} />
          </div>}
      </div>
    </Scoped>;
};