import React, { Component } from 'react';
import { object, func } from 'prop-types';
import { toInteger } from 'lodash';
import { RecurringOption } from './recurring-option.component';
export class Quarterly extends Component {
  static propTypes = {
    data: object,
    rrule: object,
    update: func
  };
  updateDay = e => {
    const num = toInteger(e.target.value) || 1;
    this.props.update({
      rrule: {
        ...this.props.rrule.options,
        bymonthday: num > 31 ? 31 : num
      }
    });
  };
  render() {
    const {
      rrule
    } = this.props;
    return <div>
        <RecurringOption active>
          On Day
          <input onChange={this.updateDay} value={rrule.options.bymonthday} className="form-control inline recurring-inline-with-text" type="number" />
        </RecurringOption>
      </div>;
  }
}