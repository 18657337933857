import React from 'react';
function Icon() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
      <mask id="mask0_24_6443" style={{
      maskType: 'alpha'
    }} width="20" height="20" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0 0H20V20H0z"></path>
      </mask>
      <g mask="url(#mask0_24_6443)">
        <path fill="#53A6D6" d="M9.25 16.125l-.104-3.813a.718.718 0 01.216-.573.78.78 0 01.575-.24.79.79 0 01.584.23.79.79 0 01.229.583v3.813l1.667-1.667a.73.73 0 011.062 0 .729.729 0 010 1.063l-2.958 2.958a.718.718 0 01-.521.23.718.718 0 01-.52-.23l-2.96-2.958a.693.693 0 01-.218-.532.768.768 0 01.24-.53.729.729 0 011.062 0l1.646 1.666zM3.875 10.75l1.667 1.666c.152.153.229.327.229.521a.718.718 0 01-.23.521.73.73 0 01-1.062 0l-2.958-2.937A.718.718 0 011.29 10c0-.195.077-.368.23-.521l2.958-2.958a.729.729 0 011.063 0 .73.73 0 010 1.062L3.875 9.25h3.75c.208 0 .385.073.531.219a.723.723 0 01.219.53.723.723 0 01-.219.532.723.723 0 01-.531.219h-3.75zm12.25 0H12.75a.723.723 0 01-.531-.219A.723.723 0 0112 10c0-.209.073-.386.219-.531a.723.723 0 01.531-.22h3.375l-1.667-1.666a.693.693 0 01-.219-.531.769.769 0 01.24-.531.73.73 0 011.063 0l2.937 2.958c.153.153.23.326.23.52a.718.718 0 01-.23.522l-2.958 2.958a.681.681 0 01-.521.219.759.759 0 01-.52-.24.729.729 0 010-1.062l1.645-1.646zM9.25 3.875L7.583 5.542a.681.681 0 01-.52.218.758.758 0 01-.521-.24.729.729 0 010-1.062l2.937-2.937A.718.718 0 0110 1.29c.194 0 .368.077.52.23l2.96 2.958c.152.153.228.326.228.52a.718.718 0 01-.229.522.729.729 0 01-1.062 0L10.75 3.875l-.104 3.958a.619.619 0 01-.206.48.716.716 0 01-.5.187.663.663 0 01-.492-.201.668.668 0 01-.198-.487V3.875z"></path>
      </g>
    </svg>;
}
export default Icon;