import React, { useContext, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography, TextField, Stack, RadioGroup, Radio, FormControlLabel, FormControl, Checkbox, FormGroup } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { UserStateContext } from 'context/user-state-context';
import { useEffect } from 'react';
import { Label } from '@mui/icons-material';
export const MultiCompanyPrompt = ({
  open,
  onClose,
  onSubmit,
  title,
  permission
}) => {
  const {
    user
  } = useContext(UserStateContext);
  const [option, setOption] = useState('self');
  const [companies, setCompanies] = useState([]);
  const [search, setSearch] = useState('');
  const [availableCompanies, setAvailableCompanies] = useState([]);
  const [showMoreCompanies, setShowMoreCompanies] = useState(10);
  const toggleAll = () => {
    setShowMoreCompanies(availableCompanies.length);
    setCompanies(companies => {
      if (availableCompanies.filter(c => search === '' || c.name.toLowerCase().includes(search.toLowerCase())).every(c => companies.indexOf(c.id) > -1)) {
        return [];
      }
      return availableCompanies.filter(c => search === '' || c.name.toLowerCase().includes(search.toLowerCase())).map(c => c.id);
    });
  };
  const toggleCompany = companyId => {
    setCompanies(companies => {
      if (companies.indexOf(companyId) > -1) {
        return companies.filter(c => c !== companyId);
      }
      return [...companies, companyId];
    });
  };
  useEffect(() => {
    if (!user?.permissions) return;
    setAvailableCompanies(user.companies.sort((a, b) => a.name > b.name ? 1 : -1).filter(function (c) {
      const found = user.permissions['*'] || user.permissions[permission] === '*' || user.permissions?.[permission]?.indexOf(c.id) > -1;
      return found;
    }));
  }, [user]);
  return <Dialog PaperProps={{
    style: {
      borderRadius: '28px',
      width: '460px'
    }
  }} open={open} onClose={onClose}>
      <DialogTitle sx={{
      m: 0,
      p: 2,
      fontSize: 'medium'
    }}>
        <IconButton aria-label="close" onClick={onClose} sx={{
        position: 'absolute',
        right: 8,
        top: 8,
        color: theme => theme.palette.grey[500]
      }}>
          <CloseIcon />
        </IconButton>
        <Stack alignItems="center" component="span">
          <Typography variant="h4" component="span">
            {title}
          </Typography>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack direction="column">
          <FormControl>
            <RadioGroup value={option} onChange={e => setOption(e.target.value)}>
              <FormControlLabel value="self" control={<Radio />} label="Copy to this company" />
              <FormControlLabel value="other" control={<Radio />} label="Copy to other companies" />
            </RadioGroup>
          </FormControl>
          {option !== 'self' && <>
              <TextField label="Search companies" value={search} onChange={e => setSearch(e.target.value)} sx={{
            marginBottom: '5px'
          }} />
              <FormGroup>
                <FormControlLabel onChange={toggleAll} value="self" control={<Checkbox checked={availableCompanies.filter(c => search === '' || c.name.toLowerCase().includes(search.toLowerCase())).every(c => companies.indexOf(c.id) > -1) && availableCompanies.filter(c => search === '' || c.name.toLowerCase().includes(search.toLowerCase())).map(c => c.id).length === companies.length} />} label={search == '' ? 'Select all' : 'Select ONLY these filtered companies'} />
                {availableCompanies.filter((comp, index) => index < showMoreCompanies || search != '').filter(c => search === '' || c.name.toLowerCase().includes(search.toLowerCase())).map(c => <FormControlLabel onChange={() => toggleCompany(c.id)} value={c.id} key={c.id} control={<Checkbox checked={companies.indexOf(c.id) > -1} />} label={<Typography sx={{
              maxWidth: '400px'
            }}>{c.name}</Typography>} />)}
              </FormGroup>
              {showMoreCompanies < availableCompanies.length && search == '' && <Button // click this button to show more companies in increments of 20
          onClick={() => setShowMoreCompanies(showMoreCompanies + 20)} variant="text" sx={{
            mr: 1,
            borderRadius: '20px',
            width: '100px',
            color: '#53A6D6',
            textTransform: 'none'
          }}>
                  Show more
                </Button>}
            </>}
        </Stack>
      </DialogContent>
      <DialogActions sx={{
      py: 1
    }}>
        <Button onClick={onClose} variant="text" sx={{
        borderRadius: '20px',
        color: 'rgba(29, 37, 45, 0.7)',
        width: '75px',
        textTransform: 'none'
      }}>
          Cancel
        </Button>
        <Button disabled={option !== 'self' && companies.length === 0} onClick={() => onSubmit(option === 'self' ? undefined : companies)} variant="text" sx={{
        mr: 1,
        borderRadius: '20px',
        width: '75px',
        color: '#53A6D6',
        textTransform: 'none'
      }}>
          Duplicate
        </Button>
      </DialogActions>
    </Dialog>;
};