import React, { Component, memo } from 'react';
import moment from 'moment-timezone';
import { v4 as uuid } from 'uuid';
import { DynamicTypeAhead } from 'components/dynamic-type-ahead/dynamic-type-ahead.component';
import { CustomerCanvas } from '../customer-canvas/customer-canvas.component';
import { getMail, patchPrints, getPrintRevision, createPrintRevision, createPrintRevisionFromDraft } from 'shared/common.api';
import { Loader } from 'components/loader/loader.component';
import { Icon } from 'components/icon/icon.component';
import { updatePrintRevision, populatePreviewData, getSegments } from '../../../shared/common.api';
import { UserStateContext } from '../../../context/user-state-context';
import { PageHeaderMui } from 'components';
import { Content, DraftIcon, MailIcon, PublishIcon, RevisionIcon, TemplatesIcon, PreviewIcon, SaveIcon, Modal, snackbarService, DownloadIcon } from 'components/mui';
import { Box, IconButton, MenuItem, Stack, TextField, Tooltip, Button } from '@mui/material';
import utils from 'shared/utils';
function makeid(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}
export const Thumbnail = memo(({
  active,
  src
}) => <Box sx={{
  borderRadius: '8px',
  padding: '10px',
  background: active ? '#EFEFEF' : '',
  '&:hover': {
    background: '#DAEFFF'
  },
  marginBottom: '10px'
}}>
    <Box style={{
    padding: '4px',
    background: '#fff',
    borderRadius: '3px',
    border: '1px solid #000'
  }}>
      <img width="72" src={src} />
    </Box>
  </Box>);
export class MailTemplate extends Component {
  static contextType = UserStateContext;
  state = {
    editor: null,
    idMailTemplate: '',
    data: {},
    draft: {},
    loadTemplate: true,
    previewModal: false,
    viewPreviewModal: false,
    publishModal: false,
    isGeneratingProofs: false,
    approveFront: false,
    approveBack: false,
    proofs: [],
    selectedProof: 0,
    company: this.context.asCompany.id,
    segment: null
  };
  componentDidMount() {
    const {
      idMailTemplate
    } = this.props.match.params;
    getMail(idMailTemplate).then(({
      data
    }) => {
      // check for different company link
      this.context.checkCompany(data.company).then(() => {
        const mail = data;
        const draft = data.revisions.find(r => !r.published_when);
        const latestRev = data.revisions.find(r => !!r.published_when);
        if (!draft && latestRev) {
          createPrintRevisionFromDraft(latestRev.id).then(({
            data
          }) => {
            mail.revisions.push({
              id: data.id,
              published_when: null
            });
            this.setState({
              data: mail,
              draft: data,
              loadTemplate: false
            });
          });
        } else if (!draft) {
          this.setState({
            data,
            draft: {
              canvas_id: uuid()
            },
            loadTemplate: false
          });
        } else {
          getPrintRevision(draft.id).then(({
            data
          }) => {
            this.setState({
              data: mail,
              draft: data,
              loadTemplate: false
            });
          });
        }
      });
    }).catch(error => this.setState({
      error,
      isLoading: false
    }));
  }
  updateName = ({
    name
  }) => {
    const data = {
      ...this.state.data,
      name
    };
    this.setState({
      data
    });
    patchPrints(data.id, {
      name
    });
  };
  publish = () => {
    const {
      draft
    } = this.state;
    this.saveProduct().then(updatePrintRevision(draft.id, {
      published_when: new Date()
    })).then(() => {
      snackbarService.popup({
        type: 'success',
        message: 'Mail template successfully published'
      });
      this.props.history.push('/templates');
    });
  };
  saveProduct = () => {
    const {
      idMailTemplate
    } = this.props.match.params;
    const {
      editor,
      draft
    } = this.state;
    return editor.saveProduct(draft.canvas_id).then(res => {
      if (!draft.id) {
        return createPrintRevision({
          print: idMailTemplate,
          canvas_id: res.stateId
        }).then(({
          data
        }) => {
          const print = JSON.parse(JSON.stringify(this.state.data));
          print.revisions.unshift({
            id: data.id
          });
          this.setState({
            draft: data,
            data: print
          });
        });
      }
    });
  };
  onLoad = editor => {
    editor.subscribe();
    this.setState({
      editor
    });
    this.saveProduct();
  };
  openPreviewModal = openModal => {
    this.setState({
      [openModal]: true,
      isGeneratingProofs: true
    });

    // On open preview/publish force save.
    this.saveProduct().then(() => {
      populatePreviewData(this.state.draft.id, {
        segment: this.state.segment?.id
      }).then(({
        data
      }) => {
        this.state.editor.getProofImages({
          maxHeight: 968,
          maxWidth: 968
        }).then(result => {
          this.setState({
            proofs: [
            // This format is a little wonky  but it follows the customer canvas API response
            [data.front + '?cache=' + makeid(32)], [data.back + '?cache=' + makeid(32)]],
            isGeneratingProofs: false
          });
        });
      });
    });
  };
  closePreviewModal = () => {
    this.setState({
      viewPreviewModal: false,
      previewModal: false,
      publishModal: false,
      isGeneratingProofs: false,
      proofs: [],
      selectedProof: 0,
      approveFront: false,
      approveBack: false,
      segment: null
    });
  };
  loadRevision(revision_id) {
    const {
      id
    } = this.state.data;
    this.props.history.push(`/templates/print/${id}/${revision_id}`);
  }
  togglePreviewHandler = () => {
    this.saveProduct().then(() => snackbarService.popup({
      type: 'success',
      message: 'Successfully saved template'
    }));
    this.setState({
      previewModal: true
    });
  };
  toggleDownloadPDFHandler = () => {
    this.saveProduct().then(() => {
      snackbarService.popup({
        type: 'success',
        message: 'Successfully saved template'
      });
      populatePreviewData(this.state.draft.id, {
        segment: this.state.segment?.id
      }).then(({
        data
      }) => {
        this.state.editor.getProofImages({
          maxHeight: 968,
          maxWidth: 968,
          generateProductProof: true
        }).then(async result => {
          window.open(data.pdf + '?filename=' + this?.state?.data?.name, '_blank');
        });
      });
    });
  };
  render() {
    const {
      data,
      draft,
      previewModal,
      viewPreviewModal,
      publishModal,
      isGeneratingProofs,
      proofs,
      editor,
      selectedProof,
      approveFront,
      approveBack,
      loadTemplate
    } = this.state;
    const {
      name,
      substrate_length_inches,
      substrate_width_inches
    } = data;
    const {
      id,
      canvas_id
    } = draft;
    return <div className="wrapper height-100" style={{
      overflow: 'hidden'
    }}>
        <PageHeaderMui type="Templates" name={name} objIcon={<MailIcon />} icon={<TemplatesIcon size="lg" />} updateName={this.updateName} updateNamePermission={this.context.hasPermission('template.change_template')} />
        <Content>
          <Stack direction="row" sx={{
          padding: '12px 24px',
          width: '100%',
          gap: '20px'
        }}>
            <Box sx={{
            flex: '1'
          }}>
              {!!data && !!data.revisions && !!draft && !!draft.id && <TextField value={draft.id} select size="small" onChange={e => this.loadRevision(e.target.value)} sx={{
              maxWidth: '290px',
              width: '100%'
            }}>
                  {!!data && !!data.revisions && data.revisions?.map(revision => <MenuItem key={revision.id} value={revision.id}>
                        <Stack direction="row" sx={{
                  gap: '5px',
                  alignItems: 'center'
                }}>
                          {!revision.published_when ? <>
                              <DraftIcon />
                              Draft
                            </> : <>
                              <RevisionIcon />
                              Published {moment(revision.published_when).format('MMM D, YYYY h:mm A')}
                            </>}
                        </Stack>
                      </MenuItem>)}
                </TextField>}
            </Box>
            <Tooltip title="Download as PDF">
              <IconButton variant="outlined" onClick={() => this.toggleDownloadPDFHandler()} disabled={!editor}>
                <DownloadIcon fill="#53A6D6" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Toggle preview">
              <IconButton variant="outlined" onClick={() => this.togglePreviewHandler()} disabled={!editor}>
                <PreviewIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Save">
              <IconButton variant="outlined" disabled={!editor} onClick={() => this.saveProduct().then(() => snackbarService.popup({
              type: 'success',
              message: 'Successfully saved template'
            }))}>
                <SaveIcon />
              </IconButton>
            </Tooltip>
            <Button disableElevation onClick={() => this.openPreviewModal('publishModal')} disabled={!editor} variant="contained" startIcon={<PublishIcon fill="#ffffff" />}>
              Publish
            </Button>
          </Stack>

          <Box sx={{
          borderRadius: '0 0 14px 14px',
          overflow: 'hidden'
        }}>
            {!loadTemplate && <CustomerCanvas onLoad={this.onLoad} canvasId={id ? canvas_id : null} length={substrate_length_inches} width={substrate_width_inches} />}
          </Box>
        </Content>

        <Modal open={viewPreviewModal} onClose={this.closePreviewModal} title="Preview" noDivider fullWidth maxWidth={false} sx={{
        maxWidth: '996px',
        margin: '0 auto'
      }}>
          {isGeneratingProofs && <Loader />}
          {!!proofs.length && <div style={{
          padding: '0 27px'
        }}>
              <img src={proofs[selectedProof][0] + '?r=' + uuid()} width="100%" style={{
            border: '1px solid #BCBCBC'
          }} />
            </div>}
          <Stack direction="row" gap="20px" sx={{
          justifyContent: 'center',
          textAlign: 'center',
          marginTop: '40px'
        }}>
            {proofs.map((proof, index) => <>
                <Box key={index.toString()} style={{
              cursor: 'pointer'
            }} onClick={() => this.setState({
              selectedProof: index
            })}>
                  <Thumbnail src={proof[0]} active={selectedProof === index} />
                  {index === 0 ? 'Front' : 'Back'}
                </Box>
              </>)}
          </Stack>
        </Modal>

        <Modal open={previewModal} onClose={this.closePreviewModal} title="Preview" sx={{
        margin: '0 auto'
      }} allowOverflow={true} actions={<>
              <Button variant="outlined" sx={{
          width: '125px'
        }} onClick={this.closePreviewModal} size="large">
                Cancel
              </Button>
              <Button disableElevation variant="contained" sx={{
          width: '125px'
        }} size="large" onClick={() => {
          this.setState({
            previewModal: false
          });
          this.openPreviewModal('viewPreviewModal');
        }}>
                Preview
              </Button>
            </>}>
          <p>Select a sample customer from a segment or leave blank to generate random data:</p>
          <DynamicTypeAhead getItems={getSegments} getItemsFilters={{
          company: this.context.asCompany.id
        }} placeholder="Choose Customer Segment" displayProperty="name" keyProperty="id" value={null} onChange={segment => this.setState({
          segment
        })} />
          <p className="mt-5">Make sure you've saved your changes before previewing.</p>
        </Modal>

        <Modal open={publishModal} title="Publish" onClose={this.closePreviewModal} noDivider fullWidth maxWidth={false} sx={{
        maxWidth: '996px',
        margin: '0 auto'
      }} actions={<>
              <Button variant="outlined" onClick={this.closePreviewModal}>
                Cancel
              </Button>
              <Button variant="outlined" disabled={selectedProof === 0 ? approveFront : approveBack} onClick={() => {
          let newState = {};
          if (selectedProof === 0) {
            newState.approveFront = true;
            if (!approveBack) newState.selectedProof = 1;
          } else {
            newState.approveBack = true;
            if (!approveFront) newState.selectedProof = 0;
          }
          this.setState(newState);
        }}>
                {selectedProof === 0 && approveFront || selectedProof !== 0 && approveBack ? 'Approved' : `Approve ${selectedProof === 0 ? 'Front' : 'Back'}`}
              </Button>
              <Button disableElevation disabled={!approveBack || !approveFront} onClick={this.publish} variant="contained">
                Publish
              </Button>
            </>}>
          <>
            {isGeneratingProofs && <Loader />}
            <p>
              Please check these images carefully for misprints or errors.
              <br />
              By proceeding you confirm that you are responsible for any mistakes or problems with this design.
            </p>

            {!!proofs.length && <div style={{
            padding: '0 27px'
          }}>
                <img src={proofs[selectedProof][0]} width="100%" style={{
              border: '1px solid #BCBCBC'
            }} />
              </div>}
            <Stack direction="row" sx={{
            justifyContent: 'center',
            gap: '20px',
            textAlign: 'center',
            marginTop: '40px'
          }}>
              {proofs.map((proof, index) => <>
                  <Box key={index.toString()} style={{
                borderRadius: '8px',
                cursor: 'pointer'
              }} onClick={() => this.setState({
                selectedProof: index
              })}>
                    <Thumbnail src={proof[0]} active={selectedProof === index} />
                    <Stack direction="row" gap="15px" sx={{
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                      {index === 0 ? 'Front' : 'Back'}
                      {(index === 0 && approveFront || index !== 0 && approveBack) && <div className="preview-proof__check">
                          <Icon name="fa-regular-check" size={14} />
                        </div>}
                    </Stack>
                  </Box>
                </>)}
            </Stack>
          </>
        </Modal>
      </div>;
  }
}