import React, { Component } from 'react';
import { snackbarService } from './snackbar-service';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import MuiAlert from '@mui/material/Alert';
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export class SnackbarToaster extends Component {
  state = {
    toast: {},
    open: false
  };
  componentDidMount() {
    snackbarService.passToastFunc(this.toast);
  }
  toast = toastConfig => {
    this.setState(({
      toast
    }) => ({
      toast: {
        ...toastConfig
      }
    }));
  };
  close = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      toast: {}
    });
  };
  render() {
    const {
      toast
    } = this.state;
    return <div>
        {toast.open && <div>
            <Snackbar open={true} autoHideDuration={toast.duration ? toast.duration : 6000} onClose={this.close} sx={{
          top: toast.middle ? '0px' : window.innerHeight - 50 + 'px',
          whiteSpace: 'pre-line'
        }} message={toast.message} action={toast.action} anchorOrigin={{
          vertical: toast.vertical || 'bottom',
          horizontal: toast.horizontal || 'left'
        }} TransitionComponent={Slide}>
              {toast.type && toast.type != 'default' && <Alert onClose={this.close} severity={toast.type} sx={{
            width: '100%'
          }}>
                  {toast.message}
                </Alert>}
            </Snackbar>
          </div>}
      </div>;
  }
}