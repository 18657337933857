import React from "react";
function Icon() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" fill="none" viewBox="0 0 21 20">
      <mask id="mask0_104_1799" style={{
      maskType: "alpha"
    }} width="21" height="20" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0.73 0H20.73V20H0.73z"></path>
      </mask>
      <g fill="#53A6D6" mask="url(#mask0_104_1799)">
        <path d="M3.727 14.899c0 .3.116.527.35.684a.724.724 0 00.746.056l4.5-1.89v-.172c.005-.176.03-.393.073-.652.043-.258.11-.5.2-.725L5.06 14.083v-2.93L9.6 10.02 5.06 8.889V5.96l7.335 3.09a3.872 3.872 0 011.01-.406c.39-.103.77-.16 1.14-.17h.007L4.831 4.37a.732.732 0 00-.75.056c-.236.157-.354.385-.354.685v9.788zM14.795 15.643c.458 0 .849-.164 1.173-.491.325-.328.487-.72.487-1.178 0-.457-.164-.848-.491-1.173a1.614 1.614 0 00-1.178-.487 1.59 1.59 0 00-1.173.492c-.325.327-.487.72-.487 1.177 0 .458.164.849.492 1.173.327.325.72.487 1.177.487zm-.122 2.098a.45.45 0 01-.301-.113.453.453 0 01-.16-.28l-.112-.566a3.74 3.74 0 01-.754-.294 2.205 2.205 0 01-.594-.473l-.598.205a.47.47 0 01-.311-.006.45.45 0 01-.218-.194l-.126-.194a.558.558 0 01-.072-.292.438.438 0 01.132-.287l.447-.433a2.955 2.955 0 01-.118-.813c0-.267.039-.539.117-.814l-.424-.41a.527.527 0 01-.143-.272.51.51 0 01.052-.307l.127-.194a.398.398 0 01.23-.217.531.531 0 01.329-.005l.554.182c.18-.22.381-.39.605-.51.223-.12.478-.21.765-.27l.113-.59c.019-.108.074-.199.167-.27a.5.5 0 01.315-.108h.191a.55.55 0 01.316.102.364.364 0 01.167.268l.112.599c.287.074.546.167.776.28.231.114.43.28.594.5l.576-.183a.446.446 0 01.311.011c.09.04.163.111.218.21l.126.195c.052.093.072.19.06.292a.495.495 0 01-.142.286l-.402.389c.078.275.114.55.107.825-.008.274-.05.55-.13.824l.425.411a.53.53 0 01.143.283.452.452 0 01-.052.296l-.126.194a.468.468 0 01-.224.193.476.476 0 01-.314.007l-.598-.183c-.18.199-.378.357-.594.473-.216.115-.467.21-.754.284l-.134.59a.429.429 0 01-.16.272.465.465 0 01-.3.107h-.214z"></path>
      </g>
    </svg>;
}
export default Icon;