import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Scoped } from 'kremling';
import classnames from 'classnames';
import styles from './domains.styles.scss';
import { PageHeader } from '../../components/page-header/page-header';
import { Loader } from '../../components/loader/loader.component';
import { Button } from '../../components/button/button.component';
import { Icon } from '../../components/icon/icon.component';
import { SortHeader } from '../../components/table/sort-header.component';
import { Dropdown } from '../../components/dropdown/dropdown.component';
import { Pagination } from '../../components/pagination/pagination.component';
import { AsyncModalDialog } from '../../components/modal/async-modal-dialog.component';
import { UserStateContext } from 'context/user-state-context';
import { toasterService } from '../../components/toaster/toaster-service';
export function VerifyDomainModal(props) {
  const copyToClipboard = text => {
    navigator.clipboard.writeText(text);
    toasterService.success(`${text} copied to clipboard!`);
  };
  return <Scoped css={styles}>
      <AsyncModalDialog size="xl" open={!!props.verifyModal} title="Verify Domain" submitText="Verify" onSubmit={() => props.onSubmit()} onClose={() => props.onClose()} allowBackdropClick>
        <p>Add these records to your domain provider's DNS settings</p>

        <table className="table-list mb-2">
          <thead>
            <tr>
              <th>Type</th>
              <th>Host</th>
              <th>Data</th>
              <th style={{
              width: '70px'
            }}>Verified</th>
            </tr>
          </thead>
          <tbody>
            {props.verifyModalDomain && Object.values(props.verifyModalDomain.dns).map(dns => <tr key={dns.host}>
                  <td className="text-uppercase">{dns.type}</td>
                  <td onClick={() => copyToClipboard(dns.host)}>
                    {dns.host} <Icon size={13} name="fa-regular-clipboard" />
                  </td>
                  <td onClick={() => copyToClipboard(dns.data)}>
                    {dns.data} <Icon size={13} name="fa-regular-clipboard" />
                  </td>
                  <td className="text-center">
                    {!dns.valid && <Icon className="text-danger" name="fa-solid-exclamation-circle" />}
                    {dns.valid && <Icon className="text-success" name="fa-solid-check-circle" />}
                  </td>
                </tr>)}
          </tbody>
        </table>

        <p className="text-center">
          Having trouble?{' '}
          <a href="http://support.cinch.io/knowledge/why-you-should-verify-your-domain" target="_blank">
            View our vendor's documentation
          </a>
        </p>
      </AsyncModalDialog>
    </Scoped>;
}