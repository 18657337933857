import React, { useState, useContext } from 'react';
import classnames from 'classnames';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { AsyncModalDialog } from 'components/modal/async-modal-dialog.component';
import { DynamicTypeAheadMui } from 'components/dynamic-type-ahead/dynamic-type-ahead-mui.component';
import { createDomains, getDomains } from 'shared/common.api';
import { UserStateContext } from 'context/user-state-context';
export function AddDomainModal({
  onClose,
  onSubmit,
  open
}) {
  const {
    user,
    asCompany,
    flags,
    hasMultiPermission
  } = useContext(UserStateContext);
  const [tab, setTab] = useState("new");
  const [domain, setDomain] = useState("");
  const [importDomain, setImportDomain] = useState({});
  const [useCustom, setUseCustom] = useState(false);
  const [customDKIM, setCustomDKIM] = useState('');
  const [errors, setErrors] = useState({});
  const createDomain = () => {
    return createDomains({
      company: asCompany.id,
      domain: tab === 'import' ? importDomain.domain : domain,
      inherited_from: tab === 'import' ? importDomain.id : null,
      custom_dkim_selector: tab === 'new' && useCustom ? customDKIM : null
    }).then(({
      data
    }) => {
      onSubmit(data);
    }).catch(e => {
      setErrors(e.response.data);
      throw e;
    });
  };
  return <AsyncModalDialog open={!!open} title="Add New Domain" submitText="Add" onSubmit={() => createDomain()} onClose={() => onClose()} submitDisabled={tab === 'import' && !importDomain?.id || tab === 'new' && domain === ''} allowBackdropClick>
      {(user.is_superuser || flags.email_domain_mapping) && hasMultiPermission('email.add_domain') && <Tabs value={tab} onChange={(e, val) => setTab(val)} style={{
      marginBottom: '10px'
    }}>
          <Tab sx={{
        textTransform: 'none'
      }} label="New" value="new" />
          <Tab sx={{
        textTransform: 'none'
      }} label="Import" value="import" />
        </Tabs>}

      {tab === 'new' ? <>
          <p>Enter the domain you would like to verify.</p>
          <div className={classnames('form-group', {
        'is-invalid': errors.domain
      })}>
            <label>Domain</label>
            <input className="form-control" name="create-modal-domain" onChange={e => setDomain(e.target.value)} value={domain} autoFocus />
            {errors.domain && errors.domain.map((e, i) => <div className="invalid-feedback text-danger" key={i}>
                  {e}
                </div>)}
          </div>
          <div className="form-group">
            <div className="d-flex align-item-center" style={{
          gap: '8px'
        }}>
              <label className="m-0 p-0">Use a custom DKIM selector?</label>
              <input type="checkbox" className="form-check-input" checked={useCustom} onChange={e => setUseCustom(e.target.checked)} />
            </div>
            <div className="text-sm">
              <em>Advanced users only.</em>
            </div>
          </div>
          {useCustom && <div className={classnames('form-group', {
        'is-invalid': errors.custom_dkim_selector
      })}>
                <label>Custom DKIM Selector</label>
                <input className="form-control" name="create-modal-domain" onChange={e => setCustomDKIM(e.target.value)} value={customDKIM} autoFocus />
                {errors.custom_dkim_selector && errors.custom_dkim_selector.map((e, i) => <div className="invalid-feedback text-danger" key={i}>
                      {e}
                    </div>)}
              </div>}
        </> : <>
          <p>Select the domain you want to import:</p>
          <div className={classnames('form-group', {
        'is-invalid': errors.domain
      })}>
            <label>Domain</label>
            <DynamicTypeAheadMui getItems={getDomains} getItemsFilters={{
          status: 'verified',
          is_parent: true
        }} placeholder={'Choose Domain'} size="small" displayProperty="domain" keyProperty="id" value={importDomain?.value} onChange={val => setImportDomain(val)} />
            {errors.domain && errors.domain.map((e, i) => <div className="invalid-feedback text-danger" key={i}>
                  {e}
                </div>)}
          </div>
        </>}

    </AsyncModalDialog>;
}