import React from "react";
function Icon() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" fill="none" viewBox="0 0 25 24">
      <mask id="mask0_1_977" style={{
      maskType: "alpha"
    }} width="25" height="25" x="0" y="-1" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0.051 0H24.051V24H0.051z"></path>
      </mask>
      <g mask="url(#mask0_1_977)">
        <path fill="#1D252D" d="M16.55 18.75a.725.725 0 01-.534-.216.726.726 0 01-.215-.534v-3.25h-3.25a.726.726 0 01-.535-.216.726.726 0 01-.215-.535c0-.212.072-.39.215-.534a.726.726 0 01.535-.215h3.25V10c0-.213.072-.39.216-.535a.726.726 0 01.534-.215c.213 0 .39.072.534.215a.726.726 0 01.216.535v3.25h3.25c.212 0 .39.072.534.216a.726.726 0 01.216.534c0 .213-.072.39-.216.534a.726.726 0 01-.534.216H17.3V18c0 .212-.072.39-.216.534a.726.726 0 01-.535.216zm-13-4a.725.725 0 01-.534-.216.726.726 0 01-.215-.535c0-.212.072-.39.215-.534a.726.726 0 01.535-.215h5.5c.212 0 .39.072.534.216a.726.726 0 01.216.534c0 .213-.072.39-.216.534a.726.726 0 01-.534.216h-5.5zm0-4a.725.725 0 01-.534-.216.726.726 0 01-.215-.535c0-.212.072-.39.215-.534a.726.726 0 01.535-.215h9.5c.212 0 .39.072.534.215a.726.726 0 01.216.535c0 .213-.072.39-.216.534a.727.727 0 01-.534.216h-9.5zm0-4a.725.725 0 01-.534-.216.726.726 0 01-.215-.535c0-.212.072-.39.215-.534a.726.726 0 01.535-.215h9.5c.212 0 .39.072.534.215a.726.726 0 01.216.535c0 .213-.072.39-.216.534a.726.726 0 01-.534.216h-9.5z"></path>
      </g>
    </svg>;
}
export default Icon;