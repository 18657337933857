import * as React from 'react';
import { MultiSelectFilter } from './multi-select.component';
import { UserStateContext } from 'context/user-state-context';
import { getUsers } from 'shared/common.api';
export const UserFilter = ({
  ...props
}) => {
  const {
    asCompany
  } = React.useContext(UserStateContext);
  const [users, setUsers] = React.useState([]);
  React.useEffect(() => {
    if (asCompany.id) {
      props.api(asCompany.id).then(data => {
        setUsers(data.data.map(user => ({
          label: user.first_name + ' ' + user.last_name,
          value: user.id
        })));
      });
    }
  }, [asCompany?.id]);
  return <MultiSelectFilter label={props.label || 'Created By'} pluralLabel={props.pluralLabel || 'Created By'} key={props.key || 'created_by_id'} options={users} {...props} />;
};