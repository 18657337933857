import React, { useState, useEffect, useContext } from 'react';
import { Button, Loader, toasterService } from 'components';
import { updateBusinessAddress } from 'shared/common.api';
import { UserStateContext } from 'context/user-state-context';
const REQUIRED_FIELDS = ['city', 'street', 'region', 'postal_code'];
export const BusinessAddressStep = props => {
  const {
    updateProfile,
    profile,
    setActiveStep,
    verification
  } = props;
  const {
    asCompany
  } = useContext(UserStateContext);
  const [disabled, setDisabled] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const submit = () => {
    // Submit this to the Cinch API which will submit to the Twilio API...
    // This will not be stored in Cinch's database but will be retrieved directly from Twilio
    setIsSaving(true);
    updateBusinessAddress({
      company: asCompany.id,
      ...profile.business_address
    }).then(() => {
      setActiveStep(3);
    }).catch(() => {
      toasterService.error('There was an unknown error trying to save your profile. Check your inputs and try again');
    }).then(() => {
      setIsSaving(false);
    });
  };
  useEffect(() => {
    setDisabled(REQUIRED_FIELDS.some(field => !profile?.business_address?.[field] || profile?.business_address?.[field] === ''));
  }, [profile]);
  return <>
      <div className="form-group">
        <label>Address</label>
        <input className="form-control" name="street" onChange={e => updateProfile('business_address', 'street', e.target.value)} value={profile.business_address?.street || ''} autoFocus />
        {verification?.error?.street && <small className="mt-1 block text-danger">{verification?.error?.street}</small>}
      </div>

      <div className="form-group">
        <label>City</label>
        <input className="form-control" name="city" onChange={e => updateProfile('business_address', 'city', e.target.value)} value={profile.business_address?.city || ''} />
        {verification?.error?.city && <small className="mt-1 block text-danger">{verification?.error?.city}</small>}
      </div>

      <div className="form-group">
        <label>State</label>
        <input className="form-control" name="region" onChange={e => updateProfile('business_address', 'region', e.target.value)} value={profile.business_address?.region || ''} />
        {verification?.error?.region && <small className="mt-1 block text-danger">{verification?.error?.region}</small>}
      </div>

      <div className="form-group">
        <label>Zip Code</label>
        <input className="form-control" name="postal_code" onChange={e => updateProfile('business_address', 'postal_code', e.target.value)} value={profile.business_address?.postal_code || ''} />
        {verification?.error?.postal_code && <small className="mt-1 block text-danger">{verification?.error?.postal_code}</small>}
      </div>

      <div style={{
      display: 'flex',
      gap: '10px',
      justifyContent: 'end'
    }}>
        <Button actionType="flat" onClick={() => setActiveStep(1)}>
          Back
        </Button>
        <Button actionType="primary" disabled={disabled || isSaving} onClick={submit}>
          Save {isSaving && <Loader className="ml-3" size="sm" />}
        </Button>
      </div>
    </>;
};