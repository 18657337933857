import React from 'react';
function Icon(props) {
  if (props.size == 'sm') {
    return <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#e8eaed" viewBox="0 -960 960 960">
        <path fill={props.fill || '#107EBE'} d="M276.03-116q-27.03 0-45.53-18.65T212-180.31v-599.38q0-27.01 18.65-45.66Q249.3-844 276.31-844H586l162 162v501.69q0 27.01-18.66 45.66Q710.68-116 683.65-116H276.03zM548-644v-148H276.31q-4.62 0-8.46 3.85-3.85 3.84-3.85 8.46v599.38q0 4.62 3.85 8.46 3.84 3.85 8.46 3.85h407.38q4.62 0 8.46-3.85 3.85-3.84 3.85-8.46V-644H548zM264-792v169-169 624-624z"></path>
      </svg>;
  }
  if (props.size == 'lg') {
    return <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="#e8eaed" viewBox="0 -960 960 960">
        <path fill={props.fill || '#107EBE'} d="M242.57-100q-25.79 0-44.18-18.39T180-162.57v-634.86q0-25.79 18.39-44.18T242.57-860h337.58L780-660.15v497.58q0 25.79-18.39 44.18T717.43-100H242.57zm312.46-536.51v-173.23H242.57q-4.62 0-8.47 3.84-3.84 3.85-3.84 8.47v634.86q0 4.62 3.84 8.47 3.85 3.84 8.47 3.84h474.86q4.62 0 8.47-3.84 3.84-3.85 3.84-8.47v-473.94H555.03zM230.26-809.74v173.23-173.23 659.48-659.48z"></path>
      </svg>;
  }
  if (props.size == 'xlg') {
    return <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="#e8eaed" viewBox="0 -960 960 960">
        <path fill={props.fill || '#107EBE'} d="M237.69-100q-23.53 0-40.61-17.08T180-157.69v-644.62q0-23.53 17.08-40.61T237.69-860h347.54L780-665.23v507.54q0 23.53-17.08 40.61T722.31-100H237.69zm324.85-544.77v-169.84H237.69q-4.61 0-8.46 3.84-3.84 3.85-3.84 8.46v644.62q0 4.61 3.84 8.46 3.85 3.84 8.46 3.84h484.62q4.61 0 8.46-3.84 3.84-3.85 3.84-8.46v-487.08H562.54zM225.39-814.61v169.84-169.84 669.22-669.22z"></path>
      </svg>;
  }
  return <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="none" viewBox="0 0 25 25">
      <mask id="mask0_10_673" style={{
      maskType: 'alpha'
    }} width="25" height="25" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0.902 0.141H24.902V24.141H0.902z"></path>
      </mask>
      <g mask="url(#mask0_10_673)">
        <path fill={props.fill || '#107EBE'} d="M7.21 21.641c-.505 0-.933-.175-1.283-.525a1.745 1.745 0 01-.525-1.282V4.449c0-.505.175-.933.525-1.283.35-.35.778-.525 1.283-.525h7.194c.241 0 .473.047.695.14.223.094.416.223.58.387l4.196 4.196c.165.164.293.358.387.58.093.223.14.454.14.695v11.195c0 .505-.175.932-.525 1.282-.35.35-.777.525-1.282.525H7.21zm7.192-13.904V4.141H7.21a.294.294 0 00-.212.096.294.294 0 00-.096.212v15.385c0 .076.032.147.096.211a.294.294 0 00.212.096h11.385a.294.294 0 00.211-.096.294.294 0 00.096-.211V8.64h-3.596a.876.876 0 01-.645-.258.876.876 0 01-.259-.646z"></path>
      </g>
    </svg>;
}
export default Icon;