import React from 'react';
const STATUS_MAP = {
  subscribed: 'Subscribed',
  pending: 'Pending',
  suppressed: 'Suppressed',
  unknown: 'Unknown'
};

// TODO replace this entire component with a simple camel to title case component

export const MultiSelect = props => {
  const optStatuses = props.data.optStatuses || [];
  const statuses = optStatuses.map(s => STATUS_MAP[s]);
  return <div>
      {statuses.length ? statuses.join(', ') : <em>No statuses selected</em>}
    </div>;
};