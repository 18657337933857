import React, { useContext } from 'react';
import { Status, PageHeaderMui } from 'components';
import EnhancedTable from 'components/mui/enhanced-table/enhanced-table.component';
import { getFiltersFromURL } from 'components/mui/enhanced-table/utils';
import CssBaseline from '@mui/material/CssBaseline';
import { ModalContext } from 'context/modal-context';
import { getIntegrationLogs } from 'shared/common.api';
import moment from 'moment-timezone';
import { IntegrationLogDetailModal } from './integration-log-detail-modal.component';
const headCells = [{
  key: 'integration_name',
  label: 'Integration',
  width: '17%',
  displayComponent: ({
    data: integration
  }) => integration.integration_name || 'Unknown'
}, {
  key: 'updated_when',
  label: 'Last Updated',
  width: '17%',
  displayComponent: ({
    data: integration
  }) => moment(integration.updated_when).format('MMM D, YYYY h:mm A')
}, {
  key: 'source_stream',
  label: 'Source Stream',
  width: '17%',
  displayComponent: ({
    data: integration
  }) => integration.source_stream || 'Unknown'
}, {
  key: 'target_stream',
  label: 'Target Table',
  width: '17%',
  displayComponent: ({
    data: integration
  }) => integration.target_stream || 'Unknown'
}, {
  key: 'rows_affected',
  label: 'Rows Affected',
  width: '17%',
  displayComponent: ({
    data: integration
  }) => (integration.rows_affected || "0").toLocaleString('en-US')
}, {
  key: 'status',
  label: 'Status',
  width: '15%',
  displayComponent: ({
    data: integration
  }) => {
    if (integration.error_message) {
      return <Status status="deleted">Error</Status>;
    } else if (integration.completed_when) {
      return <Status status="published">Finished</Status>;
    }
    return <Status status="draft">In Progress</Status>;
  }
}];
export const IntegrationLogs = () => {
  const {
    show
  } = useContext(ModalContext);
  const filterOptions = [];
  const [filters, setFilters] = React.useState(() => {
    const fils = getFiltersFromURL(filterOptions);
    return fils;
  });
  const handleRowClick = row => {
    show(IntegrationLogDetailModal, {
      row
    }).catch(() => {});
  };
  return <>
      <CssBaseline />
      <div className="wrapper">
        <PageHeaderMui type={'Integration Logs'} />

        <div className="mui-wrapper">
          <EnhancedTable type="Integration Logs" headCells={headCells} api={getIntegrationLogs} defaultSort={'-updated_when'} filterOptions={filterOptions} filtersState={[filters, setFilters]} handleRowClick={handleRowClick} />
        </div>
      </div>
    </>;
};