import * as React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import moment from 'moment';
import { LicenseInfo } from '@mui/x-license-pro';
import { Icon } from '../../icon/icon.component';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Stack from '@mui/material/Stack';
import dayjs from 'dayjs';
import { Calendar24HoursIcon, Calendar30DaysIcon, Calendar365DaysIcon, Calendar7DaysIcon, CalendarAllIcon } from '../icon';
function TabPanel(props) {
  const {
    children,
    value,
    index,
    ...other
  } = props;
  return <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} {...other}>
      {value === index && <Box sx={{}}>
          <Typography component="div">{children}</Typography>
        </Box>}
    </div>;
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};
function tabProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}
export default function PresetsCalendar(props) {
  const {
    after,
    before
  } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [currentTab, setCurrentTab] = React.useState(!!before ? 1 : 0);
  const [calendarValue, setCalendarValue] = React.useState([dayjs().subtract(7, 'day'), dayjs()]);
  const [pendingDateFilter, setPendingDateFilter] = React.useState(() => {
    const days = moment().diff(moment(after), 'days');

    // These dates all have arbitrary "rounding" to find the best closest setting
    // For example if the date difference between the filter date and today is between 0 and 5 days
    // We say that's "Last 24 hours"
    if (days < 5) {
      return 'Last 24 hours';
    } else if (days < 25) {
      return 'Last 7 days';
    } else if (days < 300) {
      return 'Last 30 days';
    } else if (days < 400) {
      return 'Last year';
    }
    return 'All';
  });
  const [dateChanged, setDateChanged] = React.useState(false);
  const dateRanges = [{
    key: 'Last 24 hours',
    label: <span> Last 24 hours </span>,
    label: <Stack direction="row" spacing={2}>
          <Calendar24HoursIcon />
          <span style={{
        marginTop: '2px'
      }}>Last 24 hours</span>
        </Stack>
  }, {
    key: 'Last 7 days',
    label: <Stack direction="row" spacing={2}>
          <Calendar7DaysIcon />
          <span style={{
        marginTop: '2px'
      }}>Last 7 days</span>
        </Stack>
  }, {
    key: 'Last 30 days',
    label: <Stack direction="row" spacing={2}>
          <Calendar30DaysIcon />
          <span style={{
        marginTop: '2px'
      }}>Last 30 days</span>
        </Stack>
  }, {
    key: 'Last year',
    label: <Stack direction="row" spacing={2}>
          <Calendar365DaysIcon />
          <span style={{
        marginTop: '2px'
      }}>Last year</span>
        </Stack>
  }, {
    key: 'All',
    label: <Stack direction="row" spacing={2}>
          <CalendarAllIcon />
          <span style={{
        marginTop: '2px'
      }}>All</span>
        </Stack>
  }];
  LicenseInfo.setLicenseKey(MUIX_API);
  const handleTabsChange = (event, newValue) => {
    setCurrentTab(newValue);
  };
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const horizontal = props.horizontal ? props.horizontal : 'left';
  const vertical = props.vertical ? props.vertical : 'bottom';
  return <>
      <Button variant="outlined" endIcon={<KeyboardArrowDownIcon />} onClick={handleClick} sx={{
      color: '#1D252D',
      textTransform: 'none',
      border: '1px solid #BEBEBE',
      borderRadius: '8px',
      minWidth: '170px'
    }}>
        {after && before ? <Typography sx={{
        color: '#1D252D'
      }}>
            {moment(after).format('MM/D/YY')}-{moment(before).format('MM/D/YY')}
          </Typography> : !after ? <>
            <CalendarAllIcon />
            <Typography sx={{
          pl: 1,
          color: '#1D252D'
        }}>All</Typography>
          </> : after && moment(after).format('MM/D/YY') === moment(new Date().setDate(new Date().getDate() - 30)).format('MM/D/YY') ? <>
            <Calendar30DaysIcon />
            <Typography sx={{
          pl: 1,
          color: '#1D252D'
        }}>Last 30 days</Typography>
          </> : after && moment(after).format('MM/D/YY') === moment(new Date().setDate(new Date().getDate() - 1)).format('MM/D/YY') ? <>
            <Calendar24HoursIcon />
            <Typography sx={{
          pl: 1,
          color: '#1D252D'
        }}>Last 24 hours</Typography>
          </> : after && moment(after).format('MM/D/YY') === moment(new Date().setDate(new Date().getDate() - 365)).format('MM/D/YY') ? <>
            <Calendar365DaysIcon />
            <Typography sx={{
          pl: 1,
          color: '#1D252D'
        }}>Last year</Typography>
          </> : after && moment(after).format('MM/D/YY') === moment(new Date().setDate(new Date().getDate() - 7)).format('MM/D/YY') ? <>
            <Calendar7DaysIcon />
            <Typography sx={{
          pl: 1,
          color: '#1D252D'
        }}>Last 7 days</Typography>
          </> : <>
            <Calendar7DaysIcon /> Last 7 days
          </>}
      </Button>

      <Popper id={id} open={open} anchorEl={anchorEl} onClose={handleClose} elevation={3} anchorOrigin={{
      vertical: vertical,
      horizontal: horizontal
    }} sx={{
      zIndex: 200,
      borderRadius: '28px'
    }}>
        <ClickAwayListener onClickAway={handleClose}>
          <Paper elevation={0} sx={{
          width: '311px',
          height: '366px',
          borderRadius: '28px',
          filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))'
        }}>
            <Box sx={{
            borderBottom: 1,
            borderColor: 'divider'
          }}>
              <Tabs value={currentTab} sx={{
              height: '53px'
            }} onChange={handleTabsChange}>
                <Tab sx={{
                textTransform: 'none'
              }} label="Preset" {...tabProps(0)} />
                <Tab sx={{
                textTransform: 'none'
              }} label="Range" {...tabProps(1)} />
              </Tabs>
            </Box>
            <Box sx={{
            height: '270px'
          }}>
              <TabPanel value={currentTab} index={0}>
                <Box display="flex" flexDirection="column">
                  {dateRanges.map(date => <Box key={date.key} onClick={() => {
                  setPendingDateFilter(date.key);
                  setDateChanged(true);
                }} sx={{
                  borderLeft: '2px solid',
                  borderColor: pendingDateFilter === date.key ? '#53A6D6' : '#FFFFFF'
                }}>
                      <Button variant="text" fullWidth sx={{
                    justifyContent: 'flex-start',
                    py: 2
                  }}>
                        <Typography sx={{
                      pl: 1,
                      textTransform: 'none',
                      color: pendingDateFilter === date.key ? '#53A6D6' : '#1D252D'
                    }}>
                          {date.label}
                        </Typography>
                      </Button>
                    </Box>)}
                </Box>
              </TabPanel>

              <TabPanel value={currentTab} index={1}>
                <Box sx={{
                p: 2
              }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} localeText={{
                  after: 'after date',
                  before: 'before date'
                }} sx={{
                  padding: '10px'
                }}>
                    <DateRangePicker value={calendarValue} onChange={newValue => {
                    setCalendarValue(newValue);
                    setDateChanged(true);
                  }} renderInput={(afterProps, endProps) => <React.Fragment>
                          <TextField {...afterProps} />
                          <Box sx={{
                      mx: 2
                    }}> to </Box>
                          <TextField {...endProps} />
                        </React.Fragment>} />
                  </LocalizationProvider>
                </Box>
              </TabPanel>
            </Box>
            <Box sx={{
            borderTop: 1,
            borderColor: 'divider',
            height: '24px'
          }}>
              <Button fullWidth variant="text" disabled={currentTab === 0 && !pendingDateFilter} onClick={() => {
              setDateChanged(false);
              if (currentTab === 0) {
                let val = '';
                let d = 0;
                if (pendingDateFilter === 'Last 30 days') {
                  d = 30;
                } else if (pendingDateFilter === 'Last 24 hours') {
                  d = 1;
                } else if (pendingDateFilter === 'Last year') {
                  d = 365;
                } else if (pendingDateFilter === 'Last 7 days') {
                  d = 7;
                }
                val = d ? moment().subtract(d, 'days').startOf('day').toISOString() : '';
                props.handleDateChange(val, null);
              } else if (currentTab === 1) {
                props.handleDateChange(moment.isDate(calendarValue[0]) ? moment(calendarValue[0]).startOf('day').toISOString() : calendarValue[0].startOf('day').toISOString(), moment.isDate(calendarValue[1]) ? moment(calendarValue[1]).endOf('day').toISOString() : calendarValue[1].endOf('day').toISOString());
              }
              handleClose();
            }} sx={{
              justifyContent: 'flex-end'
            }}>
                <Typography sx={{
                pt: 1,
                textTransform: 'none',
                mr: '16px',
                color: dateChanged ? '#53A6D6' : '#D9D9D9'
              }}>Apply</Typography>
              </Button>
            </Box>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>;
}