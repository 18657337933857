import React, { useState, useEffect, useContext } from 'react';
import { Scoped } from 'kremling';
import classnames from 'classnames';
import styles from './domains.styles.scss';
import { Icon } from '../../components/icon/icon.component';
import { AsyncModalDialog } from '../../components/modal/async-modal-dialog.component';
import { toasterService } from '../../components/toaster/toaster-service';
import { getDomains, verifyDomain, deleteDomain, getAddresses, createAddress, resendVerificationEmail, deleteAddress } from '../../shared/common.api';
import { UserStateContext } from 'context/user-state-context';
import utils from '../../shared/utils';
import { PageHeaderMui } from 'components';
import EnhancedTable from 'components/mui/enhanced-table/enhanced-table.component';
import { IconButton, Tooltip, Box, Stack, Typography } from '@mui/material';
import { DeleteIcon, DomainIcon, ForwardToInboxIcon } from 'components/mui';
import { NewButton } from 'components/mui/button/new.component';
import { history } from '../../root.component';
import { VerifyDomainModal } from './verify-domain-modal.component';
import { ModalContext } from 'context/modal-context';
import { AddDomainModal } from './add-domain-modal.component';
export function Domains() {
  const {
    asCompany,
    hasPermission
  } = useContext(UserStateContext);
  const {
    show
  } = useContext(ModalContext);

  // Domain State
  const [domainPagination, setDomainPagination] = useState(0);
  const [verifyModal, setVerifyModal] = useState(false);
  const [verifyModalDomain, setVerifyModalDomain] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalDomain, setDeleteModalDomain] = useState();
  const [deleteConfirm, setDeleteConfirm] = useState("");

  // Address State
  const [addressPagination, setAddressPagination] = useState(0);
  const [createAddressModal, setCreateAddressModal] = useState(false);
  const [createAddressModalAddress, setCreateAddressModalAddress] = useState('');
  const [createAddressModalErrors, setCreateAddressModalErrors] = useState({});
  const [domainCount, setDomainCount] = useState(0);
  const [deleteAddressModal, setDeleteAddressModal] = useState(false);
  const [deleteAddressModalAddress, setDeleteAddressModalAddress] = useState();
  const [renderIteration, setRenderIteration] = useState(0);
  const openCreate = () => {
    show(AddDomainModal).then(data => {
      setRenderIteration(r => r + 1);
      if (data.dns) {
        openVerify(data);
      }
    }).catch(() => {});
  };
  const openAddressCreate = () => {
    setCreateAddressModal(true);
    setCreateAddressModalAddress('');
    setCreateAddressModalErrors({});
  };
  const preCreateAddress = address => {
    return createAddress({
      company: asCompany.id,
      address
    }).then(({
      data
    }) => {
      setRenderIteration(r => r + 1);
      setCreateAddressModal(false);
    }).catch(e => {
      setCreateAddressModalErrors(e.response.data);
      throw e;
    });
  };
  const openVerify = domain => {
    setVerifyModal(true);
    setVerifyModalDomain(domain);
  };
  const checkDomain = domain => {
    return verifyDomain(domain.id).then(({
      data
    }) => {
      if (data.status === 'pending') {
        setVerifyModalDomain({
          ...data
        });
        toasterService.error('Unable to verify DNS settings. Wait a few minutes and try again.');
        throw false;
      } else {
        setRenderIteration(r => r + 1);
      }
    });
  };
  const preResendVerificationEmail = address => {
    resendVerificationEmail(address.id).then(() => {
      toasterService.success('Verification email resent.');
    }).catch(() => {
      toasterService.error('Unable to resend verification email. Please try again.');
    });
  };
  const openAddressDelete = address => {
    setDeleteAddressModal(true);
    setDeleteAddressModalAddress(address);
  };
  const openDelete = domain => {
    setDeleteModal(true);
    setDeleteModalDomain(domain);
  };
  const removeDomain = domain => {
    return deleteDomain(domain.id).then(() => {
      setRenderIteration(r => r + 1);
      setDeleteModal(false);
    }).catch(e => {
      toasterService.error('Unable to remove domain. Please try again.');
      throw e;
    });
  };
  const preDeleteAddress = address => {
    return deleteAddress(address.id).then(() => {
      setRenderIteration(r => r + 1);
      setDeleteAddressModal(false);
    }).catch(e => {
      toasterService.error('Unable to remove address. Please try again.');
      throw e;
    });
  };
  const domainHeadCells = [{
    key: 'domain',
    label: 'Domain',
    width: '50%',
    displayComponent: ({
      data: domain
    }) => <>
        {domain?.domain || ''}{domain.parent_company && <sm>{' '}<em>inherited from {domain.parent_company}</em></sm>}
      </>
  }, {
    key: 'status',
    label: 'Status',
    width: '50%',
    displayComponent: ({
      data: domain
    }) => {
      const status = domain.parent_status || domain.status;
      return <>
          {' '}
          {status === 'pending' && <>
              <Icon name="fa-regular-ellipsis-h" className="mr-2" size={14} /> Pending
            </>}
          {status === 'verified' && <>
              <Icon name="fa-solid-check-circle" className="mr-2 text-success" size={14} /> Verified
            </>}
        </>;
    }
  }, {
    key: 'tools',
    noSort: true,
    displayComponent: ({
      data: domain,
      onHover
    }) => {
      if (domain.id === onHover.row) {
        return <Box sx={{
          position: 'absolute'
        }}>
              <Stack direction="row" spacing={1} sx={{
            position: 'absolute',
            right: 0,
            top: '-18px',
            justifyContent: 'flex-end'
          }}>
                {!domain.valid && domain.sendgrid_ref && hasPermission('email.change_domain') && <Tooltip title="Verify Domain">
                    <IconButton onClick={e => {
                e.stopPropagation();
                openVerify(domain);
              }}>
                      <DomainIcon />
                    </IconButton>
                  </Tooltip>}
                {hasPermission('email.delete_domain') && <Tooltip title=" Remove Domain">
                    <IconButton onClick={e => {
                e.stopPropagation();
                openDelete(domain);
              }}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>}
              </Stack>
            </Box>;
      }
      return <></>;
    }
  }];
  const addressHeadCells = [{
    key: 'address',
    label: 'Address',
    width: '50%',
    displayComponent: ({
      data: address
    }) => address.address || ''
  }, {
    key: 'status',
    label: 'Status',
    width: '50%',
    displayComponent: ({
      data: address
    }) => <>
          {address.status === 'pending' && <>
              <Icon name="fa-regular-ellipsis-h" className="mr-2" size={14} /> Pending
            </>}
          {address.status === 'verified' && <>
              <Icon name="fa-solid-check-circle" className="mr-2 text-success" size={14} /> Verified
            </>}
          {address.status === 'rejected' && <>
              <Icon name="fa-solid-exclamation-circle" className="mr-2 text-danger" size={14} /> Rejected
            </>}
        </>
  }, {
    key: 'tools',
    noSort: true,
    displayComponent: ({
      data: address,
      onHover
    }) => {
      if (address.id === onHover.row) {
        return <Box sx={{
          position: 'absolute'
        }}>
              <Stack direction="row" spacing={1} sx={{
            position: 'absolute',
            right: 0,
            top: '-18px',
            justifyContent: 'flex-end'
          }}>
                {hasPermission('email.change_address') && <Tooltip title=" Resend Verification Email">
                    <IconButton onClick={e => {
                e.stopPropagation();
                preResendVerificationEmail(address);
              }}>
                      <ForwardToInboxIcon />
                    </IconButton>
                  </Tooltip>}
                {hasPermission('email.delete_address') && <Tooltip title="Remove Address">
                    <IconButton onClick={e => {
                e.stopPropagation();
                openAddressDelete(address);
              }}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>}
              </Stack>
            </Box>;
      }
      return <></>;
    }
  }];
  useEffect(() => {
    if (history?.location?.state?.openNew) {
      openCreate();
    }
  }, []);
  return <Scoped css={styles}>
      <div className="wrapper">
        <PageHeaderMui type={`Domains ${domainPagination ? `(${utils.commaize(domainPagination)})` : ''}`} icon={<Icon name="custom-dns" size={34} />} />

        <div className="mui-wrapper">
          <EnhancedTable type="Domains" headCells={domainHeadCells} api={getDomains} defaultSort={'-updated_when'} toolbarVisible={!(domainCount > 0)} exportData={data => {
          setDomainCount(data.length);
        }} renderIteration={renderIteration} searchCount={count => setDomainPagination(count)} actions={hasPermission('email.add_domain') && <NewButton onClick={e => openCreate()} />} />
        </div>
        <Box sx={{
        pl: 3
      }}>
          <Typography variant="tableHeader">{`Addresses ${addressPagination ? `(${utils.commaize(addressPagination)})` : ''}`}</Typography>
        </Box>
        <div className="mui-wrapper">
          <EnhancedTable type="Addresses" headCells={addressHeadCells} api={getAddresses} defaultSort={'-updated_when'} renderIteration={renderIteration} searchCount={count => setAddressPagination(count)} actions={hasPermission('email.add_address') && <NewButton onClick={e => openAddressCreate()} />} />
        </div>

        <AsyncModalDialog open={createAddressModal} title="Add New Address" submitText="Add" onSubmit={() => preCreateAddress(createAddressModalAddress)} onClose={() => setCreateAddressModal(false)} allowBackdropClick>
          <p>Enter the email address you would like to verify.</p>
          <div className={classnames('form-group', {
          'is-invalid': createAddressModalErrors.address
        })}>
            <label>Email Address</label>
            <input className="form-control" name="create-modal-domain" onChange={e => setCreateAddressModalAddress(e.target.value)} value={createAddressModalAddress} autoFocus />
            {createAddressModalErrors.domain && createAddressModalErrors.domain.map((e, i) => <div className="invalid-feedback text-danger" key={i}>
                  {e}
                </div>)}
          </div>
        </AsyncModalDialog>


        <AsyncModalDialog open={!!deleteModal} title="Remove Domain" submitText="Remove" actionType="danger" onSubmit={() => removeDomain(deleteModalDomain)} onClose={() => setDeleteModal(false)} submitDisabled={deleteConfirm.toUpperCase() !== 'DELETE'} allowBackdropClick>
          <p>Are you sure you wish to remove this domain?</p>

          {!!deleteModalDomain?.children_companies?.length && <p>This domain is also being used by the following companies: {deleteModalDomain.children_companies.join(', ')}</p>}

          <p>Enter the word DELETE to confirm permanent deletion:</p>
          <input className="form-control" name="confirm-delete" onChange={e => setDeleteConfirm(e.target.value)} value={deleteConfirm} autoFocus />
        </AsyncModalDialog>

        <AsyncModalDialog open={!!deleteAddressModal} title="Remove Address" submitText="Remove" actionType="danger" onSubmit={() => preDeleteAddress(deleteAddressModalAddress)} onClose={() => setDeleteAddressModal(false)} allowBackdropClick>
          <p>Are you sure you wish to remove this address?</p>
        </AsyncModalDialog>

        <VerifyDomainModal verifyModal={verifyModal} verifyModalDomain={verifyModalDomain} onSubmit={() => checkDomain(verifyModalDomain)} onClose={() => setVerifyModal(false)} />
      </div>
    </Scoped>;
}