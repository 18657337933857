import React from 'react';
function Icon(props) {
  if (props.size == 'lg') {
    return <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="#e8eaed" viewBox="0 -960 960 960">
        <path fill={props.fill || '#1D252D'} d="M296.51-288.51h50.26v-192.57h-50.26v192.57zm316.72 0h50.26v-399.75h-50.26v399.75zm-158.36 0h50.26v-109.18h-50.26v109.18zm0-192.57h50.26v-70.25h-50.26v70.25zM202.57-140q-25.79 0-44.18-18.39T140-202.57v-554.86q0-25.79 18.39-44.18T202.57-820h554.86q25.79 0 44.18 18.39T820-757.43v554.86q0 25.79-18.39 44.18T757.43-140H202.57z"></path>
      </svg>;
  }
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#e8eaed" viewBox="0 -960 960 960">
      <path fill={props.fill || '#1D252D'} d="M294.62-290h59.99v-190h-59.99v190zm310.77 0h59.99v-390h-59.99v390zM450-290h60v-110h-60v110zm0-190h60v-80h-60v80zM212.31-140Q182-140 161-161q-21-21-21-51.31v-535.38Q140-778 161-799q21-21 51.31-21h535.38Q778-820 799-799q21 21 21 51.31v535.38Q820-182 799-161q-21 21-51.31 21H212.31z"></path>
    </svg>;
}
export default Icon;