import { Container, Graphics, Sprite, TextStyle, Text } from 'pixi.js';
import smallArrowRightImg from '../assets/small-arrow-right.png';
const radius = 10;
const size = 20;
export class NodeNumberDual {
  constructor(textIn, textOut, color, hasLinks) {
    this.color = color;
    this.hasLinks = hasLinks;
    this.container = new Container();
    this.build(textIn, textOut);
  }
  update = (textIn, textOut) => {
    this.container.removeChild(this.textIn);
    this.container.removeChild(this.textOut);
    this.container.removeChild(this.shape);
    this.container.removeChild(this.icon);
    this.build(textIn, textOut);
  };
  build = (textIn, textOut) => {
    this.textIn = this.buildText(textIn);
    this.textIn.y = 3;
    this.textIn.x = 5;
    const iconWidth = this.textIn.width + 8;
    this.icon = new Sprite.from(smallArrowRightImg);
    this.icon.alpha = 0.7;
    this.icon.scale.x = 0.5;
    this.icon.scale.y = 0.5;
    this.icon.x = iconWidth;
    this.icon.y = 6;
    const textOutWidth = iconWidth + 10;
    this.textOut = this.buildText(textOut);
    this.textOut.y = 3;
    this.textOut.x = textOutWidth;
    if (this.hasLinks) {
      this.shape = this.buildSquare(textOutWidth + this.textOut.width + 6);
      this.container.addChild(this.shape);
      this.container.addChild(this.textIn);
      this.container.addChild(this.icon);
      this.container.addChild(this.textOut);
    } else {
      this.shape = this.buildSquare(textOutWidth);
      this.container.addChild(this.shape);
      this.container.addChild(this.textIn);
      this.shape.width = textOutWidth;
      this.textIn.x = 9;
    }
  };
  buildSquare = width => {
    const shape = new Graphics();
    shape.beginFill('0xffffff');
    shape.lineStyle(1, `0x${this.color}`);
    shape.moveTo(0, radius);
    shape.lineTo(0, size - radius);
    shape.arcTo(0, size, radius, size, radius);
    shape.lineTo(size - radius, size);
    shape.arcTo(width, size, width, size - radius, radius);
    shape.lineTo(width, radius);
    shape.arcTo(width, 0, size - radius, 0, radius);
    shape.lineTo(radius, 0);
    shape.arcTo(0, 0, 0, radius, radius);
    shape.endFill();
    return shape;
  };
  buildText = number => {
    const style = new TextStyle({
      wordWrap: true,
      align: 'center',
      fontSize: 12,
      fill: `0x${this.color}`
    });
    return new Text(number, style);
  };
}