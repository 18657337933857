import React from 'react';
function Icon(props) {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <g>
        <mask id="mask0_1106_3186" style={{
        maskType: 'alpha'
      }} width="24" height="24" x="0" y="0" maskUnits="userSpaceOnUse">
          <path fill="#1D252D" d="M0 0H24V24H0z"></path>
        </mask>
        <g mask="url(#mask0_1106_3186)">
          <path fill="#1D252D" d="M5.308 21.5c-.505 0-.933-.175-1.283-.525a1.745 1.745 0 01-.525-1.283V6.308c0-.505.175-.933.525-1.283.35-.35.778-.525 1.283-.525h1.384V3.154c0-.22.074-.402.22-.55a.745.745 0 01.55-.22c.219 0 .402.074.549.22.146.148.22.33.22.55V4.5h7.577V3.135c0-.213.071-.391.215-.535a.726.726 0 01.535-.215c.212 0 .39.071.534.215a.726.726 0 01.216.535V4.5h1.384c.505 0 .933.175 1.283.525.35.35.525.778.525 1.283v13.384c0 .505-.175.933-.525 1.283-.35.35-.778.525-1.283.525H5.308zm0-1.5h13.384a.294.294 0 00.212-.096.294.294 0 00.096-.212v-9.384H5v9.384c0 .077.032.148.096.212a.294.294 0 00.212.096zM5 8.808h14v-2.5a.294.294 0 00-.096-.212.294.294 0 00-.212-.096H5.308a.294.294 0 00-.212.096.294.294 0 00-.096.212v2.5z"></path>
          <g fill={props.fill || '#1D252D'}>
            <path d="M7.465 13.535A.726.726 0 008 13.75h8a.726.726 0 00.535-.215.726.726 0 00.215-.535.726.726 0 00-.215-.535.726.726 0 00-.535-.215H8a.726.726 0 00-.535.215.726.726 0 00-.215.535c0 .213.072.391.215.535zM7.465 17.535A.726.726 0 008 17.75h5a.726.726 0 00.535-.215.726.726 0 00.215-.535.726.726 0 00-.215-.535.726.726 0 00-.535-.215H8a.726.726 0 00-.535.215.726.726 0 00-.215.535c0 .213.072.391.215.535z"></path>
          </g>
        </g>
      </g>
    </svg>;
}
export default Icon;