import React, { useState, useContext } from 'react';
import { ModalDialog, Button } from 'components';
import { AddFieldModal } from './add-field-modal.component';
import { UserStateContext } from 'context/user-state-context';
export const AddMissingFieldModal = props => {
  const {
    missingFields = [],
    model = {},
    addField
  } = props;
  const [showAddCustomField, setShowAddCustomField] = useState(false);
  const [search, setSearch] = useState('');
  const reg = new RegExp(search, 'i');
  const {
    companyFieldsChange
  } = useContext(UserStateContext);
  return <>
      <ModalDialog title={`Add Fields to ${model.name}`} open={props.open} onClose={props.onClose} allowBackdropClick size="xl" cancelText="Done" type="scroll">
        <div className="d-flex justify-content-flex-end mb-3">
          <input type="text" className="form-control mr-3" onChange={e => setSearch(e.target.value)} value={search} style={{
          width: '30%'
        }} placeholder="Search Fields" />
          <Button actionType="primary" onClick={() => setShowAddCustomField(true)}>
            Add New Field
          </Button>
        </div>
        {missingFields.length === 0 && <p>No more fields left to add.</p>}
        {missingFields.sort((a, b) => a.name.localeCompare(b.name)).filter(f => search === '' || f.id.match(reg) || f.name.match(reg)).map(field => <div style={{
        display: 'flex',
        justifyContent: 'space-between'
      }} key={field.id}>
              <div style={{
          width: '250px'
        }}>{field.name}</div>
              <div style={{
          width: '250px'
        }}>{field.id}</div>
              <div>
                <Button onClick={() => addField(model.id, field)}>Add</Button>
              </div>
            </div>)}
      </ModalDialog>
      <AddFieldModal open={showAddCustomField} title={`Add Custom Field to ${model.name}`} onClose={() => {
      companyFieldsChange();
      setShowAddCustomField(false);
    }} addField={addField} modelID={model.id} />
    </>;
};