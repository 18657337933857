import React, { useState } from 'react';
import { AsyncModalDialog } from 'components';
import { createCouponBatch } from 'shared/common.api';
export const CouponCreateBatchModal = props => {
  const [message, setMessage] = useState();
  const [data, setData] = useState({
    batch_size: 100,
    character_set: 'upper_safe',
    code_length: 12
  });
  const onSubmit = () => {
    const batchSize = parseInt(data.batch_size, 10);
    const codeLength = parseInt(data.code_length, 10);
    if (isNaN(batchSize)) {
      setMessage('Batch size must be a number');
      return Promise.reject();
    }
    if (batchSize <= 0) {
      setMessage('Batch size must be greater than 0');
      return Promise.reject();
    }
    if (isNaN(codeLength)) {
      setMessage('Code length must be a number');
      return Promise.reject();
    }
    if (codeLength <= 0) {
      setMessage('Code length must be greater than 0');
      return Promise.reject();
    }
    setMessage();
    return createCouponBatch(props.coupon.id, {
      batch_size: batchSize,
      character_set: data.character_set,
      code_length: codeLength
    }).then(() => {
      if (props.onSubmit) {
        props.onSubmit();
      }
    });
  };
  return <AsyncModalDialog title="Generate Batch" onClose={props.onClose} onSubmit={onSubmit} open={props.open} submitText="Generate" allowBackdropClick submitDisabled={!data.batch_size || !data.character_set || !data.code_length}>
      {message && <p className="text-danger">{message}</p>}
      <div className="form-group">
        <label>
          Number of Coupons <span className="form-error">*</span>
        </label>
        <input id="batch-size" className="form-control" name="num" onChange={e => setData({
        ...data,
        batch_size: e.target.value
      })} value={data.batch_size} type="number" min="1" max="100000" />
      </div>
      <div className="form-group">
        <label>
          Character Set <span className="form-error">*</span>
        </label>
        <select id="batch-character-set" className="form-control" name="discount_type" onChange={e => setData({
        ...data,
        character_set: e.target.value
      })} value={data.character_set}>
          <option value="lower_alpha">Lowercase Alphabet</option>
          <option value="upper_alpha">Uppercase Alphabet</option>
          <option value="mixed_alpha">Mixed Case Alphabet</option>
          <option value="lower_alphanumeric">Lowercase Alphanumeric</option>
          <option value="upper_alphanumeric">Uppercase Alphanumeric</option>
          <option value="mixed_alphanumeric">Mixed Case Alphanumeric</option>
          <option value="lower_safe">Lowercase Safe Characters</option>
          <option value="upper_safe">Uppercase Safe Characters</option>
          <option value="mixed_safe">Mixed Case Safe Characters</option>
        </select>
      </div>
      <div className="form-group">
        <label>
          Code Length <span className="form-error">*</span>
        </label>
        <input id="batch-code-length" className="form-control" name="code_length" onChange={e => setData({
        ...data,
        code_length: e.target.value
      })} value={data.code_length} type="number" min="6" max="16" />
      </div>
    </AsyncModalDialog>;
};