import React, { useState, useRef, useContext, useEffect } from 'react';
import { getJourneyTestRun, getNodeTemplates } from '../../shared/common.api';
import { Loader } from '../../components/loader/loader.component';
import { Icon, PageHeaderMui } from 'components';
import { Scoped, k } from 'kremling';
import { Stack, Paper, IconButton, Typography, Checkbox } from '@mui/material';
import { RemoveIcon, AddIcon } from 'components/mui';
import { NodeModal, DynamicModalPublished } from './node/node-modal';
import { UserStateContext } from 'context/user-state-context';
import { JourneyApp } from './journey-app';
import { ModalContext } from 'context/modal-context';
import { MODES } from './node/node-utils';
import { allNodes } from './node/node-list';
import { mapNode } from './node/node-utils';
import { TestRunAssetModal } from './test-run-asset-modal.component';
export const TestRunComponent = ({
  match
}) => {
  // App state items
  const canvasRef = useRef(null);
  const appRef = useRef(null);
  const {
    asCompany,
    companyFields
  } = useContext(UserStateContext);
  const {
    show
  } = useContext(ModalContext);
  const [showGrid, setShowGrid] = useState(() => localStorage.getItem('journeyShowGrid') === 'true');
  const [zoom, setZoom] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [testRun, setTestRun] = useState(null);
  const [nodes, setNodes] = useState(allNodes);

  // Sync zoom level
  useEffect(() => {
    if (!appRef.current) return;
    appRef.current.setZoom(zoom);
  }, [zoom]);

  // Sync show grid to app and storage
  useEffect(() => {
    if (!appRef.current) return;
    appRef.current.setShowGrid(showGrid);
    localStorage.setItem('journeyShowGrid', `${showGrid}`);
  }, [showGrid]);
  useEffect(() => {
    getJourneyTestRun(match?.params?.id).then(({
      data
    }) => {
      setTestRun(data);
      setIsLoading(false);
    });
  }, [match?.params?.id]);
  useEffect(() => {
    if (!asCompany?.id) {
      return;
    }
    Node.asCompany = asCompany;
    Node.companyFields = companyFields;

    // Get nodes from API
    getNodeTemplates({
      company: asCompany.id,
      limit: 1000
    }).then(({
      data
    }) => {
      // Map the database nodes to ones the journey builder can understand.
      const newNodes = [...allNodes, ...data.results.map(mapNode)];
      newNodes.sort((a, b) => (a.order || 100000) - (b.order || 100000));
      setNodes(newNodes);
    });
  }, [asCompany?.id]);

  // Initialize app and fetch all journey nodes
  useEffect(() => {
    if (isLoading) return;
    appRef.current = new JourneyApp(canvasRef.current, false, on);
    appRef.current.setShowGrid(showGrid);
    appRef.current.setZoom(zoom);
    appRef.current.setNodeLibrary(nodes);
    appRef.current.setRevision(testRun.revision);
    appRef.current.setAnalytics(testRun.stats);
    return () => appRef.current.destroy();
  }, [isLoading, testRun]);
  useEffect(() => {
    if (appRef.current) {
      appRef.current.setNodeLibrary(nodes);
    }
  }, [nodes]);
  const on = (event, obj) => {
    // Events from the app.
    if (event === 'OPEN_CUSTOMER_LIST_SQL') {
      const assets = testRun.assets[obj.id];
      console.log('assets', testRun.assets);
      if (assets) {
        show(TestRunAssetModal, {
          assets,
          testRunID: match?.params?.id
        }).catch(() => {});
      }
    } else if (event === 'EDIT_PARAM') {
      if (!!obj && !obj.componentParams) {
        /**
         * Old Node Modal
         * This is used for nodes that are not using the new `componentParams` and are still using the old `component`.
         * Also this is used for editing node links... Node links should probably be moved to its own component
         */
        appRef.current.setMode(MODES.EDIT_PARAM);
        const {
          left: clientX,
          top: clientY
        } = canvasRef.current.getBoundingClientRect();
        show(NodeModal, {
          readOnly: true,
          node: obj,
          linkEdit: null,
          stageX: appRef.current.movableContainer.x,
          stageY: appRef.current.movableContainer.y,
          clientX,
          clientY,
          scale: zoom
        }).catch(e => console.error(e)).then(() => appRef.current.setMode(MODES.PAN));
      } else if (!!obj && !!obj.componentParams) {
        /**
         * New Dynamic Node Modal
         * This modal automatically generates the modal based on the configuration.
         * This is the published version
         */
        appRef.current.setMode(MODES.EDIT_PARAM);
        const {
          left: clientX,
          top: clientY
        } = canvasRef.current.getBoundingClientRect();
        show(DynamicModalPublished, {
          node: obj,
          stageX: appRef.current.movableContainer.x,
          stageY: appRef.current.movableContainer.y,
          clientX,
          clientY,
          scale: zoom
        }).catch(e => console.error(e)).then(() => appRef.current.setMode(MODES.PAN));
      }
    }
  };
  return <>
      <Scoped css={outerCss}>
        <div className="wrapper-contain">
          {isLoading && <Loader overlay />}
          {!isLoading && <>
              <PageHeaderMui type="Journey Test Run" icon={<Icon name="custom-account_tree" size={34} />} />

              <div className="canvas-wrapper">
                <Scoped css={css}>
                  <Stack direction="row" className="inner-wrapper">
                    <div ref={canvasRef} className="journey-canvas"></div>

                    <div className="journey-bottom-right">
                      <Paper>
                        <Stack direction="row" spacing="30px">
                          <Stack sx={{
                        flexWrap: 'wrap',
                        display: 'flex',
                        alignItems: 'center'
                      }} direction="row" spacing="10px">
                            <Checkbox checked={showGrid} value={showGrid} onChange={() => setShowGrid(!showGrid)} className="show-grid" />

                            <Typography>Show Grid</Typography>
                          </Stack>
                          <Stack sx={{
                        flexWrap: 'wrap',
                        display: 'flex',
                        alignItems: 'center'
                      }} direction="row" spacing="10px">
                            <IconButton onClick={() => setZoom(zoom > 0.6 ? Math.round((zoom - 0.1) * 10) / 10 : 0.5)} disabled={zoom === 0.5}>
                              <RemoveIcon />
                            </IconButton>
                            <Typography> {zoom * 100}%</Typography>
                            <IconButton onClick={() => setZoom(zoom < 1 ? Math.round((zoom + 0.1) * 10) / 10 : 1)} disabled={zoom === 1}>
                              <AddIcon />
                            </IconButton>
                          </Stack>
                        </Stack>
                      </Paper>
                    </div>
                  </Stack>

                  {/*
                   <Popper
                    anchorEl={analyticsEl}
                    open={!!analyticsEl}
                    sx={{ zIndex: 200 }}
                    onClose={() => setAnalyticsEl(null)}
                    placement="left-end"
                   >
                    <Paper
                      elevation={0}
                      sx={{
                        borderRadius: '16px',
                         filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))',
                      }}
                    >
                      <Box sx={{ p: '12px' }}>
                        <Typography variant="tableHeader">Filter Statistics</Typography>
                      </Box>
                      {FILTER_TYPES.map((filter) => (
                        <Stack
                          direction="row"
                          sx={{ cursor: 'pointer', p: '12px', display: 'flex', alignItems: 'center' }}
                          key={filter.key}
                          spacing="12px"
                          onClick={(e) => {
                            setAnalyticsFilter({ type: filter.key });
                            setAnalyticsEl(null);
                          }}
                        >
                          <Box> {filter.icon}</Box>
                          <Typography> {filter.name}</Typography>
                        </Stack>
                      ))}
                      <Box sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }} className="d-flex column space-between">
                        <Stack
                          direction="row"
                          spacing="12px"
                          sx={{ p: '12px', display: 'flex', alignItems: 'center' }}
                          onClick={(e) => setAnalyticsDateEl(e.target)}
                        >
                          <CalendarDateRangeIcon />
                          <Typography>Date Range </Typography>
                        </Stack>
                        <RightCaretIcon />
                      </Box>
                    </Paper>
                   </Popper>
                   <Popper
                    anchorEl={analyticsDateEl}
                    sx={{ zIndex: 200 }}
                    open={!!analyticsDateEl}
                    onClose={() => setAnalyticsDateEl(null)}
                    placement="left"
                   >
                    <Paper
                      elevation={0}
                      sx={{
                        borderRadius: '16px',
                        filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))',
                      }}
                    >
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        localeText={{
                          after: 'after date',
                          before: 'before date',
                        }}
                        sx={{
                          padding: '10px',
                        }}
                      >
                        <DateRangeCalendar
                          value={analyticsCalendarCache}
                          onChange={(newValue) => setAnalyticsCalendarCache(newValue)}
                        />
                      </LocalizationProvider>
                      <Stack
                        sx={{ pb: '12px', cursor: 'pointer', display: 'flex', alignItems: 'center', flexDirection: 'row-reverse' }}
                        direction="row"
                      >
                        <ButtonMui
                          onClick={() => {
                            setAnalyticsDateEl(null);
                            setAnalyticsEl(null);
                            setAnalyticsFilter({
                              type: 'custom',
                              startDate: analyticsCalendarCache[0],
                              endDate: analyticsCalendarCache[1],
                            });
                          }}
                        >
                          {' '}
                          <Typography sx={{ textTransform: 'none' }}> Ok</Typography>
                        </ButtonMui>
                        <ButtonMui
                          onClick={() => setAnalyticsDateEl(null)}
                        >
                          {' '}
                          <Typography sx={{ textTransform: 'none' }}> Cancel </Typography>
                        </ButtonMui>
                      </Stack>
                    </Paper>
                   </Popper>
                   <CustomersListModal nodeId={customersListNodeId} onClose={() => setCustomersListNodeId(null)} />
                   */}
                </Scoped>
              </div>
            </>}
        </div>
      </Scoped>
    </>;
};
const outerCss = {
  styles: `[kremling="i10"] body,body[kremling="i10"] {
  --color-primary: #487aae;
  --color-highlight: #e7efff;
  --color-accent: #354052;
  --color-success: #73b56e;
  --color-warning: #f4b707;
  --color-danger: #df5651;
  --color-grey-10: #f9f9f9;
  --color-grey-25: #f3f3f3;
  --color-grey-50: #e9e9e9;
  --color-grey-75: #e3e3e3;
  --color-grey-100: #d3d3d3;
  --color-grey-200: #c3c3c3;
  --color-grey-300: #b3b3b3;
  --color-grey-400: #a3a3a3;
  --color-grey-500: #808080;
  --color-grey-600: #707070;
  --color-grey-700: #606060;
  --color-grey-800: #505050;
  --color-grey-900: #404040;
  --base-font-family: Roboto, Helvetica, Arial, sans-serif;
  --base-font-size: 1.4rem;
  --base-font-color: #404040;
  --base-font-weight: 400;
  --base-line-height: 1.4;
  --box-shadow-1: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.12);
  --box-shadow-2: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-3: 0 10px 20px rgba(0, 0, 0, 0.17), 0 6px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-4: 0 14px 28px rgba(0, 0, 0, 0.13), 0 10px 10px rgba(0, 0, 0, 0.1);
  --box-shadow-5: 0 19px 38px rgba(0, 0, 0, 0.18), 0 15px 12px rgba(0, 0, 0, 0.1);
  --base-border-radius: 0.4rem;
}

[kremling="i10"] .box-button,[kremling="i10"].box-button {
  background-color: #F3F6FC;
  cursor: pointer;
  text-transform: none;
  height: 32px;
  border-radius: 16px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
}

[kremling="i10"] .box-button path,[kremling="i10"].box-button path {
  fill: #1D252D;
}

[kremling="i10"] .box-button p,[kremling="i10"].box-button p {
  color: #1D252D;
}

[kremling="i10"] .box-button:hover,[kremling="i10"].box-button:hover {
  background-color: #C2E7FF;
}

[kremling="i10"] .box-button p,[kremling="i10"].box-button p {
  color: #1D252D;
  padDing-left: 10px;
}

[kremling="i10"] .box-button.active,[kremling="i10"].box-button.active {
  background-color: #3898D9;
}

[kremling="i10"] .box-button.active path,[kremling="i10"].box-button.active path {
  fill: #ffffff;
}

[kremling="i10"] .box-button.active p,[kremling="i10"].box-button.active p {
  color: #ffffff;
}

[kremling="i10"] .canvas-wrapper,[kremling="i10"].canvas-wrapper {
  display: flex;
  height: calc(100vh - 105px);
  margin: 20px;
  background-color: #ffffff;
  overflow: hidden;
  border-radius: 14px;
}`,
  id: 'i10',
  namespace: 'kremling'
};
const css = {
  styles: `[kremling="i11"] body,body[kremling="i11"] {
  --color-primary: #487aae;
  --color-highlight: #e7efff;
  --color-accent: #354052;
  --color-success: #73b56e;
  --color-warning: #f4b707;
  --color-danger: #df5651;
  --color-grey-10: #f9f9f9;
  --color-grey-25: #f3f3f3;
  --color-grey-50: #e9e9e9;
  --color-grey-75: #e3e3e3;
  --color-grey-100: #d3d3d3;
  --color-grey-200: #c3c3c3;
  --color-grey-300: #b3b3b3;
  --color-grey-400: #a3a3a3;
  --color-grey-500: #808080;
  --color-grey-600: #707070;
  --color-grey-700: #606060;
  --color-grey-800: #505050;
  --color-grey-900: #404040;
  --base-font-family: Roboto, Helvetica, Arial, sans-serif;
  --base-font-size: 1.4rem;
  --base-font-color: #404040;
  --base-font-weight: 400;
  --base-line-height: 1.4;
  --box-shadow-1: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.12);
  --box-shadow-2: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-3: 0 10px 20px rgba(0, 0, 0, 0.17), 0 6px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-4: 0 14px 28px rgba(0, 0, 0, 0.13), 0 10px 10px rgba(0, 0, 0, 0.1);
  --box-shadow-5: 0 19px 38px rgba(0, 0, 0, 0.18), 0 15px 12px rgba(0, 0, 0, 0.1);
  --base-border-radius: 0.4rem;
}

[kremling="i11"] .inner-wrapper,[kremling="i11"].inner-wrapper {
  width: 100%;
  height: 100%;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  position: relative;
}

[kremling="i11"] button,button[kremling="i11"] {
  background-color: #fff;
}

[kremling="i11"] button:hover,button[kremling="i11"]:hover {
  background-color: #daefff;
}

[kremling="i11"] .journey-canvas,[kremling="i11"].journey-canvas {
  flex-grow: 1;
}

[kremling="i11"] .revision-picker,[kremling="i11"].revision-picker {
  position: absolute;
  top: 1rem;
  z-index: 10;
}

[kremling="i11"] .journey-bottom-right,[kremling="i11"].journey-bottom-right {
  display: flex;
  align-items: center;
  padding: 0 1rem 1rem 0;
  z-index: 1;
  position: absolute;
  bottom: 0;
  right: 0;
}
[kremling="i11"] .journey-bottom-right .show-grid,[kremling="i11"].journey-bottom-right .show-grid {
  color: #1d252d;
}
[kremling="i11"] .journey-bottom-right .show-grid.Mui-checked,[kremling="i11"].journey-bottom-right .show-grid.Mui-checked {
  color: #53a6d6;
}
[kremling="i11"] .journey-bottom-right .show-grid .MuiSvgIcon-root,[kremling="i11"].journey-bottom-right .show-grid .MuiSvgIcon-root {
  font-size: 22px;
}
[kremling="i11"] .journey-bottom-right div.control,[kremling="i11"].journey-bottom-right div.control {
  width: 2.2rem;
  height: 2.2rem;
}
[kremling="i11"] .journey-bottom-right div.count,[kremling="i11"].journey-bottom-right div.count {
  font-size: 1.2rem;
  color: #606060;
  font-weight: 700;
  height: 2.2rem;
  line-height: 2.2rem;
  padding: 0 0.5rem;
  margin: 0 0.5rem;
  width: 4rem;
  background-color: #fff;
  border-radius: 0.4rem;
  text-align: center;
}

[kremling="i11"] .journey-bottom-left,[kremling="i11"].journey-bottom-left {
  position: absolute;
  padding: 0 1rem 1rem 1rem;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  user-select: none;
}
[kremling="i11"] .journey-bottom-left>button,[kremling="i11"].journey-bottom-left>button {
  margin-right: 1rem;
  background: #daefff;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
}

[kremling="i11"] .journey-top-right,[kremling="i11"].journey-top-right {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1rem;
  z-index: 1;
  display: flex;
  align-items: center;
}
[kremling="i11"] .journey-top-right button,[kremling="i11"].journey-top-right button {
  border: 1px solid #D9D9D9;
}
[kremling="i11"] .journey-top-right button path,[kremling="i11"].journey-top-right button path {
  fill: rgb(83, 166, 214);
}
[kremling="i11"] .journey-top-right>button,[kremling="i11"] .journey-top-right .dropdown,[kremling="i11"].journey-top-right>button,[kremling="i11"].journey-top-right .dropdown {
  margin-left: 1rem;
}

[kremling="i11"] .journey-top-right__toggle,[kremling="i11"].journey-top-right__toggle {
  margin-right: 1rem;
  position: relative;
}
[kremling="i11"] .journey-top-right__toggle>button,[kremling="i11"].journey-top-right__toggle>button {
  margin-right: -0.1rem;
  border-radius: 0;
  z-index: 0;
}
[kremling="i11"] .journey-top-right__toggle>button:first-child,[kremling="i11"].journey-top-right__toggle>button:first-child {
  border-top-left-radius: 0.4rem;
  border-bottom-left-radius: 0.4rem;
}
[kremling="i11"] .journey-top-right__toggle>button:last-child,[kremling="i11"].journey-top-right__toggle>button:last-child {
  border-top-right-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
}`,
  id: 'i11',
  namespace: 'kremling'
};