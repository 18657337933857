import React, { Component } from 'react';
import { bool, func, oneOf } from 'prop-types';
import { history } from '../../root.component';
import { Modal } from '../../components/modal/modal.component';
import { Stack, Button, Menu, Typography } from '@mui/material';
export class DuplicateSegmentModal extends Component {
  static propTypes = {
    open: bool,
    onClose: func,
    onSubmit: func,
    publishedState: bool
  };
  state = {
    duplicateSegments: [],
    duplicateSegmentWarningOpen: false
  };
  closeModalHandler = (journey_id, reason) => {
    if (journey_id = null || journey_id === '') {
      this.setState({
        duplicateSegmentWarningOpen: false
      });
    } else {
      return reason;
    }
  };
  goToJourneyClicked = journey_id => {
    this.setState({
      duplicateSegmentWarningOpen: false
    });
    history.push(`/customer-journeys/published/${journey_id}`);
  };
  render() {
    const {
      open,
      onSubmit,
      onClose,
      duplicateSegments,
      publishedState
    } = this.props;
    return <Modal open={open} onClose={onClose} type="scroll" closeBtn={false}>
        <div className="modal__title">Duplicate Segment Warning</div>
        <div className="modal__body">
          <div className="mb-md">
            You have used the same segment from this journey in a different journey(s) or in another revision of this journey. You can view
            the pontentially conflicting journeys below. If you are updating a journey with a new revision, you will have the option to
            pause or stop it after this warning. If this was intentional, you can ignore this warning.
          </div>
          <div sx={{
          //handle the size and add a scrollbar to comb through the cells
          overflowY: 'auto',
          borderRadius: '14px',
          scrollbarWidth: 'thin',
          '&::-webkit-scrollbar': {
            width: '0.4em'
          },
          '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
            marginTop: '25px',
            marginBottom: '25px'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888'
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#555'
          }
        }}>
            <Stack>
              {duplicateSegments.map(segment => <Button sx={{
              width: '100%',
              textTransform: 'none',
              fontSize: '14px',
              fontWeight: '500',
              color: '#1D252D',
              padding: '10px 0'
            }} onClick={() => onClose(this.goToJourneyClicked(segment.journey_id))} key={segment.id}>
                  <Typography>
                    {segment?.journey_name} - Revision #{segment?.revision_number?.index}
                  </Typography>
                </Button>)}
            </Stack>
          </div>
        </div>
        <div className="modal__actions">
          {!publishedState && <Button onClick={() => onSubmit(this.closeModalHandler(null, 'close'))}>Cancel</Button>}
          <Button onClick={() => onSubmit(this.closeModalHandler(null, 'continue'))}>Acknowledge</Button>
        </div>
      </Modal>;
  }
}