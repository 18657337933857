import React from 'react';
function Icon() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none" viewBox="0 0 24 25">
      <mask id="mask0_65_4072" style={{
      maskType: 'alpha'
    }} width="24" height="25" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0 0.5H24V24.5H0z"></path>
      </mask>
      <g mask="url(#mask0_65_4072)">
        <path fill="#1D252D" d="M12 17.55a.831.831 0 01-.287-.05.923.923 0 01-.263-.15c-.633-.533-1.142-1.246-1.525-2.137A6.814 6.814 0 019.35 12.5c0-.933.188-1.833.563-2.7.375-.867.895-1.583 1.562-2.15.083-.067.167-.12.25-.162A.602.602 0 0112 7.425c.1 0 .196.021.288.063.091.041.179.095.262.162.65.55 1.163 1.258 1.538 2.125s.562 1.775.562 2.725a6.78 6.78 0 01-.575 2.725c-.383.883-.9 1.592-1.55 2.125a.993.993 0 01-.25.15.735.735 0 01-.275.05zM15.85 19a5.94 5.94 0 01-2.7-.625c.2-.15.4-.333.6-.55.2-.217.375-.425.525-.625.25.083.504.154.763.213.258.058.529.087.812.087 1.383 0 2.563-.488 3.538-1.463.975-.975 1.462-2.154 1.462-3.537 0-1.4-.487-2.583-1.462-3.55-.975-.967-2.155-1.45-3.538-1.45-.283 0-.554.025-.812.075-.259.05-.513.117-.763.2-.15-.2-.32-.408-.512-.625a3.067 3.067 0 00-.638-.55c.417-.2.85-.35 1.3-.45.45-.1.925-.15 1.425-.15 1.783 0 3.313.629 4.588 1.887 1.275 1.259 1.912 2.796 1.912 4.613 0 1.8-.637 3.333-1.912 4.6-1.275 1.267-2.805 1.9-4.588 1.9zm-7.7 0c-1.8 0-3.333-.629-4.6-1.887-1.267-1.259-1.9-2.796-1.9-4.613 0-1.817.633-3.354 1.9-4.613C4.817 6.629 6.35 6 8.15 6c.483 0 .95.054 1.4.162.45.109.883.263 1.3.463-.817.567-1.471 1.429-1.963 2.587-.491 1.159-.737 2.255-.737 3.288s.246 2.129.737 3.287c.492 1.159 1.146 2.021 1.963 2.588-.417.2-.85.354-1.3.462-.45.109-.917.163-1.4.163z"></path>
      </g>
    </svg>;
}
export default Icon;