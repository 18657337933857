import React, { useState, useEffect, useContext } from 'react';
import { UserStateContext } from 'context/user-state-context';
import { ModalContext } from 'context/modal-context';
import { debounce } from 'lodash';
import { createCredentials, updateCredential } from '../integrations.resource';
import { snackbarService, ModalDialog, DynamicTypeAhead } from 'components';
import { getLocations, getLocationRefs, editLocationRef } from 'shared/common.api';
import EnhancedTable from 'components/mui/enhanced-table/enhanced-table.component';
import { Box, Checkbox, Stack, Typography, Button, TextField } from '@mui/material';
import { AddIcon } from 'components/mui';
import { IntegrationCarfaxReviewsModal } from './integration-carfax-reviews-modal.component';
const save = debounce((company_id, credential_id, responder_name, setCredential) => {
  if (!credential_id) {
    createCredentials({
      company: company_id,
      integration: 'carfax_reviews',
      entity_data: {
        responder_name
      }
    }).then(setCredential);
  } else {
    updateCredential(credential_id, {
      entity_data: {
        responder_name
      }
    }).then(setCredential);
  }
}, 1000);
export const IntegrationCarfaxReviews = ({
  install
}) => {
  const {
    user,
    asCompany,
    hasPermission
  } = useContext(UserStateContext);
  const {
    confirm,
    show
  } = useContext(ModalContext);
  const [data, setData] = useState([]);
  const [renderIteration, setRenderIteration] = useState(0);
  const [saving, setSaving] = useState(false);
  const [credential, setCredential] = useState(install?.credentials ? install.credentials[0] : null);
  const [mapLocation, setMapLocation] = useState();
  const [responderName, setResponderName] = useState(credential?.entity_data?.responder_name || '');
  const headCells = [{
    key: 'ref',
    label: 'Carfax Location',
    noSort: true,
    displayComponent: ({
      data
    }) => <Stack>
          <Box>
            <strong>{data.name}</strong>
          </Box>
          <Box>
            {data.address1}, {data.city}, {data.state}, {data.zip_code}
          </Box>
        </Stack>
  }, {
    key: 'location',
    label: 'Matched Location',
    noSort: true,
    displayComponent: ({
      data
    }) => {
      // TODO on click allow them to edit it...
      if (!data.location) {
        return <i>No Match</i>;
      }
      return <Stack>
            <Box>
              <strong>{data.location_name}</strong>
            </Box>
            <Box>
              {data.location_address1}, {data.location_city}, {data.location_state}, {data.location_zip_code}
            </Box>
          </Stack>;
    }
  }, {
    key: 'is_active',
    label: 'Sync Reviews',
    noSort: true,
    displayComponent: ({
      data: row
    }) => <Checkbox color="primary" disabled={!hasPermission('integration.change_credential')} checked={row.is_active} onChange={() => {
      // Call API to update is_active
      let promise = Promise.resolve();
      if (!row.is_active && !row.location) {
        promise = confirm({
          title: 'Create New Location',
          message: `You are attempting to sync reviews for a location that is not mapped. Would you like to create a new location for this Carfax Business location?`,
          submitText: 'Confirm'
        });
      }
      promise.then(() => {
        setSaving(true);
        editLocationRef(row.id, {
          is_active: !row.is_active
        }).then(() => {
          snackbarService.popup({
            type: 'success',
            message: 'This change was saved'
          });
          // I was unable to figure out how to get the location information to update the UI without fetching from the API.
          // So we are updating via the API
          setRenderIteration(i => i + 1);
          setSaving(false);
        });
      }).catch(() => {});
    }} sx={{
      color: '#1D252D',
      '&.Mui-checked': {
        color: '#53A6D6'
      },
      '& .MuiSvgIcon-root': {
        fontSize: 22
      }
    }} />
  }];
  const handleRowClick = (data, column) => {
    if (!hasPermission('integration.change_credential')) {
      snackbarService.popup({
        type: 'error',
        message: 'You do not have permission to edit this credential'
      });
      return;
    }
    if (column == 'location' || column == 'ref') {
      setMapLocation(data);
    }
  };
  const addLocations = () => {
    show(IntegrationCarfaxReviewsModal).then(() => {
      setRenderIteration(i => i + 1);
    }).catch(() => {});
  };
  useEffect(() => {
    if (responderName && responderName !== '') {
      save(asCompany.id, credential?.id, responderName, setCredential);
    }
  }, [responderName]);
  return <>
    <Box>
      <Stack spacing="36px">
        <Typography variant="tableHeader">Review Locations</Typography>
        <Typography>
          Map your Carfax locations with your POS locations. Also enable and disable the locations you wish to sync reviews.
        </Typography>

        <Typography>
          Carfax allows users to specify the name of the responder to the review.  Enter the name you would like to use for all of our reviews here:
        </Typography>
        <TextField label="Review Responder Name" value={responderName} onChange={e => setResponderName(e.target.value)} />

        {user?.is_superuser && <Stack direction="row" spacing="16px">
            <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
              <Typography>
                If there are missing locations, click the add button to add more locations from Carfax.
              </Typography>
            </Box>
            <Button disabled={!hasPermission('integration.change_credential')} variant="contained" sx={{
            backgroundColor: '#F3F3F4',
            '&:hover': {
              backgroundColor: '#F3F3F4'
            }
          }} startIcon={<AddIcon fill="#3898D9" />} onClick={e => {
            addLocations();
          }}>
              <Box sx={{
              py: '5px',
              pr: '12px'
            }}>
                <Typography sx={{
                color: '#3898D9'
              }}>Add</Typography>
              </Box>
            </Button>
          </Stack>}

        <EnhancedTable renderIteration={renderIteration} type="Locations" headCells={headCells} api={args => getLocationRefs({
          ...args,
          entity_source: 'carfax_reviews'
        })} defaultSort="name" handleRowClick={handleRowClick} dataState={[data, setData]} />
      </Stack>
    </Box>

    <ModalDialog open={!!mapLocation} title="Assign Location" submitText="Assign" onSubmit={() => {
      setSaving(true);
      editLocationRef(mapLocation.id, {
        location: mapLocation.location
      }).then(() => {
        snackbarService.popup({
          type: 'success',
          message: 'Changes were saved'
        });
        setSaving(false);
      });
      setData(data => {
        // Update UI without fetching from API.
        const newData = JSON.parse(JSON.stringify(data));
        const newRow = newData.find(r => r.id === mapLocation.id);
        newRow.location = mapLocation.location;
        newRow.location_name = mapLocation.location_name;
        newRow.location_address1 = mapLocation.location_address1;
        newRow.location_address2 = mapLocation.location_address2;
        newRow.location_city = mapLocation.location_city;
        newRow.location_state = mapLocation.location_state;
        newRow.location_zip_code = mapLocation.location_zip_code;
        return newData;
      });
    }} onClose={() => setMapLocation()} allowBackdropClick level={2}>
      <p>Map the following Carfax Business:</p>
      <p>
        <strong>{mapLocation?.name}</strong>
        <br />
        {mapLocation?.address1}, {mapLocation?.city}, {mapLocation?.state}, {mapLocation?.zip_code}
      </p>

      <p>To the following Location:</p>
      <DynamicTypeAhead getItems={getLocations} getItemsFilters={{
        company: asCompany.id
      }} placeholder="Choose" displayProperty="name" displayComponent={data => <Stack style={{
        borderBottom: '1px solid #cccccc',
        paddingBottom: '5px'
      }}>
            <Box>
              <strong>{data?.name}</strong>
            </Box>
            <Box>
              {data?.address1}, {data?.city}, {data?.state}, {data?.zip_code}
            </Box>
          </Stack>} keyProperty="id" value={mapLocation?.location} onChange={val => {
        setMapLocation(mapLocation => ({
          ...mapLocation,
          location: val?.id || null,
          location_name: val?.name,
          location_address1: val?.address1,
          location_address2: val?.address2,
          location_city: val?.city,
          location_state: val?.state,
          location_zip_code: val?.zip_code
        }));
      }} />
    </ModalDialog>
  </>;
};