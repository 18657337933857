import React from 'react';
function Icon(props) {
  return <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="none" viewBox="0 0 21 21">
      <mask id="mask0_424_215" style={{
      maskType: 'alpha'
    }} width="21" height="21" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0.091 0.257H20.091V20.257H0.091z"></path>
      </mask>
      <g mask="url(#mask0_424_215)">
        <path fill={props.fill || '#6C9'} d="M10.097 14.84c-1.275 0-2.359-.444-3.251-1.332-.892-.888-1.338-1.97-1.338-3.245 0-1.275.444-2.359 1.332-3.251.888-.892 1.97-1.338 3.245-1.338 1.275 0 2.36.444 3.251 1.332.892.889 1.338 1.97 1.338 3.245 0 1.276-.444 2.36-1.332 3.251-.888.892-1.97 1.338-3.245 1.338z"></path>
      </g>
    </svg>;
}
export default Icon;