import React, { Component } from 'react';
import { func, object, shape, string, number } from 'prop-types';
import { getAdwordsAudiencesNoUnwrap, getAdwordsAudiences } from '../../../integrations/integrations.resource';
import { UserStateContext } from 'context/user-state-context';
import { DynamicTypeAhead } from '../../../../components/dynamic-type-ahead/dynamic-type-ahead.component';
export class ActionAdwordsAudience extends Component {
  static contextType = UserStateContext;
  static propTypes = {
    update: func,
    node: object,
    data: shape({
      audience_id: number,
      audience_name: string
    })
  };
  state = {
    selectedAudience: null,
    loading: true
  };
  componentDidMount() {
    const {
      audience_id
    } = this.props.data;
    if (audience_id) {
      getAdwordsAudiences({
        account__company: this.context.asCompany.id,
        entity_ref: audience_id,
        limit: 1
      }).then(({
        results
      }) => {
        if (results.length === 1) {
          this.setState({
            selectedAudience: results[0],
            loading: false
          });
        }
      });
    } else {
      this.setState({
        loading: false
      });
    }
  }
  updateAudience = selectedAudience => {
    this.setState({
      selectedAudience
    });
    this.props.update({
      audience_id: selectedAudience.entity_ref,
      audience_name: selectedAudience.name
    });
  };
  render() {
    const {
      selectedAudience,
      loading
    } = this.state;
    const {
      audience_id
    } = this.props.data;
    return <div>
        <div className="form-group">
          <label>Audience</label>
          {!loading && <DynamicTypeAhead getItems={getAdwordsAudiencesNoUnwrap} getItemsFilters={{
          account__company: this.context.asCompany.id
        }} placeholder={selectedAudience ? `${selectedAudience.name}` : 'Choose a Audience'} displayProperty="name" keyProperty="entity_ref" value={audience_id} onChange={audience => this.updateAudience(audience)} />}
        </div>
      </div>;
  }
}