import React from 'react';
import { Dropdown, Button, Icon } from 'components';
const STATUS_MAP = {
  subscribed: 'Subscribed',
  pending: 'Pending',
  suppressed: 'Suppressed',
  unknown: 'Unknown'
};
export const EmailOptStatuses = props => {
  const {
    param
  } = props;
  const optStatuses = props.data.optStatuses || [];
  const statuses = optStatuses.map(s => STATUS_MAP[s]);
  const toggleStatus = status => {
    const optStatuses = props.data.optStatuses ? [...props.data.optStatuses] : [];
    const index = optStatuses.indexOf(status);
    if (index === -1) {
      optStatuses.push(status);
    } else {
      optStatuses.splice(index, 1);
    }
    props.update({
      optStatuses
    });
  };
  return <div className="form-group">
      <label>{param.label}</label>
      <Dropdown size="block" contentHeight={250} trigger={() => <Button dropdown block>
            {statuses.length ? statuses.join(', ') : 'Choose Statuses'}
          </Button>} content={() => <ul className="select-list">
            {Object.keys(STATUS_MAP).map(key => <li key={key}>
                <a onClick={() => toggleStatus(key)}>
                  <div className="select-list__content">{STATUS_MAP[key]}</div>
                  {optStatuses && optStatuses.indexOf(key) > -1 && <Icon className="select-list__selected" size={14} name="fa-regular-check" />}
                </a>
              </li>)}
          </ul>} />
    </div>;
};