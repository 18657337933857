import React from 'react';
function Icon(props) {
  if (props.size == 'lg') {
    return <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="none" viewBox="0 0 40 40">
        <mask id="mask0_801_5388" style={{
        maskType: 'alpha'
      }} width="40" height="40" x="0" y="0" maskUnits="userSpaceOnUse">
          <path fill="#D9D9D9" d="M0 0H40V40H0z"></path>
        </mask>
        <g fill={props.fill || '#1D252D'} mask="url(#mask0_801_5388)">
          <path d="M33.292 22.303c-.53 2.725-1.854 4.967-3.974 6.727-2.12 1.76-4.596 2.64-7.428 2.64-1.53 0-2.994-.304-4.396-.91a11.96 11.96 0 01-3.74-2.55.518.518 0 01-.182-.433.7.7 0 01.223-.471.611.611 0 01.506-.213c.2.01.376.096.527.256a9.242 9.242 0 003.234 2.174c1.228.49 2.504.737 3.828.737 2.448 0 4.602-.768 6.463-2.303 1.861-1.535 3.044-3.485 3.549-5.85a.613.613 0 01.247-.41.743.743 0 01.461-.162c.224 0 .402.078.534.232a.63.63 0 01.148.536zm.064-4.634a.68.68 0 01-.139.558.64.64 0 01-.524.231.77.77 0 01-.76-.594c-.599-2.347-1.816-4.287-3.65-5.822-1.835-1.534-3.966-2.302-6.393-2.302-2.859 0-5.283.995-7.272 2.984-1.99 1.99-2.984 4.414-2.984 7.273v1.27l2.374-2.374a.696.696 0 01.502-.225c.184 0 .352.075.502.225.154.155.232.324.232.509a.687.687 0 01-.232.502l-3.229 3.235a1.235 1.235 0 01-.394.275c-.136.057-.29.085-.46.085a1.21 1.21 0 01-.45-.085 1.198 1.198 0 01-.405-.275l-3.235-3.235a.73.73 0 01-.204-.502c0-.185.068-.354.204-.509a.728.728 0 01.519-.225.64.64 0 01.492.225l2.373 2.374v-1.27c0-3.252 1.136-6.01 3.409-8.273 2.272-2.262 5.025-3.394 8.258-3.394 2.809 0 5.271.88 7.389 2.642 2.117 1.762 3.476 3.994 4.077 6.697z"></path>
          <path stroke={props.fill || '#1D252D'} strokeWidth="0.5" d="M19.559 25.099c-.427-.323-.796-.753-1.107-1.29a.429.429 0 01.22-.607.492.492 0 01.608.223c.28.488.635.88 1.064 1.177a3.1 3.1 0 001.807.56c.69 0 1.323-.18 1.9-.542.58-.361.868-.928.868-1.7 0-.637-.204-1.143-.613-1.516-.408-.373-1.215-.795-2.42-1.264-1.192-.448-2.044-.91-2.553-1.385-.509-.476-.763-1.117-.763-1.924 0-.688.25-1.304.752-1.848.501-.544 1.233-.886 2.195-1.024v-.818c0-.126.049-.237.147-.334a.458.458 0 01.669 0 .458.458 0 01.144.334v.818a3.303 3.303 0 011.607.554c.35.234.652.52.902.858.161.217.057.517-.192.624a.5.5 0 01-.579-.16 3.137 3.137 0 00-.769-.686c-.393-.247-.866-.37-1.42-.37-.747 0-1.352.195-1.816.587-.463.392-.695.88-.695 1.465 0 .598.177 1.059.533 1.383.355.324 1.136.708 2.344 1.152 1.303.494 2.203 1.005 2.7 1.53.496.525.745 1.2.745 2.024 0 1.013-.357 1.773-1.07 2.28a4.344 4.344 0 01-2.345.823v.832a.465.465 0 01-.145.335.454.454 0 01-.333.147.477.477 0 01-.335-.137.447.447 0 01-.147-.34v-.892c-.734-.176-1.368-.466-1.903-.87z"></path>
        </g>
      </svg>;
  }
  return <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" fill="none" viewBox="0 0 25 24">
      <mask id="mask0_1469_1317" style={{
      maskType: 'alpha'
    }} width="25" height="24" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0.518 0H24.518V24H0.518z"></path>
      </mask>
      <g fill={props.fill || '#1D252D'} mask="url(#mask0_1469_1317)">
        <path d="M20.915 13.877c-.42 1.652-1.294 3.003-2.626 4.05-1.331 1.049-2.872 1.573-4.622 1.573a7.156 7.156 0 01-2.578-.47 8.044 8.044 0 01-2.257-1.317.674.674 0 01-.24-.501c-.005-.197.075-.377.24-.543a.717.717 0 01.507-.215c.2-.005.387.061.563.2a5.67 5.67 0 001.748 1.002 5.95 5.95 0 002.017.344c1.376 0 2.6-.415 3.67-1.245a5.886 5.886 0 002.134-3.188.77.77 0 01.279-.407.731.731 0 01.456-.16c.25 0 .446.086.59.259.143.172.183.378.12.618zm.02-3.754c.064.24.021.446-.128.618a.75.75 0 01-.598.26.71.71 0 01-.44-.16.826.826 0 01-.288-.408 6.312 6.312 0 00-2.175-3.183C16.249 6.417 15.036 6 13.667 6c-1.667 0-3.083.583-4.25 1.75-1.167 1.167-1.75 2.583-1.75 4.25v.354l.823-.823a.717.717 0 01.527-.218c.207 0 .382.073.527.218.145.145.217.32.217.527a.717.717 0 01-.217.527L7.55 14.579a.83.83 0 01-.633.256.83.83 0 01-.633-.256L4.29 12.585a.717.717 0 01-.217-.527c0-.207.072-.382.217-.527a.717.717 0 01.527-.218c.207 0 .382.073.527.218l.823.823V12c0-2.092.727-3.865 2.18-5.32 1.455-1.453 3.228-2.18 5.32-2.18 1.733 0 3.267.526 4.6 1.577a7.523 7.523 0 012.667 4.046z"></path>
        <path stroke={props.fill || '#1D252D'} strokeWidth="0.5" d="M12.273 15.06a2.55 2.55 0 01-.665-.774.257.257 0 01.132-.365.295.295 0 01.365.134c.168.293.381.528.638.707a1.86 1.86 0 001.085.335 2.11 2.11 0 001.14-.325c.347-.217.52-.557.52-1.02 0-.382-.122-.686-.367-.91-.245-.224-.73-.476-1.452-.758-.716-.269-1.226-.546-1.532-.831-.306-.285-.458-.67-.458-1.154 0-.413.15-.783.451-1.11.3-.326.74-.53 1.317-.614v-.49c0-.076.03-.143.088-.2a.275.275 0 01.401 0 .275.275 0 01.087.2v.49c.349.026.67.137.964.333.21.14.391.312.542.514.096.13.034.311-.115.375a.3.3 0 01-.348-.096 1.88 1.88 0 00-.461-.411 1.568 1.568 0 00-.852-.223c-.448 0-.812.118-1.09.353a1.105 1.105 0 00-.417.879c0 .358.106.635.32.83.213.194.682.424 1.406.69.782.297 1.322.603 1.62.919.298.315.447.72.447 1.214 0 .608-.214 1.064-.642 1.368a2.606 2.606 0 01-1.407.494v.499a.28.28 0 01-.087.201.273.273 0 01-.2.088.286.286 0 01-.2-.082.269.269 0 01-.089-.204v-.535a3.056 3.056 0 01-1.141-.522z"></path>
      </g>
    </svg>;
}
export default Icon;