import React, { useState, useEffect, useContext } from 'react';
import { AsyncModalDialog, Toggle } from 'components';
import { sortBy } from 'lodash';
import { deleteInstalls, createInstall } from './integrations.resource';
import { UserStateContext } from 'context/user-state-context';
export const ModalAppManager = ({
  open,
  onSubmit: postSave,
  onClose,
  integrations,
  installedIntegrations
}) => {
  const {
    asCompany
  } = useContext(UserStateContext);
  const [appManager, setAppManager] = useState({});
  useEffect(() => {
    if (integrations) {
      setAppManager(integrations.reduce((result, row) => {
        result[row.key] = installedIntegrations && installedIntegrations[row.key];
        return result;
      }, {}));
    }
  }, [integrations, installedIntegrations]);
  const onSubmit = () => {
    // get all keys that are different from what they were.
    const [newApps, removeApps] = integrations.reduce(([newApps, removeApps], integration) => {
      if (!appManager[integration.key] && installedIntegrations[integration.key]) {
        // Was installed, no longer
        removeApps.push(installedIntegrations[integration.key]);
      } else if (!!appManager[integration.key] && !installedIntegrations[integration.key]) {
        // Not installed, now is
        newApps.push(integration.key);
      }
      return [newApps, removeApps];
    }, [[], []]);
    let promiseChain = Promise.resolve();
    newApps.forEach(integration => {
      promiseChain = promiseChain.then(() => createInstall({
        integration,
        company: asCompany.id
      }));
    });
    removeApps.forEach(id => {
      promiseChain = promiseChain.then(() => deleteInstalls(id));
    });
    if (newApps.length || removeApps.length) {
      // Trigger the reload credentials if we changed anything.
      promiseChain = promiseChain.then(() => postSave());
    }
    return promiseChain;
  };
  return <AsyncModalDialog open={open} title="Manage Installed Apps" submitText="Done" onSubmit={onSubmit} onClose={onClose} allowBackdropClick type="scroll" stickyFooter>
      <p>Toggle which apps you want displayed for your company on the integrations page. Hiding integrations disable the integration.</p>

      <table className="w-100">
        <tbody>
          {sortBy(integrations, 'name', 'desc').map(integration => <tr key={integration.key}>
              <td>{integration.name}</td>
              <td className="text-right">
                <Toggle className="my-2" checked={!!appManager[integration.key]} onToggle={checked => setAppManager({
              ...appManager,
              [integration.key]: checked
            })} />
              </td>
            </tr>)}
        </tbody>
      </table>
    </AsyncModalDialog>;
};