import React, { Component } from 'react';
import { Scoped } from 'kremling';
import { string, number, object, oneOfType } from 'prop-types';
import styles from './icon.styles.scss';
export class Icon extends Component {
  static propTypes = {
    name: string,
    size: number,
    className: oneOfType([string, object]),
    fill: string,
    text: string
  };
  render() {
    const {
      name,
      size = 20,
      fill,
      className,
      text
    } = this.props;
    return <Scoped css={styles}>
        <svg className={`icon ${className || ''}`} style={{
        width: `${size / 10}rem`,
        height: `${size / 10}rem`,
        fill
      }}>
          <use href={`#${name}`} xlinkHref={`#${name}`} />
        </svg>
        {text && <span style={{
        margin: '9px'
      }}>{text}</span>}
      </Scoped>;
  }
}