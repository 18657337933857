import React from 'react';
function Icon(props) {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none" viewBox="0 0 24 25">
      <mask id="mask0_457_578" style={{
      maskType: 'alpha'
    }} width="25" height="25" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#1D252D" d="M0 0.5H24V24.5H0z"></path>
      </mask>
      <g mask="url(#mask0_457_578)">
        <path fill={props.fill || '#1D252D'} d="M7.02 17.25h3.96a.726.726 0 00.535-.215.726.726 0 00.216-.535.726.726 0 00-.216-.535.726.726 0 00-.534-.215H7.019a.726.726 0 00-.534.215.726.726 0 00-.216.535c0 .213.072.391.216.535a.726.726 0 00.534.215zm3-4h3.96a.726.726 0 00.535-.215.726.726 0 00.216-.535.726.726 0 00-.216-.535.726.726 0 00-.534-.215h-3.962a.726.726 0 00-.534.215.726.726 0 00-.216.535c0 .213.072.391.216.535a.726.726 0 00.534.215zm3-4h3.96a.726.726 0 00.535-.215.726.726 0 00.216-.535.726.726 0 00-.216-.535.726.726 0 00-.534-.215h-3.962a.726.726 0 00-.534.215.726.726 0 00-.216.535c0 .213.072.391.216.535a.726.726 0 00.534.215zM5.307 21c-.505 0-.933-.175-1.283-.525a1.745 1.745 0 01-.525-1.283V5.808c0-.505.175-.933.525-1.283.35-.35.778-.525 1.283-.525h13.384c.506 0 .933.175 1.283.525.35.35.525.778.525 1.283v13.384c0 .505-.175.933-.525 1.283-.35.35-.777.525-1.283.525H5.308zm0-1.5h13.384a.294.294 0 00.212-.096.294.294 0 00.096-.212V5.808a.294.294 0 00-.096-.212.294.294 0 00-.212-.096H5.308a.294.294 0 00-.212.096.294.294 0 00-.096.212v13.384c0 .077.032.148.096.212a.294.294 0 00.212.096z"></path>
      </g>
    </svg>;
}
export default Icon;