import { api, unwrap, buildQueryParams } from '../../shared/api';

// installs
// ----------------------

export function getInstall(installId) {
  return api.get(`/integration/installs/${installId}`);
}
export function getInstalls(params) {
  return api.get(`/integration/installs${buildQueryParams(params)}`).then(unwrap);
}
export function deleteInstalls(id) {
  return api.delete(`/integration/installs/${id}`).then(unwrap);
}
export function createInstall(data) {
  return api.post(`/integration/installs`, data).then(unwrap);
}

// integrations
// ----------------------
export function getIntegrations(params) {
  return api.get(`/integration/integrations${buildQueryParams(params)}`).then(unwrap);
}
export function getIntegrationsNoUnwrap(params) {
  return api.get(`/integration/integrations${buildQueryParams(params)}`);
}

// credentials
// ----------------------
export function getCredentials(params) {
  return api.get(`/integration/credentials${buildQueryParams(params)}`).then(unwrap);
}
export function getCredentialsNoUnwrap(params) {
  return api.get(`/integration/credentials${buildQueryParams(params)}`);
}
export function createCredentials(params) {
  return api.post(`/integration/credentials`, params).then(unwrap);
}
export function updateCredential(id, params) {
  return api.patch(`/integration/credentials/${id}`, params).then(unwrap);
}
export function deleteCredential(id) {
  return api.delete(`/integration/credentials/${id}`).then(unwrap);
}
export function resyncCredential(id) {
  return api.post(`/integration/credentials/${id}/resync`);
}

// tokens
// ----------------------

export function getFacebookToken(tokenId) {
  return api.get(`/facebook/token/${tokenId}`).then(unwrap);
}
export function getFacebookTokens(params) {
  return api.get(`/facebook/token${buildQueryParams(params)}`).then(unwrap);
}
export function postFacebookToken(access_token, expires_when) {
  return api.post(`/facebook/token`, {
    access_token,
    expires_when
  }).then(unwrap);
}
export function refreshFacebookToken(tokenId) {
  return api.post(`/facebook/token/${tokenId}/refresh`).then(unwrap);
}
export function deleteFacebookToken(tokenId) {
  return api.delete(`/facebook/token/${tokenId}`).then(unwrap);
}
export function getFacebookInstalls(params) {
  return api.get(`/facebook/install${buildQueryParams(params)}`).then(unwrap);
}
export function createFacebookInstall(params) {
  return api.post(`/facebook/install`, params).then(unwrap);
}
export function deleteFacebookInstall(id) {
  return api.delete(`/facebook/install/${id}`).then(unwrap);
}

// ad accounts
// ----------------------
export function getFacebookAccounts(params) {
  return api.get(`/facebook/ad/account${buildQueryParams(params)}`).then(unwrap);
}
export function patchFacebookAccount(accountId, company) {
  return api.patch(`/facebook/ad/account/${accountId}`, {
    company
  }).then(unwrap);
}

// review accounts
// ----------------------
export function getFacebookReviewAccounts(params) {
  return api.get(`/integration/facebook_reviews/accounts${buildQueryParams(params)}`).then(unwrap);
}
export function getFacebookReviewAccount(id) {
  return api.get(`/integration/facebook_reviews/accounts/${id}`).then(unwrap);
}
export function deleteFacebookReviewAccount(id) {
  return api.delete(`/integration/facebook_reviews/accounts/${id}`).then(unwrap);
}
export function postFacebookReviewAccount(body) {
  return api.post(`/integration/facebook_reviews/accounts`, body).then(unwrap);
}
export function getFacebookReviewAccountLocationRefs(id) {
  return api.get(`/integration/facebook_reviews/accounts/${id}/location_refs`).then(unwrap);
}
export function getFacebookReviewAccountInstalls(params) {
  return api.get(`/integration/facebook_reviews/installs${buildQueryParams(params)}`).then(unwrap);
}
export function createFacebookReviewInstall(data) {
  return api.post(`/integration/facebook_reviews/installs`, data).then(unwrap);
}
export function deleteFacebookReviewInstall(id) {
  return api.delete(`/integration/facebook_reviews/installs/${id}`).then(unwrap);
}
export function syncFacebookReviewAccountPages(id) {
  return api.post(`/integration/facebook_reviews/accounts/${id}/sync/pages`).then(unwrap);
}
export function syncFacebookReviewPageRatings(id, body) {
  return api.post(`/integration/facebook_reviews/accounts/${id}/sync/ratings`, body).then(unwrap);
}

// audiences
// ----------------------
export function getFacebookAudiences(params) {
  return api.get(`/facebook/ad/audience${buildQueryParams(params)}`).then(unwrap);
}
export function getFacebookAudiencesNoUnWrap(params) {
  return api.get(`/facebook/ad/audience${buildQueryParams(params)}`);
}

// foreup courses
// ----------------------
export function getCourses(params) {
  return api.get(`/foreup/courses${buildQueryParams(params)}`).then(unwrap);
}
export function deleteCourse(courseId) {
  return api.delete(`/foreup/courses/${courseId}`).then(unwrap);
}
export function addCourse(courseRef, company) {
  return api.post(`/foreup/courses`, {
    course_ref: courseRef,
    company
  }).then(unwrap);
}

// newstore connections
// ----------------------
export function getNSConnections(params) {
  return api.get(`/newstore/connections${buildQueryParams(params)}`).then(unwrap);
}
export function deleteNSConnection(connectionId) {
  return api.delete(`/newstore/connections/${connectionId}`).then(unwrap);
}
export function addNSConnection(retailer, stage, company) {
  return api.post(`/newstore/connections`, {
    retailer,
    stage,
    company
  }).then(unwrap);
}
export function refreshNSConnection(connectionId) {
  return api.get(`/newstore/connections/${connectionId}/refresh`).then(unwrap);
}

// linkpos stores
// -----------------------
export function getLPStores(params) {
  return api.get(`/linkpos/stores${buildQueryParams(params)}`).then(unwrap);
}
export function deleteLPStore(storeId) {
  return api.delete(`/linkpos/stores/${storeId}`).then(unwrap);
}
export function addLPStore(storeId, company) {
  return api.post(`/linkpos/stores`, {
    store_id: storeId,
    company
  }).then(unwrap);
}

// mindbody stores
// -----------------------
export function getMBSites(params) {
  return api.get(`/mindbody/sites${buildQueryParams(params)}`).then(unwrap);
}
export function deleteMBSite(siteId) {
  return api.delete(`/mindbody/sites/${siteId}`).then(unwrap);
}
export function addMBSite(siteId, username, password, company) {
  return api.post(`/mindbody/sites`, {
    site_id: siteId,
    username,
    password,
    company
  }).then(unwrap);
}
export function verifyMBSite(id) {
  return api.post(`/mindbody/sites/${id}/verify`).then(unwrap);
}
export function updateMBSite(id, username, password) {
  return api.patch(`/mindbody/sites/${id}`, {
    username,
    password
  }).then(unwrap);
}

// oxifresh franchises
// -----------------------
export function getOFFranchises(params) {
  return api.get(`/oxifresh/franchises${buildQueryParams(params)}`).then(unwrap);
}
export function deleteOFFranchise(id) {
  return api.delete(`/oxifresh/franchises/${id}`).then(unwrap);
}
export function addOFFranchise(franchiseRef, company) {
  return api.post(`/oxifresh/franchises`, {
    franchise_ref: franchiseRef,
    company
  }).then(unwrap);
}

// shopify shops
// -----------------------
export function getShopifyShops(params) {
  return api.get(`/shopify/shops${buildQueryParams(params)}`).then(unwrap);
}
export function deleteShopifyShop(id) {
  return api.delete(`/shopify/shops/${id}`).then(unwrap);
}
export function addShopifyShop(name, code, hmac, timestamp, company, token) {
  return api.post(`/shopify/shops`, {
    name,
    code,
    hmac,
    timestamp,
    company,
    token
  }).then(unwrap);
}
export function authorizeShopifyShop(shop) {
  return api.post(`/shopify/shops/authorize`, {
    shop
  }).then(unwrap);
}

// google accounts
// -------------------------

export function getGoogleAccounts(params) {
  return api.get(`/google/account${buildQueryParams(params)}`).then(unwrap);
}
export function deleteGoogleAccount(id) {
  return api.delete(`/google/account/${id}`).then(unwrap);
}
export function addGoogleAccount(install_id, company) {
  return api.post(`/google/account`, {
    install_id,
    company
  }).then(unwrap);
}
export function syncGoogleForms(id) {
  return api.post(`/google/account/${id}/sync`).then(unwrap);
}

// google adwords accounts
// -------------------------

export function getAdwordsAccounts(params) {
  return api.get(`/ga/account${buildQueryParams(params)}`).then(unwrap);
}
export function deleteAdwordsAccount(id) {
  return api.delete(`/ga/account/${id}`).then(unwrap);
}
export function addAdwordsAccount(install_id, company) {
  return api.post(`/ga/account`, {
    install_id,
    company
  }).then(unwrap);
}
export function syncAdwordsAudiences(id) {
  return api.post(`/ga/account/${id}/sync`).then(unwrap);
}
export function createAdwordsAudience(id, data) {
  return api.post(`/ga/account/${id}/audience`, data).then(unwrap);
}
export function getAdwordsAudiences(params) {
  return api.get(`/ga/audience${buildQueryParams(params)}`).then(unwrap);
}
export function getAdwordsAudiencesNoUnwrap(params) {
  return api.get(`/ga/audience${buildQueryParams(params)}`);
}

// mailbox power
// -------------------------
export function getMailboxPowerContactGroups(params) {
  return api.get(`/mailbox_power/contact_groups${buildQueryParams(params)}`).then(unwrap);
}

// isi endpoints
export function getIsiSftpFiles(companyId, storeId) {
  return api.get(`/isi/sftp/${companyId}?store_id=${storeId}`);
}
export function chooseIsiSftpFile(companyId, storeId, files) {
  return api.post(`/isi/sftp/${companyId}`, {
    store_id: storeId,
    files: files
  });
}
export function getMailboxPowerContactGroupsNoUnwrap(params) {
  return api.get(`/mailbox_power/contact_groups${buildQueryParams(params)}`);
}
// woocommmerce shops
// -------------------------

export function getWoocommerceShops(params) {
  return api.get(`/woocommerce/shop${buildQueryParams(params)}`).then(unwrap);
}
export function deleteWoocommerceShop(id) {
  return api.delete(`/woocommerce/shop/${id}`).then(unwrap);
}
export function syncWoocommerceShop(id) {
  return api.post(`/woocommerce/shop/${id}/sync`).then(unwrap);
}

// calltools tokens
// -------------------------

export function getCallToolsToken(params) {
  return api.get(`/calltools/tokens${buildQueryParams(params)}`).then(unwrap);
}
export function addCallToolsToken(token, company) {
  const params = {
    company: company,
    token: token,
    version: '2'
  };
  return api.post(`/calltools/tokens`, params).then(unwrap);
}
export function deleteCallToolsToken(id) {
  return api.delete(`/calltools/tokens/${id}`).then(unwrap);
}
export function getCallToolsLists(id) {
  return api.get(`/calltools/tokens/${id}/lists`).then(unwrap);
}
export function addCallToolsDisposition(name, token) {
  return api.post(`/calltools/dispositions`, {
    name,
    token
  }).then(unwrap);
}
export function getCallToolsDispositions(params) {
  return api.get(`/calltools/dispositions${buildQueryParams(params)}`).then(unwrap);
}
export function getCallToolsDisposition(id) {
  return api.get(`/calltools/dispositions/${id}`, {
    name
  }).then(unwrap);
}
export function editCallToolsDisposition(id, name) {
  return api.patch(`/calltools/dispositions/${id}`, {
    name
  }).then(unwrap);
}
export function deleteCallToolsDisposition(id) {
  return api.delete(`/calltools/dispositions/${id}`).then(unwrap);
}

// pestroutes credentials
// -------------------------

export function getPestRoutesCredentials(params) {
  return api.get(`/pestroutes/credentials${buildQueryParams(params)}`).then(unwrap);
}
export function addPestRoutesCredentials(domain, authentication_key, authentication_token, company, timezone) {
  return api.post(`/pestroutes/credentials`, {
    domain,
    authentication_key,
    authentication_token,
    company,
    timezone
  }).then(unwrap);
}
export function updatePestRoutesCredentials(id, domain, authentication_key, authentication_token, timezone) {
  return api.patch(`/pestroutes/credentials/${id}`, {
    domain,
    authentication_key,
    authentication_token,
    timezone
  }).then(unwrap);
}
export function deletePestRoutesCredentials(id) {
  return api.delete(`/pestroutes/credentials/${id}`).then(unwrap);
}

// swell accounts
// -----------------------
export function getSwellAccounts(params) {
  return api.get(`/swell/accounts${buildQueryParams(params)}`).then(unwrap);
}
export function deleteSwellAccount(id) {
  return api.delete(`/swell/accounts/${id}`).then(unwrap);
}
export function addSwellAccount(data) {
  return api.post(`/swell/accounts`, data).then(unwrap);
}
export function updateSwellAccount(data) {
  return api.patch(`/swell/accounts/${data.id}`, data).then(unwrap);
}

// jiffy lube franchises
// -------------------------
export function getJiffyLubeFranchises(params) {
  return api.get(`/jiffylube/franchises${buildQueryParams(params)}`).then(unwrap);
}
export function addJiffyLubeFranchise(data) {
  return api.post(`/jiffylube/franchises`, data).then(unwrap);
}
export function deleteJiffyLubeFranchise(id) {
  return api.delete(`/jiffylube/franchises/${id}`).then(unwrap);
}
export function updateJiffyLubeFranchise(data) {
  return api.patch(`/jiffylube/franchises/${data.id}`, data).then(unwrap);
}

// loyalty
export function getLoyaltyCompanies(data) {
  return api.post(`/loyalty/companies`, data).then(unwrap);
}

// Audiohook
export function getAudiohookCampaigns(data) {
  return api.post(`/audiohook/${data.credential}/campaigns`, data);
}

// OilChanger

export function getOilTypeRules(params) {
  return api.get(`/integrations/oilchange/calculator`, {
    params
  });
}
export function deleteOilTypeRule(oilTypeRuleId) {
  return api.delete(`/integrations/oilchange/calculator/${oilTypeRuleId}`);
}
export function updateOilTypeRule(oilTypeRule) {
  return api.patch(`/integrations/oilchange/calculator/${oilTypeRule.id}`, oilTypeRule);
}
export function addOilTypeRule(oilTypeRule) {
  return api.post(`/integrations/oilchange/calculator`, oilTypeRule);
}
export function runCalculator(company) {
  return api.get(`/integrations/oilchange/calculator/run/${company}`);
}

// DripDrop

export function getDripDropCampaigns(params) {
  return api.get(`/dripdrop/campaigns`, {
    params
  });
}
export function createDripDropCampaign(data) {
  return api.post(`/dripdrop/campaigns`, data);
}

// Google Business

export function addGoogleBusinessAccount(install_id, company) {
  return api.post(`/google-business/account`, {
    install_id,
    company
  }).then(unwrap);
}

// AutoOps
export function getAutoOpsStatus() {
  return api.get('/autoops/statuses');
}
export function autoOpsShopsSync(credentialId) {
  return api.post('/autoops/sync/shops', {
    credential: credentialId
  });
}
export function autoOpsShopInitialSync(credentialId, locationRefId) {
  return api.post('/autoops/sync/initial', {
    credential: credentialId,
    location_ref: locationRefId
  });
}