import React from 'react';
function Icon() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 18 18">
      <mask id="mask0_251_8369" style={{
      maskType: 'alpha'
    }} width="18" height="18" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0 0H18V18H0z"></path>
      </mask>
      <g mask="url(#mask0_251_8369)">
        <path fill="#1C1B1F" d="M4.725 11.85a.726.726 0 01-.535-.216.726.726 0 01-.215-.534c0-.213.072-.39.215-.534a.726.726 0 01.535-.216h8.55c.212 0 .39.072.534.216a.725.725 0 01.216.534.726.726 0 01-.216.534.726.726 0 01-.534.216h-8.55zm0-4.2a.726.726 0 01-.535-.216.725.725 0 01-.215-.534c0-.213.072-.39.215-.534a.726.726 0 01.535-.216h8.55c.212 0 .39.072.534.216a.726.726 0 01.216.534c0 .213-.072.39-.216.534a.726.726 0 01-.534.216h-8.55z"></path>
      </g>
    </svg>;
}
export default Icon;