import React from 'react';
function Icon(props) {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <mask id="mask0_350_13997" style={{
      maskType: 'alpha'
    }} width="24" height="25" x="0" y="-1" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0 0H24V24H0z"></path>
      </mask>
      <g mask="url(#mask0_350_13997)">
        <path fill={props.fill || '#1D252D'} d="M19 15.125V12.75H5v2.375h14zm0-3.875V8.875H5v2.375h14zm0-3.875V5.307a.3.3 0 00-.087-.22.3.3 0 00-.22-.087H5.307a.3.3 0 00-.221.086.3.3 0 00-.087.221v2.068h14zM5.308 20.5c-.505 0-.933-.175-1.283-.525a1.745 1.745 0 01-.525-1.283V5.307c0-.505.175-.932.525-1.282.35-.35.778-.525 1.283-.525h13.384c.505 0 .933.175 1.283.525.35.35.525.777.525 1.282v13.385c0 .505-.175.933-.525 1.283-.35.35-.778.525-1.283.525H5.308zM19 18.692v-2.067H5v2.067a.3.3 0 00.087.221.3.3 0 00.22.087h13.385a.3.3 0 00.221-.087.3.3 0 00.087-.221z"></path>
      </g>
    </svg>;
}
export default Icon;