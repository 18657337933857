import React, { useEffect, useState, useContext } from 'react';
import { Loader } from '../../components/loader/loader.component';
import { UserStateContext } from 'context/user-state-context';
import { Scoped } from 'kremling';
import { PageHeader } from '../../components/page-header/page-header';
import { Pagination } from '../../components/pagination/pagination.component';
import styles from './users.styles.scss';
import { getUser, updateUser, getCompanies, getUserCompanies, getRoles, deleteUserCompany, updateUserCompany, createUserCompany, getUserLocations, getMarkets, getLocations, replaceUserToLocation, replaceUserToMarket } from '../../shared/common.api';
import { StaticTypeAhead } from '../../components/static-type-ahead/static-type-ahead.component';
import { sortBy } from 'lodash';
import { ColorPicker, Icon, PageHeaderMui, Toggle } from 'components';
import utils from 'shared/utils';
import { snackbarService } from 'components/mui/snackbar/snackbar-service';
import { Autocomplete, Box, Button, Checkbox, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, Stack, TextField, Typography } from '@mui/material';
import { CloseIcon } from 'components/mui';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
const User = props => {
  const {
    hasPermission,
    user: me,
    triggerNeedsPhoneModal,
    changedPhone,
    flags
  } = useContext(UserStateContext);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState({});
  const [companies, setCompanies] = useState([]);
  const [search, setSearch] = useState('');
  const [count, setCount] = useState(0);
  const [roles, setRoles] = useState({});
  const [globalRoles, setGlobalRoles] = useState([]);
  const [paginationParams, setPaginationParams] = useState({
    offset: 0,
    limit: 20
  });
  const [initalRender, setInitalRender] = useState(true);
  const [colorPreference, setColorPreference] = useState('');
  const [colorPreferenceSecondary, setColorPreferenceSecondary] = useState('');
  const [reload, setReload] = useState(false);
  const [editUserLocationsModal, setEditUserLocationsModal] = useState(false);
  const [userLocationsLoading, setUserLocationsLoading] = useState(true);
  const [locationsData, setLocationsData] = useState([]);
  const [selectedUserLocations, setSelectedUserLocations] = useState([]);
  const [selectedUserLocationsChanged, setSelectedUserLocationsChanged] = useState(false);
  const [marketsData, setMarketsData] = useState([]);
  const [selectedMarkets, setSelectedMarkets] = useState([]);
  const [selectedMarketsChanged, setSelectedMarketsChanged] = useState(false);
  const [saving, setSaving] = useState(false);
  useEffect(() => {
    getUser(props.match.params.userId).then(({
      data
    }) => {
      setUser(data);
      setColorPreference(data.color_preference || '#e4e9f1');
      setColorPreferenceSecondary(data.color_preference_secondary || '#ffffff');
      setIsLoading(false);
    });
    getRoles({
      limit: 1
    }).then(({
      data
    }) => {
      const promises = [];
      for (let i = 0; i < Math.ceil(data.count / 100); i++) {
        promises.push(getRoles({
          offset: i * 100,
          limit: 100
        }));
      }
      Promise.all(promises).then(allRoles => {
        const roles = {};
        const globalRoles = [];
        allRoles.forEach(({
          data
        }) => {
          data.results.forEach(role => {
            if (role.company) {
              if (!roles[role.company]) {
                roles[role.company] = [];
              }
              roles[role.company].push(role);
            } else {
              globalRoles.push(role);
            }
          });
          Object.keys(roles).forEach(companyId => {
            roles[companyId] = [...roles[companyId], ...globalRoles].sort((a, b) => {
              if (a.display_name === b.display_name) {
                return 0;
              }
              return a.display_name < b.display_name ? -1 : 1;
            });
          });
          setRoles(roles);
          if (!flags.catalyst_user) {
            setGlobalRoles(globalRoles.filter(r => r.display_name != 'Catalyst'));
          } else {
            setGlobalRoles(globalRoles);
          }
        });
      });
    });
  }, []);
  const handleEditLocationsClick = company => {
    setEditUserLocationsModal(company);
    setUserLocationsLoading(true);
    const promises = [getUserLocations({
      user: props.match.params.userId,
      company: company.id
    }), getMarkets({
      company: company.id,
      limit: 1000
    }), getLocations({
      company: company.id,
      limit: 1000
    })];
    Promise.all(promises).then(([userLocations, markets, locations]) => {
      //console.log(userLocations.data.results, 'userLocations');
      //console.log(markets.data.results, 'markets');
      //console.log(locations.data.results, 'locations');

      const selectedLocations = [];
      const selectedMarks = [];
      userLocations.data.results.map(ul => {
        if (ul.source == 'location' && selectedLocations.findIndex(e => e.id == ul.location) == -1) {
          selectedLocations.push({
            friendly_name: ul.friendly_name,
            id: ul.location,
            name: ul.name
          });
        } else if (ul.source == 'markets' && selectedMarks.indexOf(ul.source_id) == -1) {
          selectedMarks.push(ul.source_id);
        }
      });
      setLocationsData(locations.data.results.map(e => ({
        friendly_name: e.friendly_name,
        id: e.id,
        name: e.name
      })));
      setSelectedUserLocations(selectedLocations);
      setMarketsData(markets.data.results);
      setSelectedMarkets(selectedMarks);
      //console.log(selectedMarks, 'selectedMarks');
      setUserLocationsLoading(false);
    });
  };
  useEffect(() => {
    console.log(selectedUserLocations, 'selectedUserLocations');
  }, [selectedUserLocations]);
  const handleEditLocationsSubmit = () => {
    setSaving(true);
    const promises = [];
    if (selectedUserLocationsChanged) {
      promises.push(replaceUserToLocation({
        user: props.match.params.userId,
        company: editUserLocationsModal.id,
        locations: selectedUserLocations.map(e => e.id)
      }));
    }
    if (selectedMarketsChanged) {
      promises.push(replaceUserToMarket({
        user: props.match.params.userId,
        company: editUserLocationsModal.id,
        markets: selectedMarkets
      }));
    }
    Promise.all(promises).then(() => {
      setSaving(false);
      setEditUserLocationsModal(false);
    });
  };
  const loadData = () => {
    getCompanies({
      ...paginationParams,
      search
    }).then(({
      data
    }) => {
      if (data) {
        const companies = data.results;
        setCount(data.count);

        // TODO Get user to company for these companies
        getUserCompanies(props.match.params.userId).then(({
          data
        }) => {
          companies.forEach(company => {
            data.results.forEach(utc => {
              if (utc.company === company.id) {
                company.role_name = utc.role_name;
                company.role_id = utc.role;
                company.utc_id = utc.id;
              }
            });
          });
          const sortCompanies = sortBy(companies, 'role_name');
          setCompanies(sortCompanies);
        });
      }
    });
  };
  const updateRole = (companyId, roleId, utcId) => {
    if (roleId === '') {
      if (utcId) {
        // Delete UTC
        deleteUserCompany(utcId).then(loadData);
      } else {
        // ... We shouldn't get here...
      }
    } else {
      if (utcId) {
        // Update UTC
        updateUserCompany(utcId, {
          role: roleId
        }).then(loadData);
      } else {
        // Create UTC
        createUserCompany({
          user: user.id,
          company: companyId,
          role: roleId
        }).then(loadData);
      }
    }
  };
  useEffect(() => {
    loadData();
  }, [search, paginationParams]);
  useEffect(() => {
    if (!!user.id && !initalRender) {
      triggerSave(true);
    }
    if (!!user.id) {
      setInitalRender(false);
    }
  }, [user.beta_opt_in, user.color_preference, user.color_preference_secondary]);
  const triggerSave = triggerReload => {
    const cleanedUser = JSON.parse(JSON.stringify(user));
    cleanedUser.phone = utils.formatIntlPhone(cleanedUser.phone);
    setUser(cleanedUser);
    updateUser(me.is_superuser ? cleanedUser.id : me.id, cleanedUser).then(() => {
      snackbarService.popup({
        type: 'success',
        message: 'Your user account has been updated'
      });
      if (triggerReload) {
        window.location.reload();
      }
    }).catch(() => {
      snackbarService.popup({
        type: 'error',
        message: 'Unable to save your user information.  Please check your inputs and try again.'
      });
    });
  };
  useEffect(() => {
    if (!!changedPhone) {
      const cleanedUser = JSON.parse(JSON.stringify(user));
      cleanedUser.phone = utils.formatIntlPhone(changedPhone);
      setUser(cleanedUser);
    }
  }, [changedPhone]);
  if (isLoading) {
    return <Loader overlay />;
  }
  return <>
      <Scoped css={styles}>
        <div className="wrapper">
          <PageHeaderMui type="Users" name={!user ? 'User' : `${user.first_name} ${user.last_name}`} icon={<Icon name="custom-segment" size={34} />} />
          <div className="wrapper-scroll user-detail">
            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label>First Name</label>
                  <input className="form-control" type="text" disabled={user.id !== me.id} value={user.first_name || ''} onChange={e => setUser({
                  ...user,
                  first_name: e.target.value
                })} onBlur={triggerSave} />
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label>Last Name</label>
                  <input className="form-control" type="text" disabled={user.id !== me.id} value={user.last_name || ''} onChange={e => setUser({
                  ...user,
                  last_name: e.target.value
                })} onBlur={triggerSave} />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label>Email</label>
                  <input className="form-control" type="text" disabled={true} value={user.email || ''} />
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <label>Phone Number</label>
                  <input className="form-control" type="text" disabled={!me.is_superuser} value={user.phone || ''} onChange={e => setUser({
                  ...user,
                  phone: e.target.value
                })} onBlur={triggerSave} />
                </div>
              </div>
              <div className="col-3">
                <Box sx={{
                mt: 2
              }}>
                  <Button variant="contained" onClick={e => {
                  triggerNeedsPhoneModal(true);
                }}>
                    MFA Settings / Change Phone Number
                  </Button>
                </Box>
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label>Beta Site Opt in</label>
                  <Toggle className="ml-3" checked={!!user.beta_opt_in} onToggle={() => {
                  setUser({
                    ...user,
                    beta_opt_in: !user.beta_opt_in
                  });
                }} />
                </div>
              </div>
              {me.is_superuser && <>
                  <div className="col-3">
                    <div className="form-group">
                      <label>Background Color</label>
                      {reload && <span className="pl-2">
                          <Button variant="contained" onClick={e => {
                      setUser({
                        ...user,
                        color_preference: colorPreference,
                        color_preference_secondary: colorPreferenceSecondary
                      });
                    }}>
                            Click to save Colors and Apply sitewide
                          </Button>
                        </span>}
                      <div className="color-picker-wrapper">
                        <ColorPicker color={colorPreference} onChange={color => {
                      setColorPreference(color);
                      setReload(true);
                      const root = document.documentElement;
                      root?.style.setProperty('--custom-background-color', color);
                    }} />
                      </div>
                    </div>
                  </div>

                  <div className="col-3">
                    <div className="form-group">
                      <label>Background Secondary Color</label>
                      <div className="color-picker-wrapper">
                        <ColorPicker color={colorPreferenceSecondary} onChange={color => {
                      setColorPreferenceSecondary(color);
                      setReload(true);
                      const root = document.documentElement;
                      root?.style.setProperty('--custom-background-color-secondary', color);
                    }} />
                      </div>
                    </div>
                  </div>
                </>}
            </div>

            <hr />

            <div className="row">
              <div className="col-6 my-5">
                <strong>User Access</strong>
              </div>
              <div className="col-6 my-5" styles={{
              textAlign: 'right'
            }}>
                <input className="form-control" placeholder="Search Company" value={search} onChange={e => setSearch(e.target.value)} />
              </div>
            </div>
            {companies.length ? <>
                <table className="table-list">
                  <thead>
                    <tr>
                      <th width="50%">Company</th>
                      <th width="50%">Role</th>
                    </tr>
                  </thead>
                  <tbody>
                    {companies.length && companies.map(company => <tr key={company.id}>
                          <td>{company.name}</td>
                          <td>
                            {hasPermission('authentication.change_usertocompany') ? <Stack spacing="16px" sx={{
                      width: '100%'
                    }} direction="row">
                                <Box sx={{
                        width: flags.catalyst_user && company.role_id == globalRoles.find(r => r.display_name == 'Catalyst')?.id ? '65%' : '100%'
                      }}>
                                  <StaticTypeAhead items={roles[company.id] && [{
                          id: '',
                          display_name: 'Not in Company'
                        }, ...roles[company.id]] || [{
                          id: '',
                          display_name: 'Not in Company'
                        }, ...globalRoles]} placeholder={company && company.role_id ? company.role_name : 'Not in Company'} placeholderStyles={company && company.role_id} displayProperty="display_name" keyProperty="id" value={company && company.role_id ? company.role_name : ''} onChange={role => {
                          role && updateRole(company.id, role.id, company.utc_id);
                          //if role selected is catalyst, show modal
                          if (role && role.display_name == 'Catalyst') {
                            handleEditLocationsClick(company);
                          }
                        }} />
                                </Box>
                                {flags.catalyst_user && company.role_id == globalRoles.find(r => r.display_name == 'Catalyst')?.id && <Button variant="contained" onClick={e => {
                        handleEditLocationsClick(company);
                      }}>
                                    Edit Locations
                                  </Button>}
                              </Stack> : company.role_name || <i>Not in Company</i>}
                          </td>
                        </tr>)}
                  </tbody>
                </table>
                <Pagination data={{
              count
            }} onChange={setPaginationParams} />
              </> : <span>No company results</span>}
          </div>
        </div>
      </Scoped>

      <Dialog PaperProps={{
      style: {
        borderRadius: '28px',
        minWidth: '550px'
      }
    }} open={!!editUserLocationsModal} onClose={(e, r) => {
      if (r != 'backdropClick') {
        setEditUserLocationsModal(false);
      }
    }}>
        <DialogTitle sx={{
        m: 0,
        p: 2,
        fontSize: 'medium'
      }}>
          <IconButton aria-label="close" onClick={e => {
          setEditUserLocationsModal(false);
        }} sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500]
        }}>
            <CloseIcon />
          </IconButton>
          <Stack alignItems="center" component="span">
            <Typography variant="h4" component="span">
              Edit Catalyst Locations
            </Typography>
          </Stack>
        </DialogTitle>
        <DialogContent>
          {userLocationsLoading ? <Box sx={{
          width: '100%',
          height: '100%'
        }}>
              <Box sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            justifyItems: 'center',
            alignItems: 'center'
          }}>
                <CircularProgress />
              </Box>
            </Box> : <Stack sx={{
          py: 2
        }} spacing={2}>
              <Box sx={{
            width: '100%'
          }}>
                <Autocomplete disabled={saving} multiple loading={userLocationsLoading} id="location-select" options={locationsData} disableCloseOnSelect value={selectedUserLocations || []} getOptionLabel={option => option.friendly_name} onChange={(event, newValue) => {
              setSelectedUserLocations(newValue);
              setSelectedUserLocationsChanged(true);
            }} renderOption={(props, option, {
              selected
            }) => {
              const {
                key,
                ...optionProps
              } = props;
              return <li key={option.id} {...optionProps}>
                        <Checkbox icon={<CheckBoxOutlineBlankIcon />} checkedIcon={<CheckBoxIcon />} style={{
                  marginRight: 8
                }} checked={!!selectedUserLocations?.find(e => e.id == option.id)} />
                        {option.friendly_name}
                      </li>;
            }} renderTags={(tagValue, getTagProps) => {
              if (tagValue.length == 0) {
                return <Chip label="None" />;
              }
              if (tagValue.length == 1) {
                return <Typography sx={{
                  width: '350px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden !important',
                  textOverflow: 'ellipsis'
                }}>
                          {tagValue[0].friendly_name}
                        </Typography>;
              }
              return <Typography sx={{
                width: '350px',
                whiteSpace: 'nowrap',
                overflow: 'hidden !important',
                textOverflow: 'ellipsis'
              }}>
                        {tagValue[0].friendly_name + ' + ' + (tagValue.length - 1) + ' more'}
                      </Typography>;
            }} renderInput={params => <TextField {...params} label="Locations" />} />
              </Box>

              <Box sx={{
            width: '100%'
          }}>
                <FormControl sx={{
              width: '100%'
            }}>
                  <InputLabel id="market-label">Markets</InputLabel>
                  <Select disabled={saving} sx={{
                borderRadius: '14px'
              }} labelId="market-label" id="market" multiple value={selectedMarkets || []} onChange={event => {
                const {
                  target: {
                    value
                  }
                } = event;
                setSelectedMarkets(typeof value === 'string' ? value.split(',') : value);
                setSelectedMarketsChanged(true);
              }} input={<OutlinedInput label={'markets'} />} renderValue={selected => {
                if (selected.length === 0) {
                  return 'None';
                }
                if (selected.length === 1) {
                  return marketsData.find(e => e.id == selected[0])?.name;
                }
                return marketsData.find(e => e.id == selected[0])?.name + ' + ' + (selected.length - 1) + ' more';
              }} MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 224,
                    width: 250
                  }
                }
              }}>
                    {marketsData.map(market => <MenuItem key={market.id} value={market.id}>
                        <Checkbox checked={selectedMarkets?.indexOf(market.id) > -1} />
                        <ListItemText primary={market?.name} />
                      </MenuItem>)}
                  </Select>
                </FormControl>
              </Box>
            </Stack>}
        </DialogContent>
        <DialogActions sx={{
        py: 1
      }}>
          <Button disabled={saving} onClick={() => {
          setEditUserLocationsModal(false);
        }} variant="text" sx={{
          borderRadius: '20px',
          color: 'rgba(29, 37, 45, 0.7)',
          width: '75px',
          textTransform: 'none'
        }}>
            Cancel
          </Button>
          <Button disabled={saving} endIcon={saving && <CircularProgress sx={{
          color: '#FFF'
        }} size={'20px'} />} onClick={handleEditLocationsSubmit} variant="text" sx={{
          mr: 1,
          borderRadius: '20px',
          width: '75px',
          color: '#53A6D6',
          textTransform: 'none'
        }}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>;
};
export default User;