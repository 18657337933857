import React from 'react';
function Icon() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <mask id="mask0_431_883" style={{
      maskType: 'alpha'
    }} width="24" height="24" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#3898D9" d="M0 0H24V24H0z"></path>
      </mask>
      <g mask="url(#mask0_431_883)">
        <path fill="#3898D9" d="M12 12.277a.83.83 0 01-.633-.256L6.873 7.527a.744.744 0 01-.22-.514.697.697 0 01.22-.54.717.717 0 01.527-.217c.206 0 .382.072.527.217L12 10.531l4.073-4.058a.744.744 0 01.515-.22.697.697 0 01.539.22c.145.145.217.32.217.527a.717.717 0 01-.217.527l-4.494 4.494a.83.83 0 01-.633.256zm0 5.98a.83.83 0 01-.633-.256L6.873 13.51a.744.744 0 01-.22-.514.698.698 0 01.22-.54.718.718 0 01.527-.217c.206 0 .382.072.527.217L12 16.512l4.073-4.058a.744.744 0 01.515-.22.697.697 0 01.539.22c.145.145.217.32.217.527a.717.717 0 01-.217.527l-4.494 4.494a.83.83 0 01-.633.256z"></path>
      </g>
    </svg>;
}
export default Icon;