import React from 'react';
function Icon() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none" viewBox="0 0 24 25">
      <mask id="mask0_124_2307" style={{
      maskType: 'alpha'
    }} width="24" height="25" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0 0.5H24V24.5H0z"></path>
      </mask>
      <g mask="url(#mask0_124_2307)">
        <path fill="#1D252D" d="M8.37 8.642a.736.736 0 01-.218-.542c0-.217.072-.397.217-.542l2.998-2.998a.818.818 0 01.301-.198 1.027 1.027 0 01.676 0 .692.692 0 01.289.198l3.013 3.013a.701.701 0 01.21.535.755.755 0 01-.768.752.736.736 0 01-.542-.218L12 6.096 9.438 8.658a.701.701 0 01-.534.21.754.754 0 01-.535-.226zM12 20.758a.872.872 0 01-.332-.066 1.041 1.041 0 01-.3-.19l-2.999-2.998a.736.736 0 01-.217-.543c0-.216.072-.397.217-.542a.736.736 0 01.543-.217c.216 0 .397.072.542.217L12 18.965l2.562-2.561a.7.7 0 01.534-.21.754.754 0 01.752.768.736.736 0 01-.217.542l-2.998 2.998a.85.85 0 01-.289.19.919.919 0 01-.344.066z"></path>
      </g>
    </svg>;
}
export default Icon;