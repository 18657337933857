import React from 'react';
function Icon() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" fill="none" viewBox="0 0 25 24">
      <mask id="mask0_144_1538" style={{
      maskType: 'alpha'
    }} width="25" height="24" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0.976 0H24.976V24H0.976z"></path>
      </mask>
      <g fill="#1D252D" mask="url(#mask0_144_1538)">
        <path d="M14.226 12.89a.826.826 0 01-.625-.263.858.858 0 01-.25-.612c0-.25.083-.463.25-.638a.828.828 0 01.625-.262c.25 0 .458.087.625.262.166.175.25.388.25.638a.858.858 0 01-.25.612.826.826 0 01-.625.263zm-4 0a.826.826 0 01-.625-.263.858.858 0 01-.25-.612c0-.25.083-.463.25-.638a.828.828 0 01.625-.262c.25 0 .458.087.625.262.166.175.25.388.25.638a.858.858 0 01-.25.612.826.826 0 01-.625.263zm8 0a.826.826 0 01-.625-.263.858.858 0 01-.25-.612c0-.25.083-.463.25-.638a.828.828 0 01.625-.262c.25 0 .458.087.625.262.166.175.25.388.25.638a.858.858 0 01-.25.612.826.826 0 01-.625.263zm-4 3.925a.828.828 0 01-.625-.262.862.862 0 01-.25-.613c0-.25.083-.463.25-.637a.827.827 0 01.625-.263c.25 0 .458.088.625.263.166.174.25.387.25.637a.862.862 0 01-.25.613.828.828 0 01-.625.262zm-4 0a.828.828 0 01-.625-.262.862.862 0 01-.25-.613c0-.25.083-.463.25-.637a.827.827 0 01.625-.263c.25 0 .458.088.625.263.166.174.25.387.25.637a.862.862 0 01-.25.613.828.828 0 01-.625.262zm8 0a.828.828 0 01-.625-.262.862.862 0 01-.25-.613c0-.25.083-.463.25-.637a.827.827 0 01.625-.263c.25 0 .458.088.625.263.166.174.25.387.25.637a.862.862 0 01-.25.613.828.828 0 01-.625.262zm-10.7 3.5c-.5 0-.925-.175-1.275-.525a1.736 1.736 0 01-.525-1.275v-13.4c0-.5.175-.925.525-1.275.35-.35.775-.525 1.275-.525h1.4v-1.35c0-.217.07-.4.212-.55a.71.71 0 01.538-.225c.233 0 .42.075.562.225a.77.77 0 01.213.55v1.35h7.575V1.94c0-.2.075-.375.225-.525a.72.72 0 01.525-.225c.216 0 .395.075.537.225a.74.74 0 01.213.525v1.375h1.4c.5 0 .925.175 1.275.525.35.35.525.775.525 1.275v13.4c0 .5-.175.925-.525 1.275-.35.35-.775.525-1.275.525h-13.4zm0-1.5h13.4a.292.292 0 00.213-.087.292.292 0 00.087-.213v-9.4h-14v9.4c0 .083.029.154.088.213a.288.288 0 00.212.087zm-.3-11.2h14v-2.5a.289.289 0 00-.087-.212.29.29 0 00-.213-.088h-13.4a.287.287 0 00-.212.088.287.287 0 00-.088.212v2.5z"></path>
        <path d="M5.027 22.81c-.5 0-.925-.175-1.275-.525a1.736 1.736 0 01-.525-1.275V8.86c0-.2.07-.375.213-.525a.706.706 0 01.537-.225c.216 0 .395.075.537.225a.74.74 0 01.213.525v12.15c0 .084.029.155.088.213a.289.289 0 00.212.087h12.15c.2 0 .375.07.525.212a.71.71 0 01.225.538.71.71 0 01-.225.538.741.741 0 01-.525.212H5.027z"></path>
      </g>
    </svg>;
}
export default Icon;