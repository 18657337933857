import * as React from 'react';
import { Stack, IconButton } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
export default function TablePagination(props) {
  const {
    page,
    rowsPerPage,
    rowCount,
    hasNext,
    hasPrevious,
    count,
    handleChangePage
  } = props;
  const startRow = rowCount === 0 ? 0 : page * rowsPerPage + 1;
  const endRow = rowCount === 0 ? 0 : page * rowsPerPage + props.rowCount;
  return <Stack direction="row" alignItems="center" {...props.sx}>
      <div className="mr-2">
        {startRow} - {endRow}{' '}
        {count !== undefined && count !== null && `of ${count}`}
      </div>

      <IconButton icon="fa-regular-arrow-up" size="medium" onClick={() => {
      handleChangePage(page - 1);
    }} disabled={!hasPrevious}>
        <ArrowBackIosNewIcon />
      </IconButton>
      <IconButton icon="fa-regular-arrow-up" size="medium" disabled={!hasNext} onClick={() => {
      handleChangePage(page + 1);
    }}>
        <ArrowForwardIosIcon />
      </IconButton>
    </Stack>;
}