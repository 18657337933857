import React from 'react';
function Icon(props) {
  return <svg xmlns="http://www.w3.org/2000/svg" width={props.size || '24'} height={props.size || '24'} fill="none" viewBox="0 0 21 20">
      <mask id="mask0_21_3763" style={{
      maskType: 'alpha'
    }} width="21" height="20" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0.091 0H20.091V20H0.091z"></path>
      </mask>
      <g mask="url(#mask0_21_3763)">
        <path fill={props.fill || '#1C1B1F'} d="M3.474 15.625a.647.647 0 01-.474-.2.64.64 0 010-.933.648.648 0 01.474-.2h3.402c.182 0 .34.066.473.2a.64.64 0 010 .933.648.648 0 01-.473.2H3.474zm-.015-9.917a.632.632 0 01-.459-.2.64.64 0 010-.933.632.632 0 01.46-.2h6.534c.172 0 .325.067.46.2a.64.64 0 010 .933.632.632 0 01-.46.2H3.459zm6.715 11.583a.639.639 0 01-.466-.2.637.637 0 01-.2-.464V13.31c0-.176.067-.331.2-.465a.64.64 0 01.933 0 .64.64 0 01.2.466v.98h5.885c.17 0 .323.066.456.2a.64.64 0 010 .933.63.63 0 01-.456.2H10.84v1a.64.64 0 01-.2.466.64.64 0 01-.467.2zm-3.291-4.958a.639.639 0 01-.466-.2.64.64 0 01-.2-.466v-1H3.47a.645.645 0 01-.47-.201.64.64 0 010-.932c.133-.134.29-.2.47-.2h2.745v-.98a.64.64 0 01.2-.466.64.64 0 01.933 0c.134.134.2.288.2.464v3.316c0 .176-.066.331-.2.465a.64.64 0 01-.466.2zm3.285-1.667a.633.633 0 01-.46-.2.64.64 0 010-.932.633.633 0 01.46-.2h6.554c.173 0 .327.066.46.2a.64.64 0 010 .932.633.633 0 01-.46.2h-6.554zM13.3 7.354a.639.639 0 01-.466-.2.638.638 0 01-.2-.465V3.373c0-.176.067-.33.2-.464a.64.64 0 01.933 0c.133.133.2.289.2.466v1h2.745c.18 0 .338.067.471.2a.64.64 0 010 .933.645.645 0 01-.471.2h-2.745v.98a.64.64 0 01-.2.466.64.64 0 01-.467.2z"></path>
      </g>
    </svg>;
}
export default Icon;