import React from 'react';
import { Box, Paper } from "@mui/material";
export const Content = ({
  children,
  ...props
}) => <Box sx={{
  backgroundColor: '#DAEFFF',
  height: props.height || "100%",
  padding: "20px",
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch'
}}>
    <Paper sx={{
    borderRadius: '14px',
    flex: '1'
  }}>
      {children}
    </Paper>
  </Box>;