import React from 'react';
function Icon() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none" viewBox="0 0 24 25">
      <mask id="mask0_65_4048" style={{
      maskType: 'alpha'
    }} width="25" height="25" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0 0.257H24V24.257H0z"></path>
      </mask>
      <g mask="url(#mask0_65_4048)">
        <path fill="#1D252D" d="M17.4 13.757a.579.579 0 00.425-.175.579.579 0 00.175-.425.578.578 0 00-.175-.425.578.578 0 00-.425-.175h-2.8a.579.579 0 00-.425.175.579.579 0 00-.175.425c0 .167.059.309.175.425a.579.579 0 00.425.175h2.8zm0 2.7a.579.579 0 00.425-.175.579.579 0 00.175-.425.578.578 0 00-.175-.425.578.578 0 00-.425-.175h-2.8a.579.579 0 00-.425.175.579.579 0 00-.175.425c0 .167.059.309.175.425a.579.579 0 00.425.175h2.8zm-2.9-8.7h5.2c.5 0 .925.175 1.275.525.35.35.525.775.525 1.275v10.4c0 .5-.175.925-.525 1.275-.35.35-.775.525-1.275.525H4.3c-.5 0-.925-.175-1.275-.525a1.736 1.736 0 01-.525-1.275v-10.4c0-.5.175-.925.525-1.275.35-.35.775-.525 1.275-.525h5.2v-3.5c0-.416.146-.77.438-1.062A1.444 1.444 0 0111 2.757h2c.417 0 .77.146 1.062.438.292.292.438.646.438 1.062v3.5zm-3.5 1.5h2v-5h-2v5zm-2 6c.367 0 .683-.129.95-.387.267-.258.4-.58.4-.963 0-.366-.133-.683-.4-.95a1.298 1.298 0 00-.95-.4c-.367 0-.683.134-.95.4-.267.267-.4.584-.4.95 0 .367.133.684.4.95.267.267.583.4.95.4zm-2.85 2.7h5.7v-.275c0-.266-.07-.508-.212-.725a1.385 1.385 0 00-.588-.5 4.153 4.153 0 00-.988-.325 5.526 5.526 0 00-1.062-.1c-.367 0-.72.034-1.062.1a4.154 4.154 0 00-.988.325c-.25.117-.446.284-.587.5a1.294 1.294 0 00-.213.725v.275z"></path>
      </g>
    </svg>;
}
export default Icon;