import React, { Component } from 'react';
import { func, object, shape, string } from 'prop-types';
import { getEmailTemplates } from 'shared/common.api';
import { UserStateContext } from 'context/user-state-context';
import { DynamicTypeAhead } from '../../../../components/dynamic-type-ahead/dynamic-type-ahead.component';
export class ConditionEmail extends Component {
  static contextType = UserStateContext;
  static propTypes = {
    update: func,
    node: object,
    data: shape({
      template_id: string
    })
  };
  state = {
    templateList: []
  };
  componentDidMount() {
    getEmailTemplates({
      company: this.context.asCompany.id,
      published: true,
      archived: false,
      limit: 200
    }).then(({
      data
    }) => {
      this.setState({
        templateList: data.results
      });
    });
  }
  toggleTemplate = id => {
    const {
      template_id
    } = this.props.data;
    this.props.update({
      template_id: !template_id ? id : template_id.id !== id ? id : null
    });
  };
  render() {
    const {
      templateList
    } = this.state;
    const selectedTemplate = templateList.find(template => template.id === this.props.data.template_id);
    return <div>
        <div className="form-group">
          <label>Template</label>
          <DynamicTypeAhead getItems={getEmailTemplates} getItemsFilters={{
          company: this.context.asCompany.id,
          published: true,
          archived: false
        }} placeholder={selectedTemplate ? `${selectedTemplate.name}` : "Choose Email Template"} displayProperty="name" keyProperty="id" value={selectedTemplate ? selectedTemplate.id : null} onChange={template => {
          this.toggleTemplate(template ? template.id : null);
        }} />
        </div>
      </div>;
  }
}