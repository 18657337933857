import React from 'react';
function Icon() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none" viewBox="0 0 24 25">
      <mask id="mask0_52_2264" style={{
      maskType: 'alpha'
    }} width="24" height="25" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0 0.5H24V24.5H0z"></path>
      </mask>
      <g mask="url(#mask0_52_2264)">
        <path fill="#1D252D" d="M17.4 14a.578.578 0 00.425-.175A.578.578 0 0018 13.4a.578.578 0 00-.175-.425.578.578 0 00-.425-.175h-2.8a.578.578 0 00-.425.175.578.578 0 00-.175.425c0 .167.058.308.175.425A.578.578 0 0014.6 14h2.8zm0 2.7a.578.578 0 00.425-.175A.578.578 0 0018 16.1a.578.578 0 00-.175-.425.578.578 0 00-.425-.175h-2.8a.578.578 0 00-.425.175.578.578 0 00-.175.425c0 .167.058.308.175.425a.578.578 0 00.425.175h2.8zM14.5 8h5.2c.5 0 .925.175 1.275.525.35.35.525.775.525 1.275v10.4c0 .5-.175.925-.525 1.275-.35.35-.775.525-1.275.525H4.3c-.5 0-.925-.175-1.275-.525A1.736 1.736 0 012.5 20.2V9.8c0-.5.175-.925.525-1.275C3.375 8.175 3.8 8 4.3 8h5.2V4.5c0-.417.146-.77.438-1.062A1.444 1.444 0 0111 3h2c.417 0 .77.146 1.062.438.292.291.438.645.438 1.062V8zM11 9.5h2v-5h-2v5zm-2 6c.367 0 .683-.129.95-.387.267-.259.4-.58.4-.963 0-.367-.133-.683-.4-.95a1.298 1.298 0 00-.95-.4c-.367 0-.683.133-.95.4-.267.267-.4.583-.4.95s.133.683.4.95c.267.267.583.4.95.4zm-2.85 2.7h5.7v-.275c0-.267-.07-.508-.212-.725a1.386 1.386 0 00-.588-.5 4.665 4.665 0 00-1-.325 5.342 5.342 0 00-1.05-.1c-.367 0-.717.033-1.05.1a4.665 4.665 0 00-1 .325 1.38 1.38 0 00-.587.5 1.294 1.294 0 00-.213.725v.275zM9.5 9.5H4.3a.289.289 0 00-.212.087A.29.29 0 004 9.8v10.4c0 .083.03.154.088.213a.289.289 0 00.212.087h15.4a.292.292 0 00.213-.087A.292.292 0 0020 20.2V9.8a.292.292 0 00-.087-.213.292.292 0 00-.213-.087h-5.2c0 .45-.154.812-.462 1.087-.309.275-.688.413-1.138.413h-1.8c-.45 0-.829-.138-1.137-.413C9.654 10.312 9.5 9.95 9.5 9.5z"></path>
      </g>
    </svg>;
}
export default Icon;