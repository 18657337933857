import React from 'react';
function Icon(props) {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none" viewBox="0 0 24 25">
      <mask id="mask0_1689_1516" style={{
      maskType: 'alpha'
    }} width="24" height="25" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill={props.fill || '#1D252D'} d="M0 0.5H24V24.5H0z"></path>
      </mask>
      <g fill={props.fill || '#1D252D'} mask="url(#mask0_1689_1516)">
        <path fillRule="evenodd" d="M7.382 11.835a.853.853 0 01-.626.259.853.853 0 01-.626-.26.853.853 0 01-.259-.625c0-.245.086-.454.259-.626a.853.853 0 01.626-.259c.245 0 .453.087.626.259a.853.853 0 01.258.626.853.853 0 01-.258.626zm4 0a.853.853 0 01-.626.259.853.853 0 01-.626-.26.853.853 0 01-.259-.625c0-.245.086-.454.259-.626a.853.853 0 01.626-.259c.245 0 .453.087.626.259a.853.853 0 01.258.626.853.853 0 01-.258.626zm4 0a.853.853 0 01-.626.259.853.853 0 01-.626-.26.853.853 0 01-.259-.625c0-.245.086-.454.259-.626a.853.853 0 01.626-.259c.245 0 .453.087.626.259a.853.853 0 01.258.626.853.853 0 01-.258.626zM2.792 20.71l2.002-2.002h7.65a5.4 5.4 0 017.812-5.978V5.516c0-.505-.175-.933-.525-1.283a1.745 1.745 0 00-1.283-.525H3.064c-.506 0-.933.175-1.283.525-.35.35-.525.778-.525 1.283v14.546c0 .405.185.685.555.841.37.156.697.091.981-.193z" clipRule="evenodd"></path>
        <path d="M14.863 18.704h5.37l-1.299 1.332a.702.702 0 00-.208.518c0 .206.07.379.208.517a.714.714 0 00.53.22.708.708 0 00.514-.21l2.494-2.495a.867.867 0 00.272-.632.867.867 0 00-.271-.633l-2.485-2.485a.71.71 0 00-.527-.22.76.76 0 00-.527.21.716.716 0 00-.236.528.71.71 0 00.22.527l1.324 1.323h-5.38a.726.726 0 00-.534.215.726.726 0 00-.215.535c0 .212.072.39.215.534a.726.726 0 00.535.216z"></path>
      </g>
    </svg>;
}
export default Icon;