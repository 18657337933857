import React, { Component } from 'react';
import { oneOfType, oneOf, node, string, object } from 'prop-types';
import { Scoped, a } from 'kremling';
import styles from './status.styles.scss';
const processNames = {
  draft: 'Draft',
  deleted: 'Deleted',
  paused: 'Paused',
  published: 'Published',
  closed: 'Closed',
  stopped: 'Stopped',
  archived: 'Archived'
};
export class Status extends Component {
  static propTypes = {
    status: oneOf(['draft', 'deleted', 'paused', 'published', 'closed', 'stopped', 'archived']),
    children: node,
    type: oneOf(['success']),
    className: oneOfType([string, object])
  };
  static defaultProps = {
    status: 'published'
  };
  render() {
    const {
      status,
      children,
      type,
      className,
      ...rest
    } = this.props;
    return <Scoped css={styles}>
        <div {...rest} className={a(`journey-status journey-status--${status} ${className}`).m(`journey-status--${type}`, type)}>
          {children || processNames[status]}
        </div>
      </Scoped>;
  }
}