import React from 'react';
function Icon(props) {
  return <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="none" viewBox="0 0 25 25">
      <mask id="mask0_10_866" style={{
      maskType: 'alpha'
    }} width="25" height="25" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0.902 0.141H24.902V24.141H0.902z"></path>
      </mask>
      <g mask="url(#mask0_10_866)">
        <path fill={props.fill || '#616161'} d="M12.902 13.195l3.073 3.073c.139.139.313.21.522.213a.707.707 0 00.532-.213.717.717 0 00.218-.527.717.717 0 00-.218-.527l-3.073-3.073 3.073-3.073a.724.724 0 00.213-.522.707.707 0 00-.213-.532.717.717 0 00-.527-.217.718.718 0 00-.527.217l-3.073 3.073L9.83 8.014a.725.725 0 00-.522-.212.707.707 0 00-.532.212.717.717 0 00-.217.527c0 .207.073.382.217.527l3.073 3.073-3.073 3.073a.725.725 0 00-.212.522.707.707 0 00.212.532c.145.145.32.217.527.217a.717.717 0 00.527-.217l3.073-3.073zm.002 8.446a9.254 9.254 0 01-3.705-.748 9.596 9.596 0 01-3.017-2.03 9.591 9.591 0 01-2.031-3.016 9.245 9.245 0 01-.749-3.704c0-1.314.25-2.55.748-3.705A9.597 9.597 0 016.18 5.42 9.592 9.592 0 019.197 3.39a9.245 9.245 0 013.705-.749c1.314 0 2.549.25 3.705.748a9.596 9.596 0 013.017 2.03 9.593 9.593 0 012.031 3.016 9.245 9.245 0 01.748 3.704c0 1.315-.249 2.55-.748 3.706a9.596 9.596 0 01-2.03 3.017 9.591 9.591 0 01-3.016 2.03 9.247 9.247 0 01-3.704.75zm-.002-1.5c2.234 0 4.125-.775 5.675-2.325 1.55-1.55 2.325-3.441 2.325-5.675 0-2.233-.775-4.125-2.325-5.675-1.55-1.55-3.441-2.325-5.675-2.325-2.233 0-4.125.775-5.675 2.325-1.55 1.55-2.325 3.442-2.325 5.675 0 2.233.775 4.125 2.325 5.675 1.55 1.55 3.442 2.325 5.675 2.325z"></path>
      </g>
    </svg>;
}
export default Icon;