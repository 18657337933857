import React from 'react';
function Icon(props) {
  return <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" fill="none" viewBox="0 0 21 20">
      <mask id="mask0_38_2555" style={{
      maskType: 'alpha'
    }} width="21" height="20" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0.73 0H20.73V20H0.73z"></path>
      </mask>
      <g mask="url(#mask0_38_2555)">
        <path fill={props.fill || '#1D252D'} d="M5.012 15.732h1.093l7.529-7.529-1.093-1.092-7.529 7.528v1.093zM16.452 7.3l-3-3.016.941-.941c.273-.272.591-.409.955-.409.365 0 .683.137.956.409L17.4 4.441c.259.258.386.58.38.963a1.336 1.336 0 01-.396.963l-.932.933zm-.924.916l-8.85 8.85h-3v-3l8.85-8.85 3 3z"></path>
      </g>
    </svg>;
}
export default Icon;