import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
const ExpandMore = styled(props => {
  const {
    expand,
    ...other
  } = props;
  return <IconButton {...other} />;
})(({
  theme,
  expand
}) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest
  })
}));
const CardComponent = ({
  title,
  subheader,
  imgUrl,
  actions,
  cardContent,
  cardActions,
  onClick
}) => {
  return <Card sx={{
    maxWidth: 345
  }} onClick={onClick}>
      <CardHeader title={title} subheader={subheader} action={actions} />
      <CardMedia component="img" height="168" image={imgUrl} />
      <CardContent>{cardContent}</CardContent>
      <CardActions>{cardActions}</CardActions>
    </Card>;
};
export default CardComponent;