import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import 'rc-tooltip/assets/bootstrap_white.css';
import './styles/main.scss';
import Root from './root.component';
Sentry.init({
  dsn: SENTRY_DSN,
  release: VERSION,
  beforeSend: event => {
    if (event?.extra?.__serialized__?.message === 'canceled') {
      // This is a weird unexplained error message.  I'm not sure what it means.
      // It might be bad to surpress it.  Let Chris know if you have any thoughts
      return null;
    }
    if (event.exception && window.CIErrorBoundary) {
      window.CIErrorBoundary(event);
    }
    return event;
  }
});
(function (apiKey) {
  (function (p, e, n, d, o) {
    var v, w, x, y, z;
    o = p[d] = p[d] || {};
    o._q = o._q || [];
    v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
    for (w = 0, x = v.length; w < x; ++w) (function (m) {
      o[m] = o[m] || function () {
        o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
      };
    })(v[w]);
    y = e.createElement(n);
    y.async = !0;
    y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js';
    z = e.getElementsByTagName(n)[0];
    z.parentNode.insertBefore(y, z);
  })(window, document, 'script', 'pendo');
})(PENDO_API);

// may be unecessary
if ('serviceWorker' in navigator) {
  window.addEventListener('load', function () {
    navigator.serviceWorker.register('/firebase-messaging-sw.js').then(function (registration) {
      // console.log('Firebase service worker registered:', registration);
      // You can now use Firebase messaging features.
    }).catch(function (error) {
      console.log('Error registering Firebase service worker:', error);
    });
  });
}
ReactDOM.render(<Root />, document.querySelector('#app'));