import React, { useState, useEffect, useContext } from 'react';
import { UserStateContext } from 'context/user-state-context';
import { addGoogleBusinessAccount, deleteCredential as deleteCredentialsAPI, resyncCredential } from './integrations.resource';
import { Button, snackbarService, ModalDialog, ModalCompanySelect, DynamicTypeAhead } from 'components';
import { getLocations, getLocationRefs, editLocationRef } from 'shared/common.api';
import EnhancedTable from 'components/mui/enhanced-table/enhanced-table.component';
import { Box, Checkbox, Stack } from '@mui/material';
export const IntegrationGoogleBusiness = props => {
  const {
    hasMultiAccess,
    asCompany,
    hasPermission
  } = useContext(UserStateContext);
  const [installId, setInstallId] = useState();
  const [data, setData] = useState([]);
  const [deleteCredential, setDeleteCredential] = useState();
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [mapLocation, setMapLocation] = useState();
  const [filters, setFilters] = useState([]);
  const editCredential = credential => {
    setFilters({
      entity_source: 'google_business',
      entity_data__credential_id: credential.id
    });
    setShowLocationModal(true);
  };
  const headCells = [{
    key: 'ref',
    label: 'Google Location',
    noSort: true,
    displayComponent: ({
      data
    }) => <Stack>
          <Box>
            <strong>{data.name}</strong>
          </Box>
          <Box>
            {data.address1}, {data.city}, {data.state}, {data.zip_code}
          </Box>
        </Stack>
  }, {
    key: 'location',
    label: 'Matched Location',
    noSort: true,
    displayComponent: ({
      data
    }) => {
      // TODO on click allow them to edit it...
      if (!data.location) {
        return <i>No Match</i>;
      }
      return <Stack>
            <Box>
              <strong>{data.location_name}</strong>
            </Box>
            <Box>
              {data.location_address1}, {data.location_city}, {data.location_state}, {data.location_zip_code}
            </Box>
          </Stack>;
    }
  }, {
    key: 'is_active',
    label: 'Sync Reviews',
    noSort: true,
    displayComponent: ({
      data: row
    }) => <Checkbox color="primary" disabled={!row.location} checked={row.is_active} onChange={() => {
      // Call API to update is_active
      editLocationRef(row.id, {
        is_active: !row.is_active
      });
      setData(data => {
        // Update UI without fetching from API.
        const newData = JSON.parse(JSON.stringify(data));
        const newRow = newData.find(r => r.id === row.id);
        newRow.is_active = !newRow.is_active;
        return newData;
      });
    }} sx={{
      color: '#1D252D',
      '&.Mui-checked': {
        color: '#53A6D6'
      },
      '& .MuiSvgIcon-root': {
        fontSize: 22
      }
    }} />
  }];
  const handleRowClick = (data, column) => {
    // TODO if they cilck on the location match column then open up the ability to edit

    if (column === 'is_active' && !data.location) {
      snackbarService.popup({
        type: 'error',
        message: 'The Google Business location needs to be mapped first.'
      });
    } else if (column === 'location') {
      setMapLocation(data);
    }
  };
  const finallyAddAccount = (installId, companyId) => {
    snackbarService.popup({
      type: 'success',
      message: "Adding your account. Please don't leave this page. Locations and reviews will start syncing momentarily. Once done, you will be prompted to exclude locations from the sync."
    });
    return addGoogleBusinessAccount(installId, companyId).then(result => {
      setLocationsList(result.data.locations);
      setToolModal('review_filter_maker');
    }).then(() => props.reload()).catch(err => {
      const key = Object.keys(err.response.data)[0];
      snackbarService.popup({
        type: 'error',
        message: err.response.data[key][0]
      });
    });
  };
  const addAccount = installId => {
    if (hasMultiAccess()) {
      setInstallId(installId);
    } else {
      finallyAddAccount(installId, asCompany.id);
    }
  };
  useEffect(() => {
    const {
      match,
      location
    } = props;
    if (match.params.redirect === 'google-business-authorize') {
      const params = new URLSearchParams(location.search);
      if (params.get('error')) {
        snackbarService.popup({
          type: 'error',
          message: 'Unable to link Google My Business account. Please try again. If the problem persists contact support.'
        });
      } else {
        const installId = params.get('install_id');
        addAccount(installId);
      }
    }
  }, []);
  const resync = id => {
    resyncCredential(id).then(() => {
      snackbarService.popup({
        type: 'success',
        message: 'Locations and reviews will start syncing momentarily'
      });
    }).catch(() => {
      snackbarService.popup({
        type: 'error',
        message: 'Unknown error occured trying ot resync credential'
      });
    });
  };
  const removeCredentials = credentialsId => {
    return deleteCredentialsAPI(credentialsId).then(() => snackbarService.popup({
      type: 'success',
      message: 'Successfully deleted credentials'
    })).then(() => props.reload()).catch(() => snackbarService.popup({
      type: 'error',
      message: 'Unknown error to trying delete credentials. Please try again.'
    }));
  };
  return <div className="integration mb-5">
      <div className="integration__header">
        <div>Google Business</div>
        {hasPermission('integration.add_credential') && <Button className="btn" actionType="primary" onClick={() => window.open(`${API_URL}/google-business/account/request`)}>
            Add Account
          </Button>}
      </div>
      <div className="integration__body">
        {props.credentials.map(credential => <div className="integration-item" key={credential.id}>
            <div className="integration-item__inner">
              <div className="integration-title">{credential.identifier}</div>

              <div className="integration-actions">
                {hasPermission('integration.change_credential') && <Button onClick={() => resync(credential.id)} actionType="flat" icon="fa-regular-sync-alt" />}
                {hasPermission('integration.change_credential') && <Button onClick={() => editCredential(credential)} actionType="flat" icon="fa-regular-pencil-alt"></Button>}
                {hasPermission('integration.delete_credential') && <Button actionType="flat" icon="fa-regular-trash" onClick={() => setDeleteCredential(credential)} />}
              </div>
            </div>
          </div>)}
      </div>
      <ModalCompanySelect open={!!installId} onClose={() => setInstallId(null)} onSubmit={companyId => finallyAddAccount(installId, companyId)} onCancel={() => snackbarService.popup({
      type: 'error',
      message: 'You must select a company to link your account'
    })}>
        <p>Select which company to link this Google Business account.</p>
      </ModalCompanySelect>

      <ModalDialog open={showLocationModal} title="Reviews Locations" cancelText="Close" onClose={() => setShowLocationModal(false)} allowBackdropClick type="scroll" size="xl">
        <div className="w-100">
          <p>
            {' '}
            Map your Google Business locations with your POS locations. Also enable and disable the locations you wish to sync reviews.
          </p>
          <div className="modal__body">
            <EnhancedTable type="Locations" headCells={headCells} api={getLocationRefs} defaultSort="-created_when" handleRowClick={handleRowClick} dataState={[data, setData]} filterState={[filters, setFilters]} />
          </div>
        </div>
      </ModalDialog>

      <ModalDialog open={!!mapLocation} title="Assign Location" submitText="Assign" onSubmit={() => {
      editLocationRef(mapLocation.id, {
        location: mapLocation.location
      });
      setData(data => {
        // Update UI without fetching from API.
        const newData = JSON.parse(JSON.stringify(data));
        const newRow = newData.find(r => r.id === mapLocation.id);
        newRow.location = mapLocation.location;
        newRow.location_name = mapLocation.location_name;
        newRow.location_address1 = mapLocation.location_address1;
        newRow.location_address2 = mapLocation.location_address2;
        newRow.location_city = mapLocation.location_city;
        newRow.location_state = mapLocation.location_state;
        newRow.location_zip_code = mapLocation.location_zip_code;
        return newData;
      });
    }} onClose={() => setMapLocation()} allowBackdropClick level={2}>
        <p>Map the following Google Business:</p>
        <p>
          <strong>{mapLocation?.name}</strong>
          <br />
          {mapLocation?.address1}, {mapLocation?.city}, {mapLocation?.state}, {mapLocation?.zip_code}
        </p>

        <p>To the following Location:</p>
        <DynamicTypeAhead getItems={getLocations} getItemsFilters={{
        company: asCompany.id
      }} placeholder="Choose" displayProperty="name" displayComponent={data => <Stack style={{
        borderBottom: '1px solid #cccccc',
        paddingBottom: '5px'
      }}>
              <Box>
                <strong>{data?.name}</strong>
              </Box>
              <Box>
                {data?.address1}, {data?.city}, {data?.state}, {data?.zip_code}
              </Box>
            </Stack>} keyProperty="id" value={mapLocation?.location} onChange={val => {
        setMapLocation(mapLocation => ({
          ...mapLocation,
          location: val?.id || null,
          location_name: val?.name,
          location_address1: val?.address1,
          location_address2: val?.address2,
          location_city: val?.city,
          location_state: val?.state,
          location_zip_code: val?.zip_code
        }));
      }} />
      </ModalDialog>

      <ModalDialog open={!!deleteCredential && deleteCredential.id} title="Remove Credential" submitText="Remove" onSubmit={() => removeCredentials(deleteCredential.id)} onClose={() => setDeleteCredential(null)} allowBackdropClick>
        <p>
          <strong>{deleteCredential && deleteCredential.identifier}</strong>
        </p>
        <p>Are you sure you want to remove this credential?</p>
      </ModalDialog>
    </div>;
};