import React from 'react';
function Icon() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <mask id="mask0_22_5218" style={{
      maskType: 'alpha'
    }} width="24" height="24" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0 0H24V24H0z"></path>
      </mask>
      <g fill="#1D252D" mask="url(#mask0_22_5218)">
        <rect width="5.744" height="1.492" x="12.502" y="11.255" rx="0.746"></rect>
        <rect width="5.744" height="1.492" x="8.251" y="15.182" rx="0.746"></rect>
        <path d="M9.25 12.888a.826.826 0 01-.624-.263.858.858 0 01-.25-.612c0-.25.083-.462.25-.637a.828.828 0 01.625-.262c.25 0 .458.087.625.261.166.176.25.388.25.638a.859.859 0 01-.25.612.826.826 0 01-.625.263zm7.999 3.924a.828.828 0 01-.625-.262.862.862 0 01-.25-.613c0-.25.084-.462.25-.636a.826.826 0 01.625-.264c.25 0 .458.088.625.263.166.175.25.387.25.637a.861.861 0 01-.25.613.828.828 0 01-.625.262zm-10.697 3.5c-.5 0-.925-.176-1.275-.526a1.735 1.735 0 01-.525-1.274V5.115c0-.5.175-.925.525-1.275.35-.35.775-.524 1.275-.524h1.4v-1.35c0-.217.07-.4.211-.55a.71.71 0 01.538-.225c.233 0 .42.075.562.225a.77.77 0 01.213.55v1.35h7.573V1.94c0-.2.075-.375.225-.525a.72.72 0 01.525-.225c.216 0 .395.075.537.225a.74.74 0 01.213.525v1.375h1.4c.5 0 .924.175 1.274.524.35.35.525.775.525 1.275v13.397c0 .5-.175.924-.525 1.274-.35.35-.775.525-1.275.525H6.552zm0-1.5h13.396a.292.292 0 00.213-.087.292.292 0 00.087-.213V9.114H6.252v9.398c0 .083.029.154.088.213a.288.288 0 00.212.087zm-.3-11.198h13.996V5.115a.288.288 0 00-.087-.212.29.29 0 00-.213-.088H6.552a.287.287 0 00-.212.088.287.287 0 00-.088.212v2.5z"></path>
        <path d="M4.06 22.809c-.505 0-.933-.175-1.283-.525A1.745 1.745 0 012.252 21V8.867c0-.213.072-.391.216-.535a.726.726 0 01.534-.215c.213 0 .391.071.535.215a.726.726 0 01.215.535V21a.3.3 0 00.087.221.3.3 0 00.22.087h12.135c.213 0 .391.072.535.215a.726.726 0 01.215.535.726.726 0 01-.215.534.726.726 0 01-.535.216H4.06z"></path>
      </g>
    </svg>;
}
export default Icon;