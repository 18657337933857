import React, { useState } from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody, Checkbox } from '@mui/material';
import { ModalDialog } from 'components';
export const SelectTileModal = ({
  onClose,
  onSubmit,
  open,
  node,
  param
}) => {
  let params;
  if (node.componentParams) {
    params = node.componentParams;
  } else {
    params = Object.keys(node.parameters || {}).map(key => ({
      key,
      label: key,
      // TODO maybe try and update the key to be better looking?
      type: 'unknown'
    }));
  }
  const [selectedTiles, setSelectedTiles] = useState(() => {
    const temp = Object.keys(node.parameters).reduce((acc, key) => {
      if (node.parameters[key] === `##${param.key}##`) {
        return [...acc, key];
      }
      return acc;
    }, []);
    return temp;
  });
  const toggle = key => {
    if (selectedTiles.includes(key)) {
      setSelectedTiles(selectedTiles.filter(k => k !== key));
    } else {
      setSelectedTiles([...selectedTiles, key]);
    }
  };
  return <ModalDialog size="lg" open={open} title="Select Parameters" cancelText="Close" onClose={onClose} onSubmit={() => onSubmit(selectedTiles)} allowBackdropClick type="scroll">
      {params.length === 0 && <div className="mt-3">
          <em>This node doesn't have any available parameters</em>
        </div>}

      {params.length > 0 && <Table>
          <colgroup>
            <col width="40%" />
            <col width="40%" />
            <col width="20%" />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell>Field</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {params.map(param => <TableRow key={param.key}>
                <TableCell>{param.label}</TableCell>
                <TableCell>{param.type}</TableCell>
                <TableCell><Checkbox checked={selectedTiles.includes(param.key)} onChange={() => toggle(param.key)} /></TableCell>
              </TableRow>)}
          </TableBody>
        </Table>}
    </ModalDialog>;
};