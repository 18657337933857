import React, { useEffect, useState, useContext } from 'react';
import { Loader, PageHeaderMui, Icon } from 'components';
import { UserStateContext } from 'context/user-state-context';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Paper, Stack, Tooltip, Typography } from '@mui/material';
import { ActionItemGettingStartedIcon, ArrowForwardIcon, BlockIcon, CheckCircleFilledIcon, CheckCircleIcon, CloseIcon, FiberManualRecordIcon, InformationIcon, PendingIcon, PriorityIcon, RefreshIcon, RestoreIcon, WarningRedFilledIcon } from 'components/mui';
import { history } from '../../root.component';
import { getDomains, patchCompany, refreshPhoneRegistration, verifyDomain } from 'shared/common.api';
import { createInstall } from '../integrations/integrations.resource';
import { VerifyDomainModal } from '../domains/verify-domain-modal.component';
export const ActionItems = () => {
  const [isLoading, setIsLoading] = useState(true);
  const {
    asCompany,
    isUserEnrolledInLaunchDarklyBeta,
    actionItems,
    refreshActionItems,
    hasIntegration,
    setAsCompany
  } = useContext(UserStateContext);
  const [isHover, setIsHover] = useState('');
  const [verifyModal, setVerifyModal] = useState(false);
  const [verifyModalDomain, setVerifyModalDomain] = useState();
  const [showConfirmSkipDialog, setShowConfirmSkipDialog] = useState(null);
  useEffect(() => {
    if (!!Object.keys(actionItems).length) {
      setIsLoading(false);
    }
  }, [actionItems]);
  const getNumberActionItemsCategoryComplete = category => {
    let count = 0;
    actionItems[category]?.forEach(item => {
      if (item?.status == 'done') {
        count++;
      }
    });
    return count;
  };
  const categoryHasErrorItems = category => {
    for (let i = 0; i < actionItems[category]?.length; i++) {
      if (actionItems[category][i]?.status == 'error') {
        return true;
      }
    }
    return false;
  };
  const getKeyIndex = (category, key) => {
    for (let i = 0; i < actionItems[category]?.length; i++) {
      if (actionItems[category][i]?.key == key) {
        return i;
      }
    }
    return null;
  };
  const checkDomain = domain => {
    return verifyDomain(domain.id).then(({
      data
    }) => {
      if (data.status === 'pending') {
        setVerifyModalDomain({
          ...data
        });
        toasterService.error('Unable to verify DNS settings. Wait a few minutes and try again.');
        throw false;
      }
      refreshActionItems();
    });
  };
  const handleSkipActionItem = () => {
    const newEntityData = {
      ...asCompany?.entity_data
    };
    const newActionItemIgnores = newEntityData['action_item_ignores'] || [];
    newActionItemIgnores.push(showConfirmSkipDialog['category'] + '-' + showConfirmSkipDialog['key']);
    newEntityData['action_item_ignores'] = newActionItemIgnores;
    patchCompany(asCompany.id, {
      entity_data: newEntityData
    }).then(comp => {
      if (asCompany.id === comp?.data?.id) {
        setAsCompany(comp.data);
      }
      refreshActionItems();
      setShowConfirmSkipDialog(null);
    });
  };
  const handleRemoveIgnore = (category, key) => {
    const newEntityData = {
      ...asCompany?.entity_data
    };
    const newActionItems = (newEntityData['action_item_ignores'] || []).filter(item => item != category + '-' + key);
    newEntityData['action_item_ignores'] = newActionItems;
    patchCompany(asCompany.id, {
      entity_data: newEntityData
    }).then(comp => {
      if (asCompany.id === comp?.data?.id) {
        setAsCompany(comp.data);
      }
      refreshActionItems();
    });
  };
  const gettingStartedData = [{
    key: 'data_integration',
    link: '/integrations',
    title: 'Data integration',
    description: 'Import and sync your customer data with our system',
    helperLink: 'https://support.cinch.io/knowledge/current-cinch-integrations-including-instructions',
    displayComponent: ({
      data: data
    }) => <Stack direction={'row'} spacing="8px">
          {isHover === 'getting_started_step_' + data.index && actionItems['getting_started_steps'][getKeyIndex('getting_started_steps', 'data_integration')]?.status != 'ignored' && actionItems['getting_started_steps'][getKeyIndex('getting_started_steps', 'data_integration')]?.status != 'done' && <Tooltip title={'Skip'}>
                <IconButton onClick={e => {
          e.stopPropagation();
          setShowConfirmSkipDialog({
            category: 'getting_started_steps',
            key: 'data_integration'
          });
        }}>
                  <BlockIcon />
                </IconButton>
              </Tooltip>}
          {actionItems['getting_started_steps'][getKeyIndex('getting_started_steps', 'data_integration')]?.status == 'ignored' ? <Tooltip title="Unskip">
              <IconButton onClick={e => {
          e.stopPropagation();
          handleRemoveIgnore('getting_started_steps', 'data_integration');
        }}>
                <RestoreIcon />
              </IconButton>
            </Tooltip> : <Tooltip title={actionItems['getting_started_steps'][getKeyIndex('getting_started_steps', 'data_integration')]?.status == 'not_started' ? 'Get Started' : 'View details'}>
              <IconButton onClick={e => {
          if (actionItems['getting_started_steps'][getKeyIndex('getting_started_steps', 'data_integration')]?.status == 'not_started') {
            history.push('/phone-numbers', {
              openNew: true
            });
          } else {
            history.push('/phone-numbers');
          }
        }}>
                <ArrowForwardIcon />
              </IconButton>
            </Tooltip>}
        </Stack>
  }, {
    key: '10dlc',
    link: '/phone-numbers/',
    title: '10DLC registration',
    description: 'Set up phone numbers for text message marketing',
    helperLink: 'https://support.cinch.io/knowledge/understanding-10dlc-registration',
    displayComponent: ({
      data: data
    }) => <Stack direction={'row'} spacing="8px">
          {isHover === 'getting_started_step_' + data.index && actionItems['getting_started_steps'][getKeyIndex('getting_started_steps', '10dlc')]?.status != 'ignored' && actionItems['getting_started_steps'][getKeyIndex('getting_started_steps', '10dlc')]?.status != 'done' && <Tooltip title={'Skip'}>
                <IconButton onClick={e => {
          e.stopPropagation();
          setShowConfirmSkipDialog({
            category: 'getting_started_steps',
            key: '10dlc'
          });
        }}>
                  <BlockIcon />
                </IconButton>
              </Tooltip>}

          {isHover === 'getting_started_step_' + data.index && actionItems['getting_started_steps'][getKeyIndex('getting_started_steps', '10dlc')]?.status != 'not_started' && <Tooltip title={'Refresh'}>
                <IconButton onClick={e => {
          setIsLoading(true);
          refreshPhoneRegistration(asCompany.id).then(({
            data
          }) => {
            refreshActionItems();
          });
        }}>
                  <RefreshIcon />
                </IconButton>
              </Tooltip>}
          {actionItems['getting_started_steps'][getKeyIndex('getting_started_steps', '10dlc')]?.status == 'ignored' ? <Tooltip title="Unskip">
              <IconButton onClick={e => {
          e.stopPropagation();
          handleRemoveIgnore('getting_started_steps', '10dlc');
        }}>
                <RestoreIcon />
              </IconButton>
            </Tooltip> : <Tooltip title={actionItems['getting_started_steps'][getKeyIndex('getting_started_steps', '10dlc')]?.status == 'not_started' ? 'Get Started' : 'View details'}>
              <IconButton onClick={e => {
          if (actionItems['getting_started_steps'][getKeyIndex('getting_started_steps', '10dlc')]?.status == 'not_started') {
            history.push('/phone-numbers', {
              openNew: true
            });
          } else {
            history.push('/phone-numbers');
          }
        }}>
                <ArrowForwardIcon />
              </IconButton>
            </Tooltip>}
        </Stack>
  }, {
    key: 'domain_verification',
    link: '/domains',
    title: 'Domain Verification',
    description: "Add and verify your company's email domain",
    helperLink: 'https://support.cinch.io/knowledge/why-you-should-verify-your-domain',
    displayComponent: ({
      data: data
    }) => <Stack direction={'row'} spacing="8px">
          {isHover === 'getting_started_step_' + data.index && actionItems['getting_started_steps'][getKeyIndex('getting_started_steps', 'domain_verification')]?.status != 'ignored' && actionItems['getting_started_steps'][getKeyIndex('getting_started_steps', 'domain_verification')]?.status != 'done' && <Tooltip title={'Skip'}>
                <IconButton onClick={e => {
          e.stopPropagation();
          setShowConfirmSkipDialog({
            category: 'getting_started_steps',
            key: 'domain_verification'
          });
        }}>
                  <BlockIcon />
                </IconButton>
              </Tooltip>}

          {isHover === 'getting_started_step_' + data.index && actionItems['getting_started_steps'][getKeyIndex('getting_started_steps', 'domain_verification')]?.has_domain && <Tooltip title={'Refresh'}>
                <IconButton onClick={e => {
          setIsLoading(true);
          refreshActionItems();
        }}>
                  <RefreshIcon />
                </IconButton>
              </Tooltip>}
          {actionItems['getting_started_steps'][getKeyIndex('getting_started_steps', 'domain_verification')]?.status == 'ignored' ? <Tooltip title="Unskip">
              <IconButton onClick={e => {
          e.stopPropagation();
          handleRemoveIgnore('getting_started_steps', 'domain_verification');
        }}>
                <RestoreIcon />
              </IconButton>
            </Tooltip> : <Tooltip title={actionItems['getting_started_steps'][getKeyIndex('getting_started_steps', 'domain_verification')]?.status == 'not_started' ? 'Get Started' : 'View details'}>
              <IconButton onClick={e => {
          history.push('/domains');
        }}>
                <ArrowForwardIcon />
              </IconButton>
            </Tooltip>}
        </Stack>
  }, {
    key: 'google_business_profile',
    link: '/integrations',
    title: 'Google Business Profile',
    description: 'Connect your account for Google Reviews',
    helperLink: 'https://support.cinch.io/knowledge/how-to-connect-google-business',
    displayComponent: ({
      data: data
    }) => <Stack direction={'row'} spacing="8px">
          {isHover === 'getting_started_step_' + data.index && actionItems['getting_started_steps'][getKeyIndex('getting_started_steps', 'google_business_profile')]?.status != 'ignored' && actionItems['getting_started_steps'][getKeyIndex('getting_started_steps', 'google_business_profile')]?.status != 'done' && <Tooltip title={'Skip'}>
                <IconButton onClick={e => {
          e.stopPropagation();
          setShowConfirmSkipDialog({
            category: 'getting_started_steps',
            key: 'google_business_profile'
          });
        }}>
                  <BlockIcon />
                </IconButton>
              </Tooltip>}
          {actionItems['getting_started_steps'][getKeyIndex('getting_started_steps', 'google_business_profile')]?.status == 'ignored' ? <Tooltip title="Unskip">
              <IconButton onClick={e => {
          e.stopPropagation();
          handleRemoveIgnore('getting_started_steps', 'google_business_profile');
        }}>
                <RestoreIcon />
              </IconButton>
            </Tooltip> : <Tooltip title={actionItems['getting_started_steps'][getKeyIndex('getting_started_steps', 'google_business_profile')]?.status == 'not_started' ? 'Get Started' : 'View details'}>
              <IconButton onClick={e => {
          if (hasIntegration('google_business')) {
            window.open(`${API_URL}/google-business/account/request`);
          } else {
            createInstall({
              integration: 'google_business',
              company: asCompany.id
            }).then(() => {
              window.open(`${API_URL}/google-business/account/request`);
            });
          }
        }}>
                <ArrowForwardIcon />
              </IconButton>
            </Tooltip>}
        </Stack>
  }];
  if (isLoading) return <Loader overlay />;
  return <>
      <div className="wrapper">
        <PageHeaderMui type={isUserEnrolledInLaunchDarklyBeta ? 'Action Items (Beta)' : 'Action Items'} icon={<PriorityIcon size="lg" />} />
        <div className="mui-wrapper">
          <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center'
        }}>
            <Stack sx={{
            p: '20px'
          }} spacing={'20px'}>
              <Paper sx={{
              borderRadius: '16px',
              width: '1040px'
            }}>
                <Stack spacing={'20px'} sx={{
                p: '20px'
              }}>
                  <Stack spacing={'12px'}>
                    <Stack direction={'row'} spacing={'20px'}>
                      <Box sx={{
                      display: 'flex',
                      alignItems: 'center'
                    }}>
                        <Typography sx={{
                        display: 'flex',
                        alignItems: 'center',
                        color: '#3898D9',
                        fontSize: '24px'
                      }}>
                          Getting started
                        </Typography>
                      </Box>
                      <Divider orientation="vertical" flexItem />
                      <Stack direction={'row'} spacing={'10px'}>
                        <CheckCircleFilledIcon fill={getNumberActionItemsCategoryComplete('getting_started_steps') == actionItems['getting_started_steps']?.length ? '#3EB87B' : '#1D252D4D'} />
                        <Box sx={{
                        display: 'flex',
                        alignItems: 'center'
                      }}>
                          <Typography sx={{
                          display: 'flex',
                          alignItems: 'center'
                        }}>
                            {getNumberActionItemsCategoryComplete('getting_started_steps') + ' of ' + actionItems['getting_started_steps']?.length}
                          </Typography>
                        </Box>
                      </Stack>
                      {categoryHasErrorItems('getting_started_steps') && <Divider orientation="vertical" flexItem />}
                      {categoryHasErrorItems('getting_started_steps') && <Box>
                          <WarningRedFilledIcon />
                        </Box>}
                    </Stack>
                    <Stack direction={'row'}>
                      <Box sx={{
                      width: '50%'
                    }}>
                        <Typography sx={{
                        color: '#1D252D',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: '300',
                        lineHeight: '24px'
                      }}>
                          Welcome to Cinch! We're excited to help you get started with creating amazing marketing campaigns. Before you dive
                          in, we'll just need you to get a few things set up first. Click any of the modules below to get started!
                        </Typography>
                      </Box>
                      <Box sx={{
                      width: '50%'
                    }}>
                        <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}>
                          <ActionItemGettingStartedIcon />
                        </Box>
                      </Box>
                    </Stack>
                  </Stack>

                  {gettingStartedData.map((item, index) => getKeyIndex('getting_started_steps', item.key) != null && <Box key={item.key + index} sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  borderRadius: '14px',
                  border: '1px solid #E2E2E2',
                  boxShadow: isHover === 'getting_started_step_' + index ? '0px 0px 12px 0px rgba(0, 0, 0, 0.1)' : 'none',
                  cursor: actionItems['getting_started_steps'][getKeyIndex('getting_started_steps', item.key)]?.status == 'not_started' ? 'pointer' : 'default'
                }} onClick={e => {
                  if (actionItems['getting_started_steps'][getKeyIndex('getting_started_steps', item.key)]?.key == 'google_business_profile' && hasIntegration('google_business')) {
                    window.open(`${API_URL}/google-business/account/request`);
                  } else if (actionItems['getting_started_steps'][getKeyIndex('getting_started_steps', item.key)]?.key == 'google_business_profile' && !hasIntegration('google_business')) {
                    createInstall({
                      integration: 'google_business',
                      company: asCompany.id
                    }).then(() => {
                      window.open(`${API_URL}/google-business/account/request`);
                    });
                  } else if (actionItems['getting_started_steps'][getKeyIndex('getting_started_steps', item.key)]?.status == 'not_started') {
                    history.push(item.link, {
                      openNew: true
                    });
                  }
                }} onMouseEnter={e => {
                  setIsHover('getting_started_step_' + index);
                }} onMouseLeave={e => {
                  setIsHover('');
                }}>
                          <Stack sx={{
                    py: actionItems['getting_started_steps'][getKeyIndex('getting_started_steps', item.key)]?.status == 'done' ? '32px' : '24px',
                    px: '20px'
                  }} direction={'row'} spacing={'10px'}>
                            <Box>
                              {actionItems['getting_started_steps'][getKeyIndex('getting_started_steps', item.key)]?.status == 'pending' ? <FiberManualRecordIcon size="lg" fill="#FFB82B" /> : actionItems['getting_started_steps'][getKeyIndex('getting_started_steps', item.key)]?.status == 'done' ? <CheckCircleIcon fill="#3EB87B" /> : actionItems['getting_started_steps'][getKeyIndex('getting_started_steps', item.key)]?.status == 'error' ? <FiberManualRecordIcon size="lg" fill="#F30F0F" /> : <FiberManualRecordIcon size="lg" fill="#1D252D4D" />}
                            </Box>
                            <Box sx={{
                      display: 'flex',
                      alignItems: 'center'
                    }}>
                              <Typography sx={{
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '24px'
                      }}> {item.title} </Typography>
                            </Box>

                            <Divider orientation="vertical" flexItem />

                            <Box sx={{
                      display: 'flex',
                      alignItems: 'center'
                    }}>
                              <Typography sx={{
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '20px'
                      }}>{item.description}</Typography>
                            </Box>
                            {item?.helperLink && <Box>
                                <IconButton onClick={e => {
                        e.stopPropagation();
                        window.open(item.helperLink);
                      }}>
                                  <InformationIcon fill="#3898D9" size="sm" />
                                </IconButton>
                              </Box>}
                          </Stack>

                          <Box sx={{
                    py: '22px',
                    px: '20px'
                  }}>
                            {actionItems['getting_started_steps'][getKeyIndex('getting_started_steps', item.key)]?.status == 'done' ? <Box sx={{
                      borderRadius: '8px',
                      py: '6px',
                      px: '8px',
                      backgroundColor: '#C5EAD7'
                    }}>
                                <Stack direction={'row'} spacing="8px">
                                  <CheckCircleIcon fill="#3EB87B" />
                                  <Typography sx={{
                          color: '#3EB87B',
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '14px',
                          fontWeight: '700'
                        }}>
                                    Done
                                  </Typography>
                                </Stack>
                              </Box> : actionItems['getting_started_steps'][getKeyIndex('getting_started_steps', item.key)]?.status == 'not_started' || isHover === 'getting_started_step_' + index ? React.createElement(item.displayComponent, {
                      data: {
                        item: item,
                        index: index
                      }
                    }) : actionItems['getting_started_steps'][getKeyIndex('getting_started_steps', item.key)]?.status == 'error' ? <Box sx={{
                      borderRadius: '8px',
                      py: '6px',
                      px: '8px',
                      backgroundColor: '#F30F0F'
                    }}>
                                <Stack direction={'row'} spacing="8px">
                                  <WarningRedFilledIcon fill="#FFFFFF" />
                                  <Typography sx={{
                          color: '#FFFFFF',
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '14px',
                          fontWeight: '700'
                        }}>
                                    Failed
                                  </Typography>
                                </Stack>
                              </Box> : actionItems['getting_started_steps'][getKeyIndex('getting_started_steps', item.key)]?.status == 'ignored' ? <Box sx={{
                      borderRadius: '8px',
                      py: '6px',
                      px: '8px',
                      backgroundColor: '#F3F3F4'
                    }}>
                                <Stack direction={'row'} spacing="8px">
                                  <BlockIcon fill="#616161" />
                                  <Typography sx={{
                          color: '#616161',
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '14px',
                          fontWeight: '700'
                        }}>
                                    Skipped
                                  </Typography>
                                </Stack>
                              </Box> : <Box sx={{
                      borderRadius: '8px',
                      cursor: 'pointer',
                      py: '6px',
                      px: '8px',
                      backgroundColor: '#FFEABF'
                    }}>
                                <Stack direction={'row'} spacing="8px">
                                  <PendingIcon fill="#FFB82B" />
                                  <Typography sx={{
                          color: '#FFB82B',
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '14px',
                          fontWeight: '700'
                        }}>
                                    Pending
                                  </Typography>
                                </Stack>
                              </Box>}
                          </Box>
                        </Box>)}
                </Stack>
              </Paper>

              {/*      <Paper sx={{ borderRadius: '16px', width: '1040px' }}>
               <Stack spacing={'20px'} sx={{ p: '20px' }}>
                <Box>Journey walkthrough guide</Box>
               </Stack>
               </Paper> */}
            </Stack>
          </Box>
        </div>
      </div>

      <VerifyDomainModal verifyModal={verifyModal} verifyModalDomain={verifyModalDomain} onSubmit={() => checkDomain(verifyModalDomain)} onClose={() => {
      refreshActionItems();
      setVerifyModal(false);
    }} />

      <Dialog scroll="paper" PaperProps={{
      style: {
        borderRadius: '28px'
      }
    }} open={!!showConfirmSkipDialog} onClose={(e, r) => {
      setShowConfirmSkipDialog(null);
    }}>
        <DialogTitle sx={{
        m: 0,
        p: 2,
        fontSize: 'medium'
      }}>
          <Stack spacing={'20px'}>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Stack direction="row" spacing={'10px'}>
                <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                  <Typography sx={{
                  fontWeight: '300',
                  lineHeight: '30px',
                  fontSize: '24px',
                  display: 'flex',
                  alignItems: 'center'
                }} variant="h4" component="span">
                    Skip this action item?{' '}
                  </Typography>
                </Box>
              </Stack>

              <IconButton onClick={() => {
              setShowConfirmSkipDialog(null);
            }}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Divider />
          </Stack>
        </DialogTitle>

        <DialogContent sx={{
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
          width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
          marginTop: '20px',
          marginBottom: '20px'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#888'
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555'
        }
      }}>
          <Stack sx={{
          pt: 1
        }} spacing={'24px'}>
            <Box>
              {' '}
              Skipping this may impact your ability to use Cinch to its fullest potential. If you have any questions or concerns about
              completeing this item, please contact us at support@cinch.io{' '}
            </Box>
          </Stack>
        </DialogContent>

        <DialogActions sx={{
        pt: 1,
        pb: 2
      }}>
          <Stack sx={{
          pr: '24px'
        }} direction={'row'} spacing={'20px'}>
            <Button onClick={() => {
            setShowConfirmSkipDialog(null);
          }} variant="contained" sx={{
            backgroundColor: '#F3F3F4',
            '&:hover': {
              backgroundColor: '#F3F3F4'
            }
          }}>
              <Box sx={{
              py: '5px',
              px: '12px'
            }}>
                <Typography sx={{
                color: '#3898D9'
              }}>Cancel </Typography>
              </Box>
            </Button>
            <Button onClick={() => {
            handleSkipActionItem();
          }} variant="contained" sx={{
            backgroundColor: '#3898D9',
            '&:hover': {
              backgroundColor: '#3898D9'
            }
          }}>
              <Box sx={{
              py: '5px',
              px: '12px'
            }}>
                <Typography sx={{
                color: '#fff'
              }}> Skip</Typography>
              </Box>
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </>;
};