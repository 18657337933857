import React, { useContext, useEffect, useState } from 'react';
import { Scoped, k } from 'kremling';
import { Loader } from 'components/loader/loader.component';
import { getAnalytics } from 'shared/common.api';
import { Icon, PageHeaderMui } from 'components';
import { UserStateContext } from 'context/user-state-context';
import { Box, Paper, Stack, Typography, Popper, ClickAwayListener } from '@mui/material';
import { history } from '../../root.component';
import { CarrotDownIcon, CarrotUpIcon, CheckIcon } from 'components/mui';
export const Analytics = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedView, setSelectedView] = useState();
  const [views, setViews] = useState([]);
  const [selectViewAnchorEl, setSelectViewAnchorEl] = useState(null);
  const [selectViewOpen, setSelectViewOpen] = useState(false);
  const {
    asCompany
  } = useContext(UserStateContext);
  useEffect(() => {
    setIsLoading(true);
    getAnalytics({
      company: asCompany.id
    }).then(({
      data
    }) => {
      setViews(data.results);
      setIsLoading(false);
      if (data.results.length > 0) {
        const queryParams = new URLSearchParams(window.location.search);
        let usableView;
        if (queryParams.has('reportId')) {
          usableView = data.results.find(r => r.id === queryParams.get('reportId'));
        }
        if (usableView) {
          setSelectedView(usableView);
        } else {
          setSelectedView(data.results[0]);
          queryParams.set('reportId', data.results[0]?.id);
          let newurl = window.location.protocol + '//' + window.location.host + window.location.pathname + '?' + queryParams.toString();
          window.history.pushState({
            path: newurl
          }, '', newurl);
        }
      }
    });
  }, [asCompany]);
  const pushToUrl = sv => {
    if (!sv) {
      return;
    }
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set('reportId', sv.id);
    let newurl = window.location.protocol + '//' + window.location.host + window.location.pathname + '?' + queryParams.toString();
    window.history.pushState({
      path: newurl
    }, '', newurl);
  };
  const handleSelectViewOpen = event => {
    if (isLoading) {
      return;
    }
    setSelectViewAnchorEl(event.currentTarget);
    setSelectViewOpen(true);
  };
  const handleSelectViewClose = () => {
    setSelectViewOpen(false);
    setSelectViewAnchorEl(null);
  };
  const handleSelectViewClick = view => {
    setSelectedView(view);
    pushToUrl(view);
    handleSelectViewClose();
  };
  const postMessage = view => {
    if (!view.post_message) {
      return;
    }
    const iframe = document.getElementById('dashboardIframe');
    if (iframe) {
      iframe.contentWindow.postMessage(view.post_message, view.embed_url.split('/', 3).join('/'));
    }
  };
  return <>
      <Scoped css={css}>
        <div className="wrapper">
          <PageHeaderMui displayComponent={<Box sx={{
          display: 'flex'
        }} className="d-flex justify-content-between">
                <Stack sx={{
            pl: '16px'
          }} direction="row">
                  <Box sx={{
              py: 1,
              color: 'black',
              fontSize: 40
            }}>
                    <Icon name="custom-auto_graph" size={34} />
                  </Box>
                  <Typography onClick={() => {
              history.push('/analytics-list');
            }} sx={{
              color: '#1D252D',
              textAlign: 'center',
              margin: 'auto',
              pl: 1,
              opacity: '1',
              fontSize: '24px',
              lineHeight: '30px',
              fontWeight: '400',
              cursor: 'pointer',
              ':hover': {
                backgroundColor: '#fff',
                color: '#1D252D'
              }
            }}>
                    Analytics
                  </Typography>

                  <Typography onClick={() => {
              history.push('/analytics-list');
            }} sx={{
              fontFamily: 'Nexa',
              fontSize: 24,
              color: '#1D252D',
              textAlign: 'center',
              margin: 'auto',
              pl: 1,
              opacity: '0.6',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: '#DAEFFF',
                color: '#53A6D6'
              }
            }}>
                    &gt;
                  </Typography>
                  <Box sx={{
              pl: '16px',
              display: 'flex',
              alignContent: 'center'
            }}>
                    <Box onClick={e => handleSelectViewOpen(e)} sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderRadius: '25px',
                width: '500px',
                backgroundColor: '#F3F3F4',
                border: selectViewOpen ? '2px solid #3898D9 ' : '1px solid #F3F3F4',
                cursor: 'pointer',
                '&:hover': {
                  border: selectViewOpen ? '2px solid #3898D9 ' : '1px solid #1D252D'
                }
              }}>
                      <Box sx={{
                  pl: '16px',
                  pr: '16px'
                }}>
                        <Typography sx={{
                    width: '440px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden !important',
                    textOverflow: 'ellipsis',
                    fontSize: '24px',
                    lineHeight: '30px',
                    fontWeight: '400'
                  }}>
                          {selectedView?.display_name ? selectedView.display_name : isLoading ? 'Loading...' : 'Choose a View'}
                        </Typography>
                      </Box>
                      <Box sx={{
                  pr: '16px'
                }}>{selectViewOpen ? <CarrotUpIcon /> : <CarrotDownIcon />}</Box>
                    </Box>
                  </Box>
                </Stack>
              </Box>} />

          <div className="wrapper-scroll analytics">
            {isLoading && <Loader overlay />}

            {!isLoading && selectedView && <iframe id="dashboardIframe" src={selectedView?.embed_url} onLoad={() => postMessage(selectedView)}></iframe>}
            {!isLoading && views.length === 0 && <p>Unable to find any views. Please try again later.</p>}
          </div>
        </div>
      </Scoped>

      <Popper anchorEl={selectViewAnchorEl} open={!!selectViewOpen} onClose={handleSelectViewClose} placement="bottom" sx={{
      zIndex: 200
    }}>
        <ClickAwayListener onClickAway={handleSelectViewClose}>
          <Paper elevation={0} sx={{
          borderRadius: '16px',
          overflow: 'visible'
        }}>
            <Box sx={{
            py: '16px'
          }}>
              <Stack>
                {views.map(view => <Box key={view.id} onClick={() => {
                handleSelectViewClick(view);
              }} sx={{
                padding: '10px 16px',
                cursor: 'pointer',
                backgroundColor: view?.id == selectedView?.id ? '#3898D933' : '#fff',
                '&:hover': {
                  backgroundColor: view?.id == selectedView?.id ? '#3898D933' : '#F3F3F4'
                }
              }}>
                    <Stack direction={'row'} spacing="16px">
                      {view?.id == selectedView?.id ? <CheckIcon /> : <Box sx={{
                    p: '12px'
                  }}></Box>}
                      <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}>
                        <Typography sx={{
                      width: '240px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden !important',
                      textOverflow: 'ellipsis'
                    }}>
                          {view.display_name}
                        </Typography>
                      </Box>
                    </Stack>
                  </Box>)}
              </Stack>
            </Box>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>;
};
const css = {
  styles: `[kremling="i16"] body,body[kremling="i16"] {
  --color-primary: #487aae;
  --color-highlight: #e7efff;
  --color-accent: #354052;
  --color-success: #73b56e;
  --color-warning: #f4b707;
  --color-danger: #df5651;
  --color-grey-10: #f9f9f9;
  --color-grey-25: #f3f3f3;
  --color-grey-50: #e9e9e9;
  --color-grey-75: #e3e3e3;
  --color-grey-100: #d3d3d3;
  --color-grey-200: #c3c3c3;
  --color-grey-300: #b3b3b3;
  --color-grey-400: #a3a3a3;
  --color-grey-500: #808080;
  --color-grey-600: #707070;
  --color-grey-700: #606060;
  --color-grey-800: #505050;
  --color-grey-900: #404040;
  --base-font-family: Roboto, Helvetica, Arial, sans-serif;
  --base-font-size: 1.4rem;
  --base-font-color: #404040;
  --base-font-weight: 400;
  --base-line-height: 1.4;
  --box-shadow-1: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.12);
  --box-shadow-2: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-3: 0 10px 20px rgba(0, 0, 0, 0.17), 0 6px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-4: 0 14px 28px rgba(0, 0, 0, 0.13), 0 10px 10px rgba(0, 0, 0, 0.1);
  --box-shadow-5: 0 19px 38px rgba(0, 0, 0, 0.18), 0 15px 12px rgba(0, 0, 0, 0.1);
  --base-border-radius: 0.4rem;
}

[kremling="i16"] .analytics,[kremling="i16"].analytics {
  overflow: hidden;
}

[kremling="i16"] .analytics iframe,[kremling="i16"].analytics iframe {
  width: 100%;
  height: 100%;
  border: 0;
  position: absolute;
}

[kremling="i16"] .analytics p,[kremling="i16"].analytics p {
  width: 100%;
  margin-top: 20px;
  text-align: center;
}`,
  id: 'i16',
  namespace: 'kremling'
};