import React from 'react';
function Icon() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <mask id="mask0_663_1524" style={{
      maskType: 'alpha'
    }} width="25" height="24" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0 0H24V24H0z"></path>
      </mask>
      <g mask="url(#mask0_663_1524)">
        <path fill="#1D252D" d="M5.3 20.5c-.5 0-.925-.175-1.275-.525A1.736 1.736 0 013.5 18.7V5.3c0-.5.175-.925.525-1.275.35-.35.775-.525 1.275-.525h13.4c.5 0 .925.175 1.276.525.35.35.524.775.524 1.275v13.4c0 .5-.174.925-.524 1.275-.35.35-.775.525-1.275.525H5.3zm0-1.5h13.4a.292.292 0 00.213-.087A.292.292 0 0019 18.7v-2.9h-3.3a4.62 4.62 0 01-1.611 1.475A4.293 4.293 0 0112 17.8c-.75 0-1.446-.175-2.088-.525A4.616 4.616 0 018.3 15.8H5v2.9c0 .083.03.154.088.213A.289.289 0 005.3 19zm6.7-2.7a2.91 2.91 0 001.489-.4c.457-.267.82-.633 1.086-1.1a.942.942 0 01.364-.362 1 1 0 01.511-.138H19v-9a.289.289 0 00-.087-.212.29.29 0 00-.212-.088H5.3a.287.287 0 00-.213.088A.287.287 0 005 5.3v9h3.55a1 1 0 01.512.138c.16.091.28.212.363.362.267.467.63.833 1.088 1.1.459.267.954.4 1.488.4zM5.3 19H5h14H5.3z"></path>
      </g>
    </svg>;
}
export default Icon;