import React, { Component } from 'react';
import { a } from 'kremling';
import { Button } from '../../components/button/button.component';
import { deleteFacebookToken, getFacebookAccounts, getFacebookTokens, patchFacebookAccount, postFacebookToken, refreshFacebookToken } from './integrations.resource';
import { getCompanies } from '../../shared/common.api';
import { toasterService } from '../../components/toaster/toaster-service';
import { Loader } from '../../components/loader/loader.component';
import { buildQueryParams } from '../../shared/api';
import { ModalDialog } from '../../components/modal/modal-dialog.component';
import { Dropdown } from '../../components/dropdown/dropdown.component';
import { Icon } from '../../components/icon/icon.component';
import { DateTime } from 'luxon';
import { UserStateContext } from 'context/user-state-context';
import queryString from 'query-string';
import { buildFacebookQueryParams } from 'shared/utils';
export class IntegrationFacebook extends Component {
  static contextType = UserStateContext;
  static propTypes = {};
  state = {
    tokens: [],
    redirect: false,
    refreshId: '',
    deleteToken: {},
    detailsId: ''
  };
  generateFacebookQueryParams = () => {
    const {
      flags
    } = this.context;
    let queryParamsScope = 'ads_management,leads_retrieval,pages_manage_metadata,pages_show_list,pages_manage_ads,pages_read_engagement,business_management';
    if (flags.facebook_reviews) {
      queryParamsScope = queryParamsScope.concat(',pages_read_user_content');
    }
    return buildFacebookQueryParams(`${window.location.protocol}//${window.location.host}/integrations/fb-redirect`, queryParamsScope);
  };
  componentDidMount() {
    const onLoad = this.props.initLoad('facebook');
    this.facebookRedirect().then(hasRedirect => {
      if (hasRedirect) this.props.history.push('/integrations');
    }).then(() => this.getFacebookData()).finally(() => onLoad());
  }
  facebookRedirect = () => {
    return new Promise(resolve => {
      const {
        match,
        location
      } = this.props;
      if (match.params.redirect === 'fb-redirect') {
        if (!location.search) {
          const {
            access_token,
            expires_in
          } = queryString.parse(location.hash.slice(1));
          if (access_token) {
            const seconds = new Date().getTime() / 1000 + parseInt(expires_in);
            const expires_when = DateTime.fromSeconds(seconds).toUTC().toISO();
            postFacebookToken(access_token, expires_when).then(() => {
              toasterService.success('Successfully connected facebook account');
              resolve(true);
            }).catch(err => {
              if (err.response.data && err.response.data) {
                toasterService.error(err.response.data.non_field_errors[0]);
              }
              resolve(true);
            });
          } else {
            resolve(false);
          }
        } else {
          const {
            error
          } = queryString.parse(location.search);
          if (error) toasterService.error('Unable to connect account from facebook');
          resolve(true);
        }
      } else {
        resolve(false);
      }
    });
  };
  getFacebookData = () => {
    let tokens = [];
    this.setState({
      companies: this.context.user.companies
    });
    return getFacebookTokens({
      ordering: 'name',
      user: this.context.user.id
    }).then(({
      results
    }) => {
      tokens = results;
      return getFacebookAccounts({
        ordering: 'name',
        token__user: this.context.user.id,
        limit: 1000
      });
    }).then(({
      results
    }) => {
      tokens = tokens.map(token => {
        token.accounts = results.filter(account => account.token === token.id);
        return token;
      });
      this.setState({
        tokens
      });
    });
  };
  refresh = tokenId => {
    this.setState({
      refreshId: tokenId
    });
    refreshFacebookToken(tokenId).then(this.getFacebookData).then(() => {
      toasterService.success('Integration successfully synced');
      this.setState({
        refreshId: ''
      });
    }).catch(err => {
      if (err.toString().includes('500 (Internal Server Error)')) this.setState({
        refreshId: ''
      });
      toasterService.warning('Unable to refresh audiences. You may need to refresh your token');
    });
  };
  removeToken = tokenId => {
    return deleteFacebookToken(tokenId).then(this.getFacebookData);
  };
  toggleCompany = (tokenId, accountId, companyId) => {
    let companies = [];
    let update = true;
    this.setState({
      tokens: this.state.tokens.map(token => {
        token.accounts = token.accounts.map(account => {
          if (account.id === accountId) {
            const index = account.company.indexOf(companyId);
            if (index === -1) {
              account.company = companies = [...account.company, companyId];
            } else {
              if (account.company.length > 1) {
                account.company = companies = [...account.company.slice(0, index), ...account.company.slice(index + 1)];
              } else {
                toasterService.warning('Ad accounts must have at least 1 company assigned');
                update = false;
              }
            }
          }
          return account;
        });
        return token;
      })
    }, () => {
      if (update) {
        return patchFacebookAccount(accountId, companies);
      }
    });
  };
  toggleDetails = tokenId => {
    const {
      detailsId
    } = this.state;
    this.setState({
      detailsId: detailsId === tokenId ? '' : tokenId
    });
  };
  alertWrongSource = () => {
    toasterService.error('This Ad Account contains Ad Audiences with the wrong data source.  They will be excluded from the journey.');
  };
  render() {
    const {
      tokens,
      refreshId,
      deleteToken,
      detailsId
    } = this.state;
    let isTokenExpired = token => {
      const isExpired = new Date(token).getTime() / 1000 < new Date().getTime() / 1000;
      if (isExpired) {
        return <span style={{
          color: 'red',
          fontWeight: 'bold'
        }}>Permissions Expired</span>;
      } else {
        const seconds = new Date(token).getTime() / 1000 - new Date().getTime() / 1000;
        const days = Math.floor(seconds / (3600 * 24));
        return <span style={{
          color: '#000',
          fontWeight: 'bold'
        }}>Permissions Expire in {days} days </span>;
      }
    };
    return <div className="integration mb-5">
        <div className="integration__header">
          <div>Facebook</div>
          {this.context.hasPermission('integration.add_credential') && <Button className="btn" actionType="primary" tag="a" target="_blank" href={`https://www.facebook.com/v4.0/dialog/oauth${this.generateFacebookQueryParams()}`}>
              Add Account
            </Button>}
        </div>
        <div className="integration__body">
          {tokens.map(token => <div className="integration-item" key={token.id}>
              <div onClick={() => {
            this.toggleDetails(token.id);
          }} className={a('integration-item__inner').m('active', detailsId === token.id)}>
                <div className="integration-title">
                  <Icon size={16} name="fa-regular-angle-up" className={a('caret').m('caret--active', detailsId === token.id)} />
                  <strong>{token.name || token.id}</strong>
                </div>
                <div className="integration-actions">
                  <>
                    {' '}
                    {isTokenExpired(token.expires_when)}
                    {this.context.hasPermission('integration.add_credential') && <Button className="btn ml-3 mr-3" actionType="primary" tag="a" target="_blank" href={`https://www.facebook.com/v4.0/dialog/oauth${this.generateFacebookQueryParams()}`}>
                        Refresh Permissions
                      </Button>}
                  </>
                  {detailsId !== token.id && `  ${token.accounts.length} ad accounts`}
                  <div className="integration__sync">
                    {refreshId === token.id ? <Loader size="sm" /> : <Button onClick={e => {
                  e.stopPropagation();
                  this.refresh(token.id);
                }} actionType="flat" icon="fa-regular-sync-alt" />}
                  </div>
                  <Button actionType="flat" icon="fa-regular-trash" onClick={e => {
                e.stopPropagation();
                this.setState({
                  deleteToken: token
                });
              }} />
                </div>
              </div>
              {detailsId === token.id && <div className="integration-accounts">
                  {!!token.accounts.length ? <table className="accounts">
                      <thead>
                        <tr>
                          <th style={{
                    width: 20
                  }} />
                          <th>Ad Account</th>
                          <th>Audiences</th>
                          <th>Connected Companies</th>
                        </tr>
                      </thead>
                      <tbody>
                        {token.accounts.map(account => <tr key={account.id}>
                            <td>
                              <div className={a('integration-status').m('integration-status--active', account.company.length > 0)} />
                            </td>
                            <td>{account.name}</td>
                            <td>
                              {account.audiences}{' '}
                              {account.has_wrong_source && <a onClick={() => this.alertWrongSource()}>
                                  <Icon className="wrong-source" name="fa-solid-exclamation-triangle" size={12} />
                                </a>}
                            </td>
                            <td className="accounts__connect">
                              <Dropdown size="block" contentHeight={288} allowContentClicks trigger={() => <Button block actionType="flat" dropdown>
                                    {account.company.length}{' '}
                                    {account.company.length === 0 || account.company.length > 1 ? 'Companies' : 'Company'}
                                  </Button>} content={() => <ul className="select-list">
                                    {this.state.companies.map(company => <li key={company.id}>
                                        <a title={company.name} onClick={() => this.toggleCompany(token.id, account.id, company.id)}>
                                          <div className="select-list__content">{company.name}</div>
                                          {!!account.company.find(c => c === company.id) && <Icon className="select-list__selected" size={14} name="fa-regular-check" />}
                                        </a>
                                      </li>)}
                                  </ul>} />
                            </td>
                          </tr>)}
                      </tbody>
                    </table> : 'No ad accounts associated with this user'}
                </div>}
            </div>)}
        </div>
        <ModalDialog open={!!deleteToken.id} title="Remove connection" submitText="Remove" onSubmit={() => this.removeToken(deleteToken.id)} onClose={() => this.setState({
        deleteToken: {}
      })} allowBackdropClick>
          <p>
            <strong>{deleteToken.name || deleteToken.id}</strong>
          </p>
          <p>Are you sure you want to remove this account?</p>
        </ModalDialog>
      </div>;
  }
}