import React from 'react';
function Icon(props) {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <mask id="mask0_671_1065" style={{
      maskType: 'alpha'
    }} width="24" height="24" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0 0H24V24H0z"></path>
      </mask>
      <g mask="url(#mask0_671_1065)">
        <path fill={props.fill || '#3898D9'} d="M11.25 12.75V16c0 .212.072.39.216.534a.726.726 0 00.534.216c.213 0 .391-.072.535-.216A.726.726 0 0012.75 16v-3.25H16c.212 0 .39-.072.534-.216A.727.727 0 0016.75 12a.725.725 0 00-.216-.535.726.726 0 00-.534-.215h-3.25V8a.726.726 0 00-.216-.534A.726.726 0 0012 7.25a.725.725 0 00-.535.216.726.726 0 00-.215.534v3.25H8a.725.725 0 00-.534.216.726.726 0 00-.216.534c0 .213.072.391.216.535A.726.726 0 008 12.75h3.25zm.752 8.75a9.255 9.255 0 01-3.705-.748 9.598 9.598 0 01-3.018-2.03 9.591 9.591 0 01-2.03-3.016 9.245 9.245 0 01-.749-3.704c0-1.314.25-2.55.748-3.705a9.597 9.597 0 012.03-3.018 9.592 9.592 0 013.016-2.03 9.245 9.245 0 013.704-.749c1.314 0 2.55.25 3.705.748a9.597 9.597 0 013.018 2.03 9.592 9.592 0 012.03 3.016 9.245 9.245 0 01.749 3.704c0 1.314-.25 2.55-.748 3.705a9.598 9.598 0 01-2.03 3.018 9.592 9.592 0 01-3.016 2.03 9.245 9.245 0 01-3.704.749z"></path>
      </g>
    </svg>;
}
export default Icon;