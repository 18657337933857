import React from "react";
function Icon() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
      <mask id="mask0_38_2882" style={{
      maskType: "alpha"
    }} width="20" height="20" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0 0H20V20H0z"></path>
      </mask>
      <g mask="url(#mask0_38_2882)">
        <path fill="#53A6D6" d="M7.69 17.292a.642.642 0 01-.469-.2.64.64 0 010-.933.64.64 0 01.467-.2h.979v-1.584H3.673c-.438 0-.813-.155-1.124-.466a1.531 1.531 0 01-.466-1.124V4.298c0-.438.156-.813.466-1.123.311-.311.686-.466 1.124-.466h12.654c.438 0 .813.155 1.124.466.31.31.466.685.466 1.123v8.487c0 .439-.156.813-.466 1.124-.311.31-.686.466-1.124.466h-4.994v1.584h.98a.64.64 0 01.466.2.64.64 0 010 .932.642.642 0 01-.47.2H7.69zm-4.017-4.25h12.654a.25.25 0 00.184-.072.25.25 0 00.072-.185V4.298a.25.25 0 00-.072-.184.25.25 0 00-.184-.072H3.673a.25.25 0 00-.184.072.25.25 0 00-.072.184v8.487a.25.25 0 00.072.185.25.25 0 00.184.072z"></path>
      </g>
    </svg>;
}
export default Icon;