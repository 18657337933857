import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import { UserStateContext } from 'context/user-state-context';
import { FileViewer2 } from './file-viewer.component-mui';
import CssBaseline from '@mui/material/CssBaseline';
import { Paper, TextField } from '@mui/material';
export function ModalFileViewer2(props) {
  const [selectedFile, setSelectedFile] = React.useState(null);
  React.useEffect(() => {}, []);
  const selectFile = file => {
    if (file.isFolder) {
      setSelectedFile(null);
    } else {
      setSelectedFile(file);
    }
  };
  return <>
      <div className="mui-wrapper">
        <CssBaseline />
        <Dialog sx={{
        fontFamily: 'Nexa',
        color: '#1D252D'
      }} PaperProps={{
        style: {
          borderRadius: '28px',
          minWidth: '70% '
        }
      }} open={props.open} onClose={(event, reason) => {
        props.onClose(null);
      }}>
          <DialogTitle className="d-flex w-100 justify-content-between" sx={{
          m: 0,
          px: 0,
          py: 1
        }}>
            <Box sx={{
            pl: '24px',
            display: 'flex',
            alignContent: 'center',
            alignItems: 'center'
          }}>
              <Typography sx={{
              fontSize: 24
            }}> Attach File</Typography>
            </Box>

            <Box sx={{
            pr: '24px',
            display: 'flex',
            alignContent: 'center',
            alignItems: 'center'
          }}>
              <IconButton aria-label="close" onClick={e => {
              props.onClose(null);
            }} sx={{
              color: theme => theme.palette.grey[500]
            }}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>

          <DialogContent dividers>
            <DialogContentText>
              <Stack component="span"></Stack>
            </DialogContentText>

            <FileViewer2 onSelect={selectFile} hideCSVs />
          </DialogContent>

          <DialogActions sx={{
          py: 3
        }}>
            <Button onClick={e => {
            props.onClose();
          }} sx={{
            borderRadius: '20px',
            color: '#53A6D6',
            width: '125px',
            borderStyle: 'solid',
            borderColor: '#53A6D6',
            borderWidth: '1px'
          }}>
              Cancel
            </Button>
            <Button disabled={!selectedFile} onClick={e => {
            props.onSubmit(selectedFile);
            props.onClose();
          }} variant="contained" sx={{
            borderRadius: '20px',
            mr: 2,
            width: '125px'
          }}>
              Attach
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>;
}