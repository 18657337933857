import { api } from '../../shared/api';
export function createMultipartUpload(file) {
  const metadata = {};
  Object.keys(file.meta).map(key => {
    if (file.meta[key] != null) {
      metadata[key] = file.meta[key].toString();
    }
  });
  return api.post(this.opts.companionUrl + '/create', {
    filename: file.name,
    type: file.type,
    parent_folder: this.opts.parentFolder,
    metadata
  }).then(({
    data
  }) => data);
}
export function listParts(file, {
  key,
  uploadId
}) {
  const keyEnc = encodeURIComponent(key);
  const uploadIdEnc = encodeURIComponent(uploadId);
  return api.get(this.opts.companionUrl + `/list?upload_id=${uploadIdEnc}&key=${keyEnc}`).then(({
    data
  }) => data);
}
export function prepareUploadPart(file, {
  key,
  uploadId,
  number
}) {
  const keyEnc = encodeURIComponent(key);
  const uploadIdEnc = encodeURIComponent(uploadId);
  const numberEnc = encodeURIComponent(number);
  return api.get(this.opts.companionUrl + `/prepare?upload_id=${uploadIdEnc}&key=${keyEnc}&number=${numberEnc}`).then(({
    data
  }) => data);
}
export function completeMultipartUpload(file, {
  key,
  uploadId,
  parts
}) {
  return api.post(this.opts.companionUrl + `/complete`, {
    key,
    upload_id: uploadId,
    parts
  }).then(({
    data
  }) => data);
}
export function abortMultipartUpload(file, {
  key,
  uploadId
}) {
  const keyEnc = encodeURIComponent(key);
  const uploadIdEnc = encodeURIComponent(uploadId);
  return api.delete(this.opts.companionUrl + `/abort?upload_id=${uploadIdEnc}&key=${keyEnc}`).then(({
    data
  }) => data);
}