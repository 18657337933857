import React from 'react';
function Icon() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none" viewBox="0 0 24 25">
      <mask id="mask0_15_5220" style={{
      maskType: 'alpha'
    }} width="25" height="25" x="-1" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M-0.001 0.5H23.999V24.5H-0.001z"></path>
      </mask>
      <g mask="url(#mask0_15_5220)">
        <path fill="#1D252D" d="M3.524 19.613a.892.892 0 01-.638-.25.858.858 0 01-.262-.65v-1.326c0-.516.134-.974.4-1.375.267-.4.617-.716 1.05-.95.85-.433 1.75-.783 2.7-1.05.95-.266 2.067-.4 3.35-.4.5 0 .963.021 1.388.063.425.041.829.104 1.212.188L11.4 15.188a4.312 4.312 0 00-.6-.064 18.313 18.313 0 00-.675-.011c-1.183 0-2.22.133-3.112.4-.892.266-1.638.566-2.238.9-.233.116-.4.258-.5.424-.1.167-.15.35-.15.55v.726h6.05l1.5 1.5h-8.15zm12.05.375a.936.936 0 01-.337-.063.823.823 0 01-.288-.188L13 17.788a.74.74 0 01-.213-.512.693.693 0 01.213-.537.72.72 0 01.525-.226c.2 0 .375.075.525.226l1.525 1.524 4.525-4.524a.74.74 0 01.513-.213.693.693 0 01.537.213.72.72 0 01.225.524.72.72 0 01-.225.525l-4.95 4.95a.826.826 0 01-.287.189.944.944 0 01-.338.062zm-5.45-7.976c-.966 0-1.791-.346-2.475-1.037-.683-.692-1.025-1.513-1.025-2.463 0-.966.342-1.791 1.025-2.475a3.372 3.372 0 012.475-1.025c.967 0 1.792.342 2.475 1.025a3.372 3.372 0 011.025 2.475c0 .95-.341 1.771-1.025 2.463-.683.691-1.508 1.037-2.475 1.037zm0-1.5c.55 0 1.021-.196 1.413-.588.392-.39.587-.862.587-1.412 0-.55-.195-1.02-.587-1.413a1.928 1.928 0 00-1.413-.587c-.55 0-1.02.196-1.413.587a1.928 1.928 0 00-.587 1.413c0 .55.196 1.021.587 1.412.392.393.863.588 1.413.588z"></path>
      </g>
    </svg>;
}
export default Icon;