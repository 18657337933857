import React, { useState } from 'react';
import { useCss } from 'kremling';
import { api } from '../shared/api';
import styles from './fb-test.styles.scss';
import { Button } from '../components/button/button.component';
const formNodeId = `3b79b447-311d-4b93-a7e6-ac0f689889f1`;
export function FbTest() {
  const scope = useCss(styles);
  const [firstName, updateFirstName] = useState('');
  const [lastName, updateLastName] = useState('');
  const [email, updateEmail] = useState('');
  const [companyName, updateCompanyName] = useState('');
  const [message, updateMessage] = useState('');
  const [checkError, updateCheckError] = useState(false);
  const [formSubmitted, updateFormSubmitted] = useState(false);
  const [submitError, updateSubmitError] = useState(false);
  const [loading, updateLoading] = useState(false);
  function submit() {
    if (firstName && lastName && email && companyName) {
      updateCheckError(false);
      updateFormSubmitted(true);
      updateLoading(true);
      api.post(`/form/${formNodeId}`, {
        first_name: firstName,
        last_name: lastName,
        email,
        entity_data: {
          company_name: companyName,
          message: message
        }
      }).then(() => updateLoading(false)).catch(() => {
        updateLoading(false);
        updateSubmitError(true);
      });
    } else {
      updateCheckError(true);
    }
  }
  function cancel() {
    updateFirstName('');
    updateLastName('');
    updateEmail('');
    updateCompanyName('');
    updateMessage('');
    updateCheckError(false);
    updateFormSubmitted(false);
    updateSubmitError(false);
    updateLoading(false);
  }
  if (loading) return null;
  return <div {...scope} className="fb-test">

      {formSubmitted ? <div className="form-wrapper">
            {submitError ? <>
                <h2>Uh Oh...</h2>
                <div className="mb-md">Looks like there was an error submitting the form...</div>
                <div className="text-right">
                  <Button onClick={cancel} className="mr-sm">Reset</Button>
                  <Button onClick={submit} actionType="primary">Try again</Button>
                </div>
              </> : <>
                <h2>Thank you!</h2>
                <div>Form Submitted</div>
              </>}
          </div> : <>
            <div className="form-wrapper mb-lg">
              <h2 className="text-center mb-md">Facebook Test</h2>

              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label>First Name <span className="form-error">*</span></label>
                    <input type="text" className="form-control" value={firstName} onChange={e => updateFirstName(e.target.value)} />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label>Last Name <span className="form-error">*</span></label>
                    <input type="text" className="form-control" value={lastName} onChange={e => updateLastName(e.target.value)} />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label>Email <span className="form-error">*</span></label>
                    <input type="email" className="form-control" value={email} onChange={e => updateEmail(e.target.value)} />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label>Company Name <span className="form-error">*</span></label>
                    <input type="text" className="form-control" value={companyName} onChange={e => updateCompanyName(e.target.value)} />
                  </div>
                </div>
              </div>

              <div className="form-divider" />
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label>Message</label>
                    <textarea rows="8" className="form-control" value={message} onChange={e => updateMessage(e.target.value)} style={{
                height: '8%'
              }}></textarea>
                  </div>
                </div>
              </div>

              {checkError && <div className="form-error">
                  Please fill in the required fields before submitting.
                </div>}
            </div>
            <div className="text-center">
              <Button actionType="primary" large style={{
          paddingLeft: '3rem',
          paddingRight: '3rem'
        }} onClick={submit}>
                Submit
              </Button>
            </div>
          </>}
    </div>;
}