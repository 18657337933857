import React from 'react';
function Icon(props) {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none" viewBox="0 0 24 25">
      <mask id="mask0_136_289" style={{
      maskType: 'alpha'
    }} width="25" height="25" x="-1" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M-0.001 0.5H23.999V24.5H-0.001z"></path>
      </mask>
      <g mask="url(#mask0_136_289)">
        <path fill={props.fill || '#1D252D'} d="M12 12.394c.485 0 .898-.17 1.239-.51.34-.34.51-.754.51-1.24s-.17-.899-.51-1.24c-.34-.34-.754-.51-1.24-.51s-.899.17-1.24.51c-.34.341-.51.754-.51 1.24s.17.9.51 1.24c.341.34.754.51 1.24.51zm0 8.052c2.058-1.84 3.615-3.598 4.669-5.27 1.053-1.672 1.58-3.116 1.58-4.332 0-1.893-.603-3.442-1.81-4.645-1.206-1.203-2.686-1.805-4.44-1.805-1.754 0-3.234.602-4.44 1.805-1.207 1.203-1.81 2.752-1.81 4.645 0 1.216.527 2.66 1.581 4.332 1.054 1.672 2.61 3.43 4.67 5.27zm0 1.66a1.047 1.047 0 01-.664-.237c-2.382-2.143-4.159-4.133-5.33-5.968-1.171-1.835-1.757-3.52-1.757-5.057 0-2.423.78-4.354 2.34-5.792C8.15 3.613 9.954 2.894 12 2.894c2.046 0 3.85.72 5.41 2.158s2.34 3.37 2.34 5.792c0 1.536-.585 3.222-1.756 5.057-1.172 1.835-2.948 3.825-5.33 5.968a.902.902 0 01-.307.176c-.117.04-.236.06-.357.06z"></path>
      </g>
    </svg>;
}
export default Icon;