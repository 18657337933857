import React from 'react';
function Icon() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="none" viewBox="0 0 25 25">
      <mask id="mask0_861_17419" style={{
      maskType: 'alpha'
    }} width="25" height="25" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0.5 0.5H24.5V24.5H0.5z"></path>
      </mask>
      <g mask="url(#mask0_861_17419)">
        <path fill="#1D252D" d="M3.365 18.946v-.942H9.75v-1.5h-7v-4.923h10v-1.5H3.358l1.184-3.375a.32.32 0 01.116-.15.314.314 0 01.182-.052h7.91v-1.5H4.837c-.39 0-.742.113-1.057.338a1.726 1.726 0 00-.651.877l-1.79 5.064a1.575 1.575 0 00-.069.298 2.42 2.42 0 00-.02.312v7.053c0 .294.103.544.308.75.205.205.454.308.749.308.294 0 .544-.103.75-.309a1.02 1.02 0 00.308-.749z"></path>
        <path fill="#1D252D" d="M5.214 15.35c.364 0 .672-.127.925-.382.254-.255.38-.564.38-.928 0-.364-.127-.672-.382-.925a1.267 1.267 0 00-.928-.38c-.364 0-.672.127-.925.382-.254.254-.38.564-.38.928 0 .364.127.672.382.925s.564.38.928.38z"></path>
        <path fill="#1D252D" stroke="#fff" strokeWidth="1.5" d="M9.885 14.76h0a15.247 15.247 0 013.146-1.145 13.7 13.7 0 016.438 0 15.25 15.25 0 013.146 1.146l.007.004h0a3.414 3.414 0 011.361 1.228c.342.537.517 1.13.517 1.764v.704c0 .619-.225 1.169-.663 1.606 0 0 0 0 0 0M9.885 14.761l-.692 4.776c.296.296.654.443 1.076.443h11.962c.421 0 .78-.148 1.076-.443l.53.53M9.885 14.761l-.007.004m.007-.004l-.007.004m13.959 5.302a2.214 2.214 0 01-1.606.663H10.269a2.214 2.214 0 01-1.606-.663m15.174 0H8.663m0 0s0 0 0 0A2.214 2.214 0 018 18.461v-.704c0-.634.175-1.227.517-1.764l.146 4.074zm1.215-5.303a3.414 3.414 0 00-1.361 1.23l1.36-1.23zm11.576 2.853h0a.238.238 0 01.045.113H11a.237.237 0 01.045-.113h0a.577.577 0 01.204-.179c.81-.397 1.632-.697 2.467-.901a10.638 10.638 0 015.066 0c.835.204 1.657.504 2.467.901.1.056.162.117.204.179z"></path>
        <mask id="path-5-outside-1_861_17419" width="11" height="11" x="10.75" y="2.996" fill="#000" maskUnits="userSpaceOnUse">
          <path fill="#fff" d="M10.75 2.996H21.75V13.996H10.75z"></path>
          <path fillRule="evenodd" d="M16.25 11.996c-.963 0-1.787-.343-2.472-1.028s-1.028-1.51-1.028-2.472.343-1.786 1.028-2.472c.685-.685 1.51-1.028 2.472-1.028s1.786.343 2.472 1.028c.685.686 1.028 1.51 1.028 2.472 0 .963-.343 1.787-1.028 2.472-.686.685-1.51 1.028-2.472 1.028zm0-1.5c.55 0 1.02-.196 1.413-.587.391-.392.587-.863.587-1.413s-.196-1.02-.587-1.412a1.926 1.926 0 00-1.413-.588c-.55 0-1.02.196-1.412.588a1.926 1.926 0 00-.588 1.412c0 .55.196 1.02.588 1.413.391.391.862.587 1.412.587z" clipRule="evenodd"></path>
        </mask>
        <path fill="#1D252D" fillRule="evenodd" d="M16.25 11.996c-.963 0-1.787-.343-2.472-1.028s-1.028-1.51-1.028-2.472.343-1.786 1.028-2.472c.685-.685 1.51-1.028 2.472-1.028s1.786.343 2.472 1.028c.685.686 1.028 1.51 1.028 2.472 0 .963-.343 1.787-1.028 2.472-.686.685-1.51 1.028-2.472 1.028zm0-1.5c.55 0 1.02-.196 1.413-.587.391-.392.587-.863.587-1.413s-.196-1.02-.587-1.412a1.926 1.926 0 00-1.413-.588c-.55 0-1.02.196-1.412.588a1.926 1.926 0 00-.588 1.412c0 .55.196 1.02.588 1.413.391.391.862.587 1.412.587z" clipRule="evenodd"></path>
        <path fill="#fff" d="M13.778 10.968l-1.06 1.06 1.06-1.06zm0-4.944l1.06 1.06-1.06-1.06zm4.944 0l-1.06 1.06 1.06-1.06zm0 4.944l1.06 1.06-1.06-1.06zm-1.06-3.884l-1.06 1.06 1.06-1.06zm-2.824 0l1.06 1.06-1.06-1.06zm-2.12 4.945c.964.964 2.168 1.467 3.532 1.467v-3c-.562 0-1.005-.182-1.411-.589l-2.122 2.122zM11.25 8.496c0 1.364.503 2.568 1.467 3.533l2.122-2.122c-.407-.406-.589-.85-.589-1.41h-3zm1.467-3.532c-.964.964-1.467 2.169-1.467 3.532h3c0-.561.182-1.005.589-1.411l-2.122-2.121zm3.533-1.468c-1.364 0-2.568.503-3.533 1.468l2.122 2.12c.406-.405.85-.588 1.411-.588v-3zm3.532 1.468c-.964-.965-2.169-1.468-3.532-1.468v3c.561 0 1.005.183 1.411.589l2.121-2.121zm1.468 3.532c0-1.363-.503-2.568-1.468-3.532l-2.12 2.12c.405.407.588.85.588 1.412h3zm-1.468 3.533c.965-.965 1.468-2.17 1.468-3.533h-3c0 .562-.183 1.005-.589 1.411l2.121 2.122zm-3.532 1.467c1.363 0 2.568-.503 3.532-1.467l-2.12-2.122c-.407.407-.85.589-1.412.589v3zm.352-4.648a.434.434 0 01-.352.148v3c.951 0 1.802-.356 2.473-1.027l-2.121-2.121zm.148-.352c0 .15-.036.24-.148.352l2.121 2.121a3.425 3.425 0 001.027-2.473h-3zm-.148-.352a.434.434 0 01.148.352h3c0-.95-.356-1.802-1.027-2.473l-2.121 2.121zm-.352-.148c.149 0 .24.036.352.148l2.121-2.121a3.425 3.425 0 00-2.473-1.027v3zm-.352.148a.434.434 0 01.352-.148v-3c-.951 0-1.802.356-2.473 1.027l2.121 2.121zm-.148.352c0-.149.036-.24.148-.352l-2.121-2.121a3.425 3.425 0 00-1.027 2.473h3zm.148.352a.434.434 0 01-.148-.352h-3c0 .951.356 1.803 1.027 2.473l2.121-2.121zm.352.148a.434.434 0 01-.352-.148l-2.121 2.121a3.425 3.425 0 002.473 1.027v-3z" mask="url(#path-5-outside-1_861_17419)"></path>
      </g>
    </svg>;
}
export default Icon;