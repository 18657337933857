import React, { Component } from 'react';
import { func, object, shape, string, number } from 'prop-types';
import { Dropdown } from "../../../../components/dropdown/dropdown.component";
import { Button } from "components";
export class ActionGivePoints extends Component {
  static propTypes = {
    update: func,
    node: object,
    data: shape({
      action_type: string,
      points: number
    })
  };
  render() {
    const {
      action_type,
      points
    } = this.props.data;
    return <div>
          <div className="form-group">
            <label>Action Type</label>
            <Dropdown size="block" contentHeight={280} trigger={() => <Button block dropdown>
                      {action_type ? action_type : null}
                    </Button>} content={() => <ul className="select-list">
                      {['Add', 'Remove'].map(action => <li key={action}>
                            <a onClick={() => this.props.update(action)}>{action}</a>
                          </li>)}
                    </ul>} />
          </div>
          <div className="form-group">
            <label>Points</label>
            <input type="number" className="form-control" placeholder="Points" value={points} onChange={e => this.props.update(e.target.value)} />
          </div>
        </div>;
  }
}