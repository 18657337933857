import React, { Component } from 'react';
import { bool, node, func, string } from 'prop-types';
import { a } from 'kremling';
export class RecurringOption extends Component {
  static propTypes = {
    active: bool,
    children: node,
    onChange: func,
    inactiveMessage: string
  };
  render() {
    const {
      onChange,
      children,
      active,
      inactiveMessage
    } = this.props;
    return <label className={a('recurring-option').m('recurring-option--active', active)}>
        {onChange && <input type="radio" name="recurring-option" onChange={onChange} checked={active} />}
        {active ? children : inactiveMessage || children}
      </label>;
  }
}