import React, { useState, useEffect, useContext, useRef } from 'react';
import { cloneDeep } from 'lodash';
import { getAllCompanyFields, getCompany, getCompanyFields, getMarkets, patchCompany, updateMarket } from '../../shared/common.api';
import { UserStateContext } from 'context/user-state-context';
import { Box, Stack, Typography, Dialog, DialogTitle, DialogContent, TextField, Divider, IconButton, Button, CircularProgress } from '@mui/material';
import { AddIcon, CloseIcon } from 'components/mui';
import { AddFieldModal } from '../data-fields/add-field-modal.component';
import { sortFields, propogateLocationField } from '../data-fields/utils';
export const EditModalMarket = props => {
  const {
    asCompany,
    companyFields,
    companyFieldsChange
  } = useContext(UserStateContext);
  const {
    showEditModal,
    setShowEditModal,
    updateData,
    editErrors,
    editData,
    setEditData,
    marketLocations
  } = props;
  const [marketEntityFields, setMarketEntityFields] = useState([]);
  const [markets, setMarkets] = useState([]);
  const [showAddCustomField, setShowAddCustomField] = useState(false);
  const [isSaving, setIsSaving] = useState(0);
  const [canSave, setCanSave] = useState(false);
  const originalData = useRef(null);
  const [fieldHasBeenAdded, setFieldHasBeenAdded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [editMarketData, setEditMarketData] = useState({});
  const [entityFields, setEntityFields] = useState([]);
  const [fields, setFields] = useState([]);
  const [company, setCompany] = useState(null);
  const [allFields, setAllFields] = useState([]);
  const [editsToEntityData, setEditsToEntityData] = useState({});
  const addField = (modelID, field) => {
    /**
     * Add field to model
     */

    const newFields = cloneDeep(fields);
    const model = newFields.find(m => m.id === modelID);
    if (!model.fields.find(f => f.id === field.id)) {
      model.fields.push(field);
    }

    // TODO we should probably be saving any `entity_data__` fields to the company all fields
    if (field.id.startsWith('location__market__entity_data__')) {
      // We have some extra places we need to add this field.
      propogateLocationField(newFields, field, 'market');
    }
    setFields(newFields);
    setFieldHasBeenAdded(true);
    return saveFields(newFields);
  };
  const saveFields = async newFields => {
    /**
     * Wrapper for saving fields to API with a count for how many saves are still happening.
     * TODO convert this to debouncing.
     */

    // replace the field that has location__market__entity_data__ in it with just market__entity_data__
    newFields.forEach(model => {
      // Exclude models with id or name 'transaction' and 'schedule'
      if (model.id !== 'transaction' && model.id !== 'schedule') {
        model.fields.forEach(field => {
          if (field.id.startsWith('location__market__entity_data__')) {
            const newID = field.id.replace('location__market__entity_data__', 'market__entity_data__');
            field.id = newID;
          }
        });
      }
    });
    newFields = sortFields(newFields);
    setFields(newFields);
    setIsSaving(isSaving => isSaving + 1);
    await patchCompany(asCompany.id, {
      fields: newFields
    }).then(() => {
      companyFieldsChange();
      setIsSaving(isSaving_1 => isSaving_1 - 1);
    });
  };
  useEffect(() => {
    const locationModel = companyFields.find(i => i.id === 'location');
    if (!locationModel) return;
    const allEntityFields = locationModel.fields.filter(i => i.id.includes('market__entity_data__'));
    const rowifiedEntityFields = [];
    for (let i = 0; i < allEntityFields.length; i++) {
      if (i % 3 === 0) {
        rowifiedEntityFields.push([]);
      }
      rowifiedEntityFields[Math.floor(i / 3)].push(allEntityFields[i]);
    }
    setEntityFields(rowifiedEntityFields);
  }, [companyFields]);

  // set originalData for comparison later to set canSave
  useEffect(() => {
    originalData.current = cloneDeep(editData);
  }, [showEditModal]);
  function deepMerge(target, source) {
    if (!source) return target;
    for (const key of Object.keys(source)) {
      if (source[key] instanceof Object && !Array.isArray(source[key])) {
        if (!target[key] || typeof target[key] !== 'object') {
          target[key] = {};
        }
        target[key] = deepMerge(target[key], source[key]);
      } else {
        target[key] = source[key];
      }
    }
    return target;
  }
  const handleEditMarket = () => {
    const clonedEditData = JSON.parse(JSON.stringify(editData));

    // see if there are changes first, if not then dont deepMerge
    if (!editMarketData) {
      setFieldHasBeenAdded(false);
      setShowEditModal(false);
      return;
    }

    // if it is only editMarketData.name and nothing else dont merge
    if (editMarketData.name && Object.keys(editMarketData).length === 1) {
      updateMarket(editData.id, {
        ...editMarketData,
        name: editMarketData.name
      }).then(() => {
        setIsSaving(isSaving => isSaving + 1);
        setShowEditModal(false);
        updateData();
      });
      return;
    }
    const updatedMarket = deepMerge(clonedEditData, editMarketData?.newEditData || {});

    // merge the changed fields so everything saves
    updatedMarket.entity_data = {
      ...updatedMarket.entity_data,
      ...editsToEntityData
    };

    // Add any additional fields from editMarketData that are not nested in newEditData
    if (editMarketData.name) {
      updatedMarket.name = editMarketData.name;
    }

    // replace any id that has location__market__entity_data__ in it with just the name
    Object.keys(updatedMarket.entity_data).forEach(key => {
      if (key.includes('market__entity_data__')) {
        const newKey = key.replace('market__entity_data__', '');
        updatedMarket.entity_data[newKey] = updatedMarket.entity_data[key];
        delete updatedMarket.entity_data[key];
      }
    });
    updateMarket(editData.id, updatedMarket).then(() => {
      setIsSaving(isSaving => isSaving + 1);
      setShowEditModal(false);
      updateData();
    });
  };
  const editEntityData = (id, val) => {
    const {
      editData,
      setEditData
    } = props;
    const newEditData = JSON.parse(JSON.stringify(editData));
    if (!newEditData?.entity_data) {
      newEditData.entity_data = {};
    }
    setEditsToEntityData({
      ...editsToEntityData,
      [id]: val
    });
    newEditData.entity_data[id] = val;
    setEditMarketData({
      ...editMarketData,
      newEditData
    });
    setEditData(newEditData);
  };
  const getEntityData = id => {
    const {
      editData
    } = props;
    if (!editData?.entity_data) {
      return '';
    }
    // because we're pulling from location table
    const strippedId = id.replace('market__entity_data__', '');
    return editData.entity_data[strippedId] || '';
  };
  useEffect(() => {
    if (props.timezone) {
      setTimezoneSelected(props.timezone);
    }
  }, [props.timezone]);
  useEffect(() => {
    /**
     * Load company fields on mount
     */

    setIsLoading(true);
    const companyId = asCompany.id;
    Promise.all([getCompany(companyId), getCompanyFields(companyId), getAllCompanyFields(companyId)]).then(([{
      data: company
    }, {
      data: fields
    }, {
      data: allFields
    }]) => {
      setCompany(company);
      setFields(fields);
      setAllFields(allFields);
    });
    getMarkets({
      company: asCompany.id,
      limit: 200
    }).then(({
      data
    }) => {
      setMarkets(data?.results);
      if (!data?.results || !editData?.id) {
        return;
      }
      const allEntityFields = data.results.find(market => market.id === editData.id)?.entity_data;

      // key value pairs of entity_data
      if (!allEntityFields) {
        setIsLoading(false);
        return;
      }
      const allEntityFieldsArray = Object.entries(allEntityFields);
      const rowifiedEntityFields = [];
      for (let i = 0; i < allEntityFieldsArray.length; i++) {
        if (i % 3 === 0) {
          rowifiedEntityFields.push([]);
        }
        rowifiedEntityFields[Math.floor(i / 3)].push(allEntityFieldsArray[i]);
      }
      setMarketEntityFields(rowifiedEntityFields);
      setEditMarketData(markets.find(market => market.id === editData.id));
      setIsLoading(false);
    });
  }, [asCompany, isSaving]);

  // useEffect(() => {
  //   const editMarketDataString = editMarketData ? JSON.stringify(editMarketData).trim().toLowerCase() : '';
  //   const originalDataString = originalData.current ? JSON.stringify(originalData.current).trim().toLowerCase() : '';

  //   const editMarketDataName = editMarketData?.name?.trim().toLowerCase() || '';
  //   const originalDataName = editData?.name?.trim().toLowerCase() || '';

  //   if ((editMarketDataString && editMarketDataString !== originalDataString) || fieldHasBeenAdded) {
  //     setCanSave(true);
  //   } else {
  //     setCanSave(false);
  //   }
  // }, [editMarketData, fieldHasBeenAdded]);

  return <>
      <AddFieldModal open={showAddCustomField} sx={{
      display: 'flex',
      width: '312px',
      height: 'auto',
      p: '24px',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '24px',
      borderRadius: '28px'
    }} title="Add new field" onClose={() => {
      setShowAddCustomField(false);
    }} modelID={'location'} addField={addField} customModel={'market'} typeDisabled={true} />

      <Dialog open={!!showEditModal} onClose={() => {
      setShowEditModal(false);
    }} PaperProps={{
      style: {
        borderRadius: '28px',
        background: '#fff',
        height: 'auto',
        minWidth: '828px',
        margin: 'auto'
      }
    }} sx={{
      height: 'auto',
      minHeight: '775px',
      minWidth: '828px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexShrink: 0,
      margin: 'auto',
      zIndex: 900
    }}>
        <DialogTitle sx={{
        pb: '12px'
      }}>Edit Market</DialogTitle>
        <IconButton aria-label="close" onClick={e => {
        setShowEditModal(false);
      }} sx={{
        position: 'absolute',
        right: 8,
        top: 8,
        color: theme => theme.palette.grey[500]
      }}>
          <CloseIcon />
        </IconButton>
        <Divider />
        <DialogContent sx={{
        p: 0,
        overflowY: 'scroll',
        overflowX: 'hidden'
      }}>
          <Stack sx={{
          py: '24px'
        }}>
            <Stack direction={'row'} display={'flex'} sx={{
            px: '24px',
            justifyContent: 'space-between'
          }}>
              <Box>
                <Button sx={{
                borderRadius: '20px',
                bgcolor: '#F3F3F4',
                p: '10px 24px 10px 14px',
                height: '32px',
                display: 'inline-flex',
                gap: '6px',
                justifyContent: 'center',
                alignItems: 'center',
                textTransform: 'none'
              }} onClick={e => {
                setShowAddCustomField(true);
              }}>
                  <AddIcon fill="#3898D9" />
                  <Typography sx={{
                  color: '#3898D9'
                }}>New field</Typography>
                </Button>
              </Box>
            </Stack>
            <Stack display={'flex'} direction={'row'} spacing={'24px'} sx={{
            pt: '24px',
            px: '24px'
          }}>
              <Box>
                <TextField sx={{
                width: '244px',
                height: '56px'
              }} id="textfield-name" label="Name" variant="outlined" autoComplete="off" fullWidth defaultValue={editData?.name} onChange={e => {
                setEditMarketData({
                  ...editMarketData,
                  name: e.target.value
                });
              }} />
              </Box>
            </Stack>
            <Stack>
              {isLoading ? <Stack>
                  <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
                    <CircularProgress />
                  </Box>
                </Stack> : entityFields.map((row, rowIndex) => <Stack key={row.id || rowIndex} display={'flex'} direction={'row'} spacing={'24px'} sx={{
              pt: '24px',
              px: '24px'
            }}>
                    {row.map((field, fieldIndex) => <React.Fragment key={field.id || fieldIndex}>
                        {field?.type === 'text' ? <Box>
                            <TextField sx={{
                    width: '244px',
                    height: '56px'
                  }} id={field.type === 'text' ? `textfield-${field.id}` : field.type === 'date' ? `date-${field.id}` : field.type === 'datetime' ? `datetime-${field.id}` : `number-${field.id}`} label={field.name} variant="outlined" autoComplete="off" fullWidth defaultValue={getEntityData(field.id)} onChange={e => {
                    editEntityData(field.id, e.target.value);
                  }} />
                          </Box> : null}
                      </React.Fragment>)}
                  </Stack>)}
            </Stack>
          </Stack>
        </DialogContent>
        <Divider />
        <Stack direction="row" spacing={2} sx={{
        pb: '16px',
        pt: '24px',
        pr: '15px',
        justifyContent: 'right'
      }}>
          <Button id="edit-market-button-cancel" onClick={() => {
          setShowEditModal(false);
          setFieldHasBeenAdded(false);
        }} sx={{
          textTransform: 'none',
          display: 'inline-flex',
          height: '32px',
          width: '80px',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '10px',
          flexShrink: 0,
          p: '10px 24px',
          borderRadius: '20px',
          background: '#F3F3F4',
          '&:hover': {
            background: '#F3F3F4'
          }
        }}>
            Cancel
          </Button>
          <Button
        // disabled={!(canSave || fieldHasBeenAdded)}
        id="edit-market-button-save" variant="contained" onClick={() => {
          handleEditMarket();
          // setFieldHasBeenAdded(false);
        }} sx={{
          height: '32px',
          width: '80px',
          p: '10px 24px'
        }}>
            {fieldHasBeenAdded && !canSave ? 'Done' : 'Save'}
          </Button>
        </Stack>
      </Dialog>
    </>;
};