import React, { Component } from 'react';
import { Scoped } from 'kremling';
import styles from './integrations.styles.scss';
import { UserStateContext } from 'context/user-state-context';
import { Button } from '../../components/button/button.component';
import { createCredentials as createCredentialsAPI, updateCredential as updateCredentialsAPI, deleteCredential as deleteCredentialsAPI } from './integrations.resource';
import { toasterService } from '../../components/toaster/toaster-service';
import { ModalDialog } from '../../components/modal/modal-dialog.component';
const INTEGRATION_KEY = 'autodata';
export class IntegrationAutoData extends Component {
  static contextType = UserStateContext;
  static propTypes = {};
  state = {
    deleteCredential: {},
    updateCredential: {},
    isCredentialModalOpen: false,
    identifier: null,
    stores: []
  };
  addCredential = (identifier, stores) => {
    return createCredentialsAPI({
      identifier,
      entity_data: {
        stores
      },
      company: this.context.asCompany.id,
      integration: INTEGRATION_KEY
    }).then(() => toasterService.success('Successfully added credentials. Syncing will begin soon.')).then(() => this.props.reload()).catch(err => {
      if (err.response.data.non_field_errors) {
        toasterService.error(err.response.data.non_field_errors[0]);
      } else {
        toasterService.error('Unknown error trying to add credentials. Please try again.');
      }
    });
  };
  updateCredential = credential => {
    if (credential.entity_data && credential.entity_data.stores) {
      credential.entity_data.stores = credential.entity_data.stores.filter(store => store !== '');
    }
    return updateCredentialsAPI(credential.id, {
      identifier: credential.identifier,
      entity_data: credential.entity_data
    }).then(() => toasterService.success('Successfully updated credentials.')).then(() => this.props.reload()).catch(err => {
      if (err.response.data.site_id) {
        toasterService.error(err.response.data.site_id[0]);
      } else {
        toasterService.error('Unknown error trying to update credentials. Please try again.');
      }
    });
  };
  removeCredentials = credentialsId => {
    return deleteCredentialsAPI(credentialsId).then(() => toasterService.success('Successfully deleted credentials')).then(() => this.props.reload()).catch(() => toasterService.error('Unknown error to trying delete credentials. Please try again.'));
  };
  render() {
    const {
      isCredentialModalOpen,
      identifier,
      deleteCredential,
      updateCredential,
      stores
    } = this.state;
    const {
      credentials
    } = this.props;
    return <Scoped css={styles}>
        <div className="integration mb-5">
          <div className="integration__header">
            <div>Auto Data</div>
            {this.context.hasPermission('integration.add_credential') && <Button className="btn" actionType="primary" tag="a" onClick={() => this.setState({
            isCredentialModalOpen: true,
            identifier: null,
            stores: []
          })}>
                Add Credentials
              </Button>}
          </div>
          <div className="integration__body">
            {credentials.map(credential => <div className="integration-item" key={credential.id}>
                <div className="integration-item__inner">
                  <div className="integration-title">
                    {credential.identifier}
                  </div>
                  <div className="integration-actions">
                    {this.context.hasPermission('integration.change_credential') && <Button actionType="flat" icon="fa-regular-edit" onClick={e => {
                  e.stopPropagation();
                  this.setState({
                    updateCredential: credential
                  });
                }} />}

                    {this.context.hasPermission('integration.delete_credential') && <Button actionType="flat" icon="fa-regular-trash" onClick={e => {
                  e.stopPropagation();
                  this.setState({
                    deleteCredential: credential
                  });
                }} />}
                  </div>
                </div>
              </div>)}
          </div>

          <ModalDialog open={!!deleteCredential.id} title="Remove Credential" submitText="Remove" onSubmit={() => this.removeCredentials(deleteCredential.id)} onClose={() => this.setState({
          deleteCredential: {}
        })} allowBackdropClick>
            <p>
              <strong>{deleteCredential.identifier}</strong>
            </p>
            <p>Are you sure you want to remove this credential?</p>
          </ModalDialog>

          <ModalDialog open={isCredentialModalOpen} title="Add Credential" onSubmit={() => this.addCredential(identifier, stores)} onClose={() => this.setState({
          isCredentialModalOpen: false
        })} allowBackdropClick>
            <p>
              Please enter the database name for this legacy Auto Data account.
            </p>
            <p>
              <label>Database Name</label>
              <input required className="form-control" name="identifier" onChange={e => this.setState({
              identifier: e.target.value
            })} />
            </p>
            <p>Optionally limit the stores you want to import.</p>
            <div className="d-flex justify-content-between">
              <label>Stores</label>
              <Button actionType="flat" className="mb-2" onClick={() => {
              stores.push('');
              this.setState({
                stores
              });
            }}>
                Add Store
              </Button>
            </div>
            {!stores.length && <em>Import all stores.</em>}
            {stores.map((store, i) => <div className="d-flex align-items-center" key={`addStore${i}`}>
                <input required className="form-control mt-1" name="storeNum" value={store} onChange={e => {
              stores[i] = e.target.value;
              this.setState({
                stores
              });
            }} />
                <Button icon="fa-regular-times" small actionType="flat" onClick={() => {
              stores.splice(i, 1);
              this.setState({
                stores
              });
            }} />
              </div>)}
          </ModalDialog>

          <ModalDialog open={!!updateCredential.id} title="Update Credential" submitText="Update" onSubmit={() => this.updateCredential(updateCredential)} onClose={() => this.setState({
          updateCredential: {}
        })} allowBackdropClick>
            <p>Please update your legacy Auto Data information below.</p>
            <p>
              <label>Database Name</label>
              <input required className="form-control" name="identifier" defaultValue={updateCredential.identifier || ''} onChange={e => {
              updateCredential.identifier = e.target.value;
              this.setState({
                updateCredential
              });
            }} />
            </p>
            <p>Optionally limit the stores you want to import.</p>
            <div className="d-flex justify-content-between">
              <label>Stores</label>
              <Button actionType="flat" className="mb-2" onClick={() => {
              if (!updateCredential.entity_data || !updateCredential.entity_data.stores) {
                updateCredential.entity_data.stores = [];
              }
              updateCredential.entity_data.stores.push('');
              this.setState({
                updateCredential
              });
            }}>
                Add Store
              </Button>
            </div>
            {(!updateCredential.entity_data || !updateCredential.entity_data.stores || !updateCredential.entity_data.stores.length) && <em>Import all stores.</em>}
            {updateCredential.entity_data && updateCredential.entity_data.stores && updateCredential.entity_data.stores.map((store, i) => <div className="d-flex align-items-center" key={`updateStore${i}`}>
                  <input required className="form-control mt-1" name="storeNum" value={store} onChange={e => {
              updateCredential.entity_data.stores[i] = e.target.value;
              this.setState({
                updateCredential
              });
            }} />
                  <Button icon="fa-regular-times" small actionType="flat" onClick={() => {
              updateCredential.entity_data.stores.splice(i, 1);
              this.setState({
                updateCredential
              });
            }} />
                </div>)}
          </ModalDialog>
        </div>
      </Scoped>;
  }
}