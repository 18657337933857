import React, { useContext, useEffect } from 'react';
import { runCustomPicklistSegmentQuery } from '../../shared/common.api';
import { snackbarService } from 'components/mui/snackbar/snackbar-service';
import { Box, Button, IconButton, Tooltip, Typography, InputAdornment, Divider, InputLabel, Select, MenuItem, FormControl } from '@mui/material';
import Stack from '@mui/material/Stack';
import { UserStateContext } from 'context/user-state-context';
import { Icon } from '../../components/icon/icon.component';
import SelectMUI from 'components/mui/select/select.component';
import { ArrowCircleRightIcon, RemoveCircleIcon } from 'components/mui';
import { CustomTextField } from './custom-text-field.component';
import { fieldFields } from './consts';
export const EditSegmentAdvanced = ({
  segment,
  updateSegment,
  setSegment,
  setIsValid
}) => {
  const {
    asCompany,
    hasPermission,
    user
  } = useContext(UserStateContext);
  const [customPicklistSQLIsLoading, setCustomPicklistSQLIsLoading] = React.useState(null);
  useEffect(() => {
    setIsValid(!!segment.segment_query && segment.segment_query !== '');
  }, [segment?.segment_query]);
  const updateField = (key, val, index) => {
    const newSegment = JSON.parse(JSON.stringify(segment));
    if (key === 'variable' && newSegment.values && newSegment.values[newSegment.fields[index].variable]) {
      // Change variable key name to match
      newSegment.values[val] = newSegment.values[newSegment.fields[index].variable];
      delete newSegment.values[newSegment.fields[index].variable];
    }
    newSegment.fields[index][key] = val;
    if (key === 'type' && val === 'picklist') {
      newSegment.fields[index].options = [''];
    } else if (key === 'type' && val !== 'picklist') {
      newSegment.fields[index].options = [];
    } else if (key === 'type' && val === 'picklist-custom') {
      newSegment.fields[index].options = [''];
    }
    setSegment(newSegment);
  };
  const addField = () => {
    const newSegment = JSON.parse(JSON.stringify(segment));
    if (!newSegment.fields) {
      newSegment.fields = [];
    }
    newSegment.fields.push({
      variable: '',
      label: '',
      type: 'text',
      options: [],
      description: ''
    });
    setSegment(newSegment);
  };
  const updateValue = (key, val) => {
    const newSegment = JSON.parse(JSON.stringify(segment));
    if (!newSegment.values) {
      newSegment.values = {};
    }
    newSegment.values[key] = val;
    setSegment(newSegment);
  };
  const addPicklistOption = index => {
    const newSegment = JSON.parse(JSON.stringify(segment));
    newSegment.fields[index].options.push('');
    setSegment(newSegment);
  };
  const updateOption = (fieldIndex, optionIndex, val) => {
    const newSegment = JSON.parse(JSON.stringify(segment));
    if (!optionIndex) {
      // custom picklist option
      newSegment.fields[fieldIndex].options[0] = val;
    } else {
      newSegment.fields[fieldIndex].options[optionIndex] = val;
    }
    setSegment(newSegment);
  };
  const removeField = index => {
    const newSegment = JSON.parse(JSON.stringify(segment));
    if (newSegment.values && newSegment.values[newSegment.fields[index].variable]) {
      // Remove old value
      delete newSegment.values[newSegment.fields[index].variable];
    }
    newSegment.fields.splice(index, 1);
    setSegment(newSegment);
  };
  const removePicklistOption = (fieldIndex, optionIndex) => {
    const newSegment = JSON.parse(JSON.stringify(segment));
    if (newSegment.fields && newSegment.fields[fieldIndex] && newSegment.fields[fieldIndex].options) {
      newSegment.fields[fieldIndex].options.splice(optionIndex, 1);
    }
    setSegment(newSegment);
  };
  const getPicklistOptions = field => {
    const options = [];
    if (field.options.indexOf('') === -1) {
      options.push({
        key: '',
        name: ''
      });
    }
    field.options.map((option, index) => options.push({
      key: `value${index}option${index}`,
      name: option
    }));
    return options;
  };
  const getCustomPicklistValue = (field, index) => {
    if (!segment.values || !segment.values[field.variable]) {
      return '';
    }
    return segment.values[field?.variable];
  };
  const getPicklistValue = field => {
    if (!segment.values || !field?.variable || !segment.values[field.variable]) {
      return '';
    }
    const index = field.options.indexOf(segment.values[field.variable]);
    return index === -1 ? '' : `value${index}option${index}`;
  };
  const updateSQLOption = (fieldIndex, val) => {
    const newSegment = JSON.parse(JSON.stringify(segment));
    newSegment.fields[fieldIndex].sql = val;
    setSegment(newSegment);
  };
  const runCustomSQL = (query, index) => {
    setCustomPicklistSQLIsLoading(index);
    runCustomPicklistSegmentQuery({
      query: query,
      company_id: asCompany.id
    }).then(data => {
      const newSegment = JSON.parse(JSON.stringify(segment));
      newSegment.fields[index].options = data.data;
      setSegment(newSegment);
      setCustomPicklistSQLIsLoading(null);
    }).catch(error => {
      console.log('error:', error);
      snackbarService.popup({
        type: 'error',
        message: "Couldn't run the SQL query"
      });
      setCustomPicklistSQLIsLoading(null);
    });
  };
  return <Stack spacing="30px">
      {hasPermission('customer.manage_advanced_sql') ? <Stack spacing="30px">
          <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{
        width: '100%'
      }}>
            <Typography sx={{
          fontFamily: 'Nexa',
          fontSize: 20,
          color: '#1D252D'
        }}>Segment Query </Typography>
          </Box>

          <Box>
            <CustomTextField onChange={e => updateSegment('segment_query', e.target.value)} name="segmentQuery" rows={3} sx={{
          width: '50%'
        }} multiline value={segment.segment_query || ''} InputLabelProps={{
          style: {
            color: '#BEBEBE'
          }
        }} />
          </Box>

          {user.is_superuser && <Box>
              <FormControl sx={{
          width: '25%',
          minWidth: '300px'
        }}>
                <InputLabel id="target_database-label">Target Database</InputLabel>
                <Select labelId="target_database-label" id="target_database" value={segment?.target_database || 'postgres'} label="target_database____" onChange={e => {
            updateSegment('target_database', e.target.value);
          }}>
                  <MenuItem value="postgres">Postgres </MenuItem>
                  <MenuItem disabled={!segment?.time_dynamic_segment} value="snowflake">
                    Snowflake
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>}
          <Divider />
          <Stack spacing="30px">
            <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{
          width: '100%'
        }}>
              <Typography sx={{
            fontFamily: 'Nexa',
            fontSize: 20,
            color: '#1D252D'
          }}>Fields </Typography>{' '}
            </Box>
            <Button sx={{
          width: '122px'
        }} variant="text" onClick={addField}>
              <Stack display="flex" alignItems="center" direction="row" spacing="8px">
                {' '}
                <Icon fill="#53A6D6" name="custom-add_circle_nofill" />
                <Typography sx={{
              textTransform: 'none',
              color: '#53A6D6'
            }}>Add Field</Typography>{' '}
              </Stack>
            </Button>
            <Stack sx={{
          px: '20px'
        }} spacing="20px">
              {segment.fields && segment.fields.length > 0 && segment.fields.map((field, index) => <Stack key={index} spacing="20px" direction="row">
                    <CustomTextField sx={{
              width: '216px'
            }} placeholder="SQL Variable" label={field.variable ? 'SQL Variable' : ''} value={field.variable || ''} onChange={e => updateField('variable', e.target.value, index)} InputLabelProps={{
              style: {
                color: '#BEBEBE'
              }
            }} />{' '}
                    <CustomTextField sx={{
              width: '216px'
            }} placeholder="Label" label={field.label ? 'Label' : ''} value={field.label || ''} onChange={e => updateField('label', e.target.value, index)} InputLabelProps={{
              style: {
                color: '#BEBEBE'
              }
            }} />
                    <SelectMUI label="Field" placeholder="Field" top="17px" options={fieldFields} value={field.type || ''} onChange={opt => updateField('type', opt.key, index)} />
                    {field.type === 'picklist' && <Stack spacing="20px">
                        {field.options.map((option, optionIndex) => <Stack key={`field${index}option${optionIndex}`} direction="row" spacing="20px">
                            <CustomTextField sx={{
                  width: '216px'
                }} placeholder={'Option ' + (optionIndex + 1)} label={option ? 'Option ' + (optionIndex + 1) : ''} value={option} onChange={e => updateOption(index, optionIndex, e.target.value)} InputLabelProps={{
                  style: {
                    color: '#BEBEBE'
                  }
                }} />
                            {optionIndex > 0 && <Box>
                                <IconButton sx={{
                    width: '40px',
                    height: '40px'
                  }} onClick={() => removePicklistOption(index, optionIndex)}>
                                  <RemoveCircleIcon />
                                </IconButton>
                              </Box>}
                          </Stack>)}

                        <Button sx={{
                width: '130px'
              }} variant="text" onClick={() => addPicklistOption(index)}>
                          <Stack display="flex" alignItems="center" direction="row" spacing="8px">
                            <Icon fill="#53A6D6" name="custom-add_circle_nofill" />
                            <Typography sx={{
                    textTransform: 'none',
                    color: '#53A6D6'
                  }}>Add Option</Typography>
                          </Stack>
                        </Button>
                      </Stack>}
                    {field.type === 'picklist-custom' && <>
                        <Stack spacing="20px">
                          <CustomTextField id={`custom-sql-${index}`} placeholder="Enter custom SQL" label={field.sql ? 'Custom Picklist' : ''} value={field.sql} onChange={e => {
                  updateSQLOption(index, e.target.value);
                }} InputProps={{
                  endAdornment: <Tooltip title="Run SQL (select ordering should be ID, DisplayValue">
                                  <InputAdornment position="start">
                                    <IconButton sx={{
                        cursor: 'pointer'
                      }} onClick={e => {
                        runCustomSQL(segment.fields[index].sql, index);
                      }} disabled={segment?.fields[index]?.sql == '' || !segment?.fields[index]?.sql}>
                                      <ArrowCircleRightIcon />
                                    </IconButton>
                                  </InputAdornment>
                                </Tooltip>
                }} />
                        </Stack>
                      </>}
                    <CustomTextField multiline label={segment.description ? 'Description' : ''} placeholder="Description" sx={{
              maxWidth: '504px'
            }} onChange={e => updateField('description', e.target.value, index)} value={field.description} InputLabelProps={{
              style: {
                color: '#BEBEBE'
              }
            }} InputProps={{
              startAdornment: <InputAdornment position="start">
                            <Icon fill="#1D252D" name="custom-description" />
                          </InputAdornment>
            }} />
                    <Box sx={{
              position: 'relative',
              top: '5px'
            }}>
                      <IconButton sx={{
                width: '40px',
                height: '40px'
              }} onClick={() => removeField(index)}>
                        <RemoveCircleIcon />
                      </IconButton>
                    </Box>
                  </Stack>)}
            </Stack>
            <Stack spacing="30px">
              <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{
            width: '100%'
          }}>
                <Typography sx={{
              fontFamily: 'Nexa',
              fontSize: 20,
              color: '#1D252D'
            }}>Values </Typography>{' '}
              </Box>

              {segment.fields && segment.fields.length > 0 && segment.fields.map((field, index) => <Stack display="flex" alignItems="center" sx={{
            px: '20px'
          }} key={`value${index}`} direction="row" spacing="20px">
                    <Box sx={{
              width: '200px'
            }}>
                      {field.label ? <strong>{field.label}</strong> : <Typography sx={{
                color: '#1D252D80'
              }}>No label</Typography>}
                    </Box>

                    <Box>
                      {field.type === 'picklist' && <>
                          <SelectMUI top="17px" label={field.label} placeholder="Select Option" value={getPicklistValue(field)} options={getPicklistOptions(field)} onChange={opt => {
                  updateValue(field.variable, opt.name);
                }} />
                        </>}
                      {field.type === 'picklist-custom' && <>
                          <SelectMUI loading={customPicklistSQLIsLoading == index} top="17px" label={field.label} placeholder={segment?.fields[index]?.options?.length == 0 ? 'Run SQL to get options' : 'Select Option'} value={getCustomPicklistValue(field, index)} options={segment.fields[index].options || []} onChange={opt => {
                  updateValue(field.variable, opt.key);
                }} />
                        </>}
                      {field.type === 'numeric' && <CustomTextField sx={{
                width: '216px'
              }} label={field.label ? field.label : ''} type="number" value={segment.values && segment.values[field.variable] || ''} onChange={e => {
                updateValue(field.variable, e.target.value);
              }} InputLabelProps={{
                style: {
                  color: '#BEBEBE'
                }
              }} />}
                      {field.type === 'text' && <CustomTextField sx={{
                width: '216px'
              }} label={field.label ? field.label : ''} value={segment.values && segment.values[field.variable] || ''} onChange={e => {
                updateValue(field.variable, e.target.value);
              }} InputLabelProps={{
                style: {
                  color: '#BEBEBE'
                }
              }} />}
                    </Box>
                    <Box> {field.description || 'No description'}</Box>
                  </Stack>)}
            </Stack>
          </Stack>
        </Stack> : <Stack spacing="30px">
          <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{
        width: '100%'
      }}>
            <Typography sx={{
          fontFamily: 'Nexa',
          fontSize: 20,
          color: '#1D252D'
        }}>Values </Typography>{' '}
          </Box>

          {segment.fields && segment.fields.length > 0 && segment.fields.map((field, index) => <Stack display="flex" alignItems="center" sx={{
        px: '20px'
      }} key={`value${index}`} direction="row" spacing="20px">
                <Box>
                  {field.type === 'picklist' && <>
                      <SelectMUI top="17px" label={field.label} placeholder="Select Option" value={getPicklistValue(field)} options={getPicklistOptions(field)} onChange={opt => {
              updateValue(field.variable, opt.name);
            }} />
                    </>}

                  {field.type === 'picklist-custom' && <>
                      <SelectMUI top="17px" label={field.label} placeholder="Select Option" value={getCustomPicklistValue(field, index)} options={segment.fields[index].options || []} onChange={opt => {
              updateValue(field.variable, opt.key);
            }} />
                    </>}

                  {field.type === 'numeric' && <CustomTextField sx={{
            width: '216px'
          }} label={field.label ? field.label : ''} type="number" value={segment.values && segment.values[field.variable] || ''} onChange={e => updateValue(field.variable, e.target.value)} InputLabelProps={{
            style: {
              color: '#BEBEBE'
            }
          }} />}
                  {field.type === 'text' && <CustomTextField sx={{
            width: '216px'
          }} label={field.label ? field.label : ''} value={segment.values && segment.values[field.variable] || ''} onChange={e => updateValue(field.variable, e.target.value)} InputLabelProps={{
            style: {
              color: '#BEBEBE'
            }
          }} />}
                </Box>
                <Box> {field.description}</Box>
              </Stack>)}
        </Stack>}
    </Stack>;
};