import React, { useState, useRef, createContext, createElement } from 'react';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';
import { registerDeviceToFirebase } from 'shared/common.api';
export const BrowserNotificationsContext = createContext();
export const BrowserNotificationsProvider = ({
  children
}) => {
  const requestPermissionToNotify = () => {
    Notification.requestPermission().then(permission => {
      if (permission === 'granted') {
        const app = initializeApp(FIREBASE_CONFIG);
        const messaging = getMessaging(app);
        getToken(messaging, {
          vapidKey: FIREBASE_VAPID_KEY
        }).then(currentToken => {
          if (currentToken) {
            registerDeviceToFirebase({
              registration_id: currentToken,
              type: 'web'
            }).then(e => {});
          } else {
            //console.log('No registration token available. Request permission to generate one.');
          }
        }).catch(err => {
          //console.log('An error occurred while retrieving token. ', err);
        });
      } else {
        //console.log('Notification permission blocked.');
      }
    });
  };
  return <BrowserNotificationsContext.Provider value={{
    requestPermissionToNotify
  }}>{children}</BrowserNotificationsContext.Provider>;
};