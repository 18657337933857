import React from 'react';
import { Button, Grid } from '@mui/material';
import BackspaceIcon from '@mui/icons-material/Backspace';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
export const ManagedBroadcastConfirmationFooter = ({
  saveManagedBroadcast,
  values,
  setShowSaveConfirmation
}) => {
  return <Grid container style={{
    padding: '20px 24px '
  }}>
      <Grid item xs>
        <Grid container direction="row-reverse">
          <Button onClick={async () => {
          await saveManagedBroadcast(values);
        }} startIcon={<ThumbUpAltIcon />}>
            Confirm
          </Button>
          <Button onClick={() => {
          setShowSaveConfirmation(false);
        }} startIcon={<BackspaceIcon />} style={{
          marginLeft: '1rem'
        }}>
            Back To Editing
          </Button>
        </Grid>
      </Grid>
    </Grid>;
};