import React from 'react';
import { UserStateContextProvider } from './user-state-context';
import { CustomerDrawerContextProvider } from './customer-drawer-context';
import { ModalContextProvider } from './modal-context';
import { BrowserNotificationsProvider } from './browser-notifications';
export const ContextProviders = ({
  children
}) => {
  return <UserStateContextProvider>
      <CustomerDrawerContextProvider>
        <ModalContextProvider>
          <BrowserNotificationsProvider>{children}</BrowserNotificationsProvider>
        </ModalContextProvider>
      </CustomerDrawerContextProvider>
    </UserStateContextProvider>;
};