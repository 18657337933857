import React, { useContext } from 'react';
import { PageHeaderMui } from 'components';
import { getSegments, editSegment, getSegmentsTags, getSegmentCreatedByUsers, getInvoices } from '../../shared/common.api';
import moment from 'moment';
import EnhancedTable from '../../components/mui/enhanced-table/enhanced-table.component';
import CssBaseline from '@mui/material/CssBaseline';
import { Icon } from '../../components/icon/icon.component';
import { history } from '../../root.component';
import { Scoped } from 'kremling';
import styles from './invoices.styles.scss';
import { NewButton } from 'components/mui/button/new.component';
import { UserStateContext } from 'context/user-state-context';
export const Invoices = () => {
  const {
    token,
    asCompany
  } = useContext(UserStateContext);
  const headCells = [{
    key: 'TxnDate',
    label: 'Date',
    width: '20%',
    displayComponent: ({
      data
    }) => <>{data.TxnDate}</>
  }, {
    key: 'DocNumber',
    label: 'Invoice',
    width: '20%',
    displayComponent: ({
      data
    }) => <>#{data.DocNumber}</>
  }, {
    key: 'TotalAmt',
    label: 'Amount',
    width: '20%',
    displayComponent: ({
      data
    }) => <>{data.TotalAmt.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD'
      })}
      </>
  }, {
    key: 'Status',
    label: 'Status',
    width: '40%',
    noSort: true,
    displayComponent: ({
      data
    }) => {
      if (data.Balance === 0) {
        return <>Paid</>;
      }
      const dueDate = moment(data.DueDate);
      const today = moment();
      const daysLeft = dueDate.diff(today, 'days');
      if (daysLeft === 0) {
        return <>Due today</>;
      }
      if (daysLeft > 0) {
        return <>Due {daysLeft} days</>;
      }
      return <>Overdue {Math.abs(daysLeft)} days</>;
    }
  }];
  const handleRowClick = row => {
    // Download Invoice PDF
    window.open(`${API_URL}/billing/invoice/${row.id}?company=${asCompany.id}&_token=${token}`, '_blank');
  };
  return <>
      <CssBaseline />
      <Scoped css={styles}>
        <div className="wrapper">
          <PageHeaderMui type="Billing" icon={<Icon name="custom-segment" size={24} />} />

          <div className="mui-wrapper">
            <EnhancedTable type="Invoices" headCells={headCells} api={getInvoices} defaultSort={'-TxnDate'} handleRowClick={handleRowClick} />
          </div>
        </div>
      </Scoped>
    </>;
};