import React, { useState, useEffect, useContext } from 'react';
import CssBaseline from '@mui/material/CssBaseline'; // TODO do we need this...?
import { UserStateContext } from 'context/user-state-context';
import { cloneDeep } from 'lodash';
import { SearchIcon, CloseIcon } from 'components/mui';
import { Stack, Paper, Grid, Table, TableHead, TableBody, TableRow, TableCell, TableContainer, Checkbox, Typography, Button, Box, Dialog, DialogTitle, DialogContent, DialogActions, Divider, Tooltip, IconButton, TextField, InputAdornment } from '@mui/material';
import { createMotorOemConfigDetail, deleteMotorOemConfigDetail } from 'shared/common.api';
import { history } from '../../../root.component';
import { withStyles } from '@mui/styles';
const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      padding: 0,
      backgroundColor: 'white',
      borderRadius: `14px`,
      '& fieldset': {
        border: 'none',
        padding: 0
      }
    },
    '& .MuiOutlinedInput-input': {
      padding: '10px'
    }
  }
})(TextField);

/**
 * Displays recommendations to the user and allows them to check / uncheck recommendations
 * at any point in time.
 *
 * If a user unchecks a value they've previously configure, we warn them we're
 * deleting a partially or fully configured recommendation.
 */
export const MotorOemRecommendations = ({
  configuration,
  recommendations,
  configurationDetails,
  setConfigurationDetails,
  setPage
}) => {
  const {
    asCompany
  } = useContext(UserStateContext);
  const [originalConfigurationDetails, setOriginalConfigurationDetails] = useState(cloneDeep(configurationDetails));
  const [showRemovedConfigModal, setShowRemovedConfigModal] = useState(false);
  const [missingKeys, setMissingKeys] = useState([]);
  const [openSearch, setOpenSearch] = useState(false);
  const [search, setSearch] = useState('');
  const [filteredRecommendations, setFilteredRecommendations] = useState(cloneDeep(recommendations));
  useEffect(() => {
    if (search) {
      const matchingRecommendations = [];
      const lowerCaseSearch = search.toLowerCase();
      for (const recommendation of recommendations) {
        if (
        // TODO non performant when using 100+ recommendations
        recommendation.description.toLowerCase().includes(lowerCaseSearch) || recommendation.action.toLowerCase().includes(lowerCaseSearch) || recommendation.full_name.toLowerCase().includes(lowerCaseSearch) || recommendation.group.toLowerCase().includes(lowerCaseSearch) || recommendation.subgroup.toLowerCase().includes(lowerCaseSearch) || recommendation.system.toLowerCase().includes(lowerCaseSearch)) {
          matchingRecommendations.push(recommendation);
        }
      }
      setFilteredRecommendations(matchingRecommendations);
    } else {
      // reset
      setFilteredRecommendations(cloneDeep(recommendations));
    }
  }, [search]);
  const handleOpeningSearch = () => {
    setOpenSearch(!openSearch);
  };
  const isChecked = id => {
    if (configurationDetails[id]) {
      return true;
    }
    return false;
  };
  const changeCheckedState = recommendation => {
    if (isChecked(recommendation.id)) {
      const copyOfConfigDetails = {
        ...configurationDetails
      };
      delete copyOfConfigDetails[recommendation.id];
      setConfigurationDetails(copyOfConfigDetails);
    } else {
      const newConfigDetail = recommendationToConfigDetails(recommendation);
      const copyOfConfigDetails = {
        ...configurationDetails,
        ...newConfigDetail
      };
      setConfigurationDetails(copyOfConfigDetails);
    }
  };

  /**
   * Auto populates newly selected records with a rank and
   * makes sure to not step on the toes of other ranks
   * @returns {null}
   */

  const populateRanking = () => {
    const currentRanks = [];
    let configurationCount = 0;
    for (const [recommendationId, configDetails] of Object.entries(configurationDetails)) {
      if (configDetails.rank) {
        currentRanks.push(configDetails.rank);
      }
      configurationCount++;
    }
    // since this is called pre-save add an extra to know full available
    configurationCount++;
    let availableRank = null;
    for (let rank = 1; rank <= configurationCount; rank++) {
      if (!currentRanks.includes(rank)) {
        availableRank = rank;
        break;
      }
    }
    if (availableRank) {
      return availableRank;
    }
    return 0;
  };

  /**
   * Translates a recommendation into a default (non saved)
   * configurationDetail object which is used throughout components
   * to do CRUD against configDetails
   */
  const recommendationToConfigDetails = recommendation => {
    const configDetail = {};
    configDetail[recommendation.id] = {
      id: null,
      name: recommendation.full_name,
      rank: populateRanking(),
      title: recommendation.group,
      description: recommendation.description,
      url: '',
      // mui gets upset at nulls as a default value
      config: configuration.id
    };
    return configDetail;
  };

  /**
   * We prompt the user if they're no longer including configurations
   * which they had already saved / setup.
   */
  const handleNextClick = async () => {
    const removedKeys = [];
    for (const recommendationId of Object.keys(originalConfigurationDetails)) {
      if (!configurationDetails[recommendationId]) {
        removedKeys.push(recommendationId);
      }
    }
    if (removedKeys.length > 0) {
      setMissingKeys(removedKeys);
      setShowRemovedConfigModal(true);
      return;
    }
    await continueToNextPage();
  };
  const saveNewConfigurationDetails = async () => {
    const copyOfConfigurationDetails = {
      ...configurationDetails
    };
    for (const [recommendationId, configDetail] of Object.entries(copyOfConfigurationDetails)) {
      if (!configDetail.id) {
        const payload = {
          company: asCompany.id,
          config: configDetail.config,
          description: configDetail.description,
          rank: configDetail.rank,
          title: configDetail.title,
          url: configDetail.url,
          recommendation: recommendationId
        };
        const response = await createMotorOemConfigDetail(payload);
        configDetail.id = response.data.id;
      }
    }
    setConfigurationDetails(copyOfConfigurationDetails);
  };
  const continueToNextPage = async () => {
    await saveNewConfigurationDetails();
    setPage(2);
  };
  const deleteUncheckedConfigDetails = async () => {
    for (const recommendationId of missingKeys) {
      const configToDelete = originalConfigurationDetails[recommendationId];
      await deleteMotorOemConfigDetail(configToDelete.id);
    }
  };
  return <>
      <CssBaseline />
      <div className="wrapper">
        <Stack sx={{
        p: '20px'
      }} spacing={'20px'} padding={'3rem'} alignItems={'center'}>
          <Paper sx={{
          width: '100%',
          padding: '5px'
        }}>
            <Box sx={{
            display: 'flex',
            justifyContent: 'space-between'
          }}>
              <Stack sx={{
              margin: 1
            }} direction="row" spacing={'10px'} alignItems={'center'}>
                <Typography sx={{
                fontSize: 18
              }}>1. Select Your Services |</Typography>
                <Typography sx={{
                fontSize: 14
              }}>
                  You may not offer every service the OEM recommends. Please select the services that are relevant to your business.
                </Typography>
              </Stack>
              <Stack direction={'row'} spacing={'10px'} sx={{
              pr: '20px'
            }} alignItems={'center'}>
                <Button sx={{
                width: '115px',
                height: '30px'
              }} variant="outlined" onClick={() => {
                history.goBack();
              }}>
                  Cancel
                </Button>
                <Button sx={{
                width: '115px',
                height: '30px'
              }} variant="contained" onClick={async () => {
                await handleNextClick();
              }}>
                  Next
                </Button>
              </Stack>
            </Box>
          </Paper>
        </Stack>
        <Stack sx={{
        p: '20px'
      }} spacing={'20px'}>
          <Paper sx={{
          borderRadius: '14px',
          minHeight: '75vh'
        }}>
            <Stack sx={{
            pt: '8px'
          }} spacing={'20px'}>
              <Grid sx={{
              px: '8px'
            }} container spacing={{
              xs: 2,
              sm: 2,
              md: 3,
              lg: 3
            }} columns={{
              xs: 4,
              sm: 12,
              md: 12,
              lg: 12
            }}>
                {/* Start Search Component */}
                <Grid sx={{
                padding: '2px'
              }} container justifyContent={'flex-end'}>
                  <Box>
                    <Stack spacing={'20px'} direction={'row'} sx={{
                    pr: '20px',
                    ml: 'auto'
                  }}>
                      <Divider orientation="vertical" sx={{
                      height: '32px'
                    }} />
                      {openSearch ? <Box sx={{
                      height: '42px'
                    }}>
                          <CustomTextField id="outlined-basic" autoFocus={true} placeholder={'Search'} sx={{
                        width: '290px',
                        height: '56px',
                        pr: '20px',
                        py: 0
                      }} onChange={e => {
                        setSearch(e.target.value || '');
                      }} value={search || ''} InputProps={{
                        startAdornment: <InputAdornment position="start">
                                  <Tooltip title="Collapse Search">
                                    <IconButton sx={{
                              width: '40px',
                              height: '40px',
                              textAlign: 'center',
                              padding: 0,
                              backgroundColor: '#fff',
                              color: '#1D252D',
                              ':hover': {
                                backgroundColor: '#DAEFFF',
                                color: '#53A6D6'
                              }
                            }} onClick={() => {
                              handleOpeningSearch();
                            }}>
                                      <SearchIcon />
                                    </IconButton>
                                  </Tooltip>
                                </InputAdornment>,
                        endAdornment: !!search && <InputAdornment onClick={e => {
                          setSearch('');
                        }} position="start" sx={{
                          cursor: 'pointer'
                        }}>
                                  <CloseIcon />
                                </InputAdornment>
                      }} />
                        </Box> : <Tooltip title={'Search'}>
                          <IconButton sx={{
                        width: '40px',
                        height: '40px',
                        textAlign: 'center',
                        padding: 0,
                        backgroundColor: '#fff',
                        color: '#1D252D',
                        ':hover': {
                          backgroundColor: '#DAEFFF',
                          color: '#53A6D6'
                        }
                      }} onClick={() => {
                        handleOpeningSearch();
                      }}>
                            <SearchIcon />
                          </IconButton>
                        </Tooltip>}
                      <Divider orientation="vertical" sx={{
                      height: '32px'
                    }} />
                      <Box sx={{
                      display: 'flex',
                      alignItems: 'center',
                      marginRight: '5px'
                    }}>
                        {filteredRecommendations.length + ' items'}
                      </Box>
                    </Stack>
                  </Box>
                </Grid>
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell sortDirection={'asc'}>System</TableCell>
                        <TableCell sortDirection={'asc'}>Group</TableCell>
                        <TableCell>Subgroup</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Action</TableCell>
                        <TableCell>Full Name</TableCell>
                        <TableCell>Include</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {/* TODO mimic the enhanced table TODO fix this logic...*/}
                      {!filteredRecommendations ? <TableRow>
                          <TableCell colSpan={7}>No Data</TableCell>
                        </TableRow> :
                    // TODO map if there's none
                    filteredRecommendations.map(recommendation => {
                      return <TableRow key={recommendation.id} sx={{
                        height: '10px'
                      }}>
                              {/* TODO get sorting working, can't search but I think it must be something like this....*/}
                              <TableCell sx={{
                          padding: '4px'
                        }} sortDirection={'asc'}>
                                {recommendation.system}
                              </TableCell>
                              <TableCell>{recommendation.group}</TableCell>
                              <TableCell>{recommendation.subgroup}</TableCell>
                              <TableCell>{recommendation.description}</TableCell>
                              <TableCell>{recommendation.action}</TableCell>
                              <TableCell>{recommendation.full_name}</TableCell>
                              <TableCell>
                                <Checkbox sx={{
                            color: '#1D252D',
                            '&.Mui-checked': {
                              color: '#53A6D6'
                            },
                            '& .MuiSvgIcon-root': {
                              fontSize: 22
                            }
                          }} color="primary" checked={isChecked(recommendation.id)} // TODO control
                          onClick={() => {
                            changeCheckedState(recommendation);
                          }} />
                              </TableCell>
                            </TableRow>;
                    })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Stack>
          </Paper>
        </Stack>
      </div>

      {/* Modal for informing the user they're unchecking a previously saved configuration*/}
      <Dialog open={showRemovedConfigModal}>
        <DialogTitle>Warning: existing configuration removal</DialogTitle>
        <DialogContent>
          You are electing to remove the following already existing configurations:
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>MOTOR Recommendation</TableCell>
                <TableCell>Rank</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Link</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* TODO make this nicer... fixme*/}
              {!missingKeys ? <div>no data</div> : missingKeys.map(recommendationId => {
              // TODO in next story, fix the stateful issue where it doesn't show
              // most recent updates when going back and forth between pages
              const configToRemove = originalConfigurationDetails[recommendationId];
              return <TableRow key={recommendationId}>
                      <TableCell>{configToRemove.name}</TableCell>
                      <TableCell>{configToRemove.rank}</TableCell>
                      <TableCell>{configToRemove.title}</TableCell>
                      <TableCell>{configToRemove.description}</TableCell>
                      <TableCell>{configToRemove.link}</TableCell>
                    </TableRow>;
            })}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
          setShowRemovedConfigModal(false);
        }}>
            Go Back
          </Button>
          <Button onClick={async () => {
          await deleteUncheckedConfigDetails();
          await continueToNextPage();
        }}>
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </>;
};