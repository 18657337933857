import React from 'react';
function Icon(props) {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none" viewBox="0 0 24 25">
      <mask id="mask0_420_222" style={{
      maskType: 'alpha'
    }} width="25" height="25" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0 0.5H24V24.5H0z"></path>
      </mask>
      <g mask="url(#mask0_420_222)">
        <path fill={props.fill || '#1D252D'} d="M15.425 14.463a2.502 2.502 0 01-1.837-.763 2.502 2.502 0 01-.763-1.837c0-.734.255-1.355.763-1.863a2.504 2.504 0 011.837-.762c.733 0 1.354.254 1.863.762.508.508.762 1.13.762 1.863 0 .716-.254 1.329-.762 1.837a2.534 2.534 0 01-1.863.763zm-4.7 5.624a.858.858 0 01-.65-.261.892.892 0 01-.25-.639v-.625c0-.283.067-.554.2-.813.133-.258.325-.461.575-.611a9.342 9.342 0 012.288-.95 9.753 9.753 0 012.537-.325c.9 0 1.75.108 2.55.325.8.216 1.567.533 2.3.95.234.15.42.353.562.611.142.26.213.53.213.814v.625a.87.87 0 01-.262.638.87.87 0 01-.638.261h-9.425zM9.7 14.412h-6a.726.726 0 01-.75-.75.728.728 0 01.75-.75h6c.2 0 .375.071.525.212a.71.71 0 01.225.538.706.706 0 01-.225.537.74.74 0 01-.525.213zm4-8h-10a.726.726 0 01-.75-.75.728.728 0 01.75-.75h10c.2 0 .375.071.525.213a.71.71 0 01.225.538.706.706 0 01-.225.537.74.74 0 01-.525.213zm-2.8 4H3.7a.726.726 0 01-.75-.75.728.728 0 01.75-.75h7.925a3.74 3.74 0 00-.425.7c-.116.25-.216.517-.3.8z"></path>
      </g>
    </svg>;
}
export default Icon;