import React, { useState, useEffect } from 'react';
import { Select, MenuItem, Checkbox, ListItemText, FormControl, InputLabel, OutlinedInput, FormHelperText, Autocomplete, TextField, Chip, Typography, Stack, Box } from '@mui/material';
import { Field } from 'formik';
import { useFormikContext } from 'formik';
import { styled, lighten, darken } from '@mui/system';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};
const GroupHeader = styled('div')(({
  theme
}) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: theme.palette.primary.main,
  backgroundColor: lighten(theme.palette.primary.light, 0.85),
  ...theme.applyStyles('dark', {
    backgroundColor: darken(theme.palette.primary.main, 0.8)
  })
}));
const GroupItems = styled('ul')({
  padding: 0
});
export function FormikMultipleAutoComplete({
  name,
  label,
  validate,
  options,
  onChange
}) {
  const [actualValues, setActualValues] = useState([]);
  const {
    initialValues
  } = useFormikContext();
  useEffect(() => {
    const keyExists = initialValues.hasOwnProperty(name);
    const startingValues = initialValues[name];
    if (!keyExists) {
      console.warn('FormikMultipleSelectCheckmarks is misconfigured, you must provide the correct name prop');
      setActualValues([]);
    } else {
      const verifiedValues = [];
      for (const data of startingValues) {
        for (const option of options) {
          if (option.value === data) {
            verifiedValues.push(data);
          }
        }
      }
      setActualValues(verifiedValues.map(e => {
        return options.find(o => o.value == e);
      }));
    }
  }, []);
  const changeSelection = async (event, newValues, form) => {
    setActualValues(newValues);
    await form.setFieldValue(name, newValues.map(v => v.value), true);
  };
  const isSelected = (item, array) => {
    if (!array) {
      return false;
    }
    return array.find(i => i.value == item.value) ? true : false;
  };
  return <Field name={name} validate={validate}>
      {({
      field,
      // { name, value, onChange, onBlur }
      form,
      // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
      meta
    }) => <FormControl fullWidth={true} error={meta.touched && meta.error ? true : false}>
          <Autocomplete id="multi-checkbox" multiple value={actualValues} fullWidth={true} options={options.sort((a, b) => isSelected(b, actualValues) - isSelected(a, actualValues))} getOptionLabel={option => option.name} renderInput={params => <TextField {...params} label={label} />} onChange={async (event, newValue) => {
        await changeSelection(event, newValue, form);
      }} renderOption={(props, option, {
        selected
      }) => {
        const {
          key,
          ...optionProps
        } = props;
        return <li key={option.value} {...optionProps}>
                  <Checkbox icon={<CheckBoxOutlineBlankIcon />} checkedIcon={<CheckBoxIcon />} style={{
            marginRight: 8
          }} checked={!!actualValues.find(e => e.value === option.value)} />
                  {option.name}
                </li>;
      }} renderTags={(tagValue, getTagProps) => {
        if (tagValue.length == 0) {
          return <Chip label="None" />;
        }
        if (tagValue.length == 1) {
          return <Stack direction="row" spacing="8px">
                    <Box />
                    <Typography sx={{
              width: '150px',
              whiteSpace: 'nowrap',
              overflow: 'hidden !important',
              textOverflow: 'ellipsis'
            }}>
                      {tagValue[0].name}
                    </Typography>
                  </Stack>;
        }
        return <Stack direction="row" spacing="8px">
                  <Box />
                  <Typography sx={{
            width: '100px',
            whiteSpace: 'nowrap',
            overflow: 'hidden !important',
            textOverflow: 'ellipsis'
          }}>
                    {tagValue.length + ' selected'}
                  </Typography>
                </Stack>;
      }} groupBy={option => {
        return isSelected(option, actualValues) ? 'Selected' : 'All';
      }} renderGroup={params => {
        return <li key={params.key}>
                  <GroupHeader>{params.group}</GroupHeader>
                  <GroupItems>{params.children}</GroupItems>
                </li>;
      }} />
          <FormHelperText>{meta.touched && meta.error}</FormHelperText>
        </FormControl>}
    </Field>;
}