import React from 'react';
function Icon(props) {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <mask id="mask0_17_18683" style={{
      maskType: 'alpha'
    }} width="24" height="24" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0 0H24V24H0z"></path>
      </mask>
      <g mask="url(#mask0_17_18683)">
        <path fill={props.fill || '#1D252D'} d="M5.3 21.5c-.5 0-.925-.175-1.275-.525A1.736 1.736 0 013.5 19.7V6.3c0-.5.175-.925.525-1.275.35-.35.775-.525 1.275-.525h4.45a2.344 2.344 0 01.75-1.425A2.138 2.138 0 0112 2.5c.583 0 1.083.192 1.5.575.417.383.667.858.75 1.425h4.45c.5 0 .925.175 1.275.525.35.35.525.775.525 1.275v13.4c0 .5-.175.925-.525 1.275-.35.35-.775.525-1.275.525H5.3zM12 5.35a.728.728 0 00.75-.75.731.731 0 00-.212-.538A.731.731 0 0012 3.85a.728.728 0 00-.75.75.726.726 0 00.75.75zm-7 13.7a10.07 10.07 0 013.138-2.087c1.191-.509 2.479-.763 3.862-.763s2.671.254 3.863.763A10.074 10.074 0 0119 19.05V6.3c0-.067-.033-.133-.1-.2s-.133-.1-.2-.1H5.3c-.067 0-.133.033-.2.1s-.1.133-.1.2v12.75zm7-5c.9 0 1.667-.317 2.3-.95.633-.633.95-1.4.95-2.3 0-.917-.317-1.688-.95-2.313-.633-.625-1.4-.937-2.3-.937-.9 0-1.667.312-2.3.937-.633.625-.95 1.396-.95 2.313 0 .9.317 1.667.95 2.3.633.633 1.4.95 2.3.95zM6.45 20h11.1v-.2a7.714 7.714 0 00-2.587-1.588A8.628 8.628 0 0012 17.7c-1.017 0-2 .17-2.95.512a7.885 7.885 0 00-2.6 1.563V20zM12 12.55c-.483 0-.896-.175-1.238-.525a1.7 1.7 0 01-.512-1.225c0-.483.17-.896.512-1.238A1.689 1.689 0 0112 9.05c.483 0 .896.17 1.238.512.341.342.512.755.512 1.238a1.7 1.7 0 01-.512 1.225c-.342.35-.755.525-1.238.525z"></path>
      </g>
    </svg>;
}
export default Icon;