import React, { useEffect, useState } from 'react';
import { Button } from 'components/button/button.component';
export const TableFields = ({
  isSuperUser,
  ...props
}) => {
  const [fields, setFields] = useState([]);
  const [values, setValues] = useState({});
  const newField = {
    variable: "",
    label: "",
    type: "text",
    description: "",
    options: []
  };
  useEffect(() => {
    setFields(props.fields);
    setValues(props.values);
  }, [props.fields, props.values]);
  const updateField = (e, index, indexOpt, action) => {
    const {
      name,
      value
    } = e.target;
    const updatedFields = [...fields];
    if (action === "ADD_OPTION") {
      updatedFields[index].options.push("");
    } else if (name === "pick-item") {
      updatedFields[index].options[indexOpt] = value;
    } else {
      updatedFields[index][name] = value;
    }
    setFields(updatedFields);
    props.onChange(updatedFields, values);
  };
  const removeField = index => {
    let updatedFields = [...fields];
    let updatedValues = {
      ...values
    };
    delete updatedValues[updatedFields[index].variable];
    updatedFields.splice(index, 1);
    setFields(updatedFields);
    setValues(updatedFields);
    props.onChange(updatedFields, updatedValues);
  };
  const updateValue = (variable, value) => {
    const updatedValues = {
      ...values
    };
    updatedValues[variable] = value;
    setValues(updatedValues);
    props.onChange(fields, updatedValues);
  };
  return <div>
        <>
            {isSuperUser && <div className="form-group">
                    <label>
                      Fields
                      <Button actionType="primary" className="ml-3" onClick={() => {
            const updatedFields = fields;
            updatedFields.push(newField);
            setFields(updatedFields);
            props.onChange(updatedFields, values);
          }}>
                        Add Field
                      </Button>
                    </label>
                    {fields && fields.length > 0 && <table className="w-100">
                          <thead>
                          <tr>
                            <th style={{
                width: '15%'
              }}>SQL Variable</th>
                            <th style={{
                width: '15%'
              }}>Label</th>
                            <th style={{
                width: '30%'
              }} colSpan={2}>
                              Field
                            </th>
                            <th style={{
                width: '40%'
              }}>Description</th>
                            <th style={{
                width: '40px'
              }}>&nbsp;</th>
                          </tr>
                          </thead>
                          <tbody>
                          {fields.map((field, index) => <tr key={`field${index}`}>
                                <td className="pr-2">
                                  <input className="form-control" value={field.variable} name="variable" onChange={e => updateField(e, index)} />
                                </td>
                                <td className="pr-2">
                                  <input className="form-control" name="label" value={field.label} onChange={e => updateField(e, index)} />
                                </td>
                                <td colSpan={field.type === 'picklist' ? 1 : 2} style={{
                width: field.type === 'picklist' ? '10%' : '30%'
              }} className="pr-2">
                                  <select className="form-control" name="type" value={field.type} onChange={e => updateField(e, index)}>
                                    <option value="text">Text</option>
                                    <option value="numeric">Numeric</option>
                                    <option value="picklist">Picklist</option>
                                  </select>
                                </td>
                                {field.type === 'picklist' && <td className="pr-2" style={{
                width: '20%'
              }}>
                                      {field.options.map((option, optionIndex) => <input name="pick-item" key={`field${index}option${optionIndex}`} className="form-control mb-2" value={option} onChange={e => updateField(e, index, optionIndex)} />)}

                                      <Button actionType="primary" onClick={e => {
                  updateField(e, index, null, "ADD_OPTION");
                }}>
                                        Add Option
                                      </Button>
                                    </td>}
                                <td className="pr-2">
                                  <textarea className="form-control" value={field.description} name="description" onChange={e => updateField(e, index, null)} />
                                </td>

                                <td>
                                  <Button icon="fa-regular-times" small actionType="flat" onClick={() => removeField(index)} />
                                </td>
                              </tr>)}
                          </tbody>
                        </table>}
                  </div>}
          {fields && fields.length > 0 && <div className="form-group">
                <label>Values</label>
                <table className="w-100">
                  <tbody>
                  {fields.map((field, index) => <tr key={`value${index}`}>
                        <td className="pr-5" style={{
                width: '20%'
              }}>
                          <strong>{field.label}</strong>
                        </td>
                        <td className="pr-5" style={{
                width: '20%'
              }}>
                          {field.type === 'picklist' && <select className="form-control" value={values && values[field.variable] || ''} onChange={e => updateValue(field.variable, e.target.value)}>
                                {field.options.indexOf('') === -1 && <option></option>}

                                {field.options.map((option, index) => <option key={`value${index}option${index}`}>
                                      {option}
                                    </option>)}
                              </select>}
                          {field.type === 'numeric' && <input type="number" className="form-control" value={values && values[field.variable] || ''} onChange={e => updateValue(field.variable, e.target.value)} />}
                          {field.type === 'text' && <input className="form-control" value={values && values[field.variable] || ''} onChange={e => updateValue(field.variable, e.target.value)} />}
                        </td>
                        <td style={{
                width: '70%'
              }}>
                          {field.description}
                        </td>
                      </tr>)}
                  </tbody>
                </table>
              </div>}
        </>
      </div>;
};