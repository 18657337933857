import React, { useContext } from 'react';
import { RunRTCCalculator, getRTCAggregate, getRTCRule, getRTCRules, updateRTCRule } from '../../shared/common.api';
import { Box, Button, CircularProgress, ClickAwayListener, Divider, IconButton, Paper, Popper, Table, TableBody, TableCell, TableHead, Tooltip, Typography, TextField, InputAdornment, Skeleton } from '@mui/material';
import Stack from '@mui/material/Stack';
import TableRow from '@mui/material/TableRow';
import { UserStateContext } from 'context/user-state-context';
import { CloseIcon, SearchIcon, UnfoldLessIcon, UnfoldMoreIcon, Calendar24HoursIcon, Calendar30DaysIcon, Calendar365DaysIcon, Calendar7DaysIcon, CalendarAllIcon } from 'components/mui';
import { getFiltersFromURL } from 'components/mui/enhanced-table/utils';
import moment from 'moment';
import DatePicker from 'components/mui/calendar/date-picker.component';
import { withStyles } from '@mui/styles';
import { Rules } from 'components/rules/rules.component';
import { Icon } from '../../components/icon/icon.component';
const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      padding: 0,
      backgroundColor: 'white',
      borderRadius: `14px`,
      '& fieldset': {
        border: 'none',
        padding: 0
      }
    },
    '& .MuiOutlinedInput-input': {
      padding: '10px'
    }
  }
})(TextField);
export const RTCStepOne = ({
  RTC,
  updateRTC,
  updateRulesQueryValid,
  showRuleErrors
}) => {
  const {
    asCompany,
    hasPermission,
    companyFields
  } = useContext(UserStateContext);
  const [isLoading, setIsLoading] = React.useState(true);
  const [transactionData, setTransactionData] = React.useState([]);
  const [searchOpen, setSearchOpen] = React.useState(false);
  const [search, setSearch] = React.useState();
  const [rulesExpanded, setRulesExpanded] = React.useState(true);
  const [queryMode, setQueryMode] = React.useState(!!RTC.query);
  const [filters, setFilters] = React.useState(() => {
    const fils = getFiltersFromURL([]);
    if (!fils['date_after']) {
      if (RTC.transactionDataDateFilterChoice?.date_after) {
        fils['date_after'] = RTC.transactionDataDateFilterChoice?.date_after;
      } else {
        fils['date_after'] = moment().subtract(7, 'days').startOf('day').toISOString();
      }
    }
    if (!fils['date_before']) {
      if (RTC.transactionDataDateFilterChoice?.date_before) {
        fils['date_before'] = RTC.transactionDataDateFilterChoice?.date_before;
      }
    }
    return fils;
  });
  const [fieldModels, setFieldModels] = React.useState([]);
  React.useEffect(() => {
    //Hardcoding specific fields for now
    /*
      setFieldModels(companyFields.filter((field) => field.id === 'transaction' || field.id === 'transaction_detail' || field.id === 'coupon'));
    */
    setFieldModels([{
      id: 'transaction_detail',
      name: 'Transaction Detail',
      fields: [{
        id: 'item_type',
        name: 'Item Type',
        type: 'text'
      }, {
        id: 'item',
        name: 'Item',
        type: 'text'
      }, {
        id: 'item_descr',
        name: 'Item Description',
        type: 'text'
      }]
    }]);
  }, []);
  React.useEffect(() => {
    setIsLoading(true);
    const builder = {
      and: []
    };

    /*     if (filters['date_after']) {
      builder['and'].push({
        field: 'transaction_when',
        model: 'transaction',
        value: filters['date_after'],
        operator: 'after',
        time_type: '',
        time_unit: '',
        query_type: 'value',
        related_field: '',
        related_model: '',
      });
      updateRTC({ ...RTC, transactionDataDateFilterChoice: { ...RTC.transactionDataDateFilterChoice, date_after: filters['date_after'] } });
    }
     if (filters['date_before']) {
      builder['and'].push({
        field: 'transaction_when',
        model: 'transaction',
        value: filters['date_before'],
        operator: 'before',
        time_type: '',
        time_unit: '',
        query_type: 'value',
        related_field: '',
        related_model: '',
      });
      updateRTC({
        ...RTC,
        transactionDataDateFilterChoice: { ...RTC.transactionDataDateFilterChoice, date_before: filters['date_before'] },
      });
    } */

    const after = filters['date_after'] ? filters['date_after'] : moment().subtract(7, 'days').startOf('day').toISOString();
    const before = filters['date_before'] ? filters['date_before'] : moment().toISOString();
    builder['and'].push({
      field: 'transaction_when',
      model: 'transaction',
      operator: 'is_between',
      query_type: 'between_time_values',
      time_type: '',
      time_unit: '',
      related_field: '',
      related_model: '',
      value: [after, before]
    });
    if (filters['date_before']) {
      updateRTC({
        ...RTC,
        transactionDataDateFilterChoice: {
          ...RTC.transactionDataDateFilterChoice,
          date_before: filters['date_before'],
          date_after: filters['date_after']
        }
      });
    } else {
      updateRTC({
        ...RTC,
        transactionDataDateFilterChoice: {
          ...RTC.transactionDataDateFilterChoice,
          date_after: filters['date_after']
        }
      });
    }
    if (search) {
      builder['and'].push({
        or: [{
          model: 'transaction_detail',
          field: 'item_type',
          operator: 'contains',
          query_type: 'value',
          value: search,
          related_model: '',
          related_field: '',
          time_unit: '',
          time_type: ''
        }, {
          model: 'transaction_detail',
          field: 'item',
          operator: 'contains',
          query_type: 'value',
          value: search,
          related_model: '',
          related_field: '',
          time_unit: '',
          time_type: ''
        }, {
          model: 'transaction_detail',
          field: 'item_descr',
          operator: 'contains',
          query_type: 'value',
          value: search,
          related_model: '',
          related_field: '',
          time_unit: '',
          time_type: ''
        }]
      });
    }
    const params = {
      company: asCompany.id,
      builder: builder
    };
    getRTCAggregate(params).then(data => {
      setIsLoading(false);
      setTransactionData(data.data.data);
    });
  }, [search, filters]);
  const handleDateChange = (after, before) => {
    const {
      date_after: _,
      date_before: __,
      ...newFilters
    } = filters;
    setFilters({
      ...newFilters,
      date_after: after,
      ...(!!before && {
        date_before: before
      })
    });
  };
  const handleSearchOpenClick = () => {
    setSearchOpen(!searchOpen);
  };
  const handleRulesClick = () => {
    setRulesExpanded(!rulesExpanded);
  };
  const handleBuilderChange = e => {
    updateRTC({
      ...RTC,
      builder: e
    });
  };
  const handleQueryInputChange = e => {
    updateRTC({
      ...RTC,
      query: e.target.value
    });
  };
  const handleIsRulesQueryValid = isValid => {
    updateRulesQueryValid(isValid);
  };
  return <Stack spacing={'20px'} sx={{
    height: '100%',
    padding: '20px',
    background: '#E4E9F1'
  }}>
      <Paper sx={{
      height: rulesExpanded ? '44vh' : '62vh',
      borderRadius: '14px'
    }}>
        <Stack sx={{
        height: '100%',
        overflowY: 'auto'
      }}>
          <Box sx={{
          paddingY: '13px',
          paddingX: '24px',
          borderBottom: '1px solid #E3E5E7',
          borderTop: '1px solid #E3E5E7',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
            <Typography fontSize={'20px'}>Transaction Data</Typography>
            {isLoading ? <Box>
                <CircularProgress sx={{
              color: '#FFB82B'
            }} size={'20px'} />
              </Box> : <Box />}
            <Stack spacing={'20px'} direction={'row'}>
              <Divider orientation="vertical" sx={{
              height: '32px'
            }} />
              <DatePicker filters={filters} after={filters.date_after} before={filters?.date_before} handleFilterChange={setFilters} handleDateChange={handleDateChange} bottom="10px" types={[{
              key: '24hours',
              name: 'Last 24 Hours',
              icon: <Calendar24HoursIcon />
            }, {
              key: '7days',
              name: 'Last 7 Days',
              icon: <Calendar7DaysIcon />
            }, {
              key: '30days',
              name: 'Last 30 Days',
              icon: <Calendar30DaysIcon />
            }, {
              key: '365days',
              name: 'Last year',
              icon: <Calendar365DaysIcon />
            }]} />
              <Divider orientation="vertical" sx={{
              height: '32px'
            }} />
              {searchOpen ? <Box sx={{
              height: '42px'
            }}>
                  <CustomTextField id="outlined-basic" autoFocus={true} placeholder={'Search'} sx={{
                width: '290px',
                height: '56px',
                pr: '20px',
                py: 0
              }} onChange={e => setSearch(e.target.value || '')} value={search || ''} InputProps={{
                startAdornment: <InputAdornment position="start">
                          <Tooltip title="Collapse Search">
                            <IconButton sx={{
                      width: '40px',
                      height: '40px',
                      textAlign: 'center',
                      padding: 0,
                      backgroundColor: '#fff',
                      color: '#1D252D',
                      ':hover': {
                        backgroundColor: '#DAEFFF',
                        color: '#53A6D6'
                      }
                    }} onClick={handleSearchOpenClick}>
                              <SearchIcon />
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>,
                endAdornment: !!search && <InputAdornment onClick={e => {
                  setSearch('');
                }} position="start" sx={{
                  cursor: 'pointer'
                }}>
                          <CloseIcon />
                        </InputAdornment>
              }} />
                </Box> : <Tooltip title={'Search'}>
                  <IconButton sx={{
                width: '40px',
                height: '40px',
                textAlign: 'center',
                padding: 0,
                backgroundColor: '#fff',
                color: '#1D252D',
                ':hover': {
                  backgroundColor: '#DAEFFF',
                  color: '#53A6D6'
                }
              }} onClick={handleSearchOpenClick}>
                    <SearchIcon />
                  </IconButton>
                </Tooltip>}
              <Divider orientation="vertical" sx={{
              height: '32px'
            }} />
              <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>{transactionData.length + ' items'}</Box>
            </Stack>
          </Box>
          <Box sx={{
          height: '100%'
        }}>
            <Table>
              <colgroup>
                <col style={{
                width: '25%'
              }} />
                <col style={{
                width: '25%'
              }} />
                <col style={{
                width: '35%'
              }} />
                <col style={{
                width: '15%'
              }} />
              </colgroup>
              <TableHead>
                <TableRow>
                  <TableCell sx={{
                  py: '12px'
                }}>
                    <Typography variant="tableHeader">Item Type</Typography>
                  </TableCell>
                  <TableCell sx={{
                  py: '12px'
                }}>
                    <Typography variant="tableHeader">Item </Typography>
                  </TableCell>
                  <TableCell sx={{
                  py: '12px'
                }}>
                    <Typography variant="tableHeader">Item Description </Typography>
                  </TableCell>
                  <TableCell sx={{
                  py: '12px'
                }}>
                    <Typography variant="tableHeader">Transaction Count </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              {isLoading ? <TableBody>
                  {Array(3).fill(null).map((row, index) => <TableRow key={index}>
                        {Array(4).fill(null).map((r, i) => <TableCell key={index + i}>
                              <Box>
                                <Skeleton />
                              </Box>
                            </TableCell>)}
                      </TableRow>)}
                </TableBody> : transactionData?.length > 0 ? <TableBody>
                  {transactionData?.map((row, index) => <TableRow key={index}>
                      <TableCell>{row.item_type || 'N/A'}</TableCell>
                      <TableCell>{row.item_detail || 'N/A'}</TableCell>
                      <TableCell>{row.item_description || 'N/A'}</TableCell>
                      <TableCell>{row.count || 'N/A'}</TableCell>
                    </TableRow>)}
                </TableBody> : filters['date_after'] || filters['date_before'] ? <TableBody>
                  <TableRow>
                    <TableCell />
                    <TableCell>
                      <Typography>No data found</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>Try adjusting your date range</Typography>
                    </TableCell>
                    <TableCell />
                  </TableRow>
                </TableBody> : <TableBody>
                  <TableRow>
                    <TableCell />
                    <TableCell>
                      <Typography>No data found</Typography>
                    </TableCell>
                    <TableCell />
                    <TableCell />
                  </TableRow>
                </TableBody>}
            </Table>
          </Box>
        </Stack>
      </Paper>

      <Paper sx={{
      height: rulesExpanded ? '25vh' : 'auto',
      borderRadius: '14px'
    }}>
        <Stack sx={{
        height: '100%',
        overflowY: 'auto'
      }}>
          <Box sx={{
          paddingTop: '13px',
          paddingX: '24px',
          paddingBottom: rulesExpanded ? '0px' : '13px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
            <Typography fontSize={'20px'}>{queryMode ? 'Calculation Query' : 'Calculation Rules'}</Typography>
            <Stack direction="row" spacing={1}>
              {hasPermission('customer.manage_advanced_sql') && !queryMode ? <Button onClick={() => {
              updateRTC({
                ...RTC,
                builder: null
              });
              setQueryMode(true);
            }} variant="text">
                  <Icon fill="#53A6D6" name="custom-edit_note" size={26} />
                  <Typography sx={{
                textTransform: 'none',
                fontFamily: 'Nexa',
                fontSize: 14,
                color: '#53A6D6'
              }}>
                    Advanced Query Editor
                  </Typography>
                </Button> : <span></span>}
              <Tooltip title={rulesExpanded ? 'Collapse Rules' : 'Expand Rules'}>
                <IconButton onClick={e => handleRulesClick()}>{rulesExpanded ? <UnfoldLessIcon /> : <UnfoldMoreIcon />}</IconButton>
              </Tooltip>
            </Stack>
          </Box>
          {rulesExpanded && (queryMode ? <Box sx={{
          paddingX: '24px'
        }}>
                <TextField onChange={e => {
            handleQueryInputChange(e);
          }} name="calculationQuery" rows={3} sx={{
            width: '80%'
          }} multiline value={RTC.query || ''} InputLabelProps={{
            style: {
              color: '#BEBEBE'
            }
          }} />
              </Box> : <Box sx={{
          pl: '12px'
        }}>
                <Rules isQueryValid={e => {
            handleIsRulesQueryValid(e);
          }} showRuleErrors={showRuleErrors} fieldModels={fieldModels.filter(field => field.id !== 'location')} defaultNewRule={{
            field: '',
            model: 'transaction_detail',
            operator: '',
            query_type: ''
          }} defaultNewGroup={{
            and: [{
              field: '',
              model: 'transaction_detail',
              operator: '',
              query_type: ''
            }]
          }} query={RTC.builder || {
            and: [{
              field: '',
              model: 'transaction_detail',
              operator: '',
              query_type: ''
            }]
          }} onChange={e => {
            handleBuilderChange(e);
          }} />
              </Box>)}
        </Stack>
      </Paper>
    </Stack>;
};