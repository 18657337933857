import React, { useState, useEffect } from 'react';
import { FormControlLabel, Switch } from '@mui/material';
import { Field } from 'formik';
import { useFormikContext } from 'formik';

/**
 * This is a basic switch component which can be be leveraged with formik.
 *
 * Why?  Because in some instances, validation may be determined on the state of turning something else on or off.
 * If you change the value of this, you can choose (in your Yup validation schema or custom validation) whether or not
 * to validate depending on the state of a switch.
 *
 * The default values expected are boolean:
 * ```js
 * true | false
 * ```
 */
export const FormikSwitchComponent = ({
  name,
  validate,
  label,
  onChange
}) => {
  const [switchValue, setSwitchValue] = useState('');
  const {
    initialValues
  } = useFormikContext();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const keyExists = initialValues.hasOwnProperty(name);
    const startingValue = initialValues[name];
    if (!keyExists) {
      console.warn('FormikSwitchComponent is misconfigured, you must provide the correct name prop');
      setSwitchValue(false);
    } else {
      setSwitchValue(startingValue);
    }
    setLoading(false);
    // some funky state / mui stuff, during component render it goes from undefined -> callback
    if (onChange) {
      onChange(startingValue);
    }
  }, []);
  const changeSelection = async (newValue, form) => {
    await form.setFieldValue(name, newValue, true);
    setSwitchValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };
  return <>
      {!loading && <Field name={name} validate={validate}>
          {({
        field,
        // { name, value, onChange, onBlur }
        form,
        // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        meta
      }) => <FormControlLabel control={<Switch checked={switchValue} />} label={label} onChange={async (event, newValue) => {
        await changeSelection(newValue, form);
      }} />}
        </Field>}
    </>;
};