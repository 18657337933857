import React, { useState, useEffect, useContext } from 'react';
import { PageHeaderMui } from 'components';
import { RunRTCCalculator, createRTCRule, deleteRTCRule, getRTCRules, duplicateRTCRule } from 'shared/common.api';
import { UserStateContext } from 'context/user-state-context';
import CssBaseline from '@mui/material/CssBaseline';
import moment from 'moment-timezone';
import EnhancedTable from 'components/mui/enhanced-table/enhanced-table.component';
import { CancelIcon, CloseIcon, DeleteIcon, DraftIcon, EditSquareIcon, PublishedIcon, RepeatTransactionCalculatorIcon, ReplayIcon, SegmentIcon, WarningRedIcon } from 'components/mui';
import { history } from '../../root.component';
import { NewButton } from 'components/mui/button/new.component';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Paper, Stack, Tooltip, Typography } from '@mui/material';
import { snackbarService } from 'components/mui/snackbar/snackbar-service';
export const RepeatTransactionCalculatorList = () => {
  const {
    hasPermission,
    asCompany
  } = useContext(UserStateContext);
  const [renderIteration, setRenderIteration] = useState(0);
  const [disabledRows, setDisabledRows] = useState([]);
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(null);
  const [failedRestartModal, setFailedRestartModal] = useState(null);
  const handleRefreshedData = data => {
    setDisabledRows(data?.filter(obj => obj.status === 'in_progress').map(obj => obj.id));
    handleAutoRefresh();
  };
  useEffect(() => {
    if (history?.location?.state?.id) {
      snackbarService.popup({
        type: 'success',
        message: `Calculation running for "${history?.location?.state?.name}". (This may take a while)`,
        delay: 10000,
        action: <React.Fragment>
            <IconButton size="small" color="white" onClick={() => snackbarService.close()}>
              <CloseIcon size="sm" fontSize="small" />
            </IconButton>
          </React.Fragment>
      });
    }
  }, []);
  const handleAutoRefresh = () => {
    const timer = setTimeout(() => {
      setRenderIteration(renderIteration + 1);
    }, 10000);
    return () => clearTimeout(timer);
  };
  const headCells = [{
    key: 'name',
    label: 'Item Name',
    width: '22%',
    displayComponent: ({
      data: RTC
    }) => {
      return <Box sx={{
        alignItems: 'center',
        width: '100%'
      }} display="flex" flexDirection="row" justifyContent="space-between">
            <Typography sx={{
          color: RTC.status == 'in_progress' ? '#1D252D80' : '#1D252D'
        }}> {RTC?.name || '--'}</Typography>
          </Box>;
    }
  }, {
    key: 'status',
    label: 'Status',
    width: '13%',
    displayComponent: ({
      data: RTC
    }) => {
      return <Box sx={{
        color: RTC.status == 'in_progress' ? '#1D252D80' : '#1D252D'
      }}>
            {RTC.status == 'in_progress' ? <Stack sx={{
          display: 'flex',
          alignItems: 'center',
          paddingRight: '16px'
        }} direction={'row'} spacing={1}>
                <CircularProgress sx={{
            color: '#FFB82B'
          }} size={20} />
                <Typography sx={{
            color: RTC.status == 'in_progress' ? '#1D252D80' : '#1D252D'
          }}>Calculating...</Typography>
              </Stack> : RTC.status?.includes('draft') ? <Stack sx={{
          display: 'flex',
          alignItems: 'center',
          paddingRight: '16px'
        }} direction={'row'} spacing={1}>
                <DraftIcon />
                <Typography sx={{
            color: RTC.status == 'in_progress' ? '#1D252D80' : '#1D252D'
          }}>Draft</Typography>
              </Stack> : RTC.status?.includes('completed') ? <Stack sx={{
          display: 'flex',
          alignItems: 'center',
          paddingRight: '16px'
        }} direction={'row'} spacing={1}>
                <PublishedIcon />
                <Typography sx={{
            color: RTC.status == 'in_progress' ? '#1D252D80' : '#1D252D'
          }}>Completed</Typography>
              </Stack> : RTC.status?.includes('failed') ? <Stack sx={{
          display: 'flex',
          alignItems: 'center',
          paddingRight: '16px'
        }} direction={'row'} spacing={1}>
                <WarningRedIcon />
                <Typography sx={{
            color: RTC.status == 'failed' ? '#1D252D80' : '#1D252D'
          }}>Failed</Typography>
              </Stack> : <Typography sx={{
          color: RTC.status == 'in_progress' ? '#1D252D80' : '#1D252D'
        }}>{RTC.status}</Typography>}
          </Box>;
    }
  }, {
    key: 'created_when',
    label: 'Created',
    width: '13%',
    displayComponent: ({
      data: RTC
    }) => {
      return <Typography sx={{
        color: RTC.status == 'in_progress' ? '#1D252D80' : '#1D252D'
      }}>
            {RTC.created_when ? moment(RTC.created_when).local().format('MMM D, YYYY h:mm A') : '--'}
          </Typography>;
    }
  }, {
    key: 'updated_when',
    label: 'Last Updated',
    width: '13%',
    displayComponent: ({
      data: RTC
    }) => {
      return <Typography sx={{
        color: RTC.status == 'in_progress' ? '#1D252D80' : '#1D252D'
      }}>
            {RTC.updated_when ? moment(RTC.updated_when).local().format('MMM D, YYYY h:mm A') : '--'}
          </Typography>;
    }
  }, {
    key: 'optimal_value',
    label: 'Target',
    width: '13%',
    displayComponent: ({
      data: RTC
    }) => {
      return <Typography sx={{
        color: RTC.status == 'in_progress' ? '#1D252D80' : '#1D252D'
      }}>
            {RTC?.optimal_value + ' ' + RTC?.type || '--'}
          </Typography>;
    }
  }, {
    key: 'min',
    label: 'Min Days',
    width: '13%',
    displayComponent: ({
      data: RTC
    }) => {
      return <Typography sx={{
        color: RTC.status == 'in_progress' ? '#1D252D80' : '#1D252D'
      }}>{RTC?.min || '--'}</Typography>;
    }
  }, {
    key: 'max',
    label: ' Max Days',
    width: '13%',
    displayComponent: ({
      data: RTC,
      onHover
    }) => {
      if (RTC.id === onHover.row) {
        return <></>;
      }
      return <Typography sx={{
        color: RTC.status == 'in_progress' ? '#1D252D80' : '#1D252D'
      }}>{RTC?.max || '--'}</Typography>;
    }
  }, {
    key: 'tools',
    noSort: true,
    displayComponent: ({
      data: RTC,
      onHover
    }) => {
      if (RTC.id === onHover.row) {
        return <Box sx={{
          position: 'absolute'
        }}>
              <Stack direction="row" spacing={1} sx={{
            position: 'absolute',
            right: 0,
            top: '-20px',
            justifyContent: 'flex-end'
          }}>
                {hasPermission('repeat_transaction_calculator.change_transactioncalculatorrule') && (RTC.status === 'draft_1' || RTC.status === 'draft_2' || RTC.status === 'draft_3') && <Tooltip title="Edit Calculation">
                      <IconButton onClick={e => {
                e.stopPropagation();
                history.push(`/repeat-transaction-calculator-builder/${RTC.id}`, {
                  duplicate: RTC.status == 'completed' ? true : false
                });
              }}>
                        <EditSquareIcon />
                      </IconButton>
                    </Tooltip>}
                {hasPermission('customer.add_segment') && RTC.status === 'completed' && <Tooltip title="Create Segment">
                    <IconButton onClick={e => {
                e.stopPropagation();
                handleCreateSegmentClick(RTC);
              }}>
                      <SegmentIcon />
                    </IconButton>
                  </Tooltip>}
                {hasPermission('repeat_transaction_calculator.add_transactioncalculatorrule') && RTC.status === 'failed' && <Tooltip title="Rerun Rule">
                    <IconButton onClick={e => {
                setFailedRestartModal(RTC.id);
              }}>
                      <ReplayIcon />
                    </IconButton>
                  </Tooltip>}
                {hasPermission('repeat_transaction_calculator.delete_transactioncalculatorrule') && <Tooltip title="Delete Calculation">
                    <IconButton onClick={e => {
                e.stopPropagation();
                //handleDeleteRTC(RTC.id);
                setDeleteConfirmModal(RTC.id);
              }}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>}
              </Stack>
            </Box>;
      }
      return <></>;
    }
  }];
  const handleDeleteRTC = id => {
    deleteRTCRule(id).then(() => {
      setDeleteConfirmModal(null);
      setRenderIteration(renderIteration + 1);
    });
  };
  const handleDuplicateRTC = async id => {
    try {
      const duplicateResponse = await duplicateRTCRule(id);
      const newRuleId = duplicateResponse.data.id;
      await RunRTCCalculator(newRuleId);
      setRenderIteration(renderIteration + 1);
      setFailedRestartModal(null);
    } catch (error) {
      console.error('failed to duplicate RTC rule');
    }
  };
  const handleRTCListNewButtonClick = event => {
    createRTCRule({
      name: 'Untitled',
      company: asCompany.id,
      includes_possession: true,
      max: 365,
      min: 1,
      optimal_value: 0,
      accelerator: 0,
      type: 'miles'
    }).then(rtc => {
      history.push(`/repeat-transaction-calculator-builder/${rtc.data.id}`);
    });
  };
  const handleRTCListRowItemClick = event => {
    if (event.status === 'completed') {
      hasPermission('repeat_transaction_calculator.view_transactioncalculatorresults') && history.push(`/repeat-transaction-calculator-results/${event.id}`);
    } else if (event.status === 'failed') {
      hasPermission('repeat_transaction_calculator.add_transactioncalculatorrule') && setFailedRestartModal(event.id);
    } else if (event.status !== 'in_progress') {
      hasPermission('repeat_transaction_calculator.add_transactioncalculatorrule') && history.push(`/repeat-transaction-calculator-builder/${event.id}`);
    }
  };
  const handleCreateSegmentClick = rule => {
    history.push({
      pathname: '/create-segment',
      state: {
        rtcRule: {
          ...rule
        }
      }
    });
  };
  return <>
      <CssBaseline />
      <div className="wrapper">
        <PageHeaderMui type="Repeat Transaction Calculator" icon={<RepeatTransactionCalculatorIcon size="lg" />} />
        <div className="mui-wrapper">
          <EnhancedTable type="Repeat Transaction Calculator" headCells={headCells} api={getRTCRules} exportData={newData => handleRefreshedData(newData)} disabledRows={disabledRows} defaultSort={'-updated_when'} handleRowClick={calculation => {
          handleRTCListRowItemClick(calculation);
        }} renderIteration={renderIteration} actions={hasPermission('repeat_transaction_calculator.add_transactioncalculatorrule') && <NewButton onClick={e => handleRTCListNewButtonClick(e)} />} />
        </div>
      </div>

      {/* Modal which handles the rule deletion prompt*/}
      <Dialog sx={{
      fontFamily: 'Nexa',
      color: '#1D252D'
    }} PaperProps={{
      style: {
        borderRadius: '28px',
        width: '312px '
      }
    }} open={!!deleteConfirmModal} onClose={(event, reason) => {
      setDeleteConfirmModal(null);
    }}>
        <DialogTitle className="d-flex w-100 justify-content-between" sx={{
        m: 0,
        px: 0,
        py: 1
      }}>
          <Stack spacing={'20px'} sx={{
          width: '100%',
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center'
        }}>
            <CancelIcon fill="#F30F0F" />
            <Typography sx={{
            fontSize: 24
          }}>Delete Calculation?</Typography>
          </Stack>
        </DialogTitle>

        <DialogContent dividers>
          <DialogContentText>
            <Stack component="span"></Stack>
          </DialogContentText>
          <Stack spacing="10px">
            <Typography>Are you sure you want to delete these results? This action cannot be undone.</Typography>
          </Stack>
        </DialogContent>

        <DialogActions sx={{
        py: 3
      }}>
          <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
            <Box />
            <Stack direction="row" spacing="10px">
              <Button variant="text" onClick={e => {
              setDeleteConfirmModal(null);
            }} sx={{
              px: 2,
              py: 1
            }}>
                <Typography sx={{
                textTransform: 'none'
              }}> Cancel</Typography>
              </Button>
              <Button onClick={e => {
              handleDeleteRTC(deleteConfirmModal);
            }} variant="text" sx={{
              px: 2,
              py: 1
            }}>
                <Typography sx={{
                textTransform: 'none',
                color: '#3898D9'
              }}> Delete</Typography>
              </Button>
            </Stack>
          </Box>
        </DialogActions>
      </Dialog>

      {/* Modal for restarting (duplicating) a failed rule run. */}
      <Dialog sx={{
      fontFamily: 'Nexa',
      color: '#1D252D'
    }} PaperProps={{
      style: {
        borderRadius: '28px',
        width: '312px '
      }
    }} open={failedRestartModal} onClose={() => {
      setFailedRestartModal(null);
    }}>
        <DialogTitle className="d-flex w-100 justify-content-between" sx={{
        m: 0,
        px: 0,
        py: 1
      }}>
          <Stack spacing={'20px'} sx={{
          width: '100%',
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center'
        }}>
            <CancelIcon fill="#F30F0F" />
            <Typography sx={{
            fontSize: 24
          }}>Rerun rule</Typography>
          </Stack>
        </DialogTitle>

        <DialogContent dividers>
          <DialogContentText>
            <Stack component="span"></Stack>
          </DialogContentText>
          <Stack spacing="10px">
            <Typography>
              The current rule has failed processing.
              <br />
              <br />
              Would you like to reattempt running a new rule with the same definitions?
            </Typography>
          </Stack>
        </DialogContent>

        <DialogActions sx={{
        py: 3
      }}>
          <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
            <Box />
            <Stack direction="row" spacing="10px">
              <Button variant="text" onClick={e => {
              setFailedRestartModal(null);
            }} sx={{
              px: 2,
              py: 1
            }}>
                <Typography sx={{
                textTransform: 'none'
              }}> Cancel</Typography>
              </Button>
              <Button onClick={async e => {
              await handleDuplicateRTC(failedRestartModal);
            }} variant="text" sx={{
              px: 2,
              py: 1
            }}>
                <Typography sx={{
                textTransform: 'none',
                color: '#3898D9'
              }}>Rerun</Typography>
              </Button>
            </Stack>
          </Box>
        </DialogActions>
      </Dialog>
    </>;
};