import React, { useState, useEffect, useContext } from 'react';
import { getMailboxPowerContactGroups } from '../../../integrations/integrations.resource';
import { UserStateContext } from 'context/user-state-context';
import { StaticTypeAhead } from '../../../../components/static-type-ahead/static-type-ahead.component';

// TODO how to utilize the reset button?
export const ActionMailboxPower = ({
  data,
  node,
  readOnly,
  resize,
  update
}) => {
  const [contactGroupId, setContactGroupId] = useState();
  const [contactGroupName, setContactGroupName] = useState();
  const [contactGroups, setContactGroups] = useState([]);
  const context = useContext(UserStateContext);
  useEffect(() => {
    const getContactGroups = async () => {
      try {
        const contactGroupsResponseBody = await getMailboxPowerContactGroups({
          company: context.asCompany.id,
          limit: 1000
        });
        setContactGroups(contactGroupsResponseBody);
        if (data['contact_group_id']) {
          setContactGroupId(data['contact_group_id']);
          for (const group of contactGroupsResponseBody) {
            if (group.ID === data['contact_group_id']) {
              setContactGroupName(group['NAME']);
            }
          }
        }
      } catch (error) {
        console.error(error, 'There was an issue retrieving the contact Groups');
      }
    };
    getContactGroups();
  }, []);
  const updateContactGroup = async group => {
    // we shouldn't have to do this, likely a bug
    // something to do with re-renders
    if (group) {
      setContactGroupId(group['ID']);
      data['contact_group_id'] = group['ID'];
    }
  };
  return <div>
      <div className="form-group">
        <label>Contact Group</label>
        <StaticTypeAhead items={contactGroups} displayProperty="NAME" placeholder={contactGroupName ? contactGroupName : 'Please Select a Contact Group'} keyProperty="ID" value={contactGroupId} onChange={updateContactGroup} disabled={false} />
      </div>
    </div>;
};