import React, { useContext } from 'react';
import { UserStateContext } from 'context/user-state-context';
import { DynamicTypeAhead as DTA } from 'components/dynamic-type-ahead/dynamic-type-ahead.component';
export const DynamicTypeAhead = props => {
  const {
    asCompany
  } = useContext(UserStateContext);
  const {
    param,
    update,
    data
  } = props;
  const displayProperty = param.displayProperty || 'name';
  const keyProperty = param.keyProperty || 'id';
  const cacheKey = `_${param.key}__${displayProperty}`;
  const onChange = item => {
    const toUpdate = {
      [param.key]: item ? item[keyProperty] : null,
      [cacheKey]: item ? item[displayProperty] : null
    };
    if (param.cacheFields) {
      param.cacheFields.forEach(field => {
        toUpdate[`_${param.key}__${field}`] = item ? item[field] : null;
      });
    }
    update(toUpdate);
  };
  let value = null;
  if (data[param.key]) {
    // We're going to try caching the display property in the database to save API calls
    // but since this isn't applied retroactively we need to be able to handle both.
    if (data[cacheKey]) {
      value = {
        [keyProperty]: data[param.key],
        [displayProperty]: data[cacheKey]
      };
    } else {
      value = data[param.key];
    }
  }
  return <div className="form-group">
      <label>{param.label}</label>
      <DTA getItems={param.getItems} getItemsFilters={{
      company: asCompany.id,
      ...(param.getItemExtraFilters || {})
    }} placeholder={param.placeholder || `Choose ${param.label}`} displayProperty={displayProperty} keyProperty={keyProperty} value={value} onChange={onChange} />
    </div>;
};