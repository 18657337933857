import React from 'react';
function Icon() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <mask id="mask0_17_18663" style={{
      maskType: 'alpha'
    }} width="24" height="25" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0 0H24V24H0z"></path>
      </mask>
      <g mask="url(#mask0_17_18663)">
        <path fill="#1D252D" d="M12 21.5a9.263 9.263 0 01-3.712-.75 9.58 9.58 0 01-3.013-2.025 9.58 9.58 0 01-2.025-3.013A9.263 9.263 0 012.5 12c0-1.316.25-2.554.75-3.713a9.583 9.583 0 012.025-3.012A9.58 9.58 0 018.288 3.25 9.263 9.263 0 0112 2.5a9.27 9.27 0 013.713.75 9.583 9.583 0 013.012 2.025 9.583 9.583 0 012.025 3.012A9.27 9.27 0 0121.5 12c0 1.317-.25 2.554-.75 3.712a9.58 9.58 0 01-2.025 3.013 9.583 9.583 0 01-3.012 2.025A9.27 9.27 0 0112 21.5zm-1-1.55V18c-.55 0-1.02-.195-1.412-.587A1.927 1.927 0 019 16v-1l-4.8-4.8c-.05.3-.096.6-.138.9-.041.3-.062.6-.062.9 0 2.017.663 3.784 1.988 5.3 1.325 1.517 2.995 2.4 5.012 2.65zm6.9-2.55c.333-.366.633-.762.9-1.188.267-.425.488-.866.663-1.325a8.32 8.32 0 00.4-1.412C19.954 12.992 20 12.5 20 12c0-1.633-.454-3.13-1.362-4.488A7.681 7.681 0 0015 4.575V5c0 .55-.196 1.02-.587 1.412A1.927 1.927 0 0113 7h-2v2c0 .284-.096.52-.287.712A.968.968 0 0110 10H8v2h6a.97.97 0 01.713.287A.97.97 0 0115 13v3h1c.433 0 .825.13 1.175.387.35.259.592.596.725 1.013z"></path>
      </g>
    </svg>;
}
export default Icon;