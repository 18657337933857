import React, { useState, useEffect, useContext } from 'react';
import * as Sentry from '@sentry/react';
import { ErrorFOB } from './error-fob.component';
import { UserStateContext } from 'context/user-state-context';
export const ErrorBoundary = ({
  children
}) => {
  const {
    user
  } = useContext(UserStateContext);
  const [eventId, setEventId] = useState(false);
  useEffect(() => {
    let timeoutId;
    window.CIErrorBoundary = ({
      event_id
    }) => {
      setEventId(event_id);
      timeoutId = setTimeout(() => {
        setEventId(false);
      }, 5000);
    };
    return () => clearTimeout(timeoutId);
  }, []);
  const openReportDialog = eventId => {
    Sentry.showReportDialog({
      eventId,
      user: {
        email: user.email,
        name: `${user.first_name} ${user.last_name}`.trim()
      }
    });
    setEventId(false);
  };
  return <>
      {children}

      <ErrorFOB open={!!eventId} onClick={() => openReportDialog(eventId)} />
    </>;
};