import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Scoped } from 'kremling';
import classnames from 'classnames';
import styles from './file-viewer.styles.scss';
import { Loader } from '../loader/loader.component';
import { Icon } from '../icon/icon.component';
import moment from 'moment-timezone';
import { fileSize, formatFile } from './utils';
import { DashboardModal } from '@uppy/react';
import Uppy from '@uppy/core';
import AwsS3Multipart from '@uppy/aws-s3-multipart';
import { createMultipartUpload, listParts, prepareUploadPart, completeMultipartUpload, abortMultipartUpload } from './provider';
import { getCompanyFiles, createCompanyFilesFolder, deleteCompanyFile, renameCompanyFile, downloadCompanyFile } from '../../shared/common.api';
import { UserStateContext } from 'context/user-state-context';
import { Modal } from '../modal/modal.component';
import { toasterService } from '../toaster/toaster-service';
import { Box, IconButton, Stack, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Typography, Button } from '@mui/material';
import { AddFolderIcon, CsvIcon, DeleteIcon, DownloadIcon, DraftIcon, EditSquareIcon, FolderIcon, FolderOpenIcon, OpenInNewWindowIcon, PdfIcon, UploadIcon } from 'components/mui';
import { withStyles } from '@mui/styles';
const CustomTooltip = withStyles({
  tooltip: {
    backgroundColor: 'white'
  }
})(Tooltip);
const renderIcon = file => {
  if (file.isFolder) {
    if (file.expanded) {
      return <FolderOpenIcon />;
    } else {
      return <FolderIcon />;
    }
  }
  if (['jpg', 'jpeg', 'png', 'bmp'].indexOf(file.extension) > -1) {
    return <CustomTooltip title={<img src={file.url} style={{
      maxHeight: '50vh',
      maxWidth: '50vw'
    }} />} placement="right">
        <img src={file.url} height="25px" />
      </CustomTooltip>;
  }
  if (['pdf'].indexOf(file.extension) > -1) {
    return <PdfIcon />;
  }
  if (['csv'].indexOf(file.extension) > -1) {
    return <CsvIcon />;
  }
  return <DraftIcon fill="#1D252DB2" />;
};
export function FileViewer2(props) {
  const {
    asCompany,
    hasPermission
  } = useContext(UserStateContext);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [uppy, setUppy] = useState();
  const [newFolderTarget, setNewFolderTarget] = useState(null);
  const [newFolder, setNewFolder] = useState();
  const [newFolderError, setNewFolderError] = useState();
  const [deleteFile, setDeleteFile] = useState();
  const [deleteFileMessage, setDeleteFileMessage] = useState();
  const [renameFile, setRenameFile] = useState();
  const [renameFileError, setRenameFileError] = useState();
  const [renameNewFile, setRenameNewFile] = useState();
  const [search, setSearch] = React.useState('');
  const loadData = useCallback(oldFiles => {
    // TODO refactor this nightmare to make it faster
    getCompanyFiles(asCompany.id).then(({
      data
    }) => {
      setLoading(false);
      const newFiles = data.map(file => {
        const oldFile = oldFiles && oldFiles.find(f => f.Key === file.Key);
        if (oldFile) {
          return oldFile;
        }
        return formatFile(file);
      }).sort((a, b) => {
        for (let i = 0; i < Math.max(a.sortParts.length, b.sortParts.length); i++) {
          if (a.sortParts[i] !== b.sortParts[i]) {
            return a.sortParts[i] < b.sortParts[i] ? -1 : 1;
          }
        }
        return 0;
      }).map((file, index, array) => {
        if (file.parentFolder !== '/') {
          for (let i = index - 1; i >= 0; i--) {
            if (array[i].shortKey === file.parentFolder) {
              file.visible = array[i].expanded;
            }
          }
        } else {
          file.visible = true;
        }
        return file;
      });
      setFiles(newFiles);
    });
  }, [setLoading, setFiles, asCompany]);
  useEffect(() => {
    loadData();
  }, [loadData]);
  useEffect(() => {
    loadData([]);
  }, [asCompany]);
  const selectFile = file => {
    // If file is a folder then expand it
    if (file.isFolder) {
      file.expanded = !file.expanded;
      files.filter(f => f.parentFolder === file.shortKey || !file.expanded && file.shortKey === f.parentFolder.substring(0, file.shortKey.length)).forEach(f => {
        f.visible = file.expanded;
        if (!file.expanded && f.isFolder) {
          f.expanded = file.expanded;
        }
      });
    }
    if (props.onSelect) {
      props.onSelect({
        Key: file.Key,
        ETag: file.ETag,
        LastModified: file.LastModified,
        Owner: file.Owner,
        Size: file.Size,
        StorageClass: file.StorageClass,
        name: file.name,
        isFolder: file.isFolder,
        url: file.url,
        extension: file.extension,
        formatedSize: fileSize(file.Size)
      });
    }
    files.forEach(f => f.selected = f === file);
    setFiles([...files]);
  };
  const openModal = (event, parentFolder) => {
    event.stopPropagation();
    const uppy = new Uppy({
      autoProceed: true,
      maxTotalFileSize: 25000000,
      // Max file size 25mb
      allowedFileTypes: ['.jpg', '.jpeg', '.png', '.gif', '.pdf', '.vcf']
    }).use(AwsS3Multipart, {
      companionUrl: API_URL + `/company-files/${asCompany.id}/upload`,
      parentFolder,
      createMultipartUpload,
      listParts,
      prepareUploadPart,
      completeMultipartUpload,
      abortMultipartUpload
    });
    setUppy(uppy);
  };
  const openNewFolder = (event, targetFolder) => {
    event.stopPropagation();
    setNewFolderTarget(targetFolder);
    setNewFolder('');
  };
  const updateNewFolder = newFolder => {
    setNewFolder(newFolder);
    if (RegExp(/[~`!#$%\^&*+=\[\]\\';,/{}|\\":<>\?]/g).test(newFolder)) {
      setNewFolderError('Folder name must not contain special characters.');
    } else {
      setNewFolderError(null);
    }
  };
  const createFolder = () => {
    if (newFolderError) {
      return;
    }
    if (!(newFolder || '').length) {
      setNewFolderError('Folder name is required.');
      return;
    }
    createCompanyFilesFolder(asCompany.id, newFolderTarget + newFolder).then(() => {
      loadData(files);
      setNewFolderTarget(null);
      setNewFolder(null);
      setNewFolderError(null);
      toasterService.success('Successfully create new folder');
    }).catch(err => {
      toasterService.error('There was an error trying to create the folder. Please try again.');
    });
  };
  const openDeleteFile = (event, file) => {
    event.stopPropagation();
    if (file.isFolder) {
      const subItemCount = files.filter(f => f.shortKey.substring(0, file.shortKey.length) === file.shortKey).length - 1;
      if (subItemCount > 0) {
        setDeleteFileMessage(`Are you sure you want to delete the folder ${file.name} and its ${subItemCount} files?`);
      } else {
        setDeleteFileMessage(`Are you sure you want to delete the empty folder ${file.name}?`);
      }
    } else {
      setDeleteFileMessage(`Are you sure you want to delete the file ${file.name}?`);
    }
    setDeleteFile(file);
  };
  const confirmDeleteFile = () => {
    deleteCompanyFile(asCompany.id, deleteFile.shortKey).then(() => {
      loadData(files);
      setDeleteFile(null);
      setDeleteFileMessage(null);
      toasterService.success('Successfully deleted file.');
    }).catch(err => {
      toasterService.error('There was an error trying to delete the file. Please try again.');
    });
  };
  const openRenameFile = (event, file) => {
    event.stopPropagation();
    setRenameFile(file);
    setRenameFileError(null);
    setRenameNewFile(file.name);
  };
  const updateRenameFile = newName => {
    setRenameNewFile(newName);
    if (renameFile.isFolder && RegExp(/[~`!#$%\^&*+=\[\]\\';,/{}|\\":<>\?]/g).test(newName)) {
      setRenameFileError('Folder name must not contain special characters.');
    } else if (!renameFile.isFolder && RegExp(/[/]/g).test(newName)) {
      setRenameFileError('File name must not contain forward slash.');
    } else if (newName.length === 0) {
      setRenameFileError('Name is required.');
    } else {
      setRenameFileError(null);
    }
  };
  const confirmRenameFile = () => {
    if (!(renameNewFile || '').length) {
      setRenameFileError('Name is required.');
      return;
    }
    let oldKey, newKey;
    if (renameFile.parentFolder !== '/') {
      oldKey = `${renameFile.parentFolder}${renameFile.name}`;
      newKey = `${renameFile.parentFolder}${renameNewFile}`;
    } else {
      oldKey = renameFile.name;
      newKey = renameNewFile;
    }
    if (renameFile.isFolder) {
      oldKey += '/';
      newKey += '/';
    }
    renameCompanyFile(asCompany.id, oldKey, newKey).then(() => {
      setFiles(files.map(file => {
        if (file.shortKey.substring(0, oldKey.length) === oldKey) {
          // Renaming!
          const expanded = file.extension;
          const visible = file.visible;
          const prefix = file.Key.substring(0, file.Key.length - file.shortKey.length);
          file.Key = prefix + newKey + file.shortKey.substring(oldKey.length);
          file.url = file.url.replace(oldKey, newKey);
          file = formatFile(file);
          file.visible = visible;
          file.expanded = expanded;
        }
        return file;
      }));
      setRenameFile(null);
      setRenameFileError(null);
      setRenameNewFile(null);
      toasterService.success('Successfully renamed file.');
    }).catch(err => {
      toasterService.error('There was an error trying to rename the file. Please try again.');
    });
  };
  if (loading) return <Loader overlay />;
  return <Scoped css={styles}>
      <Stack>
        {hasPermission('files.add_files') && <Stack direction="row" spacing="20px">
            <Button onClick={e => openNewFolder(e, '')} variant="contained" sx={{
          borderRadius: '20px'
        }}>
              New top-level folder
            </Button>

            <Button onClick={openModal} variant="contained" sx={{
          borderRadius: '20px'
        }}>
              Upload top-level file
            </Button>
          </Stack>}
        <Table>
          <colgroup>
            <col width="50%" />
            <col width="20%" />
            <col width="15%" />
            <col width="15%" />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="tableHeader">Name </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="tableHeader">Size </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="tableHeader">Last Modified </Typography>
              </TableCell>
              <TableCell>
                <TextField size="small" margin="dense" placeholder="Search files" value={search || ''} onChange={e => {
                setSearch(e.target.value);
              }} />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {files.filter(f => props.hideCSVs ? !(['csv'].indexOf(f.extension) > -1) : true).filter(file => !!file.visible || search != '').filter(f => (f.name || '').toLowerCase().includes(search.toLowerCase())).map(file => <TableRow key={file.Key} className="row" selected={!!file.selected} onClick={() => selectFile(file)}>
                  <TableCell sx={{
              paddingLeft: file.paddingLeft + 'px'
            }}>
                    <Stack sx={{
                cursor: 'pointer',
                display: 'flex',
                alignContent: 'center',
                alignItems: 'center'
              }} direction="row" spacing="10px">
                      {renderIcon(file)}
                      <Typography> {file.name}</Typography>
                    </Stack>
                  </TableCell>
                  <TableCell>{!file.isFolder && fileSize(file.Size)}</TableCell>
                  <TableCell>{!file.isFolder && moment(file.LastModified).fromNow()}</TableCell>

                  <TableCell className="icons__remove">
                    <Stack direction="row" sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
                      {file.isFolder && hasPermission('files.add_files') && <Tooltip title={'Create new folder inside ' + file.name}>
                          <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                            <IconButton onClick={e => openNewFolder(e, file.shortKey)}>
                              <AddFolderIcon />
                            </IconButton>
                          </Box>
                        </Tooltip>}

                      {file.isFolder && hasPermission('files.add_files') && <Tooltip title={'Upload a new file into folder ' + file.name}>
                          <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                            <IconButton onClick={e => openModal(e, file.shortKey)}>
                              <UploadIcon />
                            </IconButton>
                          </Box>
                        </Tooltip>}

                      {!file.isFolder && <Tooltip title={file.extension === 'csv' ? 'Download file' : 'View file in a new window'}>
                          <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                            <IconButton onClick={e => {
                      if (file.extension === 'csv') {
                        downloadCompanyFile(asCompany.id, file.shortKey).then(response => {
                          window.open(response.data.url);
                        });
                      } else {
                        window.open(file.url);
                      }
                    }}>
                              {file.extension === 'csv' ? <DownloadIcon /> : <OpenInNewWindowIcon />}
                            </IconButton>
                          </Box>
                        </Tooltip>}

                      {hasPermission('files.change_files') &&
                // if file type is .csv then dont allow rename to prevent s3 issues
                file.extension !== 'csv' && <Tooltip title={'Rename ' + file.name}>
                            <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                              <IconButton onClick={e => openRenameFile(e, file)}>
                                <EditSquareIcon />
                              </IconButton>
                            </Box>
                          </Tooltip>}
                      {hasPermission('files.delete_files') && <Tooltip title={'Delete ' + file.name}>
                          <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                            <IconButton onClick={e => openDeleteFile(e, file)}>
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        </Tooltip>}
                    </Stack>
                  </TableCell>
                </TableRow>)}

            {!files.length && <TableRow className="no-hover">
                <TableCell colSpan="4">
                  <i>There are currently no files here.</i>
                </TableCell>
              </TableRow>}
          </TableBody>
        </Table>
      </Stack>
      {uppy && <DashboardModal uppy={uppy} closeModalOnClickOutside open={true} onRequestClose={() => {
      // TODO it might be better to find a way to update the files after they're uploaded and not when the modal closes
      loadData(files);
      setUppy(null);
    }} />}

      <Modal level={10} open={newFolderTarget !== null} onClose={() => setNewFolderTarget(null)} allowBackdropClick title="Create New Folder">
        <div className="modal__body">
          <p>Enter the name of the new folder.</p>
          <div className="form-group">
            <input className="form-control" name="newFolder" placeholder="New Folder Name" onChange={e => updateNewFolder(e.target.value)} value={newFolder || ''} autoFocus />
            {newFolderError && <p className="form-error mt-2">{newFolderError}</p>}
          </div>
        </div>
        <div className="modal__actions">
          <Button actionType="flat" onClick={() => setNewFolderTarget(null)}>
            Cancel
          </Button>
          <Button actionType="primary" onClick={() => createFolder()}>
            Submit
          </Button>
        </div>
      </Modal>

      <Modal level={10} open={!!deleteFile} onClose={() => setDeleteFile(null)} allowBackdropClick title="Delete File">
        <div className="modal__body">
          <p>{deleteFileMessage} This cannot be undone</p>
        </div>
        <div className="modal__actions">
          <Button actionType="flat" onClick={() => setDeleteFile(null)}>
            Cancel
          </Button>
          <Button actionType="primary" onClick={() => confirmDeleteFile()}>
            Confirm
          </Button>
        </div>
      </Modal>

      <Modal level={10} open={!!renameFile} onClose={() => setRenameFile(null)} allowBackdropClick title="Rename File">
        <div className="modal__body">
          <p>Enter the new name for the {!!renameFile && renameFile.isFolder ? 'folder' : 'file'}.</p>
          <div className="form-group">
            <input className="form-control" name="renameNewFile" placeholder="New Name" onChange={e => updateRenameFile(e.target.value)} value={renameNewFile || ''} autoFocus />
            {renameFileError && <p className="form-error mt-2">{renameFileError}</p>}
          </div>
        </div>
        <div className="modal__actions">
          <Button actionType="flat" onClick={() => setRenameFile(null)}>
            Cancel
          </Button>
          <Button actionType="primary" onClick={() => confirmRenameFile()}>
            Confirm
          </Button>
        </div>
      </Modal>
    </Scoped>;
}