import React from 'react';
function Icon() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none" viewBox="0 0 24 25">
      <mask id="mask0_136_275" style={{
      maskType: 'alpha'
    }} width="25" height="25" x="-1" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M-0.001 0.5H23.999V24.5H-0.001z"></path>
      </mask>
      <g fill="#1D252D" mask="url(#mask0_136_275)">
        <path d="M17.694 5.98c-.251 0-.461.086-.63.255a.858.858 0 00-.254.631c0 .25.085.46.254.63.17.17.38.254.631.254.25 0 .46-.085.63-.255.17-.17.254-.38.254-.63 0-.251-.085-.461-.255-.63a.858.858 0 00-.63-.254zm0 3.885c-.251 0-.461.085-.63.255a.857.857 0 00-.254.63c0 .252.085.461.254.63.17.17.38.255.631.255.25 0 .46-.085.63-.255.17-.17.254-.38.254-.63 0-.252-.085-.462-.255-.63a.858.858 0 00-.63-.255zm0 3.885c-.251 0-.461.085-.63.255a.857.857 0 00-.254.63c0 .251.085.461.254.63.17.17.38.254.631.254.25 0 .46-.085.63-.254.17-.17.254-.38.254-.631 0-.25-.085-.461-.255-.63a.858.858 0 00-.63-.254zm-3.788-7.635H9.714a.725.725 0 00-.535.216.726.726 0 00-.215.535c0 .212.072.39.215.534a.726.726 0 00.535.215h4.192c.212 0 .39-.072.534-.215a.726.726 0 00.216-.535.725.725 0 00-.216-.534.726.726 0 00-.534-.216zm0 3.885H9.714a.726.726 0 00-.535.216.726.726 0 00-.215.534c0 .213.072.391.215.535a.726.726 0 00.535.215h4.192c.212 0 .39-.072.534-.216a.726.726 0 00.216-.534.725.725 0 00-.216-.535.726.726 0 00-.534-.215zm0 3.885H9.714a.726.726 0 00-.535.215.726.726 0 00-.215.535c0 .213.072.39.215.534a.726.726 0 00.535.216h4.192c.212 0 .39-.072.534-.216a.726.726 0 00.216-.535.724.724 0 00-.216-.534.726.726 0 00-.534-.215zM20.444 2.25c.506 0 .933.175 1.283.525.35.35.525.778.525 1.283v13.384c0 .505-.175.933-.525 1.283-.35.35-.777.525-1.283.525H7.06c-.505 0-.933-.175-1.283-.525a1.745 1.745 0 01-.525-1.283V4.058c0-.505.175-.933.525-1.283.35-.35.778-.525 1.283-.525h13.384zm0 1.5H7.06a.294.294 0 00-.212.096.294.294 0 00-.096.212v13.384c0 .077.032.148.096.212a.294.294 0 00.212.096h13.384a.294.294 0 00.212-.096.294.294 0 00.096-.212V4.058a.294.294 0 00-.096-.212.294.294 0 00-.212-.096z"></path>
        <path d="M3.554 22.75c-.505 0-.933-.175-1.283-.525a1.745 1.745 0 01-.525-1.283V8.808c0-.213.072-.391.216-.535a.726.726 0 01.534-.215c.213 0 .391.072.535.215a.726.726 0 01.215.535v12.134c0 .077.032.148.096.212a.294.294 0 00.212.096h12.135c.212 0 .39.072.534.215a.726.726 0 01.216.535.726.726 0 01-.216.535.726.726 0 01-.534.215H3.554z"></path>
      </g>
    </svg>;
}
export default Icon;