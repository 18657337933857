import React from 'react';
function Icon(props) {
  return <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" fill="none" viewBox="0 0 25 24">
      <mask id="mask0_444_3280" style={{
      maskType: 'alpha'
    }} width="25" height="24" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0.237 0H24.237V24H0.237z"></path>
      </mask>
      <g mask="url(#mask0_444_3280)">
        <path fill={props.fill || '#1D252D'} d="M16.987 20.75c-.417 0-.771-.146-1.062-.438a1.444 1.444 0 01-.438-1.062v-1.5h-2.5c-.417 0-.771-.146-1.062-.438a1.444 1.444 0 01-.438-1.062v-8.5h-2.5v1.5c0 .417-.146.77-.438 1.062a1.444 1.444 0 01-1.062.438h-3.5c-.417 0-.771-.146-1.062-.438a1.444 1.444 0 01-.438-1.062v-4.5c0-.417.146-.77.438-1.062a1.444 1.444 0 011.062-.438h3.5c.416 0 .77.146 1.062.438.292.291.438.645.438 1.062v1.5h6.5v-1.5c0-.417.146-.77.438-1.062a1.444 1.444 0 011.062-.438h3.5c.416 0 .77.146 1.062.438.292.291.438.645.438 1.062v4.5c0 .417-.146.77-.438 1.062a1.444 1.444 0 01-1.062.438h-3.5c-.417 0-.771-.146-1.062-.438a1.444 1.444 0 01-.438-1.062v-1.5h-2.5v8.5h2.5v-1.5c0-.417.146-.77.438-1.062a1.444 1.444 0 011.062-.438h3.5c.416 0 .77.146 1.062.438.292.291.438.645.438 1.062v4.5c0 .417-.146.77-.438 1.062a1.444 1.444 0 01-1.062.438h-3.5zm0-11.5h3.5v-4.5h-3.5v4.5zm0 10h3.5v-4.5h-3.5v4.5zm-13-10h3.5v-4.5h-3.5v4.5z"></path>
      </g>
    </svg>;
}
export default Icon;