import React from 'react';
function Icon(props) {
  return <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" fill="none" viewBox="0 0 25 24">
      <mask id="mask0_442_3705" style={{
      maskType: 'alpha'
    }} width="25" height="24" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0.237 0H24.237V24H0.237z"></path>
      </mask>
      <g mask="url(#mask0_442_3705)">
        <path fill={props.fill || '#1D252D'} d="M8.237 11.739c.25 0 .458-.084.625-.25a.848.848 0 00.25-.625.848.848 0 00-.25-.625.848.848 0 00-.625-.25.848.848 0 00-.625.25.848.848 0 00-.25.625c0 .25.083.458.25.625.166.166.375.25.625.25zm4 0c.25 0 .458-.084.625-.25a.848.848 0 00.25-.625.848.848 0 00-.25-.625.848.848 0 00-.625-.25.848.848 0 00-.625.25.848.848 0 00-.25.625c0 .25.083.458.25.625.166.166.375.25.625.25zm4 0c.25 0 .458-.084.625-.25a.848.848 0 00.25-.625.848.848 0 00-.25-.625.848.848 0 00-.625-.25.848.848 0 00-.625.25.848.848 0 00-.25.625c0 .25.083.458.25.625.166.166.375.25.625.25zm-13.5 7.975V5.164c0-.5.175-.925.525-1.275.35-.35.775-.525 1.275-.525h15.4c.5 0 .925.175 1.275.525.35.35.525.775.525 1.275v11.4c0 .5-.175.925-.525 1.275-.35.35-.775.525-1.275.525H6.287l-2.025 2c-.284.283-.609.346-.975.188-.367-.16-.55-.438-.55-.838z"></path>
      </g>
    </svg>;
}
export default Icon;