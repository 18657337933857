import React from 'react';
function Icon(props) {
  return <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" fill="none" viewBox="0 0 20 21">
      <mask id="mask0_64_3698" style={{
      maskType: 'alpha'
    }} width="21" height="21" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0 0.487H20V20.487H0z"></path>
      </mask>
      <g mask="url(#mask0_64_3698)">
        <path fill={props.fill || '#1D252D'} d="M4.5 17.57a1.53 1.53 0 01-1.125-.458 1.53 1.53 0 01-.458-1.125v-11c0-.444.153-.82.458-1.125A1.53 1.53 0 014.5 3.404h4.604c.167 0 .32.066.458.198a.625.625 0 010 .937.651.651 0 01-.458.198H4.5c-.055 0-.11.028-.167.083-.055.056-.083.112-.083.167v11c0 .056.028.111.083.167.056.055.112.083.167.083h11c.056 0 .111-.027.167-.083.056-.056.083-.111.083-.167v-4.604c0-.166.066-.32.198-.458a.625.625 0 01.47-.209c.18 0 .336.07.467.21a.651.651 0 01.198.457v4.604c0 .445-.152.82-.458 1.125a1.53 1.53 0 01-1.125.458h-11zm3.167-4.75a.591.591 0 01-.209-.458c0-.18.07-.34.21-.479l7.144-7.146H12.5a.641.641 0 01-.469-.198.641.641 0 01-.198-.469c0-.18.066-.336.198-.468a.641.641 0 01.47-.198h3.791a.76.76 0 01.552.24.76.76 0 01.24.551v3.792c0 .18-.067.337-.199.47a.638.638 0 01-.468.197.641.641 0 01-.469-.198.641.641 0 01-.198-.469V5.675l-7.167 7.166a.582.582 0 01-.448.208c-.173 0-.33-.076-.468-.229z"></path>
      </g>
    </svg>;
}
export default Icon;