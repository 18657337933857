import React from 'react';
import { v4 as uuid } from 'uuid';
import { Dropdown, Button, Icon } from 'components';
import { Grid, Item } from '@mui/material';
export const MultiItem = props => {
  const {
    param,
    update,
    data
  } = props;
  const {
    addLabel,
    key,
    items
  } = param;
  const addItem = () => {
    // TODO should I prepopulate the object with defaults?
    const newData = JSON.parse(JSON.stringify(data[key] || []));
    update({
      [key]: [...newData, {
        _i: uuid()
      }]
    });
  };
  const updateItem = (index, subKey, value) => {
    const newData = JSON.parse(JSON.stringify(data[key] || []));
    newData[index][subKey] = value;
    update({
      [key]: newData
    });
  };
  const removeItem = index => {
    const newData = JSON.parse(JSON.stringify(data[key] || []));
    newData.splice(index, 1);
    update({
      [key]: newData
    });
  };
  return <div className="form-group">
      <label>{param.label}</label>
      <table className="w-100">
        <tbody>
          {(data[key] || []).map((row, index) => <tr key={row._i}>
              {items.map(item => <td key={item.key}>
                  {item.type === 'select' && <select className="form-control" onChange={e => updateItem(index, item.key, e.target.value)} value={row[item.key] || ''}>
                      <option></option>
                      {item.options.map(option => <option key={option.key} value={option.key}>
                          {option.val}
                        </option>)}
                    </select>}
                  {item.type === 'input' && <input onChange={e => updateItem(index, item.key, e.target.value)} value={row[item.key] || ''} className="form-control"></input>}
                </td>)}
              <td width="30px">
                <Button icon="fa-regular-times" actionType="flat" onClick={() => removeItem(index)} tabIndex={-1} type="button" />
              </td>
            </tr>)}
        </tbody>
      </table>
      <Button className="mt-3" onClick={addItem} actionType="flat">
        + {addLabel}
      </Button>
    </div>;
};