import React, { useContext, useState, useEffect } from 'react';
import { UserStateContext } from 'context/user-state-context';
import { getFacebookReviewAccount, refreshFacebookToken, deleteFacebookReviewAccount, syncFacebookReviewPageRatings } from '../integrations.resource';
import { Icon, PageHeaderMui } from 'components';
import { Box, Button, IconButton, Paper, Stack, Tooltip, Typography, Divider, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, Checkbox } from '@mui/material';
import { CancelIcon, CheckCircleIcon, CloseIcon } from 'components/mui';
import { snackbarService } from 'components/mui/snackbar/snackbar-service';
import EnhancedTable from 'components/mui/enhanced-table/enhanced-table.component';
import { editLocationRef, getLocationRefs } from 'shared/common.api';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};
const FACEBOOKICON = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACkAAAAoCAYAAABjPNNTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALTSURBVHgBzZkxTBRBFIZ/JtdIg4mWGjqwQuzEBBoKm7v2Kkho1JhoZWy4swMLE2MhatQGghUl0GgiBSQaO8RG6VATGyHQcCXsv5M5dvdm92Z2dpb7ks1ddmdv/33z5s177/pOApCDzV1g5w/wPTi2gu9HLeDw+Oz6yBXgYr/8rN0AJoaQmz4bkRTxagNY+BwXZAIF164DjRoweMnqVjORLuJ0TI/Zie0qcnUbuLdUjLgkzUBoo9p9XKbIxyvSej6hr76bybZqqsi7i8DyV5QCBX58lC5U6E6WKZDs7QO3n8tPHR2WnFsH5tdQCFHLJEOUDoarb086z8dELn8JrLgEJ8aH5Oqtjcqwk4Sxtf4m3WoPJ4Fn9fi5ivrCm+bX4USzKkNLFiNXs6+/DBZqdTQe/Ns+yVWc9nZFCTQl6W6hSIpzCTX0vaIEEm65PBShSPqiCw8mUThRa4Y++cEx3EwMp1+jRThLR5GVbeJWvI/jOEsVZjEuvkgYOnTs/JXxLy9r23KWxNYuvDG3CieUX4rNX/DGYQtOKAMK16n2CXcoHuL3AXoabqfCR55YJHv/I9uiCSqlMmXlvrREFK4B2/ygwiTAxpo29Ql/O5lkDPTDGjFwAaXCLMiGwcuByLRA7AsbkZwFzpywLS9dsQl5yoAVFu4LG+Y36h6S9qK6sdwqTWmLVJ0Gk8XDh16b7Tzfeqsff2fxbNfIA7N7Iihw6iZ6Ds6Oyq7CfJJT3mtEk+hQJOuJcYeGUtHQiizmFO0a5/2Mvro7D5KlSFsk1c8a9GV8MzUWtyKJdTBY857ntNNQTU1B19FmYVJQdoAndLW0fpCwGeyLbs/UNqxUSlbGvm7yLJF1M5tHDY+Lif5vYgyBLtCRfz4tdvo5vWycfjJ0K6PMnD9Eoex0sG+Zdz+mxaZvyTBjE5Ot/n1QMNFg4c5++o8gq/n3Qj+u/lqWtdzRwmMYuTgFmxL6hnUmfpQAAAAASUVORK5CYII=';
export const IntegrationsConfigureFacebookReview = ({
  history,
  location,
  match
}) => {
  const {
    user,
    asCompany,
    hasPermission
  } = useContext(UserStateContext);
  const [loading, setLoading] = useState(true);
  const [tokenIsRefreshing, setTokenIsRefreshing] = useState();
  const [showDeleteCredentialModal, setShowDeleteCredentialModal] = useState();
  const [account, setAccount] = useState();
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState();
  const fetchFacebookAccount = async () => {
    try {
      const accountData = await getFacebookReviewAccount(match.params.id);
      setAccount(accountData);
      setFilters({
        entity_source: 'facebook_reviews',
        entity_data__account_id: accountData.id
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    setLoading(true);
    if (!user || !asCompany?.id) {
      return;
    }
    if (!match.params.id) {
      history.push(`/integrations`);
    }
    fetchFacebookAccount();
  }, [hasPermission, asCompany?.id]);
  const refresh = async () => {
    setTokenIsRefreshing(true);
    try {
      await refreshFacebookToken(account.token.id);
      snackbarService.popup({
        type: 'success',
        message: 'Token refreshed'
      });
    } catch (error) {
      snackbarService.popup({
        type: 'error',
        message: 'Unable to refresh Token. You may need to reauthenticate permissions on your token'
      });
    } finally {
      setTokenIsRefreshing();
    }
  };
  const handleDeleteCredential = async accountId => {
    try {
      await deleteFacebookReviewAccount(accountId);
      snackbarService.popup({
        type: 'success',
        message: `Account has been removed successfully!`
      });
      history.push(`/integrations/manage/facebook_reviews`);
    } catch (error) {
      snackbarService.popup({
        type: 'error',
        message: `There was an issue removing this Account.`
      });
    } finally {
      setShowDeleteCredentialModal();
    }
  };
  const handleRowClick = (data, column) => {
    if (!hasPermission('integration.change_credential')) {
      snackbarService.popup({
        type: 'error',
        message: 'You do not have permission to edit this credential'
      });
      return;
    }

    // if (column == 'location' || column == 'ref') {
    //   setMapLocation(data);
    // }
  };
  const headCells = [{
    key: 'ref',
    label: 'Facebook Page',
    noSort: true,
    displayComponent: ({
      data
    }) => <Stack>
          <Box>
            <strong>{data.name}</strong>
          </Box>
          <Box>
            {data.address1}, {data.city}, {data.state}, {data.zip_code}
          </Box>
        </Stack>
  }, {
    key: 'location',
    label: 'Matched Location',
    noSort: true,
    displayComponent: ({
      data
    }) => {
      if (!data.location) {
        return <i>No Match</i>;
      }
      return <Stack>
            <Box>
              <strong>{data.location_name}</strong>
            </Box>
            <Box>
              {data.location_address1}, {data.location_city}, {data.location_state}, {data.location_zip_code}
            </Box>
          </Stack>;
    }
  }, {
    key: 'is_active',
    label: 'Activate Location',
    noSort: true,
    displayComponent: ({
      data: row
    }) => <Checkbox color="primary" disabled={!hasPermission('integration.change_credential')} checked={row.is_active} onChange={async () => {
      try {
        const newIsActive = !row.is_active;
        await editLocationRef(row.id, {
          is_active: newIsActive
        });
        if (newIsActive === true) {
          await syncFacebookReviewPageRatings(account.id, {
            location_ref_id: row.id
          });
        }
        snackbarService.popup({
          type: 'success',
          message: 'This change was saved'
        });
        setData(data => {
          const newData = JSON.parse(JSON.stringify(data));
          const newRow = newData.find(r => r.id === row.id);
          newRow.is_active = !newRow.is_active;
          return newData;
        });
      } catch (error) {
        console.error(error);
      }
    }} sx={{
      color: '#1D252D',
      '&.Mui-checked': {
        color: '#53A6D6'
      },
      '& .MuiSvgIcon-root': {
        fontSize: 22
      }
    }} />
  }];
  const gangsterWrapper = () => {
    return params => getLocationRefs({
      ...params,
      ...filters
    });
  };
  return <>
      <div className="wrapper" style={{
      height: loading ? '100vh' : 'auto'
    }}>
        <PageHeaderMui type="Integrations and Apps" icon={<Icon name="custom-call_merge" size={34} />} />

        <Box sx={{
        p: '20px',
        display: 'flex',
        justifyContent: 'center'
      }}>
          <Paper sx={{
          borderRadius: '14px',
          maxWidth: '1080px',
          width: '800px',
          minHeight: loading ? '60vh' : 'none'
        }}>
            {loading ? <Box sx={{
            width: '100%',
            height: '100%'
          }}>
                <Box sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              justifyItems: 'center',
              alignItems: 'center'
            }}>
                  <CircularProgress />
                </Box>
              </Box> : <Stack sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}>
                <Stack sx={{
              py: '10px'
            }}>
                  <Stack>
                    <Box sx={{
                  px: '20px',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  pb: '10px'
                }}>
                      <Stack direction="row" spacing="20px">
                        <Box>
                          <img src={FACEBOOKICON} height="40" width="40" alt="facebook" />
                        </Box>

                        <Box sx={{
                      display: 'flex',
                      alignItems: 'center'
                    }}>
                          <Typography sx={{
                        fontSize: '20px',
                        fontWeight: '300',
                        lineHeight: '28px'
                      }}>
                            {account?.name || 'Facebook Reviews'}
                          </Typography>
                        </Box>

                        {account && <Tooltip title="You have configured this credential">
                            <Stack direction="row" spacing="8px" sx={{
                        p: '7px',
                        borderRadius: '8px',
                        backgroundColor: '#C5EAD7'
                      }}>
                              <Box sx={{
                          display: 'flex',
                          alignItems: 'center'
                        }}>
                                <CheckCircleIcon size="sm" fill="#3EB87B" />
                              </Box>
                              <Box sx={{
                          display: 'flex',
                          alignItems: 'center',
                          pr: '4px'
                        }}>
                                <Typography sx={{
                            color: '#3EB87B'
                          }}>Connected</Typography>
                              </Box>
                            </Stack>
                          </Tooltip>}
                      </Stack>

                      <Box>
                        <IconButton onClick={e => {
                      history.push(`/integrations`);
                    }}>
                          <CloseIcon />
                        </IconButton>
                      </Box>
                    </Box>
                    <Divider />
                  </Stack>

                  <Stack sx={{
                px: '20px',
                pt: '20px',
                pb: '10px'
              }}>
                    <Box sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}>
                      <Typography sx={{
                    fontSize: '18px',
                    lineHeight: '26px'
                  }}>Pages</Typography>
                      {hasPermission('integration.change_credential') && <>
                          <Box>
                            <Button variant="contained" endIcon={tokenIsRefreshing && <CircularProgress sx={{
                        color: '#FFF'
                      }} size={'20px'} />} onClick={async () => {
                        if (tokenIsRefreshing) {
                          return;
                        }
                        await refresh();
                      }}>
                              {tokenIsRefreshing ? 'Resyncing' : 'Resync Token'}
                            </Button>
                          </Box>
                        </>}
                    </Box>
                    {!hasPermission('integration.change_credential') && <>You do not have permission to edit this page</>}
                  </Stack>
                  <EnhancedTable type="Locations" headCells={headCells} api={gangsterWrapper()} defaultSort="name" handleRowClick={handleRowClick} dataState={[data, setData]} />
                  <Stack sx={{
                pt: '16px'
              }}>
                    <Divider />
                    <Box sx={{
                  p: '16px',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}>
                      <Stack direction="row" spacing="16px">
                        {hasPermission('integration.delete_credential') && <Button variant="contained" sx={{
                      backgroundColor: '#F3F3F4',
                      '&:hover': {
                        backgroundColor: '#F3F3F4'
                      }
                    }} startIcon={<CancelIcon fill="#3898D9" />} onClick={e => {
                      setShowDeleteCredentialModal(true);
                    }}>
                            <Box sx={{
                        py: '5px',
                        pr: '12px'
                      }}>
                              <Typography sx={{
                          color: '#3898D9'
                        }}>Remove </Typography>
                            </Box>
                          </Button>}
                      </Stack>
                      <Stack direction="row" spacing="16px">
                        <Button variant="contained" sx={{
                      backgroundColor: '#F3F3F4',
                      '&:hover': {
                        backgroundColor: '#F3F3F4'
                      }
                    }} onClick={e => {
                      history.push(`/integrations/manage/facebook_reviews`);
                    }}>
                          <Box sx={{
                        py: '5px',
                        px: '12px'
                      }}>
                            <Typography sx={{
                          color: '#3898D9'
                        }}>Cancel </Typography>
                          </Box>
                        </Button>
                      </Stack>
                    </Box>
                  </Stack>
                </Stack>
              </Stack>}
          </Paper>
        </Box>
      </div>

      <Dialog scroll="paper" PaperProps={{
      style: {
        borderRadius: '28px'
      }
    }} open={!!showDeleteCredentialModal} onClose={(e, r) => {
      setShowDeleteCredentialModal();
    }}>
        <DialogTitle sx={{
        m: 0,
        p: 2,
        fontSize: 'medium'
      }}>
          <Stack spacing={'20px'}>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Stack direction="row" spacing={'10px'}>
                <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                  <Typography sx={{
                  fontWeight: '300',
                  lineHeight: '30px',
                  fontSize: '24px',
                  display: 'flex',
                  alignItems: 'center'
                }} variant="h4" component="span">
                    Remove Linked Facebook Account
                  </Typography>
                </Box>
              </Stack>

              <IconButton onClick={() => {
              setShowDeleteCredentialModal();
            }}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Divider />
          </Stack>
        </DialogTitle>

        <DialogContent sx={{
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
          width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
          marginTop: '20px',
          marginBottom: '20px'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#888'
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555'
        }
      }}>
          <Stack sx={{
          pt: 1
        }} spacing={'24px'}>
            Are you sure you want to unlink this account?
          </Stack>
        </DialogContent>

        <DialogActions sx={{
        pt: 1,
        pb: 2
      }}>
          <Stack sx={{
          pr: '24px'
        }} direction={'row'} spacing={'20px'}>
            <Button onClick={() => {
            setShowDeleteCredentialModal();
          }} variant="contained" sx={{
            backgroundColor: '#F3F3F4',
            '&:hover': {
              backgroundColor: '#F3F3F4'
            }
          }}>
              <Box sx={{
              py: '5px',
              px: '12px'
            }}>
                <Typography sx={{
                color: '#3898D9'
              }}>Cancel </Typography>
              </Box>
            </Button>
            <Button onClick={async () => {
            await handleDeleteCredential(account.id);
          }} variant="contained" sx={{
            backgroundColor: '#3898D9',
            '&:hover': {
              backgroundColor: '#3898D9'
            }
          }}>
              <Box sx={{
              py: '5px',
              px: '12px'
            }}>
                <Typography sx={{
                color: '#fff'
              }}> Remove</Typography>
              </Box>
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </>;
};