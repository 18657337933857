import React, { useContext } from 'react';
import { Loader, PageHeaderMui } from 'components';
import { Box, Button, ClickAwayListener, Grid, IconButton, Paper, Popper, Tooltip, Typography, Table, TableBody, TableRow, TableHead, TableCell, TextField } from '@mui/material';
import Stack from '@mui/material/Stack';
import CssBaseline from '@mui/material/CssBaseline';
import { UserStateContext } from 'context/user-state-context';
import * as IconsIndex from '../../components/mui/icon/index';
import * as Icons from '../../components/mui/icon';
import { createElement } from 'react';
const iconExludeList = ['ActionItemGettingStartedIcon', 'CsvImportWizardIcon'];
export const IconList = props => {
  const [iconList, setIconList] = React.useState([]);
  const [search, setSearch] = React.useState('');
  React.useEffect(() => {
    const tempList = [];
    Object.getOwnPropertyNames(IconsIndex).forEach((val, idx, array) => {
      if (!val.includes('Icon')) {
        return;
      }
      if (!iconExludeList.includes(val)) {
        tempList.push({
          name: val
        });
      }
    });
    const rows = [...Array(Math.ceil(tempList.length / 4))];
    const productRows = rows.map((row, idx) => tempList.slice(idx * 4, idx * 4 + 4));
    setIconList(productRows);
  }, []);
  return <>
      <CssBaseline />
      <div className="wrapper">
        <PageHeaderMui type={'Icon List'} />

        <div className="mui-wrapper">
          <Box sx={{
          m: '20px'
        }}>
            <Paper sx={{
            width: '100%',
            borderRadius: '14px',
            display: 'flex',
            alignItems: 'center'
          }}>
              <Stack sx={{
              p: 3,
              width: '100%'
            }}>
                <Box sx={{
                pb: 2
              }}>
                  <TextField variant="outlined" autoFocus label="Search Icons" value={search || ''} onChange={e => setSearch(e.target.value)} />
                </Box>
                <Table>
                  <colgroup>
                    <col width="25%" />
                    <col width="25%" />
                    <col width="25%" />
                    <col width="25%" />
                  </colgroup>

                  <TableBody>
                    {iconList.map((row, idx) => <TableRow key={idx}>
                        {row.filter(r => r.name.toLowerCase().includes(search.toLowerCase())).map((icon, index) => <TableCell sx={{
                      borderLeft: '1px solid rgba(224, 224, 224, 1)'
                    }} key={index}>
                              <Stack direction="row" spacing={'15px'}>
                                <Box sx={{
                          width: '20px'
                        }}> {idx * 4 + (index + 1)}</Box>
                                <Box sx={{
                          width: '165px'
                        }}> {icon.name}</Box>
                                <Box> {createElement(Icons[icon.name])}</Box>
                              </Stack>
                            </TableCell>)}
                      </TableRow>)}
                  </TableBody>
                </Table>
              </Stack>
            </Paper>
          </Box>
        </div>
      </div>
    </>;
};