import React, { useState, useEffect, useContext } from 'react';
import styles from './roles-list.styles.scss';
import { Scoped } from 'kremling';
import { PageHeader } from '../../components/page-header/page-header';
import { Button } from '../../components/button/button.component';
import { Loader } from '../../components/loader/loader.component';
import { Pagination } from '../../components/pagination/pagination.component';
import { getRoles } from '../../shared/common.api';
import { UserStateContext } from 'context/user-state-context';
import { SortHeader } from '../../components/table/sort-header.component';
import { Icon, PageHeaderMui } from 'components';
const RolesList = props => {
  const {
    asCompany,
    hasPermission
  } = useContext(UserStateContext);
  const [roles, setRoles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [ordering, setOrdering] = useState();
  const [paginationParams, setPaginationParams] = useState({
    offset: 0,
    limit: 20
  });
  const [count, setCount] = useState();
  useEffect(() => {
    setIsLoading(true);
    getRoles({
      ...paginationParams,
      search,
      company: asCompany.id,
      ordering
    }).then(({
      data
    }) => {
      if (data) {
        setRoles(data.results);
        setCount(data.count);
        setIsLoading(false);
      }
    });
  }, [search, ordering, paginationParams, asCompany]);
  return <Scoped css={styles}>
      <div className="wrapper">
        <PageHeaderMui type="Roles" icon={<Icon name="custom-assignment_ind" size={34} />} />
        <PageHeader name="Roles" onSearch={setSearch} actions={hasPermission('authentication.add_role') && <Button actionType="primary" icon="fa-regular-plus" onClick={() => props.history.push('/roles/new')} />} />
        <div className="wrapper-scroll roles-list">
          {isLoading && <Loader overlay />}

          <table className="table-list">
            {!isLoading && !!roles.length && <thead>
                <tr>
                  <SortHeader name="name" ordering={ordering} update={setOrdering}>
                    Name
                  </SortHeader>
                  <SortHeader name="company" ordering={ordering} update={setOrdering}>
                    Inherited
                  </SortHeader>
                </tr>
              </thead>}
            <tbody>
              {roles.length && !isLoading && roles.map(role => <tr key={role.id} onClick={() => props.history.push(`/roles/${role.id}`)}>
                    <td>
                      {role.display_name || '-'}
                      {!!role.description && <>
                          <br /> <small>{role.description}</small>
                        </>}
                    </td>
                    <td>{!role.company && <em>Inherited from Cinch System</em>}</td>
                  </tr>) || <tr>
                  {!isLoading && <td colSpan={2}>
                      <strong>No Results</strong>
                    </td>}
                </tr>}
            </tbody>
          </table>
          <Pagination data={{
          count
        }} onChange={setPaginationParams} />
        </div>
      </div>
    </Scoped>;
};
export default RolesList;