import React from 'react';
function Icon(props) {
  if (props.size == 'sm') {
    return <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 -960 960 960">
        <path fill={props.fill || '#1D252D'} d="M481.56-255.386q16.132 0 27.44-11.329 11.307-11.329 11.307-27.461 0-16.132-11.329-27.439-11.329-11.308-27.461-11.308-16.132 0-27.439 11.329-11.308 11.329-11.308 27.461 0 16.132 11.329 27.44 11.329 11.307 27.461 11.307zm-28.329-142.23h55.307q.769-33.538 7.654-47.692 6.884-14.153 27.269-34.307 35.384-34.385 47.923-57.231 12.538-22.846 12.538-49.279 0-50.643-33.614-84.182-33.615-33.538-86.308-33.538-44.076 0-78.884 23.731-34.807 23.73-49.807 63.96l50.383 21.615q11.308-29.923 31-43.307 19.693-13.385 44.539-13.385 31.462 0 50.423 17.924 18.962 17.923 18.962 46.231 0 23.384-15.116 40.807-15.115 17.423-34.115 35.193-30.384 28.538-39.269 50.192-8.885 21.654-8.885 63.268zm26.836 281.615q-74.836 0-141.204-28.42-66.369-28.42-116.182-78.21-49.814-49.791-78.247-116.129-28.433-66.337-28.433-141.173 0-75.836 28.42-141.704 28.42-65.869 78.21-115.682 49.791-49.814 116.129-78.247 66.337-28.433 141.173-28.433 75.836 0 141.704 28.42 65.869 28.42 115.682 78.21 49.814 49.791 78.247 115.629 28.433 65.837 28.433 141.673 0 74.836-28.42 141.204-28.42 66.369-78.21 116.182-49.791 49.814-115.629 78.247-65.837 28.433-141.673 28.433zM480-168q130 0 221-91t91-221q0-130-91-221t-221-91q-130 0-221 91t-91 221q0 130 91 221t221 91zm0-312z"></path>
      </svg>;
  }
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 -960 960 960">
      <path fill={props.fill || '#1D252D'} d="M479.56-255.386q17.132 0 28.94-11.829 11.807-11.829 11.807-28.961 0-17.132-11.829-28.939-11.829-11.808-28.961-11.808-17.132 0-28.939 11.829-11.808 11.829-11.808 28.961 0 17.132 11.829 28.94 11.829 11.807 28.961 11.807zm-28.329-143.23h56.307q.769-29.538 8.654-47.192 7.884-17.653 38.269-46.807 26.384-26.385 40.423-48.731 14.038-22.346 14.038-52.779 0-51.643-37.114-80.682-37.115-29.038-87.808-29.038-50.076 0-82.884 26.731-32.807 26.73-46.807 62.96l51.383 20.615q7.308-19.923 25-38.807 17.693-18.885 52.539-18.885 35.462 0 52.423 19.424 16.962 19.423 16.962 42.731 0 20.384-11.616 37.307-11.615 16.923-29.615 32.693-39.384 35.538-49.769 56.692-10.385 21.154-10.385 63.768zm28.836 298.615q-78.836 0-148.204-29.92-69.369-29.92-120.682-81.21-51.314-51.291-81.247-120.629-29.933-69.337-29.933-148.173t29.92-148.204q29.92-69.369 81.21-120.682 51.291-51.314 120.629-81.247 69.337-29.933 148.173-29.933t148.204 29.92q69.369 29.92 120.682 81.21 51.314 51.291 81.247 120.629 29.933 69.337 29.933 148.173t-29.92 148.204q-29.92 69.369-81.21 120.682-51.291 51.314-120.629 81.247-69.337 29.933-148.173 29.933zM480-160q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93zm0-320z"></path>
    </svg>;
}
export default Icon;