import React from "react";
function Icon() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <mask id="mask0_38_3036" style={{
      maskType: "alpha"
    }} width="24" height="24" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0 0H24V24H0z"></path>
      </mask>
      <g mask="url(#mask0_38_3036)">
        <path fill="#1D252D" d="M12.796 13.558l-1.338 1.338a.725.725 0 00-.213.522.707.707 0 00.213.532c.144.145.32.217.527.217a.718.718 0 00.527-.217l2.51-2.51a.867.867 0 00.27-.632.867.867 0 00-.27-.633l-2.51-2.51a.725.725 0 00-.523-.212.707.707 0 00-.531.212.717.717 0 00-.218.527c0 .207.073.382.218.527l1.338 1.339h-3.7a.726.726 0 00-.534.215.726.726 0 00-.216.535c0 .213.072.39.216.534a.726.726 0 00.534.216h3.7zm-8.488 5.75c-.505 0-.933-.175-1.283-.525A1.745 1.745 0 012.5 17.5v-11c0-.505.175-.933.525-1.283.35-.35.778-.525 1.283-.525h5.329c.24 0 .472.047.695.14.222.094.415.223.58.387L12 6.308h7.692c.505 0 .933.175 1.283.525.35.35.525.777.525 1.282V17.5c0 .505-.175.933-.525 1.283-.35.35-.778.525-1.283.525H4.308zM4 6.5v11a.3.3 0 00.087.221.3.3 0 00.22.087h15.385a.3.3 0 00.221-.087A.3.3 0 0020 17.5V8.115a.3.3 0 00-.087-.22.3.3 0 00-.22-.087h-8.307L9.771 6.192H4.308a.3.3 0 00-.221.087A.3.3 0 004 6.5zm0 0v-.308 11.616V6.5z"></path>
      </g>
    </svg>;
}
export default Icon;