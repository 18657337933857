import React from 'react';
function Icon(props) {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none" viewBox="0 0 24 25">
      <mask id="mask0_49_1307" style={{
      maskType: 'alpha'
    }} width="24" height="25" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0 0.5H24V24.5H0z"></path>
      </mask>
      <g mask="url(#mask0_49_1307)">
        <path fill={props.fill || '#1D252D'} d="M12 15.507c-.117 0-.229-.017-.337-.05a.605.605 0 01-.288-.2l-4.525-4.5a.766.766 0 01-.2-.525c0-.2.075-.375.225-.525.15-.134.325-.2.525-.2s.375.066.525.2L12 13.782l4.1-4.075c.133-.15.3-.221.5-.213.2.008.375.08.525.213.15.15.225.329.225.537 0 .208-.075.38-.225.513l-4.5 4.5a.605.605 0 01-.287.2c-.109.033-.221.05-.338.05z"></path>
      </g>
    </svg>;
}
export default Icon;