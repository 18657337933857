import React from 'react';
function Icon() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none" viewBox="0 0 24 25">
      <mask id="mask0_440_6015" style={{
      maskType: 'alpha'
    }} width="24" height="25" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0 0.5H24V24.5H0z"></path>
      </mask>
      <g mask="url(#mask0_440_6015)">
        <path fill="#fff" d="M2.692 16.399V4.629c0-.251.085-.461.255-.63.17-.17.38-.255.63-.255h12.461c.251 0 .461.085.63.254.17.17.255.38.255.63v8.462c0 .25-.085.46-.254.63a.856.856 0 01-.63.255H6.23L3.475 16.73a.43.43 0 01-.499.103c-.19-.075-.284-.22-.284-.434zm4.423 1.96a.856.856 0 01-.63-.254.856.856 0 01-.254-.63v-1.5h12.375l.317.317V7.244h1.5c.25 0 .46.084.63.254.17.17.255.38.255.63v12.656c0 .214-.095.359-.284.434a.43.43 0 01-.499-.103l-2.756-2.756H7.115z"></path>
      </g>
    </svg>;
}
export default Icon;