function floatPrecision(floatValue, precision) {
  floatValue = parseFloat(floatValue);
  if (isNaN(floatValue)) {
    return parseFloat('0').toFixed(precision);
  } else {
    const power = Math.pow(10, precision);
    floatValue = (Math.round(floatValue * power) / power).toFixed(precision);
    return floatValue.toString();
  }
}
function fileSize(size) {
  if (size > 1024) {
    const kbSize = size / 1024;
    if (kbSize > 1024) {
      const mbSize = kbSize / 1024;
      return `${floatPrecision(mbSize, 2)} MB`;
    }
    return `${Math.round(kbSize)} kB`;
  }
  return `${size} B`;
}
const formatFile = file => {
  file.isFolder = file.Key[file.Key.length - 1] === '/';
  const keyParts = file.Key.split('/');
  file.shortKey = keyParts.slice(2, keyParts.length).join('/');
  if (file.isFolder) keyParts.pop();
  file.name = keyParts[keyParts.length - 1];
  file.sortParts = keyParts.slice(2, keyParts.length).map(p => 'A' + p);
  if (!file.isFolder) {
    file.sortParts[file.sortParts.length - 1] = 'B' + file.sortParts[file.sortParts.length - 1].substring(1);
    file.extension = (file.name.split('.').pop() || '').toLowerCase();
  } else {
    file.sortParts[file.sortParts.length - 1] = 'A' + file.sortParts[file.sortParts.length - 1].substring(1);
  }
  file.parentFolder = keyParts.slice(2, keyParts.length - 1).join('/') + '/';
  file.paddingLeft = (keyParts.length - 2) * 20;
  file.expanded = false;
  return file;
};
export { floatPrecision, fileSize, formatFile };