import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { AddIcon } from '../icon';
export const NewButton = props => {
  return <Box sx={{
    zIndex: 100,
    position: 'relative',
    backgroundColor: '#3898D9',
    borderRadius: '8px',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.40)'
    }
  }} onClick={e => props.onClick(e)}>
      <Stack sx={{
      px: '15px',
      py: '6px',
      display: 'flex',
      alignItems: 'center',
      height: '100%'
    }} spacing="10px" direction="row">
        {props.icon || <AddIcon fill="#fff" />}
        <Typography sx={{
        color: '#fff'
      }}>{props.label || 'New'}</Typography>
      </Stack>
    </Box>;
};