import React from 'react';
function Icon(props) {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <mask id="mask0_56_2395" style={{
      maskType: 'alpha'
    }} width="24" height="24" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0 0H24V24H0z"></path>
      </mask>
      <g mask="url(#mask0_56_2395)">
        <path fill={props.fill || '#1D252D'} d="M15.813 15.725a.805.805 0 01-.226-.55c0-.2.075-.375.226-.525l1.9-1.9h-7.65a.726.726 0 01-.75-.75.728.728 0 01.75-.75h7.65l-1.9-1.925a.72.72 0 01-.226-.525c0-.2.075-.375.226-.525a.72.72 0 01.524-.225c.2 0 .367.067.5.2l3.125 3.125a.826.826 0 01.189.287.944.944 0 010 .675.823.823 0 01-.189.288l-3.125 3.125c-.15.167-.32.242-.511.225a.81.81 0 01-.514-.25zM5.586 20.75c-.5 0-.925-.175-1.275-.525a1.736 1.736 0 01-.525-1.275V5.05c0-.5.175-.925.525-1.275.35-.35.775-.525 1.275-.525h5.976c.2 0 .374.07.524.212a.71.71 0 01.226.538.706.706 0 01-.226.537.74.74 0 01-.524.213H5.587c-.066 0-.133.033-.2.1-.066.067-.1.133-.1.2v13.9c0 .067.034.133.1.2.067.067.134.1.2.1h5.976c.2 0 .374.07.524.212a.71.71 0 01.226.538.71.71 0 01-.226.538.741.741 0 01-.524.212H5.587z"></path>
      </g>
    </svg>;
}
export default Icon;