import React, { useState } from 'react';
import { useCss } from 'kremling';
import { api } from '../shared/api';
import styles from './loyalty.styles.scss';
import { Button } from '../components/button/button.component';
import { Loader } from '../components/loader/loader.component';
export function LoyaltyForm(props) {
  const scope = useCss(styles);
  const [phone, updatePhone] = useState('');
  const [phoneConfirm, updatePhoneConfirm] = useState('');
  const [optin, updateOptin] = useState(true);
  const [email, updateEmail] = useState('');
  const [password, updatePassword] = useState('');
  const [passwordConfirm, updatePasswordConfirm] = useState('');
  const [firstName, updateFirstName] = useState('');
  const [lastName, updateLastName] = useState('');
  const [address1, updateAddress1] = useState('');
  const [address2, updateAddress2] = useState('');
  const [city, updateCity] = useState('');
  const [state, updateState] = useState('');
  const [zip, updateZip] = useState('');
  const [birthday, updateBirthday] = useState('');
  const [birthmonth, updateBirthmonth] = useState('');
  const [birthyear, updateBirthyear] = useState('');
  const [gender, updateGender] = useState('');
  const [errorMessage, updateErrorMessage] = useState(false);
  const [formSubmitted, updateFormSubmitted] = useState(false);
  const [submitError, updateSubmitError] = useState(false);
  const [loading, updateLoading] = useState(false);
  function submit() {
    let hasCreateCinch = email || password || passwordConfirm || firstName || lastName || address1 || address2 || city || state || zip || birthday || birthmonth || birthyear || gender;
    if (!phone || !phoneConfirm || !optin) {
      updateErrorMessage('Please fill in required fields before submitting.');
    } else if (phone !== phoneConfirm) {
      updateErrorMessage('Phone numbers don\'t match.');
    } else if (hasCreateCinch && (!email || !password || !passwordConfirm || !firstName || !lastName || !zip)) {
      updateErrorMessage('When attempting to create a Cinch rewards account all secondary required fields are required.');
    } else if (hasCreateCinch && password !== passwordConfirm) {
      updateErrorMessage('Passwords don\'t match.');
    } else {
      updateSubmitError(false);
      updateErrorMessage(false);
      updateFormSubmitted(true);
      updateLoading(true);
      api.post(`/loyalty/signup`, {
        email,
        phone,
        password,
        password_confirmation: passwordConfirm,
        first_name: firstName,
        last_name: lastName,
        address1,
        address2,
        city,
        state,
        postal_code: zip,
        birthday: birthday && birthmonth && birthyear ? `${birthyear}/${birthmonth}/${birthday}` : null,
        sms_opt_out: false,
        location_apikey: props.locationAPIKey
      }).then(() => updateLoading(false)).catch(e => {
        if (e.response && e.response.data.error) {
          updateFormSubmitted(false);
          updateErrorMessage(e.response.data.error);
        } else {
          updateSubmitError(true);
        }
        updateLoading(false);
      });
    }
  }
  function cancel() {
    updatePhone('');
    updatePhoneConfirm('');
    updateOptin('');
    updateEmail('');
    updatePassword('');
    updatePasswordConfirm('');
    updateFirstName('');
    updateLirstName('');
    updateAddress1('');
    updateAddress2('');
    updateCity('');
    updateState('');
    updateZip('');
    updateBirthday('');
    updateBirthmonth('');
    updateBirthyear('');
    updateGender('');
    updateErrorMessage(false);
    updateFormSubmitted(false);
    updateSubmitError(false);
    updateLoading(false);
  }
  if (loading) return <Loader overlay />;
  return <div {...scope} className="fb-test">

      {formSubmitted ? <div className="form-wrapper">
            {submitError ? <>
                <h2>Uh Oh...</h2>
                <div className="mb-md">Looks like there was an error submitting the form...</div>
                <div className="text-right">
                  <Button onClick={cancel} className="mr-sm">Reset</Button>
                  <Button onClick={() => updateFormSubmitted(false)} actionType="primary">Try again</Button>
                </div>
              </> : <>
                <h2>Thank you!</h2>
                <div>Form Submitted</div>
              </>}
          </div> : <>
            <div className="form-wrapper mb-lg">
              <h2 className="text-center mb-md">{props.title}</h2>

              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label>Mobile Phone <span className="form-error">*</span></label>
                    <input type="tel" className="form-control" value={phone} onChange={e => updatePhone(e.target.value)} />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label>Confirm Phone <span className="form-error">*</span></label>
                    <input type="tel" className="form-control" value={phoneConfirm} onChange={e => updatePhoneConfirm(e.target.value)} />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label>Opt-in <span className="form-error">*</span></label>
                    <div className="d-flex">
                      <input type="checkbox" className="form-control form-checkbox" checked={optin} onChange={() => updateOptin(!optin)} />
                      <div className="pl-2">I agree to receive text messages from {props.title}, which may be sent via automated means.</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-divider" />

              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <h3>Optional: Create Cinch Rewards</h3>

                    <p>Optionally create a Cinch Rewards account to keep track of your loyalty rewards.</p>

                    <label>Email <span className="form-error">**</span></label>
                    <input type="email" className="form-control" value={email} onChange={e => updateEmail(e.target.value)} />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label>Password <span className="form-error">**</span></label>
                    <input type="password" className="form-control" value={password} onChange={e => updatePassword(e.target.value)} />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label>Confirm Password <span className="form-error">**</span></label>
                    <input type="password" className="form-control" value={passwordConfirm} onChange={e => updatePasswordConfirm(e.target.value)} />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label>First Name <span className="form-error">**</span></label>
                    <input type="text" className="form-control" value={firstName} onChange={e => updateFirstName(e.target.value)} />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label>Last Name <span className="form-error">**</span></label>
                    <input type="text" className="form-control" value={lastName} onChange={e => updateLastName(e.target.value)} />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="form-group mb-2">
                    <label>Address</label>
                    <input type="text" className="form-control mb-2" value={address1} placeholder="Address Line 1" onChange={e => updateAddress1(e.target.value)} />
                    <input type="text" className="form-control" value={address2} placeholder="Address Line 2" onChange={e => updateAddress2(e.target.value)} />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-5">
                  <div className="form-group">
                    <input type="text" className="form-control" value={city} placeholder="City" onChange={e => updateCity(e.target.value)} />
                  </div>
                </div>
                <div className="col-3">
                  <div className="form-group">
                    <input type="text" className="form-control" value={state} placeholder="St" onChange={e => updateState(e.target.value)} />
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group">
                    <input type="text" className="form-control" value={zip} placeholder="Zip **" onChange={e => updateZip(e.target.value)} />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label>Birthday</label>

                    <div className="row">
                      <div className="col-5">
                        <select type="text" className="form-control" value={birthmonth} onChange={e => updateBirthmonth(e.target.value)}>
                          <option value=''></option>
                          <option value='01'>January</option>
                          <option value='02'>February</option>
                          <option value='03'>March</option>
                          <option value='04'>April</option>
                          <option value='05'>May</option>
                          <option value='06'>June</option>
                          <option value='07'>July</option>
                          <option value='08'>August</option>
                          <option value='09'>September</option>
                          <option value='10'>October</option>
                          <option value='11'>November</option>
                          <option value='12'>December</option>
                        </select>
                      </div>
                      <div className="col-3">
                        <select type="text" className="form-control" value={birthday} onChange={e => updateBirthday(e.target.value)}>
                          <option value=''></option>
                          <option value='01'>1</option>
                          <option value='02'>2</option>
                          <option value='03'>3</option>
                          <option value='04'>4</option>
                          <option value='05'>5</option>
                          <option value='06'>6</option>
                          <option value='07'>7</option>
                          <option value='08'>8</option>
                          <option value='09'>9</option>
                          <option value='10'>10</option>
                          <option value='11'>11</option>
                          <option value='12'>12</option>
                          <option value='13'>13</option>
                          <option value='14'>14</option>
                          <option value='15'>15</option>
                          <option value='16'>16</option>
                          <option value='17'>17</option>
                          <option value='18'>18</option>
                          <option value='19'>19</option>
                          <option value='20'>20</option>
                          <option value='21'>21</option>
                          <option value='22'>22</option>
                          <option value='23'>23</option>
                          <option value='24'>24</option>
                          <option value='25'>25</option>
                          <option value='26'>26</option>
                          <option value='27'>27</option>
                          <option value='28'>28</option>
                          <option value='29'>29</option>
                          <option value='30'>30</option>
                          <option value='30'>31</option>
                        </select>
                      </div>
                      <div className="col-4">
                        <select type="text" className="form-control" value={birthyear} onChange={e => updateBirthyear(e.target.value)}>
                          <option value=''></option>
                          {[...Array(100).keys()].map(i => <option key={i} value={i + 1920}>{i + 1920}</option>)}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label>Gender</label>
                    <select type="text" className="form-control" value={gender} onChange={e => updateGender(e.target.value)}>
                      <option value=''></option>
                      <option value='male'>Male</option>
                      <option value='female'>Female</option>
                      <option value='other'>Other</option>
                    </select>
                  </div>
                </div>
              </div>

              {errorMessage && <div className="form-error">
                  {errorMessage}
                </div>}
            </div>

            <div className="row required-text">
              <div className="col-12">
                <div className="form-group">
                  <p>* Required for signing up to receive SMS messages</p>
                  <p>** Required for creating a Cinch Rewards account.</p>
                </div>
              </div>
            </div>

            <div className="text-center">
              <Button actionType="primary" large style={{
          paddingLeft: '3rem',
          paddingRight: '3rem'
        }} onClick={submit}>
                Submit
              </Button>
            </div>
          </>}
    </div>;
}