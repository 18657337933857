import React, { Component } from 'react';
import { bool, func, oneOf, string } from 'prop-types';
import { Scoped } from 'kremling';
import styles from './toggle.component.scss';
const Toggle = props => {
  return <Scoped css={styles}>
            <label className={"switch size--" + props.size + ' ' + props.className}>
                <input type="checkbox" disabled={props.disabled} value={true} checked={props.checked} onChange={e => props.onToggle(e.target.checked)} />
                <span className="slider round"></span>
            </label>
        </Scoped>;
};
Toggle.propTypes = {
  onToggle: func,
  checked: bool,
  size: oneOf(['sm', 'lg']),
  className: string,
  disabled: bool
};
Toggle.defaultProps = {
  checked: false,
  size: 'sm',
  className: ''
};
export { Toggle };