import React, { Component } from 'react';
import { Scoped } from 'kremling';
import styles from './integrations.styles.scss';
import { UserStateContext } from 'context/user-state-context';
import { Button } from '../../components/button/button.component';
import { createCredentials as createCredentialsAPI, updateCredential as updateCredentialsAPI, deleteCredential as deleteCredentialsAPI } from './integrations.resource';
import { toasterService } from '../../components/toaster/toaster-service';
import { ModalDialog } from '../../components/modal/modal-dialog.component';
const INTEGRATION_KEY = 'aircall';
export class IntegrationAircall extends Component {
  static contextType = UserStateContext;
  static propTypes = {};
  state = {
    deleteCredential: {},
    updateCredential: {},
    isCredentialModalOpen: false,
    ac_id: null,
    ac_token: null
  };
  addCredential = (ac_id, ac_token) => {
    return createCredentialsAPI({
      identifier: ac_id,
      secret1: ac_token,
      company: this.context.asCompany.id,
      integration: INTEGRATION_KEY
    }).then(() => toasterService.success('Successfully added franchise.')).then(() => this.props.reload()).catch(err => {
      if (err.response.data.non_field_errors) {
        toasterService.error(err.response.data.non_field_errors[0]);
      } else {
        toasterService.error('Unknown error trying to add credentials. Please try again.');
      }
    });
  };
  updateCredential = (id, ac_id, ac_token) => {
    return updateCredentialsAPI(id, {
      identifier: ac_id,
      secret1: ac_token
    }).then(() => toasterService.success('Successfully updated credentials.')).then(() => this.props.reload()).catch(err => {
      if (err.response.data.site_id) {
        toasterService.error(err.response.data.site_id[0]);
      } else {
        toasterService.error('Unknown error trying to update franchise. Please try again.');
      }
    });
  };
  removeCredentials = credentialsId => {
    return deleteCredentialsAPI(credentialsId).then(() => toasterService.success('Successfully deleted credentials')).then(() => this.props.reload()).catch(() => toasterService.error('Unknown error to trying delete credentials. Please try again.'));
  };
  render() {
    const {
      isCredentialModalOpen,
      ac_id,
      ac_token,
      deleteCredential,
      updateCredential
    } = this.state;
    const {
      credentials
    } = this.props;
    return <Scoped css={styles}>
        <div className="integration mb-5">
          <div className="integration__header">
            <div>Aircall</div>
            {this.context.hasPermission('integration.add_credential') && <Button className="btn" actionType="primary" tag="a" onClick={() => this.setState({
            isCredentialModalOpen: true
          })}>
                Add Credentials
              </Button>}
          </div>
          <div className="integration__body">
            {credentials.map(credential => <div className="integration-item" key={credential.id}>
                <div className="integration-item__inner">
                  <div className="integration-title">
                    {credential.identifier}
                  </div>
                  <div className="integration-actions">
                    {this.context.hasPermission('integration.change_credential') && <Button actionType="flat" icon="fa-regular-edit" onClick={e => {
                  e.stopPropagation();
                  this.setState({
                    updateCredential: credential
                  });
                }} />}

                    {this.context.hasPermission('integration.delete_credential') && <Button actionType="flat" icon="fa-regular-trash" onClick={e => {
                  e.stopPropagation();
                  this.setState({
                    deleteCredential: credential
                  });
                }} />}
                  </div>
                </div>
              </div>)}
          </div>

          <ModalDialog open={!!deleteCredential.id} title="Remove Credential" submitText="Remove" onSubmit={() => this.removeCredentials(deleteCredential.id)} onClose={() => this.setState({
          deleteCredential: {}
        })} allowBackdropClick>
            <p>
              <strong>{deleteCredential.ac_id}</strong>
            </p>
            <p>Are you sure you want to remove this credential?</p>
          </ModalDialog>

          <ModalDialog open={isCredentialModalOpen} title="Add Credential" onSubmit={() => this.addCredential(ac_id, ac_token)} onClose={() => this.setState({
          isCredentialModalOpen: false
        })} allowBackdropClick>
            <p>
              Please enter your Agemni information below to link to your
              account.
            </p>
            <p>
              <label>ID</label>
              <input required className="form-control" name="ac_id" onChange={e => this.setState({
              ac_id: e.target.value
            })} />
            </p>
            <p>
              <label>Token</label>
              <input required className="form-control" name="ac_token" type="password" onChange={e => this.setState({
              ac_token: e.target.value
            })} />
            </p>
          </ModalDialog>

          <ModalDialog open={!!updateCredential.id} title="Update Credential" submitText="Update" onSubmit={() => this.updateCredential(updateCredential.id, ac_id, ac_token)} onClose={() => this.setState({
          updateCredential: {}
        })} allowBackdropClick>
            <p>Please update your Agemni information below.</p>
            <p>
              <label>ID</label>
              <input required className="form-control" name="ac_id" defaultValue={updateCredential.identifier || ''} onChange={e => this.setState({
              ac_id: e.target.value
            })} />
            </p>
            <p>
              <label>Token</label>
              <input required className="form-control" name="ac_token" type="password" onChange={e => this.setState({
              ac_token: e.target.value
            })} />
            </p>
          </ModalDialog>
        </div>
      </Scoped>;
  }
}