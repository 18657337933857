import { Container, Graphics } from 'pixi.js';
export class NodeConnect {
  constructor(parentNode, trigger) {
    this.parentNode = parentNode;
    this.trigger = trigger;
    this.container = this.build();
    this.container.interactive = true;
    this.container.alpha = 0;
    this.container.on('pointerdown', this._onDragStart).on('pointerup', this._onDragEnd).on('pointerupoutside', this._onDragEnd).on('pointermove', this._onDragMove);
  }
  build = () => {
    const container = new Container();
    const size = 40;
    const radius = size / 2;
    this.connect = new Graphics();
    this.connect.lineStyle(2, 0xffffff);
    this.connect.beginFill(0xc3c3c3);
    this.connect.drawCircle(radius, radius, radius);
    this.connect.endFill();
    const shape = new Graphics();
    shape.beginFill(0xffffff);
    shape.drawPolygon([0, 18, 16, 18, 16, 14, 30, 20, 16, 26, 16, 22, 0, 22]);
    shape.endFill();
    container.addChild(this.connect);
    container.addChild(shape);
    return container;
  };
  willDestroy = () => {
    this.container.destroy();
  };
  _onDragStart = event => {
    if (this.container.alpha === 1) {
      event.stopped = true;
      this.data = event.data;
      this.container.dragging = true;
      const parentPosition = this.container.parent.position;
      this.trigger('START_POINT_LINK', this.parentNode, parentPosition._x + 62, parentPosition._y + 62);
    }
  };
  _onDragEnd = event => {
    if (this.container.dragging) {
      this.container.dragging = false;
      this.data = null;
      const parentPosition = this.container.parent.position;
      this.trigger('END_POINT_LINK', this.parentNode, parentPosition._x + 62, parentPosition._y + 62, event.target);
    }
  };
  _onDragMove = () => {
    if (this.container.dragging) {
      this.trigger('UPDATE_POINT_LINK', this.parentNode, this.data);
    }
  };
}