import React from 'react';
function Icon(props) {
  return <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" fill="none" viewBox="0 0 21 20">
      <g>
        <mask id="mask0_138_38155" style={{
        maskType: 'alpha'
      }} width="21" height="20" x="0" y="0" maskUnits="userSpaceOnUse">
          <path fill="#D9D9D9" d="M0.5 0H20.5V20H0.5z"></path>
        </mask>
        <g fill="#3898D9" mask="url(#mask0_138_38155)">
          <path d="M7 15c-.412 0-.766-.147-1.06-.44a1.445 1.445 0 01-.44-1.06v-10c0-.413.147-.766.44-1.06C6.235 2.148 6.588 2 7 2h10c.413 0 .766.147 1.06.44.293.294.44.647.44 1.06v10c0 .412-.147.766-.44 1.06-.294.293-.647.44-1.06.44H7zm0-1.5h10v-10H7v10zM4 18c-.413 0-.766-.147-1.06-.44a1.445 1.445 0 01-.44-1.06V5.75c0-.213.071-.39.214-.534A.72.72 0 013.246 5c.21 0 .39.072.535.216A.72.72 0 014 5.75V16.5h10.75c.213 0 .39.072.534.214a.72.72 0 01.216.532c0 .21-.072.39-.216.535a.72.72 0 01-.534.219H4z"></path>
          <g>
            <path fill={props.fill || '#3898D9'} d="M10.031 9.036a.736.736 0 01-.535.214.714.714 0 01-.532-.218.736.736 0 01-.214-.536c0-.211.073-.388.219-.531a.736.736 0 01.535-.215c.212 0 .389.073.532.22a.736.736 0 01.214.535.714.714 0 01-.219.531zM12.531 9.036a.736.736 0 01-.535.214.714.714 0 01-.532-.218.736.736 0 01-.214-.536c0-.211.073-.388.219-.531a.736.736 0 01.535-.215c.212 0 .389.073.532.22a.736.736 0 01.214.535.714.714 0 01-.219.531zM15.031 9.036a.736.736 0 01-.535.214.714.714 0 01-.532-.218.736.736 0 01-.214-.536c0-.211.073-.388.219-.531a.736.736 0 01.535-.215c.212 0 .389.073.532.22a.736.736 0 01.214.535.714.714 0 01-.219.531z"></path>
          </g>
        </g>
      </g>
    </svg>;
}
export default Icon;