import jsonLogic from 'json-logic-js';
import { isEmpty, isArray, isObject } from 'lodash';
export const isVisible = (param, data) => {
  if (!param.visible) {
    return true;
  }
  return jsonLogic.apply(param.visible, data);
};
export const checkBetterFalsy = value => {
  return value === undefined || value === null || value === '' || value === false || value === 0 || isArray(value) && value.length === 0 || isObject(value) && isEmpty(value);
};
export const isValid = (param, data) => {
  if (!isVisible(param, data)) {
    return true;
  }
  if (param.required) {
    return !checkBetterFalsy(data[param.key]);
  }
  if (!param.validation) {
    return true;
  }

  // We're using negatives here so it will stop searching after the first error
  return !param.validation.some(rule => !jsonLogic.apply(rule.logic, data));
};
export const errorMessage = (param, data) => {
  if (!isVisible(param, data)) {
    return '';
  }
  if (param.required) {
    return !checkBetterFalsy(data[param.key]) ? '' : 'This field is required';
  }
  if (!param.validation) {
    return '';
  }
  const error = param.validation.find(rule => !jsonLogic.apply(rule.logic, data));
  return error ? error.message : '';
};