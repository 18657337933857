import React, { Component } from 'react';
import { oneOfType, oneOf, node, string, object } from 'prop-types';
import { Scoped, a } from 'kremling';
import styles from './status.styles.scss';
import { Box, Stack, Tooltip, Typography } from '@mui/material';
import { ArchivedIcon, CancelIcon, DeleteIcon, DraftIcon, PausedIcon, PublishedIcon, StoppedIcon } from 'components/mui';
export const StatusMui = props => {
  const [process, setProcess] = React.useState(null);
  const [processes, setProcesses] = React.useState([]);
  React.useEffect(() => {
    setProcesses([{
      key: 'draft',
      name: 'Draft',
      color: '#107EBE',
      icon: <DraftIcon fill={props.hideBackground ? null : '#fff'} />
    }, {
      key: 'deleted',
      name: 'Deleted',
      color: '#EF3C34',
      icon: <DeleteIcon fill={props.hideBackground ? null : '#fff'} />
    }, {
      key: 'paused',
      name: 'Paused',
      color: '#FFB82B',
      icon: <PausedIcon fill={props.hideBackground ? null : '#fff'} />
    }, {
      key: 'published',
      name: 'Published',
      color: '#3EB87B',
      icon: <PublishedIcon fill={props.hideBackground ? null : '#fff'} />
    }, {
      key: 'closed',
      name: 'Closed',
      color: '#616161',
      icon: <CancelIcon fill={props.hideBackground ? null : '#fff'} />
    }, {
      key: 'stopped',
      name: 'Stopped',
      color: '#EF3C34',
      icon: <StoppedIcon fill={props.hideBackground ? null : '#fff'} />
    }, {
      key: 'archived',
      name: 'Archived',
      color: '#1D252D',
      icon: <ArchivedIcon fill="#fff" />
    }]);
  }, []);
  React.useEffect(() => {
    const pro = processes.find(p => p.key === props.status);
    if (pro) {
      setProcess(pro);
    } else {
      setProcess(processes.find(p => p.key === 'published'));
    }
  }, [processes, props]);
  return <Tooltip title={props.tooltip}>
      <Stack spacing="8px" direction="row" sx={{
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'nowrap',
      justifyContent: props.justifyContent | 'center',
      height: '32px',
      //width: '106px',
      px: props.disablePadding ? '0px' : '8px',
      backgroundColor: props.hideBackground ? 'inherit' : process?.color,
      borderRadius: '27px'
    }}>
        <>{process?.icon}</>
        <Typography sx={{
        color: props.hideBackground ? process?.color : '#FFFFFF'
      }}>{process?.name} </Typography>
      </Stack>
    </Tooltip>;
};