import React, { useState } from 'react';
import { useCss } from 'kremling';
import { api } from '../shared/api';
import styles from './ifa2019.styles.scss';
import { Button } from '../components/button/button.component';
const formNodeId = `6764a1fc-dcb2-499e-8243-b504186d6a9d`;
export function Ifa2019() {
  const scope = useCss(styles);
  const [firstName, updateFirstName] = useState('');
  const [lastName, updateLastName] = useState('');
  const [email, updateEmail] = useState('');
  const [phone, updatePhone] = useState('');
  const [title, updateTitle] = useState('');
  const [companyName, updateCompanyName] = useState('');
  const [address1, updateAddress1] = useState('');
  const [address2, updateAddress2] = useState('');
  const [city, updateCity] = useState('');
  const [state, updateState] = useState('');
  const [zip, updateZip] = useState('');
  const [country, updateCountry] = useState('');
  const [spokeWith, updateSpokeWith] = useState('');
  const [checkError, updateCheckError] = useState(false);
  const [formSubmitted, updateFormSubmitted] = useState(false);
  const [submitError, updateSubmitError] = useState(false);
  const [loading, updateLoading] = useState(false);
  function submit() {
    if (firstName && lastName && email && title && companyName && phone && spokeWith) {
      updateCheckError(false);
      updateFormSubmitted(true);
      updateLoading(true);
      api.post(`/form/${formNodeId}`, {
        first_name: firstName,
        last_name: lastName,
        email,
        address1,
        address2,
        city,
        state,
        zip_code: zip,
        country,
        entity_data: {
          spoke_with: spokeWith,
          company_name: companyName,
          title: title
        }
      }).then(() => updateLoading(false)).catch(() => {
        updateLoading(false);
        updateSubmitError(true);
      });
    } else {
      updateCheckError(true);
    }
  }
  function cancel() {
    updateFirstName('');
    updateLastName('');
    updateEmail('');
    updatePhone('');
    updateTitle('');
    updateCompanyName('');
    updateAddress1('');
    updateAddress2('');
    updateCity('');
    updateState('');
    updateZip('');
    updateCountry('');
    updateSpokeWith('');
    updateCheckError(false);
    updateFormSubmitted(false);
    updateSubmitError(false);
    updateLoading(false);
  }
  if (loading) return null;
  return <div {...scope} className="ifa2019">

      {formSubmitted ? <div className="form-wrapper">
            {submitError ? <>
                <h2>Uh Oh...</h2>
                <div className="mb-md">Looks like there was an error submitting the form...</div>
                <div className="text-right">
                  <Button onClick={cancel} className="mr-sm">Reset</Button>
                  <Button onClick={submit} actionType="primary">Try again</Button>
                </div>
              </> : <>
                <h2>Thank you!</h2>
                <div>Form Submitted</div>
              </>}
          </div> : <>
            <div className="form-wrapper mb-lg">
              <h2 className="text-center mb-md">IFA 2019</h2>

              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label>First Name <span className="form-error">*</span></label>
                    <input type="text" className="form-control" value={firstName} onChange={e => updateFirstName(e.target.value)} />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label>Last Name <span className="form-error">*</span></label>
                    <input type="text" className="form-control" value={lastName} onChange={e => updateLastName(e.target.value)} />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label>Email <span className="form-error">*</span></label>
                    <input type="email" className="form-control" value={email} onChange={e => updateEmail(e.target.value)} />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label>Phone <span className="form-error">*</span></label>
                    <input type="phone" className="form-control" value={phone} onChange={e => updatePhone(e.target.value)} />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label>Title <span className="form-error">*</span></label>
                    <input type="text" className="form-control" value={title} onChange={e => updateTitle(e.target.value)} />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label>Company Name <span className="form-error">*</span></label>
                    <input type="text" className="form-control" value={companyName} onChange={e => updateCompanyName(e.target.value)} />
                  </div>
                </div>
              </div>

              <div className="form-divider" />
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label>Address 1</label>
                    <input type="text" className="form-control" value={address1} onChange={e => updateAddress1(e.target.value)} />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label>Address 2</label>
                    <input type="text" className="form-control" value={address2} onChange={e => updateAddress2(e.target.value)} />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label>City</label>
                    <input type="text" className="form-control" value={city} onChange={e => updateCity(e.target.value)} />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label>State</label>
                    <input type="text" className="form-control" value={state} onChange={e => updateState(e.target.value)} />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label>Zip</label>
                    <input type="text" className="form-control" value={zip} onChange={e => updateZip(e.target.value)} />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label>Country</label>
                    <input type="text" className="form-control" value={country} onChange={e => updateCountry(e.target.value)} />
                  </div>
                </div>
              </div>

              <div className="form-divider" />
              <div className="form-group">
                <label>Who did you speak with? <span className="form-error">*</span></label>
                <select className="form-control" value={spokeWith} onChange={e => updateSpokeWith(e.target.value)}>
                  <option value="" disabled></option>
                  {['Justin', 'Charlie', 'Bill', 'Chadly'].map(name => <option key={name} value={name}>{name}</option>)}
                </select>
              </div>
              {checkError && <div className="form-error">
                  Please fill in the required fields before submitting.
                </div>}
            </div>
            <div className="text-center">
              <Button actionType="primary" large style={{
          paddingLeft: '3rem',
          paddingRight: '3rem'
        }} onClick={submit}>
                Submit
              </Button>
            </div>
          </>}
    </div>;
}