import React, { useState, useContext } from 'react';
import { UserStateContext } from 'context/user-state-context';
import { bool, func, object, shape, string, any } from 'prop-types';
import { Button } from 'components/button/button.component';
import { Loader } from 'components/loader/loader.component';
import { toasterService } from 'components/toaster/toaster-service';
import { testWebhookSQL } from '../../../../shared/common.api';
const ActionWebhook = ({
  update,
  node,
  data
}) => {
  const {
    user
  } = useContext(UserStateContext);
  const {
    state,
    uri,
    query,
    single,
    query_keys = []
  } = data;
  const [loading, setLoading] = useState(false);
  const onValChange = (key, val) => {
    update({
      [key]: val
    });
  };
  const verifySql = () => {
    setLoading(true);
    testWebhookSQL(query).then(({
      data
    }) => {
      onValChange('query_keys', data.query_keys);
      toasterService.success("Query validated successfully");
    }).catch(e => {
      toasterService.error(e.response.data.sql);
    }).then(() => {
      setLoading(false);
    });
  };
  return <div>
      {user.is_superuser && <div className="form-group">
          <label>SQL Query</label>
          <textarea style={{
        height: "100px"
      }} className="form-control" onChange={e => onValChange('query', e.target.value)} value={query || ''} />
          <div className="d-flex">
            <select required className="form-control mr-5" onChange={e => onValChange('query', `${query || ''}%(${e.target.value})s`)} value="">
              <option value="" disabled={true}>Insert Variable Data</option>
              <option value="customer_ids">Customer IDs</option>
              <option value="customer_node_ids">Customer Node IDs</option>
              <option value="transaction_ids">Transaction IDs</option>
              <option value="possession_ids">Possession IDs</option>
              <option value="schedule_ids">Schedule IDs</option>
              <option value="cart_ids">Cart IDs</option>
              <option value="session_ids">Session IDs</option>
              <option value="company_id">Company ID</option>
            </select>
            <Button actionType="primary" onClick={() => verifySql()} disabled={loading}>
              Validate Query
              {loading && <Loader className="ml-3" size="sm" />}
            </Button>
          </div>

          <p className="mt-3">
            The values returned by this SQL query will be used in the paylod of the <code>POST</code> HTTP request.
          </p>
        </div>}
      <div className="form-group">
        <label>URI</label>
        <input className="form-control" onChange={e => onValChange('uri', e.target.value)} value={uri || ''} />
        <p className="mt-3">
          When a customer enters this node a <code>POST</code> HTTP request will be made to the specified URI.
        </p>
      </div>
      <div className="form-group">
        <label>State</label>
        <input className="form-control" onChange={e => onValChange('state', e.target.value)} value={state || ''} />
        <p className="mt-3">
          Arbitrary value you can add at your leisure.
        </p>
      </div>

      <div className="form-group">
        <label>Send single record</label> <br />
        <input type="checkbox" checked={single} onChange={e => onValChange('single', !single)} />
        <p className="mt-3">
          Should the values be past as a single call with an array payload or as multiple calls with a single object payload?
        </p>
      </div>
      <div className="form-group">
        <label>Sample</label>
        <p className="mt-3">
          {!!single ? <>
              The <code> POST</code> payload will be a single object that entered the node. This may lead to many calls:
            </> : <>
              The <code> POST</code> payload will be an array of objects that entered the node. Multiple customers may enter a node at the same time:
            </>}
        </p>
        <pre style={{
        maxHeight: '150px'
      }}>
          {!!single ? '{' : '[{'}<br />
          {query && query !== '' ? query_keys.map((key, i) => <>
              &nbsp;&nbsp;&nbsp;"{key}": "... value ...",<br />
            </>) : <>
              &nbsp;&nbsp;&nbsp;"customer_id": "12345678-1234-5678-1234-567812345678",<br />
              &nbsp;&nbsp;&nbsp;"customer_node_id": "12345678-1234-5678-1234-567812345678",<br />
              &nbsp;&nbsp;&nbsp;"node_id": "12345678-1234-5678-1234-567812345678",<br />
              &nbsp;&nbsp;&nbsp;"state": "(This will be populated from the state field above)"<br />
            </>}
          {!!single ? '}' : '}]'}
        </pre>
      </div>
    </div>;
};
ActionWebhook.propTypes = {
  update: func,
  node: object,
  data: shape({
    uri: string,
    state: string,
    query: string,
    single: bool,
    query_keys: any
  })
};
export default ActionWebhook;