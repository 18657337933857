import React, { Component } from 'react';
import { Scoped } from 'kremling';
import styles from './tags.styles.scss';
import { Icon } from '../icon/icon.component';
import classnames from 'classnames';
export class Tags extends Component {
  render() {
    const {
      tags,
      styleType,
      onDelete
    } = this.props;
    return <Scoped css={styles}>
        {tags !== null && tags.map((tag, index) => <div onClick={e => onDelete === null && this.props.onClick(e, tag)} key={index} className={classnames('ml-2', {
        'journey-tag-custom': this.props.styleType === 'custom',
        'journey-tag--tag-custom': this.props.styleType === 'custom',
        'journey-tag': this.props.styleType !== 'custom',
        'journey-tag--tag': this.props.styleType !== 'custom',
        'hoverable': !!this.props.onClick
      })}>
              {tag}
              {styleType === 'custom' && <span key={index} onClick={() => onDelete(index)}>
                  <Icon size={14} name="fa-regular-times" />
                </span>}
            </div>)}
      </Scoped>;
  }
}