// iframe.js

import React, { useState, useEffect } from 'react';
export const IFrame = ({
  content,
  ...props
}) => {
  const [contentRef, setContentRef] = useState(null);
  useEffect(() => {
    if (!contentRef || !content) return;
    const doc = contentRef.contentWindow.document;
    doc.open();
    doc.write(content);
    doc.close();
  }, [contentRef, content]);
  return <iframe {...props} ref={setContentRef} />;
};