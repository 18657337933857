import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, InputLabel, Select, MenuItem, FormControl, Stack } from '@mui/material';
import { Loader } from 'components';
import { generateAiSegment } from 'shared/common.api';
import { CustomTextField } from './custom-text-field.component';
export const EditSegmentAI = ({
  segment,
  updateSegment,
  setIsValid,
  company
}) => {
  const [prompt, setPrompt] = useState('');
  const [isGeneratingAI, setIsGeneratingAI] = useState(false);
  const [explanation, setExplanation] = useState('');
  const [filters, setFilters] = useState('');
  useEffect(() => {
    setIsValid(!!segment?.segment_query && segment?.segment_query !== '');
  }, [segment?.segment_query]);
  const handlePromptSubmit = () => {
    if (prompt === '') {
      return;
    }
    setIsGeneratingAI(true);
    generateAiSegment({
      prompt,
      included: segment.included,
      company
    }).then(({
      data
    }) => {
      setExplanation(data.explanation);
      updateSegment('segment_query', data.query);
      setFilters(<ul>
        {data.filters.map((filter, index) => <li key={index}>{filter}</li>)}
      </ul>);
    }).catch(() => {}).then(() => {
      setIsGeneratingAI(false);
    });
  };
  return <Stack spacing="30px">
      <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{
      width: '100%'
    }}>
        <Typography sx={{
        fontFamily: 'Nexa',
        fontSize: 20,
        color: '#1D252D'
      }}>AI Assistant</Typography>
      </Box>

      <Box>
        <CustomTextField onChange={e => setPrompt(e.target.value)} label="Prompt" name="prompt" sx={{
        width: '100%'
      }} rows={1} multiline value={prompt} InputLabelProps={{
        style: {
          color: '#BEBEBE'
        }
      }} />
      </Box>

      <Stack spacing="30px" direction="row">
        <Box>
          <FormControl sx={{
          width: '25%',
          minWidth: '300px'
        }}>
            <InputLabel id="target_database-label">Target Database</InputLabel>
            <Select labelId="target_database-label" id="target_database" value={segment?.target_database || 'postgres'} label="target_database____" onChange={e => {
            updateSegment('target_database', e.target.value);
          }}>
              <MenuItem value="postgres">Postgres</MenuItem>
              <MenuItem value="snowflake">Snowflake</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Button sx={{
        backgroundColor: '#53A6D6',
        opacity: isGeneratingAI || prompt === '' ? '50%' : '100%',
        color: '#FFFFFF',
        cursor: isGeneratingAI || prompt === '' ? 'default' : 'cursor',
        borderRadius: '27px',
        textTransform: 'none',
        border: '1px solid #53A6D6',
        height: '32px',
        width: '112px',
        '&:hover': {
          backgroundColor: '#53A6D6',
          color: '#fff'
        }
      }} onClick={handlePromptSubmit} variant="outlined">
          Submit {isGeneratingAI && <Loader size="sm" className="ml-2" />}
        </Button>
      </Stack>

      {explanation !== '' && <>
        <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{
        width: '100%'
      }}>
          <Typography sx={{
          fontFamily: 'Nexa',
          fontSize: 20,
          color: '#1D252D'
        }}>Explanation</Typography>
        </Box>

        <Typography sx={{
        fontFamily: 'Nexa',
        fontSize: 14,
        color: '#1D252D80'
      }}>
          {explanation}{' '}
        </Typography>

        <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{
        width: '100%'
      }}>
          <Typography sx={{
          fontFamily: 'Nexa',
          fontSize: 20,
          color: '#1D252D'
        }}>Filters</Typography>
        </Box>

        {filters}

        <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{
        width: '100%'
      }}>
          <Typography sx={{
          fontFamily: 'Nexa',
          fontSize: 20,
          color: '#1D252D'
        }}>Query</Typography>
        </Box>

        <pre style={{
        padding: '10px',
        whiteSpace: 'pre-wrap'
      }}>{segment?.segment_query}</pre>
      </>}
    </Stack>;
};