import React, { useContext, useState, useEffect } from 'react';
import { UserStateContext } from 'context/user-state-context';
import { Loader, PageHeaderMui } from 'components';
import { Box, Button, IconButton, Paper, Stack, Tooltip, Typography, Divider } from '@mui/material';
import { HandymanIcon, OpenInNewWindowIcon } from 'components/mui';
import { history } from '../../../root.component';
import { snackbarService } from 'components/mui/snackbar/snackbar-service';
import validator from 'validator';
import { DateRangeFilter } from 'components/mui/filter/date-range.component';
import { MultiSelectFilter } from 'components/mui/filter/multi-select.component';
import { getPrebuiltSegment, getPrebuiltSegmentInstalls, getPrebuiltSegmentVersions } from 'shared/common.api';
import moment from 'moment';
import EnhancedTable from '../../../components/mui/enhanced-table/enhanced-table.component';
import CssBaseline from '@mui/material/CssBaseline';
import { getFiltersFromURL } from 'components/mui/enhanced-table/utils';
export const PrebuiltAdminSegmentsUsageStats = props => {
  const {
    user,
    asCompany,
    hasPermission
  } = useContext(UserStateContext);
  const [isLoading, setIsLoading] = useState(true);
  const [versions, setVersions] = useState([]);
  const [renderIteration, setRenderIteration] = useState(0);
  const [prebuiltSegment, setPrebuiltSegment] = useState(null);
  useEffect(() => {
    if (!user || !asCompany?.id) {
      return;
    }
    setIsLoading(true);
    if (!validator.isUUID(props.match.params.id)) {
      snackbarService.popup({
        type: 'error',
        message: 'No Id provided.'
      });
      history.push('/prebuilt/admin');
      return;
    }
    const promises = [getPrebuiltSegmentVersions({
      prebuilt: props.match.params.id
    }), getPrebuiltSegment(props.match.params.id)];
    Promise.all(promises).then(([versionsResults, prebuiltSegment]) => {
      console.log(prebuiltSegment);
      setVersions(versionsResults.data.results);
      setPrebuiltSegment(prebuiltSegment.data);
      setIsLoading(false);
    });
  }, [hasPermission, asCompany?.id]);
  const filterOptions = [{
    component: MultiSelectFilter,
    label: 'Version',
    pluralLabel: 'Versions',
    key: 'version',
    options: versions?.map(i => ({
      label: i.version,
      value: i.id
    }))
  }, {
    component: DateRangeFilter,
    label: 'Installed Date',
    key: 'created'
  }];
  const [filters, setFilters] = React.useState(() => getFiltersFromURL(filterOptions));
  const headCells = [{
    key: 'company__name',
    align: 'left',
    disablePadding: true,
    label: 'Name',
    component: 'th',
    scope: 'row',
    padding: 'none',
    lableId: `enhanced-table-checkbox-0`,
    width: '40%',
    displayComponent: ({
      data
    }) => <Stack direction="row" spacing={1}>
          <Typography sx={{
        display: 'flex',
        alignItems: 'center'
      }}> {data.company_name}</Typography>
        </Stack>
  }, {
    key: 'prebuilt_version__version',
    align: 'left',
    disablePadding: true,
    label: 'Version',
    type: 'string',
    width: '30%',
    displayComponent: ({
      data
    }) => <>{data.version}</>
  }, {
    key: 'created_when',
    align: 'left',
    disablePadding: true,
    label: 'Installed Date',
    width: '25%',
    displayComponent: ({
      data,
      index,
      onHover
    }) => <>{moment(data.created_when).format('MMM D, YYYY')}</>
  }];
  const handleRowClick = row => {
    //not implemented, we'd have to change companies for this, which is possible, but never done yet
    snackbarService.popup({
      type: 'error',
      message: 'Not implemented, would need to automatically change companies'
    });
  };
  const PrebuiltSegmentsInstallsApiWrapper = () => {
    return params => {
      delete params.company;
      return getPrebuiltSegmentInstalls({
        prebuilt_parent: props.match.params.id,
        ...params
      });
    };
  };
  const handleNamePrefixAction = () => {
    history.push({
      pathname: window?.location?.pathname + '/edit'
    });
  };
  const handleGoToSegment = data => {
    history.push(`/segments/${data.segment}`);
  };
  return <>
      <CssBaseline />
      {isLoading && <Loader overlay />}
      <>
        <div className="wrapper">
          <PageHeaderMui type={'Prebuilt Admin - Segments'} typeHistoryOverride={'/prebuilt/admin/segments'} namePrefix={prebuiltSegment?.active_version?.title || ''} namePrefixAction={e => {
          handleNamePrefixAction();
        }} name="Usage Stats" icon={<HandymanIcon />} />

          <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}>
            <Stack direction="row" spacing="32px">
              <Box sx={{
              px: '20px',
              pt: '20px',
              width: 'fit-content'
            }}>
                <Stack sx={{
                borderRadius: '32px',
                backgroundColor: '#fff',
                p: '16px'
              }} spacing="8px" direction={'row'}>
                  <Box sx={{
                  height: '32px',
                  display: 'flex',
                  alignItems: 'center'
                }}> Status: Released</Box>
                  <Divider orientation="vertical" flexItem />
                  <Box sx={{
                  height: '32px',
                  display: 'flex',
                  alignItems: 'center'
                }}>
                    Latest Released Version #: {prebuiltSegment?.active_version?.version}
                  </Box>
                  <Divider orientation="vertical" flexItem />
                  <Box sx={{
                  height: '32px',
                  display: 'flex',
                  alignItems: 'center'
                }}>
                    Total Install Count: {prebuiltSegment?.install_count}
                  </Box>
                  <Divider orientation="vertical" flexItem />
                  <Box sx={{
                  height: '32px',
                  display: 'flex',
                  alignItems: 'center'
                }}>
                    {' '}
                    Tags Count: {prebuiltSegment?.tags?.length || 0}{' '}
                  </Box>
                </Stack>
              </Box>
            </Stack>
            <Stack direction="row" spacing="32px">
              <Box sx={{
              px: '20px',
              pt: '20px',
              width: 'fit-content'
            }}>
                <Stack sx={{
                p: '16px'
              }} spacing="8px" direction={'row'}>
                  <Button onClick={e => {
                  history.push(`/prebuilt/admin/segments/${prebuiltSegment?.id}/edit`);
                }} variant="contained">
                    Edit Version Details
                  </Button>
                  <Button onClick={e => {
                  history.push(`/prebuilt/admin/segments/${prebuiltSegment?.id}/add-new-version`);
                }} variant="contained">
                    Make New Version
                  </Button>
                </Stack>
              </Box>
            </Stack>
          </Box>
          <div className="mui-wrapper">
            <EnhancedTable type={'Prebuilt Segment Installs'} headCells={headCells} api={PrebuiltSegmentsInstallsApiWrapper()} defaultSort={'-created_when'} handleRowClick={row => {
            handleRowClick(row);
          }} filterOptions={filterOptions} filtersState={[filters, setFilters]} renderIteration={renderIteration} hoverActions={({
            data
          }) => <Stack direction="row" gap="10px">
                  {hasPermission('customer.change_segment') && <Tooltip title="Go to Segment">
                      <IconButton sx={{
                ':hover': {
                  backgroundColor: '#DAEFFF'
                }
              }} onClick={e => {
                e.stopPropagation();
                handleGoToSegment(data);
              }}>
                        <OpenInNewWindowIcon />
                      </IconButton>
                    </Tooltip>}
                </Stack>} />
          </div>
        </div>
      </>
    </>;
};