import React, { useContext, useState, useEffect } from 'react';
import { UserStateContext } from 'context/user-state-context';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper, Stack, TextField, Tooltip, Typography, Divider, FormControl, Select, MenuItem, InputLabel, Skeleton, Popper, ClickAwayListener, Switch, InputAdornment } from '@mui/material';
import { AddIcon, CloseIcon, DashedCircleFilledIcon, DashedCiricleIcon, DeleteIcon, EditIcon, HelpIcon, RemoveCircleIcon } from 'components/mui';
import { withStyles } from '@mui/styles';
import { ModalFileViewer2 } from 'components/file-viewer/modal-file-viewer.component-mui';
import { ColorPickerRound } from 'components/color-picker/color-picker-round.component';
const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius: `14px`,
      '& fieldset': {}
    }
  }
})(TextField);
const CustomTooltip = withStyles({
  tooltip: {
    backgroundColor: 'white'
  }
})(Tooltip);
export const CompanySettingsInfoCard = props => {
  const {
    hasPermission
  } = useContext(UserStateContext);
  const [companySettingsModal, setCompanySettingsModal] = useState(null);
  const [companySettingsModalFocus, setCompanySettingsModalFocus] = useState(null);
  const [helpAnchorEl, setHelpAnchorEl] = React.useState(null);
  const [helpData, setHelpData] = React.useState(null);
  const [showFileViewer, setShowFileViewer] = useState(false);
  const [aFieldHasChanged, setAFieldHasChanged] = useState(false);
  const handleHelpPopupClick = (e, index) => {
    if (index == 'company_settings') {
      setHelpData({
        title: <Typography variant="tableHeader"> Company Settings</Typography>,
        content: <Stack spacing={'20px'}>
            <Typography component="div">
              <Box fontWeight="700" display="inline" sx={{
              pr: '3px'
            }}>
                Session timeout length:
              </Box>
              Number of minutes of inactivity before a session closes.
            </Typography>
            <Typography component="div">
              <Box fontWeight="700" display="inline" sx={{
              pr: '3px'
            }}>
                Password expiration:
              </Box>
              Maximum number of days a password is valid before it needs to be reset.
            </Typography>
          </Stack>
      });
    }
    setHelpAnchorEl(e.currentTarget);
  };
  const handleHelpClose = () => {
    setHelpAnchorEl(null);
    setHelpData(null);
  };
  const hasInputErrors = () => {
    if (isNaN(companySettingsModal?.session_timeout) || Number(companySettingsModal?.session_timeout) < 0 || isNaN(companySettingsModal?.days_to_reset_password) || Number(companySettingsModal?.days_to_reset_password) < 0) {
      return true;
    }
    return false;
  };
  const hasBackendRejectionErrors = () => {
    if (props?.errors?.session_timeout || props?.errors?.days_to_reset_password || props?.errors?.company_logo || props?.errors?.primary_color || props?.errors?.secondary_color || props?.errors?.require_mfa) {
      return true;
    }
    return false;
  };
  const canEdit = () => {
    return props?.company?.id && hasPermission('organization.change_company');
  };
  return <>
      <Paper sx={{
      width: '65vw',
      maxWidth: '1200px',
      borderRadius: '14px',
      border: hasBackendRejectionErrors() ? '2px solid #EF3C34' : 'none'
    }}>
        <Stack>
          <Stack direction="row" spacing={'10px'}>
            <Box sx={{
            py: '20px',
            pl: '20px',
            display: 'flex',
            alignItems: 'center'
          }}>
              <Typography sx={{
              fontSize: '20px',
              fontWeight: '400',
              lineHeight: '16.8px'
            }}>Company settings</Typography>
            </Box>
            <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
              <IconButton onClick={e => handleHelpPopupClick(e, 'company_settings')}>
                <HelpIcon fill="#3898D9" size="sm" />
              </IconButton>
            </Box>
          </Stack>

          {/* 2fa  */}

          <Box className="cardrow" sx={{
          py: '10px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          '&:hover': {
            backgroundColor: '#F3F3F4',
            cursor: 'pointer'
          }
        }} onClick={e => {
          if (canEdit()) {
            props.updateCompany({
              ...props.company,
              require_mfa: !props.company.require_mfa
            });
          }
        }}>
            <Stack direction="row" spacing="10px" sx={{
            px: '20px'
          }}>
              <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                <Typography>Require two-factor authentication</Typography>
              </Box>
            </Stack>

            {props?.errors?.require_mfa && <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
                <Typography sx={{
              color: '#EF3C34'
            }}>{props?.errors?.require_mfa}</Typography>
              </Box>}

            {props.loading ? <Box sx={{
            px: '20px',
            py: '8px',
            width: '30%'
          }}>
                <Skeleton variant="rectangular" width="100%" height={20} />
              </Box> : <Box sx={{
            pr: '35px'
          }}>
                <Switch disabled={!canEdit()} checked={props?.company?.require_mfa} onChange={e => {
              props.updateCompany({
                ...props.company,
                require_mfa: e.target.checked
              });
            }} />
              </Box>}
          </Box>
          <Box sx={{
          px: '20px'
        }}>
            <Divider />
          </Box>

          {/* Session timeout length */}

          <Box className="cardrow" sx={{
          py: '10px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          '&:hover': {
            backgroundColor: '#F3F3F4',
            cursor: 'pointer'
          }
        }} onClick={e => {
          setCompanySettingsModalFocus('session_timeout');
          setCompanySettingsModal(props.company);
        }}>
            <Stack direction="row" spacing="10px" sx={{
            px: '20px'
          }}>
              <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                <Typography>Session timeout length</Typography>
              </Box>
            </Stack>

            {props?.errors?.session_timeout && <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
                <Typography sx={{
              color: '#EF3C34'
            }}>{props?.errors?.session_timeout}</Typography>
              </Box>}

            {props.loading ? <Box sx={{
            px: '20px',
            py: '8px',
            width: '30%'
          }}>
                <Skeleton variant="rectangular" width="100%" height={20} />
              </Box> : <Stack sx={{
            pr: '6px'
          }} direction={'row'} spacing="4px">
                <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                  {props.company.session_timeout ? <Typography>{props.company.session_timeout} minutes</Typography> : <Typography sx={{
                opacity: 0.5
              }}>None</Typography>}
                </Box>
                <Box className="icons__remove">
                  {props.company.session_timeout ? <Tooltip title={'Edit Session timeout length'}>
                      <IconButton>
                        <EditIcon />
                      </IconButton>
                    </Tooltip> : <Tooltip title={'Add Session timeout length'}>
                      <IconButton>
                        <AddIcon />
                      </IconButton>
                    </Tooltip>}
                </Box>
              </Stack>}
          </Box>
          <Box sx={{
          px: '20px'
        }}>
            <Divider />
          </Box>

          {/* password expiration */}

          <Box className="cardrow" sx={{
          py: '10px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          '&:hover': {
            backgroundColor: '#F3F3F4',
            cursor: 'pointer'
          }
        }} onClick={e => {
          setCompanySettingsModalFocus('days_to_reset_password');
          setCompanySettingsModal(props.company);
        }}>
            <Stack direction="row" spacing="10px" sx={{
            px: '20px'
          }}>
              <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                <Typography>Password expiration</Typography>
              </Box>
            </Stack>

            {props?.errors?.days_to_reset_password && <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
                <Typography sx={{
              color: '#EF3C34'
            }}>{props?.errors?.days_to_reset_password}</Typography>
              </Box>}

            {props.loading ? <Box sx={{
            px: '20px',
            py: '8px',
            width: '30%'
          }}>
                <Skeleton variant="rectangular" width="100%" height={20} />
              </Box> : <Stack sx={{
            pr: '6px'
          }} direction={'row'} spacing="4px">
                <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                  {props.company.days_to_reset_password ? <Typography>{props.company.days_to_reset_password} days</Typography> : <Typography sx={{
                opacity: 0.5
              }}>None</Typography>}
                </Box>
                <Box className="icons__remove">
                  {props.company.days_to_reset_password ? <Tooltip title={'Edit Password expiration'}>
                      <IconButton>
                        <EditIcon />
                      </IconButton>
                    </Tooltip> : <Tooltip title={'Add Password expiration'}>
                      <IconButton>
                        <AddIcon />
                      </IconButton>
                    </Tooltip>}
                </Box>
              </Stack>}
          </Box>
          <Box sx={{
          px: '20px'
        }}>
            <Divider />
          </Box>

          {/* logo */}

          <Box className="cardrow" sx={{
          py: '10px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          '&:hover': {
            backgroundColor: '#F3F3F4',
            cursor: 'pointer'
          }
        }} onClick={e => {
          setCompanySettingsModal(props.company);
        }}>
            <Stack direction="row" spacing="10px" sx={{
            px: '20px'
          }}>
              <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                <Typography>Logo</Typography>
              </Box>
            </Stack>

            {props?.errors?.company_logo && <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
                <Typography sx={{
              color: '#EF3C34'
            }}>{props?.errors?.company_logo}</Typography>
              </Box>}

            {props.loading ? <Box sx={{
            px: '20px',
            py: '8px',
            width: '30%'
          }}>
                <Skeleton variant="rectangular" width="100%" height={20} />
              </Box> : <Stack sx={{
            pr: '6px'
          }} direction={'row'} spacing="4px">
                <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                  {props.company.company_logo ? <img onClick={() => {
                setCompanySettingsModal(props.company);
              }} src={props.company.company_logo} height="36px" /> : <Typography sx={{
                opacity: 0.5
              }}>None</Typography>}
                </Box>
                <Box className="icons__remove">
                  {props.company.company_logo ? <Tooltip title={'Edit logo'}>
                      <IconButton>
                        <EditIcon />
                      </IconButton>
                    </Tooltip> : <Tooltip title={'Add logo'}>
                      <IconButton>
                        <AddIcon />
                      </IconButton>
                    </Tooltip>}
                </Box>
              </Stack>}
          </Box>
          <Box sx={{
          px: '20px'
        }}>
            <Divider />
          </Box>

          {/* colors */}

          <Box className="cardrow" sx={{
          py: '10px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          '&:hover': {
            backgroundColor: '#F3F3F4',
            cursor: 'pointer'
          }
        }} onClick={e => {
          setCompanySettingsModal(props.company);
        }}>
            <Stack direction="row" spacing="10px" sx={{
            px: '20px'
          }}>
              <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                <Typography>Colors</Typography>
              </Box>
            </Stack>

            {(props?.errors?.primary_color || props?.errors?.secondary_color) && <Stack sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
                <Typography sx={{
              color: '#EF3C34'
            }}>Primary Color: {props?.errors?.primary_color}</Typography>
                <Typography sx={{
              color: '#EF3C34'
            }}>Secondary Color: {props?.errors?.secondary_color}</Typography>
              </Stack>}

            {props.loading ? <Box sx={{
            px: '20px',
            py: '8px',
            width: '30%'
          }}>
                <Skeleton variant="rectangular" width="100%" height={20} />
              </Box> : <Stack sx={{
            pr: '6px'
          }} direction={'row'} spacing="4px">
                <Stack direction={'row'} spacing="26px">
                  <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                    <Tooltip title="Primary Color">
                      <IconButton>
                        {props.company.primary_color ? <DashedCircleFilledIcon fill={props.company.primary_color} /> : <DashedCiricleIcon />}
                      </IconButton>
                    </Tooltip>
                  </Box>

                  <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                    <Tooltip title="Secondary Color">
                      <IconButton>
                        {props.company.secondary_color ? <DashedCircleFilledIcon fill={props.company.secondary_color} /> : <DashedCiricleIcon />}
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Stack>
                <Box className="icons__remove">
                  {props.company.primary_color || props.company.secondary_color ? <Box>
                      <Tooltip title={'Edit colors'}>
                        <IconButton>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    </Box> : <Box>
                      <Tooltip title={'Add colors'}>
                        <IconButton>
                          <AddIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>}
                </Box>
              </Stack>}
          </Box>
          <Box sx={{
          px: '20px'
        }}>
            <Divider />
          </Box>
        </Stack>
      </Paper>

      <Dialog scroll="paper" PaperProps={{
      style: {
        borderRadius: '28px',
        maxWidth: '1080px'
      }
    }} open={!!companySettingsModal} onClose={(e, r) => {
      if (r != 'backdropClick') {
        setCompanySettingsModalFocus(null);
        setCompanySettingsModal(null);
      }
    }}>
        <DialogTitle sx={{
        m: 0,
        p: 2,
        fontSize: 'medium'
      }}>
          <Stack spacing={'20px'}>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Stack direction="row" spacing={'10px'}>
                <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                  <Typography sx={{
                  fontWeight: '300',
                  lineHeight: '30px',
                  fontSize: '24px',
                  display: 'flex',
                  alignItems: 'center'
                }} variant="h4" component="span">
                    Company Settings
                  </Typography>
                </Box>
                <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                  <IconButton onClick={e => handleHelpPopupClick(e, 'company_settings')}>
                    <HelpIcon fill="#3898D9" size="sm" />
                  </IconButton>
                </Box>
              </Stack>
              <IconButton onClick={() => {
              setCompanySettingsModalFocus(null);
              setCompanySettingsModal(null);
            }}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Divider />
          </Stack>
        </DialogTitle>

        <DialogContent sx={{
        //overflowY: 'scroll',
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
          width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
          marginTop: '20px',
          marginBottom: '20px'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#888'
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555'
        }
      }}>
          <Stack sx={{
          pt: 1
        }} spacing={'24px'}>
            <Stack spacing={'24px'} direction={'row'}>
              <CustomTextField disabled={!canEdit()} variant="outlined" fullWidth sx={{
              minWidth: '300px'
            }} autoFocus={companySettingsModalFocus === 'session_timeout'} error={isNaN(companySettingsModal?.session_timeout) || Number(companySettingsModal?.session_timeout) < 0} helperText={isNaN(companySettingsModal?.session_timeout) || Number(companySettingsModal?.session_timeout) < 0 ? 'Please enter a non-negative number' : ''} label="Session timeout length" autoComplete="new-password" InputProps={{
              endAdornment: <InputAdornment position="end">
                      <Stack direction="row" spacing="5px">
                        <Box sx={{
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                          <Typography>minutes</Typography>
                        </Box>
                      </Stack>
                    </InputAdornment>
            }} value={companySettingsModal?.session_timeout || ''} onChange={e => {
              setAFieldHasChanged(true);
              setCompanySettingsModal({
                ...companySettingsModal,
                session_timeout: e.target.value
              });
            }} />

              <CustomTextField id="disable-pwd-mgr-1" disabled={!canEdit()} variant="outlined" fullWidth sx={{
              minWidth: '300px'
            }} autoFocus={companySettingsModalFocus === 'days_to_reset_password'} error={isNaN(companySettingsModal?.days_to_reset_password) || Number(companySettingsModal?.days_to_reset_password) < 0} helperText={isNaN(companySettingsModal?.days_to_reset_password) || Number(companySettingsModal?.days_to_reset_password) < 0 ? 'Please enter a non-negative number' : ''} label="Password expiration" autoComplete="new-password" InputProps={{
              endAdornment: <InputAdornment position="end">
                      <Stack direction="row" spacing="5px">
                        <Box sx={{
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                          <Typography>days</Typography>
                        </Box>
                      </Stack>
                    </InputAdornment>
            }} value={companySettingsModal?.days_to_reset_password || ''} onChange={e => {
              setAFieldHasChanged(true);
              setCompanySettingsModal({
                ...companySettingsModal,
                days_to_reset_password: e.target.value
              });
            }} />
            </Stack>
            <Divider />

            <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
              <Stack direction="row" spacing="10px" sx={{
              px: '20px'
            }}>
                <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                  <Typography>Logo</Typography>
                </Box>
              </Stack>

              <Stack sx={{
              pr: '6px'
            }} direction={'row'} spacing="18px">
                <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                  {companySettingsModal?.company_logo && <CustomTooltip title={<img src={companySettingsModal?.company_logo} style={{
                  maxHeight: '25vh',
                  maxWidth: '25vw'
                }} />}>
                      <img src={companySettingsModal?.company_logo} height="46px" />
                    </CustomTooltip>}
                </Box>
                {companySettingsModal?.company_logo && canEdit() && <Box>
                    <Tooltip title={'Remove logo'}>
                      <IconButton onClick={e => {
                    setAFieldHasChanged(true);
                    setCompanySettingsModal({
                      ...companySettingsModal,
                      company_logo: null
                    });
                  }}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>}
                {companySettingsModal?.company_logo && canEdit() ? <Box>
                    <Tooltip title={'Change logo'}>
                      <IconButton onClick={e => {
                    setShowFileViewer(true);
                  }}>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </Box> : <>
                    {canEdit() && <Box>
                        <Tooltip title={'Add logo'}>
                          <IconButton onClick={e => {
                      setShowFileViewer(true);
                    }}>
                            <AddIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>}
                  </>}
              </Stack>
            </Box>
            <Divider />

            <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
              <Stack direction="row" spacing="10px" sx={{
              px: '20px'
            }}>
                <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                  <Typography>Colors</Typography>
                </Box>
              </Stack>

              <Stack sx={{
              pr: '6px'
            }} direction={'row'} spacing="4px">
                <Stack direction={'row'} spacing="26px">
                  <Box sx={{
                  cursor: canEdit() ? 'pointer' : 'none',
                  display: 'flex',
                  alignItems: 'center'
                }}>
                    <ColorPickerRound disabled={!canEdit()} color={companySettingsModal?.primary_color} onChange={color => {
                    setCompanySettingsModal({
                      ...companySettingsModal,
                      primary_color: color
                    });
                    setAFieldHasChanged(true);
                  }} />
                  </Box>

                  <Box sx={{
                  cursor: canEdit() ? 'pointer' : 'none',
                  display: 'flex',
                  alignItems: 'center'
                }}>
                    <ColorPickerRound disabled={!canEdit()} color={companySettingsModal?.secondary_color} onChange={color => {
                    setCompanySettingsModal({
                      ...companySettingsModal,
                      secondary_color: color
                    });
                    setAFieldHasChanged(true);
                  }} />
                  </Box>
                </Stack>
              </Stack>
            </Box>
            <Divider />
          </Stack>
        </DialogContent>

        <DialogActions sx={{
        pt: 1,
        pb: 2
      }}>
          <Stack sx={{
          pr: '24px'
        }} direction={'row'} spacing={'20px'}>
            <Button onClick={() => {
            setCompanySettingsModal(null);
            setCompanySettingsModalFocus(null);
            setAFieldHasChanged(false);
          }} variant="contained" sx={{
            borderRadius: '20px',
            textTransform: 'none'
          }}>
              Cancel
            </Button>
            <Button disabled={hasInputErrors() || !aFieldHasChanged || !canEdit()} onClick={() => {
            setCompanySettingsModalFocus(null);
            props.updateCompany(companySettingsModal);
            setCompanySettingsModal(null);
            setAFieldHasChanged(false);
          }} variant="contained" sx={{
            mr: 1,
            borderRadius: '20px',
            textTransform: 'none',
            px: '20px'
          }}>
              Save
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>

      <Popper anchorEl={helpAnchorEl} open={!!helpData} onClose={handleHelpClose} placement="right-start" sx={{
      zIndex: 2100
    }}>
        <ClickAwayListener onClickAway={handleHelpClose}>
          <Paper elevation={0} sx={{
          borderRadius: '16px',
          overflow: 'visible',
          filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))',
          width: '312px'
        }}>
            <Stack>
              <Box sx={{
              padding: '16px'
            }}>{helpData?.title} </Box>
              <Box sx={{
              px: '16px'
            }}> {helpData?.content}</Box>
              <Box sx={{
              py: '16px'
            }}>
                <Button sx={{
                display: 'flex',
                justifyItems: 'left',
                textTransform: 'none'
              }} onClick={handleHelpClose}>
                  {helpData?.buttonText || ' Got it'}
                </Button>
              </Box>
            </Stack>
          </Paper>
        </ClickAwayListener>
      </Popper>

      <ModalFileViewer2 open={showFileViewer} onSubmit={file => {
      setAFieldHasChanged(true);
      setCompanySettingsModal({
        ...companySettingsModal,
        company_logo: file.url
      });
    }} onClose={() => setShowFileViewer(false)} />
    </>;
};