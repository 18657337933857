import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import { Scoped, a } from 'kremling';
import { func, bool, string, oneOf } from 'prop-types';
import { Transition } from 'react-spring/renderprops';
import styles from './error-fob.styles.scss';
import { Button } from '../button/button.component';
import { Icon } from '../icon/icon.component';
export class ErrorFOB extends Component {
  static propTypes = {
    open: bool,
    onClick: func
  };
  static defaultProps = {};
  render() {
    const {
      open,
      onClick
    } = this.props;
    return createPortal(<Scoped css={styles}>
                <div>
                    <Transition items={open} from={{
          transform: `translateY(10rem)`
        }} enter={{
          transform: `translateY(0)`
        }} leave={{
          transform: `translateY(10rem)`
        }} config={{
          mass: 0.5,
          tension: 120,
          friction: 10
        }}>
                        {open => {
            return open && (props => {
              return <div style={props} id="error-fob" onClick={onClick}>
                                        <Icon name='fa-solid-exclamation-circle' size={30} />
                                    </div>;
            });
          }}
                    </Transition>
                </div>
            </Scoped>, document.body);
  }
}