import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
export function CinchModal({
  show,
  setShow,
  headerComponent,
  contentComponent,
  footerComponent
}) {
  const closeModal = () => {
    setShow(false);
  };

  // TODO find out how children works with the dialog api
  // https://mui.com/material-ui/api/dialog/
  return <Dialog maxWidth="sm" fullWidth={true} open={show} onClose={closeModal} onClick={e => e.stopPropagation()}>
      <DialogTitle>{headerComponent}</DialogTitle>
      <DialogContent>{contentComponent}</DialogContent>
      <DialogActions>{footerComponent}</DialogActions>
    </Dialog>;
}