import React from 'react';
function Icon(props) {
  return <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" fill="none" viewBox="0 0 21 20">
      <mask id="mask0_91_866" style={{
      maskType: 'alpha'
    }} width="21" height="20" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0.067 0H20.067V20H0.067z"></path>
      </mask>
      <g mask="url(#mask0_91_866)">
        <path fill={props.fill || '#1D252D'} d="M10.067 16.667a.84.84 0 01-.615-.26.84.84 0 01-.26-.615v-5.688L7.65 11.646a.859.859 0 01-.625.27.859.859 0 01-.625-.27.859.859 0 01-.27-.625c0-.236.09-.445.27-.625l3.042-3.042a.91.91 0 01.302-.208.862.862 0 01.948.208l3.041 3.042c.18.18.271.389.271.625s-.09.444-.27.625a.859.859 0 01-.626.27.858.858 0 01-.625-.27l-1.541-1.542v5.688a.84.84 0 01-.26.614.84.84 0 01-.615.26zM4.275 7.5a.84.84 0 01-.615-.26.84.84 0 01-.26-.615V5.083c0-.486.17-.899.51-1.24.34-.34.754-.51 1.24-.51h9.833c.486 0 .9.17 1.24.51.34.341.51.754.51 1.24v1.542a.84.84 0 01-.26.614.84.84 0 01-.615.26.84.84 0 01-.614-.26.84.84 0 01-.26-.614V5.083H5.15v1.542a.84.84 0 01-.26.614.84.84 0 01-.615.26z"></path>
      </g>
    </svg>;
}
export default Icon;