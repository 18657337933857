import React from 'react';
function Icon(props) {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <mask id="mask0_17_18668" style={{
      maskType: 'alpha'
    }} width="24" height="24" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0 0H24V24H0z"></path>
      </mask>
      <g mask="url(#mask0_17_18668)">
        <path fill={props.fill || '#1D252D'} d="M4.425 6.875a.72.72 0 01.525-.225c.2 0 .375.075.525.225L9.85 11.25h7.45l-2.325-2.325a.679.679 0 01-.2-.5c0-.2.075-.375.225-.525.166-.167.35-.25.55-.25.2 0 .375.083.525.25l3.475 3.475c.1.083.166.18.2.288a1.137 1.137 0 010 .675.605.605 0 01-.2.287l-3.525 3.525a.726.726 0 01-.5.213.678.678 0 01-.525-.213c-.167-.167-.25-.35-.25-.55 0-.2.083-.375.25-.525l2.3-2.325H9.25L4.4 7.9a.678.678 0 01-.2-.5c0-.2.075-.375.225-.525zm-.025 9.2L7.075 13.4l1.05 1.075-2.7 2.675a.663.663 0 01-.487.2.791.791 0 01-.538-.225.766.766 0 01-.2-.525c0-.2.066-.375.2-.525z"></path>
      </g>
    </svg>;
}
export default Icon;