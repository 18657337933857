import React from 'react';
function Icon(props) {
  if (props.size == 'sm') {
    return <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 -960 960 960">
        <path fill={props.fill || "#1D252D"} d="M291-253.847L253.847-291l189-189-189-189L291-706.153l189 189 189-189L706.153-669l-189 189 189 189L669-253.847l-189-189-189 189z"></path>
      </svg>;
  }
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <mask id="mask0_41_1839" style={{
      maskType: 'alpha'
    }} width="25" height="24" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0 0H24V24H0z"></path>
      </mask>
      <g mask="url(#mask0_41_1839)">
        <path fill={props.fill || "#1D252D"} d="M12 13.05l-5.075 5.075a.736.736 0 01-.512.212.69.69 0 01-.538-.212.72.72 0 01-.225-.525c0-.2.075-.375.225-.525L10.95 12 5.875 6.925a.737.737 0 01-.212-.513.69.69 0 01.212-.537.72.72 0 01.525-.225c.2 0 .375.075.525.225L12 10.95l5.075-5.075a.74.74 0 01.513-.213.693.693 0 01.537.213.72.72 0 01.225.525.72.72 0 01-.225.525L13.05 12l5.075 5.075a.735.735 0 01.212.512.69.69 0 01-.212.538.72.72 0 01-.525.225.72.72 0 01-.525-.225L12 13.05z"></path>
      </g>
    </svg>;
}
export default Icon;