const LOCAL = ['localhost:8080', 'localhost:8088'];
const DEV = ['beta.marketing-dev.cinch.io', 'marketing-dev.cinch.io'];
const PROD = ['beta.marketing.cinch.io', 'marketing.cinch.io'];
const CONSTANTS = {
  URL: {
    ADD_TEMPLATE_MAIL: '/templates/print/add',
    LIST_TEMPLATE: '/templates'
  },
  MODES: {
    TEMPLATE: {
      CREATE: 'CREATE',
      PREVIEW: 'PREVIEW',
      PUBLISHV: 'PUBLISH'
    }
  },
  READ_ONLY_CUSTOMER_FIELDS: ['id', 'company_id', 'created_when', 'updated_when', 'deleted_when', 'entity_ref', 'entity_source', 'entity_number', 'index_rank', 'customer_value', 'customer_value_segment', 'visit_trend_days', 'predicted_return_date', 'predicted_visit_next_12_months', 'predicted_spend_next_12_months', 'product_preferences', 'recommended_products', 'pricing_sensitivity', 'last_transaction_when', 'first_transaction_when', 'appended_data__', 'stat_last_location'],
  /**
   * All Canadian province abbreviations.
   * @type {string[]}
   */
  PROVINCES_CANADA: ['AB', 'BC', 'MB', 'NB', 'NL', 'NS', 'NT', 'NU', 'ON', 'PE', 'QC', 'SK', 'YT'],
  /**
   * A list of timezones
   * @type {Object[]} The object format is: {id, label}
   */
  TIMEZONES: [{
    id: 'America/New_York',
    label: 'Eastern Standard Time',
    abbr: 'EST'
  }, {
    id: 'America/Chicago',
    label: 'Central Standard Time',
    abbr: 'CST'
  }, {
    id: 'America/Denver',
    label: 'Mountain Standard Time',
    abbr: 'MST'
  }, {
    id: 'America/Los_Angeles',
    label: 'Pacific Standard Time',
    abbr: 'PST'
  }, {
    id: 'America/Anchorage',
    label: 'Alaska Standard Time',
    abbr: 'AKST'
  }, {
    id: 'Pacific/Honolulu',
    label: 'Hawaii Standard Time',
    abbr: 'HST'
  }, {
    id: 'Etc/GMT+4',
    label: 'Atlantic Standard Time',
    abbr: 'AST'
  }, {
    id: 'Etc/GMT+7',
    label: 'Arizona',
    abbr: 'AZ'
  }],
  ENV: LOCAL.includes(window.location.host) ? 'LOCAL' : DEV.includes(window.location.host) ? 'DEV' : 'PROD'
};
export default CONSTANTS;