import * as React from 'react';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '../pagination/pagination.component';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import { visuallyHidden } from '@mui/utils';
import { ChipsArray } from '../chip/chip.component';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import PresetsCalendar from '../calendar/presets-calendar.component';
import DatePicker from '../calendar/date-picker.component';
import { FilterMenu } from '../filter/filter.component';
import qs from 'qs';
import { Loader } from '../../loader/loader.component';
import Typography from '@mui/material/Typography';
import { AntSwitch } from '../switch/ant-switch.component';
import { TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import CloseIcon from '@mui/icons-material/Close';
import { withStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import { UserStateContext } from 'context/user-state-context';
import { useContext } from 'react';
import { deleteFolder, getCompanyFolders, updateFolder } from 'shared/common.api';
import { Icon } from 'components/icon/icon.component';
import validator from 'validator';
import { useHistory, useLocation } from 'react-router-dom';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import { getFilterKeys, getFiltersFromURL } from './utils';
import FolderSelectDialog from '../folder/folder-select-dialog';
import { Modal as NewModal } from '../../../components/modal/modal.component';
import { Button } from 'components/button/button.component';
import { isEqual } from 'lodash';
import { AddFolderIcon, DeleteIcon } from '../icon';
const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'white',
      borderRadius: `14px`,
      '& fieldset': {
        border: 'none'
      }
    }
  }
})(TextField);
const renderIcon = item => {
  if (item === 'folder') {
    return <Icon name="custom-folder" className="mr-3" />;
  }
  return <Icon name="fa-solid-map-marker-alt" className="mr-3" />;
};
const ORDER_DESC = 'desc';
const ORDER_ASC = 'asc';
const bypassFilters = ['folder', 'ordering', 'customer_drawer_id', 'customer_drawer_tab'];

/**
 * @param {array}           filterOptions     List of filter options. TODO type this out somehow.
 * @param {string}          defaultSort       Default sort column and direction (e.g., -updated_when) // TODO we need to support multiple sorts in some cases ie: name,-created_when
 * @param {boolean}         enableAutoRefresh Whether or not we should have autorefresh going.
 * @param {string}          folderModel       Key for the folder types we're showing.
 * @param {function}        api               Function to make API call to get data
 * @param {number}          renderIteration   A number that is used to trigger a rerender when the parent wants it to happen.
 * @param {function}        folderApi         Function to make API call to get folders.
 * @param {list}            actionButtons     List of top-level actions. TODO type this out somehow.  What's the difference between this and actions?
 * @param {string}          type              Label for this table.
 * @param {boolean}         presetsCalendar   Whether or not to show the Presets Calendar
 * @param {function}        datePicker        Function to generate the Date Range picker calendar
 * @param {list}            actions           List of top-level actions. TODO Type this out somehow.  What's the difference between this and actionButtons?
 * @param {boolean}         enableCheckboxes  Whether or not to show the check boxes for each row.
 * @param {list}            headCells         List of column configurations. TODO Type this out somehow
 * @param {function}        handleRowClick    Callback for when a row is clicked
 * @param {function}        hoverActions      Function to generate the hover actions component
 * @param {[any, function]} dataState         Optionally allow parent object to control data state.
 * @param {[any, function]} filtersState      Optionally allow parent object to control filter state.  NOTE if you use this option
 * @description                                 you have to use getFiltersFromURL(filterOptions) in the parent component to load the URL filters.
 * @param {list}            middleActions     List of top-level actions. TODO Type this out somehow.  What's the difference between this and actionButtons?
 * @param {boolean}         toolbarVisible    Visiblity of the toolbar, default is TRUE, pass it in as prop.
 * @returns
 */
export default function EnhancedTable({
  filterOptions = [],
  defaultSort,
  enableAutoRefresh = false,
  folderModel,
  api,
  renderIteration,
  folderApi,
  actionButtons,
  type,
  datePicker,
  actions,
  enableCheckboxes = false,
  headCells,
  handleRowClick,
  hoverActions,
  dataState,
  filtersState,
  customSearch,
  middleActions,
  searchCount,
  disableCellPadding,
  getSearch,
  disabledRows,
  exportData,
  toolbarVisible = true,
  parentLoading
}) {
  const location = useLocation();
  const history = useHistory();
  const {
    asCompany
  } = useContext(UserStateContext);
  const [order, setOrder] = React.useState(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const url = queryParams.get('ordering');
    if (url) {
      return url.charAt(0) === '-' ? ORDER_DESC : ORDER_ASC;
    }
    return defaultSort?.charAt(0) === '-' ? ORDER_DESC : ORDER_ASC;
  });
  const [orderBy, setOrderBy] = React.useState(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const url = queryParams.get('ordering');
    if (url) {
      return url.charAt(0) === '-' ? url.substring(1) : url;
    }
    return defaultSort?.charAt(0) === '-' ? defaultSort.substring(1) : defaultSort;
  });
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [rowHover, setRowHover] = React.useState('');
  const [columnHover, setColumnHover] = React.useState('');
  const [paginationParams, setPaginationParams] = React.useState({
    limit: 20,
    offset: 0
  });
  const searchParams = new URLSearchParams(location.search);
  const folderParam = searchParams.get('folder');
  const [parentFolder, setParentFolder] = React.useState('home');
  const [isLoading, setIsLoading] = React.useState(false);
  const [paginationData, setPaginationData] = React.useState({});
  const [paramsLength, setParamsLength] = React.useState();
  const [search, setSearch] = React.useState();
  const [autoRefresh, setAutoRefresh] = React.useState(enableAutoRefresh);
  const [isRefreshing, setIsRefreshing] = React.useState(false);
  const [searchOpen, setSearchOpen] = React.useState(false);
  const [folderData, setFolderData] = React.useState([]);
  const [dragItem, setDragItem] = React.useState(null);
  const [folderSelectDialog, setFolderSelectDialog] = React.useState([]);
  const [deleteModal, setDeleteModal] = React.useState(null);
  const [initalLoad, setInitalLoad] = React.useState(true);
  const [orderParamOverride, setOrderParamOverride] = React.useState(false);
  let data, setData;
  if (dataState) {
    [data, setData] = dataState;
  } else {
    [data, setData] = React.useState([]);
  }
  let filters, setFilters;
  if (filtersState) {
    [filters, setFilters] = filtersState;
  } else {
    [filters, setFilters] = React.useState(() => getFiltersFromURL(filterOptions));
  }
  React.useEffect(() => {
    if (!initalLoad) {
      //const pageFilters = getFilterKeys(filterOptions);
      const newFilters = {};
      const changedParams = new URLSearchParams(window.location.search);
      for (let key of changedParams.keys()) {
        const extract = key?.match(/\[(.*)\]/)?.pop();
        const subKey = key.substring(0, key.length - 2 - (extract?.length || 1));
        if (!bypassFilters.includes(key)) {
          if (changedParams[key] !== '') {
            if (filterOptions.find(f => f.key === subKey)) {
              if (!newFilters[subKey]) {
                newFilters[subKey] = [];
              }
              newFilters[subKey].push(changedParams.get(key));
            } else {
              newFilters[key] = changedParams.get(key);
            }
          }
        } else if (key = 'ordering') {
          const value = changedParams.get(key);
          if (value && value.charAt(0) === '-') {
            setOrder(ORDER_DESC);
            setOrderBy(value.slice(1));
          } else if (value) {
            setOrder(ORDER_ASC);
            setOrderBy(value);
          }
        }
      }
      if (!isEqual(filters, newFilters)) {
        setFilters(newFilters);
      }
    }
    setInitalLoad(false);
  }, [window.location.search]);
  React.useEffect(() => {
    if (folderParam && validator.isUUID(folderParam)) {
      setParentFolder(folderParam);
    } else {
      setParentFolder('home');
    }
  }, [folderParam]);
  React.useEffect(() => {
    if (folderModel) {
      getCompanyFolders({
        model: folderModel,
        company: asCompany.id,
        parent: parentFolder
      }).then(({
        data
      }) => {
        if (data) {
          setFolderData(data.results.map(obj => ({
            ...obj,
            _type: 'folder'
          })));
        } else {
          setFolderData([]);
        }
      });
    }
  }, [asCompany?.id, parentFolder, renderIteration]);
  React.useEffect(() => {
    if (exportData) {
      exportData(data);
    }
  }, [data]);
  React.useEffect(() => {
    // Reload data when these values change.
    getData();
  }, [order, orderBy, paginationParams?.limit, paginationParams?.offset, asCompany?.id, parentFolder, filters]);
  React.useEffect(() => {
    if (renderIteration) {
      getData(true);
    }
  }, [renderIteration]);
  const handleRequestSort = (event, property) => {
    const ord = orderBy === property && order === ORDER_ASC ? ORDER_DESC : ORDER_ASC;
    setOrder(ord);
    setOrderBy(property);
  };
  const handleSelectAllClick = event => {
    if (event.target.checked) {
      if (search == null || search == '') {
        setSelected([...folderData, ...data]);
      } else {
        setSelected([...data]);
      }
      return;
    }
    setSelected([]);
  };
  const handleClickbox = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (event.shiftKey) {
      if (selectedIndex === -1) {
        //find closest checked item
        let i = data.indexOf(name) - 1;
        while (i > 0) {
          if (selected.find(f => f.id === data[i].id)) {
            break;
          }
          i--;
        }
        let range = [];
        for (i; i <= data.indexOf(name); i++) {
          range.push(data[i]);
        }
        let combined = selected.concat(range);
        newSelected = combined.filter((item, pos) => combined.indexOf(item) === pos);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
      }
    } else {
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
      }
    }
    setSelected(newSelected);
  };
  const handleChangePage = newPage => {
    setPage(newPage);
    if (newPage != 0) setAutoRefresh(false);
    setPaginationParams({
      limit: rowsPerPage,
      offset: rowsPerPage * newPage
    });
  };
  const isSelected = name => selected.indexOf(name) !== -1;
  function handleDateChange(after, before) {
    const {
      date_after: _,
      date_before: __,
      ...newFilters
    } = filters;
    setFilters({
      ...newFilters,
      date_after: after,
      ...(!!before && {
        date_before: before
      })
    });
  }
  const getData = (autoRefreshLoading, resetPagination) => {
    if (!autoRefreshLoading) {
      setIsLoading(true);
    } else {
      setIsRefreshing(true);
    }
    setSelected([]);
    if (api) {
      const params = {
        ...paginationParams,
        ...filters,
        company: asCompany.id,
        offset: resetPagination ? 0 : paginationParams.offset
      };
      if (search && search !== '') {
        if (customSearch) {
          params[customSearch] = search;
        } else {
          params.search = search;
        }
      }
      if (orderBy) {
        params.ordering = (order === ORDER_DESC ? '-' : '') + orderBy;
      }
      if (folderModel && !hasFilterOptions()) {
        if (folderParam && validator.isUUID(folderParam)) {
          params.folders = folderParam;
        } else {
          params.folders = 'home';
        }
      }
      api(params).then(({
        data
      }) => {
        if (data) {
          const results = data.results;
          setData(results.map(obj => ({
            ...obj,
            _type: 'rowItem'
          })));
          setIsLoading(false);
          setIsRefreshing(false);
          setPaginationData(data);
          if (searchCount) {
            searchCount(data?.count);
          }
        }
      });
    }
  };
  React.useEffect(() => {
    if (search === undefined || search === null) {
      // On our first load this will be a non string so don't load data again.
      return;
    }
    const delayDebounceFn = setTimeout(() => {
      getData(false, true);

      // only run if the prop exists
      if (search && getSearch) {
        getSearch(search);
      }
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [search]);
  React.useEffect(() => {
    let params = {};
    if (paginationParams.offset) {
      params.offset = 0; // paginationParams.offset;
    }
    for (const property in filters) {
      if (filters[property] !== '') {
        params[property] = filters[property];
      }
    }
    const pageFilters = getFilterKeys(filterOptions);

    // Add in other query params that are not filters
    const queryParams = new URLSearchParams(location.search);
    for (let key of queryParams.keys()) {
      const subKey = key.substring(0, key.length - 3);
      const inFilters = pageFilters.indexOf(key) > -1 || pageFilters.indexOf(subKey) > -1;
      if (!inFilters) {
        // If we have a query param that's not a filter then add it to the URL
        params[key] = queryParams.get(key);
      }
    }
    // Update URL with parameters so we can maintain our filtering as a history.
    let newurl;
    if (Object.keys(params).length) {
      newurl = window.location.protocol + '//' + window.location.host + window.location.pathname + '?' + qs.stringify(params);
    } else {
      newurl = window.location.protocol + '//' + window.location.host + window.location.pathname;
    }
    if (newurl === window.location.href) {
      window.history.replaceState({
        path: newurl
      }, '', newurl);
    } else {
      window.history.pushState({
        path: newurl
      }, '', newurl);
    }
    setParamsLength(Object.keys(params).length);
    handleChangePage(0);
  }, [filters]);
  React.useEffect(() => {
    if (autoRefresh && page == 0) {
      const interval = setInterval(() => getData(true), 60000);
      return () => clearInterval(interval);
    }
  }, [data, autoRefresh]);
  const onInputChange = searchVal => {
    setSearch(searchVal || '');
  };
  const reset = () => {
    setSearch('');
  };
  const handleSearchOpenClick = () => {
    setSearchOpen(!searchOpen);
  };
  const handleDragStart = item => {
    setDragItem(item);
  };
  const handleDragEnter = e => {
    e.target.parentNode.style.backgroundColor = 'grey';
  };
  const handleDragLeave = e => {
    e.target.parentNode.style.backgroundColor = 'white';
  };
  const handleDrop = (e, folder) => {
    folderApi(dragItem.id, {
      folder: folder.id
    }, dragItem).then(() => {
      getData();
    });
    e.target.parentNode.style.backgroundColor = 'white';
  };
  const handleEnterDirectory = folderId => {
    const queryParams = new URLSearchParams(searchParams);
    queryParams.set('folder', folderId);
    const newurl = window.location.pathname + '?' + queryParams.toString();
    history.push(newurl);
    setParentFolder(folderId);
  };

  /**
   * Checks to see if a user has applied filters to their table or not, and return boolean
   * to indicate whether to flatten our data or not.
   * @returns
   */
  const hasFilterOptions = () => {
    return !!search && search !== '' || Object.keys(filters).length > 0;
  };
  function onClose() {
    setFolderSelectDialog([]);
  }
  function onSubmitData(data) {
    const promises = [];
    folderSelectDialog.map(s => {
      promises.push(updateFolder(s.id, {
        parent: data === 'home' ? null : data
      }));
    });
    Promise.all(promises).then(() => {
      getData();
      setFolderSelectDialog([]);
    });
  }
  function onCloseDeleteModal() {
    setDeleteModal(null);
  }
  function deleteFolders() {
    deleteFolder(deleteModal.id).then(() => {
      setDeleteModal(null);
      getCompanyFolders({
        model: folderModel,
        company: asCompany.id,
        parent: parentFolder
      }).then(({
        data
      }) => {
        if (data) {
          setFolderData(data.results.map(obj => ({
            ...obj,
            _type: 'folder'
          })));
        }
      });
    });
  }
  const commas = x => {
    if (!x) {
      return 0;
    }
    let parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  };
  React.useEffect(() => {
    if (orderBy && (!defaultSort?.includes(orderBy) || orderParamOverride)) {
      const queryParams = new URLSearchParams(window.location.search);
      const orderDirection = order == 'desc' ? '-' : '';
      queryParams.set('ordering', orderDirection + orderBy);
      const newurl = window.location.pathname + '?' + queryParams.toString();
      history.push(newurl);
    }
    setOrderParamOverride(true);
  }, [orderBy, order]);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 && data.length % rowsPerPage > 0 ? Math.max(0, rowsPerPage - data.length % rowsPerPage) : 0;
  return <>
      <Box sx={{
      m: '20px'
    }}>
        <Paper className="secondary-color" elevation={0} sx={{
        width: '100%',
        borderRadius: '14px'
      }}>
          {toolbarVisible && <Toolbar variant="dense" sx={{
          pl: {
            sm: 2
          },
          pr: {
            xs: 1,
            sm: 1
          },
          borderBottom: '1px solid #E2E2E2',
          ...(false && selected.length > 0 && {
            bgcolor: theme => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity)
          })
        }}>
              <Stack direction="row" spacing={2} sx={{
            width: '100%',
            alignItems: 'center',
            pr: '10px'
          }}>
                {!!filterOptions?.length && !filterOptions.every(opt => opt.hidden?.hideFilterButton === true) && <FilterMenu filters={filters} handleFilterChange={setFilters} filterOptions={filterOptions} />}

                <ChipsArray filters={filters} handleFilterChange={setFilters} filterOptions={filterOptions} sx={{
              flexGrow: 1
            }} />
                {actionButtons && <Box>
                    {React.createElement(actionButtons.displayComponent, {
                data: {
                  selected: selected
                }
              })}
                  </Box>}
                <Divider orientation="vertical" sx={{
              height: '32px'
            }} />
                {searchOpen ? <Box>
                    <CustomTextField id="outlined-basic" autoFocus={true} placeholder={'Search ' + type + (searchCount ? ' (' + commas(paginationData.count) + ')' : '')} sx={{
                width: searchCount ? '290px' : '260px',
                height: '56px',
                pr: '20px'
              }} onChange={e => onInputChange(e.target.value)} value={search || ''} InputProps={{
                startAdornment: <InputAdornment position="start">
                            <Tooltip title="Collapse Search">
                              <IconButton sx={{
                      width: '40px',
                      height: '40px',
                      textAlign: 'center',
                      padding: 0,
                      backgroundColor: '#fff',
                      color: '#1D252D',
                      ':hover': {
                        backgroundColor: '#DAEFFF',
                        color: '#53A6D6'
                      }
                    }} onClick={handleSearchOpenClick}>
                                <SearchIcon sx={{
                        transform: 'scale(1.5)'
                      }} />
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>,
                endAdornment: !!search && <InputAdornment onClick={reset} position="start" sx={{
                  cursor: 'pointer'
                }}>
                            <CloseIcon />
                          </InputAdornment>
              }} />
                  </Box> : <Tooltip title={'Search ' + type}>
                    <IconButton sx={{
                width: '40px',
                height: '40px',
                textAlign: 'center',
                padding: 0,
                backgroundColor: '#fff',
                color: '#1D252D',
                ':hover': {
                  backgroundColor: '#DAEFFF',
                  color: '#53A6D6'
                }
              }} onClick={handleSearchOpenClick}>
                      <SearchIcon sx={{
                  transform: 'scale(1.5)'
                }} />
                    </IconButton>
                  </Tooltip>}

                {enableAutoRefresh && <>
                    <AntSwitch sx={{
                ml: '8px',
                colorPrimary: '#53A6D6'
              }} disabled={page != 0} checked={autoRefresh} onChange={() => {
                setAutoRefresh(!autoRefresh);
              }} inputProps={{
                'aria-label': 'ant design'
              }} />
                    <Typography> {isRefreshing ? 'Refreshing...' : 'Auto refresh'} </Typography>
                  </>}
                {enableAutoRefresh && <Divider orientation="vertical" sx={{
              height: '32px'
            }} />}
                {datePicker && <DatePicker filters={filters} after={filters.date_after} before={filters.date_before} handleFilterChange={setFilters} handleDateChange={handleDateChange} bottom="10px" />}
                {!!middleActions && <Divider orientation="vertical" sx={{
              height: '32px'
            }} />}
                {!!middleActions && <Box>{middleActions}</Box>}

                <Divider orientation="vertical" sx={{
              height: '32px'
            }} />
                <TablePagination sx={{
              height: '100%',
              overflow: 'hidden',
              minWidth: '140px'
            }} count={paginationData.count ? paginationData.count : !!paginationData.next ? 'many' : null} hasNext={!!paginationData.next} hasPrevious={!!paginationData.previous} rowsPerPage={rowsPerPage} rowCount={data.length} page={page} handleChangePage={handleChangePage} />
                {!!actions && <Divider orientation="vertical" sx={{
              height: '32px'
            }} />}
                {!!actions && <Box>{actions}</Box>}
              </Stack>
            </Toolbar>}

          <TableContainer>
            <Table sx={{
            minWidth: 750
          }} aria-labelledby="tableTitle" size={'medium'}>
              <colgroup>
                {enableCheckboxes && <col width="5%" />}
                {headCells.map((headCell, index) => <col key={headCell.key + index} width={headCell.width || '5%'} />)}
                {hoverActions && <col width="0%" />}
              </colgroup>
              <TableHead sx={{
              height: '60px'
            }}>
                <TableRow>
                  {enableCheckboxes && <TableCell padding="checkbox">
                      {data.length > 0 && <Checkbox color="primary" indeterminate={selected.length > 0 && selected.length < data.length + folderData.length} checked={data.length > 0 && selected.length === data.length + folderData.length} onChange={handleSelectAllClick} indeterminateIcon={<IndeterminateCheckBoxIcon sx={{
                    color: '#53A6D6'
                  }} />} inputProps={{}} sx={{
                    color: '#1D252D',
                    '&.Mui-checked': {
                      color: '#53A6D6'
                    },
                    '& .MuiSvgIcon-root': {
                      fontSize: 22
                    }
                  }} />}
                    </TableCell>}

                  {headCells.map((headCell, index) => <TableCell key={headCell.key + index} align={headCell.align} sortDirection={orderBy === headCell.key ? order : false} sx={{
                  py: 1,
                  pr: 1
                }}>
                      {headCell.noSort ? <Typography variant="tableHeader">{headCell.label}</Typography> : <TableSortLabel active={orderBy === headCell.key} direction={orderBy === headCell.key ? order : 'asc'} onClick={e => handleRequestSort(e, headCell.sortKey || headCell.key)}>
                          <Typography variant="tableHeader">{headCell.label}</Typography>
                          {orderBy === headCell.key ? <Box component="span" sx={visuallyHidden}>
                              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box> : null}
                        </TableSortLabel>}
                    </TableCell>)}

                  {hoverActions && <TableCell sx={{
                  padding: 0,
                  width: 0
                }} />}
                </TableRow>
              </TableHead>
              <TableBody>
                {!isLoading && folderData.length > 0 && !hasFilterOptions() && folderData.map((folder, index) => {
                const isItemSelected = isSelected(folder);
                return <TableRow hover onDragEnter={e => handleDragEnter(e)} onDragLeave={e => handleDragLeave(e)} onDrop={e => handleDrop(e, folder)} onDragOver={e => e.preventDefault()} sx={{
                  cursor: 'pointer',
                  height: '60px'
                }} role="checkbox" aria-checked={isItemSelected} tabIndex={-1} key={folder.id + index} selected={isItemSelected} onMouseEnter={() => setRowHover(folder.id)} onMouseLeave={() => setRowHover(null)}>
                        {enableCheckboxes && <TableCell padding="checkbox">
                            <Checkbox sx={{
                      color: '#1D252D',
                      '&.Mui-checked': {
                        color: '#53A6D6'
                      },
                      '& .MuiSvgIcon-root': {
                        fontSize: 22
                      }
                    }} onClick={event => handleClickbox(event, folder)} color="primary" checked={isItemSelected} />
                          </TableCell>}
                        <TableCell sx={{
                    py: 0
                  }} onClick={() => {
                    handleEnterDirectory(folder.id);
                  }}>
                          {renderIcon('folder')}
                          {folder.name}
                        </TableCell>
                        {headCells.length - 1 > 0 && Array(headCells.length - 1).fill(null).map((h, index) => <TableCell sx={{
                    py: 0
                  }} onClick={() => {
                    handleEnterDirectory(folder.id);
                  }} key={index} colSpan={hoverActions && folder.id !== rowHover && index + 2 === headCells.length ? 2 : 1}>
                                {' '}
                                --{' '}
                              </TableCell>)}

                        {rowHover === folder.id && <TableCell sx={{
                    position: 'absolute',
                    right: '20px',
                    background: '#F5F5F5',
                    flexDirection: 'row',
                    display: 'flex',
                    alignItems: 'center',
                    height: '60px',
                    borderBottomRightRadius: '14px'
                  }}>
                            <Box>
                              <Stack direction="row" spacing={2}>
                                <Tooltip title="Move to folder">
                                  <IconButton sx={{
                            ':hover': {
                              backgroundColor: '#DAEFFF'
                            }
                          }} onClick={e => {
                            setFolderSelectDialog([folder]);
                          }}>
                                    <AddFolderIcon />
                                  </IconButton>
                                </Tooltip>

                                <Tooltip title="Delete">
                                  <IconButton sx={{
                            ':hover': {
                              backgroundColor: '#DAEFFF'
                            }
                          }} onClick={e => {
                            setDeleteModal(folder);
                          }}>
                                    <DeleteIcon />
                                  </IconButton>
                                </Tooltip>
                              </Stack>
                            </Box>
                          </TableCell>}
                      </TableRow>;
              })}
                {!isLoading && !parentLoading && data.length > 0 ? data.map((row, index) => {
                const isItemSelected = isSelected(row);
                return <TableRow hover draggable={!!folderModel} onDragStart={() => handleDragStart(row)} onDragOver={e => e.preventDefault()} sx={{
                  cursor: disabledRows?.find(d => d === row.id) ? 'auto' : 'pointer',
                  height: '60px',
                  backgroundColor: disabledRows?.find(d => d === row.id) ? '#F9F9F9' : '#fff'
                }} role="checkbox" aria-checked={isItemSelected} tabIndex={-1} key={row.id} selected={isItemSelected} onMouseEnter={e => {
                  setRowHover(row.id);
                }} onMouseLeave={() => {
                  setRowHover(null);
                }}>
                        {enableCheckboxes && <TableCell padding="checkbox">
                            <Checkbox sx={{
                      color: '#1D252D',
                      '&.Mui-checked': {
                        color: '#53A6D6'
                      },
                      '& .MuiSvgIcon-root': {
                        fontSize: 22
                      }
                    }} onClick={event => handleClickbox(event, row)} color="primary" checked={isItemSelected} />
                          </TableCell>}

                        {headCells.map((headCell, index) => <TableCell sx={{
                    py: 0,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    maxWidth: '300px',
                    px: disableCellPadding ? '0px' : '16px'
                  }} onClick={() => handleRowClick && handleRowClick(row, columnHover)} key={headCell.key + index} {...headCell.props} onMouseEnter={() => setColumnHover(headCell.key)} onMouseLeave={() => setColumnHover(null)} colSpan={hoverActions && row.id !== rowHover && index + 1 === headCells.length ? 2 : 1}>
                            {React.createElement(headCell.displayComponent, {
                      data: row,
                      index: index,
                      onHover: {
                        row: rowHover,
                        column: columnHover
                      },
                      isNested: row.folder && hasFilterOptions(),
                      selected: selected
                    })}
                          </TableCell>)}

                        {hoverActions && row.id === rowHover && <TableCell sx={{
                    position: 'absolute',
                    right: '20px',
                    background: '#F5F5F5',
                    flexDirection: 'row',
                    display: 'flex',
                    alignItems: 'center',
                    height: '60px',
                    borderBottomRightRadius: '14px'
                  }}>
                            {React.createElement(hoverActions, {
                      data: row,
                      index: index,
                      isNested: row.folder && hasFilterOptions(),
                      selected: selected
                    })}
                          </TableCell>}
                      </TableRow>;
              }) : !isLoading && !parentLoading && data.length == 0 && paramsLength > 0 ? <TableRow>
                    {enableCheckboxes && <TableCell />}
                    <TableCell colSpan={headCells.length}>No results. Try adjusting filters</TableCell>
                  </TableRow> : !isLoading && !parentLoading && data.length == 0 ? <TableRow>
                    {enableCheckboxes && <TableCell />}
                    <TableCell colSpan={headCells.length}>No results</TableCell>
                  </TableRow> : <TableRow>
                    <TableCell>
                      <Loader overlay />
                    </TableCell>
                  </TableRow>}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>

      {folderSelectDialog.length > 0 && <FolderSelectDialog data={folderSelectDialog} onClose={onClose} onSubmit={onSubmitData} folderModel={folderModel}></FolderSelectDialog>}

      <NewModal title={'Delete folder'} onClose={onCloseDeleteModal} open={!!deleteModal} allowBackdropClick>
        <div className="modal__body">
          <p>Are you sure you would like to delete the following folder?</p>
          <p> {deleteModal?.name}</p>

          <em component="span">If this folder has any items in it, they will not be deleted, but will be moved back to the home folder.</em>
        </div>
        <div className="modal__actions">
          <Button actionType="flat" onClick={onCloseDeleteModal}>
            Cancel
          </Button>
          <Button actionType="primary" onClick={() => deleteFolders()}>
            Confirm
          </Button>
        </div>
      </NewModal>
    </>;
}