import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import { object, number, bool } from 'prop-types';
import { Scoped, k } from 'kremling';
import { debounce } from 'lodash';
import { Button } from 'components/button/button.component';
import OnEvent from '../parameters/on-event';
import css from './node-modal.styles.scss';
const sideBarWidth = 220;
const defaultBoxWidth = 124;
const defaultBoxHeight = 40;
const gap = 14;
export class NodeModal extends Component {
  constructor(props) {
    super(props);
    this.nodeModalRef = React.createRef();
    this.state = {
      rect: null,
      data: props.node.parameters,
      modalWidth: props.linkEdit ? 280 : props.node.modalWidth || 380
    };
  }
  componentDidMount() {
    window.addEventListener('resize', this.resize);
    this.forceUpdate();
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }
  static propTypes = {
    node: object,
    linkEdit: object,
    stageX: number,
    stageY: number,
    clientX: number,
    clientY: number,
    scale: number,
    readyOnly: bool
  };
  resize = debounce(() => this.forceUpdate(), 750);
  apply = () => {
    if (this.props.linkEdit) {
      if (this.state.data.id) {
        this.props.linkEdit.onEvent = this.state.data.id;
        this.props.node.updateOnEvent(this.props.linkEdit.id, this.state.data);
        this.props.linkEdit.eventText.text = this.state.data.name.toUpperCase();
        this.props.linkEdit.updateNodes();
        this.props.onSubmit();
      }
    } else {
      this.props.node.updateParameters(this.state.data);
      this.props.onSubmit();
    }
  };
  close = () => {
    this.props.onSubmit();
  };
  update = data => {
    this.setState(prevState => {
      return {
        data: {
          ...prevState.data,
          ...data
        }
      };
    }, () => {
      this.forceUpdate();
    });
  };
  reset = () => {
    this.setState(({
      data
    }) => ({
      data: {
        ...data,
        ...this.props.node.defaultParameters
      }
    }));
  };
  render() {
    const {
      node,
      stageX,
      stageY,
      scale,
      clientX,
      clientY,
      readOnly
    } = this.props;
    let rect = null;
    if (this.nodeModalRef && this.nodeModalRef.current) {
      rect = this.nodeModalRef.current.getBoundingClientRect();
    }
    const {
      data,
      modalWidth
    } = this.state;
    const boxWidth = defaultBoxWidth * scale;
    const boxHeight = defaultBoxHeight * scale;
    if (node) {
      const viewWidth = window.innerWidth;
      const viewHeight = window.innerHeight - 120; // 120 is my guess on how much extra space there is above and below the canvas
      const spaceOnLeft = clientX + stageX + node.container.x * scale;
      const spaceOnRight = viewWidth - (spaceOnLeft + boxWidth * scale + gap);
      const onLeft = spaceOnLeft - modalWidth - gap;
      const onRight = spaceOnLeft + boxWidth + gap;
      const spaceOnTop = clientY + stageY + node.container.y * scale;
      const spaceOnBottom = viewHeight - (spaceOnTop + boxHeight * scale + gap);
      let left = spaceOnLeft > spaceOnRight ? onLeft : onRight;
      if (left < 0 || left + modalWidth + gap > viewWidth) {
        left = 100;
      }
      let top = 0;
      if (rect) {
        if (rect.height + stageY + clientY + node.container.y * scale > viewHeight) {
          top = viewHeight - rect.height - gap;
        } else {
          top = stageY + clientY + node.container.y * scale;
        }
      } else {
        top = stageY + clientY + node.container.y * scale;
      }
      if (top < 0) {
        top = 25;
      }
      if (node.subType === 'condition_check_field') {
        if (spaceOnLeft > spaceOnRight) {
          if (spaceOnLeft < 200) {
            // if the space on left is small, then reposition less than usual
            left = stageX + clientX + node.container.x * scale - 395;
          } else {
            left = stageX + clientX + node.container.x * scale - 665;
          }
        } else {
          if (spaceOnRight < 200) {
            // space on right is larger
            // if the space on right is small, then reposition less than usual
            left = stageX + clientX + node.container.x * scale + 200;
          } else {
            left = stageX + clientX + node.container.x * scale;
          }
        }
        if (spaceOnTop > spaceOnBottom) {
          if (rect?.height > 415) {
            // if the modal will spill over the bottom then reposition it higher
            top = stageY + clientY + node.container.y * scale - 450;
          } else {
            top = stageY + clientY + node.container.y * scale - 285;
          }
        } else {
          top = stageY + clientY + node.container.y * scale + 130;
        }
      }
      const Component = this.props.linkEdit ? OnEvent : node.component;
      return createPortal(<Scoped css={css}>
          <div ref={this.nodeModalRef} className={!readOnly ? 'node-modal' : 'node-modal node-modal-disabled'} style={{
          left,
          top,
          width: modalWidth
        }}>
            {!this.props.linkEdit && <Button className="node-modal__head-close" actionType="flat" icon="fa-regular-times" small onClick={this.close} />}
            <div className="node-modal__inner" style={{
            overflow: rect && rect.height >= viewHeight * 0.84 ? 'auto' : 'visible'
          }}>
              <Component readOnly={readOnly} node={node} resize={this.resize} update={this.update} data={data || node.parameters} />
            </div>
            <div className="node-modal__footer">
              <div>
                {!this.props.linkEdit && <Button actionType="flat" onClick={this.reset}>
                    Reset
                  </Button>}
              </div>
              <div className="node-modal__footer-inner">
                {!this.props.linkEdit && <Button actionType="flat" onClick={this.close}>
                    Cancel
                  </Button>}
                <Button actionType="primary" onClick={this.apply} disabled={this.props.linkEdit && (!this.state.data || !this.state.data.id)}>
                  Apply
                </Button>
              </div>
            </div>
          </div>
        </Scoped>, document.body);
    }
    return null;
  }
}