import React from 'react';
function Icon(props) {
  return <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" fill="none" viewBox="0 0 25 24">
      <mask id="mask0_663_1571" style={{
      maskType: 'alpha'
    }} width="25" height="24" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#fff" d="M0.998 0H24.998V24H0.998z"></path>
      </mask>
      <g mask="url(#mask0_663_1571)">
        <path fill={props.fill || '#1D252D'} d="M5.203 7.727a.682.682 0 01-.705-.705.682.682 0 01.705-.704h9.392a.685.685 0 01.704.704.684.684 0 01-.704.705H5.203zm0 3.756a.682.682 0 01-.705-.704.685.685 0 01.705-.704h9.392c.204 0 .372.066.505.199s.2.302.2.505a.684.684 0 01-.705.704H5.203zm0 3.757a.682.682 0 01-.705-.704.685.685 0 01.705-.704h5.635c.204 0 .372.066.505.199s.2.301.2.505a.684.684 0 01-.705.704H5.203zm11.106 2.231l-2.02-2.02a.676.676 0 01-.21-.492c0-.188.07-.353.21-.493a.676.676 0 01.494-.212c.188 0 .352.07.493.212l1.526 1.502 3.499-3.498a.676.676 0 01.493-.212c.188 0 .352.07.493.212.14.156.211.328.211.516s-.078.36-.235.517l-3.968 3.968a.676.676 0 01-.493.211.676.676 0 01-.493-.211z"></path>
      </g>
    </svg>;
}
export default Icon;