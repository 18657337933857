import React, { Component } from 'react';
import { func, object, shape, string } from 'prop-types';
import { getSegments } from 'shared/common.api';
import { UserStateContext } from 'context/user-state-context';
import { DynamicTypeAhead } from '../../../../components/dynamic-type-ahead/dynamic-type-ahead.component';
import { Autocomplete, Box, FormControl, InputAdornment, Paper, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { CustomerIcon, TransactionIcon, VehicleIcon, ShoppingCartIcon, Calendar30DaysIcon } from 'components/mui';
export class ActionRemoveFromSegment extends Component {
  static contextType = UserStateContext;
  static propTypes = {
    update: func,
    node: object,
    data: shape({
      segment_id: string
    })
  };
  state = {
    segmentList: [],
    segmentInclusion: null,
    segmentNamePlaceholder: 'Choose Static Segment'
  };
  componentDidMount() {
    getSegments({
      company: this.context.asCompany.id,
      time_dynamic_segment: false,
      limit: 600
    }).then(({
      data
    }) => {
      this.setState({
        segmentList: data.results
      });
    });
  }
  toggleSegment = id => {
    const {
      segment_id
    } = this.props.data;
    this.props.update({
      segment_id: !segment_id ? id : segment_id.id !== id ? id : null
    });
  };
  makeOverflowTooltip = (content, length, bold) => {
    const contentLength = content?.length || content?.props?.children?.length;
    if (content) {
      return <Tooltip title={contentLength > length ? <> {content} </> : ''}>
          <Typography variant={bold ? 'tableHeader' : ''}>
            {contentLength > length ? <>{('' + (content.props?.children || content)).slice(0, length) + '...'}</> : <>{content}</>}
          </Typography>
        </Tooltip>;
    }
    return <Typography variant="nullData" color={'#BEBEBE'}>
        <span>--</span>{' '}
      </Typography>;
  };
  getIcon = included => {
    if (included === 'possession') {
      return <VehicleIcon />;
    } else if (included === 'transaction') {
      return <TransactionIcon />;
    } else if (included === 'schedule') {
      return <Calendar30DaysIcon />;
    } else if (included === 'cart') {
      return <ShoppingCartIcon />;
    } else {
      return <CustomerIcon />;
    }
  };
  render() {
    const {
      segmentList
    } = this.state;
    const selectedSegment = segmentList.find(segment => segment.id === this.props.data.segment_id);
    const segmentNamePlaceholder = selectedSegment ? `${selectedSegment.name}` : 'Choose Static Segment';
    const segmentInclusion = selectedSegment ? selectedSegment.included : null;
    const {
      getIcon
    } = this;
    return <div>
        <div className="form-group">
          <Paper sx={{
          width: '103px',
          height: '19px',
          pl: '10px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          position: 'absolute',
          marginTop: '-16px',
          zIndex: 1,
          boxShadow: 'none'
        }}>
            <Typography sx={{
            position: 'absolute',
            marginTop: '10px',
            opacity: 0.5,
            '&.Mui-focused': {
              opacity: 1
            },
            fontSize: '12px'
          }} id="static-segment-select-label">
              Static Segment
            </Typography>
          </Paper>
          <FormControl variant="outlined" sx={{
          width: '315px'
        }}>
            <Autocomplete id="choose-static-segment-select" options={segmentList} getOptionLabel={option => option.name} value={selectedSegment || null} onChange={(event, newValue) => {
            if (!newValue) {
              this.toggleSegment(null);
              return;
            }
            const segment = segmentList.find(segment => segment.name === newValue.name);
            this.toggleSegment(segment ? segment.id : null);
          }} filterOptions={(options, {
            inputValue
          }) => {
            return options.filter(option => option.name.toLowerCase().includes(inputValue.toLowerCase()));
          }} renderInput={params => <TextField {...params} label="" placeholder={selectedSegment ? `${selectedSegment.name}` : 'Choose Static Segment'} InputProps={{
            ...params.InputProps,
            startAdornment: <InputAdornment position="start">{getIcon(selectedSegment?.included)}</InputAdornment>
          }} value={selectedSegment ? selectedSegment.name : ''} />} renderOption={(props, option, {
            index
          }) => <Box component="li" {...props} key={option.name + index} sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px'
          }}>
                  {getIcon(option.included)}
                  {this.makeOverflowTooltip(option.name, 30)}
                </Box>} />
          </FormControl>

          <Stack direction={'column'} spacing={2} sx={{
          pb: '25px',
          pt: '10px',
          opacity: 0.7
        }}>
            <Typography>{'Segment Type: Static'}</Typography>

            <Typography>
              {'Segment Inclusion: '}

              {segmentInclusion === 'possession' ? 'Vehicle' : segmentInclusion ? segmentInclusion.charAt(0).toUpperCase() + segmentInclusion.slice(1) : segmentNamePlaceholder === 'Choose Static Segment' ? 'None' : 'Customer Only'}
            </Typography>
          </Stack>
        </div>
      </div>;
  }
}