import React from 'react';
function Icon() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" fill="none" viewBox="0 0 25 24">
      <mask id="mask0_1036_5909" style={{
      maskType: 'alpha'
    }} width="25" height="24" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#24335D" d="M0.517 0H24.517V24H0.517z"></path>
      </mask>
      <g mask="url(#mask0_1036_5909)">
        <path fill="#fff" d="M11.613 21.206l-6.692-3.852a1.78 1.78 0 01-.904-1.562V8.208a1.77 1.77 0 01.904-1.562l6.692-3.852a1.782 1.782 0 011.808 0l6.692 3.852a1.781 1.781 0 01.904 1.562v7.584a1.77 1.77 0 01-.904 1.562l-6.692 3.852a1.782 1.782 0 01-1.808 0zm.154-8.775v7.138l.615.354a.27.27 0 00.135.039.27.27 0 00.135-.039l.615-.354v-7.138l6.25-3.62v-.646a.189.189 0 00-.034-.1.39.39 0 00-.13-.111l-.661-.385-6.175 3.57-6.175-3.57-.662.385a.39.39 0 00-.13.11.19.19 0 00-.033.101v.647l6.25 3.619z"></path>
      </g>
    </svg>;
}
export default Icon;