import React from 'react';
function Icon(props) {
  if (props.size === 'lg') {
    return <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 -960 960 960">
        <path fill={props.fill || '#1D252D'} d="M478.923-478.923zM480-220.001q-108.333 0-184.166-75.833T220.001-480q0-108.333 75.833-184.166T480-739.999q108.333 0 184.166 75.833T739.999-480q0 108.333-75.833 184.166T480-220.001zm-.034-50.255q87.496 0 148.637-61.107 61.141-61.107 61.141-148.603 0-87.496-61.107-148.637-61.107-61.141-148.603-61.141-87.496 0-148.637 61.107-61.141 61.107-61.141 148.603 0 87.496 61.107 148.637 61.107 61.141 148.603 61.141z"></path>
      </svg>;
  }
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 -960 960 960">
      <path fill={props.fill || '#1D252D'} d="M480-480zm0 259.999q-107.922 0-183.961-76.038Q220.001-372.078 220.001-480q0-107.922 76.038-183.961Q372.078-739.999 480-739.999q107.922 0 183.961 76.038Q739.999-587.922 739.999-480q0 107.922-76.038 183.961Q587.922-220.001 480-220.001zM480-280q83 0 141.5-58.5T680-480q0-83-58.5-141.5T480-680q-83 0-141.5 58.5T280-480q0 83 58.5 141.5T480-280z"></path>
    </svg>;
}
export default Icon;