import React, { Component } from 'react';
import { Scoped } from 'kremling';
import styles from './integrations.styles.scss';
import { UserStateContext } from 'context/user-state-context';
import { Button } from '../../components/button/button.component';
import { createCredentials as createCredentialsAPI, updateCredential as updateCredentialsAPI, deleteCredential as deleteCredentialsAPI, getLoyaltyCompanies } from './integrations.resource';
import { toasterService } from '../../components/toaster/toaster-service';
import { ModalDialog } from '../../components/modal/modal-dialog.component';
import { AsyncModalDialog } from 'components';
const INTEGRATION_KEY = 'loyalty';
export class IntegrationLoyalty extends Component {
  static contextType = UserStateContext;
  static propTypes = {};
  state = {
    deleteCredential: {},
    updateCredential: {},
    isCredentialModalOpen: false,
    email: '',
    password: '',
    company: '',
    addError: null,
    companies: null
  };
  closeAdd = () => {
    this.setState({
      isCredentialModalOpen: false,
      email: '',
      password: '',
      company: '',
      addError: null,
      companies: null
    });
  };
  addCredential = (email, password, company) => {
    if (company === '') {
      return getLoyaltyCompanies({
        email,
        password
      }).then(companies => {
        this.setState({
          addError: null,
          companies
        });
      }).catch(err => {
        if (err.response) {
          this.setState({
            addError: err.response.data.non_field_errors[0]
          });
        } else {
          this.setState({
            addError: 'An unknown error occured'
          });
        }
      }).then(() => {
        // They need to pick company first
        throw 'skip';
      });
    }
    const company_data = this.state.companies.find(c => c.api_key === company);
    return createCredentialsAPI({
      identifier: email,
      secret1: password,
      secret2: company_data.id,
      company: this.context.asCompany.id,
      integration: INTEGRATION_KEY,
      entity_data: {
        name: company_data.name
      }
    }).then(() => toasterService.success('Successfully added loyalty company.')).then(() => this.props.reload()).catch(err => {
      if (err.response.data.non_field_errors) {
        toasterService.error(err.response.data.non_field_errors[0]);
      } else {
        toasterService.error('Unknown error trying to add credentials. Please try again.');
      }
    });
  };
  updateCredential = (id, email, password) => {
    return updateCredentialsAPI(id, {
      identifier: email,
      secret1: password
    }).then(() => toasterService.success('Successfully updated credentials.')).then(() => this.props.reload()).catch(err => {
      if (err.response.data.site_id) {
        toasterService.error(err.response.data.site_id[0]);
      } else {
        toasterService.error('Unknown error trying to update franchise. Please try again.');
      }
    });
  };
  removeCredentials = credentialsId => {
    return deleteCredentialsAPI(credentialsId).then(() => toasterService.success('Successfully deleted credentials')).then(() => this.props.reload()).catch(() => toasterService.error('Unknown error to trying delete credentials. Please try again.'));
  };
  render() {
    const {
      isCredentialModalOpen,
      email,
      password,
      deleteCredential,
      updateCredential,
      addError,
      companies,
      company
    } = this.state;
    const {
      credentials
    } = this.props;
    return <Scoped css={styles}>
        <div className="integration mb-5">
          <div className="integration__header">
            <div>Loyalty</div>
            {this.context.hasPermission('integration.add_credential') && <Button className="btn" actionType="primary" tag="a" onClick={() => this.setState({
            isCredentialModalOpen: true
          })}>
                Add Credentials
              </Button>}
          </div>
          <div className="integration__body">
            {credentials.map(credential => <div className="integration-item" key={credential.id}>
                <div className="integration-item__inner">
                  <div className="integration-title">
                    {credential.entity_data.name}
                  </div>
                  <div className="integration-actions">
                    {this.context.hasPermission('integration.change_credential') && <Button actionType="flat" icon="fa-regular-edit" onClick={e => {
                  e.stopPropagation();
                  this.setState({
                    updateCredential: credential
                  });
                }} />}

                    {this.context.hasPermission('integration.delete_credential') && <Button actionType="flat" icon="fa-regular-trash" onClick={e => {
                  e.stopPropagation();
                  this.setState({
                    deleteCredential: credential
                  });
                }} />}
                  </div>
                </div>
              </div>)}
          </div>

          <ModalDialog open={!!deleteCredential.id} title="Remove Credential" submitText="Remove" onSubmit={() => this.removeCredentials(deleteCredential.id)} onClose={() => this.setState({
          deleteCredential: {}
        })} allowBackdropClick>
            <p>
              <strong>{deleteCredential.email}</strong>
            </p>
            <p>Are you sure you want to remove this credential?</p>
          </ModalDialog>

          <AsyncModalDialog open={isCredentialModalOpen} title="Add Credential" onSubmit={() => this.addCredential(email, password, company)} onClose={this.closeAdd} allowBackdropClick>
            <p>
              Please enter your Cinch Loyalty below to link to your account.
            </p>

            {!!addError && <p className="form-error">{addError}</p>}

            <p>
              <label>Email</label>
              <input required className="form-control" name="email" value={email} onChange={e => this.setState({
              email: e.target.value
            })} />
            </p>
            <p>
              <label>Password</label>
              <input required className="form-control" name="password" type="password" value={password} onChange={e => this.setState({
              password: e.target.value
            })} />
            </p>
            {companies !== null && <p>
                <label>Company</label>
                <select required className="form-control" name="company" value={company} onChange={e => this.setState({
              company: e.target.value
            })}>
                  <option value=""></option>
                  {companies.map(company => <option key={company.api_key} value={company.api_key}>
                      {company.name}
                    </option>)}
                </select>
                <small>Select which company you want to integrate.</small>
              </p>}
          </AsyncModalDialog>

          <ModalDialog open={!!updateCredential.id} title="Update Credential" submitText="Update" onSubmit={() => this.updateCredential(updateCredential.id, email, password)} onClose={() => this.setState({
          updateCredential: {}
        })} allowBackdropClick>
            <p>Please update your Agemni information below.</p>
            <p>
              <label>ID</label>
              <input required className="form-control" name="email" defaultValue={updateCredential.identifier || ''} onChange={e => this.setState({
              email: e.target.value
            })} />
            </p>
            <p>
              <label>Token</label>
              <input required className="form-control" name="password" type="password" onChange={e => this.setState({
              password: e.target.value
            })} />
            </p>
          </ModalDialog>
        </div>
      </Scoped>;
  }
}