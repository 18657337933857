import React, { Component } from 'react';
import { bool, func, oneOf, string, node, oneOfType } from 'prop-types';
import { Modal } from '../modal/modal.component';
import { Button } from '../button/button.component';
import { FileViewer } from './file-viewer.component';
export class ModalFileViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: null
    };
  }
  static propTypes = {
    open: bool,
    onClose: func,
    allowBackdropClick: bool,
    title: string,
    size: oneOf(['sm', 'md', 'lg', 'xl']),
    scroll: bool,
    onSubmit: func,
    submitText: oneOfType([string, node]),
    cancelText: oneOfType([string, node]),
    children: node,
    actionType: oneOf(['primary', 'flat', 'grey', 'warning', 'flat-light', 'white'])
  };
  static defaultProps = {
    actionType: 'primary',
    title: "Select File",
    cancelText: 'Cancel',
    submitText: 'Select',
    size: "xl"
  };
  selectFile = file => {
    if (file.isFolder) {
      this.setState({
        selectedFile: null
      });
    } else {
      this.setState({
        selectedFile: file
      });
    }
  };
  render() {
    const {
      selectedFile
    } = this.state;
    const {
      open,
      onClose,
      allowBackdropClick,
      title,
      size,
      type,
      onSubmit,
      submitText,
      cancelText,
      actionType
    } = this.props;
    return <div>
                <Modal open={open} onClose={onClose} allowBackdropClick={allowBackdropClick} title={title} size={size} type={type} level={10}>
                    <div className="modal__body">
                        <FileViewer onSelect={this.selectFile} />
                    </div>

                    <div className="modal__actions">
                        <Button onClick={onClose}>
                            {cancelText}
                        </Button>
                        <Button disabled={!selectedFile} actionType={actionType} onClick={() => {
            onSubmit(selectedFile);
            onClose();
          }}>
                            {submitText}
                        </Button>
                    </div>
                </Modal>
            </div>;
  }
}