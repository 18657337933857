import React, { useState, useEffect } from 'react';
import { AsyncModalDialog, Button } from 'components';
export const RenameModelModal = props => {
  const {
    modelToRename = {}
  } = props;
  const [name, setName] = useState('');
  const onSubmit = () => {
    return props.onSubmit(name);
  };
  useEffect(() => {
    setName(modelToRename ? modelToRename.name : '');
  }, [props.modelToRename]);
  return <AsyncModalDialog title="Edit Model" open={props.open} onClose={props.onClose} onSubmit={onSubmit} allowBackdropClick>
      <p>
        <label>Name</label>
        <input autoFocus required className="form-control" defaultValue={modelToRename ? modelToRename.name : ''} onChange={e => setName(e.target.value)} />
      </p>
      <p>
        <label>ID</label>
        <input disabled className="form-control" defaultValue={modelToRename ? modelToRename.id : ''} />
      </p>
    </AsyncModalDialog>;
};