import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
const StyledDialog = styled(Dialog)(({
  theme,
  props
}) => ({
  maxWidth
}) => ({
  '& .MuiDialogContent-root': {
    padding: '24px',
    maxWidth
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));
const CustomDialogTitle = ({
  children,
  onClose,
  ...props
}) => {
  return <DialogTitle sx={{
    m: 0,
    p: '22px 0 22px 24px',
    paddingRight: !!onClose ? '10px' : '24px',
    display: 'flex'
  }} {...props}>
      <div style={{
      flex: '1'
    }}>
        {children}
      </div>
      {onClose ? <IconButton aria-label="close" onClick={onClose} sx={{
      color: theme => theme.palette.grey[500]
    }}>
          <CloseIcon />
        </IconButton> : null}
    </DialogTitle>;
};
export default function ({
  title,
  content,
  actions,
  children,
  open,
  onClose,
  maxWidth,
  height,
  sx,
  noDivider,
  allowOverflow = false
}) {
  return <StyledDialog fullWidth maxWidth={maxWidth} open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" PaperProps={{
    style: {
      borderRadius: '24px',
      overflowY: allowOverflow ? 'visible' : 'auto'
    }
  }} sx={sx}>
      <CustomDialogTitle onClose={onClose}>{title}</CustomDialogTitle>
      {!noDivider && <Divider />}
      <DialogContent sx={{
      overflowY: allowOverflow ? 'visible' : 'auto'
    }}>{content || children}</DialogContent>
      {!!actions && <>
          <Divider />
          <DialogActions sx={{
        margin: '16px',
        gap: '20px'
      }}>{actions}</DialogActions>
        </>}
    </StyledDialog>;
}