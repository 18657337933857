import React from 'react';
function Icon(props) {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <mask id="mask0_17_18688" style={{
      maskType: 'alpha'
    }} width="24" height="25" x="0" y="-1" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0 0H24V24H0z"></path>
      </mask>
      <g mask="url(#mask0_17_18688)">
        <path fill={props.fill || '#1D252D'} d="M19.425 20.475c-1.933 0-3.854-.463-5.762-1.388A18.727 18.727 0 018.55 15.45c-1.5-1.5-2.712-3.2-3.637-5.1-.925-1.9-1.388-3.825-1.388-5.775 0-.3.1-.55.3-.75.2-.2.45-.3.75-.3h3.25a1.044 1.044 0 011.05.85l.575 2.95c.034.25.025.475-.025.675-.05.2-.158.375-.325.525l-2.3 2.25A18.486 18.486 0 009.637 14.4a18.126 18.126 0 003.713 2.825l2.225-2.25a1.37 1.37 0 01.588-.35c.225-.067.454-.084.687-.05l2.775.575c.25.05.455.166.613.35a.998.998 0 01.237.675v3.25c0 .3-.1.55-.3.75-.2.2-.45.3-.75.3zM6.1 9.35l1.775-1.7a.221.221 0 00.063-.113.171.171 0 00-.013-.112L7.5 5.175a.227.227 0 00-.075-.113.204.204 0 00-.125-.037H5.175c-.05 0-.087.012-.113.037a.122.122 0 00-.037.088A12.459 12.459 0 006.1 9.35zm12.75 9.575a.12.12 0 00.088-.038.119.119 0 00.037-.087v-2.1a.204.204 0 00-.037-.125.227.227 0 00-.113-.075l-2.1-.425a.107.107 0 00-.1 0l-.1.05-1.775 1.8a10.21 10.21 0 002.075.725c.717.166 1.392.258 2.025.275z"></path>
      </g>
    </svg>;
}
export default Icon;