import React, { useContext } from 'react';
import { getRTCSample } from '../../shared/common.api';
import { Box, CircularProgress, Divider, IconButton, Paper, Table, TableBody, TableCell, TableHead, Tooltip, Typography, Skeleton } from '@mui/material';
import Stack from '@mui/material/Stack';
import TableRow from '@mui/material/TableRow';
import { UserStateContext } from 'context/user-state-context';
import { TransactionIcon, Calendar24HoursIcon, Calendar30DaysIcon, Calendar365DaysIcon, Calendar7DaysIcon } from 'components/mui';
import DatePicker from 'components/mui/calendar/date-picker.component';
import { CustomerDrawerContext } from 'context/customer-drawer-context';
import moment from 'moment';
export const RTCStepTwo = ({
  RTC,
  updateRTC
}) => {
  const {
    asCompany
  } = useContext(UserStateContext);
  const [isLoading, setIsLoading] = React.useState(true);
  const [sampleTransactionData, setSampleTransactionData] = React.useState([]);
  const [onHover, setOnHover] = React.useState({});
  const {
    openCustomerDrawer
  } = useContext(CustomerDrawerContext);
  const [filters, setFilters] = React.useState(() => {
    const newFilters = {};
    if (RTC.transactionDataDateFilterChoice?.date_after) {
      newFilters['date_after'] = RTC.transactionDataDateFilterChoice?.date_after;
    } else {
      newFilters['date_after'] = moment().subtract(7, 'days').startOf('day').toISOString();
    }
    if (RTC.transactionDataDateFilterChoice?.date_before) {
      newFilters['date_before'] = RTC.transactionDataDateFilterChoice?.date_before;
    }
    return newFilters;
  });
  React.useEffect(() => {
    setIsLoading(true);
    const sampleBuilder = {
      and: []
    };

    /*     if (filters['date_after']) {
      sampleBuilder['and'].push({
        field: 'transaction_when',
        model: 'transaction',
        value: filters['date_after'],
        operator: 'after',
        time_type: '',
        time_unit: '',
        query_type: 'value',
        related_field: '',
        related_model: '',
      });
    }
     if (filters['date_before']) {
      sampleBuilder['and'].push({
        field: 'transaction_when',
        model: 'transaction',
        value: filters['date_before'],
        operator: 'before',
        time_type: '',
        time_unit: '',
        query_type: 'value',
        related_field: '',
        related_model: '',
      });
    } */

    const after = filters['date_after'] ? filters['date_after'] : moment().subtract(7, 'days').startOf('day').toISOString();
    const before = filters['date_before'] ? filters['date_before'] : moment().toISOString();
    sampleBuilder['and'].push({
      field: 'transaction_when',
      model: 'transaction',
      operator: 'is_between',
      query_type: 'between_time_values',
      time_type: '',
      time_unit: '',
      related_field: '',
      related_model: '',
      value: [after, before]
    });
    const builder = {
      ...RTC.builder
    };
    if (builder['and']) {
      sampleBuilder['and'].push(...builder['and']);
    }
    if (builder['or']) {
      sampleBuilder['and'].push({
        or: [...builder['or']]
      });
    }
    updateRTC({
      ...RTC,
      sampleData: []
    });
    getRTCSample({
      company: asCompany.id,
      query: RTC.query,
      builder: sampleBuilder
    }).then(({
      data
    }) => {
      setSampleTransactionData(data.data);
      updateRTC({
        ...RTC,
        sampleData: data.data
      });
      setIsLoading(false);
    });
  }, [filters]);
  const handleRowClick = row => {
    openCustomerDrawer(row.customer_id, 'transactions', row.transaction_id, true);
  };
  const handleDateChange = (after, before) => {
    const {
      date_after: _,
      date_before: __,
      ...newFilters
    } = filters;
    setFilters({
      ...newFilters,
      date_after: after,
      ...(!!before && {
        date_before: before
      })
    });
  };
  return <Box spacing={'20px'} sx={{
    height: '67vh',
    padding: '20px',
    background: '#E4E9F1'
  }}>
      <Paper sx={{
      height: '100%',
      borderRadius: '14px'
    }}>
        <Stack sx={{
        height: '100%',
        overflowY: 'auto'
      }}>
          <Box sx={{
          borderBottom: '1px solid #E3E5E7',
          borderTop: '1px solid #E3E5E7',
          paddingY: '13px',
          paddingX: '24px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
            <Box sx={{
            paddingY: '10px'
          }}>
              <Typography fontSize={'20px'}>Sample Customer Transactions</Typography>
            </Box>
            {isLoading ? <Box>
                <CircularProgress sx={{
              color: '#FFB82B'
            }} size={'20px'} />
              </Box> : <Box />}
            <Stack spacing={'20px'} direction={'row'}>
              <Divider orientation="vertical" sx={{
              height: '32px'
            }} />
              <DatePicker filters={filters} after={filters.date_after} before={filters?.date_before} handleFilterChange={setFilters} handleDateChange={handleDateChange} bottom="10px" types={[{
              key: '24hours',
              name: 'Last 24 Hours',
              icon: <Calendar24HoursIcon />
            }, {
              key: '7days',
              name: 'Last 7 Days',
              icon: <Calendar7DaysIcon />
            }, {
              key: '30days',
              name: 'Last 30 Days',
              icon: <Calendar30DaysIcon />
            }, {
              key: '365days',
              name: 'Last year',
              icon: <Calendar365DaysIcon />
            }]} />
              <Divider orientation="vertical" sx={{
              height: '32px'
            }} />
              <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>{sampleTransactionData?.length + ' customers'}</Box>
            </Stack>
          </Box>
          <Box>
            <Table>
              <colgroup>
                <col style={{
                width: '25%'
              }} />
                <col style={{
                width: '25%'
              }} />
                <col style={{
                width: '25%'
              }} />
                <col style={{
                width: '25%'
              }} />
              </colgroup>
              <TableHead>
                <TableRow>
                  <TableCell sx={{
                  py: '12px'
                }}>
                    <Typography variant="tableHeader">Name</Typography>
                  </TableCell>
                  <TableCell sx={{
                  py: '12px'
                }}>
                    <Typography variant="tableHeader">Transaction Date </Typography>
                  </TableCell>
                  <TableCell sx={{
                  py: '12px'
                }}>
                    <Typography variant="tableHeader">Location </Typography>{' '}
                  </TableCell>

                  <TableCell sx={{
                  py: '12px'
                }}>
                    <Typography variant="tableHeader">Total </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              {isLoading ? <TableBody>
                  {Array(3).fill(null).map((row, index) => <TableRow key={index}>
                        {Array(4).fill(null).map((r, i) => <TableCell key={index + i}>
                              <Box>
                                <Skeleton />
                              </Box>
                            </TableCell>)}
                      </TableRow>)}
                </TableBody> : sampleTransactionData?.length > 0 ? <TableBody>
                  {sampleTransactionData?.map((row, index) => <TableRow sx={{
                cursor: 'pointer'
              }} hover key={index} onClick={e => {
                handleRowClick(row);
              }} onMouseEnter={() => setOnHover(index)} onMouseLeave={() => setOnHover({})}>
                      <TableCell>
                        <Box sx={{
                    py: '4px'
                  }}>
                          <Typography>
                            {row.first_name} {row.last_name}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {row.transaction_when ? moment(row.transaction_when).local().format('MMM D, YYYY h:mm A') : '--'}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{row.location}</Typography>
                      </TableCell>
                      <TableCell sx={{
                  padding: 0
                }}>
                        {onHover == index ? <Tooltip title="Transaction Details">
                            <IconButton onClick={e => handleRowClick(row)}>
                              <TransactionIcon />
                            </IconButton>
                          </Tooltip> : <Typography>$ {Number(row.total).toFixed(2)}</Typography>}
                      </TableCell>
                    </TableRow>)}
                </TableBody> : <TableBody>
                  <TableRow>
                    <TableCell>
                      <Box sx={{
                    py: '4px'
                  }}>
                        <Typography>No Data matched your rule(s)</Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Typography>Please go back and adjust your rule(s)</Typography>
                    </TableCell>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                  </TableRow>
                </TableBody>}
            </Table>
          </Box>
        </Stack>
      </Paper>
    </Box>;
};