import React, { useState, useContext, useEffect, useCallback } from 'react';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import CssBaseline from '@mui/material/CssBaseline';
import Alert from '@mui/material/Alert';
import moment from 'moment-timezone';
import { postForm, fetchFacebookForms, fetchFacebookBusinesses, fetchFacebookPages } from 'shared/forms.api';
import { Loader } from '../../../components/loader/loader.component';
import Box from '@mui/material/Box';
import { getFacebookTokens } from '../../../pages/integrations/integrations.resource';
import { UserStateContext } from 'context/user-state-context';
import { FormWizardMapper } from '../form-wizard/form-wizard-mapper.component';
export default function FacebookLeads(props) {
  const {
    asCompany,
    user
  } = useContext(UserStateContext);
  const [selectedToken, setSelectedToken] = useState(null);
  const [preselectedToken, setPreselectedToken] = useState(null);
  const [tokens, setTokens] = useState(null);
  const [preselectedPage, setPreselectedPage] = useState(null);
  const [selectedPage, setSelectedPage] = useState(null);
  const [pages, setPages] = useState(null);
  const [preselectedForm, setPreselectedForm] = useState(null);
  const [selectedForm, setSelectedForm] = useState(null);
  const [forms, setForms] = useState(null);
  const [formFields, setFormFields] = useState(null);
  const retrieveFacebookTokens = useCallback(async () => {
    try {
      const response = await getFacebookTokens({
        ordering: 'name',
        user: user.id
      });
      const data = response.results;
      const tokens = data.map(token => {
        token.expires_when = moment(token.expires_when);
        return token;
      });
      setTokens(tokens);
      if (tokens.length === 1) {
        setSelectedToken(tokens[0]);
      }
    } catch (error) {
      console.error(error);
      setTokens([]);
    }
  }, []);

  /* Previously we were grabbing pages via fetchFacebookPages, however,
     in some instances users don't have direct page (account) access so 
     we use this endpoint to see what businessess the user has access to and check
     the associated pages. */
  const retrieveFacebookPages = useCallback(async () => {
    try {
      const allPages = [];
      try {
        const businessResponse = await fetchFacebookBusinesses(selectedToken.id);
        const businesses = businessResponse.results;
        for (const business of businesses) {
          for (const ownedPage of business.owned_pages.data) {
            allPages.push(ownedPage);
          }
        }
      } catch (error) {
        console.log(error);
        console.log('Waiting on permission approval from Facebook... skipping business page retrieval');
      }
      const pageReponse = await fetchFacebookPages(selectedToken.id);
      for (const page of pageReponse) {
        allPages.push(page);
      }
      setPages(allPages);
      if (allPages.length === 1) {
        setSelectedPage(allPages[0]);
      }
    } catch (error) {
      setPages([]);
    }
  }, [selectedToken]);
  const retrieveFacebookForms = useCallback(async () => {
    try {
      const response = await fetchFacebookForms(selectedToken.id, selectedPage.id, asCompany.id);
      const allForms = response.map(form => {
        form.questions = form.questions.map(question => ({
          name: question.key,
          label: question.label
        }));
        return form;
      });
      setForms(allForms);
      if (allForms.length === 1) {
        setSelectedForm(allForms[0]);
      }
    } catch (error) {
      console.error(error);
      setForms([]);
    }
  }, [selectedToken, selectedPage, asCompany.id]);

  /* get list of facebook tokens on initial load */
  useEffect(() => {
    setTokens(null); // TODO I added this in does this casue any issues
    retrieveFacebookTokens();
  }, []);

  /* get pages from selected token */
  useEffect(() => {
    setPages(null);
    if (!selectedToken) {
      return;
    }
    retrieveFacebookPages();
  }, [selectedToken]);

  /* get forms from selected page */
  useEffect(() => {
    setForms(null);
    if (!selectedPage) {
      return;
    }
    retrieveFacebookForms();
  }, [selectedPage]);
  const handleSubmit = () => {
    postForm({
      company: asCompany.id,
      name: selectedForm.name,
      entity_source: 'facebook_leads',
      entity_ref: selectedForm.id,
      url: null,
      redirect: null,
      fields: formFields,
      entity_data: {
        token_id: selectedToken.id,
        page_id: selectedPage.id
      },
      html_index: null,
      html_tag: null
    }).then(() => {
      props.onSubmit();
    });
  };
  let content = <></>;
  let action = <></>;
  if (tokens === null || selectedToken && pages === null || selectedPage && forms === null) {
    content = <DialogContentText component="span">
        <Loader overlay background="rgba(255,255,255,.5)" />
      </DialogContentText>;
  }
  if (tokens !== null && tokens.length === 0) {
    content = <Alert severity="error">Unable to find any Facebook tokens. Please link your Facebook account on the Integrations page.</Alert>;
  }
  if (pages !== null && pages.length === 0) {
    content = <Alert severity="error">Unable to find any Facebook pages for token {selectedToken.name}.</Alert>;
  }
  if (forms !== null && forms.length === 0) {
    content = <Alert severity="error">
        Unable to find any Facebook forns for page {selectedPage.name} and token {selectedToken.name}.
      </Alert>;
  }

  /* token selection */
  if (!selectedToken && tokens !== null && tokens.length > 0) {
    content = <>
        <DialogContentText>Select which Facebook token to use:</DialogContentText>
        <Stack sx={{
        width: '100%',
        marginTop: '10px'
      }} spacing={2}>
          {tokens.map(token => <Stack direction="row" alignItems="center" gap={1} key={token.id}>
              <input id={token.id} name="token" type="radio" disabled={token.expires_when.isBefore(moment())} onClick={() => setPreselectedToken(token)} />
              <Box>
                <label htmlFor={token.id}>
                  {token.name} {token.expires_when.isBefore(moment()) && ' (Expired)'}
                </label>
              </Box>
            </Stack>)}
        </Stack>
      </>;
    action = <Button onClick={() => {
      setSelectedToken(preselectedToken);
    }} disabled={!preselectedToken} variant="text" sx={{
      borderRadius: '20px',
      width: '75px',
      textTransform: 'none'
    }}>
        Next
      </Button>;
  }

  /* page selection */
  if (selectedToken && pages != null && pages.length > 0 && !selectedPage) {
    content = <>
        <DialogContentText>Select which Facebook Page to use:</DialogContentText>
        <Stack sx={{
        width: '100%',
        marginTop: '10px'
      }} spacing={2}>
          {pages.map(page => <Stack direction="row" alignItems="center" gap={1} key={page.id}>
              <input id={page.id} name="page" type="radio" onClick={() => setPreselectedPage(page)} />
              <Box>
                <label htmlFor={page.id}>{page.name}</label>
              </Box>
            </Stack>)}
        </Stack>
      </>;
    action = <Button onClick={() => {
      setSelectedPage(preselectedPage);
    }} disabled={!preselectedPage} variant="text" sx={{
      borderRadius: '20px',
      width: '75px',
      textTransform: 'none'
    }}>
        Next
      </Button>;
  }

  /* form selection */
  if (selectedToken && selectedPage && forms != null && forms.length > 0 && !selectedForm) {
    content = <>
        <DialogContentText>Select which Facebook Form to use:</DialogContentText>
        <Stack sx={{
        width: '100%',
        marginTop: '10px'
      }} spacing={2}>
          {forms.map(form => <Stack direction="row" alignItems="center" gap={1} key={form.id}>
              <input disabled={form.already_exists} id={form.id} name="form" type="radio" onClick={() => setPreselectedForm(form)} />
              <Box>
                <label htmlFor={form.id}>
                  {form.name}
                  {form.already_exists && ' (already in use)'}
                </label>
              </Box>
            </Stack>)}
        </Stack>
      </>;
    action = <Button onClick={() => {
      setSelectedForm(preselectedForm);
    }} disabled={!preselectedForm} variant="text" sx={{
      borderRadius: '20px',
      width: '75px',
      textTransform: 'none'
    }}>
        Next
      </Button>;
  }

  /* field mapping */
  if (selectedToken && selectedPage && selectedForm) {
    content = <>
        <DialogContentText></DialogContentText>
        <FormWizardMapper formFields={selectedForm.questions} updateState={({
        formFields
      }) => setFormFields(formFields)} />
      </>;
    action = <Button onClick={() => {
      handleSubmit();
    }} variant="text" sx={{
      borderRadius: '20px',
      width: '75px',
      textTransform: 'none'
    }}>
        Finish
      </Button>;
  }
  return <>
      <CssBaseline />
      <Dialog maxWidth="md" PaperProps={{
      style: {
        borderRadius: '28px',
        minWidth: '350px'
      }
    }} open={!!props.open} onClose={(e, reason) => {
      if (reason === 'backdropClick') {
        return;
      }
      props.onClose();
    }}>
        <DialogTitle sx={{
        m: 0,
        p: 2,
        fontSize: 'medium'
      }}>
          <IconButton aria-label="close" onClick={e => {
          props.onClose();
        }} sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500]
        }}>
            <CloseIcon />
          </IconButton>
          <Stack alignItems="center" component="span">
            <Typography variant="h4" component="span">
              Add Facebook Leads
            </Typography>
          </Stack>
        </DialogTitle>
        <DialogContent dividers>{content}</DialogContent>
        <DialogActions sx={{
        py: 1
      }}>
          <Button onClick={() => {
          props.onClose();
        }} variant="text" sx={{
          borderRadius: '20px',
          color: 'rgba(29, 37, 45, 0.7)',
          width: '75px',
          textTransform: 'none'
        }}>
            Cancel
          </Button>
          {action}
        </DialogActions>
      </Dialog>
    </>;
}