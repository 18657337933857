import React, { Component } from 'react';
import { func, object, shape, string } from 'prop-types';
import { UserStateContext } from 'context/user-state-context';
import { Dropdown } from 'components/dropdown/dropdown.component';
import { Button } from 'components/button/button.component';
import { Icon } from 'components/icon/icon.component';
import { getPhoneNumbers } from 'shared/common.api';
import { StaticTypeAhead } from '../../../../components/static-type-ahead/static-type-ahead.component';
import utils from "../../../../shared/utils";
export class TriggerSMSIncoming extends Component {
  static contextType = UserStateContext;
  constructor(props) {
    super(props);
    this.state = {
      segmentList: [],
      numbers: []
    };
  }
  static propTypes = {
    update: func,
    node: object,
    data: shape({
      method: string,
      value: string,
      sentTo: string
    })
  };
  componentDidMount() {
    getPhoneNumbers({
      company: this.context.asCompany.id,
      limit: 1000
    }).then(numbers => {
      const numbersWithFormat = numbers.data.results.map(number => {
        let numberFormat = {
          ...number
        };
        numberFormat.phone = utils.formatPhoneNumber(numberFormat.phone);
        return numberFormat;
      });
      this.setState({
        numbers: [{
          id: '',
          phone: 'All'
        }].concat(numbersWithFormat)
      });
    });
  }
  sentToChange = sentTo => {
    this.props.update({
      sentTo
    });
  };
  render() {
    const {
      method,
      value,
      sentTo
    } = this.props.data;
    const {
      numbers
    } = this.state;
    const selectedSentTo = numbers.find(n => n.id === sentTo);
    return <div>
        <div className="form-group">
          <label>Sent To</label>
          <StaticTypeAhead items={numbers} placeholder={selectedSentTo ? selectedSentTo.phone : 'All'} displayProperty="phone" keyProperty="id" value={selectedSentTo ? selectedSentTo.id : ''} onChange={sentTo => sentTo && this.sentToChange(sentTo.id)} />
        </div>
        <div className="form-group">
          <label>Method</label>
          <Dropdown size="block" contentHeight={250} trigger={() => <Button dropdown block>
                {method === 'equals' ? 'Exact Match' : method === 'contains' ? 'Partial Match' : 'Choose Method'}
              </Button>} content={() => <ul className="select-list">
                <li>
                  <a onClick={() => this.props.update({
              method: 'equals'
            })}>
                    <div className="select-list__content">Exact Match</div>
                    {method === 'equals' && <Icon className="select-list__selected" size={14} name="fa-regular-check" />}
                  </a>
                </li>
                <li>
                  <a onClick={() => this.props.update({
              method: 'contains'
            })}>
                    <div className="select-list__content">Partial Match</div>
                    {method === 'contains' && <Icon className="select-list__selected" size={14} name="fa-regular-check" />}
                  </a>
                </li>
              </ul>} />
        </div>
        <div className="form-group">
          <label>Value</label>
          <input className="form-control" value={value || ''} onChange={e => this.props.update({
          value: e.target.value
        })} />
        </div>
      </div>;
  }
}