import React, { useState, useContext, useEffect } from 'react';
import { PageHeaderMui, Icon, Status } from 'components';
import { UserStateContext } from 'context/user-state-context';
import EnhancedTable from 'components/mui/enhanced-table/enhanced-table.component';
import { IconButton, Chip, Tooltip, Stack, Typography, Box } from '@mui/material';
import { getManagedBroadcasts } from 'shared/common.api';
import { ManageBroadcastEmailModal } from './modal/broadcast-email-create-modal.component';
import moment from 'moment-timezone';
import { ManageBroadcastInitialValueBuilder } from './utility';
import { UnfoldLessIcon, UnfoldMoreIcon } from 'components/mui';
import { NewButton } from 'components/mui/button/new.component';
export const BroadCastEmailManageList = ({
  history,
  location,
  match
}) => {
  const {
    hasPermission
  } = useContext(UserStateContext);
  const [initialFormValues, setInitialFormValues] = useState(new ManageBroadcastInitialValueBuilder().build());
  const [showModal, setShowModal] = useState(false);
  const [getData, setGetData] = useState(false);
  const [expandedRow, setExpandedRow] = useState(null);
  const openNewBroadcastModal = () => {
    setInitialFormValues(new ManageBroadcastInitialValueBuilder().build());
    setShowModal(true);
  };
  const openEditBroadcastModal = async data => {
    let showDrip = false;
    if (data.drip_end) {
      showDrip = true;
    }

    // TODO validate, if you select one you must select them all...
    let showWindow = false;
    if (data.window_start || data.window_end || data.window_days.length > 0) {
      showWindow = true;
    }
    const companies_selected = [];
    for (const broadcast of data.children_broadcasts) {
      companies_selected.push(broadcast.company);
    }
    let scheduleWhenTimezoneToggled = false;
    if (!data.schedule_timezone) {
      scheduleWhenTimezoneToggled = true;
    }
    const initialValues = new ManageBroadcastInitialValueBuilder().setId(data.id).setChildrenCompanies(companies_selected).setSegment(data.segment).setEmailTemplate(data.email_template).setScheduleWhenTimezoneToggle(scheduleWhenTimezoneToggled).setScheduleTimezone(data.schedule_timezone).setScheduleWhen(data.schedule_when).setDripToggled(showDrip).setDripEnd(data.drip_end).setWindowToggle(showWindow).setWindowStart(data.window_start).setWindowEnd(data.window_end).setWindowDays(data.window_days).setDistinctEmails(data.distinct_emails).setGoogleCampaign(data.google_campaign).build();
    setInitialFormValues(initialValues);
    setShowModal(true);
  };
  const hasBroadcastBeenSent = data => {
    return data.broadcast_when ? true : false;
  };
  const headcells = [{
    key: 'template',
    label: 'Template',
    width: '8%',
    displayComponent: row => {
      const data = row.data;
      if (expandedRow == data.id) {
        return <Stack sx={{
          pt: '14px',
          width: '8vw'
        }} spacing={3}>
              <Box sx={{
            px: '16px',
            borderBottom: '1px solid #E2E2E2'
          }}>
                <Tooltip title={data?.email_template_name}>
                  <Typography>{data.email_template_name}</Typography>
                </Tooltip>
              </Box>
              <Stack spacing={3}>
                {row.data.children_broadcasts.map(broadcast => <Box key={broadcast.id} sx={{
              px: '16px',
              width: '8vw'
            }}>
                    <Typography sx={{
                opacity: 0
              }}> a </Typography>
                  </Box>)}
              </Stack>
            </Stack>;
      } else {
        return <Box sx={{
          px: '16px',
          width: '8vw'
        }}>
              <Tooltip title={data?.email_template_name}>
                <Typography> {data.email_template_name}</Typography>
              </Tooltip>
            </Box>;
      }
    }
  }, {
    key: 'segment',
    label: 'Segment',
    width: '8%',
    displayComponent: row => {
      const data = row.data;
      if (expandedRow == data.id) {
        return <Stack sx={{
          pt: '14px',
          width: '8vw'
        }} spacing={3}>
              <Box sx={{
            px: '16px',
            borderBottom: '1px solid #E2E2E2'
          }}>
                <Tooltip title={data?.segment_name}>
                  <Typography>{data.segment_name}</Typography>
                </Tooltip>
              </Box>
              <Stack spacing={3}>
                {row.data.children_broadcasts.map(broadcast => <Box key={broadcast.id} sx={{
              px: '16px',
              width: '8vw'
            }}>
                    <Typography sx={{
                opacity: 0
              }}> a </Typography>
                  </Box>)}
              </Stack>
            </Stack>;
      } else {
        return <Box sx={{
          px: '16px',
          width: '8vw'
        }}>
              <Tooltip title={data?.segment_name}>
                <Typography>{data.segment_name} </Typography>
              </Tooltip>
            </Box>;
      }
    }
  }, {
    key: 'companies',
    label: 'Companies',
    width: '8%',
    displayComponent: row => {
      const data = row.data;
      if (expandedRow == data.id) {
        return <Stack sx={{
          pt: '18px',
          width: '8vw'
        }} spacing={3}>
              <Box sx={{
            px: '16px',
            borderBottom: '1px solid #E2E2E2'
          }}>
                <Typography>{data.children_broadcasts.length}</Typography>
              </Box>
              <Stack spacing={3}>
                {row.data.children_broadcasts.map(broadcast => <Box key={broadcast.id} sx={{
              px: '16px',
              borderBottom: '1px solid #E2E2E2',
              width: '8vw',
              overflowX: 'ellipsis'
            }}>
                    <Tooltip title={broadcast.company_name}>
                      <Typography> {broadcast.company_name}</Typography>
                    </Tooltip>
                  </Box>)}
              </Stack>
            </Stack>;
      } else {
        return <Box sx={{
          px: '16px',
          width: '8vw'
        }}>{data.children_broadcasts.length}</Box>;
      }
    }
  }, {
    key: 'status',
    label: 'Status',
    width: '8%',
    displayComponent: row => {
      const data = row.data;
      const hasBroadcasted = hasBroadcastBeenSent(data);
      if (expandedRow == data.id) {
        return <Stack sx={{
          pt: '18px'
        }} spacing={3}>
              <Box sx={{
            px: '16px',
            borderBottom: '1px solid #E2E2E2'
          }}>
                {hasBroadcasted ? <Typography>Sent</Typography> : <Typography>Scheduled</Typography>}
              </Box>
              <Stack spacing={3}>
                {row.data.children_broadcasts.map(broadcast => <Box sx={{
              px: '16px',
              borderBottom: '1px solid #E2E2E2'
            }} key={broadcast.id}>
                    {broadcast.broadcast_when ? <Typography>Sent</Typography> : <Typography>Scheduled</Typography>}
                  </Box>)}
              </Stack>
            </Stack>;
      } else {
        return <Box sx={{
          px: '16px'
        }}>{hasBroadcasted ? <Typography>Sent</Typography> : <Typography>Scheduled</Typography>}</Box>;
      }
    }
  }, {
    key: 'date',
    label: 'Date',
    width: '8%',
    displayComponent: row => {
      const data = row.data;
      let soonestBroadcast = undefined;
      for (const childBroadcast of data.children_broadcasts) {
        const childBroadcastTime = moment(childBroadcast.schedule_when);
        if (!soonestBroadcast) {
          soonestBroadcast = childBroadcastTime;
        } else {
          if (childBroadcastTime.isBefore(soonestBroadcast)) {
            soonestBroadcast = childBroadcastTime;
          }
        }
      }
      if (expandedRow == data.id) {
        if (soonestBroadcast) {
          return <Stack sx={{
            pt: 3,
            pt: '18px'
          }} spacing={3}>
                <Box sx={{
              px: '16px',
              borderBottom: '1px solid #E2E2E2'
            }}>
                  <Typography>{soonestBroadcast.format('MMM D, YYYY h:mm A')}</Typography>
                </Box>
                <Stack spacing={3}>
                  {row.data.children_broadcasts.map(broadcast => <Box key={broadcast.id} sx={{
                px: '16px',
                borderBottom: '1px solid #E2E2E2'
              }}>
                      <Typography> {moment(broadcast.schedule_when).format('MMM D, YYYY h:mm A')}</Typography>
                    </Box>)}
                </Stack>
              </Stack>;
        } else {
          return null;
        }
      } else {
        if (soonestBroadcast) {
          return <Box sx={{
            px: '16px'
          }}>{soonestBroadcast.format('MMM D, YYYY h:mm A')}</Box>;
        } else {
          return null;
        }
      }
    }
  }, {
    key: 'estimated_sends',
    label: 'Est. Sends',
    width: '8%',
    displayComponent: row => {
      const data = row.data;
      if (expandedRow == data.id) {
        return <Stack sx={{
          pt: 3,
          pt: '18px'
        }} spacing={3}>
              <Box sx={{
            px: '16px',
            borderBottom: '1px solid #E2E2E2'
          }}>
                <Typography>{data.estimated_sends} </Typography>
              </Box>
              <Stack spacing={3}>
                {row.data.children_broadcasts.map(broadcast => <Box sx={{
              px: '16px',
              borderBottom: '1px solid #E2E2E2'
            }} key={broadcast.id}>
                    <Typography> {data.estimated_sends} </Typography>
                  </Box>)}
              </Stack>
            </Stack>;
      } else {
        return <Box sx={{
          px: '16px'
        }}>{data.estimated_sends}</Box>;
      }
    }
  }, {
    key: 'sends',
    label: 'Sends',
    width: '8%',
    displayComponent: row => {
      const data = row.data;
      if (expandedRow == data.id) {
        return <Stack sx={{
          pt: 3,
          pt: '18px'
        }} spacing={3}>
              <Box sx={{
            px: '16px',
            borderBottom: '1px solid #E2E2E2'
          }}>
                <Typography> {data.sends}</Typography>{' '}
              </Box>
              <Stack spacing={3}>
                {row.data.children_broadcasts.map(broadcast => <Box sx={{
              px: '16px',
              borderBottom: '1px solid #E2E2E2'
            }} key={broadcast.id}>
                    <Typography>{data.sends} </Typography>
                  </Box>)}
              </Stack>
            </Stack>;
      } else {
        return <Box sx={{
          px: '16px'
        }}>{data.sends}</Box>;
      }
    }
  }, {
    key: 'delivers',
    label: 'Delivers',
    width: '8%',
    displayComponent: row => {
      const data = row.data;
      if (expandedRow == data.id) {
        return <Stack sx={{
          pt: 3,
          pt: '18px'
        }} spacing={3}>
              <Box sx={{
            px: '16px',
            borderBottom: '1px solid #E2E2E2'
          }}>
                <Typography> {data.delivers}</Typography>
              </Box>
              <Stack spacing={3}>
                {row.data.children_broadcasts.map(broadcast => <Box sx={{
              px: '16px',
              borderBottom: '1px solid #E2E2E2'
            }} key={broadcast.id}>
                    <Typography> {data.delivers} </Typography>
                  </Box>)}
              </Stack>
            </Stack>;
      } else {
        return <Box sx={{
          px: '16px'
        }}>{data.delivers}</Box>;
      }
    }
  }, {
    key: 'bounces',
    label: 'Bounces',
    width: '8%',
    displayComponent: row => {
      const data = row.data;
      if (expandedRow == data.id) {
        return <Stack sx={{
          pt: 3,
          pt: '18px'
        }} spacing={3}>
              <Box sx={{
            px: '16px',
            borderBottom: '1px solid #E2E2E2'
          }}>
                <Typography> {data.bounces}</Typography>
              </Box>
              <Stack spacing={3}>
                {row.data.children_broadcasts.map(broadcast => <Box sx={{
              px: '16px',
              borderBottom: '1px solid #E2E2E2'
            }} key={broadcast.id}>
                    <Typography>{data.bounces} </Typography>
                  </Box>)}
              </Stack>
            </Stack>;
      } else {
        return <Box sx={{
          px: '16px'
        }}>{data.bounces}</Box>;
      }
    }
  }, {
    key: 'opens',
    label: 'Opens',
    width: '8%',
    displayComponent: row => {
      const data = row.data;
      if (expandedRow == data.id) {
        return <Stack sx={{
          pt: 3,
          pt: '18px'
        }} spacing={3}>
              <Box sx={{
            px: '16px',
            borderBottom: '1px solid #E2E2E2'
          }}>
                <Typography> {data.opens}</Typography>{' '}
              </Box>
              <Stack spacing={3}>
                {row.data.children_broadcasts.map(broadcast => <Box sx={{
              px: '16px',
              borderBottom: '1px solid #E2E2E2'
            }} key={broadcast.id}>
                    <Typography> {data.opens}</Typography>
                  </Box>)}
              </Stack>
            </Stack>;
      } else {
        return <Box sx={{
          px: '16px'
        }}>{data.opens}</Box>;
      }
    }
  }, {
    key: 'clicks',
    label: 'Clicks',
    width: '8%',
    displayComponent: row => {
      const data = row.data;
      if (expandedRow == data.id) {
        return <Stack sx={{
          pt: 3,
          pt: '18px'
        }} spacing={3}>
              <Box sx={{
            px: '16px',
            borderBottom: '1px solid #E2E2E2'
          }}>
                <Typography>{data.clicks} </Typography>{' '}
              </Box>
              <Stack spacing={3}>
                {row.data.children_broadcasts.map(broadcast => <Box sx={{
              px: '16px',
              borderBottom: '1px solid #E2E2E2'
            }} key={broadcast.id}>
                    <Typography> {data.clicks} </Typography>
                  </Box>)}
              </Stack>
            </Stack>;
      } else {
        return <Box sx={{
          px: '16px'
        }}>{data.clicks}</Box>;
      }
    }
  }, {
    key: 'unsubscribes',
    label: 'Unsubscribes',
    width: '8%',
    displayComponent: row => {
      const data = row.data;
      if (expandedRow == data.id) {
        return <Stack sx={{
          pt: 3,
          pt: '18px'
        }} spacing={3}>
              <Box sx={{
            px: '16px',
            borderBottom: '1px solid #E2E2E2'
          }}>
                <Typography> {data.sends}</Typography>{' '}
              </Box>
              <Stack spacing={3}>
                {row.data.children_broadcasts.map(broadcast => <Box sx={{
              px: '16px',
              borderBottom: '1px solid #E2E2E2'
            }} key={broadcast.id}>
                    <Typography> {data.sends}</Typography>
                  </Box>)}
              </Stack>
            </Stack>;
      } else {
        return <Box sx={{
          px: '16px'
        }}>{data.sends}</Box>;
      }
    }
  }];
  const reloadData = () => {
    setGetData(true);
    setGetData(false);
  };
  return <div className="wrapper">
      <PageHeaderMui type="Managed Email Broadcasts" icon={<svg preserveAspectRatio="xMinYMin meet" width="40px" height="40px" viewBox="0 0 1 1">
            <use href={'#custom-cell_tower'} xlinkHref={'#custom-cell_tower'} />
          </svg>} />

      <div className="mui-wrapper">
        <EnhancedTable type="Managed Email Broadcasts" headCells={headcells} disableCellPadding handleRowClick={async data => {
        // TODO modal that tells the user the broadcast has already gone out
        const hasBroadcasted = hasBroadcastBeenSent(data);
        if (!hasBroadcasted) {
          await openEditBroadcastModal(data);
        }
      }} api={getManagedBroadcasts} renderIteration={reloadData} defaultSort={'-created_when'} actions={hasPermission('email.add_broadcast') && <NewButton onClick={e => openNewBroadcastModal()} />} hoverActions={({
        data
      }) => <Stack direction="row" gap="10px">
              <Tooltip title="Show Children Stats">
                <IconButton sx={{
            ':hover': {
              backgroundColor: '#DAEFFF'
            }
          }} onClick={e => {
            e.stopPropagation();
            setExpandedRow(expandedRow == data.id ? null : data.id);
          }}>
                  {data.id == expandedRow ? <UnfoldLessIcon /> : <UnfoldMoreIcon />}
                </IconButton>
              </Tooltip>
            </Stack>} />
      </div>
      {/**
       * This seems redundant, but we need to unmount
       * (allow the dom to be rewritten) to show the
       * different initialValues passed in to the same modal
       */}
      {showModal && <ManageBroadcastEmailModal show={showModal} setShow={setShowModal} initialFormValues={initialFormValues} reloadData={reloadData} />}
    </div>;
};