import React, { Component } from 'react';
import { Scoped } from 'kremling';
import { isNumber } from 'lodash';
import styles from './toaster.styles.scss';
import { toasterService } from './toaster-service';
import { Icon } from '../icon/icon.component';
import { Button } from '../button/button.component';
let timer;
let increment = 0;
export class Toaster extends Component {
  state = {
    toasts: []
  };
  componentDidMount() {
    toasterService.passToastFunc(this.toast);
  }
  runTimer = () => {
    window.clearTimeout(timer);
    timer = setTimeout(this.close, 6000);
  };
  toast = toastConfig => {
    this.setState(({
      toasts
    }) => ({
      toasts: [{
        key: increment,
        ...toastConfig
      }, ...toasts]
    }), () => {
      increment += 1;
      this.runTimer();
    });
  };
  close = index => {
    this.setState(({
      toasts
    }) => ({
      toasts: isNumber(index) ? [...toasts.slice(0, index), ...toasts.slice(index + 1)] : toasts.slice(1)
    }), () => {
      if (this.state.toasts.length) {
        this.runTimer();
      } else {
        window.clearTimeout(timer);
      }
    });
  };
  getIconFromType = type => {
    switch (type) {
      case 'success':
        return 'fa-solid-check-circle';
      case 'info':
        return 'fa-solid-info-circle';
      case 'warning':
        return 'fa-solid-exclamation-circle';
      case 'error':
        return 'fa-solid-exclamation-triangle';
    }
  };
  render() {
    const {
      toasts
    } = this.state;
    return <Scoped css={styles}>
        <div className="toaster">
          {toasts.map((toast, index) => <div key={toast.key.toString()} className={`toaster-item toaster-item-${index}`}>
              <div className="toaster-item__inner">
                <div className={`toaster-item__icon toaster-item__icon--${toast.type}`}>
                  <Icon name={toast.icon || this.getIconFromType(toast.type)} size={18} />
                </div>
                <div className="toaster-item__body">
                  {toast.message}
                </div>
                <Button icon="fa-regular-times" small actionType="flat-light" onClick={() => this.close(index)} />
              </div>
            </div>)}
        </div>
      </Scoped>;
  }
}