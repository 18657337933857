import React, { Component } from 'react';
import { object, func } from 'prop-types';
import { RRule } from 'rrule';
import { toInteger } from 'lodash';
import { dailyOptions } from './trigger-recurring.utils';
import { RecurringOption } from './recurring-option.component';
export class Daily extends Component {
  static propTypes = {
    data: object,
    rrule: object,
    update: func
  };
  setDaily = (patternOption, options = {}) => {
    if (patternOption === 'everyDay') {
      this.props.update({
        patternOption: 'everyDay',
        rrule: {
          ...this.props.rrule.options,
          ...dailyOptions.everyDay,
          ...options
        }
      });
    } else {
      this.props.update({
        patternOption,
        rrule: {
          ...this.props.rrule.options,
          ...dailyOptions[patternOption]
        }
      });
    }
  };
  updateEveryDay = (options = {}) => {
    this.setDaily('everyDay', {
      ...this.props.data.rrule.options,
      ...options
    });
  };
  render() {
    const {
      data,
      rrule
    } = this.props;
    return <div>
        <RecurringOption active={data.patternOption === 'everyDay'} onChange={() => this.setDaily('everyDay')} inactiveMessage="Every 1 Day">
          Every
          <input onChange={e => this.updateEveryDay({
          interval: toInteger(e.target.value) || 1
        })} value={rrule.options.interval} className="form-control inline recurring-inline-with-text" type="number" />
          day{rrule.options.interval > 1 ? 's' : ''}
        </RecurringOption>
        <RecurringOption active={data.patternOption === 'everyWeekday'} onChange={() => this.setDaily('everyWeekday')}>
          Every week day
        </RecurringOption>
        <RecurringOption active={data.patternOption === 'everyWeekendDay'} onChange={() => this.setDaily('everyWeekendDay')}>
          Every weekend day
        </RecurringOption>
      </div>;
  }
}