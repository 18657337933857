import React, { useState, useEffect, useContext } from 'react';
import { Button, Loader, toasterService, Banner } from 'components';
import { updateA2PBusinessInformation } from 'shared/common.api';
import { UserStateContext } from 'context/user-state-context';
import { submitCustomerProfile } from 'shared/common.api';
export const BusinessTypeStep = props => {
  const {
    updateProfile,
    profile,
    setActiveStep,
    verification,
    setVerification
  } = props;
  const {
    asCompany
  } = useContext(UserStateContext);
  const [isSaving, setIsSaving] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const submit = () => {
    // Submit this to the Cinch API which will submit to the Twilio API...
    // This will not be stored in Cinch's database but will be retrieved directly from Twilio
    setIsSaving(true);
    updateA2PBusinessInformation({
      company: asCompany.id,
      ...profile.a2p_business_information
    }).then(() => {
      setActiveStep(5);
    }).catch(() => {
      toasterService.error('There was an unknown error trying to save your profile. Check your inputs and try again');
    }).then(() => {
      setIsSaving(false);
    });
  };
  useEffect(() => {
    if (!profile?.a2p_business_information?.company_type || profile?.a2p_business_information?.company_type === '') {
      setIsDisabled(true);
    } else if (profile?.a2p_business_information?.company_type === 'public') {
      setIsDisabled(!profile?.a2p_business_information?.stock_exchange || profile?.a2p_business_information?.stock_exchange === '' || !profile?.a2p_business_information?.stock_ticker || profile?.a2p_business_information?.stock_ticker === '');
    } else {
      if (profile?.a2p_business_information?.stock_exchange) {
        updateProfile('a2p_business_information', 'stock_exchange', null);
      }
      if (profile?.a2p_business_information?.stock_ticker) {
        updateProfile('a2p_business_information', 'stock_ticker', null);
      }
      setIsDisabled(false);
    }
  }, [profile]);
  return <>
      <div className="form-group">
        <label>Company Type</label>
        <select className="form-control" name="company_type" onChange={e => updateProfile('a2p_business_information', 'company_type', e.target.value)} value={profile.a2p_business_information?.company_type || ''}>
          <option value=""></option>
          <option value="private">Private</option>
          <option value="public">Public</option>
          <option value="non-profit">Non-Profit</option>
          <option value="government">Government</option>
        </select>
        {verification?.error?.company_type && <small className="mt-1 block text-danger">{verification?.error?.company_type}</small>}
      </div>

      {profile?.a2p_business_information?.company_type === 'public' && <>
          <div className="form-group">
            <label>Stock Exchange</label>
            <select className="form-control" name="stock_exchange" onChange={e => updateProfile('a2p_business_information', 'stock_exchange', e.target.value)} value={profile.a2p_business_information?.stock_exchange || ''}>
              <option value=""></option>
              <option value="NASDAQ">NASDAQ</option>
              <option value="NYSE">NYSE</option>
            </select>
            {verification?.error?.stock_exchange && <small className="mt-1 block text-danger">{verification?.error?.stock_exchange}</small>}
          </div>
          <div className="form-group">
            <label>Stock Ticker</label>
            <input className="form-control" name="stock_ticker" onChange={e => updateProfile('a2p_business_information', 'stock_ticker', e.target.value)} value={profile.a2p_business_information?.stock_ticker || ''} />
            {verification?.error?.stock_ticker && <small className="mt-1 block text-danger">{verification?.error?.stock_ticker}</small>}
          </div>
        </>}

      <div style={{
      display: 'flex',
      gap: '10px',
      justifyContent: 'end'
    }}>
        <Button actionType="flat" onClick={() => setActiveStep(3)}>
          Back
        </Button>
        <Button actionType="primary" disabled={isDisabled || isSaving} onClick={submit}>
          Submit {isSaving && <Loader className="ml-3" size="sm" />}
        </Button>
      </div>
    </>;
};