import React from 'react';
function Icon() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none" viewBox="0 0 24 25">
      <mask id="mask0_65_4084" style={{
      maskType: 'alpha'
    }} width="24" height="25" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0 0.5H24V24.5H0z"></path>
      </mask>
      <g mask="url(#mask0_65_4084)">
        <path fill="#1D252D" d="M5.625 18v.975c0 .284-.1.525-.3.725-.2.2-.458.3-.775.3-.283 0-.53-.104-.738-.312a1.01 1.01 0 01-.312-.738v-7.375L5.55 5.75a.956.956 0 01.4-.55c.2-.133.425-.2.675-.2h10.8c.233 0 .446.071.637.213.192.142.321.32.388.537l2.05 5.825v7.4c0 .284-.1.525-.3.725-.2.2-.45.3-.75.3s-.554-.104-.762-.312a1.01 1.01 0 01-.313-.738V18H5.625zM5.6 10.075h12.8L17.125 6.5H6.875L5.6 10.075zm1.875 5.275c.35 0 .654-.13.912-.388.259-.258.388-.57.388-.937 0-.35-.13-.654-.388-.912a1.275 1.275 0 00-.937-.388c-.35 0-.654.13-.912.388-.259.258-.388.57-.388.937 0 .35.13.654.388.912.258.259.57.388.937.388zm9.075 0c.35 0 .654-.13.912-.388.259-.258.388-.57.388-.937 0-.35-.13-.654-.388-.912a1.275 1.275 0 00-.937-.388c-.35 0-.654.13-.912.388-.259.258-.388.57-.388.937 0 .35.13.654.388.912.258.259.57.388.937.388z"></path>
      </g>
    </svg>;
}
export default Icon;