import React, { useState, useContext, useEffect } from 'react';
import moment from 'moment-timezone';
import classnames from 'classnames';
import { Transition, animated } from 'react-spring/renderprops';
import { Icon } from '../../icon/icon.component';
import { Media } from '../../media/media.component';
import { Button } from '../../button/button.component';
import { toasterService } from '../../toaster/toaster-service';
import { Scoped } from 'kremling';
import styles from './index.styles.scss';
import utils from '../../../shared/utils';
import { Emoji } from '../../../components/emoji/emoji.component';
import { SmsTemplatesMenu } from 'components/templates/sms-templates.component';
import { UserStateContext } from 'context/user-state-context';
import { ModalFileViewer2 } from 'components/file-viewer/modal-file-viewer.component-mui';
import { Box, ClickAwayListener, Paper, Popper, Stack, Tooltip, Typography, Button as MuiButton, IconButton, Grid } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { AttachFileIcon, WarningRedIcon } from 'components/mui';
export function MessagingComponent(props) {
  const {
    hasPermission,
    user
  } = useContext(UserStateContext);
  const {
    messages,
    customerDetails,
    customer,
    phoneNumbers,
    sendMessage,
    isLoading
  } = props;
  const [addFileOpen, setAddFileOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [attachments, setAttachments] = useState([]);
  const [helpAnchorEl, setHelpAnchorEl] = React.useState(null);
  const [helpData, setHelpData] = React.useState(null);
  const [phoneNumbersAnchorEl, setPhoneNumbersAnchorEl] = React.useState(null);
  const phoneNumbersOpen = Boolean(phoneNumbersAnchorEl);
  const [helpDataLoading, setHelpDataLoading] = React.useState(false);
  const [twilioData, setTwilioData] = React.useState(null);
  useEffect(() => {
    if (phoneNumbers?.length == 0 || phoneNumber !== '' || messages == null || messages?.length == 0) {
      return;
    }
    //get last used phone number from messages
    const lastOutgoingMessage = messages.filter(m => m.direction === 'outgoing').reverse()[0];
    if (lastOutgoingMessage) {
      setPhoneNumber(lastOutgoingMessage.twilio_phone);
    }
  }, [phoneNumbers, messages]);
  const addFile = file => {
    setAttachments([...attachments, file]);
    setAddFileOpen(false);
  };
  const removeFile = index => {
    const newAttachments = [...attachments];
    newAttachments.splice(index, 1);
    setAttachments(newAttachments);
  };
  const openAddFile = () => {
    if (customerDetails.sms_unsubscribe_id) {
      return;
    }
    if (attachments.length >= 10) {
      toasterService.error('Message cannot contain include any more attachments');
      return;
    }

    // TODO add up size to make sure it's less than max.

    setAddFileOpen(true);
  };
  const innerSendMessage = () => {
    const phoneId = phoneNumbers.find(p => p.phone === phoneNumber)?.id || '';
    sendMessage(message, phoneId, attachments).then(() => {
      setMessage('');
      setAttachments([]);
    });
  };
  const handleTemplateChoice = (template, revision) => {
    setMessage(message ? message + revision.message : revision.message);
    setAttachments([...attachments, ...revision.media]);
  };
  const getTwilioData = async () => {
    const api_call = await fetch(`https://www.twilio.com/docs/api/errors/twilio-error-codes.json`);
    const data = await api_call.json();
    setTwilioData(data);
    return data;
  };
  const showMessagingErrorText = (errorCode, target) => {
    //have we fetched the data?
    if (!twilioData) {
      setHelpDataLoading(true);
      getTwilioData().then(data => {
        setHelpDataLoading(false);
        formatHelpData(errorCode, target, data);
      });
    } else {
      formatHelpData(errorCode, target);
    }
  };
  const formatHelpData = (errorCode, target, localData) => {
    const td = localData || twilioData;
    const errorObject = td.find(error => error.code == errorCode);
    if (!errorObject) {
      return '';
    }
    const hd = {
      title: 'Error Code: ' + errorObject?.code,
      content: !!errorObject?.message ? 'Message: ' + errorObject?.message : '',
      cause: !!errorObject?.causes ? 'Cause: ' + errorObject?.causes : ''
    };
    if (user.is_superuser) {
      hd.description = !!errorObject?.description ? 'Description: ' + errorObject?.description : '';
      hd.solutions = !!errorObject?.solutions ? 'Solutions: ' + errorObject?.solutions : '';
      hd.secondary_message = !!errorObject?.secondary_message ? 'Secondary Message: ' + errorObject?.secondary_message : '';
      hd.docs = !!errorObject?.docs ? 'Secondary Message: ' + errorObject?.docs : '';
    }
    setHelpData(hd);
    setHelpAnchorEl(target);
  };
  const handleHelpClose = () => {
    setHelpAnchorEl(null);
    setHelpData(null);
  };
  const handlePhoneNumbersClose = () => {
    setPhoneNumbersAnchorEl(null);
  };
  const handlePhoneNumbersClick = event => {
    if (!isLoading && hasPermission('communication.view_phonenumber')) setPhoneNumbersAnchorEl(phoneNumbersOpen ? null : event.currentTarget);
  };
  return <>
      <Scoped css={styles}>
        <div className="drawer__body" style={{
        height: '100%',
        overflowY: 'auto'
      }}>
          {isLoading ? <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          pt: '25px'
        }}>
              <CircularProgress />
            </Box> : <div className="d-flex h-100 flex-column">
              <div className="flex-grow messages-list">
                <div className="messages-list-content">
                  <Transition native items={messages} keys={message => message.id} from={{
                transform: 'translateY(10rem)'
              }} enter={{
                transform: 'translateY(0)'
              }} config={{
                mass: 0.5,
                tension: 120,
                friction: 10
              }}>
                    {message => props => <animated.div style={props} className={`message message--${message.direction} mb-2`}>
                          {(message.status === 'sent' || message.status === 'queued') && <div className="message-status" data-tip={message.status.charAt(0).toUpperCase() + message.status.slice(1)}>
                              <Tooltip title={message.status.charAt(0).toUpperCase() + message.status.slice(1)}>
                                <Icon name="fa-regular-ellipsis-h" size={10} />{' '}
                              </Tooltip>
                            </div>}
                          {(message.status === 'failed' || message.status === 'undelivered') && <div className="message-status" data-tip={message.status.charAt(0).toUpperCase() + message.status.slice(1)}>
                              {helpDataLoading ? <Tooltip title="Help Data Loading">
                                  <IconButton>
                                    <CircularProgress size={'24px'} />
                                  </IconButton>
                                </Tooltip> : <Tooltip title="Click for more info">
                                  <IconButton onClick={e => {
                        {
                          showMessagingErrorText(message?.error_code, e.currentTarget);
                        }
                      }}>
                                    <WarningRedIcon />
                                  </IconButton>
                                </Tooltip>}
                            </div>}
                          <div className="message-bubble">
                            {(message.media || []).map((media, index) => <Media media={media} key={index} />)}
                            {message.message}
                          </div>
                          <div className="d-flex w-100 justify-content-between text-sm">
                            <div>{message.direction === 'outgoing' ? message.twilio_phone : message.phone}</div>
                            <div className="ml-3">{moment(message.created_when).local().format('MMM D, YYYY h:mm A')}</div>
                          </div>
                        </animated.div>}
                  </Transition>
                </div>
              </div>

              {hasPermission('customer.send_two_way_messaging') && <>
                  <textarea placeholder="Send customer a message" className="form-control mt-3 reply-box" value={customerDetails.sms_unsubscribe_id ? 'Customer unsubscribed from text messages' : !customer.phone ? "Customer doesn't have a phone number" : !phoneNumbers.length ? 'You need to buy a phone number first' : message} onChange={e => setMessage(e.target.value)} autoFocus disabled={customerDetails.sms_unsubscribe_id || !customer.phone || !phoneNumbers.length} />
                  {!!attachments.length && <div className="mt-3">
                      <strong>Attachments:</strong>
                      {attachments.map((a, i) => <div className="d-flex justify-content-between align-items-center w-100">
                          <div className="truncate">{a.name}</div>
                          <div>
                            <Button small actionType="flat" icon="fa-regular-times" onClick={() => removeFile(i)} />
                          </div>
                        </div>)}
                    </div>}

                  <Box sx={{
              pt: '12px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
                    <Stack direction={'row'} spacing={'8px'}>
                      {!customerDetails?.sms_unsubscribe_id && customer?.phone && phoneNumbers?.length ? <SmsTemplatesMenu handleTemplateChoice={handleTemplateChoice} placement="top-start" /> : null}

                      {!customerDetails.sms_unsubscribe_id && customer.phone && phoneNumbers.length ? <Emoji onEmojiClick={emoji => setMessage(message + emoji)} /> : null}

                      {!customerDetails.sms_unsubscribe_id && customer.phone && phoneNumbers.length ? <IconButton onClick={() => openAddFile()}>
                          <AttachFileIcon />
                        </IconButton> : null}
                    </Stack>
                    <Stack direction={'row'} spacing={'20px'}>
                      <Box>
                        <Box sx={{
                    height: '32px',
                    borderRadius: '8px',
                    border: '1px solid #BEBEBE',
                    display: 'flex',
                    margin: 'auto',
                    alignItems: 'center',
                    cursor: customerDetails.sms_unsubscribe_id || !customer.phone || !phoneNumbers.length ? 'default' : 'pointer',
                    background: customerDetails.sms_unsubscribe_id || !customer.phone || !phoneNumbers.length ? 'rgba(239, 239, 239, 0.3)' : '#fff'
                  }} onClick={handlePhoneNumbersClick}>
                          <Box display="flex" flexDirection="row" justifyContent="space-around" sx={{
                      width: '100%',
                      color: phoneNumbersOpen ? '#53A6D6' : '#1D252D'
                    }}>
                            <Box sx={{
                        flexShrink: 1,
                        pl: 1
                      }}>
                              <Icon name="custom-send_to_mobile" size={24} />
                            </Box>
                            <Box sx={{
                        flexGrow: 5,
                        width: '100%',
                        alignContent: 'center',
                        margin: 'auto',
                        alignItems: 'center'
                      }}>
                              <Box sx={{
                          display: 'flex',
                          alignContent: 'center',
                          margin: 'auto',
                          alignItems: 'center'
                        }}>
                                <Typography sx={{
                            color: phoneNumbersOpen ? '#53A6D6' : '#1D252D',
                            alignContent: 'center',
                            margin: 'auto',
                            alignItems: 'center'
                          }}>
                                  {phoneNumber ? utils.formatPhoneNumber(phoneNumber) : 'Automatic'}
                                </Typography>
                              </Box>
                            </Box>
                            {phoneNumbersOpen ? <Box sx={{
                        pl: 1,
                        flexShrink: 1,
                        transform: 'rotate(180deg)',
                        alignContent: 'center',
                        margin: 'auto',
                        alignItems: 'center'
                      }}>
                                <Icon name="custom-keyboard-arrow-down" size={20} />
                              </Box> : <Box sx={{
                        flexShrink: 1,
                        alignContent: 'center',
                        margin: 'auto',
                        alignItems: 'center',
                        pl: 1
                      }}>
                                <Icon name="custom-keyboard-arrow-down" size={20} />
                              </Box>}
                          </Box>
                        </Box>
                      </Box>

                      <MuiButton variant="contained" disabled={!message || customerDetails.sms_unsubscribe_id} onClick={innerSendMessage}>
                        <Typography sx={{
                    color: '#fff',
                    textTransform: 'none',
                    padding: '1px 24px'
                  }}>
                          Send
                        </Typography>
                      </MuiButton>
                    </Stack>
                  </Box>
                </>}
            </div>}

          <ModalFileViewer2 open={addFileOpen} onSubmit={addFile} onClose={() => setAddFileOpen(false)} />
        </div>
      </Scoped>

      <Popper anchorEl={helpAnchorEl} open={!!helpData} onClose={handleHelpClose} placement="left-start" sx={{
      zIndex: 2000
    }}>
        <ClickAwayListener onClickAway={handleHelpClose}>
          <Paper elevation={0} sx={{
          borderRadius: '16px',
          overFlowX: 'ellipsis',
          overflowY: 'scroll',
          filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))',
          width: '312px',
          maxHeight: '30vh'
        }}>
            <Stack spacing={'8px'}>
              <Box sx={{
              px: '16px',
              pt: '16px'
            }}>
                <Typography sx={{
                fontWeight: '700'
              }}> {helpData?.title}</Typography>
              </Box>
              {helpData?.message && <Box sx={{
              px: '16px'
            }}> {helpData?.message}</Box>}
              {helpData?.cause && <Box sx={{
              px: '16px'
            }}> {helpData?.cause}</Box>}
              {/* for superusers */}
              {user.is_superuser && <Box sx={{
              px: '16px'
            }}>For Superusers:</Box>}
              {user.is_superuser && helpData?.description && <Box sx={{
              px: '16px'
            }}> {helpData?.description}</Box>}
              {user.is_superuser && helpData?.solutions && <Box sx={{
              px: '16px'
            }}> {helpData?.solutions}</Box>}
              {user.is_superuser && helpData?.secondary_message && <Box sx={{
              px: '16px'
            }}> {helpData?.secondary_message}</Box>}
              {user.is_superuser && helpData?.docs && <Box sx={{
              px: '16px'
            }}> {helpData?.docs}</Box>}
              <Box sx={{
              px: '16px'
            }}> Please contact Cinch support with this error code to get assistance</Box>

              <Box sx={{
              py: '16px'
            }}>
                <MuiButton sx={{
                display: 'flex',
                justifyItems: 'left',
                textTransform: 'none'
              }} onClick={handleHelpClose}>
                  {helpData?.buttonText || 'Okay'}
                </MuiButton>
              </Box>
            </Stack>
          </Paper>
        </ClickAwayListener>
      </Popper>

      <Popper anchorEl={phoneNumbersAnchorEl} sx={{
      zIndex: 2000
    }} open={phoneNumbersOpen} onClose={handlePhoneNumbersClose} placement="top">
        <ClickAwayListener onClickAway={handlePhoneNumbersClose}>
          <Paper elevation={0} sx={{
          py: 1,
          borderRadius: '16px',
          overflow: 'visible',
          width: '168px',
          // height: Math.max(168, 24 + numbers.length * 48) + 'px',
          filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))',
          mt: 1.5
        }}>
            <Box sx={{
            py: 1
          }}>
              <Grid container justifyContent="center" sx={{
              px: 2
            }}></Grid>
              <Box display="flex" flexDirection="column">
                <span key={'auto'}>
                  <MuiButton variant="text" fullWidth sx={{
                  cursor: 'pointer',
                  height: '48px',
                  color: '#1D252D',
                  textTransform: 'none',
                  justifyContent: 'flex-start',
                  px: 2
                }} onClick={() => {
                  setPhoneNumber('');
                  handlePhoneNumbersClose();
                }}>
                    <Typography> Automatic</Typography>
                  </MuiButton>
                </span>
                {phoneNumbers.length > 0 ? phoneNumbers.map(number => <span key={number.id}>
                      <MuiButton variant="text" fullWidth sx={{
                  cursor: 'pointer',
                  height: '48px',
                  color: '#1D252D',
                  textTransform: 'none',
                  justifyContent: 'flex-start',
                  px: 2
                }} onClick={() => {
                  setPhoneNumber(number.phone);
                  handlePhoneNumbersClose();
                }}>
                        <Typography> {utils.formatPhoneNumber(number.phone)}</Typography>
                      </MuiButton>
                    </span>) : <Box sx={{
                p: 2
              }}>
                    <Typography sx={{
                  pb: 2
                }}>No Phone Numbers </Typography>
                    <a onClick={() => props.history.push({
                  pathname: `/phone-numbers`
                })}>Click here to buy one</a>
                  </Box>}
              </Box>
              <Grid container justifyContent="flex-end">
                <MuiButton variant="text" onClick={() => {}}></MuiButton>
              </Grid>
            </Box>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>;
}