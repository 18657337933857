import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Chip from '@mui/material/Chip';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
const CustomRadio = withStyles({
  root: {
    '&$checked': {
      color: '#53A6D6'
    }
  },
  checked: {}
})(props => <Radio color="default" {...props} />);
export const SingleSelectFilter = props => {
  const {
    showChip,
    filterKey,
    filters,
    disabled,
    chipStyle,
    label,
    handleFilterChange,
    options
  } = props;
  if (showChip) {
    if (!filters[filterKey]) {
      return null;
    }
    const option = options.find(option => option.value === filters[filterKey]);
    if (!option) {
      return null;
    }
    return <Chip sx={{
      margin: '8px',
      backgroundColor: '#DAEFFF',
      color: '#53A6D6',
      borderRadius: '8px',
      textTransform: 'none'
    }} label=<span>
          {label}: {option.label}
        </span> onDelete={() => {
      const {
        [filterKey]: _,
        ...newFilters
      } = filters || {};
      handleFilterChange(newFilters);
    }} className={chipStyle} deleteIcon={<CloseOutlinedIcon sx={{
      transform: 'scale(0.75)'
    }} />} />;
  }
  return <FormControl>
      <FormLabel>
        <Typography variant="tableHeader">{label}</Typography>
      </FormLabel>
      <RadioGroup sx={{
      ml: '8px'
    }} onChange={(e, v) => {
      const opt = options.find(opt => opt.value === v);
      if (opt.isDefault) {
        let {
          [filterKey]: _,
          ...newFilters
        } = filters || {};
        handleFilterChange(newFilters);
      } else {
        handleFilterChange({
          ...(filters || {}),
          [filterKey]: v
        });
      }
    }} name="radio-buttons">
        {options.map(opt => <FormControlLabel disabled={disabled} key={opt.value} value={opt.value} control={<CustomRadio />} checked={!filters[filterKey] && opt.isDefault || opt.value == filters[filterKey]} label={opt.label} />)}
      </RadioGroup>
    </FormControl>;
};