import React, { useState, useContext } from 'react';
import { Scoped } from 'kremling';
import { getLocations, getTransactions, getTransactionsLocations, getTransactionsTotals } from '../../shared/common.api';
import styles from '../customers/customers.styles.scss';
import { CustomerDrawerContext } from 'context/customer-drawer-context';
import { Icon, PageHeaderMui } from 'components';
import EnhancedTable from 'components/mui/enhanced-table/enhanced-table.component';
import { Box, Divider, IconButton, Paper, Stack, Tooltip, Typography } from '@mui/material';
import moment from 'moment';
import { getFiltersFromURL } from 'components/mui/enhanced-table/utils';
import { DateRangeFilter } from 'components/mui/filter/date-range.component';
import utils from 'shared/utils';
import { LocationTooltip } from 'components/mui/tooltip/location-tooltip.component';
import { MultiSelectFilter } from 'components/mui/filter/multi-select.component';
import { UserStateContext } from 'context/user-state-context';
import { ListAltBoundingSideIcon, LocationOnIcon, MonetizationOnIcon, TransactionIcon, UnfoldLessIcon, UnfoldMoreIcon } from 'components/mui';
export const Transactions = () => {
  const {
    openCustomerDrawer
  } = useContext(CustomerDrawerContext);
  const [paginationDataCount, setPaginationDataCount] = useState(0);
  const [renderIteration, setRenderIteration] = React.useState(0);
  const [locations, setLocations] = React.useState([]);
  const {
    asCompany,
    isCatalystUser,
    userLocations
  } = useContext(UserStateContext);
  const [toolbarExpanded, setToolbarExpanded] = useState(false);
  const [stats, setStats] = useState({});
  const [search, setSearch] = React.useState([]);
  React.useEffect(() => {
    getLocations({
      company: asCompany.id,
      limit: 1000,
      user_locations: isCatalystUser,
      ordering: 'name'
    }).then(({
      data
    }) => {
      if (data && data.results) {
        setLocations(data.results);
      }
    });
  }, [asCompany.id, isCatalystUser]);
  React.useEffect(() => {
    if (!isCatalystUser) {
      return;
    }
    setRenderIteration(r => r + 1);
  }, [userLocations, isCatalystUser]);
  const filterOptions = [{
    component: MultiSelectFilter,
    label: 'Location',
    key: 'location',
    options: locations.map(location => ({
      label: location.friendly_name || 'Unnamed Location',
      value: location.id
    }))
  }];
  const [filters, setFilters] = React.useState(() => {
    const fils = getFiltersFromURL(filterOptions);
    if (!fils['date_after']) {
      fils['date_after'] = moment().subtract(7, 'days').startOf('day').toISOString();
    }
    return fils;
  });
  React.useEffect(() => {
    getTransactionsTotals({
      company: asCompany.id,
      location: !!isCatalystUser ? userLocations : null,
      ...filters,
      search
    }).then(({
      data
    }) => {
      if (data) {
        setStats(data);
      }
    });
  }, [asCompany.id, filters, search]);
  const headCells = [{
    key: 'customer_ref__first_name',
    label: 'Customer',
    width: '16%',
    noSort: true,
    displayComponent: ({
      data: transaction
    }) => transaction.customer_first_name || transaction.customer_last_name ? (transaction.customer_first_name || '(No first name)') + ' ' + (transaction.customer_last_name || '(No last name)') : <Typography>(No name) </Typography>
  }, {
    key: 'customer_ref__email',
    label: 'Email',
    width: '16%',
    noSort: true,
    displayComponent: ({
      data: t
    }) => <>{t.customer_email ? t.customer_email : <Typography> --</Typography>} </>
  }, {
    key: 'customer_ref__phone',
    label: 'Phone',
    width: '16%',
    noSort: true,
    displayComponent: ({
      data: t
    }) => <>{t.customer_phone ? utils.formatPhoneNumber(t.customer_phone) : <Typography> --</Typography>} </>
  }, {
    key: 'transaction_when',
    label: 'Transaction Date',
    width: '16%',
    displayComponent: ({
      data: t
    }) => <>{t.transaction_when && moment(t.transaction_when).local().format('MM-DD-YYYY h:mm A')} </>
  }, {
    key: 'location',
    align: 'left',
    disablePadding: true,
    label: 'Location',
    width: '20%',
    displayComponent: ({
      data
    }) => <LocationTooltip location={data.full_location} />
  }, {
    key: 'total',
    label: 'Total',
    width: '16%',
    displayComponent: ({
      data: t
    }) => t.total ? <> {`$${Number(t.total).toFixed(2)}`}</> : <Typography sx={{
      color: '#1D252D80'
    }}> $0.00 </Typography>
  }];
  const handleRowClick = row => {
    // customer_id, tab, transaction
    openCustomerDrawer(row.customer_id, 'transactions', row.id);
  };
  const commas = x => {
    let parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  };
  const getPresetDateFilterValue = () => {
    const days = moment().diff(moment(filters['date_after']), 'days');
    if (filters['date_before']) {
      return 'Date Range';
    }
    if (days < 5) {
      return 'Last 24 hours';
    }
    if (days < 25) {
      return 'Last 7 days';
    }
    if (days < 300) {
      return 'Last 30 days';
    }
    if (days < 400) {
      return 'Last year';
    }
    if (isNaN(days) || days == 0) {
      return 'All';
    }
    return 'All';
  };
  const TransactionsApiWrapper = () => {
    return params => getTransactions({
      location: !!isCatalystUser ? userLocations : null,
      ...params
    });
  };
  return <Scoped css={styles}>
      <div className="wrapper">
        <PageHeaderMui type={`Transactions`} icon={<Icon name="custom-receipt_long" size={34} />} />

        <div className="mui-wrapper">
          <Stack sx={{
          pt: 3
        }}>
            <Box sx={{
            px: 3
          }}>
              <Paper sx={{
              height: toolbarExpanded ? '345px' : '65px'
            }}>
                <Stack sx={{
                height: '100%',
                py: '10px'
              }} className="d-flex justify-content-between align-items-center w-100" direction="row">
                  <Stack sx={{
                  pl: '24px',
                  display: 'flex',
                  alignItems: 'center'
                }} spacing="20px" direction="row">
                    <Typography fontSize="20px">{getPresetDateFilterValue()}</Typography>
                    <Stack spacing="10px" sx={{
                    display: 'flex',
                    alignItems: 'center'
                  }} direction="row">
                      <ListAltBoundingSideIcon />
                      <Typography variant="tableHeader"> Transaction Count:</Typography>
                      <Typography> {` ${paginationDataCount ? `${commas(paginationDataCount)}` : ''}`}</Typography>
                    </Stack>
                    <Divider orientation="vertical" sx={{
                    height: '24px'
                  }} />
                    <Stack spacing="10px" sx={{
                    display: 'flex',
                    alignItems: 'center'
                  }} direction="row">
                      <MonetizationOnIcon />
                      <Typography variant="tableHeader"> Transaction Total:</Typography>
                      <Typography>{`$${commas(Number(stats.transaction_total).toFixed(2))}`} </Typography>
                    </Stack>
                    <Divider orientation="vertical" sx={{
                    height: '24px'
                  }} />
                    <Stack spacing="10px" sx={{
                    display: 'flex',
                    alignItems: 'center'
                  }} direction="row">
                      <LocationOnIcon />
                      <Typography variant="tableHeader"> Location Total:</Typography>
                      <Typography> {stats.location_count ? commas(stats.location_count) : ''} </Typography>
                    </Stack>
                  </Stack>
                  {/* hiding this button to expand the toolbar for now unless we need it in the future */}
                  {/* <IconButton
                    onClick={(e) => {
                      setToolbarExpanded(!toolbarExpanded);
                    }}
                   >
                    {toolbarExpanded ? <UnfoldLessIcon /> : <UnfoldMoreIcon />}
                   </IconButton> */}
                </Stack>
              </Paper>
            </Box>
            <EnhancedTable type="Transactions" headCells={headCells} api={TransactionsApiWrapper()} defaultSort={'-transaction_when'} searchCount={count => setPaginationDataCount(count)} getSearch={value => setSearch(value)} handleRowClick={row => {
            handleRowClick(row);
          }} filterOptions={filterOptions} filtersState={[filters, setFilters]} renderIteration={renderIteration} datePicker hoverActions={({
            data
          }) => <Stack direction="row" gap="10px">
                  <Tooltip title="Transaction Details">
                    <IconButton sx={{
                ':hover': {
                  backgroundColor: '#DAEFFF'
                }
              }} onClick={e => {
                e.stopPropagation();
                handleRowClick(data);
              }}>
                      <TransactionIcon />
                    </IconButton>
                  </Tooltip>
                </Stack>} />
          </Stack>
        </div>
      </div>
    </Scoped>;
};