import React, { Component } from 'react';
export class ButtonGroup extends Component {
  render() {
    const {
      children
    } = this.props;
    return <div className="btn-group">
        {children}
      </div>;
  }
}