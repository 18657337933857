import React from 'react';
function Icon(props) {
  if (props.size == 'lg') {
    return <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="#e8eaed" viewBox="0 -960 960 960">
        <path fill={props.fill || '#3EB87B'} d="M425.13-103.54q-68.72-10.59-127.54-43.4-58.82-32.81-101.6-82.63-42.78-49.82-66.95-113.8-24.17-63.99-24.17-136.63 0-87.2 39.32-164.35 39.32-77.14 111.48-135.91H135.41v-50.25h209.72v209.72h-50.26v-125.67q-66.9 49.69-103.32 119.28-36.42 69.59-36.42 147.18 0 123.23 75.87 213.86 75.87 90.63 194.13 111.35v51.25zM423-316.62L274.62-465.21 310-500.59l113 113 227-227L685.38-579 423-316.62zM614.87-129.1v-209.72h50.26v125.67q66.77-50.7 103.25-119.98 36.49-69.28 36.49-146.87 0-123.23-75.87-213.86-75.87-90.63-194.13-111.35v-51.25q138.64 20.72 229.45 125.65Q855.13-625.87 855.13-480q0 87.2-39.32 164.54-39.32 77.33-111.48 136.1h120.26v50.26H614.87z"></path>
      </svg>;
  }
  if (props.size == 'xlg') {
    return <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="#e8eaed" viewBox="0 -960 960 960">
        <path fill={props.fill || '#3EB87B'} d="M422.69-103.54q-67.77-10.92-125.84-43.77-58.08-32.85-100.12-82.46-42.04-49.62-65.73-113.5-23.69-63.88-23.69-136.73 0-86.46 40.54-164.35 40.54-77.88 114.38-138.34H135.62v-45.39h207.07V-621h-45.38v-130.54q-69 51.39-106.81 122.46Q152.69-558 152.69-480q0 125.46 75.77 217.04 75.77 91.57 194.23 113.04v46.38zM423-320L278-465l32-32 113 113 227-227 32 32-259 259zm194.31 188.46v-207.08h45.38v130.54q69-52.38 106.81-123.15Q807.31-402 807.31-480q0-125.46-75.77-217.04-75.77-91.57-194.23-113.04v-46.38Q674.46-835 763.58-730.54 852.69-626.08 852.69-480q0 86.46-40.54 164.54-40.54 78.07-114.38 138.54h126.61v45.38H617.31z"></path>
      </svg>;
  }
  return <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="none" viewBox="0 0 25 25">
      <mask id="mask0_10_682" style={{
      maskType: 'alpha'
    }} width="25" height="25" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0.902 0.141H24.902V24.141H0.902z"></path>
      </mask>
      <g mask="url(#mask0_10_682)">
        <path fill={props.fill || '#3EB87B'} d="M15.039 2.876c2.14.5 3.903 1.602 5.287 3.306 1.384 1.703 2.076 3.69 2.076 5.96 0 1.477-.307 2.849-.922 4.113-.615 1.264-1.495 2.357-2.641 3.28h1.938c.213 0 .391.072.535.216a.726.726 0 01.215.534.726.726 0 01-.215.535.726.726 0 01-.535.215h-3.72a.875.875 0 01-.646-.258.875.875 0 01-.259-.645V16.41c0-.212.072-.39.216-.534a.726.726 0 01.534-.216c.213 0 .391.072.535.216a.726.726 0 01.215.534v2.148c1.039-.788 1.84-1.735 2.404-2.84a7.754 7.754 0 00.846-3.577c0-1.884-.573-3.54-1.719-4.964-1.146-1.425-2.624-2.359-4.433-2.801a.78.78 0 01-.598-.744c0-.257.088-.463.264-.619a.636.636 0 01.623-.137zm-4.273 18.53c-2.141-.5-3.904-1.6-5.288-3.3-1.384-1.701-2.076-3.69-2.076-5.965 0-1.478.308-2.848.922-4.108C4.94 6.772 5.82 5.68 6.966 4.757H5.027a.726.726 0 01-.534-.216.726.726 0 01-.216-.534c0-.213.072-.391.216-.535a.726.726 0 01.534-.215h3.721c.258 0 .473.086.646.258a.876.876 0 01.258.645v3.722a.726.726 0 01-.215.534.726.726 0 01-.535.216.726.726 0 01-.534-.216.726.726 0 01-.216-.534V5.734a7.918 7.918 0 00-2.408 2.823 7.838 7.838 0 00-.842 3.584c0 1.878.572 3.532 1.715 4.96 1.143 1.428 2.622 2.363 4.437 2.806a.779.779 0 01.598.744.786.786 0 01-.263.618.637.637 0 01-.623.138zm.711-5.388a.828.828 0 01-.633-.256l-2.719-2.734a.736.736 0 01-.225-.52.708.708 0 01.225-.534.718.718 0 01.527-.217c.207 0 .382.072.527.217l2.298 2.298 5.148-5.148a.688.688 0 01.523-.215.777.777 0 01.756.757.699.699 0 01-.225.527l-5.569 5.57a.828.828 0 01-.633.255z"></path>
      </g>
    </svg>;
}
export default Icon;