import React, { Component } from 'react';
import { func, object, shape, string } from 'prop-types';
import { getSegments } from 'shared/common.api';
import { UserStateContext } from 'context/user-state-context';
import { Autocomplete, Box, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputAdornment, Paper, Stack, TextField, Tooltip, Typography, Button as MUIButton } from '@mui/material';
import { CustomerIcon, TransactionIcon, VehicleIcon, ShoppingCartIcon, Calendar30DaysIcon } from 'components/mui';
import { Button } from 'components/button/button.component';
export class TriggerSegment extends Component {
  static contextType = UserStateContext;
  static propTypes = {
    update: func,
    node: object,
    data: shape({
      segment_id: string,
      audience: string
    })
  };
  state = {
    segmentList: [],
    segmentInclusion: null,
    segmentNamePlaceholder: 'Choose Static Segment',
    sessionTypeChangeWarning: false,
    suppressWarning: false
  };
  componentDidMount() {
    getSegments({
      company: this.context.asCompany.id,
      time_dynamic_segment: false,
      limit: 600
    }).then(({
      data
    }) => {
      this.setState({
        segmentList: data.results
      });
    });
  }
  toggleSegment = id => {
    const {
      segment_id
    } = this.props.data;
    this.props.update({
      segment_id: !segment_id ? id : segment_id.id !== id ? id : null
    });
  };
  onAudienceChange = e => {
    this.props.update({
      audience: e.target.value
    });
  };
  makeOverflowTooltip = (content, length, bold) => {
    const contentLength = content?.length || content?.props?.children?.length;
    if (content) {
      return <Tooltip title={contentLength > length ? <> {content} </> : ''}>
          <Typography variant={bold ? 'tableHeader' : ''}>
            {contentLength > length ? <>{('' + (content.props?.children || content)).slice(0, length) + '...'}</> : <>{content}</>}
          </Typography>
        </Tooltip>;
    }
    return <Typography variant="nullData" color={'#BEBEBE'}>
        <span>--</span>{' '}
      </Typography>;
  };
  getIcon = included => {
    if (included === 'possession') {
      return <VehicleIcon />;
    } else if (included === 'transaction') {
      return <TransactionIcon />;
    } else if (included === 'schedule') {
      return <Calendar30DaysIcon />;
    } else if (included === 'cart') {
      return <ShoppingCartIcon />;
    } else {
      return <CustomerIcon />;
    }
  };
  toggleSessionTypeChangeWarning = subType => {
    if (subType) {
      this.setState({
        sessionTypeChangeWarning: false
      });
    }
    const existingData = JSON.parse(localStorage.getItem('sessionDataChangeWarning'));
    let sessionData = existingData ? existingData : {};
    if (subType) {
      sessionData = {
        ...sessionData,
        [subType]: true
      };
    }
    if (this.state.suppressWarning) {
      localStorage.setItem('sessionDataChangeWarning', JSON.stringify(sessionData));
    }
  };
  render() {
    const {
      segmentList
    } = this.state;
    const selectedSegment = segmentList.find(segment => segment.id === this.props.data.segment_id);
    const {
      audience
    } = this.props.data;
    const segmentInclusion = selectedSegment ? selectedSegment.included : null;
    const segmentNamePlaceholder = selectedSegment ? selectedSegment.name : 'Choose Static Segment';
    const {
      getIcon
    } = this;
    const {
      sessionTypeChangeWarning
    } = this.state;
    const {
      suppressWarning
    } = this.state;
    return <div>
        {this.state.sessionTypeChangeWarning ? <Dialog sx={{
        '& .MuiDialog-paper': {
          maxWidth: '600px',
          width: '100%',
          borderRadius: '24px',
          padding: '12px'
        }
      }} open={sessionTypeChangeWarning} onClose={() => {
        this.setState({
          sessionTypeChangeWarning: false
        });
        this.setState({
          suppressWarning: false
        });
      }} id="session-type-change-warning">
            <DialogTitle>
              <Stack direction="row" spacing={1}>
                <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
                  {selectedSegment.included.includes('transaction') ? <TransactionIcon /> : selectedSegment.included.includes('possession') ? <VehicleIcon /> : selectedSegment.included.includes('review') ? <ReviewIcon /> : selectedSegment.included.includes('cart') ? <ShoppingCartIcon /> : selectedSegment.included.includes('survey') ? <SurveysIcon /> : selectedSegment.included.includes('schedule') ? <Calendar30DaysIcon /> : <CustomerIcon />}
                </Box>
                <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
                  <Typography>{` ${selectedSegment.included === 'possession' ? 'Vehicle' : selectedSegment.included ? selectedSegment.included.charAt(0).toUpperCase() + selectedSegment.included.slice(1) : 'Customer'} Journey Session`}</Typography>
                </Box>
              </Stack>
            </DialogTitle>
            <DialogContent>
              <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
                <Typography>
                  {`
                  ${selectedSegment.included === 'possession' ? 'Vehicle' : selectedSegment.included ? selectedSegment.included.charAt(0).toUpperCase() + selectedSegment.included.slice(1) : 'Customer'} data is now available for customers who enter the journey through this trigger. You can filter on this specific customer ${selectedSegment.included === 'possession' ? 'Vehicle' : selectedSegment.included ? selectedSegment.included.charAt(0).toUpperCase() + selectedSegment.included.slice(1) : 'Customer'} data in your Check Data Field tiles or use this ${selectedSegment.included === 'possession' ? 'Vehicle' : selectedSegment.included ? selectedSegment.included.charAt(0).toUpperCase() + selectedSegment.included.slice(1) : 'Customer'} information in your communications (email, text messages, or mail).
                    `}
                </Typography>
              </Box>
            </DialogContent>
            <DialogActions>
              <Stack direction="row" spacing={1}>
                <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              pr: '209px'
            }}>
                  <Checkbox checked={suppressWarning} onChange={() => {
                this.setState({
                  suppressWarning: !this.state.suppressWarning
                });
              }} sx={{
                color: '#53A6D6'
              }} />{' '}
                  <Typography sx={{
                cursor: 'default'
              }}>{`Don't show this warning again`}</Typography>
                </Box>
              </Stack>
              <Stack direction="row" spacing={3}>
                <MUIButton variant="contained" sx={{
              width: '100px'
            }} onClick={() => {
              this.toggleSessionTypeChangeWarning('trigger_segment', this.state.suppressWarning);
            }} autoFocus>
                  Okay
                </MUIButton>
              </Stack>
            </DialogActions>
          </Dialog> : <>
            <div className="form-group">
              <Paper sx={{
            width: '107px',
            height: '19px',
            pl: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            position: 'absolute',
            marginTop: '-16px',
            zIndex: 1,
            boxShadow: 'none'
          }}>
                <Typography sx={{
              position: 'absolute',
              marginTop: '10px',
              opacity: 0.5,
              '&.Mui-focused': {
                opacity: 1
              },
              fontSize: '12px'
            }} id="static-segment-select-label">
                  Static Segment
                </Typography>
              </Paper>
              <FormControl variant="outlined" sx={{
            width: '315px'
          }}>
                <Autocomplete id="choose-static-segment-select" options={segmentList} getOptionLabel={option => option.name} value={selectedSegment || null} onChange={(event, newValue) => {
              if (!newValue) {
                this.toggleSegment(null);
                return;
              }
              const segment = segmentList.find(segment => segment.name === newValue.name);
              this.toggleSegment(segment ? segment.id : null);
              const sessionDataChangeWarning = JSON.parse(localStorage.getItem('sessionDataChangeWarning'));
              if (segment.included !== null && !sessionDataChangeWarning?.trigger_segment) this.setState({
                sessionTypeChangeWarning: true
              });
            }} filterOptions={(options, {
              inputValue
            }) => {
              return options.filter(option => option.name.toLowerCase().includes(inputValue.toLowerCase()));
            }} renderInput={params => <TextField {...params} label="" placeholder={selectedSegment ? `${selectedSegment.name}` : 'Choose Static Segment'} InputProps={{
              ...params.InputProps,
              startAdornment: <InputAdornment position="start">{getIcon(selectedSegment?.included)}</InputAdornment>
            }} value={selectedSegment ? selectedSegment.name : ''} />} renderOption={(props, option, {
              index
            }) => <Box component="li" {...props} key={option.name + index} sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px'
            }}>
                      {getIcon(option.included)}
                      {this.makeOverflowTooltip(option.name, 30)}
                    </Box>} />
              </FormControl>
            </div>
            <Stack direction={'column'} spacing={2} sx={{
          pb: '25px',
          pl: '24px',
          opacity: 0.7
        }}>
              <Typography>{'Segment Type: Static'}</Typography>

              <Typography>
                {'Segment Inclusion: '}

                {segmentInclusion === 'possession' ? 'Vehicle' : segmentInclusion ? segmentInclusion.charAt(0).toUpperCase() + segmentInclusion.slice(1) : segmentNamePlaceholder === 'Choose Static Segment' ? 'None' : 'Customer Only'}
              </Typography>
            </Stack>

            <div className="form-group form-group__radio">
              <label>
                <input type="radio" value="now" name="option" onChange={() => {
              this.onAudienceChange({
                target: {
                  value: 'now'
                }
              });
            }} checked={audience === 'now'} />
                <span>Add all from this segment now</span>
              </label>
              <label>
                <input type="radio" value="all" name="option" onChange={this.onAudienceChange} checked={audience === 'all'} />
                <span>Add all contacts on this segment now and that are added in the future</span>
              </label>
              <label>
                <input type="radio" value="future" name="option" onChange={this.onAudienceChange} checked={audience === 'future'} />
                <span>Add only contacts that enter this segment in the future</span>
              </label>
            </div>
          </>}
      </div>;
  }
}