import React from 'react';
function Icon() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" fill="none" viewBox="0 0 21 20">
      <mask id="mask0_24_6424" style={{
      maskType: 'alpha'
    }} width="21" height="20" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0.466 0H20.466V20H0.466z"></path>
      </mask>
      <g mask="url(#mask0_24_6424)">
        <path fill="#1D252D" d="M5.507 10.667a.64.64 0 01-.466-.2.64.64 0 010-.933.64.64 0 01.466-.2h9.917c.177 0 .332.066.466.2a.64.64 0 010 .932.64.64 0 01-.466.2H5.507z"></path>
      </g>
    </svg>;
}
export default Icon;