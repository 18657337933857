import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Field, useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useState } from 'react';

/**
 * This is a component which allows you to use an api (list)
 * call to generate autocomplete options relative to the LIST api call.
 *
 * This component has two independent states
 * `selectedValue` is what the component believes the user has
 * actually selected (on click / enter on an option)
 * `displayValue` is whatever shows up in the box while the user is typing,
 * the displayValue filters the options
 * while the selectedValue is ultimately whatever the user ends up choosing.
 */
export const FormikAutoComplete = ({
  name,
  validate,
  label,
  listResourceRequest,
  resourceRequestParams,
  onChange,
  optionLabel
}) => {
  const [initialOptions, setIntialOptions] = useState();
  const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);
  const [displayValue, setDisplayValue] = useState('');
  const {
    initialValues,
    setFieldValue
  } = useFormikContext();
  const retrieveOptionsFromApi = async searchFilter => {
    const params = {
      ...resourceRequestParams
    };
    if (searchFilter) {
      params.search = searchFilter;
    }
    const response = await listResourceRequest(params);
    const results = response.data.results;
    const newOptions = [];
    for (const record of results) {
      newOptions.push({
        label: record[optionLabel || 'name'] ? record[optionLabel || 'name'] : 'Unnamed',
        id: record.id
      });
    }
    return newOptions;
  };

  // TODO consolidate the repeat code here...
  useEffect(() => {
    const getOptions = async () => {
      const newOptions = await retrieveOptionsFromApi();
      setAutoCompleteOptions(newOptions);
      setIntialOptions(newOptions);
    };
    getOptions();
  }, []);
  useEffect(() => {
    const keyExists = initialValues.hasOwnProperty(name);
    const startingValue = initialValues[name];
    if (!keyExists) {
      console.warn('FormikAutoComplete is misconfigured, you must provide the correct name prop');
    } else {
      if (startingValue && initialOptions) {
        for (const option of initialOptions) {
          if (option.id === startingValue) {
            setSelectedValue(option);
            setDisplayValue(option.label);
          }
        }
      }
    }
  }, [initialOptions]);
  const queryForNewOptions = async inputValue => {
    setDisplayValue(inputValue);
    const newOptions = await retrieveOptionsFromApi(inputValue);
    setAutoCompleteOptions(newOptions);
  };
  const changeSelectedValue = async value => {
    setSelectedValue(value);

    // MUI nulls out the value when there is an empty
    // string in the display value
    let formikValue = undefined;
    if (!value) {
      formikValue = value;
    } else {
      formikValue = value.id;
    }
    await setFieldValue(name, formikValue, true);
    if (onChange) {
      onChange(value);
    }
  };
  return <Field name={name} validate={validate}>
      {({
      field,
      // { name, value, onChange, onBlur }
      form,
      // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
      meta,
      isSubmitting
    }) => <Autocomplete disablePortal id="combo-box-demo" options={autoCompleteOptions} value={selectedValue} onChange={async (event, value, reason, details) => {
      await changeSelectedValue(value);
    }} inputValue={displayValue} onInputChange={async (event, value, reason) => {
      await queryForNewOptions(value);
    }} includeInputInList={true} getOptionLabel={option => {
      return option.label;
    }} disabled={isSubmitting} renderInput={params => <TextField {...params} label={label} error={meta.touched && meta.error ? true : false} helperText={meta.touched && meta.error ? meta.error : ''} />} isOptionEqualToValue={(option, value) => {
      return option.id === value.id;
    }} />}
    </Field>;
};