import React from 'react';
function Icon() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <mask id="mask0_22_5215" style={{
      maskType: 'alpha'
    }} width="24" height="24" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0 24H24V48H0z" transform="rotate(-90 0 24)"></path>
      </mask>
      <g mask="url(#mask0_22_5215)">
        <path fill="#1C1B1F" d="M15.282 11.994a1.1 1.1 0 01-.063.375.872.872 0 01-.212.325l-4.625 4.625a.891.891 0 01-.688.262.975.975 0 01-.687-.287.948.948 0 01-.275-.7c0-.284.091-.517.275-.7l3.9-3.9-3.925-3.925a.894.894 0 01-.263-.688.978.978 0 01.288-.687.948.948 0 01.7-.275c.283 0 .516.091.7.275l4.6 4.6c.1.1.17.208.212.325a1.1 1.1 0 01.063.375z"></path>
      </g>
    </svg>;
}
export default Icon;