import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import { Scoped, a } from 'kremling';
import { func, bool, string, oneOf } from 'prop-types';
import { Transition } from 'react-spring/renderprops';
import styles from './drawer.styles.scss';
import { Button } from '../button/button.component';
import { Box, IconButton, Paper } from '@mui/material';
import { CloseIcon } from 'components/mui';
export class Drawer extends Component {
  static propTypes = {
    open: bool,
    title: string,
    allowBackdropClick: bool,
    onClose: func,
    size: oneOf(['sm', 'md', 'lg', 'xl']),
    type: oneOf(['dialog', 'scroll', 'overlay']),
    closeBtn: bool
  };
  static defaultProps = {
    closeBtn: true,
    size: 'md',
    type: 'dialog'
  };
  render() {
    const {
      allowBackdropClick,
      children,
      open,
      title,
      onClose,
      size,
      closeBtn,
      type
    } = this.props;
    return createPortal(<Scoped css={styles}>
        <div>
          <>
            <Transition items={open} from={{
            opacity: 0
          }} enter={{
            opacity: 1
          }} leave={{
            opacity: 0
          }}>
              {open => open && (props => <div className="drawer-backdrop" style={props} />)}
            </Transition>
            <Transition items={open} from={{
            opacity: 0,
            transform: `translateX(10rem)`
          }} enter={{
            opacity: 1,
            transform: `translateX(0)`
          }} leave={{
            opacity: 0,
            transform: `translateX(10rem)`
          }} config={{
            mass: 0.5,
            tension: 120,
            friction: 10
          }}>
              {open => open && (props => <div style={props} className={a('drawer').m('drawer--scroll', type === 'scroll').m('drawer--overlay', type === 'overlay')} onMouseDown={allowBackdropClick ? onClose : null}>
                    <Box sx={{
                width: '712px',
                px: '26px',
                py: '16px'
              }}>
                      <Paper sx={{
                  height: '100%',
                  borderRadius: '28px'
                }} onMouseDown={e => e.stopPropagation()}>
                        {closeBtn && <IconButton className="drawer__head-close" onClick={onClose}>
                            <CloseIcon />
                          </IconButton>}
                        {title && <div className="drawer__title">{title}</div>}

                        {children}
                      </Paper>
                    </Box>
                  </div>)}
            </Transition>
          </>
        </div>
      </Scoped>, document.body);
  }
}