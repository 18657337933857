import React from 'react';
import { Button } from '../../components/button/button.component';
import { Dropdown } from '../../components/dropdown/dropdown.component';
import moment from 'moment-timezone';
const TimeSelector = props => {
  // props time
  // props set time?
  const selectedTime = !!props.value ? moment(props.value, 'HH:mm:ss') : null;
  const updateHour = hour => {
    const tempTime = selectedTime || moment();
    let manip = tempTime.format('A') === 'PM' ? 12 : 0;
    props.onChange(tempTime.hour(hour + manip).format('HH:mm:ss'));
  };
  const updateMinute = minute => {
    const tempTime = selectedTime || moment();
    props.onChange(tempTime.minute(minute).format('HH:mm:ss'));
  };
  const updateAMPM = ampm => {
    const tempTime = selectedTime || moment();
    let manip = ampm !== tempTime.format('A') ? 12 : 0;
    props.onChange(tempTime.hour(tempTime.hour() + manip).format('HH:mm:ss'));
  };
  return <div className={"d-flex " + props.className}>
      <Dropdown className="w-100" size="block" contentHeight={280} disabled={props.disabled} trigger={() => <Button block dropdown disabled={props.disabled}>
            {selectedTime ? selectedTime.format('h') : null}
          </Button>} content={({
      close
    }) => <ul className="select-list">
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(hour => <li key={hour}>
                <a onClick={() => {
          updateHour(hour);
          close();
        }}>
                  {hour}
                </a>
              </li>)}
          </ul>} />
      <div className="p-2">:</div>
      <Dropdown size="block" contentHeight={280} disabled={props.disabled} trigger={() => <Button block dropdown disabled={props.disabled}>
            {selectedTime ? selectedTime.format('mm') : null}
          </Button>} content={({
      close
    }) => <ul className="select-list">
            {['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59'].map(minute => <li key={minute}>
                <a onClick={() => {
          updateMinute(minute);
          close();
        }}>
                  {minute}
                </a>
              </li>)}
          </ul>} />
      <div className="p-2">:</div>
      <Dropdown className="w-100" size="block" contentHeight={280} disabled={props.disabled} trigger={() => <Button block dropdown disabled={props.disabled}>
            {selectedTime ? selectedTime.format('A') : null}
          </Button>} content={({
      close
    }) => <ul className="select-list">
            {['AM', 'PM'].map(ampm => <li key={ampm}>
                <a onClick={() => {
          updateAMPM(ampm);
          close();
        }}>
                  {ampm}
                </a>
              </li>)}
          </ul>} />
    </div>;
};
export default TimeSelector;