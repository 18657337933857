import React from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
export const Alert = ({
  open,
  onClose,
  title,
  message,
  confirmText
}) => {
  return <Dialog PaperProps={{
    style: {
      borderRadius: '28px',
      minWidth: '350px'
    }
  }} open={open} onClose={onClose}>
    <DialogTitle sx={{
      m: 0,
      p: 2,
      fontSize: 'medium'
    }}>
      <IconButton aria-label="close" onClick={onClose} sx={{
        position: 'absolute',
        right: 8,
        top: 8,
        color: theme => theme.palette.grey[500]
      }}>
        <CloseIcon />
      </IconButton>
      <Stack alignItems="center" component="span">
        <Typography variant="h4" component="span">
          {title}
        </Typography>
      </Stack>
    </DialogTitle>
    <DialogContent>
      <Box component="span">
        {message}
      </Box>
    </DialogContent>
    <DialogActions sx={{
      py: 1
    }}>
      <Button onClick={() => onClose()} variant="text" sx={{
        mr: 1,
        borderRadius: '20px',
        width: '75px',
        color: '#53A6D6',
        textTransform: 'none'
      }}>
        {confirmText || 'Ok'}
      </Button>
    </DialogActions>
  </Dialog>;
};