import React from 'react';
function Icon() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="none" viewBox="0 0 25 25">
      <mask id="mask0_85_5649" style={{
      maskType: 'alpha'
    }} width="25" height="25" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M24.998 0.001H48.998000000000005V24.001H24.998z" transform="rotate(90 24.998 .001)"></path>
      </mask>
      <g mask="url(#mask0_85_5649)">
        <path fill="#1D252D" fillOpacity="0.7" d="M5.499 4.301c0-.5.175-.925.525-1.275.35-.35.775-.525 1.275-.525h11.4c.5 0 .924.175 1.274.525.35.35.526.775.526 1.275v15.4c0 .5-.175.925-.525 1.275-.35.35-.776.525-1.276.525h-11.4c-.5 0-.924-.175-1.274-.525a1.736 1.736 0 01-.525-1.275v-15.4zm8.25-.3v4.325h5.25V4.301a.287.287 0 00-.088-.212.287.287 0 00-.213-.088h-4.95zm0 5.825v4.35h5.25v-4.35h-5.25zm0 5.85v4.325h4.95a.288.288 0 00.211-.087.29.29 0 00.088-.213v-4.025h-5.25zm-6.75-7.35h5.25V4.001h-4.95a.29.29 0 00-.213.088.289.289 0 00-.087.212v4.025zm0 1.5v4.35h5.25v-4.35h-5.25zm0 5.85v4.025c0 .084.029.155.087.213.058.058.13.087.213.087h4.95v-4.325h-5.25z"></path>
      </g>
    </svg>;
}
export default Icon;