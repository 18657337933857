import React from 'react';
function Icon(props) {
  if (props.size == 'lg') {
    return <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill={props.fill || '#1D252D'} viewBox="0 -960 960 960">
        <path d="M313.23-526.1q14.69 0 25.04-10.35t10.35-25.04q0-14.69-10.35-25.04-10.35-10.34-25.04-10.34-14.95 0-25.17 10.34-10.21 10.35-10.21 25.04t10.21 25.04q10.22 10.35 25.17 10.35zm168.26 0q14.69 0 25.04-10.35 10.34-10.35 10.34-25.04t-10.34-25.04q-10.35-10.34-25.04-10.34t-25.04 10.34q-10.35 10.35-10.35 25.04t10.35 25.04q10.35 10.35 25.04 10.35zm164.87 0q14.69 0 25.04-10.35 10.34-10.35 10.34-25.04t-10.34-25.04q-10.35-10.34-25.04-10.34t-25.04 10.34q-10.35 10.35-10.35 25.04t10.35 25.04q10.35 10.35 25.04 10.35zM100-118.46v-678.97q0-25.79 18.39-44.18T162.57-860h634.86q25.79 0 44.18 18.39T860-797.43v474.86q0 25.79-18.39 44.18T797.43-260H241.54L100-118.46zm119.8-191.8h577.63q4.62 0 8.47-3.84 3.84-3.85 3.84-8.47v-474.86q0-4.62-3.84-8.47-3.85-3.84-8.47-3.84H162.57q-4.62 0-8.47 3.84-3.84 3.85-3.84 8.47v559.48l69.54-72.31zm-69.54 0v-499.48 499.48z"></path>
      </svg>;
  }
  return <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" fill="none" viewBox="0 0 25 24">
      <mask id="mask0_8_84" style={{
      maskType: 'alpha'
    }} width="25" height="24" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0.495 0H24.495V24H0.495z"></path>
      </mask>
      <g mask="url(#mask0_8_84)">
        <path fill={props.fill || '#1D252D'} d="M8.495 11.115a.852.852 0 00.626-.258.852.852 0 00.258-.626.853.853 0 00-.258-.626.853.853 0 00-.626-.259.853.853 0 00-.626.259.853.853 0 00-.259.626c0 .245.086.453.259.626a.852.852 0 00.626.258zm4 0a.852.852 0 00.626-.258.852.852 0 00.258-.626.852.852 0 00-.258-.626.852.852 0 00-.626-.259.853.853 0 00-.626.259.853.853 0 00-.259.626c0 .245.086.453.259.626a.853.853 0 00.626.258zm4 0a.852.852 0 00.626-.258.852.852 0 00.258-.626.852.852 0 00-.258-.626.852.852 0 00-.626-.259.853.853 0 00-.626.259.853.853 0 00-.259.626c0 .245.086.453.259.626a.853.853 0 00.626.258zM2.995 21.27V4.54c0-.506.175-.933.525-1.283.35-.35.777-.525 1.282-.525h15.385c.505 0 .933.175 1.283.525.35.35.525.777.525 1.283v11.384c0 .505-.175.933-.525 1.283-.35.35-.778.525-1.283.525H6.533l-3.538 3.538zm1.5-3.623l1.415-1.415h14.277a.3.3 0 00.221-.087.3.3 0 00.087-.22V4.538a.3.3 0 00-.087-.222.3.3 0 00-.221-.086H4.802a.3.3 0 00-.22.086.3.3 0 00-.087.222v13.107zm0-13.107V4.23v13.415V4.54z"></path>
      </g>
    </svg>;
}
export default Icon;