import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Chip from '@mui/material/Chip';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Typography from '@mui/material/Typography';
import { AntSwitch } from '../switch/ant-switch.component';
export const ToggleFilter = props => {
  const {
    showChip,
    filterKey,
    filters,
    disabled,
    chipStyle,
    label,
    handleFilterChange,
    onVal,
    offVal,
    defaultVal
  } = props;
  if (showChip) {
    if (!filters[filterKey]) {
      return null;
    }
    return <Chip sx={{
      margin: '8px',
      backgroundColor: '#DAEFFF',
      color: '#53A6D6',
      borderRadius: '8px',
      textTransform: 'none'
    }} label=<span>
          {label}: {filters[filterKey]}
        </span> onDelete={() => {
      const {
        [filterKey]: _,
        ...newFilters
      } = filters || {};
      handleFilterChange(newFilters);
    }} className={chipStyle} deleteIcon={<CloseOutlinedIcon sx={{
      transform: 'scale(0.75)'
    }} />} />;
  }
  const vals = {
    on: onVal || 'true',
    off: offVal || 'false'
  };
  const isOn = filters[filterKey] !== undefined && filters[filterKey] !== null && filters[filterKey] === vals['on'] || defaultVal === 'on';
  return <FormControl>
      <FormLabel>
        <Typography variant="tableHeader">{label}</Typography>
      </FormLabel>
      <AntSwitch sx={{
      ml: '8px',
      colorPrimary: '#53A6D6'
    }} disabled={disabled} checked={isOn} onChange={() => {
      const newVal = !isOn ? 'on' : 'off';
      if (newVal === defaultVal) {
        let {
          [filterKey]: _,
          ...newFilters
        } = filters || {};
        handleFilterChange(newFilters);
      } else {
        handleFilterChange({
          ...(filters || {}),
          [filterKey]: vals[newVal]
        });
      }
    }} inputProps={{
      'aria-label': 'ant design'
    }} />
    </FormControl>;
};