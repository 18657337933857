import React, { Component } from 'react';
import { func, object, shape, string } from 'prop-types';
import { DateTime } from 'luxon';
import { getSegments } from 'shared/common.api';
import { UserStateContext } from 'context/user-state-context';
import { Dropdown } from 'components/dropdown/dropdown.component';
import { Button } from 'components/button/button.component';
import { Calendar } from 'components/calendar/calendar.component';
import { timeSlots, getTime, timeFilter } from './trigger-recurring/trigger-recurring.utils';
import { DynamicTypeAhead } from '../../../../components/dynamic-type-ahead/dynamic-type-ahead.component';
import { Autocomplete, Box, FormControl, InputAdornment, Paper, Stack, TextField, Tooltip, Typography, Button as MUIButton, Dialog, Checkbox, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { CustomerIcon, TransactionIcon, VehicleIcon, ShoppingCartIcon, Calendar30DaysIcon } from 'components/mui';
export class TriggerTime extends Component {
  static contextType = UserStateContext;
  static propTypes = {
    update: func,
    node: object,
    data: shape({
      segment_id: string,
      datetime: string
    })
  };
  state = {
    segmentList: [],
    segmentInclusion: null,
    segmentNamePlaceholder: 'Choose Segment',
    sessionTypeChangeWarning: false,
    suppressWarning: false
  };
  componentDidMount() {
    getSegments({
      company: this.context.asCompany.id,
      limit: 600
    }).then(({
      data
    }) => {
      this.setState({
        segmentList: data.results
      });
    });
  }
  toggleSegment = id => {
    const {
      segment
    } = this.props.data;
    this.props.update({
      segment_id: !segment ? id : segment.id !== id ? id : null
    });
  };
  update = dateTime => {
    this.props.update({
      datetime: dateTime.set({
        second: 0,
        millisecond: 0
      }).toUTC().toISO()
    });
  };
  updateDate = date => {
    let dateTime = DateTime.fromJSDate(date);
    if (!this.props.data.datetime) {
      const timeNow = new Date();
      const hourNow = timeNow.getHours();
      const minuteNow = timeNow.getMinutes();
      dateTime = dateTime.set({
        hour: hourNow,
        minute: minuteNow
      });
    } else {
      const oldDate = DateTime.fromISO(this.props.data.datetime);
      dateTime = dateTime.set({
        hour: oldDate.hour,
        minute: oldDate.minute
      });
    }
    this.update(dateTime);
  };
  updateTime = (hour, minute) => {
    const newDate = DateTime.fromISO(this.props.data.datetime).set({
      hour,
      minute
    });
    this.update(newDate);
  };
  makeOverflowTooltip = (content, length, bold) => {
    const contentLength = content?.length || content?.props?.children?.length;
    if (content) {
      return <Tooltip title={contentLength > length ? <> {content} </> : ''}>
          <Typography variant={bold ? 'tableHeader' : ''}>
            {contentLength > length ? <>{('' + (content.props?.children || content)).slice(0, length) + '...'}</> : <>{content}</>}
          </Typography>
        </Tooltip>;
    }
    return <Typography variant="nullData" color={'#BEBEBE'}>
        <span>--</span>{' '}
      </Typography>;
  };
  getIcon = included => {
    if (included === 'possession') {
      return <VehicleIcon />;
    } else if (included === 'transaction') {
      return <TransactionIcon />;
    } else if (included === 'schedule') {
      return <Calendar30DaysIcon />;
    } else if (included === 'cart') {
      return <ShoppingCartIcon />;
    } else {
      return <CustomerIcon />;
    }
  };
  toggleSessionTypeChangeWarning = subType => {
    if (subType) {
      this.setState({
        sessionTypeChangeWarning: false
      });
    }
    const existingData = JSON.parse(localStorage.getItem('sessionDataChangeWarning'));
    let sessionData = existingData ? existingData : {};
    if (subType) {
      sessionData = {
        ...sessionData,
        [subType]: true
      };
    }
    if (this.state.suppressWarning) {
      localStorage.setItem('sessionDataChangeWarning', JSON.stringify(sessionData));
    }
  };
  render() {
    const {
      datetime
    } = this.props.data;
    const {
      segmentList
    } = this.state;
    const selectedSegment = segmentList.find(segment => segment.id === this.props.data.segment_id);
    const currentDate = datetime && DateTime.fromISO(datetime);
    const segmentInclusion = selectedSegment ? selectedSegment.included : null;
    const segmentNamePlaceholder = selectedSegment ? selectedSegment.name : 'Choose Segment';
    const {
      getIcon
    } = this;
    const {
      sessionTypeChangeWarning
    } = this.state;
    const {
      suppressWarning
    } = this.state;
    return <div>
        {this.state.sessionTypeChangeWarning ? <Dialog sx={{
        '& .MuiDialog-paper': {
          maxWidth: '600px',
          width: '100%',
          borderRadius: '24px',
          padding: '12px'
        }
      }} open={sessionTypeChangeWarning} onClose={() => {
        this.setState({
          sessionTypeChangeWarning: false
        });
        this.setState({
          suppressWarning: false
        });
      }} id="session-type-change-warning">
            <DialogTitle>
              <Stack direction="row" spacing={1}>
                <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
                  {selectedSegment.included.includes('transaction') ? <TransactionIcon /> : selectedSegment.included.includes('possession') ? <VehicleIcon /> : selectedSegment.included.includes('review') ? <ReviewIcon /> : selectedSegment.included.includes('cart') ? <ShoppingCartIcon /> : selectedSegment.included.includes('survey') ? <SurveysIcon /> : selectedSegment.included.includes('schedule') ? <Calendar30DaysIcon /> : <CustomerIcon />}
                </Box>
                <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
                  <Typography>{` ${selectedSegment.included === 'possession' ? 'Vehicle' : selectedSegment.included ? selectedSegment.included.charAt(0).toUpperCase() + selectedSegment.included.slice(1) : 'Customer'} Journey Session`}</Typography>
                </Box>
              </Stack>
            </DialogTitle>
            <DialogContent>
              <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
                <Typography>
                  {`
                  ${selectedSegment.included === 'possession' ? 'Vehicle' : selectedSegment.included ? selectedSegment.included.charAt(0).toUpperCase() + selectedSegment.included.slice(1) : 'Customer'} data is now available for customers who enter the journey through this trigger. You can filter on this specific customer ${selectedSegment.included === 'possession' ? 'Vehicle' : selectedSegment.included ? selectedSegment.included.charAt(0).toUpperCase() + selectedSegment.included.slice(1) : 'Customer'} data in your Check Data Field tiles or use this ${selectedSegment.included === 'possession' ? 'Vehicle' : selectedSegment.included ? selectedSegment.included.charAt(0).toUpperCase() + selectedSegment.included.slice(1) : 'Customer'} information in your communications (email, text messages, or mail).
                    `}
                </Typography>
              </Box>
            </DialogContent>
            <DialogActions>
              <Stack direction="row" spacing={1}>
                <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              pr: '209px'
            }}>
                  <Checkbox checked={suppressWarning} onChange={() => {
                this.setState({
                  suppressWarning: !this.state.suppressWarning
                });
              }} sx={{
                color: '#53A6D6'
              }} />{' '}
                  <Typography sx={{
                cursor: 'default'
              }}>{`Don't show this warning again`}</Typography>
                </Box>
              </Stack>
              <Stack direction="row" spacing={3}>
                <MUIButton variant="contained" sx={{
              width: '100px'
            }} onClick={() => {
              this.toggleSessionTypeChangeWarning('trigger_time', this.state.suppressWarning);
            }} autoFocus>
                  Okay
                </MUIButton>
              </Stack>
            </DialogActions>
          </Dialog> : <>
            <div className="form-group">
              <Paper sx={{
            width: selectedSegment?.time_dynamic_segment ? '128px' : '107px',
            height: '19px',
            pl: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            position: 'absolute',
            marginTop: '-16px',
            zIndex: 1,
            boxShadow: 'none'
          }}>
                <Typography sx={{
              position: 'absolute',
              marginTop: '10px',
              opacity: 0.5,
              '&.Mui-focused': {
                opacity: 1
              },
              fontSize: '12px'
            }} id="segment-select-label">
                  {selectedSegment?.time_dynamic_segment ? 'Dynamic ' : 'Static '}Segment
                </Typography>
              </Paper>
              <FormControl variant="outlined" sx={{
            width: '315px'
          }}>
                <Autocomplete id="choose-segment-select" options={segmentList} getOptionLabel={option => option.name} value={selectedSegment || null} onChange={(event, newValue) => {
              if (!newValue) {
                this.toggleSegment(null);
                return;
              }
              const segment = segmentList.find(segment => segment.name === newValue.name);
              this.toggleSegment(segment ? segment.id : null);
              const sessionDataChangeWarning = JSON.parse(localStorage.getItem('sessionDataChangeWarning'));
              if (segment.included !== null && !sessionDataChangeWarning?.trigger_time) this.setState({
                sessionTypeChangeWarning: true
              });
            }} filterOptions={(options, {
              inputValue
            }) => {
              return options.filter(option => option.name.toLowerCase().includes(inputValue.toLowerCase()));
            }} renderInput={params => <TextField {...params} label="" placeholder={selectedSegment ? `${selectedSegment.name}` : 'Choose Segment'} InputProps={{
              ...params.InputProps,
              startAdornment: <InputAdornment position="start">{getIcon(selectedSegment?.included)}</InputAdornment>
            }} value={selectedSegment ? selectedSegment.name : ''} />} renderOption={(props, option, {
              index
            }) => <Box component="li" {...props} key={option.name + index} sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px'
            }}>
                      {getIcon(option.included)}
                      {this.makeOverflowTooltip(option.name, 30)}
                    </Box>} />
              </FormControl>
            </div>
            <Stack direction={'column'} spacing={2} sx={{
          pb: '25px',
          pl: '24px',
          opacity: 0.7
        }}>
              <Typography>Segment Type: {selectedSegment?.time_dynamic_segment ? 'Dynamic' : 'Static'}</Typography>

              <Typography>
                {'Segment Inclusion: '}

                {segmentInclusion === 'possession' ? 'Vehicle' : segmentInclusion ? segmentInclusion.charAt(0).toUpperCase() + segmentInclusion.slice(1) : segmentNamePlaceholder === 'Choose Segment' ? 'None' : 'Customer Only'}
              </Typography>
            </Stack>
            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label>Select Date</label>
                  <Dropdown size="md" allowContentClicks trigger={() => <Button dropdown block>
                        {currentDate ? currentDate.toFormat('LL/dd/yy') : 'Select Date'}
                      </Button>} content={({
                close
              }) => <div className="p-sm">
                        <Calendar value={currentDate ? currentDate.toJSDate() : null} onChange={date => {
                  this.updateDate(date);
                  close();
                }} />
                      </div>} />
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label>Select Time</label>
                  <Dropdown size="block" contentHeight={280} trigger={() => <Button block dropdown disabled={!currentDate}>
                        {currentDate ? getTime(currentDate.hour, currentDate.minute) : null}
                      </Button>} content={() => <ul className="select-list">
                        {timeSlots.map(slot => {
                  return timeFilter(slot, currentDate && currentDate.toJSDate()) && <li key={slot.time}>
                                <a onClick={() => this.updateTime(slot.hour, slot.minute)}>{slot.time}</a>
                              </li>;
                })}
                      </ul>} />
                </div>
              </div>
            </div>
          </>}
      </div>;
  }
}