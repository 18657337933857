import React, { useState, useEffect } from 'react';
import { Select, MenuItem, Checkbox, ListItemText, FormControl, InputLabel, OutlinedInput, FormHelperText, Input } from '@mui/material';
const DEFAULT = '';
const HOURLY = '0 * * * *';
const DAILY = '0 0 * * *';
export const Cron = ({
  value,
  onChange
}) => {
  const [dropdownOption, setDropdownOption] = useState(() => {
    if (!value) {
      return 'DEFAULT';
    } else if (value === HOURLY) {
      return 'HOURLY';
    } else if (props.value === DAILY) {
      return 'DAILY';
    }
    return 'CUSTOM';
  });
  const dropChange = e => {
    if (e === 'DEFAULT') {
      onChange(DEFAULT);
    } else if (e === 'HOURLY') {
      onChange(HOURLY);
    } else if (e === 'DAILY') {
      onChange(DAILY);
    }
    setDropdownOption(e);
  };
  return <div>
    <select onChange={e => dropChange(e.target.value)} className="form-control" value={dropdownOption}>
      <option value="DEFAULT">Default</option>
      <option value="HOURLY">Hourly</option>
      <option value="DAILY">Daily</option>
      <option value="CUSTOM">Custom</option>
    </select>

    {dropdownOption === 'CUSTOM' && <input onChange={e => onChange(e.target.value)} className="form-control mt-2" value={value || '0 * * * *'} />}

  </div>;
};