import React, { Component } from 'react';
import { bool, func, oneOf, string, node, oneOfType } from 'prop-types';
import classnames from 'classnames';
import { Modal } from './modal.component';
import { Button } from '../button/button.component';
import { Loader } from '../loader/loader.component';
export class AsyncModalDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saving: false
    };
  }
  static propTypes = {
    open: bool,
    onClose: func,
    allowBackdropClick: bool,
    title: string,
    size: oneOf(['sm', 'md', 'lg', 'xl']),
    scroll: bool,
    onSubmit: func,
    submitText: oneOfType([string, node]),
    cancelText: oneOfType([string, node]),
    children: node,
    actionType: oneOf(['primary', 'flat', 'grey', 'warning', 'flat-light', 'white', 'danger']),
    stickyFooter: bool,
    submitDisabled: bool
  };
  static defaultProps = {
    actionType: 'primary'
  };
  onSubmit = event => {
    event.stopPropagation();
    event.preventDefault();
    this.setState({
      saving: true
    });
    this.props.onSubmit().then(() => this.props.onClose()).catch(() => {}).then(() => this.setState({
      saving: false
    }));
  };
  render() {
    const {
      open,
      onClose,
      allowBackdropClick,
      title,
      size,
      type,
      onSubmit,
      submitText,
      cancelText,
      children,
      actionType,
      stickyFooter
    } = this.props;
    return <div>
        <Modal open={open} onClose={onClose} allowBackdropClick={allowBackdropClick} title={title} size={size} type={type}>
          <form onSubmit={this.onSubmit}>
            <div className="modal__body">{children}</div>
            <div className={stickyFooter ? 'modal__actions modal__sticky' : 'modal__actions'}>
              <Button type="button" onClick={onClose}>
                {cancelText || 'Cancel'}
              </Button>
              {onSubmit && <Button actionType={actionType} disabled={this.state.saving || this.props.submitDisabled} type="submit">
                  <div className={classnames('d-inline-block', {
                'mr-3': this.state.saving
              })}>
                    {submitText || 'Submit'}
                  </div>
                  {this.state.saving && <Loader size="sm" />}
                </Button>}
            </div>
          </form>
        </Modal>
      </div>;
  }
}