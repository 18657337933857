import React, { useState, useEffect, useContext } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { PageHeaderMui } from 'components';
import { RepeatTransactionCalculatorIcon } from 'components/mui';
import { UserStateContext } from 'context/user-state-context';
import { createMotorOemConfig, getMotorOemConfigDetails, getMotorOemConfigs, getMotorOemRecommendations } from 'shared/common.api';
import { MotorOemRecommendations } from './recommendations.component';
import { MotorOemRecommendationsConfig } from './config.component';

// TODO rename to main or something so it's less confusing
/*
 * Contains the entire flow for the recommendation -> configuration cycle
 */
export const MotorOemRecommendationConfiguration = ({
  history,
  location,
  match,
  staticContext
}) => {
  const {
    asCompany
  } = useContext(UserStateContext);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [recommendations, setRecommendations] = useState();
  const [configurationDetails, setConfigurationDetails] = useState({});
  const [configuration, setConfiguration] = useState();

  // TODO add ordering fields
  useEffect(() => {
    const initializeConfiguration = async () => {
      const configResponse = await getMotorOemConfigs({
        company: asCompany.id
      });
      let existingConfig = null;
      if (configResponse.data.results.length >= 1) {
        existingConfig = configResponse.data.results[0];
      } else {
        const createConfigResponse = await createMotorOemConfig({
          company: asCompany.id
        });
        existingConfig = createConfigResponse.data;
      }
      setConfiguration(existingConfig);
      const configDetailsResponse = await getMotorOemConfigDetails({
        company: asCompany.id,
        config: existingConfig.id
      });
      setConfigurationDetails(formatConfigurationDetails(configDetailsResponse.data.results));
    };
    const retrieveRecommendations = async () => {
      // TODO just grab all, prevent pagination possibly?
      const response = await getMotorOemRecommendations({
        limit: 999
      });
      setRecommendations(response.data.results);
    };
    const initialize = async () => {
      await initializeConfiguration();
      await retrieveRecommendations();
      setLoading(false);
    };
    initialize();
  }, [asCompany.id]);

  /**
   * Anytime we grab configuration details we want to
   * throw it into this format as a lookup table to know
   * if we're interacting with previously setup recommendations
   * and to know whether or not we have an id to know if we've
   * saved the config or not.
   */
  const formatConfigurationDetails = configurations => {
    const formattedConfigurations = {};
    for (const config of configurations) {
      formattedConfigurations[config.recommendation.id] = {
        id: config.id,
        rank: config.rank,
        title: config.title,
        description: config.description,
        url: config.url ? config.url : '',
        config: config.config,
        name: config.recommendation.full_name
      };
    }
    return formattedConfigurations;
  };
  return <>
      <CssBaseline />
      <div className="wrapper">
        {/* TODO what icon do we want to use here? */}
        <PageHeaderMui type="Motor OEM Recommendations" icon={<RepeatTransactionCalculatorIcon size="lg" />} />
        {!loading && <>
            {page === 1 && <MotorOemRecommendations configuration={configuration} recommendations={recommendations} configurationDetails={configurationDetails} setConfigurationDetails={setConfigurationDetails} setPage={setPage} />}
            {page === 2 && <MotorOemRecommendationsConfig configuration={configuration} configurationDetails={configurationDetails} setConfigurationDetails={setConfigurationDetails} setPage={setPage} />}
          </>}
      </div>
    </>;
};