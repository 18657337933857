import React from 'react';
function Icon(props) {
  if (props.size == 'lg') {
    return <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 -960 960 960">
        <path fill={props.fill || '#1D252D'} d="M312.591-292.206q12.742 0 21.614-8.898 8.872-8.899 8.872-21.641 0-12.742-8.899-21.614t-21.641-8.872q-12.742 0-21.614 8.899-8.871 8.899-8.871 21.641 0 12.742 8.898 21.613 8.899 8.872 21.641 8.872zm0-157.282q12.742 0 21.614-8.898 8.872-8.899 8.872-21.641 0-12.742-8.899-21.614-8.899-8.871-21.641-8.871-12.742 0-21.614 8.898-8.871 8.899-8.871 21.641 0 12.742 8.898 21.614 8.899 8.871 21.641 8.871zm0-157.281q12.742 0 21.614-8.899t8.872-21.641q0-12.742-8.899-21.613-8.899-8.872-21.641-8.872-12.742 0-21.614 8.898-8.871 8.899-8.871 21.641 0 12.742 8.898 21.614 8.899 8.872 21.641 8.872zm127.461 309.178H669.64v-50.255H440.052v50.255zm0-157.282H669.64v-50.254H440.052v50.254zm0-157.281H669.64v-50.255H440.052v50.255zM202.565-140.001q-25.788 0-44.176-18.388t-18.388-44.176v-554.87q0-25.788 18.388-44.176t44.176-18.388h554.87q25.788 0 44.176 18.388t18.388 44.176v554.87q0 25.788-18.388 44.176t-44.176 18.388h-554.87zm0-50.255h554.87q4.616 0 8.462-3.847 3.847-3.846 3.847-8.462v-554.87q0-4.616-3.847-8.462-3.846-3.847-8.462-3.847h-554.87q-4.616 0-8.462 3.847-3.847 3.846-3.847 8.462v554.87q0 4.616 3.847 8.462 3.846 3.847 8.462 3.847zm-12.309-579.488v579.488-579.488z"></path>
      </svg>;
  }
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 -960 960 960">
      <path fill={props.fill || '#1D252D'} d="M322.335-289.231q15.05 0 25.203-10.181 10.154-10.181 10.154-25.231 0-15.05-10.181-25.203Q337.331-360 322.281-360t-25.204 10.181q-10.153 10.18-10.153 25.23t10.18 25.204q10.181 10.154 25.231 10.154zm0-155.385q15.05 0 25.203-10.18 10.154-10.181 10.154-25.231 0-15.05-10.181-25.204-10.18-10.153-25.23-10.153t-25.204 10.18q-10.153 10.181-10.153 25.231 0 15.05 10.18 25.204 10.181 10.153 25.231 10.153zm0-155.384q15.05 0 25.203-10.181 10.154-10.18 10.154-25.23t-10.181-25.204q-10.18-10.154-25.23-10.154t-25.204 10.181q-10.153 10.181-10.153 25.231 0 15.05 10.18 25.203Q307.285-600 322.335-600zm151.511 305.384h167.693q12.749 0 21.374-8.629 8.625-8.629 8.625-21.384 0-12.756-8.625-21.371-8.625-8.615-21.374-8.615H473.846q-12.75 0-21.375 8.629-8.625 8.628-8.625 21.384 0 12.755 8.625 21.37 8.625 8.616 21.375 8.616zm0-155.385h167.693q12.749 0 21.374-8.628 8.625-8.629 8.625-21.384 0-12.756-8.625-21.371-8.625-8.615-21.374-8.615H473.846q-12.75 0-21.375 8.628-8.625 8.629-8.625 21.384 0 12.756 8.625 21.371 8.625 8.615 21.375 8.615zm0-155.384h167.693q12.749 0 21.374-8.629 8.625-8.628 8.625-21.384 0-12.755-8.625-21.37-8.625-8.616-21.374-8.616H473.846q-12.75 0-21.375 8.629t-8.625 21.384q0 12.756 8.625 21.371 8.625 8.615 21.375 8.615zM212.309-140.001q-30.308 0-51.308-21t-21-51.308v-535.382q0-30.308 21-51.308t51.308-21h535.382q30.308 0 51.308 21t21 51.308v535.382q0 30.308-21 51.308t-51.308 21H212.309zm0-59.999h535.382q4.616 0 8.463-3.846 3.846-3.847 3.846-8.463v-535.382q0-4.616-3.846-8.463-3.847-3.846-8.463-3.846H212.309q-4.616 0-8.463 3.846-3.846 3.847-3.846 8.463v535.382q0 4.616 3.846 8.463 3.847 3.846 8.463 3.846zM200-760v560-560z"></path>
    </svg>;
}
export default Icon;