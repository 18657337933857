import React, { useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography, TextField, Stack } from '@mui/material';
import { withStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius: `14px`,
      '& fieldset': {}
    }
  }
})(TextField);
export const Confirm = ({
  open,
  onClose,
  onSubmit,
  title,
  message,
  submitText
}) => {
  return <Dialog PaperProps={{
    style: {
      borderRadius: '28px',
      minWidth: '350px'
    }
  }} open={open} onClose={onClose}>
    <DialogTitle sx={{
      m: 0,
      p: 2,
      fontSize: 'medium'
    }}>
      <IconButton aria-label="close" onClick={onClose} sx={{
        position: 'absolute',
        right: 8,
        top: 8,
        color: theme => theme.palette.grey[500]
      }}>
        <CloseIcon />
      </IconButton>
      <Stack alignItems="center" component="span">
        <Typography variant="h4" component="span">
          {title}
        </Typography>
      </Stack>
    </DialogTitle>
    <DialogContent>
      <Box component="span">
        {message}
      </Box>
    </DialogContent>
    <DialogActions sx={{
      py: 1
    }}>
      <Button onClick={() => {
        onClose();
      }} variant="text" sx={{
        borderRadius: '20px',
        color: 'rgba(29, 37, 45, 0.7)',
        minWidth: '75px',
        textTransform: 'none'
      }}>
        Cancel
      </Button>
      <Button onClick={() => onSubmit()} variant="text" sx={{
        mr: 1,
        borderRadius: '20px',
        minWidth: '75px',
        color: '#53A6D6',
        textTransform: 'none'
      }}>
        {submitText || 'Submit'}
      </Button>
    </DialogActions>
  </Dialog>;
};