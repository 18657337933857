import React from 'react';
function Icon(props) {
  if (props?.size == 'sm') {
    return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill={props.fill || '#1D252D'} viewBox="0 -960 960 960">
        <path d="M172.31-180Q142-180 121-201q-21-21-21-51.31v-455.38Q100-738 121-759q21-21 51.31-21h615.38Q818-780 839-759q21 21 21 51.31v455.38Q860-222 839-201q-21 21-51.31 21H172.31zM480-457.69L160-662.31v410q0 5.39 3.46 8.85t8.85 3.46h615.38q5.39 0 8.85-3.46t3.46-8.85v-410L480-457.69zm0-62.31l313.85-200h-627.7L480-520zM160-662.31V-720v467.69q0 5.39 3.46 8.85t8.85 3.46H160v-422.31z"></path>
      </svg>;
  }
  return <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill={props.fill || '#1D252D'} viewBox="0 -960 960 960">
      <path d="M162.57-180q-25.79 0-44.18-18.39T100-242.57v-474.86q0-25.79 18.39-44.18T162.57-780h634.86q25.79 0 44.18 18.39T860-717.43v474.86q0 25.79-18.39 44.18T797.43-180H162.57zM480-468.51L150.26-684.36v441.79q0 5.39 3.46 8.85t8.85 3.46h634.86q5.39 0 8.85-3.46t3.46-8.85v-441.79L480-468.51zm0-52.57l321.69-208.66H158.97L480-521.08zM150.26-684.36v-45.38 487.17q0 5.39 3.46 8.85t8.85 3.46h-12.31v-454.1z"></path>
    </svg>;
}
export default Icon;