import React from 'react';
export const MultiItem = props => {
  const {
    param,
    data
  } = props;
  const {
    key,
    items
  } = param;
  return <table className="w-100">
      <tbody>
        {(data[key] || []).map((row, index) => <tr key={row._i}>
            {items.map(item => <td className='text-uppercase' key={item.key}>{row[item.key] || ''}</td>)}
          </tr>)}
      </tbody>
    </table>;
};