import React from 'react';
function Icon() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" fill="none" viewBox="0 0 25 24">
      <mask style={{
      maskType: 'alpha'
    }} width="25" height="24" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#24335D" d="M0.518 0H24.518V24H0.518z"></path>
      </mask>
      <g fill="#fff" clipPath="url(#clip0_1036_6306)">
        <path d="M13.648 6.345c-.13-.07-.26-.13-.4-.17-.04-.01-.09 0-.13-.02a1.35 1.35 0 00-.28-.04c-.09 0-.19.02-.28.04-.05 0-.1 0-.14.02-.14.04-.27.09-.4.17l-2.83 1.65h-4.13c-1.4 0-2.54 1.14-2.54 2.54v.96a2.534 2.534 0 002.54 2.54h.91v3.66c0 .41.34.75.75.75s.75-.34.75-.75v-3.66h1.7l2.83 1.65c.26.15.54.23.83.23.29 0 .56-.07.82-.22.52-.3.83-.83.83-1.43v-6.49c0-.6-.31-1.13-.83-1.43zM20.348 5.775a.754.754 0 00-1.06-.01c-.3.29-.3.76-.01 1.06 1.12 1.16 1.74 2.65 1.74 4.21s-.62 3.05-1.74 4.21c-.29.3-.28.77.01 1.06.15.14.33.21.52.21s.39-.08.54-.23c1.4-1.44 2.17-3.3 2.17-5.25 0-1.95-.77-3.81-2.17-5.25v-.01z"></path>
        <path d="M18.088 8.045a.746.746 0 00-1.06-.03c-.3.28-.32.76-.03 1.06.53.57.82 1.24.82 1.95s-.28 1.38-.82 1.95c-.28.3-.27.78.03 1.06a.751.751 0 001.06-.04c.79-.84 1.23-1.9 1.23-2.98s-.43-2.14-1.22-2.98l-.01.01z"></path>
      </g>
      <defs>
        <clipPath id="clip0_1036_6306">
          <path fill="#fff" d="M0 0H20V12.91H0z" transform="translate(2.518 5.545)"></path>
        </clipPath>
      </defs>
    </svg>;
}
export default Icon;