import React from 'react';
function Icon() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none" viewBox="0 0 24 25">
      <mask id="mask0_66_4872" style={{
      maskType: 'alpha'
    }} width="24" height="25" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0 0.5H24V24.5H0z"></path>
      </mask>
      <g mask="url(#mask0_66_4872)">
        <path fill="#1D252D" d="M8 4.613a.726.726 0 01-.75-.75.728.728 0 01.75-.75h8c.217 0 .396.07.538.212a.731.731 0 01.212.538.728.728 0 01-.75.75H8zm-3 2.9a.706.706 0 01-.537-.225.74.74 0 01-.213-.525.726.726 0 01.75-.75h14a.728.728 0 01.75.75c0 .2-.07.375-.212.525a.71.71 0 01-.538.225H5zm-.7 14.375c-.5 0-.925-.175-1.275-.525a1.736 1.736 0 01-.525-1.275v-9.4c0-.5.175-.925.525-1.275.35-.35.775-.525 1.275-.525h15.4c.5 0 .925.175 1.275.525.35.35.525.775.525 1.275v9.4c0 .5-.175.925-.525 1.275-.35.35-.775.525-1.275.525H4.3zm7.35-3.9l2.775-1.85a.864.864 0 00.4-.75.864.864 0 00-.4-.75l-2.775-1.85c-.3-.217-.608-.238-.925-.063-.317.175-.475.446-.475.813v3.7c0 .367.158.637.475.813.317.175.625.154.925-.063z"></path>
        <path fill="#1D252D" d="M7.354 11.019H16.647V19.598H7.354z"></path>
        <path fill="#FAFAFA" fillRule="evenodd" d="M14.601 12.954c.217-.008.401.067.544.224a.691.691 0 01.2.531.764.764 0 01-.223.526l-3.399 3.419a.928.928 0 01-.616.24.788.788 0 01-.627-.265l-1.645-1.644a.678.678 0 01-.202-.523.75.75 0 01.223-.514.71.71 0 01.52-.213c.201 0 .376.071.517.213l1.21 1.19 2.962-2.961a.773.773 0 01.536-.223z" clipRule="evenodd"></path>
      </g>
    </svg>;
}
export default Icon;