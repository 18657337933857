import React from "react";
function Icon() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <mask id="mask0_38_3033" style={{
      maskType: "alpha"
    }} width="24" height="24" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0 0H24V24H0z"></path>
      </mask>
      <g mask="url(#mask0_38_3033)">
        <path fill="#1D252D" d="M13.002 17.875v-1.5h2.615a.587.587 0 00.481-.25l3.377-4.75-3.377-4.75a.587.587 0 00-.48-.25H6.232a.294.294 0 00-.212.096.294.294 0 00-.096.212V9.24h-1.5V6.683c0-.497.177-.923.531-1.277a1.74 1.74 0 011.277-.531h9.384a2.104 2.104 0 011.727.888l3.22 4.565c.212.32.319.67.319 1.048 0 .379-.107.727-.32 1.045l-3.219 4.546c-.202.28-.453.5-.75.664a2.002 2.002 0 01-.977.244h-2.615zm-6.135 1.25a.725.725 0 01-.534-.216.726.726 0 01-.216-.534v-2.25h-2.25a.725.725 0 01-.534-.216.726.726 0 01-.216-.534c0-.213.072-.391.216-.535a.726.726 0 01.534-.215h2.25v-2.25c0-.213.072-.39.216-.534a.726.726 0 01.535-.216c.212 0 .39.072.534.216a.726.726 0 01.215.534v2.25h2.25c.213 0 .39.072.535.216a.726.726 0 01.215.534c0 .213-.072.391-.215.535a.726.726 0 01-.535.215h-2.25v2.25c0 .212-.072.39-.215.534a.726.726 0 01-.535.216z"></path>
      </g>
    </svg>;
}
export default Icon;