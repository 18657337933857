import React from 'react';
function Icon(props) {
  return <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" fill="none" viewBox="0 0 21 20">
      <g>
        <mask id="mask0_138_38172" style={{
        maskType: 'alpha'
      }} width="21" height="20" x="0" y="0" maskUnits="userSpaceOnUse">
          <path fill="#D9D9D9" d="M0.5 0H20.5V20H0.5z"></path>
        </mask>
        <g mask="url(#mask0_138_38172)">
          <g fill="#3898D9">
            <path d="M3.904 10.716a.738.738 0 01-.537-.214.714.714 0 01-.219-.531c0-.211.072-.39.216-.536a.72.72 0 01.534-.219h3.495c.212 0 .39.072.537.215.146.143.218.32.218.531 0 .211-.071.39-.215.536a.72.72 0 01-.534.218H3.904zm0-3.125a.738.738 0 01-.537-.214.714.714 0 01-.219-.531c0-.211.072-.39.216-.536a.72.72 0 01.534-.219h7.494c.213 0 .392.072.538.215.146.143.218.32.218.531 0 .211-.071.39-.215.536a.72.72 0 01-.534.218H3.904zm0-3.125a.738.738 0 01-.537-.214.714.714 0 01-.219-.531c0-.211.072-.39.216-.536a.72.72 0 01.534-.219h7.494c.213 0 .392.072.538.215.146.143.218.32.218.531 0 .211-.071.39-.215.536a.72.72 0 01-.534.218H3.904z"></path>
            <path fill={props.fill || '#3898D9'} d="M12.906 16.262v-2.608l-2.258 1.304a.777.777 0 01-.584.078.69.69 0 01-.456-.363.774.774 0 01-.074-.584.706.706 0 01.357-.463l2.243-1.304-2.243-1.304a.7.7 0 01-.357-.461.789.789 0 01.074-.59.698.698 0 01.456-.357.772.772 0 01.584.076l2.258 1.304V8.382c0-.213.075-.395.226-.545a.746.746 0 01.547-.226c.214 0 .395.075.545.226.15.15.224.332.224.545v2.608l2.258-1.304a.772.772 0 01.584-.076.697.697 0 01.456.358.79.79 0 01.074.588.702.702 0 01-.357.462l-2.244 1.304 2.244 1.304a.706.706 0 01.357.463.806.806 0 01-.074.595.696.696 0 01-.456.352.776.776 0 01-.584-.078l-2.258-1.304v2.608a.742.742 0 01-.226.546.746.746 0 01-.547.226.74.74 0 01-.545-.226.746.746 0 01-.224-.546z"></path>
          </g>
        </g>
      </g>
    </svg>;
}
export default Icon;