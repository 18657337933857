import React, { useState } from 'react';
import { ModalDialog, Button } from 'components';
export const AddModelModal = props => {
  const {
    missingModels = [],
    addModel
  } = props;
  return <ModalDialog title="Add Models" open={props.open} onClose={props.onClose} allowBackdropClick size="lg" cancelText="Done" type="scroll">
      {missingModels.length === 0 && <p>No more models left to add.</p>}

      {missingModels.sort((a, b) => a.name.localeCompare(b.name)).map(field => <div style={{
      display: 'flex',
      justifyContent: 'space-between'
    }} key={field.id}>
            <div style={{
        width: '150px'
      }}>{field.name}</div>
            <div style={{
        width: '150px'
      }}>{field.id}</div>
            <div>
              <Button onClick={() => addModel(field.id)}>Add</Button>
            </div>
          </div>)}
    </ModalDialog>;
};