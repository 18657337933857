import React from 'react';
function Icon(props) {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <mask id="mask0_17_18653" style={{
      maskType: 'alpha'
    }} width="24" height="24" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0 0H24V24H0z"></path>
      </mask>
      <g mask="url(#mask0_17_18653)">
        <path fill={props.fill || '#1D252D'} d="M5 22.2a.706.706 0 01-.537-.225.74.74 0 01-.213-.525.726.726 0 01.75-.75h14a.728.728 0 01.75.75c0 .2-.07.375-.212.525A.71.71 0 0119 22.2H5zM5 3.3a.726.726 0 01-.75-.75c0-.2.071-.375.213-.525A.706.706 0 015 1.8h14a.71.71 0 01.538.225c.141.15.212.325.212.525a.728.728 0 01-.75.75H5zm7 10.2c.767 0 1.417-.267 1.95-.8.533-.533.8-1.183.8-1.95 0-.767-.267-1.417-.8-1.95A2.653 2.653 0 0012 8c-.767 0-1.417.267-1.95.8a2.654 2.654 0 00-.8 1.95c0 .767.267 1.417.8 1.95.533.533 1.183.8 1.95.8zm-7.7 6c-.5 0-.925-.175-1.275-.525A1.736 1.736 0 012.5 17.7V6.3c0-.5.175-.925.525-1.275.35-.35.775-.525 1.275-.525h15.4c.5 0 .925.175 1.275.525.35.35.525.775.525 1.275v11.4c0 .5-.175.925-.525 1.275-.35.35-.775.525-1.275.525H4.3zM6.4 18c.75-.8 1.6-1.417 2.55-1.85A7.261 7.261 0 0112 15.5c1.083 0 2.1.217 3.05.65.95.433 1.8 1.05 2.55 1.85h2.1c.067 0 .133-.033.2-.1s.1-.133.1-.2V6.3c0-.067-.033-.133-.1-.2s-.133-.1-.2-.1H4.3c-.067 0-.133.033-.2.1s-.1.133-.1.2v11.4c0 .067.033.133.1.2s.133.1.2.1h2.1zm2.3 0h6.6a5.512 5.512 0 00-1.562-.75A6.046 6.046 0 0012 17c-.6 0-1.179.083-1.737.25A5.51 5.51 0 008.7 18zm3.3-6c-.35 0-.646-.12-.887-.363a1.205 1.205 0 01-.363-.887c0-.35.121-.646.363-.887.241-.242.537-.363.887-.363s.646.121.887.363c.242.241.363.537.363.887s-.121.646-.363.887A1.204 1.204 0 0112 12z"></path>
      </g>
    </svg>;
}
export default Icon;