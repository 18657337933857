import React, { useState, useContext } from 'react';
import { UserStateContext } from 'context/user-state-context';
import { deleteCredential as deleteCredentialsAPI, autoOpsShopsSync, autoOpsShopInitialSync } from '../integrations.resource';
import { snackbarService, ModalDialog, DynamicTypeAhead } from 'components';
import { getLocations, getLocationRefs, editLocationRef } from 'shared/common.api';
import EnhancedTable from 'components/mui/enhanced-table/enhanced-table.component';
import { Box, Checkbox, Stack, Typography, Button, Divider, CircularProgress } from '@mui/material';
import { CancelIcon, SyncIcon } from 'components/mui';
import { IntegrationSchemaForm } from './integrations-schema-form.component';
export const IntegrationAutoOpsUpdate = ({
  credential,
  install,
  match,
  location,
  history
}) => {
  const {
    asCompany,
    hasPermission
  } = useContext(UserStateContext);
  const [data, setData] = useState([]);
  const [deleteCredential, setDeleteCredential] = useState();
  const [saving, setSaving] = useState(false);
  const [mapLocation, setMapLocation] = useState();
  const [filters, setFilters] = useState({
    entity_source: 'autoops',
    entity_data__credential_id: credential?.id
  });

  // we mimic a backend INTEGRATION_SCHEMA as it plays
  // nicely with the IntegrationSchemaForm component
  const schema = {
    title: 'AutoOps',
    description: 'Fill out the following form to integrate AutoOps with Cinch',
    type: 'object',
    required: ['api_key'],
    properties: {
      api_key: {
        title: 'API Key',
        description: 'Contact AutoOps to retrieve your API Key.',
        type: 'string',
        format: 'password'
      },
      point_of_sale: {
        title: 'Point of Sale',
        description: 'Please specify your point of sale system that autoops integrates with (if available).',
        type: 'string',
        oneOf: [{
          const: '',
          title: 'None'
        }, {
          const: 'protractor',
          title: 'Protractor'
        }, {
          const: 'shopware',
          title: 'Shopware'
        }, {
          const: 'tekmetric',
          title: 'Tekmetric'
        }],
        default: ''
      },
      cinch_company_id: {
        title: 'Cinch Company Id',
        description: 'AutoOps will ask for this ID (this is your Cinch Company ID) Click to Copy.',
        type: 'string',
        customField: 'cinch_company_id'
      }
    }
  };
  const headCells = [{
    key: 'ref',
    label: 'AutoOps Location',
    noSort: true,
    displayComponent: ({
      data
    }) => <Stack>
          <Box>
            <strong>{data.name}</strong>
          </Box>
          <Box>
            {data.address1}, {data.city}, {data.state}, {data.zip_code}
          </Box>
        </Stack>
  }, {
    key: 'location',
    label: 'Matched Location',
    noSort: true,
    displayComponent: ({
      data
    }) => {
      if (!data.location) {
        return <i>No Match</i>;
      }
      return <Stack>
            <Box>
              <strong>{data.location_name}</strong>
            </Box>
            <Box>
              {data.location_address1}, {data.location_city}, {data.location_state}, {data.location_zip_code}
            </Box>
          </Stack>;
    }
  }, {
    key: 'is_active',
    label: 'Activate Location',
    noSort: true,
    displayComponent: ({
      data: row
    }) => <Checkbox color="primary" disabled={!hasPermission('integration.change_credential')} checked={row.is_active} onChange={async () => {
      try {
        setSaving(true);
        const newIsActive = !row.is_active;
        await editLocationRef(row.id, {
          is_active: newIsActive
        });
        if (newIsActive === true) {
          await autoOpsShopInitialSync(credential.id, row.id);
        }
        snackbarService.popup({
          type: 'success',
          message: 'This change was saved'
        });
        setData(data => {
          const newData = JSON.parse(JSON.stringify(data));
          const newRow = newData.find(r => r.id === row.id);
          newRow.is_active = !newRow.is_active;
          return newData;
        });
      } catch (error) {
        console.error(error);
      } finally {
        setSaving(false);
      }
    }} sx={{
      color: '#1D252D',
      '&.Mui-checked': {
        color: '#53A6D6'
      },
      '& .MuiSvgIcon-root': {
        fontSize: 22
      }
    }} />
  }];
  const handleRowClick = (data, column) => {
    if (!hasPermission('integration.change_credential')) {
      snackbarService.popup({
        type: 'error',
        message: 'You do not have permission to edit this credential'
      });
      return;
    }
    if (column == 'location' || column == 'ref') {
      setMapLocation(data);
    }
  };
  const resync = async credentialId => {
    try {
      await autoOpsShopsSync(credentialId);
      snackbarService.popup({
        type: 'success',
        message: 'Active locations will start syncing momentarily'
      });
    } catch (error) {
      console.error(error);
      snackbarService.popup({
        type: 'error',
        message: 'Unknown error occured trying ot resync credential'
      });
    }
  };
  const removeCredentials = async credentialsId => {
    try {
      await deleteCredentialsAPI(credentialsId);
      snackbarService.popup({
        type: 'success',
        message: 'Successfully deleted credentials'
      });
      history.push(`/integrations/manage/${install?.id}`);
    } catch (error) {
      console.error(error);
      snackbarService.popup({
        type: 'error',
        message: 'Unknown error to trying delete credentials. Please try again.'
      });
    }
  };
  return <>
      {credential ? <Box>
          <Stack spacing="36px">
            <Typography variant="tableHeader">Review Locations</Typography>
            <Typography>Map your AutoOps locations. Enable and disable the locations you wish to sync.</Typography>
            <Stack direction="row" spacing="16px">
              <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
                <Typography>If there are missing locations, click the resync button to fetch the latest locations from AutoOps.</Typography>
              </Box>
              <Button disabled={!hasPermission('integration.change_credential')} variant="contained" sx={{
            backgroundColor: '#F3F3F4',
            '&:hover': {
              backgroundColor: '#F3F3F4'
            }
          }} startIcon={<SyncIcon fill="#3898D9" />} onClick={async e => {
            await resync(credential.id);
          }}>
                <Box sx={{
              py: '5px',
              pr: '12px'
            }}>
                  <Typography sx={{
                color: '#3898D9'
              }}>Resync </Typography>
                </Box>
              </Button>
            </Stack>
            <EnhancedTable type="Locations" headCells={headCells} api={getLocationRefs} defaultSort="name" handleRowClick={handleRowClick} dataState={[data, setData]} filtersState={[filters, setFilters]} />

            <Stack sx={{
          pt: '16px'
        }}>
              <Divider />
              <Box sx={{
            pr: '20px',
            pt: '20px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
                <Stack direction="row" spacing="16px">
                  {hasPermission('integration.delete_credential') && <Button variant="contained" sx={{
                backgroundColor: '#F3F3F4',
                '&:hover': {
                  backgroundColor: '#F3F3F4'
                }
              }} startIcon={<CancelIcon fill="#3898D9" />} onClick={e => {
                setDeleteCredential(credential);
              }}>
                      <Box sx={{
                  py: '5px',
                  pr: '12px'
                }}>
                        <Typography sx={{
                    color: '#3898D9'
                  }}>Remove </Typography>
                      </Box>
                    </Button>}
                </Stack>
                <Stack direction="row" spacing="16px">
                  <Button variant="contained" sx={{
                backgroundColor: '#F3F3F4',
                '&:hover': {
                  backgroundColor: '#F3F3F4'
                }
              }} onClick={e => {
                history.push(`/integrations/manage/${install?.id}`);
              }}>
                    <Box sx={{
                  py: '5px',
                  px: '12px'
                }}>
                      <Typography sx={{
                    color: '#3898D9'
                  }}>Cancel </Typography>
                    </Box>
                  </Button>
                  <Button disabled={saving} sx={{
                backgroundColor: '#3898D9',
                '&:hover': {
                  backgroundColor: '#3898D9'
                }
              }} variant="contained" endIcon={saving && <CircularProgress sx={{
                color: '#FFF'
              }} size={'20px'} />} onClick={e => {
                history.push(`/integrations/manage/${install?.id}`);
              }}>
                    <Box sx={{
                  py: '6px',
                  px: '12px'
                }}>
                      <Typography sx={{
                    color: '#fff'
                  }}>{saving ? 'Working...' : 'Done'}</Typography>
                    </Box>
                  </Button>
                </Stack>
              </Box>
            </Stack>
          </Stack>
        </Box> : <>
          {hasPermission('integration.add_credential') ? <>
              <IntegrationSchemaForm install={install} schema={schema} />
            </> : <>
              <Typography> You do not have permission to add a credential. </Typography>
            </>}
        </>}

      <ModalDialog open={!!mapLocation} title="Assign Location" submitText="Assign" onSubmit={() => {
      setSaving(true);
      editLocationRef(mapLocation.id, {
        location: mapLocation.location
      }).then(() => {
        snackbarService.popup({
          type: 'success',
          message: 'Changes were saved'
        });
        setSaving(false);
      });
      setData(data => {
        const newData = JSON.parse(JSON.stringify(data));
        const newRow = newData.find(r => r.id === mapLocation.id);
        newRow.location = mapLocation.location;
        newRow.location_name = mapLocation.location_name;
        newRow.location_address1 = mapLocation.location_address1;
        newRow.location_address2 = mapLocation.location_address2;
        newRow.location_city = mapLocation.location_city;
        newRow.location_state = mapLocation.location_state;
        newRow.location_zip_code = mapLocation.location_zip_code;
        return newData;
      });
    }} onClose={() => setMapLocation()} allowBackdropClick level={2}>
        <p>Map the following AutoOps Clients:</p>
        <p>
          <strong>{mapLocation?.name}</strong>
          <br />
          {mapLocation?.address1}, {mapLocation?.city}, {mapLocation?.state}, {mapLocation?.zip_code}
        </p>

        <p>To the following Location:</p>
        <DynamicTypeAhead getItems={getLocations} getItemsFilters={{
        company: asCompany.id
      }} placeholder="Choose" displayProperty="name" displayComponent={data => <Stack style={{
        borderBottom: '1px solid #cccccc',
        paddingBottom: '5px'
      }}>
              <Box>
                <strong>{data?.name}</strong>
              </Box>
              <Box>
                {data?.address1}, {data?.city}, {data?.state}, {data?.zip_code}
              </Box>
            </Stack>} keyProperty="id" value={mapLocation?.location} onChange={val => {
        setMapLocation(mapLocation => ({
          ...mapLocation,
          location: val?.id || null,
          location_name: val?.name,
          location_address1: val?.address1,
          location_address2: val?.address2,
          location_city: val?.city,
          location_state: val?.state,
          location_zip_code: val?.zip_code
        }));
      }} />
      </ModalDialog>

      <ModalDialog open={!!deleteCredential && deleteCredential.id} title="Remove Credential" submitText="Remove" onSubmit={async () => await removeCredentials(deleteCredential.id)} onClose={() => setDeleteCredential(null)} allowBackdropClick>
        <p>
          <strong>{deleteCredential && deleteCredential.identifier}</strong>
        </p>
        <p>Are you sure you want to remove this credential?</p>
      </ModalDialog>
    </>;
};