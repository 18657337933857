import React from 'react';
import { Scoped, k } from 'kremling';
export const Delay = props => {
  const {
    data
  } = props;
  const {
    unit,
    amount,
    wait_until
  } = data;
  const showOptions = !!(wait_until && wait_until.start_time);
  const deconstructTime = time => {
    const [hourDisplay, minute] = time.split(':');
    const hourNumber = parseInt(hourDisplay);
    const meridiem = hourNumber >= 12 ? 'PM' : 'AM';
    const hour = hourNumber === 0 ? 12 : hourNumber > 12 ? hourNumber - 12 : hourNumber;
    return {
      hour,
      minute,
      meridiem
    };
  };
  const isDaysChecked = day => {
    return wait_until.days.includes(day);
  };
  const renderOptions = showOptions => {
    if (!showOptions) return null;
    const {
      wait_until
    } = data;
    const startTime = deconstructTime(wait_until.start_time);
    const endTime = deconstructTime(wait_until.end_time);
    return <>
        <div className="form-group time-delay__time">
          <label htmlFor="">Wait between</label>
          <br />
          {startTime.hour}:{startTime.minute} {startTime.meridiem} and{' '}
          {endTime.hour}:{endTime.minute} {endTime.meridiem}
        </div>
        <div className="form-group time-delay__days"></div>
        <div className="form-group mb-none">
          <label>On the following days</label>
        </div>
        <div className="form-group time-delay__days">
          {isDaysChecked(0) && <label>Monday</label>}
          {isDaysChecked(1) && <label>Tuesday</label>}
          {isDaysChecked(2) && <label>Wednesday</label>}
          {isDaysChecked(3) && <label>Thursday</label>}
          {isDaysChecked(4) && <label>Friday</label>}
          {isDaysChecked(5) && <label>Saturday</label>}
          {isDaysChecked(6) && <label>Sunday</label>}
        </div>
        <div className="form-group">
          <label>Timezone</label> <br />
          {wait_until.timezone}
        </div>
      </>;
  };
  return <Scoped css={css}>
      <div>
        <div className="form-group">
          <div className="row">
            <div className="col-12">
              {amount} {unit}
            </div>
          </div>
        </div>
        {renderOptions(showOptions)}
      </div>
    </Scoped>;
};
const css = {
  styles: `[kremling="i45"] body,body[kremling="i45"] {
  --color-primary: #487aae;
  --color-highlight: #e7efff;
  --color-accent: #354052;
  --color-success: #73b56e;
  --color-warning: #f4b707;
  --color-danger: #df5651;
  --color-grey-10: #f9f9f9;
  --color-grey-25: #f3f3f3;
  --color-grey-50: #e9e9e9;
  --color-grey-75: #e3e3e3;
  --color-grey-100: #d3d3d3;
  --color-grey-200: #c3c3c3;
  --color-grey-300: #b3b3b3;
  --color-grey-400: #a3a3a3;
  --color-grey-500: #808080;
  --color-grey-600: #707070;
  --color-grey-700: #606060;
  --color-grey-800: #505050;
  --color-grey-900: #404040;
  --base-font-family: Roboto, Helvetica, Arial, sans-serif;
  --base-font-size: 1.4rem;
  --base-font-color: #404040;
  --base-font-weight: 400;
  --base-line-height: 1.4;
  --box-shadow-1: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.12);
  --box-shadow-2: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-3: 0 10px 20px rgba(0, 0, 0, 0.17), 0 6px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-4: 0 14px 28px rgba(0, 0, 0, 0.13), 0 10px 10px rgba(0, 0, 0, 0.1);
  --box-shadow-5: 0 19px 38px rgba(0, 0, 0, 0.18), 0 15px 12px rgba(0, 0, 0, 0.1);
  --base-border-radius: 0.4rem;
}

[kremling="i45"] .time-delay__overlay-block,[kremling="i45"].time-delay__overlay-block {
  position: relative;
  cursor: default;
  user-select: none;
}

[kremling="i45"] .time-delay__overlay-block::after,[kremling="i45"].time-delay__overlay-block::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.5);
}

[kremling="i45"] .time-delay__time,[kremling="i45"].time-delay__time {
  width: 64%;
}

[kremling="i45"] .time-delay__days label,[kremling="i45"].time-delay__days label {
  display: inline-flex;
  width: 11.5rem;
  align-items: center;
}

[kremling="i45"] .time-delay__divider,[kremling="i45"].time-delay__divider {
  padding-top: 0.4rem;
  margin-bottom: 1.6rem;
  border-bottom: solid 1px #d3d3d3;
  margin-left: -1.6rem;
  margin-right: -1.6rem;
}`,
  id: 'i45',
  namespace: 'kremling'
};