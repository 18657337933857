import React from 'react';
import { Dropdown, Button, Icon } from 'components';
export const MultiSelect = props => {
  const {
    param,
    update,
    data
  } = props;
  const {
    key,
    unitName,
    options
  } = param;
  const values = data[key] || [];
  const valueNames = values.map(s => options[s]);
  const toggleItem = item => {
    let newItems;
    if (values.some(i => i === item)) {
      newItems = values.filter(i => i === item);
    } else {
      newItems = [...values, item];
    }
    update({
      [key]: newItems
    });
  };
  return <div className="form-group">
      <label>{param.label}</label>
      <Dropdown size="block" contentHeight={250} trigger={() => <Button dropdown block>
            {valueNames.length ? valueNames.join(', ') : `Choose ${unitName}s`}
          </Button>} content={() => <ul className="select-list">
            {Object.keys(options).map(key => <li key={key}>
                <a onClick={() => toggleItem(key)}>
                  <div className="select-list__content">{options[key]}</div>
                  {values && values.indexOf(key) > -1 && <Icon className="select-list__selected" size={14} name="fa-regular-check" />}
                </a>
              </li>)}
          </ul>} />
    </div>;
};