import React, { Component } from 'react';
import { UserStateContext } from 'context/user-state-context';
import { Button } from '../../../components/button/button.component';
import { getNSConnections, addNSConnection, deleteNSConnection, refreshNSConnection } from '../integrations.resource';
import { Loader } from '../../../components/loader/loader.component';
import { toasterService } from '../../../components/toaster/toaster-service';
import { ModalDialog } from '../../../components/modal/modal-dialog.component';
import { Stack } from '@mui/material';
export class IntegrationNewStoreUpdate extends Component {
  static contextType = UserStateContext;
  static propTypes = {};
  state = {
    loading: true,
    connections: [],
    deleteConnection: {},
    isModalOpen: false,
    refreshId: null,
    retailer: null,
    stage: 'p'
  };
  componentDidMount() {
    //const onLoad = this.props.initLoad('newstore');
    this.getNewStoreData();
    //.finally(() => onLoad());
  }
  getNewStoreData = () => {
    this.setState({
      loading: true
    });
    return getNSConnections({
      company: this.context.asCompany.id
    }).then(({
      results
    }) => {
      this.setState({
        connections: results
      });
      this.setState({
        loading: false
      });
    });
  };
  addConnection = (...props) => {
    return addNSConnection(...props, this.context.asCompany.id).then(() => toasterService.success('Successfully added connection.  Data import will begin shortly and can take several minutes.')).then(() => this.getNewStoreData()).catch(err => {
      const key = Object.keys(err.response.data)[0];
      toasterService.error(err.response.data[key][0]);
    });
  };
  removeConnection = connectionId => {
    return deleteNSConnection(connectionId).then(() => toasterService.success('Successfully deleted connection')).then(() => this.getNewStoreData()).catch(() => toasterService.error('Unknown error trying delete connection. Please try again.'));
  };
  refresh = connectionId => {
    this.setState({
      refreshId: connectionId
    });
    refreshNSConnection(connectionId).then(() => {
      toasterService.success('Data resync will begin shortly and can take several minutes.');
      this.setState({
        refreshId: ''
      });
    });
  };
  render() {
    const {
      refreshId,
      connections,
      isModalOpen,
      retailer,
      stage,
      deleteConnection,
      loading
    } = this.state;
    return <>
        {loading ? <Loader /> : <div className="integration mb-5">
            <div className="integration__header">
              {this.context.hasPermission('newstore.add_connection') && <Button className="btn" actionType="primary" tag="a" onClick={() => this.setState({
            isModalOpen: true
          })}>
                  Add Connection
                </Button>}
            </div>
            <div className="integration__body">
              {connections.map(connection => <div className="integration-item" key={connection.id}>
                  <div className="integration-item__inner">
                    <Stack direction="row" spacing={2}>
                      <div className="integration-title">
                        <strong>
                          {connection.retailer} {connection.stage = 'x' ? 'Sandbox' : 'Production'}
                        </strong>
                      </div>
                      <div className="integration-actions">
                        <div className="integration__sync">
                          {this.context.hasPermission('newstore.change_site') && <>
                              {refreshId === connection.id ? <Loader size="sm" /> : <Button onClick={e => {
                        e.stopPropagation();
                        this.refresh(connection.id);
                      }} actionType="flat" icon="fa-regular-sync-alt" />}
                            </>}
                        </div>
                        {this.context.hasPermission('newstore.delete_site') && <Button actionType="flat" icon="fa-regular-trash" onClick={e => {
                    e.stopPropagation();
                    this.setState({
                      deleteConnection: connection
                    });
                  }} />}
                      </div>
                    </Stack>
                  </div>
                </div>)}
            </div>

            <ModalDialog open={!!deleteConnection.id} title="Remove Connection" submitText="Remove" onSubmit={() => this.removeConnection(deleteConnection.id)} onClose={() => this.setState({
          deleteConnection: {}
        })} allowBackdropClick>
              <p>
                <strong>
                  {deleteConnection.retailer} {deleteConnection.stage = 'x' ? 'Sandbox' : 'Production'}
                </strong>
              </p>
              <p>Are you sure you want to remove this connection?</p>
            </ModalDialog>

            <ModalDialog open={isModalOpen} title="Add Connection" onSubmit={() => this.addConnection(retailer, stage)} onClose={() => this.setState({
          isModalOpen: false
        })} allowBackdropClick>
              <p>Please enter your NewStore credentials below.</p>
              <p>
                <label>Retailer</label>
                <input required className="form-control" name="retailer" onChange={e => this.setState({
              retailer: e.target.value
            })} />
              </p>
              <p>
                <label>Environment</label>
                <select required className="form-control" name="stage" onChange={e => this.setState({
              stage: e.target.value
            })}>
                  <option value="p">Production</option>
                  <option value="x">Sandbox</option>
                </select>
              </p>
            </ModalDialog>
          </div>}
      </>;
  }
}