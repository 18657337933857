import React, { useContext, useEffect } from 'react';
import { Box, Typography, Stack } from '@mui/material';
import { history } from '../../root.component';
const tabsInfo = [{
  key: 'journeys',
  name: 'Journeys'
}, {
  key: 'segments',
  name: 'Segments'
}, {
  key: 'emails',
  name: 'Emails'
}, {
  key: 'texts',
  name: 'Texts'
}, {
  key: 'mailers',
  name: 'Mailers'
}, {
  key: 'surveys',
  name: 'Surveys'
}];
export const PrebuiltAdminListNavigationTabs = props => {
  const handleTabsChange = (event, index) => {
    history.push(`/prebuilt/admin/${tabsInfo[index].key}`);
  };
  return <>
      <Stack sx={{
      borderRadius: '32px',
      backgroundColor: '#fff',
      p: '16px'
    }} spacing="15px" direction={'row'}>
        {tabsInfo.map((tab, index) => <Box key={tab.key} sx={{
        cursor: 'pointer',
        textTransform: 'none',
        height: '32px',
        borderRadius: '16px',
        px: '20px',
        display: 'flex',
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'center',
        justifyItems: 'center',
        backgroundColor: props?.currentTab == tab.key ? '#C2E7FF' : '#fff',
        '&:hover': {
          backgroundColor: '#C2E7FF'
        }
      }} onClick={e => {
        handleTabsChange(e, index);
      }}>
            <Typography sx={{
          color: props?.currentTab == tab.key ? '#3898D9' : '#1D252D'
        }}>
              {tab.name}
            </Typography>
          </Box>)}
      </Stack>
    </>;
};