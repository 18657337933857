import React from 'react';
function Icon() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <mask id="mask0_1_1000" style={{
      maskType: 'alpha'
    }} width="24" height="24" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0 0H24V24H0z"></path>
      </mask>
      <g mask="url(#mask0_1_1000)">
        <path fill="#1D252D" d="M13.5 21.5h-3.025a.834.834 0 01-.575-.225.924.924 0 01-.3-.575l-.3-2.25a3.694 3.694 0 01-.812-.375 7.633 7.633 0 01-.763-.525l-2.075.9a.934.934 0 01-1.15-.375L3 15.45a.856.856 0 01-.1-.638.92.92 0 01.325-.537L5.05 12.9c-.033-.15-.054-.3-.062-.45a7.806 7.806 0 010-.875c.008-.15.029-.308.062-.475L3.225 9.725a.92.92 0 01-.325-.537.856.856 0 01.1-.638l1.5-2.6a.852.852 0 01.5-.4.894.894 0 01.625.025l2.1.875a6.34 6.34 0 011.575-.9l.3-2.25a.924.924 0 01.3-.575.834.834 0 01.575-.225H13.5c.233 0 .433.075.6.225.167.15.267.342.3.575l.3 2.25c.3.117.57.246.812.387.242.142.488.313.738.513l2.125-.875a.863.863 0 01.613-.025.96.96 0 01.512.4l1.5 2.6c.117.2.15.413.1.638a.92.92 0 01-.325.537l-1.85 1.4c.033.167.05.317.05.45V12c0 .133-.004.27-.013.412a4.04 4.04 0 01-.062.488l1.825 1.375c.183.133.3.312.35.537a.797.797 0 01-.125.638l-1.5 2.6a.852.852 0 01-.5.4.969.969 0 01-.65-.025l-2.05-.875c-.25.2-.504.375-.762.525-.259.15-.521.275-.788.375l-.3 2.25a.923.923 0 01-.3.575.866.866 0 01-.6.225zM12 15c.833 0 1.542-.292 2.125-.875A2.893 2.893 0 0015 12c0-.833-.292-1.542-.875-2.125A2.893 2.893 0 0012 9c-.833 0-1.542.292-2.125.875A2.893 2.893 0 009 12c0 .833.292 1.542.875 2.125A2.893 2.893 0 0012 15zm0-1.5c-.417 0-.77-.146-1.062-.438A1.444 1.444 0 0110.5 12c0-.417.146-.77.438-1.062A1.444 1.444 0 0112 10.5c.417 0 .77.146 1.062.438.292.291.438.645.438 1.062 0 .417-.146.77-.438 1.062A1.444 1.444 0 0112 13.5zM11 20h1.975l.35-2.675a5.256 5.256 0 001.4-.575c.417-.25.825-.567 1.225-.95l2.475 1.05 1-1.7-2.175-1.625c.083-.267.138-.525.163-.775a7.623 7.623 0 000-1.512 3.476 3.476 0 00-.163-.738l2.175-1.65-.975-1.7-2.525 1.05a5.507 5.507 0 00-1.2-.938 5.295 5.295 0 00-1.4-.587L13 4h-1.975l-.35 2.675c-.5.117-.967.3-1.4.55-.433.25-.85.567-1.25.95L5.55 7.15l-.975 1.7 2.15 1.6c-.083.25-.142.5-.175.75s-.05.517-.05.8c0 .267.017.525.05.775s.092.5.175.75l-2.15 1.625.975 1.7 2.475-1.05c.383.383.792.7 1.225.95.433.25.908.442 1.425.575L11 20z"></path>
      </g>
    </svg>;
}
export default Icon;