import React from 'react';
function Icon() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none" viewBox="0 0 24 25">
      <mask id="mask0_15_5238" style={{
      maskType: 'alpha'
    }} width="25" height="25" x="-1" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M-0.001 0.5H23.999V24.5H-0.001z"></path>
      </mask>
      <g mask="url(#mask0_15_5238)">
        <path fill="#1D252D" d="M7.307 23c-.505 0-.933-.175-1.283-.525a1.745 1.745 0 01-.525-1.283V3.808c0-.505.175-.933.525-1.283.35-.35.778-.525 1.283-.525h9.385c.505 0 .932.175 1.282.525.35.35.525.778.525 1.283v17.384c0 .505-.175.933-.525 1.283-.35.35-.777.525-1.282.525H7.307zm-.308-2.75v.942c0 .077.032.148.096.212a.294.294 0 00.212.096h9.385a.294.294 0 00.211-.096.294.294 0 00.096-.212v-.942H7zm0-1.5h10V6.25H7v12.5zm0-14h10v-.942a.294.294 0 00-.096-.212.294.294 0 00-.211-.096H7.307a.294.294 0 00-.212.096.294.294 0 00-.096.212v.942z"></path>
      </g>
    </svg>;
}
export default Icon;