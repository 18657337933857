import { PageHeaderMui } from 'components';
import React, { useState, useEffect, useContext } from 'react';
import { Box, Divider, Paper, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import { Form, Formik } from 'formik';
import { createNotificationContact, updateNotificationContact, getNotificationContact, createNotificationContactScope, deleteNotificationContactScope, updateNotificationContactScope } from 'shared/common.api';
import { UserStateContext } from 'context/user-state-context';
import * as Yup from 'yup';
import { NotificationContactForm } from './notification-contact-form.component';
import { getAlertLevel, getContactScope } from './shared';
export const EditNotificationContact = ({
  history,
  location,
  match
}) => {
  const defaultScope = 'company';
  const defaultAlertLevel = 'low';
  const [identifier, setIdentifier] = useState(match.params.notificationContactId);
  const [contact, setContact] = useState();
  const [loading, setLoading] = useState(true);
  const [showMarket, setShowMarket] = useState(false);
  const [showLocation, setShowLocation] = useState(false);
  const [initialValues, setInitialValues] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    scope: defaultScope,
    market: [],
    location: [],
    alert_level: defaultAlertLevel
  });
  const {
    asCompany
  } = useContext(UserStateContext);
  const retrieveMarketIdentifiers = scopeArray => {
    const scope = getContactScope(scopeArray);
    const marketIdentifiers = [];
    for (const currentScope of scopeArray) {
      if (scope === 'market') {
        marketIdentifiers.push(currentScope.market.id);
      }
    }
    return marketIdentifiers;
  };
  const retrieveLocationIdentifiers = scopeArray => {
    const scope = getContactScope(scopeArray);
    const locationIdentifiers = [];
    for (const currentScope of scopeArray) {
      if (scope === 'location') {
        locationIdentifiers.push(currentScope.location.id);
      }
    }
    return locationIdentifiers;
  };
  useEffect(() => {
    const retrieveNotificationContacts = async () => {
      if (identifier) {
        const response = await getNotificationContact(identifier);
        const contact = response.data;
        const scopeArray = contact.scope;
        setContact(contact);
        const scope = getContactScope(scopeArray, defaultScope);
        setInitialValues({
          first_name: contact.first_name,
          last_name: contact.last_name,
          email: contact.email ? contact.email : '',
          phone: contact.phone ? contact.phone : '',
          scope: scope,
          market: retrieveMarketIdentifiers(scopeArray),
          location: retrieveLocationIdentifiers(scopeArray),
          alert_level: getAlertLevel(scopeArray, defaultAlertLevel)
        });
        setInitialScope(scope);
      } else {
        setInitialScope(defaultScope);
      }
      setLoading(false);
    };
    retrieveNotificationContacts();
  }, [identifier]);
  const validationSchema = () => {
    return Yup.object().shape({
      first_name: Yup.string().required('Required'),
      last_name: Yup.string().required('Required'),
      email: Yup.string().email('Invalid Email').required('Required'),
      phone: Yup.string().required('Required'),
      alert_level: Yup.string().required('Required'),
      scope: Yup.string()
    });
  };
  const customValidation = values => {
    /**
     * It's just way easier to write this
     * in customValidation rather than Yup.
     * It runs in tandem with validationSchema.
     */
    const errors = {};
    if (values.scope === 'location') {
      if (values.location.length === 0) {
        errors.location = 'You must select at least one Location.';
      }
    }
    if (values.scope === 'market') {
      if (values.market.length === 0) {
        errors.market = 'You must select at least one Market.';
      }
    }
    return errors;
  };
  const setInitialScope = newScope => {
    if (newScope === 'company') {
      setShowMarket(false);
      setShowLocation(false);
    } else if (newScope === 'market') {
      setShowMarket(true);
      setShowLocation(false);
    } else if (newScope === 'location') {
      setShowMarket(false);
      setShowLocation(true);
    }
  };
  const submitForm = async (validatedData, {
    setErrors
  }) => {
    const selectedScope = validatedData.scope;
    const contactRequestBody = {
      first_name: validatedData.first_name,
      last_name: validatedData.last_name,
      email: validatedData.email,
      phone: validatedData.phone,
      company: asCompany.id
    };
    let contactId = identifier ? identifier : null;

    /**
     * Here is my apology letter about the below logic:
     *
     * Since we're technically interacting with two different endpoints
     * (and models), model 1 (NotificationContact) is handled first.
     * There are situations in which the second model (NotificationContactScope)
     * may fail validation after a NotificationContact was generated.
     * In this instance, we need to monitor whether we pass the
     * first validation and successfully create the NotificationContact.
     *
     * Secondly, we must handle all NotificationScopeContact changes
     * as create, updates, or deletes depending on how the scope choice.
     * Since we can associate one to many, we need to check each currently
     * existing scope, and see if it is tied to what data exists.
     * Update existing, create the non existant, and delete any leftovers.
     * This keeps the API normalized, but makes the JS more complicated as one
     * dropdown (or multi select checkbox for location / market)
     * can generate multiple api calls and be responsible for cleaning up the
     * data depending on simple choices.
     */
    if (contactId) {
      try {
        await updateNotificationContact(identifier, contactRequestBody);
      } catch (error) {
        const message = 'Exception thrown attempting to update contact.';
        console.error(error, message);
        setErrors(error.response.data);
        throw error;
      }
    } else {
      try {
        const contactResponse = await createNotificationContact(contactRequestBody);
        contactId = contactResponse.data.id;
        setIdentifier(contactId);
      } catch (error) {
        const message = 'Exception thrown attempting to create contact.';
        console.error(error, message);
        setErrors(error.response.data);
        throw error;
      }
    }
    try {
      const newScopes = [];
      const existingLocationIds = [];
      const existingMarketIds = [];
      if (selectedScope === 'location') {
        for (const newLocationId of validatedData.location) {
          newScopes.push({
            alert_level: validatedData.alert_level,
            company: asCompany.id,
            location: newLocationId,
            market: null,
            contact: contactId
          });
          existingLocationIds.push(newLocationId);
        }
      } else if (selectedScope === 'market') {
        for (const newMarketId of validatedData.market) {
          newScopes.push({
            alert_level: validatedData.alert_level,
            company: asCompany.id,
            location: null,
            market: newMarketId,
            contact: contactId
          });
          existingMarketIds.push(newMarketId);
        }
      } else if (selectedScope === 'company') {
        newScopes.push({
          alert_level: validatedData.alert_level,
          company: asCompany.id,
          location: null,
          market: null,
          contact: contactId
        });
      }
      const existingScopes = contact?.scope ? contact?.scope : [];
      if (selectedScope !== getContactScope(existingScopes)) {
        const promises = [];

        /* delete the old scopes and then create the new ones */
        for (const existingScope of existingScopes) {
          promises.push(deleteNotificationContactScope(contactId, existingScope.id));
        }
        for (const newScope of newScopes) {
          promises.push(createNotificationContactScope(contactId, newScope));
        }
        await Promise.all(promises);
      } else {
        const scopesToDelete = [];
        const scopesToUpdate = [];
        const scopesToCreate = [];

        /* kinda gross little algorithm but this is the
           best I could come up with in a short time */
        newScopes.forEach(async (newScope, index) => {
          let markForCreation = true;
          existingScopes.forEach((existingScope, existingIndex) => {
            if (selectedScope === 'location') {
              if (newScope.location === existingScope.location.id) {
                const scopeWithId = {
                  ...newScope,
                  id: existingScope.id
                };
                scopesToUpdate.push(scopeWithId);
                delete newScopes[index];
                delete existingScopes[existingIndex];
                markForCreation = false;
              }
            } else if (selectedScope === 'market') {
              if (newScope.market === existingScope.market.id) {
                const scopeWithId = {
                  ...newScope,
                  id: existingScope.id
                };
                scopesToUpdate.push(scopeWithId);
                delete newScopes[index];
                delete existingScopes[existingIndex];
                markForCreation = false;
              }
            } else if (selectedScope === 'company') {
              if (newScope.company === existingScope.company) {
                const scopeWithId = {
                  ...newScope,
                  id: existingScope.id
                };
                scopesToUpdate.push(scopeWithId);
                delete newScopes[index];
                delete existingScopes[existingIndex];
                markForCreation = false;
              }
            }
          });

          /* create whatever isn't matched above, it doesn't exist, and 
             doesn't match existing records, therefore must be created */
          if (markForCreation) {
            scopesToCreate.push(newScope);
            delete newScopes[index];
          }
        });

        // whatever is leftover must be removed
        // note: there is something with react, if have already done a forEach on an
        // array you cannot do a regular for loop after? (values will be undefined).  weird.
        existingScopes.forEach(scope => {
          scopesToDelete.push(scope.id);
        });
        const promises = [];
        for (const createScope of scopesToCreate) {
          promises.push(createNotificationContactScope(contactId, createScope));
        }
        for (const updateScope of scopesToUpdate) {
          promises.push(updateNotificationContactScope(contactId, updateScope.id, updateScope));
        }
        for (const scopeId of scopesToDelete) {
          promises.push(deleteNotificationContactScope(contactId, scopeId));
        }
        await Promise.all(promises);
      }
      history.push('/notification-list');
    } catch (error) {
      console.error(error);
    }
  };
  return <>
      {!loading && <>
          <div className="wrapper">
            <PageHeaderMui type="Notification Recipient" name={contact ? contact.first_name + ' ' + contact.last_name : ''} />
            <div className="mui-wrapper">
              <Box sx={{
            m: '20px'
          }}>
                <Paper className="secondary-color" elevation={0} sx={{
              width: '100%',
              borderRadius: '14px'
            }}>
                  <Box sx={{
                m: '20px'
              }}>
                    <Paper sx={{
                  width: '100%',
                  borderRadius: '14px'
                }} />
                    <Box sx={{
                  height: '100%'
                }} display="flex" flexDirection="column" justifyContent="space-between">
                      <Stack spacing="30px" sx={{
                    py: '30px',
                    px: '24px'
                  }}>
                        <Stack spacing="30px">
                          <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{
                        width: '100%'
                      }}>
                            <Typography sx={{
                          fontFamily: 'Nexa',
                          fontSize: 24,
                          color: '#1D252D'
                        }}>Recipient</Typography>{' '}
                          </Box>
                        </Stack>
                      </Stack>
                    </Box>
                    <Divider />
                    <Formik initialValues={initialValues} onSubmit={submitForm} validationSchema={validationSchema} validate={customValidation}>
                      {({
                    submitForm
                  }) => <Form>
                          <NotificationContactForm contact={contact} submitForm={submitForm} showMarket={showMarket} setShowMarket={setShowMarket} showLocation={showLocation} setShowLocation={setShowLocation} asCompany={asCompany} history={history} />
                        </Form>}
                    </Formik>
                  </Box>
                </Paper>
              </Box>
            </div>
          </div>
        </>}
    </>;
};