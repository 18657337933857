import React, { Component, useContext, useState } from 'react';
import { Scoped, k } from 'kremling';
import { get } from 'lodash';
import { PageHeader } from 'components/page-header/page-header';
import { Dropdown } from 'components/dropdown/dropdown.component';
import { FormWizard } from './form-wizard/form-wizard.component';
import { UserStateContext } from 'context/user-state-context';
import { ModalDialog } from 'components/modal/modal-dialog.component';
import { toasterService } from 'components/toaster/toaster-service';
import { deleteForm as deleteFormAPI, duplicateForm, getForms, getFormsTags, patchForm } from 'shared/forms.api';
import { Status } from '../../components/status/status.component';
import CssBaseline from '@mui/material/CssBaseline';
import NewFormDialog from './new-form-dialog.component';
import GoogleLeads from './forms/google-leads-dialog.component';
import { Loader } from '../../components/loader/loader.component';
import FacebookLeads from './forms/facebook-leads-dialog.component';
import { Icon, PageHeaderMui, TagModal } from 'components';
import { IconButton, Tooltip, Box, Stack, Popper, ClickAwayListener, Grid, Button, Paper, Typography, Dialog, DialogContent, TextField, DialogActions, DialogTitle } from '@mui/material';
import { AddFolderIcon, AddTagsIcon, ArchiveIcon, ArchivedIcon, CloseIcon, DataFieldsIcon, DraftIcon, DuplicateIcon, ListAltIcon, MoveToFolderIcon, MultiCompanyPrompt, PlaylistAddCheckIcon, PublishedIcon, RestoreIcon, WebFormIcon } from 'components/mui';
import EnhancedTable from 'components/mui/enhanced-table/enhanced-table.component';
import { DeleteIcon, EditIcon } from 'components/mui';
import { NewButton } from 'components/mui/button/new.component';
import { MultiSelectFilter } from 'components/mui/filter/multi-select.component';
import { getFiltersFromURL } from 'components/mui/enhanced-table/utils';
import { DateRangeFilter } from 'components/mui/filter/date-range.component';
import { useLocation } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import FolderSelectDialog from 'components/mui/folder/folder-select-dialog';
import TagsMui from 'components/tags/tagsMui.component';
import { TagFilter } from 'components/mui/filter/tags.component';
import { Modal as NewModal } from '../../components/modal/modal.component';
import { DateTime } from 'luxon';
import { createNewFolder, deleteFolder, getCompanyFolders, getJourneys, updateFolder } from 'shared/common.api';
import moment from 'moment-timezone';
import { snackbarService } from 'components/mui/snackbar/snackbar-service';
import { ModalContext } from 'context/modal-context';
const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius: `14px`,
      '& fieldset': {}
    }
  }
})(TextField);
export const WebFormsList = ({
  history
}) => {
  const {
    hasPermission,
    asCompany,
    hasMultiPermission
  } = useContext(UserStateContext);
  const {
    show
  } = useContext(ModalContext);
  const [showFormWizard, setShowFormWizard] = useState(false);
  const [newForm, setNewForm] = useState(false);
  const [googleLeadsDialog, setGoogleLeadsDialog] = useState(false);
  const [facebookLeadsDialog, setFacebookLeadsDialog] = useState(false);
  const [renderIteration, setRenderIteration] = useState(0);
  const loc = useLocation();
  const searchParams = new URLSearchParams(loc.search);
  const folderParam = searchParams.get('folder');
  const [folderSelectDialog, setFolderSelectDialog] = React.useState([]);
  const [createNewFolderDialog, setCreateNewFolderDialog] = React.useState(false);
  const [newFolderName, setNewFolderName] = React.useState('');
  const [formCreateAnchorEl, setFormCreateAnchorEl] = React.useState(null);
  const formCreateOpen = Boolean(formCreateAnchorEl);
  const [tagModal, setTagModal] = React.useState(null);
  const [archiveModal, setArchiveModal] = React.useState([]);
  const [tabButtonHover, setTabButtonHover] = React.useState(null);
  const [deleteModal, setDeleteModal] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const filterOptions = [{
    component: TagFilter,
    api: getFormsTags,
    refreshApi: renderIteration,
    key: 'tags'
  }, {
    component: MultiSelectFilter,
    label: 'Source',
    key: 'source',
    options: [{
      label: 'Google Leads',
      value: 'google_leads'
    }, {
      label: 'Facebook Leads',
      value: 'facebook_leads'
    }, {
      label: 'Google',
      value: 'google'
    }, {
      label: 'Web',
      value: 'web'
    }]
  }, {
    component: DateRangeFilter,
    label: 'Updated Date',
    key: 'updated'
  }, {
    component: DateRangeFilter,
    label: 'Created Date',
    key: 'created'
  }, {
    key: 'archived',
    hidden: {
      hideInFilterList: true,
      hideChip: true
    }
  }];
  const [filters, setFilters] = React.useState(() => {
    const fils = getFiltersFromURL(filterOptions);
    return fils;
  });
  const [currentTab, setCurrentTab] = React.useState(() => {
    if (filters['archived']) {
      return 1;
    } else {
      return 0;
    }
  });
  const formCreateMenu = [{
    key: 'form',
    name: 'New Form',
    icon: <ListAltIcon />
  }, {
    key: 'folder',
    name: 'New folder',
    icon: <AddFolderIcon />
  }];
  const handleFormCreateClose = () => {
    setFormCreateAnchorEl(null);
  };
  const handleFormCreateMenuClick = item => {
    handleFormCreateClose();
    if (item.key == 'form') {
      setNewForm(true);
    }
    if (item.key == 'folder') {
      setCreateNewFolderDialog(true);
    }
  };
  const makeNewFolder = newFolderName => {
    setIsLoading(true);
    createNewFolder({
      name: newFolderName,
      model: 'form',
      parent: folderParam,
      company: asCompany.id
    }).then(() => {
      getCompanyFolders({
        model: 'survey',
        company: asCompany.id,
        parent: folderParam ? folderParam : 'home'
      }).then(results => {
        setRenderIteration(i => i + 1);
        setIsLoading(false);
        setCreateNewFolderDialog(false);
        setNewFolderName('');
      });
    });
  };
  const handleNewFormDialogContinue = choice => {
    setNewForm(false);
    if (choice === 'webform') {
      setShowFormWizard(true);
    } else if (choice === 'googleleads') {
      setGoogleLeadsDialog(true);
    } else if (choice === 'facebookleads') {
      setFacebookLeadsDialog(true);
    }
  };
  function onClose() {
    setFolderSelectDialog([]);
  }
  function onSubmitData(data) {
    const promises = [];
    folderSelectDialog.filter(f => f._type === 'folder').map(s => {
      promises.push(updateFolder(s.id, {
        parent: data === 'home' ? null : data
      }));
    });
    folderSelectDialog.filter(f => f._type === 'rowItem').map(s => {
      promises.push(patchForm(s.id, {
        folder: data === 'home' ? null : data
      }));
    });
    Promise.all(promises).then(() => {
      setRenderIteration(i => i + 1);
      setFolderSelectDialog([]);
    });
  }
  const addTagFromRow = (e, tag) => {
    e.stopPropagation();
    //check if already selected
    if (!filters['tags']?.includes(tag)) {
      setFilters({
        ...(filters || {}),
        ['tags']: [...(filters['tags'] || []), tag]
      });
    }
  };
  const duplicateForms = forms => {
    let promise;
    if (hasMultiPermission('forms.change_form')) {
      promise = show(MultiCompanyPrompt, {
        title: 'Duplicate Form',
        permission: 'forms.change_form'
      });
    } else {
      promise = Promise.resolve();
    }
    promise.then(companyIds => {
      const promises = [];
      forms.map(form => {
        promises.push(duplicateForm(form.id, companyIds));
      });
      Promise.all(promises).then(data => {
        if (forms.length > 1 || !!companyIds) {
          setRenderIteration(i => i + 1);
        } else {
          history.push(`/forms/${data[0]?.data?.id}`);
        }
        snackbarService.popup({
          type: 'success',
          message: 'Form(s) successfully duplicated.'
        });
      }).catch(() => {
        snackbarService.popup({
          type: 'error',
          message: 'There was an error trying to duplicate the form(s). Please try again.'
        });
      });
    }).catch(e => {
      console.error(e);
    });
  };
  const headCells = [{
    key: 'name',
    label: 'Name',
    width: '30%',
    displayComponent: ({
      data,
      isNested
    }) => <Stack direction="row" spacing={1}>
          {isNested ? <>
              <Box sx={{
          display: 'flex',
          alignItems: 'center'
        }}>
                <Icon name="custom-folder_open" />
              </Box>
              <Typography sx={{
          display: 'flex',
          alignItems: 'center'
        }}> {data.path + (data.name || '')}</Typography>
            </> : <Typography sx={{
        display: 'flex',
        alignItems: 'center'
      }}> {data.name || 'Untitled Form'}</Typography>}
          <TagsMui tags={data.tags} onClick={(e, tag) => {
        addTagFromRow(e, tag);
      }} />
        </Stack>
  }, {
    key: 'source',
    label: 'Source',
    width: '20%',
    displayComponent: ({
      data: form
    }) => {
      if (form.entity_source === 'google_leads') return 'Google Leads';
      if (form.entity_source === 'facebook_leads') return 'Facebook Leads';
      if (form.entity_source === 'google') {
        return <>
              Google
              {!!form.fields || <Status status="draft" className="ml-2">
                  Unlinked
                </Status>}
            </>;
      }
      return 'Web';
    }
  }, {
    key: 'updated_when',
    label: 'Updated',
    width: '20%',
    displayComponent: ({
      data: form
    }) => moment(form.updated_when).local().format('MMM D, YYYY h:mm A')
  }, {
    key: 'created_when',
    label: 'Created',
    width: '20%',
    displayComponent: ({
      data: form
    }) => <Tooltip title={form.created_by_user ? 'Created By: ' + form.created_by_first_name + ' ' + form.created_by_last_name : ''}>
          <span>{moment(form.created_when).local().format('MMM D, YYYY h:mm A')}</span>
        </Tooltip>
  }];
  const handleFormCreateClick = event => {
    setFormCreateAnchorEl(formCreateOpen ? null : event.currentTarget);
  };
  const handleTabsChange = (event, newValue) => {
    setCurrentTab(newValue);
    if (newValue == 1) {
      setFilters({
        ...(filters || {}),
        ['archived']: true
      });
    } else {
      const {
        archived: _,
        ...newFilters
      } = filters;
      setFilters(newFilters);
    }
  };
  const updateFolderName = name => {
    updateFolder(name.id, {
      name: name.name
    }).then(() => {
      setRenderIteration(i => i + 1);
    });
  };
  const hasMixedItemTypes = selected => {
    const uniqueValues = new Set(selected.map(v => v?._type));
    if (uniqueValues.size > 1) return true;
    return false;
  };
  const closeArchiveModal = () => {
    setArchiveModal([]);
  };
  const openArchiveModal = forms => {
    const promises = [];
    forms.map(form => {
      promises.push(getJourneys({
        company: asCompany.id,
        node_form: form.id,
        archived: false
      }));
    });
    Promise.all(promises).then(data => {
      let index = 0;
      const newForms = forms.map(form => {
        const seg = {
          ...form,
          journeys: data[index].data.results
        };
        index = index + 1;
        return seg;
      });
      setArchiveModal(newForms);
    });
  };
  const archiveForm = () => {
    const promises = [];
    archiveModal.map(form => {
      promises.push(patchForm(form.id, {
        archived_when: DateTime.local()
      }));
    });
    Promise.all(promises).then(data => {
      setRenderIteration(i => i + 1);
      closeArchiveModal();
    });
  };
  const restoreForm = forms => {
    const promises = [];
    forms.map(form => {
      promises.push(patchForm(form.id, {
        archived_when: null
      }));
    });
    Promise.all(promises).then(() => {
      setRenderIteration(i => i + 1);
    });
  };
  const canArchive = () => {
    return !archiveModal.some(form => form.journeys.length > 0);
  };
  const closeDeleteModal = () => {
    setDeleteModal([]);
  };
  const openDeleteModal = forms => {
    const promises = [];
    forms.filter(f => f._type === 'rowItem').map(form => {
      promises.push(getJourneys({
        company: asCompany.id,
        node_form: form.id,
        archived: false
      }));
    });
    Promise.all(promises).then(data => {
      let index = 0;
      const newForms = forms.filter(f => f._type === 'rowItem').map(form => {
        const f = {
          ...form,
          journeys: data[index].data.results
        };
        index = index + 1;
        return f;
      });
      const formFolders = forms.filter(f => f._type === 'folder').map(s => {
        return s;
      });
      setDeleteModal([...newForms, ...formFolders]);
    });
  };
  const canDelete = () => {
    return !deleteModal.filter(f => f._type === 'rowItem').some(form => form.journeys.length > 0);
  };
  const deleteForms = () => {
    const promises = [];
    deleteModal.filter(f => f._type === 'folder').map(s => {
      promises.push(deleteFolder(s.id));
    });
    deleteModal.filter(f => f._type === 'rowItem').map(s => {
      promises.push(deleteFormAPI(s.id));
    });
    Promise.all(promises).then(() => {
      setRenderIteration(i => i + 1);
      closeDeleteModal();
    });
  };
  const actionButtons = {
    displayComponent: ({
      data
    }) => <Stack direction="row" spacing={2}>
        {hasPermission('forms.change_form') && data.selected?.length > 0 && data.selected.filter(s => !s.archived_when).length > 0 && <Tooltip title="Move to folder">
            <IconButton sx={{
          ':hover': {
            backgroundColor: '#DAEFFF'
          }
        }} onClick={e => {
          setFolderSelectDialog(data.selected);
        }}>
              <MoveToFolderIcon />
            </IconButton>
          </Tooltip>}
        {hasPermission('forms.add_form') && data.selected?.length > 0 && data.selected.filter(s => s._type === 'folder').length === 0 && data.selected.filter(s => s.entity_source === 'google' || s.entity_source === 'google_leads' || s.entity_source === 'facebook_leads').length === 0 && <Tooltip title="Duplicate">
              <IconButton sx={{
          ':hover': {
            backgroundColor: '#DAEFFF'
          }
        }} onClick={e => {
          e.stopPropagation();
          duplicateForms(data.selected);
        }}>
                <DuplicateIcon />
              </IconButton>
            </Tooltip>}
        {hasPermission('forms.change_form') && data.selected?.length > 0 && !hasMixedItemTypes(data.selected) && data.selected[0]._type != 'folder' && data.selected.filter(s => !s.archived_when).length > 0 && <Tooltip title="Archive">
              <IconButton sx={{
          ':hover': {
            backgroundColor: '#DAEFFF'
          }
        }} onClick={e => {
          openArchiveModal(data.selected);
        }}>
                <ArchiveIcon />
              </IconButton>
            </Tooltip>}

        {hasPermission('forms.change_form') && data.selected?.length > 0 && !hasMixedItemTypes(data.selected) && data.selected[0]._type != 'folder' && data.selected.filter(s => !!s.archived_when).length > 0 && <Tooltip title="Restore">
              <IconButton sx={{
          ':hover': {
            backgroundColor: '#DAEFFF'
          }
        }} onClick={e => {
          e.stopPropagation();
          restoreForm(data.selected);
        }}>
                <RestoreIcon />
              </IconButton>
            </Tooltip>}

        {hasPermission('forms.delete_form') && data.selected?.length > 0 && <Tooltip title="Delete">
            <IconButton sx={{
          ':hover': {
            backgroundColor: '#DAEFFF'
          }
        }} onClick={e => {
          e.stopPropagation();
          openDeleteModal(data.selected);
        }}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>}
      </Stack>
  };
  return <>
      <CssBaseline />
      {isLoading && <Loader overlay />}
      <div className="wrapper">
        <PageHeaderMui type={currentTab == 1 ? 'Web Forms - Archived' : 'Web Forms'} icon={<Icon name="custom-web" size={24} />} folderBreadcrumbs updateName={updateFolderName} updateNamePermission={hasPermission('forms.change_form') && folderParam} />

        <Stack sx={{
        px: '20px',
        pt: '20px'
      }} spacing="15px" direction={'row'}>
          <Box sx={{
          backgroundColor: currentTab == 0 ? '#3898D9' : tabButtonHover == 0 ? '#C2E7FF' : '#F3F6FC',
          cursor: 'pointer',
          textTransform: 'none',
          height: '32px',
          borderRadius: '16px',
          paddingLeft: '20px',
          paddingRight: '20px',
          display: 'flex',
          alignItems: 'center',
          alignContent: 'center',
          justifyContent: 'center',
          justifyItems: 'center'
        }} onMouseEnter={() => setTabButtonHover(0)} onMouseLeave={() => setTabButtonHover(null)} onClick={e => {
          handleTabsChange(e, 0);
        }}>
            <PlaylistAddCheckIcon fill={currentTab == 0 ? '#ffffff' : '#1D252D'} />

            <Typography sx={{
            pl: '10px',
            color: currentTab == 0 ? '#ffffff' : '#1D252D'
          }}>
              Active
            </Typography>
          </Box>
          <Box sx={{
          backgroundColor: currentTab == 1 ? '#3898D9' : tabButtonHover == 1 ? '#C2E7FF' : '#F3F6FC',
          cursor: 'pointer',
          textTransform: 'none',
          height: '32px',
          borderRadius: '16px',
          paddingLeft: '20px',
          paddingRight: '20px',
          display: 'flex',
          alignItems: 'center',
          alignContent: 'center',
          justifyContent: 'center',
          justifyItems: 'center'
        }} onMouseEnter={() => setTabButtonHover(1)} onMouseLeave={() => setTabButtonHover(null)} onClick={e => {
          handleTabsChange(e, 1);
        }}>
            <ArchivedIcon fill={currentTab == 1 ? '#ffffff' : '#1D252D'} />

            <Typography sx={{
            pl: '10px',
            color: currentTab == 1 ? '#ffffff' : '#1D252D'
          }}>
              Archived
            </Typography>
          </Box>
        </Stack>

        <div className="mui-wrapper">
          <EnhancedTable type="Web Forms" headCells={headCells} api={getForms} folderModel="form" folderApi={patchForm} enableCheckboxes defaultSort={'-updated_when'} filterOptions={filterOptions} filtersState={[filters, setFilters]} handleRowClick={form => history.push(`/forms/${form.id}`)} renderIteration={renderIteration} actions={hasPermission('forms.add_form') && <NewButton onClick={e => {
          handleFormCreateClick(e);
        }} />} actionButtons={actionButtons} hoverActions={({
          data
        }) => <Stack direction="row" gap="10px">
                {data.entity_source === 'google' && <Tooltip title="View Connection Credentials">
                    <IconButton onClick={e => {
              e.stopPropagation();
              window.open(`https://docs.google.com/forms/d/${data.entity_ref}/edit`, '_blank');
            }}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>}
                {data.entity_source === 'google_leads' && <Tooltip title="View Connection Credentials">
                    <IconButton onClick={e => {
              e.stopPropagation();
              setGoogleLeadsDialog({
                id: data.id,
                name: data.name
              });
            }}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>}

                {hasPermission('forms.view_form') && <Tooltip title="View Form mapping">
                    <IconButton onClick={e => {
              e.stopPropagation();
              history.push(`/forms/${data.id}`);
            }}>
                      <DataFieldsIcon />
                    </IconButton>
                  </Tooltip>}

                {hasPermission('forms.change_form') && <Tooltip title="Move to folder">
                    <IconButton sx={{
              ':hover': {
                backgroundColor: '#DAEFFF'
              }
            }} onClick={e => {
              e.stopPropagation();
              setFolderSelectDialog([data]);
            }}>
                      <MoveToFolderIcon />
                    </IconButton>
                  </Tooltip>}
                {hasPermission('forms.change_form') && <Tooltip title="Add tags">
                    <IconButton sx={{
              ':hover': {
                backgroundColor: '#DAEFFF'
              }
            }} onClick={e => {
              e.stopPropagation();
              setTagModal(data);
            }}>
                      <AddTagsIcon />
                    </IconButton>
                  </Tooltip>}
                {!!data?.archived_when && hasPermission('forms.change_form') && <Tooltip title="Restore">
                    <IconButton sx={{
              ':hover': {
                backgroundColor: '#DAEFFF'
              }
            }} onClick={e => {
              e.stopPropagation();
              restoreForm([data]);
            }}>
                      <RestoreIcon />
                    </IconButton>
                  </Tooltip>}

                {!data?.archived_when && hasPermission('forms.change_form') && <Tooltip title="Archive">
                    <IconButton sx={{
              ':hover': {
                backgroundColor: '#DAEFFF'
              }
            }} onClick={e => {
              e.stopPropagation();
              openArchiveModal([data]);
            }}>
                      <ArchiveIcon />
                    </IconButton>
                  </Tooltip>}
                {hasPermission('forms.change_form') && data?.entity_source == null && <Tooltip title="Duplicate Form">
                    <IconButton sx={{
              ':hover': {
                backgroundColor: '#DAEFFF'
              }
            }} onClick={e => {
              e.stopPropagation();
              duplicateForms([data]);
            }}>
                      <DuplicateIcon />
                    </IconButton>
                  </Tooltip>}

                {hasPermission('forms.delete_form') && <Tooltip title="Delete">
                    <IconButton sx={{
              ':hover': {
                backgroundColor: '#DAEFFF'
              }
            }} onClick={e => {
              e.stopPropagation();
              openDeleteModal([data]);
            }}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>}
              </Stack>} />
        </div>
      </div>

      <FormWizard open={showFormWizard} onClose={() => setShowFormWizard(false)} onSubmit={() => setRenderIteration(r => r + 1)} />

      {!!googleLeadsDialog && <GoogleLeads open={!!googleLeadsDialog} data={googleLeadsDialog} onClose={() => setGoogleLeadsDialog(false)} onSubmit={() => setRenderIteration(r => r + 1)} />}

      {!!facebookLeadsDialog && <FacebookLeads open={!!facebookLeadsDialog} data={facebookLeadsDialog} onClose={() => setFacebookLeadsDialog(false)} onSubmit={() => {
      setRenderIteration(r => r + 1);
      setFacebookLeadsDialog(false);
    }} />}

      <NewFormDialog open={newForm} onClose={() => setNewForm(false)} onSubmit={handleNewFormDialogContinue} />

      <Popper anchorEl={formCreateAnchorEl} sx={{
      zIndex: 200
    }} open={formCreateOpen} onClose={handleFormCreateClose} placement="bottom-start">
        <ClickAwayListener onClickAway={handleFormCreateClose}>
          <Paper elevation={0} sx={{
          borderRadius: '16px',
          overflow: 'visible',
          width: '168px',
          //height: '120px',
          filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))'
        }}>
            <Box sx={{
            py: 1
          }}>
              <Grid container justifyContent="center" sx={{
              px: 2
            }}></Grid>
              <Box display="flex" flexDirection="column">
                {formCreateMenu.map(opt => <Button variant="text" fullWidth key={opt.key} sx={{
                cursor: 'pointer',
                height: '48px',
                color: '#1D252D',
                textTransform: 'none',
                justifyContent: 'flex-start',
                px: 2
              }} onClick={e => {
                handleFormCreateMenuClick(opt);
              }}>
                    {opt.icon}
                    <Typography sx={{
                  pl: 1
                }}> {opt.name}</Typography>
                  </Button>)}
              </Box>
            </Box>
          </Paper>
        </ClickAwayListener>
      </Popper>

      <Dialog PaperProps={{
      style: {
        borderRadius: '28px',
        minWidth: '350px'
      }
    }} open={createNewFolderDialog} onClose={() => {
      setCreateNewFolderDialog(false);
    }}>
        <DialogTitle sx={{
        m: 0,
        p: 2,
        fontSize: 'medium'
      }}>
          <IconButton aria-label="close" onClick={e => {
          setCreateNewFolderDialog(false);
        }} sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500]
        }}>
            <CloseIcon />
          </IconButton>
          <Stack alignItems="center" component="span">
            <Typography variant="h4" component="span">
              Create New Folder
            </Typography>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Box component="span">
            <CustomTextField placeholder="Folder Name" onChange={e => setNewFolderName(e.target.value)} value={newFolderName} focused autoFocus fullWidth onFocus={event => {
            event.target.select();
          }} onKeyPress={e => {
            if (e.key === 'Enter' && e.target.value != '') {
              makeNewFolder(e.target.value);
            }
          }} />
          </Box>
        </DialogContent>
        <DialogActions sx={{
        py: 1
      }}>
          <Button onClick={() => {
          setCreateNewFolderDialog(false);
          setNewFolderName('');
        }} variant="text" sx={{
          borderRadius: '20px',
          color: 'rgba(29, 37, 45, 0.7)',
          width: '75px',
          textTransform: 'none'
        }}>
            Cancel
          </Button>
          <Button disabled={!newFolderName} onClick={() => {
          makeNewFolder(newFolderName);
        }} variant="text" sx={{
          mr: 1,
          borderRadius: '20px',
          width: '75px',
          color: '#53A6D6',
          textTransform: 'none'
        }}>
            Create
          </Button>
        </DialogActions>
      </Dialog>

      {folderSelectDialog.length > 0 && <FolderSelectDialog data={folderSelectDialog} onClose={onClose} onSubmit={onSubmitData} folderModel="form" />}

      <TagModal onClose={() => setTagModal(null)} onSuccess={() => {
      getFormsTags(asCompany.id).then(({
        data
      }) => {
        setRenderIteration(i => i + 1);
      });
    }} objectTarget={tagModal} getViewTags={getFormsTags} patchView={patchForm} viewName={'Forms'} />

      <NewModal title={canArchive() ? 'Archive Form(s)' : 'Cannot Archive Form(s)'} onClose={closeArchiveModal} open={archiveModal.length > 0} allowBackdropClick>
        <div className="modal__body">
          {canArchive() ? <>
              <p>Are you sure you would like to archive the following forms?</p>
              {archiveModal.map(form => <div key={form.id}>{form.name}</div>)}
            </> : archiveModal.filter(s => s.journeys.length > 0).map(form => <>
                  <p>
                    The form <strong>{form.name} </strong> cannot be archived because it is used in the following places.
                  </p>
                  {!!form.journeys.length && <>
                      <h4>Journeys</h4>
                      <Box sx={{
              px: 2
            }}>
                        {form.journeys.map((j, index) => {
                return <Button key={index} onClick={e => {
                  closeArchiveModal();
                  history.push(`/customer-journeys/${j.id}`);
                }}>
                              {j.name}
                            </Button>;
              })}
                      </Box>
                    </>}
                </>)}
        </div>
        <div className="modal__actions">
          {canArchive() ? <>
              <Button actionType="flat" onClick={closeArchiveModal}>
                Cancel
              </Button>
              <Button actionType="primary" onClick={() => archiveForm()}>
                Confirm
              </Button>
            </> : <Button actionType="primary" onClick={closeArchiveModal}>
              Okay
            </Button>}
        </div>
      </NewModal>

      <NewModal title={canDelete() ? 'Delete item(s)' : 'Cannot Delete Item(s)'} onClose={closeDeleteModal} open={deleteModal.length > 0} allowBackdropClick>
        <div className="modal__body">
          {canDelete() ? <>
              <p>Are you sure you would like to delete the following items? </p>
              {deleteModal.some(i => i._type == 'folder') && <Stack sx={{
            p: 1
          }}>
                  <Typography component="span">Folders:</Typography>
                  <em component="span">
                    If these folders have any forms in it, the forms inside them will not be deleted, but will be moved back to the home
                    folder.
                  </em>
                  {deleteModal.filter(s => s._type == 'folder').map(folder => <Box component="span" key={folder.id} sx={{
              py: 1,
              pl: 2
            }}>
                        {folder.name}
                      </Box>)}
                </Stack>}

              {deleteModal.some(i => i._type == 'rowItem') && <Stack sx={{
            p: 1
          }}>
                  <Typography component="span">Forms:</Typography>
                  {deleteModal.filter(s => s._type == 'rowItem').map(form => <Box sx={{
              py: 1,
              pl: 2
            }} component="span" key={form.id}>
                        {form.name}
                      </Box>)}
                </Stack>}
            </> : deleteModal.filter(s => s._type == 'rowItem').filter(s => s.journeys.length > 0).map(form => <>
                  <p>
                    The form <strong>{form.name} </strong> cannot be deleted because it is used in the following places.
                  </p>
                  {!!form.journeys.length && <>
                      <h4>Journeys</h4>
                      <Box sx={{
              px: 2
            }}>
                        {form.journeys.map((j, index) => {
                return <Button key={index} onClick={e => {
                  closeArchiveModal();
                  history.push(`/customer-journeys/${j.id}`);
                }}>
                              {j.name}
                            </Button>;
              })}
                      </Box>
                    </>}
                </>)}
        </div>
        <div className="modal__actions">
          {canDelete() ? <>
              <Button actionType="flat" onClick={closeDeleteModal}>
                Cancel
              </Button>
              <Button actionType="primary" onClick={() => deleteForms()}>
                Confirm
              </Button>
            </> : <Button actionType="primary" onClick={closeDeleteModal}>
              Okay
            </Button>}
        </div>
      </NewModal>
    </>;
};
const css = {
  styles: `[kremling="i15"] body,body[kremling="i15"] {
  --color-primary: #487aae;
  --color-highlight: #e7efff;
  --color-accent: #354052;
  --color-success: #73b56e;
  --color-warning: #f4b707;
  --color-danger: #df5651;
  --color-grey-10: #f9f9f9;
  --color-grey-25: #f3f3f3;
  --color-grey-50: #e9e9e9;
  --color-grey-75: #e3e3e3;
  --color-grey-100: #d3d3d3;
  --color-grey-200: #c3c3c3;
  --color-grey-300: #b3b3b3;
  --color-grey-400: #a3a3a3;
  --color-grey-500: #808080;
  --color-grey-600: #707070;
  --color-grey-700: #606060;
  --color-grey-800: #505050;
  --color-grey-900: #404040;
  --base-font-family: Roboto, Helvetica, Arial, sans-serif;
  --base-font-size: 1.4rem;
  --base-font-color: #404040;
  --base-font-weight: 400;
  --base-line-height: 1.4;
  --box-shadow-1: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.12);
  --box-shadow-2: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-3: 0 10px 20px rgba(0, 0, 0, 0.17), 0 6px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-4: 0 14px 28px rgba(0, 0, 0, 0.13), 0 10px 10px rgba(0, 0, 0, 0.1);
  --box-shadow-5: 0 19px 38px rgba(0, 0, 0, 0.18), 0 15px 12px rgba(0, 0, 0, 0.1);
  --base-border-radius: 0.4rem;
}`,
  id: 'i15',
  namespace: 'kremling'
};