import React from 'react';
function Icon(props) {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none" viewBox="0 0 24 25">
      <mask id="mask0_65_4078" style={{
      maskType: 'alpha'
    }} width="24" height="25" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0 0.5H24V24.5H0z"></path>
      </mask>
      <g mask="url(#mask0_65_4078)">
        <path fill={props.fill || '#1D252D'} d="M10 18.25a.727.727 0 01-.75-.75.728.728 0 01.75-.75h10c.217 0 .396.07.538.212a.731.731 0 01.212.538c0 .217-.07.396-.212.538a.73.73 0 01-.538.212H10zm-6-10a.726.726 0 01-.75-.75.726.726 0 01.75-.75h16a.728.728 0 01.75.75.728.728 0 01-.75.75H4zm6 5a.726.726 0 01-.75-.75.728.728 0 01.75-.75h10c.217 0 .396.07.538.212a.731.731 0 01.212.538.728.728 0 01-.75.75H10z"></path>
      </g>
    </svg>;
}
export default Icon;