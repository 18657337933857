import React from 'react';
function Icon(props) {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <g>
        <mask id="mask0_1106_3176" style={{
        maskType: 'alpha'
      }} width="25" height="24" x="-1" y="0" maskUnits="userSpaceOnUse">
          <path fill="#D9D9D9" d="M-0.001 0H23.999V24H-0.001z"></path>
        </mask>
        <g mask="url(#mask0_1106_3176)">
          <path fill="#1D252D" d="M11.999 4.5c.658 0 1.286.06 1.886.179.599.12 1.183.303 1.752.552.203.08.405.225.605.432.2.208.252.405.154.59-.098.187-.31.31-.636.371-.325.06-.594.051-.807-.03a7.194 7.194 0 00-1.43-.448A7.731 7.731 0 0011.998 6c-2.217 0-4.104.78-5.663 2.337C4.779 9.896 4 11.783 4 14c0 .7.096 1.392.288 2.075.191.683.462 1.325.812 1.925h13.8a7.3 7.3 0 00.837-1.975A8.532 8.532 0 0020 13.9c0-.472-.047-.956-.141-1.453a6.603 6.603 0 00-.444-1.432 1.048 1.048 0 01-.05-.623.862.862 0 01.337-.503.596.596 0 01.537-.1.607.607 0 01.413.353c.288.629.5 1.247.633 1.855.133.608.205 1.232.215 1.872.01.905-.092 1.76-.306 2.567a10.208 10.208 0 01-.967 2.364 1.388 1.388 0 01-.558.512 1.64 1.64 0 01-.769.188h-13.8a1.56 1.56 0 01-.755-.192 1.503 1.503 0 01-.572-.546 10.197 10.197 0 01-.923-2.181A9.051 9.051 0 012.499 14c0-1.306.248-2.534.744-3.684a9.694 9.694 0 012.03-3.021 9.52 9.52 0 013.024-2.046A9.255 9.255 0 0112 4.5z"></path>
          <path fill={props.fill || '#1D252D'} d="M11.975 15.852c-.56.018-1.017-.15-1.372-.506-.355-.355-.517-.804-.486-1.347.031-.543.243-.945.636-1.207l5.85-4.024c.217-.143.412-.126.588.05.176.175.192.37.049.586l-4.033 5.842c-.262.386-.673.588-1.232.606z"></path>
        </g>
      </g>
    </svg>;
}
export default Icon;