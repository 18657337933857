import React, { useContext, useState } from 'react';
import { Box, Stack, Dialog, DialogActions, DialogContent, IconButton, DialogTitle, Typography, Divider, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { UserStateContext } from 'context/user-state-context';
import { CloseIcon } from 'components/mui';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};
export const ModalCompanySelectMui = props => {
  const {
    asCompany,
    user
  } = useContext(UserStateContext);
  const [selectedCompany, setSelectedCompany] = useState(asCompany.id);
  return <>
      <Dialog scroll="paper" PaperProps={{
      style: {
        borderRadius: '28px',
        width: '55%'
      }
    }} open={props?.open} onClose={(e, r) => {
      if (props?.allowBackdropClick) {
        props?.onCancel();
        props?.onClose();
      }
    }}>
        <DialogTitle sx={{
        m: 0,
        p: 2,
        fontSize: 'medium'
      }}>
          <Stack spacing={'20px'}>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Stack direction="row" spacing={'10px'}>
                <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                  <Typography sx={{
                  fontWeight: '300',
                  lineHeight: '30px',
                  fontSize: '24px',
                  display: 'flex',
                  alignItems: 'center'
                }} variant="h4" component="span">
                    {props.title || 'Select company to use'}
                  </Typography>
                </Box>
              </Stack>

              <IconButton onClick={() => {
              props?.onCancel();
              props?.onClose();
            }}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Divider />
          </Stack>
        </DialogTitle>

        <DialogContent>
          <Stack sx={{
          pt: 1
        }} spacing={'24px'}>
            {props.children}

            <FormControl>
              <InputLabel id="company-select-label">Company</InputLabel>
              <Select labelId="company-select-label" id="company-select" value={selectedCompany} label="Company" onChange={event => {
              setSelectedCompany(event.target.value);
            }} MenuProps={MenuProps}>
                {user?.companies.map(company => <MenuItem value={company.id} key={company.id}>
                    <Typography sx={{
                  wordWrap: 'break-word'
                }}>{company.name} </Typography>
                  </MenuItem>)}
              </Select>
            </FormControl>
          </Stack>
        </DialogContent>

        <DialogActions sx={{
        pt: 1,
        pb: 2
      }}>
          <Stack sx={{
          pr: '24px'
        }} direction={'row'} spacing={'20px'}>
            <Button onClick={() => {
            props.onCancel();
            props.onClose();
          }} variant="contained" sx={{
            borderRadius: '20px',
            textTransform: 'none'
          }}>
              Cancel
            </Button>
            <Button onClick={() => {
            props.onSubmit(selectedCompany);
            props.onClose();
          }} variant="contained" sx={{
            mr: 1,
            borderRadius: '20px',
            textTransform: 'none',
            px: '20px'
          }}>
              {props.submitText || 'Select'}
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </>;
};