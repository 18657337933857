import React from 'react';
function Icon(props) {
  return <svg id="layer" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 652 652" style={{
    enableBackground: "new 0 0 652 652"
  }} xmlSpace="preserve" {...props}>
    <style type="text/css">
      {"\n\t.st0{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}\n\t.st1{fill-rule:evenodd;clip-rule:evenodd;}\n\t.st2{fill:#FFFFFF;}\n"}
    </style>
    <g>
      <path className="st0" d="M598,365.8c0,4.8-4,8.6-8.8,8.6h-557c-4.9,0-8.8-3.9-8.8-8.6v-90.4c0-4.8,4-8.6,8.8-8.6h557 c4.9,0,8.8,3.9,8.8,8.6V365.8z" />
      <path className="st1" d="M32.1,264.3c-6.2,0-11.2,5-11.2,11v90.4c0,6.1,5,11,11.2,11h557c6.2,0,11.3-5,11.3-11v-90.4 c0-6.1-5-11-11.3-11H32.1z M25.7,365.8v-90.4c0-3.4,2.9-6.2,6.4-6.2h557c3.6,0,6.4,2.8,6.4,6.2v90.4c0,3.4-2.9,6.2-6.4,6.2h-557 C28.6,372,25.7,369.2,25.7,365.8" />
      <rect x={502.3} y={274.7} className="st2" width={87.4} height={91.5} />
      <path d="M501.1,367.4H591v-93.9h-89.9V367.4z M588.6,365h-85v-89.1h85V365z" />
      <rect x={126} y={274.9} width={87.5} height={91.5} />
      <path d="M124.8,367.5h89.9v-93.9h-89.9V367.5z M212.3,365.1h-85v-89.1h85V365.1z" />
      <rect x={219.8} y={274.9} width={87.5} height={91.5} />
      <path d="M218.6,367.5h89.9v-93.9h-89.9V367.5z M306.1,365.1h-85v-89.1h85V365.1z" />
      <rect x={314.2} y={274.9} width={87.4} height={91.5} />
      <path d="M313,367.5h89.9v-93.9H313V367.5z M400.4,365.1h-85v-89.1h85V365.1z" />
      <rect x={408.6} y={274.9} width={87.5} height={91.5} />
      <path d="M407.4,367.5h89.9v-93.9h-89.9V367.5z M494.8,365.1h-85v-89.1h85V365.1z" />
      <rect x={31.7} y={274.9} width={87.4} height={91.5} />
      <path d="M30.5,367.5h89.9v-93.9H30.5V367.5z M117.9,365.1h-85v-89.1h85V365.1z" />
      <path className="st2" d="M77.5,353.5c-18.6,0-28.6-13.8-28.6-32.4c0-20.2,11.8-32.4,28.7-32.4c15.4,0,21.5,6.6,25.8,18.5l-15.8,6.2 c-2.2-5.8-4.4-9.5-10.1-9.5c-7.3,0-10.4,7.3-10.4,17.2c0,9.6,3,17.3,10.6,17.3c5.5,0,7.8-3,11.2-8.9l14.7,7.8 C99.3,345.6,92.3,353.5,77.5,353.5" />
      <path className="st2" d="M175.2,327h-10.4l1.2-4.1c2.2-8,3.2-11.5,4-15.7c0.8,4.2,1.8,7.6,4,15.7L175.2,327z M201.6,351.8l-22.8-62.7 h-17.6l-22.8,62.7h18.6l3.4-11.1h18.8l3.4,11.1H201.6z" />
      <path className="st2" d="M272.3,310.3c0,4.5-2.2,7-7.9,7h-10.2v-13.7h10.5C270.2,303.6,272.3,306.4,272.3,310.3 M290.9,352.1 l-12.6-23.9c6.9-3.1,11.3-8.9,11.3-18.4c0-14.9-9.8-20.4-24.5-20.4h-28.1v62.7h17.1V331h7l10.2,21.2H290.9z" />
      <polygon className="st2" points="350.3,304.2 350.3,312.9 370,312.9 370,327.6 350.3,327.6 350.3,352.1 333,352.1 333,289.4  382.7,289.4 382.7,304.2  " />
      <path className="st2" d="M456.9,327.5h-10.4l1.2-4.1c2.2-8,3.2-11.5,4-15.7c0.8,4.2,1.8,7.6,4,15.7L456.9,327.5z M483.3,352.3 l-22.8-62.7H443l-22.8,62.7h18.6l3.4-11.1h18.8l3.4,11.1H483.3z" />
      <path d="M554.8,367.1l-2.8-4.3c-2.5-3.9-4.9-8.1-6.6-11.5c-1.6,3.3-4.1,7.6-6.7,11.5l-3.1,4.3h-21.3l20.6-28.6l-21.7-30.9h22.1 l3.8,6.1c2.4,3.9,5.1,8.1,6.6,11.4c1.6-3.3,4.2-7.5,6.7-11.4l3.8-6.1h21.6l-21.7,30.5l20.3,28.9H554.8z" />
      <path d="M620.7,273.1c0,2.3-0.8,4.3-2.4,5.9c-1.6,1.6-3.5,2.4-5.8,2.4c-2.3,0-4.2-0.8-5.8-2.4c-1.6-1.6-2.4-3.6-2.4-5.9 c0-2.4,0.8-4.4,2.4-6c1.6-1.6,3.5-2.4,5.8-2.4c2.3,0,4.2,0.8,5.8,2.4C619.9,268.8,620.7,270.8,620.7,273.1 M619.4,273.1 c0-1.9-0.7-3.6-2-5c-1.3-1.4-2.9-2.1-4.8-2.1c-1.9,0-3.6,0.7-5,2.1c-1.4,1.4-2,3.1-2,5.1c0,2,0.7,3.6,2,5c1.4,1.4,3,2.1,4.9,2.1 c1.9,0,3.5-0.7,4.8-2.1C618.7,276.8,619.4,275.1,619.4,273.1 M617.1,270.9c0,0.9-0.3,1.7-1,2.2c-0.6,0.5-1.4,0.8-2.2,0.8l3,4h-1.9 l-3-4h-1.7v4h-1.5v-9.9h4.8c1.1,0,1.9,0.3,2.5,0.8C616.9,269.4,617.1,270.1,617.1,270.9 M615.6,270.9c0-1-0.8-1.5-2.3-1.5h-2.9v3.2 h3C614.9,272.6,615.6,272.1,615.6,270.9" />
      <path d="M606.6,267.1c-1.6,1.7-2.4,3.7-2.4,6.1c0,2.3,0.8,4.4,2.5,6c1.6,1.6,3.6,2.5,5.9,2.5c2.3,0,4.3-0.8,5.9-2.5 c1.6-1.7,2.4-3.7,2.4-6c0-2.4-0.8-4.4-2.4-6c-1.6-1.7-3.6-2.5-5.9-2.5C610.2,264.6,608.2,265.4,606.6,267.1 M606.9,278.9 c-1.6-1.6-2.4-3.5-2.4-5.8c0-2.3,0.8-4.3,2.3-5.9c1.6-1.6,3.5-2.4,5.7-2.4c2.2,0,4.1,0.8,5.7,2.4c1.6,1.6,2.3,3.6,2.3,5.8 c0,2.3-0.8,4.2-2.3,5.8c-1.6,1.6-3.5,2.4-5.7,2.4C610.4,281.3,608.4,280.5,606.9,278.9" />
      <path d="M607.6,268c-1.4,1.4-2.1,3.2-2.1,5.2c0,2,0.7,3.7,2.1,5.1c1.4,1.4,3.1,2.1,5,2.1c1.9,0,3.6-0.7,4.9-2.2 c1.4-1.4,2.1-3.2,2.1-5.1c0-2-0.7-3.7-2.1-5.1c-1.4-1.4-3-2.2-4.9-2.2C610.7,265.8,609,266.5,607.6,268 M607.8,278.1 c-1.3-1.4-2-3-2-4.9c0-1.9,0.7-3.6,2-5c1.3-1.4,3-2.1,4.8-2.1c1.8,0,3.3,0.7,4.7,2.1c1.3,1.4,2,3,2,4.9c0,1.9-0.7,3.6-2,5 c-1.3,1.4-2.9,2.1-4.7,2.1C610.7,280.1,609.1,279.4,607.8,278.1" />
      <path d="M608.8,267.9v10.2h1.8v-4h1.6l2.9,3.9l2,0.1h0.3c0,0-2.8-3.7-3-4c0.8-0.1,1.5-0.3,2.1-0.8c0.7-0.6,1-1.4,1-2.3 c0-0.9-0.3-1.6-0.9-2.2c-0.6-0.6-1.5-0.9-2.6-0.9H608.8z M613.8,268.2c1,0,1.8,0.3,2.4,0.8c0.5,0.5,0.8,1.2,0.8,2 c0,0.9-0.3,1.6-0.9,2.1c-0.6,0.5-1.3,0.8-2.1,0.8h-0.3c0,0,2.7,3.6,3,4h-1.6l-2.9-3.9l-1.8-0.1h-0.1v4h-1.2v-9.6H613.8z" />
      <path d="M610.3,269.3v3.5h3.1c1.5,0,2.3-0.6,2.3-1.9c0-0.6-0.2-1-0.6-1.2c-0.4-0.3-1-0.4-1.8-0.4H610.3z M613.3,269.6 c0.7,0,1.3,0.1,1.6,0.3c0.3,0.2,0.5,0.6,0.5,1c0,1.1-0.7,1.6-2,1.6h-2.9v-2.9H613.3z" />
    </g>
  </svg>;
}
export default Icon;