import React from "react";
function Icon() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" fill="none" viewBox="0 0 21 20">
      <mask id="mask0_38_2888" style={{
      maskType: "alpha"
    }} width="21" height="20" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0.5 0H20.5V20H0.5z"></path>
      </mask>
      <g mask="url(#mask0_38_2888)">
        <path fill="#53A6D6" d="M9.298 16.377h2.404c.1 0 .187-.037.26-.11a.355.355 0 00.108-.259c0-.1-.036-.187-.108-.26a.354.354 0 00-.26-.109H9.298c-.1 0-.187.037-.26.11a.354.354 0 00-.108.259c0 .1.036.187.108.26.073.072.16.109.26.109zM6.673 18.75c-.438 0-.813-.155-1.124-.466a1.532 1.532 0 01-.466-1.124V2.84c0-.439.156-.813.466-1.124.311-.31.686-.466 1.124-.466h7.654c.438 0 .813.155 1.124.466.31.31.466.685.466 1.124v14.32c0 .439-.156.813-.466 1.124-.311.31-.686.466-1.124.466H6.673zm-.256-5.484h8.166V5H6.417v8.266zm0 1.333v2.561a.25.25 0 00.072.184.25.25 0 00.184.073h7.654a.25.25 0 00.184-.073.25.25 0 00.072-.184V14.6H6.417zm0-10.932h8.166V2.84a.25.25 0 00-.072-.185.25.25 0 00-.184-.072H6.673a.25.25 0 00-.184.072.25.25 0 00-.072.185v.827z"></path>
      </g>
    </svg>;
}
export default Icon;