import React, { useState, useRef, useEffect, useContext } from 'react';
import { Scoped } from 'kremling';
import Papa from 'papaparse';
import { get, set } from 'lodash';
import { getCSVImportSources, importCSV, getSegments, patchCompany, previewCSV, getCompanyFields } from 'shared/common.api';
import styles from './upload.styles.scss';
import { UserStateContext } from 'context/user-state-context';
import constants from 'shared/constants';
import { Dashboard } from '@uppy/react';
import Uppy from '@uppy/core';
import AwsS3Multipart from '@uppy/aws-s3-multipart';
import { createMultipartUpload, listParts, prepareUploadPart, completeMultipartUpload, abortMultipartUpload } from 'components/file-viewer/provider';
import { toasterService, PageHeaderMui, Icon, DynamicTypeAheadMui } from 'components';
import { Box, Stack, Typography, Paper, Divider, IconButton, Button as MUIButton, TextField, MenuItem, Select, Grid, Dialog, DialogContent, DialogActions, Checkbox, FormControl, InputLabel } from '@mui/material';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import { ArrowRightAltIcon, CloseIcon, CsvImportWizardIcon, HelpIcon, ImportIcon, WarningRedIcon, WarningTriangleFilledIcon } from 'components/mui';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { cloneDeep } from 'lodash';
import { sortFields } from '../data-fields/utils';
export const CsvOptStatus = props => {
  const {
    asCompany,
    companyFields
  } = useContext(UserStateContext);
  let fields, numRecords;
  const customerFields = useRef([]);
  const [uploadedFile, setUploadedFile] = useState();
  const [confirmUpload, setConfirmUpload] = useState(false);
  const [importSources, setImportSources] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [uppy, setUppy] = useState();
  const [agree1, setAgree1] = useState(false);
  const [agree2, setAgree2] = useState(false);
  const [isSelectingOptions, setIsSelectingOptions] = useState(false);
  const [isUploadingFile, setIsUploadingFile] = useState(true);
  const [wizardStepCount, setWizardStepCount] = useState(0);
  const [loseStepDataWarning, setLoseStepDataWarning] = useState(false);
  const [modelFields, setModelFields] = useState([]);
  const [hoverIconColorButton1, setHoverIconColorButton1] = useState('#3898D9');
  const [hoverIconColorButton2, setHoverIconColorButton2] = useState('#3898D9');
  const [newFieldValue, setNewFieldValue] = useState([]);
  const [options, setOptions] = useState([]);
  const [customerAllFields, setCustomerAllFields] = useState([]);
  const LightTooltip = styled(({
    className,
    ...props
  }) => <Tooltip {...props} classes={{
    popper: className
  }} />)(({
    theme
  }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#fff',
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 14,
      maxWidth: '312px',
      borderRadius: '16px',
      padding: '12px',
      lineHeight: '16px'
    }
  }));
  useEffect(() => {
    /**
     * Load company fields on mount
     */
    const companyId = asCompany.id;
    Promise.all([getCompanyFields(companyId)]).then(([{
      data: fields
    }]) => {
      setModelFields(fields);
    }
    // compare and output the differnet fields between fields and allfields
    );
  }, [asCompany]);
  useEffect(() => {
    const customerModel = companyFields.find(model => model.id === 'customer');
    const allCustomerFields = get(customerModel, 'fields') || [];
    const filteredCustomerFields = allCustomerFields.filter(f => {
      return !constants.READ_ONLY_CUSTOMER_FIELDS.some(cf => cf === f.id.substr(0, cf.length));
    });
    customerFields.current = filteredCustomerFields;
    getCSVImportSources({
      company: asCompany.id
    }).then(({
      data
    }) => {
      setImportSources(data.map(r => r.source));
    });
    const uppy = new Uppy({
      autoProceed: true,
      restrictions: {
        maxNumberOfFiles: 1,
        minNumberOfFiles: 1,
        maxTotalFileSize: 25000000,
        // Max file size 25mb
        allowedFileTypes: ['.csv']
      },
      disableThumbnailGenerator: true,
      showRemoveButtonAfterComplete: false,
      fileManagerSelectionType: 'files',
      allowMultipleUploads: false
    }).use(AwsS3Multipart, {
      companionUrl: API_URL + `/company-files/${asCompany.id}/upload`,
      parentFolder: 'csv/',
      createMultipartUpload,
      listParts,
      prepareUploadPart,
      completeMultipartUpload,
      abortMultipartUpload
    }).on('file-added', file => {
      handleFile(file.data);
    }).on('upload-success', file => {
      if (!fields) {
        uppy.reset();
        toasterService.error('Unable to parse any headers from the CSV file. Please check the file and try again.');
        return;
      }
      if (!numRecords) {
        uppy.reset();
        toasterService.error('CSV file does not contain any data. Please check the file and try again.');
        return;
      }
      const maps = {};
      const tempMap = {};
      customerFields.current.forEach(field => {
        tempMap[field.id.toUpperCase()] = field.id;
        tempMap[field.name.toUpperCase()] = field.id;
      });
      fields.forEach(field => {
        if (tempMap[field.toUpperCase()]) {
          maps[field] = tempMap[field.toUpperCase()];
        }
      });
      setUploadedFile({
        company: asCompany.id,
        filename: file.name,
        total_records: numRecords,
        source: 'CSV Upload',
        other: '',
        fields,
        unique_id: '',
        maps,
        new_fields: {},
        segment_option: 'new',
        new_segment: file.name,
        segment_id: '',
        model: 'phone',
        opt: '',
        column: '',
        columnName: ''
      });
    });
    setUppy(uppy);
  }, []);
  const handleFile = file => {
    const fr = new FileReader();
    fr.onload = function () {
      const data = Papa.parse(fr.result);
      const rows = data.data.filter(r => r.join('').trim() !== '');
      fields = rows.shift();
      numRecords = Math.max(0, rows.length);
    };
    try {
      fr.readAsText(file);
    } catch (e) {
      // For some unknown reason this throws an error but it still works *facepalm*
    }
  };
  const upload = () => {
    if (uploadedFile.source === 'other' && uploadedFile.other === '') {
      toasterService.error('Source set as "other" but not specified.');
      return;
    }
    if (uploadedFile.segment_option === 'new' && uploadedFile.new_segment === '') {
      toasterService.error('New segment must be given a name.');
      return;
    }
    if (uploadedFile.segment_option === 'select' && uploadedFile.new_segment === 'segment_id') {
      toasterService.error('A segment must be selected.');
      return;
    }

    // TODO make sure at least one field is mapped.
    setConfirmUpload(true);
  };
  const actuallyUpload = () => {
    //setIsUploading(true);

    setIsUploading(true);
    importCSV({
      ...uploadedFile,
      maps: {
        [uploadedFile.columnName]: uploadedFile.model
      },
      type: `${uploadedFile.opt}_${uploadedFile.model}`
    }).then(() => {
      props.history.push({
        pathname: '/imports'
      });
    }).catch(() => {
      toasterService.error('Unknown error occured uploading the CSV. Please try again.');
    }).then(() => {
      setIsUploading(false);
    });
  };
  const mapField = (field, val) => {
    const maps = {
      ...uploadedFile.maps,
      [field]: val
    };
    Object.keys(maps).forEach(key => {
      if (val && val !== 'NEW' && val !== '' && key !== field && maps[key] === val) {
        maps[key] = '';
      }
    });
    setUploadedFile({
      ...uploadedFile,
      maps
    });
  };
  return <Scoped css={styles}>
      <div className="wrapper">
        <PageHeaderMui name="Import Data" icon={<Icon name="custom-exit_to_app" size={34} />} />
        <Paper sx={{
        position: 'relative',
        height: '88vh',
        width: '874px',
        margin: '0 auto',
        padding: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 10px rgba(0,0,0,0.1)',
        backgroundColor: '#fff',
        marginTop: '20px',
        marginBottom: '20px'
      }}>
          <Stack direction="row" sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          pb: '10px'
        }}>
            <Box>
              <Typography sx={{
              fontSize: '20px'
            }}>{wizardStepCount === 0 ? 'Import Data Overview' : 'Import Configuration'}</Typography>
            </Box>
            <Box sx={{
            marginLeft: 'auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end'
          }}>
              <IconButton onClick={() => props.history.push('/imports')}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Stack>
          <Divider id="top-wizard-divider" sx={{
          ml: '-20px',
          mr: '-20px'
        }} />
          <Box sx={{
          pt: '20px',
          pr: '80px'
        }}>
            <Typography sx={{
            textAlign: 'justify'
          }}>
              {wizardStepCount === 1 ? `Congratulations on successfully uploading your CSV file! On this page, you have the option to label the source of the CSV file. For instance, if it's a list of customers who signed up from an event, you can create a custom source named 'Event Name 12-31-24 Sign up.' Additionally, you can choose to add these customers to a new or existing static segment.` : `Utilize this page to easily upload a list of email addresses or phone numbers, where you can conveniently choose to either include or exclude them from receiving communications. By uploading a file, the communication preferences for the respective customers will be automatically updated.`}
            </Typography>
          </Box>
          {isUploadingFile && <Box id="content-container-0" sx={{
          height: '88vh'
        }}>
              <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            py: '20px'
          }}>
                <CsvImportWizardIcon />
              </Box>

              {uppy && <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            pt: '15px',
            pb: '85px'
          }}>
                  <Dashboard width={370} height={322} uppy={uppy} />
                </Box>}
            </Box>}
          {isSelectingOptions && uploadedFile && <Box id="content-container-1" sx={{
          height: '61vh',
          overflowY: 'auto'
        }}>
              <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            py: '20px'
          }}>
                <Typography sx={{
              textAlign: 'justify',
              pr: '10px'
            }}>
                  File info
                </Typography>
                <LightTooltip title={<Grid container>
                      <Grid item>
                        <Typography variant="caption" sx={{
                  fontWeight: 'bold'
                }}>
                          File info
                        </Typography>
                        <br />
                        <Typography variant="caption" sx={{
                  textAlign: 'justify'
                }}>
                          This is the information about the file you uploaded.
                        </Typography>
                        <br />
                      </Grid>
                    </Grid>} placement="top-start">
                  <Box>
                    <HelpIcon fill={'#53A6D6'} />
                  </Box>
                </LightTooltip>
              </Box>
              <Stack direction="row" spacing={3}>
                <Box>
                  <TextField sx={{
                width: '389px'
              }} id="textfield-filename" label="Filename" variant="outlined" autoComplete="off" fullWidth value={uploadedFile.filename} disabled />
                </Box>
                <Box>
                  <TextField sx={{
                width: '389px'
              }} id="textfield-number-of-rows" label="Number of rows" variant="outlined" autoComplete="off" fullWidth value={uploadedFile.total_records} disabled />
                </Box>
              </Stack>
              <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            pb: '20px',
            pt: '20px'
          }}>
                <Typography sx={{
              textAlign: 'justify',
              pr: '10px'
            }}>
                  File source
                </Typography>
                <LightTooltip title={<Grid container>
                      <Grid item>
                        <Typography variant="caption" sx={{
                  fontWeight: 'bold'
                }}>
                          File Source
                        </Typography>
                        <br />
                        <Typography variant="caption" sx={{
                  textAlign: 'justify'
                }}>
                          Specify which source the file came from. This is important for tracking and reporting purposes.
                        </Typography>
                        <br />
                      </Grid>
                    </Grid>} placement="top-start">
                  <Box>
                    <HelpIcon fill={'#53A6D6'} />
                  </Box>
                </LightTooltip>
              </Box>
              <Stack direction="row" spacing={3}>
                <Box>
                  <Select defaultValue={uploadedFile.source} onChange={e => setUploadedFile({
                ...uploadedFile,
                source: e.target.value
              })} variant="outlined" sx={{
                width: '389px'
              }}>
                    <MenuItem value="CSV Upload">CSV Upload</MenuItem>
                    <MenuItem value="Other">Other (specify)</MenuItem>
                    <MenuItem value="None">None</MenuItem>
                  </Select>
                </Box>
                {uploadedFile.source === 'Other' && <Box>
                    <TextField sx={{
                width: '389px'
              }} id="textfield-other" error={uploadedFile.source === 'Other' && uploadedFile.other.trim() === ''} helperText={uploadedFile.source === 'Other' && uploadedFile.other.trim() === '' ? 'Please specify the source' : ''} label="Other*" variant="outlined" autoComplete="off" fullWidth defaultValue={uploadedFile.other} onChange={e => setUploadedFile({
                ...uploadedFile,
                other: e.target.value
              })} />
                  </Box>}
              </Stack>
              <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            pb: '20px',
            pt: '20px'
          }}>
                <Typography sx={{
              textAlign: 'justify',
              pr: '10px'
            }}>
                  Add to segment
                </Typography>
                <LightTooltip title={<Grid container>
                      <Grid item>
                        <Typography sx={{
                  fontWeight: 'bold'
                }}>Add to segment</Typography>
                        <br />
                        <Typography sx={{
                  textAlign: 'justify'
                }}>
                          Choose whether to add the customers in this file to a static segment. You can create a new segment or select an
                          existing one. If you don't want to add customers to a segment, select "None". By adding customers to a segment,
                          you can easily target them in future campaigns.
                        </Typography>
                        <br />
                      </Grid>
                    </Grid>} placement="top-start">
                  <Box>
                    <HelpIcon fill={'#53A6D6'} />
                  </Box>
                </LightTooltip>
              </Box>
              <Stack direction="row" spacing={3}>
                <Box>
                  <Select value={uploadedFile.segment_option} onChange={e => setUploadedFile({
                ...uploadedFile,
                segment_option: e.target.value
              })} variant="outlined" sx={{
                width: '389px'
              }}>
                    <MenuItem value="new">New Static Segment</MenuItem>
                    <MenuItem value="select">Select Segment</MenuItem>
                    <MenuItem value="None">None</MenuItem>
                  </Select>
                </Box>
                <Box>
                  {uploadedFile.segment_option === 'new' ? <TextField key="new-segment" sx={{
                width: '389px'
              }} id="textfield-new-segment" label="Static Segment" variant="outlined" autoComplete="off" fullWidth defaultValue={uploadedFile.filename || ''} onChange={e => setUploadedFile({
                ...uploadedFile,
                new_segment: e.target.value,
                segment_id: ''
              })} /> : uploadedFile.segment_option === 'select' ? <DynamicTypeAheadMui key="select-segment" sx={{
                width: '389px'
              }} getItems={getSegments} getItemsFilters={{
                time_dynamic_segment: false,
                company: asCompany.id
              }} placeholder="Choose Customer Segment" displayProperty="name" keyProperty="id" defaultValue={uploadedFile.segment_id} onChange={val => {
                setUploadedFile({
                  ...uploadedFile,
                  segment_id: val ? val.id : '',
                  new_segment: ''
                });
              }} /> : <></>}
                </Box>
              </Stack>
              <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            pb: '20px',
            pt: '20px'
          }}>
                <Typography sx={{
              textAlign: 'justify',
              pr: '10px'
            }}>
                  Opt Status
                </Typography>
                <LightTooltip title={<Grid container>
                      <Grid item>
                        <Typography sx={{
                  fontWeight: 'bold'
                }}>Opt Status</Typography>
                        <br />
                        <Typography sx={{
                  textAlign: 'justify'
                }}>
                          Select between Phone Number or Email for the targeted opt audience. Then, specify the opt status of the audience.
                          It is important to specify the correct status of the audience so that audiences that have opted in are not opted
                          out and vice-versa.{' '}
                        </Typography>
                        <br />
                      </Grid>
                    </Grid>} placement="top-start">
                  <Box>
                    <HelpIcon fill={'#53A6D6'} />
                  </Box>
                </LightTooltip>
              </Box>
              <Stack direction="row" spacing={3}>
                <Box>
                  <Select id="select-opt-type" value={uploadedFile.model || ''} onChange={e => {
                setUploadedFile({
                  ...uploadedFile,
                  model: e.target.value
                });
              }} variant="outlined" sx={{
                width: '389px'
              }}>
                    <MenuItem value="phone">Phone Number</MenuItem>
                    <MenuItem value="email">Email</MenuItem>
                  </Select>
                </Box>
                <Box>
                  <FormControl sx={{
                width: '389px'
              }} variant="outlined" fullWidth>
                    <InputLabel id="select-opt-status-label" style={{
                  color: 'black',
                  opacity: '0.5'
                }}>
                      Opt Status
                    </InputLabel>
                    <Select labelId="select-opt-status-label" id="select-opt-status" value={uploadedFile.opt || ''} onChange={e => {
                  setUploadedFile({
                    ...uploadedFile,
                    opt: e.target.value
                  });
                }} label="Opt Status" style={{
                  color: 'black',
                  borderColor: 'black'
                }}>
                      <MenuItem value="optin">Subscribe / Opt in</MenuItem>
                      <MenuItem value="optout">Unsubscribe / Opt out</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Stack>
              <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            pb: '20px',
            pt: '20px'
          }}>
                <Typography sx={{
              textAlign: 'justify',
              pr: '10px'
            }}>
                  Select Column to Import
                </Typography>
                <LightTooltip title={<Grid container>
                      <Grid item>
                        <Typography sx={{
                  fontWeight: 'bold'
                }}>Select Column to Import</Typography>
                        <br />
                        <Typography sx={{
                  textAlign: 'justify'
                }}>
                          Select the name of the column header that contains the phone numbers or email addresses that you wish to change
                          the opt status of.
                          <br />
                          <br />
                          Please ensure this column is clearly labeled in your uploaded CSV file. Most files will have it labeled as "Phone"
                          or "Email".
                          <br />
                          <br />
                          Selecting the column header will import the values within the selected column.
                        </Typography>
                        <br />
                      </Grid>
                    </Grid>} placement="top-start">
                  <Box>
                    <HelpIcon fill={'#53A6D6'} />
                  </Box>
                </LightTooltip>
              </Box>
              <Stack direction="row" spacing={3}>
                <Box>
                  <FormControl sx={{
                width: '389px'
              }} variant="outlined" fullWidth>
                    <InputLabel id="select-column-to-import-label" style={{
                  color: 'black',
                  opacity: '0.5'
                }}>
                      Column to Import
                    </InputLabel>
                    <Select id="select-column-to-import" labelId="select-column-to-import-label" label="Column to Import" value={uploadedFile.columnName || ''} onChange={e => {
                  if (uploadedFile.model === 'phone') {
                    mapField('phone', e.target.value);
                  } else {
                    mapField('email', e.target.value);
                  }
                  // get the index of the column
                  let colIndex = uploadedFile.fields.indexOf(e.target.value);
                  setUploadedFile({
                    ...uploadedFile,
                    column: colIndex,
                    columnName: e.target.value
                  });
                }} variant="outlined" sx={{
                  width: '801px'
                }}>
                      {uploadedFile.fields.map(field => {
                    return <MenuItem id={`menu-item-${field}`} sx={{
                      pl: '40px'
                    }} value={field}>
                            {field}
                          </MenuItem>;
                  })}
                    </Select>
                  </FormControl>
                </Box>
              </Stack>
            </Box>}
          <div style={{
          position: 'absolute',
          top: '80vh',
          left: '0',
          right: '0'
        }}>
            <Divider id="bottom-wizard-divider" />
          </div>

          <Stack direction="row" sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          pl: '26px',
          position: 'absolute',
          width: '97%',
          bottom: '20px',
          left: '0',
          right: '0'
        }}>
            <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            minWidth: '126px'
          }}>
              {wizardStepCount > 0 && <MUIButton variant="contained" sx={{
              width: '126px',
              height: '32px',
              backgroundColor: '#F3F3F4',
              '&:hover': {
                color: '#fff'
              }
            }} onMouseEnter={() => {
              setHoverIconColorButton1('#fff');
            }} onMouseLeave={() => {
              setHoverIconColorButton1('#3898D9');
            }} onClick={() => {
              setLoseStepDataWarning(true);
            }}>
                  <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                transform: 'scaleX(-1)'
              }}>
                    <ArrowRightAltIcon fill={hoverIconColorButton1} />
                  </Box>

                  <Typography sx={{
                p: '8px',
                color: hoverIconColorButton1
              }}>Previous</Typography>
                </MUIButton>}
            </Box>
            <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: '-30px'
          }}></Box>
            <Box sx={{
            display: 'flex',
            alignItem: 'center',
            justifyContent: 'flex-end'
          }}>
              <MUIButton variant="contained" sx={{
              width: '100px',
              height: '32px'
            }} disabled={uploadedFile?.source === 'Other' && uploadedFile.other.trim() === '' || wizardStepCount === 1 && uploadedFile?.column === '' || !uploadedFile} onClick={() => {
              if (uploadedFile && wizardStepCount === 0) {
                setIsUploadingFile(false);
                setIsSelectingOptions(true);
                setWizardStepCount(wizardStepCount + 1);
              } else if (uploadedFile && wizardStepCount === 1) {
                setConfirmUpload(true);
              }
            }}>
                <Typography sx={{
                p: '8px',
                color: '#fff'
              }}>{wizardStepCount === 1 ? 'Import' : 'Next'}</Typography>
                <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
                  {wizardStepCount === 1 ? <span style={{
                  fontSize: '24px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                      <ImportIcon fill={'#fff'} />
                    </span> : <ArrowRightAltIcon fill={'#fff'} />}
                </Box>
              </MUIButton>
            </Box>
          </Stack>
        </Paper>
      </div>

      <Dialog open={confirmUpload} onClose={() => setConfirmUpload(false)} PaperProps={{
      sx: {
        borderRadius: '28px',
        height: '407px',
        width: '560px',
        overflow: 'hidden'
      }
    }}>
        <Box sx={{
        p: '20px'
      }}>
          <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start'
        }}>
            <Typography sx={{
            py: '16px',
            fontSize: '24px',
            px: '10px'
          }}>Confirm Import</Typography>
            <LightTooltip title={<Grid container>
                  <Grid item>
                    <Typography variant="caption" sx={{
                fontWeight: 'bold'
              }}>
                      Confirm Import
                    </Typography>
                    <br />
                    <Typography variant="caption" sx={{
                textAlign: 'justify'
              }}>
                      Please certify the following to continue with the import.
                      <br />
                      <br />
                      It is important to understand that subscribing or unsubscribing customers may have unintentional consequences. Please
                      ensure that you have the necessary permissions to contact or market to these customer records and that the customer
                      has given you permission to do so. For example, if a customer has unsubscribed from your marketing campaigns, you
                      should not subscribe them without their explicit permission.
                      <br />
                      <br />
                      If you have any questions, please contact <a href="mailto:support@cinch.io">support@cinch.io</a>.
                    </Typography>
                    <br />
                  </Grid>
                </Grid>} placement="top-start">
              <Box>
                <HelpIcon fill={'#53A6D6'} />
              </Box>
            </LightTooltip>
          </Box>
          <DialogContent sx={{
          p: '10px',
          overflow: 'visible'
        }}>
            <Stack direction="row" justifyContent="center" alignItems="center">
              <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
                {uploadedFile && uploadedFile.total_records === 0 && <Typography sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
                    <WarningTriangleFilledIcon fill={'#FFB82B'} />
                    <Box sx={{
                  pl: '10px'
                }}>There are no records detected in this CSV file. Please check the file and import again.</Box>
                  </Typography>}
                {uploadedFile && !uploadedFile.opt && <Typography sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
                    <WarningTriangleFilledIcon fill={'#FFB82B'} />
                    <Box sx={{
                  pl: '10px'
                }}>
                      You have not selected an opt status. Please select an opt status for the imported records.
                    </Box>
                  </Typography>}
                {uploadedFile?.opt === 'optin' && <Typography sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
                    <WarningTriangleFilledIcon fill={'#FFB82B'} />
                    <Box sx={{
                  pl: '10px'
                }}>
                      Warning: You are about to <strong>SUBSCRIBE</strong> {uploadedFile?.total_records} records into {asCompany.name}. Are
                      you sure you want to proceed?
                    </Box>
                  </Typography>}
                {uploadedFile?.opt === 'optout' && <Typography sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
                    <WarningTriangleFilledIcon fill={'#FFB82B'} />
                    <Box sx={{
                  pl: '10px'
                }}>
                      Warning: You are about to <strong>UNSUBSCRIBE</strong> {uploadedFile?.total_records} records into {asCompany.name}.
                      Are you sure you want to proceed?
                    </Box>
                  </Typography>}
              </Box>
            </Stack>
            <Divider id="top-divider-confirm" sx={{
            mt: '20px',
            mb: '20px',
            ml: '-30px',
            mr: '-30px'
          }} />
            <Stack direction="row" sx={{
            pt: '16px',
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center'
          }}>
              <Box sx={{
              pr: '10px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
                <Checkbox sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'justify',
                p: 0,
                margin: 0,
                color: '#1D252D',
                '&.Mui-checked': {
                  color: '#53A6D6'
                },
                '& .MuiSvgIcon-root': {
                  fontSize: 22
                }
              }} checked={agree1} onChange={event => {
                setAgree1(event.target.checked);
              }}></Checkbox>
              </Box>
              <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
                <Typography>
                  I understand and accept the responsibilities and risks involved in updating your customers communication preferences.
                </Typography>
              </Box>
            </Stack>

            <Stack direction="row" sx={{
            pt: '16px',
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center'
          }}>
              <Box sx={{
              pr: '10px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
                <Checkbox sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'justify',
                p: 0,
                margin: 0,
                color: '#1D252D',
                '&.Mui-checked': {
                  color: '#53A6D6'
                },
                '& .MuiSvgIcon-root': {
                  fontSize: 22
                }
              }} checked={agree2} onChange={event => {
                setAgree2(event.target.checked);
              }}></Checkbox>
              </Box>
              <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
                <Typography sx={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'center'
              }}>
                  I certify that I have obtained explicit permission from these customers to update their communication preferences.
                </Typography>
              </Box>
            </Stack>
          </DialogContent>
          <Divider id="bottom-divider-confirm" sx={{
          mt: '20px',
          mb: '20px',
          ml: '-20px',
          mr: '-20px'
        }} />

          <DialogActions sx={{
          pr: '10px',
          pt: '40px'
        }}>
            <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start'
          }}>
              <MUIButton variant="contained" sx={{
              width: '94px',
              height: '32px',
              backgroundColor: '#F3F3F4',
              '&:hover': {
                backgroundColor: '#3898D9',
                color: '#fff'
              }
            }} onMouseEnter={() => {
              setHoverIconColorButton2('#fff');
            }} onMouseLeave={() => {
              setHoverIconColorButton2('#3898D9');
            }} onClick={() => {
              setConfirmUpload(false);
            }}>
                <Typography sx={{
                p: '8px',
                color: hoverIconColorButton2 || '3898D9'
              }}>Cancel</Typography>
              </MUIButton>
            </Box>
            <MUIButton variant="contained" sx={{
            width: '100px',
            height: '32px'
          }} disabled={!(agree1 && agree2 && uploadedFile.total_records > 0 && (uploadedFile.opt === 'optin' || uploadedFile.opt === 'optout'))} onClick={() => {
            if (agree1 && agree2) {
              actuallyUpload();
              setConfirmUpload(false);
            }
          }}>
              Import
            </MUIButton>
          </DialogActions>
        </Box>
      </Dialog>

      <Dialog open={loseStepDataWarning} onClose={() => setLoseStepDataWarning(false)} PaperProps={{
      sx: {
        borderRadius: '28px',
        height: '245px',
        width: '312px',
        overflow: 'hidden'
      }
    }}>
        <Box>
          <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          pt: '24px'
        }}>
            <WarningRedIcon />
          </Box>
          <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
            <Typography sx={{
            pt: '16px',
            fontSize: '24px'
          }}>Progress Warning</Typography>
          </Box>
          <DialogContent>
            <Box>
              <Typography sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'justify'
            }}>
                Are you sure you want to go back? You will lose all the data you have entered in this step.
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions sx={{
          pr: '20px',
          pt: '25px'
        }}>
            <MUIButton variant="text" sx={{
            textTransform: 'none',
            borderRadius: '20px'
          }} onClick={() => setLoseStepDataWarning(false)}>
              Cancel
            </MUIButton>
            <MUIButton variant="text" sx={{
            textTransform: 'none',
            borderRadius: '20px'
          }} onClick={() => {
            setLoseStepDataWarning(false);
            if (wizardStepCount === 1) {
              setIsUploadingFile(true);
              setIsSelectingOptions(false);
              setWizardStepCount(wizardStepCount - 1);
              setLoseStepDataWarning(false);
            }
          }}>
              Confirm
            </MUIButton>
          </DialogActions>
        </Box>
      </Dialog>
    </Scoped>;
};