import React, { useState, useEffect } from 'react';
import { AsyncModalDialog, Button } from 'components';
import { toasterService } from 'components/toaster/toaster-service';
export const TagModal = props => {
  const {
    objectTarget,
    viewName,
    getViewTags,
    patchView
  } = props;
  const [tagValue, updateTagValue] = useState('');
  const [companyId, updateCompanyId] = useState();
  const [companyTags, updateCompanyTags] = useState([]);
  const [tags, updateTags] = useState([]);
  useEffect(() => {
    if (objectTarget) {
      updateTags([...(objectTarget.tags || [])].map(t => t.toUpperCase()));
      if (companyId !== objectTarget.company) {
        updateCompanyId(objectTarget.company);
      }
    }
  }, [objectTarget]);
  useEffect(() => {
    if (companyId) {
      getViewTags(companyId).then(({
        data
      }) => updateCompanyTags(data.results.map(t => t.toUpperCase())));
    }
  }, [companyId]);
  const toggleTag = tag => {
    const index = tags.indexOf(tag);
    if (index > -1) {
      updateTags([...tags.slice(0, index), ...tags.slice(index + 1)]);
    } else {
      updateTags([...tags, tag]);
    }
  };
  const addNewTag = newTag => {
    newTag = newTag.toUpperCase();
    updateTagValue('');
    if (!companyTags.includes(newTag)) {
      updateCompanyTags([...companyTags, newTag]);
    }
    if (!tags.includes(newTag)) {
      updateTags([...tags, newTag]);
    }
  };
  const onSubmit = () => {
    return patchView(objectTarget.id, {
      tags
    }).then(({
      data
    }) => {
      toasterService.success(`Successfully updated ${viewName} tags.`);
      if (props.onSuccess) {
        props.onSuccess(data);
      }
    }).catch(e => {
      toasterService.error('An unknown error occured trying to save tags. Please try again');
      return Promise.reject();
    });
  };
  return <AsyncModalDialog title={`Edit ${viewName} Tags`} onClose={props.onClose} onSubmit={onSubmit} open={!!objectTarget} allowBackdropClick>
      <p>Edit Tags for {objectTarget && objectTarget.name}</p>
      <div className="form-group mb-sm">
        <input placeholder="+ New Tag" className="form-control" style={{
        display: 'inline',
        width: '80%'
      }} type="text" value={tagValue} name="tagValue" maxLength="20" onChange={e => updateTagValue(e.target.value)} />
        <Button disabled={tagValue === ''} actionType="primary" style={{
        marginLeft: '1rem',
        padding: '8px 14px',
        fontSize: '16px'
      }} onClick={() => addNewTag(tagValue)}>
          Add
        </Button>
      </div>
      <div className="form-group mb-sm">
        <ul className="select-list ml-2">
          {companyTags.map((tag, i) => {
          return <li key={i}>
                <div className="checkbox-label">
                  <label className="form-check-label">
                    <input className="form-check-input" style={{
                  position: 'relative',
                  top: '1px',
                  transform: 'scale(1.25)'
                }} type="checkbox" onChange={() => toggleTag(tag)} checked={tags.includes(tag)} />
                    {tag}
                  </label>
                </div>
              </li>;
        })}
        </ul>
      </div>
    </AsyncModalDialog>;
};