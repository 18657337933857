import React from 'react';
function Icon(props) {
  return <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" fill="none" viewBox="0 0 25 24">
      <mask id="mask0_37_8202" style={{
      maskType: 'alpha'
    }} width="25" height="25" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0.999 0.001H24.999V24.001H0.999z"></path>
      </mask>
      <g mask="url(#mask0_37_8202)">
        <path fill={props.fill || '#1D252D'} d="M16.424 13.964a2.502 2.502 0 01-1.838-.763 2.502 2.502 0 01-.763-1.837c0-.734.255-1.355.763-1.863a2.504 2.504 0 011.838-.762c.733 0 1.354.254 1.863.762.508.508.762 1.13.762 1.863 0 .716-.255 1.329-.762 1.837a2.535 2.535 0 01-1.863.763zm-4.7 5.625a.858.858 0 01-.65-.262.893.893 0 01-.25-.638v-.625c0-.284.066-.555.2-.813.133-.258.325-.462.575-.612a9.339 9.339 0 012.287-.95 9.753 9.753 0 012.538-.325c.9 0 1.75.108 2.55.325a9.85 9.85 0 012.3.95c.233.15.42.354.561.612.142.258.213.53.213.813v.625a.87.87 0 01-.261.638.87.87 0 01-.639.262h-9.425zm.675-1.65v.15h8.05v-.15a8.28 8.28 0 00-1.95-.8 7.971 7.971 0 00-2.075-.275c-.7 0-1.388.091-2.063.275-.675.183-1.33.45-1.963.8zm4.025-5.475c.316 0 .583-.109.8-.325.216-.217.325-.475.325-.775 0-.317-.109-.584-.325-.8a1.087 1.087 0 00-.8-.325c-.3 0-.559.108-.775.325a1.087 1.087 0 00-.325.8c0 .3.108.558.325.775.216.216.475.325.775.325zm-5.726 1.45h-6a.726.726 0 01-.75-.75.727.727 0 01.75-.75h6c.2 0 .375.07.525.212a.71.71 0 01.225.538.706.706 0 01-.225.537.74.74 0 01-.525.213zm4-8h-10a.726.726 0 01-.75-.75.728.728 0 01.75-.75h10c.2 0 .375.07.525.212a.71.71 0 01.225.538.706.706 0 01-.225.537.74.74 0 01-.525.213zm-2.8 4h-7.2a.726.726 0 01-.75-.75.728.728 0 01.75-.75h7.925a3.74 3.74 0 00-.425.7c-.116.25-.216.516-.3.8z"></path>
      </g>
    </svg>;
}
export default Icon;