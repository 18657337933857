import React from 'react';
function Icon(props) {
  return <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" fill="none" viewBox="0 0 25 24">
      <mask id="mask0_1036_6068" style={{
      maskType: 'alpha'
    }} width="25" height="25" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#C2E7FF" d="M0.518 0H24.518V24H0.518z"></path>
      </mask>
      <g mask="url(#mask0_1036_6068)">
        <path fill={props.fill || '#1D252D'} d="M10.493 15.925a.805.805 0 01-.225-.55c0-.2.083-.383.25-.55l2.075-2.075H4.568a.74.74 0 01-.525-.213.706.706 0 01-.225-.537.71.71 0 01.225-.538.742.742 0 01.525-.212h8.025l-2.1-2.1a.72.72 0 01-.225-.525c0-.2.075-.383.225-.55a.72.72 0 01.525-.225c.2 0 .383.075.55.225l3.275 3.3c.1.084.167.18.2.287a1.148 1.148 0 010 .675.604.604 0 01-.2.288l-3.3 3.325a.765.765 0 01-.525.2.72.72 0 01-.525-.225zM5.643 20.5c-.517 0-.95-.175-1.3-.525a1.736 1.736 0 01-.525-1.275v-2.825c0-.2.075-.375.225-.525a.72.72 0 01.525-.225.71.71 0 01.538.225c.141.15.212.325.212.525V18.7c0 .067.033.134.1.2a.31.31 0 00.225.1h13.75a.31.31 0 00.225-.1c.067-.066.1-.133.1-.2V5.3c0-.067-.033-.133-.1-.2a.311.311 0 00-.225-.1H5.643a.31.31 0 00-.225.1c-.067.067-.1.133-.1.2v2.825c0 .2-.07.375-.212.525a.71.71 0 01-.538.225.72.72 0 01-.525-.225.72.72 0 01-.225-.525V5.3c0-.5.175-.925.525-1.275.35-.35.783-.525 1.3-.525h13.75c.517 0 .95.175 1.3.525.35.35.525.775.525 1.275v13.4c0 .5-.175.925-.525 1.275-.35.35-.783.525-1.3.525H5.643z"></path>
      </g>
    </svg>;
}
export default Icon;