import React from 'react';
function Icon(props) {
  return <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="none" viewBox="0 0 21 21">
      <mask id="mask0_24_6565" style={{
      maskType: 'alpha'
    }} width="21" height="21" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0.903 0.004H20.903V20.004H0.903z"></path>
      </mask>
      <g mask="url(#mask0_24_6565)">
        <path fill={props.fill || '#E77A16'} d="M10.9 16.606c-.355 0-.66-.126-.91-.38a1.25 1.25 0 01-.379-.914c0-.356.127-.66.38-.911a1.25 1.25 0 01.915-.378c.356 0 .66.127.911.38.252.254.378.558.378.914s-.127.66-.38.912a1.25 1.25 0 01-.915.377zm0-4.086c-.326 0-.604-.116-.833-.347a1.14 1.14 0 01-.343-.833V4.164c0-.324.116-.602.347-.833.232-.23.51-.346.836-.346.325 0 .603.115.832.346.229.231.344.509.344.833v7.176c0 .325-.116.602-.348.833-.232.231-.51.347-.836.347z"></path>
      </g>
    </svg>;
}
export default Icon;