import React from 'react';
function Icon(props) {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <mask id="mask0_38_3027" style={{
      maskType: 'alpha'
    }} width="24" height="24" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0 0H24V24H0z"></path>
      </mask>
      <g mask="url(#mask0_38_3027)">
        <path fill={props.fill || '#1D252D'} d="M7.308 20.442c-.505 0-.933-.175-1.283-.525a1.745 1.745 0 01-.525-1.282V5.942h-.25a.726.726 0 01-.535-.215.726.726 0 01-.215-.535c0-.212.072-.39.215-.534a.726.726 0 01.535-.216H9a.84.84 0 01.263-.63.889.889 0 01.64-.254h4.193c.251 0 .465.084.64.254a.84.84 0 01.264.63h3.75c.213 0 .391.072.535.216a.726.726 0 01.215.534.726.726 0 01-.215.535.726.726 0 01-.535.215h-.25v12.693c0 .505-.175.932-.525 1.282-.35.35-.778.525-1.283.525H7.308zM7 5.942v12.693a.3.3 0 00.087.22.3.3 0 00.22.087h9.385a.3.3 0 00.221-.086.3.3 0 00.087-.221V5.942H7zm2.404 10.25c0 .213.072.391.215.535a.726.726 0 00.535.215.726.726 0 00.534-.215.726.726 0 00.216-.535v-7.5a.726.726 0 00-.216-.534.726.726 0 00-.534-.216.726.726 0 00-.535.216.726.726 0 00-.215.534v7.5zm3.692 0c0 .213.072.391.216.535a.726.726 0 00.534.215.726.726 0 00.535-.215.726.726 0 00.215-.535v-7.5a.726.726 0 00-.215-.534.726.726 0 00-.535-.216.726.726 0 00-.534.216.726.726 0 00-.216.534v7.5zM7 5.942v12.693a.3.3 0 00.087.22.3.3 0 00.22.087H7v-13z"></path>
      </g>
    </svg>;
}
export default Icon;