import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { CircularProgress } from '@mui/material';
export const DynamicTypeAheadMui = props => {
  const [search, setSearch] = useState('');
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const defaultFilters = props.getItemsFilters || {};
    setIsLoading(true);
    setOptions([]);
    const promise = props?.id ? props.getItems(props.id, {
      ...defaultFilters
    }) : props.getItems({
      ...defaultFilters,
      search: search,
      limit: 999,
      offset: 0
    });
    promise.then(({
      data
    }) => {
      let results;
      if (props?.formatApiResults) {
        results = props.formatApiResults(data);
      } else {
        results = data.results;
      }
      setOptions(results);
      for (const result of results) {
        if (result.id === props.value) {
          setValue(result);
          if (result?.name) {
            setInputValue(result.name);
          }
        }
      }
      setIsLoading(false);
    });
  }, [search]);
  return <>
      <div>
        <Autocomplete disabled={props?.disabled} disablePortal={props?.disablePortal} loading={isLoading} freeSolo={!props?.disableFreeSolo} noOptionsText={props.customNoOptionsText || 'No options'} value={value} inputValue={inputValue} onChange={(event, newValue) => {
        setValue(newValue);
        props.onChange(newValue);
      }} onInputChange={(event, newInputValue) => {
        setSearch(newInputValue);
        setInputValue(newInputValue);
      }} options={options} getOptionLabel={option => {
        if (!option[props.displayProperty]) {
          return option;
        }
        return option[props.displayProperty];
      }} sx={{
        width: props?.width || 300,
        ...props.sx
      }} renderInput={params => {
        return <TextField {...params} autoFocus={props?.autoFocus} size={props.size || 'medium'} helperText={props?.helperText} placeholder={!isLoading ? props?.placeholder || '' : props?.label ? `Loading ${props.label}...` : props?.placeholder ? `Loading ${props?.placeholder}...` : 'Loading...'} label={!isLoading ? props?.label || '' : props?.label ? `Loading ${props.label}...` : props?.placeholder ? `Loading ${props?.placeholder}...` : 'Loading...'} InputProps={{
          ...params.InputProps,
          endAdornment: <React.Fragment>
                      {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
        }} />;
      }} renderOption={(propsOpt, option, {
        inputValue
      }) => {
        if (props?.highlightSubsearch && props?.displayProperty && option[props.displayProperty]) {
          const {
            key,
            ...optionProps
          } = propsOpt;
          const matches = match(option[props.displayProperty], inputValue, {
            insideWords: true
          });
          const parts = parse(option[props.displayProperty], matches);
          return <li key={option[props?.keyProperty]} {...optionProps}>
                  <div>
                    {parts.map((part, index) => <span key={index} style={{
                fontWeight: part.highlight ? 700 : 400
              }}>
                        {part.text}
                      </span>)}
                  </div>
                </li>;
        }
        return <li {...propsOpt} key={option.id}>
                {option[props.displayProperty]}
              </li>;
      }} />
      </div>
    </>;
};