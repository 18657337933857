import React, { useContext } from 'react';
import { UserStateContext } from 'context/user-state-context';
import { addCourse, deleteCourse } from '../integrations.resource';
import { history } from '../../../root.component';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, InputAdornment, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { CancelIcon, CloseIcon, HelpIcon } from 'components/mui';
import { snackbarService } from 'components/mui/snackbar/snackbar-service';
const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius: `14px`,
      '& fieldset': {}
    }
  }
})(TextField);
export const IntegrationForeUPUpdate = props => {
  const {
    user,
    asCompany,
    hasPermission
  } = useContext(UserStateContext);
  const [courseRef, setCourseRef] = React.useState(props?.credential?.course_ref || '');
  const [saving, setSaving] = React.useState(false);
  const [showErrors, setShowErrors] = React.useState(false);
  const [changesMade, setChangesMade] = React.useState(false);
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = React.useState(false);
  const [unsavedChangesModalOpen, setUnsavedChangesModalOpen] = React.useState(false);
  const addForeUPCourse = () => {
    if (!courseRef || courseRef == '') {
      setShowErrors(true);
      return;
    }
    setSaving(true);
    addCourse(courseRef, asCompany.id).then(() => {
      history.push(`/integrations/manage/${props?.install.id}`, {
        sucessfulCredential: 'Successfully added course.  Data import will begin shortly and can take several minutes.'
      });
      setSaving(false);
    }).catch(err => {
      setSaving(false);
      if (err.response.data.course_ref) {
        snackbarService.popup({
          type: 'error',
          message: err.response.data.course_ref[0]
        });
      } else if (err.response.data.company) {
        snackbarService.popup({
          type: 'error',
          message: err.response.data.company[0]
        });
      } else {
        snackbarService.popup({
          type: 'error',
          message: 'Unknown error trying add course. Please try again.'
        });
      }
    });
  };
  const handleDeleteForeUPCourse = () => {
    setSaving(true);
    deleteCourse(props?.credential?.id).then(() => {
      history.push(`/integrations/manage/${props?.install.id}`, {
        sucessfulCredential: 'Credential sucessfully deleted'
      });
      setSaving(false);
    }).catch(() => {
      snackbarService.popup({
        type: 'error',
        message: 'Unknown error trying delete credential. Please try again.'
      });
      setSaving(false);
    });
  };
  return <>
      <Stack>
        <Box sx={{
        pb: '16px'
      }}>
          <Typography sx={{
          fontSize: '18px',
          fontWeight: '300',
          lineHeight: '26px'
        }}>Configuration</Typography>
        </Box>

        <Stack direction={'row'} spacing="16px">
          {/* course ref field */}
          <CustomTextField sx={{
          width: '50%'
        }} helperText={<>{showErrors && (!courseRef || courseRef == '') && 'A course ID is required.'}</>} error={showErrors && (!courseRef || courseRef == '')} value={courseRef} variant={props?.credential ? 'filled' : 'outlined'} required disabled={saving || !hasPermission('foreup.add_course') || !!props?.credential} onBlur={event => {
          if (event.target.value == '' || !event.target.value) {
            setShowErrors(true);
          } else {
            setShowErrors(false);
          }
        }} label={'Course ID'} onChange={event => {
          setChangesMade(true);
          setCourseRef(event.target.value);
        }} InputProps={{
          readOnly: !!props?.credential,
          endAdornment: props?.credential && <InputAdornment position="end">
                  <Stack direction="row" spacing="5px">
                    <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                      <Tooltip title="This field cannot be edited on an existing credential. If you need to change it, remove this credential and create a new one">
                        <IconButton>
                          <HelpIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Stack>
                </InputAdornment>
        }} />
        </Stack>

        <Stack sx={{
        pt: '16px'
      }}>
          <Divider />
          <Box sx={{
          pr: '20px',
          pt: '20px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
            <Stack direction="row" spacing="16px">
              {props?.credential && hasPermission('foreup.delete_course') ? <Button variant="contained" sx={{
              backgroundColor: '#F3F3F4',
              '&:hover': {
                backgroundColor: '#F3F3F4'
              }
            }} startIcon={<CancelIcon fill="#3898D9" />} onClick={e => {
              setOpenConfirmDeleteModal(true);
            }}>
                  <Box sx={{
                py: '5px',
                pr: '12px'
              }}>
                    <Typography sx={{
                  color: '#3898D9'
                }}>Remove </Typography>
                  </Box>
                </Button> : <Box></Box>}
            </Stack>
            <Stack direction="row" spacing="16px">
              <Button variant="contained" sx={{
              backgroundColor: '#F3F3F4',
              '&:hover': {
                backgroundColor: '#F3F3F4'
              }
            }} onClick={e => {
              if (changesMade) {
                setUnsavedChangesModalOpen(true);
              } else {
                history.push(`/integrations/manage/${props?.install?.id}`);
              }
            }}>
                <Box sx={{
                py: '5px',
                px: '12px'
              }}>
                  <Typography sx={{
                  color: '#3898D9'
                }}>Cancel </Typography>
                </Box>
              </Button>
              <Button sx={{
              backgroundColor: '#3898D9',
              '&:hover': {
                backgroundColor: '#3898D9'
              }
            }} variant="contained" endIcon={saving && <CircularProgress sx={{
              color: '#FFF'
            }} size={'20px'} />} disabled={!props?.credential && (!changesMade || saving || courseRef == '' || !courseRef || !hasPermission('foreup.add_course'))} onClick={e => {
              if (props?.credential) {
                history.push(`/integrations/manage/${props?.install?.id}`);
              } else {
                addForeUPCourse();
              }
            }}>
                <Box sx={{
                py: '6px',
                px: '12px'
              }}>
                  <Typography sx={{
                  color: '#fff'
                }}> {props?.credential ? 'Done' : saving ? 'Working...' : 'Submit'}</Typography>
                </Box>
              </Button>
            </Stack>
          </Box>
        </Stack>
      </Stack>

      <Dialog scroll="paper" PaperProps={{
      style: {
        borderRadius: '28px'
      }
    }} open={!!unsavedChangesModalOpen} onClose={(e, r) => {
      setUnsavedChangesModalOpen(null);
    }}>
        <DialogTitle sx={{
        m: 0,
        p: 2,
        fontSize: 'medium'
      }}>
          <Stack spacing={'20px'}>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Stack direction="row" spacing={'10px'}>
                <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                  <Typography sx={{
                  fontWeight: '300',
                  lineHeight: '30px',
                  fontSize: '24px',
                  display: 'flex',
                  alignItems: 'center'
                }} variant="h4" component="span">
                    Unsaved Changes
                  </Typography>
                </Box>
              </Stack>

              <IconButton onClick={() => {
              setUnsavedChangesModalOpen(null);
            }}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Divider />
          </Stack>
        </DialogTitle>

        <DialogContent sx={{
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
          width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
          marginTop: '20px',
          marginBottom: '20px'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#888'
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555'
        }
      }}>
          <Stack sx={{
          pt: 1
        }} spacing={'24px'}>
            <Box>You have unsaved changes. Do you want to discard them? </Box>
          </Stack>
        </DialogContent>

        <DialogActions sx={{
        pt: 1,
        pb: 2
      }}>
          <Stack sx={{
          pr: '24px'
        }} direction={'row'} spacing={'20px'}>
            <Button onClick={() => {
            setUnsavedChangesModalOpen(false);
          }} variant="contained" sx={{
            backgroundColor: '#F3F3F4',
            '&:hover': {
              backgroundColor: '#F3F3F4'
            }
          }}>
              <Box sx={{
              py: '5px',
              px: '12px'
            }}>
                <Typography sx={{
                color: '#3898D9'
              }}>Cancel </Typography>
              </Box>
            </Button>
            <Button onClick={() => {
            history.push(`/integrations/manage/${props?.install.id}`);
          }} variant="contained" sx={{
            backgroundColor: '#3898D9',
            '&:hover': {
              backgroundColor: '#3898D9'
            }
          }}>
              <Box sx={{
              py: '5px',
              px: '12px'
            }}>
                <Typography sx={{
                color: '#fff'
              }}> Discard</Typography>
              </Box>
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>

      <Dialog scroll="paper" PaperProps={{
      style: {
        borderRadius: '28px'
      }
    }} open={!!openConfirmDeleteModal} onClose={(e, r) => {
      setOpenConfirmDeleteModal(null);
    }}>
        <DialogTitle sx={{
        m: 0,
        p: 2,
        fontSize: 'medium'
      }}>
          <Stack spacing={'20px'}>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Stack direction="row" spacing={'10px'}>
                <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                  <Typography sx={{
                  fontWeight: '300',
                  lineHeight: '30px',
                  fontSize: '24px',
                  display: 'flex',
                  alignItems: 'center'
                }} variant="h4" component="span">
                    Confirm Remove Credential
                  </Typography>
                </Box>
              </Stack>

              <IconButton onClick={() => {
              setOpenConfirmDeleteModal(null);
            }}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Divider />
          </Stack>
        </DialogTitle>

        <DialogContent sx={{
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
          width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
          marginTop: '20px',
          marginBottom: '20px'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#888'
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555'
        }
      }}>
          <Stack sx={{
          pt: 1
        }} spacing={'24px'}>
            <Box>Are you sure you want to remove this credential? </Box>
          </Stack>
        </DialogContent>

        <DialogActions sx={{
        pt: 1,
        pb: 2
      }}>
          <Stack sx={{
          pr: '24px'
        }} direction={'row'} spacing={'20px'}>
            <Button onClick={() => {
            setOpenConfirmDeleteModal(false);
          }} variant="contained" sx={{
            backgroundColor: '#F3F3F4',
            '&:hover': {
              backgroundColor: '#F3F3F4'
            }
          }}>
              <Box sx={{
              py: '5px',
              px: '12px'
            }}>
                <Typography sx={{
                color: '#3898D9'
              }}>Cancel </Typography>
              </Box>
            </Button>
            <Button onClick={() => {
            handleDeleteForeUPCourse();
            setOpenConfirmDeleteModal(null);
          }} variant="contained" sx={{
            backgroundColor: '#3898D9',
            '&:hover': {
              backgroundColor: '#3898D9'
            }
          }}>
              <Box sx={{
              py: '5px',
              px: '12px'
            }}>
                <Typography sx={{
                color: '#fff'
              }}> Remove</Typography>
              </Box>
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </>;
};