import axios from 'axios';
import moment from 'moment-timezone';
export const api = axios.create({
  baseURL: API_URL,
  headers: {
    'content-type': 'application/json'
  },
  paramsSerializer: params => {
    const searchParams = new URLSearchParams();
    for (const key of Object.keys(params)) {
      let param = params[key];
      if (Array.isArray(param)) {
        param = param.join(',');
      }
      if (param instanceof Date || param instanceof moment) {
        param = param.toISOString();
      }
      if (param instanceof moment) {
        param = param.toISOString();
      }
      if (param !== undefined && param !== null && param !== '') {
        searchParams.append(key, param);
      }
    }
    return searchParams.toString();
  }
});
export const unwrap = ({
  data
}) => data;
export function buildQueryParams(params) {
  return params ? `?${Object.keys(params).map(key => `${key}=${params[key]}`).join('&')}` : '';
}