import React from 'react';
function Icon(props) {
  if (props.size == 'xl') {
    return <svg xmlns="http://www.w3.org/2000/svg" width="104" height="102" fill="none" viewBox="0 0 104 102">
        <path fill="#9AA0A6" d="M28.325 43.361c-1.54 0-2.501-1.666-1.732-3l21.524-37.29c.77-1.334 2.694-1.334 3.464 0l21.524 37.29c.77 1.334-.193 3-1.733 3H28.326z"></path>
        <rect width="38.032" height="38.032" y="61.608" fill="#9AA0A6" rx="2"></rect>
        <circle cx="82.694" cy="80.624" r="21.306" fill="#9AA0A6"></circle>
      </svg>;
  }
  return <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="none" viewBox="0 0 40 40">
      <path fill="#CCCDCF" d="M0 0H40V40H0z"></path>
      <path fill="#9AA0A6" d="M12.172 17.797L19.38 5.309l7.207 12.488H12.172z"></path>
      <path fill="#9AA0A6" d="M5 23.06H15.971V34.031H5z"></path>
      <circle cx="28.855" cy="28.545" r="6.146" fill="#9AA0A6"></circle>
    </svg>;
}
export default Icon;