import React, { useState, useContext, useEffect } from 'react';
import { Grid, Button } from '@mui/material';
import { ModalDialog } from '../../components/modal/modal-dialog.component';
import { createCredentials, getIsiSftpFiles, chooseIsiSftpFile, deleteCredential, getCredentials } from './integrations.resource';
import { UserStateContext } from 'context/user-state-context';
import { toasterService } from 'components';
import { DeleteIcon } from 'components/mui';
import { AntSwitch } from 'components/mui/switch/ant-switch.component';
export const IntegrationIsi = ({
  credentials
}) => {
  const defaultHeader = 'Please enter your Isi Store ID and address below to begin linking your store.';
  const [existingCredentials, setExistingCredentials] = useState([]);
  const [credentialToDelete, setCredentialToDelete] = useState();
  const [header, setHeader] = useState(defaultHeader);
  const [isSaving, setIsSaving] = useState(false);
  const [openAddStoreModal, setOpenAddStoreModal] = useState(false);
  const [openSftpModal, setOpenSftpModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [availableSftpFiles, setAvailableSftpFiles] = useState([]);
  const [chosenSftpFiles, setChosenSftpFiles] = useState([]);
  const [storeId, setStoreId] = useState();
  const [address1, setAddress1] = useState();
  const [address2, setAddress2] = useState();
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [zipCode, setZipCode] = useState();
  const [country, setCountry] = useState();
  const [importHistoric, setImportHistoric] = useState(false);
  const {
    asCompany,
    hasPermission
  } = useContext(UserStateContext);
  useEffect(() => {
    setExistingCredentials([...credentials]);
  }, [credentials]);
  const reloadCredentials = async () => {
    try {
      const response = await getCredentials({
        company: asCompany.id,
        limit: 1000,
        integration: 'isi'
      });
      const credentialData = response.results;
      setExistingCredentials(credentialData);
    } catch (error) {
      console.error(error, 'error');
    }
  };
  const establishStoreIntegration = async () => {
    try {
      setIsSaving(true);
      const requestBody = {
        entity_data: {
          store_id: storeId,
          address1,
          address2,
          city,
          state,
          zipCode,
          country,
          import_historic: importHistoric
        },
        company: asCompany.id,
        integration: 'isi'
      };
      await createCredentials(requestBody);
      await reloadCredentials();
      const sftpFilesResponse = await getIsiSftpFiles(asCompany.id, storeId);
      setAvailableSftpFiles(sftpFilesResponse.data.files);
      setOpenSftpModal(true);
      setOpenAddStoreModal(false);
      setHeader(defaultHeader);
    } catch (error) {
      console.error(error);
      // better way to do error handling but this should be enough for now
      if (error.response?.data?.store_id === 'Required as a query parameter.') {
        setHeader(error.response.data.store_id);
      } else if (error.response?.data?.detail) {
        setHeader(error.response.data.detail);
      }
    } finally {
      setIsSaving(false);
    }
  };
  const loadFromChosenSftpFiles = async () => {
    try {
      setIsSaving(true);
      await chooseIsiSftpFile(asCompany.id, storeId, chosenSftpFiles);
      setOpenSftpModal(false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsSaving(false);
    }
  };
  const removeCredentials = async () => {
    try {
      await deleteCredential(credentialToDelete.id);
      toasterService.success('Successfully deleted credentials');
      setCredentialToDelete();
      await reloadCredentials();
    } catch (error) {
      console.error(error, 'exception');
      toasterService.error('Unknown error to trying delete credentials. Please try again.');
    }
  };
  const updateChosenSftpFiles = e => {
    const value = e.target.value;
    let newSelections = [];
    if (chosenSftpFiles.includes(value)) {
      newSelections = chosenSftpFiles.filter(values => values !== value);
      setChosenSftpFiles(newSelections);
    } else {
      newSelections.push(value);
      newSelections.push(...chosenSftpFiles);
      setChosenSftpFiles(newSelections);
    }
  };
  return <>
      <Grid className="integration mb-5">
        <Grid className="integration__header">
          <Grid>Isi</Grid>
          {hasPermission('integration.add_credential') && <Button className="btn" actionType="primary" onClick={() => {
          setOpenAddStoreModal(true);
        }}>
              Add Store
            </Button>}
        </Grid>
        <Grid>
          {existingCredentials.map(credential => <div className="integration-item" key={credential.id}>
              <div className="integration-item__inner">
                <div className="integration-title">Store number {credential.entity_data?.store_id}</div>
                <div className="integration-actions">
                  {hasPermission('integration.delete_credential') && <Button onClick={e => {
                setOpenDeleteModal(true);
                setCredentialToDelete(credential);
              }}>
                      <DeleteIcon />
                    </Button>}
                </div>
              </div>
            </div>)}
        </Grid>
      </Grid>
      {/** Modal for adding a store */}
      <ModalDialog title="Add Store" open={openAddStoreModal} onClose={() => {
      setOpenAddStoreModal(false);
    }} onSubmit={async () => {
      await establishStoreIntegration();
    }} allowBackdropClick preventCloseOnSubmit={true} disabled={isSaving}>
        <p>{header}</p>
        <p>
          <label>Store ID</label>
          <input value={storeId} required className="form-control" name="storeId" onChange={e => {
          setStoreId(e.target.value);
        }} disabled={isSaving} />
        </p>
        <p>
          <label>Address 1</label>
          <input value={address1} required className="form-control" name="address1" onChange={e => {
          setAddress1(e.target.value);
        }} disabled={isSaving} />
        </p>
        <p>
          <label>Address 2</label>
          <input value={address2} required className="form-control" name="address2" onChange={e => {
          setAddress2(e.target.value);
        }} disabled={isSaving} />
        </p>
        <p>
          <label>City</label>
          <input value={city} required className="form-control" name="city" onChange={e => {
          setCity(e.target.value);
        }} disabled={isSaving} />
        </p>
        <p>
          <label>State</label>
          <input value={state} required className="form-control" name="state" onChange={e => {
          setState(e.target.value);
        }} disabled={isSaving} />
        </p>
        <p>
          <label>Zip Code</label>
          <input value={zipCode} required className="form-control" name="zipCode" onChange={e => {
          setZipCode(e.target.value);
        }} disabled={isSaving} />
        </p>
        <p>
          <label>Country</label>
          <input value={country} required className="form-control" name="country" onChange={e => {
          setCountry(e.target.value);
        }} disabled={isSaving} />
        </p>
        <p>
          <label>Import Non-ISI Invoices</label>
          <AntSwitch checked={importHistoric} onChange={() => {
          setImportHistoric(!importHistoric);
        }} />
          <small style={{
          display: 'block'
        }}>
            This will take all of the invoices coming in from ISI that have an
            transaction number of "0" and make it store number + invoice number
          </small>
        </p>
      </ModalDialog>
      {/** Modal for choosing ftp uploading documents */}

      <ModalDialog title="Import CSV" open={openSftpModal} onClose={() => setOpenSftpModal(false)} onSubmit={async () => {
      await loadFromChosenSftpFiles();
    }} preventCloseOnSubmit={true} disabled={isSaving}>
        {/** make this cleaner if this ever leaves private integration (it flashes when selecting more than one option) */}
        <select onChange={updateChosenSftpFiles} multiple value={chosenSftpFiles} disabled={isSaving} style={{
        width: '100%',
        height: '500px'
      }}>
          {availableSftpFiles.map(file => <option key={file} value={file}>
              {file}
            </option>)}
        </select>
        <Button variant="text" onClick={() => {
        // if all are selected, clear all
        if (chosenSftpFiles.length === availableSftpFiles.length) {
          setChosenSftpFiles([]);
        } else {
          setChosenSftpFiles(availableSftpFiles);
        }
      }}>
          {chosenSftpFiles.length === availableSftpFiles.length ? 'Clear All' : 'Select All'}
        </Button>
      </ModalDialog>
      {/** Modal for deleting the store integration */}
      <ModalDialog open={openDeleteModal} title="Remove Credential" submitText="Remove" onSubmit={removeCredentials} onClose={() => {
      setOpenDeleteModal(false);
      setCredentialToDelete();
    }} allowBackdropClick>
        <p>{credentialToDelete && <strong>Store {credentialToDelete.entity_data?.store_id}</strong>}</p>
        <p>Are you sure you want to remove the credential?</p>
      </ModalDialog>
    </>;
};