import React from 'react';
function Icon(props) {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <g>
        <mask id="mask0_174_11645" style={{
        maskType: 'alpha'
      }} width="24" height="24" x="0" y="0" maskUnits="userSpaceOnUse">
          <path fill="#D9D9D9" d="M0 24H24V48H0z" transform="rotate(-90 0 24)"></path>
        </mask>
        <g mask="url(#mask0_174_11645)">
          <path fill={props.fill || '#1D252D'} d="M21.41 12.001c0 .12-.022.232-.065.336a.935.935 0 01-.206.296l-5.498 5.513a.725.725 0 01-.522.213.743.743 0 01-.538-.203.698.698 0 01-.228-.535.747.747 0 01.228-.534l4.342-4.337h-6.396a.726.726 0 01-.534-.216.727.727 0 01-.216-.534c0-.213.072-.39.216-.534a.726.726 0 01.534-.216h6.406L14.581 6.89a.692.692 0 01-.208-.514.73.73 0 01.224-.532.702.702 0 01.52-.206c.21 0 .385.069.524.207l5.498 5.523a.932.932 0 01.206.298.868.868 0 01.065.336zm-11.633 0c0 .212-.072.39-.215.534a.725.725 0 01-.535.215h-1.5a.726.726 0 01-.534-.216.726.726 0 01-.216-.534c0-.213.072-.39.216-.534a.726.726 0 01.534-.216h1.51c.21 0 .386.072.527.216a.73.73 0 01.213.534zm-5 0c0 .212-.072.39-.215.534a.725.725 0 01-.535.215h-.5a.726.726 0 01-.534-.216.726.726 0 01-.216-.534c0-.213.072-.39.216-.534a.726.726 0 01.534-.216h.51c.21 0 .386.072.527.216a.73.73 0 01.213.534z"></path>
        </g>
      </g>
    </svg>;
}
export default Icon;