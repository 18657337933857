import React from 'react';
function Icon(props) {
  if (props.size == 'lg') {
    return <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 -960 960 960">
        <path fill={props.fill || '#1C1B1F'} d="M147.694-739.999h664.612v519.998H147.694v-519.998zm249.075 50.255v184.617h365.282v-184.617H396.769zm365.282 234.871H606.665v184.617h155.386v-184.617zm-365.282 0v184.617h156.257v-184.617H396.769zm-198.82 184.617h148.565v-419.488H197.949v419.488z"></path>
      </svg>;
  }
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 -960 960 960">
      <path fill={props.fill || '#1D252D'} d="M147.694-739.999h664.612v519.998H147.694v-519.998zM408-680v170.001h344.307V-680H408zm344.307 229.999H610.46V-280h141.847v-170.001zm-344.307 0V-280h142.462v-170.001H408zM207.693-280h140.308v-400H207.693v400z"></path>
    </svg>;
}
export default Icon;