import React, { useEffect, useState } from 'react';
import { Button, Grid, Typography, Divider, InputLabel } from '@mui/material';
export const ManagedBroadcastConfirmationContent = ({
  values,
  companiesChildren
}) => {
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [selectedCompanyCount, setSelectedCompanyCount] = useState(0);
  useEffect(() => {
    const selectedChildCompanies = [];
    companiesChildren.map(company => {
      if (values.children_companies.includes(company.value)) {
        selectedChildCompanies.push(company);
      }
    });
    setSelectedCompanies(selectedChildCompanies);
    setSelectedCompanyCount(selectedChildCompanies.length);
  }, []);
  return <Grid container>
      <Grid item xs={12}>
        <InputLabel>The following {selectedCompanyCount} companies have been selected to receive this broadcast.</InputLabel>
        <Divider />
      </Grid>
      <Grid item xs={12} style={{
      marginTop: '1.5rem'
    }}>
        {selectedCompanies.map(company => {
        return <Grid key={company.value} item xs={12}>
              <Typography variant="body1" key={company.value}>
                {company.name}
              </Typography>
            </Grid>;
      })}
      </Grid>
    </Grid>;
};