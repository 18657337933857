import React from 'react';
import classnames from 'classnames';
import { Scoped, a } from 'kremling';
import { Icon } from '../icon/icon.component';
import { Button } from '../button/button.component';
import styles from './banner.styles.scss';
const getIconFromType = type => {
  switch (type) {
    case 'success':
      return 'fa-solid-check-circle';
    case 'info':
    case 'secondary':
      return 'fa-solid-info-circle';
    case 'warning':
      return 'fa-solid-exclamation-circle';
    case 'error':
    case 'danger':
      return 'fa-solid-exclamation-triangle';
  }
};
export const Banner = props => {
  const {
    type = 'warning',
    className
  } = props;
  return <Scoped css={styles}>
      <div className={classnames('banner', `banner-${type}`, 'd-flex', 'align-items-center', className)}>
        <Icon name={getIconFromType(type)} size={18} />
        <div className="flex-grow">{props.children}</div>
        {props.onClick && <Button actionType="flat" onClick={props.onClick}>
            More Details
          </Button>}
      </div>
    </Scoped>;
};