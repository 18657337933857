import dayjs from 'dayjs';
/**
 * Things like this are a little extra work, however,
 * its a nice little class to set defaults with and / or
 * override values without having to worry
 * what the defaults actually should be
 */
export class ManageBroadcastInitialValueBuilder {
  id = null;
  children_companies = [];
  segment = '';
  email_template = '';
  schedule_timezone_toggle = false;
  schedule_timezone = null;
  schedule_when = dayjs().format('YYYY-MM-DDTHH:mm:ss');
  drip_toggled = false;
  drip_end = null;
  window_toggle = false;
  window_start = null;
  window_end = null;
  window_days = [];
  distinct_emails = true;
  google_campaign = '';
  setId(id) {
    this.id = id;
    return this;
  }
  setChildrenCompanies(childCompanies) {
    this.children_companies = childCompanies;
    return this;
  }
  setSegment(segment) {
    this.segment = segment;
    return this;
  }
  setEmailTemplate(emailTemplate) {
    this.email_template = emailTemplate;
    return this;
  }
  setScheduleWhenTimezoneToggle(timezoneToggle) {
    this.schedule_timezone_toggle = timezoneToggle;
    return this;
  }
  setScheduleTimezone(scheduleTimezone) {
    this.schedule_timezone = scheduleTimezone;
    return this;
  }
  setScheduleWhen(scheduleWhen) {
    this.schedule_when = scheduleWhen;
    return this;
  }
  setDripToggled(dripToggled) {
    this.drip_toggled = dripToggled;
    return this;
  }
  setDripEnd(dripEnd) {
    this.drip_end = dripEnd;
    return this;
  }
  setWindowToggle(windowToggle) {
    this.window_toggle = windowToggle;
    return this;
  }
  setWindowStart(windowStart) {
    this.window_start = windowStart;
    return this;
  }
  setWindowEnd(windowEnd) {
    this.window_end = windowEnd;
    return this;
  }
  setWindowDays(windowDays) {
    this.window_days = windowDays;
    return this;
  }
  setDistinctEmails(distinctEmails) {
    this.distinct_emails = distinctEmails;
    return this;
  }
  setGoogleCampaign(googleCampaign) {
    this.google_campaign = googleCampaign;
    return this;
  }
  build() {
    return {
      id: this.id,
      children_companies: this.children_companies,
      segment: this.segment,
      email_template: this.email_template,
      schedule_when: this.schedule_when,
      schedule_timezone_toggle: this.schedule_timezone_toggle,
      schedule_timezone: this.schedule_timezone,
      drip_toggled: this.drip_toggled,
      drip_end: this.drip_end,
      window_toggle: this.window_toggle,
      window_start: this.window_start,
      window_end: this.window_end,
      window_days: this.window_days,
      distinct_emails: this.distinct_emails,
      google_campaign: this.google_campaign
    };
  }
}