import React from 'react';
function Icon() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" fill="none" viewBox="0 0 25 24">
      <mask id="mask0_85_5646" style={{
      maskType: 'alpha'
    }} width="25" height="25" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0.999 0.001H24.999V24.001H0.999z"></path>
      </mask>
      <g mask="url(#mask0_85_5646)">
        <path fill="#1D252D" fillOpacity="0.7" d="M10.698 10.451h1.125c.25 0 .459-.087.625-.262a.892.892 0 00.25-.638v-1.1a.892.892 0 00-.25-.638.828.828 0 00-.625-.262H10.3a.456.456 0 00-.337.138.456.456 0 00-.138.337v3.975c0 .117.046.22.138.312a.428.428 0 00.312.138.39.39 0 00.3-.138.45.45 0 00.124-.312v-1.55zm0-.9v-1.1h1.125v1.1h-1.125zm5 2.9c.25 0 .459-.087.625-.262a.892.892 0 00.25-.638v-3.1a.892.892 0 00-.25-.638.828.828 0 00-.625-.262h-1.5a.457.457 0 00-.337.138.456.456 0 00-.138.337v3.95c0 .133.046.246.138.337a.457.457 0 00.337.138h1.5zm-1.075-.9v-3.1h1.075v3.1h-1.075zm3.95-1.1h.75a.432.432 0 00.314-.138.43.43 0 00.136-.312.43.43 0 00-.136-.312.432.432 0 00-.314-.138h-.75v-1.1h.75a.432.432 0 00.314-.138.43.43 0 00.136-.312.43.43 0 00-.136-.312.432.432 0 00-.314-.138h-1.175a.432.432 0 00-.313.138.458.458 0 00-.137.337v3.975c0 .117.046.22.137.312.092.092.188.138.288.138a.432.432 0 00.313-.138.43.43 0 00.137-.312v-1.55zm-9.524 7.05c-.5 0-.925-.175-1.275-.525a1.736 1.736 0 01-.525-1.275v-11.4c0-.5.175-.925.525-1.275.35-.35.775-.525 1.275-.525h11.4c.5 0 .924.175 1.274.525.35.35.526.775.526 1.275v11.4c0 .5-.175.925-.525 1.275-.35.35-.776.525-1.276.525h-11.4zm0-1.5h11.4a.29.29 0 00.212-.088.289.289 0 00.087-.212v-11.4a.292.292 0 00-.087-.213.292.292 0 00-.213-.087h-11.4a.289.289 0 00-.211.087.29.29 0 00-.088.213v11.4c0 .083.029.154.088.212a.287.287 0 00.212.088zm-3.5 5c-.5 0-.925-.175-1.275-.525a1.736 1.736 0 01-.525-1.275V7.051c0-.2.07-.375.213-.525a.706.706 0 01.537-.225c.216 0 .395.075.537.225a.74.74 0 01.213.525v12.15c0 .083.029.154.088.213a.289.289 0 00.212.087h12.15c.2 0 .374.07.524.212a.71.71 0 01.226.538.71.71 0 01-.226.538.742.742 0 01-.524.212H5.548z"></path>
      </g>
    </svg>;
}
export default Icon;