import { RRule } from 'rrule';
import { DateTime } from 'luxon';
let now = DateTime.local();
export const defaultOptions = {
  dtstart: now.toUTC().toJSDate(),
  byhour: [0],
  byminute: [0],
  bysecond: [0],
  wkst: RRule.MO
};
export const dateNowOptions = {
  dtstart: now.toUTC().toJSDate(),
  byhour: [now.toUTC().toJSDate().getHours()],
  byminute: [now.toUTC().toJSDate().getMinutes()],
  bysecond: [0],
  wkst: RRule.MO
};
export const patternOptions = [{
  id: RRule.DAILY,
  name: 'Daily',
  pattern: 'daily'
}, {
  id: RRule.WEEKLY,
  name: 'Weekly',
  pattern: 'weekly'
}, {
  id: RRule.MONTHLY,
  name: 'Monthly',
  pattern: 'monthly'
}, {
  id: RRule.MONTHLY,
  name: 'Quarterly',
  pattern: 'quarterly'
}, {
  id: RRule.YEARLY,
  name: 'Annually',
  pattern: 'annually'
}];
export const dailyOptions = {
  everyDay: {
    freq: RRule.DAILY,
    interval: 1,
    byweekday: null,
    bymonthday: null,
    bymonth: null
  },
  everyWeekday: {
    freq: RRule.DAILY,
    interval: 1,
    byweekday: [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR],
    bymonthday: null,
    bymonth: null
  },
  everyWeekendDay: {
    freq: RRule.DAILY,
    interval: 1,
    byweekday: [RRule.SA, RRule.SU],
    bymonthday: null,
    bymonth: null
  }
};
export const weeklyOptions = {
  everyWeek: {
    freq: RRule.WEEKLY,
    interval: 1,
    byweekday: [RRule.MO],
    bymonthday: null,
    bymonth: null
  }
};
export const monthlyOptions = {
  everyMonth: {
    freq: RRule.MONTHLY,
    interval: 1,
    byweekday: null,
    bymonthday: 1,
    bymonth: null
  },
  onEveryMonth: {
    freq: RRule.MONTHLY,
    interval: 1,
    byweekday: RRule.MO.nth(1),
    bymonthday: null,
    bymonth: null
  }
};
export const quarterlyOptions = {
  onDay: {
    freq: RRule.MONTHLY,
    interval: 3,
    byweekday: null,
    bymonthday: 1,
    bymonth: null
  }
};
export const annuallyOptions = {
  everyYear: {
    freq: RRule.YEARLY,
    interval: 1,
    byweekday: null,
    bymonthday: 1,
    bymonth: [0]
  },
  onEveryYear: {
    freq: RRule.YEARLY,
    interval: 1,
    byweekday: RRule.MO.nth(1),
    bymonthday: null,
    bymonth: [0]
  }
};
export function numToMonth(num) {
  switch (num) {
    case 1:
    case '1':
      return 'January';
    case 2:
    case '2':
      return 'February';
    case 3:
    case '3':
      return 'March';
    case 4:
    case '4':
      return 'April';
    case 5:
    case '5':
      return 'May';
    case 6:
    case '6':
      return 'June';
    case 7:
    case '7':
      return 'July';
    case 8:
    case '8':
      return 'August';
    case 9:
    case '9':
      return 'September';
    case 10:
    case '10':
      return 'October';
    case 11:
    case '11':
      return 'November';
    case 12:
    case '12':
      return 'December';
  }
}
export function dayNumToStr(num) {
  switch (num) {
    case 0:
    case '0':
      return 'MO';
    case 1:
    case '1':
      return 'TU';
    case 2:
    case '2':
      return 'WE';
    case 3:
    case '3':
      return 'TH';
    case 4:
    case '4':
      return 'FR';
    case 5:
    case '5':
      return 'SA';
    case 6:
    case '6':
      return 'SU';
    default:
      return 'MO';
  }
}
export function getTime(hour, minute) {
  return (timeSlots.find(slot => slot.hour === hour && slot.minute === minute) || {
    time: null
  }).time;
}
export function normalizeRRules(rrule) {
  if (rrule.includes('BYNWEEKDAY') && rrule.includes('BYDAY')) {
    return rrule.split(';').filter(rule => !rule.includes('BYNWEEKDAY')).join(';');
  }
  return rrule.split(';').map(rule => {
    if (rule.includes('BYNWEEKDAY')) {
      const nthValue = rule.split('=')[1];
      const [day, nth] = nthValue.split(',');
      const nthInt = parseInt(nth) > 0 ? `+${nth}` : `${nth}`;
      return `BYDAY=${nthInt}${dayNumToStr(day)}`;
    }
    return rule;
  }).join(';');
}
export function timeFilter(slot, dateSelected) {
  if (!dateSelected) {
    return false;
  }
  const timeNow = new Date();
  const testDate = new Date(dateSelected.getTime());
  testDate.setHours(slot.hour, slot.minute, 0, 0);
  return testDate > timeNow;
}
export const timeSlots = [{
  hour: 0,
  minute: 0,
  time: '12:00 am'
}, {
  hour: 0,
  minute: 15,
  time: '12:15 am'
}, {
  hour: 0,
  minute: 30,
  time: '12:30 am'
}, {
  hour: 0,
  minute: 45,
  time: '12:45 am'
}, {
  hour: 1,
  minute: 0,
  time: '1:00 am'
}, {
  hour: 1,
  minute: 15,
  time: '1:15 am'
}, {
  hour: 1,
  minute: 30,
  time: '1:30 am'
}, {
  hour: 1,
  minute: 45,
  time: '1:45 am'
}, {
  hour: 2,
  minute: 0,
  time: '2:00 am'
}, {
  hour: 2,
  minute: 15,
  time: '2:15 am'
}, {
  hour: 2,
  minute: 30,
  time: '2:30 am'
}, {
  hour: 2,
  minute: 45,
  time: '2:45 am'
}, {
  hour: 3,
  minute: 0,
  time: '3:00 am'
}, {
  hour: 3,
  minute: 15,
  time: '3:15 am'
}, {
  hour: 3,
  minute: 30,
  time: '3:30 am'
}, {
  hour: 3,
  minute: 45,
  time: '3:45 am'
}, {
  hour: 4,
  minute: 0,
  time: '4:00 am'
}, {
  hour: 4,
  minute: 15,
  time: '4:15 am'
}, {
  hour: 4,
  minute: 30,
  time: '4:30 am'
}, {
  hour: 4,
  minute: 45,
  time: '4:45 am'
}, {
  hour: 5,
  minute: 0,
  time: '5:00 am'
}, {
  hour: 5,
  minute: 15,
  time: '5:15 am'
}, {
  hour: 5,
  minute: 30,
  time: '5:30 am'
}, {
  hour: 5,
  minute: 45,
  time: '5:45 am'
}, {
  hour: 6,
  minute: 0,
  time: '6:00 am'
}, {
  hour: 6,
  minute: 15,
  time: '6:15 am'
}, {
  hour: 6,
  minute: 30,
  time: '6:30 am'
}, {
  hour: 6,
  minute: 45,
  time: '6:45 am'
}, {
  hour: 7,
  minute: 0,
  time: '7:00 am'
}, {
  hour: 7,
  minute: 15,
  time: '7:15 am'
}, {
  hour: 7,
  minute: 30,
  time: '7:30 am'
}, {
  hour: 7,
  minute: 45,
  time: '7:45 am'
}, {
  hour: 8,
  minute: 0,
  time: '8:00 am'
}, {
  hour: 8,
  minute: 15,
  time: '8:15 am'
}, {
  hour: 8,
  minute: 30,
  time: '8:30 am'
}, {
  hour: 8,
  minute: 45,
  time: '8:45 am'
}, {
  hour: 9,
  minute: 0,
  time: '9:00 am'
}, {
  hour: 9,
  minute: 15,
  time: '9:15 am'
}, {
  hour: 9,
  minute: 30,
  time: '9:30 am'
}, {
  hour: 9,
  minute: 45,
  time: '9:45 am'
}, {
  hour: 10,
  minute: 0,
  time: '10:00 am'
}, {
  hour: 10,
  minute: 15,
  time: '10:15 am'
}, {
  hour: 10,
  minute: 30,
  time: '10:30 am'
}, {
  hour: 10,
  minute: 45,
  time: '10:45 am'
}, {
  hour: 11,
  minute: 0,
  time: '11:00 am'
}, {
  hour: 11,
  minute: 15,
  time: '11:15 am'
}, {
  hour: 11,
  minute: 30,
  time: '11:30 am'
}, {
  hour: 11,
  minute: 45,
  time: '11:45 am'
}, {
  hour: 12,
  minute: 0,
  time: '12:00 pm'
}, {
  hour: 12,
  minute: 15,
  time: '12:15 pm'
}, {
  hour: 12,
  minute: 30,
  time: '12:30 pm'
}, {
  hour: 12,
  minute: 45,
  time: '12:45 pm'
}, {
  hour: 13,
  minute: 0,
  time: '1:00 pm'
}, {
  hour: 13,
  minute: 15,
  time: '1:15 pm'
}, {
  hour: 13,
  minute: 30,
  time: '1:30 pm'
}, {
  hour: 13,
  minute: 45,
  time: '1:45 pm'
}, {
  hour: 14,
  minute: 0,
  time: '2:00 pm'
}, {
  hour: 14,
  minute: 15,
  time: '2:15 pm'
}, {
  hour: 14,
  minute: 30,
  time: '2:30 pm'
}, {
  hour: 14,
  minute: 45,
  time: '2:45 pm'
}, {
  hour: 15,
  minute: 0,
  time: '3:00 pm'
}, {
  hour: 15,
  minute: 15,
  time: '3:15 pm'
}, {
  hour: 15,
  minute: 30,
  time: '3:30 pm'
}, {
  hour: 15,
  minute: 45,
  time: '3:45 pm'
}, {
  hour: 16,
  minute: 0,
  time: '4:00 pm'
}, {
  hour: 16,
  minute: 15,
  time: '4:15 pm'
}, {
  hour: 16,
  minute: 30,
  time: '4:30 pm'
}, {
  hour: 16,
  minute: 45,
  time: '4:45 pm'
}, {
  hour: 17,
  minute: 0,
  time: '5:00 pm'
}, {
  hour: 17,
  minute: 15,
  time: '5:15 pm'
}, {
  hour: 17,
  minute: 30,
  time: '5:30 pm'
}, {
  hour: 17,
  minute: 45,
  time: '5:45 pm'
}, {
  hour: 18,
  minute: 0,
  time: '6:00 pm'
}, {
  hour: 18,
  minute: 15,
  time: '6:15 pm'
}, {
  hour: 18,
  minute: 30,
  time: '6:30 pm'
}, {
  hour: 18,
  minute: 45,
  time: '6:45 pm'
}, {
  hour: 19,
  minute: 0,
  time: '7:00 pm'
}, {
  hour: 19,
  minute: 15,
  time: '7:15 pm'
}, {
  hour: 19,
  minute: 30,
  time: '7:30 pm'
}, {
  hour: 19,
  minute: 45,
  time: '7:45 pm'
}, {
  hour: 20,
  minute: 0,
  time: '8:00 pm'
}, {
  hour: 20,
  minute: 15,
  time: '8:15 pm'
}, {
  hour: 20,
  minute: 30,
  time: '8:30 pm'
}, {
  hour: 20,
  minute: 45,
  time: '8:45 pm'
}, {
  hour: 21,
  minute: 0,
  time: '9:00 pm'
}, {
  hour: 21,
  minute: 15,
  time: '9:15 pm'
}, {
  hour: 21,
  minute: 30,
  time: '9:30 pm'
}, {
  hour: 21,
  minute: 45,
  time: '9:45 pm'
}, {
  hour: 22,
  minute: 0,
  time: '10:00 pm'
}, {
  hour: 22,
  minute: 15,
  time: '10:15 pm'
}, {
  hour: 22,
  minute: 30,
  time: '10:30 pm'
}, {
  hour: 22,
  minute: 45,
  time: '10:45 pm'
}, {
  hour: 23,
  minute: 0,
  time: '11:00 pm'
}, {
  hour: 23,
  minute: 15,
  time: '11:15 pm'
}, {
  hour: 23,
  minute: 30,
  time: '11:30 pm'
}, {
  hour: 23,
  minute: 45,
  time: '11:45 pm'
}];