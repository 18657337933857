import React from 'react';
function Icon(props) {
  return <svg xmlns="http://www.w3.org/2000/svg" width={props.size || '24'} height={props.size || '24'} fill="none" viewBox="0 0 24 24">
      <mask id="mask0_448_6312" style={{
      maskType: 'alpha'
    }} width="24" height="24" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0 0H24V24H0z"></path>
      </mask>
      <g mask="url(#mask0_448_6312)">
        <path fill={props.fill || '#1D252D'} d="M7.55 14.45a6.304 6.304 0 01-1.4-2.088A6.192 6.192 0 015.675 10c0-.8.158-1.588.475-2.363a6.307 6.307 0 011.4-2.087l.925.925a4.68 4.68 0 00-1.1 1.625A5.014 5.014 0 007 10c0 .617.121 1.233.363 1.85a4.862 4.862 0 001.112 1.675l-.925.925zM18.8 16.8l-.925-.925a7.987 7.987 0 001.825-2.762c.4-1.025.6-2.063.6-3.113 0-1.05-.2-2.088-.6-3.113a7.987 7.987 0 00-1.825-2.762L18.8 3.2c.933.967 1.638 2.029 2.113 3.187A9.442 9.442 0 0121.625 10c0 1.25-.225 2.454-.675 3.613-.45 1.158-1.167 2.22-2.15 3.187zm-13.6 0c-.95-.967-1.658-2.029-2.125-3.187a9.583 9.583 0 01-.7-3.613c0-1.25.233-2.454.7-3.613C3.542 5.229 4.25 4.167 5.2 3.2l.925.925A7.987 7.987 0 004.3 6.887 8.503 8.503 0 003.7 10c0 1.05.2 2.088.6 3.113a7.987 7.987 0 001.825 2.762L5.2 16.8zm11.25-2.35l-.925-.925a4.681 4.681 0 001.1-1.625c.25-.617.375-1.25.375-1.9 0-.6-.125-1.208-.375-1.825a5.508 5.508 0 00-1.1-1.7l.925-.925a5.962 5.962 0 011.375 2.1c.3.783.467 1.567.5 2.35 0 .8-.158 1.587-.475 2.362a6.304 6.304 0 01-1.4 2.088zm-8.9 6.725l3.125-9.425a2.18 2.18 0 01-.637-.763A2.125 2.125 0 019.8 10c0-.617.213-1.137.638-1.562A2.122 2.122 0 0112 7.8c.617 0 1.137.213 1.562.638.425.425.638.945.638 1.562a2.15 2.15 0 01-.875 1.75l3.125 9.425h-1.5l-.65-2H9.725l-.65 2H7.55zm2.675-3.5h3.55L12 12.325l-1.775 5.35z"></path>
      </g>
    </svg>;
}
export default Icon;