import React from 'react';
function Icon() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" fill="none" viewBox="0 0 25 24">
      <mask id="mask0_85_5637" style={{
      maskType: 'alpha'
    }} width="25" height="25" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0.999 0.001H24.999V24.001H0.999z"></path>
      </mask>
      <g mask="url(#mask0_85_5637)">
        <path fill="#1D252D" fillOpacity="0.7" d="M5.299 19.501c-.5 0-.925-.175-1.275-.525a1.736 1.736 0 01-.525-1.275v-11c0-.517.175-.95.525-1.3.35-.35.775-.525 1.275-.525h5.324c.25 0 .488.05.713.15.225.1.412.225.563.375l1.1 1.1h7.7c.5 0 .924.175 1.274.525.35.35.526.775.526 1.275v9.4c0 .5-.175.925-.525 1.275-.35.35-.776.525-1.276.525h-15.4z"></path>
      </g>
    </svg>;
}
export default Icon;