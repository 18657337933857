import React from 'react';
function Icon(props) {
  if (props.size === 'lg' || props.size === 'thicc') {
    return <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 -960 960 960">
        <path d="M481.621-180.001q-125.04 0-212.561-87.503-87.52-87.503-87.52-212.435t87.52-212.496q87.521-87.564 212.479-87.564 78 0 141.025 34.167 63.026 34.167 105.641 94.398v-128.565h50.255v231.895H546.565v-50.255h157.999q-34.589-59.82-92.359-95.602-57.769-35.783-130.666-35.783-104.505 0-177.124 72.611-72.62 72.611-72.62 177.103t72.647 177.133q72.648 72.641 177.192 72.641 79.879 0 145.264-45.693 65.384-45.692 91.461-120.666h52.409q-27.641 97.255-107.732 156.934-80.091 59.68-181.415 59.68z"></path>
      </svg>;
  }
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 -960 960 960">
      <path d="M481.539-180.001q-125.625 0-212.812-87.17-87.187-87.169-87.187-212.768t87.187-212.829q87.187-87.231 212.812-87.231 70.154 0 132.769 31.193 62.615 31.192 104.153 88.039v-119.232h59.999v244.613H533.847v-59.998h157.999q-31.615-57.923-87.692-91.27Q548.077-720 481.539-720q-100 0-170 70t-70 170q0 100 70 170t170 70q77 0 139-44t87-116h63.229q-27.231 97.922-107.269 158.961-80.038 61.038-181.96 61.038z"></path>
    </svg>;
}
export default Icon;