import React, { useState, useContext, useEffect } from 'react';
import { UserStateContext } from 'context/user-state-context';
import { createCredentials as createCredentialsAPI, updateCredential as updateCredentialsAPI, deleteCredential as deleteCredentialsAPI, getLoyaltyCompanies } from '../integrations.resource';
import { history } from '../../../root.component';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { AddIcon, CancelIcon, CloseIcon, DeleteIcon, EditIcon, HelpIcon, VisibilityIcon, VisibilityOffIcon } from 'components/mui';
import { snackbarService } from 'components/mui/snackbar/snackbar-service';
const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius: `14px`,
      '& fieldset': {}
    }
  }
})(TextField);
const INTEGRATION_KEY = 'loyalty';
export const IntegrationLoyaltyMui = props => {
  const {
    user,
    asCompany,
    hasPermission
  } = useContext(UserStateContext);
  const [email, setEmail] = useState(props?.credential?.identifier || '');
  const [password, setPassword] = useState(props.credential?.secret1_hidden || '');
  const [company, setCompany] = useState('');
  const [companies, setCompanies] = useState(null);
  const [changesMade, setChangesMade] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [saving, setSaving] = useState(false);
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(null);
  const [unsavedChangesModalOpen, setUnsavedChangesModalOpen] = useState(null);
  const [showHiddenValues, setShowHiddenValues] = useState([]);
  const [openConfirmProtectedEditModal, setOpenConfirmProtectedEditModal] = useState(null);
  const [protectedDataEditing, setProtectedDataEditing] = useState([]);
  const [addError, setAddError] = useState(null);
  const handleGetLoyalCompanies = () => {
    getLoyaltyCompanies({
      email,
      password
    }).then(comps => {
      setCompanies(comps || []);
    }).catch(err => {
      if (err.response) {
        console.log(err, 'err');
        setAddError(err.response.data?.non_field_errors[0]);
      } else {
        setAddError('An unknown error occured');
      }
    });
  };
  const handleDeleteCredential = () => {
    deleteCredentialsAPI(props?.credential?.id).then(() => {
      history.push(`/integrations/manage/${props?.install.id}`, {
        sucessfulCredential: 'Credential sucessfully deleted'
      });
    });
  };
  useEffect(() => {
    //if they change email or password after hitting get companys button, reset companies
    if (companies !== null) {
      setCompanies(null);
      setCompany('');
    }
  }, [email, password]);
  const handleSubmit = () => {
    if (!email || email == '' || !password || password == '' || !company || company == '') {
      setShowErrors(true);
      return;
    }
    setSaving(true);
    const company_data = companies.find(c => c.api_key === company);
    if (props?.credential) {
      //existing credential

      updateCredentialsAPI(props.credential.id, {
        identifier: email,
        secret1: password,
        secret2: company_data.id,
        entity_data: {
          name: company_data.name
        }
      }).then(() => {
        history.push(`/integrations/manage/${props?.install.id}`, {
          sucessfulCredential: 'Credential sucessfully edited. Syncing will begin soon with these new credentials'
        });
      }).catch(err => {
        if (err.response.data.site_id) {
          snackbarService.popup({
            type: 'error',
            message: err.response.data.site_id[0]
          });
        } else {
          snackbarService.popup({
            type: 'error',
            message: 'There was an error editing the credential. Please try again or contact support.'
          });
        }
      });
    } else {
      //new credential
      createCredentialsAPI({
        identifier: email,
        secret1: password,
        secret2: company_data.id,
        company: asCompany.id,
        integration: INTEGRATION_KEY,
        entity_data: {
          name: company_data.name
        }
      }).then(() => {
        history.push(`/integrations/manage/${props?.install.id}`, {
          sucessfulCredential: 'New loyalty company sucessfully added. Syncing will begin soon'
        });
      }).catch(err => {
        if (err.response.data.non_field_errors) {
          snackbarService.popup({
            type: 'error',
            message: err.response.data.non_field_errors[0]
          });
        } else {
          snackbarService.popup({
            type: 'error',
            message: 'Unknown error trying to add credentials. Please try again.'
          });
        }
      });
    }
  };
  const handleProtectedDataModal = () => {
    //have we shown the modal?
    if (protectedDataEditing.length == 0) {
      setOpenConfirmProtectedEditModal(true);
    } else {
      handleProtectedDataEdit();
    }
  };
  const handleProtectedDataEdit = () => {
    setChangesMade(true);
    setPassword('');
    setProtectedDataEditing([...protectedDataEditing, 'password', 'email']);
    setOpenConfirmProtectedEditModal(null);
  };
  return <>
      <Stack>
        <Box sx={{
        pb: '16px'
      }}>
          <Typography sx={{
          fontSize: '18px',
          fontWeight: '300',
          lineHeight: '26px'
        }}>Configuration</Typography>
        </Box>
        <Typography>Please enter your Cinch Loyalty below to link to your account.</Typography>
        {!!addError && <p className="form-error">{addError}</p>}
        <Box sx={{
        py: '16px'
      }}>
          <Stack direction={'row'} spacing="16px">
            {/* EMAIL FIELD */}
            <CustomTextField sx={{
            width: '50%'
          }} helperText={<>{showErrors && (!email || email == '') && 'Email is required.'}</>} error={showErrors && (!email || email == '')} value={email} variant={props.credential && !protectedDataEditing.includes('email') ? 'filled' : 'outlined'} disabled={props.credential && !protectedDataEditing.includes('email') || !hasPermission('integration.change_credential')} required label={'Email Address'} onChange={event => {
            setChangesMade(true);
            setEmail(event.target.value);
          }} InputProps={{
            readOnly: props.credential && !protectedDataEditing.includes('email'),
            endAdornment: props.credential && !protectedDataEditing.includes('email') && hasPermission('integration.change_credential') && <InputAdornment position="end">
                      <Stack direction="row" spacing="5px">
                        <Box sx={{
                  display: 'flex',
                  alignItems: 'center'
                }}>
                          <Tooltip title="Toggle Edit">
                            <IconButton onClick={e => {
                      handleProtectedDataModal();
                    }}>
                              <EditIcon fill="#1D252D" />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Stack>
                    </InputAdornment>
          }} />
            {/* PASSWORD FIELD */}

            <CustomTextField sx={{
            width: '50%'
          }} helperText={<>{showErrors && (!password || password == '') && 'A password is required.'}</>} error={showErrors && (!password || password == '')} value={password} required type={showHiddenValues.includes('password') && (!props.credential || protectedDataEditing.includes('password')) ? 'text' : 'password'} label={'Password'} disabled={props.credential && !protectedDataEditing.includes('password') || !hasPermission('integration.change_credential')} variant={props.credential && !protectedDataEditing.includes('password') ? 'filled' : 'outlined'} onChange={event => {
            setChangesMade(true);
            setPassword(event.target.value);
          }} InputProps={{
            readOnly: props.credential && !protectedDataEditing.includes('password'),
            endAdornment: props.credential && !protectedDataEditing.includes('password') && hasPermission('integration.change_credential') ? <InputAdornment position="end">
                      <Stack direction="row" spacing="5px">
                        <Box sx={{
                  display: 'flex',
                  alignItems: 'center'
                }}>
                          <Tooltip title="Toggle Edit">
                            <IconButton onClick={e => {
                      handleProtectedDataModal();
                    }}>
                              <EditIcon fill="#1D252D" />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Stack>
                    </InputAdornment> : <>
                      {hasPermission('integration.change_credential') && <InputAdornment position="end">
                          <Stack direction="row" spacing="5px">
                            <Box sx={{
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                              <Tooltip title="Toggle Visibility">
                                <IconButton onClick={e => {
                        if (showHiddenValues.includes('password')) {
                          setShowHiddenValues(showHiddenValues.filter(value => value !== 'password'));
                        } else {
                          setShowHiddenValues([...showHiddenValues, 'password']);
                        }
                      }}>
                                  {showHiddenValues.includes('password') ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </Stack>
                        </InputAdornment>}
                    </>
          }} />
          </Stack>
        </Box>
        <Box sx={{
        pb: '16px'
      }}>
          {props?.credential && !companies && protectedDataEditing.length == 0 ? <CustomTextField sx={{
          width: '50%'
        }} value={props.credential?.entity_data?.name || ''} variant="filled" disabled label={'Company'} InputProps={{
          readOnly: true,
          endAdornment: hasPermission('integration.change_credential') && <InputAdornment position="end">
                    <Stack direction="row" spacing="5px">
                      <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                        <Tooltip title="Toggle Edit">
                          <IconButton onClick={e => {
                    handleProtectedDataModal();
                  }}>
                            <EditIcon fill="#1D252D" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Stack>
                  </InputAdornment>
        }} /> : <>
              {!companies ? <Button variant="contained" onClick={e => {
            handleGetLoyalCompanies();
          }} disabled={!email || email == '' || !password || password == '' || !hasPermission('integration.change_credential')}>
                  Get Companys
                </Button> : <>
                  {companies?.length > 0 ? <FormControl sx={{
              width: '48%'
            }}>
                      <InputLabel id="company-label">Company</InputLabel>
                      <Select labelId="company-label" id="company" value={company} label="company" onChange={e => {
                setCompany(e.target.value);
              }}>
                        {companies.map((company, index) => <MenuItem key={company.api_key} value={company.api_key}>
                            {company.name}
                          </MenuItem>)}
                      </Select>
                    </FormControl> : <>No Companies Found with these credentials</>}
                </>}
            </>}
        </Box>

        <Stack sx={{
        pt: '16px'
      }}>
          <Divider />
          <Box sx={{
          pr: '20px',
          pt: '20px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
            <Stack direction="row" spacing="16px">
              {props?.credential && hasPermission('integration.change_credential') ? <Button variant="contained" sx={{
              backgroundColor: '#F3F3F4',
              '&:hover': {
                backgroundColor: '#F3F3F4'
              }
            }} startIcon={<CancelIcon fill="#3898D9" />} onClick={e => {
              setOpenConfirmDeleteModal(true);
            }}>
                  <Box sx={{
                py: '5px',
                pr: '12px'
              }}>
                    <Typography sx={{
                  color: '#3898D9'
                }}>Remove </Typography>
                  </Box>
                </Button> : <Box></Box>}
            </Stack>
            <Stack direction="row" spacing="16px">
              <Button variant="contained" sx={{
              backgroundColor: '#F3F3F4',
              '&:hover': {
                backgroundColor: '#F3F3F4'
              }
            }} onClick={e => {
              if (changesMade) {
                setUnsavedChangesModalOpen(true);
              } else {
                history.push(`/integrations/manage/${props?.install?.id}`);
              }
            }}>
                <Box sx={{
                py: '5px',
                px: '12px'
              }}>
                  <Typography sx={{
                  color: '#3898D9'
                }}>Cancel </Typography>
                </Box>
              </Button>
              <Button sx={{
              backgroundColor: '#3898D9',
              '&:hover': {
                backgroundColor: '#3898D9'
              }
            }} variant="contained" endIcon={saving && <CircularProgress sx={{
              color: '#FFF'
            }} size={'20px'} />} disabled={(!props.credential || props.credential && protectedDataEditing.length != 0) && (!changesMade || saving || !email || email == '' || !password || password == '' || !company || company == '')} onClick={e => {
              if (protectedDataEditing.length == 0 && props.credential) {
                history.push(`/integrations/manage/${props?.install.id}`);
              } else {
                handleSubmit();
              }
            }}>
                <Box sx={{
                py: '6px',
                px: '12px'
              }}>
                  <Typography sx={{
                  color: '#fff'
                }}>
                    {saving ? 'Working...' : protectedDataEditing.length == 0 && props.credential ? 'Done' : 'Submit'}
                  </Typography>
                </Box>
              </Button>
            </Stack>
          </Box>
        </Stack>
      </Stack>

      <Dialog scroll="paper" PaperProps={{
      style: {
        borderRadius: '28px'
      }
    }} open={!!unsavedChangesModalOpen} onClose={(e, r) => {
      setUnsavedChangesModalOpen(null);
    }}>
        <DialogTitle sx={{
        m: 0,
        p: 2,
        fontSize: 'medium'
      }}>
          <Stack spacing={'20px'}>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Stack direction="row" spacing={'10px'}>
                <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                  <Typography sx={{
                  fontWeight: '300',
                  lineHeight: '30px',
                  fontSize: '24px',
                  display: 'flex',
                  alignItems: 'center'
                }} variant="h4" component="span">
                    Unsaved Changes
                  </Typography>
                </Box>
              </Stack>

              <IconButton onClick={() => {
              setUnsavedChangesModalOpen(null);
            }}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Divider />
          </Stack>
        </DialogTitle>

        <DialogContent sx={{
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
          width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
          marginTop: '20px',
          marginBottom: '20px'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#888'
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555'
        }
      }}>
          <Stack sx={{
          pt: 1
        }} spacing={'24px'}>
            <Box>You have unsaved changes. Do you want to discard them? </Box>
          </Stack>
        </DialogContent>

        <DialogActions sx={{
        pt: 1,
        pb: 2
      }}>
          <Stack sx={{
          pr: '24px'
        }} direction={'row'} spacing={'20px'}>
            <Button onClick={() => {
            setUnsavedChangesModalOpen(false);
          }} variant="contained" sx={{
            backgroundColor: '#F3F3F4',
            '&:hover': {
              backgroundColor: '#F3F3F4'
            }
          }}>
              <Box sx={{
              py: '5px',
              px: '12px'
            }}>
                <Typography sx={{
                color: '#3898D9'
              }}>Cancel </Typography>
              </Box>
            </Button>
            <Button onClick={() => {
            history.push(`/integrations/manage/${props?.install.id}`);
          }} variant="contained" sx={{
            backgroundColor: '#3898D9',
            '&:hover': {
              backgroundColor: '#3898D9'
            }
          }}>
              <Box sx={{
              py: '5px',
              px: '12px'
            }}>
                <Typography sx={{
                color: '#fff'
              }}> Discard</Typography>
              </Box>
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>

      <Dialog scroll="paper" PaperProps={{
      style: {
        borderRadius: '28px'
      }
    }} open={!!openConfirmDeleteModal} onClose={(e, r) => {
      setOpenConfirmDeleteModal(null);
    }}>
        <DialogTitle sx={{
        m: 0,
        p: 2,
        fontSize: 'medium'
      }}>
          <Stack spacing={'20px'}>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Stack direction="row" spacing={'10px'}>
                <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                  <Typography sx={{
                  fontWeight: '300',
                  lineHeight: '30px',
                  fontSize: '24px',
                  display: 'flex',
                  alignItems: 'center'
                }} variant="h4" component="span">
                    Confirm Remove Credential
                  </Typography>
                </Box>
              </Stack>

              <IconButton onClick={() => {
              setOpenConfirmDeleteModal(null);
            }}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Divider />
          </Stack>
        </DialogTitle>

        <DialogContent sx={{
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
          width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
          marginTop: '20px',
          marginBottom: '20px'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#888'
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555'
        }
      }}>
          <Stack sx={{
          pt: 1
        }} spacing={'24px'}>
            <Box>Are you sure you want to remove this credential? </Box>
          </Stack>
        </DialogContent>

        <DialogActions sx={{
        pt: 1,
        pb: 2
      }}>
          <Stack sx={{
          pr: '24px'
        }} direction={'row'} spacing={'20px'}>
            <Button onClick={() => {
            setOpenConfirmDeleteModal(false);
          }} variant="contained" sx={{
            backgroundColor: '#F3F3F4',
            '&:hover': {
              backgroundColor: '#F3F3F4'
            }
          }}>
              <Box sx={{
              py: '5px',
              px: '12px'
            }}>
                <Typography sx={{
                color: '#3898D9'
              }}>Cancel </Typography>
              </Box>
            </Button>
            <Button onClick={() => {
            handleDeleteCredential();
            setOpenConfirmDeleteModal(null);
          }} variant="contained" sx={{
            backgroundColor: '#3898D9',
            '&:hover': {
              backgroundColor: '#3898D9'
            }
          }}>
              <Box sx={{
              py: '5px',
              px: '12px'
            }}>
                <Typography sx={{
                color: '#fff'
              }}> Remove</Typography>
              </Box>
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>

      <Dialog scroll="paper" PaperProps={{
      style: {
        borderRadius: '28px'
      }
    }} open={!!openConfirmProtectedEditModal} onClose={(e, r) => {
      setOpenConfirmProtectedEditModal(null);
    }}>
        <DialogTitle sx={{
        m: 0,
        p: 2,
        fontSize: 'medium'
      }}>
          <Stack spacing={'20px'}>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Stack direction="row" spacing={'10px'}>
                <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                  <Typography sx={{
                  fontWeight: '300',
                  lineHeight: '30px',
                  fontSize: '24px',
                  display: 'flex',
                  alignItems: 'center'
                }} variant="h4" component="span">
                    Confirm Protected Data Edit
                  </Typography>
                </Box>
              </Stack>

              <IconButton onClick={() => {
              setOpenConfirmProtectedEditModal(null);
            }}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Divider />
          </Stack>
        </DialogTitle>

        <DialogContent sx={{
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
          width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
          marginTop: '20px',
          marginBottom: '20px'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#888'
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555'
        }
      }}>
          <Stack sx={{
          pt: 1
        }} spacing={'24px'}>
            <Box>
              Are you sure you want to edit this protected data? This action will clear the password field and you will have to fully
              re-enter the data.
            </Box>
          </Stack>
        </DialogContent>

        <DialogActions sx={{
        pt: 1,
        pb: 2
      }}>
          <Stack sx={{
          pr: '24px'
        }} direction={'row'} spacing={'20px'}>
            <Button onClick={() => {
            setOpenConfirmProtectedEditModal(null);
          }} variant="contained" sx={{
            backgroundColor: '#F3F3F4',
            '&:hover': {
              backgroundColor: '#F3F3F4'
            }
          }}>
              <Box sx={{
              py: '5px',
              px: '12px'
            }}>
                <Typography sx={{
                color: '#3898D9'
              }}>Cancel </Typography>
              </Box>
            </Button>
            <Button onClick={() => {
            handleProtectedDataEdit();
          }} variant="contained" sx={{
            backgroundColor: '#3898D9',
            '&:hover': {
              backgroundColor: '#3898D9'
            }
          }}>
              <Box sx={{
              py: '5px',
              px: '12px'
            }}>
                <Typography sx={{
                color: '#fff'
              }}> Allow Editing</Typography>
              </Box>
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </>;
};