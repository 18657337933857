import React, { useEffect, useState, useContext } from 'react';
import { Scoped, k } from 'kremling';
import cinchLogo from '../../assets/img/cinch-logo.svg';
import signInImg from '../../assets/img/sign-in-pattern.jpg';
import { snackbarService } from 'components/mui/snackbar/snackbar-service';
import { getSSORedirect, getSSOToken } from 'shared/auth.api';
import { UserStateContext } from 'context/user-state-context';
export const SignInSSO = ({
  match,
  history
}) => {
  const {
    setToken,
    setTokenExpiry
  } = useContext(UserStateContext);
  const [message, setMessage] = useState('');
  const [debug, setDebug] = useState([]);
  useEffect(() => {
    if (match.params.provider) {
      const sidebarParam = new URLSearchParams(window.location.search);
      if (sidebarParam.has('code')) {
        // Handle redirect
        setMessage('Please wait while we validate your SSO account.');
        getSSOToken(match.params.provider).then(({
          data
        }) => {
          setToken(data.token);
          setTokenExpiry(data.expires_when);
          history.push('/companies');
        }).catch(e => {
          if (e?.response?.data?.non_field_errors) {
            setMessage(e?.response?.data?.non_field_errors[0]);
          } else {
            setMessage(e.toString());
          }
        });
      } else if (sidebarParam.size > 0) {
        setMessage('Unknown error from SSO provider.  Please send this information to support:');
        setDebug(Array.from(sidebarParam.entries()));
      } else {
        setMessage('Redirecting you to your authentication provider.');
        getSSORedirect(match.params.provider).then(({
          data: url
        }) => {
          window.location.href = url;
        }).catch(() => {
          snackbarService.popup({
            type: 'error',
            message: `Unable to generate SSO redirect for the provider ${match.params.provider}`
          });
        });
      }
    }
  }, [match?.params?.provider]);
  return <Scoped css={css}>
      <div className="wrapper-contain">
        <div className="sign-in__wrapper" style={{
        backgroundImage: `url(${signInImg})`
      }}>
          <div className="sign-in">
            <div className="text-center">
              <div className="mb-5">
                <div className="logo" dangerouslySetInnerHTML={{
                __html: cinchLogo
              }} />
              </div>
            </div>
            <div className="mb-5">
              {message}

              {debug.map(([key, val]) => <div key={key}>
                <strong>{key}</strong> - {val}
              </div>)}
            </div>
          </div>
        </div>
      </div>
    </Scoped>;
};
const css = {
  styles: `[kremling="i3"] body,body[kremling="i3"] {
  --color-primary: #487aae;
  --color-highlight: #e7efff;
  --color-accent: #354052;
  --color-success: #73b56e;
  --color-warning: #f4b707;
  --color-danger: #df5651;
  --color-grey-10: #f9f9f9;
  --color-grey-25: #f3f3f3;
  --color-grey-50: #e9e9e9;
  --color-grey-75: #e3e3e3;
  --color-grey-100: #d3d3d3;
  --color-grey-200: #c3c3c3;
  --color-grey-300: #b3b3b3;
  --color-grey-400: #a3a3a3;
  --color-grey-500: #808080;
  --color-grey-600: #707070;
  --color-grey-700: #606060;
  --color-grey-800: #505050;
  --color-grey-900: #404040;
  --base-font-family: Roboto, Helvetica, Arial, sans-serif;
  --base-font-size: 1.4rem;
  --base-font-color: #404040;
  --base-font-weight: 400;
  --base-line-height: 1.4;
  --box-shadow-1: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.12);
  --box-shadow-2: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-3: 0 10px 20px rgba(0, 0, 0, 0.17), 0 6px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-4: 0 14px 28px rgba(0, 0, 0, 0.13), 0 10px 10px rgba(0, 0, 0, 0.1);
  --box-shadow-5: 0 19px 38px rgba(0, 0, 0, 0.18), 0 15px 12px rgba(0, 0, 0, 0.1);
  --base-border-radius: 0.4rem;
}

[kremling="i3"] .sign-in__wrapper,[kremling="i3"].sign-in__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-position: center;
}

[kremling="i3"] .sign-in,[kremling="i3"].sign-in {
  background-color: #fff;
  padding: 5rem 2rem;
  border-radius: 2rem;
  box-shadow: var(--box-shadow-5);
  flex-grow: 1;
}
@media (min-width: 768px) {
  [kremling="i3"] .sign-in,[kremling="i3"].sign-in {
    border-radius: 1rem;
    padding: 5rem 3rem;
    width: 36rem;
    flex-grow: 0;
  }
}

[kremling="i3"] .logo,[kremling="i3"].logo {
  fill: var(--color-primary);
  width: 100px;
  margin: 0 auto;
}

[kremling="i3"] .request,[kremling="i3"].request {
  color: #12aae1;
  font-size: 16px;
  line-height: 20px;
  text-decoration: underline;
}

[kremling="i3"] .bg-wrapper,[kremling="i3"].bg-wrapper {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

[kremling="i3"] .bg-wrapper video,[kremling="i3"].bg-wrapper video {
  min-width: 100vw;
  min-height: 100vh;
}

[kremling="i3"] .remember-me,[kremling="i3"].remember-me {
  color: #8f8f8f;
  font-size: 16px;
  line-height: 20px;
}

[kremling="i3"] .best,[kremling="i3"].best {
  color: #646464;
  font-size: 16px;
  line-height: 20px;
}

[kremling="i3"] .btn-sm,[kremling="i3"].btn-sm {
  font-weight: normal;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

[kremling="i3"] .forgot-btn,[kremling="i3"].forgot-btn {
  position: absolute;
  right: 6px;
  top: 37px;
  color: #a5a5a5;
  font-size: 12px;
  text-transform: inherit;
  border-radius: 2px;
  background-color: #e3e3e3;
}

[kremling="i3"] .filter,[kremling="i3"].filter {
  z-index: 100;
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  width: 100vw;
  height: 100vh;
}

[kremling="i3"] .checkbox-box,[kremling="i3"].checkbox-box {
  float: left;
  width: 30px;
}

[kremling="i3"] .checkbox-label,[kremling="i3"].checkbox-label {
  float: left;
  margin-bottom: 13px;
  margin-top: -5px;
}

[kremling="i3"] .width-240,[kremling="i3"].width-240 {
  width: 240px;
}

[kremling="i3"] .invalid-input,[kremling="i3"].invalid-input {
  margin-bottom: 0px;
  color: red;
}`,
  id: 'i3',
  namespace: 'kremling'
};