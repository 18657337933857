import React, { Component } from 'react';
import { func, object, shape, string } from 'prop-types';
import { getCoupons, getCoupon } from 'shared/common.api';
import { UserStateContext } from 'context/user-state-context';
import { DynamicTypeAhead } from '../../../../components/dynamic-type-ahead/dynamic-type-ahead.component';
export class ConditionHasOffer extends Component {
  static contextType = UserStateContext;
  static propTypes = {
    update: func,
    node: object,
    data: shape({
      coupon_id: string,
      coupon_name: string,
      session: string
    })
  };
  state = {
    couponList: []
  };
  componentDidMount() {
    const {
      coupon_id
    } = this.props.data;
    if (coupon_id) {
      getCoupon(coupon_id).then(({
        data
      }) => {
        this.setState({
          selectedCoupon: data
        });
      });
    }
  }
  toggleCoupon = coupon => {
    this.setState({
      selectedCoupon: coupon
    });
    this.props.update({
      ...this.props.data,
      coupon_id: coupon ? coupon.id : null,
      coupon_name: coupon ? coupon.name : null
    });
  };
  changeSession = e => {
    this.props.update({
      ...this.props.data,
      session: e.target.value
    });
  };
  render() {
    const {
      selectedCoupon
    } = this.state;
    const {
      session
    } = this.props.data;
    return <div>
        <div className="form-group">
          <label>Coupon</label>
          <DynamicTypeAhead getItems={getCoupons} getItemsFilters={{
          company: this.context.asCompany.id,
          entity_source: 'loyalty'
        }} placeholder={selectedCoupon ? `${selectedCoupon.name}` : 'Choose Coupon'} displayProperty="name" keyProperty="id" value={selectedCoupon ? selectedCoupon.id : null} onChange={coupon => this.toggleCoupon(coupon)} />
        </div>
        <div className="form-group">
          <label>Session</label>
          <select className="form-control" value={session} onChange={this.changeSession}>
            <option value="soft_add">
              Add coupon to session, if currently no possession
            </option>
            <option value="force_add">
              Add coupon to session, no matter what
            </option>
            <option value="do_nothing">Do nothing</option>
          </select>
        </div>
      </div>;
  }
}