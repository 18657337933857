import React, { Component } from 'react';
import { object, func } from 'prop-types';
import { RRule } from 'rrule';
import { toInteger } from 'lodash';
import { RecurringOption } from './recurring-option.component';
export class Weekly extends Component {
  static propTypes = {
    update: func,
    data: object,
    rrule: object
  };
  updateEveryWeek = (options = {}) => {
    this.props.update({
      rrule: {
        ...this.props.rrule.options,
        ...options
      }
    });
  };
  updateEveryWeekByDay = day => {
    const {
      byweekday
    } = RRule.fromString(this.props.data.rrule).options;
    this.updateEveryWeek({
      byweekday: byweekday.includes(day) ? [...byweekday.slice(0, byweekday.indexOf(day)), ...byweekday.slice(byweekday.indexOf(day) + 1)] : [...byweekday, day]
    });
  };
  render() {
    const {
      rrule
    } = this.props;
    return <div>
        <RecurringOption active>
          <div className="form-group">
            Every
            <input onChange={e => this.updateEveryWeek({
            interval: toInteger(e.target.value) || 1
          })} value={rrule.options.interval} className="form-control inline recurring-inline-with-text" type="number" />
            week{rrule.options.interval > 1 ? 's' : ''}
          </div>
          <div className="recurring-inline-days">
            <div>
              <label>On Day(s)</label>
            </div>
            <label>
              <input onChange={() => this.updateEveryWeekByDay(0)} checked={rrule.options.byweekday.includes(0)} type="checkbox" />{' '}
              <span>Mon</span>
            </label>
            <label>
              <input onChange={() => this.updateEveryWeekByDay(1)} checked={rrule.options.byweekday.includes(1)} type="checkbox" />{' '}
              <span>Tue</span>
            </label>
            <label>
              <input onChange={() => this.updateEveryWeekByDay(2)} checked={rrule.options.byweekday.includes(2)} type="checkbox" />{' '}
              <span>Wed</span>
            </label>
            <label>
              <input onChange={() => this.updateEveryWeekByDay(3)} checked={rrule.options.byweekday.includes(3)} type="checkbox" />{' '}
              <span>Thu</span>
            </label>
            <label>
              <input onChange={() => this.updateEveryWeekByDay(4)} checked={rrule.options.byweekday.includes(4)} type="checkbox" />{' '}
              <span>Fri</span>
            </label>
            <label>
              <input onChange={() => this.updateEveryWeekByDay(5)} checked={rrule.options.byweekday.includes(5)} type="checkbox" />{' '}
              <span>Sat</span>
            </label>
            <label>
              <input onChange={() => this.updateEveryWeekByDay(6)} checked={rrule.options.byweekday.includes(6)} type="checkbox" />{' '}
              <span>Sun</span>
            </label>
          </div>
        </RecurringOption>
      </div>;
  }
}