import React from 'react';
import { AsyncModalDialog } from 'components/modal/async-modal-dialog.component';
import classnames from 'classnames';
export const CreateModal = props => {
  const {
    showCreateModal,
    setShowCreateModal,
    createGroup,
    createErrors,
    setCreateData,
    createData
  } = props;
  return <AsyncModalDialog open={!!showCreateModal} title="Create Customer Group" submitText="Create" onSubmit={createGroup} onClose={() => setShowCreateModal(false)} allowBackdropClick>
      {createErrors.non_field_errors && createErrors.non_field_errors.map((e, i) => <div className="invalid-feedback text-danger mb-3" key={i}>
            {e}
          </div>)}
      <div className={classnames('form-group', {
      'is-invalid': createErrors.entity_group
    })}>
        <label>Group ID</label>
        <input className="form-control" name="create-group-id" onChange={e => setCreateData({
        ...createData,
        entity_group: e.target.value
      })} value={createData.entity_group || ''} autoFocus />
        {createErrors.entity_group && createErrors.entity_group.map((e, i) => <div className="invalid-feedback text-danger" key={i}>
              {e}
            </div>)}
      </div>
      <div className={classnames('form-group', {
      'is-invalid': createErrors.name
    })}>
        <label>Group Name</label>
        <input className="form-control" name="create-group-name" onChange={e => setCreateData({
        ...createData,
        name: e.target.value
      })} value={createData.name || ''} />
        {createErrors.name && createErrors.name.map((e, i) => <div className="invalid-feedback text-danger" key={i}>
              {e}
            </div>)}
      </div>
    </AsyncModalDialog>;
};