import React, { Component } from 'react';
import { Scoped } from 'kremling';
import { string, func } from 'prop-types';
import styles from './sort-header.styles.scss';
import { Icon } from '../icon/icon.component';
export class SortHeader extends Component {
  static propTypes = {
    ordering: string,
    name: string,
    update: func
  };
  render() {
    const {
      children,
      update,
      name,
      ordering
    } = this.props;
    return <Scoped css={styles}>
        <th className="sort-header" onClick={() => update(name === ordering ? `-${name}` : name)}>
          {children}
          {name !== ordering && `-${name}` !== ordering && <Icon className="faded ml-2" name="fa-solid-sort" size={14} />}
          {name === ordering && <Icon className="ml-2" name="fa-solid-sort-up" size={14} />}
          {`-${name}` === ordering && <Icon className="ml-2" name="fa-solid-sort-down" size={14} />}
        </th>
      </Scoped>;
  }
}