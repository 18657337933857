import React, { useEffect, useContext, useState } from 'react';
import { UserStateContext } from 'context/user-state-context';
import { createAdwordsAudience, deleteAdwordsAccount, getAdwordsAudiencesNoUnwrap, syncAdwordsAudiences } from '../integrations.resource';
import { history } from '../../../root.component';
import { snackbarService } from 'components/mui/snackbar/snackbar-service';
import { Box, Button, IconButton, Paper, Stack, TextField, Tooltip, Typography, Divider, InputAdornment, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { withStyles } from '@mui/styles';
import { AddIcon, CancelIcon, CloseIcon, OpenInNewWindowIcon, SyncIcon } from 'components/mui';
import { Icon } from 'components';
const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius: `14px`,
      '& fieldset': {}
    }
  }
})(TextField);
export const IntegrationAdwordsUpdate = props => {
  const {
    user,
    asCompany,
    hasPermission
  } = useContext(UserStateContext);
  const [loading, setLoading] = useState(true);
  const [clientCustomerID, setClientCustomerID] = useState('');
  const [audienceName, setAudienceName] = useState('');
  const [audienceDescription, setAudienceDescription] = useState('');
  const [audiences, setAudiences] = useState([]);
  const [clientCustomerIDFocusLost, setClientCustomerIDFocusLost] = useState(false);
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(null);
  const [renderIteration, setRenderIteration] = useState(0);
  const [tokenIsRefreshing, setTokenIsRefreshing] = useState(false);
  const [showCreateAudienceModal, setShowCreateAudienceModal] = useState(false);
  const [search, setSearch] = useState(null);
  const [filters, setFilters] = useState({});
  const [showSearch, setShowSearch] = useState(false);
  const [searching, setSearching] = useState(false);
  useEffect(() => {
    if (props?.credential && asCompany?.id) {
      setSearching(true);
      getAdwordsAudiencesNoUnwrap({
        account__company: asCompany.id,
        account: props.credential?.id,
        limit: 1000,
        ...filters
      }).then(({
        data
      }) => {
        setAudiences(data.results);
        setLoading(false);
        setSearching(false);
      });
    }
  }, [props?.credential, renderIteration, asCompany.id, filters]);
  useEffect(() => {
    if (search === undefined || search === null) {
      // On our first load this will be a non string so don't load data again.
      return;
    }
    const delayDebounceFn = setTimeout(() => {
      setFilters({
        ...filters,
        search: search
      });
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [search]);
  const removeAccount = () => {
    deleteAdwordsAccount(props?.credential?.id).then(() => {
      snackbarService.popup({
        type: 'success',
        message: 'Successfully deleted account'
      });
    }).then(() => {
      history.push(`/integrations/manage/${props?.install?.id}`);
    }).catch(() => {
      snackbarService.popup({
        type: 'error',
        message: 'Unknown error trying delete account. Please try again.'
      });
    });
  };
  const syncAudiences = () => {
    setTokenIsRefreshing(true);
    syncAdwordsAudiences(props?.credential?.id).then(() => {
      snackbarService.popup({
        type: 'success',
        message: 'Queued audiences for syncing.'
      });
      setRenderIteration(r => r + 1);
      setTokenIsRefreshing(false);
    }).catch(() => {
      snackbarService.popup({
        type: 'error',
        message: 'Unknown error trying delete account. Please try again.'
      });
      setTokenIsRefreshing(false);
    });
  };
  const createAudience = () => {
    createAdwordsAudience(props?.credential?.id, {
      name: audienceName,
      description: audienceDescription
    }).then(() => {
      setAudienceName('');
      setAudienceDescription('');
      snackbarService.popup({
        type: 'success',
        message: 'Audience created successfully'
      });
      setRenderIteration(r => r + 1);
    }).catch(() => {
      snackbarService.popup({
        type: 'error',
        message: 'Unknown error trying create audience. Please try again.'
      });
    });
  };
  return <>
      {props.credential ? <>
          {loading ? <Box sx={{
        width: '100%',
        height: '100%'
      }}>
              <Box sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
          justifyItems: 'center',
          alignItems: 'center'
        }}>
                <CircularProgress />
              </Box>
            </Box> : <Stack>
              <Box sx={{
          display: 'flex',
          alignItems: 'center'
        }}>
                <Typography sx={{
            fontSize: '18px',
            lineHeight: '26px'
          }}>Audiences</Typography>
              </Box>

              <Box sx={{
          py: '24px',
          display: 'flex',
          alignItems: 'center'
        }}>
                <Typography>
                  If there are missing audiences, click the resync button to fetch the latest audiences from Google Ads.
                </Typography>
              </Box>

              <Box>
                <Box sx={{
            border: '1px solid #E2E2E2',
            borderRadius: '16px'
          }}>
                  <Table>
                    <colgroup>
                      <col width="100%" />
                    </colgroup>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{
                    py: searching ? 0 : 1
                  }}>
                          <Box sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}>
                            <Box sx={{
                        display: 'flex',
                        alignItems: 'center'
                      }}>
                              <Typography variant="tableHeader">Audience Name </Typography>
                            </Box>
                            <Stack direction="row" spacing="24px">
                              {showSearch ? <Box>
                                  <CustomTextField variant="outlined" sx={{
                            width: '200px'
                          }} placeholder="Search" autoComplete="new-password" InputProps={{
                            startAdornment: <InputAdornment position="start">
                                          <IconButton onClick={() => {
                                setShowSearch(false);
                              }}>
                                            <Icon name="custom-search" />
                                          </IconButton>
                                        </InputAdornment>,
                            endAdornment: search != '' && <InputAdornment onClick={e => {
                              setSearch('');
                            }} position="start" sx={{
                              cursor: 'pointer'
                            }}>
                                          <CloseIcon />
                                        </InputAdornment>
                          }} value={search || ''} onChange={e => {
                            setSearch(e.target.value);
                          }} />
                                </Box> : <Tooltip title="Search for an audience">
                                  <IconButton onClick={() => {
                            setShowSearch(true);
                          }}>
                                    <Icon name="custom-search" />
                                  </IconButton>
                                </Tooltip>}

                              {hasPermission('adwords.change_audience') && <Tooltip title="Click to resync audiences with Google">
                                  <IconButton disabled={tokenIsRefreshing} onClick={() => {
                            syncAudiences();
                          }}>
                                    {tokenIsRefreshing ? <CircularProgress size={'20px'} /> : <SyncIcon />}
                                  </IconButton>
                                </Tooltip>}

                              {hasPermission('adwords.add_audience') && <Tooltip title="Click to add a new audience">
                                  <IconButton onClick={() => {
                            setShowCreateAudienceModal(true);
                          }}>
                                    <AddIcon size="24" />
                                  </IconButton>
                                </Tooltip>}
                            </Stack>
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {searching ? <Box sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                justifyItems: 'center',
                alignItems: 'center'
              }}>
                        <CircularProgress />
                      </Box> : <>
                        {!!audiences.length ? <TableBody>
                            {audiences.map((audience, index) => <TableRow key={audience.id}>
                                <TableCell sx={{
                      borderBottom: audiences?.length == index + 1 ? 'none' : '1px solid rgba(224, 224, 224, 1)'
                    }}>
                                  {audience.name}
                                </TableCell>
                              </TableRow>)}
                          </TableBody> : <TableBody sx={{
                  height: '200px'
                }}>
                            <TableRow>
                              <TableCell sx={{
                      borderBottom: 'none'
                    }}>
                                <Box sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center'
                      }}>
                                  <Stack spacing="24px" sx={{
                          display: 'flex',
                          justifyContent: 'center'
                        }}>
                                    <Box sx={{
                            display: 'center',
                            alignItems: 'center',
                            justifyContent: 'center',
                            justifyItems: 'center'
                          }}>
                                      <Typography>
                                        This account does not have any audiences. If you have existing audiences in Google, try resyncing
                                        your audiences.
                                      </Typography>
                                    </Box>
                                    <Box sx={{
                            display: 'center',
                            alignItems: 'center',
                            justifyContent: 'center',
                            justifyItems: 'center'
                          }}>
                                      <Typography>If you haven't created an audience, create a new one by clicking above</Typography>
                                    </Box>
                                  </Stack>
                                </Box>
                              </TableCell>
                            </TableRow>
                          </TableBody>}
                      </>}
                  </Table>
                </Box>
              </Box>
              {!hasPermission('integration.change_credential') && <>You do not have permission to edit this page</>}

              <Stack sx={{
          backgroundColor: '#fff',
          width: '100%',
          position: 'sticky',
          bottom: 0,
          pt: '16px',
          px: 2,
          pb: 2,
          borderRadius: '14px'
        }}>
                <Divider />
                <Box sx={{
            pr: '20px',
            pt: '20px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
                  <Stack direction="row" spacing="16px">
                    {hasPermission('integration.delete_credential') && <Button variant="contained" sx={{
                backgroundColor: '#F3F3F4',
                '&:hover': {
                  backgroundColor: '#F3F3F4'
                }
              }} startIcon={<CancelIcon fill="#3898D9" />} onClick={e => {
                setOpenConfirmDeleteModal(true);
              }}>
                        <Box sx={{
                  py: '5px',
                  pr: '12px'
                }}>
                          <Typography sx={{
                    color: '#3898D9'
                  }}>Remove </Typography>
                        </Box>
                      </Button>}
                  </Stack>
                  <Stack direction="row" spacing="16px">
                    <Button variant="contained" sx={{
                backgroundColor: '#F3F3F4',
                '&:hover': {
                  backgroundColor: '#F3F3F4'
                }
              }} onClick={e => {
                history.push(`/integrations/manage/${props?.install?.id}`);
              }}>
                      <Box sx={{
                  py: '5px',
                  px: '12px'
                }}>
                        <Typography sx={{
                    color: '#3898D9'
                  }}>Cancel </Typography>
                      </Box>
                    </Button>
                    <Button sx={{
                backgroundColor: '#3898D9',
                '&:hover': {
                  backgroundColor: '#3898D9'
                }
              }} variant="contained" onClick={e => {
                history.push(`/integrations/manage/${props?.install?.id}`);
              }}>
                      <Box sx={{
                  py: '6px',
                  px: '12px'
                }}>
                        <Typography sx={{
                    color: '#fff'
                  }}>Done</Typography>
                      </Box>
                    </Button>
                  </Stack>
                </Box>
              </Stack>
            </Stack>}
        </> : <Stack spacing="36px">
          <Typography variant="tableHeader">New Google Ads Account: Step 1</Typography>
          <Typography>
            To get started, please enter the client customer ID you want to link to Cinch. The ID looks like 123-123-1234 and can be found
            on the accounts page of your Ads account. Once you have entered the ID, click the button below to launch a new window to
            continue the setup process by linking yout Google account to Cinch.
          </Typography>
          <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
            <CustomTextField sx={{
          width: '50%'
        }} value={clientCustomerID} onBlur={() => setClientCustomerIDFocusLost(true)} helperText={clientCustomerIDFocusLost && !clientCustomerID.match(/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/g) && 'Please enter your client customer ID in the format 123-123-1234'} error={clientCustomerIDFocusLost && !clientCustomerID.match(/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/g)} required label={'Client Customer ID'} onChange={event => {
          setClientCustomerID(event.target.value);
        }} />

            <Button variant="contained" sx={{
          width: '250px',
          height: '46px'
        }} endIcon={<OpenInNewWindowIcon fill={'#fff'} />} disabled={!clientCustomerID || !clientCustomerID.match(/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/g)} onClick={() => {
          window.open(`${API_URL}/adwords/account/request?client_customer_id=${clientCustomerID}`);
        }}>
              Link Google Ads Account
            </Button>
          </Box>
          <Divider />
          <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
            <Box sx={{
          width: '60%'
        }}>
              <Typography>
                Once you have finished configuring this integration with Google, click the button to go back to the previous page and select
                the credential you just added.
              </Typography>
            </Box>
            <Button sx={{
          width: '250px'
        }} variant="contained" onClick={() => {
          history.push(`/integrations/manage/${props?.install?.id}`);
        }}>
              Go Back to Manage Page
            </Button>
          </Box>
        </Stack>}

      <Dialog scroll="paper" PaperProps={{
      style: {
        borderRadius: '28px'
      }
    }} open={!!openConfirmDeleteModal} onClose={(e, r) => {
      setOpenConfirmDeleteModal(null);
    }}>
        <DialogTitle sx={{
        m: 0,
        p: 2,
        fontSize: 'medium'
      }}>
          <Stack spacing={'20px'}>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Stack direction="row" spacing={'10px'}>
                <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                  <Typography sx={{
                  fontWeight: '300',
                  lineHeight: '30px',
                  fontSize: '24px',
                  display: 'flex',
                  alignItems: 'center'
                }} variant="h4" component="span">
                    Confirm Remove Account
                  </Typography>
                </Box>
              </Stack>

              <IconButton onClick={() => {
              setOpenConfirmDeleteModal(null);
            }}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Divider />
          </Stack>
        </DialogTitle>

        <DialogContent sx={{
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
          width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
          marginTop: '20px',
          marginBottom: '20px'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#888'
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555'
        }
      }}>
          <Stack sx={{
          pt: 1
        }} spacing={'24px'}>
            <Box>Are you sure you want to remove this account? </Box>
          </Stack>
        </DialogContent>

        <DialogActions sx={{
        pt: 1,
        pb: 2
      }}>
          <Stack sx={{
          pr: '24px'
        }} direction={'row'} spacing={'20px'}>
            <Button onClick={() => {
            setOpenConfirmDeleteModal(null);
          }} variant="contained" sx={{
            backgroundColor: '#F3F3F4',
            '&:hover': {
              backgroundColor: '#F3F3F4'
            }
          }}>
              <Box sx={{
              py: '5px',
              px: '12px'
            }}>
                <Typography sx={{
                color: '#3898D9'
              }}>Cancel </Typography>
              </Box>
            </Button>
            <Button onClick={() => {
            removeAccount();
            setOpenConfirmDeleteModal(null);
          }} variant="contained" sx={{
            backgroundColor: '#3898D9',
            '&:hover': {
              backgroundColor: '#3898D9'
            }
          }}>
              <Box sx={{
              py: '5px',
              px: '12px'
            }}>
                <Typography sx={{
                color: '#fff'
              }}> Remove</Typography>
              </Box>
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>

      <Dialog scroll="paper" PaperProps={{
      style: {
        borderRadius: '28px'
      }
    }} open={!!showCreateAudienceModal} onClose={(e, r) => {
      setShowCreateAudienceModal(null);
    }}>
        <DialogTitle sx={{
        m: 0,
        p: 2,
        fontSize: 'medium'
      }}>
          <Stack spacing={'20px'}>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Stack direction="row" spacing={'10px'}>
                <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                  <Typography sx={{
                  fontWeight: '300',
                  lineHeight: '30px',
                  fontSize: '24px',
                  display: 'flex',
                  alignItems: 'center'
                }} variant="h4" component="span">
                    Create Ads Audience
                  </Typography>
                </Box>
              </Stack>

              <IconButton onClick={() => {
              setShowCreateAudienceModal(null);
            }}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Divider />
          </Stack>
        </DialogTitle>

        <DialogContent sx={{
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
          width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
          marginTop: '20px',
          marginBottom: '20px'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#888'
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555'
        }
      }}>
          <Stack sx={{
          pt: 1
        }} spacing={'24px'}>
            <Box>This will create an Ads user list you can use in your journeys. </Box>
            <Box>
              <CustomTextField sx={{
              width: '100%'
            }} value={audienceName} required label={'Audience Name'} onChange={event => {
              setAudienceName(event.target.value);
            }} />
            </Box>
            <Box>
              <CustomTextField sx={{
              width: '100%'
            }} value={audienceDescription} required label={'Audience Description'} onChange={event => {
              setAudienceDescription(event.target.value);
            }} />
            </Box>
          </Stack>
        </DialogContent>

        <DialogActions sx={{
        pt: 1,
        pb: 2
      }}>
          <Stack sx={{
          pr: '24px'
        }} direction={'row'} spacing={'20px'}>
            <Button onClick={() => {
            setShowCreateAudienceModal(null);
          }} variant="contained" sx={{
            backgroundColor: '#F3F3F4',
            '&:hover': {
              backgroundColor: '#F3F3F4'
            }
          }}>
              <Box sx={{
              py: '5px',
              px: '12px'
            }}>
                <Typography sx={{
                color: '#3898D9'
              }}>Cancel </Typography>
              </Box>
            </Button>
            <Button onClick={() => {
            createAudience();
            setShowCreateAudienceModal(null);
          }} disabled={!audienceName || !audienceDescription} variant="contained" sx={{
            backgroundColor: '#3898D9',
            '&:hover': {
              backgroundColor: '#3898D9'
            }
          }}>
              <Box sx={{
              py: '5px',
              px: '12px'
            }}>
                <Typography sx={{
                color: '#fff'
              }}> Create</Typography>
              </Box>
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </>;
};