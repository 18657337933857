class ToasterService {
  checkArg(arg) {
    return typeof arg === 'string' ? {
      message: arg
    } : arg;
  }
  success(arg) {
    const config = this.checkArg(arg);
    this.toast({
      type: 'success',
      ...config
    });
  }
  info(arg) {
    const config = this.checkArg(arg);
    this.toast({
      type: 'info',
      ...config
    });
  }
  warning(arg) {
    const config = this.checkArg(arg);
    this.toast({
      type: 'warning',
      ...config
    });
  }
  error(arg) {
    const config = this.checkArg(arg);
    this.toast({
      type: 'error',
      ...config
    });
  }
  toast() {
    console.warn(`<Toaster /> component must be mounted before using the toasterService`);
  }
  passToastFunc(toast) {
    this.toast = toast;
  }
}
export const toasterService = new ToasterService();