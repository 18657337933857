import React, { Component } from 'react';
import { Scoped, k } from 'kremling';
import logo from '../assets/img/cinch-logo-small.svg';
import { Button } from '../components/button/button.component';
import { UserStateContext } from 'context/user-state-context';
export class NoCompany extends Component {
  static contextType = UserStateContext;
  render() {
    return <Scoped css={css}>
        <div className="no-company">
          <div className="no-company__child">
            <div className="no-company__logo" dangerouslySetInnerHTML={{
            __html: logo
          }} />
            <h2>
              Uh oh!
            </h2>
            <p className="mb-md">
              It looks like you aren't associated with a company.<br />
              Please contact support for help.
            </p>
            <Button onClick={() => {
            this.context.logOut();
          }}>
              Sign Out
            </Button>
          </div>
        </div>
      </Scoped>;
  }
}
const css = {
  styles: `[kremling="i0"] body,body[kremling="i0"] {
  --color-primary: #487aae;
  --color-highlight: #e7efff;
  --color-accent: #354052;
  --color-success: #73b56e;
  --color-warning: #f4b707;
  --color-danger: #df5651;
  --color-grey-10: #f9f9f9;
  --color-grey-25: #f3f3f3;
  --color-grey-50: #e9e9e9;
  --color-grey-75: #e3e3e3;
  --color-grey-100: #d3d3d3;
  --color-grey-200: #c3c3c3;
  --color-grey-300: #b3b3b3;
  --color-grey-400: #a3a3a3;
  --color-grey-500: #808080;
  --color-grey-600: #707070;
  --color-grey-700: #606060;
  --color-grey-800: #505050;
  --color-grey-900: #404040;
  --base-font-family: Roboto, Helvetica, Arial, sans-serif;
  --base-font-size: 1.4rem;
  --base-font-color: #404040;
  --base-font-weight: 400;
  --base-line-height: 1.4;
  --box-shadow-1: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.12);
  --box-shadow-2: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-3: 0 10px 20px rgba(0, 0, 0, 0.17), 0 6px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-4: 0 14px 28px rgba(0, 0, 0, 0.13), 0 10px 10px rgba(0, 0, 0, 0.1);
  --box-shadow-5: 0 19px 38px rgba(0, 0, 0, 0.18), 0 15px 12px rgba(0, 0, 0, 0.1);
  --base-border-radius: 0.4rem;
}

[kremling="i0"] .no-company,[kremling="i0"].no-company {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
}

[kremling="i0"] .no-company__logo,[kremling="i0"].no-company__logo {
  width: 3rem;
  margin-bottom: 2rem;
}

[kremling="i0"] .no-company__child,[kremling="i0"].no-company__child {
  width: 36rem;
  height: 20rem;
}`,
  id: 'i0',
  namespace: 'kremling'
};