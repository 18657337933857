import React, { Component } from 'react';
import { Scoped } from 'kremling';
import styles from './integrations.styles.scss';
import { UserStateContext } from 'context/user-state-context';
import { Button } from '../../components/button/button.component';
import { getOFFranchises, addOFFranchise, deleteOFFranchise } from './integrations.resource';
import { toasterService } from '../../components/toaster/toaster-service';
import { ModalDialog } from '../../components/modal/modal-dialog.component';
export class IntegrationOxiFresh extends Component {
  static contextType = UserStateContext;
  static propTypes = {};
  state = {
    franchises: [],
    deleteOxiFreshFranchise: {},
    isFranchiseModalOpen: false,
    franchiseRef: null
  };
  componentDidMount() {
    const onLoad = this.props.initLoad('oxifresh');
    this.getOxiFreshData().finally(() => onLoad());
  }
  getOxiFreshData = () => {
    return getOFFranchises({
      company: this.context.asCompany.id
    }).then(({
      results
    }) => {
      this.setState({
        franchises: results
      });
    });
  };
  addOxiFreshFranchise = franchiseRef => {
    return addOFFranchise(franchiseRef, this.context.asCompany.id).then(() => toasterService.success('Successfully added franchise. It can take a few minutes to sync all the data.')).then(() => this.getOxiFreshData()).catch(err => {
      if (err.response.data.franchise_ref) {
        toasterService.error(err.response.data.franchise_ref[0]);
      } else if (err.response.data.company) {
        toasterService.error(err.response.data.company[0]);
      } else {
        toasterService.error('Unknown error trying to add franchise. Please try again.');
      }
    });
  };
  removeOxiFreshFranchise = franchiseRef => {
    return deleteOFFranchise(franchiseRef).then(() => toasterService.success('Successfully deleted franchise')).then(() => this.getOxiFreshData()).catch(() => toasterService.error('Unknown error to trying delete franchise. Please try again.'));
  };
  render() {
    const {
      franchises,
      isFranchiseModalOpen,
      franchiseRef,
      deleteOxiFreshFranchise
    } = this.state;
    return <Scoped css={styles}>
        <div className="integration mb-5">
          <div className="integration__header">
            <div>OxiFresh</div>
            {this.context.hasPermission('oxifresh.add_franchise') && <Button className="btn" actionType="primary" tag="a" onClick={() => this.setState({
            isFranchiseModalOpen: true
          })}>
                Add Franchise
              </Button>}
          </div>
          <div className="integration__body">
            {franchises.map(franchise => <div className="integration-item" key={franchise.id}>
                <div className="integration-item__inner">
                  <div className="integration-title">{franchise.name}</div>
                  <div className="integration-actions">
                    {this.context.hasPermission('oxifresh.delete_franchise') && <Button actionType="flat" icon="fa-regular-trash" onClick={e => {
                  e.stopPropagation();
                  this.setState({
                    deleteOxiFreshFranchise: franchise
                  });
                }} />}
                  </div>
                </div>
              </div>)}
          </div>

          <ModalDialog open={!!deleteOxiFreshFranchise.id} title="Remove franchise" submitText="Remove" onSubmit={() => this.removeOxiFreshFranchise(deleteOxiFreshFranchise.id)} onClose={() => this.setState({
          deleteOxiFreshFranchise: {}
        })} allowBackdropClick>
            <p>
              <strong>{deleteOxiFreshFranchise.name}</strong>
            </p>
            <p>Are you sure you want to remove this franchise?</p>
          </ModalDialog>

          <ModalDialog open={isFranchiseModalOpen} title="Add Franchise" onSubmit={() => this.addOxiFreshFranchise(franchiseRef)} onClose={() => this.setState({
          isFranchiseModalOpen: false
        })} allowBackdropClick>
            <p>
              Please enter your OxiFresh information below to link to your
              account.
            </p>
            <p>
              <label>Franchise ID</label>
              <input required className="form-control" name="franchise_ref" onChange={e => this.setState({
              franchiseRef: e.target.value
            })} />
            </p>
          </ModalDialog>
        </div>
      </Scoped>;
  }
}