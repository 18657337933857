import React from 'react';
function Icon() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" fill="none" viewBox="0 0 21 20">
      <mask id="mask0_38_2658" style={{
      maskType: 'alpha'
    }} width="21" height="20" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0.73 0H20.73V20H0.73z"></path>
      </mask>
      <g mask="url(#mask0_38_2658)">
        <path fill="#53A6D6" d="M7.262 15.87a.64.64 0 01-.466-.2.64.64 0 010-.932.64.64 0 01.466-.2h4.98c.919 0 1.718-.283 2.397-.848.68-.566 1.019-1.29 1.019-2.173 0-.884-.34-1.612-1.019-2.184-.678-.572-1.478-.858-2.398-.858H6.703l1.873 1.873c.13.13.192.281.188.456a.662.662 0 01-.209.464.645.645 0 01-.94 0L4.724 8.377a.88.88 0 01-.197-.278.748.748 0 010-.582.88.88 0 01.197-.278l2.911-2.912a.65.65 0 01.467-.198.635.635 0 01.474.198.645.645 0 010 .941L6.703 7.141h5.538c1.283 0 2.396.414 3.338 1.242.941.828 1.412 1.866 1.412 3.113 0 1.246-.47 2.287-1.412 3.122s-2.055 1.253-3.338 1.253H7.262z"></path>
      </g>
    </svg>;
}
export default Icon;