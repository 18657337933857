import React, { useState, useEffect } from 'react';
export const DynamicTypeAhead = props => {
  const {
    param,
    data
  } = props;
  const [displayVal, setDisplayVal] = useState(<em>Loading...</em>);
  useEffect(() => {
    if (!data[param.key]) {
      setDisplayVal(<em>No item selected.</em>);
    } else {
      param.getItems({
        [param.keyProperty || 'id']: data[param.key],
        limit: 1
      }).then(({
        data
      }) => {
        if (data.results.length > 0) {
          setDisplayVal(data.results[0][param.displayProperty || 'name']);
        } else {
          setDisplayVal(<em>Unable to find item.</em>);
        }
      }).catch(() => {
        setDisplayVal(<em>Error retrieving item.</em>);
      });
    }
  }, []);
  return <div>{displayVal}</div>;
};