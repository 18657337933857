import React, { Component } from 'react';
import { bool, func, oneOf, string, node, oneOfType, number } from 'prop-types';
import { Modal } from './modal.component';
import { Button } from '../button/button.component';

/**
 * A custom Modal Component that can be used to display a modal-dialog with custom props.
 * @param {boolean} open - Whether the modal is open or not.
 * @param {function} onClose - Callback function to be called when the modal is closed.
 * @param {function} onCancel - Callback function to be called when the modal is canceled.
 * @param {boolean} allowBackdropClick - Whether clicking on the backdrop should be allowed to close the modal.
 * @param {string} title - The title of the modal.
 * @param {'sm' | 'md' | 'lg' | 'xl'} size - The size of the modal.
 * @param {boolean} scroll - Whether the modal should be scrollable.
 * @param {function} onSubmit - Callback function to be called when the modal is submitted.
 * @param {string|ReactNode} submitText - Text or node for the submit button.
 * @param {string|ReactNode} cancelText - Text or node for the cancel button.
 * @param {ReactNode} children - The content to be displayed within the modal.
 * @param {'primary' | 'flat' | 'grey' | 'warning' | 'flat-light' | 'white' | 'danger'} actionType - The type of action for the modal.
 * @param {boolean} cancelBtn - Whether the cancel button should be displayed.
 * @param {boolean} submitDisabled - Whether the submit button should be disabled.
 * @param {number} level - The level of the modal.
 * @param {boolean} preventCloseOnSubmit - Whether to prevent closing the modal on submit.
 */

export class ModalDialog extends Component {
  static propTypes = {
    open: bool,
    onClose: func,
    onCancel: func,
    allowBackdropClick: bool,
    title: string,
    size: oneOf(['sm', 'md', 'lg', 'xl']),
    scroll: bool,
    onSubmit: func,
    submitText: oneOfType([string, node]),
    cancelText: oneOfType([string, node]),
    children: node,
    actionType: oneOf(['primary', 'flat', 'grey', 'warning', 'flat-light', 'white', 'danger']),
    cancelBtn: bool,
    submitDisabled: bool,
    level: number,
    preventCloseOnSubmit: bool
  };
  static defaultProps = {
    actionType: 'primary',
    submitDisabled: false,
    cancelBtn: true
  };
  render() {
    const {
      open,
      onClose = () => {},
      onCancel = () => {},
      onSubmit,
      allowBackdropClick,
      title,
      size,
      type,
      submitText = 'Submit',
      cancelText = 'Cancel',
      children,
      actionType,
      submitDisabled,
      cancelBtn,
      closeBtn,
      level,
      preventCloseOnSubmit = false
    } = this.props;
    return <div>
        <Modal open={open} onClose={() => {
        onCancel();
        onClose();
      }} allowBackdropClick={allowBackdropClick} title={title} size={size} type={type} level={level || 1} closeBtn={closeBtn !== false}>
          <div className="modal__body">{children}</div>
          <div className="modal__actions">
            {cancelBtn && <Button onClick={() => {
            onCancel();
            onClose();
          }}>
                {cancelText}
              </Button>}
            {onSubmit && <Button disabled={submitDisabled} actionType={actionType} onClick={async () => {
            /* Shouldn't cause any issues and allows
             * promises to fulfull with await / async */
            await onSubmit();
            if (!preventCloseOnSubmit) {
              await onClose();
            }
          }}>
                {submitText}
              </Button>}
          </div>
        </Modal>
      </div>;
  }
}