import React from 'react';
function Icon() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none" viewBox="0 0 24 25">
      <mask id="mask0_431_954" style={{
      maskType: 'alpha'
    }} width="24" height="25" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0 0.5H24V24.5H0z"></path>
      </mask>
      <g fill="#1D252D" mask="url(#mask0_431_954)">
        <path stroke="#1D252D" d="M7.817 4.047h0l-.001-.002c-.04-.04-.066-.087-.066-.183 0-.095.026-.144.067-.184.039-.04.087-.066.183-.066h8c.096 0 .144.026.184.066.04.04.066.089.066.184 0 .097-.026.145-.066.184-.04.04-.088.066-.184.066H8c-.096 0-.143-.026-.183-.065zm-2.99 2.897h0a.242.242 0 01-.077-.182c0-.095.026-.143.066-.182h0l.001-.002c.04-.04.087-.066.183-.066h14c.096 0 .144.027.184.067.04.04.066.087.066.183a.243.243 0 01-.075.182c-.043.045-.088.068-.175.068H5c-.087 0-.132-.023-.173-.068zm15.438 13.712h0l.004-.004a.791.791 0 00.231-.565v-9.4a.791.791 0 00-.231-.564h0l-.004-.004a.791.791 0 00-.565-.232H4.3a.788.788 0 00-.566.234.79.79 0 00-.234.566v9.4c0 .192.06.38.194.538l.002.002c.172.2.406.26.604.26h15.4c.213 0 .411-.08.565-.231zM4.3 21.387c-.369 0-.666-.123-.921-.378A1.237 1.237 0 013 20.087v-9.4c0-.369.123-.666.379-.921.255-.255.552-.379.921-.379h15.4c.369 0 .666.124.921.379.256.255.379.552.379.921v9.4c0 .37-.123.667-.379.922a1.237 1.237 0 01-.921.378H4.3z"></path>
        <path fillRule="evenodd" d="M14.602 12.953c.217-.007.4.068.543.225a.691.691 0 01.2.53.764.764 0 01-.222.526l-3.4 3.42a.928.928 0 01-.616.24.788.788 0 01-.627-.266l-1.645-1.644a.679.679 0 01-.201-.522.751.751 0 01.222-.515.71.71 0 01.52-.213c.202 0 .376.072.518.213l1.21 1.19 2.961-2.96a.773.773 0 01.537-.224z" clipRule="evenodd"></path>
      </g>
    </svg>;
}
export default Icon;