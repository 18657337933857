import React, { Component } from 'react';
import { func, object, shape, string } from 'prop-types';
import { get } from 'lodash';
import { getFacebookAudiences, getFacebookAudiencesNoUnWrap } from '../../../integrations/integrations.resource';
import { UserStateContext } from 'context/user-state-context';
import { DynamicTypeAhead } from '../../../../components/dynamic-type-ahead/dynamic-type-ahead.component';
export class ActionFacebookAudience extends Component {
  static contextType = UserStateContext;
  static propTypes = {
    update: func,
    node: object,
    data: shape({
      audience_id: string
    })
  };
  state = {
    audience_id: '',
    audiences: [],
    loading: true
  };
  componentDidMount() {
    getFacebookAudiences({
      company: this.context.asCompany.id,
      source_type: 'FILE_IMPORTED,CUSTOM',
      limit: 1000
    }).then(({
      results
    }) => {
      this.setState({
        audiences: results,
        loading: false
      });
    });
  }
  updateAudience = audience_id => {
    this.props.update({
      audience_id
    });
  };
  render() {
    const {
      audiences,
      loading
    } = this.state;
    const {
      audience_id
    } = this.props.data;
    const selectedAudience = audiences.find(a => a.id === audience_id);
    return <div>
        <div className="form-group">
          <label>Audience</label>
          {!loading && <DynamicTypeAhead getItems={getFacebookAudiencesNoUnWrap} getItemsFilters={{
          company: this.context.asCompany.id,
          source_type: 'FILE_IMPORTED,CUSTOM'
        }} placeholder={selectedAudience ? `${selectedAudience.name}` : "Choose a Audience"} displayProperty="name" keyProperty="id" value={selectedAudience ? selectedAudience.id : null} onChange={audience => this.updateAudience(get(audience, 'id', null))} />}
        </div>
      </div>;
  }
}