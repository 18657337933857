import React, { useState } from 'react';
import { Field, useFormikContext } from 'formik';
import MenuItem from '@mui/material/MenuItem';
import { TextField } from '@mui/material';
import { useEffect } from 'react';

/**
 * A formik driven component that allows us to unify
 * styling our input fields with Formik `form` Field
 * component API
 *
 * Your passed in options must look something like this:
 * ```js
 * [
 *   { value: 10, name: 'Ten' },
 *   { value: 20, name: 'Twenty' },
 *   { value: 30, name: 'Thirty' },
 * ]
 * ```
 * The value is the actual data in question, and the name is the value the user sees.
 * In this example, if you wanted 'Ten' to be preselected on component load, your default value must be:
 * ```js
 * 10
 * ```
 * This would generate the selectfield with 10 already selected.
 *
 * https://formik.org/docs/api/field#example
 */
export const FormikSelect = ({
  name,
  label,
  options = [],
  size = 'medium',
  validate = undefined,
  // works with null or undefined
  onChange
}) => {
  const [selectedValue, setSelectedValue] = useState('');
  const {
    initialValues
  } = useFormikContext();
  useEffect(() => {
    const keyExists = initialValues.hasOwnProperty(name);
    const startingValue = initialValues[name];
    if (!keyExists) {
      console.warn('FormikSelect is misconfigured, you must provide the correct name prop');
      setSelectedValue('');
    } else {
      setSelectedValue(startingValue);
    }
  }, []);
  const changeSelection = async (event, form) => {
    const newValue = event.target.value;
    await form.setFieldValue(name, newValue, true);
    setSelectedValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };
  return <Field name={name} validate={validate}>
      {({
      field,
      // { name, value, onChange, onBlur }
      form,
      // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
      meta
    }) => <TextField {...field} value={selectedValue} label={label} onChange={async event => {
      await changeSelection(event, form);
    }} size={size} fullWidth={true} error={meta.touched && meta.error ? true : false} helperText={meta.touched && meta.error} select // tells textfield to render as select
    >
          {options.map(function (data) {
        return <MenuItem key={data.value} value={data.value}>
                {data.name}
              </MenuItem>;
      })}
        </TextField>}
    </Field>;
};