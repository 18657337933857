import React from 'react';
function Icon(props) {
  if (props.size == 'sm') {
    return <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 18 18">
        <g>
          <mask id="mask0_212_657" style={{
          maskType: 'alpha'
        }} width="18" height="18" x="0" y="0" maskUnits="userSpaceOnUse">
            <path fill="#D9D9D9" d="M0 0H18V18H0z"></path>
          </mask>
          <g mask="url(#mask0_212_657)">
            <path fill={props.fill || '#1D252D'} d="M7.95 10.35L6.338 8.738a.711.711 0 00-.526-.207.711.711 0 00-.525.207.711.711 0 00-.206.524c0 .213.069.388.206.525l2.138 2.138a.72.72 0 00.525.225.72.72 0 00.525-.225l4.238-4.238a.711.711 0 00.206-.525.711.711 0 00-.207-.524.711.711 0 00-.524-.207.711.711 0 00-.526.207L7.95 10.35zM9 16.5a7.304 7.304 0 01-2.925-.59 7.575 7.575 0 01-2.381-1.604 7.574 7.574 0 01-1.603-2.381A7.303 7.303 0 011.5 9c0-1.037.197-2.013.59-2.925a7.574 7.574 0 011.604-2.381A7.574 7.574 0 016.075 2.09 7.303 7.303 0 019 1.5c1.037 0 2.012.197 2.925.59a7.574 7.574 0 012.381 1.604 7.575 7.575 0 011.603 2.381c.394.912.591 1.888.591 2.925a7.304 7.304 0 01-.59 2.925 7.575 7.575 0 01-1.604 2.381 7.575 7.575 0 01-2.381 1.603A7.304 7.304 0 019 16.5zM9 15c1.675 0 3.094-.581 4.256-1.744C14.42 12.094 15 10.675 15 9c0-1.675-.581-3.094-1.744-4.256C12.094 3.58 10.675 3 9 3c-1.675 0-3.094.581-4.256 1.744C3.58 5.906 3 7.325 3 9c0 1.675.581 3.094 1.744 4.256C5.906 14.42 7.325 15 9 15z"></path>
          </g>
        </g>
      </svg>;
  }
  if (props.size == 'lg') {
    return <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 -960 960 960">
        <path fill={props.fill || '#1D252D'} d="M421.744-310.924l269.332-269.332-37.281-36.871-232.051 232.308-116.975-116.975-36.614 36.871 153.589 153.999zm58.323 210.923q-78.426 0-147.666-29.92t-120.887-81.544q-51.647-51.624-81.58-120.833-29.933-69.21-29.933-147.635 0-78.836 29.92-148.204 29.92-69.369 81.544-120.682 51.624-51.314 120.833-81.247 69.21-29.933 147.635-29.933 78.836 0 148.204 29.92 69.369 29.92 120.682 81.21 51.314 51.291 81.247 120.629 29.933 69.337 29.933 148.173 0 78.426-29.92 147.666t-81.21 120.887q-51.291 51.647-120.629 81.58-69.337 29.933-148.173 29.933zM480-150.256q137.795 0 233.769-96.18Q809.744-342.615 809.744-480q0-137.795-95.975-233.769Q617.795-809.744 480-809.744q-137.385 0-233.564 95.975-96.18 95.974-96.18 233.769 0 137.385 96.18 233.564 96.179 96.18 233.564 96.18zM480-480z"></path>
      </svg>;
  }
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 -960 960 960">
      <path fill={props.fill || '#1D252D'} d="M423.231-309.847l268.922-268.922L650-620.922 423.231-394.153l-114-114L267.078-466l156.153 156.153zm56.836 209.846q-78.836 0-148.204-29.92-69.369-29.92-120.682-81.21-51.314-51.291-81.247-120.629-29.933-69.337-29.933-148.173t29.92-148.204q29.92-69.369 81.21-120.682 51.291-51.314 120.629-81.247 69.337-29.933 148.173-29.933t148.204 29.92q69.369 29.92 120.682 81.21 51.314 51.291 81.247 120.629 29.933 69.337 29.933 148.173t-29.92 148.204q-29.92 69.369-81.21 120.682-51.291 51.314-120.629 81.247-69.337 29.933-148.173 29.933zM480-160q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93zm0-320z"></path>
    </svg>;
}
export default Icon;