import React, { Component } from 'react';
import { UserStateContext } from 'context/user-state-context';
import { Button } from '../../../components/button/button.component';
import { getWoocommerceShops, deleteWoocommerceShop, syncWoocommerceShop } from '../integrations.resource';
import { toasterService } from '../../../components/toaster/toaster-service';
import { ModalDialog } from '../../../components/modal/modal-dialog.component';
import { Loader } from 'components';
import { Stack } from '@mui/material';
export class IntegrationWoocommerceUpdate extends Component {
  static contextType = UserStateContext;
  static propTypes = {};
  state = {
    shops: [],
    deleteShop: {},
    isAddModalOpen: false,
    url: null,
    loading: true
  };
  componentDidMount() {
    // const onLoad = this.props.initLoad('woocommerce');
    this.woocommerceRedirect().then(hasRedirect => hasRedirect && this.props.history.push('/integrations')).then(() => this.getWoocommerceData());
    // .finally(() => onLoad());
  }
  woocommerceRedirect = () => {
    return new Promise(resolve => {
      const {
        match
      } = this.props;
      if (match.params.redirect === 'woocommerce-authorize') {
        toasterService.success('Successfully added shop. Your shop data will start syncing momentarily');
        resolve(false);
      } else {
        resolve(false);
      }
    });
  };
  getWoocommerceData = () => {
    this.setState({
      loading: true
    });
    return getWoocommerceShops({
      company: this.context.asCompany.id
    }).then(({
      results
    }) => {
      this.setState({
        shops: results
      });
      this.setState({
        loading: false
      });
    });
  };
  removeShop = shopId => {
    return deleteWoocommerceShop(shopId).then(() => toasterService.success('Successfully deleted shop')).then(() => this.getWoocommerceData()).catch(() => toasterService.error('Unknown error trying delete shop. Please try again.'));
  };
  syncShop = shopId => {
    return syncWoocommerceShop(shopId).then(() => toasterService.success('Queued shop for syncing.')).catch(() => toasterService.error('Unknown error trying sync shop. Please try again.'));
  };
  render() {
    const {
      shops,
      deleteShop,
      isAddModalOpen,
      url,
      loading
    } = this.state;
    return <>
        {loading ? <Loader /> : <div className="integration mb-5">
            <div className="integration__header">
              {this.context.hasPermission('woocommerce.add_shop') && <Button className="btn" actionType="primary" tag="a" onClick={() => this.setState({
            isAddModalOpen: true
          })}>
                  Add Shop
                </Button>}
            </div>
            <div className="integration__body">
              {shops.map(shop => <div className="integration-item" key={shop.id}>
                  <div className="integration-item__inner">
                    <Stack direction="row" spacing={2}>
                      <div className="integration-title">
                        <strong>{shop.url}</strong>
                      </div>
                      <div className="integration-actions">
                        {this.context.hasPermission('woocommerce.change_shop') && <Button onClick={e => {
                    e.stopPropagation();
                    this.syncShop(shop.id);
                  }} actionType="flat" icon="fa-regular-sync-alt" />}
                        {this.context.hasPermission('woocommerce.delete_shop') && <Button actionType="flat" icon="fa-regular-trash" onClick={e => {
                    e.stopPropagation();
                    this.setState({
                      deleteShop: shop
                    });
                  }} />}
                      </div>
                    </Stack>
                  </div>
                </div>)}
            </div>

            <ModalDialog open={isAddModalOpen} title="Add Shop" onSubmit={() => window.open(`${API_URL}/woocommerce/shop/request?url=${url}&company=${this.context.asCompany.id}`, '_blank')} onClose={() => this.setState({
          isAddModalOpen: false
        })} allowBackdropClick>
              <p>Please enter the url of your WooCommerce site. You must have pretty permalinks set for this to work.</p>
              <p>
                <label>URL</label>
                <input required className="form-control" name="site_id" onChange={e => this.setState({
              url: e.target.value
            })} />
              </p>
            </ModalDialog>

            <ModalDialog open={!!deleteShop.id} title="Remove Shop" submitText="Remove" onSubmit={() => this.removeShop(deleteShop.id)} onClose={() => this.setState({
          deleteShop: {}
        })} allowBackdropClick>
              <p>
                <strong>{deleteShop.url}</strong>
              </p>
              <p>Are you sure you want to remove this shop?</p>
            </ModalDialog>
          </div>}
      </>;
  }
}