import React from 'react';
function Icon(props) {
  return <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
      <g>
        <mask id="mask0_235_14989" style={{
        maskType: 'alpha'
      }} width="20" height="20" x="0" y="0" maskUnits="userSpaceOnUse">
          <path fill="#D9D9D9" d="M0 0H20V20H0z"></path>
        </mask>
        <g mask="url(#mask0_235_14989)">
          <path fill={props.fill || '#3898D9'} d="M4.5 17c-.412 0-.766-.147-1.06-.44A1.445 1.445 0 013 15.5v-11c0-.412.147-.766.44-1.06.294-.293.648-.44 1.06-.44h7.875c.197 0 .386.035.565.104.179.07.343.179.492.328l3.136 3.136c.149.149.258.313.328.492.07.18.104.368.104.565V15.5c0 .412-.147.766-.44 1.06-.294.293-.647.44-1.06.44h-11zm0-1.5h11V8h-2.75a.726.726 0 01-.534-.216A.726.726 0 0112 7.25V4.5H4.5v11zm4.744-7.75a.738.738 0 00.537-.214.714.714 0 00.219-.532.735.735 0 00-.215-.535.718.718 0 00-.534-.219H7.006a.738.738 0 00-.537.214.714.714 0 00-.219.532c0 .21.072.39.215.535A.718.718 0 007 7.75h2.245zm4.006 3.5c.213 0 .39-.072.534-.214a.72.72 0 00.216-.532.734.734 0 00-.216-.535.72.72 0 00-.534-.219h-6.5a.728.728 0 00-.534.214.72.72 0 00-.216.532c0 .21.072.39.216.535a.72.72 0 00.534.219h6.5z"></path>
        </g>
      </g>
    </svg>;
}
export default Icon;