import React, { useState, useEffect } from 'react';
import { AsyncModalDialog } from '../../components/modal/async-modal-dialog.component';
export const EditFieldModal = props => {
  const {
    field = {}
  } = props;
  const [name, setName] = useState();
  const [type, setType] = useState();
  const submit = () => {
    return props.onSubmit(props.modelID, {
      id: field.id,
      name,
      type
    });
  };
  useEffect(() => {
    setName(field ? field.name : '');
    setType(field ? field.type : '');
  }, [props.field]);
  return <AsyncModalDialog onClose={props.onClose} open={!!field && !!field.id} title="Edit Field" onSubmit={submit}>
      <p>
        <label>Name</label>
        <input required className="form-control" defaultValue={name} onChange={e => setName(e.target.value)} />
      </p>
      <p>
        <label>ID</label>
        <input disabled className="form-control" defaultValue={field && field.id} />
      </p>

      <p>
        <label>Type</label>
        <select disabled={!field || field.id && field.id.substr(0, 13) !== 'entity_data__'} className="form-control" value={type} onChange={e => setType(e.target.value)}>
          <option value="text">Text</option>
          <option value="date">Date</option>
          <option value="datetime">Date/Time</option>
          <option value="number">Number</option>
          <option value="boolean">Boolean</option>
          <option value="list">List</option>
        </select>
      </p>
    </AsyncModalDialog>;
};