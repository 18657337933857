import React from 'react';
function Icon() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none" viewBox="0 0 24 25">
      <mask id="mask0_123_2399" style={{
      maskType: 'alpha'
    }} width="25" height="25" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0 0.258H24V24.258H0z"></path>
      </mask>
      <g mask="url(#mask0_123_2399)">
        <path fill="#1D252D" d="M12 17.308a.832.832 0 01-.287-.05.924.924 0 01-.263-.15c-.633-.534-1.141-1.246-1.525-2.137a6.814 6.814 0 01-.575-2.713c0-.934.188-1.834.563-2.7.375-.867.896-1.584 1.562-2.15.084-.067.167-.121.25-.162A.602.602 0 0112 7.183c.1 0 .196.02.288.063.091.041.179.095.262.162.65.55 1.163 1.258 1.538 2.125.375.866.562 1.775.562 2.725a6.78 6.78 0 01-.575 2.725c-.383.883-.9 1.591-1.55 2.125a.991.991 0 01-.25.15.735.735 0 01-.275.05zm3.85 1.45a5.94 5.94 0 01-2.7-.625c.2-.15.4-.334.6-.55.2-.217.375-.425.525-.625.25.083.505.154.763.213.258.058.529.087.812.087 1.383 0 2.563-.488 3.538-1.463.975-.975 1.462-2.154 1.462-3.537 0-1.4-.487-2.584-1.462-3.55-.975-.967-2.155-1.45-3.538-1.45-.283 0-.554.025-.812.075-.258.05-.513.116-.763.2-.15-.2-.32-.409-.512-.625a3.065 3.065 0 00-.638-.55c.417-.2.85-.35 1.3-.45.45-.1.925-.15 1.425-.15 1.784 0 3.313.629 4.588 1.887s1.912 2.796 1.912 4.613c0 1.8-.637 3.333-1.912 4.6-1.275 1.266-2.804 1.9-4.588 1.9zm-7.7 0c-1.783 0-3.312-.634-4.587-1.9-1.275-1.267-1.913-2.8-1.913-4.6 0-1.817.638-3.355 1.913-4.613C4.838 6.387 6.367 5.758 8.15 5.758c.5 0 .975.05 1.425.15.45.1.884.25 1.3.45-.233.15-.445.333-.637.55a9.947 9.947 0 00-.513.625 6.043 6.043 0 00-.762-.2 4.277 4.277 0 00-.813-.075c-1.383 0-2.562.483-3.537 1.45-.975.966-1.463 2.15-1.463 3.55 0 1.383.488 2.562 1.463 3.537.975.975 2.154 1.463 3.537 1.463.283 0 .554-.03.813-.087.258-.059.512-.13.762-.213.15.2.325.408.525.625.2.216.4.4.6.55a5.94 5.94 0 01-2.7.625z"></path>
      </g>
    </svg>;
}
export default Icon;