import React, { useContext } from 'react';
import { Modal } from 'components/mui';
import { UserStateContext } from 'context/user-state-context';
export const TestRunAssetModal = ({
  onClose,
  open,
  assets,
  testRunID
}) => {
  const {
    token
  } = useContext(UserStateContext);
  return <Modal open={open} onClose={onClose} title="Node Assets">
      {assets?.map((asset, i) => {
      if (asset.type === 'email') {
        return <iframe key={i} src={'data:text/html,' + encodeURIComponent(asset.data.html)} style={{
          width: '100%'
        }} />;
      }
      if (asset.type === 'image/png') {
        return <img key={i} src={API_URL + '/journeys/test-runs/' + testRunID + '/file?_token=' + token + '&key=' + asset.data} style={{
          width: '100%',
          border: '1px solid #cccccc'
        }} />;
      }
      return <p key={i}>Unknown asset type</p>;
    })}
    </Modal>;
};