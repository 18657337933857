import React, { useState, useEffect, useContext } from 'react';
import { Loader, PageHeaderMui } from 'components';
import { getSurvey, updateSurvey, getSurveyRevisionTotal, getLocations, getRevisionQuestionsSummary } from 'shared/common.api';
import { Scoped } from 'kremling';
import styles from './surveys-edit.styles.scss';
import moment from 'moment-timezone';
import { UserStateContext } from '../../context/user-state-context';
import { BarChartFourBarsIcon, BidLandScapeIcon, CarrotDownIcon, CarrotUpIcon, ChecklistIcon, CloseIcon, DraftIcon, FactCheckIcon, ListAltIcon, LocationOnIcon, OpenInNewWindowIcon, PublishedIcon, QuestionsChecklistIcon, SmileIcon, SteppersIcon, SummarizeIcon, TimelineChartIcon } from 'components/mui';
import { Box, CssBaseline, Stack, Button, Typography, IconButton, Paper, Divider, Checkbox, Popper, ClickAwayListener, Tooltip as MuiTooltip, LinearProgress, Skeleton } from '@mui/material';
import classnames from 'classnames';
import { history } from '../../root.component';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import { Line, Bar, Doughnut } from 'react-chartjs-2';
import { styled } from '@mui/material/styles';
import { getFiltersFromURL } from 'components/mui/enhanced-table/utils';
import DatePicker from 'components/mui/calendar/date-picker.component';
import SlimSelect from 'components/mui/select/slim-select.component';
import { CustomerDrawerContext } from 'context/customer-drawer-context';
import { AntSwitch } from 'components/mui/switch/ant-switch.component';
import QuestionsModal from './questions-modal.component';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement, ArcElement);
const BorderLinearProgress = styled(LinearProgress)(({
  theme
}) => ({
  height: 16,
  borderRadius: 16,
  [`&.${LinearProgress.colorPrimary}`]: {
    backgroundColor: '#3898D9'
  },
  [`& .${LinearProgress.bar}`]: {
    backgroundColor: '#BAE3FF'
  }
}));
const questionTypes = [{
  key: 'excellent_to_poor',
  label: 'Excellent to Poor',
  options: ['Poor', 'Fair', 'Average', 'Good', 'Excellent']
}, {
  key: 'highly_to_unlikely',
  label: 'Highly to Unlikely',
  options: ['Highly Unlikely', 'Somewhat Unlikely', 'Neutral', 'Somewhat Likely', 'Highly Likely']
}, {
  key: 'yes_no',
  label: 'Yes/No',
  options: ['No', 'Yes']
}, {
  key: 'comment',
  label: 'Comment'
}, {
  key: 'one_to_five',
  label: 'One to Five',
  options: ['One', 'Two', 'Three', 'Four', 'Five']
}, {
  key: 'one_to_ten',
  label: 'One to Ten',
  options: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10']
}, {
  key: 'thumbs_up_down',
  label: 'Thumbs Up Or Down',
  options: ['Thumbs Down', 'Thumbs Up']
}, {
  key: 'smiley_frowney',
  label: 'Smile Or Frown',
  options: ['Dissatisfied', 'Satisfied']
}];
export const SurveysSummary = props => {
  const [survey, setSurvey] = useState({});
  const [revision, setRevision] = useState({});
  const [revisions, setRevisions] = useState([]);
  const [loading, setLoading] = useState(true);
  const {
    checkCompany,
    hasPermission,
    asCompany
  } = useContext(UserStateContext);
  const {
    openCustomerDrawer
  } = useContext(CustomerDrawerContext);
  const [chartViews, setChartViews] = useState([]);
  const [summaryData, setSummaryData] = useState([]);
  const [totalRevisionResponses, setTotalRevisionResponses] = useState(0);
  const [revisionsOptions, setRevisionsOptions] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);
  const [locationPickerOpen, setLocationPickerOpen] = React.useState(false);
  const [locationPickerAnchorEl, setLocationPickerAnchorEl] = React.useState(null);
  const [locationSelectAll, setLocationSelectAll] = React.useState(false);
  const [locationHover, setLocationHover] = React.useState(null);
  const [tabsLoading, setTabsLoading] = React.useState(true);
  const [questionsModal, setQuestionsModal] = useState(null);
  const filterOptions = [{
    key: 'date_after'
  }, {
    key: 'date_before'
  }, {
    key: 'last_location_ids'
  }];
  const [filters, setFilters] = React.useState(() => {
    const fils = getFiltersFromURL(filterOptions);
    if (!fils['date_after']) {
      fils['date_after'] = moment().subtract(30, 'days').startOf('day').toISOString();
    }
    return fils;
  });
  const loadData = rev => {
    setLoading(true);
    getRevisionQuestionsSummary(rev, {
      ...filters
    }).then(({
      data
    }) => {
      setSummaryData(data);
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    });
    getSurveyRevisionTotal(rev, {
      ...filters
    }).then(({
      data
    }) => {
      setTotalRevisionResponses(data?.count || 0);
    });
  };

  /**
   * Load page based on url parameters.  Either "create" or survey ID
   */
  useEffect(() => {
    if (props.match.params.id === 'create') {
      history.push(`/surveys`);
    } else if (props.match.params.id) {
      // Load survey
      // check for different company link
      setLoading(true);
      setTabsLoading(true);
      const promises = [];
      promises.push(getSurvey(props.match.params.id));
      promises.push(getLocations({
        company: asCompany.id,
        limit: 1000,
        ordering: 'name'
      }));
      Promise.all(promises).then(([{
        data: survey
      }, {
        data: locations
      }]) => {
        checkCompany(survey.company).then(() => {
          setSurvey(survey);
          const queryParams = new URLSearchParams(window.location.search);
          let usableRevision;
          if (queryParams.has('revision')) {
            usableRevision = survey.revisions.find(r => r.id === queryParams.get('revision'));
          }
          if (!usableRevision) {
            usableRevision = survey.revisions.length > 1 ? survey.revisions[1] : survey.revisions[0];
          }
          setRevision(usableRevision);
          setRevisions(survey.revisions);
          setRevisionsOptions(survey.revisions.filter(r => !!r.published_when).map((revision, index) => ({
            label: getRevisionName(revision, survey.revisions.filter(r => !!r.published_when).length - index),
            key: revision.id,
            icon: <PublishedIcon fill="#1D252D" />
          })));

          //if there is no published revision, then go back to edit page
          if (!usableRevision.published_when) {
            history.push(`/surveys/edit/${survey.id}`);
          }
          addToQueryParams(usableRevision.id);
          loadData(usableRevision.id);
          if (locations && locations.results) {
            setLocationOptions(locations.results.map(location => ({
              name: location.friendly_name || 'Unnamed Location',
              key: location.id
            })));
          }
          setTabsLoading(false);
        }).catch(() => {
          // User chose not to switch companies.
          setLoading(false);
        });
      });
    }
  }, [props.match.params.id]);
  const addToQueryParams = revision => {
    let queryParams = new URLSearchParams(window.location.search);
    queryParams.set('revision', revision);
    let newurl = window.location.protocol + '//' + window.location.host + window.location.pathname + '?' + queryParams.toString();
    window.history.pushState({
      path: newurl
    }, '', newurl);
  };

  /**
   * Helper function to display the revision name which is either Draft
   * or Revision # Published: <published_when>
   *
   * @param {string} revision
   * @param {int} num
   * @returns {string}
   */
  const getRevisionName = (revision, num) => {
    if (revision.published_when) {
      return `Revision #${num} Published: ${moment(revision.published_when).format('MMM D, YYYY')}`;
    }
    return 'Draft';
  };
  const updateName = name => {
    updateSurvey(survey.id, {
      title: name.name
    }).then(({
      data
    }) => {
      setSurvey(data);
    });
  };
  const getAverageScore = question => {
    if (question.q_type == 'yes_no' || question.q_type == 'thumbs_up_down' || question.q_type == 'smiley_frowney') {
      if (question.answers?.stats?.average_score == 0 || question.answers?.stats?.average_score == 1) {
        return (question.answers?.stats?.average_score * 100 || 0) + '%';
      }
      return (question.answers?.stats?.average_score * 100 || 0)?.toFixed(1) + '%';
    }
    if (question.q_type == 'highly_to_unlikely' || question.q_type == 'excellent_to_poor') {
      return (question.answers?.stats?.average_score * 5 || 0)?.toFixed(1);
    }
    if (question.q_type == 'one_to_five') {
      return (4 * question.answers?.stats?.average_score + 1 || 0)?.toFixed(1);
    }
    if (question.q_type == 'one_to_ten') {
      return (9 * question.answers?.stats?.average_score + 1 || 0)?.toFixed(1);
    }
    return 0;
  };
  const generateProgressBar = question => {
    const options = questionTypes.find(q => q.key == question.q_type)?.options;
    if (!options || question.q_type == 'comment') {
      return null;
    }
    return <Stack spacing="5px">
        <Box sx={{
        width: '100%'
      }}>
          <BorderLinearProgress variant="determinate" value={question?.answers?.stats?.total_responses > 0 ? (question.answers?.stats?.average_score || 0) * 100 : 0} />
        </Box>
        <Box sx={{
        display: 'flex',
        justifyContent: 'space-between'
      }}>
          {options.map(option => <Typography key={option}>{option}</Typography>)}
        </Box>
      </Stack>;
  };
  const getLineGraphData = question => {
    const values = Object.values(question?.answers?.line).map(value => {
      if (question.q_type == 'yes_no' || question.q_type == 'thumbs_up_down' || question.q_type == 'smiley_frowney') {
        return value * 100 || 0;
      }
      if (question.q_type == 'highly_to_unlikely' || question.q_type == 'excellent_to_poor') {
        return value * 5 || 0;
      }
      if (question.q_type == 'one_to_five') {
        return 4 * value + 1 || 0;
      }
      if (question.q_type == 'one_to_ten') {
        return 9 * value + 1 || 0;
      }
      return value;
    });
    return values;
  };
  const getMaxXValueBarChart = question => {
    const max = Math.max(...Object.values(question?.answers?.bar));
    return Math.ceil(max + max * 0.1);
  };
  const getStepSizeXBarChart = max => {
    if (max / 10 < 1) {
      return 1;
    }
    return Math.floor(max / 10);
  };
  const handleDateChange = (after, before) => {
    const {
      date_after: _,
      date_before: __,
      ...newFilters
    } = filters;
    setFilters({
      ...newFilters,
      date_after: after,
      ...(!!before && {
        date_before: before
      })
    });
  };
  const handleLocationPickerClick = e => {
    setLocationPickerOpen(true);
    setLocationPickerAnchorEl(e.currentTarget);
  };
  const handleLocationPickerClose = () => {
    setLocationPickerOpen(false);
    setLocationPickerAnchorEl(null);
  };
  const handleLocationSelectAll = () => {
    if (locationSelectAll) {
      handleSelectedLocations([]);
    } else {
      handleSelectedLocations(locationOptions.map(t => t.key));
    }
    setLocationSelectAll(!locationSelectAll);
  };
  const handleSelectedLocations = locations => {
    const {
      last_location_ids: _,
      ...newFilters
    } = filters;
    setFilters({
      ...newFilters,
      last_location_ids: locations
    });
  };
  const handleLocationPickerMenuClick = location => {
    const oldLocations = filters['last_location_ids'] || [];
    let newLocations = [];
    if (oldLocations.includes(location)) {
      newLocations = oldLocations.filter(f => f !== location);
    } else {
      newLocations = [...oldLocations, location];
    }
    setFilters({
      ...filters,
      last_location_ids: newLocations
    });
  };
  React.useEffect(() => {
    if (!revision?.id) {
      return;
    }
    loadData(revision.id);
  }, [filters]);
  const onCustomerDrawer = customer => {
    setQuestionsModal(null);
    if (hasPermission('customer.view_customer')) openCustomerDrawer(customer.id);
  };
  return <>
      <CssBaseline />
      <Scoped css={styles}>
        <div className="wrapper">
          <PageHeaderMui type="Surveys" name={survey.title} updateName={updateName} updateNamePermission={hasPermission('surveys.change_survey')} icon={<ListAltIcon size="lg" />} />

          <div className="wrapper-scroll">
            <>
              {tabsLoading ? <Box sx={{
              py: '28px',
              px: '20px',
              height: '72px'
            }}>
                  <Box sx={{
                px: '20px'
              }}>
                    <Skeleton variant="rectangular" width={'80vw'} height={15} />
                  </Box>
                </Box> : <Box sx={{
              py: '20px',
              px: '20px',
              height: '72px',
              display: 'flex',
              justifyContent: 'space-between'
            }}>
                  <Stack direction="row" spacing={'30px'}>
                    <Box className={classnames('box-button')} onClick={() => {
                  history.push(`/surveys/edit/${survey.id}`);
                }}>
                      <DraftIcon fill="#1D252D" />
                      <Typography>Draft View</Typography>
                    </Box>

                    <Box className={classnames('box-button')} onClick={() => {
                  history.push(`/surveys/edit/${survey.id}`, {
                    mode: 'published'
                  });
                }}>
                      <PublishedIcon fill="#1D252D" />
                      <Typography>Published View</Typography>
                    </Box>

                    {revisions.some(r => !!r.published_when) && <Box className={classnames('box-button', {
                  active: true
                })}>
                        <SummarizeIcon fill="#1D252D" />
                        <Typography>Summary</Typography>
                      </Box>}
                  </Stack>
                  <Stack direction="row" spacing="20px">
                    <DatePicker filters={filters} after={filters.date_after} before={filters?.date_before} handleFilterChange={setFilters} handleDateChange={handleDateChange} bottom="10px" background="#fff" />

                    <Box onClick={e => {
                  handleLocationPickerClick(e);
                }} className="d-flex h-100 justify-content-between" sx={{
                  borderRadius: '16px',
                  py: '2px',
                  pl: '9px',
                  pr: '2px',
                  cursor: 'pointer',
                  border: '1px solid #E3E5E7',
                  display: 'flex',
                  alignItems: 'center',
                  background: !filters['last_location_ids'] || filters['last_location_ids']?.length == 0 ? '#fff' : '#C2E7FF'
                }} direction="row">
                      {(!filters['last_location_ids'] || filters['last_location_ids']?.length == 0) && <Stack direction="row" spacing="6px">
                          <LocationOnIcon />
                          <Box sx={{
                      display: 'flex',
                      alignItems: 'center'
                    }}>
                            <Typography>Last Location</Typography>
                          </Box>
                        </Stack>}
                      {filters['last_location_ids']?.length > 0 && <Box className="d-flex w-100 justify-content-between">
                          <Stack spacing="5px" direction="row" sx={{
                      display: 'flex',
                      alignItems: 'center'
                    }}>
                            <Typography sx={{
                        color: '#3898D9'
                      }}>
                              {'Last Location (' + filters['last_location_ids']?.length + ')'}
                            </Typography>
                          </Stack>
                          {filters['last_location_ids']?.length > 0 && <IconButton size="small" onClick={e => {
                      e.stopPropagation();
                      handleSelectedLocations([]);
                      handleLocationPickerClose();
                    }}>
                              <CloseIcon size="sm" fill="#3898D9" />
                            </IconButton>}
                        </Box>}
                      {(!filters['last_location_ids'] || filters['last_location_ids']?.length == 0) && <> {locationPickerOpen ? <CarrotUpIcon /> : <CarrotDownIcon />}</>}
                    </Box>

                    <SlimSelect background="#fff" options={revisionsOptions} title="Revisions" placeholder={{
                  label: 'Revision',
                  icon: <PublishedIcon />
                }} value={revision?.id || ''} onChange={option => {
                  loadData(option.key);
                  setRevision(revisions.find(r => r.id === option.key));
                  addToQueryParams(option.key);
                }} />
                  </Stack>
                </Box>}

              <Stack sx={{
              pb: '24px'
            }} spacing={'20px'}>
                <Box sx={{
                px: '20px'
              }}>
                  <Paper sx={{
                  borderRadius: '14px'
                }}>
                    <Stack sx={{
                    height: '100%',
                    py: '24px'
                  }} className="d-flex justify-content-between align-items-center w-100" direction="row">
                      {loading ? <Box sx={{
                      px: '24px',
                      height: '100%'
                    }}>
                          <Box sx={{
                        py: '5px'
                      }}>
                            <Skeleton variant="rectangular" width={'80vw'} height={15} />
                          </Box>
                        </Box> : <Stack sx={{
                      pl: '24px',
                      display: 'flex',
                      alignItems: 'center'
                    }} spacing="20px" direction="row">
                          <Typography fontSize="20px">Survey Data:</Typography>

                          <Stack spacing="10px" sx={{
                        display: 'flex',
                        alignItems: 'center'
                      }} direction="row">
                            <SteppersIcon />
                            <Typography variant="tableHeader"> Total Versions:</Typography>
                            <Typography> {survey.revisions?.length - 1}</Typography>
                          </Stack>

                          <Divider orientation="vertical" sx={{
                        height: '24px'
                      }} />
                          <Stack spacing="10px" sx={{
                        display: 'flex',
                        alignItems: 'center'
                      }} direction="row">
                            <QuestionsChecklistIcon />
                            <Typography variant="tableHeader"> Total Questions:</Typography>
                            <Typography>{summaryData?.length} </Typography>
                          </Stack>
                          <Divider orientation="vertical" sx={{
                        height: '24px'
                      }} />
                          <Stack spacing="10px" sx={{
                        display: 'flex',
                        alignItems: 'center'
                      }} direction="row">
                            <ChecklistIcon />
                            <Typography variant="tableHeader"> Total Responses:</Typography>
                            <Typography> {totalRevisionResponses} </Typography>
                          </Stack>
                        </Stack>}
                    </Stack>
                  </Paper>
                </Box>
                {loading ? <>
                    {Array(5).fill(null).map((row, index) => <Box key={index} sx={{
                  px: '20px'
                }}>
                          <Paper sx={{
                    px: '20px',
                    borderRadius: '14px'
                  }}>
                            <Box sx={{
                      p: '20px',
                      height: '350px',
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}>
                              <Stack spacing="30px" sx={{
                        px: '24px'
                      }}>
                                {Array(4).fill(null).map((row2, index2) => <Box key={index + ',' + index2}>
                                      <Skeleton variant="rectangular" width={'35vw'} height={'50px'} />
                                    </Box>)}
                              </Stack>
                              <Box sx={{
                        px: '24px'
                      }}>
                                <Skeleton variant="rectangular" width={'35vw'} height={'305px'} />
                              </Box>
                            </Box>
                          </Paper>
                        </Box>)}
                  </> : <>
                    {summaryData.map(question => <Box key={question.id} sx={{
                  px: '20px'
                }}>
                        <Paper sx={{
                    px: '20px',
                    borderRadius: '14px'
                  }}>
                          <Stack sx={{
                      py: '20px',
                      pl: '24px',
                      pr: question.q_type == 'comment' ? '0px' : '24px'
                    }}>
                            <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}>
                              <Box sx={{
                          display: 'flex',
                          alignItems: 'center'
                        }}>
                                <Typography variant="tableHeader">{question.q_text}</Typography>
                              </Box>
                              {question.q_type != 'comment' && <Stack spacing="10px" sx={{
                          display: 'flex',
                          alignItems: 'center'
                        }} direction="row">
                                  <MuiTooltip title="Line Chart">
                                    <IconButton onClick={e => {
                              const q = chartViews.find(data => data.id == question.id);
                              let tempChartViews = [...chartViews];
                              if (q) {
                                tempChartViews = tempChartViews.filter(data => data.id != question.id);
                              }
                              tempChartViews.push({
                                id: question.id,
                                chartView: 'line'
                              });
                              setChartViews(tempChartViews);
                            }}>
                                      <TimelineChartIcon fill={chartViews.find(data => data.id == question.id)?.chartView == 'line' || !chartViews.find(view => view.id == question.id)?.chartView ? '#3898D9' : '#1D252D'} />
                                    </IconButton>
                                  </MuiTooltip>

                                  {question?.answers?.stats?.total_responses > 0 && <MuiTooltip title={question.q_type == 'yes_no' || question.q_type == 'thumbs_up_down' || question.q_type == 'smiley_frowney' ? 'Doughnut  Chart' : 'Bar Chart'}>
                                      <IconButton onClick={e => {
                              const q = chartViews.find(data => data.id == question.id);
                              let tempChartViews = [...chartViews];
                              if (q) {
                                tempChartViews = tempChartViews.filter(data => data.id != question.id);
                              }
                              tempChartViews.push({
                                id: question.id,
                                chartView: 'bar'
                              });
                              setChartViews(tempChartViews);
                            }}>
                                        <Box sx={{
                                transform: 'rotate(90deg)'
                              }}>
                                          <BarChartFourBarsIcon fill={chartViews.find(data => data.id == question.id)?.chartView == 'bar' ? '#3898D9' : '#1D252D'} />
                                        </Box>
                                      </IconButton>
                                    </MuiTooltip>}
                                </Stack>}
                            </Box>
                            <Stack spacing="10px">
                              <Stack direction={'row'} spacing="10px">
                                <Box sx={{
                            display: 'flex',
                            alignItems: 'center'
                          }}>
                                  <Typography>{questionTypes.find(q => q.key == question.q_type)?.label}</Typography>
                                </Box>
                                <Box sx={{
                            display: 'flex',
                            alignItems: 'center'
                          }}>
                                  <Divider orientation="vertical" sx={{
                              borderRightWidth: 2,
                              height: '17px'
                            }} />
                                </Box>
                                <Box sx={{
                            display: 'flex',
                            alignItems: 'center'
                          }}>
                                  <Typography>{question.answers?.stats?.total_responses + ' Responses'}</Typography>
                                </Box>
                                {!!question?.weight && <Box sx={{
                            display: 'flex',
                            alignItems: 'center'
                          }}>
                                    <Divider orientation="vertical" sx={{
                              borderRightWidth: 2,
                              height: '17px'
                            }} />
                                  </Box>}
                                {!!question?.weight && <Stack direction={'row'} spacing={1}>
                                    <SmileIcon />
                                    <Box sx={{
                              display: 'flex',
                              alignItems: 'center'
                            }}>
                                      <Typography>Customer Satisfaction Question </Typography>
                                    </Box>
                                  </Stack>}
                              </Stack>
                              {question.q_type != 'comment' && <Stack direction={'row'}>
                                  <Box sx={{
                            width: '40%'
                          }}>
                                    <Box sx={{
                              display: 'flex',
                              justifyContent: 'center'
                            }}>
                                      <Typography variant="tableHeader"> Average Score</Typography>
                                    </Box>
                                  </Box>
                                  <Box sx={{
                            width: '60%'
                          }}>
                                    <Box sx={{
                              display: 'flex',
                              justifyContent: 'center'
                            }}>
                                      <Typography variant="tableHeader">
                                        {chartViews.find(view => view.id == question.id)?.chartView == 'line' ? 'Average Answer' : chartViews.find(view => view.id == question.id)?.chartView == 'bar' ? question.q_type == 'yes_no' || question.q_type == 'thumbs_up_down' || question.q_type == 'smiley_frowney' ? 'Score Distribution' : 'Average Scores' : 'Average Answer'}
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Stack>}

                              {question.q_type != 'comment' ? <Stack direction={'row'} spacing={'36px'}>
                                  {question.answers?.stats?.total_responses > 0 ? <Stack spacing={'30px'} sx={{
                            width: '40%'
                          }}>
                                      <Box sx={{
                              pt: '20px',
                              display: 'flex',
                              justifyContent: 'center'
                            }}>
                                        <Typography fontSize={128} sx={{
                                fontWeight: 800,
                                lineHeight: '100px'
                              }} color={'#3898D9'}>
                                          {getAverageScore(question)}
                                        </Typography>
                                      </Box>
                                      {generateProgressBar(question)}
                                    </Stack> : <Stack spacing={'70px'} sx={{
                            width: '40%'
                          }}>
                                      <Box sx={{
                              pt: '40px',
                              display: 'flex',
                              justifyContent: 'center'
                            }}>
                                        <Box sx={{
                                backgroundColor: '#F3F3F4',
                                borderRadius: '8px'
                              }}>
                                          <Stack sx={{
                                  py: '6px',
                                  px: '12px'
                                }} direction="row" spacing="8px">
                                            <BidLandScapeIcon />
                                            <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center'
                                  }}>
                                              <Typography sx={{
                                      fontWeight: '500',
                                      color: '#616161'
                                    }}>
                                                {Object.keys(filters)?.length > 0 ? 'No responses with selected filters. Try adjusting filters' : 'No responses yet'}
                                              </Typography>
                                            </Box>
                                          </Stack>
                                        </Box>
                                      </Box>
                                      {generateProgressBar(question)}
                                    </Stack>}
                                  <Box sx={{
                            width: '60%',
                            height: '200px'
                          }}>
                                    {(chartViews.find(view => view.id == question.id)?.chartView == 'line' || !chartViews.find(view => view.id == question.id)?.chartView) && <>
                                        {Object.keys(question?.answers?.line)?.length > 0 ? <Line height={50} width={100} plugins={[{
                                afterDatasetsDraw: chart => {
                                  if (chart.tooltip?._active?.length) {
                                    let x = chart.tooltip._active[0].element.x;
                                    let yAxis = chart.scales.y;
                                    let ctx = chart.ctx;
                                    ctx.save();
                                    ctx.beginPath();
                                    ctx.moveTo(x, yAxis.top);
                                    ctx.lineTo(x, yAxis.bottom);
                                    ctx.lineWidth = 2;
                                    ctx.strokeStyle = '#3898D9';
                                    ctx.stroke();
                                    ctx.restore();
                                  }
                                }
                              }]} options={{
                                responsive: true,
                                maintainAspectRatio: false,
                                elements: {
                                  point: {
                                    borderWidth: 0,
                                    radius: 0,
                                    hitRadius: 15,
                                    hoverRadius: 10,
                                    hoverBackgroundColor: '#3898D9'
                                  }
                                },
                                scales: {
                                  y: {
                                    min: question.q_type == 'yes_no' || question.q_type == 'thumbs_up_down' || question.q_type == 'smiley_frowney' || question.q_type == 'excellent_to_poor' || question.q_type == 'highly_to_unlikely' ? 0 : 1,
                                    max: question.q_type == 'yes_no' || question.q_type == 'thumbs_up_down' || question.q_type == 'smiley_frowney' ? 100 : question.q_type == 'one_to_ten' ? 10 : 5,
                                    ticks: {
                                      color: '#1D252D',
                                      font: {
                                        family: 'Nexa',
                                        size: 12
                                      },
                                      stepSize: question.q_type == 'excellent_to_poor' || question.q_type == 'highly_to_unlikely' ? 1.25 : question.q_type == 'yes_no' || question.q_type == 'thumbs_up_down' || question.q_type == 'smiley_frowney' ? 100 : 1,
                                      callback: function (value, index, ticks) {
                                        const options = questionTypes.find(q => q.key == question.q_type)?.options;
                                        if (!options || question.q_type == 'comment') {
                                          return null;
                                        }
                                        return options[index];
                                      }
                                    },
                                    grid: {
                                      tickLength: 0
                                    }
                                  },
                                  x: {
                                    offset: question.answers?.line && Object.keys(question.answers?.line).length == 1 ? true : false,
                                    ticks: {
                                      color: '#1D252D',
                                      font: {
                                        family: 'Nexa',
                                        size: 12
                                      },
                                      callback: function (val, index) {
                                        // return this.getLabelForValue(val);
                                        return moment(this.getLabelForValue(val)).format('MMM D');
                                      }
                                    },
                                    grid: {
                                      tickLength: 0
                                    }
                                  }
                                },
                                plugins: {
                                  title: {
                                    display: false
                                  },
                                  legend: {
                                    display: false
                                  },
                                  tooltip: {
                                    xAlign: 'right',
                                    yAlign: 'bottom',
                                    mode: 'index',
                                    intersect: false,
                                    displayColors: false,
                                    callbacks: {
                                      title: function (context) {
                                        return moment(context[0]?.label).format('MMM D, YYYY');
                                      },
                                      label: function (context) {
                                        let label = context.dataset.label || '';
                                        if (context.parsed.y !== null) {
                                          if (question.q_type == 'yes_no' || question.q_type == 'thumbs_up_down' || question.q_type == 'smiley_frowney') {
                                            label += 'Average: ' + context.parsed.y + '%';
                                          } else {
                                            label += 'Average: ' + context.parsed.y.toFixed(1);
                                          }
                                        }
                                        return label;
                                      }
                                    }
                                  },
                                  hover: {
                                    mode: 'index',
                                    intersect: false
                                  }
                                }
                              }} data={{
                                //labels: ['Excellent', 'Good', 'Average', 'Fair', 'Poor'],
                                labels: Object.keys(question?.answers?.line),
                                datasets: [{
                                  // label: 'sdf,
                                  data: getLineGraphData(question),
                                  //data : [65, 59, 80, 81, 56],
                                  borderColor: '#BAE3FF'
                                  //backgroundColor: '#BAE3FF',
                                }]
                              }} /> : <Line height={50} width={100} plugins={[{
                                afterDraw: chart => {
                                  let ctx = chart.ctx;
                                  let width = chart.width;
                                  let height = chart.height;

                                  //chart.clear();
                                  ctx.save();
                                  ctx.textAlign = 'center';
                                  ctx.textBaseline = 'middle';
                                  ctx.font = '16px Nexa';
                                  ctx.fillText(Object.keys(filters)?.length > 0 ? 'No responses with selected filters. Try adjusting filters' : 'No responses yet', width / 2, (height - 20) / 2);
                                  ctx.restore();
                                }
                              }]} options={{
                                responsive: true,
                                maintainAspectRatio: false,
                                elements: {},
                                scales: {
                                  y: {
                                    min: question.q_type == 'yes_no' || question.q_type == 'thumbs_up_down' || question.q_type == 'smiley_frowney' || question.q_type == 'excellent_to_poor' || question.q_type == 'highly_to_unlikely' ? 0 : 1,
                                    max: question.q_type == 'yes_no' || question.q_type == 'thumbs_up_down' || question.q_type == 'smiley_frowney' ? 100 : question.q_type == 'one_to_ten' ? 10 : 5,
                                    ticks: {
                                      color: '#1D252D',
                                      font: {
                                        family: 'Nexa',
                                        size: 12
                                      },
                                      stepSize: question.q_type == 'excellent_to_poor' || question.q_type == 'highly_to_unlikely' ? 1.25 : question.q_type == 'yes_no' || question.q_type == 'thumbs_up_down' || question.q_type == 'smiley_frowney' ? 100 : 1,
                                      callback: function (value, index, ticks) {
                                        const options = questionTypes.find(q => q.key == question.q_type)?.options;
                                        if (!options || question.q_type == 'comment') {
                                          return null;
                                        }
                                        return options[index];
                                      }
                                    },
                                    grid: {
                                      tickLength: 0
                                    }
                                  }
                                },
                                plugins: {
                                  title: {
                                    display: false
                                  },
                                  legend: {
                                    display: false
                                  }
                                }
                              }} data={{
                                labels: Object.keys(question?.answers?.line),
                                datasets: [{
                                  data: [],
                                  borderColor: '#BAE3FF'
                                }]
                              }} />}
                                      </>}

                                    {chartViews.find(view => view.id == question.id)?.chartView == 'bar' && <>
                                        {question.q_type == 'yes_no' || question.q_type == 'thumbs_up_down' || question.q_type == 'smiley_frowney' ? <>
                                            <Doughnut height={50} width={100} options={{
                                  //rotation: 180,
                                  responsive: true,
                                  maintainAspectRatio: false,
                                  plugins: {
                                    tooltip: {
                                      displayColors: false
                                    },
                                    legend: {
                                      // display: false,
                                      position: 'bottom',
                                      reverse: true,
                                      labels: {
                                        boxWidth: 10,
                                        boxHeight: 10,
                                        usePointStyle: true
                                      }
                                    }
                                  }
                                }} data={{
                                  labels: Object.keys(question?.answers?.bar),
                                  datasets: [{
                                    data: Object.values(question?.answers?.bar),
                                    backgroundColor: ['#BAE3FF', '#3898D9'],
                                    borderColor: ['#FFF', '#FFF'],
                                    borderWidth: 5
                                  }]
                                }} {...props} />
                                          </> : <Bar options={{
                                indexAxis: 'y',
                                responsive: true,
                                maintainAspectRatio: false,
                                elements: {
                                  bar: {
                                    borderSkipped: false // Apply setting to all bar datasets
                                  }
                                },
                                scales: {
                                  x: {
                                    max: getMaxXValueBarChart(question),
                                    grid: {
                                      display: false
                                    },
                                    ticks: {
                                      color: '#1D252D',
                                      font: {
                                        family: 'Nexa',
                                        size: 14
                                      },
                                      stepSize: getStepSizeXBarChart(getMaxXValueBarChart(question))
                                    }
                                  },
                                  y: {
                                    ticks: {
                                      color: '#1D252D',
                                      font: {
                                        family: 'Nexa',
                                        size: 12
                                      }
                                    },
                                    grid: {
                                      display: false
                                    }
                                  }
                                },
                                plugins: {
                                  title: {
                                    display: false
                                  },
                                  legend: {
                                    display: false
                                  },
                                  tooltip: {
                                    displayColors: false,
                                    callbacks: {
                                      label: function (context) {
                                        return context?.parsed?.x ? context.parsed.x + ' Responses' : '';
                                      },
                                      title: function (context) {
                                        return '';
                                      }
                                    }
                                  }
                                }
                              }} data={{
                                labels: Object.keys(question?.answers?.bar),
                                datasets: [{
                                  data: Object.values(question?.answers?.bar),
                                  backgroundColor: ['#3898D9'],
                                  barThickness: 16,
                                  borderRadius: 16,
                                  borderSkipped: false
                                }]
                              }} />}
                                      </>}
                                  </Box>
                                </Stack> : <Box sx={{
                          maxHeight: '350px',
                          overflowY: 'scroll',
                          scrollbarWidth: 'thin',
                          '&::-webkit-scrollbar': {
                            width: '0.4em'
                          },
                          '&::-webkit-scrollbar-track': {
                            background: '#f1f1f1'
                          },
                          '&::-webkit-scrollbar-thumb': {
                            backgroundColor: '#888'
                          },
                          '&::-webkit-scrollbar-thumb:hover': {
                            background: '#555'
                          }
                        }}>
                                  <Stack spacing="10px" sx={{
                            pr: '16px'
                          }}>
                                    {question?.answers?.comments?.length == 0 ? <Box sx={{
                              py: '40px',
                              px: '40px',
                              width: '20%'
                            }}>
                                        <Box sx={{
                                backgroundColor: '#F3F3F4',
                                borderRadius: '8px'
                              }}>
                                          <Stack sx={{
                                  py: '6px',
                                  px: '12px'
                                }} direction="row" spacing="8px">
                                            <BidLandScapeIcon />
                                            <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center'
                                  }}>
                                              <Typography sx={{
                                      fontWeight: '500',
                                      color: '#616161'
                                    }}>
                                                {Object.keys(filters)?.length > 0 ? 'No responses with selected filters. Try adjusting filters' : 'No responses yet'}
                                              </Typography>
                                            </Box>
                                          </Stack>
                                        </Box>
                                      </Box> : <>
                                        {question?.answers?.comments?.map(comment => <Box className="d-flex w-100 justify-content-between" key={comment.id} direction="row" sx={{
                                borderRadius: '14px',
                                backgroundColor: '#E4E9F1'
                              }}>
                                            <Box sx={{
                                  py: '20px',
                                  px: '16px'
                                }}>
                                              <Typography>{comment.answer}</Typography>
                                            </Box>
                                            <Box sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  pr: '12px'
                                }}>
                                              <Box sx={{
                                    height: '40px',
                                    width: '40px'
                                  }}>
                                                <MuiTooltip title={'View Survey'}>
                                                  <IconButton onClick={e => {
                                        setQuestionsModal({
                                          id: comment?.survey_invite_id
                                        });
                                      }}>
                                                    <OpenInNewWindowIcon fill="#1D252D" />
                                                  </IconButton>
                                                </MuiTooltip>
                                              </Box>
                                            </Box>
                                          </Box>)}
                                      </>}
                                  </Stack>
                                </Box>}
                            </Stack>
                          </Stack>
                        </Paper>
                      </Box>)}
                  </>}
              </Stack>
            </>
          </div>
        </div>

        <Popper anchorEl={locationPickerAnchorEl} open={locationPickerOpen} sx={{
        zIndex: 1500,
        width: '312px'
      }} onClose={handleLocationPickerClose} placement="bottom">
          <ClickAwayListener onClickAway={handleLocationPickerClose}>
            <Paper elevation={0} sx={{
            borderRadius: '16px',
            filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))'
          }}>
              <Box sx={{
              py: '12px',
              px: '16px'
            }} className="d-flex h-100 justify-content-between">
                <Typography variant="tableHeader">Last Locations</Typography>
                <Box sx={{
                cursor: 'pointer'
              }} onClick={e => {
                handleLocationPickerClose();
              }}>
                  <CloseIcon />
                </Box>
              </Box>

              <Box sx={{
              py: '12px',
              px: '16px'
            }} className="d-flex h-100 justify-content-between">
                <Typography>Select all</Typography>
                <AntSwitch sx={{
                ml: '40px'
              }} checked={locationSelectAll} onChange={() => {
                handleLocationSelectAll();
              }} />
              </Box>
              <Box sx={{
              pl: '25px',
              pb: '25px'
            }}>
                {locationOptions.map(filter => <Box key={filter.key} onMouseEnter={e => setLocationHover(filter.key)} onMouseLeave={e => setLocationHover(null)} className="d-flex w-100 justify-content-between">
                    <Stack direction="row" sx={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center'
                }} spacing="4px">
                      <Checkbox color="primary" checked={!!filters['last_location_ids']?.find(t => t == filter.key)} onChange={e => {
                    handleLocationPickerMenuClick(filter.key);
                  }} sx={{
                    color: '#1D252D',
                    '&.Mui-checked': {
                      color: '#53A6D6'
                    },
                    '& .MuiSvgIcon-root': {
                      fontSize: 22
                    }
                  }} />
                      <Stack direction="row" sx={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center'
                  }} spacing="12px">
                        <Box>{filter.icon}</Box>
                        <Typography> {filter.name}</Typography>
                      </Stack>
                    </Stack>
                    {locationHover == filter.key && <Box onClick={e => {
                  handleSelectedLocations([filter.key]);
                }} sx={{
                  pr: '35px',
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer'
                }}>
                        <Typography sx={{
                    color: '#3898D9'
                  }}> Only</Typography>
                      </Box>}
                  </Box>)}
              </Box>
              <Divider />
              <Box sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              pr: '25px',
              py: '10px'
            }}>
                <Button onClick={e => {
                handleSelectedLocations([]);
              }}>
                  <Typography sx={{
                  color: '#3898D9',
                  textTransform: 'none',
                  cursor: 'pointer'
                }}> Clear</Typography>
                </Button>
              </Box>
            </Paper>
          </ClickAwayListener>
        </Popper>

        {questionsModal && <QuestionsModal data={questionsModal} onClose={() => setQuestionsModal(null)} onCustomerDrawer={onCustomerDrawer} />}
      </Scoped>
    </>;
};