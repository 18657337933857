import React, { useEffect, useRef, useState } from 'react';
import { getter } from './utils';
import moment from 'moment-timezone';
import numeral from 'numeral';
import { Button, Box, Paper, Stack, Table, TableBody, TableCell, TableRow, Typography, TableHead, Tooltip, IconButton } from '@mui/material';
import SelectMUI from 'components/mui/select/select.component';
import Carousel from 'react-material-ui-carousel';
import { ArrowBackIcon, ArrowForwardIcon, Calendar30DaysIcon, CarrotDownIcon, CarrotUpIcon, LocationOnIcon, MoneyIcon, VehicleIcon, VehicleTagIcon } from 'components/mui';
import CircularProgress from '@mui/material/CircularProgress';
const fieldsToExclude = ['deleted_when', 'created_when', 'company_id'];
const fieldsDetailsToExclude = ['company_id', 'deleted_when', 'created_when', 'type', 'value', 'expires_when'];
export function RewardsComponent(props) {
  const {
    rewards,
    customerDetails,
    availableModelsFields,
    availableModels,
    isLoading,
    _detailsTabs,
    handleDetailsTab,
    selectedDetailsTab
  } = props;
  const [showMoreFields, setShowMoreFields] = useState(false);
  const [showMoreDetails, setShowMoreDetails] = useState(false);
  const [expandedRewardDetail, setExpandedRewardDetail] = useState(null);
  const [selectedRewardIndex, setSelectedRewardIndex] = useState(0);
  const [onDetailsHover, setOnDetailsHover] = useState(null);
  const getFilteredRewardFields = reward => {
    return rewardFields?.filter(f => reward[f?.id] != undefined && reward[f?.id] != null && reward[f?.id] != '' || f.id.substring(0, 13) === 'entity_data__' || f.id.substring(0, 15) === 'appended_data__');
  };
  const handleRewardDetailClick = id => {
    setExpandedRewardDetail(expandedRewardDetail == id ? null : id);
  };
  const rewardFields = availableModelsFields?.find(model => model.id === 'reward_account')?.fields?.filter(f => !fieldsToExclude.includes(f.id));
  const rewardDetailsFields = availableModelsFields?.find(model => model.id === 'reward')?.fields?.filter(f => !fieldsDetailsToExclude.includes(f.id));
  return <>
      <Stack sx={{
      height: '100%',
      background: '#E4E9F1',
      borderBottomLeftRadius: '28px',
      borderBottomRightRadius: '28px',
      overflowY: 'scroll',
      scrollbarWidth: 'thin',
      '&::-webkit-scrollbar': {
        width: '0.4em'
      },
      '&::-webkit-scrollbar-track': {
        background: '#f1f1f1',
        marginTop: '25px',
        marginBottom: '25px'
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#888'
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#555'
      }
    }} className="d-flex h-100" spacing={'14px'}>
        <Box className="d-flex w-100  justify-content-between" sx={{
        position: 'relative',
        pt: '14px',
        pb: '38px',
        display: 'flex'
      }}>
          <span />
          <Box sx={{
          position: 'absolute',
          left: '37.5%'
        }}>
            <SelectMUI height="36px" width="180px" top="6px" zIndex={9001} options={_detailsTabs} value={selectedDetailsTab || ''} onChange={opt => handleDetailsTab(opt.key)} />
          </Box>
          {rewards?.length > 0 && <Box sx={{
          position: 'absolute',
          left: '77%',
          top: '24px'
        }}>
              {'' + (selectedRewardIndex + 1) + ' of ' + rewards?.length + ' Items'}
            </Box>}
        </Box>
        <Stack>
          {isLoading ? <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          pt: '25px'
        }}>
              <CircularProgress />
            </Box> : <>
              {rewards?.length > 0 ? <Carousel index={selectedRewardIndex} navButtonsAlwaysVisible cycleNavigation={false} autoPlay={false} animation="slide" indicators={false} PrevIcon={<ArrowBackIcon fill="#fff" />} NextIcon={<ArrowForwardIcon fill="#fff" />} onChange={(now, prev) => setSelectedRewardIndex(now)}>
                  {rewards?.map((reward, i) => <Box key={rewards.id + i} sx={{
              px: '50px'
            }}>
                      <Paper sx={{
                borderRadius: '14px',
                overflowY: 'scroll',
                scrollbarWidth: 'thin',
                '&::-webkit-scrollbar': {
                  width: '0.4em'
                },
                '&::-webkit-scrollbar-track': {
                  background: '#f1f1f1',
                  marginTop: '10px',
                  marginBottom: '10px'
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#888'
                },
                '&::-webkit-scrollbar-thumb:hover': {
                  background: '#555'
                },
                height: '70vh'
              }}>
                        <Table>
                          <TableBody>
                            {availableModels.indexOf('reward_account') > -1 && getFilteredRewardFields(reward)?.filter((f, index) => showMoreFields || index < 4)?.map((field, index) => {
                      const fieldValue = reward[field.id];
                      return fieldValue !== undefined && fieldValue !== null && fieldValue !== '' ? <TableRow key={field.id + index}>
                                      <TableCell>
                                        <Typography variant="tableHeader">{field.name} </Typography>
                                      </TableCell>

                                      <TableCell>
                                        {field.type === 'datetime' ? moment(fieldValue).local().format('MMM D, YYYY h:mm A') : field.type === 'date' ? moment(fieldValue).format('MMM D, YYYY') : fieldValue}
                                      </TableCell>
                                    </TableRow> : field.id.substring(0, 13) === 'entity_data__' ? <TableRow key={index}>
                                      <TableCell>
                                        <Typography variant="tableHeader">{field.name} </Typography>
                                      </TableCell>
                                      <TableCell>
                                        {getter(reward, field.id.split('__')) || <Typography sx={{
                            color: '#BEBEBE'
                          }}>--</Typography>}
                                      </TableCell>
                                    </TableRow> : field.id.substring(0, 15) === 'appended_data__' ? <TableRow key={index}>
                                      <TableCell>
                                        <Typography variant="tableHeader">{field.name} </Typography>
                                      </TableCell>
                                      <TableCell>
                                        {getter(reward, field.id.split('__')) || <Typography sx={{
                            color: '#BEBEBE'
                          }}>--</Typography>}
                                      </TableCell>
                                    </TableRow> : <React.Fragment key={field.id} />;
                    })}

                            {getFilteredRewardFields(reward)?.length > 0 && <TableRow sx={{
                      cursor: 'pointer'
                    }} onClick={() => setShowMoreFields(!showMoreFields)}>
                                <TableCell sx={{
                        py: '8px'
                      }}>
                                  <Typography sx={{
                          color: '#1D252D80'
                        }}>
                                    {'... ' + (getFilteredRewardFields(reward).length - 4) + (showMoreFields ? ' less items' : ' more items')}
                                  </Typography>
                                </TableCell>
                                <TableCell sx={{
                        py: '8px'
                      }}>
                                  <Box className="d-flex  justify-content-between">
                                    <span />
                                    <Button>
                                      <Stack sx={{
                              display: 'flex',
                              alignItems: 'center'
                            }} direction="row" spacing="10px">
                                        <Typography sx={{
                                color: '#3898D9',
                                textTransform: 'none'
                              }}>
                                          {showMoreFields ? 'View less' : 'View all'}
                                        </Typography>
                                        {showMoreFields ? <CarrotUpIcon /> : <CarrotDownIcon />}
                                      </Stack>
                                    </Button>
                                  </Box>
                                </TableCell>
                              </TableRow>}
                          </TableBody>
                        </Table>

                        {reward?.earned?.length > 0 && <Table>
                            <colgroup>
                              <col style={{
                      width: '33%'
                    }} />
                              <col style={{
                      width: '33%'
                    }} />
                              <col style={{
                      width: '33%'
                    }} />
                            </colgroup>
                            <TableHead>
                              <TableRow>
                                <TableCell>
                                  <Typography variant="tableHeader">Type</Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant="tableHeader">Value</Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant="tableHeader">Expires</Typography>
                                </TableCell>
                              </TableRow>
                            </TableHead>

                            <TableBody>
                              {reward?.earned?.filter((d, i) => showMoreDetails || i < 4)?.map((detail, index) => <React.Fragment key={detail.id + index}>
                                    <TableRow sx={{
                        cursor: 'pointer'
                      }} key={index} onClick={() => handleRewardDetailClick(detail.id)} onMouseEnter={() => {
                        setOnDetailsHover(detail.id);
                      }} onMouseLeave={() => {
                        setOnDetailsHover(null);
                      }}>
                                      <TableCell>
                                        <Box sx={{
                            py: '4px'
                          }}>
                                          <Typography variant={expandedRewardDetail == detail.id ? 'tableHeader' : ''}>
                                            {detail.type || <Typography sx={{
                                color: 'BEBEBE'
                              }}>--</Typography>}
                                          </Typography>
                                        </Box>
                                      </TableCell>

                                      <TableCell>
                                        <Typography variant={expandedRewardDetail == detail.id ? 'tableHeader' : ''}>
                                          {detail.value || <Typography sx={{
                              color: 'BEBEBE'
                            }}>--</Typography>}
                                        </Typography>
                                      </TableCell>

                                      <TableCell sx={{
                          py: '0px'
                        }}>
                                        {onDetailsHover == detail.id ? expandedRewardDetail != detail.id ? <Tooltip title="Expand">
                                              <IconButton>
                                                <CarrotDownIcon />
                                              </IconButton>
                                            </Tooltip> : <Tooltip title="Collapse">
                                              <IconButton>
                                                <CarrotUpIcon />
                                              </IconButton>
                                            </Tooltip> : <Box sx={{
                            py: '8px'
                          }}>
                                            <Typography>
                                              {detail?.expires_when ? moment.utc(detail.expires_when).local().format('MM/D/YY h:mm A') : <Typography sx={{
                                color: 'BEBEBE'
                              }}>--</Typography>}
                                            </Typography>
                                          </Box>}
                                      </TableCell>
                                    </TableRow>

                                    {expandedRewardDetail == detail.id && availableModels?.indexOf('reward') > -1 && rewardDetailsFields?.map((field, index) => {
                        const fieldValue = detail[field.id];
                        return fieldValue !== undefined && fieldValue !== null && fieldValue !== '' ? <TableRow sx={{
                          backgroundColor: '#FAFAFA'
                        }} key={field.id + index} onClick={() => handleRewardDetailClick(detail.id)}>
                                            <TableCell>
                                              <Typography variant="tableHeader">{field.name} </Typography>
                                            </TableCell>

                                            <TableCell colSpan={4}>
                                              {field.type === 'datetime' ? moment(fieldValue).local().format('MMM D, YYYY h:mm A') : field.type === 'date' ? moment(fieldValue).format('MMM D, YYYY') : fieldValue}
                                            </TableCell>
                                          </TableRow> : field.id.substring(0, 13) === 'entity_data__' ? <TableRow key={index} sx={{
                          backgroundColor: '#FAFAFA'
                        }} onClick={() => handleRewardDetailClick(detail.id)}>
                                            <TableCell>
                                              <Typography variant="tableHeader">{field.name} </Typography>
                                            </TableCell>
                                            <TableCell colSpan={4}>
                                              {getter(detail, field.id.split('__')) || getter(detail[index], field.id.split('__')) || <Typography sx={{
                              color: 'BEBEBE'
                            }}>--</Typography>}
                                            </TableCell>
                                          </TableRow> : field.id.substring(0, 15) === 'appended_data__' ? <TableRow key={index} sx={{
                          backgroundColor: '#FAFAFA'
                        }} onClick={() => handleRewardDetailClick(detail.id)}>
                                            <TableCell>
                                              <Typography variant="tableHeader">{field.name} </Typography>
                                            </TableCell>
                                            <TableCell colSpan={4}>
                                              {getter(detail[index], field.id.split('__')) || <Typography sx={{
                              color: 'BEBEBE'
                            }}>--</Typography>}
                                            </TableCell>
                                          </TableRow> : <React.Fragment key={field.id} />;
                      })}
                                  </React.Fragment>)}

                              {reward.earned?.length > 4 && <TableRow sx={{
                      cursor: 'pointer'
                    }} onClick={() => setShowMoreDetails(!showMoreDetails)}>
                                  <TableCell colSpan={2} sx={{
                        py: '8px'
                      }}>
                                    <Typography sx={{
                          color: '#1D252D80'
                        }}>
                                      {'... ' + (reward.earned.length - 4) + (showMoreDetails ? ' less items' : ' more items')}
                                    </Typography>
                                  </TableCell>
                                  <TableCell colSpan={3} sx={{
                        py: '8px'
                      }}>
                                    <Box className="d-flex  justify-content-between">
                                      <span />
                                      <Button>
                                        <Stack sx={{
                              display: 'flex',
                              alignItems: 'center'
                            }} direction="row" spacing="10px">
                                          <Typography sx={{
                                color: '#3898D9',
                                textTransform: 'none'
                              }}>
                                            {showMoreDetails ? 'View less' : 'View all'}
                                          </Typography>
                                          {showMoreDetails ? <CarrotUpIcon /> : <CarrotDownIcon />}
                                        </Stack>
                                      </Button>
                                    </Box>
                                  </TableCell>
                                </TableRow>}
                            </TableBody>
                          </Table>}
                      </Paper>
                    </Box>)}
                </Carousel> : <Stack>
                  <Box sx={{
              display: 'flex',
              alignItems: 'center',
              alignContent: 'center',
              justifyContent: 'center',
              height: '100%',
              width: '100%'
            }}>
                    <Typography>Customer has no rewards</Typography>
                  </Box>
                </Stack>}
            </>}
        </Stack>
      </Stack>
    </>;
}