import React, { Component } from 'react';
import { array, string, any, func, bool } from 'prop-types';
import { Scoped, a, m } from 'kremling';
import { isEqual, isNumber } from 'lodash';
import styles from './type-ahead.styles.scss';
import { Button } from '../button/button.component';
import { Icon } from '../icon/icon.component';
export class TypeAhead extends Component {
  constructor(props) {
    super(props);
    this.contentEl = React.createRef();
    this.dropdownEl = React.createRef();
  }
  static propTypes = {
    items: array.isRequired,
    onChange: func.isRequired,
    displayProperty: string,
    keyProperty: string,
    value: any,
    disabled: bool
  };
  state = {
    open: false,
    search: this.props.value || '',
    openAbove: false,
    selectedIndex: 0
  };
  componentDidUpdate(prevProps, prevState) {
    if (this.state.open && !this.state.openAbove) {
      const viewHeight = window.innerHeight;
      const rect = this.dropdownEl.current.getBoundingClientRect();
      if (rect.top + rect.height > viewHeight) {
        this.setState({
          openAbove: rect.top + rect.height > viewHeight
        });
      }
    }
  }
  componentWillUnmount() {
    document.removeEventListener('click', this.handleDocumentClick);
    document.removeEventListener('keydown', this.inputOnKeyDown);
  }
  onFocus = e => {
    e.target.setSelectionRange(0, e.target.value.length);
    document.addEventListener('keydown', this.inputOnKeyDown);
  };
  onBlur = () => {
    if (this.props.allowFreeText && this.props.value !== this.state.search) {
      this.props.onChange(this.state.search);
    }
    if (this.state.open) {
      this.close();
    }
    document.removeEventListener('keydown', this.inputOnKeyDown);
  };
  toggle = selectedItem => {
    const {
      open
    } = this.state;
    if (open && !selectedItem) {
      this.close();
    }
    if (selectedItem) {
      this.clear();
    }
    if (!open) {
      this.open();
    }
  };
  open = () => {
    this.setState({
      open: true
    });
    document.addEventListener('click', this.handleDocumentClick);
  };
  close = () => {
    this.setState({
      open: false,
      openAbove: false
    });
    document.removeEventListener('click', this.handleDocumentClick);
  };
  handleDocumentClick = e => {
    if (!this.contentEl.current.contains(e.target)) {
      this.close();
    }
  };
  onSearch = e => {
    if (!this.state.open) this.open();
    this.setState({
      search: e.target.value,
      selectedIndex: 0
    });
  };
  clear = () => {
    this.props.onChange(null);
    this.setState({
      search: '',
      selectedIndex: 0
    });
  };
  onSelect = item => {
    const {
      displayProperty
    } = this.props;
    this.props.onChange(item);
    this.setState({
      search: displayProperty ? item[displayProperty] : item,
      selectedIndex: 0
    }, this.close);
  };
  inputOnKeyDown = e => {
    const {
      open
    } = this.state;
    if (e.key === 'ArrowDown' && !open) {
      this.open();
    }
  };
  onKeyDown = (e, filteredList) => {
    const {
      selectedIndex,
      open
    } = this.state;
    let next;
    if (e.key === 'ArrowDown' && open) {
      if (selectedIndex === filteredList.length - 1) {
        next = 0;
      } else {
        next = selectedIndex + 1;
      }
    } else if (e.key === 'ArrowUp') {
      if (selectedIndex === 0) {
        next = filteredList.length - 1;
      } else {
        next = selectedIndex - 1;
      }
    } else if (e.key === 'Enter') {
      if (filteredList.length) {
        this.onSelect(filteredList[selectedIndex]);
      } else if (this.props.allowFreeText) {
        this.onSelect(this.state.search);
      }
    }
    if (isNumber(next)) {
      if (this.dropdownEl && this.dropdownEl.current) {
        const dropdownEl = this.dropdownEl.current;
        const el = dropdownEl.querySelector(`ul > li:nth-child(${next + 1})`);
        if (el) {
          const elementHeightFromTop = el.offsetTop;
          const scrolled = dropdownEl.scrollTop;
          const {
            height
          } = dropdownEl.getBoundingClientRect();
          const elHeight = el.getBoundingClientRect().height;
          if (elementHeightFromTop - scrolled + elHeight > height) {
            dropdownEl.scrollTop = elementHeightFromTop - height + elHeight + 5;
          } else if (elementHeightFromTop < scrolled) {
            dropdownEl.scrollTop = elementHeightFromTop - 5;
          }
        }
      }
      this.setState({
        selectedIndex: next
      });
    }
  };
  render() {
    const {
      items,
      displayProperty,
      keyProperty,
      value,
      placeholder
    } = this.props;
    const {
      open,
      openAbove,
      search,
      selectedIndex
    } = this.state;
    const filteredItems = items.filter(item => {
      if (typeof item === 'string') {
        return item.toLowerCase().includes(search.toLowerCase());
      }
      return item[displayProperty].toLowerCase().includes(search.toLowerCase());
    });
    const selectedItem = filteredItems.find(item => isEqual(value, displayProperty ? item[displayProperty] : item));
    return <Scoped css={styles}>
        <div className="type-ahead" ref={this.contentEl}>
          {!!this.props.disabled ? <div className="type-ahead__container">
              <input type="text" className="form-control" value={search} disabled={true} placeholder={placeholder} />
            </div> : <>
              <div className="type-ahead__container">
                <input type="text" className="form-control" onChange={this.onSearch} value={search} onFocus={this.onFocus} onKeyDown={e => open ? this.onKeyDown(e, filteredItems) : null} autoComplete="none" onBlur={this.onBlur} placeholder={placeholder} />
              </div>
              <Button icon={selectedItem ? 'fa-regular-times' : 'fa-solid-caret-down'} actionType="flat" onClick={() => this.toggle(selectedItem)} tabIndex={-1} />
              <div ref={this.dropdownEl} className={a('type-ahead__content').m('type-ahead__content--open', open).m('type-ahead__content--above', openAbove)}>
                <ul className="select-list select-list__controlled">
                  {filteredItems.map((item, i) => <li key={keyProperty ? item[keyProperty] : item} role="option">
                      <a onMouseDown={() => this.onSelect(item)} className={m('active', selectedIndex === i)} onMouseOver={() => this.setState({
                  selectedIndex: i
                })}>
                        <div className="select-list__content">
                          {displayProperty ? item[displayProperty] : item}
                        </div>
                        {isEqual(value, displayProperty ? item[displayProperty] : item) && <Icon className="select-list__selected" size={14} name="fa-regular-check" />}
                      </a>
                    </li>)}
                </ul>
              </div>
            </>}
        </div>
      </Scoped>;
  }
}