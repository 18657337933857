import React from 'react';
function Icon(props) {
  if (props.size == 'sm') {
    return <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" fill="none" viewBox="0 0 20 21">
        <mask id="mask0_124_2557" style={{
        maskType: 'alpha'
      }} width="21" height="21" x="0" y="0" maskUnits="userSpaceOnUse">
          <path fill="#D9D9D9" d="M0 0.257H20V20.257H0z"></path>
        </mask>
        <g mask="url(#mask0_124_2557)">
          <path fill={props.fill || '#1D252D'} d="M3.673 16.507c-.444 0-.82-.154-1.128-.462a1.534 1.534 0 01-.462-1.128v-9.32c0-.444.154-.82.462-1.128a1.534 1.534 0 011.128-.462h12.654c.444 0 .82.154 1.128.462.308.308.462.684.462 1.128v9.32c0 .444-.154.82-.462 1.128a1.534 1.534 0 01-1.128.462H3.673zM10 10.688c.084 0 .16-.012.228-.036a.882.882 0 00.198-.101l5.839-3.735a.57.57 0 00.209-.216.543.543 0 00.045-.327.506.506 0 00-.292-.444.56.56 0 00-.568.02L10 9.466 4.341 5.849a.522.522 0 00-.543-.027.514.514 0 00-.317.45.596.596 0 00.04.327c.043.096.116.169.218.22l5.837 3.733c.063.035.128.067.197.095a.599.599 0 00.227.041z"></path>
        </g>
      </svg>;
  }
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 -960 960 960">
      <path fill={props.fill || '#1D252D'} d="M172.309-180.001q-30.308 0-51.308-21t-21-51.308v-455.382q0-30.308 21-51.308t51.308-21h615.382q30.308 0 51.308 21t21 51.308v455.382q0 30.308-21 51.308t-51.308 21H172.309zM480-457.694l320-204.615L793.846-720 480-520 166.154-720 160-662.309l320 204.615z"></path>
    </svg>;
}
export default Icon;