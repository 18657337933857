import React from 'react';
function Icon(props) {
  if (props.size == 'lg') {
    return <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill={props.fill || '#1D252D'} viewBox="0 -960 960 960">
        <path d="M751.14-100.59q-6.37 0-11.65-2.1-5.29-2.11-10.23-7.05L509.15-329.72q-4.95-4.95-7.18-10.2-2.23-5.26-2.23-11.57 0-6.31 2.23-11.59t7.18-10.23l75.77-75.77q4.95-4.95 10.26-7.18 5.3-2.23 11.67-2.23 6.27 0 11.49 2.23 5.22 2.23 10.43 7.18l220.1 220.11q4.95 4.94 7.05 10.26 2.1 5.32 2.1 11.69 0 6.38-2.1 11.66t-7.05 10.23l-75.77 75.39q-4.95 4.94-10.26 7.05-5.32 2.1-11.7 2.1zm.04-57.23l49.23-49.23-193.62-193.62-49.23 49.23 193.62 193.62zm-543.39 57.43q-6.38 0-11.97-2.2-5.59-2.21-10.54-7.15l-75.15-74.77q-4.95-4.95-7.15-10.59-2.21-5.64-2.21-12t2.21-11.84q2.2-5.47 7.09-10.36l215.34-215.34h84.23L444-479 271.82-651.18h-57l-112.9-112.9 92.39-92.38 112.89 112.9v57l172.18 172.18 125.08-125.08-70.03-70.03 50.62-50.61H483.2l-17.35-16.98 121-121 16.97 16.98v102.23l50.61-50.62 163.59 162.82q14.77 14.5 22.6 33.39 7.84 18.9 7.84 40.02 0 17.11-5.54 33.5-5.54 16.4-15.74 30.02l-83.46-83.47-56.39 56.39-47.25-47.26-195.44 195.44v84.38L229.68-109.68q-4.88 4.88-10.2 7.09-5.32 2.2-11.69 2.2zm.03-57.81L405.9-356.28v-49.23h-49.23L158.59-207.44l49.23 49.24zm0 0l-49.23-49.24 24.88 24.36 24.35 24.88zm543.36.38l49.23-49.23-49.23 49.23z"></path>
      </svg>;
  }
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 -960 960 960">
      <path fill={props.fill || '#1D252D'} d="M746.307-101.001q-7.231 0-13.461-2.308-6.231-2.308-11.846-7.923L519.307-312.54q-5.615-5.615-7.923-11.846-2.307-6.23-2.307-13.461t2.307-13.462q2.308-6.23 7.923-11.846l75.769-75.768q5.615-5.616 11.846-7.923 6.231-2.308 13.462-2.308 7.23 0 13.461 2.308 6.231 2.307 11.846 7.923l201.692 201.692q5.616 5.615 7.923 11.846 2.308 6.231 2.308 13.461 0 7.231-2.308 13.462-2.307 6.231-7.923 11.846l-75.768 75.384q-5.616 5.615-11.846 7.923-6.231 2.308-13.462 2.308zm0-68.46l42.463-42.463-167.771-167.77-42.463 42.462 167.771 167.771zm-533.614 69.075q-7.231 0-13.769-2.615-6.539-2.616-12.154-8.231l-75.153-74.769q-5.616-5.615-8.231-12.153-2.615-6.539-2.615-13.77 0-7.23 2.615-13.653t8.231-12.039L320.54-446.539h84.23L437.232-479 269.924-646.308h-57L101.925-757.307l99.153-99.153 110.999 110.999v57l167.308 167.308 118.308-118.308-58.386-58.385 50.615-50.615H488.076l-22.23-21.846 127.768-127.768 21.846 21.845V-774l50.615-50.615 150.462 149.693q15.846 15.462 23.885 35.231 8.038 19.769 8.038 41.846 0 19.384-6.692 37.345t-19.461 32.654l-83.462-83.462-56.384 56.385-42.385-42.385L446.539-403.77v84.384L238-111.232q-5.615 5.615-11.846 8.231-6.23 2.615-13.461 2.615zm0-69.46l183.462-183.462v-42.463h-42.462L170.23-212.308l42.463 42.462zm0 0l-42.463-42.462 21.539 20.923 20.924 21.539zm533.614.385l42.463-42.463-42.463 42.463z"></path>
    </svg>;
}
export default Icon;