import React, { useEffect, useState, useContext } from 'react';
import { Loader, PageHeaderMui, Icon } from 'components';
import { UserStateContext } from 'context/user-state-context';
import { authenticateConvrrt } from 'shared/common.api';
import CONSTANTS from 'shared/constants';
import Cookies from 'js-cookie';
import { Box } from '@mui/material';
export const LandingPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const {
    asCompany
  } = useContext(UserStateContext);
  useEffect(() => {
    if (!asCompany?.id) {
      return;
    }
    setIsLoading(true);
    authenticateConvrrt({
      company_id: asCompany.id
    }).then(({
      data
    }) => {
      Cookies.set(data.key, data.token, {
        domain: data.domain,
        expires: 365,
        sameSite: 'lax',
        secure: true
      });
      setIsLoading(false);
    });
  }, [asCompany?.id]);
  if (isLoading) return <Loader overlay />;
  return <Box display="flex" flexDirection="column" sx={{
    height: '100vh',
    width: '100%'
  }}>
      <PageHeaderMui type="Landing Pages" icon={<Icon name="custom-marketing" size={34} />} />
      <Box sx={{
      p: '20px',
      backgroundColor: 'var(--custom-background-color, #e4e9f1)',
      height: '100%'
    }}>
        <iframe src={CONSTANTS.ENV === 'PROD' ? 'https://builder.cinch.io' : 'https://builder-uat.cinch.io'} style={{
        width: '100%',
        border: 0,
        flexGrow: 1,
        height: '100% '
      }}></iframe>
      </Box>
    </Box>;
};