import React from 'react';
function Icon(props) {
  return <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" fill="none" viewBox="0 0 25 24">
      <mask id="mask0_1036_5961" style={{
      maskType: 'alpha'
    }} width="25" height="24" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#C2E7FF" d="M0.518 0H24.518V24H0.518z"></path>
      </mask>
      <g mask="url(#mask0_1036_5961)">
        <path fill={props.fill || '#1D252D'} d="M4.826 19.5c-.505 0-.933-.175-1.283-.525a1.745 1.745 0 01-.525-1.283V6.308c0-.505.175-.933.525-1.283.35-.35.778-.525 1.283-.525H20.21c.505 0 .933.175 1.283.525.35.35.525.778.525 1.283v11.384c0 .506-.175.933-.525 1.283-.35.35-.777.525-1.283.525H4.826zm0-1.5h10.442v-3.692H4.518v3.384c0 .077.032.148.096.212a.294.294 0 00.212.096zm11.942 0h3.442a.294.294 0 00.212-.096.294.294 0 00.096-.212V9.115h-3.75V18zm-12.25-5.192h10.75V9.115H4.518v3.693z"></path>
      </g>
    </svg>;
}
export default Icon;