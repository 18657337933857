import React, { useContext, useRef, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Stack } from '@mui/material';
import { ModalDialog } from 'components';
import { FormikTextField, FormikDateTimePicker, FormikAutoComplete } from 'components/mui/formik';
import { snackbarService } from 'components/mui/snackbar/snackbar-service';
import { getRoles, createApiKey } from 'shared/common.api';
import { UserStateContext } from 'context/user-state-context';
const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  role: Yup.string().required('Role is required'),
  expires_when: Yup.date().notRequired()
});
export const ApiKeyModal = ({
  open,
  onClose,
  onSubmit,
  companyId
}) => {
  const {
    asCompany
  } = useContext(UserStateContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const formRef = useRef();
  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };
  const onFormSubmit = (data, {
    setSubmitting
  }) => {
    setIsSubmitting(true);
    if (data.expires_when === '') {
      delete data.expires_when;
    }

    // API call to create API key.
    createApiKey(companyId, data).then(({
      data
    }) => {
      onSubmit(data);
    }).catch(() => {
      snackbarService.popup({
        type: 'error',
        message: 'Unknown error trying to create API key.'
      });
      setSubmitting(false);
      setIsSubmitting(false);
    });
  };
  return <ModalDialog size="md" open={open} title="Create API Key" cancelText="Cancel" onCancel={onClose} onSubmit={handleSubmit} submitDisabled={isSubmitting} allowBackdropClick type="scroll">
      <Formik initialValues={{
      name: '',
      role: '',
      expires_when: ''
    }} validationSchema={validationSchema} validateOnBlur={true} validateOnChange={true} innerRef={formRef} onSubmit={onFormSubmit}>
        {() => <Form>
            <Stack gap="24px" sx={{
          marginTop: '1rem'
        }}>
              <FormikTextField name="name" label="Name" />
              <FormikAutoComplete name="role" label="Role" listResourceRequest={getRoles} resourceRequestParams={{
            company: asCompany.id
          }} optionLabel="display_name" />
              <FormikDateTimePicker name="expires_when" label="Expiration date" disablePast={true} helperText="Optionally specify an expiration date.  Leave blank for no expiration." />
            </Stack>
          </Form>}
      </Formik>
    </ModalDialog>;
};