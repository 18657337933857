import React, { useState, useEffect } from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { InputLabel } from '@mui/material';
import { Field } from 'formik';
import { useFormikContext } from 'formik';
import { Typography, Grid } from '@mui/material';

/**
 * A custom built MUI / formik component for picking days.
 * The available default values for this form are:
 * ```js
 * [1,2,3,4,5,6,7]
 * ```
 *
 * Regardless of value chosen, the value must be in array
 * For example if you want Monday and Saturday, your default value must be:
 * ```js
 * [2, 7]
 * ```
 *
 * Each day representing a day starting from Sunday
 */
export const FormikDayPicker = ({
  name,
  validate,
  label
}) => {
  const [selectedValues, setSelectedValues] = useState();
  const {
    initialValues
  } = useFormikContext();
  useEffect(() => {
    const keyExists = initialValues.hasOwnProperty(name);
    const startingValue = initialValues[name];
    if (!keyExists) {
      console.warn('FormikDayPicker is misconfigured, you must provide the correct name prop');
      setSelectedValues([]);
    } else {
      setSelectedValues(startingValue);
    }
  }, []);
  const handleFormat = async (event, newValues, form) => {
    setSelectedValues(newValues);
    await form.setFieldValue(name, newValues, true);
  };
  return <Field name={name} validate={validate}>
      {({
      field,
      // { name, value, onChange, onBlur }
      form,
      // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
      meta
    }) => <Grid container>
          <Grid item xs={12}>
            <InputLabel>{label}</InputLabel>
            <ToggleButtonGroup style={{
          outlineColor: '#d32f2f'
        }} {...field} value={selectedValues} onChange={async (event, newValues) => {
          await handleFormat(event, newValues, form);
        }} aria-label="text formatting" size="small" color="primary">
              {/** TODO couldnt get this working with classes for some reason, inline styling works for now */}
              <ToggleButton value={1} aria-label="bold" style={meta.touched && meta.error ? {
            outlineColor: 'red',
            outlineWidth: '1px',
            outlineStyle: 'solid',
            margin: '2px'
          } : {}}>
                Sun
              </ToggleButton>
              <ToggleButton value={2} aria-label="bold" style={meta.touched && meta.error ? {
            outlineColor: 'red',
            outlineWidth: '1px',
            outlineStyle: 'solid',
            margin: '2px'
          } : {}}>
                Mon
              </ToggleButton>
              <ToggleButton value={3} aria-label="bold" style={meta.touched && meta.error ? {
            outlineColor: 'red',
            outlineWidth: '1px',
            outlineStyle: 'solid',
            margin: '2px'
          } : {}}>
                Tue
              </ToggleButton>
              <ToggleButton value={4} aria-label="bold" style={meta.touched && meta.error ? {
            outlineColor: 'red',
            outlineWidth: '1px',
            outlineStyle: 'solid',
            margin: '2px'
          } : {}}>
                Wed
              </ToggleButton>
              <ToggleButton value={5} aria-label="bold" style={meta.touched && meta.error ? {
            outlineColor: 'red',
            outlineWidth: '1px',
            outlineStyle: 'solid',
            margin: '2px'
          } : {}}>
                Thu
              </ToggleButton>
              <ToggleButton value={6} aria-label="bold" style={meta.touched && meta.error ? {
            outlineColor: 'red',
            outlineWidth: '1px',
            outlineStyle: 'solid',
            margin: '2px'
          } : {}}>
                Fri
              </ToggleButton>
              <ToggleButton value={7} aria-label="bold" style={meta.touched && meta.error ? {
            outlineColor: 'red',
            outlineWidth: '1px',
            outlineStyle: 'solid',
            margin: '2px'
          } : {}}>
                Sat
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          {meta.touched && meta.error ? <Grid item xs={12} style={{
        marginLeft: '14px',
        marginTop: '5px',
        marginBottom: '0px',
        marginRight: '14px'
      }}>
              <Typography style={{
          color: '#d32f2f'
        }} variant="body1">
                {meta.error}
              </Typography>
            </Grid> : null}
        </Grid>}
    </Field>;
};