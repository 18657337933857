import React from 'react';
export const Select = props => {
  const {
    param,
    update,
    data
  } = props;
  const {
    key,
    options,
    label
  } = param;
  const values = data[key] || [];
  const handleChange = e => {
    update({
      [key]: e.target.value
    });
  };
  return <div className="form-group">
      <label>{label}</label>
      <select className="form-control" value={data[key]} onChange={handleChange}>
        {Object.keys(options).map(key => <option key={key} value={key}>{options[key]}</option>)}
      </select>
    </div>;
};