import React, { useContext, useState, useEffect } from 'react';
import { Icon } from '../../components/icon/icon.component';
import { getMarkets, deleteMarket, createMarket } from '../../shared/common.api';
import { UserStateContext } from 'context/user-state-context';
import { PageHeaderMui, Status } from 'components';
import CssBaseline from '@mui/material/CssBaseline';
import EnhancedTable from 'components/mui/enhanced-table/enhanced-table.component';
import { Box, IconButton, Paper, Stack, Tooltip, Typography, Button, Modal, CircularProgress, TextField, DialogContent, DialogActions, Dialog } from '@mui/material';
import { snackbarService, CloseIcon, MapIcon, EditSquareIcon, DeleteIcon, WarningRedIcon } from 'components/mui';
import { NewButton } from 'components/mui/button/new.component';
import { EditModalMarket } from './edit-modal-market.component';
export const MarketsList = props => {
  const {
    hasPermission,
    asCompany,
    flags
  } = useContext(UserStateContext);
  const [showEditModal, setShowEditModal] = useState();
  const [editErrors, setEditErrors] = useState({});
  const [editData, setEditData] = useState({});
  const [renderIteration, setRenderIteration] = useState(0);
  const [modalContent, setModalContent] = useState(null);
  const [marketName, setMarketName] = useState('');
  const [showCreateNewMarketModal, setShowCreateNewMarketModal] = useState(false);
  const [marketLocations, setMarketLocations] = useState([]);
  const [deleteMarketWarning, setDeleteMarketWarning] = useState(false);
  const [deleteMarketID, setDeleteMarketID] = useState(null);
  const headCells = [{
    key: 'name',
    label: 'Market Name',
    width: '30%',
    displayComponent: ({
      data
    }) => <>{data.name}</>
  }, {
    key: 'location_count',
    label: '# of Locations',
    width: '20%',
    displayComponent: ({
      data
    }) => <>{data.location_count}</>
  }];
  const marketsApiWrapper = () => {
    return params => getMarkets({
      view: 'location_count',
      ...params
    });
  };
  const handleMarketRowClick = row => {
    setEditData(row);
    const modalContent = <EditModalMarket showEditModal={true} setShowEditModal={setShowEditModal} updateData={() => {
      setRenderIteration(renderIteration + 1);
    }} editErrors={editErrors} setEditData={setEditData} editData={row} />;
    setModalContent(modalContent);
    setShowEditModal(true);
  };
  const handleNewMarketClick = () => {
    getMarkets({
      company: asCompany.id,
      limit: 1000,
      ordering: 'name'
    }).then(({
      data
    }) => {
      if (data && data.results) {
        setMarketLocations(data.results);
      }
    }).then(() => {
      setShowCreateNewMarketModal(true);
    });
  };
  return <>
      <CssBaseline />
      <div className="wrapper">
        <PageHeaderMui type={'Markets'} icon={<Icon name="custom-location_on" size={34} />} updateNamePermission={hasPermission('organization.change_location')} />

        {flags && flags['crud-markets-on-locations-page'] && <Stack sx={{
        px: '20px',
        pt: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start'
      }} spacing="15px" direction={'row'}>
            <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
              <Button variant="contained" sx={{
            height: '30px',
            width: '150px',
            backgroundColor: '#fff',
            color: '#1D252D',
            ':hover': {
              backgroundColor: '#DAEFFF'
            }
          }} onClick={() => {
            props.history.push('/locations');
          }}>
                <Box sx={{
              pr: '5px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
                  <Icon name="custom-location_on" size={20} />
                </Box>
                Locations
              </Button>
            </Box>
            <Box>
              <Button variant="contained" sx={{
            height: '30px',
            width: '150px'
          }} onClick={() => {
            //
          }}>
                <Box sx={{
              pr: '5px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
                  <MapIcon fill={'#fff'} />
                </Box>
                Markets
              </Button>
            </Box>
          </Stack>}

        <div className="mui-wrapper">
          <EnhancedTable type="Markets" headCells={headCells} api={marketsApiWrapper()} defaultSort="name" handleRowClick={handleMarketRowClick} renderIteration={renderIteration} actions={<NewButton onClick={e => handleNewMarketClick()} />} hoverActions={({
          data
        }) => <Stack direction="row" gap="10px">
                <Tooltip title="Edit Market Details">
                  <IconButton sx={{
              width: '40px',
              height: '40px',
              textAlign: 'center',
              padding: 0,
              backgroundColor: '#fff',
              ':hover': {
                backgroundColor: '#DAEFFF'
              }
            }} onClick={e => handleMarketRowClick(data)}>
                    <EditSquareIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete Market">
                  <IconButton sx={{
              width: '40px',
              height: '40px',
              textAlign: 'center',
              padding: 0,
              backgroundColor: '#fff',
              ':hover': {
                backgroundColor: '#DAEFFF'
              }
            }} onClick={e => {
              setDeleteMarketWarning(true);
              setDeleteMarketID(data.id);
            }}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Stack>} />
        </div>
      </div>

      {showEditModal && modalContent}

      <Modal sx={{
      height: '100%',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    }} onClose={() => {
      setShowCreateNewMarketModal(false);
    }} open={showCreateNewMarketModal}>
        <Paper sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '312px',
        height: '250px',
        borderRadius: '28px',
        backgroundColor: '#F5F5F5',
        padding: '24px'
      }}>
          <Stack flexDirection="column" spacing={'10px'} sx={{
          width: '100%'
        }}>
            <Box>
              <Typography variant="h5">New Market</Typography>
            </Box>
            <Box>
              <TextField sx={{
              width: '100%',
              height: '56px'
            }} error={!!marketLocations.find(location => location.name.trim().toLowerCase() === marketName.trim().toLowerCase()) || marketName.trim().toLowerCase() === ''} helperText={!!marketLocations.find(location => location.name.trim().toLowerCase() === marketName.trim().toLowerCase()) ? 'Market name already exists' : marketName.trim().toLowerCase() === '' ? 'Market name cannot be empty' : ''} id="outlined-basic-name" label="Market Name" variant="outlined" autoComplete="off" value={marketName} onChange={e => setMarketName(e.target.value)} />
            </Box>
            <Stack direction="row" spacing={2} sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            width: '100%',
            pt: '50px'
          }}>
              <Box>
                <Button variant="contained" sx={{
                width: '90px',
                borderRadius: '28px',
                backgroundColor: '#F3F3F4',
                color: '#53A6D6',
                ':hover': {
                  color: '#fff'
                }
              }} onClick={() => {
                setShowCreateNewMarketModal(false);
              }}>
                  Cancel
                </Button>
              </Box>
              <Box>
                <Button variant="contained" sx={{
                width: '90px',
                borderRadius: '28px',
                backgroundColor: '#53A6D6',
                color: '#fff'
              }} disabled={!!marketLocations.find(location => location.name.trim().toLowerCase() === marketName.trim().toLowerCase()) || marketName.trim().toLowerCase() === ''} onClick={() => {
                try {
                  createMarket({
                    name: marketName,
                    company: asCompany.id
                  }).then(() => {
                    snackbarService.popup({
                      message: `Market ${marketName} has been created successfully.`,
                      type: 'success',
                      delay: 5000,
                      action: <React.Fragment>
                              <IconButton size="small" color="white" onClick={() => snackbarService.close()}>
                                <CloseIcon fontSize="small" />
                              </IconButton>
                            </React.Fragment>
                    });
                    setRenderIteration(renderIteration + 1);
                    setShowCreateNewMarketModal(false);
                  });
                } catch (e) {
                  snackbarService.popup({
                    message: `Error creating market ${marketName}. Incompatabile data.`,
                    type: 'error',
                    delay: 5000,
                    action: <React.Fragment>
                            <IconButton size="small" color="white" onClick={() => snackbarService.close()}>
                              <CloseIcon fontSize="small" />
                            </IconButton>
                          </React.Fragment>
                  });
                }
              }}>
                  Create
                </Button>
              </Box>
            </Stack>
          </Stack>
        </Paper>
      </Modal>

      {/* delete market warning modal */}
      <Dialog open={deleteMarketWarning} onClose={() => setDeleteMarketWarning(false)} PaperProps={{
      sx: {
        borderRadius: '28px',
        height: '275px',
        width: '335px',
        overflow: 'hidden'
      }
    }}>
        <Box>
          <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          pt: '24px'
        }}>
            <WarningRedIcon />
          </Box>
          <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
            <Typography sx={{
            pt: '16px',
            fontSize: '24px'
          }}>Delete Market Warning</Typography>
          </Box>
          <DialogContent>
            <Box>
              <Typography sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'justify'
            }}>
                Are you sure you want to delete this market? Doing so will remove it from any location it is associated with and cannot be
                undone.
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions sx={{
          pr: '20px',
          pt: '25px'
        }}>
            <Button variant="contained" sx={{
            width: '90px',
            borderRadius: '28px',
            backgroundColor: '#F3F3F4',
            color: '#53A6D6',
            ':hover': {
              color: '#fff'
            }
          }} onClick={() => setDeleteMarketWarning(false)}>
              Cancel
            </Button>
            <Button variant="contained" sx={{
            width: '90px',
            borderRadius: '28px',
            backgroundColor: '#53A6D6',
            color: '#fff'
          }} onClick={() => {
            try {
              deleteMarket(deleteMarketID).then(() => {
                setRenderIteration(renderIteration + 1);
                snackbarService.popup({
                  message: `Market ${editData.name} has been deleted successfully.`,
                  type: 'success',
                  delay: 5000,
                  action: <React.Fragment>
                          <IconButton size="small" color="white" onClick={() => snackbarService.close()}>
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        </React.Fragment>
                });
              });
              setDeleteMarketWarning(false);
            } catch (e) {
              snackbarService.popup({
                message: `Error deleting market ${editData.name}. Unable to delete market.`,
                type: 'error',
                delay: 5000,
                action: <React.Fragment>
                        <IconButton size="small" color="white" onClick={() => snackbarService.close()}>
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      </React.Fragment>
              });
            }
          }}>
              Confirm
            </Button>
          </DialogActions>
        </Box>
      </Dialog>

      {/* <Modal
        sx={{
          height: '100%',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
        onClose={() => {
          setDeleteMarketWarning(false);
        }}
        open={deleteMarketWarning}
       >
        <Paper
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '312px',
            height: '250px',
            borderRadius: '28px',
            backgroundColor: '#F5F5F5',
            padding: '18px',
          }}
        >
          <Stack flexDirection="column" spacing={'10px'} sx={{ width: '100%' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <WarningRedIcon />
            </Box>
            <Box>
              <Typography variant="h5">Delete Market</Typography>
            </Box>
            <Box>
              <Typography variant="body1">Are you sure you want to delete this market?</Typography>
            </Box>
            <Stack
              direction="row"
              spacing={2}
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%', pt: '50px' }}
            >
              <Box>
                <Button
                  variant="contained"
                  sx={{
                    width: '90px',
                    borderRadius: '28px',
                    backgroundColor: '#F3F3F4',
                    color: '#53A6D6',
                    ':hover': { color: '#fff' },
                  }}
                  onClick={() => {
                    setDeleteMarketWarning(false);
                  }}
                >
                  Cancel
                </Button>
              </Box>
              <Box>
                <Button
                  variant="contained"
                  sx={{
                    width: '90px',
                    borderRadius: '28px',
                    backgroundColor: '#53A6D6',
                    color: '#fff',
                  }}
                  onClick={() => {
                    deleteMarket(editData.id).then(() => {
                      setRenderIteration(renderIteration + 1);
                      snackbarService.popup({
                        message: `Market ${editData.name} has been deleted successfully.`,
                        type: 'success',
                        delay: 5000,
                        action: (
                          <React.Fragment>
                            <IconButton size="small" color="white" onClick={() => snackbarService.close()}>
                              <CloseIcon fontSize="small" />
                            </IconButton>
                          </React.Fragment>
                        ),
                      });
                    });
                    setDeleteMarketWarning(false);
                  }}
                >
                  Confirm
                </Button>
              </Box>
            </Stack>
          </Stack>
        </Paper>
       </Modal> */}
    </>;
};