import React, { Component } from 'react';
import { Scoped, a } from 'kremling';
import { string, func, oneOfType, node, arrayOf, object, bool } from 'prop-types';
import { debounce } from 'lodash';
import { Transition } from 'react-spring/renderprops';
import moment from 'moment-timezone';
import { Icon } from '../icon/icon.component';
import { Button } from '../button/button.component';
import { Dropdown } from '../dropdown/dropdown.component';
import { Calendar } from '../calendar/calendar.component';
import styles from './page-header.scss';
import { StaticTypeAheadTags } from '../static-type-ahead-tags/static-type-ahead-tags.componet';
import Tooltip from 'rc-tooltip';
export class PageHeader extends Component {
  static propTypes = {
    onSearch: func,
    onFilter: func,
    name: string,
    updateName: func,
    actions: oneOfType([string, node]),
    preactions: oneOfType([string, node]),
    leftactions: oneOfType([string, node]),
    filters: arrayOf(object),
    filterVals: object,
    searchVal: string,
    tags: arrayOf(object),
    filterDrawerOpen: bool,
    hasFolders: bool
  };
  static defaultProps = {
    name: ''
  };
  state = {
    searchVal: '',
    editName: false,
    editVal: '',
    filterVals: null,
    filterDrawerOpen: false,
    tags: [],
    showSearchBarIcon: false,
    showSearchPopDown: false
  };
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions();
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.filterDrawerOpen && prevProps.filterDrawerOpen !== prevState.filterDrawerOpen) {
      this.setState({
        filterDrawerOpen: prevProps.filterDrawerOpen
      });
    }
    if (prevProps.filterVals && prevState.filterVals === null) {
      this.setState({
        filterVals: prevProps.filterVals
      });
    }
    if (prevProps.searchVal && prevState.searchVal === '') {
      this.setState({
        searchVal: prevProps.searchVal
      });
    }
  }
  updateDimensions = () => {
    window.innerWidth > 1455 ? this.setState({
      showSearchBarIcon: false
    }) : this.setState({
      showSearchBarIcon: true
    });
  };
  onInputChange = searchVal => {
    this.setState({
      searchVal
    });
    this.debounceOnChange(searchVal);
  };
  debounceOnChange = debounce(searchVal => {
    this.props.onSearch(searchVal);
  }, 500);
  reset = () => {
    this.setState({
      searchVal: ''
    });
    this.props.onSearch('');
  };
  editName = () => {
    this.setState({
      editName: true,
      editVal: this.props.name
    });
  };
  onEdit = editVal => {
    this.setState({
      editVal
    });
  };
  cancelEdit = () => {
    this.setState({
      editName: false
    });
  };
  saveEdit = () => {
    this.props.updateName(this.state.editVal);
    this.setState({
      editName: false
    });
  };
  filtersOnChange = debounce(filterVals => {
    this.props.onFilter(filterVals);
  }, 500);
  setFilter = (key, val) => {
    this.setState({
      filterVals: {
        ...(this.state.filterVals || {}),
        [key]: val
      }
    }, () => {
      this.filtersOnChange(this.state.filterVals);
    });
  };
  setFilterChecked = (key, val, checked) => {
    const filter = [...(this.state.filterVals && this.state.filterVals[key] || [])];
    if (checked) {
      filter.push(val);
    } else {
      const index = filter.indexOf(val);
      if (index > -1) {
        filter.splice(index, 1);
      }
    }
    this.setState({
      filterVals: {
        ...(this.state.filterVals || {}),
        [key]: [...filter]
      }
    }, () => {
      this.props.onFilter(this.state.filterVals);
    });
  };
  render() {
    const {
      searchVal,
      editName,
      editVal,
      filterVals,
      filterDrawerOpen,
      showSearchBarIcon,
      showSearchPopDown
    } = this.state;
    const {
      name,
      actions,
      preactions,
      onSearch,
      updateName,
      onFilter,
      filters,
      postName,
      tags,
      hasFolders,
      leftactions
    } = this.props;
    return <Scoped css={styles}>
        <div className="page-header">
          <div className='d-flex'>
            <div className="page-header__actions">
              {leftactions ? leftactions : null}
            </div>
          </div>
          <div className="page-header__actions">
            {preactions ? preactions : null}
            {onSearch && !hasFolders || !showSearchBarIcon && onSearch ? <div className="page-header__input">
                <input value={searchVal} className="form-control" placeholder={`Search ${name}`} onChange={e => this.onInputChange(e.target.value)} />
                <Icon name="fa-regular-search" className="page-header__input-icon" size={13} />
                {searchVal ? <Button className="page-header__input-close" icon="fa-regular-times" actionType="flat" small onClick={this.reset} /> : null}
              </div> : null}
            {hasFolders && showSearchBarIcon && onSearch && <div className="page-header__input">
              <Button icon="fa-regular-search" actionType='grey' onClick={() => this.setState({
              showSearchPopDown: !this.state.showSearchPopDown
            })} />
                </div>}
            {showSearchPopDown && <div className="page-header__input" style={{
            position: 'absolute',
            top: '50px',
            right: '50px'
          }}>
                <input value={searchVal} className="form-control" placeholder={`Search ${name}`} onChange={e => this.onInputChange(e.target.value)} onKeyPress={e => {
              if (e.key === 'Enter') {
                this.onInputChange(e.target.value);
                this.setState({
                  showSearchPopDown: false
                });
              }
            }} />
                <Icon name="fa-regular-search" className="page-header__input-icon" size={13} />
                {searchVal ? <Button className="page-header__input-close" icon="fa-regular-times" actionType="flat" small onClick={this.reset} /> : null}
              </div>}
            {onFilter ? <Button icon="fa-regular-filter" actionType={!filterVals ? 'grey' : 'primary'} onClick={() => this.setState({
            filterDrawerOpen: !filterDrawerOpen
          })} /> : null}
            {actions ? actions : null}
          </div>
        </div>
        <div className="drawer-wrapper">
          <Transition items={filterDrawerOpen} from={{
          opacity: 0,
          transform: `translateY(-10rem)`
        }} enter={{
          opacity: 1,
          transform: `translateY(0)`
        }} leave={{
          opacity: 0,
          transform: `translateY(-10rem)`
        }} config={{
          mass: 0.4,
          tension: 120,
          friction: 10
        }}>
            {filterDrawerOpen => filterDrawerOpen && (props => <div style={props} className="drawer">
                  <div className={`drawer__inner`} onMouseDown={e => e.stopPropagation()}>
                    <div className="d-flex drawer__header justify-content-between">
                      <strong>Filters</strong>
                      <Button onClick={() => {
                  this.setState({
                    filterVals: null
                  });
                  this.props.onFilter(null);
                }}>
                        Reset Filters
                      </Button>
                    </div>
                    <div className="d-flex filter-row flex-wrap">
                      {filters && filters.map(filter => {
                  return <div className="d-flex flex-column" key={filter.key}>
                              {filter.type === 'typeahead' && <>
                                  <label>{filter.label}</label>
                                  <StaticTypeAheadTags style={{
                        marginTop: '12px'
                      }} tags={tags} displayProperty="tag" keyProperty="id" tagsSelected={filterVals && filterVals.tags && filterVals.tags.length !== 0 && filterVals.tags} value={null} onChange={tags => {
                        return this.setFilter(filter.key, tags);
                      }} />
                                </>}
                              {filter.type === 'text' && <>
                                  <label>{filter.label}</label>
                                  <input className="form-control" onChange={e => this.setFilter(filter.key, e.target.value)} value={filterVals && filterVals[filter.key] || ''} />
                                </>}
                              {filter.type === 'options' && <>
                                  <label>{filter.label}</label>
                                  <div className="d-flex flex-column">
                                    {filter.options.map(option => <div key={option} className="capitalize">
                                        <input type="checkbox" checked={!!filterVals && !!filterVals[`${filter.key}_in`] && filterVals[`${filter.key}_in`].indexOf(option) > -1} onChange={e => this.setFilterChecked(`${filter.key}_in`, option, e.target.checked)} />
                                        {option}
                                      </div>)}
                                  </div>
                                </>}
                              {filter.type === 'range' && <div className="d-flex flex-column">
                                  <label>{filter.label} After</label>
                                  <Dropdown size="md" allowContentClicks trigger={() => <Button dropdown block>
                                        {filterVals && filterVals[`${filter.key}_after`] ? moment(filterVals[`${filter.key}_after`]).format('LL') : 'Select Date'}
                                      </Button>} content={({
                        close
                      }) => <div className="p-sm">
                                        <Calendar minDate={null} maxDate={new Date()} value={filterVals && filterVals[`${filter.key}_after`] ? moment(filterVals[`${filter.key}_after`]).toDate() : null} onChange={date => {
                          if (date) {
                            this.setFilter(`${filter.key}_after`, moment(date).format('YYYY-MM-DD'));
                          } else {
                            this.setFilter(`${filter.key}_after`, '');
                          }
                          close();
                        }} />
                                      </div>} />
                                  <label className="pt-4">
                                    {filter.label} Before
                                  </label>
                                  <Dropdown size="md" allowContentClicks trigger={() => <Button dropdown block>
                                        {filterVals && filterVals[`${filter.key}_before`] ? moment(filterVals[`${filter.key}_before`]).format('LL') : 'Select Date'}
                                      </Button>} content={({
                        close
                      }) => <div className="p-sm">
                                        <Calendar minDate={null} maxDate={new Date()} value={filterVals && filterVals[`${filter.key}_before`] ? moment(filterVals[`${filter.key}_before`]).toDate() : null} onChange={date => {
                          if (date) {
                            this.setFilter(`${filter.key}_before`, moment(date).format('YYYY-MM-DD'));
                          } else {
                            this.setFilter(`${filter.key}_before`, '');
                          }
                          close();
                        }} />
                                      </div>} />
                                </div>}
                            </div>;
                })}
                    </div>
                  </div>
                </div>)}
          </Transition>
        </div>
      </Scoped>;
  }
}