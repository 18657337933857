import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Tooltip } from '@mui/material';
import { Modal, WarningRedIcon } from 'components/mui';
import moment from 'moment-timezone';
import { isEqual } from 'lodash';
import { getJourneyTestRuns, createJourneyTestRun } from 'shared/common.api';
import { Loader } from 'components';
export const TestJourneyModal = ({
  onClose,
  open,
  revisionID
}) => {
  const [testRuns, setTestRuns] = useState(null);
  useEffect(() => {
    if (testRuns && testRuns.length > 0 && testRuns.find(tr => tr.status === 'pending')) {
      const interval = setInterval(() => {
        getJourneyTestRuns({
          revision: revisionID,
          limit: 5
        }).then(({
          data
        }) => {
          setTestRuns(data.results);
        });
      }, 10000);
      return () => clearInterval(interval);
    }
  }, [testRuns]);
  useEffect(() => {
    if (revisionID) {
      getJourneyTestRuns({
        revision: revisionID,
        limit: 5
      }).then(({
        data
      }) => {
        if (!isEqual(testRuns, data.results)) {
          setTestRuns(data.results);
        }
      });
    }
  }, [revisionID]);
  const createNewTest = () => {
    createJourneyTestRun({
      revision: revisionID
    }).then(({
      data
    }) => {
      setTestRuns([data, ...testRuns].slice(0, 5));
    });
    // TODO auto refresh?
  };
  const viewTestRun = testRunID => {
    window.open(`/test-runs/${testRunID}`, '_blank');
  };
  return <Modal open={open} onClose={onClose} title="Test Journey">
      <p>
        Select a test run to view. If there have been any changes to this journey since the test was run the data may not appear correctly.
      </p>
      <Table aria-label="simple table" sx={{
      marginBottom: '20px'
    }}>
        <TableHead>
          <TableRow>
            <TableCell component="th">Start Date</TableCell>
            <TableCell component="th">Status</TableCell>
            <TableCell>&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {testRuns === null && <TableRow sx={{
          '&:last-child td, &:last-child th': {
            border: 0
          }
        }}>
              <TableCell colSpan={3} scope="row">
                <Loader />
              </TableCell>
            </TableRow>}
          {testRuns && testRuns.length === 0 && <TableRow sx={{
          '&:last-child td, &:last-child th': {
            border: 0
          }
        }}>
              <TableCell colSpan={3} scope="row">
                <em>No test runs</em>
              </TableCell>
            </TableRow>}
          {testRuns && testRuns.map(row => <TableRow key={row.id} sx={{
          '&:last-child td, &:last-child th': {
            border: 0
          }
        }}>
                <TableCell scope="row">{moment(row.created_when).local().format('MMM D, YYYY h:mm A')}</TableCell>
                <TableCell sx={{
            textTransform: 'capitalize'
          }}>{row.status}</TableCell>
                <TableCell>
                  {row.status === 'completed' && <Button variant="contained" color="primary" onClick={() => viewTestRun(row.id)}>
                      View
                    </Button>}
                  {row.status === 'error' && <Tooltip title={row.error_message}>
                      <div>
                        <WarningRedIcon />
                      </div>
                    </Tooltip>}
                </TableCell>
              </TableRow>)}
        </TableBody>
      </Table>
      <Button variant="contained" color="primary" onClick={createNewTest}>
        Run New Test
      </Button>
    </Modal>;
};