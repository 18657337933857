import React from 'react';
function Icon(props) {
  return <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" fill="none" viewBox="0 0 25 24">
      <mask id="mask0_1036_3025" style={{
      maskType: 'alpha'
    }} width="25" height="24" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#C2E7FF" d="M0.518 0H24.518V24H0.518z"></path>
      </mask>
      <g mask="url(#mask0_1036_3025)">
        <path fill={props.fill || '#1D252D'} d="M4.136 20.056a.777.777 0 01-.25-.562c0-.208.084-.396.25-.563l5.975-5.95a.706.706 0 01.287-.213.944.944 0 01.676 0 .706.706 0 01.287.213l3.375 3.35 6.525-7.4a.64.64 0 01.525-.25c.217 0 .4.075.55.225a.712.712 0 01.213.488.703.703 0 01-.188.512l-6.975 7.95a.929.929 0 01-.3.238.78.78 0 01-.35.087 1.04 1.04 0 01-.35-.062.69.69 0 01-.3-.213l-3.35-3.325-5.475 5.475a.872.872 0 01-.563.237.73.73 0 01-.562-.237zm.1-6.85a.481.481 0 01-.425-.25l-.325-.75-.75-.325a.487.487 0 010-.85l.75-.325.325-.75a.482.482 0 01.663-.188.51.51 0 01.187.188l.325.75.75.325c.167.084.25.225.25.425 0 .2-.083.342-.25.425l-.75.325-.325.75a.487.487 0 01-.425.25zm11-2a.482.482 0 01-.425-.25l-.325-.75-.75-.325a.503.503 0 01-.187-.187.482.482 0 01.187-.663l.75-.325.325-.75a.482.482 0 01.662-.188.508.508 0 01.188.188l.325.75.75.325c.1.05.167.113.2.187a.582.582 0 010 .476.402.402 0 01-.2.187l-.75.325-.325.75a.482.482 0 01-.425.25zm-6.5-3a.419.419 0 01-.237-.075.616.616 0 01-.188-.2l-.475-1.075-1.075-.475a.616.616 0 01-.2-.188.419.419 0 010-.475.62.62 0 01.2-.187l1.075-.475.475-1.075a.616.616 0 01.188-.2.419.419 0 01.237-.075c.084 0 .163.025.237.075.076.05.138.117.188.2l.475 1.075 1.075.475c.1.05.171.113.213.187a.488.488 0 010 .475.477.477 0 01-.213.188l-1.075.475-.475 1.075a.616.616 0 01-.188.2.419.419 0 01-.237.075z"></path>
      </g>
    </svg>;
}
export default Icon;