import { sortBy } from 'lodash';
import { CUSTOMER_ORDER, TRANSACTION_COUPON_ORDER, TRANSACTION_DETAIL_ORDER, TRANSACTION_ORDER, SCHEDULE_ORDER } from './consts';
export const getFixed = modelId => {
  if (modelId === 'customer') {
    return CUSTOMER_ORDER;
  } else if (modelId === 'transaction') {
    return TRANSACTION_ORDER;
  } else if (modelId === 'coupon') {
    return TRANSACTION_COUPON_ORDER;
  } else if (modelId === 'transaction_detail') {
    return TRANSACTION_DETAIL_ORDER;
  } else if (modelId === 'schedule') {
    return SCHEDULE_ORDER;
  }
  return [];
};
export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};
export const isFixed = (modelId, fieldId) => {
  return getFixed(modelId).includes(fieldId);
};
export const handleSortFields = (fieldArray, orderArray) => {
  return sortBy(fieldArray, function (item) {
    return orderArray.indexOf(item.id) !== -1 ? orderArray.indexOf(item.id) : fieldArray.length;
  });
};

/**
 *  This function is used to sort the fields based on the fixed order. This keeps the standard fields on the top of the list and the custom fields at the bottom of the list.
 * @param {*} fields is the fields object which contains the various models and their fields (e.g. fields = [{id: 'customer', fields: [{id: 'name', name: 'Name', type: 'string'}]}])
 *
 * @returns
 */
export const sortFields = fields => {
  return fields.map(field => {
    const fixed = getFixed(field.id);
    if (fixed) {
      field.fields = handleSortFields(field.fields, fixed);
    }
    return field;
  });
};

/**
 * This function is used to propogate the location field to the various models
 * @param {*} field is the field to propogate to the various models (e.g. field.id, field.name, field.type)
 * @param {*} fields is the fields object which contains the various models and their fields (e.g. fields = [{id: 'customer', fields: [{id: 'name', name: 'Name', type: 'string'}]}])
 * @param {*} customModel is an optional non-top-level model to propogate the location field to such as market (e.g. customModel = "market", then it will be created as location__market__entity_data)
 * @returns
 */
export const propogateLocationField = (fields, field, customModel) => {
  // Modify the fields object to include the location field in it's various related models

  const customFieldID = customModel ? field.id.replace('location__', '') : null;
  const lastLocationID = customModel ? 'stat_last_location__' + customFieldID : 'stat_last_location__' + field.id;
  // Add to customer model
  const customerModel = fields.find(m => m.id === 'customer');
  if (customerModel && !customerModel.fields.find(f => f.id === lastLocationID)) {
    customerModel.fields.push({
      id: lastLocationID,
      name: 'Last Location ' + field.name,
      type: field.type
    });
  }

  // Add to schedule and transaction models
  const locationID = customModel ? field.id : 'location__' + field.id;
  ['schedule', 'transaction'].forEach(modelID => {
    const model = fields.find(m => m.id === modelID);
    if (model && !model.fields.find(f => f.id === locationID)) {
      model.fields.push({
        id: locationID,
        name: 'Location ' + field.name,
        type: field.type
      });
    }
  });

  // Add to review model
  const locationRefID = customModel ? `location_ref__` + field.id : 'location_ref__location__' + field.id;
  const reviewModel = fields.find(m => m.id === 'review');
  if (reviewModel && !reviewModel.fields.find(f => f.id === locationRefID)) {
    reviewModel.fields.push({
      id: locationRefID,
      name: 'Location ' + field.name,
      type: field.type
    });
  }
};