import React, { Component } from 'react';
import { UserStateContext } from 'context/user-state-context';
import { Button } from '../../../components/button/button.component';
import { getLPStores, addLPStore, deleteLPStore } from '../integrations.resource';
import { toasterService } from '../../../components/toaster/toaster-service';
import { ModalDialog } from '../../../components/modal/modal-dialog.component';
import { Loader } from 'components';
import { Stack } from '@mui/material';
export class IntegrationLinkPOSUpdate extends Component {
  static contextType = UserStateContext;
  static propTypes = {};
  state = {
    loading: true,
    stores: [],
    deleteLinkPOSStore: {},
    isStoreModalOpen: false,
    storeId: null
  };
  componentDidMount() {
    //const onLoad = this.props.initLoad('linkpos');
    this.getLinkPOSData();
    //.finally(() => onLoad());
  }
  getLinkPOSData = () => {
    this.setState({
      loading: true
    });
    return getLPStores({
      company: this.context.asCompany.id
    }).then(({
      results
    }) => {
      this.setState({
        stores: results
      });
      this.setState({
        loading: false
      });
    });
  };
  addLinkPOSStore = storeId => {
    return addLPStore(storeId, this.context.asCompany.id).then(() => toasterService.success('Successfully added store.  Data import will begin shortly and can take several minutes.')).then(() => this.getLinkPOSData()).catch(err => {
      if (err.response.data.store_id) {
        toasterService.error(err.response.data.store_id[0]);
      } else if (err.response.data.company) {
        toasterService.error(err.response.data.company[0]);
      } else {
        toasterService.error('Unknown error trying add store. Please try again.');
      }
    });
  };
  removeLinkPOSStore = storeId => {
    return deleteLPStore(storeId).then(() => toasterService.success('Successfully deleted store')).then(() => this.getLinkPOSData()).catch(() => toasterService.error('Unknown error trying delete store. Please try again.'));
  };
  render() {
    const {
      stores,
      isStoreModalOpen,
      storeId,
      deleteLinkPOSStore,
      loading
    } = this.state;
    return <>
        {loading ? <Loader /> : <div className="integration mb-5">
            <div className="integration__header">
              {this.context.hasPermission('linkpos.add_store') && <Button className="btn" actionType="primary" tag="a" onClick={() => this.setState({
            isStoreModalOpen: true
          })}>
                  Add Store
                </Button>}
            </div>
            <div className="integration__body">
              {stores.map(store => <div className="integration-item" key={store.store_id}>
                  <div className="integration-item__inner">
                    <Stack direction="row" spacing={2}>
                      <div className="integration-title">
                        <strong>{store.location_name}</strong>
                      </div>
                      <div className="integration-actions">
                        {this.context.hasPermission('linkpos.delete_store') && <Button actionType="flat" icon="fa-regular-trash" onClick={e => {
                    e.stopPropagation();
                    this.setState({
                      deleteLinkPOSStore: store
                    });
                  }} />}
                      </div>
                    </Stack>
                  </div>
                </div>)}
            </div>

            <ModalDialog open={!!deleteLinkPOSStore.id} title="Remove store" submitText="Remove" onSubmit={() => this.removeLinkPOSStore(deleteLinkPOSStore.id)} onClose={() => this.setState({
          deleteLinkPOSStore: {}
        })} allowBackdropClick>
              <p>
                <strong>{deleteLinkPOSStore.location_name}</strong>
              </p>
              <p>Are you sure you want to remove this store?</p>
            </ModalDialog>

            <ModalDialog open={isStoreModalOpen} title="Add Store" onSubmit={() => this.addLinkPOSStore(storeId)} onClose={() => this.setState({
          isStoreModalOpen: false
        })} allowBackdropClick>
              <p>Please enter the LinkPOS store ID number to link to your account.</p>
              <p>
                <input required className="form-control" name="store_id" onChange={e => this.setState({
              storeId: e.target.value
            })} />
              </p>
            </ModalDialog>
          </div>}
      </>;
  }
}