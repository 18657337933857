import React, { useContext, useState, useEffect } from 'react';
import { Stack, Box, Typography, Checkbox } from '@mui/material';
import { ModalDialog } from 'components';
import { getCarfaxReviewLocations, bulkUpsertLocationRefs } from 'shared/common.api';
import EnhancedTable from 'components/mui/enhanced-table/enhanced-table.component';
import { UserStateContext } from 'context/user-state-context';
export const IntegrationCarfaxReviewsModal = ({
  open,
  onClose,
  onSubmit
}) => {
  const {
    asCompany
  } = useContext(UserStateContext);
  const [locations, setLocations] = useState([]);
  const [selectedLocationIds, setSelectedLocationIds] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [renderIteration, setRenderIteration] = useState(0);
  useEffect(() => {
    getCarfaxReviewLocations().then(({
      data
    }) => {
      setLocations(data);
      setRenderIteration(renderIteration => renderIteration + 1);
    });
  }, []);
  const getLocations = ({
    offset = 0,
    limit = 20,
    search = ''
  }) => {
    return Promise.resolve({
      data: {
        results: locations.filter(l => {
          if (search === '') {
            return true;
          }
          return l.name.toLowerCase().includes(search.toLowerCase()) || l.address.streetAddress1.toLowerCase().includes(search.toLowerCase()) || l.address.city.toLowerCase().includes(search.toLowerCase()) || l.address.state.toLowerCase().includes(search.toLowerCase()) || l.address.postalCode.toLowerCase().includes(search.toLowerCase());
        }).slice(offset, offset + limit),
        count: locations.length,
        next: offset + limit < locations.length,
        previous: offset > 0
      }
    });
  };
  const handleSubmit = () => {
    setIsSubmitting(true);
    const locationsToUpsert = locations.filter(l => selectedLocationIds.includes(l.id)).map(l => ({
      name: l.name,
      lat: l.coordinates.latitude,
      lon: l.coordinates.longitude,
      address1: l.address.streetAddress1,
      city: l.address.city,
      state: l.address.state,
      zip_code: l.address.postalCode,
      company_id: asCompany.id,
      entity_source: 'carfax_reviews',
      entity_ref: l.id,
      is_active: false
    }));
    bulkUpsertLocationRefs(locationsToUpsert).then(() => {
      onSubmit();
    }).catch(() => {
      snackbarService.popup({
        type: 'error',
        message: 'Unknown error trying to create location refs'
      });
    }).finally(() => {
      setIsSubmitting(false);
    });
  };
  const headCells = [{
    key: 'ref',
    label: 'Carfax Location',
    noSort: true,
    width: '100%',
    displayComponent: ({
      data
    }) => <Stack>
          <Box>
            <strong>{data.name}</strong>
          </Box>
          <Box>
            {data.address.streetAddress1}, {data.address.city}, {data.address.state}, {data.address.postalCode}
          </Box>
        </Stack>
  }, {
    key: 'selected',
    label: '',
    noSort: true,
    displayComponent: ({
      data: row
    }) => <Checkbox color="primary" checked={selectedLocationIds.includes(row.id)} onChange={() => {
      if (selectedLocationIds.includes(row.id)) {
        setSelectedLocationIds(selectedLocationIds.filter(id => id !== row.id));
      } else {
        setSelectedLocationIds([...selectedLocationIds, row.id]);
      }
    }} sx={{
      color: '#1D252D',
      '&.Mui-checked': {
        color: '#53A6D6'
      },
      '& .MuiSvgIcon-root': {
        fontSize: 22
      }
    }} />
  }];
  return <ModalDialog size="xl" open={open} title="Add Carfax Locations" cancelText="Cancel" onCancel={onClose} onSubmit={handleSubmit} submitDisabled={isSubmitting} allowBackdropClick type="scroll">
      <Typography sx={{
      fontSize: '18px',
      lineHeight: '26px',
      padding: '20px 40px'
    }}>
        Select the locations you want to add to the Carfax integration.  This will create a Location Ref for this company and make the Carfax
        review location unavailable for other companies.
      </Typography>

      <EnhancedTable renderIteration={renderIteration} type="Locations" headCells={headCells} api={getLocations} />
    </ModalDialog>;
};