import React from 'react';
import { ModalDialog } from 'components';
import { Loader } from 'components/loader/loader.component';
import { Table, TableRow, TableCell, TableBody } from '@mui/material';
import moment from 'moment-timezone';
import { Scoped } from 'kremling';
import styles from './sample-data-modal.styles.scss';
export const SampleDataModal = ({
  open,
  data,
  onClose,
  model,
  field
}) => {
  return <ModalDialog title="Sample Data" open={open} onClose={onClose} allowBackdropClick cancelText="Done" cancelBtn={true}>
      <Scoped css={styles}>
        <div className="data-wrapper">
          <Table>
            <TableBody>
              <TableRow>
                <TableCell component="th"><strong>Model</strong></TableCell>
                <TableCell>{model?.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th"><strong>Field</strong></TableCell>
                <TableCell>{field?.name}</TableCell>
              </TableRow>

              {!data && <TableRow><TableCell colSpan={2}><Loader /></TableCell></TableRow>}
              {data?.values?.length === 0 && <TableRow><TableCell colSpan={2}>No sample data available.</TableCell></TableRow>}
              {data?.value_type === 'text' && <>
                  <TableRow>
                    <TableCell component="th"><strong>Value</strong></TableCell>
                    <TableCell component="th"><strong>Count</strong></TableCell>
                  </TableRow>
                  {data.values.map((d, index) => <TableRow className="data-row" key={index}>
                      <TableCell>{d.value}</TableCell>
                      <TableCell>{d.count}</TableCell>
                    </TableRow>)}
                </>}
              {data?.value_type === 'number' && <>
                  <TableRow>
                    <TableCell component="th"><strong>Min</strong></TableCell>
                    <TableCell>{data.values[0]}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th"><strong>Max</strong></TableCell>
                    <TableCell>{data.values[1]}</TableCell>
                  </TableRow>
                </>}
              {data?.value_type === 'date' && <>
                  <TableRow>
                    <TableCell component="th"><strong>Min</strong></TableCell>
                    <TableCell>{moment(data.values[0]).local().format('MMM D, YYYY h:mm A')}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th"><strong>Max</strong></TableCell>
                    <TableCell>{moment(data.values[1]).local().format('MMM D, YYYY h:mm A')}</TableCell>
                  </TableRow></>}
            </TableBody>
          </Table>
        </div>
      </Scoped>
    </ModalDialog>;
};