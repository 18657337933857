import React from 'react';
function Icon(props) {
  return <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 18 18">
      <g>
        <mask id="mask0_212_685" style={{
        maskType: 'alpha'
      }} width="18" height="18" x="0" y="0" maskUnits="userSpaceOnUse">
          <path fill="#D9D9D9" d="M0 0H18V18H0z"></path>
        </mask>
        <g mask="url(#mask0_212_685)">
          <path fill={props.fill || '#1D252D'} d="M14.166 15.244h-9.9c-.413 0-.766-.147-1.06-.44a1.444 1.444 0 01-.44-1.06v-9.9L1.34 2.419a.73.73 0 01-.225-.535.73.73 0 01.225-.534.73.73 0 01.534-.225.73.73 0 01.534.225L16.66 15.6a.72.72 0 01.226.525.72.72 0 01-.226.525.73.73 0 01-.534.225.73.73 0 01-.534-.225l-1.425-1.406zm-6.9-6.038l-3 3v1.538h8.4l-1.969-1.969a.76.76 0 01-.487.131.723.723 0 01-.47-.225L7.267 9.206zm-3-3.862v4.725l2.362-2.363-2.362-2.362zm.675-3.6h9.825c.412 0 .765.147 1.06.44.293.294.44.647.44 1.06v9.825l-1.5-1.5V7.18l-2.063 2.325-1.069-1.068 3.132-3.507V3.244H6.44l-1.5-1.5z"></path>
        </g>
      </g>
    </svg>;
}
export default Icon;