import React, { Component } from 'react';
import { UserStateContext } from 'context/user-state-context';
import copy from 'copy-to-clipboard';
import { snackbarService } from 'components/mui/snackbar/snackbar-service';
import CONSTANTS from 'shared/constants';
export class IntegrationContact extends Component {
  static contextType = UserStateContext;
  copyToClipboard() {
    let script = '<script';
    if (CONSTANTS.ENV === 'DEV') {
      script += ' data-env="dev"';
    } else if (CONSTANTS.ENV === 'LOCAL') {
      script += ' data-env="local"';
    }
    script += ` data-token="${this.context.asCompany.id}" src="https://survey.cinch.io/contact/cOyqE2E3.min.js"></script>`;
    if (copy(script)) {
      snackbarService.popup({
        type: 'success',
        message: 'Script copied to clipboard'
      });
    } else {
      snackbarService.popup({
        type: 'error',
        message: 'Unable to copy script to clipboard'
      });
    }
  }
  render() {
    return <div className="integration mb-5">
        <div className="integration__header">
          <div>Contact Form</div>
        </div>
        <div className="integration__body">
          <div className="integration-item" style={{
          padding: '0.8rem 1.2rem'
        }}>
            <p>
              To add a contact form to your website add the following right above your closing <code>&lt;/body&gt;</code> tag:
            </p>

            <pre style={{
            padding: '0.8rem 1.2rem',
            cursor: 'pointer'
          }} onClick={() => this.copyToClipboard()}>
              &lt;script{CONSTANTS.ENV === 'DEV' && ' data-env="dev"'} data-token="{this.context.asCompany.id}" src="https://survey.cinch.io/contact/cOyqE2E3.min.js"&gt;&lt;/script&gt;
            </pre>
          </div>
        </div>
      </div>;
  }
}