import React from 'react';
import { Scoped } from 'kremling';
import styles from './files.styles.scss';
import { PageHeader } from '../../components/page-header/page-header';
import { FileViewer } from '../../components/file-viewer/file-viewer.component';
import { Icon, PageHeaderMui } from 'components';
import { FileViewer2 } from 'components/file-viewer/file-viewer.component-mui';
import { Box, Paper } from '@mui/material';
export function Files() {
  return <Scoped css={styles}>
      <div className="wrapper">
        <PageHeaderMui type="Files" icon={<Icon name="custom-home_storage" size={34} />} />

        <PageHeader name="Files" />
        <div className="wrapper-scroll files-list px-5">
          <p className="notice notice-warning my-4">
            Warning: All files uploaded here are publically available. If they weren't they wouldn't appear in customers's emails.
          </p>
          <Box sx={{
          m: '5px'
        }}>
            <Paper sx={{
            width: '100%'
          }}>
              <Box sx={{
              p: '15px'
            }}>
                <FileViewer2 />
              </Box>
            </Paper>
          </Box>
        </div>
      </div>
    </Scoped>;
}