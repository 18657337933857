import React, { useState, useRef, createContext, createElement, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { Prompt, Alert, Confirm } from 'components/mui';
export const ModalContext = createContext();

/**
 * This is a helper context used to reduce the amount of DOM elements used on a page.  Instead of using DOM
 * elements it uses Promises.
 *
 * Example:
 *
 * const { prompt } = useContext(ModalContextProvider);
 *
 * prompt({
 *   "title": "What's your name?"
 * }).then(name => console.log(`Hellow, ${name}`));
 *
 * This context can be called as many times as necessary and stacks modals on top of each other. One caveat
 * I actually haven't tested multiple Modals and it's possible the Modal backdrops don't end up in the right
 * place and may need fixed if we actually need to stack modals.
 */
export const ModalContextProvider = ({
  children
}) => {
  const [renderIteration, setRenderIteration] = useState(0);
  const modalStack = useRef([]);
  const location = useLocation();
  useEffect(() => {
    return () => {
      // We had a route change so clear out all the modals.
      modalStack.current.forEach(modal => {
        _on(modal.props.key, 'Closed due to navigation', 'reject');
      });
    };
  }, [location]);

  /**
   * Function to handle either reject or resolve.
   *
   * @param {string} key               UUID for modal stack
   * @param {any} response             Response from the modal
   * @param {'reject'|'resolve'} func  Name of function to run
   */
  const _on = (key, response, func) => {
    const modal = modalStack.current.find(i => i.props.key === key);
    if (modal) {
      modal[func](response);
    }
    modalStack.current = modalStack.current.filter(i => i.props.key !== key);
    setRenderIteration(i => i + 1);
  };

  /**
   * When the modal is closed
   *
   * @param {string} key    UUID for modal stack
   * @param {any} response  Response from the modal
   */
  const onClose = (key, response) => {
    _on(key, response, 'reject');
  };

  /**
   * When the modal is submitted
   *
   * @param {string} key    UUID for modal stack
   * @param {any} response  Response from the modal
   */
  const onSubmit = (key, response) => {
    _on(key, response, 'resolve');
  };

  /**
   * Function to launch a modal
   *
   * @param {Componet} component    Modal component
   * @param {ComponentProps} props  Props to pass to the Modal
   *
   * @returns Promise
   */
  const show = (component, props) => {
    if (!props) props = {};
    return new Promise((resolve, reject) => {
      props.key = uuid();
      props.open = true;
      // TODO do we need to handle if there is an onClose/onSubmit prop passed in?
      props.onClose = response => onClose(props.key, response);
      props.onSubmit = response => onSubmit(props.key, response);
      modalStack.current.push({
        resolve,
        reject,
        props,
        component
      });
      setRenderIteration(i => i + 1);
    });
  };

  /**
   * Helper function to open a <Prompt> modal
   *
   * @param {object} props any component props
   *
   * @returns Promise
   */
  const prompt = props => show(Prompt, props);

  /**
   * Helper function to open a <Alert> modal
   *
   * @param {object} props any component props
   *
   * @returns Promise
   */
  const alert = props => show(Alert, props);

  /**
   * Helper function to open a <Confirm> modal
   *
   * @param {object} props any component props
   *
   * @returns Promise
   */
  const confirm = props => show(Confirm, props);
  return <ModalContext.Provider value={{
    prompt,
    alert,
    confirm,
    show
  }}>
      {children}
      {modalStack.current.map(stack => createElement(stack.component, stack.props))}
    </ModalContext.Provider>;
};