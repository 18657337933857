import React, { useEffect } from 'react';
import { Typography, Button, Box, Popper, Paper, Stack, ClickAwayListener } from '@mui/material';
import { CarrotDownIcon, CarrotUpIcon, CloseIcon } from '../icon';
export default function SlimSelect(props) {
  const [selectAnchorEl, setSelectAnchorEl] = React.useState(null);
  const [selectMenuOpen, setSelectMenuOpen] = React.useState(false);
  useEffect(() => {}, []);
  const handleSelectMenuClose = () => {
    setSelectMenuOpen(false);
    setSelectAnchorEl(null);
  };
  const handleSelectMenuOpen = (e, date) => {
    setSelectMenuOpen(true);
    setSelectAnchorEl(e.currentTarget);
  };
  const handleSelectMenuClick = (e, option) => {
    props.onChange(option);
    handleSelectMenuClose();
  };
  return <>
      <Box sx={{
      background: props.background || 'inherit',
      borderRadius: '16px'
    }}>
        <Popper anchorEl={selectAnchorEl} open={selectMenuOpen} sx={{
        zIndex: 1500,
        width: '312px'
      }} onClose={handleSelectMenuClose} placement="bottom">
          <ClickAwayListener onClickAway={handleSelectMenuClose}>
            <Paper elevation={0} sx={{
            borderRadius: '16px',
            filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))'
          }}>
              <Box sx={{
              p: '12px'
            }} className="d-flex h-100 justify-content-between">
                <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                  <Typography variant="tableHeader">{props.title}</Typography>
                </Box>
                <Box sx={{
                cursor: 'pointer'
              }} onClick={e => {
                handleSelectMenuClose();
              }}>
                  <CloseIcon />
                </Box>
              </Box>
              {props?.options?.map(option => <Stack direction="row" sx={{
              cursor: 'pointer',
              p: '12px',
              display: 'flex',
              alignItems: 'center',
              ':hover': {
                backgroundColor: '#F3F3F4'
              }
            }} key={option.key} spacing="12px" onClick={e => {
              handleSelectMenuClick(e, option);
            }}>
                  <Box> {option.icon}</Box>
                  <Box sx={{
                display: 'flex',
                alignContent: 'center'
              }}>
                    <Typography> {option.label}</Typography>
                  </Box>
                </Stack>)}
            </Paper>
          </ClickAwayListener>
        </Popper>

        <Box className="d-flex h-100 justify-content-between" onClick={e => {
        handleSelectMenuOpen(e);
      }} sx={{
        borderRadius: '16px',
        py: '6px',
        px: '4px',
        cursor: 'pointer',
        border: '1px solid #E3E5E7',
        display: 'flex',
        alignItems: 'center'
      }}>
          {!!props.value ? <Stack spacing="12px" sx={{
          display: 'flex',
          alignItems: 'center',
          px: '12px'
        }} direction="row">
              <Box> {props.options.find(option => option.key === props.value)?.icon}</Box>
              <Typography> {props.options.find(option => option.key === props.value)?.label}</Typography>
            </Stack> : <Stack spacing="12px" sx={{
          display: 'flex',
          alignItems: 'center',
          px: '12px'
        }} direction="row">
              <Box> {props?.placeholder?.icon}</Box>
              <Typography> {props?.placeholder?.label}</Typography>
            </Stack>}
          {selectMenuOpen ? <CarrotUpIcon /> : <CarrotDownIcon />}
        </Box>
      </Box>
    </>;
}