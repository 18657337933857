import React, { Component } from 'react';
import { func, object, shape, string, number } from 'prop-types';
import { getCredentialsNoUnwrap, getAudiohookCampaigns } from '../../../integrations/integrations.resource';
import { UserStateContext } from 'context/user-state-context';
import { DynamicTypeAhead } from '../../../../components/dynamic-type-ahead/dynamic-type-ahead.component';
export class ActionAudiohook extends Component {
  static contextType = UserStateContext;
  static propTypes = {
    update: func,
    node: object,
    data: shape({
      credential_id: string,
      audience_id: number,
      audience_name: string
    })
  };
  render() {
    const {
      credential_id,
      audience_id
    } = this.props.data;
    return <div>
        <div className="form-group">
          <label>Account</label>
          <DynamicTypeAhead getItems={getCredentialsNoUnwrap} getItemsFilters={{
          company: this.context.asCompany.id,
          integration: 'audiohook'
        }} placeholder="Choose Audiohook Account" displayProperty="entity_data__label" keyProperty="id" value={credential_id} onChange={credential => this.props.update({
          credential_id: credential ? credential.id : null
        })} />
        </div>
        <div className="form-group">
          <label>Campaign</label>
          {!credential_id ? <input type="text" className="form-control" placeholder="Select Account First" disabled={true} /> : <DynamicTypeAhead getItems={getAudiohookCampaigns} getItemsFilters={{
          credential: credential_id
        }} placeholder="Choose Audiohook Campaign" displayProperty="name" keyProperty="audience_id" value={audience_id} onChange={campaign => this.props.update({
          audience_id: campaign ? campaign.audience_id : null,
          audience_name: campaign ? campaign.name : null
        })} />}
        </div>
      </div>;
  }
}