import React, { useState, useEffect } from 'react';
import { Select, MenuItem, Checkbox, ListItemText, FormControl, InputLabel, OutlinedInput, FormHelperText } from '@mui/material';
import { Field } from 'formik';
import { useFormikContext } from 'formik';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

/**
 * A formik driven component that allows us to unify
 * styling our input fields with Formik `form` Field
 * component API
 *
 * Your passed in options prop must look something like this:
 * ```js
 * [
 *   { value: 10, name: 'Ten' },
 *   { value: 20, name: 'Twenty' },
 *   { value: 30, name: 'Thirty' },
 * ]
 * ```
 * The key `value` in the above array of objects is the actual data in question,
 * and the key `name` is the value the user sees.
 *
 * Your default value (which is defined in formik initialValues) must be an array.
 * An empty array as a default value implies there is nothing to pre-select.
 * The array will be populated as you select values and changed dynamically via the form.
 *
 * If you do expect the form to pre-select values you have selected
 * the array must contain values that exist in the options or they wont render.
 *
 * For example:
 * ```js
 * [10, 20, 50]
 * ```
 *
 * In this case, both 10, and 20 will display as selected values in the dropdown and already chosen.
 * and 50 will not show up OR render as an option as it's not available in the options above.
 *
 * Based off the following:
 * - https://formik.org/docs/api/field#example
 * - https://mui.com/material-ui/react-select/#checkmarks
 */
export function FormikMultipleSelectCheckmarks({
  name,
  label,
  validate,
  options,
  onChange
}) {
  const [actualValues, setActualValues] = useState([]);
  const {
    initialValues
  } = useFormikContext();
  useEffect(() => {
    const keyExists = initialValues.hasOwnProperty(name);
    const startingValues = initialValues[name];
    if (!keyExists) {
      console.warn('FormikMultipleSelectCheckmarks is misconfigured, you must provide the correct name prop');
      setActualValues([]);
    } else {
      const verifiedValues = [];
      for (const data of startingValues) {
        for (const option of options) {
          if (option.value === data) {
            verifiedValues.push(data);
          }
        }
      }
      setActualValues(verifiedValues);
    }
  }, []);

  /**
   * This is not entirely obvious and a bit of a headache, however, event returns
   * all selected values in an array.  In conjunction with events, if you feed values into
   * the "value" prop of <Select />, it will add that value to the event.target.value
   * @param {*} event
   * @param {*} form
   */
  const changeSelection = async (event, child, form) => {
    const selectedValueList = event.target.value;
    setActualValues(selectedValueList);
    await form.setFieldValue(name, selectedValueList, true);
    if (onChange) {
      // mimics mui original onChange
      return onChange(event, child);
    }
  };

  /**
   * Checks the actual value state to see if the selected checkbox value
   * is in the array.  If so, mark as checked.
   * @param {*} selectedValue
   * @returns
   */
  const determineIfChoiceIsSelected = selectedValue => {
    for (const actualValue of actualValues) {
      if (selectedValue === actualValue) {
        return true;
      }
    }
    return false;
  };
  return <Field name={name} validate={validate}>
      {({
      field,
      // { name, value, onChange, onBlur }
      form,
      // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
      meta
    }) => <FormControl fullWidth={true} error={meta.touched && meta.error ? true : false}>
          <InputLabel id="checkbox-label">{label}</InputLabel>
          <Select {...field} labelId="checkbox-label" id="multi-checkbox" multiple value={actualValues} fullWidth={true} onChange={async (event, child) => {
        await changeSelection(event, child, form);
      }} input={<OutlinedInput label="Tag" />} renderValue={listOfSelectedValues => {
        const displayNames = [];
        for (const object of options) {
          if (listOfSelectedValues.includes(object.value)) {
            displayNames.push(object.name);
          }
        }
        return displayNames.join(', ');
      }} MenuProps={MenuProps}>
            {options.map(data => <MenuItem key={data.value} value={data.value}>
                <Checkbox checked={determineIfChoiceIsSelected(data.value)} />
                <ListItemText primary={data.name} />
              </MenuItem>)}
          </Select>
          <FormHelperText>{meta.touched && meta.error}</FormHelperText>
        </FormControl>}
    </Field>;
}