import React, { Component } from 'react';
import { Scoped, m } from 'kremling';
import { object, func, number } from 'prop-types';
import { Button } from '../button/button.component';
import styles from './pagination.scss';
const range = (from, to, step = 1) => {
  let i = from;
  const range = [];
  while (i <= to) {
    range.push(i);
    i += step;
  }
  return range;
};
export class Pagination extends Component {
  static propTypes = {
    onChange: func,
    data: object,
    limit: number
  };
  static defaultProps = {
    data: {},
    limit: 20
  };
  state = {
    page: 0
  };
  componentDidUpdate(prevProps) {
    if (prevProps.data.count !== undefined && prevProps.data.count !== this.props.data.count) {
      this.setState({
        page: 0
      });
      this.change(0);
    }
  }
  change = offset => {
    this.setState({
      page: offset / this.props.limit
    });
    this.props.onChange({
      offset,
      limit: this.props.limit
    });
  };
  skipBlock = offset => {
    const {
      limit,
      data
    } = this.props;
    const {
      count = 0
    } = data;
    const pages = Math.ceil(count / limit);
    let page = offset / limit + 9;
    if (page > pages) {
      page = pages - 1;
      this.setState({
        page
      });
      this.props.onChange({
        offset: page * limit,
        limit
      });
    }
    this.setState({
      page
    });
    this.props.onChange({
      offset: page * limit,
      limit
    });
  };
  backBlock = offset => {
    const {
      limit
    } = this.props;
    let page = offset / limit - 9;
    if (page < 0) {
      page = 0;
      this.setState({
        page: page
      });
      this.props.onChange({
        offset: page * limit,
        limit: limit
      });
    }
    this.setState({
      page: page
    });
    this.props.onChange({
      offset: page * limit,
      limit: limit
    });
  };
  render() {
    const {
      page
    } = this.state;
    const {
      limit,
      data
    } = this.props;
    const {
      count
    } = data;
    const offset = page * limit;
    if (count === undefined || count === null) {
      return null;
    }
    const pages = Math.max(Math.ceil(count / limit), 1);
    const total = Array.from(Array(pages).keys());
    const currentPage = offset / limit;
    let totalShowed;
    if (page + 10 > pages && pages > 10) {
      totalShowed = range(pages - 10, pages - 1);
    } else if (page < 10 && pages > 10) {
      totalShowed = range(0, 9);
    } else if (pages < 10) {
      totalShowed = total;
    } else {
      totalShowed = range(page, page + 9);
    }
    return <Scoped css={styles}>
        <div className="pagination">
          <Button icon="fa-angle-double-left" onClick={() => this.backBlock(offset - limit)} disabled={page < 10} />
          <Button icon="fa-solid-caret-left" onClick={() => this.change(offset - limit)} disabled={page === 0} />
          {totalShowed.map(num => {
          return <Button key={num.toString()} onClick={() => this.change(num * limit)} className={m('active', currentPage === num)}>
                {num + 1}
              </Button>;
        })}
          <Button icon="fa-solid-caret-right" disabled={page === pages - 1} onClick={() => this.change(offset + limit)} />
          <Button icon="fa-angle-double-right" onClick={() => this.skipBlock(offset + limit)} disabled={totalShowed.pop() === pages - 1} />
        </div>
      </Scoped>;
  }
}