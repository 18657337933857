import React from "react";
function Icon() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" fill="none" viewBox="0 0 21 20">
      <mask id="mask0_143_6842" style={{
      maskType: "alpha"
    }} width="21" height="20" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0 0H20V20H0z" transform="matrix(-1 0 0 1 20.73 0)"></path>
      </mask>
      <g mask="url(#mask0_143_6842)">
        <path fill="#53A6D6" d="M14.198 15.87a.64.64 0 00.466-.2.64.64 0 000-.932.64.64 0 00-.466-.2h-4.98c-.92 0-1.718-.283-2.397-.848-.68-.566-1.019-1.29-1.019-2.173 0-.884.34-1.612 1.019-2.184.679-.572 1.478-.858 2.398-.858h5.538l-1.873 1.873a.602.602 0 00-.188.456c.005.175.074.33.209.464a.645.645 0 00.94 0l2.891-2.891a.88.88 0 00.197-.278.748.748 0 000-.582.88.88 0 00-.197-.278l-2.911-2.912a.65.65 0 00-.467-.198.635.635 0 00-.474.198.645.645 0 000 .941l1.873 1.873H9.22c-1.283 0-2.396.414-3.338 1.242-.941.828-1.412 1.866-1.412 3.113 0 1.246.47 2.287 1.412 3.122s2.055 1.253 3.338 1.253h4.979z"></path>
      </g>
    </svg>;
}
export default Icon;