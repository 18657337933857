/**
 * *_ORDER fields are forced at the top of the model and can't be reordered because that are shown in a special way on the customer drawer
 */
export const CUSTOMER_ORDER = ['first_name', 'last_name', 'phone', 'email', 'address1', 'address2', 'city', 'state', 'zip_code'];
export const TRANSACTION_ORDER = ['location__name', 'transaction_when', 'total'];
export const TRANSACTION_DETAIL_ORDER = ['item_type', 'item', 'item_descr', 'quantity', 'price_total'];
export const TRANSACTION_COUPON_ORDER = ['code', 'description', 'discount_amount'];
export const SCHEDULE_ORDER = ['appointment_naive', 'location', 'description', 'status', 'type'];
const handler = {
  get: function (target, name) {
    return target.hasOwnProperty(name) ? target[name] : [];
  }
};
export const ALL_ORDER = new Proxy({
  "customer": CUSTOMER_ORDER,
  "transaction": TRANSACTION_ORDER,
  "customer": TRANSACTION_DETAIL_ORDER,
  "customer": CUSTOMER_ORDER,
  "customer": CUSTOMER_ORDER
}, handler);