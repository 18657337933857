import React, { useContext, useEffect, useState } from 'react';
import { Scoped, k } from 'kremling';
import moment from 'moment-timezone';
import { UserStateContext } from 'context/user-state-context';
import { PageHeader, Button, Dropdown, Status, Pagination, Loader, PageHeaderMui, Icon } from 'components';
import { getSmsBroadcasts, deleteSmsBroadcast } from '../../../shared/common.api';
import { BroadcastSmsModal } from './broadcast-sms-modal.component';
import EnhancedTable from 'components/mui/enhanced-table/enhanced-table.component';
import { Box, IconButton, Stack, Tooltip, Button as MuiButton, Typography } from '@mui/material';
import { DeleteIcon } from 'components/mui';
import { CinchModal } from 'components/mui/modal/form.component';
import BackspaceIcon from '@mui/icons-material/Backspace';
import { NewButton } from 'components/mui/button/new.component';
import { getFiltersFromURL } from 'components/mui/enhanced-table/utils';
import { ToggleFilter } from 'components/mui/filter/toggle.component';
export const BroadcastSmsList = props => {
  const {
    hasPermission,
    asCompany
  } = useContext(UserStateContext);
  const [editBroadcast, setEditBroadcast] = useState(null);
  const [renderIteration, setRenderIteration] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(null);
  React.useEffect(() => {
    if (props.location.viewExistingId) {
      getSmsBroadcasts(asCompany.id).then(({
        data
      }) => {
        const openBroadcast = data.results?.find(b => b.id === props.location.viewExistingId);
        if (openBroadcast) {
          setEditBroadcast(openBroadcast);
        }
      });
    }
  }, []);
  const filterOptions = [{
    component: ToggleFilter,
    label: 'Filter by Id',
    key: 'id',
    defaultVal: 'off',
    hidden: {
      hideFilterButton: true,
      hideChip: false,
      hideInFilterList: true
    }
  }];
  const [filters, setFilters] = React.useState(() => {
    const fils = getFiltersFromURL(filterOptions);
    return fils;
  });
  const deleteBroadcast = broadcast => {
    deleteSmsBroadcast(broadcast.id).then(() => {
      setRenderIteration(r => r + 1);
    });
  };
  const openCreate = () => {
    setEditBroadcast({
      segment: null,
      segment_name: null,
      broadcast_when: null,
      schedule_timezone: moment.tz.guess(),
      drip: false,
      drip_hours: null,
      send_window: false,
      window_start: '09:00:00',
      window_end: '17:00:00',
      window_days: [1, 2, 3, 4, 5],
      window_timezone: moment.tz.guess(),
      drip_timezone: moment.tz.guess(),
      message: '',
      from_phone: null,
      opt_statuses: null,
      create_unique_urls: false,
      media: [],
      distinct_messages: true
    });
  };
  const headCells = [{
    key: 'message',
    label: 'Message',
    width: '12.5%',
    displayComponent: ({
      data: broadcast
    }) => broadcast.message.length < 30 ? <>{broadcast.message}</> : <>{broadcast.message.slice(0, 30)} ...</>
  }, {
    key: 'segment_name',
    label: 'Segment',
    width: '12.5%',
    displayComponent: ({
      data: broadcast
    }) => <>{broadcast.segment_name}</>
  }, {
    key: 'broadcast_when',
    noSort: true,
    label: 'Status',
    width: '12.5%',
    displayComponent: ({
      data: broadcast
    }) => {
      return <Tooltip title={broadcast.created_by_user ? 'Created by ' + broadcast.created_by_first_name + ' ' + broadcast.created_by_last_name : ''}>
            <span>{broadcast.broadcast_when ? <Status status="published">Sent</Status> : <Status status="draft">Scheduled</Status>}</span>
          </Tooltip>;
    }
  }, {
    key: 'broadcast_when',
    label: 'Date',
    width: '12.5%',
    displayComponent: ({
      data: broadcast
    }) => <>{moment(broadcast.broadcast_when || broadcast.schedule_when).format('MMM D, YYYY h:mm A')}</>
  }, {
    key: 'estimated_sends',
    label: 'Est. Sends',
    width: '12.5%',
    displayComponent: ({
      data: broadcast
    }) => <>{broadcast.estimated_sends.toLocaleString('en-US')}</>
  }, {
    key: 'sends',
    label: 'Sends',
    width: '12.5%',
    displayComponent: ({
      data: broadcast
    }) => <>{broadcast.sends.toLocaleString('en-US')}</>
  }, {
    key: 'failures',
    label: 'Failures',
    width: '12.5%',
    displayComponent: ({
      data: broadcast
    }) => <>{broadcast.failures.toLocaleString('en-US')}</>
  }, {
    key: 'tools',
    noSort: true,
    displayComponent: ({
      data: broadcast,
      onHover
    }) => {
      if (broadcast.id === onHover.row) {
        return <Box sx={{
          position: 'absolute'
        }}>
              <Stack direction="row" spacing={1} sx={{
            position: 'absolute',
            right: 0,
            top: '-18px',
            justifyContent: 'flex-end'
          }}>
                {!broadcast.broadcast_when && hasPermission('communication.change_smsbroadcast') && <Tooltip title="Edit Broadcast">
                    <IconButton onClick={e => {
                e.stopPropagation();
                setEditBroadcast(broadcast);
              }}>
                      <Icon name="custom-pencil" fill="#000" size={24} />
                    </IconButton>
                  </Tooltip>}
                {hasPermission('communication.delete_smsbroadcast') && <Tooltip title="Delete Broadcast">
                    <IconButton onClick={e => {
                e.stopPropagation();
                setShowDeleteModal(broadcast);
              }}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>}
              </Stack>
            </Box>;
      }
      return <></>;
    }
  }];
  return <Scoped css={css}>
      <div className="wrapper">
        <PageHeaderMui type="Text Broadcasts" icon={<Icon name="custom-cell_tower" size={34} />} />
        <div className="mui-wrapper">
          <EnhancedTable type="Text Broadcasts" headCells={headCells} api={getSmsBroadcasts} filterOptions={filterOptions} filtersState={[filters, setFilters]} defaultSort={'-updated_when'} handleRowClick={broadcast => {
          setEditBroadcast(broadcast);
        }} renderIteration={renderIteration} actions={hasPermission('communication.add_smsbroadcast') && <NewButton onClick={e => openCreate()} />} />
        </div>

        {editBroadcast && <BroadcastSmsModal broadcast={editBroadcast} disabled={!!editBroadcast.broadcast_when || !hasPermission('communication.change_smsbroadcast')} onSave={() => {
        setEditBroadcast(null);
        setRenderIteration(r => r + 1);
      }} onClose={() => setEditBroadcast(null)} onDelete={() => {
        setEditBroadcast(null);
        setRenderIteration(r => r + 1);
      }} />}
        {!!showDeleteModal && <CinchModal show={showDeleteModal} headerComponent={<Typography>Delete Sms Broadcast</Typography>} contentComponent={<Stack spacing={2}>
                {' '}
                <Typography>Are you sure you want to delete this Sms broadcast?</Typography>
                <Stack>
                  <Typography>
                    {' '}
                    Message:
                    {showDeleteModal.message.length < 30 ? <>{showDeleteModal.message}</> : <>{showDeleteModal.message.slice(0, 30)} ...</>}{' '}
                  </Typography>
                  <Typography> Segment: {showDeleteModal.segment_name}</Typography>
                  <Typography> Send Date: {moment(showDeleteModal.schedule_when).format('MMM D, YYYY h:mm A')}</Typography>
                </Stack>
              </Stack>} footerComponent={<>
                <MuiButton onClick={() => {
          setShowDeleteModal(null);
        }} startIcon={<BackspaceIcon />}>
                  Close
                </MuiButton>
                <MuiButton onClick={() => {
          deleteBroadcast(showDeleteModal);
          setShowDeleteModal(null);
        }} startIcon={<DeleteIcon />}>
                  Delete
                </MuiButton>
              </>} />}
      </div>
    </Scoped>;
};
const css = {
  styles: `[kremling="i18"] body,body[kremling="i18"] {
  --color-primary: #487aae;
  --color-highlight: #e7efff;
  --color-accent: #354052;
  --color-success: #73b56e;
  --color-warning: #f4b707;
  --color-danger: #df5651;
  --color-grey-10: #f9f9f9;
  --color-grey-25: #f3f3f3;
  --color-grey-50: #e9e9e9;
  --color-grey-75: #e3e3e3;
  --color-grey-100: #d3d3d3;
  --color-grey-200: #c3c3c3;
  --color-grey-300: #b3b3b3;
  --color-grey-400: #a3a3a3;
  --color-grey-500: #808080;
  --color-grey-600: #707070;
  --color-grey-700: #606060;
  --color-grey-800: #505050;
  --color-grey-900: #404040;
  --base-font-family: Roboto, Helvetica, Arial, sans-serif;
  --base-font-size: 1.4rem;
  --base-font-color: #404040;
  --base-font-weight: 400;
  --base-line-height: 1.4;
  --box-shadow-1: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.12);
  --box-shadow-2: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-3: 0 10px 20px rgba(0, 0, 0, 0.17), 0 6px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-4: 0 14px 28px rgba(0, 0, 0, 0.13), 0 10px 10px rgba(0, 0, 0, 0.1);
  --box-shadow-5: 0 19px 38px rgba(0, 0, 0, 0.18), 0 15px 12px rgba(0, 0, 0, 0.1);
  --base-border-radius: 0.4rem;
}

[kremling="i18"] .broadcast-list,[kremling="i18"].broadcast-list {
  padding: 2rem;
}

[kremling="i18"] .broadcast-list table button,[kremling="i18"].broadcast-list table button {
  opacity: 0;
}
[kremling="i18"] .broadcast-list table tr:hover button,[kremling="i18"].broadcast-list table tr:hover button {
  opacity: 1;
}

[kremling="i18"] .broadcast-list__address,[kremling="i18"].broadcast-list__address {
  font-size: 12px;
}`,
  id: 'i18',
  namespace: 'kremling'
};