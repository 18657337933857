import React from 'react';
function Icon(props) {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none" viewBox="0 0 24 25">
      <g>
        <mask id="mask0_115_6907" style={{
        maskType: 'alpha'
      }} width="24" height="25" x="0" y="0" maskUnits="userSpaceOnUse">
          <path fill="#D9D9D9" d="M0 0.5H24V24.5H0z"></path>
        </mask>
        <g fill="#1D252D" mask="url(#mask0_115_6907)">
          <path d="M7.935 13.914a2.527 2.527 0 01-1.853-.763 2.516 2.516 0 01-.765-1.85c0-.727.254-1.344.762-1.854a2.516 2.516 0 011.851-.764c.726 0 1.344.254 1.854.762s.764 1.125.764 1.85c0 .727-.254 1.344-.762 1.854a2.516 2.516 0 01-1.851.765zm-5.618 4.711v-.611c0-.284.07-.555.207-.811a1.6 1.6 0 01.57-.613 9.378 9.378 0 012.293-.964 9.665 9.665 0 012.546-.328c.892 0 1.74.11 2.545.328.804.219 1.569.54 2.293.964a1.6 1.6 0 01.57.613c.138.256.207.527.207.81v.612c0 .256-.087.47-.26.644a.875.875 0 01-.644.26H3.221a.874.874 0 01-.644-.26.875.875 0 01-.26-.644zm1.592-.75v.154h8.047v-.154a8.325 8.325 0 00-1.951-.803 7.938 7.938 0 00-4.145 0c-.678.183-1.329.45-1.95.803zm4.024-5.461c.309 0 .572-.11.789-.326.217-.218.326-.481.326-.79 0-.309-.109-.572-.326-.79a1.075 1.075 0 00-.79-.325c-.308 0-.572.108-.789.326-.217.217-.326.48-.326.789 0 .309.109.572.326.79.217.217.48.326.79.326z"></path>
          <path d="M20.372 6.308l-3.708 3.723-1.598-1.598a.73.73 0 00-1.054 0 .718.718 0 00-.228.534c.003.202.08.377.228.525l2.02 2.02c.18.18.391.27.632.27s.452-.09.633-.27l4.144-4.135a.747.747 0 00.228-.535.7.7 0 00-.228-.534.756.756 0 00-1.07 0z"></path>
        </g>
      </g>
    </svg>;
}
export default Icon;