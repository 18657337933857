import React, { useContext, useEffect } from 'react';
import { ModalDialog } from 'components/modal/modal-dialog.component';
import { CustomerDrawerContext } from 'context/customer-drawer-context';
export const EditSegmentSample = ({
  open,
  onClose,
  sampleCustomers
}) => {
  const {
    openCustomerDrawer
  } = useContext(CustomerDrawerContext);
  const viewCustomer = id => {
    //Now the sample will remain open while customer drawer is open
    //setShowSample(false);
    openCustomerDrawer(id);
  };
  return <ModalDialog size="xl" title="Sample Customers" open={open} allowBackdropClick onClose={() => onClose()} cancelText="Done">
      <table className="table-list mb-5">
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Phone</th>
            <th>Email</th>
          </tr>
        </thead>
        <tbody>
          {sampleCustomers?.map((ref, index) => {
          return <tr key={index} onClick={() => viewCustomer(ref.id)}>
                <td>{ref.first_name}</td>
                <td>{ref.last_name}</td>
                <td>{ref.phone}</td>
                <td>{ref.email}</td>
              </tr>;
        })}
        </tbody>
      </table>
    </ModalDialog>;
};