import React, { Component } from 'react';
import { bool, func } from 'prop-types';
import { Modal } from '../../components/modal/modal.component';
import { Button } from '../../components/button/button.component';
export class RepublishModal extends Component {
  static propTypes = {
    open: bool,
    onClose: func,
    onSubmit: func
  };
  state = {
    republishOption: null
  };
  render() {
    const {
      republishOption
    } = this.state;
    const {
      open,
      onSubmit,
      onClose
    } = this.props;
    return <Modal open={open} onClose={onClose} allowBackdropClick>
        <div className="modal__title">Publish Journey Draft</div>
        <div className="modal__body">
          <div className="mb-md">
            What would you like to do with your previously published version
            of this journey?
          </div>
          <div className="form-group form-group__radio">
            <label>
              <input type="radio" name="republish" checked={republishOption === 'close'} onChange={() => this.setState({
              republishOption: 'close'
            })} />
              <div>
                <strong>Close it</strong> - no new customers will enter the
                version of this journey being replaced, customers currently
                in the journey will continue.
              </div>
            </label>
            <label>
              <input type="radio" name="republish" onChange={() => this.setState({
              republishOption: 'stop'
            })} />
              <div>
                <strong>Stop it</strong> - all customers in the journey
                version being replaced will stop immediately and no new
                customers will enter it.
              </div>
            </label>
          </div>
        </div>
        <div className="modal__actions">
          <Button actionType="flat" onClick={onClose}>
            Cancel
          </Button>
          <Button actionType="primary" className="float-right" onClick={() => onSubmit(republishOption)} disabled={!republishOption}>
            Submit
          </Button>
        </div>
      </Modal>;
  }
}