import React, { Component } from 'react';
import { Calendar as ReactCalendar } from 'react-calendar';
import { Scoped } from 'kremling';
import { func, object, string, bool } from 'prop-types';
import { Button } from '../button/button.component';
import styles from './calendar.styles.scss';
export class Calendar extends Component {
  static propTypes = {
    onChange: func,
    minDate: object,
    maxDate: object,
    minDetail: string,
    showNeighboringMonth: bool,
    clearDateButton: bool
  };
  static defaultProps = {
    maxDate: null,
    minDate: new Date(),
    minDetail: 'year',
    showNeighboringMonth: false
  };
  render() {
    return <Scoped css={styles}>
        <div>
          {this.props.clearDateButton ? <Button actionType="primary" onClick={() => {
          this.props.onChange(null);
        }}>
              {' '}
              Clear Date
            </Button> : null}
          <ReactCalendar {...this.props} />
        </div>
      </Scoped>;
  }
}