import React, { useContext, useState, useEffect, createElement } from 'react';
import { Scoped } from 'kremling';
import styles from '../integrations.styles.scss';
import { UserStateContext } from 'context/user-state-context';
import { Button } from '../../../components/button/button.component';
import { getJiffyLubeFranchises, addJiffyLubeFranchise as addJiffyLubeFranchiseAPI, deleteJiffyLubeFranchise as deleteJiffyLubeFranchiseAPI, updateJiffyLubeFranchise as updateJiffyLubeFranchiseAPI } from '../integrations.resource';
import { toasterService } from '../../../components/toaster/toaster-service';
import { ModalDialog } from '../../../components/modal/modal-dialog.component';
import { Loader } from 'components';
import { Stack } from '@mui/material';
export const IntegrationJiffyLubeUpdate = props => {
  const {
    user,
    asCompany,
    hasPermission
  } = useContext(UserStateContext);
  const [franchises, setFranchises] = useState([]);
  const [deleteJiffyLubeFranchise, setDeleteJiffyLubeFranchise] = useState({});
  const [updateJLF, setUpdateJLF] = useState({});
  const [isFranchiseModalOpen, setIsFranchiseModalOpen] = useState(false);
  const [franchiseRef, setFranchiseRef] = useState(null);
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getJiffyLubeData();
  }, []);
  const getJiffyLubeData = () => {
    setLoading(true);
    return getJiffyLubeFranchises({
      company: asCompany.id
    }).then(({
      results
    }) => {
      setFranchises(results);
      setLoading(false);
    });
  };
  const addJiffyLubeFranchise = (franchiseRef, username, password) => {
    return addJiffyLubeFranchiseAPI({
      franchise_ref: franchiseRef,
      username,
      password,
      company: asCompany.id
    }).then(() => toasterService.success('Successfully added franchise.')).then(() => getJiffyLubeData()).catch(err => {
      if (err.response.data.franchise_ref) {
        toasterService.error('Invalid Franchise ID');
      } else if (err.response.data.non_field_errors) {
        toasterService.error(err.response.data.non_field_errors[0]);
      } else {
        toasterService.error('Unknown error trying to add franchise. Please try again.');
      }
    });
  };
  const updateJiffyLubeFranchise = (id, username, password) => {
    return updateJiffyLubeFranchiseAPI(id, username, password).then(() => toasterService.success('Successfully updated franchise.')).then(() => getJiffyLubeData()).catch(err => {
      if (err.response.data.site_id) {
        toasterService.error(err.response.data.site_id[0]);
      } else {
        toasterService.error('Unknown error trying to update franchise. Please try again.');
      }
    });
  };
  const removeJiffyLubeFranchise = franchiseRef => {
    return deleteJiffyLubeFranchiseAPI(franchiseRef).then(() => toasterService.success('Successfully deleted franchise')).then(() => getJiffyLubeData()).catch(() => toasterService.error('Unknown error to trying delete franchise. Please try again.'));
  };
  return <Scoped css={styles}>
      <div className="integration mb-5">
        {loading ? <Loader /> : <>
            <div className="integration__header">
              {hasPermission('jiffylube.add_franchise') && <Button className="btn" actionType="primary" tag="a" onClick={() => {
            setIsFranchiseModalOpen(true);
          }}>
                  Add Franchise
                </Button>}
            </div>
            <div className="integration__body">
              {franchises.map(franchise => <div className="integration-item" key={franchise.franchiseRef}>
                  <div className="integration-item__inner">
                    <Stack direction="row" spacing={2}>
                      <div className="integration-title">{franchise.name}</div>
                      <div className="integration-actions">
                        {hasPermission('jiffylube.change_franchise') && <Button actionType="flat" icon="fa-regular-edit" onClick={e => {
                    e.stopPropagation();
                    setUpdateJLF(franchise);
                  }} />}
                        {hasPermission('jiffylube.delete_franchise') && <Button actionType="flat" icon="fa-regular-trash" onClick={e => {
                    e.stopPropagation();
                    setDeleteJiffyLubeFranchise(franchise);
                  }} />}
                      </div>
                    </Stack>
                  </div>
                </div>)}
            </div>
          </>}

        <ModalDialog open={!!deleteJiffyLubeFranchise.id} title="Remove franchise" submitText="Remove" onSubmit={() => removeJiffyLubeFranchise(deleteJiffyLubeFranchise.id)} onClose={() => {
        setDeleteJiffyLubeFranchise({});
      }} allowBackdropClick>
          <p>
            <strong>{deleteJiffyLubeFranchise.location_name}</strong>
          </p>
          <p>Are you sure you want to remove this franchise?</p>
        </ModalDialog>

        <ModalDialog open={isFranchiseModalOpen} title="Add Franchise" onSubmit={() => {
        addJiffyLubeFranchise(franchiseRef, username, password);
      }} onClose={() => {
        setIsFranchiseModalOpen(false);
      }} allowBackdropClick>
          <p>Please enter your Jiffy Lube information below to link to your account.</p>
          <p>
            <label>Franchise Ref</label>
            <input required className="form-control" name="franchise_ref" onChange={e => {
            setFranchiseRef(e.target.value);
          }} />
          </p>
          <p>
            <label>Username</label>
            <input required className="form-control" name="username" onChange={e => {
            setUsername(e.target.value);
          }} />
          </p>
          <p>
            <label>Password</label>
            <input required className="form-control" name="password" type="password" onChange={e => {
            setPassword(e.target.value);
          }} />
          </p>
        </ModalDialog>

        <ModalDialog open={!!updateJLF.id} title="Update franchise" submitText="Update" onSubmit={() => updateJiffyLubeFranchise(updateJLF.id, username, password)} onClose={() => {
        setUpdateJLF({});
      }} allowBackdropClick>
          <p>Please update your Jiffy Lube information below.</p>
          <p>
            <label>Username</label>
            <input required className="form-control" name="username" defaultValue={updateJLF.username || ''} onChange={e => {
            setUsername(e.target.value);
          }} />
          </p>
          <p>
            <label>Password</label>
            <input required className="form-control" name="password" type="password" onChange={e => {
            setPassword(e.target.value);
          }} />
          </p>
        </ModalDialog>
      </div>
    </Scoped>;
};