import React from 'react';
import { Button, Grid } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import BackspaceIcon from '@mui/icons-material/Backspace';
export const BroadcastEmailModalFooter = ({
  openConfirmation,
  closeModal,
  deleteManagedBroadcast,
  values
}) => {
  return <Grid container style={{
    padding: '20px 24px '
  }}>
      <Grid item>
        {values.id && <Button onClick={deleteManagedBroadcast} color="error" startIcon={<DeleteIcon />}>
            Delete
          </Button>}
      </Grid>
      <Grid item xs>
        <Grid container direction="row-reverse">
          <Button onClick={openConfirmation} startIcon={<SaveAsIcon />}>
            Save
          </Button>
          <Button onClick={closeModal} startIcon={<BackspaceIcon />} style={{
          marginLeft: '1rem'
        }}>
            Cancel
          </Button>
        </Grid>
      </Grid>
    </Grid>;
};