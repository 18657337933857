import { Scoped } from 'kremling';
import React, { useState, useEffect, useRef } from 'react';
import { ChromePicker } from 'react-color';
import styles from './color-picker-round.styles.scss';
import { DashedCircleFilledIcon, DashedCiricleIcon } from 'components/mui';
import { Box, Button, Paper, Stack, Tooltip } from '@mui/material';
export const ColorPickerRound = props => {
  const [showPicker, setShowPicker] = useState(false);
  const ref = useRef(null);
  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShowPicker(false);
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);
  return <Scoped css={styles}>
      <div ref={ref} className="color-wrapper">
        <Tooltip title={props.color ? 'Change Primary Color' : 'Add Primary Color'}>
          <div sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '30px',
          height: '30px'
        }} onClick={() => !props.disabled && setShowPicker(true)}>
            {props.color ? <DashedCircleFilledIcon fill={props.color} /> : <DashedCiricleIcon />}
          </div>
        </Tooltip>

        {showPicker && <div className="color-picker">
            <ChromePicker disableAlpha={true} color={{
          hex: props.color
        }} onChange={color => {
          props.onChange(color.hex);
        }} />
            <Paper elevation={0} style={{
          backgroundColor: '#fff',
          width: '225px',
          height: '30px',
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          borderBottomLeftRadius: '10px',
          borderBottomRightRadius: '10px',
          boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
              <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyItems: 'center'
          }}>
                <Button variant="text" sx={{
              textTransform: 'none'
            }} onClick={e => {
              props.onChange(null);
            }}>
                  Remove
                </Button>
              </Box>
              <Box>
                <Button variant="text" sx={{
              textTransform: 'none'
            }} onClick={() => setShowPicker(false)}>
                  Apply
                </Button>
              </Box>
            </Paper>
          </div>}
      </div>
    </Scoped>;
};