import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import CssBaseline from '@mui/material/CssBaseline';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
export default function NewFormDialog(props) {
  const [value, setValue] = React.useState('webform');
  const formTypes = [{
    key: 'webform',
    label: 'Web Form'
  }, {
    key: 'googleleads',
    label: 'Google Leads'
  }, {
    key: 'facebookleads',
    label: 'Facebook Leads'
  }];
  const handleChange = event => {
    setValue(event.target.value);
  };
  return <>
        <CssBaseline />
        <Dialog PaperProps={{
      style: {
        borderRadius: '28px',
        minWidth: '350px'
      }
    }} open={!!props.open} onClose={() => {
      props.onClose();
    }}>
            <DialogTitle sx={{
        m: 0,
        p: 2,
        fontSize: 'medium'
      }}>
                <IconButton aria-label="close" onClick={e => {
          props.onClose();
        }} sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500]
        }}>
                    <CloseIcon />
                </IconButton>
                <Stack alignItems="center" component="span">
                    <Typography variant="h4" component="span"> Add Form </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent dividers>
                <DialogContentText component="span">
                    <Box component="span">
                        <FormControl>
                            <RadioGroup name="controlled-radio-buttons-group" value={value} onChange={handleChange}>
                                {formTypes.map(form => <FormControlLabel key={form.key} value={form.key} control={<Radio />} label={form.label} />)}
                            </RadioGroup>
                        </FormControl>
                    </Box>
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{
        py: 1
      }}>
                <Button onClick={() => {
          props.onClose();
        }} variant="text" sx={{
          borderRadius: '20px',
          color: 'rgba(29, 37, 45, 0.7)',
          width: '75px',
          textTransform: 'none'
        }}>
                    Cancel
                </Button>
                <Button onClick={() => {
          props.onSubmit(value);
        }} variant="text" sx={{
          mr: 1,
          borderRadius: '20px',
          width: '75px',
          color: '#53A6D6',
          textTransform: 'none'
        }}>
                    Continue
                </Button>
            </DialogActions>
        </Dialog>
    </>;
}