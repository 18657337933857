import React, { Component, createRef, useRef } from 'react';
import { func, object, shape, string } from 'prop-types';
import { RRule } from 'rrule';
import { Scoped, k } from 'kremling';
import { DateTime } from 'luxon';
import moment from 'moment-timezone';
import { List, ListItemButton, ListItemText } from '@mui/material';
import { UserStateContext } from 'context/user-state-context';
import { getSegments } from 'shared/common.api';
import { Dropdown } from 'components/dropdown/dropdown.component';
import { Button } from 'components/button/button.component';
import { Icon } from 'components/icon/icon.component';
import { Calendar } from 'components/calendar/calendar.component';
import { Daily } from './daily.component';
import { Weekly } from './weekly.component';
import { Monthly } from './monthly.component';
import { Quarterly } from './quarterly.component';
import { Annually } from './annually.component';
import { patternOptions, defaultOptions, dateNowOptions, dailyOptions, weeklyOptions, monthlyOptions, quarterlyOptions, annuallyOptions, timeSlots, normalizeRRules, getTime, timeFilter } from './trigger-recurring.utils';
import { TypeAhead } from 'components/type-ahead/type-ahead.component';
import { DynamicTypeAhead } from '../../../../../components/dynamic-type-ahead/dynamic-type-ahead.component';
import { ModalDialog } from '../../../../../components/modal/modal-dialog.component';
import { CustomerIcon, TransactionIcon, VehicleIcon, ShoppingCartIcon, Calendar30DaysIcon } from 'components/mui';
import { Autocomplete, Box, FormControl, InputAdornment, Paper, Stack, TextField, Tooltip, Typography, Button as MUIButton, Dialog, Checkbox, DialogActions, DialogContent, DialogTitle } from '@mui/material';
const local = DateTime.local();
export class TriggerRecurring extends Component {
  static contextType = UserStateContext;
  static propTypes = {
    update: func,
    node: object,
    data: shape({
      segment: string,
      pattern: string,
      rrule: string,
      timezone: string
    })
  };
  state = {
    segmentList: [],
    rrule: null,
    timezone: local.zoneName,
    recurringStaticSegementWarningModal: null,
    filteredTimezones: [{
      value: 'America/New_York',
      label: 'Eastern Standard Time (EST)'
    }, {
      value: 'America/Chicago',
      label: 'Central Standard Time (CST)'
    }, {
      value: 'America/Denver',
      label: 'Mountain Standard Time (MST)'
    }, {
      value: 'America/Los_Angeles',
      label: 'Pacific Standard Time (PST)'
    }, {
      value: 'America/Anchorage',
      label: 'Alaska Standard Time (AKST)'
    }, {
      value: 'Pacific/Honolulu',
      label: 'Hawaii Standard Time (HST)'
    }, {
      value: 'Etc/GMT+4',
      label: 'Atlantic Standard Time (AST)'
    }, {
      value: 'Etc/GMT+7',
      label: 'Arizona'
    }],
    listRef: createRef(),
    timezoneSelected: null,
    segmentInclusion: null,
    segmentNamePlaceholder: 'Choose Segment',
    sessionTypeChangeWarning: false,
    suppressWarning: false
  };
  componentDidMount() {
    if (!this.props.data.rrule) {
      this.props.update({
        rrule: new RRule({
          ...dailyOptions.everyDay,
          ...dateNowOptions
        }).toString()
      });
    }
    if (!this.props.data.timezone) {
      this.props.update({
        timezone: this.state.timezone
      });
    }
    if (!this.state.timezoneSelected) {
      this.setState({
        timezoneSelected: this.state.filteredTimezones.find(timezone => timezone.value === this.props.data.timezone)?.label
      });
    }
    getSegments({
      company: this.context.asCompany.id,
      limit: 600
    }).then(({
      data
    }) => {
      this.setState({
        segmentList: data.results
      });
    });
  }
  static getDerivedStateFromProps(props) {
    const rrule = props.data.rrule ? RRule.fromString(normalizeRRules(props.data.rrule)) : new RRule({
      ...dailyOptions.everyDay,
      ...defaultOptions
    });
    return {
      rrule
    };
  }
  toggleSegment = id => {
    const {
      segment
    } = this.props.data;
    this.props.update({
      segment_id: !segment ? id : segment.id !== id ? id : null
    });
  };
  checkSegmentType = segment => {
    if (!segment.time_dynamic_segment) {
      this.setState({
        recurringStaticSegementWarningModal: true
      });
    }
  };
  setPattern = option => {
    let rruleOptions, patternOption;
    if (option.pattern === 'daily') {
      rruleOptions = dailyOptions.everyDay;
      patternOption = 'everyDay';
    }
    if (option.pattern === 'weekly') {
      rruleOptions = weeklyOptions.everyWeek;
      patternOption = 'everyWeek';
    }
    if (option.pattern === 'monthly') {
      rruleOptions = monthlyOptions.everyMonth;
      patternOption = 'everyMonth';
    }
    if (option.pattern === 'quarterly') {
      rruleOptions = quarterlyOptions.onDay;
      patternOption = 'onDay';
    }
    if (option.pattern === 'annually') {
      rruleOptions = annuallyOptions.everyYear;
      patternOption = 'everyYear';
    }
    this.props.update({
      pattern: option.pattern,
      patternOption,
      rrule: new RRule({
        ...this.state.rrule.options,
        ...rruleOptions
      }).toString()
    });
  };
  update = options => {
    this.props.update({
      ...options,
      rrule: normalizeRRules(new RRule({
        ...this.state.rrule.options,
        ...(options.rrule || {})
      }).toString())
    });
  };
  updateStartDate = date => {
    this.update({
      rrule: {
        dtstart: DateTime.fromJSDate(date).toUTC().toJSDate()
      }
    });
  };
  updateEndDate = date => {
    this.update({
      rrule: {
        until: DateTime.fromJSDate(date).toUTC().toJSDate()
      }
    });
  };
  updateTime = (hour, minute) => {
    this.update({
      rrule: {
        byhour: hour,
        byminute: minute
      }
    });
  };
  toggleUntil = () => {
    let until;
    if (!this.state.rrule.options.until) {
      until = DateTime.fromJSDate(this.state.rrule.options.dtstart);
      until = until.plus({
        day: 1
      });
    }
    this.update({
      rrule: {
        until: until && until.toJSDate()
      }
    });
  };
  toggleCount = () => {
    let count;
    if (!this.state.rrule.options.count) {
      count = '10';
    }
    this.update({
      rrule: {
        count
      }
    });
  };
  getFrequency = () => {
    return patternOptions.find(option => {
      return option.pattern === this.props.data.pattern;
    });
  };
  selectTimezone = (timezone, label) => {
    this.props.update({
      timezone
    });
    this.setState({
      timezoneSelected: label
    });
    this.state.listRef.current.close();
  };
  makeOverflowTooltip = (content, length, bold) => {
    const contentLength = content?.length || content?.props?.children?.length;
    if (content) {
      return <Tooltip title={contentLength > length ? <> {content} </> : ''}>
          <Typography variant={bold ? 'tableHeader' : ''}>
            {contentLength > length ? <>{('' + (content.props?.children || content)).slice(0, length) + '...'}</> : <>{content}</>}
          </Typography>
        </Tooltip>;
    }
    return <Typography variant="nullData" color={'#BEBEBE'}>
        <span>--</span>{' '}
      </Typography>;
  };
  getIcon = included => {
    if (included === 'possession') {
      return <VehicleIcon />;
    } else if (included === 'transaction') {
      return <TransactionIcon />;
    } else if (included === 'schedule') {
      return <Calendar30DaysIcon />;
    } else if (included === 'cart') {
      return <ShoppingCartIcon />;
    } else {
      return <CustomerIcon />;
    }
  };
  toggleSessionTypeChangeWarning = subType => {
    if (subType) {
      this.setState({
        sessionTypeChangeWarning: false
      });
    }
    const existingData = JSON.parse(localStorage.getItem('sessionDataChangeWarning'));
    let sessionData = existingData ? existingData : {};
    if (subType) {
      sessionData = {
        ...sessionData,
        [subType]: true
      };
    }
    if (this.state.suppressWarning) {
      localStorage.setItem('sessionDataChangeWarning', JSON.stringify(sessionData));
    }
  };
  render() {
    const {
      data
    } = this.props;
    const {
      segmentList,
      rrule,
      timezone
    } = this.state;
    const selectedSegment = segmentList.find(segment => segment.id === data.segment_id);
    const dtstart = DateTime.fromJSDate(rrule.options.dtstart);
    const until = rrule.options.until && DateTime.fromJSDate(rrule.options.until);
    const segmentInclusion = selectedSegment ? selectedSegment.included : null;
    const segmentNamePlaceholder = selectedSegment ? selectedSegment.name : 'Choose Segment';
    const {
      getIcon
    } = this;
    const {
      sessionTypeChangeWarning
    } = this.state;
    const {
      suppressWarning
    } = this.state;
    return <Scoped css={css}>
        <div>
          {this.state.sessionTypeChangeWarning ? <Dialog sx={{
          '& .MuiDialog-paper': {
            maxWidth: '600px',
            width: '100%',
            borderRadius: '24px',
            padding: '12px'
          }
        }} open={sessionTypeChangeWarning} onClose={() => {
          this.setState({
            sessionTypeChangeWarning: false
          });
          this.setState({
            suppressWarning: false
          });
        }} id="session-type-change-warning">
              <DialogTitle>
                <Stack direction="row" spacing={1}>
                  <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
                    {selectedSegment.included.includes('transaction') ? <TransactionIcon /> : selectedSegment.included.includes('possession') ? <VehicleIcon /> : selectedSegment.included.includes('review') ? <ReviewIcon /> : selectedSegment.included.includes('cart') ? <ShoppingCartIcon /> : selectedSegment.included.includes('survey') ? <SurveysIcon /> : selectedSegment.included.includes('schedule') ? <Calendar30DaysIcon /> : <CustomerIcon />}
                  </Box>
                  <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
                    <Typography>{` ${selectedSegment.included === 'possession' ? 'Vehicle' : selectedSegment.included ? selectedSegment.included.charAt(0).toUpperCase() + selectedSegment.included.slice(1) : 'Customer'} Journey Session`}</Typography>
                  </Box>
                </Stack>
              </DialogTitle>
              <DialogContent>
                <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
                  <Typography>
                    {`
                  ${selectedSegment.included === 'possession' ? 'Vehicle' : selectedSegment.included ? selectedSegment.included.charAt(0).toUpperCase() + selectedSegment.included.slice(1) : 'Customer'} data is now available for customers who enter the journey through this trigger. You can filter on this specific customer ${selectedSegment.included === 'possession' ? 'Vehicle' : selectedSegment.included ? selectedSegment.included.charAt(0).toUpperCase() + selectedSegment.included.slice(1) : 'Customer'} data in your Check Data Field tiles or use this ${selectedSegment.included === 'possession' ? 'Vehicle' : selectedSegment.included ? selectedSegment.included.charAt(0).toUpperCase() + selectedSegment.included.slice(1) : 'Customer'} information in your communications (email, text messages, or mail).
                    `}
                  </Typography>
                </Box>
              </DialogContent>
              <DialogActions>
                <Stack direction="row" spacing={1}>
                  <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                pr: '209px'
              }}>
                    <Checkbox checked={suppressWarning} onChange={() => {
                  this.setState({
                    suppressWarning: !this.state.suppressWarning
                  });
                }} sx={{
                  color: '#53A6D6'
                }} />{' '}
                    <Typography sx={{
                  cursor: 'default'
                }}>{`Don't show this warning again`}</Typography>
                  </Box>
                </Stack>
                <Stack direction="row" spacing={3}>
                  <MUIButton variant="contained" sx={{
                width: '100px'
              }} onClick={() => {
                this.toggleSessionTypeChangeWarning('trigger_recurring', this.state.suppressWarning);
              }} autoFocus>
                    Okay
                  </MUIButton>
                </Stack>
              </DialogActions>
            </Dialog> : <>
              <div className="form-group">
                <Paper sx={{
              width: selectedSegment?.time_dynamic_segment ? '128px' : '107px',
              height: '19px',
              pl: '10px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              position: 'absolute',
              marginTop: '-16px',
              zIndex: 1,
              boxShadow: 'none'
            }}>
                  <Typography sx={{
                position: 'absolute',
                marginTop: '10px',
                opacity: 0.5,
                '&.Mui-focused': {
                  opacity: 1
                },
                fontSize: '12px'
              }} id="segment-select-label">
                    {selectedSegment?.time_dynamic_segment ? 'Dynamic ' : 'Static '}Segment
                  </Typography>
                </Paper>
                <FormControl variant="outlined" sx={{
              width: '315px'
            }}>
                  <Autocomplete id="choose-segment-select" options={segmentList} getOptionLabel={option => option.name} value={selectedSegment || null} onChange={(event, newValue) => {
                if (!newValue) {
                  this.toggleSegment(null);
                  return;
                }
                const segment = segmentList.find(segment => segment.name === newValue.name);
                this.toggleSegment(segment ? segment.id : null);
                const sessionDataChangeWarning = JSON.parse(localStorage.getItem('sessionDataChangeWarning'));
                if (segment.included !== null && !sessionDataChangeWarning?.trigger_recurring) this.setState({
                  sessionTypeChangeWarning: true
                });
              }} filterOptions={(options, {
                inputValue
              }) => {
                return options.filter(option => option.name.toLowerCase().includes(inputValue.toLowerCase()));
              }} renderInput={params => <TextField {...params} label="" placeholder={selectedSegment ? `${selectedSegment.name}` : 'Choose Segment'} InputProps={{
                ...params.InputProps,
                startAdornment: <InputAdornment position="start">{getIcon(selectedSegment?.included)}</InputAdornment>
              }} value={selectedSegment ? selectedSegment.name : ''} />} renderOption={(props, option, {
                index
              }) => <Box component="li" {...props} key={option.name + index} sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px'
              }}>
                        {getIcon(option.included)}
                        {this.makeOverflowTooltip(option.name, 30)}
                      </Box>} />
                </FormControl>
              </div>
              <Stack direction={'column'} spacing={2} sx={{
            pb: '25px',
            pl: '24px',
            opacity: 0.7
          }}>
                <Typography>Segment Type: {selectedSegment?.time_dynamic_segment ? 'Dynamic' : 'Static'}</Typography>

                <Typography>
                  {'Segment Inclusion: '}

                  {segmentInclusion === 'possession' ? 'Vehicle' : segmentInclusion ? segmentInclusion.charAt(0).toUpperCase() + segmentInclusion.slice(1) : segmentNamePlaceholder === 'Choose Segment' ? 'None' : 'Customer Only'}
                </Typography>
              </Stack>
              <div className="recurring-divider" />
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label>Start Date</label>
                    <Dropdown size="md" allowContentClicks trigger={() => <Button block dropdown>
                          {dtstart.toFormat('LL/dd/yy')}
                        </Button>} content={({
                  close
                }) => <div className="p-sm">
                          <Calendar onChange={date => {
                    this.updateStartDate(date);
                    close();
                  }} value={dtstart.toJSDate()} />
                        </div>} />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label>
                      <input type="checkbox" checked={!!until} onChange={this.toggleUntil} />
                      End Date
                    </label>
                    {!!until ? <Dropdown size="md" allowContentClicks trigger={() => <Button block dropdown>
                            {until.toFormat('LL/dd/yy')}
                          </Button>} content={({
                  close
                }) => <div className="p-sm">
                            <Calendar onChange={date => {
                    this.updateEndDate(date);
                    close();
                  }} minDate={dtstart.toJSDate()} value={until.toJSDate()} />
                          </div>} /> : <Button disabled block dropdown />}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label>Start Time</label>
                    <Dropdown size="block" contentHeight={280} trigger={() => <Button block dropdown disabled={!dtstart}>
                          {dtstart ? getTime(rrule.options.byhour[0], rrule.options.byminute[0]) : null}
                        </Button>} content={() => <ul className="select-list">
                          {timeSlots.map(slot => {
                    return timeFilter(slot, dtstart.toJSDate()) && <li key={slot.time}>
                                  <a onClick={() => this.updateTime(slot.hour, slot.minute)}>{slot.time}</a>
                                </li>;
                  })}
                        </ul>} />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label>
                      <input type="checkbox" checked={!!rrule.options.count} onChange={this.toggleCount} /># Occurrences
                    </label>
                    <input type="number" className="form-control" disabled={!rrule.options.count} value={rrule.options.count || ''} onChange={e => this.update({
                  rrule: {
                    count: parseInt(e.target.value) < 1 ? 1 : e.target.value
                  }
                })} />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-11">
                  <div className="form-group">
                    <label>
                      Select Timezone <span className="form-error">*</span>
                    </label>
                    <Dropdown size="md" ref={this.state.listRef} contentHeight={280} trigger={() => <Button block dropdown>
                          {this.state.timezoneSelected || 'Select Timezone'}
                        </Button>} content={() => <List sx={{
                  height: '100%',
                  width: '100%'
                }}>
                          {this.state.filteredTimezones.map(timezone => <ListItemButton sx={{
                    height: '100%',
                    width: '100%'
                  }} key={timezone.value} onClick={() => this.selectTimezone(timezone.value, timezone.label)}>
                              <ListItemText primary={timezone.label} />
                            </ListItemButton>)}
                        </List>}></Dropdown>
                    {/* <TypeAhead items={moment.tz.names()} onChange={this.selectTimezone} value={timezone} /> */}
                  </div>
                </div>
              </div>
              <div className="recurring-divider" />
              <div className="form-group">
                <label>Recurrence</label>
                <Dropdown size="block" trigger={() => <Button dropdown block>
                      {this.getFrequency().name}
                    </Button>} content={() => <ul className="select-list">
                      {patternOptions.map(option => <li key={option.name}>
                          <a onClick={() => this.setPattern(option)}>
                            <div className="select-list__content">{option.name}</div>
                            {this.getFrequency().name === option.name && <Icon className="select-list__selected" size={14} name="fa-regular-check" />}
                          </a>
                        </li>)}
                    </ul>} />
              </div>
            </>}

          {this.getFrequency().pattern === 'daily' && <Daily update={this.update} data={data} rrule={rrule} />}

          {this.getFrequency().pattern === 'weekly' && <Weekly update={this.update} data={data} rrule={rrule} />}

          {this.getFrequency().pattern === 'monthly' && <Monthly update={this.update} rrule={rrule} data={data} />}

          {this.getFrequency().pattern === 'quarterly' && <Quarterly update={this.update} rrule={rrule} data={data} />}

          {this.getFrequency().pattern === 'annually' && <Annually update={this.update} rrule={rrule} data={data} />}
        </div>
        <ModalDialog open={!!this.state.recurringStaticSegementWarningModal} onClose={() => {
        this.setState({
          recurringStaticSegementWarningModal: null
        });
      }} closeBtn={false} onSubmit={() => {
        this.setState({
          recurringStaticSegementWarningModal: null
        });
      }} onCancel={() => {
        window.location.href = 'mailto:support@cinch.io?subject=Recurring Static Segment Warning';
      }} title="Recurring Static Segment Warning Help" submitText="Okay" cancelText="Contact Us">
          <div className="pt-5">
            Warning: You have selected to use a STATIC segment with a recurring trigger. Most of the time, you'll want to use a DYNAMIC
            segment for a recurring trigger. <p />
            If you're certain you want to use a static segment, you can dismiss this message. If you're uncertain, please contact
            support@cinch.io and we will help you make sure you're using the correct segment. <p />
          </div>
        </ModalDialog>
      </Scoped>;
  }
}
const css = {
  styles: `[kremling="i33"] body,body[kremling="i33"] {
  --color-primary: #487aae;
  --color-highlight: #e7efff;
  --color-accent: #354052;
  --color-success: #73b56e;
  --color-warning: #f4b707;
  --color-danger: #df5651;
  --color-grey-10: #f9f9f9;
  --color-grey-25: #f3f3f3;
  --color-grey-50: #e9e9e9;
  --color-grey-75: #e3e3e3;
  --color-grey-100: #d3d3d3;
  --color-grey-200: #c3c3c3;
  --color-grey-300: #b3b3b3;
  --color-grey-400: #a3a3a3;
  --color-grey-500: #808080;
  --color-grey-600: #707070;
  --color-grey-700: #606060;
  --color-grey-800: #505050;
  --color-grey-900: #404040;
  --base-font-family: Roboto, Helvetica, Arial, sans-serif;
  --base-font-size: 1.4rem;
  --base-font-color: #404040;
  --base-font-weight: 400;
  --base-line-height: 1.4;
  --box-shadow-1: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.12);
  --box-shadow-2: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-3: 0 10px 20px rgba(0, 0, 0, 0.17), 0 6px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-4: 0 14px 28px rgba(0, 0, 0, 0.13), 0 10px 10px rgba(0, 0, 0, 0.1);
  --box-shadow-5: 0 19px 38px rgba(0, 0, 0, 0.18), 0 15px 12px rgba(0, 0, 0, 0.1);
  --base-border-radius: 0.4rem;
}

[kremling="i33"] .recurring-divider,[kremling="i33"].recurring-divider {
  padding-top: 0.4rem;
  margin-bottom: 1.6rem;
  border-bottom: solid 1px #d3d3d3;
  margin-left: -1.6rem;
  margin-right: -1.6rem;
}

[kremling="i33"] .recurring-inline-with-text,[kremling="i33"].recurring-inline-with-text {
  margin-left: 0.8rem;
  margin-right: 0.8rem;
  width: 6.2rem;
}

[kremling="i33"] .recurring-inline-with-selection,[kremling="i33"].recurring-inline-with-selection {
  margin-right: 0.8rem;
  display: inline-block;
  width: auto;
  margin-bottom: 0.8rem;
}

[kremling="i33"] .recurring-inline-days input,[kremling="i33"].recurring-inline-days input {
  margin-right: 0;
}

[kremling="i33"] .recurring-inline-days label,[kremling="i33"].recurring-inline-days label {
  width: 6rem;
}

[kremling="i33"] .recurring-option,[kremling="i33"].recurring-option {
  border-radius: 0.4rem;
  border: solid 1px #d3d3d3;
  padding: 0.8rem 0.8rem 0.8rem 1.2rem;
  margin-bottom: 0.8rem;
  user-select: none;
  text-transform: uppercase;
  color: #808080;
  font-size: 12px;
  font-weight: 500;
  display: block;
}
[kremling="i33"] .recurring-option input,[kremling="i33"] .recurring-option select,[kremling="i33"].recurring-option input,[kremling="i33"].recurring-option select {
  font-weight: 500;
  color: #808080;
  font-size: 1.2rem;
  text-transform: uppercase;
}
[kremling="i33"] .recurring-option input[type=radio],[kremling="i33"].recurring-option input[type=radio] {
  margin-right: 1.2rem;
}
[kremling="i33"] .recurring-option.recurring-option--active,[kremling="i33"].recurring-option.recurring-option--active {
  border-color: #487aae;
}`,
  id: 'i33',
  namespace: 'kremling'
};