import React, { Component } from 'react';
import { UserStateContext } from 'context/user-state-context';
import { Button } from '../../components/button/button.component';
export class IntegrationZapier extends Component {
  static contextType = UserStateContext;
  render() {
    return <div className="integration mb-5">
        <div className="integration__header">
          <div>Zapier</div>
          {this.context.hasPermission('zapier.add_subscription') && <Button className="btn" actionType="primary" tag="a" href="https://zapier.com/developer/public-invite/111066/4c461c9ac966553a8802a78598364d44/" target="_blank">
              Go to Cinch Zapier App
            </Button>}
        </div>
        <div className="integration__body"></div>
      </div>;
  }
}