import React, { Component, useRef, useEffect, useState, useCallback } from 'react';
import { array, string, any, func, bool } from 'prop-types';
import { Scoped, a, m } from 'kremling';
import { isEqual, isNumber, set } from 'lodash';
import styles from './static-type-ahead.styles.scss';
import { Button } from '../button/button.component';
import { Icon } from '../icon/icon.component';
export const StaticTypeAhead = props => {
  const contentEl = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenAbove, setIsOpenAbove] = useState(false);
  const [search, setSearch] = useState(props.value || '');
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [items, setItems] = useState([]);
  const handleDocumentClick = useCallback(e => {
    if (contentEl.current && !contentEl.current.contains(e.target)) {
      close();
    }
  }, []);
  useEffect(() => {
    return () => {
      // On unmount
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);
  useEffect(() => {
    let items = [...props.items];
    if (search !== '') {
      setItems([]);
      items = items.filter(i => i[`${props.displayProperty}`].toLowerCase().includes(search.toLowerCase()));
      setItems(items);
    } else if (search === '') {
      setItems(items);
    }
  }, [props.items, search]);

  // TODO Check if these functions should useRef or anything like that.
  const open = () => {
    setIsOpen(true);
    document.addEventListener('click', handleDocumentClick);
  };

  // TODO Check if these functions should useRef or anything like that.
  const close = () => {
    setIsOpen(false);
    setIsOpenAbove(false);
    document.removeEventListener('click', handleDocumentClick);
  };
  const onSearch = e => {
    if (!isOpen) open();
    setSearch(e.target.value);
    setSelectedIndex(0);
    if (props.onSearchChange) {
      props.onSearchChange(e.target.value);
    }
  };
  const onFocus = e => {
    e.target.setSelectionRange(0, e.target.value.length);
    if (!isOpen) {
      open();
    }
  };
  const toggle = selectedItem => {
    if (isOpen && !selectedItem) {
      close();
    }
    if (selectedItem) {
      clear();
    }
    if (!isOpen) {
      open();
    }
  };
  const clear = () => {
    props.onChange(null);
    setSearch('');
    setSelectedIndex(0);
  };
  const onSelect = item => {
    const {
      displayProperty
    } = props;
    props.onChange(item);
    setSearch(displayProperty ? item[displayProperty] : item);
    setSelectedIndex(0);
    close();
  };
  const onBlur = () => {
    if (isOpen) {
      close();
    }
  };
  return <Scoped css={styles}>
      <div className="type-ahead" ref={contentEl} style={props.style}>
        {!!props.disabled ? <div className="type-ahead__container">
            <input type="text" className="form-control" value={search} placeholder={props.placeholder} disabled={true} />
          </div> : <>
            <div className="type-ahead__container">
              <input style={{
            ...props.inputStyle
          }} type="text" className={!props.value ? 'form-control placeholderWithoutValue' : 'form-control placeholderStyle'} onChange={onSearch} value={search} onFocus={onFocus} placeholder={props.placeholder} autoComplete="none" onBlur={onBlur} />
            </div>
            <Button icon={props.value ? 'fa-regular-times' : 'fa-solid-caret-down'} actionType="flat" onClick={() => toggle(props.value)} tabIndex={-1} />
            <div className={a('type-ahead__content').m('type-ahead__content--open', isOpen).m('type-ahead__content--above', isOpenAbove)}>
              <ul className="select-list select-list__controlled">
                {search !== '' && !items.find(i => i[props.keyProperty] === search) && <li role="option">
                    <a>
                      <div className="select-list__content">{search}</div>
                      <Icon className="select-list__selected" size={14} name="fa-regular-check" />
                    </a>
                  </li>}
                {items.map((item, i) => <li key={props.keyProperty ? item[props.keyProperty] : item} role="option">
                    <a onMouseDown={() => onSelect(item)} className={m('active', selectedIndex === i)} onMouseOver={() => setSelectedIndex(i)}>
                      <div className="select-list__content">{props.displayProperty ? item[props.displayProperty] : item}</div>
                      {isEqual(props.value, props.keyProperty ? item[props.keyProperty] : item) && <Icon className="select-list__selected" size={14} name="fa-regular-check" />}
                    </a>
                  </li>)}
              </ul>
            </div>
          </>}
      </div>
    </Scoped>;
};