import React from 'react';
function Icon(props) {
  return <svg xmlns="http://www.w3.org/2000/svg" width="200" height="49" fill="none" viewBox="0 0 200 49">
      <path fill="#F3F3F4" d="M0 0.876H200V48.876H0z"></path>
      <mask id="mask0_115_6912" style={{
      maskType: 'alpha'
    }} width="24" height="25" x="12" y="12" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M12 12.5H36V36.5H12z"></path>
      </mask>
      <g mask="url(#mask0_115_6912)">
        <path fill="#1D252D" d="M26.933 18.971a.726.726 0 01-.535-.215.727.727 0 01-.215-.535c0-.213.072-.39.215-.535a.727.727 0 01.535-.215h6c.212 0 .39.072.534.216a.725.725 0 01.216.534c0 .213-.072.391-.216.535a.726.726 0 01-.534.215h-6zm0 3.885a.726.726 0 01-.535-.216.727.727 0 01-.215-.535c0-.212.072-.39.215-.534a.726.726 0 01.535-.215h6c.212 0 .39.072.534.215a.726.726 0 01.216.535c0 .213-.072.39-.216.534a.726.726 0 01-.534.216h-6zm0 3.884a.726.726 0 01-.535-.215.726.726 0 01-.215-.535c0-.212.072-.39.215-.534a.727.727 0 01.535-.215h6c.212 0 .39.071.534.215a.726.726 0 01.216.535c0 .212-.072.39-.216.534a.726.726 0 01-.534.215h-6zm-6.998-.826a2.528 2.528 0 01-1.853-.763 2.517 2.517 0 01-.765-1.85c0-.727.254-1.344.762-1.854a2.516 2.516 0 011.851-.764c.726 0 1.344.254 1.854.762s.764 1.125.764 1.85c0 .727-.254 1.344-.762 1.854a2.516 2.516 0 01-1.851.764zm-5.618 4.711v-.611c0-.284.07-.555.207-.811.138-.257.328-.461.57-.613a9.38 9.38 0 012.293-.964 9.665 9.665 0 012.546-.328c.892 0 1.74.11 2.545.328.804.218 1.569.54 2.293.964a1.6 1.6 0 01.57.613c.138.256.207.527.207.81v.612c0 .256-.087.47-.26.644a.875.875 0 01-.644.26h-9.423a.875.875 0 01-.644-.26.875.875 0 01-.26-.644zm1.592-.75v.154h8.047v-.154a8.325 8.325 0 00-1.951-.803 7.938 7.938 0 00-4.145 0c-.678.183-1.329.45-1.95.803zm4.024-5.462c.309 0 .572-.108.789-.326.217-.217.326-.48.326-.789 0-.309-.109-.572-.326-.79a1.074 1.074 0 00-.79-.325c-.308 0-.572.108-.789.326-.217.217-.326.48-.326.789 0 .309.109.572.326.79.217.217.48.325.79.325z"></path>
      </g>
    </svg>;
}
export default Icon;