import React from 'react';
function Icon() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
      <mask id="mask0_24_6438" style={{
      maskType: 'alpha'
    }} width="20" height="20" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0 0H20V20H0z"></path>
      </mask>
      <g mask="url(#mask0_24_6438)">
        <path fill="#53A6D6" d="M12.27 17.334a.989.989 0 01-.77.021.948.948 0 01-.563-.52l-2.458-5.23-1.98 2.48c-.222.277-.5.357-.833.239-.333-.118-.5-.358-.5-.719V3.335c0-.32.143-.546.427-.678a.724.724 0 01.802.094l8.146 6.667c.278.222.358.5.24.833-.118.333-.358.5-.719.5h-3.77L12.75 16c.11.25.121.507.03.77a.911.911 0 01-.51.563z"></path>
      </g>
    </svg>;
}
export default Icon;