import React, { useState, useContext } from 'react';
import { Scoped, k } from 'kremling';
import { useEffect } from 'react';
import classnames from 'classnames';
import { DraftCanvas } from './draft-canvas.component';
import { ReadOnlyCanvas } from './read-only-canvas.component';
import { Node } from './node/node';
import { allNodes } from './node/node-list';
import { mapNode } from './node/node-utils';
import { Box, Typography, Stack } from '@mui/material';
import { PageHeaderMui, Icon } from 'components';
import { ArchivedIcon, IntegrationsIcon, PlaylistAddCheckIcon, PublishedIcon } from 'components/mui';
import { UserStateContext } from 'context/user-state-context';
import { getJourneyRevisions, getJourney, patchJourney, getNodeTemplates } from 'shared/common.api';
const style = {
  modalBackdrop: {
    backgroundColor: 'rgba(53, 64, 82,0.5)',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 899
  }
};
export const CustomerJourneys = ({
  match,
  history
}) => {
  const {
    hasPermission,
    checkCompany,
    asCompany,
    user,
    companyFields,
    flags
  } = useContext(UserStateContext);
  const [isLoading, setIsLoading] = useState(true);
  const [revision, setRevision] = useState(null);
  const [allRevisions, setAllRevisions] = useState([]);
  const [journeyName, setJourneyName] = useState('');
  const [nodes, setNodes] = useState(allNodes);
  const updateName = e => {
    // API call to patch journey name and update DOM
    const name = e.name === '' ? 'Untitled' : e.name;
    setJourneyName(name);
    patchJourney(match?.params?.id, {
      name
    });
  };
  useEffect(() => {
    Node.isSuper = !!user?.is_superuser;
  }, [user?.is_superuser]);
  useEffect(() => {
    if (!asCompany?.id) {
      return;
    }
    Node.asCompany = asCompany;
    Node.companyFields = companyFields;

    // Get nodes from API
    getNodeTemplates({
      company: asCompany.id,
      limit: 1000
    }).then(({
      data
    }) => {
      // Map the database nodes to ones the journey builder can understand.
      const newNodes = [...allNodes, ...data.results.map(mapNode)];
      newNodes.sort((a, b) => (a.order || 100000) - (b.order || 100000));
      setNodes(newNodes);
    });
  }, [asCompany?.id]);
  useEffect(() => {
    if (!match?.params?.id) {
      return;
    }
    const promises = [];

    // Get journey name
    promises.push(getJourney(match?.params?.id).then(({
      data
    }) => {
      setJourneyName(data.name);
    }));

    // Get all revisions
    promises.push(getJourneyRevisions(match.params.id, {
      ordering: '-published_when',
      deleted: 'all',
      limit: 100
    }).then(({
      data
    }) => {
      if (data.results && data.results.length) {
        // check for different company link
        return checkCompany(data.results[0].company).then(() => data.results);
      }

      // How'd we get here? Bad Journey ID?
      throw new Error('No journey found');
    }).then(revisions => {
      setAllRevisions(revisions);
      if (revisions.length > 1 && match?.params?.status !== 'draft') {
        // If we have more than one revision and we're on the 'published' page. select the published revision
        setRevision(revisions[1]);
      } else {
        // We only have a draft revision or we're on the draft page.
        setRevision(revisions[0]);
      }
    }));

    // Done loading!
    Promise.all(promises).then(() => setIsLoading(false));
  }, [match?.params?.id]);
  useEffect(() => {
    if (!revision?.id) {
      return;
    }

    // Any time we switch revisions let's reload all the other revisions in case there were changes.
    getJourneyRevisions(match.params.id, {
      ordering: '-published_when',
      deleted: 'all',
      limit: 100
    }).then(({
      data
    }) => {
      setAllRevisions(data.results);
    });

    // When we change which revision we're looking at make sure we update the page URL
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.get('breadcrumbOverrideURL') && queryParams.get('breadcrumbOverrideName')) {
      history.push(`/customer-journeys/${!revision.published_when ? 'draft' : 'published'}/${match?.params?.id}?breadcrumbOverrideURL=${queryParams.get('breadcrumbOverrideURL')}&breadcrumbOverrideName=${queryParams.get('breadcrumbOverrideName')}`);
    } else {
      history.push(`/customer-journeys/${!revision.published_when ? 'draft' : 'published'}/${match?.params?.id}`);
    }
  }, [revision?.id]);
  return <Scoped css={css}>
      <div className="wrapper-contain">
        {isLoading && <div style={style.modalBackdrop} />}
        <PageHeaderMui type="Journey" name={journeyName} updateName={!isLoading ? updateName : null} updateNamePermission={!isLoading && hasPermission('journey.change_journey')} icon={<Icon name="custom-account_tree" size={34} />} />

        <Stack sx={{
        px: '20px',
        pt: '20px'
      }} spacing="15px" direction={'row'}>
          <Box className="box-button" onClick={() => history.push(`/customer-journeys/details/${match?.params?.id}`)}>
            <PlaylistAddCheckIcon fill={0 == 0 ? '#ffffff' : '#1D252D'} />
            <Typography>Details</Typography>
          </Box>
          <Box className={classnames('box-button', {
          active: !revision?.published_when
        })} onClick={() => setRevision(allRevisions[0])}>
            <ArchivedIcon fill="#1D252D" />
            <Typography>Draft View</Typography>
          </Box>

          {allRevisions.length > 1 && <Box className={classnames('box-button', {
          active: !!revision?.published_when
        })} onClick={() => setRevision(allRevisions[1])}>
              <PublishedIcon fill="#1D252D" />
              <Typography>Published View</Typography>
            </Box>}
          {(user.is_superuser || flags?.journey_params) && <Box className={classnames('box-button')} onClick={() => history.push(`/customer-journeys/wizard-architect/${match?.params?.id}`)}>
              <IntegrationsIcon fill="#1D252D" />
              <Typography>Wizard</Typography>
            </Box>}
        </Stack>

        <div className="canvas-wrapper">
          {!isLoading && <>
              {!!revision?.published_when || !hasPermission('journey.change_journey') ? <ReadOnlyCanvas revision={revision} allRevisions={allRevisions} nodes={nodes} setRevision={setRevision} history={history} /> : <DraftCanvas revision={revision} allRevisions={allRevisions} nodes={nodes} setRevision={setRevision} setAllRevisions={setAllRevisions} match={match} history={history} setIsLoading={setIsLoading} />}
            </>}
        </div>
      </div>
    </Scoped>;
};
const css = {
  styles: `[kremling="i9"] body,body[kremling="i9"] {
  --color-primary: #487aae;
  --color-highlight: #e7efff;
  --color-accent: #354052;
  --color-success: #73b56e;
  --color-warning: #f4b707;
  --color-danger: #df5651;
  --color-grey-10: #f9f9f9;
  --color-grey-25: #f3f3f3;
  --color-grey-50: #e9e9e9;
  --color-grey-75: #e3e3e3;
  --color-grey-100: #d3d3d3;
  --color-grey-200: #c3c3c3;
  --color-grey-300: #b3b3b3;
  --color-grey-400: #a3a3a3;
  --color-grey-500: #808080;
  --color-grey-600: #707070;
  --color-grey-700: #606060;
  --color-grey-800: #505050;
  --color-grey-900: #404040;
  --base-font-family: Roboto, Helvetica, Arial, sans-serif;
  --base-font-size: 1.4rem;
  --base-font-color: #404040;
  --base-font-weight: 400;
  --base-line-height: 1.4;
  --box-shadow-1: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.12);
  --box-shadow-2: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-3: 0 10px 20px rgba(0, 0, 0, 0.17), 0 6px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-4: 0 14px 28px rgba(0, 0, 0, 0.13), 0 10px 10px rgba(0, 0, 0, 0.1);
  --box-shadow-5: 0 19px 38px rgba(0, 0, 0, 0.18), 0 15px 12px rgba(0, 0, 0, 0.1);
  --base-border-radius: 0.4rem;
}

[kremling="i9"] .box-button,[kremling="i9"].box-button {
  background-color: #F3F6FC;
  cursor: pointer;
  text-transform: none;
  height: 32px;
  border-radius: 16px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
}

[kremling="i9"] .box-button path,[kremling="i9"].box-button path {
  fill: #1D252D;
}

[kremling="i9"] .box-button p,[kremling="i9"].box-button p {
  color: #1D252D;
}

[kremling="i9"] .box-button:hover,[kremling="i9"].box-button:hover {
  background-color: #C2E7FF;
}

[kremling="i9"] .box-button p,[kremling="i9"].box-button p {
  color: #1D252D;
  padDing-left: 10px;
}

[kremling="i9"] .box-button.active,[kremling="i9"].box-button.active {
  background-color: #3898D9;
}

[kremling="i9"] .box-button.active path,[kremling="i9"].box-button.active path {
  fill: #ffffff;
}

[kremling="i9"] .box-button.active p,[kremling="i9"].box-button.active p {
  color: #ffffff;
}

[kremling="i9"] .canvas-wrapper,[kremling="i9"].canvas-wrapper {
  display: flex;
  height: calc(100vh - 155px);
  margin: 20px;
  background-color: #ffffff;
  overflow: hidden;
  border-radius: 14px;
}`,
  id: 'i9',
  namespace: 'kremling'
};