import React, { useContext } from 'react';
import { Loader, PageHeaderMui } from 'components';
import { Box, Button, ClickAwayListener, Grid, IconButton, Paper, Popper, Tooltip, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import CssBaseline from '@mui/material/CssBaseline';
import { UserStateContext } from 'context/user-state-context';
import { history } from '../../root.component';
export const DevTools = props => {
  const [isLoading, setIsLoading] = React.useState(false);
  React.useEffect(() => {}, []);
  const getData = () => {};
  return <>
      <CssBaseline />
      <div className="wrapper">
        <PageHeaderMui type={'Dev Tools'} />

        <div className="mui-wrapper">
          <Box className="d-flex  justify-content-between" sx={{
          m: '20px'
        }}>
            <Paper sx={{
            height: '65px',
            width: '100%',
            borderRadius: '14px',
            display: 'flex',
            alignItems: 'center'
          }}>
              <Stack sx={{
              p: 2
            }}>
                <Button onClick={() => history.push(`/dev-tools/icon-list/`)}>Frontend Icon List</Button>
              </Stack>
            </Paper>
          </Box>
        </div>
      </div>
    </>;
};