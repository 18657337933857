import React from 'react';
function Icon(props) {
  return <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" fill="none" viewBox="0 0 25 24">
      <mask id="mask0_64_11026" style={{
      maskType: 'alpha'
    }} width="25" height="24" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0.067 0H24.067V24H0.067z"></path>
      </mask>
      <g mask="url(#mask0_64_11026)">
        <path fill={props.fill || '#1D252D'} d="M5.375 21.25c-.505 0-.933-.175-1.283-.525a1.745 1.745 0 01-.525-1.283V8.527a1.94 1.94 0 01-.698-.614 1.608 1.608 0 01-.302-.97V4.557c0-.505.175-.933.525-1.283.35-.35.778-.525 1.283-.525H19.76c.505 0 .932.175 1.282.525.35.35.525.778.525 1.283v2.384c0 .37-.1.695-.302.971a1.941 1.941 0 01-.698.614v10.915c0 .505-.175.933-.525 1.283-.35.35-.777.525-1.282.525H5.375zm-.308-12.5v10.692a.3.3 0 00.087.221.3.3 0 00.221.087H18.76a.3.3 0 00.22-.087.3.3 0 00.087-.22V8.75h-14zm14.693-1.5a.3.3 0 00.22-.087.3.3 0 00.087-.22V4.557a.3.3 0 00-.086-.221.3.3 0 00-.221-.087H4.375a.3.3 0 00-.221.087.3.3 0 00-.087.22v2.385a.3.3 0 00.087.221.3.3 0 00.221.087H19.76zm-9.75 6.192h4.115a.726.726 0 00.535-.215.726.726 0 00.215-.535.726.726 0 00-.215-.534.726.726 0 00-.535-.216H10.01a.726.726 0 00-.535.216.726.726 0 00-.215.534c0 .213.071.391.215.535a.726.726 0 00.535.215z"></path>
      </g>
    </svg>;
}
export default Icon;