import React, { useState, useContext } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { toasterService } from 'components';
import { UserStateContext } from 'context/user-state-context';
import { Stack, Paper, Grid, Table, TableHead, TableBody, TableRow, TableCell, TableContainer, Typography, Button, Box, TextField } from '@mui/material';
import { updateMotorOemConfig, updateMotorOemConfigDetail } from 'shared/common.api';
import { cloneDeep } from 'lodash';
import { history } from '../../../root.component';
class InvalidRank extends Error {}
export const MotorOemRecommendationsConfig = ({
  configuration,
  configurationDetails,
  setConfigurationDetails,
  setPage
}) => {
  const totalConfigurations = Object.entries(configurationDetails).length;
  const {
    asCompany
  } = useContext(UserStateContext);
  const [lastConfigurationSaveState, setLastConfigurationSaveState] = useState(cloneDeep(configurationDetails));

  // Individual validations
  /**
   * Throws an exception to be caught if there is an invalid rank.
   * @param {string} recommendationId
   * @returns {boolean}
   */
  const hasValidRank = recommendationId => {
    const configurationDetail = configurationDetails[recommendationId];
    if (configurationDetail.rank < 1 || configurationDetail.rank > totalConfigurations) {
      throw new InvalidRank(`${configurationDetail.name} must have a rank inbetween 1 and ${totalConfigurations}`);
    }
  };
  const rankInputIsValid = newRank => {
    if (isNaN(newRank)) {
      throw new InvalidRank(`${newRank} is not a number`);
    }
  };

  // Update Functions
  const updateRank = (recommendationId, newValue) => {
    rankInputIsValid(newValue);
    const castNewValue = Number(newValue);
    const copiedConfiguration = {
      ...cloneDeep(configurationDetails)
    };
    copiedConfiguration[recommendationId].rank = newValue;
    setConfigurationDetails(copiedConfiguration);
  };
  const updateTitle = (recommendationId, newValue) => {
    const copiedConfiguration = {
      ...cloneDeep(configurationDetails)
    };
    copiedConfiguration[recommendationId].title = newValue;
    setConfigurationDetails(copiedConfiguration);
  };
  const updateDescription = (recommendationId, newValue) => {
    const copiedConfiguration = {
      ...cloneDeep(configurationDetails)
    };
    copiedConfiguration[recommendationId].description = newValue;
    setConfigurationDetails(copiedConfiguration);
  };
  const updateUrl = (recommendationId, newValue) => {
    const copiedConfiguration = {
      ...cloneDeep(configurationDetails)
    };
    copiedConfiguration[recommendationId].url = newValue;
    setConfigurationDetails(copiedConfiguration);
  };

  /**
   * Checks the previous save state to see if we should save based on the
   * changing of the provided propertyName value in the object.
   *
   * Also runs a quick validation to make sure everything looks great.
   *
   * @param {string} recommendationId
   * @param {string} propertyName
   * @returns {boolean}
   */
  const shouldSaveConfigDetail = (recommendationId, propertyName) => {
    const config = configurationDetails[recommendationId];
    const saveState = lastConfigurationSaveState[recommendationId];
    if (!saveState) {
      return true;
    }
    if (config[propertyName] && saveState[propertyName]) {
      if (config[propertyName] === saveState[propertyName]) {
        return false;
      }
    }
    return true;
  };
  const saveConfigDetail = async recommendationId => {
    try {
      const configDetail = configurationDetails[recommendationId];
      await updateMotorOemConfigDetail(configDetail.id, configDetail);
      toasterService.success('Successfully saved Motor OEM Data configuration.');
      const saveState = {
        [recommendationId]: configDetail
      };
      const copyOfLastSaveState = {
        ...lastConfigurationSaveState
      };
      setLastConfigurationSaveState({
        ...copyOfLastSaveState,
        ...saveState
      });
    } catch (error) {
      console.error(error);
      toasterService.error('Issue attempting to save configuration.');
    }
  };
  const handleFinishClick = async () => {
    try {
      await validateAll();
      await saveConfiguration();
      toasterService.success('Successfully saved configuration.');
      history.push('/companies');
    } catch (error) {
      if (error instanceof InvalidRank) {
        toasterService.error(error.message);
      } else {
        console.error(error);
        toasterService.error('Issue Attempting to finalize configuration.');
      }
    }
  };
  const saveConfiguration = async () => {
    await updateMotorOemConfig(configuration.id, {
      status: 'completed'
    });
  };

  /**
   * Does one final pass through all the data regarding anything we care
   * about.  If an error is hit, we stop and allow the user to address.
   * @returns {null}
   */
  const validateAll = async () => {
    const ranks = [];
    for (const [recommendationId, configDetail] of Object.entries(configurationDetails)) {
      hasValidRank(recommendationId);
      if (ranks.includes(configDetail.rank)) {
        throw new InvalidRank(`There are duplicate rank values of ${configDetail.rank}.  Please ensure each configuration is uniquely ranked.`);
      }
      ranks.push(configDetail.rank);
    }
  };
  return <>
      <CssBaseline />
      <div className="wrapper">
        <Stack sx={{
        p: '20px'
      }} spacing={'20px'} padding={'3rem'} alignItems={'center'}>
          <Paper sx={{
          width: '100%',
          padding: '5px'
        }}>
            <Box sx={{
            display: 'flex',
            justifyContent: 'space-between'
          }}>
              <Stack sx={{
              margin: 1
            }} direction="row" spacing={'10px'} alignItems={'center'}>
                <Typography sx={{
                fontSize: 18
              }}>2. Customize Your Recommendations |</Typography>
                <Typography sx={{
                fontSize: 14
              }}>
                  You can customize these OEM recommendations to match your business' preferred names, descriptions, and priority.
                </Typography>
              </Stack>
              <Stack direction={'row'} spacing={'10px'} sx={{
              pr: '20px'
            }} alignItems={'center'}>
                <Button sx={{
                width: '115px',
                height: '30px'
              }} variant="outlined" onClick={() => {
                setPage(1);
              }}>
                  Go Back
                </Button>
                <Button sx={{
                width: '115px',
                height: '30px'
              }} variant="contained" onClick={async () => {
                await handleFinishClick();
              }}>
                  Finish
                </Button>
              </Stack>
            </Box>
          </Paper>
        </Stack>
        <Stack sx={{
        p: '20px'
      }} spacing={'20px'}>
          <Paper sx={{
          borderRadius: '14px',
          minHeight: '75vh'
        }}>
            <Stack sx={{
            pt: '8px'
          }} spacing={'20px'}>
              <Grid sx={{
              px: '8px'
            }} container spacing={{
              xs: 2,
              sm: 2,
              md: 3,
              lg: 3
            }} columns={{
              xs: 4,
              sm: 12,
              md: 12,
              lg: 12
            }}>
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>MOTOR Recommendation</TableCell>
                        <TableCell>Rank</TableCell>
                        <TableCell>Title</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Link</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!configurationDetails || Object.entries(configurationDetails).length === 0 ? <TableRow>
                          {/* TODO obviously fix this when you get a chance*/}
                          <TableCell colSpan={5}>No Data</TableCell>
                        </TableRow> : Object.entries(configurationDetails).map(([recommendationId, data]) => {
                      return <TableRow key={recommendationId}>
                              <TableCell>{data.name}</TableCell>
                              <TableCell>
                                <TextField size="small" value={data.rank} onChange={event => {
                            try {
                              updateRank(recommendationId, event.target.value);
                            } catch (error) {
                              if (error instanceof InvalidRank) {
                                toasterService.error(error.message);
                              } else {
                                console.error(error);
                              }
                            }
                          }} onBlur={() => {
                            if (shouldSaveConfigDetail(recommendationId, 'rank')) {
                              try {
                                hasValidRank(recommendationId);
                                saveConfigDetail(recommendationId);
                              } catch (error) {
                                if (error instanceof InvalidRank) {
                                  toasterService.error(error.message);
                                } else {
                                  console.error(error);
                                }
                              }
                            }
                          }} />
                              </TableCell>
                              <TableCell>
                                <TextField size="small" value={data.title} onChange={event => {
                            updateTitle(recommendationId, event.target.value);
                          }} onBlur={() => {
                            if (shouldSaveConfigDetail(recommendationId, 'title')) {
                              saveConfigDetail(recommendationId);
                            }
                          }} />
                              </TableCell>
                              <TableCell>
                                <TextField size="small" value={data.description} onChange={event => {
                            updateDescription(recommendationId, event.target.value);
                          }} onBlur={() => {
                            if (shouldSaveConfigDetail(recommendationId, 'description')) {
                              saveConfigDetail(recommendationId);
                            }
                          }} />
                              </TableCell>
                              <TableCell>
                                <TextField size="small" value={data.url} onChange={event => {
                            updateUrl(recommendationId, event.target.value);
                          }} onBlur={() => {
                            if (shouldSaveConfigDetail(recommendationId, 'url')) {
                              saveConfigDetail(recommendationId);
                            }
                          }} />
                              </TableCell>
                            </TableRow>;
                    })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Stack>
          </Paper>
        </Stack>
      </div>
    </>;
};