import React, { useContext, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography, TextField, Stack, RadioGroup, Radio, FormControlLabel, FormControl, Checkbox, FormGroup } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { UserStateContext } from 'context/user-state-context';
import { useEffect } from 'react';
import { Label } from '@mui/icons-material';
export const StaticPromptModal = ({
  open,
  onClose,
  onSubmit
}) => {
  const [clearSegment, setClearSegment] = useState(true);
  return <Dialog PaperProps={{
    style: {
      borderRadius: '28px',
      width: '460px'
    }
  }} open={open} onClose={onClose}>
      <DialogTitle sx={{
      m: 0,
      p: 2,
      fontSize: 'medium'
    }}>
        <IconButton aria-label="close" onClick={onClose} sx={{
        position: 'absolute',
        right: 8,
        top: 8,
        color: theme => theme.palette.grey[500]
      }}>
          <CloseIcon />
        </IconButton>
        <Stack alignItems="center" component="span">
          <Typography variant="h4" component="span">
            Update Static Segment
          </Typography>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <div className="mb-md">
          Updating a static segment causes a refresh to the customers in the segment.  Do you want to:
        </div>
        <div className="form-group form-group__radio">
          <label>
            <input type="radio" name="clear_segment" checked={!clearSegment} onChange={() => setClearSegment(!clearSegment)} />
            <div>
              Add newly matched customers to segment.
            </div>
          </label>
          <label>
            <input type="radio" name="clear_segment" checked={clearSegment} onChange={() => setClearSegment(!clearSegment)} />
            <div>
              Replace all customers in segment.
            </div>
          </label>
        </div>
      </DialogContent>
      <DialogActions sx={{
      py: 1
    }}>
        <Button onClick={onClose} variant="text" sx={{
        borderRadius: '20px',
        color: 'rgba(29, 37, 45, 0.7)',
        width: '75px',
        textTransform: 'none'
      }}>
          Cancel
        </Button>
        <Button onClick={() => onSubmit(clearSegment)} variant="text" sx={{
        mr: 1,
        borderRadius: '20px',
        width: '75px',
        color: '#53A6D6',
        textTransform: 'none'
      }}>
          Save
        </Button>
      </DialogActions>
    </Dialog>;
};