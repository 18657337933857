import * as React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, TextField, Stack, Typography, TableCell, Table, TableRow, Box, Icon, IconButton, CircularProgress, TableBody, Select, FormControl, InputLabel, MenuItem, Chip } from '@mui/material';
import { UserStateContext } from 'context/user-state-context';
import { CarrotDownIcon, CarrotUpIcon, CloseIcon, DeleteIcon, EditIcon, StatusDotIcon } from 'components/mui';
import { createReleaseNote, patchReleaseNote, deleteReleaseNote, createReleaseNoteLog } from 'shared/common.api';
import moment from 'moment';
import ReactQuill, { Quill } from 'react-quill';
import quillEmoji from 'quill-emoji';
import 'react-quill/dist/quill.snow.css';
import 'quill-emoji/dist/quill-emoji.css';
import { Scoped } from 'kremling';
import styles from './release-notes.styles.scss';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
const fontFamilyArr = ['Arial', 'Bitter', 'Cabin', 'Georgia', 'Helvetica', 'Lato', 'Merriweather', 'Nexa', 'Open Sans', 'Oswald', 'Popins', 'PT Sans', 'PT Serif', 'Roboto', 'Ubuntu', 'Verdana'];
const {
  EmojiBlot,
  ToolbarEmoji
} = quillEmoji;
Quill.register({
  'formats/emoji': EmojiBlot,
  'modules/emoji-toolbar': ToolbarEmoji
}, true);
let fonts = Quill.import('attributors/style/font');
fonts.whitelist = fontFamilyArr;
Quill.register(fonts, true);
const fontSizeArr = ['10px', '11px', '12px', '14px', '16px', '18px', '24px', '30px', '36px', '48px', '60px', '72px'];
var Size = Quill.import('attributors/style/size');
Size.whitelist = fontSizeArr;
Quill.register(Size, true);
const modules = {
  toolbar: [[{
    header: '1'
  }, {
    header: '2'
  }, {
    font: fontFamilyArr
  }], [{
    size: fontSizeArr
  }], ['bold', 'italic', 'underline', 'strike', 'blockquote'], [{
    list: 'ordered'
  }, {
    list: 'bullet'
  }, {
    indent: '-1'
  }, {
    indent: '+1'
  }, 'link', 'image', 'emoji', 'clean']],
  'emoji-toolbar': true
};
const formats = ['header', 'font', 'size', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'indent', 'link', 'image', 'clean', 'emoji'];
export const ReleaseNotesModal = props => {
  const {
    user,
    releaseNotes,
    refreshReleaseNotes
  } = React.useContext(UserStateContext);
  const [notes, setNotes] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [expandedNotes, setExpandedNotes] = React.useState([]);
  const [addNewReleaseNote, setAddNewReleaseNote] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const [note, setNote] = React.useState({});
  const [releasedWhenDateError, setReleasedWhenDateError] = React.useState(null);
  React.useEffect(() => {
    setNotes(releaseNotes);
    console.log(releaseNotes, 'releaseNotes');
    setLoading(false);
  }, [releaseNotes]);
  const handleRowClick = note => {
    if (expandedNotes.includes(note.id)) {
      setExpandedNotes(expandedNotes.filter(noteId => noteId !== note.id));
    } else {
      setExpandedNotes([...expandedNotes, note.id]);
      markAsRead(note);
    }
  };
  const handleAddNewReleaseNote = () => {
    setSaving(true);
    if (note?.id) {
      patchReleaseNote(note.id, {
        name: note.name,
        description: note.description,
        released_when: note.released_when,
        availability: note.availability
      }).then(r => {
        setSaving(false);
        setLoading(true);
        setAddNewReleaseNote(false);
        setNote({});
        refreshReleaseNotes();
      });
    } else {
      createReleaseNote({
        name: note.name,
        description: note.description,
        released_when: note.released_when,
        availability: note.availability
      }).then(r => {
        setSaving(false);
        setLoading(true);
        setAddNewReleaseNote(false);
        setNote({});
        refreshReleaseNotes();
      });
    }
  };
  const handleDeleteNote = id => {
    setLoading(true);
    setExpandedNotes([]);
    setAddNewReleaseNote(false);
    setNote({});
    deleteReleaseNote(id).then(r => {
      refreshReleaseNotes();
    });
  };
  const markAsRead = note => {
    if (note.is_read) {
      return;
    }
    createReleaseNoteLog({
      release_note: note.id
    }).then(r => {
      refreshReleaseNotes();
    });
  };
  return <>
      <Dialog scroll="paper" PaperProps={{
      style: {
        borderRadius: '28px',
        width: '65vw',
        maxWidth: '65vw'
      }
    }} open={!!true} onClose={(e, r) => {
      props.handleClose();
    }}>
        <DialogTitle sx={{
        m: 0,
        p: 2,
        fontSize: 'medium'
      }}>
          <Stack spacing={'20px'}>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Stack direction="row" spacing={'10px'}>
                <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                  <Typography sx={{
                  fontWeight: '300',
                  lineHeight: '30px',
                  fontSize: '24px',
                  display: 'flex',
                  alignItems: 'center'
                }} variant="h4" component="span">
                    What's New
                  </Typography>
                </Box>
              </Stack>
              <Stack direction="row" spacing={2}>
                {user.is_superuser && <Button onClick={e => {
                setNote({
                  released_when: dayjs().format('YYYY-MM-DD')
                });
                setAddNewReleaseNote(true);
              }} variant="contained">
                    Add New
                  </Button>}
                <Box>
                  <IconButton onClick={e => {
                  props.handleClose();
                }}>
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Stack>
            </Box>
            <Divider />
          </Stack>
        </DialogTitle>

        <DialogContent sx={{
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
          width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
          marginTop: '20px',
          marginBottom: '20px'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#888'
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555'
        }
      }}>
          {loading ? <Box sx={{
          width: '100%',
          height: '100%'
        }}>
              <Box sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            justifyItems: 'center',
            alignItems: 'center'
          }}>
                <CircularProgress />
              </Box>
            </Box> : <Stack sx={{
          pt: 1
        }} spacing={'24px'}>
              <Table>
                <colgroup>
                  <col width="20%" />
                  <col width="10%" />
                  <col width="60%" />
                  <col width="10%" />
                </colgroup>
                <TableBody>
                  {notes?.map(releaseNote => <React.Fragment key={releaseNote.id}>
                      <TableRow onClick={e => {
                  handleRowClick(releaseNote);
                }} sx={{
                  height: '85px',
                  cursor: 'pointer',
                  ':hover': {
                    backgroundColor: '#DAEFFF'
                  }
                }}>
                        <TableCell>
                          <Stack direction="row" spacing="8px">
                            {releaseNote.is_read ? <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        px: '10px'
                      }}> </Box> : <Box sx={{
                        display: 'flex',
                        alignItems: 'center'
                      }}>
                                <StatusDotIcon fill="#3898D9" />
                              </Box>}
                            <Box sx={{
                        display: 'flex',
                        alignItems: 'center'
                      }}>
                              <Typography sx={{
                          fontWeight: '600'
                        }}>
                                {releaseNote.released_when ? moment(releaseNote.released_when).local().format('MMM D YYYY') : moment(releaseNote.created_when).local().format('MMM D YYYY')}
                              </Typography>
                            </Box>
                          </Stack>
                        </TableCell>
                        <TableCell>
                          <Typography>
                            <Chip sx={{
                        background: '#C2E7FF'
                      }} label={releaseNote.availability == 'employees' ? 'Employees Only' : releaseNote.availability == 'beta' ? 'Beta Users' : 'General Release'} />
                          </Typography>
                        </TableCell>

                        <TableCell>
                          <Typography sx={{
                      fontWeight: releaseNote.is_read ? '400' : '600'
                    }}>{releaseNote.name} </Typography>
                        </TableCell>
                        <TableCell>
                          <Stack direction="row" spacing={1}>
                            <IconButton>{expandedNotes.includes(releaseNote.id) ? <CarrotUpIcon /> : <CarrotDownIcon />}</IconButton>

                            {user.is_superuser && <IconButton onClick={e => {
                        e.stopPropagation();
                        setNote({
                          name: releaseNote.name,
                          description: releaseNote.description,
                          id: releaseNote.id,
                          availability: releaseNote.availability,
                          released_when: releaseNote.released_when
                        });
                        setAddNewReleaseNote(true);
                      }}>
                                <EditIcon />
                              </IconButton>}

                            {user.is_superuser && <IconButton onClick={e => {
                        e.stopPropagation();
                        handleDeleteNote(releaseNote.id);
                      }}>
                                <DeleteIcon />
                              </IconButton>}
                          </Stack>
                        </TableCell>
                      </TableRow>

                      {expandedNotes.includes(releaseNote.id) && <TableRow>
                          <TableCell colSpan={3}>
                            <Box sx={{
                      display: 'flex',
                      alignItems: 'center'
                    }}>
                              <div dangerouslySetInnerHTML={{
                        __html: releaseNote?.description
                      }} />
                            </Box>
                          </TableCell>
                        </TableRow>}
                    </React.Fragment>)}
                </TableBody>
              </Table>
            </Stack>}
        </DialogContent>

        <DialogActions sx={{
        pt: 1,
        pb: 2
      }}>
          <Stack sx={{
          pr: '24px'
        }} direction={'row'} spacing={'20px'}>
            <Button onClick={() => {
            props.handleClose();
          }} variant="contained" sx={{
            backgroundColor: '#3898D9',
            '&:hover': {
              backgroundColor: '#3898D9'
            }
          }}>
              <Box sx={{
              py: '5px',
              px: '12px'
            }}>
                <Typography sx={{
                color: '#fff'
              }}> Go enjoy the new features!</Typography>
              </Box>
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>

      <Dialog scroll="paper" PaperProps={{
      style: {
        borderRadius: '28px',
        width: '55vw',
        maxWidth: '55vw'
      }
    }} open={!!addNewReleaseNote} onClose={(e, r) => {
      setAddNewReleaseNote(false);
      console.log('here');
      setNote({});
    }}>
        <DialogTitle sx={{
        m: 0,
        p: 2,
        fontSize: 'medium'
      }}>
          <Stack spacing={'20px'}>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Stack direction="row" spacing={'10px'}>
                <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                  <Typography sx={{
                  fontWeight: '300',
                  lineHeight: '30px',
                  fontSize: '24px',
                  display: 'flex',
                  alignItems: 'center'
                }} variant="h4" component="span">
                    Add new Release Note
                  </Typography>
                </Box>
              </Stack>
            </Box>
            <Divider />
          </Stack>
        </DialogTitle>

        <DialogContent sx={{
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
          width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
          marginTop: '20px',
          marginBottom: '20px'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#888'
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555'
        }
      }}>
          <Scoped css={styles}>
            <Stack spacing={2} sx={{
            pt: 1
          }}>
              <TextField label="Name" variant="outlined" fullWidth sx={{
              pb: 1
            }} onChange={e => {
              setNote({
                ...note,
                name: e.target.value
              });
            }} value={note?.name || ''} />
              <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker sx={{
                  width: '45%'
                }} label="Released Date" value={note?.released_when ? dayjs(note?.released_when) : null} onError={newError => releasedWhenDateError(newError)} slotProps={{
                  textField: {
                    helperText: releasedWhenDateError
                  },
                  field: {
                    clearable: true
                  },
                  actionBar: {
                    actions: ['today']
                  }
                }} onChange={newDate => {
                  console.log(newDate, 'newDate');
                  setNote({
                    ...note,
                    released_when: newDate ? newDate.format('YYYY-MM-DD') : null
                  });
                }} />
                </LocalizationProvider>

                <FormControl sx={{
                width: '45%'
              }}>
                  <InputLabel id="availability-label">Availability</InputLabel>
                  <Select labelId="availability-label" id="availability" value={note.availability || 'employees'} label="Availability" onChange={e => {
                  setNote({
                    ...note,
                    availability: e.target.value
                  });
                }}>
                    <MenuItem value={'employees'}>Employees</MenuItem>
                    <MenuItem value={'beta'}>Beta</MenuItem>
                    <MenuItem value={'general'}>General</MenuItem>
                  </Select>
                </FormControl>
              </Box>

              <Box className="ed" sx={{
              pb: 1
            }}>
                <ReactQuill readOnly={saving} className="ed" theme="snow" value={note?.description || '<p><span style="font-family: Nexa; font-size: 16px;">A description</span></p>'} onChange={e => {
                setNote({
                  ...note,
                  description: e
                });
              }} modules={modules} formats={formats} />
              </Box>
            </Stack>
          </Scoped>
        </DialogContent>

        <DialogActions sx={{
        pt: 1,
        pb: 2
      }}>
          <Stack sx={{
          pr: '24px'
        }} direction={'row'} spacing={'20px'}>
            <Button onClick={() => {
            setAddNewReleaseNote(false);
            setNote({});
          }} variant="contained" sx={{
            backgroundColor: '#3898D9',
            '&:hover': {
              backgroundColor: '#3898D9'
            }
          }}>
              <Box sx={{
              py: '5px',
              px: '12px'
            }}>
                <Typography sx={{
                color: '#fff'
              }}> Cancel</Typography>
              </Box>
            </Button>

            <Button onClick={() => {
            handleAddNewReleaseNote();
          }} variant="contained" endIcon={saving && <CircularProgress sx={{
            color: '#FFF'
          }} size={'20px'} />} sx={{
            backgroundColor: '#3898D9',
            '&:hover': {
              backgroundColor: '#3898D9'
            }
          }}>
              <Box sx={{
              py: '5px',
              px: '12px'
            }}>
                <Typography sx={{
                color: '#fff'
              }}> {note?.id ? 'Update' : 'Add New'}</Typography>
              </Box>
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </>;
};