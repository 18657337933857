import React from 'react';
function Icon(props) {
  return <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
      <mask id="mask0_38_2895" style={{
      maskType: 'alpha'
    }} width="20" height="20" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0 0H20V20H0z"></path>
      </mask>
      <g mask="url(#mask0_38_2895)">
        <path fill={props.fill || '#53A6D6'} d="M9.383 15.883l-5.317-5.296a.88.88 0 01-.197-.279.748.748 0 010-.581.88.88 0 01.197-.278L9.39 4.123a.648.648 0 01.456-.2.638.638 0 01.477.208c.129.135.192.293.19.475a.65.65 0 01-.198.466l-4.28 4.279h9.486a.64.64 0 01.467.2.64.64 0 010 .933.639.639 0 01-.467.2H6.037l4.287 4.287c.129.13.196.277.2.442.004.165-.065.322-.208.47a.658.658 0 01-.933 0z"></path>
      </g>
    </svg>;
}
export default Icon;