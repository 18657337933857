import React, { useState, useEffect } from 'react';
import { Select, MenuItem, Checkbox, ListItemText, FormControl, InputLabel, OutlinedInput, FormHelperText, Input, Stack, TextField } from '@mui/material';
const DEFAULT = '';
const HOURLY = '0 * * * *';
const DAILY = '0 0 * * *';
export const CronMui = ({
  value,
  onChange,
  formContext,
  disableDefault
}) => {
  const [dropdownOption, setDropdownOption] = useState(() => {
    if (!value) {
      return 'DEFAULT';
    } else if (value === HOURLY) {
      return 'HOURLY';
    } else if (value === DAILY) {
      return 'DAILY';
    }
    return 'CUSTOM';
  });
  const dropChange = e => {
    if (e === 'DEFAULT') {
      onChange(DEFAULT);
    } else if (e === 'HOURLY') {
      onChange(HOURLY);
    } else if (e === 'DAILY') {
      onChange(DAILY);
    }
    setDropdownOption(e);
  };
  return <Stack spacing="36px" sx={{
    py: '16px'
  }}>
      <FormControl sx={{
      width: '100%'
    }}>
        <InputLabel id="Interval-label">Interval</InputLabel>
        <Select labelId="Interval-label" id="cron" value={dropdownOption} label="Interval" onChange={e => dropChange(e.target.value)} disabled={!formContext.hasPermission}>
          {!disableDefault && <MenuItem value="DEFAULT">Default</MenuItem>}
          <MenuItem value="HOURLY">Hourly</MenuItem>
          <MenuItem value="DAILY">Daily</MenuItem>
          <MenuItem value="CUSTOM">Custom</MenuItem>
        </Select>
      </FormControl>

      {dropdownOption === 'CUSTOM' && <TextField onChange={e => onChange(e.target.value)} className="form-control mt-2" value={value || '0 * * * *'} />}
    </Stack>;
};