import React, { useEffect, useRef, useState } from 'react';
import { getter } from './utils';
import moment from 'moment-timezone';
import numeral from 'numeral';
import { Button, Box, Paper, Stack, Table, TableBody, TableCell, TableRow, Typography, TableHead, Tooltip, IconButton } from '@mui/material';
import SelectMUI from 'components/mui/select/select.component';
import Carousel from 'react-material-ui-carousel';
import { ArrowBackIcon, ArrowForwardIcon, Calendar30DaysIcon, CarrotDownIcon, CarrotUpIcon, LocationOnIcon, MoneyIcon, VehicleIcon, VehicleTagIcon } from 'components/mui';
import CircularProgress from '@mui/material/CircularProgress';
const fieldsToExclude = ['location_name', 'total', 'transaction_when', 'vehicle'];
const fieldsDetailsToExclude = ['item', 'item_descr', 'item_type', 'price_total', 'quantity'];
export function TransactionsComponent(props) {
  // These props are a lazy way to pass data around. We need to optimize this later.
  const {
    transactions,
    selectedTransaction,
    customerDetails,
    availableModelsFields,
    availableModels,
    isLoading,
    _detailsTabs,
    handleDetailsTab,
    selectedDetailsTab
  } = props;
  const [showMoreFields, setShowMoreFields] = useState(false);
  const [showMoreDetails, setShowMoreDetails] = useState(props.scrollToTransactionDetails || false);
  const [expandedTransactionDetail, setExpandedTransactionDetail] = useState(null);
  const [selectedTransactionIndex, setSelectedTransactionIndex] = useState(0);
  const [onDetailsHover, setOnDetailsHover] = useState(null);
  const transactionDetailsRef = useRef(null);
  useEffect(() => {
    let toSelect = 0;
    if (selectedTransaction && transactions) {
      toSelect = transactions?.findIndex(t => t.id === selectedTransaction);
      if (toSelect === -1) {
        toSelect = 0;
      }
    }
    setSelectedTransactionIndex(toSelect);
    if (props.scrollToTransactionDetails) {
      transactionDetailsRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }
  }, [transactions, selectedTransaction]);
  const getVehicleYMM = possessionId => {
    const vehicle = customerDetails?.vehicles?.find(v => v.possession === possessionId);
    if (vehicle) {
      return <Stack direction="row">
          <Typography>{vehicle?.year + ' ' + vehicle?.make + ' ' + vehicle?.model}</Typography>
        </Stack>;
    }
    return <Typography sx={{
      color: 'BEBEBE'
    }}>--</Typography>;
  };
  const getVehicleTag = possessionId => {
    const vehicle = customerDetails?.vehicles?.find(v => v.possession === possessionId);
    if (vehicle) {
      return <Stack direction="row">
          <Typography>{vehicle?.tag_state + ' - ' + vehicle?.tag}</Typography>
        </Stack>;
    }
    return <Typography sx={{
      color: 'BEBEBE'
    }}>--</Typography>;
  };
  const getFilteredTransactionFields = transaction => {
    return transactionFields?.filter(f => transaction[f?.id] != undefined && transaction[f?.id] != null && transaction[f?.id] != '' || f.id.substring(0, 13) === 'entity_data__' || f.id.substring(0, 15) === 'appended_data__');
  };
  const handleTransactionDetailClick = id => {
    setExpandedTransactionDetail(expandedTransactionDetail == id ? null : id);
  };
  const transactionFields = availableModelsFields?.find(model => model.id === 'transaction')?.fields?.filter(f => !fieldsToExclude.includes(f.id));
  const transactionDetailsFields = availableModelsFields?.find(model => model.id === 'transaction_detail')?.fields?.filter(f => !fieldsDetailsToExclude.includes(f.id));
  return <>
      <Stack sx={{
      height: '100%',
      background: '#E4E9F1',
      borderBottomLeftRadius: '28px',
      borderBottomRightRadius: '28px',
      overflowY: 'scroll',
      scrollbarWidth: 'thin',
      '&::-webkit-scrollbar': {
        width: '0.4em'
      },
      '&::-webkit-scrollbar-track': {
        background: '#f1f1f1',
        marginTop: '25px',
        marginBottom: '25px'
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#888'
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#555'
      }
    }} className="d-flex h-100" spacing={'14px'}>
        <Box className="d-flex w-100  justify-content-between" sx={{
        position: 'relative',
        pt: '14px',
        pb: '38px',
        display: 'flex'
      }}>
          <span />
          <Box sx={{
          position: 'absolute',
          left: '37.5%'
        }}>
            <SelectMUI height="36px" width="180px" top="6px" zIndex={9001} options={_detailsTabs} value={selectedDetailsTab || ''} onChange={opt => handleDetailsTab(opt.key)} />
          </Box>
          {transactions?.length > 0 && <Box sx={{
          position: 'absolute',
          left: '77%',
          top: '24px'
        }}>
              {'' + (selectedTransactionIndex + 1) + ' of ' + transactions?.length + ' Items'}
            </Box>}
        </Box>
        <Stack>
          {isLoading ? <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          pt: '25px'
        }}>
              <CircularProgress />
            </Box> : <>
              {transactions?.length > 0 ? <Carousel index={selectedTransactionIndex} navButtonsAlwaysVisible cycleNavigation={false} autoPlay={false} animation="slide" indicators={false} PrevIcon={<ArrowBackIcon fill="#fff" />} NextIcon={<ArrowForwardIcon fill="#fff" />} onChange={(now, prev) => setSelectedTransactionIndex(now)}>
                  {transactions?.map((transaction, i) => <Box key={transactions.id + i} sx={{
              px: '50px'
            }}>
                      <Paper sx={{
                borderRadius: '14px',
                overflowY: 'scroll',
                scrollbarWidth: 'thin',
                '&::-webkit-scrollbar': {
                  width: '0.4em'
                },
                '&::-webkit-scrollbar-track': {
                  background: '#f1f1f1',
                  marginTop: '10px',
                  marginBottom: '10px'
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#888'
                },
                '&::-webkit-scrollbar-thumb:hover': {
                  background: '#555'
                },
                height: '70vh'
              }}>
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell>
                                <Stack spacing="10px" direction="row" sx={{
                          display: 'flex',
                          alignItems: 'center'
                        }}>
                                  <LocationOnIcon />
                                  <Typography variant="tableHeader">Location </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell>
                                {transaction?.location_friendly_name || <Typography sx={{
                          color: 'BEBEBE'
                        }}>--</Typography>}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <Stack spacing="10px" direction="row" sx={{
                          display: 'flex',
                          alignItems: 'center'
                        }}>
                                  <Calendar30DaysIcon />
                                  <Typography variant="tableHeader">Transaction Date </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell>
                                {transaction?.transaction_when ? moment.utc(transaction.transaction_when).local().format('MM/D/YY h:mm A') : <Typography sx={{
                          color: 'BEBEBE'
                        }}>--</Typography>}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <Stack spacing="10px" direction="row" sx={{
                          display: 'flex',
                          alignItems: 'center'
                        }}>
                                  <MoneyIcon />
                                  <Typography variant="tableHeader">Transaction Amount </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell>{transaction?.total ? numeral(transaction?.total).format('$0,0.00') : '$0.00'}</TableCell>
                            </TableRow>

                            {transaction?.possession && <TableRow>
                                <TableCell>
                                  <Stack spacing="10px" direction="row" sx={{
                          display: 'flex',
                          alignItems: 'center'
                        }}>
                                    <VehicleIcon />
                                    <Typography variant="tableHeader">Vehicle </Typography>
                                  </Stack>
                                </TableCell>
                                <TableCell>{getVehicleYMM(transaction.possession)}</TableCell>
                              </TableRow>}

                            {transaction?.possession && <TableRow>
                                <TableCell>
                                  <Stack spacing="10px" direction="row" sx={{
                          display: 'flex',
                          alignItems: 'center'
                        }}>
                                    <VehicleTagIcon />
                                    <Typography variant="tableHeader">Vehicle Tags </Typography>
                                  </Stack>
                                </TableCell>
                                <TableCell>{getVehicleTag(transaction.possession)}</TableCell>
                              </TableRow>}

                            {availableModels.indexOf('transaction') > -1 && getFilteredTransactionFields(transaction)?.filter((f, index) => showMoreFields)?.map((field, index) => {
                      const fieldValue = transaction[field.id];
                      return fieldValue !== undefined && fieldValue !== null && fieldValue !== '' ? <TableRow key={field.id + index}>
                                      <TableCell>
                                        <Typography variant="tableHeader">{field.name} </Typography>
                                      </TableCell>

                                      <TableCell>
                                        {field.type === 'datetime' ? moment(fieldValue).local().format('MMM D, YYYY h:mm A') : field.type === 'date' ? moment(fieldValue).format('MMM D, YYYY') : fieldValue}
                                      </TableCell>
                                    </TableRow> : field.id.substring(0, 13) === 'entity_data__' ? <TableRow key={index}>
                                      <TableCell>
                                        <Typography variant="tableHeader">{field.name} </Typography>
                                      </TableCell>
                                      <TableCell>
                                        {getter(transaction, field.id.split('__')) || <Typography sx={{
                            color: '#BEBEBE'
                          }}>--</Typography>}
                                      </TableCell>
                                    </TableRow> : field.id.substring(0, 15) === 'appended_data__' ? <TableRow key={index}>
                                      <TableCell>
                                        <Typography variant="tableHeader">{field.name} </Typography>
                                      </TableCell>
                                      <TableCell>
                                        {getter(transaction, field.id.split('__')) || <Typography sx={{
                            color: '#BEBEBE'
                          }}>--</Typography>}
                                      </TableCell>
                                    </TableRow> : <React.Fragment key={field.id} />;
                    })}

                            {getFilteredTransactionFields(transaction)?.length > 0 && <TableRow sx={{
                      cursor: 'pointer'
                    }} onClick={() => setShowMoreFields(!showMoreFields)}>
                                <TableCell sx={{
                        py: '8px'
                      }}>
                                  <Typography sx={{
                          color: '#1D252D80'
                        }}>
                                    {'... ' + getFilteredTransactionFields(transaction).length + (showMoreFields ? ' less items' : ' more items')}
                                  </Typography>
                                </TableCell>
                                <TableCell sx={{
                        py: '8px'
                      }}>
                                  <Box className="d-flex  justify-content-between">
                                    <span />
                                    <Button>
                                      <Stack sx={{
                              display: 'flex',
                              alignItems: 'center'
                            }} direction="row" spacing="10px">
                                        <Typography sx={{
                                color: '#3898D9',
                                textTransform: 'none'
                              }}>
                                          {showMoreFields ? 'View less' : 'View all'}
                                        </Typography>
                                        {showMoreFields ? <CarrotUpIcon /> : <CarrotDownIcon />}
                                      </Stack>
                                    </Button>
                                  </Box>
                                </TableCell>
                              </TableRow>}
                          </TableBody>
                        </Table>

                        {transaction?.details?.length > 0 && <Table>
                            <colgroup>
                              <col style={{
                      width: '25%'
                    }} />
                              <col style={{
                      width: '25%'
                    }} />
                              <col style={{
                      width: '25%'
                    }} />
                              <col style={{
                      width: '10%'
                    }} />
                              <col style={{
                      width: '15%'
                    }} />
                            </colgroup>
                            <TableHead ref={transactionDetailsRef}>
                              <TableRow>
                                <TableCell>
                                  <Typography variant="tableHeader">Type</Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant="tableHeader">Description</Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant="tableHeader">Item</Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant="tableHeader">Qty</Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant="tableHeader">Price</Typography>
                                </TableCell>
                              </TableRow>
                            </TableHead>

                            <TableBody>
                              {transaction?.details?.filter((d, i) => showMoreDetails || i < 4)?.map((detail, index) => <React.Fragment key={detail.id + index}>
                                    <TableRow sx={{
                        cursor: 'pointer'
                      }} key={index} onClick={() => handleTransactionDetailClick(detail.id)} onMouseEnter={() => {
                        setOnDetailsHover(detail.id);
                      }} onMouseLeave={() => {
                        setOnDetailsHover(null);
                      }}>
                                      <TableCell>
                                        <Box sx={{
                            py: '4px'
                          }}>
                                          <Typography variant={expandedTransactionDetail == detail.id ? 'tableHeader' : ''}>
                                            {detail.item_type || <Typography sx={{
                                color: 'BEBEBE'
                              }}>--</Typography>}
                                          </Typography>
                                        </Box>
                                      </TableCell>
                                      <TableCell>
                                        <Typography variant={expandedTransactionDetail == detail.id ? 'tableHeader' : ''}>
                                          {detail.item_descr || <Typography sx={{
                              color: 'BEBEBE'
                            }}>--</Typography>}
                                        </Typography>
                                      </TableCell>

                                      <TableCell>
                                        <Typography variant={expandedTransactionDetail == detail.id ? 'tableHeader' : ''}>
                                          {detail.item || <Typography sx={{
                              color: 'BEBEBE'
                            }}>--</Typography>}
                                        </Typography>
                                      </TableCell>

                                      <TableCell>
                                        <Typography variant={expandedTransactionDetail == detail.id ? 'tableHeader' : ''}>
                                          {detail.quantity || <Typography sx={{
                              color: 'BEBEBE'
                            }}>--</Typography>}
                                        </Typography>
                                      </TableCell>
                                      <TableCell sx={{
                          py: '0px'
                        }}>
                                        {onDetailsHover == detail.id ? expandedTransactionDetail != detail.id ? <Tooltip title="Expand">
                                              <IconButton>
                                                <CarrotDownIcon />
                                              </IconButton>
                                            </Tooltip> : <Tooltip title="Collapse">
                                              <IconButton>
                                                <CarrotUpIcon />
                                              </IconButton>
                                            </Tooltip> : <Box sx={{
                            py: '8px'
                          }}>
                                            <Typography>
                                              {detail.price_total ? numeral(detail.price_total).format('$0,0.00') : '$0.00'}
                                            </Typography>
                                          </Box>}
                                      </TableCell>
                                    </TableRow>

                                    {expandedTransactionDetail == detail.id && availableModels?.indexOf('transaction_detail') > -1 && transactionDetailsFields?.map((field, index) => {
                        const fieldValue = detail[field.id];
                        return fieldValue !== undefined && fieldValue !== null && fieldValue !== '' ? <TableRow sx={{
                          backgroundColor: '#FAFAFA'
                        }} key={field.id + index} onClick={() => handleTransactionDetailClick(detail.id)}>
                                            <TableCell>
                                              <Typography variant="tableHeader">{field.name} </Typography>
                                            </TableCell>

                                            <TableCell colSpan={4}>
                                              {field.type === 'datetime' ? moment(fieldValue).local().format('MMM D, YYYY h:mm A') : field.type === 'date' ? moment(fieldValue).format('MMM D, YYYY') : fieldValue}
                                            </TableCell>
                                          </TableRow> : field.id.substring(0, 13) === 'entity_data__' ? <TableRow key={index} sx={{
                          backgroundColor: '#FAFAFA'
                        }} onClick={() => handleTransactionDetailClick(detail.id)}>
                                            <TableCell>
                                              <Typography variant="tableHeader">{field.name} </Typography>
                                            </TableCell>
                                            <TableCell colSpan={4}>
                                              {getter(detail, field.id.split('__')) || getter(detail[index], field.id.split('__')) || <Typography sx={{
                              color: 'BEBEBE'
                            }}>--</Typography>}
                                            </TableCell>
                                          </TableRow> : field.id.substring(0, 15) === 'appended_data__' ? <TableRow key={index} sx={{
                          backgroundColor: '#FAFAFA'
                        }} onClick={() => handleTransactionDetailClick(detail.id)}>
                                            <TableCell>
                                              <Typography variant="tableHeader">{field.name} </Typography>
                                            </TableCell>
                                            <TableCell colSpan={4}>
                                              {getter(detail[index], field.id.split('__')) || <Typography sx={{
                              color: 'BEBEBE'
                            }}>--</Typography>}
                                            </TableCell>
                                          </TableRow> : <React.Fragment key={field.id} />;
                      })}
                                  </React.Fragment>)}

                              {transaction.details?.length > 4 && <TableRow sx={{
                      cursor: 'pointer'
                    }} onClick={() => setShowMoreDetails(!showMoreDetails)}>
                                  <TableCell colSpan={2} sx={{
                        py: '8px'
                      }}>
                                    <Typography sx={{
                          color: '#1D252D80'
                        }}>
                                      {'... ' + (transaction.details.length - 4) + (showMoreDetails ? ' less items' : ' more items')}
                                    </Typography>
                                  </TableCell>
                                  <TableCell colSpan={3} sx={{
                        py: '8px'
                      }}>
                                    <Box className="d-flex  justify-content-between">
                                      <span />
                                      <Button>
                                        <Stack sx={{
                              display: 'flex',
                              alignItems: 'center'
                            }} direction="row" spacing="10px">
                                          <Typography sx={{
                                color: '#3898D9',
                                textTransform: 'none'
                              }}>
                                            {showMoreDetails ? 'View less' : 'View all'}
                                          </Typography>
                                          {showMoreDetails ? <CarrotUpIcon /> : <CarrotDownIcon />}
                                        </Stack>
                                      </Button>
                                    </Box>
                                  </TableCell>
                                </TableRow>}
                            </TableBody>
                          </Table>}
                        <Table>
                          <colgroup>
                            <col style={{
                      width: '25%'
                    }} />
                            <col style={{
                      width: '25%'
                    }} />
                            <col style={{
                      width: '25%'
                    }} />
                            <col style={{
                      width: '25%'
                    }} />
                          </colgroup>
                          <TableBody>
                            <TableRow>
                              <TableCell colSpan={2} sx={{
                        borderBottom: 0
                      }}>
                                <Typography variant="tableHeader">Subtotal</Typography>
                              </TableCell>
                              <TableCell colSpan={2} sx={{
                        borderBottom: 0
                      }}>
                                <Box sx={{
                          display: 'flex',
                          justifyContent: 'right'
                        }}>
                                  <Typography>
                                    {transaction?.subTotal ? numeral(transaction?.subTotal).format('$0,0.00') : '$0.00'}
                                  </Typography>
                                </Box>
                              </TableCell>
                            </TableRow>

                            {transaction?.coupons?.map((coupon, index) => <TableRow key={coupon.id + index}>
                                <TableCell sx={{
                        borderBottom: 0
                      }}>
                                  <Typography variant="tableHeader">Coupon</Typography>
                                </TableCell>
                                <TableCell sx={{
                        borderBottom: 0
                      }}>
                                  <Typography>{coupon.code}</Typography>
                                </TableCell>
                                <TableCell sx={{
                        borderBottom: 0
                      }}>
                                  <Typography>{coupon.discount_type}</Typography>
                                </TableCell>
                                <TableCell sx={{
                        borderBottom: 0
                      }}>
                                  <Box sx={{
                          display: 'flex',
                          justifyContent: 'right'
                        }}>
                                    <Typography>
                                      {coupon?.discount_amount ? '- ' + numeral(coupon?.discount_amount).format('$0,0.00') : '- $0.00'}
                                    </Typography>
                                  </Box>
                                </TableCell>
                              </TableRow>)}

                            <TableRow>
                              <TableCell colSpan={2} sx={{
                        borderBottom: 0
                      }}>
                                <Typography variant="tableHeader"> Tax</Typography>
                              </TableCell>
                              <TableCell colSpan={2} sx={{
                        borderBottom: 0
                      }}>
                                <Box sx={{
                          display: 'flex',
                          justifyContent: 'right'
                        }}>
                                  <Typography>{transaction?.tax1 ? numeral(transaction?.tax1).format('$0,0.00') : '$0.00'}</Typography>
                                </Box>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell colSpan={2} sx={{
                        borderBottom: 0
                      }}>
                                <Typography variant="tableHeader">Total </Typography>
                              </TableCell>
                              <TableCell colSpan={2} sx={{
                        borderBottom: 0
                      }}>
                                <Box sx={{
                          display: 'flex',
                          justifyContent: 'right'
                        }}>
                                  <Typography variant="tableHeader">
                                    {transaction?.total ? numeral(transaction?.total).format('$0,0.00') : '$0.00'}
                                  </Typography>
                                </Box>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Paper>
                    </Box>)}
                </Carousel> : <Stack>
                  <Box sx={{
              display: 'flex',
              alignItems: 'center',
              alignContent: 'center',
              justifyContent: 'center',
              height: '100%',
              width: '100%'
            }}>
                    <Typography>Customer has no transactions</Typography>
                  </Box>
                </Stack>}
            </>}
        </Stack>
      </Stack>
    </>;
}