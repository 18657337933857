import React, { useContext, useState, useEffect } from 'react';
import { UserStateContext } from 'context/user-state-context';
import { DynamicTypeAheadMui, Icon, PageHeaderMui } from 'components';
import { Box, Button, IconButton, Paper, Stack, Tooltip, Typography, Divider, Skeleton, Popper, ClickAwayListener, DialogTitle, Dialog, DialogActions, DialogContent, CircularProgress, TextField, Chip, InputAdornment, Grid, FormControl, InputLabel, Select, FormGroup, FormControlLabel, Checkbox, OutlinedInput, MenuItem, ListItemText, Autocomplete } from '@mui/material';
import { AddIcon, CancelIcon, CarrotDownIcon, CheckCircleIcon, CloseIcon, DefaultIntegrationIcon, DeleteIcon, EditIcon, HandymanIcon, MoreVertIcon, ShieldPersonIcon, SyncIcon, AddCircleFilledIcon, UploadIcon, CheckIcon, CarrotUpIcon, DraftIcon, ArchiveIcon, HelpIcon } from 'components/mui';
import { history } from '../../../root.component';
import { snackbarService } from 'components/mui/snackbar/snackbar-service';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { createPrebuiltJourneyFromJourneyRevision, createPrebuiltJourneyVersionFromJourneyRevision, getAccounts, getBrands, getNAICS, getPrebuiltJourney, getPrebuiltJourneyVersion, getPrebuiltJourneyVersions, getPrebuiltJourneysTags, getTemplates, patchPrebuiltJourney, patchPrebuiltJourneyVersion, releasePrebuiltJourneyVersion, replacePrebuiltJourneyVersionAvailability, getJourney, getJourneys } from 'shared/common.api';
import { withStyles } from '@mui/styles';
import { ModalFileViewer2 } from 'components/file-viewer/modal-file-viewer.component-mui';
import { StaticTypeAheadMui } from 'components/static-type-ahead/static-type-ahead-mui.component';
import { getIntegrations } from '../../integrations/integrations.resource';
import ReactQuill, { Quill } from 'react-quill';
import quillEmoji from 'quill-emoji';
import 'react-quill/dist/quill.snow.css';
import 'quill-emoji/dist/quill-emoji.css';
import { Scoped } from 'kremling';
import styles from './pbj-admin-journeys-details.styles.scss';
import moment from 'moment';
import { styled, lighten, darken } from '@mui/system';
const EDIT = 'EDIT';
const CREATE = 'CREATE';
const NEWVERSION = 'NEWVERSION';
const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius: `14px`,
      '& fieldset': {}
    }
  }
})(TextField);
const CustomTooltip = withStyles({
  tooltip: {
    backgroundColor: 'white'
  }
})(Tooltip);
const fontFamilyArr = ['Arial', 'Bitter', 'Cabin', 'Georgia', 'Helvetica', 'Lato', 'Merriweather', 'Nexa', 'Open Sans', 'Oswald', 'Popins', 'PT Sans', 'PT Serif', 'Roboto', 'Ubuntu', 'Verdana'];
const {
  EmojiBlot,
  ToolbarEmoji
} = quillEmoji;
Quill.register({
  'formats/emoji': EmojiBlot,
  'modules/emoji-toolbar': ToolbarEmoji
}, true);
let fonts = Quill.import('attributors/style/font');
fonts.whitelist = fontFamilyArr;
Quill.register(fonts, true);
const fontSizeArr = ['10px', '11px', '12px', '14px', '16px', '18px', '24px', '30px', '36px', '48px', '60px', '72px'];
var Size = Quill.import('attributors/style/size');
Size.whitelist = fontSizeArr;
Quill.register(Size, true);
const modules = {
  toolbar: [[{
    header: '1'
  }, {
    header: '2'
  }, {
    font: fontFamilyArr
  }], [{
    size: fontSizeArr
  }], ['bold', 'italic', 'underline', 'strike', 'blockquote'], [{
    list: 'ordered'
  }, {
    list: 'bullet'
  }, {
    indent: '-1'
  }, {
    indent: '+1'
  }, 'link', 'image', 'emoji', 'clean']],
  'emoji-toolbar': true
};
const formats = ['header', 'font', 'size', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'indent', 'link', 'image', 'clean', 'emoji'];
const GroupHeader = styled('div')(({
  theme
}) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: theme.palette.primary.main,
  backgroundColor: lighten(theme.palette.primary.light, 0.85),
  ...theme.applyStyles('dark', {
    backgroundColor: darken(theme.palette.primary.main, 0.8)
  })
}));
const GroupItems = styled('ul')({
  padding: 0
});
export const PrebuiltAdminJourneysDetails = props => {
  const {
    user,
    asCompany,
    hasPermission
  } = useContext(UserStateContext);
  const [loading, setLoading] = useState(true);
  const [view, setView] = React.useState(window.location.pathname.includes('edit') ? EDIT : window.location.pathname.includes('add-new-version') ? NEWVERSION : CREATE);
  const [prebuiltJourney, setPrebuiltJourney] = useState({
    name: 'Untitled Prebuilt Journey'
  });
  const [newTagName, setNewTagName] = useState('');
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [unsavedChangesModalOpen, setUnsavedChangesModalOpen] = useState(false);
  const [companyTags, setCompanyTags] = React.useState([]);
  const [companyTagsMenuAnchorEl, setCompanyTagsMenuAnchorEl] = React.useState(null);
  const companyTagsOpen = Boolean(companyTagsMenuAnchorEl);
  const [addFileOpen, setAddFileOpen] = useState(null);
  const [showCompanySelect, setShowCompanySelect] = useState(true);
  const [companySelect, setCompanySelect] = useState(null);
  const [showJourneySelect, setShowJourneySelect] = useState(false);
  const [journeySelect, setJourneySelect] = useState(null);
  const [showRevisionSelect, setShowRevisionSelect] = useState(false);
  const [availabilitySelect, setAvailabilitySelect] = useState({
    industry: false,
    company: false,
    brand: false,
    account: false,
    integration: false
  });
  const [naics, setNaics] = useState([]);
  const [brands, setBrands] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [integrations, setIntegrations] = useState([]);
  const [selectVersionAnchorEl, setSelectVersionAnchorEl] = useState(null);
  const [selectVersionOpen, setSelectVersionOpen] = useState(false);
  const [versions, setVersions] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState(versions[0]);
  const [version, setVersion] = useState(null);
  const [availability, setAvailability] = useState(null);
  const [availabilityChanged, setAvailabilityChanged] = useState(false);
  const [versionChanged, setVersionChanged] = useState(false);
  const [prebuiltChanged, setPrebuiltChanged] = useState(false);
  const [saving, setSaving] = useState(false);
  const [helpAnchorEl, setHelpAnchorEl] = React.useState(null);
  const [helpData, setHelpData] = React.useState(null);
  const [releaseNotesModalOpen, setReleaseNotesModalOpen] = useState(false);
  const [renderIteration, setRenderIteration] = useState(0);
  const [companyOptions, setCompanyOptions] = useState([]);
  useEffect(() => {
    if (!user || !asCompany?.id) {
      return;
    }
    setLoading(true);
    setCompanyOptions(user?.companies || []);
    const id = props.match.params.id;
    const promises = [getPrebuiltJourneysTags(), getNAICS({
      limit: 999,
      ordering: 'title'
    }), getBrands({
      limit: 999,
      ordering: 'name'
    }), getAccounts({
      limit: 999,
      ordering: 'name'
    }), getIntegrations({
      limit: 999,
      ordering: 'name'
    })];
    if (view === EDIT || view === NEWVERSION) {
      promises.push(getPrebuiltJourney(id));
      promises.push(getPrebuiltJourneyVersions({
        prebuilt: id,
        ordering: 'version'
      }));
    }
    Promise.all(promises).then(([tagResult, naicsResult, brandsResult, accountsResult, integrationsResults, prebuiltResult, versionsResult]) => {
      setCompanyTags(tagResult.data.results);
      setNaics(naicsResult.data.results);
      setBrands(brandsResult.data.results);
      setAccounts(accountsResult.data.results);
      setIntegrations(integrationsResults.results);
      if (view === EDIT) {
        setVersion(prebuiltResult?.data?.active_version);
        setCompanySelect(prebuiltResult.data?.active_version?.company);
        setJourneySelect(prebuiltResult.data?.active_version?.journey_id);
        setPrebuiltJourney(prebuiltResult?.data);
        setAvailability({
          industry: prebuiltResult?.data?.active_version?.availability?.naics?.map(i => i.id) || null,
          company: prebuiltResult?.data?.active_version?.availability?.company || null,
          brand: prebuiltResult?.data?.active_version?.availability?.brand?.map(i => i.id) || null,
          account: prebuiltResult?.data?.active_version?.availability?.account?.map(i => i.id) || null,
          integration: prebuiltResult?.data?.active_version?.availability?.integration?.map(i => i.key) || null
        });
        setAvailabilitySelect({
          industry: !!prebuiltResult?.data?.active_version?.availability?.naics,
          company: !!prebuiltResult?.data?.active_version?.availability?.company,
          brand: !!prebuiltResult?.data?.active_version?.availability?.brand,
          account: !!prebuiltResult?.data?.active_version?.availability?.account,
          integration: !!prebuiltResult?.data?.active_version?.availability?.integration
        });
        setVersions(versionsResult.data.results);
        setSelectedVersion(versionsResult.data.results.find(v => v.id == prebuiltResult.data.active_version.id) || versionsResult.data.results[0]);
        setLoading(false);
      }
      if (view == NEWVERSION) {
        setVersions(versionsResult.data.results);
        setPrebuiltJourney(prebuiltResult?.data);
        handleSelectVersionClickAddNew(prebuiltResult?.data, versionsResult.data.results);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  }, [hasPermission, asCompany?.id, renderIteration]);
  useEffect(() => {
    if (!companySelect) {
      return;
    }
    setShowJourneySelect(true);
  }, [companySelect]);
  useEffect(() => {
    if (!journeySelect) {
      return;
    }
    setShowRevisionSelect(true);
  }, [journeySelect]);
  useEffect(() => {
    if (!showCompanySelect) {
      setShowCompanySelect(true);
    }
  }, [showCompanySelect]);
  useEffect(() => {
    console.log(version, 'version');
  }, [version]);
  useEffect(() => {
    console.log(availability, 'availability');
  }, [availability]);
  const handleNewTagClick = () => {
    if (newTagName?.length > 0 && !isExistingTag()) {
      const newTags = prebuiltJourney?.tags || [];
      newTags.push(newTagName);
      setPrebuiltJourney({
        ...prebuiltJourney,
        tags: newTags
      });
      setNewTagName('');
      setHasUnsavedChanges(true);
      setPrebuiltChanged(true);
    }
  };
  const handleDelete = (tagToDelete, index) => () => {
    if (loading || saving) {
      return;
    }
    const newTags = prebuiltJourney.tags;
    newTags.splice(index, 1);
    setPrebuiltJourney({
      ...prebuiltJourney,
      tags: newTags
    });
    setHasUnsavedChanges(true);
    setPrebuiltChanged(true);
  };
  const isExistingTag = () => {
    if (!prebuiltJourney?.tags || prebuiltJourney?.tags?.length == 0) {
      return false;
    }
    for (const tag of prebuiltJourney.tags) {
      if (tag.toLowerCase() == newTagName?.toLowerCase()) {
        return true;
      }
    }
    return false;
  };
  const handleCompanyTagsClick = event => {
    if (loading || saving) {
      return;
    }
    setCompanyTagsMenuAnchorEl(companyTagsOpen ? null : event.currentTarget);
  };
  const handleCompanyTagsClose = () => {
    setCompanyTagsMenuAnchorEl(null);
  };
  const addFile = file => {
    if (addFileOpen == 'secondary') {
      setVersion({
        ...version,
        thumbnail_secondary: file.url
      });
    } else {
      setVersion({
        ...version,
        thumbnail: file.url
      });
    }
    setVersionChanged(true);
    setHasUnsavedChanges(true);
    setAddFileOpen(null);
  };
  const handleSuccessSave = release => {
    if (release) {
      setSaving(false);
      setReleaseNotesModalOpen(true);
    } else {
      snackbarService.popup({
        type: 'success',
        message: 'Prebuilt Journey saved'
      });
      history.push(`/prebuilt/admin/journeys`);
    }
  };
  const getNewVersionNumber = overrideVersions => {
    const vs = overrideVersions || versions;
    if (vs.length == 0) {
      return '0.01';
    }
    const maxVersion = Math.max(...vs.map(v => parseFloat(v.version)));
    return (maxVersion + 0.01).toFixed(2);
  };
  const handleSaveAndClose = release => {
    setSaving(true);
    if (view === CREATE) {
      if (prebuiltJourney.id) {
        createPrebuiltJourneyVersionFromJourneyRevision({
          journey_revision: version.journey_revision,
          prebuilt: prebuiltJourney.id,
          version: version,
          prebuiltOverride: prebuiltChanged ? {
            tags: prebuiltJourney.tags
          } : null
        }).then(res => {
          const promises = [replacePrebuiltJourneyVersionAvailability({
            industry: availability?.industry,
            company: availability?.company?.map(c => c.id),
            brand: availability?.brand,
            account: availability?.account,
            integration: availability?.integration,
            prebuilt_journey_version: res.data.id
          })];
          if (release) {
            setPrebuiltJourney({
              ...prebuiltJourney,
              release_notes: res.data?.version + ':\n' + (prebuiltJourney.release_notes || '')
            });
            promises.push(releasePrebuiltJourneyVersion(res.data.id));
          }
          Promise.all(promises).then(() => {
            handleSuccessSave(release);
          });
        });
      } else {
        createPrebuiltJourneyFromJourneyRevision({
          journey_revision: version.journey_revision,
          prebuilt: {
            name: prebuiltJourney.name,
            tags: prebuiltJourney.tags
          },
          version: version
        }).then(res => {
          const promises = [replacePrebuiltJourneyVersionAvailability({
            industry: availability?.industry,
            company: availability?.company?.map(c => c.id),
            brand: availability?.brand,
            account: availability?.account,
            integration: availability?.integration,
            prebuilt_journey_version: res.data.active_version.id
          })];
          if (release) {
            promises.push(releasePrebuiltJourneyVersion(res.data.active_version.id));
            setPrebuiltJourney({
              ...res.data,
              release_notes: res.data.active_version?.version + ':\n'
            });
          }
          Promise.all(promises).then(() => {
            handleSuccessSave(release);
          });
        });
      }
    }
    if (view === EDIT) {
      const promises = [];
      if (prebuiltChanged) {
        promises.push(patchPrebuiltJourney(prebuiltJourney.id, {
          tags: prebuiltJourney.tags
        }));
      }
      if (versionChanged) {
        promises.push(patchPrebuiltJourneyVersion(version.id, version));
      }
      if (availabilityChanged) {
        promises.push(replacePrebuiltJourneyVersionAvailability({
          industry: availability?.industry,
          company: availability?.company?.map(c => c.id),
          brand: availability?.brand,
          account: availability?.account,
          integration: availability?.integration,
          prebuilt_journey_version: version.id
        }));
      }
      if (release && !version.released_when) {
        setPrebuiltJourney({
          ...prebuiltJourney,
          release_notes: version.version + ':\n' + (prebuiltJourney.release_notes || '')
        });
        promises.push(releasePrebuiltJourneyVersion(version.id));
      }
      Promise.all(promises).then(() => {
        handleSuccessSave(release);
      });
    }
  };
  const handleSelectVersionOpen = event => {
    if (loading || saving) {
      return;
    }
    setSelectVersionAnchorEl(event.currentTarget);
    setSelectVersionOpen(true);
  };
  const handleSelectVersionClose = () => {
    setSelectVersionOpen(false);
    setSelectVersionAnchorEl(null);
  };
  const handleSelectVersionClick = v => {
    handleSelectVersionClose();
    if (v.id == selectedVersion?.id) {
      return;
    }
    setLoading(true);
    getPrebuiltJourneyVersion(v.id).then(versionResult => {
      console.log(versionResult, 'versionResult');
      setAvailability({
        industry: versionResult?.data?.availability?.naics?.map(i => i.id) || null,
        company: versionResult?.data?.availability?.company || null,
        brand: versionResult?.data?.availability?.brand?.map(i => i.id) || null,
        account: versionResult?.data?.availability?.account?.map(i => i.id) || null,
        integration: versionResult?.data?.availability?.integration?.map(i => i.key) || null
      });
      setAvailabilitySelect({
        industry: !!versionResult?.data?.availability?.naics,
        company: !!versionResult?.data?.availability?.company,
        brand: !!versionResult?.data?.availability?.brand,
        account: !!versionResult?.data?.availability?.account,
        integration: !!versionResult?.data?.availability?.integration
      });
      setVersion(versionResult.data);
      setCompanySelect(versionResult.data.company);
      setJourneySelect(versionResult.data.journey_id); //might need whole object
      setSelectedVersion(v);
      setLoading(false);
    });
  };
  const autoFillMetaData = journey => {
    setVersionChanged(true);
    if (!journey) {
      setVersion({
        ...version,
        journey_revision: null
      });
      return;
    }
    setVersion({
      ...version,
      title: journey?.journey_title,
      journey_revision: journey.id
    });
    setPrebuiltJourney({
      ...prebuiltJourney,
      tags: journey?.tags
    });
    setPrebuiltChanged(true);
  };
  const handleSelectVersionClickAddNew = (data, versions) => {
    handleSelectVersionClose();
    setView(CREATE);
    const d = data || prebuiltJourney;
    setVersion({
      title: d?.active_version?.title || '',
      subtitle: d?.active_version?.subtitle || '',
      description: d?.active_version?.description || '',
      journey_revision: d?.active_version?.journey_revision || null,
      thumbnail: d?.active_version?.thumbnail || '',
      company: d?.active_version?.company || null,
      version: getNewVersionNumber(versions)
    });
    setCompanySelect(d?.active_version?.company);
    setJourneySelect(d?.active_version?.journey_id);
    setAvailability({
      industry: d?.active_version?.availability?.naics?.map(i => i.id) || null,
      company: d?.active_version?.availability?.company || null,
      brand: d?.active_version?.availability?.brand?.map(i => i.id) || null,
      account: d?.active_version?.availability?.account?.map(i => i.id) || null,
      integration: d?.active_version?.availability?.integration?.map(i => i.key) || null
    });
    setAvailabilitySelect({
      industry: !!d?.active_version?.availability?.naics,
      company: !!d?.active_version?.availability?.company,
      brand: !!d?.active_version?.availability?.brand,
      account: !!d?.active_version?.availability?.account,
      integration: !!d?.active_version?.availability?.integration
    });
    setShowCompanySelect(true);
    setShowJourneySelect(true);
    setShowRevisionSelect(true);
    setSelectedVersion('new');
    setVersionChanged(false);
    setPrebuiltChanged(false);
    setAvailabilityChanged(false);
    snackbarService.popup({
      type: 'success',
      message: 'The current active versions information has been copied to a new version. Some change should be made before saving/releasing to prevent identical versions.'
    });
  };
  const handleHelpPopupClick = (e, index) => {
    if (index == 'availability') {
      setHelpData({
        title: <Typography variant="tableHeader"> Journey Availability</Typography>,
        content: <Stack spacing={'20px'}>
            <Typography component="div">
              <Box fontWeight="700" display="inline" sx={{
              pr: '3px'
            }}>
                Nothing Checked:
              </Box>
              This journey will be available to all industries, companies, brands, accounts, and integrations
            </Typography>
            <Typography component="div">
              <Box fontWeight="700" display="inline" sx={{
              pr: '3px'
            }}>
                Restricting Availability
              </Box>
              By checking the boxes, you can restrict the availability of this journey to specific industries, companies, brands, accounts,
              and integrations. If you check that box, you must select at least one option from the dropdown.
            </Typography>
            {!!version?.archived_when && <Typography component="div">
                <Box fontWeight="700" display="inline" sx={{
              pr: '3px'
            }}>
                  Archived:
                </Box>
                This version has been archived. The existing availability choices are still visible for your information but cannot be
                changed. This version is hidden from all end users despite the availability settings.
              </Typography>}
          </Stack>
      });
    }
    setHelpAnchorEl(e.currentTarget);
  };
  const handleHelpClose = () => {
    setHelpAnchorEl(null);
    setHelpData(null);
  };
  const hasEnabledAvailabilityWithNoSelection = () => {
    return availabilitySelect.industry && !availability?.industry || availabilitySelect.company && !availability?.company || availabilitySelect.brand && !availability?.brand || availabilitySelect.account && !availability?.account || availabilitySelect.integration && !availability?.integration;
  };
  const handleReleaseNotesSave = () => {
    setSaving(true);
    patchPrebuiltJourney(prebuiltJourney.id, {
      release_notes: prebuiltJourney.release_notes
    }).then(() => {
      setReleaseNotesModalOpen(false);
      setSaving(false);
      snackbarService.popup({
        type: 'success',
        message: 'Prebuilt Journey saved and released'
      });
      history.push(`/prebuilt/admin/journeys`);
    });
  };
  const isSelected = (item, array) => {
    if (!array) {
      return false;
    }
    return array.find(i => i.id == item.id) ? true : false;
  };
  return <>
      <div className="wrapper" style={{
      height: loading ? '100vh' : 'auto'
    }}>
        <PageHeaderMui name={loading ? '' : version?.title || 'Select a journey'} type="Prebuilt Admin - Journeys" typeHistoryOverride={'/prebuilt/admin/journeys'} icon={<HandymanIcon />} />
        <Box sx={{
        p: '20px',
        display: 'flex',
        justifyContent: 'center'
      }}>
          <Paper sx={{
          borderRadius: '14px',
          maxWidth: '1080px',
          width: '800px',
          minHeight: '950px'
        }}>
            {loading ? <Box sx={{
            width: '100%',
            height: '100%'
          }}>
                <Box sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              justifyItems: 'center',
              alignItems: 'center'
            }}>
                  <CircularProgress />
                </Box>
              </Box> : <Stack sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}>
                <Stack sx={{
              py: '10px'
            }}>
                  <Stack>
                    <Box sx={{
                  px: '20px',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  pb: '10px'
                }}>
                      <Stack direction="row" spacing="20px">
                        <Box sx={{
                      display: 'flex',
                      alignItems: 'center'
                    }}>
                          <Typography sx={{
                        fontSize: '20px',
                        fontWeight: '300',
                        lineHeight: '28px'
                      }}>
                            Prebuilt Journey Details
                          </Typography>
                        </Box>

                        {!!version?.archived_when ? <>
                            <Stack direction={'row'} spacing={'24px'} sx={{
                        display: 'flex'
                      }}>
                              <Stack direction="row" spacing="8px" sx={{
                          p: '7px',
                          borderRadius: '8px',
                          backgroundColor: '#C5EAD7'
                        }}>
                                <Box sx={{
                            display: 'flex',
                            alignItems: 'center'
                          }}>
                                  <ArchiveIcon size="sm" fill="#3EB87B" />
                                </Box>
                                <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            pr: '4px'
                          }}>
                                  <Typography sx={{
                              color: '#3EB87B'
                            }}>Archived</Typography>
                                </Box>
                              </Stack>
                            </Stack>
                          </> : <>
                            {version?.released_when ? <Stack direction={'row'} spacing={'24px'} sx={{
                        display: 'flex'
                      }}>
                                <Stack direction="row" spacing="8px" sx={{
                          p: '7px',
                          borderRadius: '8px',
                          backgroundColor: '#C5EAD7'
                        }}>
                                  <Box sx={{
                            display: 'flex',
                            alignItems: 'center'
                          }}>
                                    <CheckCircleIcon size="sm" fill="#3EB87B" />
                                  </Box>
                                  <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            pr: '4px'
                          }}>
                                    <Typography sx={{
                              color: '#3EB87B'
                            }}>Released</Typography>
                                  </Box>
                                </Stack>
                              </Stack> : <Stack direction={'row'} spacing={'24px'} sx={{
                        display: 'flex'
                      }}>
                                <Stack direction="row" spacing="8px" sx={{
                          p: '7px',
                          borderRadius: '8px',
                          backgroundColor: '#C2E7FF'
                        }}>
                                  <Box sx={{
                            display: 'flex',
                            alignItems: 'center'
                          }}>
                                    <DraftIcon size="sm" fill="#3898D9" />
                                  </Box>
                                  <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            pr: '4px'
                          }}>
                                    <Typography sx={{
                              color: '#3898D9'
                            }}>Unreleased</Typography>
                                  </Box>
                                </Stack>
                              </Stack>}
                          </>}

                        {(view === EDIT || versions.length > 0) && <Stack direction="row" spacing="30px">
                            <Box sx={{
                        pl: '16px',
                        display: 'flex',
                        alignContent: 'center'
                      }}>
                              <Box onClick={e => handleSelectVersionOpen(e)} sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          borderRadius: '8px',
                          backgroundColor: '#F3F3F4',
                          border: '1px solid #F3F3F4',
                          cursor: 'pointer',
                          '&:hover': {
                            border: '1px solid #1D252D'
                          }
                        }}>
                                <Box sx={{
                            px: '8px'
                          }}>
                                  {selectedVersion != 'new' ? <Typography>Version: {selectedVersion?.version}</Typography> : <Typography>Add New Version</Typography>}
                                </Box>
                                <Box sx={{
                            pr: '16px'
                          }}>{selectVersionOpen ? <CarrotUpIcon /> : <CarrotDownIcon />}</Box>
                              </Box>
                            </Box>
                          </Stack>}
                      </Stack>

                      <Box>
                        <IconButton onClick={e => {
                      if (loading || saving) {
                        return;
                      }
                      if (hasUnsavedChanges) {
                        setUnsavedChangesModalOpen(true);
                      } else {
                        history.push(`/prebuilt/admin/journeys`);
                      }
                    }}>
                          <CloseIcon />
                        </IconButton>
                      </Box>
                    </Box>
                    <Divider />
                  </Stack>
                  <Stack sx={{
                px: '20px'
              }}>
                    <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  pb: '24px',
                  pt: '30px'
                }}>
                      <Typography sx={{
                    fontSize: '18px',
                    lineHeight: '26px',
                    fontWeight: 300
                  }}>Journey selection</Typography>
                    </Box>
                    <Stack direction="row" spacing="20px">
                      <Box sx={{
                    width: '50%'
                  }}>
                        {showCompanySelect ? <StaticTypeAheadMui id="company-select" items={user.companies} value={companySelect} disabled={!!version?.released_when || !!version?.archived_when || saving || view == EDIT && prebuiltJourney?.id} disableFreeSolo displayProperty="name" keyProperty="id" onChange={c => {
                      setShowJourneySelect(false);
                      setShowRevisionSelect(false);
                      setJourneySelect(null);
                      setCompanySelect(c?.id);
                      setVersion({
                        ...version,
                        journey_revision: null
                      });
                    }} sx={{
                      width: '100%'
                    }} label="Company" placeholder="Select a Company" /> : <FormControl disabled sx={{
                      width: '100%'
                    }}>
                            <InputLabel id="company-label">Company</InputLabel>
                            <Select sx={{
                        width: '100%'
                      }} labelId="company-label" id="company" value={''} label="Company"></Select>
                          </FormControl>}
                      </Box>

                      <Box sx={{
                    width: '50%'
                  }}>
                        {showJourneySelect ? <DynamicTypeAheadMui width="100%" disabled={!!version?.released_when || !!version?.archived_when || saving || view == EDIT && prebuiltJourney?.id} label="Journey" disablePortal highlightSubsearch disableFreeSolo getItems={getJourneys} getItemsFilters={{
                      company: companySelect,
                      archived: false,
                      view: 'details'
                    }} formatApiResults={data => {
                      console.log(data, 'get journeys data');
                      return data.results.map((item, index) => {
                        return {
                          ...item,
                          name: item.name + ' (Pub Revs: ' + item.revisions.filter(r => r.published_when != null)?.length + ')'
                        };
                      });
                    }} placeholder="Select a Journey" customNoOptionsText="No journeys found for this company" displayProperty="name" keyProperty="id" value={journeySelect} onChange={item => {
                      setShowRevisionSelect(false);
                      setVersion({
                        ...version,
                        journey_revision: null
                      });
                      setHasUnsavedChanges(true);
                      setJourneySelect(item?.id);
                    }} /> : <FormControl disabled sx={{
                      width: '100%'
                    }}>
                            <InputLabel id="journey-label">Journey</InputLabel>
                            <Select sx={{
                        width: '100%'
                      }} labelId="journey-label" id="journey" value={''} label="Journey"></Select>
                          </FormControl>}
                      </Box>
                    </Stack>
                    <Stack sx={{
                  pt: '24px'
                }} direction="row" spacing="20px">
                      <Box sx={{
                    width: '50%'
                  }}>
                        {showRevisionSelect ? <DynamicTypeAheadMui width="100%" disabled={!!version?.released_when || !!version?.archived_when || saving || view == EDIT && prebuiltJourney?.id} label="Revision" disablePortal highlightSubsearch disableFreeSolo getItems={getJourney} id={journeySelect} formatApiResults={data => {
                      return data.revisions.map((item, index) => {
                        return {
                          ...item,
                          name: !!item?.published_when ? 'Published-' + moment(item?.published_when).local().format('MMM D, YYYY h:mm A') : 'Draft',
                          journey_title: data?.name,
                          tags: data?.tags
                        };
                      });
                    }} placeholder="Select a Revision" customNoOptionsText="No revisions found for this company" displayProperty="name" keyProperty="id" value={version?.journey_revision} onChange={item => {
                      setVersionChanged(true);
                      if (view === CREATE) {
                        autoFillMetaData(item);
                      } else {
                        setVersion({
                          ...version,
                          journey_revision: item?.id
                        });
                      }
                    }} /> : <FormControl disabled sx={{
                      width: '100%'
                    }}>
                            <InputLabel id="revision-label">Revision</InputLabel>
                            <Select sx={{
                        width: '100%'
                      }} labelId="revision-label" id="revision" value={''} label="Revision"></Select>
                          </FormControl>}
                      </Box>
                      <Box sx={{
                    width: '50%'
                  }}> </Box>
                    </Stack>
                  </Stack>
                  <Stack sx={{
                px: '20px',
                pt: '24px'
              }}>
                    <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  pb: '24px'
                }}>
                      <Typography sx={{
                    fontSize: '18px',
                    lineHeight: '26px',
                    fontWeight: 300
                  }}>Prebuilt Journey info</Typography>
                    </Box>
                    <Stack spacing={'20px'}>
                      <Stack direction="row" spacing="20px">
                        <CustomTextField variant="outlined" sx={{
                      width: '30%'
                    }} placeholder="Title" label="Title" disabled={saving} autoComplete="new-password" value={version?.title || ''} onChange={e => {
                      setVersionChanged(true);
                      setHasUnsavedChanges(true);
                      setVersion({
                        ...version,
                        title: e.target.value
                      });
                    }} />
                        <CustomTextField variant="outlined" sx={{
                      width: '30%'
                    }} placeholder="Subtitle" label="Subtitle" autoComplete="new-password" disabled={saving} value={version?.subtitle || ''} onChange={e => {
                      setVersionChanged(true);
                      setHasUnsavedChanges(true);
                      setVersion({
                        ...version,
                        subtitle: e.target.value
                      });
                    }} />
                        <CustomTextField variant="outlined" sx={{
                      width: '30%'
                    }} placeholder="Version Number" label="Version Number" autoComplete="new-password" value={version?.version || getNewVersionNumber()} disabled={version?.released_when || !!version?.archived_when || saving} />
                      </Stack>
                      <Scoped css={styles}>
                        <Stack>
                          <Typography sx={{
                        opacity: '0.7'
                      }}>Description</Typography>
                          <Box className="ed" sx={{
                        pb: 1
                      }}>
                            <ReactQuill readOnly={saving} className="ed" theme="snow" value={version?.description || '<p><span style="font-family: Nexa; font-size: 16px;">A description</span></p>'} onChange={e => {
                          setVersion({
                            ...version,
                            description: e
                          });
                          setVersionChanged(true);
                          setHasUnsavedChanges(true);
                        }} modules={modules} formats={formats} />
                          </Box>
                        </Stack>
                      </Scoped>
                      <Stack display="flex" alignItems="center" direction="row" spacing="10px">
                        <Box onClick={handleCompanyTagsClick}>
                          <CustomTextField label="Add tag" placeholder="Type tag name" sx={{
                        width: '360px',
                        height: '56px'
                      }} disabled={saving} onChange={e => setNewTagName(e.target.value.toUpperCase())} onKeyPress={e => {
                        if (e.key === 'Enter') {
                          handleNewTagClick();
                        }
                      }} value={newTagName || ''} InputLabelProps={{
                        style: {
                          color: '#BEBEBE'
                        }
                      }} InputProps={{
                        startAdornment: <InputAdornment position="start">
                                  <Icon fill="#1D252D" name="custom-new_label" />
                                </InputAdornment>
                      }} />
                        </Box>

                        <Tooltip title={<div style={{
                      whiteSpace: 'pre-line'
                    }}>
                              {newTagName?.length == 0 ? 'Enter a tag name' : isExistingTag() ? 'Unable to add tag ' + newTagName + '\n It has already been added to ' + (prebuiltJourney?.title || 'New Prebuilt Journey') : 'Add ' + newTagName + ' to ' + (prebuiltJourney?.title || 'New Prebuilt Journey')}
                            </div>}>
                          <IconButton disabled={saving} sx={{
                        width: '40px',
                        height: '40px',
                        textAlign: 'center',
                        padding: 0,
                        backgroundColor: '#fff',
                        ':hover': {
                          backgroundColor: '#DAEFFF'
                        }
                      }} onClick={handleNewTagClick}>
                            <AddCircleFilledIcon fill={newTagName?.length > 0 && !isExistingTag() ? '#53A6D6' : '#53A6D64D'} />
                          </IconButton>
                        </Tooltip>

                        <Box display="flex" alignItems="center">
                          <Stack direction="row" spacing="10px" sx={{
                        flexWrap: 'wrap',
                        gap: 1
                      }}>
                            {prebuiltJourney?.tags?.length > 0 && prebuiltJourney.tags.map((tag, index) => <Chip key={index} label={tag} deleteIcon={<CloseOutlinedIcon sx={{
                          transform: 'scale(0.75)'
                        }} />} onDelete={handleDelete(tag, index)} />)}
                          </Stack>
                        </Box>
                      </Stack>
                      <Stack spacing="24px" direction="row" sx={{
                    width: '100%'
                  }}>
                        <Stack spacing="6px" sx={{
                      width: '50%'
                    }}>
                          <Box sx={{
                        display: 'flex',
                        alignItems: 'center'
                      }}>
                            <Typography sx={{
                          opacity: '0.7'
                        }}>Default Thumbnail</Typography>
                          </Box>
                          <Box sx={{
                        border: '1px dashed #3898D9',
                        borderRadius: '14px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}>
                            <Box sx={{
                          display: 'flex',
                          alignItems: 'center',
                          pl: '21px'
                        }}>
                              {version?.thumbnail ? <CustomTooltip title={<img src={version.thumbnail} style={{
                            maxHeight: '25vh',
                            maxWidth: '25vw'
                          }} />}>
                                  <img src={version.thumbnail} height="46px" />
                                </CustomTooltip> : <Box>
                                  <Typography sx={{
                              fontSize: '16px',
                              lineHeight: '22px',
                              fontWeight: 300
                            }}>Default Thumbnail</Typography>
                                </Box>}
                            </Box>
                            <Stack direction="row" spacing="8px" sx={{
                          p: '21px'
                        }}>
                              {version?.thumbnail && <Tooltip title="Remove image">
                                  <IconButton disabled={loading || saving} onClick={e => {
                              if (loading || saving) {
                                return;
                              }
                              setVersion({
                                ...version,
                                thumbnail: null
                              });
                              setVersionChanged(true);
                              setHasUnsavedChanges(true);
                            }}>
                                    <DeleteIcon fill="#3898D9" />
                                  </IconButton>
                                </Tooltip>}
                              <Tooltip title={version?.thumbnail ? 'Change Image' : 'Choose Image'}>
                                <IconButton disabled={loading || saving} onClick={e => {
                              if (loading || saving) {
                                return;
                              }
                              setAddFileOpen('primary');
                            }}>
                                  <UploadIcon fill="#3898D9" />
                                </IconButton>
                              </Tooltip>
                            </Stack>
                          </Box>
                        </Stack>
                        <Stack spacing="6px" sx={{
                      width: '50%'
                    }}>
                          <Box sx={{
                        display: 'flex',
                        alignItems: 'center'
                      }}>
                            <Typography sx={{
                          opacity: '0.7'
                        }}>Preview Thumbnail</Typography>
                          </Box>
                          <Box sx={{
                        border: '1px dashed #3898D9',
                        borderRadius: '14px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}>
                            <Box sx={{
                          display: 'flex',
                          alignItems: 'center',
                          pl: '21px'
                        }}>
                              {version?.thumbnail_secondary ? <CustomTooltip title={<img src={version.thumbnail_secondary} style={{
                            maxHeight: '25vh',
                            maxWidth: '25vw'
                          }} />}>
                                  <img src={version.thumbnail_secondary} height="46px" />
                                </CustomTooltip> : <Box>
                                  <Typography sx={{
                              fontSize: '16px',
                              lineHeight: '22px',
                              fontWeight: 300
                            }}>Preview Thumbnail</Typography>
                                </Box>}
                            </Box>
                            <Stack direction="row" spacing="8px" sx={{
                          p: '21px'
                        }}>
                              {version?.thumbnail_secondary && <Tooltip title="Remove image">
                                  <IconButton disabled={loading || saving} onClick={e => {
                              if (loading || saving) {
                                return;
                              }
                              setVersion({
                                ...version,
                                thumbnail_secondary: null
                              });
                              setVersionChanged(true);
                              setHasUnsavedChanges(true);
                            }}>
                                    <DeleteIcon fill="#3898D9" />
                                  </IconButton>
                                </Tooltip>}
                              <Tooltip title={version?.thumbnail_secondary ? 'Change Image' : 'Choose Image'}>
                                <IconButton disabled={loading || saving} onClick={e => {
                              if (loading || saving) {
                                return;
                              }
                              setAddFileOpen('secondary');
                            }}>
                                  <UploadIcon fill="#3898D9" />
                                </IconButton>
                              </Tooltip>
                            </Stack>
                          </Box>
                        </Stack>
                      </Stack>
                    </Stack>

                    <Stack sx={{
                  pb: '16px'
                }}>
                      <Stack direction={'row'} spacing="16px">
                        <Box sx={{
                      display: 'flex',
                      alignItems: 'center',
                      pb: '24px',
                      pt: '30px'
                    }}>
                          <Typography sx={{
                        fontSize: '18px',
                        lineHeight: '26px',
                        fontWeight: 300
                      }}>Journey availability</Typography>
                        </Box>
                        <Box sx={{
                      display: 'flex',
                      alignItems: 'center'
                    }}>
                          <IconButton onClick={e => handleHelpPopupClick(e, 'availability')}>
                            <HelpIcon />
                          </IconButton>
                        </Box>
                      </Stack>
                      <Stack spacing="24px">
                        <Stack direction="row" spacing="40px">
                          <Stack spacing="8px" direction="row" sx={{
                        width: '50%'
                      }}>
                            <Box sx={{
                          display: 'flex',
                          alignItems: 'center'
                        }}>
                              <Checkbox sx={{
                            '&.Mui-checked': {
                              padding: 0,
                              color: '#3898D9'
                            },
                            '& .MuiSvgIcon-root': {
                              fontSize: 24
                            },
                            padding: 0
                          }} checked={availabilitySelect.industry} disabled={saving || !!version?.archived_when} onChange={e => {
                            setAvailability({
                              ...availability,
                              industry: null
                            });
                            setAvailabilitySelect({
                              ...availabilitySelect,
                              industry: e.target.checked
                            });
                            setAvailabilityChanged(true);
                            setHasUnsavedChanges(true);
                          }} />
                            </Box>
                            <Box sx={{
                          width: '100%'
                        }}>
                              <FormControl disabled={!availabilitySelect.industry || saving || !!version?.archived_when} sx={{
                            width: '100%'
                          }}>
                                <InputLabel id="industry-label">{!availabilitySelect.industry ? 'All Industries' : 'Industry'}</InputLabel>
                                <Select sx={{
                              borderRadius: '14px'
                            }} labelId="industry-label" id="industry" multiple value={availability?.industry || []} onChange={event => {
                              const {
                                target: {
                                  value
                                }
                              } = event;
                              setAvailability({
                                ...availability,
                                industry: typeof value === 'string' ? value.split(',') : value
                              });
                              setAvailabilityChanged(true);
                              setHasUnsavedChanges(true);
                            }} input={<OutlinedInput label={!availabilitySelect.industry ? 'All Industries' : 'Industry'} />} renderValue={selected => {
                              if (selected.length === 0) {
                                return 'None';
                              }
                              if (selected.length === 1) {
                                return naics.find(e => e.id == selected[0])?.title;
                              }
                              return naics.find(e => e.id == selected[0])?.title + ' + ' + (selected.length - 1) + ' more';
                            }} MenuProps={{
                              PaperProps: {
                                style: {
                                  maxHeight: 224,
                                  width: 250
                                }
                              }
                            }}>
                                  {naics.map(industry => <MenuItem key={industry.id} value={industry.id}>
                                      <Checkbox checked={availability?.industry?.indexOf(industry.id) > -1} />
                                      <ListItemText primary={industry?.title} />
                                    </MenuItem>)}
                                </Select>
                              </FormControl>
                            </Box>
                          </Stack>
                          <Stack spacing="8px" direction="row" sx={{
                        width: '50%'
                      }}>
                            <Box sx={{
                          display: 'flex',
                          alignItems: 'center'
                        }}>
                              <Checkbox sx={{
                            padding: 0,
                            '&.Mui-checked': {
                              color: '#3898D9'
                            },
                            '& .MuiSvgIcon-root': {
                              fontSize: 24
                            }
                          }} checked={availabilitySelect.company} disabled={saving || !!version?.archived_when} onChange={e => {
                            setAvailabilityChanged(true);
                            setHasUnsavedChanges(true);
                            setAvailability({
                              ...availability,
                              company: null
                            });
                            setAvailabilitySelect({
                              ...availabilitySelect,
                              company: e.target.checked
                            });
                          }} />
                            </Box>
                            <Box sx={{
                          width: '100%'
                        }}>
                              <Autocomplete disabled={!availabilitySelect.company || saving || !!version?.archived_when} multiple loading={loading} id="companyavailablty-select" options={companyOptions.sort((a, b) => isSelected(b, availability?.company) - isSelected(a, availability?.company))} disableCloseOnSelect value={availability?.company || []} getOptionLabel={option => option.name} onChange={(event, newValue) => {
                            setAvailabilityChanged(true);
                            setHasUnsavedChanges(true);
                            setAvailability({
                              ...availability,
                              company: newValue
                            });
                          }} groupBy={option => {
                            return isSelected(option, availability?.company) ? 'Selected' : 'All';
                          }} renderGroup={params => {
                            return <li key={params.key}>
                                      <GroupHeader>{params.group}</GroupHeader>
                                      <GroupItems>{params.children}</GroupItems>
                                    </li>;
                          }} renderOption={(props, option, {
                            selected
                          }) => {
                            const {
                              key,
                              ...optionProps
                            } = props;
                            return <li key={option.id} {...optionProps}>
                                      <Checkbox icon={<CheckBoxOutlineBlankIcon />} checkedIcon={<CheckBoxIcon />} style={{
                                marginRight: 8
                              }} checked={!!availability?.company?.find(e => e.id == option.id)} />
                                      {option.name}
                                    </li>;
                          }} renderTags={(tagValue, getTagProps) => {
                            if (tagValue.length == 0) {
                              return <Chip label="None" />;
                            }
                            if (tagValue.length == 1) {
                              return <Stack direction="row" spacing="8px">
                                        <Box />
                                        <Typography sx={{
                                  width: '150px',
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden !important',
                                  textOverflow: 'ellipsis'
                                }}>
                                          {tagValue[0].name}
                                        </Typography>
                                      </Stack>;
                            }
                            return <Stack direction="row" spacing="8px">
                                      <Box />
                                      <Typography sx={{
                                width: '100px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden !important',
                                textOverflow: 'ellipsis'
                              }}>
                                        {tagValue.length + ' selected'}
                                      </Typography>
                                    </Stack>;
                          }} renderInput={params => <TextField {...params} label={!availabilitySelect.company ? 'All Companies' : 'Company'} />} />
                            </Box>
                          </Stack>
                        </Stack>
                        <Stack direction="row" spacing="40px">
                          <Stack spacing="8px" direction="row" sx={{
                        width: '50%'
                      }}>
                            <Box sx={{
                          display: 'flex',
                          alignItems: 'center'
                        }}>
                              <Checkbox disabled={saving || !!version?.archived_when} sx={{
                            padding: 0,
                            '&.Mui-checked': {
                              color: '#3898D9'
                            },
                            '& .MuiSvgIcon-root': {
                              fontSize: 24
                            }
                          }} checked={availabilitySelect.account} onChange={e => {
                            setAvailabilityChanged(true);
                            setHasUnsavedChanges(true);
                            setAvailability({
                              ...availability,
                              account: null
                            });
                            setAvailabilitySelect({
                              ...availabilitySelect,
                              account: e.target.checked
                            });
                          }} />
                            </Box>
                            <Box sx={{
                          width: '100%'
                        }}>
                              <FormControl disabled={!availabilitySelect.account || saving || !!version?.archived_when} sx={{
                            width: '100%'
                          }}>
                                <InputLabel id="account-label">{!availabilitySelect.account ? 'All Accounts' : 'Account'}</InputLabel>
                                <Select sx={{
                              borderRadius: '14px'
                            }} labelId="account-label" id="account" multiple value={availability?.account || []} onChange={event => {
                              const {
                                target: {
                                  value
                                }
                              } = event;
                              setAvailability({
                                ...availability,
                                account: typeof value === 'string' ? value.split(',') : value
                              });
                              setHasUnsavedChanges(true);
                              setAvailabilityChanged(true);
                            }} input={<OutlinedInput label={!availabilitySelect.account ? 'All accounts' : 'Account'} />} renderValue={selected => {
                              if (selected.length === 0) {
                                return 'None';
                              }
                              if (selected.length === 1) {
                                return accounts.find(e => e.id == selected[0])?.name;
                              }
                              return accounts.find(e => e.id == selected[0])?.name + ' + ' + (selected.length - 1) + ' more';
                            }} MenuProps={{
                              PaperProps: {
                                style: {
                                  maxHeight: 224,
                                  width: 250
                                }
                              }
                            }}>
                                  {accounts.map(account => <MenuItem key={account.id} value={account.id}>
                                      <Checkbox checked={availability?.account?.indexOf(account.id) > -1} />
                                      <ListItemText primary={account?.name} />
                                    </MenuItem>)}
                                </Select>
                              </FormControl>
                            </Box>
                          </Stack>
                          <Stack spacing="8px" direction="row" sx={{
                        width: '50%'
                      }}>
                            <Box sx={{
                          display: 'flex',
                          alignItems: 'center'
                        }}>
                              <Checkbox sx={{
                            padding: 0,
                            '&.Mui-checked': {
                              color: '#3898D9'
                            },
                            '& .MuiSvgIcon-root': {
                              fontSize: 24
                            }
                          }} checked={availabilitySelect.brand} disabled={saving || !!version?.archived_when} onChange={e => {
                            setAvailabilityChanged(true);
                            setHasUnsavedChanges(true);
                            setAvailability({
                              ...availability,
                              brand: null
                            });
                            setAvailabilitySelect({
                              ...availabilitySelect,
                              brand: e.target.checked
                            });
                          }} />
                            </Box>
                            <Box sx={{
                          width: '100%'
                        }}>
                              <FormControl disabled={!availabilitySelect.brand || saving || !!version?.archived_when} sx={{
                            width: '100%'
                          }}>
                                <InputLabel id="brand-label">{!availabilitySelect.brand ? 'All brands' : 'brand'}</InputLabel>
                                <Select sx={{
                              borderRadius: '14px'
                            }} labelId="brand-label" id="brand" multiple value={availability?.brand || []} onChange={event => {
                              const {
                                target: {
                                  value
                                }
                              } = event;
                              setAvailability({
                                ...availability,
                                brand: typeof value === 'string' ? value.split(',') : value
                              });
                              setAvailabilityChanged(true);
                              setHasUnsavedChanges(true);
                            }} input={<OutlinedInput label={!availabilitySelect.brand ? 'All brands' : 'brand'} />} renderValue={selected => {
                              if (selected.length === 0) {
                                return 'None';
                              }
                              if (selected.length === 1) {
                                return brands.find(e => e.id == selected[0])?.name;
                              }
                              return brands.find(e => e.id == selected[0])?.name + ' + ' + (selected.length - 1) + ' more';
                            }} MenuProps={{
                              PaperProps: {
                                style: {
                                  maxHeight: 224,
                                  width: 250
                                }
                              }
                            }}>
                                  {brands.map(brand => <MenuItem key={brand.id} value={brand.id}>
                                      <Checkbox checked={availability?.brand?.indexOf(brand.id) > -1} />
                                      <ListItemText primary={brand?.name} />
                                    </MenuItem>)}
                                </Select>
                              </FormControl>
                            </Box>
                          </Stack>
                        </Stack>
                        <Stack direction="row" spacing="40px">
                          <Stack spacing="8px" direction="row" sx={{
                        width: '50%'
                      }}>
                            <Box sx={{
                          display: 'flex',
                          alignItems: 'center'
                        }}>
                              <Checkbox sx={{
                            padding: 0,
                            '&.Mui-checked': {
                              color: '#3898D9'
                            },
                            '& .MuiSvgIcon-root': {
                              fontSize: 24
                            }
                          }} checked={availabilitySelect.integration} disabled={saving || !!version?.archived_when} onChange={e => {
                            setAvailabilityChanged(true);
                            setHasUnsavedChanges(true);
                            setAvailability({
                              ...availability,
                              integration: null
                            });
                            setAvailabilitySelect({
                              ...availabilitySelect,
                              integration: e.target.checked
                            });
                          }} />
                            </Box>
                            <Box sx={{
                          width: '100%'
                        }}>
                              <FormControl disabled={!availabilitySelect.integration || saving || !!version?.archived_when} sx={{
                            width: '100%'
                          }}>
                                <InputLabel id="integration-label">
                                  {!availabilitySelect.integration ? 'All integrations' : 'Integration'}
                                </InputLabel>
                                <Select sx={{
                              borderRadius: '14px'
                            }} labelId="integration-label" id="integration" multiple value={availability?.integration || []} onChange={event => {
                              const {
                                target: {
                                  value
                                }
                              } = event;
                              setAvailability({
                                ...availability,
                                integration: typeof value === 'string' ? value.split(',') : value
                              });
                              setAvailabilityChanged(true);
                              setHasUnsavedChanges(true);
                            }} input={<OutlinedInput label={!availabilitySelect.integration ? 'All integrations' : 'Integration'} />} renderValue={selected => {
                              if (selected.length === 0) {
                                return 'None';
                              }
                              if (selected.length === 1) {
                                return integrations.find(e => e.key == selected[0])?.name;
                              }
                              return integrations.find(e => e.key == selected[0])?.name + ' + ' + (selected.length - 1) + ' more';
                            }} MenuProps={{
                              PaperProps: {
                                style: {
                                  maxHeight: 224,
                                  width: 250
                                }
                              }
                            }}>
                                  {integrations.map(integration => <MenuItem key={integration.key} value={integration.key}>
                                      <Checkbox checked={availability?.integration?.indexOf(integration.key) > -1} />
                                      <ListItemText primary={integration?.name} />
                                    </MenuItem>)}
                                </Select>
                              </FormControl>
                            </Box>
                          </Stack>
                          <Stack spacing="8px" direction="row" sx={{
                        width: '50%'
                      }}>
                            {' '}
                          </Stack>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Stack>

                  <Stack>
                    <Divider />
                    <Box sx={{
                  p: '20px',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}>
                      <Box></Box>

                      <Stack direction="row" spacing="16px">
                        {!version?.released_when && !version?.archived_when && <Button disabled={saving || !hasUnsavedChanges || !version?.journey_revision || hasEnabledAvailabilityWithNoSelection()} endIcon={saving && <CircularProgress sx={{
                      color: '#FFF'
                    }} size={'20px'} />} sx={{
                      backgroundColor: '#F3F3F4',
                      '&:hover': {
                        backgroundColor: '#F3F3F4'
                      }
                    }} variant="contained" onClick={e => {
                      handleSaveAndClose();
                    }}>
                            <Box sx={{
                        py: '5px',
                        px: '12px'
                      }}>
                              <Typography sx={{
                          color: saving || !hasUnsavedChanges || !version?.journey_revision || hasEnabledAvailabilityWithNoSelection() ? '#fff' : '#3898D9'
                        }}>
                                Save and Close
                              </Typography>
                            </Box>
                          </Button>}
                        {!version?.released_when && !version?.archived_when && <Button disabled={saving || !version?.journey_revision || hasEnabledAvailabilityWithNoSelection() || selectedVersion == 'new' && !prebuiltChanged && !availabilityChanged && !versionChanged} endIcon={saving && <CircularProgress sx={{
                      color: '#FFF'
                    }} size={'20px'} />} sx={{
                      backgroundColor: '#3898D9',
                      '&:hover': {
                        backgroundColor: '#3898D9'
                      }
                    }} variant="contained" onClick={e => {
                      handleSaveAndClose(true);
                    }}>
                            <Box sx={{
                        py: '5px',
                        px: '12px'
                      }}>
                              <Typography sx={{
                          color: '#fff'
                        }}> Release</Typography>
                            </Box>
                          </Button>}

                        {(!!version?.released_when || !!version?.archived_when) && <Button disabled={saving || !hasUnsavedChanges || !version?.journey_revision || hasEnabledAvailabilityWithNoSelection()} endIcon={saving && <CircularProgress sx={{
                      color: '#FFF'
                    }} size={'20px'} />} sx={{
                      backgroundColor: '#3898D9',
                      '&:hover': {
                        backgroundColor: '#3898D9'
                      }
                    }} variant="contained" onClick={e => {
                      handleSaveAndClose();
                    }}>
                            <Box sx={{
                        py: '5px',
                        px: '12px'
                      }}>
                              <Typography sx={{
                          color: '#fff'
                        }}> Update</Typography>
                            </Box>
                          </Button>}
                      </Stack>
                    </Box>
                  </Stack>
                </Stack>
              </Stack>}
          </Paper>
        </Box>
      </div>

      <Popper anchorEl={companyTagsMenuAnchorEl} sx={{
      zIndex: 200
    }} open={companyTagsOpen} onClose={handleCompanyTagsClose} placement="bottom-start">
        <ClickAwayListener onClickAway={handleCompanyTagsClose}>
          <Paper elevation={0} sx={{
          borderRadius: '16px',
          overflowY: 'scroll',
          width: '360px',
          maxHeight: '40vh',
          filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))',
          scrollbarWidth: 'thin',
          '&::-webkit-scrollbar': {
            width: '0.4em'
          },
          '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
            marginTop: '20px',
            marginBottom: '20px'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888'
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#555'
          },
          filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))',
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 200
          }
        }}>
            <Box sx={{
            p: 1
          }}>
              <Grid sx={{
              p: 1
            }} container justifyContent="left">
                <Typography>Existing company tags:</Typography>
              </Grid>

              <Box display="flex" flexDirection="column">
                {companyTags.map(opt => <Button variant="text" fullWidth key={opt} sx={{
                cursor: 'pointer',
                height: '32px',
                color: '#1D252D',
                textTransform: 'none',
                justifyContent: 'flex-start',
                px: 2
              }} onClick={e => {
                setNewTagName(opt.toUpperCase());
                handleCompanyTagsClose();
              }}>
                    <Typography sx={{
                  pl: 1
                }}> {opt}</Typography>
                  </Button>)}
              </Box>
            </Box>
          </Paper>
        </ClickAwayListener>
      </Popper>

      <ModalFileViewer2 open={!!addFileOpen} onSubmit={addFile} onClose={() => {
      setAddFileOpen(null);
    }} />

      <Popper anchorEl={selectVersionAnchorEl} open={!!selectVersionOpen} onClose={handleSelectVersionClose} placement="bottom" sx={{
      zIndex: 200
    }}>
        <ClickAwayListener onClickAway={handleSelectVersionClose}>
          <Paper elevation={0} sx={{
          borderRadius: '16px',
          overflow: 'visible'
        }}>
            <Box sx={{
            py: '16px'
          }}>
              <Stack>
                {versions.map(version => <Box key={version.id} onClick={() => {
                handleSelectVersionClick(version);
              }} sx={{
                padding: '10px 16px',
                cursor: 'pointer',
                backgroundColor: version?.id == selectedVersion?.id ? '#3898D933' : '#fff',
                '&:hover': {
                  backgroundColor: version?.id == selectedVersion?.id ? '#3898D933' : '#F3F3F4'
                }
              }}>
                    <Stack direction={'row'} spacing="16px">
                      {version?.id == selectedVersion?.id ? <CheckIcon /> : <Box sx={{
                    p: '12px'
                  }}></Box>}
                      <Stack direction="row">
                        {!!version.archived_when && <Box sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}>
                            <Typography sx={{
                        fontStyle: 'italic'
                      }}>
                              Archived -
                            </Typography>
                          </Box>}
                        <Box sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}>
                          <Typography sx={{
                        width: '240px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden !important',
                        textOverflow: 'ellipsis',
                        fontStyle: version.archived_when ? 'italic' : 'inherit'
                      }}>
                            Version: {version.version} - {version.title}
                          </Typography>
                        </Box>
                      </Stack>
                    </Stack>
                  </Box>)}

                {!prebuiltJourney?.archived_when && <Box onClick={() => {
                setView(NEWVERSION);
                handleSelectVersionClose();
                setRenderIteration(i => i + 1);
              }} sx={{
                padding: '10px 16px',
                cursor: 'pointer',
                backgroundColor: '#fff',
                '&:hover': {
                  backgroundColor: '#F3F3F4'
                }
              }}>
                    <Stack direction={'row'} spacing="16px">
                      <Box sx={{
                    p: '12px'
                  }}></Box>
                      <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}>
                        <Typography sx={{
                      width: '240px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden !important',
                      textOverflow: 'ellipsis'
                    }}>
                          Add New Version
                        </Typography>
                      </Box>
                    </Stack>
                  </Box>}
              </Stack>
            </Box>
          </Paper>
        </ClickAwayListener>
      </Popper>

      <Dialog scroll="paper" PaperProps={{
      style: {
        borderRadius: '28px'
      }
    }} open={!!unsavedChangesModalOpen} onClose={(e, r) => {
      setUnsavedChangesModalOpen(null);
    }}>
        <DialogTitle sx={{
        m: 0,
        p: 2,
        fontSize: 'medium'
      }}>
          <Stack spacing={'20px'}>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Stack direction="row" spacing={'10px'}>
                <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                  <Typography sx={{
                  fontWeight: '300',
                  lineHeight: '30px',
                  fontSize: '24px',
                  display: 'flex',
                  alignItems: 'center'
                }} variant="h4" component="span">
                    Unsaved Changes
                  </Typography>
                </Box>
              </Stack>

              <IconButton onClick={() => {
              setUnsavedChangesModalOpen(null);
            }}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Divider />
          </Stack>
        </DialogTitle>

        <DialogContent sx={{
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
          width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
          marginTop: '20px',
          marginBottom: '20px'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#888'
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555'
        }
      }}>
          <Stack sx={{
          pt: 1
        }} spacing={'24px'}>
            <Box>You have unsaved changes. Do you want to discard them? </Box>
          </Stack>
        </DialogContent>

        <DialogActions sx={{
        pt: 1,
        pb: 2
      }}>
          <Stack sx={{
          pr: '24px'
        }} direction={'row'} spacing={'20px'}>
            <Button onClick={() => {
            setUnsavedChangesModalOpen(null);
          }} variant="contained" sx={{
            backgroundColor: '#F3F3F4',
            '&:hover': {
              backgroundColor: '#F3F3F4'
            }
          }}>
              <Box sx={{
              py: '5px',
              px: '12px'
            }}>
                <Typography sx={{
                color: '#3898D9'
              }}>Cancel </Typography>
              </Box>
            </Button>
            <Button onClick={() => {
            history.push(`/prebuilt/admin/journeys`);
          }} variant="contained" sx={{
            backgroundColor: '#3898D9',
            '&:hover': {
              backgroundColor: '#3898D9'
            }
          }}>
              <Box sx={{
              py: '5px',
              px: '12px'
            }}>
                <Typography sx={{
                color: '#fff'
              }}> Discard</Typography>
              </Box>
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>

      <Popper anchorEl={helpAnchorEl} open={!!helpData} onClose={handleHelpClose} placement="right-start" sx={{
      zIndex: 2100
    }}>
        <ClickAwayListener onClickAway={handleHelpClose}>
          <Paper elevation={0} sx={{
          borderRadius: '16px',
          overflow: 'visible',
          filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))',
          width: '312px'
        }}>
            <Stack>
              <Box sx={{
              padding: '16px'
            }}>{helpData?.title} </Box>
              <Box sx={{
              px: '16px'
            }}> {helpData?.content}</Box>
              <Box sx={{
              py: '16px'
            }}>
                <Button sx={{
                display: 'flex',
                justifyItems: 'left',
                textTransform: 'none'
              }} onClick={handleHelpClose}>
                  {helpData?.buttonText || ' Got it'}
                </Button>
              </Box>
            </Stack>
          </Paper>
        </ClickAwayListener>
      </Popper>

      <Dialog scroll="paper" PaperProps={{
      style: {
        borderRadius: '28px'
      }
    }} open={!!releaseNotesModalOpen} onClose={(e, r) => {
      if (r != 'backdropClick') {
        setReleaseNotesModalOpen(false);
      }
    }}>
        <DialogTitle sx={{
        m: 0,
        p: 2,
        fontSize: 'medium'
      }}>
          <Stack spacing={'20px'}>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Stack direction="row" spacing={'10px'}>
                <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                  <Typography sx={{
                  fontWeight: '300',
                  lineHeight: '30px',
                  fontSize: '24px',
                  display: 'flex',
                  alignItems: 'center'
                }} variant="h4" component="span">
                    Release Notes
                  </Typography>
                </Box>
              </Stack>
            </Box>
            <Divider />
          </Stack>
        </DialogTitle>

        <DialogContent sx={{
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
          width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
          marginTop: '20px',
          marginBottom: '20px'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#888'
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555'
        }
      }}>
          <Stack sx={{
          pt: 1
        }} spacing={'24px'}>
            <Box>
              <Typography>Add some release notes for this version</Typography>
            </Box>
            <CustomTextField multiline sx={{
            width: '500px'
          }} rows={10} value={prebuiltJourney.release_notes} onChange={e => {
            setPrebuiltJourney({
              ...prebuiltJourney,
              release_notes: e.target.value
            });
          }} />
          </Stack>
        </DialogContent>

        <DialogActions sx={{
        pt: 1,
        pb: 2
      }}>
          <Stack sx={{
          pr: '24px'
        }} direction={'row'} spacing={'20px'}>
            <Button onClick={() => {
            handleReleaseNotesSave();
          }} disabled={saving} endIcon={saving && <CircularProgress sx={{
            color: '#FFF'
          }} size={'20px'} />} variant="contained" sx={{
            backgroundColor: '#3898D9',
            '&:hover': {
              backgroundColor: '#3898D9'
            }
          }}>
              <Box sx={{
              py: '5px',
              px: '12px'
            }}>
                <Typography sx={{
                color: '#fff'
              }}> Save</Typography>
              </Box>
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </>;
};