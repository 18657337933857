import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import { Scoped, a } from 'kremling';
import { func, bool, string, oneOf, number } from 'prop-types';
import { Transition } from 'react-spring/renderprops';
import styles from './modal.styles.scss';
import { Button } from '../button/button.component';
export class Modal extends Component {
  static propTypes = {
    open: bool,
    title: string,
    allowBackdropClick: bool,
    onClose: func,
    size: oneOf(['sm', 'md', 'lg', 'xl', 'xlvw', 'xxl']),
    type: oneOf(['dialog', 'scroll', 'overlay']),
    closeBtn: bool,
    height: oneOf(['none', 'sm', 'md', 'lg']),
    level: number
  };
  static defaultProps = {
    closeBtn: true,
    size: 'md',
    type: 'dialog',
    height: 'none',
    level: 1
  };
  render() {
    const {
      allowBackdropClick,
      children,
      open,
      title,
      onClose,
      size,
      height,
      closeBtn,
      type,
      level
    } = this.props;
    return createPortal(<Scoped css={styles}>
        <div>
          <>
            <Transition items={open} from={{
            opacity: 0
          }} enter={{
            opacity: 1
          }} leave={{
            opacity: 0
          }}>
              {open => open && (props => <div className="modal-backdrop" style={{
              ...props,
              zIndex: 899 + 100 * level
            }} />)}
            </Transition>
            <Transition items={open} from={{
            opacity: 0,
            transform: `translateY(-10rem)`
          }} enter={{
            opacity: 1,
            transform: `translateY(0)`
          }} leave={{
            opacity: 0,
            transform: `translateY(-10rem)`
          }} config={{
            mass: 0.5,
            tension: 120,
            friction: 10
          }}>
              {open => open && (props => <div style={{
              ...props,
              zIndex: 900 + 100 * level
            }} className={a('modal').m('modal--scroll', type === 'scroll').m('modal--overlay', type === 'overlay')} onMouseDown={allowBackdropClick ? onClose : null}>
                    <div className={`modal__inner modal--${size} height--${height}`} onMouseDown={e => e.stopPropagation()}>
                      {closeBtn && <Button className="modal__head-close" small actionType="flat" icon="fa-regular-times" onClick={onClose} />}
                      {title && <div className="modal__title">{title}</div>}

                      {children}
                    </div>
                  </div>)}
            </Transition>
          </>
        </div>
      </Scoped>, document.body);
  }
}