export const topolPremadeBlocks = {
  headers: [{
    img: 'menu1',
    definition: [{
      tagName: 'mj-section',
      attributes: {
        'full-width': !1,
        padding: '9px 0px 9px 0px',
        'background-color': '#000000'
      },
      type: null,
      children: [{
        tagName: 'mj-column',
        attributes: {
          width: '33.333333%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-social',
          attributes: {
            padding: '10px 10px 10px 30px',
            align: 'left',
            'text-mode': 'false',
            'icon-size': '25px',
            containerWidth: 200
          },
          children: [{
            attributes: {
              'background-color': 'transparent',
              href: 'https://www.facebook.com/PROFILE',
              name: 'Facebook',
              src: 'https://s3-eu-west-1.amazonaws.com/ecomail-assets/editor/social-icos/simplewhite/facebook.png'
            },
            tagName: 'mj-social-element'
          }, {
            attributes: {
              'background-color': 'transparent',
              href: 'https://www.twitter.com/PROFILE',
              name: 'Twitter',
              src: 'https://s3-eu-west-1.amazonaws.com/ecomail-assets/editor/social-icos/simplewhite/twitter.png'
            },
            tagName: 'mj-social-element'
          }, {
            attributes: {
              'background-color': 'transparent',
              href: 'https://www.instagram.com/PROFILE',
              name: 'Instagram',
              src: 'https://s3-eu-west-1.amazonaws.com/ecomail-assets/editor/social-icos/simplewhite/instagram.png'
            },
            tagName: 'mj-social-element'
          }],
          uid: 'H1lqIiX4lm'
        }],
        uid: 'SJ3I0XVx7'
      }, {
        tagName: 'mj-column',
        attributes: {
          width: '33.333333%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-image',
          attributes: {
            src: 'https://storage.googleapis.com/jan50/blackberrylogo.png',
            padding: '19px 10px 10px 10px',
            alt: '',
            href: '',
            containerWidth: 200,
            width: 100,
            widthPercent: 50
          },
          uid: 'rkEyL-HeQ'
        }],
        uid: 'r1e280m4xQ'
      }, {
        tagName: 'mj-column',
        attributes: {
          width: '33.333333%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-spacer',
          attributes: {
            height: 15,
            containerWidth: 200
          },
          uid: 'rJfqLiXEgm'
        }],
        uid: 'B1W380QVxX'
      }],
      layout: 1,
      backgroundColor: '',
      backgroundImage: '',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      uid: 'rkqIjQNe7'
    }]
  }, {
    img: 'menu2',
    definition: [{
      tagName: 'mj-section',
      attributes: {
        'full-width': !1,
        padding: '9px 0px 9px 0px',
        'background-color': '#FFFFFF'
      },
      type: null,
      children: [{
        tagName: 'mj-column',
        attributes: {
          width: '33.333333%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-social',
          attributes: {
            padding: '10px 10px 10px 30px',
            'text-mode': 'false',
            'icon-size': '25px',
            align: 'left',
            containerWidth: 200
          },
          children: [{
            attributes: {
              'background-color': 'transparent',
              href: 'https://www.facebook.com/PROFILE',
              name: 'Facebook',
              src: 'https://s3-eu-west-1.amazonaws.com/ecomail-assets/editor/social-icos/simplegrey/facebook.png'
            },
            tagName: 'mj-social-element'
          }, {
            attributes: {
              'background-color': 'transparent',
              href: 'https://www.twitter.com/PROFILE',
              name: 'Twitter',
              src: 'https://s3-eu-west-1.amazonaws.com/ecomail-assets/editor/social-icos/simplegrey/twitter.png'
            },
            tagName: 'mj-social-element'
          }, {
            attributes: {
              'background-color': 'transparent',
              href: 'https://www.instagram.com/PROFILE',
              name: 'Instagram',
              src: 'https://s3-eu-west-1.amazonaws.com/ecomail-assets/editor/social-icos/simplegrey/instagram.png'
            },
            tagName: 'mj-social-element'
          }],
          uid: 'SyexEsQNxX'
        }],
        uid: 'HkM3IA7VgQ'
      }, {
        tagName: 'mj-column',
        attributes: {
          width: '33.333333%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-image',
          attributes: {
            src: 'https://storage.googleapis.com/jan50/logo-blackberry.jpg',
            padding: '13px 10px 10px 10px',
            alt: '',
            href: '',
            containerWidth: 200,
            width: 100,
            widthPercent: 50
          },
          uid: 'H1YkLbBxX'
        }],
        uid: 'rymhURQVxX'
      }, {
        tagName: 'mj-column',
        attributes: {
          width: '33.333333%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-spacer',
          attributes: {
            height: 15,
            containerWidth: 200
          },
          uid: 'SyGeVom4xX'
        }],
        uid: 'BkVnIC74g7'
      }],
      layout: 1,
      backgroundColor: '',
      backgroundImage: '',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      uid: 'Bkg4jQNem'
    }]
  }, {
    img: 'menu3',
    definition: [{
      tagName: 'mj-section',
      attributes: {
        'full-width': !1,
        padding: '9px 0px 9px 0px',
        'background-color': '#000000'
      },
      type: null,
      children: [{
        tagName: 'mj-column',
        attributes: {
          width: '33.333333%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-spacer',
          attributes: {
            height: 15,
            containerWidth: 200
          },
          uid: 'rkeydjQVlQ'
        }],
        uid: 'SJrhLAmVem'
      }, {
        tagName: 'mj-column',
        attributes: {
          width: '33.333333%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-image',
          attributes: {
            src: 'https://storage.googleapis.com/jan50/blackberrylogo.png',
            padding: '19px 10px 10px 10px',
            alt: '',
            href: '',
            containerWidth: 200,
            width: 100,
            widthPercent: 50
          },
          uid: 'H1klIZBxm'
        }],
        uid: 'SkI2I0m4xQ'
      }, {
        tagName: 'mj-column',
        attributes: {
          width: '33.333333%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-social',
          attributes: {
            padding: '10px 30px 10px 10px',
            'text-mode': 'false',
            'icon-size': '25px',
            align: 'right',
            containerWidth: 200
          },
          children: [{
            attributes: {
              'background-color': 'transparent',
              href: 'https://www.facebook.com/PROFILE',
              name: 'Facebook',
              src: 'https://s3-eu-west-1.amazonaws.com/ecomail-assets/editor/social-icos/simplewhite/facebook.png'
            },
            tagName: 'mj-social-element'
          }, {
            attributes: {
              'background-color': 'transparent',
              href: 'https://www.twitter.com/PROFILE',
              name: 'Twitter',
              src: 'https://s3-eu-west-1.amazonaws.com/ecomail-assets/editor/social-icos/simplewhite/twitter.png'
            },
            tagName: 'mj-social-element'
          }, {
            attributes: {
              'background-color': 'transparent',
              href: 'https://www.instagram.com/PROFILE',
              name: 'Instagram',
              src: 'https://s3-eu-west-1.amazonaws.com/ecomail-assets/editor/social-icos/simplewhite/instagram.png'
            },
            tagName: 'mj-social-element'
          }],
          uid: 'S1fJuiQNg7'
        }],
        uid: 'HywnL0mNxm'
      }],
      layout: 1,
      backgroundColor: '',
      backgroundImage: '',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      uid: 'Sy1Oo7Nxm'
    }]
  }, {
    img: 'menu4',
    definition: [{
      tagName: 'mj-section',
      attributes: {
        'full-width': !1,
        padding: '9px 0px 9px 0px',
        'background-color': '#FFFFFF'
      },
      type: null,
      children: [{
        tagName: 'mj-column',
        attributes: {
          width: '33.333333%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-spacer',
          attributes: {
            height: 15,
            containerWidth: 200
          },
          uid: 'BkeKHsmVxQ'
        }],
        uid: 'Sy_2UCmVe7'
      }, {
        tagName: 'mj-column',
        attributes: {
          width: '33.333333%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-image',
          attributes: {
            src: 'https://storage.googleapis.com/jan50/logo-blackberry.jpg',
            padding: '12px 10px 10px 10px',
            alt: '',
            href: '',
            containerWidth: 200,
            width: 100,
            widthPercent: 50
          },
          uid: 'S1qyLWBlm'
        }],
        uid: 'rkFhI07Ne7'
      }, {
        tagName: 'mj-column',
        attributes: {
          width: '33.333333%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-social',
          attributes: {
            padding: '10px 30px 10px 10px',
            'text-mode': 'false',
            'icon-size': '25px',
            align: 'right',
            containerWidth: 200
          },
          children: [{
            attributes: {
              'background-color': 'transparent',
              href: 'https://www.facebook.com/PROFILE',
              name: 'Facebook',
              src: 'https://s3-eu-west-1.amazonaws.com/ecomail-assets/editor/social-icos/simplegrey/facebook.png'
            },
            tagName: 'mj-social-element'
          }, {
            attributes: {
              'background-color': 'transparent',
              href: 'https://www.twitter.com/PROFILE',
              name: 'Twitter',
              src: 'https://s3-eu-west-1.amazonaws.com/ecomail-assets/editor/social-icos/simplegrey/twitter.png'
            },
            tagName: 'mj-social-element'
          }, {
            attributes: {
              'background-color': 'transparent',
              href: 'https://www.instagram.com/PROFILE',
              name: 'Instagram',
              src: 'https://s3-eu-west-1.amazonaws.com/ecomail-assets/editor/social-icos/simplegrey/instagram.png'
            },
            tagName: 'mj-social-element'
          }],
          uid: 'rJGKrs74xQ'
        }],
        uid: 'Hkch8CX4lm'
      }],
      layout: 1,
      backgroundColor: '',
      backgroundImage: '',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      uid: 'BJFHjQEgX'
    }]
  }, {
    img: 'menu5',
    definition: [{
      tagName: 'mj-section',
      attributes: {
        'full-width': !1,
        padding: '9px 0px 9px 0px',
        'background-color': '#000000'
      },
      type: null,
      children: [{
        tagName: 'mj-column',
        attributes: {
          width: '33.333333%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-image',
          attributes: {
            src: 'https://storage.googleapis.com/jan50/blackberrylogo.png',
            padding: '19px 10px 10px 30px',
            alt: '',
            href: '',
            containerWidth: 200,
            width: 100,
            widthPercent: 50,
            align: 'left'
          },
          uid: 'BkeeIWSxX'
        }],
        uid: 'rJo3UA74xm'
      }, {
        tagName: 'mj-column',
        attributes: {
          width: '33.333333%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-spacer',
          attributes: {
            height: 15,
            containerWidth: 200
          },
          uid: 'ByZ5djmNx7'
        }],
        uid: 'HyhhIAmElm'
      }, {
        tagName: 'mj-column',
        attributes: {
          width: '33.333333%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-social',
          attributes: {
            padding: '10px 30px 10px 10px',
            'text-mode': 'false',
            'icon-size': '25px',
            align: 'right',
            containerWidth: 200
          },
          children: [{
            attributes: {
              'background-color': 'transparent',
              href: 'https://www.facebook.com/PROFILE',
              name: 'Facebook',
              src: 'https://s3-eu-west-1.amazonaws.com/ecomail-assets/editor/social-icos/simplewhite/facebook.png'
            },
            tagName: 'mj-social-element'
          }, {
            attributes: {
              'background-color': 'transparent',
              href: 'https://www.twitter.com/PROFILE',
              name: 'Twitter',
              src: 'https://s3-eu-west-1.amazonaws.com/ecomail-assets/editor/social-icos/simplewhite/twitter.png'
            },
            tagName: 'mj-social-element'
          }, {
            attributes: {
              'background-color': 'transparent',
              href: 'https://www.instagram.com/PROFILE',
              name: 'Instagram',
              src: 'https://s3-eu-west-1.amazonaws.com/ecomail-assets/editor/social-icos/simplewhite/instagram.png'
            },
            tagName: 'mj-social-element'
          }],
          uid: 'rkMcOjQVxQ'
        }],
        uid: 'Sk62U0XNxX'
      }],
      layout: 1,
      backgroundColor: '',
      backgroundImage: '',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      uid: 'rk9uoQExm'
    }]
  }, {
    img: 'menu6',
    definition: [{
      tagName: 'mj-section',
      attributes: {
        'full-width': !1,
        padding: '9px 0px 9px 0px',
        'background-color': '#FFFFFF'
      },
      type: null,
      children: [{
        tagName: 'mj-column',
        attributes: {
          width: '33.333333%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-image',
          attributes: {
            src: 'https://storage.googleapis.com/jan50/logo-blackberry.jpg',
            padding: '12px 10px 10px 30px',
            alt: '',
            href: '',
            containerWidth: 200,
            width: 100,
            widthPercent: 50,
            align: 'left'
          },
          uid: 'S1egjsQNem'
        }],
        uid: 'SJR380m4x7'
      }, {
        tagName: 'mj-column',
        attributes: {
          width: '33.333333%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-spacer',
          attributes: {
            height: 15,
            containerWidth: 200
          },
          uid: 'B1blssmEem'
        }],
        uid: 'S1kgn8CQ4lm'
      }, {
        tagName: 'mj-column',
        attributes: {
          width: '33.333333%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-social',
          attributes: {
            padding: '10px 30px 10px 10px',
            'text-mode': 'false',
            'icon-size': '25px',
            align: 'right',
            containerWidth: 200
          },
          children: [{
            attributes: {
              'background-color': 'transparent',
              href: 'https://www.facebook.com/PROFILE',
              name: 'Facebook',
              src: 'https://s3-eu-west-1.amazonaws.com/ecomail-assets/editor/social-icos/simplegrey/facebook.png'
            },
            tagName: 'mj-social-element'
          }, {
            attributes: {
              'background-color': 'transparent',
              href: 'https://www.twitter.com/PROFILE',
              name: 'Twitter',
              src: 'https://s3-eu-west-1.amazonaws.com/ecomail-assets/editor/social-icos/simplegrey/twitter.png'
            },
            tagName: 'mj-social-element'
          }, {
            attributes: {
              'background-color': 'transparent',
              href: 'https://www.instagram.com/PROFILE',
              name: 'Instagram',
              src: 'https://s3-eu-west-1.amazonaws.com/ecomail-assets/editor/social-icos/simplegrey/instagram.png'
            },
            tagName: 'mj-social-element'
          }],
          uid: 'HyMejjQ4lm'
        }],
        uid: 'Syxxh8A7ElQ'
      }],
      layout: 1,
      backgroundColor: '',
      backgroundImage: '',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      uid: 'SygijX4e7'
    }]
  }, {
    img: 'menu7',
    definition: [{
      tagName: 'mj-section',
      attributes: {
        'full-width': !1,
        padding: '9px 0px 9px 0px',
        'background-color': '#000000'
      },
      type: null,
      children: [{
        tagName: 'mj-column',
        attributes: {
          width: '40%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-image',
          attributes: {
            src: 'https://storage.googleapis.com/jan50/blackberrylogo.png',
            padding: '20px 10px 10px 30px',
            alt: '',
            href: '',
            containerWidth: 200,
            width: 100,
            widthPercent: 50,
            align: 'left'
          },
          uid: 'ryl1yUUEgX'
        }],
        uid: 'Sk4UH-rlm'
      }, {
        tagName: 'mj-column',
        attributes: {
          width: '60%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-text',
          attributes: {
            align: 'right',
            'font-size': '11px',
            padding: '11px 27px 6px 15px',
            containerWidth: 300,
            'font-family': 'Lato, "Tahoma", sans-serif',
            color: '#FFFFFF'
          },
          uid: 'HkbJJL8EgQ',
          content: '<p><span style="font-size:14px;">Travel&nbsp; &nbsp; &nbsp; &nbsp;Hotels&nbsp; &nbsp; &nbsp; &nbsp;Flights&nbsp; &nbsp; &nbsp; &nbsp;Adventure</span></p>\n'
        }],
        uid: 'rklE8Bbre7'
      }],
      layout: 1,
      backgroundColor: '',
      backgroundImage: '',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      uid: 'HJJ1LUVl7'
    }]
  }, {
    img: 'menu8',
    definition: [{
      tagName: 'mj-section',
      attributes: {
        'full-width': !1,
        padding: '9px 0px 9px 0px',
        'background-color': '#FFFFFF'
      },
      type: null,
      children: [{
        tagName: 'mj-column',
        attributes: {
          width: '40%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-image',
          attributes: {
            src: 'https://storage.googleapis.com/jan50/logo-blackberry.jpg',
            padding: '14px 10px 10px 30px',
            alt: '',
            href: '',
            containerWidth: 200,
            width: 100,
            widthPercent: 50,
            align: 'left'
          },
          uid: 'S1T5EL4xm'
        }],
        uid: 'ryZVIrbrgm'
      }, {
        tagName: 'mj-column',
        attributes: {
          width: '60%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-text',
          attributes: {
            align: 'right',
            'font-size': '11px',
            padding: '11px 27px 6px 15px',
            containerWidth: 300,
            'font-family': 'Lato, "Tahoma", sans-serif',
            color: '#000000'
          },
          uid: 'rJ1sNLEx7',
          content: '<p><span style="font-size:14px;">Travel&nbsp; &nbsp; &nbsp; &nbsp;Hotels&nbsp; &nbsp; &nbsp; &nbsp;Flights&nbsp; &nbsp; &nbsp; &nbsp;Adventure</span></p>\n'
        }],
        uid: 'B1GNLB-rgQ'
      }],
      layout: 1,
      backgroundColor: '',
      backgroundImage: '',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      uid: 'Bkcc4INeQ'
    }]
  }, {
    img: 'menu9',
    definition: [{
      tagName: 'mj-section',
      attributes: {
        'full-width': !1,
        padding: '9px 0px 9px 0px',
        'background-color': '#000000'
      },
      type: null,
      children: [{
        tagName: 'mj-column',
        attributes: {
          width: '100%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-image',
          attributes: {
            src: 'https://storage.googleapis.com/jan50/blackberrylogo.png',
            padding: '10px 10px 10px 10px',
            alt: '',
            href: '',
            containerWidth: 600,
            width: 108,
            widthPercent: 18
          },
          uid: 'Bkgs3n74e7'
        }, {
          tagName: 'mj-divider',
          attributes: {
            'border-color': '#777777',
            'border-style': 'solid',
            'border-width': '1px',
            padding: '10px 100px 10px 100px',
            containerWidth: 600
          },
          uid: 'SyZjhnQNeQ'
        }, {
          tagName: 'mj-text',
          attributes: {
            align: 'center',
            'font-size': '11px',
            padding: '10px 27px 10px 15px',
            color: '#FFFFFF',
            containerWidth: 600,
            'font-family': 'Lato, "Tahoma", sans-serif'
          },
          uid: 'HkGoh2mVg7',
          content: '<p><span style="font-size:14px;">Travel&nbsp; &nbsp; &nbsp; &nbsp;Hotels&nbsp; &nbsp; &nbsp; &nbsp;Flights&nbsp; &nbsp; &nbsp; &nbsp;Adventure</span></p>\n'
        }],
        uid: 'SJBgnUCmVx7'
      }],
      layout: 1,
      backgroundColor: '',
      backgroundImage: '',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      uid: 'BJinnXEeX'
    }]
  }, {
    img: 'menu10',
    definition: [{
      tagName: 'mj-section',
      attributes: {
        'full-width': !1,
        padding: '9px 0px 9px 0px',
        'background-color': '#FFFFFF'
      },
      type: null,
      children: [{
        tagName: 'mj-column',
        attributes: {
          width: '100%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-image',
          attributes: {
            src: 'https://storage.googleapis.com/jan50/logo-blackberry.jpg',
            padding: '10px 10px 10px 10px',
            alt: '',
            href: '',
            containerWidth: 600,
            width: 108,
            widthPercent: 18
          },
          uid: 'H1sijX4em'
        }, {
          tagName: 'mj-divider',
          attributes: {
            'border-color': '#777777',
            'border-style': 'solid',
            'border-width': '1px',
            padding: '10px 100px 10px 100px',
            containerWidth: 600
          },
          uid: 'B1NonmNe7'
        }, {
          tagName: 'mj-text',
          attributes: {
            align: 'center',
            'font-size': '11px',
            padding: '10px 27px 10px 15px',
            color: '#000000',
            containerWidth: 600,
            'font-family': 'Lato, "Tahoma", sans-serif'
          },
          uid: 'ry9q2X4gQ',
          content: '<p><span style="font-size:14px;">Travel&nbsp; &nbsp; &nbsp; &nbsp;Hotels&nbsp; &nbsp; &nbsp; &nbsp;Flights&nbsp; &nbsp; &nbsp; &nbsp;Adventure</span></p>\n'
        }],
        uid: 'r1Ix3IRQVx7'
      }],
      layout: 1,
      backgroundColor: '',
      backgroundImage: '',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      uid: 'Bytss7VlQ'
    }]
  }],
  content: [{
    img: 'content1',
    definition: [{
      tagName: 'mj-section',
      attributes: {
        'full-width': !1,
        padding: '19px 0px 19px 0px',
        'background-color': '#FFFFFF'
      },
      type: null,
      children: [{
        tagName: 'mj-column',
        attributes: {
          width: '25%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-image',
          attributes: {
            src: 'https://storage.googleapis.com/jan50/logo-yahoo.jpg',
            padding: '0px 0px 0px 0px',
            alt: '',
            href: '',
            containerWidth: 150,
            width: 98,
            widthPercent: 65
          },
          uid: 'HkgZabvExQ'
        }, {
          tagName: 'mj-image',
          attributes: {
            src: 'https://storage.googleapis.com/jan50/logo-microsoft.jpg',
            padding: '20px 0px 0px 0px',
            alt: '',
            href: '',
            containerWidth: 150,
            width: 95,
            widthPercent: 63
          },
          uid: 'BJWb6ZvNlX'
        }],
        uid: 'H1sG1vNem'
      }, {
        tagName: 'mj-column',
        attributes: {
          width: '25%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-image',
          attributes: {
            src: 'https://storage.googleapis.com/jan50/logo-amazon.jpg',
            padding: '0px 0px 0px 0px',
            alt: '',
            href: '',
            containerWidth: 150,
            width: 78,
            widthPercent: 52
          },
          uid: 'HJGbaZPNem'
        }, {
          tagName: 'mj-image',
          attributes: {
            src: 'https://storage.googleapis.com/jan50/logo-jl.jpg',
            padding: '20px 0px 0px 0px',
            alt: '',
            href: '',
            containerWidth: 150,
            width: 95,
            widthPercent: 63
          },
          uid: 'H1X-pWDExm'
        }],
        uid: 'H1sG1vNem'
      }, {
        tagName: 'mj-column',
        attributes: {
          width: '25%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-image',
          attributes: {
            src: 'https://storage.googleapis.com/jan50/logo-nintendo.jpg',
            padding: '0px 0px 0px 0px',
            alt: '',
            href: '',
            containerWidth: 150,
            width: 80,
            widthPercent: 53
          },
          uid: 'BkV-pbw4x7'
        }, {
          tagName: 'mj-image',
          attributes: {
            src: 'https://storage.googleapis.com/jan50/logo-goole.jpg',
            padding: '20px 0px 0px 0px',
            alt: '',
            href: '',
            containerWidth: 150,
            width: 80,
            widthPercent: 53
          },
          uid: 'SJBb6-wEe7'
        }],
        uid: 'H1sG1vNem'
      }, {
        tagName: 'mj-column',
        attributes: {
          width: '25%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-image',
          attributes: {
            src: 'https://storage.googleapis.com/jan50/logo-blackberry.jpg',
            padding: '0px 0px 0px 0px',
            alt: '',
            href: '',
            containerWidth: 150,
            width: 102,
            widthPercent: 68
          },
          uid: 'By8WTbv4lQ'
        }, {
          tagName: 'mj-image',
          attributes: {
            src: 'https://storage.googleapis.com/jan50/logo-yahoo.jpg',
            padding: '20px 0px 0px 0px',
            alt: '',
            href: '',
            containerWidth: 150,
            width: 98,
            widthPercent: 65
          },
          uid: 'rkDbpWwEx7'
        }],
        uid: 'H1sG1vNem'
      }],
      layout: 1,
      backgroundColor: '',
      backgroundImage: '',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      uid: 'SyWTZv4gm'
    }]
  }, {
    img: 'content2',
    definition: [{
      tagName: 'mj-section',
      attributes: {
        'full-width': !1,
        padding: '19px 0px 19px 0px',
        'background-color': '#FFFFFF'
      },
      type: null,
      children: [{
        tagName: 'mj-column',
        attributes: {
          width: '33.333333333333336%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-image',
          attributes: {
            src: 'https://storage.googleapis.com/jan50/logo-yahoo.jpg',
            padding: '0px 0px 0px 0px',
            alt: '',
            href: '',
            containerWidth: 200,
            width: 98,
            widthPercent: 65
          },
          uid: 'H1Lsbw4lm'
        }, {
          tagName: 'mj-image',
          attributes: {
            src: 'https://storage.googleapis.com/jan50/logo-microsoft.jpg',
            padding: '20px 0px 0px 0px',
            alt: '',
            href: '',
            containerWidth: 200,
            width: 95,
            widthPercent: 63
          },
          uid: 'rJ3G-vEx7'
        }],
        uid: 'H1sG1vNem'
      }, {
        tagName: 'mj-column',
        attributes: {
          width: '33.333333333333336%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-image',
          attributes: {
            src: 'https://storage.googleapis.com/jan50/logo-amazon.jpg',
            padding: '0px 0px 0px 0px',
            alt: '',
            href: '',
            containerWidth: 200,
            width: 78,
            widthPercent: 52
          },
          uid: 'SyGz7kvEg7'
        }, {
          tagName: 'mj-image',
          attributes: {
            src: 'https://storage.googleapis.com/jan50/logo-jl.jpg',
            padding: '20px 0px 0px 0px',
            alt: '',
            href: '',
            containerWidth: 200,
            width: 95,
            widthPercent: 63
          },
          uid: 'S1JQbwElX'
        }],
        uid: 'H1sG1vNem'
      }, {
        tagName: 'mj-column',
        attributes: {
          width: '33.333333333333336%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-image',
          attributes: {
            src: 'https://storage.googleapis.com/jan50/logo-nintendo.jpg',
            padding: '0px 0px 0px 0px',
            alt: '',
            href: '',
            containerWidth: 200,
            width: 80,
            widthPercent: 53
          },
          uid: 'B14fm1vVgQ'
        }, {
          tagName: 'mj-image',
          attributes: {
            src: 'https://storage.googleapis.com/jan50/logo-goole.jpg',
            padding: '20px 0px 0px 0px',
            alt: '',
            href: '',
            containerWidth: 200,
            width: 80,
            widthPercent: 53
          },
          uid: 'Bk7XZvVgm'
        }],
        uid: 'H1sG1vNem'
      }],
      layout: 1,
      backgroundColor: '',
      backgroundImage: '',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      uid: 'rkzXyDVlm'
    }]
  }, {
    img: 'content3',
    definition: [{
      tagName: 'mj-section',
      attributes: {
        'full-width': !1,
        padding: '9px 0px 9px 0px',
        'background-color': '#FFFFFF'
      },
      type: null,
      children: [{
        tagName: 'mj-column',
        attributes: {
          width: '33.333333%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-spacer',
          attributes: {
            height: 50,
            containerWidth: 200
          },
          uid: 'HkgVWvvNeX'
        }],
        uid: 'SJi2QDElm'
      }, {
        tagName: 'mj-column',
        attributes: {
          width: '33.333333%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-text',
          attributes: {
            align: 'center',
            'font-size': '11px',
            padding: '2px 2px 2px 2px',
            containerWidth: 200,
            'font-family': 'Lato, "Tahoma", sans-serif'
          },
          uid: 'ry-V-vPNgX',
          content: '<p><strong><span style="font-size:18px;">Top Stories</span></strong></p>\n'
        }],
        uid: 'Sygo3QwEgQ'
      }, {
        tagName: 'mj-column',
        attributes: {
          width: '33.333333%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-button',
          attributes: {
            align: 'right',
            border: '1px solid #7E7E7E',
            'background-color': '#FFFFFF',
            color: '#45414e',
            'border-radius': '24px',
            'font-size': '11px',
            padding: '13px 31px 13px 31px',
            href: 'https://google.com',
            'font-family': 'Ubuntu, Helvetica, Arial, sans-serif, Helvetica, Arial, sans-serif',
            containerWidth: 200,
            'inner-padding': '7px 20px'
          },
          content: 'All articles',
          uid: 'Byz4-wDNgX'
        }],
        uid: 'Hybi37vVlQ'
      }],
      layout: 1,
      backgroundColor: '',
      backgroundImage: '',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      uid: 'SkNWvwNgQ'
    }, {
      tagName: 'mj-section',
      attributes: {
        'full-width': !1,
        padding: '9px 0px 9px 0px',
        'background-color': '#FFFFFF'
      },
      type: null,
      children: [{
        tagName: 'mj-column',
        attributes: {
          width: '50%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-image',
          attributes: {
            src: 'https://storage.googleapis.com/jan50/pexels-photo-842947.jpeg',
            padding: '0px 0px 0px 0px',
            alt: '',
            href: '',
            containerWidth: 300,
            width: 240,
            widthPercent: 80
          },
          uid: 'BkxpF_PNlQ'
        }, {
          tagName: 'mj-text',
          attributes: {
            align: 'left',
            'font-size': '11px',
            padding: '10px 35px 0px 35px',
            containerWidth: 300
          },
          uid: 'S1ZaFOwVe7',
          content: '<p><strong><span style="color:#27ae60;">November 12</span></strong></p>\n'
        }, {
          tagName: 'mj-text',
          attributes: {
            align: 'left',
            'font-size': '11px',
            padding: '0px 35px 20px 35px',
            containerWidth: 300
          },
          uid: 'rJM6tdDEgX',
          content: '<h2 style="margin-top: 0">Lorem ipsum dolor sit amet</h2>\n\n<p><span style="color:#999999;"><span style="font-size:14px;">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Sed ac dolor sit amet purus</span></span></p>\n'
        }],
        uid: 'ByzinXPVgX'
      }, {
        tagName: 'mj-column',
        attributes: {
          width: '50%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-image',
          attributes: {
            src: 'https://storage.googleapis.com/jan50/pexels-photo-1118179.jpeg',
            padding: '0px 0px 0px 0px',
            alt: '',
            href: '',
            containerWidth: 300,
            width: 243,
            widthPercent: 81
          },
          uid: 'SkX6tOw4eX'
        }, {
          tagName: 'mj-text',
          attributes: {
            align: 'left',
            'font-size': '11px',
            padding: '10px 35px 0px 35px',
            containerWidth: 300
          },
          uid: 'HJ4ptdD4eX',
          content: '<p><strong><span style="color:#27ae60;">November 12</span></strong></p>\n'
        }, {
          tagName: 'mj-text',
          attributes: {
            align: 'left',
            'font-size': '11px',
            padding: '0px 35px 20px 35px',
            containerWidth: 300
          },
          uid: 'ByBpt_PNlQ',
          content: '<h2 style="margin-top: 0">Lorem ipsum dolor sit amet</h2>\n\n<p><span style="color:#999999;"><span style="font-size:14px;">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Sed ac dolor sit amet purus</span></span></p>\n'
        }],
        uid: 'SkQonmwVeX'
      }],
      layout: 1,
      backgroundColor: '',
      backgroundImage: '',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      uid: 'rkaY_w4l7'
    }]
  }, {
    img: 'content4',
    definition: [{
      tagName: 'mj-section',
      attributes: {
        'full-width': !1,
        padding: '9px 0px 9px 0px',
        'background-color': '#FFFFFF'
      },
      type: null,
      children: [{
        tagName: 'mj-column',
        attributes: {
          width: '33.333333%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-spacer',
          attributes: {
            height: 50,
            containerWidth: 200
          },
          uid: 'SJeVt_PVxm'
        }],
        uid: 'SJi2QDElm'
      }, {
        tagName: 'mj-column',
        attributes: {
          width: '33.333333%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-text',
          attributes: {
            align: 'center',
            'font-size': '11px',
            padding: '2px 2px 2px 2px',
            containerWidth: 200,
            'font-family': 'Lato, "Tahoma", sans-serif'
          },
          uid: 'r1-Etdv4gQ',
          content: '<p><strong><span style="font-size:18px;">Top Stories</span></strong></p>\n'
        }],
        uid: 'Sygo3QwEgQ'
      }, {
        tagName: 'mj-column',
        attributes: {
          width: '33.333333%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-button',
          attributes: {
            align: 'right',
            border: '1px solid #7E7E7E',
            'background-color': '#FFFFFF',
            color: '#45414e',
            'border-radius': '24px',
            'font-size': '11px',
            padding: '13px 31px 13px 31px',
            href: 'https://google.com',
            'font-family': 'Ubuntu, Helvetica, Arial, sans-serif, Helvetica, Arial, sans-serif',
            containerWidth: 200,
            'inner-padding': '7px 20px'
          },
          content: 'All articles',
          uid: 'HyMVF_vVx7'
        }],
        uid: 'Hybi37vVlQ'
      }],
      layout: 1,
      backgroundColor: '',
      backgroundImage: '',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      uid: 'SyNKODVlm'
    }, {
      tagName: 'mj-section',
      attributes: {
        'full-width': !1,
        padding: '9px 0px 9px 0px',
        'background-color': '#FFFFFF'
      },
      type: null,
      children: [{
        tagName: 'mj-column',
        attributes: {
          width: '50%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-image',
          attributes: {
            src: 'https://storage.googleapis.com/jan50/pexels-photo-842947.jpeg',
            padding: '0px 0px 0px 0px',
            alt: '',
            href: '',
            containerWidth: 300,
            width: 240,
            widthPercent: 80
          },
          uid: 'Skxd9ODExX'
        }],
        uid: 'ByzinXPVgX'
      }, {
        tagName: 'mj-column',
        attributes: {
          width: '50%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-text',
          attributes: {
            align: 'left',
            'font-size': '11px',
            padding: '10px 35px 0px 0px',
            containerWidth: 300
          },
          uid: 'HJ2qOwEeX',
          content: '<p><strong><span style="color:#27ae60;">November 12</span></strong></p>\n'
        }, {
          tagName: 'mj-text',
          attributes: {
            align: 'left',
            'font-size': '11px',
            padding: '0px 35px 20px 0px',
            containerWidth: 300
          },
          uid: 'HyJoOvNxX',
          content: '<h2 style="margin-top: 0">Lorem ipsum dolor sit amet</h2>\n\n<p><span style="color:#999999;"><span style="font-size:14px;">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Sed ac dolor sit amet purus</span></span></p>\n'
        }],
        uid: 'SkQonmwVeX'
      }],
      layout: 1,
      backgroundColor: '',
      backgroundImage: '',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      uid: 'B1O9dDNgm'
    }, {
      tagName: 'mj-section',
      attributes: {
        'full-width': !1,
        padding: '9px 0px 9px 0px',
        'background-color': '#FFFFFF'
      },
      type: null,
      children: [{
        tagName: 'mj-column',
        attributes: {
          width: '50%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-image',
          attributes: {
            src: 'https://storage.googleapis.com/jan50/pexels-photo-1118179.jpeg',
            padding: '0px 0px 0px 0px',
            alt: '',
            href: '',
            containerWidth: 300,
            width: 243,
            widthPercent: 81
          },
          uid: 'ryHiuPElQ'
        }],
        uid: 'ByzinXPVgX'
      }, {
        tagName: 'mj-column',
        attributes: {
          width: '50%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-text',
          attributes: {
            align: 'left',
            'font-size': '11px',
            padding: '10px 35px 0px 0px',
            containerWidth: 300
          },
          uid: 'BkVrWwwEem',
          content: '<p><strong><span style="color:#27ae60;">November 12</span></strong></p>\n'
        }, {
          tagName: 'mj-text',
          attributes: {
            align: 'left',
            'font-size': '11px',
            padding: '0px 35px 20px 0px',
            containerWidth: 300
          },
          uid: 'BJrBWwPNxX',
          content: '<h2 style="margin-top: 0">Lorem ipsum dolor sit amet</h2>\n\n<p><span style="color:#999999;"><span style="font-size:14px;">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Sed ac dolor sit amet purus</span></span></p>\n'
        }],
        uid: 'SkQonmwVeX'
      }],
      layout: 1,
      backgroundColor: '',
      backgroundImage: '',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      uid: 'S1rWvPNlQ'
    }]
  }, {
    img: 'content5',
    definition: [{
      tagName: 'mj-section',
      attributes: {
        'full-width': !1,
        padding: '9px 0px 9px 0px',
        'background-color': '#FFFFFF'
      },
      type: null,
      children: [{
        tagName: 'mj-column',
        attributes: {
          width: '33.333333%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-spacer',
          attributes: {
            height: 50,
            containerWidth: 200
          },
          uid: 'rylYEwPEem'
        }],
        uid: 'SJi2QDElm'
      }, {
        tagName: 'mj-column',
        attributes: {
          width: '33.333333%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-text',
          attributes: {
            align: 'center',
            'font-size': '11px',
            padding: '2px 2px 2px 2px',
            containerWidth: 200,
            'font-family': 'Lato, "Tahoma", sans-serif'
          },
          uid: 'rk-Y4vwExm',
          content: '<p><strong><span style="font-size:18px;">Top Stories</span></strong></p>\n'
        }],
        uid: 'Sygo3QwEgQ'
      }, {
        tagName: 'mj-column',
        attributes: {
          width: '33.333333%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-button',
          attributes: {
            align: 'right',
            border: '1px solid #7E7E7E',
            'background-color': '#FFFFFF',
            color: '#45414e',
            'border-radius': '24px',
            'font-size': '11px',
            padding: '13px 31px 13px 31px',
            href: 'https://google.com',
            'font-family': 'Ubuntu, Helvetica, Arial, sans-serif, Helvetica, Arial, sans-serif',
            containerWidth: 200,
            'inner-padding': '7px 20px'
          },
          content: 'All articles',
          uid: 'ByGK4PvExX'
        }],
        uid: 'Hybi37vVlQ'
      }],
      layout: 1,
      backgroundColor: '',
      backgroundImage: '',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      uid: 'BytNvv4lQ'
    }, {
      tagName: 'mj-section',
      attributes: {
        'full-width': !1,
        padding: '9px 0px 9px 0px',
        'background-color': '#FFFFFF'
      },
      type: null,
      children: [{
        tagName: 'mj-column',
        attributes: {
          width: '50%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-image',
          attributes: {
            src: 'https://storage.googleapis.com/jan50/pexels-photo-842947.jpeg',
            padding: '0px 0px 0px 0px',
            alt: '',
            href: '',
            containerWidth: 300,
            width: 240,
            widthPercent: 80
          },
          uid: 'B1g2EvvVxQ'
        }, {
          tagName: 'mj-text',
          attributes: {
            align: 'center',
            'font-size': '11px',
            padding: '10px 20px 0px 20px',
            containerWidth: 300
          },
          uid: 'HkWhEvDNeQ',
          content: '<p><strong><span style="color:#27ae60;">November 12</span></strong></p>\n'
        }, {
          tagName: 'mj-text',
          attributes: {
            align: 'center',
            'font-size': '11px',
            padding: '0px 35px 20px 35px',
            containerWidth: 300
          },
          uid: 'SJUv_wEeQ',
          content: '<h2 style="margin-top: 0">Lorem ipsum dolor sit amet</h2>\n\n<p><span style="color:#999999;"><span style="font-size:14px;">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Sed ac dolor sit amet purus</span></span></p>\n'
        }],
        uid: 'ByzinXPVgX'
      }, {
        tagName: 'mj-column',
        attributes: {
          width: '50%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-image',
          attributes: {
            src: 'https://storage.googleapis.com/jan50/pexels-photo-1118179.jpeg',
            padding: '0px 0px 0px 0px',
            alt: '',
            href: '',
            containerWidth: 300,
            width: 243,
            widthPercent: 81
          },
          uid: 'BJmhEwvNeQ'
        }, {
          tagName: 'mj-text',
          attributes: {
            align: 'center',
            'font-size': '11px',
            padding: '10px 20px 0px 20px',
            containerWidth: 300
          },
          uid: 'HyV3NwvVxm',
          content: '<p><strong><span style="color:#27ae60;">November 12</span></strong></p>\n'
        }, {
          tagName: 'mj-text',
          attributes: {
            align: 'center',
            'font-size': '11px',
            padding: '0px 35px 20px 35px',
            containerWidth: 300
          },
          uid: 'B1B3EvPVgQ',
          content: '<h2 style="margin-top: 0">Lorem ipsum dolor sit amet</h2>\n\n<p><span style="color:#999999;"><span style="font-size:14px;">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Sed ac dolor sit amet purus</span></span></p>\n'
        }],
        uid: 'SkQonmwVeX'
      }],
      layout: 1,
      backgroundColor: '',
      backgroundImage: '',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      uid: 'Bk2VPvNeQ'
    }]
  }, {
    img: 'content6',
    definition: [{
      tagName: 'mj-section',
      attributes: {
        'full-width': !1,
        padding: '9px 0px 9px 0px',
        'background-color': '#FFFFFF'
      },
      type: null,
      children: [{
        tagName: 'mj-column',
        attributes: {
          width: '33.333333%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-spacer',
          attributes: {
            height: 50,
            containerWidth: 200
          },
          uid: 'r1gJLODVem'
        }],
        uid: 'SJi2QDElm'
      }, {
        tagName: 'mj-column',
        attributes: {
          width: '33.333333%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-text',
          attributes: {
            align: 'center',
            'font-size': '11px',
            padding: '2px 2px 2px 2px',
            containerWidth: 200,
            'font-family': 'Lato, "Tahoma", sans-serif'
          },
          uid: 'HkZkI_wVlX',
          content: '<p><strong><span style="font-size:18px;">Top Stories</span></strong></p>\n'
        }],
        uid: 'Sygo3QwEgQ'
      }, {
        tagName: 'mj-column',
        attributes: {
          width: '33.333333%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-button',
          attributes: {
            align: 'right',
            border: '1px solid #7E7E7E',
            'background-color': '#FFFFFF',
            color: '#45414e',
            'border-radius': '24px',
            'font-size': '11px',
            padding: '13px 31px 13px 31px',
            href: 'https://google.com',
            'font-family': 'Ubuntu, Helvetica, Arial, sans-serif, Helvetica, Arial, sans-serif',
            containerWidth: 200,
            'inner-padding': '7px 20px'
          },
          content: 'All articles',
          uid: 'rJG18dvVx7'
        }],
        uid: 'Hybi37vVlQ'
      }],
      layout: 1,
      backgroundColor: '',
      backgroundImage: '',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      uid: 'B1JI_w4g7'
    }, {
      tagName: 'mj-section',
      attributes: {
        'full-width': !1,
        padding: '0px 0px 0px 0px',
        'background-color': '#FFFFFF'
      },
      type: null,
      children: [{
        tagName: 'mj-column',
        attributes: {
          width: '100%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-image',
          attributes: {
            src: 'https://storage.googleapis.com/jan50/pexels-photo-842947.jpeg',
            padding: '0px 0px 0px 0px',
            alt: '',
            href: '',
            containerWidth: 600,
            width: 528,
            widthPercent: 88
          },
          uid: 'HJlQLOvExX'
        }],
        uid: 'ByzinXPVgX'
      }],
      layout: 1,
      backgroundColor: '',
      backgroundImage: '',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      uid: 'SyQIdwNem'
    }, {
      tagName: 'mj-section',
      attributes: {
        'full-width': !1,
        padding: '0px 0px 0px 0px',
        'background-color': '#FFFFFF'
      },
      type: null,
      children: [{
        tagName: 'mj-column',
        attributes: {
          width: '100%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-text',
          attributes: {
            align: 'left',
            'font-size': '11px',
            padding: '10px 35px 0px 35px',
            containerWidth: 600
          },
          uid: 'HkgOLOv4xm',
          content: '<p><strong><span style="color:#27ae60;">November 12</span></strong></p>\n'
        }, {
          tagName: 'mj-text',
          attributes: {
            align: 'left',
            'font-size': '11px',
            padding: '0px 35px 20px 35px',
            containerWidth: 300
          },
          uid: 'Sy5PuDVlX',
          content: '<h2 style="margin-top: 0">Lorem ipsum dolor sit amet</h2>\n\n<p><span style="color:#999999;"><span style="font-size:14px;">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Sed ac dolor sit amet purus</span></span></p>\n'
        }],
        uid: 'SJratbSxQ'
      }],
      layout: 1,
      backgroundColor: '',
      backgroundImage: '',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      uid: 'HJOUOv4g7'
    }]
  }, {
    img: 'content7',
    definition: [{
      tagName: 'mj-section',
      attributes: {
        'full-width': !1,
        padding: '9px 0px 9px 0px',
        'background-color': '#FFFFFF'
      },
      type: null,
      children: [{
        tagName: 'mj-column',
        attributes: {
          width: '33.333333%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-spacer',
          attributes: {
            height: 50,
            containerWidth: 200
          },
          uid: 'Hk5ezDVgm'
        }],
        uid: 'SJi2QDElm'
      }, {
        tagName: 'mj-column',
        attributes: {
          width: '33.333333%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-text',
          attributes: {
            align: 'center',
            'font-size': '11px',
            padding: '2px 2px 2px 2px',
            containerWidth: 200,
            'font-family': 'Lato, "Tahoma", sans-serif'
          },
          uid: 'Bk9C-wNxQ',
          content: '<p><strong><span style="font-size:18px;">Top Stories</span></strong></p>\n'
        }],
        uid: 'Sygo3QwEgQ'
      }, {
        tagName: 'mj-column',
        attributes: {
          width: '33.333333%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-button',
          attributes: {
            align: 'right',
            border: '1px solid #7E7E7E',
            'background-color': '#FFFFFF',
            color: '#45414e',
            'border-radius': '24px',
            'font-size': '11px',
            padding: '13px 31px 13px 31px',
            href: 'https://google.com',
            'font-family': 'Ubuntu, Helvetica, Arial, sans-serif, Helvetica, Arial, sans-serif',
            containerWidth: 200,
            'inner-padding': '7px 20px'
          },
          content: 'All articles',
          uid: 'BkaezwVg7'
        }],
        uid: 'Hybi37vVlQ'
      }],
      layout: 1,
      backgroundColor: '',
      backgroundImage: '',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      uid: 'SyvCZDVxQ'
    }, {
      tagName: 'mj-section',
      attributes: {
        'full-width': !1,
        padding: '0px 0px 0px 0px',
        'background-color': '#FFFFFF'
      },
      type: null,
      children: [{
        tagName: 'mj-column',
        attributes: {
          width: '100%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-image',
          attributes: {
            src: 'https://storage.googleapis.com/jan50/pexels-photo-842947.jpeg',
            padding: '0px 0px 0px 0px',
            alt: '',
            href: '',
            containerWidth: 600,
            width: 528,
            widthPercent: 88
          },
          uid: 'rkvSzDNeX'
        }],
        uid: 'ByzinXPVgX'
      }],
      layout: 1,
      backgroundColor: '',
      backgroundImage: '',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      uid: 'Bk4HzDVxX'
    }, {
      tagName: 'mj-section',
      attributes: {
        'full-width': !1,
        padding: '0px 0px 0px 0px',
        'background-color': '#FFFFFF'
      },
      type: null,
      children: [{
        tagName: 'mj-column',
        attributes: {
          width: '50%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-text',
          attributes: {
            align: 'left',
            'font-size': '11px',
            padding: '10px 35px 0px 35px',
            containerWidth: 600
          },
          uid: 'SyYPwwNeX',
          content: '<p><strong><span style="color:#27ae60;">November 12</span></strong></p>\n'
        }, {
          tagName: 'mj-text',
          attributes: {
            align: 'left',
            'font-size': '11px',
            padding: '0px 35px 20px 35px',
            containerWidth: 600
          },
          uid: 'HyluvDEg7',
          content: '<h2 style="margin-top: 0">Lorem ipsum dolor sit amet</h2>\n'
        }],
        uid: 'B1lBpF-BxQ'
      }, {
        tagName: 'mj-column',
        attributes: {
          width: '50%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-spacer',
          attributes: {
            height: 38,
            containerWidth: 300
          },
          uid: 'Byx2DP4xQ'
        }, {
          tagName: 'mj-text',
          attributes: {
            align: 'left',
            'font-size': '11px',
            padding: '0px 35px 20px 35px',
            containerWidth: 600
          },
          uid: 'ry-dvD4e7',
          content: '<p style="margin-top: 0px;"><span style="font-size:14px;"><span style="color:#999999;">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Sed ac dolor sit amet purus.</span></span></p>\n'
        }],
        uid: 'HyWBpFWHgQ'
      }],
      layout: 1,
      backgroundColor: '',
      backgroundImage: '',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      uid: 'rkSvPvVl7'
    }]
  }],
  ecomm: [{
    img: 'ecomm6',
    definition: [{
      tagName: 'mj-section',
      attributes: {
        'full-width': !1,
        padding: '24px 0px 24px 0px',
        'background-color': '#FFFFFF'
      },
      type: null,
      children: [{
        tagName: 'mj-column',
        attributes: {
          width: '50%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-text',
          attributes: {
            align: 'left',
            'font-size': '11px',
            padding: '0px 30px 0px 0px',
            containerWidth: 300,
            'font-family': 'Lato, "Tahoma", sans-serif'
          },
          uid: 'rkXEXP8gm',
          content: '<h2 style="margin-top: 0px">Milumia</h2>\n\n<p><span style="color:#999999;"><span style="font-size:14px;">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aliquam ante. Quis autem vel eum iure reprehenderit</span></span></p>\n\n<p><span style="font-size:18px;">$30</span></p>\n'
        }, {
          tagName: 'mj-button',
          attributes: {
            align: 'left',
            'background-color': '#207DE7',
            color: '#FFFFFF',
            'border-radius': '8px',
            'font-size': '13px',
            padding: '11px 0px 0px 0px',
            'inner-padding': '9px 26px',
            href: 'https://google.com',
            'font-family': 'Ubuntu, Helvetica, Arial, sans-serif, Helvetica, Arial, sans-serif',
            containerWidth: 300,
            border: '0px solid #000'
          },
          content: 'Shop now',
          uid: 'HJxqmw8em'
        }],
        uid: 'By-4xCDIx7'
      }, {
        tagName: 'mj-column',
        attributes: {
          width: '50%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-image',
          attributes: {
            src: 'https://storage.googleapis.com/jan50/51p0p1TDGnL._AC_UL500_SR385,500_FMwebp_QL65_.jpg',
            padding: '0px 0px 0px 0px',
            alt: '',
            href: '',
            containerWidth: 300,
            width: 138,
            widthPercent: 46
          },
          uid: 'xJXgQP8xX'
        }],
        uid: 'HJMVRRwLgx'
      }],
      layout: 1,
      backgroundColor: '',
      backgroundImage: '',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      uid: 'H1IymwIxx'
    }]
  }, {
    img: 'ecomm5',
    definition: [{
      tagName: 'mj-section',
      attributes: {
        'full-width': !1,
        padding: '24px 0px 24px 0px',
        'background-color': '#FFFFFF'
      },
      type: null,
      children: [{
        tagName: 'mj-column',
        attributes: {
          width: '50%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-image',
          attributes: {
            src: 'https://storage.googleapis.com/jan50/51p0p1TDGnL._AC_UL500_SR385,500_FMwebp_QL65_.jpg',
            padding: '0px 0px 0px 0px',
            alt: '',
            href: '',
            containerWidth: 300,
            width: 138,
            widthPercent: 46
          },
          uid: 'xJXgQP8xX'
        }],
        uid: 'HJMVRRwLgx'
      }, {
        tagName: 'mj-column',
        attributes: {
          width: '50%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-text',
          attributes: {
            align: 'left',
            'font-size': '11px',
            padding: '0px 30px 0px 0px',
            containerWidth: 300,
            'font-family': 'Lato, "Tahoma", sans-serif'
          },
          uid: 'rkXEXP8gm',
          content: '<h2 style="margin-top: 0px">Milumia</h2>\n\n<p><span style="color:#999999;"><span style="font-size:14px;">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aliquam ante. Quis autem vel eum iure reprehenderit</span></span></p>\n\n<p><span style="font-size:18px;">$30</span></p>\n'
        }, {
          tagName: 'mj-button',
          attributes: {
            align: 'left',
            'background-color': '#207DE7',
            color: '#FFFFFF',
            'border-radius': '8px',
            'font-size': '13px',
            padding: '11px 0px 0px 0px',
            'inner-padding': '9px 26px',
            href: 'https://google.com',
            'font-family': 'Ubuntu, Helvetica, Arial, sans-serif, Helvetica, Arial, sans-serif',
            containerWidth: 300,
            border: '0px solid #000'
          },
          content: 'Shop now',
          uid: 'HJxqmw8em'
        }],
        uid: 'By-4xCDIx7'
      }],
      layout: 1,
      backgroundColor: '',
      backgroundImage: '',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      uid: 'H1IymwIxx'
    }]
  }, {
    img: 'ecomm1',
    definition: [{
      tagName: 'mj-section',
      attributes: {
        'full-width': !1,
        padding: '0px 0px 0px 0px',
        'background-color': '#FFFFFF'
      },
      type: null,
      children: [{
        tagName: 'mj-column',
        attributes: {
          width: '33.333333%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-spacer',
          attributes: {
            height: 37,
            containerWidth: 200
          },
          uid: 'HJxy1HvSe7'
        }],
        uid: 'SJcE07BxQ'
      }, {
        tagName: 'mj-column',
        attributes: {
          width: '33.333333%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-text',
          attributes: {
            align: 'center',
            'font-size': '11px',
            padding: '0px 20px 0px 20px',
            containerWidth: 600,
            'font-family': 'Cabin, sans-serif',
            undefinedpadding: '38px 38px 38px 38px'
          },
          uid: 'SyW11BPrlm',
          content: '<p><strong><span style="font-size:20px;">Today&#39;s Deals</span></strong></p>\n'
        }],
        uid: 'S1x94A7rx7'
      }, {
        tagName: 'mj-column',
        attributes: {
          width: '33.333333%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-button',
          attributes: {
            align: 'right',
            'background-color': '#FFFFFF',
            border: '1px solid #7E7E7E',
            color: '#313131',
            'border-radius': '24px',
            'font-size': '9px',
            padding: '13px 13px 13px 13px',
            'inner-padding': '6px 18px',
            href: 'https://google.com',
            'font-family': 'Ubuntu, Helvetica, Arial, sans-serif, Helvetica, Arial, sans-serif',
            containerWidth: 200,
            undefinedpadding: '0px 0px 0px 0px'
          },
          content: 'All products',
          uid: 'ryzJkHvBem'
        }],
        uid: 'r1b5ECQHe7'
      }],
      layout: 1,
      backgroundColor: '',
      backgroundImage: '',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      uid: 'SkkJHPre7'
    }, {
      tagName: 'mj-section',
      attributes: {
        'full-width': !1,
        padding: '0px 0px 0px 0px',
        'background-color': '#FFFFFF'
      },
      type: null,
      children: [{
        tagName: 'mj-column',
        attributes: {
          width: '100%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-product',
          attributes: {
            image: '*|PRODUCT_IMG_SRC|*',
            href: '*|PRODUCT_HREF|*',
            title: '*|PRODUCT_TITLE|*',
            price: 650,
            description: '*|PRODUCT_DESC|*',
            layout: 'image:show,title:show,description:show,price:show,button:show',
            dynamic: !0,
            'image-width': 165,
            'image-align': 'center',
            'title-align': 'center',
            'price-align': 'center',
            'description-align': 'center',
            'button-align': 'center',
            'price-suffix': 'KÄ',
            'button-background-color': '#207DE7',
            button: 'Shop now',
            containerWidth: 600,
            'image-padding-top': 0,
            'title-padding-top': 0,
            'title-padding-left': 0,
            'description-padding-top': 18,
            'description-padding-left': 79,
            'price-padding-top': 0,
            'price-padding-left': 5,
            'button-font-size': '16px',
            'title-font-size': '20px',
            'title-font-family': 'Cabin, sans-serif',
            'description-color': '#B1B1B1',
            'price-color': '#585858',
            'price-font-size': '16px',
            'price-font-family': 'Open Sans, sans-serif',
            'button-padding': '11px 11px 11px 11px',
            'image-padding': '0px 0px 13px 0px',
            'title-padding': '0px 0px 0px 0px',
            'description-padding': '13px 13px 13px 13px',
            'price-padding': '0px 0px 0px 0px',
            'button-border-radius': '24px'
          },
          uid: 'Sy-t0vmrx7'
        }],
        uid: 'HkEpvXSg7'
      }],
      layout: 1,
      backgroundColor: '',
      backgroundImage: '',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      uid: 'HktCD7Bem'
    }]
  }, {
    img: 'ecomm2',
    definition: [{
      tagName: 'mj-section',
      attributes: {
        'full-width': !1,
        padding: '0px 0px 0px 0px',
        'background-color': '#FFFFFF'
      },
      type: null,
      children: [{
        tagName: 'mj-column',
        attributes: {
          width: '33.333333%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-text',
          attributes: {
            align: 'left',
            'font-size': '11px',
            padding: '0px 20px 0px 20px',
            containerWidth: 600,
            'font-family': 'Cabin, sans-serif',
            undefinedpadding: '38px 38px 38px 38px'
          },
          uid: 'B1HJrDHlX',
          content: '<p><strong><span style="font-size:20px;">Today&#39;s Deals</span></strong></p>\n'
        }],
        uid: 'SJcE07BxQ'
      }, {
        tagName: 'mj-column',
        attributes: {
          width: '33.333333%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-spacer',
          attributes: {
            height: 37,
            containerWidth: 200
          },
          uid: 'SkUJHPSxX'
        }],
        uid: 'S1x94A7rx7'
      }, {
        tagName: 'mj-column',
        attributes: {
          width: '33.333333%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-button',
          attributes: {
            align: 'right',
            'background-color': '#FFFFFF',
            border: '1px solid #7E7E7E',
            color: '#313131',
            'border-radius': '24px',
            'font-size': '9px',
            padding: '13px 13px 13px 13px',
            'inner-padding': '6px 18px',
            href: 'https://google.com',
            'font-family': 'Ubuntu, Helvetica, Arial, sans-serif, Helvetica, Arial, sans-serif',
            containerWidth: 200,
            undefinedpadding: '0px 0px 0px 0px'
          },
          content: 'All products',
          uid: 'rkE0EvSxm'
        }],
        uid: 'r1b5ECQHe7'
      }],
      layout: 1,
      backgroundColor: '',
      backgroundImage: '',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      uid: 'HJz1AXHxQ'
    }, {
      tagName: 'mj-section',
      attributes: {
        'full-width': !1,
        padding: '9px 0px 9px 0px',
        'background-color': '#FFFFFF'
      },
      type: null,
      children: [{
        tagName: 'mj-column',
        attributes: {
          width: '100%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-product',
          attributes: {
            image: '*|PRODUCT_IMG_SRC|*',
            href: '*|PRODUCT_HREF|*',
            title: '*|PRODUCT_TITLE|*',
            price: '1450',
            description: '*|PRODUCT_DESC|*',
            layout: 'image:show,title:show,description:show,price:show,button:show',
            dynamic: !0,
            'image-width': 300,
            'image-align': 'center',
            'title-align': 'left',
            'price-align': 'left',
            'description-align': 'left',
            'button-align': 'left',
            'price-suffix': 'KÄ',
            'button-background-color': '#207DE7',
            button: 'Shop now',
            containerWidth: 600,
            'image-padding-top': 0,
            'title-padding-top': 0,
            'title-padding-left': 20,
            'description-padding-top': 18,
            'description-padding-left': 20,
            'price-padding-top': 0,
            'price-padding-left': 20,
            'button-font-size': '16px',
            'title-font-size': '20px',
            'title-font-family': 'Cabin, sans-serif',
            'description-color': '#B1B1B1',
            'price-color': '#585858',
            'price-font-size': '16px',
            'price-font-family': 'Open Sans, sans-serif',
            'button-padding': '17px 17px 17px 17px',
            'image-padding': '0px 0px 0px 0px',
            'title-padding': '0px 0px 0px 20px',
            'description-padding': '10px 0px 0px 20px',
            'price-padding': '10px 0px 0px 20px'
          },
          uid: 'HJZtz_7Slm'
        }],
        uid: 'HkEpvXSg7'
      }],
      layout: 1,
      backgroundColor: '',
      backgroundImage: '',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      uid: 'ByFMd7Sxm'
    }]
  }, {
    img: 'ecomm3',
    definition: [{
      tagName: 'mj-section',
      attributes: {
        'full-width': !1,
        padding: '0px 0px 0px 0px',
        'background-color': '#FFFFFF'
      },
      type: null,
      children: [{
        tagName: 'mj-column',
        attributes: {
          width: '50%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-text',
          attributes: {
            align: 'left',
            'font-size': '11px',
            padding: '0px 20px 0px 20px',
            containerWidth: 300,
            'font-family': 'Cabin, sans-serif',
            undefinedpadding: '0px 0px 0px 0px'
          },
          uid: 'SJxoaA7HgX',
          content: '<p><strong><span style="font-size:20px;">Today&#39;s Deals</span></strong></p>\n'
        }],
        uid: 'S1aGyVrxX'
      }, {
        tagName: 'mj-column',
        attributes: {
          width: '50%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-button',
          attributes: {
            align: 'right',
            'background-color': '#FFFFFF',
            border: '1px solid #7E7E7E',
            color: '#313131',
            'border-radius': '24px',
            'font-size': '9px',
            padding: '10px 10px 10px 10px',
            'inner-padding': '6px 18px',
            href: 'https://google.com',
            'font-family': 'Ubuntu, Helvetica, Arial, sans-serif, Helvetica, Arial, sans-serif',
            containerWidth: 200,
            undefinedpadding: '0px 0px 0px 0px'
          },
          content: 'All products',
          uid: 'rJWjp07Bl7'
        }],
        uid: 'Hyl6zJVSeX'
      }],
      layout: 1,
      backgroundColor: '',
      backgroundImage: '',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      uid: 'HJiaR7SxX'
    }, {
      tagName: 'mj-section',
      attributes: {
        'full-width': !1,
        padding: '10px 0px 10px 0px',
        'background-color': '#FFFFFF'
      },
      type: null,
      children: [{
        tagName: 'mj-column',
        attributes: {
          width: '50%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-product',
          attributes: {
            image: '*|PRODUCT_IMG_SRC|*',
            href: '*|PRODUCT_HREF|*',
            title: '*|PRODUCT_TITLE|*',
            price: '1450',
            description: '*|PRODUCT_DESC|*',
            layout: 'image:show,title:show,description:hide,price:show,button:show',
            dynamic: !0,
            'image-width': 300,
            'image-align': 'center',
            'title-align': 'left',
            'price-align': 'left',
            'description-align': 'left',
            'button-align': 'left',
            'price-suffix': 'KÄ',
            'button-background-color': '#207DE7',
            button: 'Shop now',
            containerWidth: 300,
            'image-padding-top': 0,
            'title-padding-top': 0,
            'title-padding-left': 20,
            'description-padding-top': 18,
            'description-padding-left': 20,
            'price-padding-top': 0,
            'price-padding-left': 20,
            'button-font-size': '16px',
            'title-font-size': '20px',
            'title-font-family': 'Cabin, sans-serif',
            'description-color': '#B1B1B1',
            'price-color': '#585858',
            'price-font-size': '16px',
            'price-font-family': 'Open Sans, sans-serif',
            'button-padding': '10px 0px 0px 20px',
            'image-padding': '0px 0px 0px 0px',
            'title-padding': '0px 0px 0px 20px',
            'description-padding': '0px 0px 0px 0px',
            'price-padding': '0px 0px 0px 20px'
          },
          uid: 'B1lRIIvSe7'
        }],
        uid: 'HkEpvXSg7'
      }, {
        tagName: 'mj-column',
        attributes: {
          width: '50%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-product',
          attributes: {
            image: '*|PRODUCT_IMG_SRC|*',
            href: '*|PRODUCT_HREF|*',
            title: '*|PRODUCT_TITLE|*',
            price: '1450',
            description: '*|PRODUCT_DESC|*',
            layout: 'image:show,title:show,description:hide,price:show,button:show',
            dynamic: !0,
            'image-width': 300,
            'image-align': 'center',
            'title-align': 'left',
            'price-align': 'left',
            'description-align': 'left',
            'button-align': 'left',
            'price-suffix': 'KÄ',
            'button-background-color': '#207DE7',
            button: 'Shop now',
            containerWidth: 300,
            'image-padding-top': 0,
            'title-padding-top': 0,
            'title-padding-left': 20,
            'description-padding-top': 18,
            'description-padding-left': 20,
            'price-padding-top': 0,
            'price-padding-left': 20,
            'button-font-size': '16px',
            'title-font-size': '20px',
            'title-font-family': 'Cabin, sans-serif',
            'description-color': '#B1B1B1',
            'price-color': '#585858',
            'price-font-size': '16px',
            'price-font-family': 'Open Sans, sans-serif',
            'button-padding': '10px 0px 0px 20px',
            'image-padding': '0px 0px 0px 0px',
            'title-padding': '0px 0px 0px 20px',
            'description-padding': '0px 0px 0px 0px',
            'price-padding': '0px 0px 0px 20px'
          },
          uid: 'SyWAL8PBxX'
        }],
        uid: 'HkEpvXSg7'
      }],
      layout: 1,
      backgroundColor: '',
      backgroundImage: '',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      uid: 'SkRUIPSxm'
    }, {
      tagName: 'mj-section',
      attributes: {
        'full-width': !1,
        padding: '10px 0px 10px 0px',
        'background-color': '#FFFFFF'
      },
      type: null,
      children: [{
        tagName: 'mj-column',
        attributes: {
          width: '50%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-product',
          attributes: {
            image: '*|PRODUCT_IMG_SRC|*',
            href: '*|PRODUCT_HREF|*',
            title: '*|PRODUCT_TITLE|*',
            price: '1450',
            description: '*|PRODUCT_DESC|*',
            layout: 'image:show,title:show,description:hide,price:show,button:show',
            dynamic: !0,
            'image-width': 300,
            'image-align': 'center',
            'title-align': 'left',
            'price-align': 'left',
            'description-align': 'left',
            'button-align': 'left',
            'price-suffix': 'KÄ',
            'button-background-color': '#207DE7',
            button: 'Shop now',
            containerWidth: 300,
            'image-padding-top': 0,
            'title-padding-top': 0,
            'title-padding-left': 20,
            'description-padding-top': 18,
            'description-padding-left': 20,
            'price-padding-top': 0,
            'price-padding-left': 20,
            'button-font-size': '16px',
            'title-font-size': '20px',
            'title-font-family': 'Cabin, sans-serif',
            'description-color': '#B1B1B1',
            'price-color': '#585858',
            'price-font-size': '16px',
            'price-font-family': 'Open Sans, sans-serif',
            'button-padding': '10px 0px 0px 20px',
            'image-padding': '0px 0px 0px 0px',
            'title-padding': '0px 0px 0px 20px',
            'description-padding': '0px 0px 0px 0px',
            'price-padding': '0px 0px 0px 20px'
          },
          uid: 'BJWLLvBxm'
        }],
        uid: 'HkEpvXSg7'
      }, {
        tagName: 'mj-column',
        attributes: {
          width: '50%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-product',
          attributes: {
            image: '*|PRODUCT_IMG_SRC|*',
            href: '*|PRODUCT_HREF|*',
            title: '*|PRODUCT_TITLE|*',
            price: '1450',
            description: '*|PRODUCT_DESC|*',
            layout: 'image:show,title:show,description:hide,price:show,button:show',
            dynamic: !0,
            'image-width': 300,
            'image-align': 'center',
            'title-align': 'left',
            'price-align': 'left',
            'description-align': 'left',
            'button-align': 'left',
            'price-suffix': 'KÄ',
            'button-background-color': '#207DE7',
            button: 'Shop now',
            containerWidth: 300,
            'image-padding-top': 0,
            'title-padding-top': 0,
            'title-padding-left': 20,
            'description-padding-top': 18,
            'description-padding-left': 20,
            'price-padding-top': 0,
            'price-padding-left': 20,
            'button-font-size': '16px',
            'title-font-size': '20px',
            'title-font-family': 'Cabin, sans-serif',
            'description-color': '#B1B1B1',
            'price-color': '#585858',
            'price-font-size': '16px',
            'price-font-family': 'Open Sans, sans-serif',
            'button-padding': '10px 0px 0px 20px',
            'image-padding': '0px 0px 0px 0px',
            'title-padding': '0px 0px 0px 20px',
            'description-padding': '0px 0px 0px 0px',
            'price-padding': '0px 0px 0px 20px'
          },
          uid: 'BkcILvSeQ'
        }],
        uid: 'HkEpvXSg7'
      }],
      layout: 1,
      backgroundColor: '',
      backgroundImage: '',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      uid: 'rk6k1NHeQ'
    }]
  }, {
    img: 'ecomm4',
    definition: [{
      tagName: 'mj-section',
      attributes: {
        'full-width': !1,
        padding: '0px 0px 0px 0px',
        'background-color': '#FFFFFF'
      },
      type: null,
      children: [{
        tagName: 'mj-column',
        attributes: {
          width: '33.333333333333336%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-spacer',
          attributes: {
            height: 50,
            containerWidth: 200
          },
          uid: 'Hk600QrgX'
        }],
        uid: 'B1-6fJEHgm'
      }, {
        tagName: 'mj-column',
        attributes: {
          width: '33.333333333333336%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-text',
          attributes: {
            align: 'center',
            'font-size': '11px',
            padding: '0px 20px 0px 20px',
            containerWidth: 200,
            'font-family': 'Cabin, sans-serif',
            undefinedpadding: '0px 0px 0px 0px'
          },
          uid: 'B1cAAmBxX',
          content: '<p><strong><span style="font-size:20px;">Today&#39;s Deals</span></strong></p>\n'
        }],
        uid: 'SyMaG1VrxX'
      }, {
        tagName: 'mj-column',
        attributes: {
          width: '33.333333333333336%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-button',
          attributes: {
            align: 'right',
            'background-color': '#FFFFFF',
            border: '1px solid #7E7E7E',
            color: '#000000',
            'border-radius': '24px',
            'font-size': '9px',
            padding: '10px 10px 10px 10px',
            'inner-padding': '6px 18px',
            href: 'https://google.com',
            'font-family': 'Ubuntu, Helvetica, Arial, sans-serif, Helvetica, Arial, sans-serif',
            containerWidth: 200,
            undefinedpadding: '0px 0px 0px 0px'
          },
          content: 'All products',
          uid: 'BkIACmSem'
        }],
        uid: 'HJQaMk4HxQ'
      }],
      layout: 1,
      backgroundColor: '',
      backgroundImage: '',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      uid: 'HJtuRXSeX'
    }, {
      tagName: 'mj-section',
      attributes: {
        'full-width': !1,
        padding: '10px 0px 10px 0px',
        'background-color': '#FFFFFF'
      },
      type: null,
      children: [{
        tagName: 'mj-column',
        attributes: {
          width: '50%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-product',
          attributes: {
            image: '*|PRODUCT_IMG_SRC|*',
            href: '*|PRODUCT_HREF|*',
            title: '*|PRODUCT_TITLE|*',
            price: '1450',
            description: '*|PRODUCT_DESC|*',
            layout: 'image:show,title:show,description:hide,price:show,button:show',
            dynamic: !0,
            'image-width': 300,
            'image-align': 'center',
            'title-align': 'center',
            'price-align': 'center',
            'description-align': 'left',
            'button-align': 'center',
            'price-suffix': 'KÄ',
            'button-background-color': '#207DE7',
            button: 'Shop now',
            containerWidth: 300,
            'image-padding-top': 0,
            'title-padding-top': 0,
            'title-padding-left': 20,
            'description-padding-top': 18,
            'description-padding-left': 20,
            'price-padding-top': 0,
            'price-padding-left': 20,
            'button-font-size': '16px',
            'title-font-size': '20px',
            'title-font-family': 'Cabin, sans-serif',
            'description-color': '#B1B1B1',
            'price-color': '#585858',
            'price-font-size': '16px',
            'price-font-family': 'Open Sans, sans-serif',
            'button-padding': '10px 0px 0px 0px'
          },
          uid: 'S1xtJk4SlX'
        }],
        uid: 'HkEpvXSg7'
      }, {
        tagName: 'mj-column',
        attributes: {
          width: '50%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-product',
          attributes: {
            image: '*|PRODUCT_IMG_SRC|*',
            href: '*|PRODUCT_HREF|*',
            title: '*|PRODUCT_TITLE|*',
            price: '1450',
            description: '*|PRODUCT_DESC|*',
            layout: 'image:show,title:show,description:hide,price:show,button:show',
            dynamic: !0,
            'image-width': 300,
            'image-align': 'center',
            'title-align': 'center',
            'price-align': 'center',
            'description-align': 'left',
            'button-align': 'center',
            'price-suffix': 'KÄ',
            'button-background-color': '#207DE7',
            button: 'Shop now',
            containerWidth: 300,
            'image-padding-top': 0,
            'title-padding-top': 0,
            'title-padding-left': 20,
            'description-padding-top': 18,
            'description-padding-left': 20,
            'price-padding-top': 0,
            'price-padding-left': 20,
            'button-font-size': '16px',
            'title-font-size': '20px',
            'title-font-family': 'Cabin, sans-serif',
            'description-color': '#B1B1B1',
            'price-color': '#585858',
            'price-font-size': '16px',
            'price-font-family': 'Open Sans, sans-serif',
            'button-padding': '10px 0px 0px 0px'
          },
          uid: 'rJZFkkESxX'
        }],
        uid: 'HkEpvXSg7'
      }],
      layout: 1,
      backgroundColor: '',
      backgroundImage: '',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      uid: 'H1tkkNHxX'
    }, {
      tagName: 'mj-section',
      attributes: {
        'full-width': !1,
        padding: '10px 0px 10px 0px',
        'background-color': '#FFFFFF'
      },
      type: null,
      children: [{
        tagName: 'mj-column',
        attributes: {
          width: '50%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-product',
          attributes: {
            image: '*|PRODUCT_IMG_SRC|*',
            href: '*|PRODUCT_HREF|*',
            title: '*|PRODUCT_TITLE|*',
            price: '1450',
            description: '*|PRODUCT_DESC|*',
            layout: 'image:show,title:show,description:hide,price:show,button:show',
            dynamic: !0,
            'image-width': 300,
            'image-align': 'center',
            'title-align': 'center',
            'price-align': 'center',
            'description-align': 'left',
            'button-align': 'center',
            'price-suffix': 'KÄ',
            'button-background-color': '#207DE7',
            button: 'Shop now',
            containerWidth: 300,
            'image-padding-top': 0,
            'title-padding-top': 0,
            'title-padding-left': 20,
            'description-padding-top': 18,
            'description-padding-left': 20,
            'price-padding-top': 0,
            'price-padding-left': 20,
            'button-font-size': '16px',
            'title-font-size': '20px',
            'title-font-family': 'Cabin, sans-serif',
            'description-color': '#B1B1B1',
            'price-color': '#585858',
            'price-font-size': '16px',
            'price-font-family': 'Open Sans, sans-serif',
            'button-padding': '10px 0px 0px 0px'
          },
          uid: 'S1gr3C7Sl7'
        }],
        uid: 'HkEpvXSg7'
      }, {
        tagName: 'mj-column',
        attributes: {
          width: '50%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-product',
          attributes: {
            image: '*|PRODUCT_IMG_SRC|*',
            href: '*|PRODUCT_HREF|*',
            title: '*|PRODUCT_TITLE|*',
            price: '1450',
            description: '*|PRODUCT_DESC|*',
            layout: 'image:show,title:show,description:hide,price:show,button:show',
            dynamic: !0,
            'image-width': 300,
            'image-align': 'center',
            'title-align': 'center',
            'price-align': 'center',
            'description-align': 'left',
            'button-align': 'center',
            'price-suffix': 'KÄ',
            'button-background-color': '#207DE7',
            button: 'Shop now',
            containerWidth: 300,
            'image-padding-top': 0,
            'title-padding-top': 0,
            'title-padding-left': 20,
            'description-padding-top': 18,
            'description-padding-left': 20,
            'price-padding-top': 0,
            'price-padding-left': 20,
            'button-font-size': '16px',
            'title-font-size': '20px',
            'title-font-family': 'Cabin, sans-serif',
            'description-color': '#B1B1B1',
            'price-color': '#585858',
            'price-font-size': '16px',
            'price-font-family': 'Open Sans, sans-serif',
            'button-padding': '10px 0px 0px 0px'
          },
          uid: 'SyZH3AQHeQ'
        }],
        uid: 'HkEpvXSg7'
      }],
      layout: 1,
      backgroundColor: '',
      backgroundImage: '',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      uid: 'SJr307Bem'
    }]
  }],
  footers: [{
    img: 'footer2',
    definition: [{
      tagName: 'mj-section',
      attributes: {
        'full-width': !1,
        padding: '0px 0px 0px 0px',
        'background-color': '#222222'
      },
      type: null,
      children: [{
        tagName: 'mj-column',
        attributes: {
          width: '50%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-spacer',
          attributes: {
            height: 30,
            containerWidth: 300
          },
          uid: 'ryx8H8gVlX'
        }, {
          tagName: 'mj-text',
          attributes: {
            align: 'left',
            'font-size': '11px',
            padding: '0px 30px 0px 30px',
            color: '#FFFFFF',
            containerWidth: 300
          },
          uid: 'HJWLrLl4em',
          content: '<p><span style="font-size:16px;">Follow us</span></p>\n\n<p><span style="color:#999999;"><span style="font-size:14px;">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aliquam ante. Quis autem vel eum iure reprehenderit</span></span></p>\n'
        }, {
          tagName: 'mj-social',
          attributes: {
            'text-mode': 'false',
            'icon-size': '35px',
            align: 'left',
            containerWidth: 300,
            padding: '0px 0px 15px 25px'
          },
          children: [{
            attributes: {
              'background-color': 'transparent',
              href: 'https://www.facebook.com/PROFILE',
              name: 'Facebook',
              src: 'https://s3-eu-west-1.amazonaws.com/ecomail-assets/editor/social-icos/simplegrey/facebook.png'
            },
            tagName: 'mj-social-element'
          }, {
            attributes: {
              'background-color': 'transparent',
              href: 'https://www.twitter.com/PROFILE',
              name: 'Twitter',
              src: 'https://s3-eu-west-1.amazonaws.com/ecomail-assets/editor/social-icos/simplegrey/twitter.png'
            },
            tagName: 'mj-social-element'
          }, {
            attributes: {
              'background-color': 'transparent',
              href: 'https://www.instagram.com/PROFILE',
              name: 'Instagram',
              src: 'https://s3-eu-west-1.amazonaws.com/ecomail-assets/editor/social-icos/simplegrey/instagram.png'
            },
            tagName: 'mj-social-element'
          }],
          uid: 'HJGIHLxVeQ'
        }],
        uid: 'SJ5r-g4eQ'
      }, {
        tagName: 'mj-column',
        attributes: {
          width: '50%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-spacer',
          attributes: {
            height: 30,
            containerWidth: 300
          },
          uid: 'H1XISUl4g7'
        }, {
          tagName: 'mj-text',
          attributes: {
            align: 'left',
            'font-size': '11px',
            padding: '0px 30px 0px 30px',
            color: '#FFFFFF',
            containerWidth: 300
          },
          uid: 'H1ELS8gVgQ',
          content: '<p><span style="font-size:16px;">Contact us</span></p>\n\n<p><span style="color:#999999;"><span style="font-size:14px;">16 Homeword Lane&nbsp;<br />\nFitchburg, MA 01420</span></span></p>\n'
        }, {
          tagName: 'mj-text',
          attributes: {
            align: 'left',
            'font-size': '11px',
            padding: '0px 30px 0px 30px',
            color: '#FFFFFF',
            containerWidth: 300
          },
          uid: 'r1S8HIl4eX',
          content: '<p><span style="font-size:16px;">202-555-0173</span></p>\n\n<p><a href="mailto:foo@bar.cz" style="color:#3498db;font-size: 14px;">foo@bar.cz</a></p>\n'
        }],
        uid: 'SJ5r-g4eQ'
      }],
      layout: 1,
      backgroundColor: '',
      backgroundImage: '',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      uid: 'S1USUlVgm'
    }]
  }, {
    img: 'footer3',
    definition: [{
      tagName: 'mj-section',
      attributes: {
        'full-width': !1,
        padding: '0px 0px 0px 0px',
        'background-color': '#FFFFFF'
      },
      type: null,
      children: [{
        tagName: 'mj-column',
        attributes: {
          width: '50%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-spacer',
          attributes: {
            height: 30,
            containerWidth: 300
          },
          uid: 'HygHQSe4eQ'
        }, {
          tagName: 'mj-text',
          attributes: {
            align: 'left',
            'font-size': '11px',
            padding: '0px 30px 0px 30px',
            color: '#0A0A0A',
            containerWidth: 300
          },
          uid: 'Skg2Be4em',
          content: '<p><span style="font-size:16px;">Follow us</span></p>\n\n<p><span style="color:#999999;"><span style="font-size:14px;">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aliquam ante. Quis autem vel eum iure reprehenderit</span></span></p>\n'
        }, {
          tagName: 'mj-social',
          attributes: {
            'text-mode': 'false',
            'icon-size': '35px',
            align: 'left',
            containerWidth: 300,
            padding: '0px 0px 15px 25px'
          },
          children: [{
            attributes: {
              'background-color': 'transparent',
              href: 'https://www.facebook.com/PROFILE',
              name: 'Facebook',
              src: 'https://s3-eu-west-1.amazonaws.com/ecomail-assets/editor/social-icos/outlinedbw/facebook.png'
            },
            tagName: 'mj-social-element'
          }, {
            attributes: {
              'background-color': 'transparent',
              href: 'https://www.twitter.com/PROFILE',
              name: 'Twitter',
              src: 'https://s3-eu-west-1.amazonaws.com/ecomail-assets/editor/social-icos/outlinedbw/twitter.png'
            },
            tagName: 'mj-social-element'
          }, {
            attributes: {
              'background-color': 'transparent',
              href: 'https://www.instagram.com/PROFILE',
              name: 'Instagram',
              src: 'https://s3-eu-west-1.amazonaws.com/ecomail-assets/editor/social-icos/outlinedbw/instagram.png'
            },
            tagName: 'mj-social-element'
          }],
          uid: 'S1bS7HxVgm'
        }],
        uid: 'SJ5r-g4eQ'
      }, {
        tagName: 'mj-column',
        attributes: {
          width: '50%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-spacer',
          attributes: {
            height: 30,
            containerWidth: 300
          },
          uid: 'rk1VHxNgm'
        }, {
          tagName: 'mj-text',
          attributes: {
            align: 'left',
            'font-size': '11px',
            padding: '0px 30px 0px 30px',
            color: '#000000',
            containerWidth: 300
          },
          uid: 'S1y1LxNe7',
          content: '<p><span style="font-size:16px;">Contact us</span></p>\n\n<p><span style="color:#999999;"><span style="font-size:14px;">16 Homeword Lane&nbsp;<br />\nFitchburg, MA 01420</span></span></p>\n'
        }, {
          tagName: 'mj-text',
          attributes: {
            align: 'left',
            'font-size': '11px',
            padding: '0px 30px 0px 30px',
            color: '#000000',
            containerWidth: 300
          },
          uid: 'S1f2rgNxm',
          content: '<p><span style="font-size:16px;">202-555-0173</span></p>\n\n<p><a href="mailto:foo@bar.cz" style="color:#3498db;font-size: 14px;">foo@bar.cz</a></p>\n'
        }],
        uid: 'SJ5r-g4eQ'
      }],
      layout: 1,
      backgroundColor: '',
      backgroundImage: '',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      uid: 'BJBXSlVxX'
    }]
  }, {
    img: 'footer4',
    definition: [{
      tagName: 'mj-section',
      attributes: {
        'full-width': !1,
        padding: '0px 0px 0px 0px',
        'background-color': '#222222'
      },
      type: null,
      children: [{
        tagName: 'mj-column',
        attributes: {
          width: '100%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-spacer',
          attributes: {
            height: 30,
            containerWidth: 600
          },
          uid: 'SygkZHeNe7'
        }, {
          tagName: 'mj-social',
          attributes: {
            'text-mode': 'false',
            'icon-size': '35px',
            align: 'center',
            containerWidth: 600,
            padding: '5px 5px 5px 5px'
          },
          children: [{
            attributes: {
              'background-color': 'transparent',
              href: 'https://www.facebook.com/PROFILE',
              name: 'Facebook',
              src: 'https://s3-eu-west-1.amazonaws.com/ecomail-assets/editor/social-icos/outlinedbw/facebook.png'
            },
            tagName: 'mj-social-element'
          }, {
            attributes: {
              'background-color': 'transparent',
              href: 'https://www.twitter.com/PROFILE',
              name: 'Twitter',
              src: 'https://s3-eu-west-1.amazonaws.com/ecomail-assets/editor/social-icos/outlinedbw/twitter.png'
            },
            tagName: 'mj-social-element'
          }, {
            attributes: {
              'background-color': 'transparent',
              href: 'https://www.instagram.com/PROFILE',
              name: 'Instagram',
              src: 'https://s3-eu-west-1.amazonaws.com/ecomail-assets/editor/social-icos/outlinedbw/instagram.png'
            },
            tagName: 'mj-social-element'
          }],
          uid: 'rkWJZrlVeQ'
        }, {
          tagName: 'mj-text',
          attributes: {
            align: 'center',
            'font-size': '11px',
            padding: '0px 35px 0px 35px',
            color: '#D8D8D8',
            containerWidth: 600,
            'font-family': 'Lato, "Tahoma", sans-serif'
          },
          uid: 'rkGJZBx4eX',
          content: '<p><span style="font-size:14px;">16 Homeword Lane&nbsp;<br />\nFitchburg, MA 01420</span></p>\n'
        }],
        uid: 'SJ5r-g4eQ'
      }],
      layout: 1,
      backgroundColor: '',
      backgroundImage: '',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      uid: 'ryybBg4lX'
    }, {
      tagName: 'mj-section',
      attributes: {
        'full-width': !1,
        padding: '0px 0px 0px 0px',
        'background-color': '#222222'
      },
      type: null,
      children: [{
        tagName: 'mj-column',
        attributes: {
          width: '100%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-text',
          attributes: {
            align: 'center',
            'font-size': '11px',
            padding: '0px 35px 10px 35px',
            color: '#FFFFFF',
            containerWidth: 300
          },
          uid: 'SJlQWSx4l7',
          content: '<p><span style="font-size:12px;"><a href="{{ unsubscribe_url }}" style="color:#3498db;">Unsubscribe</a></span></p>\n'
        }],
        uid: 'S1W4Vg4gQ'
      }],
      layout: 1,
      backgroundColor: '',
      backgroundImage: '',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      uid: 'S1X-BgNgm'
    }]
  }, {
    img: 'footer5',
    definition: [{
      tagName: 'mj-section',
      attributes: {
        'full-width': !1,
        padding: '0px 0px 0px 0px',
        'background-color': '#FFFFFF'
      },
      type: null,
      children: [{
        tagName: 'mj-column',
        attributes: {
          width: '100%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-spacer',
          attributes: {
            height: 30,
            containerWidth: 600
          },
          uid: 'BkwgSlEl7'
        }, {
          tagName: 'mj-social',
          attributes: {
            'text-mode': 'false',
            'icon-size': '35px',
            align: 'center',
            containerWidth: 600,
            padding: '5px 5px 5px 5px'
          },
          children: [{
            attributes: {
              'background-color': 'transparent',
              href: 'https://www.facebook.com/PROFILE',
              name: 'Facebook',
              src: 'https://s3-eu-west-1.amazonaws.com/ecomail-assets/editor/social-icos/outlinedbw/facebook.png'
            },
            tagName: 'mj-social-element'
          }, {
            attributes: {
              'background-color': 'transparent',
              href: 'https://www.twitter.com/PROFILE',
              name: 'Twitter',
              src: 'https://s3-eu-west-1.amazonaws.com/ecomail-assets/editor/social-icos/outlinedbw/twitter.png'
            },
            tagName: 'mj-social-element'
          }, {
            attributes: {
              'background-color': 'transparent',
              href: 'https://www.instagram.com/PROFILE',
              name: 'Instagram',
              src: 'https://s3-eu-west-1.amazonaws.com/ecomail-assets/editor/social-icos/outlinedbw/instagram.png'
            },
            tagName: 'mj-social-element'
          }],
          uid: 'rJ-yHeVg7'
        }, {
          tagName: 'mj-text',
          attributes: {
            align: 'center',
            'font-size': '11px',
            padding: '0px 35px 0px 35px',
            color: '#222222',
            containerWidth: 600,
            'font-family': 'Lato, "Tahoma", sans-serif'
          },
          uid: 'Byl50EeEeX',
          content: '<p><span style="font-size:14px;">16 Homeword Lane&nbsp;<br />\nFitchburg, MA 01420</span></p>\n'
        }],
        uid: 'SJ5r-g4eQ'
      }],
      layout: 1,
      backgroundColor: '',
      backgroundImage: '',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      uid: 'r190ElNl7'
    }, {
      tagName: 'mj-section',
      attributes: {
        'full-width': !1,
        padding: '0px 0px 0px 0px',
        'background-color': '#FFFFFF'
      },
      type: null,
      children: [{
        tagName: 'mj-column',
        attributes: {
          width: '100%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-text',
          attributes: {
            align: 'center',
            'font-size': '11px',
            padding: '0px 35px 10px 35px',
            color: '#FFFFFF',
            containerWidth: 300
          },
          uid: 'BkejySe4xQ',
          content: '<p><span style="font-size:12px;"><a href="{{ unsubscribe_url }}" style="color:#3498db;">Unsubscribe</a></span></p>\n'
        }],
        uid: 'S1W4Vg4gQ'
      }],
      layout: 1,
      backgroundColor: '',
      backgroundImage: '',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      uid: 'rJi1BlVgX'
    }]
  }, {
    img: 'footer6',
    definition: [{
      tagName: 'mj-section',
      attributes: {
        'full-width': !1,
        padding: '21px 0px 21px 0px',
        'background-color': '#222222'
      },
      type: null,
      children: [{
        tagName: 'mj-column',
        attributes: {
          width: '50%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-text',
          attributes: {
            align: 'left',
            'font-size': '11px',
            padding: '0px 35px 0px 35px',
            color: '#D8D8D8',
            containerWidth: 300,
            'font-family': 'Lato, "Tahoma", sans-serif'
          },
          uid: 'H1g7ezgVeX',
          content: '<p><span style="font-size:14px;">16 Homeword Lane&nbsp;<br />\nFitchburg, MA 01420</span></p>\n'
        }],
        uid: 'SJ5r-g4eQ'
      }, {
        tagName: 'mj-column',
        attributes: {
          width: '50%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-social',
          attributes: {
            'text-mode': 'false',
            'icon-size': '35px',
            align: 'right',
            containerWidth: 300,
            padding: '0px 20px 0px 0px'
          },
          children: [{
            attributes: {
              'background-color': 'transparent',
              href: 'https://www.facebook.com/PROFILE',
              name: 'Facebook',
              src: 'https://s3-eu-west-1.amazonaws.com/ecomail-assets/editor/social-icos/outlinedbw/facebook.png'
            },
            tagName: 'mj-social-element'
          }, {
            attributes: {
              'background-color': 'transparent',
              href: 'https://www.twitter.com/PROFILE',
              name: 'Twitter',
              src: 'https://s3-eu-west-1.amazonaws.com/ecomail-assets/editor/social-icos/outlinedbw/twitter.png'
            },
            tagName: 'mj-social-element'
          }, {
            attributes: {
              'background-color': 'transparent',
              href: 'https://www.instagram.com/PROFILE',
              name: 'Instagram',
              src: 'https://s3-eu-west-1.amazonaws.com/ecomail-assets/editor/social-icos/outlinedbw/instagram.png'
            },
            tagName: 'mj-social-element'
          }],
          uid: 'Sy-mlMlExQ'
        }],
        uid: 'BygcS-lNgX'
      }],
      layout: 1,
      backgroundColor: '',
      backgroundImage: '',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      uid: 'HJXlflVem'
    }, {
      tagName: 'mj-section',
      attributes: {
        'full-width': !1,
        padding: '0px 0px 0px 0px',
        'background-color': '#222222'
      },
      type: null,
      children: [{
        tagName: 'mj-column',
        attributes: {
          width: '100%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-text',
          attributes: {
            align: 'center',
            'font-size': '11px',
            padding: '0px 35px 10px 35px',
            color: '#FFFFFF',
            containerWidth: 300
          },
          uid: 'BkxsxzxNgQ',
          content: '<p><span style="font-size:12px;"><a href="{{ unsubscribe_url }}" style="color:#3498db;">Unsubscribe</a></span></p>\n'
        }],
        uid: 'S1W4Vg4gQ'
      }],
      layout: 1,
      backgroundColor: '',
      backgroundImage: '',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      uid: 'rkiezlVem'
    }]
  }, {
    img: 'footer7',
    definition: [{
      tagName: 'mj-section',
      attributes: {
        'full-width': !1,
        padding: '21px 0px 21px 0px',
        'background-color': '#FFFFFF'
      },
      type: null,
      children: [{
        tagName: 'mj-column',
        attributes: {
          width: '50%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-text',
          attributes: {
            align: 'left',
            'font-size': '11px',
            padding: '0px 35px 0px 35px',
            color: '#1D1D1D',
            containerWidth: 300,
            'font-family': 'Lato, "Tahoma", sans-serif'
          },
          uid: 'rJgDlfg4xX',
          content: '<p><span style="font-size:14px;">16 Homeword Lane&nbsp;<br />\nFitchburg, MA 01420</span></p>\n'
        }],
        uid: 'SJ5r-g4eQ'
      }, {
        tagName: 'mj-column',
        attributes: {
          width: '50%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-social',
          attributes: {
            'text-mode': 'false',
            'icon-size': '35px',
            align: 'right',
            containerWidth: 300,
            padding: '0px 20px 0px 0px'
          },
          children: [{
            attributes: {
              'background-color': 'transparent',
              href: 'https://www.facebook.com/PROFILE',
              name: 'Facebook',
              src: 'https://s3-eu-west-1.amazonaws.com/ecomail-assets/editor/social-icos/outlinedbw/facebook.png'
            },
            tagName: 'mj-social-element'
          }, {
            attributes: {
              'background-color': 'transparent',
              href: 'https://www.twitter.com/PROFILE',
              name: 'Twitter',
              src: 'https://s3-eu-west-1.amazonaws.com/ecomail-assets/editor/social-icos/outlinedbw/twitter.png'
            },
            tagName: 'mj-social-element'
          }, {
            attributes: {
              'background-color': 'transparent',
              href: 'https://www.instagram.com/PROFILE',
              name: 'Instagram',
              src: 'https://s3-eu-west-1.amazonaws.com/ecomail-assets/editor/social-icos/outlinedbw/instagram.png'
            },
            tagName: 'mj-social-element'
          }],
          uid: 'S1WDgGlNgX'
        }],
        uid: 'BygcS-lNgX'
      }],
      layout: 1,
      backgroundColor: '',
      backgroundImage: '',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      uid: 'HyvxfgNeX'
    }, {
      tagName: 'mj-section',
      attributes: {
        'full-width': !1,
        padding: '0px 0px 0px 0px',
        'background-color': '#FFFFFF'
      },
      type: null,
      children: [{
        tagName: 'mj-column',
        attributes: {
          width: '100%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-text',
          attributes: {
            align: 'center',
            'font-size': '11px',
            padding: '0px 35px 10px 35px',
            color: '#FFFFFF',
            containerWidth: 300
          },
          uid: 'BJ0j-xVlm',
          content: '<p><span style="font-size:12px;"><a href="{{ unsubscribe_url }}" style="color:#3498db;">Unsubscribe</a></span></p>\n'
        }],
        uid: 'rJebNEeEeX'
      }],
      layout: 1,
      backgroundColor: '',
      backgroundImage: '',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      uid: 'HJii-gVxQ'
    }]
  }, {
    img: 'footer8',
    definition: [{
      tagName: 'mj-section',
      attributes: {
        'full-width': !1,
        padding: '21px 0px 21px 0px',
        'background-color': '#222222'
      },
      type: null,
      children: [{
        tagName: 'mj-column',
        attributes: {
          width: '50%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-text',
          attributes: {
            align: 'left',
            'font-size': '11px',
            padding: '0px 35px 0px 35px',
            color: '#D8D8D8',
            containerWidth: 300,
            'font-family': 'Lato, "Tahoma", sans-serif'
          },
          uid: 'rJg_kWxVgm',
          content: '<p><span style="font-size:14px;">16 Homeword Lane&nbsp;<br />\nFitchburg, MA 01420</span></p>\n'
        }],
        uid: 'SJ5r-g4eQ'
      }, {
        tagName: 'mj-column',
        attributes: {
          width: '50%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-social',
          attributes: {
            'text-mode': 'false',
            'icon-size': '35px',
            align: 'right',
            containerWidth: 300,
            padding: '0px 20px 0px 0px'
          },
          children: [{
            attributes: {
              'background-color': 'transparent',
              href: 'https://www.facebook.com/PROFILE',
              name: 'Facebook',
              src: 'https://s3-eu-west-1.amazonaws.com/ecomail-assets/editor/social-icos/outlinedbw/facebook.png'
            },
            tagName: 'mj-social-element'
          }, {
            attributes: {
              'background-color': 'transparent',
              href: 'https://www.twitter.com/PROFILE',
              name: 'Twitter',
              src: 'https://s3-eu-west-1.amazonaws.com/ecomail-assets/editor/social-icos/outlinedbw/twitter.png'
            },
            tagName: 'mj-social-element'
          }, {
            attributes: {
              'background-color': 'transparent',
              href: 'https://www.instagram.com/PROFILE',
              name: 'Instagram',
              src: 'https://s3-eu-west-1.amazonaws.com/ecomail-assets/editor/social-icos/outlinedbw/instagram.png'
            },
            tagName: 'mj-social-element'
          }],
          uid: 'rJMu1WxVgm'
        }],
        uid: 'BygcS-lNgX'
      }],
      layout: 1,
      backgroundColor: '',
      backgroundImage: '',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      uid: 'rydkWxVg7'
    }, {
      tagName: 'mj-section',
      attributes: {
        'full-width': !1,
        padding: '0px 0px 0px 0px',
        'background-color': '#222222'
      },
      type: null,
      children: [{
        tagName: 'mj-column',
        attributes: {
          width: '100%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-text',
          attributes: {
            align: 'left',
            'font-size': '11px',
            padding: '0px 35px 10px 35px',
            color: '#FFFFFF',
            containerWidth: 300
          },
          uid: 'B1l3KNxNgQ',
          content: '<p><span style="font-size:12px;"><a href="{{ unsubscribe_url }}" style="color:#3498db;">Unsubscribe</a>&nbsp;or&nbsp;<br /></span></p>\n'
        }],
        uid: 'B1bb4NxExX'
      }],
      layout: 1,
      backgroundColor: '',
      backgroundImage: '',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      uid: 'r1htNlNgX'
    }]
  }, {
    img: 'footer9',
    definition: [{
      tagName: 'mj-section',
      attributes: {
        'full-width': !1,
        padding: '21px 0px 21px 0px',
        'background-color': '#FFFFFF'
      },
      type: null,
      children: [{
        tagName: 'mj-column',
        attributes: {
          width: '50%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-text',
          attributes: {
            align: 'left',
            'font-size': '11px',
            padding: '0px 35px 0px 35px',
            color: '#1B1B1B',
            containerWidth: 300,
            'font-family': 'Lato, "Tahoma", sans-serif'
          },
          uid: 'HkbkCFpJQ',
          content: '<p><span style="font-size:14px;">16 Homeword Lane&nbsp;<br />\nFitchburg, MA 01420</span></p>\n'
        }],
        uid: 'r1W5BWlNgm'
      }, {
        tagName: 'mj-column',
        attributes: {
          width: '50%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-social',
          attributes: {
            'text-mode': 'false',
            'icon-size': '35px',
            align: 'right',
            containerWidth: 300,
            padding: '0px 20px 0px 0px'
          },
          children: [{
            attributes: {
              'background-color': 'transparent',
              href: 'https://www.facebook.com/PROFILE',
              name: 'Facebook',
              src: 'https://s3-eu-west-1.amazonaws.com/ecomail-assets/editor/social-icos/outlinedbw/facebook.png'
            },
            tagName: 'mj-social-element'
          }, {
            attributes: {
              'background-color': 'transparent',
              href: 'https://www.twitter.com/PROFILE',
              name: 'Twitter',
              src: 'https://s3-eu-west-1.amazonaws.com/ecomail-assets/editor/social-icos/outlinedbw/twitter.png'
            },
            tagName: 'mj-social-element'
          }, {
            attributes: {
              'background-color': 'transparent',
              href: 'https://www.instagram.com/PROFILE',
              name: 'Instagram',
              src: 'https://s3-eu-west-1.amazonaws.com/ecomail-assets/editor/social-icos/outlinedbw/instagram.png'
            },
            tagName: 'mj-social-element'
          }],
          uid: 'S1eQAYa1Q'
        }],
        uid: 'Syz5r-gElm'
      }],
      layout: 1,
      backgroundColor: '',
      backgroundImage: '',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      uid: 'r1zhxe4eX'
    }, {
      tagName: 'mj-section',
      attributes: {
        'full-width': !1,
        padding: '0px 0px 0px 0px',
        'background-color': '#FFFFFF'
      },
      type: null,
      children: [{
        tagName: 'mj-column',
        attributes: {
          width: '100%',
          'vertical-align': 'top'
        },
        children: [{
          tagName: 'mj-text',
          attributes: {
            align: 'left',
            'font-size': '11px',
            padding: '0px 35px 10px 35px',
            color: '#1D1D1D',
            containerWidth: 300
          },
          uid: 'BJi5flNeX',
          content: '<p><span style="font-size:12px;"><a draggable="false" href="{{ unsubscribe_url }}" style="color:#3498db;">Unsubscribe</a></span></p>\n'
        }],
        uid: 'B1bb4NxExX'
      }],
      layout: 1,
      backgroundColor: '',
      backgroundImage: '',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      uid: 'SyKczgNeQ'
    }]
  }]
};
export const topolJson = {
  template: {
    tagName: 'mj-global-style',
    attributes: {
      'h1:color': '#000',
      'h1:font-family': 'Helvetica, sans-serif',
      'h2:color': '#000',
      'h2:font-family': 'Ubuntu, Helvetica, Arial, sans-serif',
      'h3:color': '#000',
      'h3:font-family': 'Ubuntu, Helvetica, Arial, sans-serif',
      ':color': '#000',
      ':font-family': 'Ubuntu, Helvetica, Arial, sans-serif',
      ':line-height': '1.5',
      'a:color': '#24bfbc',
      'button:background-color': '#e85034',
      containerWidth: 600,
      fonts: 'Helvetica,sans-serif,Ubuntu,Arial',
      'mj-text': {
        'line-height': 1.5,
        'font-size': 15
      },
      'mj-button': []
    },
    children: [{
      tagName: 'mj-container',
      attributes: {
        'background-color': '#FFFFFF',
        containerWidth: 600
      },
      children: [{
        tagName: 'mj-section',
        attributes: {
          'full-width': false,
          padding: '9px 0px 9px 0px'
        },
        children: [{
          tagName: 'mj-column',
          attributes: {
            width: '100%',
            'vertical-align': 'top'
          },
          children: [],
          uid: 'HJQ8ytZzW'
        }],
        layout: 1,
        backgroundColor: null,
        backgroundImage: null,
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        uid: 'Byggju-zb'
      }]
    }],
    style: {
      h1: {
        'font-family': '"Cabin", sans-serif'
      }
    },
    fonts: ['"Cabin", sans-serif']
  },
  feeds: [],
  placeholders: []
};