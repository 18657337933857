import React, { useContext, useState, useEffect } from 'react';
import { cloneDeep } from 'lodash';
import { Button, IconButton } from '@mui/material';
import { ModalContext } from 'context/modal-context';
import { DeleteIcon, EditIcon } from 'components/mui';
import { EditParamModal } from './edit-param-modal.component';
import CloseIcon from '@mui/icons-material/Close';
import { Scoped } from 'kremling';
import { patchRevision } from 'shared/common.api';
import { MODES } from '../node/node-utils';
import styles from './parameter-manager.styles.scss';
export const ParameterManager = ({
  revision,
  appRef,
  paramNodes,
  trigger,
  mode,
  setMode
}) => {
  const {
    show,
    confirm
  } = useContext(ModalContext);

  // NOTE to self: do we need to sync the params with the parent components?
  const [newParams, setNewParams] = useState(revision.meta?.params || []);
  const updateParams = params => {
    patchRevision(revision.id, {
      meta__params: params
    });
  };
  const editRevisionParam = param => {
    show(EditParamModal, {
      param: param,
      existing: newParams
    }).then(data => {
      if (data.subtype == '') {
        delete data.subtype;
      }
      setNewParams(ogParams => {
        const newParams = cloneDeep(ogParams) || [];

        // If the key we're trying to set already exists, we need to update the record instead
        // of adding a new one.
        let oldParam = newParams.find(param => param.key === data.key);
        if (!oldParam && param?.key && param.key !== data.key) {
          // We didn't find an item for the new key, let's try and find one for the old key.
          oldParam = newParams.find(param2 => param2.key === param.key);
        }
        if (oldParam) {
          oldParam.key = data.key;
          oldParam.type = data.type;
          oldParam.subtype = data?.subtype;
        } else {
          newParams.push({
            ...data,
            page: '1'
          });
        }
        updateParams(newParams);
        return newParams;
      });
    }).catch(() => {});
  };
  const deleteRevisionParam = data => {
    confirm({
      title: 'Delete Journey Parameter',
      message: `Are you sure you want to delete the parameter '${data.key}'? This action cannot be undone.`,
      submitText: 'Delete'
    }).then(() => {
      setNewParams(params => {
        const newParams = cloneDeep(params).filter(param => param.key !== data.key);
        updateParams(newParams);
        return newParams;
      });
    }).catch(() => {});
  };
  return <Scoped css={styles}>
      <div id="parameter-bar">
        <Button disabled={false} onClick={() => editRevisionParam()} variant="text" className="add-parameter">
          Add Parameter
        </Button>

        {newParams.filter(p => p.type != 'decorator').map(param => <div className="param-group" key={param.key}>
              <hr />

              <div className="row">
                <div className="form-group col-4">
                  <label>Key</label>
                  <br />
                  {param.key}
                </div>

                <div className="form-group col-4">
                  <label>Type</label>
                  <br />
                  {param.subtype ? `${param.type} (${param.subtype})` : param.type}
                </div>

                <div className="param-tools col-4">
                  <IconButton onClick={() => editRevisionParam(param)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => deleteRevisionParam(param)}>
                    <DeleteIcon />
                  </IconButton>
                </div>
              </div>

              <div className="form-group">
                <div className="tile-group">
                  <label>Tiles</label>
                  {mode === MODES.NODE_PARAM ? <Button disabled={false} onClick={() => setMode(MODES.CANVAS)} variant="text" className="add-parameter">
                      Cancel Select
                    </Button> : <Button disabled={false} onClick={() => trigger('SELECT_TILES', param)} variant="text" className="add-parameter">
                      Select Tile
                    </Button>}
                </div>
                {!paramNodes?.[param.key]?.length ? <em>No tiles selected</em> : paramNodes?.[param.key]?.map(node => <Button key={node.id} onClick={() => appRef.current.scrollToNode(node.id)} variant="text" className="tile-button">
                      {node.name}
                    </Button>)}
              </div>
            </div>)}
      </div>
    </Scoped>;
};