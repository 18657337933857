import React, { useState, useEffect } from 'react';
import { getPhoneNumbers } from 'shared/common.api';
import utils from 'shared/utils';
export const FromPhone = props => {
  const {
    param,
    data
  } = props;
  const [displayVal, setDisplayVal] = useState(<em>Loading...</em>);
  useEffect(() => {
    const phone = data[param.key];
    if (phone === 'location') {
      setDisplayVal('Last Location Phone Number');
    } else if (phone) {
      getPhoneNumbers({
        id: phone,
        limit: 1
      }).then(({
        data
      }) => {
        if (data.results.length > 0) {
          setDisplayVal(utils.formatPhoneNumber(data.results[0].phone));
        } else {
          setDisplayVal(<em>Unable to find phone number.</em>);
        }
      }).catch(() => {
        setDisplayVal(<em>Error retrieving item.</em>);
      });
    } else {
      setDisplayVal('Automatic');
    }
  }, []);
  return <div>{displayVal}</div>;
};