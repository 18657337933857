import React, { useState, useEffect } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { Field } from 'formik';
import { useFormikContext } from 'formik';
import dayjs from 'dayjs';

/**
 * This is a MUI-x timepicker which plays well with formik
 * The default value is just a string that is in the following format:
 * `HH:mm:ss`
 *
 * If you prefer the picker to be empty on load just provide the default value with the following:
 * ```js
 * null | undefined | ''
 * ```
 * The form will change the value of the name of the component to a string in the following format:
 *
 * `HH:mm:ss`
 *
 */
export function FormikTimePicker({
  name,
  validate,
  label,
  onChange
}) {
  const [timeValue, setTimeValue] = useState();
  const {
    initialValues
  } = useFormikContext();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const keyExists = initialValues.hasOwnProperty(name);
    const startingValue = initialValues[name];
    if (!keyExists) {
      console.warn('FormikTimePicker is misconfigured, you must provide the correct name prop.  The options are:');
      setTimeValue(dayjs());
    } else if (!startingValue) {
      setTimeValue(null);
    } else {
      setTimeValue(dayjs(startingValue, 'HH:mm:ss'));
    }
    if (onChange) {
      onChange(startingValue);
    }
    setLoading(false);
  }, [setTimeValue]);
  const getTimeChange = async (time, form) => {
    setTimeValue(time);
    await form.setFieldValue(name, dayjs(time).format('HH:mm:ss'), true);
    if (onChange) {
      onChange(time);
    }
  };
  return <>
      {!loading && <Field name={name} validate={validate}>
          {({
        field,
        // { name, value, onChange, onBlur }
        form,
        // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        meta
      }) => <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker value={timeValue} label={label} onChange={async timeData => {
          try {
            await getTimeChange(timeData, form);
          } catch (error) {
            console.error(error);
          }
        }} slotProps={{
          textField: {
            helperText: meta.touched && meta.error ? meta.error : null,
            error: meta.touched && meta.error ? true : false
          }
        }} />
            </LocalizationProvider>}
        </Field>}
    </>;
}