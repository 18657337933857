import React, { useEffect, useState, useContext } from 'react';
import validator from 'validator';
import { ModalDialog } from 'components';
import { Table, TableBody, TableRow, TableCell } from '@mui/material';
import moment from 'moment-timezone';
export const IntegrationLogDetailModal = ({
  open,
  onClose,
  row
}) => {
  return <ModalDialog size="lg" open={open} title="Log Details" cancelText="Close" onClose={onClose} allowBackdropClick type="scroll">
      <div className="form-group pt-3">
        <label>Integration</label> <br />
        {row.integration_name}
      </div>
      <div className="form-group pt-3">
        <label>Date Started</label> <br />
        {moment(row.created_when).format('MMM D, YYYY h:mm A')}
      </div>
      <div className="form-group pt-3">
        <label>Last Updated</label> <br />
        {moment(row.updated_when).format('MMM D, YYYY h:mm A')}
      </div>
      <div className="form-group pt-3">
        <label>Date Finished</label> <br />
        {row.completed_when ? moment(row.completed_when).format('MMM D, YYYY h:mm A') : 'Unknown'}
      </div>
      <div className="form-group pt-3">
        <label>Filepath</label> <br />
        {row.filepath || 'No filepath'}
      </div>
      <div className="form-group pt-3">
        <label>Source Stream</label> <br />
        {row.source_stream}
      </div>
      <div className="form-group pt-3">
        <label>Target Table</label> <br />
        {row.target_stream}
      </div>
      <div className="form-group pt-3">
        <label>Rows Affected</label> <br />
        {(row.rows_affected || "0").toLocaleString('en-US')}
      </div>
      {row.error_message && <div className="form-group pt-3">
        <label>Error Message</label> <br />
        <pre>{row.error_message}</pre>
      </div>}
      {row.credential_parameters && <div className="form-group pt-3">
          <label>Connection Details</label> <br />
          <Table>
            <TableBody>
              {Object.keys(row.credential_parameters).map(key => <TableRow key={key}>
                  <TableCell><strong>{key}</strong></TableCell>
                  <TableCell>{row.credential_parameters[key]}</TableCell>
                </TableRow>)}
            </TableBody>
          </Table>
        </div>}
    </ModalDialog>;
};