import React from 'react';
function Icon() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" fill="none" viewBox="0 0 20 21">
      <mask id="mask0_124_2551" style={{
      maskType: 'alpha'
    }} width="21" height="21" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0 0.257H20V20.257H0z"></path>
      </mask>
      <g mask="url(#mask0_124_2551)">
        <path fill="#1D252D" d="M16.213 17.317c-1.648 0-3.267-.382-4.856-1.144A15.39 15.39 0 017.1 13.157a15.481 15.481 0 01-3.02-4.252C3.315 7.318 2.933 5.7 2.933 4.052c0-.236.087-.437.26-.604a.858.858 0 01.615-.25h2.801c.216 0 .4.067.555.2.153.133.25.304.288.515l.52 2.514a1.16 1.16 0 01-.022.528.873.873 0 01-.253.408L5.773 9.257a13.804 13.804 0 002.254 2.946c.9.902 1.919 1.68 3.057 2.338l1.862-1.936c.15-.15.3-.246.45-.29a1.16 1.16 0 01.518-.014l2.439.512a.835.835 0 01.715.843v2.786a.858.858 0 01-.25.614.807.807 0 01-.605.26z"></path>
      </g>
    </svg>;
}
export default Icon;