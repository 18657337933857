import React from 'react';
function Icon(props) {
  if (props.size == 'sm') {
    return <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 18 18">
        <g>
          <mask id="mask0_213_1976" style={{
          maskType: 'alpha'
        }} width="18" height="18" x="0" y="0" maskUnits="userSpaceOnUse">
            <path fill="#D9D9D9" d="M0 0H18V18H0z"></path>
          </mask>
          <g mask="url(#mask0_213_1976)">
            <path fill={props.fill || '#1D252D'} d="M9 9.731c-.738 0-1.36-.253-1.866-.76-.506-.505-.759-1.127-.759-1.865 0-.737.253-1.36.76-1.865.506-.507 1.127-.76 1.865-.76s1.36.253 1.866.76c.506.506.759 1.128.759 1.865 0 .738-.253 1.36-.76 1.866-.506.506-1.127.76-1.865.76zm0-1.5c.325 0 .594-.106.806-.318.213-.213.319-.482.319-.807 0-.325-.106-.593-.319-.806A1.093 1.093 0 009 5.981c-.325 0-.594.107-.806.319a1.093 1.093 0 00-.319.806c0 .325.106.594.319.807.212.212.481.318.806.318zm0-5.156L4.5 4.763v3.543c0 .675.094 1.332.281 1.969a8.22 8.22 0 00.769 1.8 8.446 8.446 0 011.65-.619 7.103 7.103 0 011.8-.225c.625 0 1.225.075 1.8.225.575.15 1.125.357 1.65.619a8.225 8.225 0 00.769-1.8 6.944 6.944 0 00.281-1.969V4.763L9 3.075zm0 9.656c-.45 0-.887.05-1.313.15-.425.1-.83.238-1.218.413.362.375.756.7 1.181.975.425.275.875.487 1.35.637.475-.15.925-.362 1.35-.637a6.71 6.71 0 001.181-.975 6.656 6.656 0 00-1.219-.413c-.425-.1-.862-.15-1.312-.15zm0 3.675a1.485 1.485 0 01-.469-.075C6.844 15.77 5.5 14.728 4.5 13.21 3.5 11.691 3 10.056 3 8.306V4.763c0-.313.09-.594.272-.844.181-.25.416-.431.703-.544l4.5-1.687c.175-.063.35-.094.525-.094.175 0 .35.031.525.094l4.5 1.687c.287.113.522.294.703.544.181.25.272.531.272.844v3.543c0 1.75-.5 3.385-1.5 4.903-1 1.52-2.344 2.56-4.031 3.122a1.48 1.48 0 01-.469.075z"></path>
          </g>
        </g>
      </svg>;
  }
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#e8eaed" viewBox="0 -960 960 960">
      <path fill={props.fill || '#1D252D'} d="M480-450q-54.77 0-92.38-37.62Q350-525.23 350-580q0-54.77 37.62-92.38Q425.23-710 480-710q54.77 0 92.38 37.62Q610-634.77 610-580q0 54.77-37.62 92.38Q534.77-450 480-450zm0-60q29.85 0 49.92-20.08Q550-550.15 550-580t-20.08-49.92Q509.85-650 480-650t-49.92 20.08Q410-609.85 410-580t20.08 49.92Q450.15-510 480-510zm0 409.23q-129.77-35.39-214.88-152.77Q180-370.92 180-516v-230.15l300-112.31 300 112.31V-516q0 145.08-85.12 262.46Q609.77-136.16 480-100.77zm0-378.85zm0-315L240-705v189q0 57.08 16.35 110.39 16.34 53.3 45.42 99.46 40.46-20.62 84.73-32.23Q430.77-350 480-350t93.5 11.62q44.27 11.61 84.73 32.23 29.08-46.16 45.42-99.46Q720-458.92 720-516v-189l-240-89.62zM480-290q-38.69 0-74.42 8.38-35.73 8.39-67.5 23.54 29.77 33.08 65.5 57.2Q439.31-176.77 480-164q40.69-12.77 76.23-36.88 35.54-24.12 65.31-57.2-31.77-15.15-67.31-23.54Q518.69-290 480-290z"></path>
    </svg>;
}
export default Icon;