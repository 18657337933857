import React from 'react';
function Icon() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <mask id="mask0_424_329" style={{
      maskType: 'alpha'
    }} width="24" height="24" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0 0H24V24H0z"></path>
      </mask>
      <g mask="url(#mask0_424_329)">
        <path fill="#3898D9" d="M6.871 11.792a.717.717 0 01-.217-.527c0-.207.072-.382.217-.527l4.495-4.494a.83.83 0 01.632-.256.83.83 0 01.633.256l4.494 4.494c.139.138.212.31.22.514a.697.697 0 01-.22.54.717.717 0 01-.527.217.717.717 0 01-.527-.217l-4.073-4.058-4.073 4.058a.744.744 0 01-.514.22.697.697 0 01-.54-.22zm0 6a.717.717 0 01-.217-.527c0-.207.072-.382.217-.527l4.495-4.494a.829.829 0 01.632-.256.829.829 0 01.633.256l4.494 4.494c.139.138.212.31.22.514a.697.697 0 01-.22.54.717.717 0 01-.527.217.717.717 0 01-.527-.217l-4.073-4.058-4.073 4.058a.744.744 0 01-.514.22.697.697 0 01-.54-.22z"></path>
      </g>
    </svg>;
}
export default Icon;