import React from 'react';
import utils from 'shared/utils';
import Stack from '@mui/material/Stack';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
export const LocationTooltip = ({
  fieldId,
  location
}) => {
  if (!location) {
    return <em>Unknown Location</em>;
  }
  return <Tooltip title={<Stack>
          <span>{location.name}</span>
          <span>{location.entity_number || location.entity_ref}</span>
          <span>{location.address1}</span>
          <span>{location.address2}</span>
          <span>
            {location.city}, {location.state} {location.zip_code}
          </span>
          <span> {utils.formatPhoneNumber(location.phone)} </span>
          <span>{location.store_manager}</span>
        </Stack>}>
      <span

    /* sx={{
         overflow:  id == onHover.row && onHover.column == 'location_format' ? 'visible' : 'hidden',
          textOverflow: id == onHover.row && onHover.column == 'location_format' ? 'visible' : 'ellipsis',
          whiteSpace:  id == onHover.row && onHover.column == 'location_format' ? 'normal' : 'nowrap',
        }}*/>
        {!!fieldId ? fieldId === 'stat_last_location_id' ? location.id : fieldId === 'stat_last_location__entity_ref' ? location.entity_ref : location.friendly_name : location.friendly_name}
      </span>
    </Tooltip>;
};