import React, { Component } from 'react';
import { func, object, shape, string } from 'prop-types';
import { EditorState, convertToRaw, ContentState, getDefaultKeyBinding, RichUtils } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import styles from '../customer-journey.styles.scss';
import { Scoped } from 'kremling';
export class TextParam extends Component {
  static propTypes = {
    update: func,
    node: object,
    data: shape({
      text: string
    })
  };
  state = {
    editorState: EditorState.createEmpty()
  };
  componentDidMount() {
    const contentBlock = htmlToDraft(this.props.data.text);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      this.setState({
        editorState
      });
    }
  }
  onEditorStateChange = editorState => {
    this.props.update({
      text: draftToHtml(convertToRaw(editorState.getCurrentContent()))
    });
    this.setState({
      editorState
    });
  };
  myKeyBindingFn = e => {
    if (e.keyCode === 13 /* `enter` key */) {
      return 'enter_command';
    }
    return getDefaultKeyBinding(e);
  };
  myHandleReturn(e, editorState) {
    const newEditorState = RichUtils.insertSoftNewline(editorState);
    if (newEditorState !== editorState) {
      this.onChange(newEditorState);
    }
  }
  render() {
    const {
      editorState
    } = this.state;
    return <Scoped css={styles}>
        <div>
          <div className="form-group mt-3">
            <Editor editorState={editorState} wrapperClassName="demo-wrapper" editorClassName="demo-editor rich-editor-control" onEditorStateChange={this.onEditorStateChange} keyBindingFn={this.myKeyBindingFn} handleReturn={this.myHandleReturn} style={{
            height: '100%',
            lineHeight: 1
          }} toolbar={{
            options: ['inline', 'fontFamily', 'fontSize', 'list', 'textAlign', 'colorPicker', 'emoji'],
            inline: {
              inDropdown: true
            },
            list: {
              inDropdown: true
            },
            textAlign: {
              inDropdown: true
            }
          }} />
          </div>
        </div>
      </Scoped>;
  }
}