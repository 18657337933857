export const operatorText = [{
  id: 'begins_with',
  name: 'begins with'
}, {
  id: 'does_not_begin_with',
  name: 'does not begin with'
}, {
  id: 'contains',
  name: 'contains'
}, {
  id: 'does_not_contain',
  name: 'does not contain'
}, {
  id: 'ends_with',
  name: 'ends with'
}, {
  id: 'does_not_end_with',
  name: 'does not end with'
}, {
  id: 'is_empty',
  name: 'is empty',
  end: true
}, {
  id: 'is_not_empty',
  name: 'is not empty',
  end: true
}, {
  id: 'equals',
  name: 'equals'
}, {
  id: 'does_not_equal',
  name: 'does not equal'
}, {
  id: 'in',
  name: 'in list'
}, {
  id: 'not_in',
  name: 'not in list'
}];
export const operatorUUID = [{
  id: 'equals',
  name: 'equals'
}, {
  id: 'does_not_equal',
  name: 'does not equal'
}];
export const operatorDate = [{
  id: 'before',
  name: 'is before'
}, {
  id: 'before_or_equal',
  name: 'is before or equal'
}, {
  id: 'after',
  name: 'is after'
}, {
  id: 'after_or_equal',
  name: 'is after or equal'
}, {
  id: 'equal_to',
  name: 'is equal to'
}, {
  id: 'not_equal',
  name: 'is not equal'
}, {
  id: 'is_null',
  name: 'is null',
  end: true
}, {
  id: 'is_not_null',
  name: 'is not null',
  end: true
}, {
  id: 'is_between',
  name: 'is between'
}];
export const operatorNumber = [{
  id: 'lesser',
  name: 'is lesser'
}, {
  id: 'lesser_or_equal',
  name: 'is lesser or equal'
}, {
  id: 'greater',
  name: 'is greater'
}, {
  id: 'greater_or_equal',
  name: 'is greater or equal'
}, {
  id: 'equal_to',
  name: 'is equal to'
}, {
  id: 'not_equal',
  name: 'is not equal'
}, {
  id: 'is_null',
  name: 'is null',
  end: true
}, {
  id: 'is_not_null',
  name: 'is not null',
  end: true
}];
export const operatorBoolean = [{
  id: 'is_true',
  name: 'is true',
  end: true
}, {
  id: 'is_false',
  name: 'is false',
  end: true
}];
export const operatorList = [{
  id: 'contains',
  name: 'contains'
}, {
  id: 'does_not_contain',
  name: 'does not contain'
}, {
  id: 'is_empty',
  name: 'is empty',
  end: true
}, {
  id: 'is_not_empty',
  name: 'is not empty',
  end: true
}, {
  id: 'is_null',
  name: 'is null',
  end: true
}, {
  id: 'is_not_null',
  name: 'is not null',
  end: true
}];
export function fieldTypeName(fieldType) {
  if (fieldType === 'number') return 'Number';
  if (fieldType === 'boolean') return 'Boolean';
  if (fieldType === 'date') return 'Date';
  if (fieldType === 'datetime') return 'Date+Time';
  return 'Text';
}
export function getField(fieldId, models) {
  return models.reduce((acc, next) => {
    const field = next.fields.find(field => field.id === fieldId);
    if (field) return field;
    return acc;
  }, {}) || null;
}
export function getFieldsFromModel(modelId, models) {
  return models.find(model => model.id === modelId).fields || null;
}