import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { Loader } from './loader/loader.component';
import { UserStateContext } from 'context/user-state-context';
import { NoCompany } from '../pages/no-company.component';
export const AuthCheck = ({
  children,
  location
}) => {
  const {
    user,
    asCompany,
    isLoadingUserStateApis,
    isLoadingFlags
  } = useContext(UserStateContext);

  // We haven't loaded the user yet.
  if (user === undefined || user && !asCompany) return <Loader overlay />;

  // User is not logged in.
  if (!user) return <Redirect to={`/sign-in?redirect=${encodeURIComponent(location.pathname + location.search)}`} />;

  // User has no companies to look at.
  if (user.companies.length === 0) return <NoCompany />;

  // The sidebar integrations and company fields data are still loading.
  if (isLoadingUserStateApis || isLoadingFlags) return <Loader overlay />;

  // Everything looks good, go ahead and render
  return children;
};