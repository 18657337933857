import React, { useState, useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import { UserStateContext } from 'context/user-state-context';
import { Calendar24HoursIcon, LibraryAddCheckIcon, LocationOnIcon } from 'components/mui';
import { Score } from 'components/score/score.component';
import { getSurveyCustomerAnswers, getSurveyInvite } from 'shared/common.api';
import { CircularProgress, Tooltip } from '@mui/material';
export default function QuestionsModal(props) {
  const {
    asCompany
  } = useContext(UserStateContext);
  const [surveyCustomerAnswers, setSurveyCustomerAnswers] = React.useState(null);
  const [inviteData, setInviteData] = React.useState(null);
  React.useEffect(() => {
    const promises = [];
    promises.push(getSurveyCustomerAnswers({
      company: asCompany.id,
      survey_invite: props.data?.id
    }));
    if (!props?.data?.customer) {
      promises.push(getSurveyInvite(props.data?.id));
    }
    Promise.all(promises).then(data => {
      if (data[0]?.data) {
        setSurveyCustomerAnswers(data[0].data?.results);
      }
      if (!props?.data?.customer && data[1]?.data) {
        setInviteData(data[1]?.data);
      } else {
        setInviteData({
          customer: props.data?.customer,
          customer_full_name: props.data?.customer_full_name,
          overall_score: props.data?.overall_score,
          survey_name: props.data?.survey_name,
          completed_when: props.data?.completed_when,
          survey_transaction_location_friendly_name: props.data?.survey_transaction_location_friendly_name
        });
      }
    });
  }, []);
  return <>
      <Dialog PaperProps={{
      style: {
        borderRadius: '28px',
        minWidth: '725px'
      }
    }} open={!!props.data} maxWidth="md" onClose={() => {
      props.onClose();
    }}>
        <DialogTitle sx={{
        m: 0,
        p: 2,
        fontSize: 'medium'
      }}>
          <IconButton aria-label="close" onClick={e => {
          props.onClose();
        }} sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500]
        }}>
            <CloseIcon />
          </IconButton>

          <Stack direction="row" spacing={2} onClick={e => {
          props.onCustomerDrawer({
            id: inviteData?.customer
          });
        }} sx={{
          cursor: 'pointer'
        }}>
            <Box>
              <Avatar />
            </Box>
            <Stack>
              <>{inviteData?.customer_full_name}</>
              <Box sx={{
              display: 'flex'
            }}>
                <Score rawValue={inviteData?.overall_score} />
              </Box>
            </Stack>
          </Stack>
        </DialogTitle>

        <DialogContent dividers>
          {surveyCustomerAnswers ? <DialogContentText>
              <Stack sx={{
            paddingBottom: '20px'
          }} component="span" spacing="26px">
                <Typography variant="tableHeader" sx={{
              fontSize: '18px'
            }}>
                  {inviteData?.survey_name}
                </Typography>

                <Stack direction="row" spacing="16px">
                  <Stack sx={{
                display: 'flex',
                alignItems: 'center'
              }} direction="row" spacing="8px">
                    <Calendar24HoursIcon />
                    <Box sx={{
                  display: 'flex',
                  alignItems: 'center'
                }}>
                      <Tooltip title={inviteData?.completed_when ? moment(inviteData?.completed_when).local().format('MMM D, YYYY h:mm A') : '--'}>
                        <Typography>
                          {inviteData?.completed_when ? moment(inviteData?.completed_when).local().format('MMM D, YYYY h:mm A') : '--'}
                        </Typography>
                      </Tooltip>
                    </Box>
                  </Stack>
                  <Stack sx={{
                display: 'flex',
                alignItems: 'center'
              }} direction="row" spacing="8px">
                    <LocationOnIcon />
                    <Box sx={{
                  display: 'flex',
                  alignItems: 'center'
                }}>
                      <Typography> {inviteData?.survey_transaction_location_friendly_name || '--'}</Typography>
                    </Box>
                  </Stack>
                  <Stack direction="row" spacing="8px" sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                    <LibraryAddCheckIcon />
                    <Box sx={{
                  display: 'flex',
                  alignItems: 'center'
                }}>
                      <Typography>
                        {'Version #' + surveyCustomerAnswers[0]?.revision?.index + ' Published: ' + (surveyCustomerAnswers[0]?.revision?.published_when ? moment(surveyCustomerAnswers[0]?.revision.published_when).local().format('MMM D, YYYY h:mm A') : '--')}
                      </Typography>
                    </Box>
                  </Stack>
                </Stack>
              </Stack>
            </DialogContentText> : <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          paddingTop: '20px'
        }}>
              <CircularProgress />
            </Box>}

          <Divider sx={{
          px: 2
        }} />

          <Stack sx={{
          pt: 2
        }}>
            {surveyCustomerAnswers?.map((surveyCustomerAnswer, index) => <Stack sx={{
            py: '10px'
          }} spacing={'20px'}>
                <Typography variant="tableHeader">{surveyCustomerAnswer.order + '. ' + surveyCustomerAnswer.q_text}</Typography>
                <Typography sx={{
              pl: '16px'
            }}>{surveyCustomerAnswer.answer}</Typography>
                {index < surveyCustomerAnswers.length - 1 && <Divider sx={{
              px: 2
            }} />}
              </Stack>)}
          </Stack>
        </DialogContent>

        <DialogActions sx={{
        py: 3,
        pr: 3
      }}>
          <Button onClick={() => {
          props.onClose();
        }} sx={{
          borderRadius: '20px',
          color: '#3898D9',
          width: '125px',
          borderStyle: 'solid',
          borderColor: '#53A6D6',
          borderWidth: '1px'
        }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>;
}