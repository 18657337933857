import React, { useState, useRef } from 'react';
import { AsyncModalDialog, ModalDialog, DynamicTypeAhead } from 'components';
import { getCompanies } from 'shared/common.api';
export const CopyFieldsModal = props => {
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);
  const [confirmCopyInput, setConfirmCopyInput] = useState('');
  const resetModal = () => {
    setSelectedCompany(null);
    setConfirmModal(false);
    setConfirmCopyInput('');
  };
  const onSubmit = () => {
    return props.onSubmit(selectedCompany.id).then(resetModal);
  };
  const onClose = () => {
    resetModal();
    props.onClose();
  };
  return <>
      <ModalDialog open={props.open && !confirmModal} onCancel={onClose} allowBackdropClick title="Copy Fields" onSubmit={() => setConfirmModal(true)} submitDisabled={!selectedCompany}>
        Copy fields from another company. This will completely replace all of
        the current company's fields.
        <div className="form-group mt-4">
          <label>Copy From:</label>

          <DynamicTypeAhead getItems={getCompanies} placeholder="Choose Company" displayProperty="name" keyProperty="id" value={selectedCompany} onChange={val => setSelectedCompany(val)} />
        </div>
      </ModalDialog>
      <AsyncModalDialog open={props.open && confirmModal} onClose={() => setConfirmModal(false)} allowBackdropClick title="Confirm Copy Fields" onSubmit={onSubmit} submitDisabled={confirmCopyInput !== 'YES'}>
        Are you sure you want to copy the data fields, names, and data types
        from {selectedCompany && selectedCompany.name}? Doing so will replace
        every currently set for this company, this may have a negative impact on
        any journeys or templates. Please Type YES below to confirm.
        <div className="form-group mt-4">
          <label>Confirm:</label>
          <input required className="form-control" onChange={e => setConfirmCopyInput(e.target.value)} value={confirmCopyInput} />
        </div>
      </AsyncModalDialog>
    </>;
};