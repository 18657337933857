import * as Sentry from '@sentry/react';
import { api } from './api';
export function signIn(body) {
  return api.post('/users/login', body);
}
export function validateMFA(mfa, rememberMe, deviceId) {
  return api.post('/users/validate-mfa', {
    mfa: mfa,
    remember_me: rememberMe,
    device_id: deviceId
  });
}
export function validatePhone(phone) {
  return api.post('/users/validate-phone', {
    phone
  });
}
export function validateCode(phone, code) {
  return api.post('/users/validate-code', {
    phone,
    code
  });
}
export function updatePassword(currentPassword, newPassword, newPasswordConfirmation) {
  return api.post('/users/update-password', {
    current_password: currentPassword,
    new_password: newPassword,
    new_password_confirmation: newPasswordConfirmation
  });
}
export function getMe() {
  return api.get(`/users/me`).then(response => {
    Sentry.setUser(response?.data);
    return response;
  });
}
export function logOut() {
  return api.post(`/users/logout`);
}
export function signInSSO(body) {
  return api.post('/integration/hubspot/sign-in', body);
}
export function getHubspotToken() {
  return api.get('/integration/hubspot/token');
}
export function getSSORedirect(provider) {
  return api.get(`/sso/${provider}/auth`);
}
export function getSSOToken(provider) {
  return api.get(`/sso/${provider}/redirect${window.location.search}`);
}
export function requestUnlock(email) {
  return api.post('/users/request-unlock', {
    email
  });
}
export function unlock(token) {
  return api.post('/users/unlock', {
    token
  });
}