import React from "react";
function Icon() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="none" viewBox="0 0 25 25">
      <mask id="mask0_1_981" style={{
      maskType: "alpha"
    }} width="25" height="25" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0.051 0.623H24.051V24.623H0.051z"></path>
      </mask>
      <g mask="url(#mask0_1_981)">
        <path fill="#1D252D" d="M15.05 16.18a.726.726 0 00.535-.214.726.726 0 00.216-.535v-1.25h1.25a.726.726 0 00.534-.215.726.726 0 00.216-.535.726.726 0 00-.216-.535.726.726 0 00-.534-.215H15.8v-1.25a.726.726 0 00-.216-.535.726.726 0 00-.534-.215.726.726 0 00-.535.215.726.726 0 00-.215.535v1.25h-1.25a.726.726 0 00-.535.215.726.726 0 00-.215.535c0 .213.072.391.215.535a.725.725 0 00.535.215h1.25v1.25c0 .213.072.391.215.535a.725.725 0 00.535.215zM4.359 19.93c-.505 0-.932-.174-1.282-.524a1.745 1.745 0 01-.525-1.283v-11c0-.505.175-.932.525-1.282.35-.35.777-.525 1.282-.525h5.33c.24 0 .472.046.694.14.223.094.416.222.58.387l1.089 1.088h7.692c.505 0 .933.175 1.283.525.35.35.525.778.525 1.283v9.384c0 .505-.175.933-.525 1.283-.35.35-.778.525-1.283.525H4.358zM4.051 7.124v11a.3.3 0 00.086.221.3.3 0 00.221.087h15.385a.3.3 0 00.221-.087.3.3 0 00.087-.22V8.738a.3.3 0 00-.087-.221.3.3 0 00-.221-.087h-8.306L9.822 6.816H4.358a.3.3 0 00-.22.086.3.3 0 00-.087.221zm0 0v-.307V18.43 7.123z"></path>
      </g>
    </svg>;
}
export default Icon;