import React, { Component } from 'react';
import { Scoped, k } from 'kremling';
import { func, object, shape, array } from 'prop-types';
export class ConditionValueSegment extends Component {
  static propTypes = {
    update: func,
    node: object,
    data: shape({
      value_segments: array
    })
  };
  isSegmentsChecked = segment => {
    return this.props.data.value_segments.includes(segment);
  };
  updateSegments = segment => {
    const segments = this.props.data.value_segments;
    this.props.update({
      value_segments: segments.includes(segment) ? [...segments.splice(0, segments.indexOf(segment)), ...segments.splice(segments.indexOf(segment) + 1)] : [...segments, segment]
    });
  };
  render() {
    return <Scoped css={css}>
        <div>
          <div className="form-group segment_values">
            <label>
              <input type="checkbox" checked={this.isSegmentsChecked('1')} onChange={() => this.updateSegments('1')} />{' '}
              1
          </label>
            <label>
              <input type="checkbox" checked={this.isSegmentsChecked('2')} onChange={() => this.updateSegments('2')} />{' '}
              2
          </label>
            <label>
              <input type="checkbox" checked={this.isSegmentsChecked('3')} onChange={() => this.updateSegments('3')} />{' '}
              3
          </label>
            <label>
              <input type="checkbox" checked={this.isSegmentsChecked('4')} onChange={() => this.updateSegments('4')} />{' '}
              4
          </label>
            <label>
              <input type="checkbox" checked={this.isSegmentsChecked('5')} onChange={() => this.updateSegments('5')} />{' '}
              5
          </label>
            <label>
              <input type="checkbox" checked={this.isSegmentsChecked('no_value')} onChange={() => this.updateSegments('no_value')} />{' '}
              No Value
          </label>
          </div>
        </div>
      </Scoped>;
  }
}
const css = {
  styles: `[kremling="i31"] body,body[kremling="i31"] {
  --color-primary: #487aae;
  --color-highlight: #e7efff;
  --color-accent: #354052;
  --color-success: #73b56e;
  --color-warning: #f4b707;
  --color-danger: #df5651;
  --color-grey-10: #f9f9f9;
  --color-grey-25: #f3f3f3;
  --color-grey-50: #e9e9e9;
  --color-grey-75: #e3e3e3;
  --color-grey-100: #d3d3d3;
  --color-grey-200: #c3c3c3;
  --color-grey-300: #b3b3b3;
  --color-grey-400: #a3a3a3;
  --color-grey-500: #808080;
  --color-grey-600: #707070;
  --color-grey-700: #606060;
  --color-grey-800: #505050;
  --color-grey-900: #404040;
  --base-font-family: Roboto, Helvetica, Arial, sans-serif;
  --base-font-size: 1.4rem;
  --base-font-color: #404040;
  --base-font-weight: 400;
  --base-line-height: 1.4;
  --box-shadow-1: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.12);
  --box-shadow-2: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-3: 0 10px 20px rgba(0, 0, 0, 0.17), 0 6px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-4: 0 14px 28px rgba(0, 0, 0, 0.13), 0 10px 10px rgba(0, 0, 0, 0.1);
  --box-shadow-5: 0 19px 38px rgba(0, 0, 0, 0.18), 0 15px 12px rgba(0, 0, 0, 0.1);
  --base-border-radius: 0.4rem;
}

[kremling="i31"] .segment_values label,[kremling="i31"].segment_values label {
  display: inline-flex;
  width: 11.5rem;
  align-items: center;
}`,
  id: 'i31',
  namespace: 'kremling'
};