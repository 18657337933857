import React from 'react';
function Icon() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none" viewBox="0 0 24 25">
      <mask id="mask0_116_1235" style={{
      maskType: 'alpha'
    }} width="24" height="25" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0 0.5H24V24.5H0z"></path>
      </mask>
      <g mask="url(#mask0_116_1235)">
        <path fill="#1D252D" d="M12 9.677a.93.93 0 01-.34-.065.84.84 0 01-.293-.19L8.373 6.426a.717.717 0 01-.217-.527c0-.206.072-.382.217-.527a.717.717 0 01.527-.217c.206 0 .382.072.527.217L12 7.946l2.573-2.573a.717.717 0 01.527-.217c.206 0 .382.072.527.217.145.145.217.32.217.527a.717.717 0 01-.217.527L12.633 9.42a.829.829 0 01-.633.256zm-3.627 9.95a.717.717 0 01-.217-.527c0-.206.072-.382.217-.527l2.994-2.994a.817.817 0 01.301-.198 1.026 1.026 0 01.676 0 .691.691 0 01.289.198l3.01 3.01a.682.682 0 01.209.519.736.736 0 01-.225.519.717.717 0 01-.527.217.717.717 0 01-.527-.217L12 17.054l-2.573 2.573a.717.717 0 01-.527.217.717.717 0 01-.527-.217z"></path>
      </g>
    </svg>;
}
export default Icon;