import React from 'react';
function Icon(props) {
  if (props.size == 'xlg') {
    return <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="#e8eaed" viewBox="0 -960 960 960">
        <path fill={props.fill || '#FFB82B'} d="M385.08-369.92h45.38v-220.77h-45.38v220.77zm144.46 0h45.38v-220.77h-45.38v220.77zM480.07-100q-78.84 0-148.21-29.86-69.37-29.85-120.68-81.04-51.31-51.18-81.25-120.37Q100-400.46 100-479.23q0-39.11 7.23-75.83 7.23-36.71 21.93-72.02l34.23 34.23q-8.39 27.62-13.2 55.84-4.8 28.23-4.8 57.01 0 140.12 97.23 237.37 97.24 97.24 237.34 97.24 140.11 0 237.38-97.23 97.27-97.24 97.27-237.34 0-140.11-97.34-237.38-97.35-97.27-237.62-97.27-28.27 0-56.4 4.57-28.14 4.57-55.33 13.43l-35.23-34.85q34.54-12.46 69.77-20.5t72.39-8.04q79.4 0 149.25 29.77 69.84 29.77 122.35 81.27 52.52 51.5 83.03 120.81Q860-558.84 860-480.04q0 78.81-29.92 148.18t-81.21 120.68q-51.29 51.31-120.63 81.25Q558.9-100 480.07-100zM223.25-697.85q-16.63 0-28.44-11.94Q183-721.74 183-738.37t11.95-28.44q11.95-11.8 28.58-11.8 16.62 0 28.43 11.94 11.81 11.95 11.81 28.58t-11.95 28.44q-11.95 11.8-28.57 11.8zM480-480z"></path>
      </svg>;
  }
  if (props.size == 'lg') {
    return <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="#e8eaed" viewBox="0 -960 960 960">
        <path fill={props.fill || '#FFB82B'} d="M382.1-369.18h50.26v-222.05H382.1v222.05zm145.54 0h50.26v-222.05h-50.26v222.05zM480.07-100q-78.84 0-148.21-29.86-69.37-29.85-120.68-81.04-51.31-51.18-81.25-120.37Q100-400.46 100-479.23q0-39.27 7.44-76.48 7.43-37.21 22.25-72.85l38.36 38.35q-8.38 27.08-13.09 54.61-4.7 27.53-4.7 55.6 0 138.08 95.82 233.91 95.83 95.83 233.9 95.83 138.06 0 233.91-95.82 95.85-95.83 95.85-233.9 0-138.06-95.9-233.91t-234.08-95.85q-27.84 0-55.22 4.54-27.39 4.55-53.98 13.25l-38.82-38.56q34.95-13.41 70.56-21.45 35.6-8.04 73.49-8.04 79.36 0 149.15 29.77t122.03 81.27q52.24 51.5 82.63 120.81Q860-558.84 860-480.04q0 78.81-29.92 148.18t-81.21 120.68q-51.29 51.31-120.63 81.25Q558.9-100 480.07-100zM224.98-693.18q-17.62 0-30.17-12.64-12.55-12.65-12.55-30.27 0-17.63 12.64-30.18 12.65-12.55 30.27-12.55 17.63 0 30.18 12.65 12.55 12.64 12.55 30.26 0 17.63-12.65 30.18-12.64 12.55-30.27 12.55zM480-480z"></path>
      </svg>;
  }
  return <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="none" viewBox="0 0 25 25">
      <mask id="mask0_10_845" style={{
      maskType: 'alpha'
    }} width="25" height="25" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0.902 0.141H24.902V24.141H0.902z"></path>
      </mask>
      <g mask="url(#mask0_10_845)">
        <path fill={props.fill || '#FFB82B'} d="M11.056 14.949c.213 0 .391-.072.535-.216a.726.726 0 00.215-.534v-4.115a.725.725 0 00-.216-.535.726.726 0 00-.534-.215.725.725 0 00-.534.215.726.726 0 00-.216.535v4.115c0 .212.072.39.216.534a.726.726 0 00.534.216zm3.693 0c.212 0 .39-.072.534-.216a.726.726 0 00.215-.534v-4.115a.725.725 0 00-.215-.535.726.726 0 00-.535-.215.725.725 0 00-.534.215.726.726 0 00-.216.535v4.115c0 .212.072.39.216.534a.726.726 0 00.535.216zm-1.845 6.692a9.272 9.272 0 01-3.705-.746 9.596 9.596 0 01-3.017-2.026 9.57 9.57 0 01-2.031-3.01 9.215 9.215 0 01-.749-3.698c0-.44.031-.877.093-1.31.061-.432.148-.86.261-1.283.07-.283.216-.478.435-.584.219-.107.454-.086.704.061.125.074.221.19.29.35.068.159.078.323.03.492-.102.37-.18.742-.233 1.118-.053.375-.08.754-.08 1.136 0 2.234.775 4.125 2.325 5.675 1.55 1.55 3.442 2.325 5.675 2.325 2.234 0 4.125-.775 5.675-2.325 1.55-1.55 2.325-3.441 2.325-5.675 0-2.233-.775-4.125-2.325-5.675-1.55-1.55-3.441-2.325-5.675-2.325a7.93 7.93 0 00-1.133.08 8.582 8.582 0 00-1.072.224.78.78 0 01-.51-.009.717.717 0 01-.38-.322.62.62 0 01-.035-.66.888.888 0 01.573-.45 6.168 6.168 0 011.23-.273c.464-.06.888-.09 1.27-.09 1.32 0 2.561.248 3.723.745a9.589 9.589 0 013.034 2.031 9.653 9.653 0 012.046 3.02 9.18 9.18 0 01.754 3.703c0 1.314-.249 2.548-.748 3.705a9.596 9.596 0 01-2.03 3.017 9.59 9.59 0 01-3.016 2.03 9.245 9.245 0 01-3.704.75zM6.614 7.045c-.327 0-.607-.117-.841-.35a1.148 1.148 0 01-.351-.842c0-.327.117-.608.35-.842.235-.234.515-.35.842-.35.327 0 .607.117.841.35.234.234.351.515.351.842 0 .327-.117.607-.35.841a1.148 1.148 0 01-.842.351z"></path>
      </g>
    </svg>;
}
export default Icon;