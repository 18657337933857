import React from 'react';
function Icon() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" fill="none" viewBox="0 0 25 24">
      <mask id="mask0_1036_3005" style={{
      maskType: 'alpha'
    }} width="25" height="24" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#C2E7FF" d="M0.518 0H24.518V24H0.518z"></path>
      </mask>
      <g mask="url(#mask0_1036_3005)">
        <path fill="#1D252D" d="M8.643 16.75c.2 0 .375-.07.525-.212A.71.71 0 009.393 16v-5a.71.71 0 00-.225-.538.741.741 0 00-.525-.212.727.727 0 00-.75.75v5a.728.728 0 00.75.75zm3.875 0c.217 0 .396-.07.538-.212a.731.731 0 00.212-.538V8a.728.728 0 00-.75-.75.726.726 0 00-.75.75v8a.728.728 0 00.75.75zm3.875 0c.217 0 .396-.07.538-.212a.731.731 0 00.212-.538v-2a.731.731 0 00-.212-.538.73.73 0 00-.538-.212c-.2 0-.375.07-.525.212a.71.71 0 00-.225.538v2a.71.71 0 00.225.538c.15.141.325.212.525.212zM5.818 20.5c-.5 0-.925-.175-1.275-.525a1.736 1.736 0 01-.525-1.275V5.3c0-.5.175-.925.525-1.275.35-.35.775-.525 1.275-.525h13.4c.5 0 .925.175 1.275.525.35.35.525.775.525 1.275v13.4c0 .5-.175.925-.525 1.275-.35.35-.775.525-1.275.525h-13.4zm0-1.5h13.4a.292.292 0 00.213-.087.292.292 0 00.087-.213V5.3a.289.289 0 00-.087-.212.29.29 0 00-.213-.088h-13.4a.287.287 0 00-.212.088.287.287 0 00-.088.212v13.4c0 .083.03.154.088.213a.288.288 0 00.212.087z"></path>
      </g>
    </svg>;
}
export default Icon;