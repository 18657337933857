import React, { useContext, useEffect } from 'react';
import { Loader, PageHeaderMui } from 'components';
import { copySegment, editSegment, getJourneys, getSegment, getSegmentsTags, newSegment, segmentConvertToQuery, testSegment } from '../../shared/common.api';
import { Box, Button, Chip, ClickAwayListener, IconButton, Paper, Popper, Tooltip, Typography, InputAdornment, Divider, Radio, Grid, InputLabel, Select, MenuItem, FormControl } from '@mui/material';
import Stack from '@mui/material/Stack';
import CssBaseline from '@mui/material/CssBaseline';
import { UserStateContext } from 'context/user-state-context';
import { withStyles } from '@mui/styles';
import { Icon } from '../../components/icon/icon.component';
import { history } from '../../root.component';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { AntSwitch } from 'components/mui/switch/ant-switch.component';
import numeral from 'numeral';
import { Prompt } from 'react-router-dom';
import { AddCircleFilledIcon, Calendar30DaysIcon, CustomerIcon, CustomersIcon, ShoppingCartIcon, TransactionIcon, VehicleIcon, SmartToyIcon } from 'components/mui';
import { ModalContext } from 'context/modal-context';
import { StaticPromptModal } from './static-prompt-modal.component';
import { EditSegmentAI } from './edit-segment-ai.component';
import { EditSegmentAdvanced } from './edit-segment-advanced.component';
import { EditSegmentBuilder } from './edit-segment-builder.component';
import { EditMode, BuilderType, segmentIncludedTypes, segmentTypes } from './consts';
import { CustomTextField } from './custom-text-field.component';
import { EditSegmentSample } from './edit-segment-sample.component';
const CustomRadio = withStyles({
  root: {
    '& .MuiSvgIcon-root': {
      height: 19,
      width: 19
    },
    '&$checked': {
      color: '#53A6D6'
    }
  },
  checked: {}
})(props => <Radio color="default" {...props} />);
export const EditSegment = props => {
  const {
    asCompany,
    hasPermission,
    flags,
    hasIntegration
  } = useContext(UserStateContext);
  const {
    show,
    confirm
  } = useContext(ModalContext);
  const [segment, setSegment] = React.useState({
    name: history.location.segmentName || 'New Segment',
    company: asCompany.id
  });
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const [builderType, setBuilderType] = React.useState(BuilderType.BUILDER);
  const [segmentError, setSegmentError] = React.useState(null);
  const [testCount, setTestCount] = React.useState(null);
  const [testLoading, setTestLoading] = React.useState(false);
  const [testError, setTestError] = React.useState(false);
  const [hasMore, setHasMore] = React.useState(false);
  const [sampleCustomers, setSampleCustomers] = React.useState([]);
  const [journeysTriggered, setJourneysTriggered] = React.useState([]);
  const [journeysEdited, setJourneysEdited] = React.useState([]);
  const [segmentTypesMenuAnchorEl, setSegmentTypesMenuAnchorEl] = React.useState(null);
  const segmentTypesOpen = Boolean(segmentTypesMenuAnchorEl);
  const [distinctValuesSwitch, setDistinctValuesSwitch] = React.useState(false);
  const [newTagName, setNewTagName] = React.useState('');
  const [companyTags, setCompanyTags] = React.useState([]);
  const [companyTagsMenuAnchorEl, setCompanyTagsMenuAnchorEl] = React.useState(null);
  const companyTagsOpen = Boolean(companyTagsMenuAnchorEl);
  const [hasUnsavedChanges, setHasUnsavedChanges] = React.useState(false);
  const [isValid, setIsValid] = React.useState(true);
  const [showErrors, setShowErrors] = React.useState(false);
  const view = window.location.pathname.includes('edit') ? EditMode.EDIT : window.location.pathname.includes('copy') ? EditMode.COPY : EditMode.CREATE;
  useEffect(() => {
    const id = props.match.params.id;
    getSegmentsTags(asCompany.id).then(data => {
      setCompanyTags(data.data.results);
    });
    if (view === EditMode.EDIT) {
      setIsLoading(true);
      Promise.all([getSegment(id), getJourneys({
        company: asCompany.id,
        node_segment_triggers: id,
        archived: false
      }), getJourneys({
        company: asCompany.id,
        node_segment_edits: id,
        archived: false
      })]).then(([segmentResult, journeysTriggered, journeysEdited]) => {
        setBuilderType(!!segmentResult.data.segment_builder || !segmentResult.data.segment_query ? BuilderType.BUILDER : BuilderType.ADVANCED);
        setIsLoading(false);
        setSegment(segmentResult.data);
        setDistinctValuesSwitch(!!segmentResult.data.distinct_on);
        setJourneysEdited(journeysEdited.data.results);
        setJourneysTriggered(journeysTriggered.data.results);
      });
    } else if (view === EditMode.COPY) {
      // Chris - This API call didn't do anything with the data so I'm commenting it out to save resources.
      /* getSegmentCustomers(props.match.params.id).then(({ data }) => {
        if (data) {
          //this.setState({ customers: data });
        }
      }); */
      getSegment(props.match.params.id).then(({
        data
      }) => {
        if (data) {
          setBuilderType(BuilderType.BUILDER);
          const newSegment = data;
          newSegment.company = data.company;
          newSegment.name = data.name + ' Copy';
          setSegment(newSegment);
        }
      });
    }
  }, []);

  /**
   * This useEffects responsibility is to load in any history.push() props and
   * check for RTC related props and load them into the segment model.
   */
  useEffect(() => {
    if (props.location.state?.rtcRule) {
      const rule = props.location.state?.rtcRule;
      if (rule) {
        const currentSegment = segment;
        currentSegment.segment_builder = {
          and: [{
            field: 'rule_id',
            model: 'transaction_calculator_results',
            value: rule.id,
            operator: 'equals',
            time_type: '',
            time_unit: '',
            query_type: 'value',
            related_field: '',
            related_model: ''
          }, {
            model: 'transaction_calculator_results',
            field: 'estimated_return_date',
            operator: '',
            query_type: ''
          }]
        };
        currentSegment.time_dynamic_segment = true;
        if (rule.includes_possession) {
          currentSegment.included = 'possession';
        }
        setSegment(currentSegment);
        updateSegment('name', `RTC rule: ${rule.name}`);
      }
    }
  }, []);
  const updateSegment = (key, val) => {
    setHasUnsavedChanges(true);
    if (val === '' || val === 'Customer Only') {
      val = null;
    }
    setSegment(prevState => ({
      ...prevState,
      [key]: val
    }));
  };
  const handleSegmentTypesClose = () => {
    setSegmentTypesMenuAnchorEl(null);
  };
  const handleCloseClick = () => {
    if (segment.hasOwnProperty('id')) {
      history.push(`/segments/${segment.id}`);
    } else {
      history.push(`/segments/`);
    }
  };
  const handleNewTagClick = () => {
    if (newTagName?.length > 0 && !isExistingTag()) {
      const newTags = segment.tags || [];
      newTags.push(newTagName);
      updateSegment('tags', newTags);
      setNewTagName('');
      setHasUnsavedChanges(true);
    }
  };
  const handleDelete = index => () => {
    const newTags = segment.tags;
    newTags.splice(index, 1);
    updateSegment('tags', newTags);
  };
  const isExistingTag = () => {
    if (!segment.tags || segment.tags?.length == 0) {
      return false;
    }
    for (const tag of segment.tags) {
      if (tag.toLowerCase() == newTagName?.toLowerCase()) {
        return true;
      }
    }
    return false;
  };
  const handleDistinctValuesSwitchChange = event => {
    setDistinctValuesSwitch(event.target.checked);
    if (!event.target.checked) {
      updateSegment('distinct_on', null);
    } else {
      updateSegment('distinct_on', 'email');
    }
  };
  const handledistinctValuesRadioChange = event => {
    updateSegment('distinct_on', event.target.value);
  };
  const handleAskMore = () => {
    confirm({
      title: 'Calculate Total',
      message: `Calculating the total segment size may take several minutes. Are you sure you would like to proceed?`,
      submitText: 'Calculate'
    }).then(() => {
      handleFullCount();
    }).catch(() => {});
  };
  const handleAdvancedSwitch = () => {
    confirm({
      title: 'Use Advanced Query Editor',
      message: `Are you sure you want to switch to advanced mode? This action cannot be undone - all "segment builder" logic will be lost.`,
      submitText: 'Use Advanced Query Editor'
    }).then(() => {
      switchToAdvancedMode();
    }).catch(() => {});
  };
  const handleAISwitch = () => {
    confirm({
      title: 'Use AI Assistant',
      message: `Are you sure you want to switch to AI mode? This action cannot be undone - all "segment builder" logic will be lost.`,
      submitText: 'Use AI Assistant'
    }).then(() => {
      setBuilderType(BuilderType.AI);
    }).catch(() => {});
  };
  const handleShowRuleErrors = () => {
    setShowErrors(true);
    const timer = setTimeout(() => {
      setShowErrors(false);
    }, 4000);
    return () => clearTimeout(timer);
  };
  const handleSubmit = () => {
    if (!isValid) {
      handleShowRuleErrors();
    }
    if (!segment.name || isSaving || !isValid) {
      return;
    }
    setIsSaving(true);
    if (view === EditMode.EDIT) {
      let promise = Promise.resolve();
      if (!segment.time_dynamic_segment) {
        // This is a static segment.  Ask customer if they want to clear out segment or append
        promise = promise.then(() => show(StaticPromptModal)).then(clearSegment => {
          segment.clear_on_update = clearSegment;
        });
      }
      promise.then(() => editSegment(segment.id, segment)).then(({
        data
      }) => {
        setHasUnsavedChanges(false);
        if (data) {
          history.push(`/segments/${segment?.id}`);
        }
      }).catch(error => {
        if (error?.response?.data?.segment_query) {
          setSegmentError(error.response.data.segment_query[0]);
        }
      }).then(() => {
        setIsSaving(false);
      });
    } else if (view === EditMode.COPY) {
      copySegment(segment).then(({
        data
      }) => {
        setHasUnsavedChanges(false);
        if (data) {
          history.push('/segments');
        }
      }).catch(error => {
        setSegmentError(error.response.data.segment_query[0]);
      });
    } else if (view === EditMode.CREATE) {
      newSegment({
        ...segment,
        company: asCompany.id,
        folder: history.location.folder
      }).then(({
        data
      }) => {
        setHasUnsavedChanges(false);
        if (data) {
          if (history.location.folder) {
            history.push(`/segments?folder=${history.location.folder}`);
          } else {
            history.push('/segments');
          }
        }
      }).catch(error => {
        if (error.response.data.segment_query) {
          setSegmentError(error.response.data.segment_query[0]);
        } else if (error.response.data.time_dynamic_segment) setSegmentError(error.response.data.time_dynamic_segment[0]);
        setIsSaving(false);
      });
    }
  };
  const handleTest = () => {
    if (testLoading || !segment.segment_builder && !segment.segment_query) {
      return true;
    }
    setTestLoading(true);
    setTestCount(null);
    setTestError(null);
    testSegment(segment).then(({
      data
    }) => {
      if (segment.included) {
        let customerCount = data.customer_count;
        if (data.customer_count > 1000) {
          customerCount = 1000;
        }
        setTestLoading(false);
        setSampleCustomers(data.customer_sample);
        setTestCount(`${numeral(customerCount).format('0,0')}${data.included_count > 1000 ? '+' : ''} customers, ${data.included_count > 1000 ? '1,000+' : numeral(data.included_count).format('0,0')} ${segment.included}s found`);
        setHasMore(data.included_count > 1000);
      } else {
        setTestLoading(false);
        setSampleCustomers(data.customer_sample);
        setTestCount(`${data.customer_count > 1000 ? '1,000+' : numeral(data.customer_count).format('0,0')} customers found`);
        setHasMore(data.customer_count > 1000);
      }
    }).catch(e => {
      let testErr = 'There was an error in the query.  Please double check your query.';
      if (e.response && Array.isArray(e.response.data) && e.response.data.length) {
        testErr = e.response.data[0];
      }
      setTestLoading(false);
      setTestCount(null);
      setTestError(testErr);
      setHasMore(false);
      setSampleCustomers([]);
    });
  };
  const handleCompanyTagsClick = event => {
    setCompanyTagsMenuAnchorEl(companyTagsOpen ? null : event.currentTarget);
  };
  const handleCompanyTagsClose = () => {
    setCompanyTagsMenuAnchorEl(null);
  };
  const handleViewSample = () => {
    show(EditSegmentSample, {
      sampleCustomers
    });
  };
  const switchToAdvancedMode = () => {
    let promise = Promise.resolve();
    if (segment.segment_builder) {
      promise = promise.then(() => {
        return segmentConvertToQuery({
          segment_builder: segment.segment_builder,
          company: segment.company,
          included: segment.included
        }).then(({
          data
        }) => data.query);
      });
    }
    promise.then(query => {
      setSegment(prevState => ({
        ...prevState,
        segment_builder: null,
        segment_query: segment.segment_query || query
      }));
      setBuilderType(BuilderType.ADVANCED);
    });
  };
  const handleFullCount = () => {
    setTestLoading(true);
    setTestCount(null);
    setTestError(null);
    testSegment({
      ...segment,
      full: true
    }).then(({
      data
    }) => {
      setTestLoading(false);
      setTestCount(`${numeral(data.count).format('0,0')} records found`);
      setHasMore(false);
    });
  };
  return <>
      <CssBaseline />
      {isLoading && <Loader overlay />}
      <div className="wrapper">
        <PageHeaderMui type="Segments" name={segment.name} updateName={name => updateSegment('name', name.name)} updateNamePermission={hasPermission('customer.change_segment')} icon={<Icon name="custom-segment" size={34} />} />

        <div className="mui-wrapper">
          <Box sx={{
          m: '20px'
        }}>
            <Paper sx={{
            width: '100%',
            //height: '85vh',
            borderRadius: '14px'
          }}>
              <Box sx={{
              height: '100%'
            }} display="flex" flexDirection="column" justifyContent="space-between">
                <Stack spacing="30px" sx={{
                py: '30px',
                px: '24px'
              }}>
                  <Stack spacing="30px">
                    <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{
                    width: '100%'
                  }}>
                      <Typography sx={{
                      fontFamily: 'Nexa',
                      fontSize: 24,
                      color: '#1D252D'
                    }}>Segment Details </Typography>
                      <Box sx={{
                      cursor: 'pointer'
                    }} onClick={handleCloseClick}>
                        <Icon name="custom-close" size={24} />
                      </Box>
                    </Box>

                    <Stack direction="row" spacing={'20px'}>
                      <Box>
                        <FormControl variant="outlined" sx={{
                        width: '215px',
                        height: '50px'
                      }}>
                          <InputLabel sx={{
                          opacity: 0.5,
                          '&.Mui-focused': {
                            opacity: 1
                          }
                        }} id="segment-type-select-label">
                            Segment Type
                          </InputLabel>

                          <Select labelId="segment-type-select-label" id="segment-type-select" value={segment.time_dynamic_segment ? 'dynamic' : 'static'} onChange={e => {
                          if (e.target.value === 'dynamic') {
                            updateSegment('time_dynamic_segment', true);
                          } else if (e.target.value === 'static') {
                            updateSegment('time_dynamic_segment', false);
                            updateSegment('target_database', 'postgres');
                          }
                          handleSegmentTypesClose();
                        }} label="Segment Type" sx={{
                          width: '215px',
                          height: '55px'
                        }} renderValue={selected => <Box sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '10px'
                        }}>
                                <Icon name={selected === 'dynamic' ? 'custom-dynamic_segment' : 'custom-web_asset'} size={24} sx={{
                            pl: '15px'
                          }} />
                                {selected.charAt(0).toUpperCase() + selected.slice(1)}
                              </Box>}>
                            <MenuItem sx={{
                            pl: '15px'
                          }} value="static">
                              <Icon name="custom-web_asset" size={24} />
                              Static
                            </MenuItem>

                            <MenuItem sx={{
                            pl: '15px'
                          }} value="dynamic">
                              <Icon name="custom-dynamic_segment" size={24} />
                              Dynamic
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                      <Box>
                        <FormControl variant="outlined" sx={{
                        width: '215px',
                        height: '50px'
                      }}>
                          <InputLabel sx={{
                          opacity: 0.5,
                          '&.Mui-focused': {
                            opacity: 1
                          }
                        }} id="segment-inclusion-select-label">
                            Segment Inclusion
                          </InputLabel>

                          <Select labelId={`segment-inclusion-select-label-${segment.included}`} id={`"segment-inclusion-select-"${segment.included}`} options={segmentIncludedTypes} onChange={e => {
                          updateSegment('included', e.target.value);
                          handleSegmentTypesClose();
                        }} label="Segment Inclusion" sx={{
                          width: '215px',
                          height: '55px'
                        }} value={segment.included || 'Customer Only'} renderValue={selected =>
                        // this handles the CSS of the items in the select
                        <Box sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '10px'
                        }}>
                                {segment.included === 'possession' ? <VehicleIcon size={24} sx={{
                            pl: '15px'
                          }} /> : segment.included === 'schedule' ? <Calendar30DaysIcon size={24} sx={{
                            pl: '15px'
                          }} /> : segment.included === 'transaction' ? <TransactionIcon size={24} sx={{
                            pl: '15px'
                          }} /> : segment.included === 'cart' ? <ShoppingCartIcon size={24} sx={{
                            pl: '15px'
                          }} /> : <CustomerIcon size={24} sx={{
                            pl: '15px'
                          }} />}
                                {/* {segment?.included.charAt(0).toUpperCase() + segment?.included.slice(1)} */}
                                {selected.charAt(0).toUpperCase() + selected.slice(1)}
                              </Box>}>
                            {segmentIncludedTypes.map(type => <MenuItem sx={{
                            pl: '15px'
                          }} value={type.key}>
                                {type.key === null ? <CustomerIcon size={24} sx={{
                              pl: '15px'
                            }} /> : type.key === 'possession' ? <VehicleIcon size={24} sx={{
                              pl: '15px'
                            }} /> : type.key === 'schedule' ? <Calendar30DaysIcon size={24} sx={{
                              pl: '15px'
                            }} /> : type.key === 'transaction' ? <TransactionIcon size={24} sx={{
                              pl: '15px'
                            }} /> : type.key === 'cart' ? <ShoppingCartIcon size={24} sx={{
                              pl: '15px'
                            }} /> : <CustomersIcon size={24} sx={{
                              pl: '15px'
                            }} />}
                                {type.name}
                              </MenuItem>)}
                          </Select>
                        </FormControl>
                      </Box>

                      <Stack display="flex" alignItems="center" direction="row" spacing="10px">
                        <Box onClick={handleCompanyTagsClick}>
                          <CustomTextField label="Add tag" placeholder="Type tag name" sx={{
                          width: '360px',
                          height: '56px'
                        }} onChange={e => setNewTagName(e.target.value.toUpperCase())} onKeyPress={e => {
                          if (e.key === 'Enter') {
                            handleNewTagClick();
                          }
                        }} value={newTagName || ''} InputLabelProps={{
                          style: {
                            color: '#BEBEBE'
                          }
                        }} InputProps={{
                          startAdornment: <InputAdornment position="start">
                                  <Icon fill="#1D252D" name="custom-new_label" />
                                </InputAdornment>
                        }} />
                        </Box>

                        <Tooltip title={<div style={{
                        whiteSpace: 'pre-line'
                      }}>
                              {newTagName?.length == 0 ? 'Enter a tag name' : isExistingTag() ? 'Unable to add tag ' + newTagName + '\n It has already been added to ' + (segment.name || 'New Segment') : 'Add ' + newTagName + ' to ' + (segment.name || 'New Segment')}
                            </div>}>
                          <IconButton sx={{
                          width: '40px',
                          height: '40px',
                          textAlign: 'center',
                          padding: 0,
                          backgroundColor: '#fff',
                          ':hover': {
                            backgroundColor: '#DAEFFF'
                          }
                        }} onClick={handleNewTagClick}>
                            <AddCircleFilledIcon fill={newTagName?.length > 0 && !isExistingTag() ? '#53A6D6' : '#53A6D64D'} />
                          </IconButton>
                        </Tooltip>

                        <Box display="flex" alignItems="center">
                          <Stack direction="row" spacing="10px" sx={{
                          flexWrap: 'wrap',
                          gap: 1
                        }}>
                            {segment.tags?.length > 0 && segment.tags.map((tag, index) => <Chip key={index} label={tag} deleteIcon={<CloseOutlinedIcon sx={{
                            transform: 'scale(0.75)'
                          }} />} onDelete={handleDelete(index)} />)}
                          </Stack>
                        </Box>
                      </Stack>
                    </Stack>
                  </Stack>
                  <Divider />
                  <Stack spacing="30px">
                    <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{
                    width: '100%'
                  }}>
                      <Typography sx={{
                      fontFamily: 'Nexa',
                      fontSize: 20,
                      color: '#1D252D'
                    }}>Description </Typography>{' '}
                    </Box>

                    <Box sx={{
                    flexGrow: 2
                  }}>
                      <CustomTextField multiline label={segment.description ? 'Description' : ''} placeholder="Description" sx={{
                      pr: '20px',
                      width: '100%'
                    }} onChange={e => updateSegment('description', e.target.value)} value={segment.description || ''} InputLabelProps={{
                      style: {
                        color: '#BEBEBE'
                      }
                    }} InputProps={{
                      startAdornment: <InputAdornment position="start">
                              <Icon fill="#1D252D" name="custom-description" />
                            </InputAdornment>
                    }} />
                    </Box>
                  </Stack>

                  <Divider />
                  {builderType === BuilderType.BUILDER && <EditSegmentBuilder segment={segment} updateSegment={updateSegment} setSegment={setSegment} setHasUnsavedChanges={setHasUnsavedChanges} showErrors={showErrors} setIsValid={setIsValid} />}
                  {builderType === BuilderType.ADVANCED && <EditSegmentAdvanced segment={segment} updateSegment={updateSegment} setSegment={setSegment} setIsValid={setIsValid} />}
                  {builderType === BuilderType.AI && <EditSegmentAI segment={segment} updateSegment={updateSegment} setIsValid={setIsValid} company={asCompany.id} />}
                  
                  {view !== EditMode.CREATE && <>
                    <Divider />


                    <Stack spacing="30px">
                      <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{
                      width: '100%'
                    }}>
                        <Typography sx={{
                        fontFamily: 'Nexa',
                        fontSize: 20,
                        color: '#1D252D'
                      }}>Journeys </Typography>{' '}
                      </Box>
                      {journeysTriggered.length + journeysEdited.length > 0 ? <Stack direction="row" spacing={'10px'} sx={{
                      flexWrap: 'wrap',
                      gap: 1
                    }}>
                          {journeysTriggered.map(journey => <Button variant="text" key={journey.id} sx={{
                        cursor: 'pointer',
                        height: '28px',
                        color: '#FFFFFF',
                        backgroundColor: '#53A6D6',
                        borderRadius: '8px',
                        px: '8px',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        textTransform: 'none',
                        alignItems: 'center',
                        ':hover': {
                          backgroundColor: '#53A6D6'
                        }
                      }} onClick={() => {
                        history.push(`/customer-journeys/${journey.id}`);
                      }}>
                              <Icon name="custom-ads_click_3" />
                              <Typography sx={{
                          pl: 1,
                          color: '#FFFFFF'
                        }}> {journey.name}</Typography>
                            </Button>)}
                          {journeysEdited.map(journey => <Button variant="text" key={journey.id} sx={{
                        cursor: 'pointer',
                        height: '28px',
                        color: '#FFFFFF',
                        backgroundColor: '#53A6D6',
                        borderRadius: '8px',
                        px: '8px',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        textTransform: 'none',
                        alignItems: 'center',
                        ':hover': {
                          backgroundColor: '#53A6D6'
                        }
                      }} onClick={() => {
                        history.push(`/customer-journeys/${journey.id}`);
                      }}>
                              <Icon name="custom-border_color" />
                              <Typography sx={{
                          pl: 1,
                          color: '#FFFFFF'
                        }}> {journey.name}</Typography>
                            </Button>)}
                        </Stack> : <Typography sx={{
                      fontFamily: 'Nexa',
                      fontSize: 14,
                      color: '#1D252D80'
                    }}>
                          Not triggered or edited by any journeys{' '}
                        </Typography>}
                    </Stack>
                  </>}

                  <Divider />

                  <Stack spacing="30px">
                    <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{
                    width: '100%'
                  }}>
                      <Typography sx={{
                      fontFamily: 'Nexa',
                      fontSize: 20,
                      color: '#1D252D'
                    }}>Distinct Values </Typography>{' '}
                    </Box>

                    <Stack direction="row" spacing={'30px'} sx={{
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                      <Stack direction="row" spacing={'10px'}>
                        <AntSwitch sx={{
                        ml: '8px',
                        colorPrimary: '#53A6D6'
                      }} checked={distinctValuesSwitch} onChange={handleDistinctValuesSwitchChange} inputProps={{
                        'aria-label': 'ant design'
                      }} />
                        <Typography sx={{
                        fontFamily: 'Nexa',
                        fontSize: 14,
                        color: '#1D252D'
                      }}>Use distinct values </Typography>
                      </Stack>
                      <Stack direction="row" spacing={'10px'} sx={{
                      display: 'flex',
                      alignItems: 'center'
                    }}>
                        <CustomRadio disabled={!distinctValuesSwitch} checked={segment.distinct_on === 'email'} onChange={handledistinctValuesRadioChange} value="email" />
                        <Typography sx={{
                        fontFamily: 'Nexa',
                        fontSize: 14,
                        color: distinctValuesSwitch ? '#1D252D' : '#1D252D80'
                      }}>
                          Distinct emails{' '}
                        </Typography>
                      </Stack>
                      <Stack direction="row" spacing={'10px'} sx={{
                      display: 'flex',
                      alignItems: 'center'
                    }}>
                        <CustomRadio disabled={!distinctValuesSwitch} checked={segment.distinct_on === 'phone'} onChange={handledistinctValuesRadioChange} value="phone" />
                        <Typography sx={{
                        fontFamily: 'Nexa',
                        fontSize: 14,
                        color: distinctValuesSwitch ? '#1D252D' : '#1D252D80'
                      }}>
                          Distinct phone numbers{' '}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>

                  {segmentError ? <div className="form-error">{segmentError}</div> : null}
                </Stack>

                <Stack>
                  <Divider />
                  <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{
                  minHeight: '80px',
                  width: '100%',
                  p: '24px'
                }}>

                    <Box display="flex" flexDirection="column" justifyContent="space-between" alignItems="flex-start">
                      {hasPermission('customer.manage_advanced_sql') && builderType !== BuilderType.ADVANCED && <Button onClick={handleAdvancedSwitch} variant="text">
                          <Icon fill="#53A6D6" name="custom-edit_note" size={26} />
                          <Typography sx={{
                        textTransform: 'none',
                        fontFamily: 'Nexa',
                        fontSize: 14,
                        color: '#53A6D6',
                        paddingLeft: 1
                      }}>
                            Advanced Query Editor
                          </Typography>
                        </Button>}
                      {hasIntegration('ai_segments') && flags['ai_segment'] && builderType === BuilderType.BUILDER && <Button onClick={handleAISwitch} variant="text">
                          <SmartToyIcon fill="#53A6D6" size={26} sx={{
                        marginRight: '18px'
                      }} />
                          <Typography sx={{
                        textTransform: 'none',
                        fontFamily: 'Nexa',
                        fontSize: 14,
                        color: '#53A6D6',
                        paddingLeft: 1
                      }}>
                            AI Assistant
                          </Typography>
                        </Button>}
                    </Box>
                    <Stack direction="row" spacing="20px" alignItems="center">
                      {testCount && <Stack display="flex" alignItems="center" spacing="10px" direction="row">
                          <Typography>{testCount}</Typography>
                          <Button sx={{
                        backgroundColor: '#FFFFFF',
                        color: '#53A6D6',
                        cursor: 'cursor',
                        borderRadius: '27px',
                        textTransform: 'none',
                        border: '1px solid #53A6D6',
                        height: '32px',
                        width: '132px',
                        '&:hover': {
                          backgroundColor: '#FFFFFF',
                          color: '#53A6D6'
                        }
                      }} onClick={handleViewSample} variant="outlined">
                            View Sample
                          </Button>
                          {hasMore && <Button disabled={testLoading} sx={{
                        backgroundColor: '#FFFFFF',
                        color: '#53A6D6',
                        cursor: 'cursor',
                        borderRadius: '27px',
                        textTransform: 'none',
                        border: '1px solid #53A6D6',
                        height: '32px',
                        width: '132px',
                        '&:hover': {
                          backgroundColor: '#FFFFFF',
                          color: '#53A6D6'
                        }
                      }} onClick={handleAskMore} variant="outlined">
                              Get Total {testLoading && <Loader size="sm" className="ml-2" />}
                            </Button>}
                        </Stack>}
                      {testError && <span>{testError}</span>}
                      <Button sx={{
                      backgroundColor: '#FFFFFF',
                      opacity: testLoading || isSaving || !isValid ? '50%' : '100%',
                      color: '#53A6D6',
                      cursor: testLoading || isSaving || !isValid ? 'default' : 'cursor',
                      borderRadius: '27px',
                      textTransform: 'none',
                      border: '1px solid #53A6D6',
                      height: '32px',
                      width: '112px',
                      '&:hover': {
                        backgroundColor: '#FFFFFF',
                        color: '#53A6D6'
                      }
                    }} onClick={handleTest} variant="outlined">
                        Test {testLoading && <Loader size="sm" className="ml-2" />}
                      </Button>

                      <Button sx={{
                      backgroundColor: '#53A6D6',
                      opacity: !segment.name || isSaving || !isValid ? '50%' : '100%',
                      color: '#FFFFFF',
                      cursor: !segment.name || isSaving || !isValid ? 'default' : 'cursor',
                      borderRadius: '27px',
                      textTransform: 'none',
                      border: '1px solid #53A6D6',
                      height: '32px',
                      width: '112px',
                      '&:hover': {
                        backgroundColor: '#53A6D6',
                        color: '#fff'
                      }
                    }} onClick={e => {
                      handleSubmit();
                    }} variant="outlined">
                        {view === EditMode.EDIT ? 'Save' : isSaving ? <Loader size="sm" /> : 'Create'}
                      </Button>
                    </Stack>
                  </Box>
                </Stack>
              </Box>
            </Paper>
          </Box>
        </div>
      </div>

      <Popper anchorEl={segmentTypesMenuAnchorEl} sx={{
      zIndex: 200
    }} open={segmentTypesOpen} onClose={handleSegmentTypesClose} placement="bottom-start">
        <ClickAwayListener onClickAway={handleSegmentTypesClose}>
          <Paper elevation={0} sx={{
          borderRadius: '16px',
          overflow: 'visible',
          width: '216px',
          filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))'
        }}>
            <Box sx={{
            py: 1
          }}>
              <Box display="flex" flexDirection="column">
                {segmentTypes.map(opt => <Button variant="text" fullWidth key={opt.key} sx={{
                cursor: 'pointer',
                height: '48px',
                color: '#1D252D',
                textTransform: 'none',
                justifyContent: 'flex-start',
                px: 2
              }} onClick={e => {
                updateSegment('time_dynamic_segment', opt.value);
                handleSegmentTypesClose();
              }}>
                    <Icon name={opt.icon} />
                    <Typography sx={{
                  pl: 1
                }}> {opt.name}</Typography>
                  </Button>)}
              </Box>
            </Box>
          </Paper>
        </ClickAwayListener>
      </Popper>

      <Popper anchorEl={companyTagsMenuAnchorEl} sx={{
      zIndex: 200
    }} open={companyTagsOpen} onClose={handleCompanyTagsClose} placement="bottom-start">
        <ClickAwayListener onClickAway={handleCompanyTagsClose}>
          <Paper elevation={0} sx={{
          borderRadius: '16px',
          overflowY: 'scroll',
          width: '360px',
          maxHeight: '40vh',
          filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))',
          scrollbarWidth: 'thin',
          '&::-webkit-scrollbar': {
            width: '0.4em'
          },
          '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
            marginTop: '20px',
            marginBottom: '20px'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888'
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#555'
          },
          filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))',
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 200
          }
        }}>
            <Box sx={{
            p: 1
          }}>
              <Grid sx={{
              p: 1
            }} container justifyContent="left">
                <Typography>Existing company tags:</Typography>
              </Grid>

              <Box display="flex" flexDirection="column">
                {companyTags.map(opt => <Button variant="text" fullWidth key={opt} sx={{
                cursor: 'pointer',
                height: '32px',
                color: '#1D252D',
                textTransform: 'none',
                justifyContent: 'flex-start',
                px: 2
              }} onClick={e => {
                setNewTagName(opt.toUpperCase());
                handleCompanyTagsClose();
              }}>
                    <Typography sx={{
                  pl: 1
                }}> {opt}</Typography>
                  </Button>)}
              </Box>
            </Box>
          </Paper>
        </ClickAwayListener>
      </Popper>

      <Prompt when={hasUnsavedChanges} message="There are unsaved changes, do you wish to discard them?" />
    </>;
};