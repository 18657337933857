import React from 'react';
function Icon(props) {
  return <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" fill="none" viewBox="0 0 20 21">
      <mask id="mask0_424_258" style={{
      maskType: 'alpha'
    }} width="20" height="21" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0 0.257H20V20.257H0z"></path>
      </mask>
      <g mask="url(#mask0_424_258)">
        <path fill={props.fill || '#1D252D'} d="M10.003 10.232c.417 0 .77-.146 1.061-.439.29-.292.436-.647.436-1.064 0-.417-.146-.77-.438-1.061a1.453 1.453 0 00-1.065-.436c-.417 0-.77.146-1.061.438-.29.293-.436.647-.436 1.064 0 .417.146.771.438 1.062.293.29.647.436 1.065.436zm-.008 8.01a.92.92 0 01-.31-.051 1.182 1.182 0 01-.28-.147c-1.985-1.8-3.458-3.454-4.42-4.963-.962-1.508-1.443-2.905-1.443-4.19 0-2.028.646-3.638 1.94-4.83C6.775 2.87 8.28 2.273 10 2.273c1.719 0 3.225.596 4.518 1.788 1.294 1.192 1.94 2.802 1.94 4.83 0 1.285-.48 2.682-1.443 4.19-.962 1.509-2.435 3.163-4.42 4.963a.726.726 0 01-.272.147c-.108.034-.217.05-.328.05z"></path>
      </g>
    </svg>;
}
export default Icon;