import React, { useRef } from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
export const CustomWidthTooltip = styled(({
  className,
  ...props
}) => {
  const textElementRef = useRef(null);
  return <Tooltip title={props.tip} disableHoverListener={props.tip?.length * 6.3 < textElementRef?.current?.offsetWidth} placement="left" classes={{
    popper: className
  }}>
      <div ref={textElementRef} style={{
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }}>
        {props.tip}
      </div>
    </Tooltip>;
})({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 600
  }
});