import { api, unwrap } from './api';
export function getForms(params) {
  return api.get('/forms', {
    params: {
      archived: false,
      ...params
    }
  });
}
export function getForm(formId, params) {
  return api.get(`/forms/${formId}`, {
    params
  });
}
export function postForm(body) {
  return api.post('/forms', body);
}
export function patchForm(formId, body) {
  return api.patch(`/forms/${formId}`, body);
}
export function deleteForm(formId) {
  return api.delete(`/forms/${formId}`);
}
export function getFormsTags(companyId) {
  return api.get(`/forms/tags/${companyId}`);
}
export function duplicateForm(id, company_ids) {
  return api.post(`/forms/${id}/duplicate`, {
    company_ids
  });
}

// facebook forms fetch
// ----------------------

export function fetchFacebookAccounts(tokenId) {
  return api.get(`/facebook/token/${tokenId}/accounts`).then(unwrap);
}
export function fetchFacebookPages(tokenId) {
  return api.get(`/facebook/token/${tokenId}/pages`).then(unwrap);
}
export function fetchFacebookForms(tokenId, pageId, companyId) {
  return api.get(`/facebook/token/${tokenId}/pages/${pageId}/forms`, {
    params: {
      company: companyId
    }
  }).then(unwrap);
}
export function fetchFacebookBusinesses(tokenId) {
  return api.get(`/facebook/token/${tokenId}/businesses`).then(unwrap);
}

/* export function getFacebookTokens(params) {
  return api.get(`/facebook/token${buildQueryParams(params)}`).then(unwrap); */