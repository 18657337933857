import React, { useState, useContext, useEffect } from 'react';
import { CustomerProfileModal } from './customer-profile-modal.component';
import { BuyPhoneNumberModal } from './buy-phone-number-modal.component';
import { UserStateContext } from 'context/user-state-context';
export const PhoneNumberModal = props => {
  const {
    flags,
    user
  } = useContext(UserStateContext);
  const [showBuy, setShowBuy] = useState(false);
  useEffect(() => {
    if (props.profile) {
      setShowBuy(props.profile.brand_status === 'APPROVED' && props.profile.campaign_status === 'VERIFIED');
    }
  }, [props.profile]);
  if (showBuy) {
    // Only allow them to purchase a phone number if superuser or
    // the brand status is approved and the campaign is verified
    if (user?.is_superuser || props.profile?.brand_status === 'APPROVED' && props.profile?.campaign_status === 'VERIFIED') {
      return <BuyPhoneNumberModal {...props} profile={props.profile} setShowBuy={setShowBuy} />;
    }
  }
  if (!props.profile) {
    // We're not loaded yet so skip for now.
    return null;
  }
  return <CustomerProfileModal {...props} profile={props.profile} setShowBuy={setShowBuy} />;
};