import React from 'react';
function Icon(props) {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none" viewBox="0 0 24 25">
      <mask id="mask0_420_208" style={{
      maskType: 'alpha'
    }} width="25" height="25" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#1D252D" d="M0 0.5H24V24.5H0z"></path>
      </mask>
      <g mask="url(#mask0_420_208)">
        <path fill={props.fill || '#1D252D'} d="M2.7 16.39V4.615c0-.25.083-.458.25-.625a.848.848 0 01.625-.25H16.05c.25 0 .458.084.625.25.167.167.25.375.25.625v8.475c0 .25-.083.459-.25.625a.848.848 0 01-.625.25H6.225l-2.75 2.75c-.15.167-.317.209-.5.125-.183-.083-.275-.233-.275-.45zm4.425 1.975a.867.867 0 01-.637-.262.869.869 0 01-.263-.638v-1.5H18.6l.325.325V7.24h1.5c.25 0 .458.084.625.25.167.167.25.375.25.625V20.79c0 .2-.092.342-.275.425-.183.084-.35.05-.5-.1l-2.75-2.75H7.125zm8.3-13.125H4.2v7.925l.675-.7h10.55V5.24z"></path>
      </g>
    </svg>;
}
export default Icon;